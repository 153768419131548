import * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';
import { CapaErrorCode, Output } from '@generated/Common/graphql';
import { Exact, Maybe, Scalars } from '@generated/Common/types';
import { CapaQuotationRfqEventType } from './enum';
import { CaPaConnectFileDetail, CaPaConnectGroupsDetailOutput } from './types';

const defaultOptions = {};

export type Query = {
  __typename?: 'Query';
  fetchGroups: CapaConnectGroupsOutput;
  fetchGroup: CapaConnectGroupOutput;
  fetchFilePermission: CapaConnectFilePermissionOutput;
  fetchConnectChatMessages: CapaConnectChatMessagesOutput;
};

export enum CapaConnectGroupAccessLevel {
  Owner = 'OWNER',
  Manager = 'MANAGER',
  Member = 'MEMBER'
}

export enum CapaFileConvertStatus {
  Processing = 'PROCESSING',
  Queued = 'QUEUED',
  Converting = 'CONVERTING',
  Converted = 'CONVERTED',
  Failed = 'FAILED'
}

export enum CapaQuotationChatMessageType {
  AllowRequest = 'ALLOW_REQUEST',
  AllowResponse = 'ALLOW_RESPONSE',
  CompleteRequest = 'COMPLETE_REQUEST',
  ConfirmRequest = 'CONFIRM_REQUEST',
  DateDivider = 'DATE_DIVIDER',
  SimpleQuotation = 'SIMPLE_QUOTATION',
  DetailedQuotation = 'DETAILED_QUOTATION',
  FFQComplete = 'RFQ_COMPLETE',
  FFQConfirm = 'RFQ_CONFIRM',
  FFQExpirationConfirm = 'RFQ_EXPIRATION_CONFIRM',
  QuitChatroomPartner = 'QUIT_CHATROOM_PARTNER',
  QuitChatroomClient = 'QUIT_CHATROOM_CLIENT',
  ChatStart = 'CHAT_START',
  System = 'SYSTEM',
  Message = 'MESSAGE'
}

export enum CapaQuotationRfqAllowOpenFileStatus {
  Allow = 'ALLOW',
  Denied = 'DENIED',
  Request = 'REQUEST'
}

export enum CapaConnectChatMessageType {
  System = 'SYSTEM',
  Message = 'MESSAGE'
}

export type CapaQuotationSimpleQuotation = {
  __typename?: 'CAPAQuotationSimpleQuotation';
  quotation?: Maybe<Scalars['Float']>;
  excludeDeliveryFee?: Maybe<Scalars['Boolean']>;
  etc?: Maybe<Scalars['String']>;
};

export type CapaQuotationChatMessageMessage = {
  __typename?: 'CAPAQuotationChatMessageMessage';
  messageType?: Maybe<CapaQuotationChatMessageType>;
  message?: Maybe<Scalars['String']>;
};

export type CapaQuotationDetailedQuotationItem = {
  __typename?: 'CAPAQuotationDetailedQuotationItem';
  fileId?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  material?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  etc?: Maybe<Scalars['String']>;
};

export type CapaQuotationDetailedQuotation = {
  __typename?: 'CAPAQuotationDetailedQuotation';
  rfqId?: Maybe<Scalars['String']>;
  quotationPartnerId?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['String']>;
  expiration?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  item?: Maybe<Array<CapaQuotationDetailedQuotationItem>>;
  quotation?: Maybe<Scalars['Float']>;
  excludeDeliveryFee?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['String']>;
  etc?: Maybe<Scalars['String']>;
};

export type CapaQuotationChatRfq = {
  __typename?: 'CAPAQuotationChatRfq';
  rfqId?: Maybe<Scalars['String']>;
  userOrganizationName?: Maybe<Scalars['String']>;
  userManagerName?: Maybe<Scalars['String']>;
  partnerOrganizationName?: Maybe<Scalars['String']>;
  partnerManagerName?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  event?: Maybe<CapaQuotationRfqEventType>;
};

export type CapaQuotationChatMessageSimpleQuotation = {
  __typename?: 'CAPAQuotationChatMessageSimpleQuotation';
  messageType?: Maybe<CapaQuotationChatMessageType>;
  quotation?: Maybe<CapaQuotationSimpleQuotation>;
};

export type CapaQuotationChatMessageDetailedQuotation = {
  __typename?: 'CAPAQuotationChatMessageDetailedQuotation';
  messageType?: Maybe<CapaQuotationChatMessageType>;
  quotation?: Maybe<CapaQuotationDetailedQuotation>;
};

export type CapaQuotationChatMessageRfq = {
  __typename?: 'CAPAQuotationChatMessageRfq';
  messageType?: Maybe<CapaQuotationChatMessageType>;
  rfq?: Maybe<Array<CapaQuotationChatRfq>>;
};

export type CapaQuotationChatMessageUnion =
  | CapaQuotationChatMessageMessage
  | CapaQuotationChatMessageSimpleQuotation
  | CapaQuotationChatMessageDetailedQuotation
  | CapaQuotationChatMessageRfq;

export type CapaConnectGroupUser = {
  __typename?: 'CAPAConnectGroupUser';
  id?: Maybe<Scalars['ID']>;
  groupId?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
  connectUserName?: Maybe<Scalars['String']>;
  isCapaUser?: Maybe<Scalars['Boolean']>;
  accessLevel?: Maybe<CapaConnectGroupAccessLevel>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type CapaConnectSharedFile = {
  __typename?: 'CAPAConnectSharedFile';
  id?: Maybe<Scalars['ID']>;
  filePath?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  isFile?: Maybe<Scalars['Boolean']>;
  fileSize?: Maybe<Scalars['Float']>;
  originalFileExt?: Maybe<Scalars['String']>;
  status?: Maybe<CapaFileConvertStatus>;
  fileId?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  thumbnailUri?: Maybe<Scalars['String']>;
  drawingFile?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
  connectUserName?: Maybe<Scalars['String']>;
  permission?: CapaQuotationRfqAllowOpenFileStatus;
};

export type CapaConnectGroup = {
  __typename?: 'CAPAConnectGroup';
  id?: Maybe<Scalars['ID']>;
  groupName?: Maybe<Scalars['String']>;
  chatRoomId?: Maybe<Scalars['String']>;
  users?: Maybe<Array<CapaConnectGroupUser>>;
  files?: Maybe<Array<CapaConnectSharedFile>>;
  message?: Maybe<CapaQuotationChatMessageUnion>;
  accessLevel?: Maybe<CapaConnectGroupAccessLevel>;
  unReadCount?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type QueryGroupsDetail = {
  __typename?: 'Query';
  fetchGroups: CaPaConnectGroupsDetailOutput;
};

export type CapaConnectGroupsOutput = {
  __typename?: 'CAPAConnectGroupsOutput';
  result: CapaErrorCode;
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<Array<CapaConnectGroup>>;
};

export type CapaConnectGroupOutput = {
  __typename?: 'CAPAConnectGroupsOutput';
  result: CapaErrorCode;
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<Array<CapaConnectGroup>>;
};

export type CreateConnectFolderMutationVariables = Exact<{
  folderName: Scalars['String'];
  pathId: Scalars['String'];
}>;

export type RenameConnectFileMutationVariables = Exact<{
  fileId: Scalars['String'];
  toName: Scalars['String'];
}>;

export type ShareFilesToGroupMutationVariables = Exact<{
  groupId: Scalars['String'];
  connectFileIds: Scalars['String'];
  permission: CapaQuotationRfqAllowOpenFileStatus;
}>;

export type FetchFilePermissionVariables = Exact<{
  groupId: Scalars['String'];
  connectFileIds: Scalars['String'];
}>;

export type ManageDownloadPermissionMutationVariables = Exact<{
  groupId: Scalars['String'];
  groupUserId: Scalars['String'];
  connectFileId: Scalars['String'];
  status: CapaQuotationRfqAllowOpenFileStatus;
}>;

export type SendConnectChatMessageMutationVariables = Exact<{
  chatRoomId: Maybe<Scalars['String']>;
  message: Maybe<Scalars['String']>;
  fileIds: String[];
}>;

export type FetchConnectChatMessagesQueryVariables = Exact<{
  chatRoomId: Maybe<Scalars['String']>;
  limit: Maybe<Scalars['Float']>;
  offset: Maybe<Scalars['Float']>;
}>;

export type SubscribeConnectChatMessagesQueryVariables = Exact<{
  chatRoomId: Maybe<Scalars['String']>;
}>;

export type GroupsFileDetails = Exact<{
  fileId: Scalars['String'];
}>;

export type FetchGroupVariables = Exact<{
  groupId: Scalars['String'];
}>;

export type CreateConnectFolderMutation = { __typename?: 'Mutation' } & {
  createConnectFolder: { __typename?: 'Output' } & Pick<
    Output,
    'result' | 'errorMessage'
  >;
};

export type RenameConnectFileMutation = { __typename?: 'Mutation' } & {
  renameConnectFile: { __typename?: 'Output' } & Pick<
    Output,
    'result' | 'errorMessage'
  >;
};

export type ShareFilesToGroupMutation = { __typename?: 'Mutation' } & {
  shareFilesToGroup: { __typename?: 'Output' } & Pick<
    Output,
    'result' | 'errorMessage'
  >;
};

export type ManageDownloadPermissionMutation = { __typename?: 'Mutation' } & {
  manageDownloadPermission: { __typename?: 'Output' } & Pick<
    Output,
    'result' | 'errorMessage'
  >;
};

export type SendConnectChatMessageMutation = { __typename?: 'Mutation' } & {
  sendConnectChatMessage: { __typename?: 'Output' } & Pick<
    Output,
    'result' | 'errorMessage'
  >;
};

export type CapaConnectFilePermissionOutput = {
  __typename?: 'CAPAConnectFilePermissionOutput';
  result: CapaErrorCode;
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<Array<CapaConnectFilePermission>>;
};

export type CapaConnectChatMessageUnion = {
  __typename?: 'CAPAConnectChatMessageUnion';
  messageType: CapaConnectChatMessageType;
  message: Scalars['String'];
};

export type CapaConnectChatFile = {
  __typename?: 'CAPAConnectChatFile';
  fileId: Scalars['String'];
  fileName: Scalars['String'];
};

export type CapaConnectChatMessage = {
  __typename?: 'CAPAConnectChatMessage';
  id: Scalars['ID'];
  chatRoomId: Scalars['String'];
  groupId: Scalars['String'];
  senderId: Scalars['String'];
  senderName: Scalars['String'];
  senderEmail: Scalars['String'];
  message: CapaConnectChatMessageUnion;
  unReadCount: Scalars['Float'];
  chatFiles: CapaConnectChatFile;
  createdAt: Scalars['String'];
};

export type CapaConnectChatMessagesOutput = {
  __typename?: 'CAPAConnectChatMessagesOutput';
  result: CapaErrorCode;
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<Array<CapaConnectChatMessage>>;
};

export type CapaConnectFilePermission = {
  __typename?: 'CAPAConnectFilePermission';
  fileId?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  connectUserId?: Maybe<Scalars['String']>;
  groupUserId?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  isCapaUser?: Maybe<Scalars['Boolean']>;
  permission?: Maybe<CapaQuotationRfqAllowOpenFileStatus>;
};

export type FetchGroupsQuery = { __typename?: 'Query' } & {
  fetchGroups: { __typename?: 'CAPAConnectGroupsOutput' } & Pick<
    CapaConnectGroupsOutput,
    'result' | 'errorMessage' | 'data'
  > & {
      data?: Maybe<
        { __typename?: 'CAPAConnectGroup' } & Pick<
          CapaConnectGroup,
          | 'id'
          | 'groupName'
          | 'chatRoomId'
          | 'users'
          | 'files'
          | 'message'
          | 'unReadCount'
          | 'createdAt'
          | 'accessLevel'
          | 'updatedAt'
        >
      >;
    } & {
      users?: Maybe<
        { __typename?: 'CAPAConnectGroupUser' } & Pick<
          CapaConnectGroupUser,
          | 'id'
          | 'groupId'
          | 'email'
          | 'connectUserName'
          | 'isCapaUser'
          | 'accessLevel'
          | 'createdAt'
          | 'updatedAt'
        >
      >;
    } & {
      files?: Maybe<
        { __typename?: 'CAPAConnectSharedFile' } & Pick<
          CapaConnectSharedFile,
          | 'id'
          | 'filePath'
          | 'fileName'
          | 'isFile'
          | 'fileSize'
          | 'status'
          | 'fileId'
          | 'thumbnail'
          | 'drawingFile'
          | 'createdAt'
          | 'updatedAt'
          | 'groupId'
          | 'email'
        >
      >;
    };
};

export type FetchGroupQuery = { __typename?: 'Query' } & {
  fetchGroup: { __typename?: 'CAPAConnectGroupOutput' } & Pick<
    CapaConnectGroupOutput,
    'result' | 'errorMessage' | 'data'
  > & {
      data?: Maybe<
        { __typename?: 'CAPAConnectGroup' } & Pick<
          CapaConnectGroup,
          | 'id'
          | 'groupName'
          | 'chatRoomId'
          | 'users'
          | 'files'
          | 'message'
          | 'unReadCount'
          | 'createdAt'
          | 'accessLevel'
          | 'updatedAt'
        >
      >;
    } & {
      users?: Maybe<
        { __typename?: 'CAPAConnectGroupUser' } & Pick<
          CapaConnectGroupUser,
          | 'id'
          | 'groupId'
          | 'email'
          | 'connectUserName'
          | 'accessLevel'
          | 'createdAt'
          | 'updatedAt'
        >
      >;
    } & {
      files?: Maybe<
        { __typename?: 'CAPAConnectSharedFile' } & Pick<
          CapaConnectSharedFile,
          | 'id'
          | 'filePath'
          | 'fileName'
          | 'isFile'
          | 'fileSize'
          | 'status'
          | 'fileId'
          | 'thumbnail'
          | 'drawingFile'
          | 'createdAt'
          | 'updatedAt'
          | 'groupId'
          | 'email'
          | 'permission'
          | 'thumbnailUri'
        >
      >;
    };
};

export type FetchFilePermissionQuery = { __typename?: 'Query' } & {
  fetchFilePermission: {
    __typename?: 'CAPAConnectFilePermissionOutput';
  } & Pick<
    CapaConnectFilePermissionOutput,
    'result' | 'errorMessage' | 'data'
  > & {
      data?: Maybe<
        { __typename?: 'CAPAConnectFilePermission' } & Pick<
          CapaConnectFilePermission,
          | 'fileId'
          | 'fileName'
          | 'email'
          | 'connectUserId'
          | 'userName'
          | 'permission'
          | 'isCapaUser'
        >
      >;
    };
};

export type FetchConnectChatMessagesQuery = { __typename?: 'Query' } & {
  fetchConnectChatMessages: {
    __typename?: 'CAPAConnectChatMessagesOutput';
  } & Pick<
    CapaConnectChatMessagesOutput,
    'result' | 'errorMessage' | 'data'
  > & {
      data?: Maybe<
        { __typename?: 'CAPAConnectFilePermission' } & Pick<
          CapaConnectFilePermission,
          | 'fileId'
          | 'fileName'
          | 'email'
          | 'connectUserId'
          | 'userName'
          | 'permission'
        >
      >;
    };
};

export type FetchGroupsDetailQuery = { __typename?: 'QueryGroupsDetail' } & {
  fetchConnectFileDetail: { __typename?: 'CAPAConnectFileDetailOutput' } & Pick<
    CaPaConnectGroupsDetailOutput,
    'result' | 'errorMessage' | 'data'
  > & {
      data?: Maybe<
        { __typename?: 'CAPAConnectFileDetail' } & Pick<
          CaPaConnectFileDetail,
          | 'id'
          | 'fileName'
          | 'filePath'
          | 'isFile'
          | 'linkStatus'
          | 'filePermission'
          | 'groups'
          | 'thumbnail'
          | 'originalFileExt'
        >
      >;
    } & {
      groups?: Maybe<
        { __typename?: 'CAPAConnectGroup' } & Pick<
          CapaConnectGroup,
          | 'id'
          | 'groupName'
          | 'chatRoomId'
          | 'users'
          | 'files'
          | 'message'
          | 'unReadCount'
          | 'createdAt'
          | 'updatedAt'
        >
      >;
    } & {
      users?: Maybe<
        { __typename?: 'CAPAConnectGroupUser' } & Pick<
          CapaConnectGroupUser,
          | 'id'
          | 'groupId'
          | 'email'
          | 'connectUserName'
          | 'isCapaUser'
          | 'accessLevel'
          | 'createdAt'
          | 'updatedAt'
        >
      >;
    } & {
      files?: Maybe<
        { __typename?: 'CAPAConnectSharedFile' } & Pick<
          CapaConnectSharedFile,
          | 'id'
          | 'filePath'
          | 'fileName'
          | 'isFile'
          | 'fileSize'
          | 'status'
          | 'fileId'
          | 'thumbnail'
          | 'drawingFile'
          | 'createdAt'
          | 'updatedAt'
          | 'groupId'
          | 'email'
        >
      >;
    };
};

export const CreateConnectFolder = gql`
  mutation createConnectFolder($folderName: String!, $pathId: String!) {
    createConnectFolder(input: { folderName: $folderName, pathId: $pathId }) {
      result
      errorMessage
    }
  }
`;

export type CreateConnectFolderFn = Apollo.MutationFunction<
  CreateConnectFolderMutation,
  CreateConnectFolderMutationVariables
>;

export function useCreateConnectFolderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateConnectFolderMutation,
    CreateConnectFolderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateConnectFolderMutation,
    CreateConnectFolderMutationVariables
  >(CreateConnectFolder, options);
}

export type CreateConnectFolderMutationHookResult = ReturnType<
  typeof useCreateConnectFolderMutation
>;
export type CreateConnectFolderMutationResult =
  Apollo.MutationResult<CreateConnectFolderMutation>;
export type CreateConnectFolderMutationOptions = Apollo.BaseMutationOptions<
  CreateConnectFolderMutation,
  CreateConnectFolderMutationVariables
>;

export const RenameConnectFile = gql`
  mutation renameConnectFile($fileId: String!, $toName: String!) {
    renameConnectFile(input: { fileId: $fileId, toName: $toName }) {
      result
      errorMessage
    }
  }
`;

export type RenameConnectFileFn = Apollo.MutationFunction<
  RenameConnectFileMutation,
  RenameConnectFileMutationVariables
>;

export function useRenameConnectFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RenameConnectFileMutation,
    RenameConnectFileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RenameConnectFileMutation,
    RenameConnectFileMutationVariables
  >(RenameConnectFile, options);
}

export type RenameConnectFileMutationHookResult = ReturnType<
  typeof useRenameConnectFileMutation
>;
export type RenameConnectFileMutationResult =
  Apollo.MutationResult<RenameConnectFileMutation>;
export type RenameConnectFileMutationOptions = Apollo.BaseMutationOptions<
  RenameConnectFileMutation,
  RenameConnectFileMutationVariables
>;

export const ShareFilesToGroup = gql`
  mutation shareFilesToGroup(
    $groupId: String!
    $connectFileIds: [String!]!
    $permission: CAPAQuotationRfqAllowOpenFileStatus
  ) {
    shareFilesToGroup(
      input: {
        groupId: $groupId
        connectFileIds: $connectFileIds
        permission: $permission
      }
    ) {
      result
      errorMessage
    }
  }
`;

export type ShareFilesToGroupFn = Apollo.MutationFunction<
  ShareFilesToGroupMutation,
  ShareFilesToGroupMutationVariables
>;

export function useShareFilesToGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ShareFilesToGroupMutation,
    ShareFilesToGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ShareFilesToGroupMutation,
    ShareFilesToGroupMutationVariables
  >(ShareFilesToGroup, options);
}

export type ShareFilesToGroupMutationHookResult = ReturnType<
  typeof useShareFilesToGroupMutation
>;
export type ShareFilesToGroupMutationResult =
  Apollo.MutationResult<ShareFilesToGroupMutation>;
export type ShareFilesToGroupMutationOptions = Apollo.BaseMutationOptions<
  ShareFilesToGroupMutation,
  ShareFilesToGroupMutationVariables
>;

export const FetchGroups = gql`
  query fetchGroups($limit: Float, $offset: Float) {
    fetchGroups(input: { limit: $limit, offset: $offset }) {
      result
      errorMessage
      data {
        id
        groupName
        chatRoomId
        accessLevel
        users {
          id
          groupId
          email
          connectUserName
          isCapaUser
          accessLevel
          createdAt
          updatedAt
        }
        files {
          id
          filePath
          fileName
          isFile
          fileSize
          originalFileExt
          status
          fileId
          thumbnail
          drawingFile
          createdAt
          updatedAt
          groupId
          email
          connectUserName
        }
        message {
          __typename
        }
        unReadCount
        createdAt
        updatedAt
      }
    }
  }
`;

export function useFetchGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchGroupsQuery>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchGroupsQuery>(FetchGroups, options);
}

export function useFetchGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchGroupsQuery>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchGroupsQuery>(FetchGroups, options);
}

export type FetchGroupsQueryHookResult = ReturnType<typeof useFetchGroupsQuery>;
export type FetchGroupsLazyQueryHookResult = ReturnType<
  typeof useFetchGroupsLazyQuery
>;
export type FetchGroupsQueryResult = Apollo.QueryResult<FetchGroupsQuery>;

export const FetchGroupsDetail = gql`
  query fetchConnectFileDetail($fileId: String!) {
    fetchConnectFileDetail(input: { fileId: $fileId }) {
      result
      errorMessage
      data {
        id
        fileName
        filePath
        thumbnail
        originalFileExt
        linkStatus
        filePermission
        isFile
        groups {
          id
          groupName
          chatRoomId
          users {
            id
            groupId
            email
            connectUserName
            isCapaUser
            accessLevel
            createdAt
            updatedAt
          }
          files {
            id
            filePath
            fileName
            isFile
            fileSize
            status
            fileId
            drawingFile
            createdAt
            updatedAt
            groupId
            email
            connectUserName
          }
          message {
            __typename
          }
          unReadCount
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export function useFetchGroupsDetailQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchGroupsDetailQuery,
    GroupsFileDetails
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchGroupsDetailQuery, GroupsFileDetails>(
    FetchGroupsDetail,
    options
  );
}

export function useFetchGroupsDetailLazyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchGroupsDetailQuery,
    GroupsFileDetails
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchGroupsDetailQuery, GroupsFileDetails>(
    FetchGroupsDetail,
    options
  );
}

export const FetchFilePermission = gql`
  query fetchFilePermission($groupId: String!, $connectFileId: String!) {
    fetchFilePermission(
      input: { groupId: $groupId, connectFileId: $connectFileId }
    ) {
      result
      errorMessage
      data {
        accessLevel
        fileId
        fileName
        email
        connectUserId
        groupUserId
        userName
        permission
        isCapaUser
      }
    }
  }
`;

export function useFetchFilePermissionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchFilePermissionQuery,
    FetchFilePermissionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchFilePermissionQuery,
    FetchFilePermissionVariables
  >(FetchFilePermission, options);
}

export function useFetchFilePermissionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchFilePermissionQuery,
    FetchFilePermissionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchFilePermissionQuery,
    FetchFilePermissionVariables
  >(FetchFilePermission, options);
}

export type FetchFilePermissionQueryHookResult = ReturnType<
  typeof useFetchFilePermissionQuery
>;
export type FetchFilePermissionLazyQueryHookResult = ReturnType<
  typeof useFetchFilePermissionLazyQuery
>;
export type FetchFilePermissionQueryResult = Apollo.QueryResult<
  FetchFilePermissionQuery,
  FetchFilePermissionVariables
>;

export const ManageDownloadPermission = gql`
  mutation manageDownloadPermission(
    $groupId: String!
    $groupUserId: String!
    $connectFileId: String!
    $status: CAPAQuotationRfqAllowOpenFileStatus
  ) {
    manageDownloadPermission(
      input: {
        groupId: $groupId
        groupUserId: $groupUserId
        connectFileId: $connectFileId
        status: $status
      }
    ) {
      result
      errorMessage
    }
  }
`;

export type ManageDownloadPermissionFn = Apollo.MutationFunction<
  ManageDownloadPermissionMutation,
  ManageDownloadPermissionMutationVariables
>;

export function useManageDownloadPermissionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ManageDownloadPermissionMutation,
    ManageDownloadPermissionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ManageDownloadPermissionMutation,
    ManageDownloadPermissionMutationVariables
  >(ManageDownloadPermission, options);
}

export type ManageDownloadPermissionMutationHookResult = ReturnType<
  typeof useManageDownloadPermissionMutation
>;
export type ManageDownloadPermissionMutationResult =
  Apollo.MutationResult<ManageDownloadPermissionMutation>;
export type ManageDownloadPermissionMutationOptions =
  Apollo.BaseMutationOptions<
    ManageDownloadPermissionMutation,
    ManageDownloadPermissionMutationVariables
  >;

export const FetchGroup = gql`
  query fetchGroup(
    $groupId: String!
    $sortFileType: ConnectFileSortType
    $fileType: CAPAConnectSharedFileType
    $keyword: String
  ) {
    fetchGroup(
      input: {
        groupId: $groupId
        sortFileType: $sortFileType
        keyword: $keyword
        fileType: $fileType
      }
    ) {
      result
      errorMessage
      data {
        id
        groupName
        chatRoomId
        accessLevel
        users {
          id
          groupId
          email
          connectUserName
          isCapaUser
          accessLevel
          createdAt
          updatedAt
        }
        message {
          __typename
        }
        files {
          id
          filePath
          fileName
          isFile
          fileSize
          originalFileExt
          status
          fileId
          thumbnail
          thumbnailUri
          drawingFile
          createdAt
          updatedAt
          groupId
          email
          connectUserName
          permission
          hasCommentThread
        }
        unReadCount
        createdAt
        updatedAt
      }
    }
  }
`;
export const fetchCapaConnectSharedFile = gql`
  query fetchCapaConnectSharedFile(
    $groupId: String!
    $sortFileType: ConnectFileSortType
    $fileType: CAPAConnectSharedFileType
    $keyword: String
    $offset: Float
    $limit: Float
  ) {
    fetchCapaConnectSharedFile(
      input: {
        groupId: $groupId
        sortFileType: $sortFileType
        keyword: $keyword
        fileType: $fileType
        limit: $limit
        offset: $offset
      }
    ) {
      result
      errorMessage
      data {
        id
        filePath
        fileName
        isFile
        fileSize
        originalFileExt
        status
        fileId
        email
        thumbnail
        thumbnailUri
        drawingFile
        createdAt
        updatedAt
        groupId
        connectUserName
        permission
        hasCommentThread
        isFirstShared
      }
    }
  }
`;

export function useFetchGroupQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchGroupQuery, FetchGroupVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchGroupQuery, FetchGroupVariables>(
    FetchGroup,
    options
  );
}

export function useFetchGroupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchGroupQuery,
    FetchGroupVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchGroupQuery, FetchGroupVariables>(
    FetchGroup,
    options
  );
}

export type FetchGroupQueryHookResult = ReturnType<typeof useFetchGroupQuery>;
export type FetchGroupLazyQueryHookResult = ReturnType<
  typeof useFetchGroupLazyQuery
>;
export type FetchGroupQueryResult = Apollo.QueryResult<
  FetchGroupQuery,
  FetchGroupVariables
>;

export const SendConnectChatMessage = gql`
  mutation sendConnectChatMessage(
    $chatRoomId: String!
    $message: String!
    $fileIds: [String!]
  ) {
    sendConnectChatMessage(
      input: { chatRoomId: $chatRoomId, message: $message, fileIds: $fileIds }
    ) {
      result
      errorMessage
    }
  }
`;

export type SendConnectChatMessageFn = Apollo.MutationFunction<
  SendConnectChatMessageMutation,
  SendConnectChatMessageMutationVariables
>;

export function useSendConnectChatMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendConnectChatMessageMutation,
    SendConnectChatMessageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendConnectChatMessageMutation,
    SendConnectChatMessageMutationVariables
  >(SendConnectChatMessage, options);
}

export type SendConnectChatMessageMutationHookResult = ReturnType<
  typeof useSendConnectChatMessageMutation
>;
export type SendConnectChatMessageMutationResult =
  Apollo.MutationResult<SendConnectChatMessageMutation>;
export type SendConnectChatMessageMutationOptions = Apollo.BaseMutationOptions<
  SendConnectChatMessageMutation,
  SendConnectChatMessageMutationVariables
>;

export const FetchConnectChatMessages = gql`
  query fetchConnectChatMessages(
    $chatRoomId: String!
    $limit: Float
    $offset: Float
  ) {
    fetchConnectChatMessages(
      input: { chatRoomId: $chatRoomId, limit: $limit, offset: $offset }
    ) {
      result
      errorMessage
      data {
        id
        chatRoomId
        groupId
        senderId
        senderName
        senderEmail
        message {
          ... on CAPAConnectChatMessageMessage {
            messageType
            message
          }
          ... on CAPAConnectChatMessageInvited {
            messageType
            userName
          }
          ... on CAPAConnectChatMessageRemoved {
            messageType
            userName
          }
          ... on CAPAConnectChatMessageFileShared {
            messageType
            userName
            fileName
          }
          ... on CAPAConnectChatMessageLeave {
            messageType
            userName
          }
          ... on CAPAConnectChatMessageAnnotation {
            messageType
            annotationId
            commentId
            comment
            email
            userName
          }
          ... on CAPAConnectChatTutorialMessage {
            messageType
            message
            uiType
            fileCount
          }
        }
        unReadCount
        chatFiles {
          fileId
          fileName
          fileSize
          id
          linkStatus
          filePermission
          thumbnailUri
          originalFileExt
          permission
        }
        fileOwnerName
        fileOwnerEmail
        createdAt
      }
    }
  }
`;

export function useFetchConnectChatMessagesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchConnectChatMessagesQuery,
    FetchConnectChatMessagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchConnectChatMessagesQuery,
    FetchConnectChatMessagesQueryVariables
  >(FetchConnectChatMessages, options);
}

export function useFetchConnectChatMessagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchConnectChatMessagesQuery,
    FetchConnectChatMessagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchConnectChatMessagesQuery,
    FetchConnectChatMessagesQueryVariables
  >(FetchConnectChatMessages, options);
}

export type FetchConnectChatMessagesHookResult = ReturnType<
  typeof useFetchConnectChatMessagesQuery
>;
export type FetchConnectChatMessagesLazyQueryHookResult = ReturnType<
  typeof useFetchConnectChatMessagesLazyQuery
>;
export type FetchConnectChatMessagesQueryResult = Apollo.QueryResult<
  FetchConnectChatMessagesQuery,
  FetchConnectChatMessagesQueryVariables
>;

export const SubscribeConnectChatMessages = gql`
  subscription subscribeConnectChatMessages($chatRoomId: String!) {
    subscribeConnectChatMessages(input: { chatRoomId: $chatRoomId }) {
      result
      errorMessage
      data {
        id
        chatRoomId
        groupId
        senderId
        senderName
        senderEmail
        message {
          ... on CAPAConnectChatMessageMessage {
            messageType
            message
          }
          ... on CAPAConnectChatMessageInvited {
            messageType
            userName
          }
          ... on CAPAConnectChatMessageRemoved {
            messageType
            userName
          }
          ... on CAPAConnectChatMessageFileShared {
            messageType
            userName
            fileName
          }
          ... on CAPAConnectChatMessageLeave {
            messageType
            userName
          }
          ... on CAPAConnectChatMessageAnnotation {
            messageType
            annotationId
            commentId
            comment
            email
            userName
          }
          ... on CAPAConnectChatTutorialMessage {
            messageType
            message
            uiType
            fileCount
          }
        }
        unReadCount
        chatFiles {
          fileId
          fileName
          fileSize
          id
          linkStatus
          filePermission
          thumbnailUri
          originalFileExt
          permission
        }
        fileOwnerName
        fileOwnerEmail
        createdAt
      }
    }
  }
`;

export function useSubscribeConnectChatMessages(
  baseOptions?: Apollo.QueryHookOptions<
    FetchConnectChatMessagesQuery,
    SubscribeConnectChatMessagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    FetchConnectChatMessagesQuery,
    SubscribeConnectChatMessagesQueryVariables
  >(SubscribeConnectChatMessages, options);
}

export const UnshareFileFromGroup = gql`
  mutation unshareFileFromGroup(
    $groupId: String!
    $connectFileIds: [String!]
    $removeAllMyFile: Boolean
    $groupName: String
    $chatRoomId: String
    $messageIds: [String!]
  ) {
    unshareFileFromGroup(
      input: {
        groupId: $groupId
        connectFileIds: $connectFileIds
        removeAllMyFile: $removeAllMyFile
        groupName: $groupName
        chatRoomId: $chatRoomId
        messageIds: $messageIds
      }
    ) {
      result
      errorMessage
    }
  }
`;

export const FetchConnectMyDriveUploadFileInChat = gql`
  query fetchConnectMyDrive($limit: Float, $offset: Float, $pathId: String) {
    fetchConnectMyDrive(
      input: { limit: $limit, offset: $offset, pathId: $pathId }
    ) {
      result
      errorMessage
      data {
        files {
          id
          fileId
          isShared
          fileName
          fileSize
          originalFileExt
          filePath
          status
          thumbnail
          drawingFile
          isFile
          linkStatus
          filePermission
          thumbnailUri
          createdAt
          updatedAt
        }
        path {
          id
          folderName
        }
      }
    }
  }
`;

export const MoveConnectFile = gql`
  mutation moveConnectFile($fileId: String!, $destId: String!) {
    moveConnectFile(input: { fileId: $fileId, destId: $destId }) {
      result
      errorMessage
    }
  }
`;

export const RenameGroup = gql`
  mutation renameGroupName($groupId: String!, $toName: String!) {
    renameGroupName(input: { groupId: $groupId, toName: $toName }) {
      result
      errorMessage
    }
  }
`;

export const RemoveGroup = gql`
  mutation removeGroup($groupId: String!) {
    removeGroup(input: { groupId: $groupId }) {
      result
      errorMessage
    }
  }
`;

export const ManageGroup = gql`
  mutation manageGroupMember(
    $groupId: String!
    $userEmail: String!
    $action: CAPAConnectGroupUserAction!
    $accessLevel: CAPAConnectGroupAccessLevel!
  ) {
    manageGroupMember(
      input: {
        groupId: $groupId
        userEmail: $userEmail
        action: $action
        accessLevel: $accessLevel
      }
    ) {
      result
      errorMessage
    }
  }
`;

export const RegisterConnectUser = gql`
  mutation registerConnectUser(
    $userName: String!
    $userLanguage: Language!
    $isWebMember: Boolean!
  ) {
    registerConnectUser(
      input: {
        userName: $userName
        userLanguage: $userLanguage
        isWebMember: $isWebMember
      }
    ) {
      result
      errorMessage
      data {
        accessToken
        tokenType
        expiresIn
        refreshToken
        redirectUrl
      }
    }
  }
`;

export const SearchUser = gql`
  query searchUser($keyword: String!, $limit: Float, $offset: Float) {
    searchUser(input: { keyword: $keyword, limit: $limit, offset: $offset }) {
      result
      errorMessage
      data {
        email
        userId
        connectUserId
        userName
      }
    }
  }
`;

export const FetchConnectGroupChatRooms = gql`
  query fetchConnectGroupChatRooms($limit: Float, $offset: Float) {
    fetchConnectGroupChatRooms(input: { limit: $limit, offset: $offset }) {
      result
      errorMessage
      data {
        groupId
        groupName
        thumbnail
        senderName
        totalMembers
        unReadCount
        accessLevel
        chatRoomId
        originalFileExt
        lastMessage {
          ... on CAPAConnectChatMessageMessage {
            messageType
            message
            fileCount
          }
          ... on CAPAConnectChatMessageInvited {
            messageType
            userName
          }
          ... on CAPAConnectChatMessageRemoved {
            messageType
            userName
          }
          ... on CAPAConnectChatMessageFileShared {
            messageType
            userName
            fileName
          }
          ... on CAPAConnectChatMessageLeave {
            messageType
            userName
          }
          ... on CAPAConnectChatMessageAnnotation {
            messageType
            annotationId
            commentId
            comment
            email
            userName
          }
          ... on CAPAConnectChatTutorialMessage {
            messageType
            message
            uiType
            fileCount
          }
        }
        users {
          id
          userId
          groupId
          email
          connectUserName
          isCapaUser
          accessLevel
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const LeaveGroup = gql`
  mutation leaveGroup($groupId: String!) {
    leaveGroup(input: { groupId: $groupId }) {
      result
      errorMessage
    }
  }
`;

export const CreateConnectAnnotation = gql`
  mutation createConnectAnnotation(
    $groupId: String!
    $connectSharedFileId: String!
    $comment: String
    $anchorPos: CAPA3DPosInput
    $handlePos: CAPA3DPosInput
    $nodeId: Float
  ) {
    createConnectAnnotation(
      input: {
        groupId: $groupId
        connectSharedFileId: $connectSharedFileId
        comment: $comment
        anchorPos: $anchorPos
        handlePos: $handlePos
        nodeId: $nodeId
      }
    ) {
      result
      errorMessage
      data {
        id
        groupId
        groupUserId
        connectFileId
        anchorPos {
          x
          y
          z
        }
        handlePos {
          x
          y
          z
        }
        nodeId
        comments {
          id
          email
          userName
          comment
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const SearchGroups = gql`
  query searchGroups($limit: Float, $offset: Float, $groupName: String!) {
    searchGroups(
      input: { limit: $limit, offset: $offset, groupName: $groupName }
    ) {
      result
      errorMessage
      data {
        groupId
        groupName
        thumbnail
        senderName
        totalMembers
        unReadCount
        accessLevel
        chatRoomId
        originalFileExt
        lastMessage {
          ... on CAPAConnectChatMessageMessage {
            messageType
            message
            fileCount
          }
          ... on CAPAConnectChatMessageInvited {
            messageType
            userName
          }
          ... on CAPAConnectChatMessageRemoved {
            messageType
            userName
          }
          ... on CAPAConnectChatMessageFileShared {
            messageType
            userName
            fileName
          }
          ... on CAPAConnectChatMessageLeave {
            messageType
            userName
          }
          ... on CAPAConnectChatMessageAnnotation {
            messageType
            annotationId
            commentId
            comment
            email
            userName
          }
          ... on CAPAConnectChatTutorialMessage {
            messageType
            message
            uiType
            fileCount
          }
        }
        users {
          id
          userId
          groupId
          email
          connectUserName
          isCapaUser
          accessLevel
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const FetchConnectAnnotations = gql`
  query fetchConnectAnnotations(
    $groupId: String!
    $connectSharedFileId: String
  ) {
    fetchConnectAnnotations(
      input: { groupId: $groupId, connectSharedFileId: $connectSharedFileId }
    ) {
      result
      errorMessage
      data {
        id
        groupId
        groupUserId
        chatRoomId
        connectFileId
        nodeId
        connectFileName
        drawingFile
        anchorPos {
          x
          y
          z
        }
        handlePos {
          x
          y
          z
        }
        createdAt
        updatedAt
        hasNew
        comments {
          id
          email
          userName
          comment
          createdAt
          updatedAt
          edited
        }
      }
    }
  }
`;

export const SendAnnotationCommentToChat = gql`
  mutation sendAnnotationCommentToChat($commentId: String!) {
    sendAnnotationCommentToChat(input: { commentId: $commentId }) {
      result
      errorMessage
    }
  }
`;

export const RemoveConnectAnnotation = gql`
  mutation removeConnectAnnotation(
    $groupId: String!
    $connectSharedFileId: String!
    $annotationId: String!
  ) {
    removeConnectAnnotation(
      input: {
        groupId: $groupId
        connectSharedFileId: $connectSharedFileId
        annotationId: $annotationId
      }
    ) {
      result
      errorMessage
    }
  }
`;

export const RequestDownloadPermission = gql`
  mutation requestDownloadPermission(
    $groupId: String!
    $connectFileId: String
  ) {
    requestDownloadPermission(
      input: { groupId: $groupId, connectFileId: $connectFileId }
    ) {
      result
      errorMessage
    }
  }
`;

export const UpdateConnectAnnotation = gql`
  mutation updateConnectAnnotation(
    $annotationId: String!
    $anchorPos: CAPA3DPosInput
    $handlePos: CAPA3DPosInput
    $nodeId: Float
  ) {
    updateConnectAnnotation(
      input: {
        annotationId: $annotationId
        anchorPos: $anchorPos
        handlePos: $handlePos
        nodeId: $nodeId
      }
    ) {
      result
      errorMessage
      data {
        id
        groupId
        groupUserId
        connectFileId
        connectFileName
        anchorPos {
          x
          y
          z
        }
        handlePos {
          x
          y
          z
        }
        nodeId
        comments {
          id
          email
          userName
          comment
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const UpdateConnectAnnotationComment = gql`
  mutation updateConnectAnnotationComment(
    $groupId: String!
    $connectSharedFileId: String!
    $annotationId: String!
    $commentId: String!
    $comment: String!
    $edited: Boolean!
  ) {
    updateConnectAnnotationComment(
      input: {
        groupId: $groupId
        connectSharedFileId: $connectSharedFileId
        annotationId: $annotationId
        commentId: $commentId
        comment: $comment
        edited: $edited
      }
    ) {
      result
      errorMessage
      data {
        id
        groupId
        groupUserId
        connectFileId
        connectFileName
        anchorPos {
          x
          y
          z
        }
        handlePos {
          x
          y
          z
        }
        nodeId
        comments {
          id
          email
          userName
          comment
          createdAt
          updatedAt
          edited
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const CreateConnectAnnotationComment = gql`
  mutation createConnectAnnotationComment(
    $groupId: String!
    $connectSharedFileId: String!
    $annotationId: String!
    $comment: String!
  ) {
    createConnectAnnotationComment(
      input: {
        groupId: $groupId
        connectSharedFileId: $connectSharedFileId
        annotationId: $annotationId
        comment: $comment
      }
    ) {
      result
      errorMessage
      data {
        id
        groupId
        groupUserId
        connectFileId
        connectFileName
        drawingFile
        anchorPos {
          x
          y
          z
        }
        handlePos {
          x
          y
          z
        }
        nodeId
        comments {
          id
          email
          userName
          comment
          createdAt
          updatedAt
        }
        hasNew
        createdAt
        updatedAt
      }
    }
  }
`;

export const AddConnectFiles = gql`
  mutation addConnectFiles($connectFileIds: [String!]!) {
    addConnectFiles(input: { connectFileIds: $connectFileIds }) {
      result
      errorMessage
    }
  }
`;
export const AddConnectLinkFiles = gql`
  mutation addConnectLinkFiles($connectFileIds: [String!]!) {
    addConnectLinkFiles(input: { connectFileIds: $connectFileIds }) {
      result
      errorMessage
    }
  }
`;

export const FetchCAPANotification = gql`
  query fetchCAPANotification(
    $service: CAPAServiceType!
    $lastSeq: Float
    $limit: Float
    $offset: Float
  ) {
    fetchCAPANotification(
      input: {
        service: $service
        limit: $limit
        offset: $offset
        lastSeq: $lastSeq
      }
    ) {
      result
      errorMessage
      data {
        count
        notifications {
          seq
          data {
            ... on CAPANotificationConnect {
              groupId
              groupName
              userName
              chatRoomId
              accessLevel
              fileName
              comment
              message
              notificationType
              oldGroupName
              connectFileId
              commentId
              annotationId
              toBeShown
            }
          }
          read
          createdAt
        }
      }
    }
  }
`;

export const SubscribeCAPANotification = gql`
  subscription subscribeCAPANotification($serviceType: CAPAServiceType!) {
    subscribeCAPANotification(input: { serviceType: $serviceType }) {
      result
      errorMessage
      data {
        count
        notifications {
          seq
          data {
            ... on CAPANotificationConnect {
              groupId
              groupName
              userName
              accessLevel
              fileName
              comment
              message
              notificationType
              oldGroupName
            }
          }
          read
          createdAt
        }
      }
    }
  }
`;

export const RemoveConnectAnnotationComment = gql`
  mutation removeConnectAnnotationComment(
    $groupId: String!
    $connectSharedFileId: String!
    $annotationId: String!
    $commentId: String!
  ) {
    removeConnectAnnotationComment(
      input: {
        groupId: $groupId
        connectSharedFileId: $connectSharedFileId
        annotationId: $annotationId
        commentId: $commentId
      }
    ) {
      result
      errorMessage
      data {
        id
        groupId
        groupUserId
        connectFileId
        connectFileName
        drawingFile
        anchorPos {
          x
          y
          z
        }
        handlePos {
          x
          y
          z
        }
        nodeId
        comments {
          id
          email
          userName
          comment
          createdAt
          updatedAt
        }
        hasNew
        createdAt
        updatedAt
      }
    }
  }
`;

export const ReadConnectChatMessage = gql`
  mutation readConnectChatMessage($chatRoomId: String!) {
    readConnectChatMessage(input: { chatRoomId: $chatRoomId }) {
      result
      errorMessage
      data {
        lastChatMessageId
      }
    }
  }
`;

export const ReadNotification = gql`
  mutation readNotification($service: CAPAServiceType!, $seq: Float) {
    readNotification(input: { service: $service, seq: $seq }) {
      result
      errorMessage
    }
  }
`;

export const ReadConnectAnnotationComment = gql`
  mutation readConnectAnnotationComment($annotationId: String!) {
    readConnectAnnotationComment(input: { annotationId: $annotationId }) {
      result
      errorMessage
    }
  }
`;

export const SubscribeConnectUpdateData = gql`
  subscription subscribeConnectUpdateData {
    subscribeConnectUpdateData {
      result
      errorMessage
      data {
        groupId
        modelId
        comment
        nodeId
        email
        username
        authorEmail
        authorName
        authorId
        edited
        anchorPos {
          x
          y
          z
        }
        handlePos {
          x
          y
          z
        }
        annotationId
        commentId
        type
      }
    }
  }
`;

export const SubscribeFileStatusUpdate = gql`
  subscription subscribeFileStatusUpdate {
    subscribeFileStatusUpdate {
      result
      errorMessage
      data {
        fileId
        status
      }
    }
  }
`;

export const CheckRegisteredCapaService = gql`
  query checkRegisteredCapaService($emails: [String!]!) {
    checkRegisteredCapaService(emails: $emails) {
      result
      errorMessage
      data {
        email
        scopes
        connectUser {
          id
          userName
        }
      }
    }
  }
`;

export const UpdateConnectUser = gql`
  mutation updateConnectUser(
    $username: String
    $notificationFilterType: ConnectNotificationType
    $notificationFilterValue: Boolean
  ) {
    updateConnectUser(
      input: {
        username: $username
        notificationFilterType: $notificationFilterType
        notificationFilterValue: $notificationFilterValue
      }
    ) {
      result
      errorMessage
      data {
        id
        email
        userName
        notificationFilter {
          chatPush
          groupPush
          groupEmail
          commentThreadPush
          commentThreadEmail
          filePush
          fileEmail
        }
        userName
        usedSize
        availSize
        driveSize
        serviceAgreed
        privacyAgreed
        promotionAgreed
      }
    }
  }
`;
export const ClearNewNotification = gql`
  mutation clearNewNotification($service: CAPAServiceType!, $seq: Float) {
    clearNewNotification(input: { service: $service, seq: $seq }) {
      result
      errorMessage
    }
  }
`;

export const CreateGroupWithCapa = gql`
  mutation createGroupWithCapa {
    createGroupWithCapa {
      result
      errorMessage
      data {
        id
        chatRoomId
      }
    }
  }
`;

export const SendConnectChatMessageToCapa = gql`
  mutation sendConnectChatMessageToCapa(
    $chatRoomId: String
    $message: String
    $fileIds: [String!]
  ) {
    sendConnectChatMessageToCapa(
      input: { chatRoomId: $chatRoomId, message: $message, fileIds: $fileIds }
    ) {
      result
      errorMessage
    }
  }
`;

export const UpdateConnectUserFileLinkStatus = gql`
  mutation updateConnectUserFileLinkStatus(
    $connectFileIds: [String!]!
    $linkStatus: ConnectUserFileLinkStatus!
  ) {
    updateConnectUserFileLinkStatus(
      input: { connectFileIds: $connectFileIds, linkStatus: $linkStatus }
    ) {
      result
      errorMessage
      data {
        shareLink
        linkStatus
        filePermission
      }
    }
  }
`;

export const UpdateConnectUserFilePermission = gql`
  mutation updateConnectUserFilePermission(
    $connectFileIds: [String!]!
    $permission: ConnectUserFileStatus!
  ) {
    updateConnectUserFilePermission(
      input: { connectFileIds: $connectFileIds, permission: $permission }
    ) {
      result
      errorMessage
    }
  }
`;

export const FetchConnectUserLinkFiles = gql`
  query fetchConnectUserLinkFiles(
    $shareLink: [String!]!
    $email: String
    $ip: String!
  ) {
    fetchConnectUserLinkFiles(
      input: { shareLink: $shareLink, email: $email, ip: $ip }
    ) {
      result
      errorMessage
      data {
        files {
          id
          filePath
          fileName
          fileExtension
          fileOwnerUsername
          fileOwnerEmail
          isFile
          fileSize
          linkStatus
          filePermission
          originalFileExt
          status
          fileId
          thumbnail
          thumbnailUri
          drawingFile
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const SubscribeConnectUserFileStatus = gql`
  subscription subscribeConnectUserFileStatus($fileId: String!) {
    subscribeConnectUserFileStatus(input: { fileId: $fileId }) {
      result
      errorMessage
      data {
        id
        linkStatus
        filePermission
      }
    }
  }
`;
