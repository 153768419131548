import React, { useEffect, useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField
} from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import { CustomizedButton } from '@components/Customized';
import { useStyles } from './styles';
import { KEY_CODE } from '@routes/Dashboard/types';
import { useTranslation } from 'react-i18next';
import i18n from '@i18n/index';
import { LIMIT_CHARACTERS_GROUP_NAME } from '@routes/Common/types';
import { getFileExtension } from '@utils/file';

interface Props {
  isOpen: boolean;
  folderNameValue: string;
  onClose?: () => void;
  onSubmit?: () => void;
  setValue?: (arg0: any) => void;
  dialogInfo: {
    title: string;
    submitButtonName: string;
  };
  checkedFiles?: any;
  disabledSubmitButton?: boolean;
  setDisbaledRenameSubmitButton?: () => void;
}

const FolderFormGroupDialog: React.FC<Props> = ({
  folderNameValue,
  isOpen,
  onClose,
  onSubmit,
  setValue,
  dialogInfo,
  checkedFiles,
  disabledSubmitButton,
  setDisbaledRenameSubmitButton
}) => {
  const classes = useStyles();
  const folderNameTextFieldRef = useRef<any>(null);
  const folderNameTextFieldId = 'folder-name-text-field';
  const { t } = useTranslation();
  const languageValue = i18n.language;

  useEffect(() => {
    if (isOpen) {
      if (setDisbaledRenameSubmitButton) setDisbaledRenameSubmitButton();
    } else {
      setTimeout(() => {
        if (setValue) setValue(t('my_drive.new_folder.default_value'));
      }, 200);
    }
    const onHandlerFolderNameTextField = (event: any) => {
      if (
        folderNameTextFieldRef &&
        folderNameTextFieldRef.current &&
        !folderNameTextFieldRef.current?.contains(event.target)
      ) {
        const folderNameTextFieldEl: any = document.getElementById(
          folderNameTextFieldId
        );

        if (checkedFiles) {
          folderNameTextFieldEl.focus();
          folderNameTextFieldEl.setSelectionRange(
            0,
            folderNameTextFieldEl.value.length -
              getFileExtension(folderNameTextFieldEl.value).length
          );
        } else {
          folderNameTextFieldEl.select();
        }
      }
    };

    window.addEventListener('click', onHandlerFolderNameTextField);
    return () =>
      window.removeEventListener('click', onHandlerFolderNameTextField);
  }, [isOpen]);

  useEffect(() => {
    if (setValue) setValue(t('my_drive.new_folder.default_value'));
  }, [languageValue]);

  useEffect(() => {
    if (folderNameValue)
      setValue &&
        setValue(
          folderNameValue?.length > LIMIT_CHARACTERS_GROUP_NAME
            ? folderNameValue?.substring(0, folderNameValue?.length - 3)
            : folderNameValue
        );
  }, []);

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={isOpen}
      onClose={onClose && onClose}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <DialogTitle classes={{ root: classes.rootTitle }} disableTypography>
        <div>{t(dialogInfo.title)}</div>
        {onClose && <Clear className={classes.cancelBtn} onClick={onClose} />}
      </DialogTitle>
      <DialogContent className={classes.csDialogContent}>
        <TextField
          ref={folderNameTextFieldRef}
          id={folderNameTextFieldId}
          value={folderNameValue ?? undefined}
          placeholder=""
          variant="outlined"
          size="small"
          fullWidth
          onChange={(event) => setValue && setValue(event.target.value)}
          onKeyDown={(e) => {
            if (e.keyCode === KEY_CODE.ENTER) {
              onSubmit && onSubmit();
            }
          }}
        />
      </DialogContent>
      <DialogActions classes={{ root: classes.action }}>
        <div className={classes.wrapperBtn}>
          <CustomizedButton
            variant="contained"
            color="default"
            onClick={onClose}
          >
            {t('my_drive.new_folder.cancel_button')}
          </CustomizedButton>
          <CustomizedButton
            type="submit"
            variant="contained"
            color="primary"
            onClick={onSubmit}
            disabled={
              !folderNameValue.trim() ||
              disabledSubmitButton ||
              folderNameValue.length > LIMIT_CHARACTERS_GROUP_NAME
            }
          >
            {t(dialogInfo.submitButtonName)}
          </CustomizedButton>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default FolderFormGroupDialog;
