import { colorSet } from '@components/Provider';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  '.MuiContainer-root': {
    padding: 0
  },
  tabs: {
    '& .MuiTab-textColorInherit': {
      opacity: 1,
      fontWeight: 700,
      fontSize: 16,
      minHeight: 54,
      borderBottom: `1px solid ${colorSet.gray400}`,
      color: `${colorSet.gray500}`
    },
    '& .MuiTabs-indicator': {
      backgroundColor: `${colorSet.gray900}`
    }
  },
  container: {
    marginTop: 10,
    marginBottom: 10,

    [theme.breakpoints.down('xs')]: {
      margin: 0
    }
  },
  title: {
    fontWeight: 700,
    fontSize: 16,
    textTransform: 'initial',
    color: `${colorSet.gray500}`
  },
  active: {
    color: `${colorSet.gray900} !important`
  }
}));
