import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  stringAvatar,
  stringToColor
} from '@routes/Dashboard/Dialog/Share/utils';
import { Avatar } from '@material-ui/core';
import { getDisplayedAvatarString } from '@utils/common';
import { colorSet } from '@components/Provider';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1)
      }
    },
    customAvatar: {
      width: 36,
      height: 36,
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '20px',
      marginLeft: '0 !important',
      textTransform: 'uppercase',
      [theme.breakpoints.down('xs')]: {
        width: 48,
        height: 48,
        fontSize: 15,
        lineHeight: '21px'
      }
    },
    customNotCapaAvt: {
      width: 36,
      height: 36,
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '20px',
      marginLeft: '0 !important',
      background: '#fff',
      textTransform: 'uppercase',
      color: colorSet.gray500,
      border: `1px dashed ${colorSet.gray500} !important`,
      [theme.breakpoints.down('xs')]: {
        width: 48,
        height: 48,
        fontSize: 15,
        lineHeight: '21px'
      }
    },
    small: {
      width: 28,
      height: 28,
      fontSize: 12
    },
    xSmall: {
      width: 32,
      height: 32,
      fontSize: 12,
      lineHeight: '18px'
    },
    medium: {
      width: 36,
      height: 36
    },
    mid: {
      width: 48,
      height: 48,
      fontSize: 20
    },
    large: {
      fontSize: 30,
      width: theme.spacing(11),
      height: theme.spacing(11)
    }
  })
);

interface Props {
  item?: any;
  size?: string;
  showTooltip?: boolean;
  internalClassName?: any;
}

const CapaAvatar = (props: Props) => {
  const { item, size, internalClassName } = props;
  const classes = useStyles();

  if (!item) return <></>;
  const textAvatar = getDisplayedAvatarString(
    item.connectUserName ?? item.nickName,
    item.email
  );
  const bgAvatar = item.isCapaUser
    ? stringToColor(
        getDisplayedAvatarString(
          item.connectUserName ?? item.nickName,
          item.email
        )
      )
    : '#fff';
  const sizeClass =
    size == 'small'
      ? classes.small
      : size === 'xSmall'
      ? classes.xSmall
      : size == 'large'
      ? classes.large
      : size == 'mid'
      ? classes.mid
      : classes.medium;
  return (
    <Avatar
      {...stringAvatar(textAvatar)}
      alt={item.connectUserName ?? item.nickName}
      style={{
        backgroundColor: bgAvatar
      }}
      className={clsx(
        item.isCapaUser ? classes.customAvatar : classes.customNotCapaAvt,
        sizeClass,
        internalClassName
      )}
    />
  );
};

export default CapaAvatar;
