import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DROP_EFFECT } from './types';
import { dragParentEl, dragContentEl, draggingStyle } from './styles';
import { DASHBOARD_DROPZONE_WRAPPER_ID } from '@routes/Dashboard/types';
import useMyDrive from '@hooks/Drive/MyDrive/useMyDrive';

const Drag = (props: any) => {
  const [isDragging, setIsDragging] = useState(false);
  const parentElId: any = 'parent-el-id';
  const [dragClientY, setDragClientY] = useState(0);
  const { onSetShowHideFileDetail } = useMyDrive();

  const startDrag = (ev: any) => {
    props.setSelectedFirstFile(props.file);
    props.setCheckedFiles([props.file]);
    onSetShowHideFileDetail({
      detailDrive: props.file
    });

    setIsDragging(true);

    let parentEl = document.createElement('div');
    parentEl.style.cssText = dragParentEl;
    parentEl.setAttribute('id', parentElId);

    document.body.appendChild(parentEl);

    let contentEl = document.createElement('div');
    contentEl.style.cssText = dragContentEl;
    contentEl.innerHTML = props.file.fileName;

    if (parentEl) {
      parentEl.appendChild(contentEl);
    }

    const internalDataItem = JSON.stringify(props.dataItem);
    ev.dataTransfer.setData('drag-item', internalDataItem);
    ev.dataTransfer.effectAllowed = props.dropEffect;
    ev.dataTransfer.setDragImage(parentEl, 0, 0);
  };

  const drag = (e: any) => {
    setDragClientY(e.clientY);
    const mousemoveTopHeight = 400;
    const mousemoveBottomHeight = 50;
    if (e.clientY < mousemoveTopHeight) {
      onScroll();
    }

    if (
      e.clientY >
      // @ts-ignore
      document.getElementById(DASHBOARD_DROPZONE_WRAPPER_ID).offsetHeight +
        mousemoveBottomHeight
    ) {
      onScroll();
    }
  };

  const onScroll = () => {
    // @ts-ignore
    const dashboardDropzoneWrapperEl = document.getElementById(
      DASHBOARD_DROPZONE_WRAPPER_ID
    );
    if (dashboardDropzoneWrapperEl !== null) {
      const delta = dragClientY - dashboardDropzoneWrapperEl.offsetHeight / 1.5;
      dashboardDropzoneWrapperEl.scrollTop =
        dashboardDropzoneWrapperEl.scrollTop + delta * 0.1;
    }
  };

  const dragEnd = () => {
    // @ts-ignore
    document.body.removeChild(document.getElementById(parentElId));
    setIsDragging(false);
    setDragClientY(0);
  };

  return (
    <div
      style={isDragging ? draggingStyle : {}}
      draggable={true}
      onDrag={drag}
      onDragStart={startDrag}
      onDragEnd={dragEnd}
    >
      {props.children}
    </div>
  );
};

Drag.propTypes = {
  dataItem: PropTypes.array.isRequired,
  dropEffect: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  id: PropTypes.string,
  file: PropTypes.any,
  setCheckedFiles: PropTypes.any,
  setSelectedFirstFile: PropTypes.any
};

Drag.defaultProps = {
  dragImage: null,
  dropEffect: DROP_EFFECT.ALL
};

export default Drag;
