import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import {
  CapaErrorCode,
  useFetchConnectUserQuery,
  CapaServiceType
} from '@generated/Common/graphql';
import { JwtPayloadType } from '@modules/auth';
import {
  STORAGE_ACTION,
  setStorageItem,
  clearStorageAll
} from '@utils/storage';
import AuthPresenter from './AuthPresenter';
import { groupPathName, myDrivePathName } from '@routes/Common/types';
import useMyDrive from '@hooks/Drive/MyDrive/useMyDrive';
import { useMediaQuery, useTheme } from '@material-ui/core';

const AuthContainer: React.FC = () => {
  const history = useHistory();
  const { search } = useLocation();
  const query = queryString.parse(search);
  const accessTokenQueryParam = query && (query.accessToken as string);
  const refreshTokenQueryParam = query && (query.refreshToken as string);
  const { loading, refetch: refetchConnectUser } = useFetchConnectUserQuery({
    skip: true
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { onSetShowHideFileDetail } = useMyDrive(true);

  useEffect(() => {
    if (
      accessTokenQueryParam &&
      accessTokenQueryParam !== 'null' &&
      accessTokenQueryParam !== 'undefined' &&
      refreshTokenQueryParam &&
      refreshTokenQueryParam !== 'null' &&
      refreshTokenQueryParam !== 'undefined'
    ) {
      setStorageItem(
        STORAGE_ACTION.CONNECT_ACCESS_TOKEN,
        accessTokenQueryParam
      );
      setStorageItem(
        STORAGE_ACTION.CONNECT_REFRESH_TOKEN,
        refreshTokenQueryParam
      );
      const decodedJwt = (
        accessTokenQueryParam
          ? jwtDecode<JwtPayload>(accessTokenQueryParam)
          : {}
      ) as JwtPayloadType;
      const scopes = decodedJwt.scopes ?? [];
      const userEmail = decodedJwt.aud ? decodedJwt.aud.toString() : undefined;
      refetchConnectUser()
        .then((resp) => {
          if (
            resp &&
            resp.data &&
            resp.data.fetchConnectUser &&
            resp.data.fetchConnectUser.result === CapaErrorCode.Success
          ) {
            localStorage.removeItem(STORAGE_ACTION.CONNECT_USER_RESULT);
            if (resp.data.fetchConnectUser.data) {
              setStorageItem(STORAGE_ACTION.LOGGED_IN, 'true');
              setStorageItem(
                STORAGE_ACTION.USER_INFO,
                JSON.stringify({
                  email: userEmail,
                  isSaved: 'true'
                })
              );
              if (!scopes.includes(CapaServiceType.Connect))
                history.push(groupPathName);
              else history.push(myDrivePathName);
            }
          } else {
            setStorageItem(
              STORAGE_ACTION.CONNECT_USER_RESULT,
              resp.data.fetchConnectUser.result
            );
            setStorageItem(STORAGE_ACTION.LOGGED_IN, 'true');
            setStorageItem(
              STORAGE_ACTION.USER_INFO,
              JSON.stringify({
                email: userEmail,
                isSaved: 'true'
              })
            );
            if (!isMobile) {
              onSetShowHideFileDetail({
                isShowIsDetailFile: true
              });
              setStorageItem(STORAGE_ACTION.IS_OPEN_DETAIL_FILE, true);
            }
            history.push(groupPathName);
          }
        })
        .catch(() => {
          clearStorageAll();
          history.push('/');
        });
    } else {
      clearStorageAll();
      history.push('/');
    }
    // eslint-disable-next-line
  }, []);

  return <AuthPresenter isOpen={loading} />;
};

export default AuthContainer;
