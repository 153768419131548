import { uploadBasicFile } from '@api/index';
import { CustomizedButton } from '@components/Customized';
import {
  CapaErrorCode,
  useCreateConnectFeedback,
  useCreateConnectIssue
} from '@generated/Common/graphql';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import ImageRoundedIcon from '@material-ui/icons/ImageRounded';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import Rating from '@material-ui/lab/Rating';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FeedbackVariable, IssueVariable, MessageStateType } from './types';

import { useSnackbar } from '@components/Provider';
import useCommon from '@hooks/Common/useCommon';
import colorSet from '../../../../components/Provider/styles/colorSet';
import ScreenshotList from './ScreenshotList';
import { RatingStyle, UploadScreenshotWrapper, useStyles } from './styles';

const FeedbackAndReport = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { common, openFeedbackDialog } = useCommon();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();
  const { t } = useTranslation();
  const [createConnectFeedback] = useCreateConnectFeedback();
  const [createConnectIssue] = useCreateConnectIssue();

  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);
  const [rateValue, setRateValue] = useState<number | null>(null);
  const [messageState, setMessageState] = useState<MessageStateType>({
    value: '',
    errorMessage: ''
  });
  const [fileList, setfileList] = useState<any>([]);

  useEffect(() => {
    setRateValue(null);
    setMessageState({
      value: '',
      errorMessage: ''
    });
    setfileList([]);
  }, [common.isOpenFeedback]);

  const isValidSubmit = () => {
    if (common.isFeedback && rateValue) return true;
    if (!common.isFeedback && messageState.value && !messageState.errorMessage)
      return true;
    return false;
  };

  const handleSubmitFeedback = () => {
    setIsLoadingSubmit(true);
    const variables: FeedbackVariable = {
      rating: rateValue ? rateValue : 0,
      feedBack: messageState?.value,
      fileIds: fileList.length ? fileList.map((file: any) => file.fileId) : []
    };
    createConnectFeedback({ variables })
      .then((res) => {
        const result = res?.data?.createConnectFeedback?.result;
        if (result === CapaErrorCode.Success) {
          enqueueSnackbar(t('user_feedback.feedback_success'), {
            variant: 'success'
          });
          openFeedbackDialog({
            // isFeedback: false,
            isOpenFeedback: false
          });
        }

        if (result === CapaErrorCode.QueryError) {
          enqueueSnackbar(res?.data?.createConnectFeedback?.errorMessage, {
            variant: 'error'
          });
        }
      })
      .finally(() => setIsLoadingSubmit(false));
  };

  const handleSubmitIssue = () => {
    setIsLoadingSubmit(true);
    const variables: IssueVariable = {
      issue: messageState?.value,
      fileIds: fileList.length ? fileList.map((file: any) => file.fileId) : []
    };
    createConnectIssue({ variables })
      .then((res) => {
        const result = res?.data?.createConnectIssue?.result;
        if (result === CapaErrorCode.Success) {
          enqueueSnackbar(t('user_feedback.report_issues_success'), {
            variant: 'success'
          });
          openFeedbackDialog({
            isFeedback: false,
            isOpenFeedback: false
          });
        }

        if (result === CapaErrorCode.QueryError) {
          enqueueSnackbar(res?.data?.createConnectIssue?.errorMessage, {
            variant: 'error'
          });
        }
      })
      .finally(() => setIsLoadingSubmit(false));
  };

  const handleUploadScreenshot = (files: FileList | null) => {
    if (!files || !files.length) return;

    const request = Array.from(files).map((file: any) => {
      return uploadBasicFile(file, '');
    });
    Promise.all(request).then((res) => {
      const fileUploadSuccess = res
        .filter((result) => result.data.result === CapaErrorCode.Success)
        .map((responseItem) => responseItem.data.data);
      setfileList([...fileList, ...fileUploadSuccess]);
    });
  };

  const handleRemove = (id: string) => {
    if (!fileList) return;
    const newFileList = fileList.filter((item: any) => item.fileId !== id);
    setfileList(newFileList);
  };

  return (
    <Dialog
      classes={{
        paper: classes.paper
      }}
      open={common.isOpenFeedback}
      onClick={(e) => {
        e.stopPropagation();
      }}
      onClose={() => {
        openFeedbackDialog({
          isOpenFeedback: false
          // isFeedback: false
        });
      }}
      maxWidth="xs"
      fullWidth={matches}
    >
      <DialogTitle classes={{ root: classes.rootTitle }} disableTypography>
        {matches && (
          <KeyboardArrowLeftIcon
            htmlColor={colorSet.gray900}
            fontSize="large"
            onClick={() =>
              openFeedbackDialog({
                isOpenFeedback: false,
                isFeedback: false
              })
            }
          />
        )}
        <span className={classes.firstTitle}>
          {common.isFeedback
            ? t('user_feedback.feedback_title')
            : t('user_feedback.report_issues_title')}
        </span>
      </DialogTitle>

      <DialogContent className={classes.csDialogContent}>
        {common.isFeedback && (
          <>
            <p className={classes.labelText}>
              {t('user_feedback.feedback_label_rate')}
            </p>
            <RatingStyle>
              <Rating
                name="simple-controlled"
                size="large"
                value={rateValue}
                icon={
                  <StarRoundedIcon
                    fontSize="inherit"
                    className="star-rounded-icon"
                  />
                }
                onChange={(event, newValue) => setRateValue(newValue)}
                defaultValue={0}
              />
            </RatingStyle>
            <div className="empty-block" />
          </>
        )}
        <p className={classes.labelText}>
          {common.isFeedback
            ? t('user_feedback.feedback_label')
            : t('user_feedback.report_issues_label')}
        </p>
        <TextField
          placeholder={
            common.isFeedback
              ? t('user_feedback.feedback_content_placeholder')
              : t('user_feedback.report_content_placeholder')
          }
          variant="outlined"
          multiline
          rows={5}
          size="small"
          fullWidth
          value={messageState?.value}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setMessageState({
              value: event.target.value,
              errorMessage: ''
            })
          }
        />

        <UploadScreenshotWrapper>
          <input
            accept="image/*"
            id="contained-button-file"
            multiple
            type="file"
            onChange={(event) => {
              handleUploadScreenshot(event.target.files);
            }}
          />
          <label htmlFor="contained-button-file">
            <CustomizedButton
              variant="contained"
              color="default"
              size="large"
              fullWidth
              component="span"
              startIcon={<ImageRoundedIcon />}
            >
              {t('user_feedback.add_screenshots')}
            </CustomizedButton>
          </label>
        </UploadScreenshotWrapper>
        <ScreenshotList fileList={fileList} handleRemove={handleRemove} />
        <div className={classes.noteWrapper}>
          {t('user_feedback.note')}&nbsp;
          <a className={classes.linkEmail} href="mailto:support@capa.ai">
            support@capa.ai
          </a>
        </div>
      </DialogContent>

      <DialogActions classes={{ root: classes.action }}>
        <div className={classes.wrapperBtn}>
          <CustomizedButton
            variant="contained"
            color="default"
            onClick={() => {
              openFeedbackDialog({
                isOpenFeedback: false
              });
            }}
          >
            {t('user_feedback.cancel')}
          </CustomizedButton>
          <CustomizedButton
            type="submit"
            variant="contained"
            color="primary"
            disabled={!isValidSubmit() || isLoadingSubmit}
            onClick={() =>
              common.isFeedback ? handleSubmitFeedback() : handleSubmitIssue()
            }
          >
            {t('user_feedback.submit')}
          </CustomizedButton>
        </div>
      </DialogActions>
    </Dialog>
  );
};
export default FeedbackAndReport;
