import React, { useEffect, useState } from 'react';
import { useStyles } from './styles';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CustomizedButton } from '@components/Customized';
import { useHistory } from 'react-router-dom';
import * as Apollo from '@apollo/client';
import {
  LeaveGroup,
  UnshareFileFromGroup
} from '@generated/Drive/MyDrive/graphql';
import { CapaErrorCode } from '@generated/Common/graphql';
import { useSnackbar } from '@components/Provider';
import { groupPathName } from '@routes/Common/types';
import useSharedWithGroups from '@hooks/Drive/MyDrive/useSharedWithGroups';
import useAuth from '@hooks/useAuth';
import useGroupRoom from '@hooks/GroupRoom/useGroupRoom';

interface Props {
  isOpen?: boolean;
  onClose?: () => void;
  clickSubmit?: () => void;
  groupId?: string;
  groupName?: any;
  refetchConnectGroupChatRooms?: () => void;
}

const LeaveGroupModal = (props: Props) => {
  const history = useHistory();
  const classes = useStyles();
  const {
    isOpen,
    onClose,
    groupId,
    refetchConnectGroupChatRooms,
    clickSubmit
  } = props;
  const { t } = useTranslation();
  const [isChecked, setChecked] = useState<boolean>(false);
  const [leaveGroup] = Apollo.useMutation(LeaveGroup);
  const [unshareFileFromGroup] = Apollo.useMutation(UnshareFileFromGroup);
  const { enqueueSnackbar } = useSnackbar();
  const { onFetchGroupData, groupDataState } = useSharedWithGroups();
  const { groupDataFilesState } = useGroupRoom();
  const { userState } = useAuth();
  const userInfo = userState?.data?.email;

  const onCancel = () => {
    if (onClose) {
      onClose();
      setChecked(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      onFetchGroupData({
        variables: {
          groupId: groupId
        }
      });
    }
  }, [isOpen]);

  const onSubmit = () => {
    clickSubmit && clickSubmit();
    if (isChecked) {
      unshareFileFromGroup({
        variables: {
          groupId: groupId,
          connectFileIds: groupDataFilesState?.data?.reduce(
            (fileIds: string[], file: any) => {
              if (file.email !== userInfo) return fileIds;
              return [...fileIds, file.id];
            },
            []
          ),
          removeAllMyFile: isChecked
        }
      });
    }
    leaveGroup({
      variables: {
        groupId: groupId
      }
    })
      .then((res) => {
        let result = res.data.leaveGroup.result;
        if (result === CapaErrorCode.Success) {
          if (refetchConnectGroupChatRooms) {
            refetchConnectGroupChatRooms();
          }
          if (onClose) {
            onClose();
          }
          enqueueSnackbar(
            `${t('snackbar.leave_group_success', {
              groupName: groupDataState?.data?.groupName
            })}`,
            {
              variant: 'success'
            }
          );
          history.push(groupPathName);
        }
      })
      .catch(() => {
        enqueueSnackbar(`${t('snackbar.leave_group_error')}`, {
          variant: 'error'
        });
      });
  };

  return (
    <>
      <Dialog
        classes={{
          paper: classes.paper
        }}
        open={isOpen!}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <DialogTitle classes={{ root: classes.rootTitle }} disableTypography>
          <span className={classes.firstTitle}>{t('leave_group.title')}</span>
        </DialogTitle>

        <DialogContent className={classes.csDialogContent}>
          <div className={classes.marginBottomText}>
            {t('leave_group.prepared_features')}
            <div className={classes.marginTextContent}>
              {t('leave_group.prepared_features_desc')}
            </div>
          </div>

          <FormControlLabel
            checked={isChecked}
            className={classes.checkbox}
            control={<Checkbox defaultChecked color="primary" />}
            label={t('leave_group.checkbox_label_remove_title')}
            onChange={(e, checked) => {
              setChecked(checked);
            }}
          />
          <div className={classes.checkedText}>
            {t('leave_group.checkbox_label_remove_desc')}
          </div>
        </DialogContent>

        <DialogActions classes={{ root: classes.action }}>
          <div className={classes.wrapperBtn}>
            <CustomizedButton
              type="submit"
              color="default"
              variant="contained"
              onClick={onCancel}
            >
              {t('leave_group.cancel_button')}
            </CustomizedButton>
            <CustomizedButton
              type="submit"
              variant="contained"
              color="primary"
              onClick={onSubmit}
            >
              {t('leave_group.leave_button')}
            </CustomizedButton>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LeaveGroupModal;
