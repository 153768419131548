import { IBMDelete, IBMDownload, IBMEdit, IBMShare, IBMView } from '@assets';

export enum EnumDashboardDialogType {
  Delete = 'DELETE',
  Download = 'DOWNLOAD',
  Viewer = 'VIEWER'
}

export enum EnumDashboardListControlType {
  ListHeader = 'LISTHEADER',
  ListItem = 'LISTITEM'
}

export enum EnumListControl {
  OPEN = 'OPEN',
  SHARE = 'SHARE',
  RENAME = 'RENAME',
  DOWNLOAD = 'DOWNLOAD',
  DELETE = 'DELETE'
}

export enum ValueSortByTable {
  Name = 'NAME',
  Date = 'DATE',
  Size = 'SIZE'
}

export const LIST_TABLE_HEADER = [
  {
    name: 'my_drive.column_title.name',
    value: ValueSortByTable.Name,
    className: 'table-header-th-one',
    isOrder: true
  },
  {
    name: 'my_drive.column_title.upload_date',
    value: ValueSortByTable.Date,
    className: 'table-header-th-two',
    isOrder: true
  },
  {
    name: 'my_drive.column_title.size',
    value: ValueSortByTable.Size,
    className: 'table-header-th-three'
  },
  {
    name: 'my_drive.column_title.more',
    value: 'sefs',
    className: 'asefa'
  }
];

export const LongPressActionTimeOut = 1000;

export const DEFAULT_FOLDER_NAME = 'Untitled folder';
export const MAX_LENGTH_FOLDER_NAME = 255;

export const FOLDER_TYPE = {
  CREATE: 0,
  RENAME: 1
};

export const DEFAULT_ROUTER_MY_DRIVE = {
  id: '',
  folderName: 'My Drive',
  folderNameKO: '내 드라이브'
};

export const URL_PARAM = {
  urlMyDrive: 'my-drive',
  urlShared: 'shared',
  urlPublic: 'public'
};
export const URL_PARAM_TYPE = {
  urlMyDriveType: 0,
  urlSharedType: 1,
  urlPublicType: 2
};

export const KEY_CODE = {
  ENTER: 13,
  SHIFT: 16,
  CONTROL: 17,
  ALT: 18
};

export const ACCEPTED_EXTENSIONS_STRING_LENGTH = 4;

export const LIST_CONTROL_TYPE = [
  {
    name: 'my_drive.dropdown_menu.open',
    icon: IBMView,
    borderBottom: true,
    type: EnumListControl.OPEN
  },
  {
    name: 'my_drive.dropdown_menu.share',
    icon: IBMShare,
    borderBottom: false,
    type: EnumListControl.SHARE
  },
  {
    name: 'my_drive.dropdown_menu.rename',
    icon: IBMEdit,
    borderBottom: false,
    type: EnumListControl.RENAME
  },
  {
    name: 'my_drive.dropdown_menu.download',
    icon: IBMDownload,
    borderBottom: true,
    type: EnumListControl.DOWNLOAD
  },
  {
    name: 'my_drive.dropdown_menu.delete',
    icon: IBMDelete,
    borderBottom: false,
    type: EnumListControl.DELETE
  }
];

export const IMAGE_ORIENTATION = {
  LANDSCAPE: 'LANDSCAPE',
  PORTRAIT: 'PORTRAIT'
};

export const MESSAGE_TYPE = {
  TEXT: 'TEXT',
  IMAGE: 'IMAGE',
  FILE: 'FILE'
};

export const FILE_UPLOAD_DOWNLOAD_STATUS = {
  DOWNLOAD_INCOMPLETE: 'DOWNLOAD_INCOMPLETE',
  DOWNLOAD_COMPLETE: 'DOWNLOAD_COMPLETE',
  UPLOAD_FAILED: 'UPLOAD_FAILED'
};

export const DISPLAY_DATE_FORMAT = 'YYYY. MM. DD';

export const DOWNLOAD_PERMISSION_STATUS = {
  ALLOW: 'ALLOW',
  DENIED: 'DENIED',
  REQUEST: 'REQUEST'
};

export const DASHBOARD_DROPZONE_WRAPPER_ID = 'dashboard-dropzone-wrapper';

export const LIST_CONTROL_TYPE_FOLDER = [
  {
    name: 'my_drive.dropdown_menu.rename',
    icon: IBMEdit,
    borderBottom: true,
    type: EnumListControl.RENAME
  },
  {
    name: 'my_drive.dropdown_menu.delete',
    icon: IBMDelete,
    borderBottom: false,
    type: EnumListControl.DELETE
  }
];

export const LIST_CONTROL_TYPE_MULTI_FOLDER = [
  {
    name: 'my_drive.dropdown_menu.delete',
    icon: IBMDelete,
    type: EnumListControl.DELETE
  }
];

export const LIST_CONTROL_TYPE_NO_RENAME = [
  {
    name: 'my_drive.dropdown_menu.open',
    icon: IBMView,
    borderBottom: true,
    type: EnumListControl.OPEN
  },
  {
    name: 'my_drive.dropdown_menu.share',
    icon: IBMShare,
    borderBottom: false,
    type: EnumListControl.SHARE
  },
  {
    name: 'my_drive.dropdown_menu.download',
    icon: IBMDownload,
    borderBottom: true,
    type: EnumListControl.DOWNLOAD
  },
  {
    name: 'my_drive.dropdown_menu.delete',
    icon: IBMDelete,
    borderBottom: false,
    type: EnumListControl.DELETE
  }
];

export const LIST_CONTROL_TYPE_MULTIPLE_FILE = [
  {
    name: 'my_drive.dropdown_menu.share',
    icon: IBMShare,
    borderBottom: false,
    type: EnumListControl.SHARE
  },
  {
    name: 'my_drive.dropdown_menu.download',
    icon: IBMDownload,
    borderBottom: true,
    type: EnumListControl.DOWNLOAD
  },
  {
    name: 'my_drive.dropdown_menu.delete',
    icon: IBMDelete,
    borderBottom: false,
    type: EnumListControl.DELETE
  }
];

export const EMPTY_SIZE_FOLDER = '-';
