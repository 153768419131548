import React from 'react';
import { ScreenshotListWrapper, ScreenshotItem } from './styles';
import CloseIcon from '@material-ui/icons/Close';
import { CapaFileName } from '@assets';

type Props = {
  fileList: any;
  handleRemove: (id: string) => void;
};

const ScreenshotList = (props: Props) => {
  const { fileList, handleRemove } = props;
  return (
    <ScreenshotListWrapper>
      {!!fileList &&
        !!fileList.length &&
        fileList.map((file: any) => (
          <ScreenshotItem key={file.fileId}>
            <div className="icon-svg">
              <img src={CapaFileName} alt="" />
            </div>
            <div className="ml-10">{file.fileName}</div>
            <span
              className="icon-remove"
              onClick={() => handleRemove(file.fileId)}
            >
              <CloseIcon color="secondary" fontSize="small" />
            </span>
          </ScreenshotItem>
        ))}
    </ScreenshotListWrapper>
  );
};

export default ScreenshotList;
