import { ApolloLink, HttpLink, fromPromise, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import AppConfig from '@config/index';
import {
  CapaErrorCode,
  RefreshAuthenticateDocument
} from '@generated/Common/graphql';
import { NODE_ENV, loginPathName } from '@routes/Common/types';
import * as Sentry from '@sentry/react';
import history from '@utils/history';
import jwtDecode from '@utils/jwtDecoed';
import {
  STORAGE_ACTION,
  clearStorageAll,
  getStorageItem,
  getTokens,
  setStorageItem
} from '@utils/storage';
import {
  InMemoryCache,
  IntrospectionFragmentMatcher
} from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { Observable } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { WebSocketLink } from 'apollo-link-ws';
import { setSentryUserInfo } from './utils';

const GRAPHQL_URL = AppConfig.graphqlUrl;

const httpLink = new HttpLink({
  uri: `https://${GRAPHQL_URL}`
});

const authLink = new ApolloLink((operations, forward) => {
  const context = operations.getContext() ?? {};
  const { accessToken } = getTokens();
  const decoded = jwtDecode();

  operations.setContext({
    headers: {
      ...(context.headers ?? {}),
      authorization: `Bearer ${accessToken ? accessToken : ''}`
    }
  });

  return forward(operations).map((operation) => {
    const queryName = operation.data
      ? Object.keys(operation.data)[0]
      : undefined;

    const capaErrorCode = queryName
      ? operation.data && (operation.data[queryName]?.result as CapaErrorCode)
      : undefined;

    const isError = capaErrorCode !== CapaErrorCode.Success;

    if (isError && queryName) {
      setSentryUserInfo(decoded, queryName);

      Sentry.withScope((scope) => {
        scope.setLevel('log');
        scope.setContext('response', { ...operation });
        scope.setContext('variables', operations.variables);
        scope.setContext('error message', {
          message: operation.data?.[queryName]?.errorMessage ?? ''
        });
        Sentry.captureMessage(
          `${queryName}/${operation.data?.[queryName]?.result}`
        );
        scope.clear();
      });
    }

    return operation;
  });
});

const httpAuthLink = authLink.concat(httpLink);

export const wsLink: any = new WebSocketLink({
  uri: `wss://${GRAPHQL_URL}`,
  options: {
    lazy: true,
    reconnect: true,
    connectionParams: async () => {
      return {
        Authorization: `Bearer ${
          getTokens().accessToken ? getTokens().accessToken : ''
        }`
      };
    }
  }
});

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, response, forward }) => {
    if (AppConfig.env === NODE_ENV.DEVELOPMENT) {
      console.log(response);
      console.log(graphQLErrors);
      console.log(networkError);
      console.log(operation);
    }

    if (graphQLErrors && graphQLErrors.length > 0) {
      for (const error of graphQLErrors) {
        if (error.message === 'TOKEN_EXPIRED') {
          const accessToken = getStorageItem(
            STORAGE_ACTION.CONNECT_ACCESS_TOKEN
          );
          const refreshToken = getStorageItem(
            STORAGE_ACTION.CONNECT_REFRESH_TOKEN
          );
          const userInfo = getStorageItem(STORAGE_ACTION.USER_INFO);
          if (accessToken && refreshToken && userInfo?.isSaved === 'true') {
            return new Observable((observer) => {
              fromPromise(
                apolloClient
                  .mutate({
                    mutation: RefreshAuthenticateDocument,
                    variables: {
                      refreshToken: refreshToken
                    }
                  })
                  .then(({ data }) => {
                    if (
                      data &&
                      data.refresh &&
                      data.refresh.data &&
                      data.refresh.result === CapaErrorCode.Success
                    ) {
                      operation.setContext(() => ({
                        headers: {
                          authorization: `Bearer ${data.refresh.data.accessToken}`
                        }
                      }));
                      setStorageItem(
                        STORAGE_ACTION.CONNECT_ACCESS_TOKEN,
                        data.refresh.data.accessToken
                      );
                      setStorageItem(
                        STORAGE_ACTION.CONNECT_REFRESH_TOKEN,
                        data.refresh.data.refreshToken
                      );
                    }
                  })
                  .then(() => {
                    const subscriber = {
                      next: observer.next.bind(observer),
                      error: observer.error.bind(observer),
                      complete: observer.complete.bind(observer)
                    };

                    forward(operation).subscribe(subscriber);
                  })
                  .catch(() => {
                    clearStorageAll();
                    window.location.reload();
                  })
              );
            });
          } else {
            clearStorageAll();
            history.push(loginPathName);
          }
        } else if (
          error.message === 'INVALID_TOKEN' &&
          window.location.pathname !== '/'
        ) {
          clearStorageAll();
          history.push(loginPathName);
        }

        if (
          operation.query.definitions[0].kind === 'OperationDefinition' &&
          operation.query.definitions[0].operation === 'mutation'
        ) {
          console.error(
            '[Network error]:',
            networkError,
            '[operation]:',
            operation
          );

          if (networkError) {
            Sentry.withScope((scope) => {
              scope.setLevel('error');
              scope.setContext('network error', {
                message: networkError.message,
                stack: networkError.stack,
                name: networkError.name
              });
              scope.setContext('response', {
                data: response?.data,
                errors: response?.errors,
                extensions: response?.extensions
              });
              scope.setContext('operation', { ...operation });
              Sentry.captureMessage(
                `${operation.operationName}/Network error : ${networkError.message}`
              );
              scope.clear();
            });
            return;
          } else {
            Sentry.withScope((scope) => {
              scope.setLevel('error');
              scope.setContext('error data', { ...error });
              scope.setContext('response', {
                data: response?.data,
                errors: response?.errors,
                extensions: response?.extensions
              });
              scope.setContext('operation', { operation });
              Sentry.captureMessage(
                `${operation.operationName}/Error : ${error.message}`
              );
              scope.clear();
            });
          }
        }
      }
    }
  }
);

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink as any,
  httpAuthLink
);

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: {
    __schema: {
      types: []
    }
  }
});

const apolloClient = new ApolloClient({
  link: ApolloLink.from([errorLink as any, link]) as any,
  cache: new InMemoryCache({ fragmentMatcher })
});

export default apolloClient;
