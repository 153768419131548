import React, { useEffect, useState } from 'react';
import { CAROUSEL_ITEMS, CAROUSEL_ITEM_VALUE } from '@routes/Home/types';
import { Trans, useTranslation } from 'react-i18next';
import {
  carouselMobileUseStyles,
  carouselUseStyles,
  carouselTabletUseStyles,
  themeLanding
} from './styles';
import { useMediaQuery } from '@material-ui/core';
import { LANGUAGE_VALUE } from '@routes/Common/ChangeLanguage/types';
import i18n from '@i18n/index';
import { ChevronLeft, ChevronRight } from '@routes/Home/assets';
import clsx from 'clsx';

const CarouselDesktop = () => {
  const { t } = useTranslation();
  const classes = carouselUseStyles();
  const [activeSlide, setActiveSlide] = useState(
    CAROUSEL_ITEM_VALUE.COLLABORATION
  );
  const [currentItem, setCurrentItem] = useState(
    CAROUSEL_ITEMS[CAROUSEL_ITEM_VALUE.COLLABORATION]
  );
  const [previousItem, setPreviousItem] = useState(
    CAROUSEL_ITEMS[CAROUSEL_ITEM_VALUE.CARD_VIEWER]
  );
  const [nextItem, setNextItem] = useState(
    CAROUSEL_ITEMS[CAROUSEL_ITEM_VALUE.DOWNLOAD_PERMISSION]
  );

  const previous = () => {
    if (activeSlide > 0) {
      setActiveSlide(activeSlide - 1);
    } else {
      setActiveSlide(CAROUSEL_ITEMS.length - 1);
    }
  };

  const next = () => {
    if (activeSlide === CAROUSEL_ITEMS.length - 1) {
      setActiveSlide(0);
    } else {
      setActiveSlide(activeSlide + 1);
    }
  };

  useEffect(() => {
    setCurrentItem(CAROUSEL_ITEMS[activeSlide]);

    if (activeSlide > 0) {
      setPreviousItem(CAROUSEL_ITEMS[activeSlide - 1]);
    } else {
      setPreviousItem(CAROUSEL_ITEMS[CAROUSEL_ITEMS.length - 1]);
    }

    if (activeSlide === CAROUSEL_ITEMS.length - 1) {
      setNextItem(CAROUSEL_ITEMS[0]);
    } else {
      setNextItem(CAROUSEL_ITEMS[activeSlide + 1]);
    }
  }, [activeSlide]);

  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.previousSlide} onClick={previous}>
          <img
            src={previousItem?.img}
            alt={`capaPreviousCarousel`}
            className={classes.image}
          />
        </div>
        <div className={classes.currentSlide}>
          <img
            src={currentItem?.gif}
            alt={`capaCurrentCarousel`}
            className={classes.currentImage}
          />
        </div>
        <div className={classes.nextSlide} onClick={next}>
          <img
            src={nextItem?.img}
            alt={`capaNextCarousel`}
            className={classes.image}
          />
        </div>
      </div>
      <div className={classes.containerFriud}>
        <div className={classes.content}>
          <div className={classes.title}>{t(currentItem?.title)}</div>
          <div className={classes.subTitle}>
            <div
              className={clsx(classes.action, classes.previous)}
              onClick={previous}
            >
              <img src={ChevronLeft} alt="ChevronLeft" />
            </div>
            {t(currentItem?.subTitle)}
            <div className={clsx(classes.action, classes.next)} onClick={next}>
              <img src={ChevronRight} alt="ChevronRight" />
            </div>
          </div>
          <div className={classes.contentText}>
            <Trans i18nKey={t(currentItem?.content)} />
          </div>
        </div>
      </div>
    </>
  );
};

const CarouselMobile = () => {
  const { t } = useTranslation();
  const classes = carouselMobileUseStyles();
  return (
    <div className={classes.wrapper}>
      {CAROUSEL_ITEMS.map((item: any, index: number) => {
        return (
          <div key={index} className={classes.content}>
            <div className={classes.title}>{t(item?.title)}</div>
            <div className={classes.subTitle}>
              <Trans i18nKey={t(item?.subTitle)} />
            </div>
            <div className={classes.contentText}>{t(item?.content)}</div>
            <div className={classes.imgWrapper}>
              <img
                src={
                  i18n.language === LANGUAGE_VALUE.KO
                    ? item?.mobileImgKo
                    : item?.mobileImgEn
                }
                className={classes.image}
                alt={`carousel${index}`}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

const CarouselTablet = () => {
  const { t } = useTranslation();
  const classes = carouselTabletUseStyles();
  return (
    <div className={classes.wrapper}>
      {CAROUSEL_ITEMS.map((item: any, index: number) => {
        return (
          <div key={index} className={classes.content}>
            <div className={classes.title}>{t(item?.title)}</div>
            <div className={classes.subTitle}>{t(item?.subTitle)}</div>
            <div className={classes.contentText}>
              <Trans i18nKey={t(item?.content)} />
            </div>
            <div className={classes.imgWrapper}>
              <img
                src={item?.gif}
                className={classes.image}
                alt={`carousel${index}`}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

const Carousel = () => {
  const isMobile = useMediaQuery(themeLanding.breakpoints.down('xs'));
  const isTablet = useMediaQuery(themeLanding.breakpoints.between('xs', 'md'));

  if (isMobile) return <CarouselMobile />;
  if (isTablet) return <CarouselTablet />;

  return <CarouselDesktop />;
};

export default Carousel;
