export const SHARE_FILES_TO_VALUE = {
  EMPTY: null,
  GROUPS_YOU_ARE_JOINED: 1,
  A_NEW_GROUP: 2
};

export const SHARE_FILES_TO_OPTIONS = [
  {
    value: SHARE_FILES_TO_VALUE.GROUPS_YOU_ARE_JOINED,
    name: 'share_modal.option_1'
  },
  {
    value: SHARE_FILES_TO_VALUE.A_NEW_GROUP,
    name: 'share_modal.option_2'
  }
];

export const SHARE_DIALOG_SCREEN = {
  SHARE: 1,
  GROUP_NAME: 2,
  GROUPS_YOU_ARE_JOINED: 3,
  ADD_MEMBERS_TO: 4,
  SUCCESSFULLY_SHARED: 5,
  CREATE_NEW_GROUP: 6
};

export const ACCESS_LEVEL_VALUE = {
  OWNER: 'OWNER',
  MANAGER: 'MANAGER',
  MEMBER: 'MEMBER',
  REMOVE: 'REMOVE',
  UPDATE: 'UPDATE'
};

export const ACCESS_LEVEL_NAME = {
  OWNER: 'add_members_modal.access_level.owner',
  MANAGER: 'add_members_modal.access_level.can_invite',
  MEMBER: 'add_members_modal.access_level.can_participate',
  REMOVE: 'add_members_modal.remove'
};

export const ACCESS_LEVEL_SHOW_OPTIONS = [
  {
    name: ACCESS_LEVEL_NAME.OWNER,
    value: ACCESS_LEVEL_VALUE.OWNER
  },
  {
    name: ACCESS_LEVEL_NAME.MANAGER,
    value: ACCESS_LEVEL_VALUE.MANAGER
  },
  {
    name: ACCESS_LEVEL_NAME.MEMBER,
    value: ACCESS_LEVEL_VALUE.MEMBER
  },
  {
    name: ACCESS_LEVEL_NAME.REMOVE,
    value: ACCESS_LEVEL_VALUE.REMOVE
  }
];

export const ACCESS_LEVEL_OPTIONS = [
  {
    name: ACCESS_LEVEL_NAME.MANAGER,
    value: ACCESS_LEVEL_VALUE.MANAGER
  },
  {
    name: ACCESS_LEVEL_NAME.MEMBER,
    value: ACCESS_LEVEL_VALUE.MEMBER
  },
  {
    name: ACCESS_LEVEL_NAME.REMOVE,
    value: ACCESS_LEVEL_VALUE.REMOVE
  }
];

export const DEFAULT_SELECTED_MEMBER = {
  index: -1,
  item: {
    userName: '',
    email: '',
    accessLevel: ACCESS_LEVEL_VALUE.MEMBER,
    accessLevelName: ACCESS_LEVEL_VALUE.MEMBER,
    isCapaUser: false
  }
};
