import React, { useEffect, useState } from 'react';
import { useStyles } from './styles';
import { Trans, useTranslation } from 'react-i18next';
import { isAndroid, isIOS, isMobile } from 'react-device-detect';
import { Backdrop, Popper } from '@material-ui/core';
import {
  HorizontalIcon,
  DownloadAppBannerIcon,
  CapaDownloadTheApp
} from '@assets';
import { CustomizedButton } from '@components/Customized';
import AppConfig from '@config/index';

interface DownloadAppBannerProps {
  onClose?: () => void;
}

const DownloadAppBannerPresenter = (props: DownloadAppBannerProps) => {
  const { onClose } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(true);

  const onSubmit = () => {
    if (isIOS) {
      window.location.href = `${AppConfig.downloadIOSUrl}`;
    } else if (isAndroid) {
      window.location.href = `${AppConfig.downloadAndroidUrl}`;
    }
  };

  const openDialog = () => {
    setOpen(true);
  };

  const onClickClose = () => {
    onClose && onClose();
    setOpen(false);
  };

  useEffect(() => {
    openDialog();
  }, []);

  if (!isMobile) return null;

  return (
    <>
      <Backdrop
        className={isOpen ? classes.backDrop : 'd-none'}
        open={isOpen}
        invisible
        onClick={onClickClose}
      />
      <Popper
        className={classes.rootPopupMobile}
        open={isOpen}
        transition
        placement="bottom-end"
      >
        <div className={classes.contentPopup}>
          <div className={classes.contentBox}>
            <img src={HorizontalIcon} alt="Logo" onClick={onClickClose} />
          </div>
          <div className={classes.contentBox}>
            <img
              className={classes.downloadAppBannerIcon}
              src={DownloadAppBannerIcon}
              alt="Logo"
            />
          </div>
          <div className={classes.title}>{t('download_app_banner.title')}</div>
          <div className={classes.subTitle}>
            <Trans i18nKey={t('download_app_banner.sub_title')} />
          </div>
          <div className={classes.contentBox}>
            <img src={CapaDownloadTheApp} alt="Logo" />
          </div>
          <div className={classes.contentBox}>
            <CustomizedButton
              type="submit"
              color="primary"
              onClick={onSubmit}
              className={classes.useAppButton}
            >
              {t('download_app_banner.use_app')}
            </CustomizedButton>
          </div>
          <div className={classes.footerTitle} onClick={onClickClose}>
            {t('download_app_banner.footer_title')}
          </div>
        </div>
      </Popper>
    </>
  );
};

export default DownloadAppBannerPresenter;
