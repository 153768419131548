import * as Apollo from '@apollo/client';
import { CapaLogoBeta, CapaLogoBetaKo } from '@assets';
import {
  CustomizedButton,
  CustomizedCheckBox,
  CustomizedTypography
} from '@components/Customized';
import { useSnackbar } from '@components/Provider';
import { CapaInflowRoute } from '@generated/Common/enum';
import {
  CapaErrorCode,
  useVerifyEmailVerificationCodeQuery
} from '@generated/Common/graphql';
import { CheckRegisteredCapaService } from '@generated/Drive/MyDrive/graphql';
import useUserActionLog, {
  CapaActivityType,
  Enum_Action_Button_Enum,
  Enum_Action_Input_Enum
} from '@hooks/useUserActionLog';
import i18n from '@i18n/index';
import {
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import ChangeLanguageContainer from '@routes/Common/ChangeLanguage';
import { LANGUAGE_VALUE } from '@routes/Common/ChangeLanguage/types';
import { loginPathName } from '@routes/Common/types';
import clsx from 'clsx';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import AccordionPresenter from './AccordionPresenter';
import DialogTermsContainer from './DialogTerms';
import { InputItem } from './SignUpInputElement';
import { Content, FooterBtn } from './styles';
import { AccountReq, TDialogKind, validationForm } from './types';
import { useStyleSelect } from './useStyles';
import useValidCheck from './useValidCheck';

interface Props {
  onRequestEmailVerificationCode: (email?: string) => Promise<boolean>;
  onRegisterUser: (req?: AccountReq) => Promise<void>;
  onGotoLogin?: () => void;
}

const AccountInfoPresenter: React.FC<Props> = ({
  onRequestEmailVerificationCode,
  onRegisterUser,
  onGotoLogin
}) => {
  const { t } = useTranslation();
  const inflowRoute: Array<{ value: CapaInflowRoute; label: string }> = [
    { value: CapaInflowRoute.Sns, label: t('inflowRoute.Sns') },
    { value: CapaInflowRoute.Phone, label: t('inflowRoute.Phone') },
    { value: CapaInflowRoute.Internet, label: t('inflowRoute.Internet') },
    {
      value: CapaInflowRoute.Recommendation,
      label: t('inflowRoute.Recommendation')
    },
    { value: CapaInflowRoute.Article, label: t('inflowRoute.Article') },
    { value: CapaInflowRoute.Email, label: t('inflowRoute.Email') },
    { value: CapaInflowRoute.Community, label: t('inflowRoute.Community') },
    {
      value: CapaInflowRoute.Presentation,
      label: t('inflowRoute.Presentation')
    },
    { value: CapaInflowRoute.Etc, label: t('inflowRoute.Etc') }
  ];

  const history = useHistory();
  const languageValue = i18n.language;
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyleSelect();
  const { onInsertUserActionLogOne } = useUserActionLog();
  const {
    register,
    handleSubmit,
    errors,
    control,
    setValue,
    setError,
    trigger,
    clearErrors
  } = useForm<AccountReq>({
    defaultValues: {}
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { refetch: verifyEmailVerificationCode } =
    useVerifyEmailVerificationCodeQuery({
      skip: true
    });
  const [dialogState, setDialogState] = useState<{
    isOpen: boolean;
    kind: TDialogKind;
  }>({
    isOpen: false,
    kind: undefined
  });
  const [isCheckVerification, setIsCheckVerification] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [checkedAgree, setCheckedAgree] = useState<Array<string>>([]);
  const { search } = useLocation();
  const emailQuery =
    queryString.parse(search, { decode: false })?.email || null;
  const [checkRegisteredCapaService, { data: checkRegisteredCapaServiceData }] =
    Apollo.useLazyQuery(CheckRegisteredCapaService, {
      fetchPolicy: 'no-cache'
    });

  const { getIsDisabledBtn, currentState: formValues } = useValidCheck(
    control,
    ['email', 'code', 'password', 'privacyAgree', 'serviceAgree', 'inflowRoute']
  );

  const insertLog = (action: Enum_Action_Button_Enum) => {
    onInsertUserActionLogOne(action, CapaActivityType.ButtonClick);
  };

  const onRequestEmailVerificationCodeHandle = async () => {
    setValue('code', undefined);
    clearErrors('code');
    setIsComplete(false);
    const errorCheck = await trigger('email');
    if (!errorCheck) return;
    const result = await onRequestEmailVerificationCode(formValues.email);
    if (result) {
      setIsCheckVerification(true);
    } else {
      setIsCheckVerification(false);
    }
  };

  const onCheckEmailVerificationCode = async () => {
    const email = formValues.email;
    const code = formValues.code;
    if (!email || !code) return;
    const resp = await verifyEmailVerificationCode({
      email: email,
      code: code
    });

    if (
      resp &&
      resp.data &&
      resp.data.verifyEmailVerificationCode &&
      resp.data.verifyEmailVerificationCode.result
    ) {
      const result = resp.data.verifyEmailVerificationCode.result;
      if (result !== CapaErrorCode.Success) {
        const errMessage =
          result === CapaErrorCode.CodeMismatch
            ? t('sign_up.code_does_not_match')
            : result === CapaErrorCode.ParmeterValidationError
            ? t('sign_up.invalid_authentication_number')
            : t('sign_up.email_verification_failed');
        if (
          result !== CapaErrorCode.CodeMismatch &&
          result !== CapaErrorCode.ParmeterValidationError
        ) {
          setIsCheckVerification(false);
        }
        setError('code', {
          type: 'manual',
          message: errMessage
        });
      } else {
        clearErrors('code');
        setIsComplete(true);
      }
    } else {
      enqueueSnackbar(t('sign_up.email_verification_failed'), {
        variant: 'error'
      });
    }
  };

  const handleOnSubmit = async (req: AccountReq) => {
    insertLog(Enum_Action_Button_Enum.Button_SignUp_Submit);
    if (emailQuery !== null) {
      const internalReq: any = {
        ...req,
        email: emailQuery || '',
        code: formValues.code
      };
      onRegisterUser({
        ...internalReq
      });
      return;
    }
    onRegisterUser({
      ...req,
      code: formValues.code
    });
  };

  useEffect(() => {
    if (emailQuery) {
      checkRegisteredCapaService({
        variables: {
          emails: [emailQuery]
        }
      });
    }
  }, [emailQuery]);

  useEffect(() => {
    if (
      checkRegisteredCapaServiceData?.checkRegisteredCapaService?.result ===
        CapaErrorCode.Success &&
      checkRegisteredCapaServiceData?.checkRegisteredCapaService?.data[0]
        ?.scopes !== null
    ) {
      history.push({
        pathname: loginPathName,
        search: search,
        state: { prevLocation: window.location.href }
      });
    }
  }, [checkRegisteredCapaServiceData]);

  return (
    <form
      className={classes.formSignUp}
      onSubmit={handleSubmit(handleOnSubmit)}
    >
      <DialogTermsContainer
        isOpen={dialogState.isOpen}
        kind={dialogState.kind}
        onClose={() => setDialogState({ isOpen: false, kind: undefined })}
      />
      <Content className="space-box" />
      <div className="logo-register">
        <img
          className={classes.cursorPointer}
          src={
            languageValue === LANGUAGE_VALUE.EN ? CapaLogoBeta : CapaLogoBetaKo
          }
          alt="Logo"
          onClick={() => history.push('/')}
        />
      </div>
      <Content className="signup-content">
        <div className={clsx(classes.title, 'title_signup')}>
          {t('sign_up.title')}
        </div>
        <CustomizedTypography
          variant="body1"
          fontWeight="bold"
          className="group-title"
          gutterBottoms={isMobile ? 16 : 20}
        >
          {t('sign_up.login_information')}
        </CustomizedTypography>
        <CustomizedTypography
          variant="body1"
          gutterBottoms={isMobile ? 10 : 12}
        >
          {t('sign_up.email')}
        </CustomizedTypography>
        <div className="content-email">
          <div className="content-email-item">
            <TextField
              size="small"
              variant="outlined"
              name="email"
              placeholder={t('sign_up.email_placeholder')}
              inputProps={{
                name: 'email',
                ref: register(validationForm['email'])
              }}
              error={!!errors.email}
              helperText={errors.email && errors.email.message}
              fullWidth
              onFocus={() => {
                onInsertUserActionLogOne(
                  Enum_Action_Input_Enum.Input_Signup_Email,
                  CapaActivityType.actionInput
                );
              }}
              defaultValue={emailQuery ? emailQuery : ''}
              disabled={emailQuery !== null}
              onChange={() => {
                clearErrors('email');
                clearErrors('code');
                if (isCheckVerification) {
                  setValue('code', undefined);
                  setIsCheckVerification(false);
                  setIsComplete(false);
                }
              }}
            />
            {!isComplete && (
              <CustomizedButton
                color={isCheckVerification ? 'secondary' : 'primary'}
                disabled={!formValues.email}
                size="large"
                className={classes.verifyButton}
                onClick={() => {
                  insertLog(
                    Enum_Action_Button_Enum.Button_SignUp_SendAuthEmail
                  );
                  onRequestEmailVerificationCodeHandle();
                }}
              >
                {isCheckVerification
                  ? t('sign_up.verified')
                  : t('sign_up.verify')}
              </CustomizedButton>
            )}
          </div>
          {!isCheckVerification ? null : (
            <div className="email-verification-code">
              <div className="content-email-item">
                <TextField
                  disabled={isComplete}
                  size="small"
                  variant="outlined"
                  name="code"
                  onFocus={() => {
                    onInsertUserActionLogOne(
                      Enum_Action_Input_Enum.Input_SignUp_VerifyingCode,
                      CapaActivityType.actionInput
                    );
                  }}
                  inputProps={{
                    name: 'code',
                    ref: register(validationForm['code'])
                  }}
                  placeholder={t('sign_up.code_placeholder')}
                  error={!!errors.code}
                  helperText={errors.code && errors.code.message}
                  fullWidth
                />
                {isCheckVerification && !isComplete && (
                  <CustomizedButton
                    color="primary"
                    size="large"
                    className={classes.verifyButton}
                    disabled={isComplete || !formValues.code}
                    onClick={() => {
                      insertLog(
                        Enum_Action_Button_Enum.Button_SignUp_Verification
                      );
                      onCheckEmailVerificationCode();
                    }}
                  >
                    {t('sign_up.verify')}
                  </CustomizedButton>
                )}
              </div>
              {isComplete && (
                <div className="content-email-item-description">
                  <CustomizedTypography variant="caption" color="primary">
                    {t('sign_up.verified_email_code')}
                  </CustomizedTypography>
                </div>
              )}
            </div>
          )}
        </div>
        <InputItem
          name="password"
          mainClass="input-signup"
          label={t('sign_up.password')}
          placeholder={t('sign_up.password_placeholder')}
          errors={errors}
          inputRef={register(validationForm['password'])}
          onFocus={() => {
            onInsertUserActionLogOne(
              Enum_Action_Input_Enum.Input_Signup_PW,
              CapaActivityType.actionInput
            );
          }}
        />
        <CustomizedTypography
          variant="body1"
          fontWeight="bold"
          gutterBottoms={isMobile ? 16 : 20}
          className="group-title"
        >
          {t('sign_up.user_info')}
        </CustomizedTypography>
        <CustomizedTypography
          variant="body1"
          gutterBottoms={isMobile ? 10 : 12}
        >
          {t('sign_up.manager_name')}
        </CustomizedTypography>
        {/* 이름 */}
        <TextField
          inputProps={{
            name: 'userName',
            ref: register(validationForm['userName'])
          }}
          className={clsx('input-signup', classes.usernameInput)}
          size="small"
          variant="outlined"
          name="userName"
          placeholder={t('sign_up.manage_name_placeholder')}
          fullWidth
          onFocus={() => {
            onInsertUserActionLogOne(
              Enum_Action_Input_Enum.Input_Signup_Name,
              CapaActivityType.actionInput
            );
          }}
          error={!!errors.userName}
          helperText={errors.userName && errors.userName.message}
          onChange={() => {
            clearErrors('userName');
          }}
        />
        <CustomizedTypography
          variant="body1"
          fontWeight="bold"
          className="inflow-route-title group-title"
          gutterBottoms={isMobile ? 16 : 20}
        >
          {t('sign_up.discover_capa_connect_question')}
        </CustomizedTypography>
        <CustomizedTypography
          variant="body1"
          gutterBottoms={isMobile ? 10 : 12}
        >
          {t('sign_up.find_out_about_capa_connect_question')}
        </CustomizedTypography>
        <Controller
          control={control}
          defaultValue=""
          name="inflowRoute"
          render={(props) => (
            <Select
              {...props}
              classes={{
                root: clsx(classes.selectRoot, props.value ? '' : 'placeholder')
              }}
              MenuProps={{ classes: { paper: classes.selectGroup } }}
              className={clsx('input-signup', classes.pleaseSelect)}
              variant="outlined"
              margin="dense"
              displayEmpty
              fullWidth
            >
              <MenuItem
                value=""
                disabled
                classes={{
                  root: classes.rootMenuItem
                }}
              >
                {t('sign_up.please_select')}
              </MenuItem>
              {inflowRoute.map((option, index) => {
                return (
                  <MenuItem
                    key={index}
                    classes={{
                      selected: classes.selected,
                      root: classes.rootMenuItem
                    }}
                    value={option.value}
                  >
                    {option.label}
                  </MenuItem>
                );
              })}
            </Select>
          )}
        />
        <CustomizedTypography
          variant="body1"
          fontWeight="bold"
          gutterBottoms={isMobile ? 16 : 20}
          className="terms group-title"
        >
          {t('sign_up.terms_conditions')}
        </CustomizedTypography>
        <AccordionPresenter
          summary={
            <div
              className="checkbox-agree-wrapper"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <CustomizedCheckBox
                label={
                  <CustomizedTypography variant="body2">
                    {t('sign_up.agree_to_all_terms')}
                  </CustomizedTypography>
                }
                className={classes.agreeAll}
                value="All"
                values={checkedAgree}
                size="medium"
                fullwidth
                onChange={(data) => {
                  if (data.includes('All')) {
                    setValue('privacyAgree', true);
                    setValue('serviceAgree', true);
                    setValue('consentAgree', true);
                    setValue('promotionAgree', true);
                    setCheckedAgree([
                      'All',
                      'privacyAgree',
                      'consentAgree',
                      'serviceAgree',
                      'promotionAgree'
                    ]);
                    insertLog(
                      Enum_Action_Button_Enum.Button_SignUp_Agree_ServiceAndTerms
                    );
                  } else {
                    setValue('privacyAgree', false);
                    setValue('consentAgree', false);
                    setValue('serviceAgree', false);
                    setValue('promotionAgree', false);
                    setCheckedAgree([]);
                  }
                }}
              />
            </div>
          }
          detail={
            <>
              <Controller
                control={control}
                defaultValue={false}
                name="serviceAgree"
                render={() => (
                  <div className="checkbox-agree-wrapper">
                    <CustomizedCheckBox
                      label={
                        <CustomizedTypography variant="body2">
                          <span>{t('sign_up.required')}</span>{' '}
                          <div
                            className="view-full-text"
                            onClick={() =>
                              setDialogState({
                                isOpen: true,
                                kind: 'serviceAgree'
                              })
                            }
                          >
                            {t('sign_up.service_terms')}
                          </div>
                        </CustomizedTypography>
                      }
                      value="serviceAgree"
                      values={checkedAgree}
                      size="medium"
                      fullwidth
                      onChange={(values) => {
                        if (
                          values.includes('serviceAgree') &&
                          values.includes('consentAgree') &&
                          values.includes('privacyAgree') &&
                          values.includes('promotionAgree')
                        ) {
                          setCheckedAgree([...values, ...['All']]);
                        } else {
                          setCheckedAgree(
                            values.filter((item) => item !== 'All')
                          );
                        }
                        setValue(
                          'serviceAgree',
                          values.includes('serviceAgree')
                        );
                      }}
                    />
                  </div>
                )}
              />
              <Controller
                control={control}
                defaultValue={false}
                name="consentAgree"
                render={() => (
                  <div className="checkbox-agree-wrapper">
                    <CustomizedCheckBox
                      label={
                        <CustomizedTypography variant="body2">
                          <span>{t('sign_up.required')}</span>{' '}
                          <div
                            className="view-full-text"
                            onClick={() =>
                              setDialogState({
                                isOpen: true,
                                kind: 'consentAgree'
                              })
                            }
                          >
                            {t('sign_up.consent_term')}
                          </div>
                        </CustomizedTypography>
                      }
                      value="consentAgree"
                      values={checkedAgree}
                      size="medium"
                      fullwidth
                      onChange={(values) => {
                        if (
                          values.includes('serviceAgree') &&
                          values.includes('consentAgree') &&
                          values.includes('privacyAgree') &&
                          values.includes('promotionAgree')
                        ) {
                          setCheckedAgree([...values, ...['All']]);
                        } else {
                          setCheckedAgree(
                            values.filter((item) => item !== 'All')
                          );
                        }
                        setValue(
                          'consentAgree',
                          values.includes('consentAgree')
                        );
                      }}
                    />
                  </div>
                )}
              />
              <Controller
                control={control}
                defaultValue={false}
                name="privacyAgree"
                render={() => (
                  <div className="checkbox-agree-wrapper">
                    <CustomizedCheckBox
                      label={
                        <CustomizedTypography variant="body2">
                          <span>{t('sign_up.required')}</span>{' '}
                          <div
                            onClick={() =>
                              setDialogState({
                                isOpen: true,
                                kind: 'privacyAgree'
                              })
                            }
                            className="view-full-text"
                          >
                            {t('sign_up.consent_to_collection')}
                          </div>
                        </CustomizedTypography>
                      }
                      value="privacyAgree"
                      values={checkedAgree}
                      size="medium"
                      fullwidth
                      onChange={(values) => {
                        if (
                          values.includes('serviceAgree') &&
                          values.includes('consentAgree') &&
                          values.includes('privacyAgree') &&
                          values.includes('promotionAgree')
                        ) {
                          setCheckedAgree([...values, ...['All']]);
                        } else {
                          setCheckedAgree(
                            values.filter((item) => item !== 'All')
                          );
                        }
                        setValue(
                          'privacyAgree',
                          values.includes('privacyAgree')
                        );
                      }}
                    />
                  </div>
                )}
              />
              <Controller
                control={control}
                defaultValue={false}
                name="promotionAgree"
                render={() => (
                  <div className="checkbox-agree-wrapper">
                    <CustomizedCheckBox
                      label={
                        <CustomizedTypography variant="body2">
                          <span>{t('sign_up.optional')}</span>{' '}
                          <div
                            className="view-full-text"
                            onClick={() =>
                              setDialogState({
                                isOpen: true,
                                kind: 'promotionAgree'
                              })
                            }
                          >
                            {t('sign_up.consent_to_receive')}
                          </div>
                        </CustomizedTypography>
                      }
                      value="promotionAgree"
                      values={checkedAgree}
                      size="medium"
                      fullwidth
                      onChange={(values) => {
                        if (
                          values.includes('serviceAgree') &&
                          values.includes('consentAgree') &&
                          values.includes('privacyAgree') &&
                          values.includes('promotionAgree')
                        ) {
                          setCheckedAgree([...values, ...['All']]);
                        } else {
                          setCheckedAgree(
                            values.filter((item) => item !== 'All')
                          );
                        }
                        setValue(
                          'promotionAgree',
                          values.includes('promotionAgree')
                        );
                      }}
                    />
                  </div>
                )}
              />
            </>
          }
        />
        <FooterBtn className="button-submit-signup">
          <CustomizedButton
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            className={classes.doneButton}
            disabled={getIsDisabledBtn()}
            fullWidth
          >
            {t('sign_up.done')}
          </CustomizedButton>
        </FooterBtn>
      </Content>
      <div className={clsx(classes.goLogin, 'go-login')} onClick={onGotoLogin}>
        {t('sign_up.go_to_login')}
      </div>
      <div className={clsx(classes.footerWrapper, 'change-language')}>
        <ChangeLanguageContainer />
      </div>
    </form>
  );
};

export default AccountInfoPresenter;
