import { NODE_ENV } from './../routes/Common/types';
import { createStore, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore } from 'redux-persist';
import rootReducer from '../modules';

const middlewares = [];

if (process.env.NODE_ENV === NODE_ENV.DEVELOPMENT) {
  const logger = createLogger({
    collapsed: true
  });
  middlewares.push(logger);
}

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middlewares))
);

export const persistor = persistStore(store);

const reduxStore = { store, persistor };
export default reduxStore;
