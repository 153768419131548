import React from 'react';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@material-ui/core';

interface Props {
  message?: any;
  userEmail?: string;
  clickDownload?: () => void;
  clickDownloadAccess?: () => void;
  clickRemove?: () => void;
  clickAddToMyDrive?: () => void;
}

const ActionButtonPresenter = (props: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const {
    message,
    userEmail,
    clickDownload,
    clickDownloadAccess,
    clickRemove,
    clickAddToMyDrive
  } = props;
  const classes = useStyles();
  const isCurrentUser = message?.fileOwnerEmail
    ? message?.fileOwnerEmail === userEmail
    : message.senderEmail === userEmail;

  return (
    <div className={classes.actionButtonWrapper}>
      {isCurrentUser ? (
        <>
          <div
            className="cs-button black-color"
            onClick={(e) => {
              if (isMobile) e.stopPropagation();
              clickDownloadAccess && clickDownloadAccess();
            }}
          >
            {t('chat_room.action_button.download_access')}
          </div>
          <div className="dot">・</div>
          <div
            className="cs-button red-color"
            onClick={(e) => {
              if (isMobile) e.stopPropagation();
              clickRemove && clickRemove();
            }}
          >
            {t('chat_room.action_button.remove')}
          </div>
        </>
      ) : (
        <>
          <div
            className="cs-button primary-color"
            onClick={(e) => {
              if (isMobile) e.stopPropagation();
              clickDownload && clickDownload();
            }}
          >
            {t('chat_room.action_button.download')}
          </div>
          <div className="dot">・</div>
          <div
            className="cs-button black-color"
            onClick={(e) => {
              if (isMobile) e.stopPropagation();
              clickAddToMyDrive && clickAddToMyDrive();
            }}
          >
            {t('chat_room.action_button.add_to_my_drive')}
          </div>
        </>
      )}
    </div>
  );
};

export default ActionButtonPresenter;
