import React from 'react';
import DropzonePresenter from './DropzonePresenter';

interface Props {
  accept?: Array<string>;
  noClick?: boolean;
  onDropAccepted?: (files: any) => void;
  isFolder?: boolean;
  setIsDragDrop?: (isDragDrop: any) => void;
  noDrag?: boolean;
  noKeyboard?: boolean;
}

const DropzoneContainer: React.FC<Props> = ({
  accept,
  noClick,
  children,
  onDropAccepted,
  setIsDragDrop,
  noDrag,
  noKeyboard
}) => {
  return (
    <DropzonePresenter
      accept={accept}
      noClick={noClick}
      onDropAccepted={onDropAccepted}
      setIsDragDrop={setIsDragDrop}
      noDrag={noDrag}
      noKeyboard={noKeyboard}
    >
      {children}
    </DropzonePresenter>
  );
};

export default DropzoneContainer;
