import React from 'react';
import { CustomizedTypography } from '@components/Customized';
import EmailEdit from './EmailEdit';
import PasswordEdit from './PasswordEdit';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { PageContainer } from './styles';
import UsernameEdit from './UsernameEdit';
import { useTranslation } from 'react-i18next';
import { ACCOUNT_INFO_TYPE } from '@routes/MyPage/Account/types';

interface AccountEditPageProps {
  infoType?: 'email' | 'password';
}

const AccountEditPage: React.FC<AccountEditPageProps> = ({ infoType }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const editorDistributor = {
    email: <EmailEdit />,
    password: <PasswordEdit />,
    username: <UsernameEdit />
  };

  return (
    <PageContainer>
      {infoType &&
      infoType === ACCOUNT_INFO_TYPE.USERNAME &&
      isMobile ? null : (
        <CustomizedTypography
          variant={isMobile ? 'h3' : 'h2'}
          fontWeight="700"
          className="accountPageTitle"
        >
          {infoType &&
            (infoType === ACCOUNT_INFO_TYPE.EMAIL
              ? t('gnb.email_settings')
              : infoType === ACCOUNT_INFO_TYPE.PASSWORD
              ? t('gnb.password_settings')
              : t('account_page.change_name'))}
        </CustomizedTypography>
      )}

      {infoType && editorDistributor[infoType]}
    </PageContainer>
  );
};

export default AccountEditPage;
