import React from 'react';
import {
  Dialog,
  DialogContent,
  useTheme,
  useMediaQuery
} from '@material-ui/core';

import { useStyles } from './styles';
import RequestPasswordContainer from '../RequestPasswordContainer';

interface Props {
  open?: any;
  onClose: () => void;
  onSuccess?: () => void;
  onGotoLogin?: () => void;
}

const ForgotPassDialog: React.FC<Props> = ({
  open,
  onClose,
  onSuccess,
  onGotoLogin
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        disableEscapeKeyDown={true}
        classes={{ paper: !isMobile ? classes.paper : classes.paperMobile }}
      >
        <DialogContent className={classes.DialogContent}>
          <RequestPasswordContainer
            onSuccess={onSuccess}
            onGotoLogin={onGotoLogin}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ForgotPassDialog;
