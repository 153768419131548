import React, { useEffect, useRef, useState } from 'react';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Paper,
  InputBase,
  useMediaQuery,
  useTheme,
  Tooltip,
  Radio
} from '@material-ui/core';
import { CustomizedButton } from '@components/Customized';
import { useStyles } from './styles';
import clsx from 'clsx';
import { CancelRounded, Search } from '@material-ui/icons';
import { CapaErrorCode } from '@generated/Common/graphql';
import { CapaActivityType } from '@generated/Common/types';
import {
  DEFAULT_ITEMS_LIMIT,
  DEFAULT_ITEMS_OFFSET,
  chatBotEmail,
  CONNECT_UPDATE_TYPE
} from '@routes/Common/types';
import { useTranslation } from 'react-i18next';
import useAuth from '@hooks/useAuth';
import Avatar from '@routes/Dashboard/Dialog/Share/Avatar';
import useUserActionLog, {
  Enum_Action_Button_Enum
} from '@hooks/useUserActionLog';
import { formatTimeStamp } from '@utils/formatter';
import Loading from '@components/LoadingInComponent';
import { useSubscription } from '@apollo/client';
import { SubscribeConnectUpdateData } from '@generated/Drive/MyDrive/graphql';
import { getGroupNameByUsernameOrEmail } from '@utils/common';
import useGroup from '@hooks/Group/useGroup';

interface Props {
  dialogTitle?: React.ReactNode;
  onClose?: () => void;
  onSubmit?: (activeSearchResult: any, activeGroup: any) => void;
  checkedUsers?: any;
}

const GroupsYouAreJoined = (props: Props) => {
  const { dialogTitle, onClose, onSubmit, checkedUsers } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();
  const { groupsState, onFetchMore, onRefetchGroupsData } = useGroup();
  const [searchText, setSearchText] = useState<string>('');
  const [activeSearchResult, setActiveSearchResult] = useState<string>('');
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [isFetching, setIsFetching] = useState(false);
  const [groupData, setGroupData] = useState<any>([]);
  const dropzoneWrapperRef = useRef<any>(null);
  const groupsStateFiles = groupsState?.data ?? [];
  const { onInsertUserActionLogOne } = useUserActionLog();
  const { userState } = useAuth();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [activeGroup, setActiveGroup] = useState<any>(null);
  const [hoverItem, setHoverItem] = useState(null);
  const onClickSearchResult = (group: any) => {
    if (group?.id === activeSearchResult) {
      setActiveSearchResult('');
      setActiveGroup(null);
      return;
    }
    setActiveSearchResult(group?.id);
    setActiveGroup(group);
  };

  const onScrollDropzoneWrapper = () => {
    const currentDropzoneWrapperRef =
      dropzoneWrapperRef && dropzoneWrapperRef.current;
    if (
      Math.ceil(currentDropzoneWrapperRef.scrollTop) +
        currentDropzoneWrapperRef.clientHeight !==
        currentDropzoneWrapperRef.scrollHeight ||
      isFetching ||
      searchText !== ''
    )
      return;

    setIsFetching(true);
  };

  useSubscription(SubscribeConnectUpdateData, {
    onSubscriptionData: (subscriptionData) => {
      switch (
        subscriptionData?.subscriptionData?.data?.subscribeConnectUpdateData
          ?.data?.type
      ) {
        case CONNECT_UPDATE_TYPE.MEMBER_UPDATE:
        case CONNECT_UPDATE_TYPE.GROUP_UPDATE: {
          setLoading(true);

          onRefetchGroupsData({
            limit: DEFAULT_ITEMS_LIMIT,
            offset: DEFAULT_ITEMS_OFFSET
          }).then((res: any) => {
            const result = res?.data?.fetchGroups;
            if (result?.result === CapaErrorCode.Success) {
              if (
                activeSearchResult &&
                result?.data?.every(
                  (item: any) => item.id !== activeSearchResult
                )
              ) {
                setActiveSearchResult('');
                setActiveGroup(null);
              }
            }
          });
          break;
        }
      }
    }
  });

  useEffect(() => {
    onRefetchGroupsData({
      limit: DEFAULT_ITEMS_LIMIT,
      offset: DEFAULT_ITEMS_OFFSET
    });
  }, []);

  useEffect(() => {
    if (!isFetching) return;
    setTimeout(() => {
      onFetchMore({
        variables: {
          limit: DEFAULT_ITEMS_LIMIT,
          offset: groupsStateFiles.length
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return prev;
          }
          const prevFiles = prev.fetchGroups.data;
          const moreFiles = fetchMoreResult.fetchGroups.data;

          if (fetchMoreResult.fetchGroups.data)
            fetchMoreResult.fetchGroups.data = [...prevFiles!, ...moreFiles!];
          setGroupData(
            fetchMoreResult.fetchGroups.data?.filter((group: any) =>
              group.users.every((user: any) => user.email !== chatBotEmail)
            )
          );
          return {
            ...fetchMoreResult
          };
        }
      });
      setIsFetching(false);
    }, 500);
  }, [isFetching]);

  useEffect(() => {
    if (groupsState?.data) {
      setLoading(false);
      setGroupData(
        groupsState?.data?.filter((group: any) =>
          group.users.every((user: any) => user.email !== chatBotEmail)
        ) ?? []
      );
    }
  }, [groupsState]);

  return (
    <>
      <DialogTitle
        classes={{ root: classes.existingGroupRootTitle }}
        disableTypography
      >
        {dialogTitle}
      </DialogTitle>

      <DialogContent className={classes.existingGroupsDialogContent}>
        <Paper component="form" className={classes.searchExistingInputWrapper}>
          <div className={classes.searchIconWrapper}>
            <Search className={classes.customExistingSearchIcon} />
          </div>
          <InputBase
            className={classes.searchInputBase}
            placeholder={t('groups_you_are_joined.search')}
            value={searchText!}
            onChange={(event) => {
              setLoading(true);
              const internalGroupData = groupsState.data!.filter(
                (group: any) => {
                  return (
                    group.users.every(
                      (user: any) => user.email !== chatBotEmail
                    ) &&
                    group.groupName
                      .toLowerCase()
                      .includes(event.target.value.toLowerCase())
                  );
                }
              );
              setSearchText(event.target.value);
              setTimeout(() => {
                setLoading(false);
                setGroupData(internalGroupData);
              }, 500);
            }}
          />
          {searchText ? (
            <div
              className={classes.searchCloseIconWrapper}
              onClick={() => {
                setGroupData(
                  groupsState?.data?.filter((group: any) =>
                    group.users.every(
                      (user: any) => user.email !== chatBotEmail
                    )
                  )
                );
                setSearchText('');
              }}
            >
              <CancelRounded className={classes.customSearchIcon} />
            </div>
          ) : null}
        </Paper>

        <div
          className={classes.searchGroupResultWrapper}
          onScroll={onScrollDropzoneWrapper}
          ref={dropzoneWrapperRef}
        >
          {isLoading || groupsState?.isLoading ? (
            <Loading
              open={true}
              styles={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: 40
              }}
            />
          ) : groupData.length > 0 ? (
            groupData.map((group: any, index: number) => {
              const internalUsers =
                group?.users?.length === 1
                  ? group?.users
                  : group?.users
                      ?.filter(
                        (item: any) => item.email !== userState?.data?.email
                      )
                      .slice(0, 4);
              const isChecked = group.id === activeSearchResult;
              const isHover = hoverItem === group.id;
              const membersText =
                group?.users?.length === 1
                  ? t('groups_you_are_joined.member', {
                      member: group?.users?.length
                    })
                  : t('groups_you_are_joined.members', {
                      members: group?.users?.length
                    });
              const disabledItem =
                checkedUsers &&
                checkedUsers.length > 0 &&
                group?.users?.filter(
                  (item: any) =>
                    item.email !== userState?.data?.email &&
                    checkedUsers.includes(item.email)
                ).length === checkedUsers.length;
              return (
                <div
                  key={index}
                  className={clsx(
                    classes.searchGroupResult,
                    classes.pointerCursor,
                    isChecked ? classes.activeSearchResult : '',
                    disabledItem ? classes.disabledItem : '',
                    isHover && !isMobile ? classes.hoverItem : ''
                  )}
                  onClick={() => {
                    onInsertUserActionLogOne(
                      Enum_Action_Button_Enum.Button_Share_MyGroupsModal_Group,
                      CapaActivityType.ButtonClick
                    );

                    onClickSearchResult(group);
                  }}
                  onMouseEnter={() => {
                    if (isMobile) return;
                    setHoverItem(group.id);
                  }}
                  onMouseLeave={() => {
                    if (isMobile) return;
                    setHoverItem(null);
                  }}
                >
                  <div className={classes.image}>
                    <Avatar data={internalUsers} isChecked={isChecked} />
                  </div>
                  <div className={classes.groupInfo}>
                    <Tooltip
                      title={
                        group?.groupName
                          ? group?.groupName
                          : getGroupNameByUsernameOrEmail(group?.users, true)
                      }
                    >
                      <div className={classes.groupName}>
                        {group.groupName
                          ? group.groupName
                          : getGroupNameByUsernameOrEmail(group?.users)}
                      </div>
                    </Tooltip>
                    <div className={classes.members}>
                      {membersText} ·{' '}
                      {formatTimeStamp(
                        group.updatedAt,
                        false,
                        true,
                        true,
                        true
                      )}
                    </div>
                  </div>
                  {isMobile || disabledItem ? null : (
                    <Radio
                      className={classes.customRadio}
                      checked={isChecked}
                      name="radio-buttons"
                    />
                  )}
                </div>
              );
            })
          ) : searchText ? (
            <div className={classes.noResultText}>
              {t('groups_you_are_joined.empty_result', {
                searchContent: searchText
              })}
            </div>
          ) : (
            <div className={classes.noResultText}>
              {t('groups_you_are_joined.no_group')}
            </div>
          )}
        </div>
      </DialogContent>

      <DialogActions classes={{ root: classes.groupsAction }}>
        <div className={classes.groupsMobileButtonWrapper}>
          {isMobile ? null : (
            <CustomizedButton
              variant="contained"
              color="default"
              onClick={() => onClose && onClose()}
            >
              {t('groups_you_are_joined.cancel_button')}
            </CustomizedButton>
          )}
          <CustomizedButton
            type="submit"
            variant="contained"
            color="primary"
            onClick={() => {
              onSubmit && onSubmit(activeSearchResult, activeGroup);
            }}
            disabled={activeSearchResult === ''}
          >
            {t('groups_you_are_joined.done_button')}
          </CustomizedButton>
        </div>
      </DialogActions>
    </>
  );
};

export default GroupsYouAreJoined;
