import React, { useEffect, useState } from 'react';
import { useStyles } from './styles';
import useMyDrive from '@hooks/Drive/MyDrive/useMyDrive';
import { blobToBase64, convertSubstring } from '@utils/file';
import {
  DRAWING_FILE_TYPES,
  IMAGE_TYPES,
  SUPORT_DOCUMENT_TYPES,
  PDF_TYPE
} from '@routes/Common/Dropzone/types';
import AnnotationViewer from '@routes/Viewer/AnnotationViewer/AnnotationViewer';
import { groupDetailFileApi } from '@api/index';
import PDFViewer from '@components/PDFViewer';
import Loading from '@components/LoadingInComponent';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import OfficeFileViewer from '@components/OfficeFileViewer';

const DetailFileViewer = () => {
  const classes = useStyles();
  const { myDrive } = useMyDrive();
  const [urlFileViewer, setUrlFileViewer] = useState([]);
  const [imgUrl, setImgUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [originalFileExt, setOriginalFileExt] = useState('');

  useEffect(() => {
    if (myDrive && myDrive.detailDrive && myDrive.detailDrive.id) {
      setOriginalFileExt(
        myDrive?.detailDrive?.originalFileExt?.toLocaleLowerCase()
      );
      setIsLoading(true);
      groupDetailFileApi(myDrive.detailDrive).then((res: any) => {
        setIsLoading(false);
        blobToBase64(res.data).then((imageUrl: any) => {
          setImgUrl(imageUrl);
        });
        res.data.arrayBuffer().then((arrayBuffer: any) => {
          setUrlFileViewer(arrayBuffer);
        });
        return;
      });
    } else {
      setImgUrl('');
      setOriginalFileExt('');
      setUrlFileViewer([]);
    }

    return () => {
      // Fix display old image.
      setImgUrl('');
    };
  }, [myDrive.detailDrive]);

  return (
    <div
      className={
        convertSubstring(DRAWING_FILE_TYPES).includes(originalFileExt)
          ? classes.viewerWrapper
          : classes.viewerWrapperImage
      }
    >
      {convertSubstring(DRAWING_FILE_TYPES).includes(originalFileExt) ? (
        <AnnotationViewer file={myDrive.detailDrive} />
      ) : null}
      {convertSubstring(IMAGE_TYPES).includes(originalFileExt) && (
        <div className={classes.imageViewer}>
          <div
            className={classes.imageViewer}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {isLoading ? (
              <Loading open={isLoading} />
            ) : (
              <TransformWrapper
                initialScale={1}
                initialPositionX={0}
                initialPositionY={0}
              >
                {() => (
                  <React.Fragment>
                    <TransformComponent
                      wrapperClass={classes.wrapperClass}
                      contentClass={classes.contentClass}
                    >
                      <img src={imgUrl} alt={myDrive?.detailDrive?.fileName} />
                    </TransformComponent>
                  </React.Fragment>
                )}
              </TransformWrapper>
            )}
          </div>
        </div>
      )}
      {originalFileExt === PDF_TYPE && (
        <PDFViewer fileUrl={new Uint8Array(urlFileViewer)} />
      )}
      {convertSubstring(SUPORT_DOCUMENT_TYPES).includes(originalFileExt) && (
        <OfficeFileViewer file={myDrive?.detailDrive} />
      )}
    </div>
  );
};

export default DetailFileViewer;
