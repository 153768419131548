import React, { useState } from 'react';
import { useStylesMobile } from './styles';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FiberManualRecord } from '@material-ui/icons';
import { SLIDE_MOBILE_DATA } from '@routes/Common/Dialog/Welcome/types';
import useAuth from '@hooks/useAuth';
import { Trans } from 'react-i18next';
import useCommon from '@hooks/Common/useCommon';
import { CapaClose } from '@assets';
import { useSwipeable } from 'react-swipeable';
import clsx from 'clsx';

const WelcomeMobile = () => {
  const classes = useStylesMobile();
  const { userState } = useAuth();
  const { t } = useTranslation();
  const [indexActive, setIndexActive] = useState(0);
  const { onSetShowHideWelcomeDialog, common } = useCommon();

  const handlersSwiped = useSwipeable({
    onSwipedLeft: () => {
      if (indexActive === SLIDE_MOBILE_DATA.length - 1) return;
      setIndexActive(indexActive + 1);
    },
    onSwipedRight: () => {
      if (indexActive === 0) return;
      setIndexActive(indexActive - 1);
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
    trackTouch: true
  });

  return (
    <Dialog
      classes={{
        paper: classes.paper
      }}
      open={common.isShowWelcomeDialog!}
      fullScreen
    >
      <DialogTitle classes={{ root: classes.rootTitle }} disableTypography>
        <img
          src={CapaClose}
          onClick={() =>
            onSetShowHideWelcomeDialog({
              isShowWelcomeDialog: false
            })
          }
          alt="close"
        />
      </DialogTitle>
      <DialogContent className={classes.csDialogContent}>
        {SLIDE_MOBILE_DATA.map((item, index) => {
          return index === indexActive ? (
            <div key={index}>
              <div className={classes.title}>{t(item.title)}</div>
              <div className={classes.content}>
                <Trans
                  i18nKey={t(item.content, {
                    username: userState?.data?.userName
                  })}
                />
              </div>
            </div>
          ) : null;
        })}
        {SLIDE_MOBILE_DATA.map((item, index) => {
          return (
            <FiberManualRecord
              key={index}
              className={clsx(
                classes.icon,
                index === indexActive
                  ? classes.activeColorIcon
                  : classes.colorIcon
              )}
              onClick={() => {
                setIndexActive(index);
              }}
            />
          );
        })}
        <div {...handlersSwiped}>
          {SLIDE_MOBILE_DATA.map((item, index) => {
            return index === indexActive ? (
              <div key={index}>
                <img src={item.image} className={classes.customImage} alt="" />
              </div>
            ) : null;
          })}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default WelcomeMobile;
