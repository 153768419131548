import React from 'react';
import clsx from 'clsx';
import { Typography, TypographyProps } from '@material-ui/core';

interface Props extends TypographyProps {
  fontWeight?: '100' | '300' | '500' | '700' | '900' | 'bold' | 'normal';
  gutterBottoms?: number;
  component?: any;
}

const CustomizedTypography: React.FC<Props> = (props) => {
  const { fontWeight, gutterBottoms, component, ...rest } = props;
  let styled = {};
  styled = fontWeight ? { fontWeight: fontWeight } : styled;
  styled = gutterBottoms ? { ...styled, marginBottom: gutterBottoms } : styled;

  return (
    <Typography
      component={component}
      {...rest}
      className={clsx('customized-typography', props.className, props.variant)}
      style={{ ...styled }}
      color={rest.color ?? 'textPrimary'}
      variantMapping={{ 'caption': 'p' }}
    >
      {props.children}
    </Typography>
  );
};

export default CustomizedTypography;
