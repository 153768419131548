import React, { useState, useEffect } from 'react';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import { useTranslation } from 'react-i18next';

import { notificationSettingsPathName } from '@routes/Common/types';
import DownloadAppBanner from '@components/DownloadAppBanner/GroupRoom';
import NotificationContainer from '@components/Notifications';
import { BottomNavMenu, GnbAppBar, LayoutDashboard } from '@routes/Common';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import useCommon from '@hooks/Common/useCommon';

const HeaderTitle = styled.p`
  font-size: 20px;
  font-weight: 700;
`;

const BoxWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  position: relative;
  top: -25px;
  align-items: center;
  padding: 0 16px 0 16px;
`;

const ActivityPage: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const { onShowHideNumberNotice } = useCommon();

  const [isShowDownloadSheet, setIsShowDownloadSheet] = useState(false);

  useEffect(() => {
    onShowHideNumberNotice({
      isShowNumberNotification: false
    });

    return () => {
      onShowHideNumberNotice({
        isShowNumberNotification: true
      });
    };
  }, []);

  const gotoNotificationSettings = () => {
    history.push(notificationSettingsPathName);
  };

  return (
    <LayoutDashboard
      contentShift={!matches}
      matches={matches}
      gnbAppBar={!matches ? <GnbAppBar /> : null}
    >
      <BoxWrapper>
        <HeaderTitle>{t('gnb.activity')}</HeaderTitle>
        <SettingsOutlinedIcon onClick={gotoNotificationSettings} />
      </BoxWrapper>
      <NotificationContainer
        open={true}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onClose={() => {}}
        isFullPage
        onShowDownloadApp={() => setIsShowDownloadSheet(true)}
      />
      {isShowDownloadSheet && (
        <DownloadAppBanner onClose={() => setIsShowDownloadSheet(false)} />
      )}

      <BottomNavMenu />
    </LayoutDashboard>
  );
};

export default ActivityPage;
