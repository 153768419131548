import React from 'react';
import DropzonePresenter from './DropzonePresenter';

interface Props {
  accept?: Array<string>;
  noClick?: boolean;
  callback?: (filesIds: any, fileName: any) => void;
  locationInfo?: any;
  locationIcon?: React.ReactNode;
  isFolder?: boolean;
  rootMinHeight?: string;
  rootHeight?: string;
  noDrag?: boolean;
  noKeyboard?: boolean;
}

const DropzoneContainer: React.FC<Props> = ({
  accept,
  noClick,
  callback,
  children,
  locationInfo,
  locationIcon,
  isFolder,
  rootMinHeight,
  rootHeight,
  noDrag,
  noKeyboard
}) => {
  return (
    <DropzonePresenter
      accept={accept}
      noClick={noClick}
      callback={callback}
      locationInfo={locationInfo}
      locationIcon={locationIcon}
      isFolder={isFolder}
      rootMinHeight={rootMinHeight}
      rootHeight={rootHeight}
      noDrag={noDrag}
      noKeyboard={noKeyboard}
    >
      {children}
    </DropzonePresenter>
  );
};

export default DropzoneContainer;
