import { makeStyles } from '@material-ui/core';
import { colorSet } from '@components/Provider';

export const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: 520,
    maxHeight: 552,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      minWidth: 343
    },
    borderRadius: '16px'
  },
  rootTitle: {
    padding: '32px 32px 24px 32px',
    display: 'flex',
    fontSize: '20px',
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
      marginBottom: 15,
      padding: '12px 16px',
      borderBottom: `1px solid ${colorSet.gray300}`
    }
  },
  subTitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      '& svg': {
        fontSize: 20
      }
    }
  },
  titleIcon: {
    cursor: 'pointer',
    width: 36,
    height: 36,
    background: `${colorSet.primary450}`,
    borderRadius: 12,
    marginRight: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      fontSize: 26,
      color: `${colorSet.gray000}`
    }
  },
  name: {
    fontSize: 20,
    fontWeight: 700,
    lineHeight: '160%',
    letterSpacing: '-0.1px',
    color: `${colorSet.gray900}`,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      fontSize: 16,
      width: '100%',
      display: 'block',
      fontWeight: 500
    }
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  cancelBtn: {
    fontSize: 32,
    marginLeft: 'auto',
    cursor: 'pointer'
  },
  content: {
    padding: '0px 32px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px 12px'
    }
  },
  action: {
    padding: '24px 32px 32px 32px',
    [theme.breakpoints.down('xs')]: {
      padding: 16
    }
  },
  wrapperBtn: {
    display: 'flex',
    '& button': {
      textTransform: 'none',
      marginLeft: 'auto',
      height: '48px !important',
      width: 132,
      '&:first-child': {
        marginRight: 8
      }
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      '& button': {
        width: 'calc(100% / 2)'
      },
      '& button + button': {
        marginLeft: 6
      }
    }
  },
  csDialogContent: {
    '& .MuiOutlinedInput-input': {
      border: `1px solid ${colorSet.gray400}`,
      borderRadius: 8
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent !important'
    },
    '& .MuiOutlinedInput-inputMarginDense': {
      paddingTop: 13.5,
      paddingBottom: 13.5
    },
    padding: '0 32px',
    [theme.breakpoints.down('xs')]: {
      padding: '0 15px 32px 15px'
    }
  }
}));
