import { ActionType } from 'typesafe-actions';
import * as actions from './actions';
import { CREATE_GROUP_SCREEN } from '@routes/Group/Dialog/types';

export interface GroupState {
  createGroupScreen?: any;
  selectedGroup?: any;
}

export const initialGroupState: GroupState = {
  createGroupScreen: CREATE_GROUP_SCREEN.CREATE_GROUP,
  selectedGroup: []
};

export type GroupAction = ActionType<typeof actions>;
