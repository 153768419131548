import React, { useEffect, useState, useRef, useMemo } from 'react';
import {
  useMediaQuery,
  useTheme,
  Breadcrumbs,
  Link,
  Checkbox
} from '@material-ui/core';
import { CustomizedTypography } from '@components/Customized';
import {
  MuiBoxTable,
  useStylesInsertFromMyDrive,
  MuiBoxTableItem,
  MuiDiv
} from './styles';
import useMyDrive from '@hooks/Drive/MyDrive/useMyDrive';
import { uniqueArray, formatTimeStamp } from '@utils/formatter';
import { DEFAULT_ROUTER_MY_DRIVE } from '@routes/Dashboard/types';
import { DEFAULT_ITEMS_LIMIT, KEY_TYPE } from '@routes/Common/types';
import { useTranslation } from 'react-i18next';
import { IS_MAC_OS } from '@routes/Common/utils';

import Loading from '@components/LoadingInComponent';
import IconThumbnail from '@components/IconThumbnail/IconThumbnail';
import useUserActionLog, {
  CapaActivityType,
  Enum_Action_Button_Enum
} from '@hooks/useUserActionLog';
import i18n from '@i18n/index';
import { LANGUAGE_VALUE } from '@routes/Common/ChangeLanguage/types';
import {
  IBMCAD,
  IBMCheckbox,
  IBMCheckboxChecked,
  IBMCheckboxUndeterminate,
  IBMChevronBreadcrumb,
  IBMMultiUser
} from '@assets';
import { LINK_STATUS } from '@routes/Dashboard/Dialog/Share/ShareLink/types';
import { ReactComponent as IBMLink } from '../../../../../assets/icon/IBMLink.svg';
import { convertSubstring } from '@utils/file';
import { DRAWING_FILE_TYPES } from '@routes/Common/Dropzone/types';

interface Props {
  isShowHideUploadFilesDialog?: boolean;
  filesUploaded?: any;
}

const UploadFromMyDrive = (props: Props) => {
  const { isShowHideUploadFilesDialog, filesUploaded } = props;
  const theme = useTheme();
  const classes = useStylesInsertFromMyDrive();
  const languageValue = i18n.language;
  const {
    myDrive,
    onFetchConnectMyDriveFileUploadInChat,
    connectMyDriveFileUploadInChatState,
    onFetchMoreConnectMyDriveFileUploadInChat,
    onSetSelectedFilesUploadInChat,
    connectMyDriveUploadFileInChatLoading
  } = useMyDrive();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const myDrivePath = (connectMyDriveFileUploadInChatState.path ?? []).filter(
    (item: any) => item.id !== null
  );
  myDrivePath.unshift(DEFAULT_ROUTER_MY_DRIVE);
  const [isFetching, setIsFetching] = useState(false);
  const [folderId, setFolderId] = useState('');
  const searchResultRef = useRef<any>(null);
  const { t } = useTranslation();
  const [selectedFirstFile, setSelectedFirstFile] = useState<any>(null);
  const [selectedFileMetaKey, setSelectedFileMetaKey] = useState<any>([]);
  const { onInsertUserActionLogOne } = useUserActionLog();
  const [hoverItem, setHoverItem] = useState(null);
  const myDriveFiles: any = connectMyDriveFileUploadInChatState?.files ?? [];

  const onSelectOnlyFile = (fileList: any) => {
    const { selectedFilesUploadInChat } = fileList;
    if (!selectedFilesUploadInChat || !selectedFilesUploadInChat.length)
      return onSetSelectedFilesUploadInChat({ selectedFilesUploadInChat: [] });

    onSetSelectedFilesUploadInChat({
      selectedFilesUploadInChat: selectedFilesUploadInChat.filter(
        (file: any) => file.isFile
      )
    });
  };

  const resetMyDrive = () => {
    onSelectOnlyFile({ selectedFilesUploadInChat: [] });
  };

  const onClickBreadcrumb = (folderId: string) => {
    setFolderId(folderId);
    onFetchConnectMyDriveFileUploadInChat({
      pathId: folderId ? folderId : '',
      limit: 40,
      offset: 0
    });

    if (filesUploaded?.length === 0) {
      onSelectOnlyFile({
        selectedFilesUploadInChat: []
      });
    }
  };

  const onClickMuiBoxTableItem = (
    e: any,
    isChecked: boolean,
    id: string,
    file: any,
    isCheckBox?: boolean
  ) => {
    if ((e.metaKey && IS_MAC_OS()) || e.ctrlKey || isCheckBox) {
      setSelectedFirstFile(file);
      if (myDrive.selectedFilesUploadInChat.length === 0) {
        setSelectedFileMetaKey([file]);
      }
      if (isChecked) {
        onSelectOnlyFile({
          selectedFilesUploadInChat: myDrive.selectedFilesUploadInChat.filter(
            (file: any) => file.id !== id
          )
        });
      } else {
        onSelectOnlyFile({
          selectedFilesUploadInChat: [
            ...myDrive.selectedFilesUploadInChat,
            file
          ]
        });
        setSelectedFileMetaKey([...myDrive.selectedFilesUploadInChat, file]);
      }
      return;
    }

    if (e.type === KEY_TYPE.CLICK && !e.shiftKey) {
      setSelectedFileMetaKey([file]);
      setSelectedFirstFile(file);
    }

    if (e.shiftKey) {
      if (myDrive.selectedFilesUploadInChat.length === 0) {
        setSelectedFirstFile(file);
        onSelectOnlyFile({
          selectedFilesUploadInChat: [...[file]]
        });
        return;
      }

      let indexOfNew = myDriveFiles?.files
        .map((file: any) => file.id)
        .indexOf(selectedFirstFile !== null ? selectedFirstFile.id : file.id);
      let indexOfLast = myDriveFiles.files
        .map((file: any) => file.id)
        .indexOf(file.id);

      const isSelectingForwards = indexOfNew! > indexOfLast!;
      const start = isSelectingForwards ? indexOfLast : indexOfNew;
      const end = isSelectingForwards ? indexOfNew : indexOfLast;
      onSelectOnlyFile({
        selectedFilesUploadInChat: uniqueArray(
          [
            ...selectedFileMetaKey,
            ...myDriveFiles.files.slice(start, end! + 1)
          ],
          'id'
        )
      });
      return;
    }

    if (!isCheckBox) {
      onSelectOnlyFile({
        selectedFilesUploadInChat: [...myDrive.selectedFilesUploadInChat, file]
      });
      setSelectedFileMetaKey([...myDrive.selectedFilesUploadInChat, file]);
    }
  };

  const onScrollContent = () => {
    const currentSearchResultRef = searchResultRef && searchResultRef.current;
    if (
      Math.ceil(currentSearchResultRef.scrollTop) +
        currentSearchResultRef.clientHeight !==
        currentSearchResultRef.scrollHeight ||
      isFetching
    )
      return;

    setIsFetching(true);
  };

  useEffect(() => {
    if (!isFetching) return;
    setTimeout(() => {
      if (isShowHideUploadFilesDialog) {
        onFetchMoreConnectMyDriveFileUploadInChat({
          variables: {
            pathId: folderId,
            limit: DEFAULT_ITEMS_LIMIT,
            offset: myDriveFiles?.length
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) {
              return prev;
            }

            const prevFiles = prev.fetchConnectMyDrive.data?.files;
            const moreFiles = fetchMoreResult.fetchConnectMyDrive.data?.files;

            if (fetchMoreResult.fetchConnectMyDrive.data)
              fetchMoreResult.fetchConnectMyDrive.data.files = [
                ...prevFiles!,
                ...moreFiles!
              ];

            return {
              ...fetchMoreResult
            };
          }
        });
      }
      setIsFetching(false);
    }, 500);
  }, [isFetching]);

  useEffect(() => {
    if (isShowHideUploadFilesDialog) {
      onFetchConnectMyDriveFileUploadInChat({
        pathId: '',
        limit: 40,
        offset: 0
      });
    } else {
      resetMyDrive();
    }
  }, [isShowHideUploadFilesDialog]);

  const fileListEl = useMemo(() => {
    const selectedFilesUploadInChatPath =
      myDrive.selectedFilesUploadInChat?.filter(
        (item: any) =>
          item.isFile && item?.filePath === myDriveFiles[0]?.filePath
      );
    const filesUploadedPath = filesUploaded.filter(
      (item: any) => item.isFile && item?.filePath === myDriveFiles[0]?.filePath
    );
    const uniqueSelectedFilesPath = uniqueArray(
      [...myDrive.selectedFilesUploadInChat, ...filesUploaded],
      'id'
    )?.filter(
      (item: any) => item.isFile && item?.filePath === myDriveFiles[0]?.filePath
    );
    return (
      <>
        <MuiBoxTable>
          <div className="table-header">
            <div className="checkbox-wrapper">
              <Checkbox
                checkedIcon={<img src={IBMCheckboxChecked} alt="" />}
                icon={<img src={IBMCheckbox} alt="" />}
                indeterminateIcon={
                  <img src={IBMCheckboxUndeterminate} alt="" />
                }
                color="primary"
                checked={
                  myDriveFiles?.length > 0 &&
                  (myDriveFiles?.filter((item: any) => item.isFile)?.length ===
                    selectedFilesUploadInChatPath?.length ||
                    myDriveFiles?.filter((item: any) => item.isFile)?.length ===
                      filesUploadedPath?.length)
                }
                indeterminate={
                  uniqueSelectedFilesPath?.length > 0 &&
                  myDriveFiles?.length > 0 &&
                  myDriveFiles?.filter((item: any) => item.isFile)?.length !==
                    uniqueSelectedFilesPath?.length
                }
                disabled={
                  myDriveFiles?.filter((item: any) => item.isFile)?.length ===
                  filesUploadedPath?.length
                }
                onClick={() => {
                  if (myDriveFiles?.length === 0) return;
                  if (
                    myDrive.selectedFilesUploadInChat?.filter(
                      (item: any) => item.isFile
                    )?.length === 0
                  ) {
                    onSetSelectedFilesUploadInChat({
                      selectedFilesUploadInChat: uniqueArray(
                        [
                          ...myDrive.selectedFilesUploadInChat?.filter(
                            (item: any) => item.isFile
                          ),
                          ...myDriveFiles?.filter((item: any) => item.isFile)
                        ],
                        'id'
                      )
                    });
                    return;
                  }
                  onSetSelectedFilesUploadInChat({
                    selectedFilesUploadInChat: []
                  });
                }}
              />
            </div>
            <CustomizedTypography
              className="table-header-th-one"
              variant="body2"
              color="textSecondary"
            >
              {t('upload_files_modal.column_title.name')}
            </CustomizedTypography>
            {isMobile ? null : (
              <CustomizedTypography
                className="table-header-th-two"
                variant="body2"
                color="textSecondary"
              >
                {t('upload_files_modal.column_title.upload_date')}
              </CustomizedTypography>
            )}
          </div>
        </MuiBoxTable>
        <div
          className={classes.searchResultWrapper}
          onScroll={onScrollContent}
          ref={searchResultRef}
        >
          {myDriveFiles?.length > 0 ? (
            myDriveFiles.map((item: any, index: number) => {
              const isChecked =
                myDrive.selectedFilesUploadInChat.filter(
                  (file: any) => file.id === item.id
                ).length > 0;
              const isDisabled = filesUploaded
                .map((file: any) => file.id)
                .includes(item?.id);
              return (
                <MuiBoxTableItem
                  key={index}
                  isChecked={isChecked}
                  isDisabled={isDisabled}
                  isHover={hoverItem === item.id}
                  onDoubleClick={() => {
                    if (!item.isFile) {
                      if (filesUploaded?.length === 0) {
                        onSetSelectedFilesUploadInChat({
                          selectedFilesUploadInChat: []
                        });
                      }
                      setFolderId(item.id);
                      onFetchConnectMyDriveFileUploadInChat({
                        pathId: item.id,
                        limit: 40,
                        offset: 0
                      });
                    }
                  }}
                  onClick={(e) => {
                    if (isDisabled || isChecked) return;
                    if (item.isFile) {
                      onInsertUserActionLogOne(
                        Enum_Action_Button_Enum.Button_UploadFileModal_MyDrive_ClickFile,
                        CapaActivityType.ButtonClick
                      );
                    }
                    onClickMuiBoxTableItem(e, isChecked, item.id, item);
                  }}
                  onMouseEnter={() => {
                    if (isMobile) return;
                    setHoverItem(item.id);
                  }}
                  onMouseLeave={() => {
                    if (isMobile) return;
                    setHoverItem(null);
                  }}
                >
                  <div className="table-item-td-one">
                    <div className="checkbox-wrapper">
                      <Checkbox
                        checkedIcon={<img src={IBMCheckboxChecked} alt="" />}
                        icon={<img src={IBMCheckbox} alt="" />}
                        checked={uniqueArray(
                          [
                            ...myDrive.selectedFilesUploadInChat,
                            ...filesUploaded
                          ],
                          'id'
                        )
                          ?.map((file: any) => file.id)
                          .includes(item?.id)}
                        color="primary"
                        onClick={(e) => {
                          e.stopPropagation();
                          if (!item.isFile) return;
                          onClickMuiBoxTableItem(
                            e,
                            isChecked,
                            item.id,
                            item,
                            item.isFile
                          );
                        }}
                        disabled={filesUploaded
                          .map((file: any) => file.id)
                          .includes(item?.id)}
                      />
                    </div>
                    <IconThumbnail
                      file={item}
                      size="small"
                      isInsertFromMyDrive={true}
                    />
                    <CustomizedTypography
                      component={'div'}
                      variant="body2"
                      noWrap
                    >
                      <div className="table-item-share">
                        {convertSubstring(DRAWING_FILE_TYPES).includes(
                          item?.originalFileExt?.toLocaleLowerCase()
                        ) &&
                          item?.isFile && (
                            <img src={IBMCAD} alt="" className="ibm-cad-icon" />
                          )}
                        <div className="table-item-text">{item?.fileName}</div>
                        {item?.isShared && item?.isFile && (
                          <img
                            className="table-item-share-icon"
                            src={IBMMultiUser}
                            alt=""
                          />
                        )}
                        {item?.linkStatus === LINK_STATUS.ACTIVE &&
                          item?.isFile && <IBMLink />}
                      </div>
                    </CustomizedTypography>
                  </div>
                  {isMobile ? null : (
                    <div className="table-item-td-two">
                      <CustomizedTypography variant="body2">
                        {formatTimeStamp(
                          item.createdAt,
                          true,
                          false,
                          false,
                          true
                        )}
                      </CustomizedTypography>
                    </div>
                  )}
                </MuiBoxTableItem>
              );
            })
          ) : (
            <div className={classes.noDocuments}>
              {t('upload_files_modal.no_documents')}
            </div>
          )}
        </div>
      </>
    );
  }, [
    connectMyDriveFileUploadInChatState,
    myDrive.selectedFilesUploadInChat,
    filesUploaded
  ]);

  return (
    <>
      <Loading
        open={connectMyDriveUploadFileInChatLoading}
        styles={{
          display: 'flex',
          justifyContent: 'center',
          minHeight: 300
        }}
      />
      {!connectMyDriveUploadFileInChatLoading ? (
        <MuiDiv>
          <div className={classes.csBreadcrumb}>
            <CustomizedTypography
              className="cs-typography"
              component={'div'}
              variant={isMobile ? 'h6' : 'h3'}
              fontWeight="500"
            >
              <Breadcrumbs
                separator={<img src={IBMChevronBreadcrumb} alt="" />}
                aria-label="breadcrumb"
              >
                {myDrivePath &&
                  myDrivePath.map((item: any, index: number) => (
                    <Link
                      key={index}
                      onClick={() => onClickBreadcrumb(item.id)}
                      className="cursor-pointer"
                      color="inherit"
                    >
                      {languageValue == LANGUAGE_VALUE.KO
                        ? item.folderNameKO
                          ? item.folderNameKO
                          : item.folderName
                        : item.folderName}
                    </Link>
                  ))}
              </Breadcrumbs>
            </CustomizedTypography>
          </div>
        </MuiDiv>
      ) : null}
      {connectMyDriveUploadFileInChatLoading ? null : fileListEl}
    </>
  );
};

export default UploadFromMyDrive;
