import React from 'react';
import { CustomizedTypography } from '@components/Customized';
import { useTranslation } from 'react-i18next';

interface Props {
  item?: any;
}

const DashboardHeaderTable: React.FC<Props> = ({ item }) => {
  const { t } = useTranslation();

  return (
    <>
      <CustomizedTypography
        className={item.className}
        variant="body2"
        color="textSecondary"
      >
        {t(item.name)}
        {/* Hide beta */}
        {/*        {item.isOrder && (
          <span
            className="icon-up"
            onClick={() => {
              onSortByItem(item.value);
            }}
          >
            {isSortTable ? <ArrowDownwardOutlined /> : <ArrowUpwardOutlined />}
          </span>
        )}*/}
      </CustomizedTypography>
    </>
  );
};

export default DashboardHeaderTable;
