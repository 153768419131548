import React, { useState } from 'react';
import clsx from 'clsx';
import { CapaServiceType } from '@generated/Common/graphql';
import { CapaFaviconImg, PartnersFaviconImg, ConnectFaviconImg } from '@assets';
import useUserActionLog, {
  Enum_Action_Button_Enum,
  CapaActivityType
} from '@hooks/useUserActionLog';
import { colorSet } from '@components/Provider';
import { CustomizedTypography } from '@components/Customized';
import { UserType } from './types';
import {
  Popper,
  Box,
  IconButton,
  ClickAwayListener,
  makeStyles
} from '@material-ui/core';
import { Apps } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 2000,
    top: '61px !important',
    left: 'initial !important',
    right: '32px !important',
    transform: 'initial !important',
    [theme.breakpoints.down('xs')]: {
      top: '49px !important',
      right: '16px !important'
    }
  },
  menuIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 32,
    height: 32,
    color: colorSet.gray900,
    '&:hover': {
      backgroundColor: colorSet.gray100
    }
  },
  boxRoot: {
    borderRadius: 4,
    backgroundColor: colorSet.gray000,
    padding: 8,
    boxShadow:
      '0px 8px 16px 2px rgba(97, 97, 97, 0.1), 0px 16px 32px 2px rgba(97, 97, 97, 0.1)'
  },
  menuWrapper: {
    display: 'flex'
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      width: 46,
      marginBottom: 7
    },
    textAlign: 'center',
    width: 96,
    height: 96,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: colorSet.gray100
    },
    borderRadius: 4,
    '& + div': {
      marginLeft: 8
    }
  }
}));

interface Props {
  onClick: (service: UserType) => void;
}

const GnbCheckerBoardMenuPresenter: React.FC<Props> = ({ onClick }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const { onInsertUserActionLogOne } = useUserActionLog();

  const onClose = () => setOpen(false);

  const onClickHandle = (service: UserType) => {
    onClose();
    onClick(service);
  };

  return (
    <React.Fragment>
      <IconButton
        className={clsx(classes.menuIcon, 'gnb-checkerboard-menu')}
        size="small"
        onClick={(e) => {
          if (e.currentTarget) {
            onInsertUserActionLogOne(
              Enum_Action_Button_Enum.Button_Gnb_CheckerBoard_Capa,
              CapaActivityType.ButtonClick
            );
            setAnchorEl(e.currentTarget);
            setOpen((prevOpen) => !prevOpen);
          }
        }}
      >
        <Apps />
      </IconButton>
      <Popper
        className={classes.root}
        open={open}
        anchorEl={anchorEl}
        transition
        placement="bottom"
      >
        <ClickAwayListener onClickAway={onClose}>
          <Box className={classes.boxRoot}>
            <div className={classes.menuWrapper}>
              <div
                className={classes.menuItem}
                onClick={() => onClickHandle(CapaServiceType.Quotation)}
              >
                <img src={CapaFaviconImg} alt="capa" />
                <CustomizedTypography variant="subtitle2">
                  캐파
                </CustomizedTypography>
              </div>
              <div
                className={classes.menuItem}
                onClick={() => onClickHandle(CapaServiceType.QuotationPartner)}
              >
                <img src={PartnersFaviconImg} alt="partners" />
                <CustomizedTypography variant="subtitle2">
                  캐파 파트너스
                </CustomizedTypography>
              </div>
              <div
                className={classes.menuItem}
                onClick={() => onClickHandle(CapaServiceType.Cloud)}
              >
                <img src={ConnectFaviconImg} alt="capa" />
                <CustomizedTypography variant="subtitle2">
                  캐파 클라우드
                </CustomizedTypography>
              </div>
            </div>
          </Box>
        </ClickAwayListener>
      </Popper>
    </React.Fragment>
  );
};

export default GnbCheckerBoardMenuPresenter;
