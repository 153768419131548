import React from 'react';
import { colorSet } from '@components/Provider';
import {
  createMuiTheme,
  createStyles,
  makeStyles,
  Theme,
  useMediaQuery
} from '@material-ui/core';
import useUserActionLog, {
  CapaActivityType,
  Enum_Action_Button_Enum
} from '@hooks/useUserActionLog';
import { singUpPathName } from '@routes/Common/types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  heroBanner,
  heroBannerKO,
  heroBannerApp,
  heroBannerAppKO
} from '@routes/Home/assets';
import i18n from '@i18n/index';
import { LANGUAGE_VALUE } from '@routes/Common/ChangeLanguage/types';
const themeLanding = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1024,
      xl: 1536
    }
  }
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerFriud: {
      maxWidth: '100%',
      overflow: 'hidden',
      paddingLeft: 32,
      paddingRight: 32,
      [themeLanding.breakpoints.down('md')]: {
        paddingLeft: 16,
        paddingRight: 16
      }
    },
    container: {
      width: '100%',
      maxWidth: '1024px',
      margin: 'auto',
      [themeLanding.breakpoints.down('md')]: {
        maxWidth: '960px'
      }
    },
    heroFlex: {
      display: 'flex',
      maxWidth: '100%',
      marginBottom: 80,
      alignItems: 'center',
      [themeLanding.breakpoints.down('md')]: {
        marginBottom: 65,
        flexDirection: 'column'
      }
    },
    wrapText: {
      display: 'flex',
      padding: '100px 0',
      marginRight: 1,
      width: 415,
      flexShrink: 0,
      flexDirection: 'column',
      [themeLanding.breakpoints.down('md')]: {
        padding: '48px 0 0',
        alignItems: 'center',
        width: 'fit-content',
        margin: 'auto'
      },
      [themeLanding.breakpoints.down('xs')]: {
        width: 'fit-content',
        padding: '40px 0'
      }
    },
    titleText: {
      textAlign: 'left',
      fontWeight: 700,
      fontSize: 44,
      lineHeight: '62px',
      marginBottom: 24,
      letterSpacing: '-0.1px',
      color: colorSet.gray100,
      whiteSpace: 'break-spaces',
      [themeLanding.breakpoints.down('md')]: {
        textAlign: 'center',
        marginRight: 0
      },
      [themeLanding.breakpoints.down('xs')]: {
        fontSize: 28,
        lineHeight: '39px',
        marginRight: 0,
        marginBottom: 20
      }
    },
    subTitleText: {
      textAlign: 'left',
      fontWeight: 400,
      fontSize: 18,
      lineHeight: '25px',
      marginBottom: 56,
      letterSpacing: '-0.1px',
      color: colorSet.gray000,
      whiteSpace: 'break-spaces',
      [themeLanding.breakpoints.down('md')]: {
        textAlign: 'center'
      },
      [themeLanding.breakpoints.down('xs')]: {
        fontSize: 14,
        lineHeight: '20px',
        marginBottom: 54
      }
    },
    buttonSigin: {
      padding: '20px 57px',
      fontWeight: 500,
      fontSize: 20,
      width: 'fit-content',
      lineHeight: '25px',
      background: colorSet.primary450,
      borderRadius: 8,
      border: 'none',
      color: 'white',
      letterSpacing: '-0.1px',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: colorSet.primary600
      },
      [themeLanding.breakpoints.down('md')]: {
        padding: '12px 43px',
        fontSize: 16,
        lineHeight: '24px'
      }
    },
    imageBanner: {
      marginRight: '-216px',
      [themeLanding.breakpoints.down('lg')]: {
        paddingTop: 25,
        paddingBottom: 25
      },
      [themeLanding.breakpoints.down('md')]: {
        marginRight: 0,
        paddingTop: 0,
        paddingBottom: 0
      },
      '& img': {
        maxWidth: 825,
        width: '100%',
        [themeLanding.breakpoints.down('lg')]: {
          maxWidth: 825
        },
        [themeLanding.breakpoints.down('md')]: {
          maxWidth: 560
        },
        [themeLanding.breakpoints.down('xs')]: {
          maxWidth: 180
        }
      }
    }
  })
);

const Hero = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const languageValue = i18n.language;
  const { onInsertUserActionLogOne } = useUserActionLog();
  const history = useHistory();
  const isMobile = useMediaQuery(themeLanding.breakpoints.down('xs'));

  const handleGoToSignUp = () => {
    onInsertUserActionLogOne(
      Enum_Action_Button_Enum.Button_Landing_Hero_CTA,
      CapaActivityType.ButtonClick
    );
    history.push(singUpPathName);
  };

  let imageHero = heroBanner;
  if (isMobile) {
    if (languageValue === LANGUAGE_VALUE.KO) imageHero = heroBannerAppKO;
    else imageHero = heroBannerApp;
  } else {
    if (languageValue === LANGUAGE_VALUE.KO) imageHero = heroBannerKO;
    else imageHero = heroBanner;
  }
  return (
    <div className={classes.containerFriud}>
      <div className={classes.container}>
        <div className={classes.heroFlex}>
          <div className={classes.wrapText}>
            <h1 className={classes.titleText}>
              {t('landing_page.home_section.head_title')}
            </h1>
            <h2 className={classes.subTitleText}>
              {t('landing_page.home_section.sub_title')}
            </h2>
            <button className={classes.buttonSigin} onClick={handleGoToSignUp}>
              {t('landing_page.home_section.sign_up')}
            </button>
          </div>
          <div className={classes.imageBanner}>
            <img src={imageHero} alt="heroBanner" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
