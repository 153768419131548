import React from 'react';
import { useStyles } from './styles';
import { toolbarPlugin, ToolbarSlot } from '@react-pdf-viewer/toolbar';
import { SpecialZoomLevel, Viewer, Worker } from '@react-pdf-viewer/core';
import { WORKER_URL } from '@routes/Viewer/types';
import { CONNECT_USER_FILE_STATUS } from '@routes/Dashboard/Dialog/Share/ShareLink/types';
import clsx from 'clsx';

interface Props {
  fileUrl?: any;
  permission?: any;
}

const PDFViewer = (props: Props) => {
  const { fileUrl, permission } = props;
  const classes = useStyles();
  const toolbarPluginInstance = toolbarPlugin();
  const { Toolbar } = toolbarPluginInstance;

  if (!fileUrl) return <></>;

  return (
    <Worker workerUrl={WORKER_URL}>
      <div className={classes.viewerPdf}>
        <div className={clsx('rpv-core__viewer', classes.coreViewer)}>
          <div className={classes.pdfWrapper}>
            <Toolbar>
              {(props: ToolbarSlot) => {
                const {
                  CurrentPageInput,
                  EnterFullScreen,
                  GoToNextPage,
                  GoToPreviousPage,
                  Print,
                  ZoomIn,
                  ZoomOut
                } = props;
                return (
                  <>
                    <div className="pd-button">
                      <ZoomOut />
                    </div>
                    <div className="pd-button">
                      <ZoomIn />
                    </div>
                    <div className="pd-button ml-auto">
                      <GoToPreviousPage />
                    </div>
                    <div className="pd-button current-page-input">
                      <CurrentPageInput />
                    </div>
                    <div className="pd-button">
                      <GoToNextPage />
                    </div>
                    <div className="pd-button ml-auto">
                      <EnterFullScreen />
                    </div>
                    {(!permission ||
                      permission != CONNECT_USER_FILE_STATUS.VIEWABLE) && (
                      <div className="pd-button">
                        <Print />
                      </div>
                    )}
                  </>
                );
              }}
            </Toolbar>
          </div>
          <div className={classes.viewerWrapper}>
            <Viewer
              fileUrl={new Uint8Array(fileUrl)}
              plugins={[toolbarPluginInstance]}
              defaultScale={SpecialZoomLevel.PageWidth}
              theme={{
                theme: 'light'
              }}
            />
          </div>
        </div>
      </div>
    </Worker>
  );
};

export default PDFViewer;
