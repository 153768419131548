const localStorage = window.localStorage;
const sessionStorage = window.sessionStorage;

export enum STORAGE_ACTION {
  CONNECT_ACCESS_TOKEN = 'CONNECT_ACCESS_TOKEN',
  CONNECT_REFRESH_TOKEN = 'CONNECT_REFRESH_TOKEN',
  USER_INFO = 'USER_INFO',
  LOGGED_IN = 'LOGGED_IN',
  REDIRECT_PATH = 'REDIRECT_PATH',
  NOT_SEEING_AGAIN_UPDATE = 'NOT_SEEING_AGAIN_UPDATE',
  NOT_SEEN_FOR_A_DAY_UPDATE = 'NOT_SEEN_FOR_A_DAY_UPDATE',
  NOT_SEEING_AGAIN_PRE_NOTICE = 'NOT_SEEING_AGAIN_PRE_NOTICE',
  NOT_SEEING_AGAIN_URGENT_NOTICE = 'NOT_SEEING_AGAIN_URGENT_NOTICE',
  CONNECT_USER_RESULT = 'CONNECT_USER_RESULT',
  CLOSE_DOWNLOAD_APP_BANNER_TIME = 'CLOSE_DOWNLOAD_APP_BANNER_TIME',
  CLOSE_DOWNLOAD_APP_BANNER_IN_GROUP_TIME = 'CLOSE_DOWNLOAD_APP_BANNER_IN_GROUP_TIME',
  COUNT_TIME_SHARE = 'COUNT_TIME_SHARE',
  IS_OPEN_DETAIL_FILE = 'IS_OPEN_DETAIL_FILE'
}

export const setStorageItem = (action: STORAGE_ACTION, data: any) => {
  if (
    action === STORAGE_ACTION.REDIRECT_PATH ||
    action === STORAGE_ACTION.LOGGED_IN
  ) {
    sessionStorage.setItem(action, data);
  } else {
    localStorage.setItem(action, data);
  }
};

export const getStorageItem = (action: STORAGE_ACTION) => {
  if (
    action === STORAGE_ACTION.LOGGED_IN ||
    action === STORAGE_ACTION.REDIRECT_PATH
  ) {
    return sessionStorage.getItem(action);
  } else if (action === STORAGE_ACTION.USER_INFO) {
    const data = localStorage.getItem(action);
    const userInfo = data ? JSON.parse(data) : undefined;
    return userInfo;
  } else {
    return localStorage.getItem(action);
  }
};

export const deleteStorageItem = (action: STORAGE_ACTION) => {
  if (
    action === STORAGE_ACTION.LOGGED_IN ||
    action === STORAGE_ACTION.REDIRECT_PATH
  ) {
    sessionStorage.removeItem(action);
  } else {
    localStorage.removeItem(action);
  }
};

export const clearStorageAll = () => {
  localStorage.removeItem(STORAGE_ACTION.CONNECT_ACCESS_TOKEN);
  localStorage.removeItem(STORAGE_ACTION.CONNECT_REFRESH_TOKEN);
  localStorage.removeItem(STORAGE_ACTION.USER_INFO);
  localStorage.removeItem(STORAGE_ACTION.COUNT_TIME_SHARE);
  sessionStorage.removeItem(STORAGE_ACTION.LOGGED_IN);
  sessionStorage.removeItem(STORAGE_ACTION.REDIRECT_PATH);
};

export const getTokens = () => {
  return {
    accessToken: getStorageItem(STORAGE_ACTION.CONNECT_ACCESS_TOKEN),
    refreshToken: getStorageItem(STORAGE_ACTION.CONNECT_REFRESH_TOKEN)
  };
};
