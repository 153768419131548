import { makeStyles } from '@material-ui/core';
import { colorSet } from '@components/Provider';

export const useStyles = makeStyles((theme) => ({
  linkApps: {
    display: 'grid',
    padding: '8px 0 30px',
    flexDirection: 'column',
    borderTop: `1px solid ${colorSet.gray300}`,
    position: 'absolute',
    bottom: 0,
    width: 'calc(100% - 16px)'
  },
  linkItem: {
    padding: '6px 16px',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: '-0.1px',
    color: colorSet.gray800,
    transition: '300ms',

    cursor: 'pointer',
    '&:hover': {
      color: colorSet.primary450,
      '& svg path': {
        fill: colorSet.primary450
      }
    },
    '& svg': {
      marginRight: 12,
      '& path': {
        transition: '300ms'
      }
    }
  }
}));
