import AppConfig from '@config/index';
import { wsLink } from '../apollo';
import { CapaServiceType } from '@generated/Common/graphql';
import { getTokens } from '@utils/storage';
import {
  groupPathName,
  LIMIT_CHARACTERS_GROUP_NAME
} from '@routes/Common/types';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { JwtPayloadType } from '@modules/auth';
import { getStorageItem, STORAGE_ACTION } from '@utils/storage';

export const getDisplayedAvatarString = (userName: any, userEmail: any) => {
  return userName ? userName : userEmail ?? '';
};

export const checkOnlySpaces = (value: any) => {
  return value.trim().length === 0;
};

export const trimSpaces = (value: string) => {
  return value.replace(/\s+/g, ' ');
};

export const hasWrapperElementOverflow = (element: any) => {
  if (!element) return false;
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
};
export const generateOfficeURL = (fileID: string, fileExt: string) => {
  const { accessToken } = getTokens();
  if (accessToken)
    return (
      AppConfig.officeIframeUrl +
      '?src=' +
      AppConfig.fileApiUrl +
      '/connect/' +
      accessToken +
      '/' +
      fileID +
      '.' +
      fileExt
    );
  return (
    AppConfig.officeIframeUrl +
    '?src=' +
    AppConfig.fileApiUrl +
    '/connect/' +
    fileID +
    '.' +
    fileExt
  );
};

export const reconnectSubscriptionClient = (
  accessToken: any,
  refreshToken: any
) => {
  wsLink.subscriptionClient.connectionParams.Authorization = `Bearer ${
    accessToken ? accessToken : refreshToken
  }`;

  wsLink.subscriptionClient.close();
  wsLink.subscriptionClient.connect();
};

export const redirectToGroupListNotConnect = (history: any) => {
  const accessToken = getStorageItem(STORAGE_ACTION.CONNECT_ACCESS_TOKEN);
  const decodedJwt = (
    accessToken ? jwtDecode<JwtPayload>(accessToken) : {}
  ) as JwtPayloadType;
  const scopes: any = decodedJwt?.scopes ?? undefined;

  if (!scopes.includes(CapaServiceType.Connect)) {
    history.push({
      pathname: groupPathName
    });
  } else {
    localStorage.removeItem(STORAGE_ACTION.CONNECT_USER_RESULT);
  }
};

export const getGroupNameByUsernameOrEmail = (
  users: any,
  isToolTip?: boolean
) => {
  const groupName = users
    ?.map((user: any) =>
      user?.connectUserName ? user?.connectUserName : user?.email
    )
    .join(', ');
  if (isToolTip) {
    return groupName;
  }

  return groupName?.length > LIMIT_CHARACTERS_GROUP_NAME
    ? `${groupName.slice(0, 20)}...`
    : groupName;
};

export const getGroupListUsers = (users: any) => {
  const internalUsers = users?.map((user: any) =>
    user?.connectUserName ? user?.connectUserName : user?.email
  );

  if (internalUsers?.length > 3) return internalUsers?.slice(0, 3)?.join(', ');

  return internalUsers?.join(', ');
};
