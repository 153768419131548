import { createReducer } from 'typesafe-actions';
import {
  DashboardState,
  DashboardAction,
  initialDashboardState
} from './types';
import { UPDATE_DASHBOARD_STATE, INIT_DASHBOARD_STATE } from './actions';

export const dashboard = createReducer<DashboardState, DashboardAction>(
  initialDashboardState,
  {
    [UPDATE_DASHBOARD_STATE]: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    },
    [INIT_DASHBOARD_STATE]: () => initialDashboardState
  }
);
