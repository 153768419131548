import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  avatarWrapper: {
    width: 48,
    height: 48,
    position: 'relative'
  },
  moreThanFourMembersWrapper: {
    width: 48,
    height: 48,
    display: 'grid',
    position: 'relative',
    gridTemplateColumns: 'auto auto',
    justifyContent: 'center',
    alignContent: 'center'
  },
  oneMember: {
    width: 48,
    height: 48,
    textTransform: 'uppercase',
    fontWeight: 500
  },
  twoMembers: {
    width: 32,
    height: 32,
    textTransform: 'uppercase',
    fontSize: 12,
    fontWeight: 500,
    position: 'absolute',
    '&:nth-child(1)': {
      right: 0,
      top: 0
    },
    '&:nth-child(2)': {
      bottom: 0,
      left: 0
    }
  },
  threeMembers: {
    width: 28,
    height: 28,
    textTransform: 'uppercase',
    fontSize: 10,
    fontWeight: 500,
    zIndex: 1,
    position: 'absolute',
    '&:nth-child(1)': {
      zIndex: 2,
      right: 10
    },
    '&:nth-child(2)': {
      zIndex: 1,
      bottom: 0,
      left: 0
    },
    '&:nth-child(3)': {
      zIndex: 0,
      bottom: 0,
      right: 0
    }
  },
  moreThanFourMembers: {
    width: 24,
    height: 24,
    textTransform: 'uppercase',
    fontSize: 10,
    fontWeight: 500
  },
  personIcon: {
    width: 7.5,
    height: 10.5
  },
  onePersonIcon: {
    width: 19.5,
    height: 22.5
  },
  twoPersonIcon: {
    width: 12.5,
    height: 15.5
  }
}));
