import React from 'react';
import RequestForDownloadPresenter from './RequestForDownloadPresenter';
import useMyDrive from '@hooks/Drive/MyDrive/useMyDrive';

interface Props {
  onSend?: () => void;
}

const RequestForDownloadContainer = (props: Props) => {
  const { onSend } = props;
  const { onSetShowHideRequestForDownload } = useMyDrive();

  const onCloseDialog = () => {
    onSetShowHideRequestForDownload({
      isShowHideRequestForDownload: false
    });
  };

  return (
    <RequestForDownloadPresenter onClose={onCloseDialog} onSend={onSend} />
  );
};

export default RequestForDownloadContainer;
