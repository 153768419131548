import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MobileAsideMenu from '../MobileAsideMenu';
import { AppBar, Toolbar, useTheme, useMediaQuery } from '@material-ui/core';
import {
  Close,
  ArrowBackIos,
  ArrowBackRounded,
  ArrowBackIosRounded
} from '@material-ui/icons';

import { CapaLogoBeta } from '@assets';
import { CustomizedButton, CustomizedTypography } from '@components/Customized';
import { STORAGE_ACTION } from '@utils/storage';
import useUserActionLog, {
  CapaActivityType,
  Enum_Action_Button_Enum
} from '@hooks/useUserActionLog';
import StagingChipPresenter from '../StagingChipPresenter';
import GnbCheckerBoardMenu from '../GnbCheckerBoardMenu';
import GnbPopupMenu from '../GnbPopupMenu';
import NotificationMenu from '../NotificationMenu';
import { useStylesGnbAppBar } from './useStyles';
import { GnbMenuStyle, GnbMenuMobileStyle, FolderNameHeader } from './styles';
import { myDrivePathName } from '@routes/Common/types';
import ChangeLanguageContainer from '@routes/Common/ChangeLanguage';
import { isChildrenPage } from '@routes/Common/utils';
import TabsMenu from '@routes/Common/tabsHeader/TabsMenu';
import FeedbackAndReport from '@routes/Common/Dialog/FeedbackAndReport';
import ConnectGNB from '@routes/Common/ConnectGNB/ConnectGNB';

interface Props {
  isSelect?: boolean;
  count?: number;
  onSetIsSelect?: () => void;
  headerName?: string;
}

const GnbAppBarPresenter: React.FC<Props> = ({
  isSelect,
  count,
  onSetIsSelect,
  headerName
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStylesGnbAppBar();
  const history = useHistory();
  const { t } = useTranslation();
  const [isOpenAsideMenu, setIsOpenAsideMenu] = useState(false);
  const { onInsertUserActionLogOne } = useUserActionLog();
  const loggedIn =
    localStorage.getItem(STORAGE_ACTION.CONNECT_ACCESS_TOKEN) &&
    localStorage.getItem(STORAGE_ACTION.CONNECT_REFRESH_TOKEN);
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
  const matchesXs = useMediaQuery(theme.breakpoints.down('xs'));
  const { pathname } = useLocation();

  const route =
    pathname?.indexOf('mypage') !== -1
      ? 'mypage'
      : pathname?.indexOf('dashboard') !== -1
      ? 'dashboard'
      : pathname?.indexOf('terms') !== -1
      ? 'terms'
      : '';

  const insertLog = (action: Enum_Action_Button_Enum) => {
    onInsertUserActionLogOne(action, CapaActivityType.ButtonClick);
  };

  const gnbExtraMenu = () => {
    switch (route) {
      case 'dashboard':
        return (
          <GnbMenuStyle>
            <NotificationMenu />
            <GnbPopupMenu />
          </GnbMenuStyle>
        );
      case 'mypage':
        return (
          <GnbMenuStyle>
            <ChangeLanguageContainer />
            <ConnectGNB />
            <NotificationMenu />
            <GnbPopupMenu />
          </GnbMenuStyle>
        );
      case 'terms':
        return (
          <GnbMenuStyle>
            <GnbCheckerBoardMenu />
            {loggedIn && (
              <>
                <NotificationMenu />
                <GnbPopupMenu />
              </>
            )}
            {matchesXs ? (
              <></>
            ) : loggedIn ? (
              <CustomizedButton
                className="gnb-drive-button"
                variant={loggedIn || matchesXs ? 'contained' : 'outlined'}
                size={matchesSm ? 'small' : 'medium'}
                onClick={() => {
                  insertLog(Enum_Action_Button_Enum.Button_Gnb_MyDrive);
                  history.push(myDrivePathName);
                }}
              >
                드라이브로 이동
              </CustomizedButton>
            ) : (
              <CustomizedButton
                className="gnb-login-button"
                variant={matchesXs ? 'contained' : 'outlined'}
                size={matchesSm ? 'small' : 'medium'}
                onClick={() => {
                  insertLog(Enum_Action_Button_Enum.Button_Gnb_Login);
                  if (matchesXs) {
                    history.push('/signup');
                  } else {
                    history.push('/login');
                  }
                }}
              >
                {matchesXs ? '회원가입' : '로그인/회원가입'}
              </CustomizedButton>
            )}
          </GnbMenuStyle>
        );

      default:
        return (
          <GnbMenuStyle>
            <ChangeLanguageContainer />
            <ConnectGNB />
            <NotificationMenu />
            <GnbPopupMenu />
          </GnbMenuStyle>
        );
    }
  };

  const GnbMobileExtraMenu = () => {
    const history = useHistory();
    const menuLabel = () => {
      if (pathname?.includes('edit/email')) return t('gnb.email_settings');
      if (pathname?.includes('edit/password'))
        return t('gnb.password_settings');
      if (pathname?.includes('mypage/notification-settings'))
        return (
          <div className={classes.notificationBar}>
            <ArrowBackIos
              onClick={() => {
                history.goBack();
              }}
            />
            <div className={classes.notificationTitle}>
              {t('notification_settings.notifications')}
            </div>
            <div />
          </div>
        );

      if (pathname?.includes('mypage/account'))
        return (
          <div className={classes.notificationBar}>
            <ArrowBackIos
              onClick={() => {
                history.push('/mypage');
              }}
            />
            <div className={classes.notificationTitle}>
              {t('account_page.account_setting')}
            </div>
            <div />
          </div>
        );

      if (pathname?.includes('edit/username'))
        return (
          <div className={classes.notificationBar}>
            <ArrowBackIos
              onClick={() => {
                history.push('/mypage/account');
              }}
            />
            <div className={classes.notificationTitle}>
              {t('account_page.change_name')}
            </div>
            <div />
          </div>
        );

      if (pathname?.indexOf('mypage') !== -1) return t('gnb.profile');
      if (isChildrenPage(pathname, 'my-drive')) {
        return (
          <FolderNameHeader>
            <ArrowBackRounded onClick={() => history.goBack()} />
            <div className="folder-name">{headerName}</div>
          </FolderNameHeader>
        );
      } else t('gnb.drive');

      if (isChildrenPage(pathname, 'shared-with-groups')) {
        return (
          <FolderNameHeader>
            <ArrowBackIosRounded onClick={() => history.goBack()} />
            <div className="folder-name">{headerName}</div>
          </FolderNameHeader>
        );
      } else return t('gnb.drive');
    };

    if (
      pathname?.includes('my-contacts') ||
      pathname?.includes('recommended-contacts')
    )
      return <div className={classes.contactsTitle}>{t('gnb.contacts')}</div>;

    return (
      <GnbMenuMobileStyle>
        {!isSelect ? (
          <div className={classes.menuTitle}>{menuLabel()}</div>
        ) : (
          <div className="select-touch-appbar">
            <Close onClick={onSetIsSelect} />
            <CustomizedTypography
              variant="body1"
              fontWeight="500"
              color="primary"
            >
              {count} selected
            </CustomizedTypography>
          </div>
        )}
      </GnbMenuMobileStyle>
    );
  };

  return (
    <React.Fragment>
      {matches && (
        <MobileAsideMenu
          open={isOpenAsideMenu}
          onClose={() => setIsOpenAsideMenu(false)}
        />
      )}

      <AppBar elevation={0} position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          {!matches && (
            <div
              className={classes.logo}
              onClick={() => {
                insertLog(Enum_Action_Button_Enum.Button_Gnb_Logo);
                history.push('/');
              }}
            >
              <img src={CapaLogoBeta} alt="Logo" />
              <StagingChipPresenter />
            </div>
          )}
          {!matches ? <TabsMenu /> : null}
          <div className={classes.menuWrapper}>
            {matches ? GnbMobileExtraMenu() : gnbExtraMenu()}
          </div>
        </Toolbar>
      </AppBar>

      <FeedbackAndReport />
    </React.Fragment>
  );
};

export default GnbAppBarPresenter;
