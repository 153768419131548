import { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../index';
import {
  addUploadData,
  cancelUploadData,
  getUploadData,
  initUploadState,
  updateUploadData
} from './actions';
import { UploadData, UploadState } from './types';
import { useHistory } from 'react-router-dom';
import { sharedWithGroupsPathName } from '@routes/Common/types';

const useUpload = () => {
  const dispatch = useDispatch();
  const uploadState = useSelector((state: RootState) => state.upload);
  const filteredUploadState = uploadState.filter(
    (fileState) => fileState.state !== UploadState.OVERSIZE
  );
  const successUploadFiles = uploadState.filter(
    (fileState) => fileState.state === UploadState.FINISHED
  );
  const [uploadStatusOpen, setUploadStatusOpen] = useState<boolean>(false);
  const history = useHistory();
  const isSharedWithGroupPathName =
    history.location.pathname === sharedWithGroupsPathName;
  const fileSizeOnProgress = filteredUploadState.reduce((acc, cur) => {
    if (
      cur.state === UploadState.PENDING ||
      cur.state === UploadState.UPLOADING
    ) {
      return acc + (cur.file?.size ?? 0);
    } else {
      return acc;
    }
  }, 0);
  const isCompleted = useMemo(() => {
    const notFinished = [UploadState.PENDING, UploadState.UPLOADING];
    return filteredUploadState.every(
      (fileState) =>
        !notFinished.includes(fileState?.state ?? UploadState.PENDING)
    );
  }, [filteredUploadState]);

  useEffect(() => {
    if (!isCompleted && !isSharedWithGroupPathName) {
      setUploadStatusOpen(true);
    }
  }, [uploadState]);

  const onGetUploadData = useCallback(
    (payload: UploadData[]) => {
      dispatch(getUploadData(payload));
    },
    [dispatch]
  );

  const onAddUploadData = useCallback(
    (payload: UploadData[]) => {
      dispatch(addUploadData(payload));
    },
    [dispatch]
  );

  const onUpdateUploadData = useCallback(
    (payload: UploadData) => {
      dispatch(updateUploadData(payload));
    },
    [dispatch]
  );

  const onCancelUploadData = useCallback(
    (payload: UploadData) => {
      dispatch(cancelUploadData(payload));
    },
    [dispatch]
  );

  const onInitUploadData = useCallback(() => {
    dispatch(initUploadState());
  }, [dispatch]);

  return {
    uploadState,
    filteredUploadState,
    successUploadFiles,
    uploadStatusOpen,
    setUploadStatusOpen,
    isCompleted,
    fileSizeOnProgress,
    onGetUploadData,
    onAddUploadData,
    onUpdateUploadData,
    onCancelUploadData,
    onInitUploadData
  };
};

export default useUpload;
