import React, { useState } from 'react';
import { useDropzone, FileRejection } from 'react-dropzone';
import useUserActionLog, {
  Enum_Action_Button_Enum,
  CapaActivityType
} from '@hooks/useUserActionLog';
import { useSnackbar } from '@components/Provider';
import Loading from '@components/Loading';
import { acceptedExtensions } from './types';
import { DropzoneContainer, useStyles } from './styles';
import { useTranslation } from 'react-i18next';

interface Props {
  accept?: Array<string>;
  noClick?: boolean;
  onDropAccepted?: (files: any) => void;
  setIsDragDrop?: (isDragDrop: any) => void;
  noDrag?: boolean;
  noKeyboard?: boolean;
}

const DropzonePresenter: React.FC<Props> = ({
  accept,
  noClick,
  children,
  onDropAccepted,
  setIsDragDrop,
  noDrag,
  noKeyboard
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { getRootProps, getInputProps, isDragReject } = useDropzone({
    accept: accept ?? acceptedExtensions,
    noClick: noClick ?? false,
    multiple: true,
    noDragEventsBubbling: true,
    noDrag: noDrag ?? false,
    noKeyboard: noKeyboard ?? false,
    onDrop: (acceptedFiles, fileRejections) =>
      onDropHandle(acceptedFiles, fileRejections),
    onDragEnter: () => onDragEnterHandle(),
    onDragLeave: () => onDragLeaveHandle(),
    onDropAccepted: (file) => onDropAcceptedHandle(file)
  });
  const { onInsertUserActionLogOne } = useUserActionLog();
  useState<boolean>(false);

  const onDragEnterHandle = () => {
    if (setIsDragDrop) setIsDragDrop(true);
  };

  const onDragLeaveHandle = () => {
    if (setIsDragDrop) setIsDragDrop(false);
  };

  const onDropHandle = (
    acceptedFiles: Array<File>,
    fileRejections: Array<FileRejection>
  ) => {
    onInsertUserActionLogOne(
      Enum_Action_Button_Enum.Button_MyDrive_Upload,
      CapaActivityType.ButtonClick
    );

    if (acceptedFiles.length > 0 && fileRejections.length > 0) {
      enqueueSnackbar(t('snackbar.incorrect_format_upload_file_1'), {
        variant: 'success'
      });
    } else if (acceptedFiles.length < 1 && fileRejections.length > 0) {
      enqueueSnackbar(t('snackbar.incorrect_format_upload_file_2'), {
        variant: 'error'
      });
    }
  };

  const onDropAcceptedHandle = (files: Array<File>) => {
    if (setIsDragDrop) setIsDragDrop(false);
    if (onDropAccepted) onDropAccepted(files);
  };

  return (
    <>
      <Loading open={false} />
      <div className={classes.root}>
        <DropzoneContainer
          className={classes.rootParent}
          {...getRootProps({ isDragReject })}
        >
          <input {...getInputProps()} />
          {children}
        </DropzoneContainer>
      </div>
    </>
  );
};

export default DropzonePresenter;
