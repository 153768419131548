import React, { useState } from 'react';
import { useStyles } from './styles';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FiberManualRecord } from '@material-ui/icons';
import { SLIDE_DATA } from '@routes/Common/Dialog/Welcome/types';
import useAuth from '@hooks/useAuth';
import { Trans } from 'react-i18next';
import { CustomizedButton } from '@components/Customized';
import useCommon from '@hooks/Common/useCommon';
import { Close } from '@material-ui/icons';
import clsx from 'clsx';

interface Props {
  onClose?: () => void;
}

const Welcome = (props: Props) => {
  const { onClose } = props;
  const classes = useStyles();
  const { userState } = useAuth();
  const { t } = useTranslation();
  const [indexActive, setIndexActive] = useState(0);
  const { onSetShowHideWelcomeDialog, common } = useCommon();

  const onBack = (index: any) => {
    setIndexActive(index - 1);
  };

  const onNext = (index: any) => {
    if (indexActive === SLIDE_DATA.length - 1) {
      onSetShowHideWelcomeDialog({
        isShowWelcomeDialog: false
      });
      onClose && onClose();
      return;
    }
    setIndexActive(index + 1);
  };

  return (
    <Dialog
      classes={{
        paper: classes.paper
      }}
      open={common.isShowWelcomeDialog!}
      onClose={() => {
        onSetShowHideWelcomeDialog({
          isShowWelcomeDialog: false
        });
        onClose && onClose();
      }}
    >
      <DialogTitle classes={{ root: classes.rootTitle }} disableTypography>
        <Close
          className="close-icon"
          onClick={() => {
            onSetShowHideWelcomeDialog({
              isShowWelcomeDialog: false
            });
            onClose && onClose();
          }}
        />
      </DialogTitle>
      <DialogContent className={classes.csDialogContent}>
        {SLIDE_DATA.map((item, index) => {
          return index === indexActive ? (
            <div key={index}>
              <div className={classes.imageWrapper}>
                <img src={item.image} className={classes.customImage} />
              </div>
              <div className={classes.title}>{t(item.title)}</div>
              <div className={classes.content}>
                <Trans
                  i18nKey={t(item.content, {
                    username: userState?.data?.userName
                  })}
                />
              </div>
              <div className={classes.wrapperBtn}>
                <CustomizedButton
                  variant="outlined"
                  color="primary"
                  className={classes.customOutlinedButton}
                  disabled={indexActive === 0}
                  onClick={() => onBack(index)}
                >
                  {t('welcome.back')}
                </CustomizedButton>
                <CustomizedButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.customContainedButton}
                  onClick={() => onNext(index)}
                >
                  {t(
                    indexActive === SLIDE_DATA.length - 1
                      ? 'welcome.start'
                      : 'welcome.next'
                  )}
                </CustomizedButton>
              </div>
            </div>
          ) : null;
        })}
        {SLIDE_DATA.map((item, index) => {
          return (
            <FiberManualRecord
              key={index}
              className={clsx(
                classes.icon,
                index === indexActive
                  ? classes.activeColorIcon
                  : classes.colorIcon
              )}
              onClick={() => {
                setIndexActive(index);
              }}
            />
          );
        })}
      </DialogContent>
    </Dialog>
  );
};

export default Welcome;
