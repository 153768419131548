import React, { useEffect, useState } from 'react';
import {
  useTheme,
  useMediaQuery,
  MenuItem,
  Dialog,
  Popover,
  Typography,
  Box,
  IconButton
} from '@material-ui/core';
import { useStyles } from './styles';
import useMyDrive from '@hooks/Drive/MyDrive/useMyDrive';
import ChatMessages from '@routes/GroupRoom/ChatMessages';
import { CustomizedButton } from '@components/Customized';
import { checkShareTimes } from '@routes/Dashboard/Dialog/Share/utils';
import { useSnackbar } from '@components/Provider';
import { useHistory, useLocation } from 'react-router-dom';
import { CONNECT_UPDATE_TYPE, REDIRECT_TO } from '@routes/Common/types';
import { PopupMenu } from './styles';
import useSharedWithGroups from '@hooks/Drive/MyDrive/useSharedWithGroups';
import GroupNameMembersForm from '@routes/Dashboard/Dialog/Share/GroupNameMembers/GroupNameMembersForm';
import FolderFormGroupDialog from '@routes/Dashboard/Dialog/FolderForm/FolderFormGroup';
import { DEFAULT_FOLDER_NAME } from '@routes/Dashboard/types';
import { CapaErrorCode } from '@generated/Common/graphql';
import { CapaActivityType } from '@generated/Common/types';
import {
  RenameGroup,
  RequestDownloadPermission,
  SubscribeConnectUpdateData,
  useShareFilesToGroupMutation
} from '@generated/Drive/MyDrive/graphql';
import { useMutation, useSubscription } from '@apollo/client';
import LeaveGroupModal from '@routes/Dashboard/Dialog/Share/LeaveGroup/LeaveGroupModal';
import DetailFileViewer from '@routes/GroupRoom/FileViewer/DetailFileViewer';
import RegisterUserContainer from '@routes/Common/Dialog/RegisterUser/RegisterUserContainer';
import { getStorageItem, STORAGE_ACTION } from '@utils/storage';
import { ACCESS_LEVEL_VALUE } from '@routes/Dashboard/Dialog/Share/types';
import useAuth from '@hooks/useAuth';
import { useTranslation } from 'react-i18next';
import LeaveLastMemberGroupModal from '@routes/Dashboard/Dialog/LeaveLastMemberGroup/LeaveLastMemberGroupModal';
import { groupPathName, notFoundPathName } from '@routes/Common/types';
import DeleteGroupContainer from '@routes/Group/Dialog/DeleteGroup/DeleteGroupContainer';
import FeedbackAndReport from '@routes/Common/Dialog/FeedbackAndReport';
import useCommon from '@hooks/Common/useCommon';
import useUserActionLog, {
  Enum_Action_Button_Enum,
  Enum_Action_Page_Enum
} from '@hooks/useUserActionLog';
import OverflowTip from '@components/OverflowTooltips';
import { Dropzone } from '@routes/Common';
import {
  getDisplayedAvatarString,
  getGroupNameByUsernameOrEmail
} from '@utils/common';
import queryString from 'query-string';
import DownloadAppBanner from '@components/DownloadAppBanner/GroupRoom';
import { Tooltip } from '@material-ui/core';
import { chatBotEmail } from '@routes/Common/types';
import CapaAvatar from '@components/CapaAvatar';
import { ReactComponent as IBMChevronLeft } from '../../assets/icon/IBMChevronLeft.svg';
import { ReactComponent as IBMSetting } from '../../assets/icon/IBMSetting.svg';
import { ReactComponent as IBMEdit } from '../../assets/icon/IBMEdit.svg';
import { ReactComponent as IBMExit } from '../../assets/icon/IBMExit.svg';
import { ReactComponent as IBMDelete } from '../../assets/icon/IBMDelete.svg';
import { ReactComponent as IBMRequestQuote } from '../../assets/icon/IBMRequestQuote.svg';
import { ReactComponent as IBMWarningHex } from '../../assets/icon/IBMWarningHex.svg';
import { ReactComponent as IBMMultiUser } from '../../assets/icon/IBMMultiUser.svg';
import { ReactComponent as IBMFolder } from '../../assets/icon/IBMFolder.svg';
import { ReactComponent as IBMCrowdReport } from '../../assets/icon/IBMCrowdReport.svg';
import { ReactComponent as IBMOverflowMenuHorizontal } from '../../assets/icon/IBMOverflowMenuHorizontal.svg';
import clsx from 'clsx';
import LeftMenu from './LeftMenu/LeftMenu';
import RequestForDownloadContainer from '@routes/GroupRoom/Dialog/RequestForDownload';
import DownloadPermissionDialog from '@routes/Dashboard/Dialog/DownloadPermission/DownloadPermission';
import useGroupRoom from '@hooks/GroupRoom/useGroupRoom';

const GroupRoomContainer = () => {
  const theme = useTheme();
  const history: any = useHistory();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();
  const { onFetchGroupFilesData } = useGroupRoom();
  const [attachFileMenuAnchorEl, setAttachFileMenuAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const isOpenAttachFileMenu = Boolean(attachFileMenuAnchorEl);
  const { onFetchGroupData, groupDataState } = useSharedWithGroups();
  const { search } = useLocation();
  const query = queryString.parse(search);
  const groupId = new URLSearchParams(search).get('groupId');
  const [openDialog, setOpenDialog] = useState(false);
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [RenameGroupFile] = useMutation(RenameGroup);
  const [attachFileMenuAnchorElMobile, setAttachFileMenuAnchorElMobile] =
    useState(null);
  const isOpenAttachFileMenuMobile = Boolean(attachFileMenuAnchorElMobile);
  const [isShowHideRegisterDialog, setShowHideRegisterDialog] = useState(false);
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const openPopover = Boolean(anchorEl);
  const popoverId = openPopover ? 'popover-user-id' : undefined;

  const [isShowHideFolderDialog, setShowHideFolderDialog] =
    useState<boolean>(false);
  const [folderName, setFolderName] = useState<any>(DEFAULT_FOLDER_NAME);
  const [isOpenLeaveGroup, setOpenLeaveGroup] = useState(false);
  const [isOpenLeaveLastMemberGroup, setOpenLeaveLastMemberGroup] =
    useState(false);
  const usersOwners = groupDataState?.data?.accessLevel;
  const usersLastOwner = groupDataState?.data?.users.filter(
    (item: any) => item.accessLevel === ACCESS_LEVEL_VALUE.OWNER
  );
  const { userState } = useAuth();
  const [disbaledRenameSubmitButton, setDisbaledRenameSubmitButton] =
    useState(false);
  const {
    myDrive,
    onSetFirstSelectedDrawFile,
    onSetUploadFileFromComputerStatus,
    onSetShowHideRequestForDownload,
    onSetShowHideFileDetail
  } = useMyDrive();
  const { openFeedbackDialog } = useCommon();
  const { onInsertUserActionLogOne } = useUserActionLog();
  const [ShareFilesToGroup] = useShareFilesToGroupMutation();
  const [isChatBotGroup, setChatBotGroup] = useState<boolean>(false);
  const [requestDownloadPermission] = useMutation(RequestDownloadPermission);

  const onClose = () => {
    if (
      history.location.state === undefined ||
      history.location.state.prevLocation.includes(
        `?redirect=${REDIRECT_TO.GROUP_ROOM}`
      )
    ) {
      history.push(groupPathName);
      return;
    }
    history.go(-1);
  };

  const onClosePopup = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      onSetFirstSelectedDrawFile({ isFirstSelectedDrawFile: false });
      onSetShowHideFileDetail({
        detailDrive: ''
      });
      document.body.style.overflow = '';
    };
  }, []);

  useEffect(() => {
    refetchGroup();
  }, [history]);

  useEffect(() => {
    if (!groupId) return history.push(groupPathName);
    refetchGroup();
  }, [groupId]);

  const handleClickShowPopup = (event: React.MouseEvent<HTMLElement>) => {
    setAttachFileMenuAnchorEl(event.currentTarget);
  };

  const handleClickShowPopupMobile = (event: any) => {
    setAttachFileMenuAnchorElMobile(event.currentTarget);
  };

  const openDownloadAppBannerFromChatMessage = (event: any) => {
    setAttachFileMenuAnchorElMobile(event.currentTarget);
  };

  const onSubmitRenameGroup = () => {
    setDisbaledRenameSubmitButton(true);
    const payload: {
      groupId: string;
      toName: string;
    } = {
      groupId: groupDataState?.data?.id,
      toName: folderName
    };

    RenameGroupFile({ variables: payload })
      .then((res: any) => {
        const result = res?.data?.renameGroupName?.result;
        if (result === 'DUPLICATE_GROUP_NAME') {
          enqueueSnackbar(`Duplicate group name error`, {
            variant: 'error'
          });
          return;
        }
        if (result === CapaErrorCode.Success) {
          enqueueSnackbar(
            `"${groupDataState?.data?.groupName}" renamed to "${folderName}"`,
            {
              variant: 'success'
            }
          );
          setShowHideFolderDialog(false);
          onFetchGroupData({
            variables: {
              groupId: groupId
            }
          });
        }
      })
      .catch(() => {
        enqueueSnackbar(`Rename error`, {
          variant: 'error'
        });
      })
      .finally(() => setDisbaledRenameSubmitButton(false));
  };

  const removeGroupSuccess = () => {
    history.push({
      pathname: groupPathName,
      state: { prevLocation: window.location.href }
    });
  };

  const refetchGroup = () => {
    onFetchGroupData({
      variables: {
        groupId: groupId
      }
    });
  };

  useSubscription(SubscribeConnectUpdateData, {
    onSubscriptionData: (subscriptionData) => {
      const subscribeConnectUpdateData =
        subscriptionData?.subscriptionData?.data;
      if (subscribeConnectUpdateData) {
        switch (
          subscribeConnectUpdateData?.subscribeConnectUpdateData?.data?.type
        ) {
          case CONNECT_UPDATE_TYPE.GROUP_UPDATE:
          case CONNECT_UPDATE_TYPE.MEMBER_UPDATE: {
            refetchGroup();
            break;
          }
        }
      }
    }
  });

  useEffect(() => {
    onFetchGroupFilesData({
      variables: {
        groupId: groupId
      }
    });
    onInsertUserActionLogOne(
      Enum_Action_Page_Enum.Page_GroupRoom,
      CapaActivityType.PageVisit
    );
    const connectUserResultStorage = getStorageItem(
      STORAGE_ACTION.CONNECT_USER_RESULT
    )
      ? getStorageItem(STORAGE_ACTION.CONNECT_USER_RESULT)
      : '';

    if (
      connectUserResultStorage === CapaErrorCode.NotAllowedUser ||
      (query.isOpenInputUsername &&
        connectUserResultStorage === CapaErrorCode.NotAllowedUser)
    ) {
      setShowHideRegisterDialog(true);
    }
  }, []);

  useEffect(() => {
    if (
      groupDataState?.result === CapaErrorCode.GroupNotExists ||
      groupDataState?.result === CapaErrorCode.QueryError
    ) {
      history.push({
        pathname: notFoundPathName,
        state: { prevLocation: window.location.href }
      });
    }
    if (groupDataState?.data?.users?.length > 1) {
      setChatBotGroup(
        groupDataState?.data?.users?.some(
          (user: any) => user.email === chatBotEmail
        )
      );
    }
  }, [groupDataState]);

  const onUploadFileCallback = (filesIds: any) => {
    const payload: any = {
      groupId: groupId,
      connectFileIds: filesIds
    };
    onSetUploadFileFromComputerStatus({
      isUploadFileFromComputer: false
    });
    ShareFilesToGroup({ variables: payload }).then((res) => {
      const result = res?.data?.shareFilesToGroup?.result;
      if (result === CapaErrorCode.Success) {
        const successMessage =
          filesIds.length === 1
            ? t('snackbar.shared_file_complete')
            : t('snackbar.shared_files_complete');
        enqueueSnackbar(successMessage, {
          variant: 'success'
        });
        onFetchGroupData({
          variables: {
            groupId: groupId
          }
        });
        checkShareTimes(() => {
          openFeedbackDialog({
            isOpenFeedback: true,
            isFeedback: true
          });
        });
      } else {
        enqueueSnackbar(t('snackbar.shared_file_error'), {
          variant: 'success'
        });
      }
    });
  };

  const onRequestForDownload = () => {
    requestDownloadPermission({
      variables: {
        groupId: groupId,
        connectFileId: myDrive.activeFileIdInGroupRoom
      }
    }).then((res: any) => {
      if (res.data.requestDownloadPermission.result === CapaErrorCode.Success) {
        onSetShowHideRequestForDownload({
          isShowHideRequestForDownload: false
        });

        enqueueSnackbar(t('snackbar.you_sent_a_request_for_download'), {
          variant: 'success'
        });
      } else {
        onSetShowHideRequestForDownload({
          isShowHideRequestForDownload: false
        });
      }
    });
  };

  return (
    <>
      <div className={classes.rootTitle}>
        <IconButton
          className={classes.arrowBackIosOutlinedIcon}
          onClick={onClose}
        >
          <IBMChevronLeft />
        </IconButton>
        <Tooltip
          title={
            groupDataState?.data?.groupName
              ? groupDataState?.data?.groupName
              : getGroupNameByUsernameOrEmail(groupDataState?.data?.users, true)
          }
        >
          <div className={classes.customTitle}>
            {groupDataState && groupDataState?.data && (
              <OverflowTip value={groupDataState.data.groupName}>
                {groupDataState?.data?.groupName
                  ? groupDataState?.data?.groupName
                  : getGroupNameByUsernameOrEmail(groupDataState?.data?.users)}
              </OverflowTip>
            )}
          </div>
        </Tooltip>
        {isMobile ? (
          <div>
            <div className={classes.groupIcon}>
              <IconButton onClick={handleClickShowPopupMobile}>
                <IBMFolder />
              </IconButton>
              <IconButton onClick={handleClickShowPopupMobile}>
                <IBMCrowdReport />
              </IconButton>
              {isChatBotGroup ? null : (
                <IconButton onClick={handleClickShowPopup}>
                  <IBMOverflowMenuHorizontal />
                </IconButton>
              )}
            </div>
            <PopupMenu
              className={classes.PopUpFile}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              anchorEl={attachFileMenuAnchorElMobile}
              getContentAnchorEl={null}
              open={isOpenAttachFileMenuMobile}
              onClose={() => {
                setAttachFileMenuAnchorElMobile(null);
              }}
            >
              <MenuItem>
                <DownloadAppBanner
                  onClose={() => {
                    setAttachFileMenuAnchorElMobile(null);
                  }}
                />
              </MenuItem>
            </PopupMenu>
            <PopupMenu
              className={classes.PopUp}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              anchorEl={attachFileMenuAnchorEl}
              getContentAnchorEl={null}
              open={isOpenAttachFileMenu}
              onClose={() => {
                setAttachFileMenuAnchorEl(null);
              }}
            >
              <MenuItem
                onClick={() => {
                  onInsertUserActionLogOne(
                    Enum_Action_Button_Enum.Button_Members,
                    CapaActivityType.ButtonClick
                  );
                  setOpenDialog(true);
                  setAttachFileMenuAnchorEl(null);
                }}
              >
                <IBMMultiUser />
                {t('group_room.button_members')}
              </MenuItem>
              <div className={classes.divider} />
              {usersOwners && usersOwners === ACCESS_LEVEL_VALUE.OWNER ? (
                <MenuItem
                  onClick={() => {
                    setFolderName(groupDataState?.data?.groupName);
                    setShowHideFolderDialog(true);
                    setAttachFileMenuAnchorEl(null);
                  }}
                >
                  <IBMEdit />
                  {t('group_room.rename_group')}
                </MenuItem>
              ) : null}
              <MenuItem
                onClick={() => {
                  if (
                    usersLastOwner &&
                    usersLastOwner.length === 1 &&
                    usersLastOwner[0].email === userState?.data?.email
                  ) {
                    setOpenLeaveLastMemberGroup(true);
                  } else {
                    setOpenLeaveGroup(true);
                  }
                  setAttachFileMenuAnchorEl(null);
                }}
              >
                <IBMExit />
                {t('group_room.leave_group')}
              </MenuItem>
              {usersOwners && usersOwners === ACCESS_LEVEL_VALUE.OWNER ? (
                <MenuItem
                  onClick={() => {
                    setOpenRemoveDialog(true);
                    setAttachFileMenuAnchorEl(null);
                  }}
                >
                  <IBMDelete />
                  {t('group_room.delete_group')}
                </MenuItem>
              ) : null}
              <div className={classes.divider} />
              <MenuItem
                onClick={() => {
                  openFeedbackDialog({
                    isOpenFeedback: true,
                    isFeedback: true
                  });
                  setAttachFileMenuAnchorEl(null);
                }}
              >
                <IBMRequestQuote />
                {t('gnb.give_feedback')}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  openFeedbackDialog({
                    isOpenFeedback: true,
                    isFeedback: false
                  });
                  setAttachFileMenuAnchorEl(null);
                }}
              >
                <IBMWarningHex />
                {t('gnb.report_issues')}
              </MenuItem>
            </PopupMenu>
            <DeleteGroupContainer
              isShowHideDialog={openRemoveDialog}
              onCloseDialog={() => setOpenRemoveDialog(false)}
              removeGroupSuccess={removeGroupSuccess}
              groupId={groupDataState?.data?.id}
              groupName={
                groupDataState &&
                groupDataState?.data &&
                groupDataState.data.groupName
              }
            />

            <Dialog
              className={classes.dialogMembers}
              open={openDialog}
              onClick={(e) => {
                e.stopPropagation();
              }}
              onClose={() => setOpenDialog(false)}
            >
              <GroupNameMembersForm
                groupDataState={groupDataState}
                isGroupMembers={true}
                listGroup={groupDataState?.data?.users}
                onClose={onClosePopup}
                groupId={groupDataState?.data?.id}
                refetchGroup={refetchGroup}
              />
            </Dialog>
          </div>
        ) : (
          <Box className={classes.avatarGroupWrapper}>
            <Popover
              className={classes.customPopover}
              id={popoverId}
              open={openPopover}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
            >
              <div className={classes.csDialogContentPopup}>
                {groupDataState &&
                  groupDataState?.data &&
                  groupDataState?.data?.users
                    .slice(
                      4,
                      groupDataState &&
                        groupDataState?.data &&
                        groupDataState?.data?.users.length
                    )
                    .map((avatar: any, index: number) => (
                      <Typography key={index}>
                        <div className="name">
                          <CapaAvatar item={avatar} />
                          <div>
                            {getDisplayedAvatarString(
                              avatar?.connectUserName,
                              avatar?.email
                            )}
                          </div>
                        </div>
                      </Typography>
                    ))}
              </div>
            </Popover>
            {isChatBotGroup ? null : (
              <>
                {' '}
                <CustomizedButton
                  className={classes.memberButton}
                  aria-haspopup="true"
                  variant="contained"
                  size="small"
                  onClick={() => {
                    onInsertUserActionLogOne(
                      Enum_Action_Button_Enum.Button_Members,
                      CapaActivityType.ButtonClick
                    );
                    setOpenDialog(true);
                  }}
                >
                  {t('group_room.button_members')}
                </CustomizedButton>
                <Tooltip
                  title={t('tooltip.group_settings') || 'Group settings'}
                >
                  <IconButton
                    onClick={handleClickShowPopup}
                    className={classes.buttonSetting}
                  >
                    <IBMSetting />
                  </IconButton>
                </Tooltip>
              </>
            )}

            <PopupMenu
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              anchorEl={attachFileMenuAnchorEl}
              getContentAnchorEl={null}
              open={isOpenAttachFileMenu}
              onClose={() => {
                setAttachFileMenuAnchorEl(null);
              }}
            >
              <MenuItem
                onClick={() => {
                  onInsertUserActionLogOne(
                    Enum_Action_Button_Enum.Button_Members,
                    CapaActivityType.ButtonClick
                  );
                  setOpenDialog(true);
                  setAttachFileMenuAnchorEl(null);
                }}
              >
                <IBMMultiUser />
                {t('group_room.button_members')}
              </MenuItem>
              <div className={classes.divider} />
              {usersOwners && usersOwners === ACCESS_LEVEL_VALUE.OWNER ? (
                <MenuItem
                  onClick={() => {
                    setFolderName(groupDataState?.data?.groupName);
                    setShowHideFolderDialog(true);
                    setAttachFileMenuAnchorEl(null);
                  }}
                >
                  <IBMEdit />
                  {t('group_room.rename_group')}
                </MenuItem>
              ) : null}

              <MenuItem
                onClick={() => {
                  if (
                    usersLastOwner &&
                    usersLastOwner.length === 1 &&
                    usersLastOwner[0].email === userState?.data?.email
                  ) {
                    setOpenLeaveLastMemberGroup(true);
                  } else {
                    setOpenLeaveGroup(true);
                  }
                  setAttachFileMenuAnchorEl(null);
                }}
              >
                <IBMExit />
                {t('group_room.leave_group')}
              </MenuItem>
              {usersOwners && usersOwners === ACCESS_LEVEL_VALUE.OWNER ? (
                <MenuItem
                  onClick={() => {
                    setOpenRemoveDialog(true);
                    setAttachFileMenuAnchorEl(null);
                  }}
                >
                  <IBMDelete />
                  {t('group_room.delete_group')}
                </MenuItem>
              ) : null}
              <div className={classes.divider} />
              <MenuItem
                onClick={() => {
                  openFeedbackDialog({
                    isOpenFeedback: true,
                    isFeedback: true
                  });
                  setAttachFileMenuAnchorEl(null);
                }}
              >
                <IBMRequestQuote />
                {t('gnb.give_feedback')}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  openFeedbackDialog({
                    isOpenFeedback: true,
                    isFeedback: false
                  });
                  setAttachFileMenuAnchorEl(null);
                }}
              >
                <IBMWarningHex />
                {t('gnb.report_issues')}
              </MenuItem>
            </PopupMenu>

            <DeleteGroupContainer
              isShowHideDialog={openRemoveDialog}
              onCloseDialog={() => setOpenRemoveDialog(false)}
              removeGroupSuccess={removeGroupSuccess}
              groupId={groupDataState?.data?.id}
              groupName={
                groupDataState &&
                groupDataState?.data &&
                groupDataState.data.groupName
              }
            />

            <Dialog
              className={classes.dialogMembers}
              open={openDialog}
              onClick={(e) => {
                e.stopPropagation();
              }}
              onClose={() => setOpenDialog(false)}
            >
              <GroupNameMembersForm
                groupDataState={groupDataState}
                isGroupMembers={true}
                listGroup={groupDataState?.data?.users}
                onClose={onClosePopup}
                groupId={groupDataState?.data?.id}
                refetchGroup={refetchGroup}
              />
            </Dialog>
          </Box>
        )}
      </div>

      <LeaveGroupModal
        groupId={groupDataState?.data?.id}
        groupName={groupDataState}
        isOpen={isOpenLeaveGroup}
        onClose={() => {
          setOpenLeaveGroup(false);
        }}
      />

      <LeaveLastMemberGroupModal
        isOpen={isOpenLeaveLastMemberGroup}
        onClose={() => {
          setOpenLeaveLastMemberGroup(false);
        }}
      />
      {isShowHideFolderDialog ? (
        <FolderFormGroupDialog
          dialogInfo={{
            title: 'group_room.rename_group',
            submitButtonName: 'group_room.save_button'
          }}
          onClose={() => setShowHideFolderDialog(false)}
          folderNameValue={folderName}
          isOpen={isShowHideFolderDialog}
          setValue={setFolderName}
          onSubmit={onSubmitRenameGroup}
          disabledSubmitButton={disbaledRenameSubmitButton}
          setDisbaledRenameSubmitButton={() =>
            setDisbaledRenameSubmitButton(false)
          }
        />
      ) : null}
      <Dropzone
        noClick={true}
        noKeyboard={isChatBotGroup}
        locationInfo={{
          title: `${t('drag_drop_message.upload_to')}`,
          name: `${groupDataState?.data?.groupName}`
        }}
        callback={onUploadFileCallback}
        noDrag={isChatBotGroup}
      >
        <div className={classes.dialogContent}>
          {!isMobile && (
            <>
              <div className={classes.leftMenu}>
                <LeftMenu isChatBotGroup={isChatBotGroup} />
              </div>
              <div
                className={clsx(
                  classes.contentViewer,
                  myDrive.isShowGroupFiles && 'active'
                )}
              >
                <DetailFileViewer />
              </div>
            </>
          )}
          <ChatMessages
            isChatBotGroup={isChatBotGroup}
            openDownloadAppBanner={openDownloadAppBannerFromChatMessage}
          />
        </div>
      </Dropzone>
      {myDrive.isShowHideRequestForDownload && (
        <RequestForDownloadContainer onSend={() => onRequestForDownload()} />
      )}
      <RegisterUserContainer
        isOpen={isShowHideRegisterDialog}
        onClose={() => {
          setShowHideRegisterDialog(false);
        }}
      />
      <FeedbackAndReport />
      {isMobile && (
        <DownloadPermissionDialog groupData={groupDataState?.data} />
      )}
    </>
  );
};

export default GroupRoomContainer;
