export const WORKER_URL =
  'https://unpkg.com/pdfjs-dist@2.5.207/build/pdf.worker.js';

export const HOOPS_VIEWER_MESSAGE = {
  LOAD: {
    LOADED_3D_VIEWER: 'load:loaded-3d-viewer',
    LOADED_MODEL: 'load:loaded-model',
    LOADED_USER: 'load:user-info',
    LOAD_MODEL: 'load:load-model'
  },
  ANNOTATION: {
    ADD: 'annotation:add',
    UPDATE: 'annotation:update',
    DELETE: 'annotation:delete',
    OPEN: 'annotation:open'
  },
  COMMENT: {
    ADD: 'comment:add',
    UPDATE: 'comment:update',
    DELETE: 'comment:delete',
    OPEN: 'comment:open'
  }
};

export const HOOPS_VIEWER_IFRAME_ID = 'hoops-viewer-iframe';

export type CAPA3DPos = {
  x: number;
  y: number;
  z: number;
};

export type CAPAConnectAnnotationComment = {
  id: string;
  email: string;
  userName: string;
  comment: string;
  edited: boolean;
  createdAt: string;
  updatedAt: string;
};

export type CAPAConnectAnnotation = {
  id: string;
  groupId: string;
  chatRoomId: string;
  groupUserId: string;
  connectFileId: string;
  connectFileName: string;
  drawingFile: string;
  anchorPos: CAPA3DPos;
  handlePos: CAPA3DPos;
  nodeId: number;
  comments: CAPAConnectAnnotationComment[];
  hasNew: boolean;
  createdAt: string;
  updatedAt: string;
};
