import React from 'react';
import { useStyles } from './styles';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CustomizedButton } from '@components/Customized';

interface Props {
  isOpen?: boolean;
  onClose?: () => void;
  onSubmit?: () => void;
  userEmail?: string;
  listMembers?: any;
}

const ConfirmRemoveDialog = (props: Props) => {
  const classes = useStyles();
  const { isOpen, onClose, onSubmit, userEmail, listMembers } = props;
  const { t } = useTranslation();
  const userName = listMembers.filter((user: any) => user.email === userEmail);

  const onSubmitButton = () => {
    if (onSubmit) {
      onSubmit();
    }
  };

  const onCloseButton = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <>
      <Dialog
        classes={{
          paper: classes.paper
        }}
        open={isOpen!}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <DialogTitle classes={{ root: classes.rootTitle }} disableTypography>
          <span className={classes.firstTitle}>
            {t('confirm_remove_email_group_dialog.title', {
              userName:
                userName && userName.length > 0 && userName[0].connectUserName
                  ? userName[0].connectUserName
                  : userEmail
            })}
          </span>
        </DialogTitle>

        <DialogContent className={classes.csDialogContent}>
          <div className={classes.text}>
            {t('confirm_remove_email_group_dialog.desc', {
              userName:
                userName && userName.length > 0 && userName[0].connectUserName
                  ? userName[0].connectUserName
                  : userEmail
            })}
          </div>
        </DialogContent>

        <DialogActions classes={{ root: classes.action }}>
          <div className={classes.wrapperBtn}>
            <CustomizedButton
              type="submit"
              color="default"
              variant="contained"
              onClick={onCloseButton}
            >
              {t('confirm_remove_email_group_dialog.cancel_button')}
            </CustomizedButton>
          </div>
          <div className={classes.wrapperBtn}>
            <CustomizedButton
              type="submit"
              variant="contained"
              color="primary"
              onClick={onSubmitButton}
            >
              {t('confirm_remove_email_group_dialog.confirm_button')}
            </CustomizedButton>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmRemoveDialog;
