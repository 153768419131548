import { Enum_Action_Button_Enum } from '@hooks/useUserActionLog';

export const LINK_SETTINGS_VALUE = {
  VIEWED: 1,
  DOWNLOADED: 2
};

export const LINK_STATUS = {
  ACTIVE: 'ACTIVE',
  DEACTIVE: 'DEACTIVE'
};

export const CONNECT_USER_FILE_STATUS = {
  VIEWABLE: 'VIEWABLE',
  DOWNLOADABLE: 'DOWNLOADABLE'
};

export const LINK_SETTINGS_OPTIONS = [
  {
    value: CONNECT_USER_FILE_STATUS.VIEWABLE,
    name: 'share_modal.share_link.link_settings.viewed.name',
    log: Enum_Action_Button_Enum.Button_ShareModal_OnlyView,
    description: 'share_modal.share_link.link_settings.viewed.description'
  },
  {
    value: CONNECT_USER_FILE_STATUS.DOWNLOADABLE,
    name: 'share_modal.share_link.link_settings.downloaded.name',
    log: Enum_Action_Button_Enum.Button_ShareModal_AllowDownload,
    description: 'share_modal.share_link.link_settings.downloaded.description'
  }
];
