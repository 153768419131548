import { useLazyQuery } from '@apollo/client';
import { CheckRegisteredCapaService } from '@generated/Drive/MyDrive/graphql';
import React, { useEffect, useState } from 'react';
// import useAuth from '@hooks/useAuth';
import AppConfig from '@config/index';
import { CapaErrorCode, CapaServiceType } from '@generated/Common/graphql';
import { STORAGE_ACTION, getStorageItem } from '@utils/storage';

interface Props {
  isLandingPage?: boolean;
}

const CAPA_VALUE = {
  CAPA: 1,
  PARTNERS: 2
};

const ConnectGNB = (props: Props) => {
  const { isLandingPage } = props;
  // const classes = useStyles();
  // const [anchorEl, setAnchorEl] = useState(null);
  // const open = Boolean(anchorEl);
  // const id = open ? 'popoverId' : undefined;
  // const { t, i18n } = useTranslation();
  // const isKorean = i18n.language === 'ko';
  const [value] = useState(0);
  const [, { data: checkRegisteredCapaServiceData }] = useLazyQuery(
    CheckRegisteredCapaService,
    {
      fetchPolicy: 'no-cache'
    }
  );

  const accessToken = getStorageItem(STORAGE_ACTION.CONNECT_ACCESS_TOKEN);
  const refreshToken = getStorageItem(STORAGE_ACTION.CONNECT_REFRESH_TOKEN);

  const handleClosePopUp = () => {
    // setAnchorEl(null);
  };

  useEffect(() => {
    if (
      checkRegisteredCapaServiceData?.checkRegisteredCapaService?.result ===
      CapaErrorCode.Success
    ) {
      const checkRegisteredCapaServiceScopes =
        checkRegisteredCapaServiceData?.checkRegisteredCapaService?.data[0]
          .scopes;
      let url = '';
      switch (value) {
        case CAPA_VALUE.CAPA: {
          url = `${AppConfig.clientUrl}/auth${
            checkRegisteredCapaServiceScopes?.includes(
              CapaServiceType.Quotation
            )
              ? `?accessToken=${accessToken}&refreshToken=${refreshToken}`
              : ''
          }`;
          break;
        }
        case CAPA_VALUE.PARTNERS: {
          url = `${AppConfig.partnersUrl}/auth${
            checkRegisteredCapaServiceScopes?.includes(
              CapaServiceType.QuotationPartner
            )
              ? `?accessToken=${accessToken}&refreshToken=${refreshToken}`
              : ''
          }`;
          break;
        }
      }
      location.replace(url);
      handleClosePopUp();
    }
  }, [checkRegisteredCapaServiceData]);

  useEffect(() => {
    if (isLandingPage && value !== 0) {
      let url =
        value === CAPA_VALUE.CAPA
          ? `${AppConfig.clientUrl}/auth`
          : value === CAPA_VALUE.PARTNERS
          ? `${AppConfig.partnersUrl}/auth`
          : `${AppConfig.clientUrl}/auth`;
      location.replace(url);
      handleClosePopUp();
    }
  }, [value]);

  return (
    <>
      {/* <Button
        style={{
          padding: '6px 9px',
          height: '33px',
          marginRight: '20px',
          background: '#2B83FF',
          borderRadius: '8px',
          overflow: 'hidden'
        }}
        onClick={() => window.open('https://capa.ai')}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '5px',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <ArrowSvg />
          {!isKorean && (
            <CustomizedTypography variant="body2" color="secondary">
              move to
            </CustomizedTypography>
          )}
          <CapaSvg />
          {isKorean && (
            <CustomizedTypography variant="body2" color="secondary">
              바로가기
            </CustomizedTypography>
          )}
        </Box>
      </Button> */}
    </>
  );
};

export default ConnectGNB;
