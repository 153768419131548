import { colorSet } from '@components/Provider';
import { STORAGE_ACTION, getStorageItem, setStorageItem } from '@utils/storage';
const NUMBER_OF_SHARE_TIMES_TO_SHOW_FEEDBACK_MODAL = 3;

export const stringToColor = (string: string) => {
  if (!string) return colorSet.gray000;
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }

  return color;
};

export const stringAvatar = (name: string) => {
  let internalName = name.replace(/ +(?= )/g, '');
  let result: any = {
    children: ''
  };

  if (!internalName) return result;

  if (internalName.split(' ').length === 1) {
    result.children = `${internalName.split(' ')[0][0]}`.toUpperCase();
  } else {
    result.children = `${internalName.split(' ')[0][0]}${
      internalName && internalName.split(' ')[1][0]
    }`.toUpperCase();
  }

  return result;
};

export const checkShareTimes = (callback: any) => {
  const countTimesShare = getStorageItem(STORAGE_ACTION.COUNT_TIME_SHARE);
  const parseIntCount = countTimesShare ? parseInt(countTimesShare) : 0;
  setStorageItem(
    STORAGE_ACTION.COUNT_TIME_SHARE,
    !parseIntCount ? 1 : parseIntCount + 1
  );
  if (parseIntCount + 1 === NUMBER_OF_SHARE_TIMES_TO_SHOW_FEEDBACK_MODAL)
    callback();
};
