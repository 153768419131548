import { makeStyles } from '@material-ui/core';
import { colorSet } from '@components/Provider';

export const useStyles = makeStyles((theme) => ({
  rootTabs: {
    width: 52,
    borderRight: `1px solid ${colorSet.gray400}`,
    zIndex: 1,
    '& .MuiTabs-indicator': {
      left: '2px !important',
      background: colorSet.primary450,
      maxHeight: 40,
      borderRadius: 1,
      margin: '4px 0'
    },
    '& .MuiTabs-scrollable': {
      height: 'calc(100vh - 60px)',
      background: colorSet.gray000,
      overflow: 'hidden',
      zIndex: 2
    },
    '& .MuiTab-root:hover': {
      background: colorSet.gray200
    },
    '&.deactive .MuiTabs-indicator': {
      display: 'none'
    }
  },
  rootTabsItem: {
    width: 'auto',
    minWidth: 'auto !important',
    '& svg': {
      width: 24,
      height: 24
    },
    '& .MuiIconButton-root': {
      padding: 4
    },
    '&.Mui-selected': {
      '&.deactive svg path': {
        fill: colorSet.gray600
      },
      '& svg path': {
        fill: colorSet.primary450
      }
    },
    '& .MuiTab-wrapper': {
      '& svg path': {
        fill: colorSet.gray600
      }
    }
  },
  rootTabsHideFile: {
    width: 52,
    '& .MuiTabs-scrollable': {
      height: 'calc(100vh - 60px)',
      background: colorSet.gray000,
      overflow: 'hidden',
      zIndex: 2
    },
    borderRight: `0 !important`,
    '& .MuiTabs-indicator': {
      left: '0px !important',
      background: 'transparent'
    },
    '& .MuiTab-root:hover': {
      background: colorSet.gray200
    }
  },
  root: {
    display: 'flex',
    '& .file-group': {
      transition: '.3s ease',
      marginLeft: '-336px',
      '&.show': {
        marginLeft: 0
      }
    }
  },

  menuLeft: {
    display: 'flex',
    flexDirection: 'column'
  }
}));
