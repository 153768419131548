import React, { useMemo } from 'react';
import { fileUploadApi } from '@api/index';
import { UploadState, UploadData } from '@modules/upload';
import useUpload from '@modules/upload/useUpload';
import useAuth from '@hooks/useAuth';
import useUserActionLog, {
  Enum_Action_Button_Enum,
  CapaActivityType
} from '@hooks/useUserActionLog';
import { CustomizedTypography } from '@components/Customized';
import { colorSet } from '@components/Provider';
import StatusIcon from './StatusIcon';
import { Box, makeStyles } from '@material-ui/core';
import useMyDrive from '@hooks/Drive/MyDrive/useMyDrive';

export interface UploadFileListEntryProps {
  fileState: UploadData;
}

const UploadFileListEntry: React.FC<UploadFileListEntryProps> = ({
  fileState
}) => {
  const { onRefetchConnectUser } = useAuth();
  const { myDrive, onRefetchConnectMyDrive } = useMyDrive();
  const { container, fileNameBox, statusIconBox } = useStyles();
  const { onUpdateUploadData } = useUpload();
  const { onInsertUserActionLogOne } = useUserActionLog();

  const progress = useMemo(() => {
    const loaded = fileState.loaded ?? 0;
    const total = fileState.total ?? 0;
    return loaded + total === 0 ? 0 : (loaded / total) * 100;
  }, [fileState.loaded, fileState.total]);

  const retry = async () => {
    onInsertUserActionLogOne(
      Enum_Action_Button_Enum.Button_MyDrive_UploadStatus_Retry,
      CapaActivityType.ButtonClick
    );

    onUpdateUploadData({
      ...fileState,
      state: UploadState.PENDING
    });

    await fileUploadApi([fileState], myDrive.path!);
    await onRefetchConnectUser();
    await onRefetchConnectMyDrive();
  };

  return (
    <Box className={container}>
      {/* 파일명 */}
      <Box className={fileNameBox}>
        <CustomizedTypography variant="body2">
          {fileState.file?.name}
        </CustomizedTypography>
      </Box>

      {/* 상태표시 아이콘 */}
      <Box className={statusIconBox}>
        <StatusIcon
          uploadState={fileState.state || UploadState.ERROR}
          progress={progress}
          retry={retry}
        />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '14px 24px',
    borderBottom: `1px solid ${colorSet.gray400}`
  },
  fileNameBox: {
    display: 'flex',
    alignItems: 'center',
    width: 200,
    '& p': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineHeight: '20px'
    }
  },
  statusIconBox: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));

export default UploadFileListEntry;
