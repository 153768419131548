import React from 'react';
import { Dialog, useMediaQuery, useTheme } from '@material-ui/core';
import { useStyles } from '@routes/Dashboard/Dialog/Share/styles';
import CreateGroupPresenter from '@routes/Group/Dialog/CreateGroup/CreateGroupPresenter/CreateGroupPresenter';
import { CREATE_GROUP_SCREEN } from '@routes/Group/Dialog/types';
import useGroup from '@hooks/Group/useGroup';
import UploadFiles from '@routes/Common/Dialog/UploadFiles/UploadFiles';
import GroupSuccess from '@routes/Dashboard/Dialog/Share/GroupSuccess/GroupSuccess';
import { ArrowBackIosOutlined } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useStyles as useUploadFileStyles } from '@routes/Common/Dialog/UploadFiles/styles';
import clsx from 'clsx';
import AddMembers from '@routes/Group/Dialog/CreateGroup/AddMembers/AddMembers';
import useMyDrive from '@hooks/Drive/MyDrive/useMyDrive';
import UploadFilesContainer from '@routes/Common/Dialog/UploadFiles/Mobile/UploadFilesContainer';
import UploadFilesFrom from '@routes/Common/Dialog/UploadFiles/Mobile/UploadFilesFrom';
import OverflowTip from '@components/OverflowTooltips';
import { ADD_FILES_FROM_VALUE } from '@routes/Common/Dialog/UploadFiles/Mobile/types';
import useUserActionLog, {
  CapaActivityType,
  Enum_Action_Button_Enum
} from '@hooks/useUserActionLog';

interface CreateGroupProps {
  isShowHideCreateGroupDialog: boolean;
  onCloseDialog?: () => void;
}

const CreateGroupContainerDialog = (props: CreateGroupProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const uploadFileClasses = useUploadFileStyles();
  const { isShowHideCreateGroupDialog, onCloseDialog } = props;
  const { group, onSetCreateGroupScreen } = useGroup();
  const { onSetAddFilesFromType, onSetSelectedFilesUploadInChat } =
    useMyDrive();
  const { myDrive, onSetValueGroupNameDialog } = useMyDrive();
  const { onInsertUserActionLogOne } = useUserActionLog();

  let createGroupComponent = (
    <CreateGroupPresenter onCloseDialog={onCloseDialog} />
  );

  const onAddFile = () => {
    onSetCreateGroupScreen({
      createGroupScreen: CREATE_GROUP_SCREEN.ADD_MEMBERS_TO
    });
  };

  const onBackToCreateGroupDialog = () => {
    onInsertUserActionLogOne(
      Enum_Action_Button_Enum.Button_UploadFileModal_Back,
      CapaActivityType.ButtonClick
    );
    onSetCreateGroupScreen({
      createGroupScreen: CREATE_GROUP_SCREEN.CREATE_GROUP
    });
  };

  const closeDialog = () => {
    onSetValueGroupNameDialog({
      valueGroupNameDialog: ''
    });
    onSetSelectedFilesUploadInChat({ selectedFilesUploadInChat: [] });
    onSetAddFilesFromType({ addFilesFromType: ADD_FILES_FROM_VALUE.EMPTY });
    if (onCloseDialog) onCloseDialog();
  };

  const onBackToAddFiles = () => {
    onInsertUserActionLogOne(
      Enum_Action_Button_Enum.Button_CreateGroup_AddMembersModal_Cancel,
      CapaActivityType.ButtonClick
    );
    if (matches) {
      onSetCreateGroupScreen({
        createGroupScreen: CREATE_GROUP_SCREEN.ADD_FILES_CONTAINER_MOBILE
      });
      return;
    }
    onSetCreateGroupScreen({
      createGroupScreen: CREATE_GROUP_SCREEN.ADD_FILES
    });
  };

  switch (group.createGroupScreen) {
    case CREATE_GROUP_SCREEN.CREATE_GROUP: {
      createGroupComponent = (
        <CreateGroupPresenter onCloseDialog={onCloseDialog} />
      );
      break;
    }
    case CREATE_GROUP_SCREEN.ADD_FILES: {
      createGroupComponent = (
        <UploadFiles
          onSubmit={onAddFile}
          onClose={closeDialog}
          submitText={t('upload_files_modal.next_button')}
          isShowHideUploadFilesDialog={
            group.createGroupScreen === CREATE_GROUP_SCREEN.ADD_FILES
          }
          allowEmptyFile
          dialogTitleNode={
            matches ? (
              <ArrowBackIosOutlined
                className={uploadFileClasses.arrowBackIosOutlinedIcon}
                onClick={() => {
                  onSetCreateGroupScreen({
                    createGroupScreen: CREATE_GROUP_SCREEN.CREATE_GROUP
                  });
                }}
              />
            ) : (
              <>
                <div
                  className={clsx(
                    uploadFileClasses.arrowBackIosOutlinedWrapper,
                    uploadFileClasses.pointerCursor
                  )}
                  onClick={onBackToCreateGroupDialog}
                >
                  <ArrowBackIosOutlined
                    className={uploadFileClasses.arrowBackIosOutlinedIcon}
                  />
                </div>
                <OverflowTip
                  value={myDrive.valueGroupNameDialog || false}
                  longText={
                    <>
                      {t('file_list.add_files_to')}
                      <span className={uploadFileClasses.groupNameText}>
                        {myDrive.valueGroupNameDialog}
                      </span>
                    </>
                  }
                ></OverflowTip>
              </>
            )
          }
        />
      );
      break;
    }
    case CREATE_GROUP_SCREEN.ADD_FILES_CONTAINER_MOBILE: {
      createGroupComponent = (
        <UploadFilesContainer
          isShowHideUploadFilesMobileDialog={
            group.createGroupScreen ===
            CREATE_GROUP_SCREEN.ADD_FILES_CONTAINER_MOBILE
          }
          onClose={closeDialog}
          isAllowEmptyFile
        />
      );
      break;
    }
    case CREATE_GROUP_SCREEN.ADD_FILES_FROM_MOBILE: {
      createGroupComponent = (
        <UploadFilesFrom
          isShowHideUploadFilesFromDialog={
            group.createGroupScreen ===
            CREATE_GROUP_SCREEN.ADD_FILES_FROM_MOBILE
          }
          onSubmit={() => {
            onSetCreateGroupScreen({
              createGroupScreen: CREATE_GROUP_SCREEN.ADD_MEMBERS_TO
            });
          }}
          onClose={closeDialog}
        />
      );
      break;
    }
    case CREATE_GROUP_SCREEN.ADD_MEMBERS_TO: {
      createGroupComponent = (
        <AddMembers onClose={closeDialog} onBack={onBackToAddFiles} />
      );
      break;
    }
    case CREATE_GROUP_SCREEN.SUCCESSFULLY_SHARED: {
      createGroupComponent = <GroupSuccess onCloseDialog={onCloseDialog} />;
      break;
    }
  }

  return (
    <Dialog
      classes={{
        paper:
          group.createGroupScreen === CREATE_GROUP_SCREEN.SUCCESSFULLY_SHARED
            ? classes.successFullyPaper
            : group.createGroupScreen === CREATE_GROUP_SCREEN.ADD_MEMBERS_TO
            ? classes.addMembersToWrapper
            : classes.paper,
        root:
          group.createGroupScreen === CREATE_GROUP_SCREEN.ADD_FILES
            ? classes.rootDialog
            : ''
      }}
      open={isShowHideCreateGroupDialog}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {createGroupComponent}
    </Dialog>
  );
};

export default CreateGroupContainerDialog;
