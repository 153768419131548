import React, { useEffect } from 'react';
import SignUpPresenter from './SignUpPresenter';
import { LayoutContainer } from './styles';
import DownloadAppBanner from '@components/DownloadAppBanner';
import useUserActionLog, {
  CapaActivityType,
  Enum_Action_Page_Enum
} from '@hooks/useUserActionLog';

const SignUpContainer: React.FC = () => {
  const { onInsertUserActionLogOne } = useUserActionLog();

  useEffect(() => {
    onInsertUserActionLogOne(
      Enum_Action_Page_Enum.Page_SignUp,
      CapaActivityType.PageVisit
    );
  }, []);

  return (
    <LayoutContainer>
      <SignUpPresenter />
      <DownloadAppBanner />
    </LayoutContainer>
  );
};

export default SignUpContainer;
