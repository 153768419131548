import React, { useLayoutEffect } from 'react';
import clsx from 'clsx';
import { useStylesLayout } from './useStyles';
import useMyDrive from '@hooks/Drive/MyDrive/useMyDrive';
import { MuiBackdrop } from '@routes/Common/Layout/useStyles';
import { useHistory } from 'react-router-dom';
import { groupPathName } from '@routes/Common/types';
import { useMediaQuery, useTheme } from '@material-ui/core';

interface Props {
  contentShift?: boolean;
  asideDrawer?: React.ReactNode;
  sideBarRight?: React.ReactNode;
  matches?: boolean;
  extraBtn?: React.ReactNode;
  gnbAppBar?: React.ReactNode;
  header?: React.ReactNode;
  checkedFiles?: any;
  isShare?: boolean;
}

const LayoutDashboardPresenter: React.FC<Props> = ({
  contentShift,
  asideDrawer,
  sideBarRight,
  gnbAppBar,
  children,
  header,
  checkedFiles,
  isShare
}) => {
  const classes = useStylesLayout();
  const { myDrive } = useMyDrive();
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);
  return (
    <div
      className={clsx(
        classes.root,
        myDrive && myDrive.isShowHideMenuCreate ? classes.rootMobile : null
      )}
    >
      {myDrive.isShowHideMenuCreate ? <MuiBackdrop /> : null}
      {gnbAppBar && gnbAppBar}
      {asideDrawer}
      <main
        className={clsx(
          classes.main,
          {
            [classes.contentShift]: contentShift
          },
          history.location.pathname === groupPathName ? classes.customNav : ''
        )}
      >
        {history.location.pathname === groupPathName && isMobile ? null : (
          <div className={classes.contentHeader} />
        )}
        <div className={classes.content}>
          {header}
          <div className={classes.mainContent}>
            <div className={classes.mainContentLeft}>{children}</div>
            {sideBarRight}
          </div>
        </div>
      </main>
    </div>
  );
};

export default LayoutDashboardPresenter;
