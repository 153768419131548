import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { CustomizedButton } from '@components/Customized';
import useMyContacts from '@hooks/Contacts/useMyContacts';
import * as Apollo from '@apollo/client';
import { DeleteMyContactUser } from '@generated/Contacts/MyContacts/graphql';
import { useSnackbar } from '@components/Provider';
import { CapaErrorCode } from '@generated/Common/graphql';
import { useStyles } from './styles';
import clsx from 'clsx';

interface Props {
  onClose?: () => void;
  onCallback?: () => void;
}

const DeleteContactDialog: React.FC<Props> = ({ onClose, onCallback }) => {
  const classes = useStyles();
  const { myContacts, onSetStateContact } = useMyContacts();
  const [deleteMyContact] = Apollo.useMutation(DeleteMyContactUser);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const onSubmit = (isShowSnackbar = true) => {
    let emails = myContacts.dataDeleteDialog?.map(
      (contact: any) => contact.email
    );
    if (emails.length > 0)
      deleteMyContact({
        variables: {
          contactUserEmails: emails
        }
      }).then((res: any) => {
        if (
          res.data.removeConnectContactUser.result === CapaErrorCode.Success
        ) {
          onSetStateContact({
            dataDeleteDialog: []
          });
          onClose && onClose();
          onCallback && onCallback();
          if (isShowSnackbar)
            enqueueSnackbar(t('snackbar.contact_user_deleted_success'), {
              variant: 'success'
            });
        } else {
          if (isShowSnackbar)
            enqueueSnackbar(t('snackbar.contact_user_error'), {
              variant: 'error'
            });
        }
      });
  };
  return (
    <Dialog
      open={myContacts.dataDeleteDialog.length > 0 ?? false}
      disableEscapeKeyDown={true}
      classes={{ paper: clsx(classes.paper, 'delete') }}
      onClose={() => {
        onSetStateContact({
          dataDeleteDialog: []
        });
      }}
    >
      <DialogTitle classes={{ root: classes.titleRoot }} disableTypography>
        <div className={classes.Title}>
          <Trans i18nKey={'contact.delete.title'} />
        </div>
      </DialogTitle>
      <DialogContent>
        <div className={classes.ContentMain}>
          <Trans i18nKey={'contact.delete.message'} />
        </div>
      </DialogContent>
      <DialogActions classes={{ root: classes.action }}>
        <div className={clsx(classes.wrapperBtn, classes.Full, 'grid-col-2')}>
          <CustomizedButton
            variant="contained"
            color="default"
            onClick={() =>
              onSetStateContact({
                dataDeleteDialog: []
              })
            }
          >
            <Trans i18nKey={'contact.delete.cancel'} />
          </CustomizedButton>
          <CustomizedButton
            type="submit"
            variant="contained"
            color="error"
            onClick={() => onSubmit()}
          >
            <Trans i18nKey={'contact.delete.submit'} />
          </CustomizedButton>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteContactDialog;
