import React, { useState } from 'react';
import { CustomizedTypography } from '@components/Customized';
import { Tab, Tabs } from '@material-ui/core';
import { useStyles } from './styles';
import { URL_PARAM, URL_PARAM_TYPE } from '@routes/Dashboard/types';
import {
  myDrivePathName,
  sharedWithGroupsPathName
} from '@routes/Common/types';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

interface Props {
  urlParam?: string;
}

const TabPanelControl = (props: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { urlParam } = props;
  const [value, setValue] = useState(
    urlParam
      ? urlParam === URL_PARAM.urlMyDrive
        ? URL_PARAM_TYPE.urlMyDriveType
        : urlParam === URL_PARAM.urlShared
        ? URL_PARAM_TYPE.urlSharedType
        : urlParam === URL_PARAM.urlPublic
        ? URL_PARAM_TYPE.urlPublicType
        : URL_PARAM_TYPE.urlMyDriveType
      : URL_PARAM_TYPE.urlMyDriveType
  );

  const history = useHistory();
  return (
    <>
      <div className={classes.container}>
        <Tabs
          className={classes.tabs}
          value={value}
          variant="fullWidth"
          onChange={(event, value) => {
            if (value === URL_PARAM_TYPE.urlMyDriveType) {
              history.push(myDrivePathName);
            } else if (value === URL_PARAM_TYPE.urlSharedType) {
              history.push(sharedWithGroupsPathName);
            }
            setValue(value);
          }}
        >
          <Tab
            label={
              <CustomizedTypography
                variant="body2"
                className={clsx(
                  classes.title,
                  value === URL_PARAM_TYPE.urlMyDriveType ? classes.active : ''
                )}
              >
                {t('my_drive.header')}
              </CustomizedTypography>
            }
          />
          <Tab
            label={
              <CustomizedTypography
                variant="body2"
                className={clsx(
                  classes.title,
                  value === URL_PARAM_TYPE.urlSharedType ? classes.active : ''
                )}
              >
                {t('my_drive.shared')}
              </CustomizedTypography>
            }
          />
        </Tabs>
      </div>
    </>
  );
};

export default TabPanelControl;
