import { colorSet } from '@components/Provider';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  viewerPdf: {
    width: '100%',
    height: '100%',
    display: 'block',
    position: 'relative',
    cursorEvent: 'none',
    margin: 'auto',
    overflow: 'auto',
    '& .current-page-input': {
      width: '4rem'
    },
    '& .pd-button': {
      padding: '0px 2px'
    },
    '& .ml-auto': {
      marginLeft: 'auto'
    },
    '& .rpv-default-layout__sidebar': {
      display: 'none'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: 20
    },
    '& .rpv-core__doc-error': {
      display: 'none'
    },
    '& .rpv-core__viewer': {
      border: 'none !important'
    },
    '& div::-webkit-scrollbar': {
      width: 4
    },
    '& div::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '& div::-webkit-scrollbar-thumb': {
      backgroundColor: colorSet.gray600,
      borderRadius: 8
    },
    '& button': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    '& svg': {
      color: colorSet.gray000
    }
  },
  coreViewer: {
    border: '1px solid rgba(0, 0, 0, 0.3)',
    display: 'flex',
    height: '100%',
    position: 'relative'
  },
  pdfWrapper: {
    alignItems: 'center',
    backgroundColor: 'rgb(0 0 0 / 79%)',
    borderRadius: '5px',
    bottom: '35px',
    display: 'flex',
    left: '50%',
    padding: '7px',
    position: 'absolute',
    transform: 'translate(-50%, 0)',
    zIndex: 1
  },
  viewerWrapper: {
    flex: 1,
    overflow: 'hidden'
  }
}));
