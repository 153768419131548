import React from 'react';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import { NoDataIcon } from '@assets';
import { CustomizedButton } from '@components/Customized';

interface Props {
  title?: string;
  subTitle?: any;
  isSharedWithGroup?: boolean;
  clickCreateGroup?: any;
}

const NoData = (props: Props) => {
  const { title, subTitle, isSharedWithGroup, clickCreateGroup } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const onSubmit = () => {
    if (clickCreateGroup) clickCreateGroup();
  };

  return (
    <div className={classes.noDataWrapper}>
      <div className={classes.noDataBox}>
        <img src={NoDataIcon} alt="" />
        <div className={classes.noDataTitle}>{title}</div>
        <div className={classes.noDataSubTitle}>{subTitle && subTitle[0]}</div>
        <div className={classes.noDataSubTitle}>{subTitle && subTitle[1]}</div>
        {isSharedWithGroup ? (
          <CustomizedButton
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submitButton}
            onClick={onSubmit}
          >
            {t('shared_with_groups.no_data.create_group')}
          </CustomizedButton>
        ) : null}
      </div>
    </div>
  );
};

export default NoData;
