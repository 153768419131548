import { CapaConnect } from '@assets';
import { CustomizedButton, CustomizedTypography } from '@components/Customized';
import { colorSet, useSnackbar } from '@components/Provider';
import {
  CapaServiceType,
  CapaWithdrawClientReasonType,
  useWithdrawQuotationUserMutation
} from '@generated/graphql';
import useAuth from '@hooks/useAuth';
import {
  Box,
  Dialog,
  Divider,
  FormControl,
  FormControlLabel,
  List,
  ListItem,
  Radio,
  RadioGroup,
  TextField,
  makeStyles,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { ACCOUNT_INFO_TYPE } from '@routes/MyPage/Account/types';
import { STORAGE_ACTION, clearStorageAll } from '@utils/storage';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import AccountInfoListEntry from './AccountInfoListEntry';
import { AccountPageStyles, PageContainer } from './styles';

const useStyles = makeStyles({
  modal: {
    width: '360px',
    maxHeight: '80vh',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    padding: '20px',
    '& .radioList': {
      padding: '0px 10px',
      '& span': {
        fontSize: '14px'
      }
    },
    '& .confirmNotRecover': {
      padding: '0px 5px',
      '& span': {
        color: 'red'
      }
    },
    '& .capa-connect': {
      display: 'flex',
      flexDirection: 'column',
      '& li': {
        padding: '3x 15px'
      }
    },
    '& .contentItem4': {
      padding: '3px 20px'
    }
  }
});

const AccountPage = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const isRef = useRef<HTMLInputElement>(null);
  const [withDraw, setWithdraw] = useState('');
  const [checker, setChecker] = useState({ 1: false, 2: false, 3: false });
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { basicInfo, logInInfo } = AccountPageStyles();
  const accessToken = localStorage.getItem(STORAGE_ACTION.CONNECT_ACCESS_TOKEN);
  const decodedJwt: JwtPayload | undefined = accessToken
    ? jwtDecode(accessToken)
    : undefined;
  const { onRefetchConnectUser, userState, onInitAuthState } = useAuth();
  const [mutation] = useWithdrawQuotationUserMutation({
    onCompleted: () => {
      onInitAuthState();
      clearStorageAll();
      history.push('/login');
    },
    onError: () => {
      enqueueSnackbar('탈퇴에 실패하였습니다. 다시 시도해주세요.', {
        variant: 'error'
      });
    }
  });

  const handleCheck = (num: 1 | 2 | 3) => {
    setChecker({ ...checker, [num]: !checker[num] });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target) {
      setWithdraw(event.target.value);
    }
  };

  const handleClose = () => {
    setWithdraw('');
    setChecker({ 1: false, 2: false, 3: false });
    setOpen(false);
  };

  const handleWithdrawal = () => {
    const reason = isRef.current?.value;
    if (withDraw === CapaWithdrawClientReasonType.Etc && reason?.length === 0) {
      alert(t('deleteAccount.etcReason'));
    } else {
      if (
        window.confirm(t('deleteAccount.confirmDeleteModal.subTitle')) &&
        userState.data
      ) {
        mutation({
          variables: {
            input: {
              email: userState.data?.email,
              withdrawReason: {
                serviceType: CapaServiceType.Connect,
                reason: withDraw as CapaWithdrawClientReasonType,
                detailedReason: '',
                etcReason: reason
              }
            }
          }
        });
      }
    }
  };

  useEffect(() => {
    onRefetchConnectUser();
  }, []);

  return (
    <>
      <PageContainer>
        {isMobile ? null : (
          <CustomizedTypography
            variant="h2"
            color="textPrimary"
            fontWeight="700"
            className="accountPageTitle"
          >
            {t('account_page.account_setting')}
          </CustomizedTypography>
        )}

        <Box>
          <div className={logInInfo}>{t('account_page.log_in_info')}</div>
          <AccountInfoListEntry
            title={ACCOUNT_INFO_TYPE.EMAIL}
            value={decodedJwt ? (decodedJwt?.aud as string) : ''}
            key="email"
          />
          <AccountInfoListEntry
            title={ACCOUNT_INFO_TYPE.PASSWORD}
            value="*********"
            key="password"
          />

          <div className={basicInfo}>{t('account_page.basic_info')}</div>
          <AccountInfoListEntry
            title={ACCOUNT_INFO_TYPE.USERNAME}
            value={userState?.data?.userName ?? ''}
            key="username"
          />
          <CustomizedButton
            variant="text"
            style={{ color: colorSet.gray400 }}
            onClick={() => setOpen(true)}
          >
            {t('deleteAccount.title')}
          </CustomizedButton>
        </Box>
      </PageContainer>
      <Dialog onClose={handleClose} open={open}>
        <Box className={classes.modal}>
          <CustomizedTypography variant="body1" align="center" fontWeight="500">
            {t('deleteAccount.headerTitle')}
          </CustomizedTypography>
          <Box className="radioList">
            <FormControl>
              <RadioGroup value={withDraw} onChange={handleChange}>
                <FormControlLabel
                  value={CapaWithdrawClientReasonType.UselessAlarm}
                  control={<Radio color="primary" />}
                  label={t('deleteAccount.reasons.getALotNotify')}
                />
                <FormControlLabel
                  value={
                    CapaWithdrawClientReasonType.PersonalInformationLeakage
                  }
                  control={<Radio color="primary" />}
                  label={t('deleteAccount.reasons.leakPersonalInformation')}
                />
                <FormControlLabel
                  value={CapaWithdrawClientReasonType.AteamServiceProblem}
                  control={<Radio color="primary" />}
                  label={t('deleteAccount.reasons.unsatisfiedService')}
                />
                <FormControlLabel
                  value={CapaWithdrawClientReasonType.PrivacyIssue}
                  control={<Radio color="primary" />}
                  label={t('deleteAccount.reasons.personalReason')}
                />
                <FormControlLabel
                  value={CapaWithdrawClientReasonType.Etc}
                  control={<Radio color="primary" />}
                  label={t('deleteAccount.reasons.other')}
                />
              </RadioGroup>
            </FormControl>
          </Box>
          {withDraw === CapaWithdrawClientReasonType.Etc && (
            <TextField
              variant="outlined"
              placeholder={t('deleteAccount.reasons.otherTextInputPlaceholder')}
              multiline
              rows={5}
              inputRef={isRef}
            />
          )}
          <Divider />
          <CustomizedTypography
            variant="body2"
            className="confirmNotRecover"
            gutterBottoms={10}
            fontWeight="500"
          >
            {t(['deleteAccount.confirmNotRecover.title'])}
            <span> {t('deleteAccount.confirmNotRecover.highlightTitle')}</span>
          </CustomizedTypography>
          <CustomizedTypography
            variant="caption"
            color="textSecondary"
            className="confirmNotRecover"
            gutterBottoms={10}
          >
            {t('deleteAccount.confirmNotRecover.content')}
          </CustomizedTypography>

          <Box className="capa-connect">
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 10
              }}
            >
              <img src={CapaConnect} />
              <CustomizedTypography fontWeight="500">
                {t('deleteAccount.services.connect.title')}
              </CustomizedTypography>
            </Box>
            <List>
              <ListItem>
                <CustomizedTypography variant="caption">
                  {t('deleteAccount.services.connect.content1')}
                </CustomizedTypography>
              </ListItem>
              <ListItem>
                <CustomizedTypography variant="caption">
                  {t('deleteAccount.services.connect.content2')}
                </CustomizedTypography>
              </ListItem>
              <ListItem>
                <CustomizedTypography variant="caption">
                  {t('deleteAccount.services.connect.content3')}
                </CustomizedTypography>
              </ListItem>
            </List>
          </Box>

          <CustomizedButton
            size="small"
            onClick={() => handleCheck(1)}
            style={{ minHeight: '32px' }}
            variant={checker[1] ? 'text' : 'contained'}
          >
            {checker[1]
              ? t('deleteAccount.confirmButton.confirmed')
              : t('deleteAccount.confirmButton.notConfirm')}
          </CustomizedButton>

          <Divider />

          <CustomizedTypography
            variant="body2"
            className="confirmNotRecover"
            gutterBottoms={10}
            fontWeight="500"
          >
            {t(['deleteAccount.confirmNotUserAnyMore.title'])}
            <span>
              {t('deleteAccount.confirmNotUserAnyMore.highlightTitle')}
            </span>
          </CustomizedTypography>
          <CustomizedTypography
            variant="caption"
            color="textSecondary"
            className="confirmNotRecover"
            gutterBottoms={10}
          >
            {t('deleteAccount.confirmNotUserAnyMore.content')}
          </CustomizedTypography>

          <CustomizedTypography
            variant="caption"
            color="textSecondary"
            className="contentItem4"
            gutterBottoms={10}
          >
            {t('deleteAccount.confirmReceipt.contentItem4')}
          </CustomizedTypography>

          <CustomizedButton
            size="small"
            onClick={() => handleCheck(2)}
            style={{ minHeight: '32px' }}
            variant={checker[2] ? 'text' : 'contained'}
          >
            {checker[2]
              ? t('deleteAccount.confirmButton.confirmed')
              : t('deleteAccount.confirmButton.notConfirm')}
          </CustomizedButton>

          <Divider />
          <CustomizedTypography
            variant="body2"
            className="confirmNotRecover"
            gutterBottoms={10}
            fontWeight="500"
          >
            {t(['deleteAccount.reconfirm.title'])}
          </CustomizedTypography>
          <CustomizedButton
            size="small"
            onClick={() => handleCheck(3)}
            style={{ minHeight: '32px' }}
            variant={checker[3] ? 'text' : 'contained'}
          >
            {checker[3]
              ? t('deleteAccount.confirmButton.confirmed')
              : t('deleteAccount.confirmButton.notConfirm')}
          </CustomizedButton>

          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '10px',
              paddingBottom: '10px'
            }}
          >
            <CustomizedButton
              onClick={handleClose}
              style={{ backgroundColor: 'gray' }}
              variant="contained"
              fullWidth
            >
              {t('deleteAccount.confirmDeleteModal.cancel')}
            </CustomizedButton>
            <CustomizedButton
              onClick={handleWithdrawal}
              disabled={
                !checker[1] ||
                !checker[2] ||
                !checker[3] ||
                withDraw.length === 0
              }
              variant="contained"
              color="error"
              fullWidth
            >
              {t('deleteAccount.confirmDeleteModal.ok')}
            </CustomizedButton>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default AccountPage;
