import styled from 'styled-components';
import { colorSet } from '@components/Provider';
import { createStyles, makeStyles } from '@material-ui/core';

export const NavWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(5, 1fr);
  .MuiTouchRipple-root {
    left: -20px;
    right: -20px;
  }
`;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface NavItemWrapperProps {
  isSelected: boolean;
  isHomePage: boolean;
}

export const NavItemWrapper = styled.div<NavItemWrapperProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: ${(props) => (props.isHomePage ? colorSet.gray2000 : 'unset')};
  color: ${(props) => {
    if (props.isHomePage) return colorSet.gray600;

    if (!props.isHomePage) {
      return props.isSelected ? colorSet.gray800 : colorSet.gray600;
    }
  }};

  .MuiTypography-colorTextPrimary {
    color: unset;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const NavItemIcon = styled.div`
  svg {
    font-size: 24px;
  }

  .MuiBadge-badge {
    background: ${colorSet.pink300};
    top: 4px;
  }
`;

export const useStylesBottomNav = makeStyles((theme) =>
  createStyles({
    root: {
      position: 'fixed',
      width: '100%',
      bottom: 0,
      height: 65,
      borderTop: `1px solid ${colorSet.gray400}`,
      backgroundColor: colorSet.gray000,
      borderRadius: 0,
      zIndex: 100,
      display: 'none',
      '& .upload-btn': {
        display: 'flex',
        alignItems: 'center',
        width: 'calc(100% / 4)',
        height: '100%',
        textAlign: 'center'
      },

      [theme.breakpoints.down('xs')]: {
        display: 'flex'
      }
    }
  })
);
