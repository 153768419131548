import { makeStyles } from '@material-ui/core';
import { colorSet } from '@components/Provider';

export const useStyles = makeStyles((theme) => ({
  paper: {
    width: 476,
    borderRadius: 16,
    [theme.breakpoints.down('xs')]: {
      width: 343,
      margin: 0
    }
  },
  content: {
    padding: 32,
    [theme.breakpoints.down('xs')]: {
      padding: '24px 16px 32px 16px'
    },
    '&:first-child': {
      paddingTop: '32px !important',
      [theme.breakpoints.down('xs')]: {
        paddingTop: '24px !important'
      }
    },
    '& .title': {
      fontWeight: 700,
      fontSize: 20,
      lineHeight: '160%',
      letterSpacing: '-0.1px',
      marginBottom: 24,
      color: colorSet.gray900,
      [theme.breakpoints.down('xs')]: {
        marginBottom: 20
      }
    },
    '& .content': {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '150%',
      letterSpacing: '-0.1px',
      color: colorSet.gray700,
      marginBottom: 4,
      [theme.breakpoints.down('xs')]: {
        marginBottom: 0
      }
    }
  },
  action: {
    padding: '0px 32px 32px 32px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px 16px 20px 16px'
    }
  },
  wrapperBtn: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    '& button': {
      textTransform: 'none',
      height: '48px !important',
      width: '50%',
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '150%',
      letterSpacing: '-0.1px',
      '&:first-child': {
        marginRight: 8
      }
    },
    '& .cancel-button': {
      color: colorSet.gray900
    }
  }
}));
