import React, { useEffect, useState } from 'react';
import { ArrowDropUp, ArrowDropDown } from '@material-ui/icons';
import { Switch, useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useAuth from '@hooks/useAuth';
import { useMutation } from '@apollo/client';
import { UpdateConnectUser } from '@generated/Drive/MyDrive/graphql';
import { CapaErrorCode } from '@generated/Common/graphql';

interface Props {
  data: any;
  isCollapse: boolean;
  setCollapse?: () => void;
  title?: string;
  subTitle?: string;
  boxMarginTop?: number;
}

const NotificationBox = (props: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { data, isCollapse, title, subTitle, setCollapse, boxMarginTop } =
    props;
  const [internalData, setInternalData] = useState([]);
  const { onRefetchConnectUser, userState } = useAuth();
  const [updateConnectUser] = useMutation(UpdateConnectUser);

  const changeStatus = (item: any, checked: boolean) => {
    updateConnectUser({
      variables: {
        notificationFilterType: item?.type,
        notificationFilterValue: checked
      }
    }).then((res: any) => {
      if (res.data.updateConnectUser.result === CapaErrorCode.Success)
        onRefetchConnectUser();
    });
  };

  useEffect(() => {
    if (data?.length === 0 || !userState) return;

    setInternalData(
      data?.map((item: any) => {
        return {
          ...item,
          // @ts-ignore
          status: userState?.data?.notificationFilter[item.filterType]
        };
      })
    );
  }, [data, userState]);

  return (
    <>
      <div
        className="notification-title-box"
        style={{ marginTop: boxMarginTop }}
      >
        <div>
          <div className="notification-title">{title}</div>
          <div className="notification-sub-title">{subTitle}</div>
        </div>
        {isCollapse ? (
          <ArrowDropDown
            className="cursor-pointer"
            onClick={() => {
              if (setCollapse) setCollapse();
            }}
          />
        ) : (
          <ArrowDropUp
            className="cursor-pointer"
            onClick={() => {
              if (setCollapse) setCollapse();
            }}
          />
        )}
      </div>
      {internalData.length > 0 && !isCollapse
        ? internalData.map((item: any) => {
            return item?.status !== undefined ? (
              <div className="notification-type-box">
                <div className="type">
                  <div className="name">{t(item.name)}</div>
                  <div className="status-box">
                    <div className="status">
                      {item.status
                        ? t('notification_settings.on')
                        : t('notification_settings.off')}
                    </div>
                    <Switch
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        changeStatus(item, event.target.checked);
                      }}
                      defaultChecked={item.status}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="primary"
                    />
                  </div>
                </div>
                <div className={'description-box'}>
                  {isMobile ? (
                    t(item.description)
                      .split(',')
                      .map((desc: string) => {
                        return <div className="description-mobile">{desc}</div>;
                      })
                  ) : (
                    <div className="description">{t(item.description)}</div>
                  )}
                </div>
              </div>
            ) : null;
          })
        : null}
    </>
  );
};

export default NotificationBox;
