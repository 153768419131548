import { createAction } from 'typesafe-actions';
import { GroupState } from './types';

export const INIT_GROUP_STATE = 'INIT_GROUP_STATE';
export const initGroupState = createAction(INIT_GROUP_STATE)();

export const SET_CREATE_GROUP_SCREEN = 'SET_CREATE_GROUP_SCREEN';
export const setCreateGroupScreen = createAction(
  SET_CREATE_GROUP_SCREEN
)<GroupState>();

export const SET_SELECTED_GROUP = 'SET_SELECTED_GROUP';
export const setSelectedGroup = createAction(SET_SELECTED_GROUP)<GroupState>();
