import { createStyles, makeStyles } from '@material-ui/core';
import { colorSet } from '@components/Provider';
import {
  contentHeaderHeight,
  contentMobileHeaderHeight
} from '@routes/Common/types';

export const useStylesViewer = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      background: `${colorSet.gray000}`,
      [theme.breakpoints.down('xs')]: {
        marginTop: 35
      }
    },
    header: {
      borderBottom: `1px solid ${colorSet.gray400}`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      minHeight: contentHeaderHeight,
      height: contentHeaderHeight,
      backgroundColor: colorSet.gray000,
      '& .MuiSvgIcon-root': {
        fontSize: 24,
        marginLeft: 'auto',
        cursor: 'pointer'
      },
      '& img': {
        cursor: 'pointer',
        height: 24,
        width: 196
      },
      padding: '0px 24px',
      '& .get-app-icon': {
        cursor: 'pointer',
        padding: 9,
        marginRight: 6
      },
      [theme.breakpoints.down('xs')]: {
        height: contentMobileHeaderHeight,
        minHeight: contentMobileHeaderHeight,
        padding: '0px 16px',
        '& .get-app-icon': {
          display: 'none'
        },
        '& img': {
          cursor: 'pointer',
          height: 18,
          width: 147
        }
      }
    },
    menuWrapper: {
      color: colorSet.gray900,
      display: 'flex',
      alignItems: 'center'
    },
    main: {},
    subTitle: {
      padding: '14px 24px',
      height: 78,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& .get-app-icon': {
        cursor: 'pointer',
        padding: 9,
        marginRight: 6,
        display: 'none'
      },
      [theme.breakpoints.down('xs')]: {
        height: 66,
        padding: '12px 16px',
        '& .get-app-icon': {
          display: 'block'
        }
      }
    },
    iframe: {
      '& iframe': {
        height: 'calc(100vh - 60px)'
      }
    },
    thumbnailList: {
      display: 'flex',
      flexDirection: 'row',
      maxWidth: '100%',
      whiteSpace: 'nowrap',
      overflowX: 'hidden',
      height: 60,
      borderTop: `1px solid ${colorSet.gray200}`
    },
    thumbnail: {
      width: 60,
      height: 60,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      borderRight: `1px solid ${colorSet.gray200}`,
      '& img': {
        width: 58,
        height: 58
      },
      '&.selected': {
        '& img': {
          border: `1px solid ${colorSet.gray900}`
        }
      }
    }
  })
);
