import { ActionType } from 'typesafe-actions';
import * as actions from './actions';

export enum UploadState {
  PENDING = 'PENDING',
  UPLOADING = 'UPLOADING',
  FINISHED = 'FINISHED',
  ERROR = 'ERROR',
  STOPPED = 'STOPPED',
  TIMEOUT = 'TIMEOUT',
  OVERSIZE = 'OVERSIZE'
}

export interface UploadData {
  cancelSource?: any;
  file?: File;
  loaded?: number;
  total?: number;
  isError?: boolean;
  state?: UploadState;
  dataKey: string;
  cancelToken?: any;
}

export const initialUploadState: UploadData[] = [];

export type UploadAction = ActionType<typeof actions>;
