import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  viewerWrapper: {
    height: 'calc(100vh - 60px)',
    overflow: 'hidden'
  },
  viewerWrapperImage: {
    width: '100%',
    height: 'calc(100vh - 60px)',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center'
  },
  imageViewerItem: {
    textAlign: 'center',
    width: 'calc(100% - 100px)',
    height: 'calc(100vh - 53px)',
    overflow: 'hidden',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center'
    }
  },
  wrapperClass: {
    overflow: 'inherit'
  },
  contentClass: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center'
  },
  imageViewer: {
    margin: 'auto',
    '& img': {
      maxWidth: '100%',
      maxHeight: 'calc(100vh - 130px)'
    }
  }
}));
