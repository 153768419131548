import React, { useEffect, useRef } from 'react';
import { DialogContent, DialogActions, useMediaQuery } from '@material-ui/core';
import { CustomizedButton } from '@components/Customized';
import { useStyles } from './styles';
import { Close } from '@material-ui/icons';
import useMyDrive from '@hooks/Drive/MyDrive/useMyDrive';
import { SHARE_FILES_TO_VALUE } from '@routes/Dashboard/Dialog/Share/types';
import { theme, useSnackbar } from '@components/Provider';
import { groupRoomPathName } from '@routes/Common/types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { LANGUAGE_VALUE } from '@routes/Common/ChangeLanguage/types';
import useUserActionLog, {
  CapaActivityType,
  Enum_Action_Button_Enum,
  Enum_Action_Page_Enum
} from '@hooks/useUserActionLog';
import i18n from '@i18n/index';
import { IBMCheckMarkOutline } from '@assets';

interface Props {
  onCloseDialog?: () => void;
  content?: React.ReactNode;
}

const GroupSuccess = (props: Props) => {
  const { t } = useTranslation();
  const { onCloseDialog, content } = props;
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const {
    onSetShowHideShareDialog,
    onSetValueGroupNameDialog,
    onSetShareFilesToType,
    myDrive
  } = useMyDrive();
  const myRefGroupSuccess = useRef();
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const languageValue = i18n.language;
  const { onInsertUserActionLogOne } = useUserActionLog();

  const onClose = () => {
    onInsertUserActionLogOne(
      Enum_Action_Button_Enum.Button_Share_ConfirmationModal_GotoGroup,
      CapaActivityType.ButtonClick
    );
    history.push({
      pathname: `${groupRoomPathName}`,
      search: `?chatroomId=${myDrive.chatRoomIdGroupName}&groupId=${myDrive.idGroupName}&firstItem=true`,
      state: { prevLocation: window.location.href }
    });
    setTimeout(() => {
      onSetShowHideShareDialog({ isShowHideShareDialog: false });
      onSetShareFilesToType({ shareFilesToType: SHARE_FILES_TO_VALUE.EMPTY });
      onSetValueGroupNameDialog({
        valueGroupNameDialog: ''
      });
    }, 500);
    if (onCloseDialog) onCloseDialog();
  };
  useEffect(() => {
    onInsertUserActionLogOne(
      Enum_Action_Page_Enum.Page_Share_ConfirmationModal,
      CapaActivityType.PageVisit
    );

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);
  const handleClickOutside = (e: any) => {
    // @ts-ignore
    if (!myRefGroupSuccess.current.contains(e.target)) {
      setTimeout(() => {
        onSetShowHideShareDialog({ isShowHideShareDialog: false });
        onSetShareFilesToType({ shareFilesToType: SHARE_FILES_TO_VALUE.EMPTY });
        onSetValueGroupNameDialog({
          valueGroupNameDialog: ''
        });
      }, 500);
      if (onCloseDialog) onCloseDialog();
    }
  };
  return (
    <>
      <DialogContent
        ref={myRefGroupSuccess}
        className={classes.csDialogContent}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div>
          {matches && (
            <div className={classes.rootClose}>
              <Close onClick={() => onCloseDialog && onCloseDialog()} />
            </div>
          )}
          <div className={classes.rootTitle}>
            <div className="icon-wrapper">
              <div className="done-icon-wrapper">
                <img src={IBMCheckMarkOutline} alt="" />
              </div>
            </div>
          </div>
          <div className="name-email">
            {content ? (
              content
            ) : (
              <>
                {languageValue === LANGUAGE_VALUE.KO ? (
                  <div className="text-bold-ko">
                    {myDrive.valueGroupNameDialog}
                  </div>
                ) : null}
                {t('share_confirmation_modal.successfully_shared')}
                {languageValue === LANGUAGE_VALUE.EN ? (
                  <div className="text-bold-en">
                    {myDrive.valueGroupNameDialog}
                  </div>
                ) : null}
              </>
            )}
          </div>
        </div>
        <DialogActions classes={{ root: classes.action }}>
          <div className={classes.wrapperBtn}>
            <CopyToClipboard
              text={`${window.location.origin}${groupRoomPathName}?chatroomId=${myDrive.chatRoomIdGroupName}&groupId=${myDrive.idGroupName}`}
              onCopy={() => {
                onInsertUserActionLogOne(
                  Enum_Action_Button_Enum.Button_Share_ConfirmationModal_CopyLink,
                  CapaActivityType.ButtonClick
                );
                enqueueSnackbar(t('snackbar.link_copied'), {
                  variant: 'error'
                });
              }}
            >
              <CustomizedButton variant="contained" color="default">
                {t('share_confirmation_modal.copy_link_button')}
              </CustomizedButton>
            </CopyToClipboard>
            <CustomizedButton
              type="submit"
              variant="contained"
              color="primary"
              onClick={onClose}
            >
              {t('share_confirmation_modal.go_to_group_button')}
            </CustomizedButton>
          </div>
        </DialogActions>
      </DialogContent>
    </>
  );
};

export default GroupSuccess;
