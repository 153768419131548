import { Box, makeStyles } from '@material-ui/core';
import { colorSet } from '@components/Provider';
import styled from 'styled-components';

export const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: 800,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: 0,
      height: '100%',
      maxHeight: '100%',
      maxWidth: '100%',
      borderRadius: 0
    },
    maxHeight: 640,
    borderRadius: '16px'
  },
  rootDialog: {
    zIndex: '10000 !important' as unknown as 10000
  },
  rootTitle: {
    padding: '24px 32px',
    display: 'flex',
    fontSize: '25px',
    fontWeight: 'bold',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: '12px 16px',
      border: `1px solid ${colorSet.gray300}`,
      display: 'inline-block',
      textAlign: 'center',
      fontSize: 16
    }
  },
  arrowBackIosOutlinedIcon: {
    width: 14,
    color: colorSet.gray100,
    [theme.breakpoints.down('xs')]: {
      color: colorSet.gray900,
      fontWeight: 500,
      position: 'absolute',
      left: 16,
      top: 10
    }
  },
  closeIcon: {
    color: colorSet.gray900,
    fontWeight: 500,
    position: 'absolute',
    left: 16,
    top: 10
  },
  customTitle: {
    [theme.breakpoints.down('xs')]: {
      color: colorSet.gray900,
      fontWeight: 500
    }
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  cancelBtn: {
    fontSize: 24,
    marginLeft: 'auto',
    cursor: 'pointer'
  },
  content: {
    padding: '0px 24px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px 12px'
    }
  },
  action: {
    padding: '24px 32px 24px 32px'
  },
  wrapperBtn: {
    display: 'flex',
    '& button': {
      textTransform: 'none',
      marginLeft: 'auto',
      '&:first-child': {
        marginRight: 8
      }
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      '& button': {
        width: 'calc(100% / 2)'
      },
      '& button + button': {
        marginLeft: 6
      }
    }
  },
  csDialogText: {
    color: colorSet.gray700,
    fontSize: '16px'
  },
  csDialogContent: {
    padding: '0 0 24px 0',
    marginLeft: 32,
    marginRight: 32,
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: {
      padding: '24px 0',
      margin: 0
    }
  },
  csTabWrapper: {
    fontSize: 16
  },
  selectedTab: {
    fontWeight: 700
  },
  cloudUploadIconWrapper: {
    backgroundColor: colorSet.primary500,
    borderRadius: 12,
    padding: 10,
    marginRight: 16
  },
  cloudUploadAddIcon: {
    color: colorSet.gray100
  },
  activeIconWrapper: {
    backgroundColor: `${colorSet.primary200} !important`
  },
  searchInputWrapper: {
    boxShadow: 'none',
    backgroundColor: colorSet.gray100,
    borderRadius: 8,
    padding: '6px 15px',
    marginBottom: 24,
    marginTop: 24,
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0
    }
  },
  searchIconWrapper: {
    display: 'inline-flex',
    verticalAlign: 'middle'
  },
  searchCloseIconWrapper: {
    display: 'inline-flex',
    verticalAlign: 'middle',
    position: 'absolute',
    right: 16,
    top: 10
  },
  searchInputBase: {
    width: '92%',
    paddingLeft: 10,
    paddingRight: 12
  },
  customSearchIcon: {
    cursor: 'pointer',
    color: colorSet.gray500
  },
  searchResultWrapper: {
    maxHeight: 300,
    minHeight: 300,
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: 4
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: colorSet.gray600,
      borderRadius: 8
    },
    [theme.breakpoints.down('xs')]: {
      maxHeight: 'calc(100vh - 216px)',
      overflow: 'auto'
    }
  },
  checkIconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 10,
    marginLeft: 'auto',
    '& .check-icon': {
      color: colorSet.primary500
    }
  },
  searchResult: {
    display: 'inline-flex',
    width: '100%',
    padding: '12px 20px',
    border: `1px solid ${colorSet.gray300}`,
    borderRadius: 12,
    marginTop: 8
  },
  noResultText: {
    display: 'flex',
    justifyContent: 'center',
    color: colorSet.gray900,
    marginTop: 30
  },
  activeSearchResult: {
    backgroundColor: colorSet.primary100
  },
  pointerCursor: {
    cursor: 'pointer'
  },
  csTabs: {
    paddingLeft: 16,
    borderBottom: `1px solid ${colorSet.gray200}`,
    '& button': {
      textTransform: 'none',
      minWidth: 56
    },
    '& .MuiTabs-indicator': {
      backgroundColor: colorSet.gray900,
      width: 104
    }
  },
  noDocuments: {
    fontSize: 14
  },
  computerWrapper: {
    cursor: 'pointer',
    marginTop: 24,
    minHeight: 344,
    maxHeight: 344,
    border: `2px dashed ${colorSet.gray400}`,
    borderRadius: 16,
    backgroundColor: colorSet.gray100,
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: 4
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: colorSet.gray600,
      borderRadius: 8
    }
  },
  hasDrag: {
    backgroundColor: colorSet.gray200
  },
  hasFile: {
    padding: 20
  },
  noFile: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  noteAddWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 12
  },
  noteAddIcon: {
    display: 'inline-flex',
    padding: 10,
    backgroundColor: colorSet.gray300,
    borderRadius: 12
  },
  addFileText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 25,
    fontWeight: 500,
    color: colorSet.gray900,
    marginBottom: 12
  },
  dragFileText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 14,
    color: colorSet.gray700
  },
  fileWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: 54,
    border: `1px solid ${colorSet.gray300}`,
    borderRadius: 12,
    paddingLeft: 16,
    paddingRight: 16,
    fontSize: 14,
    justifyContent: 'space-between',
    marginBottom: 8,
    '&:last-child': {
      marginBottom: 0
    },
    backgroundColor: colorSet.gray000
  },
  fileSizeText: {
    marginLeft: 4,
    color: colorSet.gray700
  },
  closeWrapper: {
    width: 32,
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colorSet.gray100,
    borderRadius: 100
  },
  arrowBackIosOutlinedWrapper: {
    backgroundColor: colorSet.primary500,
    borderRadius: 12,
    padding: '9px 15px',
    marginRight: 16
  },
  groupNameText: {
    fontWeight: 'normal',
    marginLeft: 5
  }
}));

export const MuiDiv = styled.div`
  &.connect-empty {
    align-items: center;
    margin-top: 100px;
    ${({ theme }) => `
      ${theme.breakpoints.down('xs')} {
        margin-top: 53px;
      }
    `}
    img {
      height: 144px;
      width: 144px;
      margin-bottom: 36px;
    }
  }
  .sub-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    & .cursor-pointer {
      cursor: pointer;
    }

    .MuiBreadcrumbs-li {
      font-size: 20px;
      font-weight: 500;
      color: ${colorSet.gray900};
      &:nth-child(1) {
        font-weight: bold;
        .MuiLink-underlineHover:hover {
          text-decoration: none !important;
        }
      }
      &:last-child {
        pointer-events: none;
        cursor: auto;
      }
    }
    .label-group {
      cursor: pointer;
      display: flex;
      align-items: center;
      .MuiSvgIcon-root {
        color: ${colorSet.gray900};
        margin-right: 4px;
      }
    }
    ${({ theme }) => `
      ${theme.breakpoints.down('xs')} {
        margin-bottom: 24px;
        padding: 0px 16px;
      }
    `}
  }
`;

export const MuiBoxTable = styled(Box)`
  ${({ theme }) => `
    ${theme.breakpoints.down('xs')} {
      padding-bottom: 57px;
    }
  `}
  .table-header {
    position: sticky;
    background: ${colorSet.gray000};
    top: 0;
    border-bottom: 1px solid ${colorSet.gray200};
    padding: 0 8px 12px 0;
    display: flex;
    align-items: center;
    color: ${colorSet.gray700};

    .table-header-th-one {
      width: calc(100% - 120px - 130px);
    }
    .table-header-th-two {
      width: 130px;
    }
    .table-header-th-three {
      width: 120px;
    }
  }
`;

interface IMuiBoxTableItemProps {
  isChecked?: boolean;
}

export const MuiBoxTableItem = styled.div.attrs(
  (props: IMuiBoxTableItemProps) => props
)`
  background-color: ${(props) =>
    props.isChecked ? `${colorSet.primary100}` : ''};
  border-bottom: 1px solid ${colorSet.gray200};
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
  .table-item-td-one {
    padding-left: 15px;
    width: calc(100% - 120px - 138px);
    display: flex;
    min-height: 60px;
    align-items: center;
    .customized-checkbox {
      margin-right: 10px;
    }
    .MuiTypography-body2 {
      margin-left: 16px;
    }
    .folder-icon {
      color: ${colorSet.gray600};
      font-size: 36.5px;
    }
    & .icon-svg {
      display: flex;
      min-width: 36px;
      justify-content: center;
      & img {
        width: 27px;
        height: auto;
      }
    }
    & .icon-svg-file-rar {
      display: flex;
      min-width: 36px;
      justify-content: center;
      & img {
        width: 24px;
        height: auto;
        object-fit: scale-down;
      }
    }
  }
  .custom-span {
    margin-left: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: ${colorSet.gray900};
    font-size: 14px;
    font-family: Noto Sans KR, sans-serif;
    font-weight: normal;
    line-height: 143%;
    letter-spacing: -0.1px;
  }
  .table-item-td-two {
    display: flex;
    align-items: center;
    min-height: 60px;
    width: 130px;
  }
  .table-item-td-three {
    display: flex;
    align-items: center;
    min-height: 60px;
    width: 120px;
  }
`;
