import React from 'react';
import {
  DOCUMENT_TYPES_FILE,
  DRAWING_FILE_TYPES,
  IMAGE_TYPES,
  PDF_TYPE,
  VIDEO_TYPES
} from '@routes/Common/Dropzone/types';
import { convertSubstring } from '@utils/file';
import { useStyles } from './styles';
import { ReactComponent as IBMFile } from '../../assets/icon/IBMFile.svg';
import { ReactComponent as IBMImage } from '../../assets/icon/IBMImage.svg';
import { ReactComponent as IBMFolder } from '../../assets/icon/IBMFolder.svg';
import { ReactComponent as IBMPDF } from '../../assets/icon/IBMPDF.svg';
import { ReactComponent as IBMVideo } from '../../assets/icon/IBMVideo.svg';
import clsx from 'clsx';

type Props = {
  file: any;
  onClick?: () => void;
};

const FileIcon = (props: Props) => {
  const { file, onClick } = props;
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.FileIconWrapper, 'file-icon')}
      onClick={onClick}
    >
      {!file?.isFile ? (
        <IBMFolder />
      ) : (
        <>
          {convertSubstring([...DRAWING_FILE_TYPES, ...IMAGE_TYPES]).includes(
            file?.originalFileExt?.toLocaleLowerCase()
          ) && <IBMImage />}

          {file?.originalFileExt?.toLocaleLowerCase() === PDF_TYPE && (
            <IBMPDF />
          )}
          {convertSubstring(DOCUMENT_TYPES_FILE).includes(
            file?.originalFileExt?.toLocaleLowerCase()
          ) && <IBMFile />}
          {convertSubstring(VIDEO_TYPES).includes(
            file?.originalFileExt?.toLocaleLowerCase()
          ) && <IBMVideo />}
        </>
      )}
    </div>
  );
};

export default FileIcon;
