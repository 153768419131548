import { setStateContacts } from '@modules/Contacts/MyContacts/actions';
import {
  FetchMyContacts,
  FetchRecommendedContactUsersList
} from '@generated/Contacts/MyContacts/graphql';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@modules/index';
import { useLazyQuery } from '@apollo/client';

export default function useMyContacts() {
  const { myContacts } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const [
    fetchMyContacts,
    {
      data: myContactsList,
      loading: myContactsListLoading,
      fetchMore: onFetchMoreMyContacts
    }
  ] = useLazyQuery(FetchMyContacts, {
    fetchPolicy: 'no-cache'
  });
  const [
    fetchRecommendedContactUsersList,
    {
      data: recommendedContactUsersList,
      loading: recommendedContactUsersListLoading,
      fetchMore: onFetchMoreRecommendedContactUsers
    }
  ] = useLazyQuery(FetchRecommendedContactUsersList, {
    fetchPolicy: 'no-cache'
  });

  const onFetchMyContacts = useCallback(fetchMyContacts, [fetchMyContacts]);
  const onFetchRecommendedContactUsersList = useCallback(
    fetchRecommendedContactUsersList,
    [fetchRecommendedContactUsersList]
  );

  const onSetStateContact = (params: any) => dispatch(setStateContacts(params));

  const recommendedContactUsersListState = useMemo(() => {
    if (
      recommendedContactUsersList &&
      recommendedContactUsersList?.fetchRecommendedContactUsersList
    ) {
      return {
        data:
          recommendedContactUsersList?.fetchRecommendedContactUsersList.data ??
          [],
        isLoading: recommendedContactUsersListLoading
      };
    }

    return {
      data: undefined,
      isLoading: recommendedContactUsersListLoading
    };
  }, [recommendedContactUsersList]);

  return {
    myContacts,
    myContactsList,
    myContactsListLoading,
    onSetStateContact,
    onFetchMyContacts,
    onFetchRecommendedContactUsersList,
    recommendedContactUsersListState,
    onFetchMoreMyContacts,
    onFetchMoreRecommendedContactUsers
  };
}
