import { makeStyles } from '@material-ui/core';
import { colorSet } from '@components/Provider';

export const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: 520,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: 0,
      height: '100%',
      maxHeight: '100%',
      maxWidth: '100%',
      borderRadius: 0
    },
    minHeight: 556,
    borderRadius: '16px'
  },
  addMembersToWrapper: {
    maxWidth: 640,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: 0,
      height: '100%',
      maxHeight: '100%',
      maxWidth: '100%',
      borderRadius: 0
    },
    borderRadius: '16px'
  },
  rootDialog: {
    display: 'none'
  },
  successFullyPaper: {
    maxWidth: 480,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: 0,
      height: '100%',
      maxHeight: '100%',
      maxWidth: '100%',
      borderRadius: 0
    },
    maxHeight: 593,
    borderRadius: '16px'
  },
  rootTitle: {
    padding: '24px 24px 20px 24px',
    display: 'flex',
    fontSize: '25px',
    fontWeight: 'bold',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: '12px 16px',
      border: `1px solid ${colorSet.gray300}`,
      display: 'inline-block',
      textAlign: 'center',
      fontSize: 16
    }
  },
  closeIcon: {
    color: colorSet.gray900,
    fontWeight: 500,
    position: 'absolute',
    left: 16,
    top: 10,
    [theme.breakpoints.down('xs')]: {
      top: 14
    }
  },
  customTitle: {
    fontSize: 20,
    fontWeight: 700,
    lineHeight: '160%',
    letterSpacing: '-0.1px',
    [theme.breakpoints.down('xs')]: {
      color: colorSet.gray900,
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '150%'
    }
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  cancelBtn: {
    fontSize: 24,
    marginLeft: 'auto',
    cursor: 'pointer'
  },
  content: {
    padding: '0px 24px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px 12px'
    }
  },
  action: {
    padding: '20px 32px 24px 32px'
  },
  groupsAction: {
    padding: '20px 24px 24px 24px',
    [theme.breakpoints.down('xs')]: {
      padding: 16,
      borderTop: `1px solid ${colorSet.gray400}`
    }
  },
  groupsMobileButtonWrapper: {
    display: 'flex',
    '& button': {
      textTransform: 'none',
      marginLeft: 'auto',
      height: '48px !important',
      width: 132
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      '& button': {
        width: '100%',
        marginRight: 0,
        '&:first-child': {
          marginRight: '0 !important'
        }
      }
    }
  },
  wrapperBtn: {
    display: 'flex',
    '& button': {
      textTransform: 'none',
      marginLeft: 'auto',
      '&:first-child': {
        marginRight: 8
      }
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      '& button': {
        width: 'calc(100% / 2)'
      },
      '& button + button': {
        marginLeft: 6
      }
    }
  },
  csDialogText: {
    color: colorSet.gray900,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '150%',
    letterSpacing: '-0.1px'
  },
  csDialogContent: {
    padding: 0,
    marginLeft: 24,
    marginRight: 24,
    [theme.breakpoints.down('xs')]: {
      padding: 16,
      margin: 0
    },
    overflow: 'hidden'
  },
  disabledBox: {
    backgroundColor: colorSet.gray100
  },
  arrowBackIosOutlinedWrapper: {
    backgroundColor: colorSet.primary450,
    borderRadius: 12,
    marginRight: 16,
    width: 36,
    height: 36,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  arrowBackIosOutlinedIcon: {
    width: 14,
    color: colorSet.gray100,
    [theme.breakpoints.down('xs')]: {
      color: colorSet.gray900,
      fontWeight: 500,
      position: 'absolute',
      left: 16,
      top: 14
    }
  },
  searchInputWrapper: {
    boxShadow: 'none',
    backgroundColor: colorSet.gray100,
    borderRadius: 8,
    padding: '8px 15px',
    marginBottom: 16,
    position: 'relative'
  },
  searchIconWrapper: {
    display: 'inline-flex',
    verticalAlign: 'middle'
  },
  searchCloseIconWrapper: {
    display: 'inline-flex',
    verticalAlign: 'middle',
    position: 'absolute',
    right: 16,
    top: 10
  },
  searchInputBase: {
    width: '92%',
    paddingLeft: 10,
    paddingRight: 12
  },
  customSearchIcon: {
    cursor: 'pointer',
    color: colorSet.gray500
  },
  searchResultWrapper: {
    height: 324,
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: 4
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: colorSet.gray600,
      borderRadius: 8
    },
    '& .MuiAvatar-root': {
      marginRight: 16
    },
    [theme.breakpoints.down('xs')]: {
      height: 'calc(100vh - 220px)',
      overflow: 'auto'
    }
  },
  searchResult: {
    display: 'flex',
    width: '100%',
    padding: '12px 16px',
    border: `1px solid ${colorSet.gray300}`,
    borderRadius: 12,
    marginTop: 8,
    alignItems: 'center',
    '&:nth-child(1)': {
      marginTop: 0
    }
  },
  hoverItem: {
    '&:hover': {
      border: `1px solid ${colorSet.primary450}`
    }
  },
  activeResult: {
    border: `1px solid ${colorSet.primary450}`
  },
  disabledResult: {
    pointerEvents: 'none',
    border: `1px solid ${colorSet.gray300}`,
    opacity: 0.3
  },
  image: {
    marginRight: 16,
    [theme.breakpoints.down('xs')]: {
      marginRight: 16,
      userSelect: 'none'
    }
  },
  groupInfo: {
    color: colorSet.gray900,
    display: 'grid',
    alignItems: 'center',
    width: '100%'
  },
  groupName: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '150%',
    letterSpacing: '-0.1px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: {
      fontSize: 15,
      lineHeight: '140%'
    }
  },
  customCheckbox: {
    color: `${colorSet.gray400} !important`,
    paddingRight: '0 !important',
    '&.Mui-checked': {
      color: `${colorSet.primary450} !important`
    }
  },
  noResultText: {
    display: 'flex',
    justifyContent: 'center',
    color: colorSet.gray900,
    marginTop: 30
  },
  activeSearchResult: {
    border: `1px solid ${colorSet.primary450}`
  },
  pointerCursor: {
    cursor: 'pointer'
  }
}));
