import React from 'react';
import { BottomNavMenu, GnbAppBar } from '@routes/Common';
import MyPagePresenter from './MyPagePresenter';

const MyPageContainer: React.FC = () => {
  return (
    <>
      <GnbAppBar />
      <MyPagePresenter />
      <BottomNavMenu />
    </>
  );
};

export default MyPageContainer;
