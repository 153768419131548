import { makeStyles } from '@material-ui/core';
import { colorSet } from '@components/Provider';

export const useStyles = makeStyles((theme) => ({
  paper: {
    background: 'res',
    maxWidth: 520,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      minWidth: 343
    },
    borderRadius: '16px'
  },
  rootHideModal: {
    display: 'none'
  },
  rootShowModal: {
    display: 'block',
    width: 520,
    [theme.breakpoints.down('xs')]: {
      width: 'auto'
    }
  },
  rootTitle: {
    padding: '24px 24px 20px 24px',
    display: 'flex',
    fontSize: 20,
    fontWeight: 700,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
      marginBottom: 15,
      padding: '12px 16px',
      borderBottom: `1px solid ${colorSet.gray300}`
    }
  },
  subTitle: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',

    [theme.breakpoints.down('xs')]: {
      width: '100%',
      '& svg': {
        fontSize: 20
      }
    }
  },
  titleIcon: {
    cursor: 'pointer',
    width: '44px',
    height: '44px',
    background: `${colorSet.primary450}`,
    borderRadius: 12,
    marginRight: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      fontSize: 26,
      color: `${colorSet.gray000}`
    },
    minWidth: 44
  },
  name: {
    fontSize: 20,
    color: `${colorSet.gray900}`,
    '& .title-group-name': {
      fontWeight: 'normal'
    },
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      width: '100%',
      fontSize: 16,
      display: 'block',
      fontWeight: 500
    }
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  cancelBtn: {
    fontSize: 32,
    marginLeft: 'auto',
    cursor: 'pointer'
  },
  content: {
    padding: '0px 32px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px 12px'
    }
  },
  action: {
    padding: '0 24px 24px 24px',
    [theme.breakpoints.down('xs')]: {
      padding: '0 16px 16px 16px',
      position: 'fixed',
      bottom: 0,
      width: '100%',
      background: colorSet.gray000
    }
  },
  wrapperBtn: {
    display: 'flex',
    '& button': {
      textTransform: 'none',
      marginLeft: 'auto',
      height: '48px !important',
      width: '132px !important',
      '&:first-child': {
        marginRight: 8
      }
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      '& button': {
        width: '100% !important'
      }
    }
  },
  wrapperMemberBtn: {
    '& button': {
      '&:first-child': {
        marginRight: '0 !important'
      }
    }
  },
  actionNoBtn: {
    padding: '14px 0 22px 0',
    margin: '14px 22px 0 22px'
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 12,
    borderRadius: '100%',
    marginLeft: 'auto',
    cursor: 'pointer',
    transition: '300ms all',
    '&:hover': {
      background: '#F7FAFF'
    }
  },
  addContactsIcon: {
    marginLeft: 'auto',
    cursor: 'pointer'
  },
  customAddButton: {
    minWidth: '88px !important',
    height: '48px !important',
    textTransform: 'none'
  },
  csDialogContent: {
    '& .MuiAutocomplete-hasClearIcon': {
      width: 376,
      '& input': {
        padding: '8.5px 10px !important'
      },
      [theme.breakpoints.down('xs')]: {
        width: 150
      }
    },
    '& .MuiOutlinedInput-input': {
      height: 29,
      '&:focus': {
        border: 'none'
      }
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent !important'
    },
    display: 'flex',
    padding: '0 24px',
    [theme.breakpoints.down('xs')]: {
      padding: '0 16px'
    },
    '& .MuiAutocomplete-root': {
      flex: '1 0 auto',
      [theme.breakpoints.down('xs')]: {
        marginRight: 0
      }
    },
    '& .MuiInputBase-root': {
      padding: '0 !important',
      '&:focus': {
        border: `1px solid ${colorSet.primary500} !important`
      }
    },
    '& .MuiTextField-root.MuiFormControl-fullWidth': {
      border: `1px solid ${colorSet.gray400}`,
      width: '100%',
      flex: '1 0 auto',
      borderRadius: 8
    }
  },
  csDialogSearch: {
    display: 'flex',
    width: '100%',
    '& .MuiAutocomplete-root': {
      flex: '1 0 auto',
      marginRight: 10
    }
  },
  line: {
    width: '100%',
    height: 1,
    background: colorSet.gray400
  },
  customPopover: {
    '& .MuiPopover-paper': {
      boxShadow:
        '0px 8px 16px 2px rgba(97, 97, 97, 0.1), 0px 16px 32px 2px rgba(97, 97, 97, 0.1)'
    },
    [theme.breakpoints.down('xs')]: {
      background: `${colorSet.backgroundDrop}`,
      '& .MuiPopover-paper': {
        boxShadow: 'none',
        background: 'transparent',
        minHeight: 'auto'
      }
    }
  },
  contentWrapper: {
    overflow: 'auto',
    height: 280,
    marginBottom: 32,
    marginTop: 20,
    [theme.breakpoints.down('xs')]: {
      height: 'calc(100vh - 185px)'
    },
    '&::-webkit-scrollbar': {
      width: 4
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: colorSet.gray600,
      borderRadius: 8
    }
  },
  csDialogContentBottom: {
    padding: '0 24px',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    '&:first-child': {
      paddingTop: 0
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0 22px',
      marginBottom: 16,
      alignItems: 'flex-start'
    }
  },
  csDialogContentList: {
    display: 'flex',
    paddingBottom: 8,
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 4,
      display: 'grid',
      gridTemplateColumns: '45px 1fr'
    }
  },
  csDialogContentImage: {
    display: 'flex',
    width: '100%',
    alignItems: 'center'
  },
  avatar: {
    [theme.breakpoints.down('xs')]: {
      gridRow: 'span 2 / span 2'
    },
    '& .MuiAvatar-root': {
      marginRight: 6
    }
  },
  csDialogUserName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  csConnectUserName: {
    display: 'flex',
    '& .name': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      marginLeft: '0 !important',
      color: `${colorSet.gray900} !important`,
      fontWeight: '500 !important'
    },
    '& .text-you': {
      marginLeft: 2,
      flexShrink: 0
    }
  },
  csDialogContentText: {
    fontWeight: 500,
    width: 'calc(100% - 185px)',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      maxWidth: '100%',
      display: 'inherit'
    },
    '& div': {
      marginLeft: 12,
      fontSize: 16,
      lineHeight: '150%'
    },
    '& div:first-child': {
      color: `${colorSet.gray900}`
    },
    '& div:last-child': {
      color: `${colorSet.gray700}`,
      fontWeight: 'normal'
    },
    '& .text-you': {
      fontWeight: 'normal'
    }
  },
  csDialogContentPosition: {
    flexShrink: 0,
    color: `${colorSet.gray900}`,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '6px 6px 6px 12px',
    maxWidth: 150,
    marginLeft: 'auto',
    lineHeight: '20px',
    borderRadius: 8,
    fontSize: 14,
    fontWeight: 500,
    background: `${colorSet.gray100}`,
    '&:hover': {
      background: `${colorSet.gray100}`
    },
    [theme.breakpoints.down('xs')]: {
      color: `${colorSet.primary450}`,
      display: 'grid',
      marginLeft: 6,
      padding: 6,
      width: 'fit-content',
      gridColumn: 2,
      justifyContent: 'flex-start',
      alignItems: 'center',
      borderRadius: 8,
      textTransform: 'capitalize',
      background: 'transparent',
      '& svg': {
        display: 'none'
      }
    }
  },
  csDialogContentPopup: {
    width: 242,
    '& .spacing': {
      marginLeft: 24
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      background: `${colorSet.gray000}`,
      position: 'fixed',
      left: 0,
      bottom: 0,
      borderRadius: '8px 8px 0 0',
      '& p svg': {
        marginRight: 5,
        marginLeft: 10
      },
      '& .spacing': {
        marginLeft: 40
      }
    },
    '.MuiPopover-root': {
      background: `${colorSet.backgroundDrop}`
    },
    '& .MuiTypography-body1': {
      padding: 15,
      display: 'flex',
      flexDirection: 'row',
      cursor: 'pointer',
      color: `${colorSet.gray900}`,
      fontSize: 16,
      lineHeight: '150%',
      '&:hover': {
        background: colorSet.gray100
      }
    },
    '& .name': {
      marginLeft: 6,
      '& .text': {
        fontWeight: 500
      },
      '& p': {
        color: `${colorSet.gray700}`
      }
    }
  },
  csDialogContentTop: {
    flexShrink: 0,
    textTransform: 'capitalize',
    color: `${colorSet.gray700}`,
    display: 'flex',
    alignItems: 'center',
    width: 140,
    justifyContent: 'flex-end',
    cursor: 'pointer',
    padding: '6px 0 6px 8px',
    borderRadius: 8,
    fontSize: 14,
    [theme.breakpoints.down('xs')]: {
      padding: '6px 0 6px 13px',
      width: '100%',
      gridColumn: 2,
      justifyContent: 'flex-start',
      color: `${colorSet.primary450}`,
      background: 'transparent',
      '& svg': {
        display: 'none'
      }
    }
  },
  csDialogContentTextFile: {
    color: `${colorSet.gray700}`,
    display: 'flex',
    alignItems: 'center',
    width: 140,
    justifyContent: 'flex-end',
    cursor: 'pointer',
    padding: '6px 0 6px 8px',
    borderRadius: 8,
    fontSize: 14,
    '& .icon': {
      display: 'none'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '6px 0 6px 13px',
      width: '100%',
      gridColumn: 2,
      justifyContent: 'flex-start',
      color: `${colorSet.primary450}`,
      alignItems: 'center',
      textTransform: 'capitalize',
      background: 'transparent',
      '& svg': {
        display: 'none'
      }
    }
  },
  itemPopUp: {
    '&:nth-child(3)': {
      paddingBottom: '23px'
    },
    '&:last-child': {
      borderTop: `1px solid ${colorSet.gray200}`,
      alignItems: 'center',
      padding: '23px 15px'
    }
  },
  iconCheck: {
    marginRight: 2,
    marginTop: 2,
    color: `${colorSet.primary500}`
  },
  item: {
    alignItems: 'center'
  }
}));
