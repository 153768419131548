import React, { useRef, useEffect, useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { useStyles } from './styles';

interface OverflowTipProps {
  value: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal;
  longText?: string | React.ReactChild;
  maxWidth?: number;
  children?: React.ReactChild;
}

const OverflowTip = (props: OverflowTipProps) => {
  const { value, longText, maxWidth, children } = props;
  // Create Ref
  const textElementRef = useRef<HTMLDivElement>(null);
  const classes = useStyles({ maxWidth });

  const compareSize = () => {
    if (null !== textElementRef.current) {
      const compare =
        textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
      setHover(compare);
    }
  };

  // compare once and add resize listener on "componentDidMount"
  useEffect(() => {
    compareSize();
    window.addEventListener('resize', compareSize);
  }, []);

  // remove resize listener again on "componentWillUnmount"
  useEffect(
    () => () => {
      window.removeEventListener('resize', compareSize);
    },
    []
  );

  // Define state and function to update the value
  const [hoverStatus, setHover] = useState(false);

  return (
    <Tooltip title={value} interactive disableHoverListener={!hoverStatus}>
      <div ref={textElementRef} className={classes.wrapper}>
        {children || longText || value}
      </div>
    </Tooltip>
  );
};

export default OverflowTip;
