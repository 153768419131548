import { CapaConnectContact } from '@generated/Contacts/MyContacts/types';
import { ActionType } from 'typesafe-actions';
import * as actions from './actions';
export interface MyContactsState {
  data?: Array<CapaConnectContact>;
  dataCreateEditDialog?: any;
  dataDeleteDialog?: any;
  dataHoverContactID?: any;
  dataCheckedContacts?: Array<CapaConnectContact>;
  dataDetailContact?: CapaConnectContact;
  isShowHideExistingGroups?: boolean;
  isShowHideCreateNewGroup?: boolean;
}

export const initialMyContactsState: MyContactsState = {
  data: [],
  dataCreateEditDialog: false,
  dataDeleteDialog: [],
  dataHoverContactID: null,
  dataCheckedContacts: [],
  dataDetailContact: undefined,
  isShowHideExistingGroups: false,
  isShowHideCreateNewGroup: false
};

export type MyContactsAction = ActionType<typeof actions>;
