import { makeStyles } from '@material-ui/core';
import { colorSet } from '@components/Provider';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: 186,
    marginRight: 84
  },
  avatarWrapper: {
    display: 'flex',
    justifyContent: 'center'
  },
  username: {
    marginTop: 20,
    marginBottom: 24,
    fontSize: 16,
    textAlign: 'center',
    fontWeight: 500,
    lineHeight: '150%',
    letterSpacing: '-0.1px',
    wordBreak: 'break-all'
  },
  logOutItem: {
    marginTop: 24
  },
  logOut: {
    color: colorSet.gray700,
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '143%',
    letterSpacing: '-0.1px'
  },
  customAvatar: {
    width: '88px',
    height: '88px',
    textTransform: 'uppercase',
    border: 'none !important',
    fontSize: 32,
    fontWeight: 700,
    lineHeight: 48,
    letterSpacing: '-0.1px'
  },
  divider: {
    margin: '24px 0px'
  },
  menuList: {
    '& p': {
      fontSize: 14
    },
    '& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
      backgroundColor: colorSet.gray100,
      borderRadius: 8,
      color: colorSet.primary500,
      '& p': {
        color: colorSet.primary500
      }
    },
    '& .MuiListItem-root': {
      height: 48,
      borderRadius: 4,
      '& svg': {
        marginRight: 10
      },
      '&:not(:last-child)': {
        marginBottom: 8
      }
    },
    '& .MuiListItem-gutters': {
      paddingLeft: 14,
      paddingRight: 14
    }
  }
}));

export const useStylesDialogLanguages = makeStyles((theme) => ({
  paper: {
    borderRadius: '8px'
  },

  csDialogContentModal: {
    padding: '8px 0 !important'
  },

  csDialogContent: {
    width: 240,
    padding: 15,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 54,

    '.MuiPopover-root': {
      background: `${colorSet.backgroundDrop}`
    },
    '& .MuiTypography-body1': {
      marginBottom: 15,
      display: 'flex',
      flexDirection: 'row',
      cursor: 'pointer',
      color: `${colorSet.gray900}`,
      fontSize: 16,
      lineHeight: '150%'
    },
    '& .spacing': {
      marginLeft: 24
    },
    '& .name': {
      marginLeft: 6,
      '& p': {
        color: `${colorSet.gray700}`
      }
    },
    '&:hover': {
      backgroundColor: colorSet.gray100
    }
  },

  checkIcon: {
    width: 20,
    height: 20,
    color: colorSet.primary500
  }
}));

export const mobileMyPageMenuStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  '& .ml-16': {
    marginLeft: 16
  },
  '& .mr-10': {
    marginRight: 10
  },
  infoWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  accountSettingWrapper: {
    margin: '16px 24px 16px 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& .MuiAvatar-root': {
      cursor: 'pointer',
      width: 72,
      height: 72,
      textTransform: 'uppercase',
      marginRight: 16,
      border: 'none !important',
      fontSize: 32,
      fontWeight: 700,
      lineHeight: 48,
      letterSpacing: '-0.1px'
    }
  },
  username: {
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '150%',
    letterSpacing: '-0.1px',
    color: colorSet.gray900,
    marginBottom: 2,
    wordBreak: 'break-word'
  },
  email: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '143%',
    letterSpacing: '-0.1px',
    color: colorSet.gray700
  },
  divider: {
    marginTop: '24px'
  },
  menuList: {
    '&.MuiList-padding': {
      padding: 0
    },
    '& .MuiListItem-root': {
      display: 'flex',
      justifyContent: 'space-between',
      height: 56,
      borderBottom: `1px solid ${colorSet.gray200}`,
      borderRadius: 4,
      '& div': {
        display: 'flex',
        '& p': {
          fontWeight: 500
        }
      },
      '& svg': {
        marginRight: 10
      }
    },
    '& .MuiListItem-gutters': {
      paddingLeft: 14,
      paddingRight: 14
    }
  },
  logOut: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '143%',
    letterSpacing: '-0.1px',
    textDecorationLine: 'underline',
    color: colorSet.gray700,
    margin: 18
  }
}));
