import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Layout } from '../Common';
import useAuth from '@hooks/useAuth';
import { GnbAppBar, BottomNavMenu } from '@routes/Common';
import useSharedWithGroups from '@hooks/Drive/MyDrive/useSharedWithGroups';
import RemoveFormGroup from '@routes/Dashboard/Dialog/notification/RemoveFormGroup/RemoveFormGroup';
import { getStorageItem, STORAGE_ACTION } from '@utils/storage';
import { loginPathName } from '@routes/Common/types';
import NotFoundContent from './NotFoundContent';

const NotFoundPresenter = () => {
  const history = useHistory();
  const { fetchConnectUserLoading } = useAuth();
  const { search } = useLocation();
  const [isShowHideRemoveGroupDialog, setShowHideRemoveGroupDialog] =
    useState(false);
  const { onFetchGroupData, groupDataState } = useSharedWithGroups();
  const userInfo = getStorageItem(STORAGE_ACTION.USER_INFO);
  const accessToken = getStorageItem(STORAGE_ACTION.CONNECT_ACCESS_TOKEN);
  const isLoggedIn = !!accessToken && !!(userInfo && userInfo.email);

  useEffect(() => {
    if (history.location.search.includes('confirmLeave')) {
      const groupId = new URLSearchParams(search).get('groupId');
      setShowHideRemoveGroupDialog(true);
      onFetchGroupData({
        variables: {
          groupId: groupId
        }
      });
    }
  }, [history]);

  if (!isLoggedIn) {
    history.push(loginPathName);
    return <></>;
  }

  return !fetchConnectUserLoading ? (
    <Layout GnbMenu={<GnbAppBar />}>
      <NotFoundContent />
      <BottomNavMenu />
      {isShowHideRemoveGroupDialog && (
        <RemoveFormGroup
          groupName={groupDataState?.data}
          isOpen={isShowHideRemoveGroupDialog}
          onClose={() => {
            setShowHideRemoveGroupDialog(false);
          }}
        />
      )}
    </Layout>
  ) : (
    <></>
  );
};

export default NotFoundPresenter;
