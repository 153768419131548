export enum ValueSortByTable {
  Name = 'NAME',
  Email = 'EMAIL',
  Memo = 'MEMO'
}
export const LIST_TABLE_HEADER = [
  {
    name: 'contact.my_contacts.column_title.name',
    value: ValueSortByTable.Name,
    className: 'table-header-th-one',
    isOrder: true
  },
  {
    name: 'contact.my_contacts.column_title.email',
    value: ValueSortByTable.Email,
    className: 'table-header-th-two',
    isOrder: true
  },
  {
    name: 'contact.my_contacts.column_title.memo',
    value: ValueSortByTable.Memo,
    className: 'table-header-th-three'
  }
];

export const RECOMMENDED_LIST_TABLE_HEADER = [
  {
    name: 'contact.recommended_contacts.name',
    value: ValueSortByTable.Name,
    className: 'table-header-th-one',
    isOrder: true
  },
  {
    name: 'contact.recommended_contacts.email',
    value: ValueSortByTable.Email,
    className: 'table-header-th-two',
    isOrder: true
  }
];

export enum EnumListControlType {
  ListHeader = 'LISTHEADER',
  ListItem = 'LISTITEM'
}

export const ADD_TO_GROUP_SCREEN = {
  CHOOSE_GROUP: 'CHOOSE_GROUP',
  SUCCESSFULLY_SHARED: 'SUCCESSFULLY_SHARED'
};

export const URL_PARAM = {
  myContactsType: 'my-contacts',
  recommendedType: 'recommended-contacts'
};

export const URL_PARAM_TYPE = {
  myContactsType: 0,
  recommendedType: 1
};
