import React, { useEffect } from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useLocation } from 'react-router-dom';

const ChannelIoProvider: React.FC = ({ children }) => {
  const { pathname } = useLocation();
  const matches = useMediaQuery(useTheme().breakpoints.down('xs'));

  useEffect(() => {
    const win: Window & { ChannelIO?: any } = window;
    if (win.ChannelIO) {
      if (matches) {
        if (pathname !== '/mypage' && pathname !== '/') {
          win.ChannelIO('hideChannelButton');
        }

        return () => win.ChannelIO('showChannelButton');
      }

      if (!matches) {
        if (pathname.includes('/group')) {
          win.ChannelIO('hideChannelButton');
        }

        return () => win.ChannelIO('showChannelButton');
      }
    }
  }, [pathname, matches]);

  return <>{children}</>;
};

export default ChannelIoProvider;
