import React, { useCallback, useState } from 'react';
import { useDropzone, FileRejection } from 'react-dropzone';
import { fileUploadApi } from '@api/index';
import useUpload from '@modules/upload/useUpload';
import { UploadData, UploadState } from '@modules/upload';
import useAuth from '@hooks/useAuth';
import useUserActionLog, {
  Enum_Action_Button_Enum,
  CapaActivityType
} from '@hooks/useUserActionLog';
import { useSnackbar } from '@components/Provider';
import Loading from '@components/Loading';
import { acceptedExtensions } from './types';
import { DropzoneContainer, useStyles } from '@routes/Common/Dropzone/styles';
import useMyDrive from '@hooks/Drive/MyDrive/useMyDrive';
import {
  myDrivePathName,
  groupRoomPathName,
  sharedWithGroupsPathName
} from '@routes/Common/types';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import axios from 'axios';
import { DASHBOARD_DROPZONE_WRAPPER_ID } from '@routes/Dashboard/types';
import { hasWrapperElementOverflow } from '@utils/common';

interface Props {
  accept?: Array<string>;
  noClick?: boolean;
  callback?: (fileIds: any, fileName: any) => void;
  locationInfo?: any;
  locationIcon?: React.ReactNode;
  isFolder?: boolean;
  rootMinHeight?: string;
  rootHeight?: string;
  noDrag?: boolean;
  noKeyboard?: boolean;
}

let internalFileId = '';

const DropzonePresenter: React.FC<Props> = ({
  accept,
  noClick,
  callback,
  children,
  locationInfo,
  locationIcon,
  isFolder,
  rootMinHeight,
  rootHeight,
  noDrag,
  noKeyboard
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { uploadState, onGetUploadData, onAddUploadData, fileSizeOnProgress } =
    useUpload();
  const { getRootProps, getInputProps, isDragReject } = useDropzone({
    accept: accept ?? acceptedExtensions,
    noClick: noClick ?? false,
    multiple: true,
    noDragEventsBubbling: true,
    noDrag: noDrag ?? false,
    noKeyboard: noKeyboard ?? false,
    onDrop: (acceptedFiles, fileRejections) =>
      onDropHandle(acceptedFiles, fileRejections),
    onDragEnter: (e) => onDragEnterHandle(e),
    onDragLeave: () => onDragLeaveHandle(),
    onDropAccepted: (file) => onDropAcceptedHandle(file)
  });
  const { tokenRefresh, onRefetchConnectUser, userState } = useAuth();
  const { onInsertUserActionLogOne } = useUserActionLog();
  const [isShowHideLocationBox, setShowHideLocationBox] =
    useState<boolean>(false);
  const {
    onSetLocationName,
    onSetShowHideFileDetail,
    onSetUploadFileFromComputerStatus,
    onSetInternalFileFromComputer,
    onSetDragDropFilesToGroupRoom
  } = useMyDrive();
  const history = useHistory();
  const isGroupRoomPathName = history.location.pathname === groupRoomPathName;
  const isMyDrivePathName = history.location.pathname === myDrivePathName;
  const isSharedWithGroupPathName =
    history.location.pathname === sharedWithGroupsPathName;
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const onDragEnterHandle = (e: any) => {
    const targetEl = e?.target;
    let internalLocationName = '';
    if (targetEl?.nodeName === 'path') {
      internalLocationName = targetEl?.parentElement?.parentElement?.innerText;
      internalFileId = targetEl?.parentElement?.parentElement?.id;
    } else if (targetEl?.nodeName === 'P') {
      internalLocationName =
        targetEl?.parentElement?.previousElementSibling?.innerText;
      internalFileId = targetEl?.parentElement?.previousElementSibling?.id;
    } else if (targetEl?.nodeName === 'svg') {
      internalLocationName = targetEl?.parentElement?.innerText;
      internalFileId = targetEl?.parentElement?.id;
    } else if (targetEl?.className === 'table-item-td-three') {
      internalLocationName = targetEl?.previousElementSibling?.innerText;
      internalFileId = targetEl?.previousElementSibling?.id;
    } else if (targetEl?.className === 'table-item-td-four') {
      internalLocationName =
        targetEl?.previousElementSibling?.previousElementSibling?.innerText;
      internalFileId =
        targetEl?.previousElementSibling?.previousElementSibling?.id;
    } else {
      internalLocationName = targetEl?.innerText;
      internalFileId = targetEl?.id;
    }

    onSetLocationName({ locationName: internalLocationName });
    setShowHideLocationBox(true);

    if (isGroupRoomPathName) {
      onSetDragDropFilesToGroupRoom({
        isDragDropFilesToGroupRoom: true
      });
    }
  };

  const onDragLeaveHandle = () => {
    if (isGroupRoomPathName) {
      onSetDragDropFilesToGroupRoom({
        isDragDropFilesToGroupRoom: false
      });
    }
    setShowHideLocationBox(false);
  };

  const onDropHandle = (
    acceptedFiles: Array<File>,
    fileRejections: Array<FileRejection>
  ) => {
    onInsertUserActionLogOne(
      Enum_Action_Button_Enum.Button_MyDrive_Upload,
      CapaActivityType.ButtonClick
    );

    if (acceptedFiles.length > 0 && fileRejections.length > 0) {
      enqueueSnackbar(
        '캐파에서 지원하지 않는 형식의 파일 일부가 업로드에서 제외되었습니다',
        {
          variant: 'success'
        }
      );
    } else if (acceptedFiles.length < 1 && fileRejections.length > 0) {
      enqueueSnackbar(
        '캐파에서 지원하지 않는 형식의 파일은 업로드가 불가능합니다',
        {
          variant: 'error'
        }
      );
    }
    setShowHideLocationBox(false);
  };

  const onDropAcceptedHandle = useCallback(
    async (files: Array<File>) => {
      let fileId = '';
      const pathnameUrl = history.location.pathname.split(
        `${myDrivePathName}/`
      );

      if (!isGroupRoomPathName || !isSharedWithGroupPathName) {
        if (isFolder) {
          fileId = internalFileId;
        } else {
          fileId = pathnameUrl[1] ? pathnameUrl[1] : '';
        }
      }

      setShowHideLocationBox(false);

      const uploadData: UploadData[] = files.map((file) => {
        return {
          file: file,
          loaded: 0,
          total: file.size,
          isError: false,
          state: UploadState.PENDING,
          dataKey: `${file.name}${new Date().getTime()}`,
          cancelSource: source
        };
      });

      const filteredUploadData = uploadData.filter(
        (fileData) => fileData.state !== UploadState.OVERSIZE
      );

      if (uploadState.length === 0) {
        onGetUploadData(uploadData);
      } else {
        onAddUploadData(uploadData);
      }

      if (isGroupRoomPathName) {
        onSetInternalFileFromComputer({
          internalFileFromComputer: filteredUploadData.map(
            (file: any) => file.file
          )
        });
        onSetUploadFileFromComputerStatus({
          isUploadFileFromComputer: true
        });
      }

      fileUploadApi(filteredUploadData, fileId)
        .then(async (responses) => {
          const isErrorHappend = responses.filter(
            (response) => response.status === 401
          );
          /* axios.all을 통해 전달된 request중 하나라도 401 error가 나는 경우 tokenRefresh 로직 실행 */
          if (isErrorHappend.length > 0) {
            tokenRefresh();
          }

          if (
            isErrorHappend.length > 0 &&
            isErrorHappend.length === filteredUploadData.length
          ) {
            enqueueSnackbar(`파일 업로드에 실패했습니다. 다시 시도해주세요`, {
              variant: 'error'
            });
          }

          const fileIds = responses.map((res) => res.data.data.data[0].id);
          const fileName = responses.map(
            (res) => res.data.data.data[0].fileName
          );

          if (isMyDrivePathName)
            onSetShowHideFileDetail({
              detailDrive: null
            });

          /* 파일 리스트 갱신 */
          if (callback) {
            callback(fileIds, fileName);
            await onRefetchConnectUser();
          }
        })
        .catch((e) => {
          return e;
        });
    },
    [
      history.location.pathname,
      isGroupRoomPathName,
      isSharedWithGroupPathName,
      userState.data?.availSize,
      fileSizeOnProgress,
      uploadState.length,
      isFolder,
      enqueueSnackbar,
      source,
      onGetUploadData,
      onAddUploadData,
      onSetInternalFileFromComputer,
      onSetUploadFileFromComputerStatus,
      callback,
      tokenRefresh,
      onRefetchConnectUser
    ]
  );

  return (
    <React.Fragment>
      <Loading open={false} />

      <div
        className={classes.root}
        style={{
          minHeight:
            rootMinHeight &&
            !isFolder &&
            hasWrapperElementOverflow(
              document.getElementById(DASHBOARD_DROPZONE_WRAPPER_ID)
            )
              ? rootMinHeight
              : '',
          height:
            rootHeight &&
            !isFolder &&
            !hasWrapperElementOverflow(
              document.getElementById(DASHBOARD_DROPZONE_WRAPPER_ID)
            )
              ? rootHeight
              : ''
        }}
      >
        <DropzoneContainer
          className={classes.rootParent}
          {...getRootProps({ isDragReject, isGroupRoomPathName })}
        >
          <input {...getInputProps()} />
          {children}
          {isGroupRoomPathName && isShowHideLocationBox ? (
            <div className={classes.wrapperOverlap} />
          ) : null}
          {isShowHideLocationBox ? (
            <div
              className={clsx(
                isGroupRoomPathName
                  ? classes.groupNameLocationBox
                  : classes.locationBox
              )}
            >
              <div className={classes.locationTitle}>
                {locationInfo && locationInfo.title}
              </div>
              <div className={classes.locationNameRow}>
                {locationIcon}
                <div
                  className={clsx(
                    classes.locationName,
                    locationIcon
                      ? classes.haveLocationIcon
                      : classes.noLocationIcon
                  )}
                >
                  {locationInfo && locationInfo.name}
                </div>
              </div>
            </div>
          ) : null}
        </DropzoneContainer>
      </div>
    </React.Fragment>
  );
};

export default DropzonePresenter;
