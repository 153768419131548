import React from 'react';
import { CustomizedTypography } from '@components/Customized';
import { useTranslation } from 'react-i18next';

interface Props {
  item?: any;
}

const HeadeTable: React.FC<Props> = ({ item }) => {
  const { t } = useTranslation();

  return (
    <>
      <CustomizedTypography
        className={item.className}
        variant="body2"
        color="textSecondary"
      >
        {t(item.name)}
      </CustomizedTypography>
    </>
  );
};

export default HeadeTable;
