import { makeStyles } from '@material-ui/core';
import { colorSet } from '@components/Provider';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  rootTabs: {
    display: 'flex',
    flex: '1 0 auto',
    marginLeft: 38,
    color: colorSet.gray900,
    height: 60,
    alignItems: 'center',
    '& .itemTab': {
      height: '100%',
      alignItems: 'center',
      display: 'flex',
      padding: '0 25px',
      marginBottom: '-1px',
      cursor: 'pointer'
    }
  },
  unReadCountTotal: {
    backgroundColor: colorSet.pink300,
    padding: '4.5px 6.5px',
    marginLeft: 4,
    color: `${colorSet.gray000} !important`,
    fontSize: 12,
    lineHeight: '10px',
    fontWeight: 400,
    borderRadius: 12
  },
  active: {
    borderBottom: `2px solid ${colorSet.gray800}`,
    height: '100%',
    alignItems: 'center',
    display: 'flex'
  }
}));
