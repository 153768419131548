import { useMutation } from '@apollo/client';
import Loading from '@components/Loading';
import { useSnackbar } from '@components/Provider';
import {
  CapaErrorCode,
  CapaServiceType,
  Language,
  useRegisterConnectUserMutation,
  useRegisterUserMutation,
  useRequestEmailVerificationCodeMutation
} from '@generated/Common/graphql';
import { CreateGroupWithCapa } from '@generated/Drive/MyDrive/graphql';
import useCommon from '@hooks/Common/useCommon';
import useMyDrive from '@hooks/Drive/MyDrive/useMyDrive';
import useAuth from '@hooks/useAuth';
import i18n from '@i18n/index';
import { useMediaQuery, useTheme } from '@material-ui/core';
import {
  LANGUAGE_LIST,
  REDIRECT_TO,
  groupPathName,
  groupRoomPathName
} from '@routes/Common/types';
import { reconnectSubscriptionClient, trimSpaces } from '@utils/common';
import { STORAGE_ACTION, setStorageItem } from '@utils/storage';
import queryString from 'query-string';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import AccountInfoPresenter from './AccountInfoPresenter';
import { AccountReq } from './types';

interface Props {
  showInModal?: boolean;
  onSuccess?: () => void;
  onGotoLogin?: () => void;
}
const AccountInfoContainer: React.FC<Props> = ({
  showInModal,
  onSuccess,
  onGotoLogin
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const history = useHistory();
  const [registerUser] = useRegisterUserMutation();
  const [requestEmailVerificationCode] =
    useRequestEmailVerificationCodeMutation();
  const [registerConnectUser] = useRegisterConnectUserMutation();
  const { search } = useLocation();
  const query = queryString.parse(search);
  const { onSetShowHideWelcomeDialog } = useCommon();
  const [createGroupWithCapa] = useMutation(CreateGroupWithCapa);
  const { onRefetchConnectUser } = useAuth();

  const goToGroupRoom = () => {
    history.push({
      pathname: `${groupRoomPathName}`,
      search: search,
      state: { prevLocation: window.location.href }
    });
  };

  const { onSetShowHideFileDetail } = useMyDrive(true);
  const onRegisterUser = async (req?: AccountReq) => {
    setIsLoading(true);
    let insertData: any = {
      email: req?.email,
      code: req?.code,
      password: req?.password,
      passwordConfirm: req?.password,
      inflowRoute: req?.inflowRoute,
      privacyAgree: req?.privacyAgree,
      serviceAgree: req?.serviceAgree,
      promotionAgree: req?.promotionAgree,
      service: CapaServiceType.Cloud
      // isWebMember: true
    };
    const registerUserResp = await registerUser({
      variables: {
        input: insertData
      }
    }).catch(() => {
      setIsLoading(false);
    });

    if (
      registerUserResp &&
      registerUserResp.data &&
      registerUserResp.data.register
    ) {
      if (!isMobile) {
        onSetShowHideFileDetail({
          isShowIsDetailFile: true
        });
        setStorageItem(STORAGE_ACTION.IS_OPEN_DETAIL_FILE, true);
      }
      if (
        registerUserResp.data.register.result === CapaErrorCode.Success &&
        registerUserResp.data.register.data
      ) {
        setStorageItem(
          STORAGE_ACTION.CONNECT_ACCESS_TOKEN,
          registerUserResp.data.register.data.accessToken
        );
        setStorageItem(
          STORAGE_ACTION.CONNECT_REFRESH_TOKEN,
          registerUserResp.data.register.data.refreshToken
        );
        const registerConnectUserResp = await registerConnectUser({
          variables: {
            userName: trimSpaces((req?.userName || '').trim()),
            userLanguage:
              LANGUAGE_LIST.filter(
                (item: any) => item.value === i18n.language
              )[0].name ?? Language.English,
            isWebMember: true
          },
          context: {
            headers: {
              authorization: `Bearer ${registerUserResp.data.register.data.accessToken}`
            }
          }
        });
        setIsLoading(false);

        if (
          registerConnectUserResp &&
          registerConnectUserResp.data &&
          registerConnectUserResp.data.registerConnectUser
        ) {
          if (
            registerConnectUserResp.data.registerConnectUser.result ===
            CapaErrorCode.Success
          ) {
            const accessToken =
              registerConnectUserResp.data.registerConnectUser.data
                ?.accessToken;
            const refreshToken =
              registerConnectUserResp.data.registerConnectUser.data
                ?.refreshToken;
            setStorageItem(STORAGE_ACTION.CONNECT_ACCESS_TOKEN, accessToken);
            setStorageItem(STORAGE_ACTION.CONNECT_REFRESH_TOKEN, refreshToken);
            reconnectSubscriptionClient(accessToken, refreshToken);
            setStorageItem(
              STORAGE_ACTION.USER_INFO,
              JSON.stringify({
                email: req?.email,
                isSaved: 'true',
                userName: trimSpaces((req?.userName || '').trim())
              })
            );
            setStorageItem(STORAGE_ACTION.LOGGED_IN, 'true');
            onSetShowHideWelcomeDialog({
              isShowWelcomeDialog: true
            });
            await createGroupWithCapa();
            await onRefetchConnectUser();
            if (query.redirect === REDIRECT_TO.GROUP_ROOM) {
              return goToGroupRoom();
            }
            if (onSuccess) {
              onSuccess && onSuccess();
              return;
            }
            history.push({
              pathname: groupPathName,
              state: {
                isSignUp: 'true'
              }
            });
          } else {
            enqueueSnackbar(
              `가입에 실패했습니다. 정보를 정확하게 입력했는지 확인해주세요.`,
              {
                variant: 'error'
              }
            );
          }
        }
      } else if (
        registerUserResp.data?.register.result ===
        CapaErrorCode.ParmeterValidationError
      ) {
        setIsLoading(false);
        enqueueSnackbar(
          '비밀번호는 영문+숫자 조합으로  8글자 이상 16글자 이하로 입력해주세요.',
          {
            variant: 'error'
          }
        );
      } else {
        setIsLoading(false);
        enqueueSnackbar(
          `가입에 실패했습니다. 정보를 정확하게 입력했는지 확인해주세요.`,
          {
            variant: 'error'
          }
        );
      }
    } else {
      setIsLoading(false);
      enqueueSnackbar(
        `가입에 실패했습니다. 정보를 정확하게 입력했는지 확인해주세요.`,
        {
          variant: 'error'
        }
      );
    }
  };

  const onRequestEmailVerificationCode = async (email?: string) => {
    if (!email) return false;

    try {
      const data = await requestEmailVerificationCode({
        variables: { email: email.trim() }
      });
      const result = data.data?.requestEmailVerificationCode;
      if (result?.result === CapaErrorCode.Success) {
        enqueueSnackbar(`인증번호 이메일이 발송되었습니다.`, {
          variant: 'success'
        });
        return true;
      } else {
        if (result?.result === CapaErrorCode.DuplicateEmail) {
          enqueueSnackbar('이미 가입된 이메일입니다.', {
            variant: 'error'
          });
        } else {
          enqueueSnackbar(`인증번호 이메일 발송에 실패했습니다.`, {
            variant: 'error'
          });
        }
        return false;
      }
    } catch (error) {
      enqueueSnackbar(`인증번호 이메일 발송에 실패했습니다.`, {
        variant: 'error'
      });
      return false;
    }
  };

  return (
    <React.Fragment>
      <Loading open={isLoading} />
      <AccountInfoPresenter
        onRequestEmailVerificationCode={onRequestEmailVerificationCode}
        onRegisterUser={onRegisterUser}
        onGotoLogin={onGotoLogin}
      />
    </React.Fragment>
  );
};

export default AccountInfoContainer;
