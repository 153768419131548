import { makeStyles, Menu, Popover } from '@material-ui/core';
import { colorSet } from '@components/Provider';
import styled from 'styled-components';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: 336,
    height: 'calc(100vh - 60px)',
    position: 'relative',
    boxShadow:
      '0px 8px 16px 2px rgba(97, 97, 97, 0.1), 0px 16px 32px 2px rgba(97, 97, 97, 0.1)',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  groupWraper: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 6px 8px 12px',
    [theme.breakpoints.down('xs')]: {
      paddingTop: 0
    }
  },
  apply: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '10px 16px',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
    textAlign: 'center',
    letterSpacing: '-0.1px',
    backgroundColor: colorSet.primary450,
    color: colorSet.gray000,
    textTransform: 'unset',
    borderRadius: 8,
    '&:hover': {
      backgroundColor: colorSet.primary600
    }
  },
  menuRoot: {
    marginTop: 40
  },
  selectOptionSelected: {
    display: 'none'
  },
  selectOption: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: '-0.1px',
    padding: '6px 6px 6px 12px',
    '&.Mui-selected': {
      backgroundColor: colorSet.gray000,
      display: 'flex',
      justifyContent: 'space-between',
      '& .selected': {
        display: 'block'
      }
    }
  },
  selectMenu: {
    marginTop: 4
  },
  groupSearch: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '4px 10px 4px 14px',
    borderRadius: 8,
    margin: '0 8px 4px',
    backgroundColor: colorSet.gray100,
    border: `1px solid ${colorSet.gray100}`,
    caretColor: colorSet.primary450,
    '&:hover': {
      backgroundColor: colorSet.gray000,
      border: `1px solid ${colorSet.gray400}`
    },
    '& input::placeholder': {
      color: colorSet.gray700
    },
    '& .icon_search': {
      flexShink: 0,
      display: 'flex',
      alignItems: 'center',
      marginRight: 8
    },
    '& .input_search': {
      fontWeight: 400,
      width: '100%',
      padding: 0,
      fontSize: 14,
      letterSpacing: '-0.1px',
      '& input': {
        width: '100%',
        border: 'none',
        lineHeight: '30px',
        background: 'none'
      }
    },
    '& .icon_filter': {
      flexShink: 0,
      padding: '6px',
      display: 'flex',
      marginLeft: 8,
      alignItems: 'center',
      '&.active svg path': {
        fill: colorSet.primary450
      }
    }
  },
  groupFile: {
    flex: '1 0 auto',
    fontSize: 14,
    fontWeight: 'bold',
    color: colorSet.gray900,
    '& span': {
      color: colorSet.gray700,
      fontWeight: 'normal',
      marginLeft: 4
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16
    }
  },
  groupAdd: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& span': {
      color: colorSet.gray900,
      fontSize: 14,
      fontWeight: 500
    },
    '& svg': {
      fontSize: 20,
      marginRight: 1
    }
  },
  searchResultTitle: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: '-0.1px',
    color: colorSet.gray900,
    padding: '0 4px',
    margin: '12px 8px 8px 8px'
  },
  emptyFilesSearchTitle: {
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: '-0.1px',
    color: colorSet.gray900
  },
  filterChips: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    flexWrap: 'wrap',
    margin: '0 8px 12px 8px'
  },
  filterChipItem: {
    fontWeight: 500,
    flexShrink: 0,
    width: 'fit-content',
    background: colorSet.gray100,
    borderRadius: 4,
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: '-0.1px',
    padding: '6px 6px 6px 12px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      flexShrink: 0
    },
    '& .close': {
      padding: 0,
      marginLeft: 8
    }
  },
  collapseButton: {
    position: 'absolute',
    borderTop: `1px solid ${colorSet.gray400}`,
    borderRight: `1px solid ${colorSet.gray400}`,
    borderBottom: `1px solid ${colorSet.gray400}`,
    top: '50%',
    transform: 'translate(0, -50%)',
    cursor: 'pointer',
    backgroundColor: colorSet.gray000,
    borderRadius: '0px 8px 8px 0px',
    right: -28,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 28,
    height: 56,
    '& svg': {
      transition: '0.3s',
      transform: 'scaleX(-1)'
    },
    '&.expanded svg': {
      transform: 'scaleX(1)'
    },
    '&:hover': {
      boxShadow: 'inset 999px 999px 0px rgba(0, 0, 0, 0.08)'
    }
  },
  sortBy: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    height: 32,
    padding: '8px 6px',
    borderRadius: 8,
    textTransform: 'unset',
    '& span': {
      color: colorSet.gray900,
      fontSize: 13,
      lineHeight: '18px',
      fontWeight: 400,
      marginRight: 4
    },
    '& svg': {
      transform: 'scaleY(1)',
      transition: '300ms all',
      '&.desc': {
        transform: 'scaleY(-1)'
      }
    }
  },
  listItem: {
    maxHeight: 'calc(100vh - 166px)',
    '&.search-mode': {
      maxHeight: 'calc(100vh - 206px)'
    },
    '&.has-filter-type': {
      maxHeight: 'calc(100vh - 238px)'
    },
    [theme.breakpoints.down('xs')]: {
      height: '70vh'
    },
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: 4
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: colorSet.gray600,
      borderRadius: 8
    }
  },
  item: {
    cursor: 'pointer',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    padding: '10px 8px 10px 20px',
    margin: '0 8px',
    overflow: 'hidden',
    borderRadius: 8,
    '& .table-content': {
      display: 'grid',
      marginLeft: 12,
      width: '100%',
      '& div': {
        overflow: 'hidden',
        textAlign: 'left'
      }
    },
    '& .menu-function': {
      width: 0,
      flexShrink: 0,
      display: 'flex',
      transition: '0.1s',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& svg path': {
        fill: colorSet.gray2000
      }
    },
    '& .comment-icon': {
      position: 'absolute',
      left: 3,
      top: 5,
      '& svg': {
        width: 14,
        height: 14,
        '& path': {
          fill: colorSet.gray2000
        }
      }
    },
    '& .menu-function-item': {
      width: 32,
      height: 32,
      display: 'none',
      background: colorSet.gray000,
      border: `1px solid ${colorSet.gray400}`,
      boxShadow:
        '0px 2px 4px -1px rgba(0, 0, 0, 0.1), 0px 4px 5px rgba(0, 0, 0, 0.07), 0px 1px 10px rgba(0, 0, 0, 0.06)',
      borderRadius: 50
    },
    '& .MuiIconButton-root': {
      padding: 4
    },
    '&:hover': {
      background: colorSet.gray200,
      '& .menu-function': {
        width: 68,
        '& .menu-function-item': {
          display: 'block',
          '&:hover': {
            boxShadow: 'inset 999px 999px 0px rgba(0, 0, 0, 0.08)'
          }
        }
      }
    },
    '& .menu-function.show-more': {
      width: 68,
      '& .menu-function-item': {
        display: 'block',
        '&:hover': {
          boxShadow: 'inset 999px 999px 0px rgba(0, 0, 0, 0.08)'
        }
      }
    }
  },
  comment: {
    fontSize: 20,
    marginBottom: 15,
    marginRight: 2,
    color: colorSet.gray500,
    position: 'absolute',
    left: '-22px',
    top: 0
  },
  emptyFilesShared: {
    marginTop: 76,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  emptyFilesTitle: {
    color: colorSet.gray900,
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '-0.1px',
    marginBottom: 2
  },
  emptyFilesDescription: {
    color: colorSet.gray700,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '-0.1px'
  },
  menuMore: {
    marginTop: 8
  },
  loadingWrapper: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 54,
    height: 54,
    borderRadius: 8,
    '& svg': {
      width: 32,
      height: 32
    }
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    alignItems: 'center'
  },
  image: {
    position: 'relative',
    display: 'flex',
    borderRadius: 4,
    alignItems: 'center',
    background: colorSet.gray000,
    outline: `1px solid ${colorSet.gray200}`,
    '& .image-icon img': {
      border: 'none'
    },
    '& .image-icon div': {
      border: 'none'
    },
    '& .badge': {
      position: 'absolute',
      bottom: 3,
      left: 3
    },
    '& .image-file': {
      width: 56,
      height: 56,
      borderRadius: 4
    },
    '& .icon-svg>div': {
      display: 'flex',
      alignItem: 'center',
      justifyContent: 'center',
      width: 56,
      height: 56,
      borderRadius: 4,
      overflow: 'hidden',
      '& img': {
        width: '100%',
        height: 'auto',
        '&.unknown': {
          width: 32,
          height: 'auto'
        }
      }
    },
    '& .icon-svg': {
      width: 56,
      height: 56,
      borderRadius: 4,
      overflow: 'hidden',
      '& img': {
        width: '100%',
        height: 'auto'
      }
    },
    '& .icon-svg-file-rar': {
      width: 56,
      height: 56,
      borderRadius: 4,
      border: `1px solid ${colorSet.gray200}`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& img': {
        width: '32px',
        height: 'auto',
        objectFit: 'scale-down'
      }
    }
  },
  activeFile: {
    background: colorSet.primary200,
    '&:hover': {
      background: colorSet.primary200
    },
    '& .image-icon': {
      outline: '2px solid #4B88FF'
    }
  },
  lineBottom: {
    background: colorSet.gray300,
    width: '100%',
    margin: '8px 0',
    height: 1
  },
  fileSize: {
    color: colorSet.gray900
  },
  dot: {
    margin: '0 2px',
    width: 8,
    display: 'inline-flex',
    alignItem: 'center',
    justifyContent: 'center'
  },
  fileInfo: {
    overflow: 'hidden',
    color: colorSet.gray900,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: 13,
    lineHeight: '17.94px'
  },
  sharedTime: {
    overflow: 'hidden',
    color: colorSet.gray900,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: 13,
    lineHeight: '17.94px'
  },
  controlButtonList: {
    marginRight: 26
  },
  capaIcon: {
    marginLeft: 22,
    cursor: 'pointer'
  }
}));

export const FilterSearch = styled(Popover)`
  ${({ theme }) => `
  & .MuiPaper-root {
    border-radius: 8px;
    min-width: 218px;
    min-height: 160px;
    padding: 20px 16px;
    box-shadow: 0px 8px 16px 2px rgba(97, 97, 97, 0.1), 0px 16px 32px 2px rgba(97, 97, 97, 0.1);

    & .title {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.1px;
      margin-bottom: 8px;
    }
    & .MuiOutlinedInput-root {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.1px;
      color: ${colorSet.gray900};
      &:hover .MuiOutlinedInput-notchedOutline {
        border-color: transparent;
      }
    }
    & .select-type {
      margin-bottom: 20px;
      border-radius: 4px;
      color: ${colorSet.gray900};
      background: ${colorSet.gray100};
      &:hover {
        background: ${colorSet.gray200};
      }
      & .MuiOutlinedInput-inputMarginDense {
        padding-top: 6px;
        padding-bottom: 6px;
        padding-left: 12px;
      }
      & .MuiOutlinedInput-notchedOutline {
        border-color: transparent
      }
    }
    & fieldset {
      border-color: transparent;
    }
    & .select-option {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.1px;
      padding: 6px 12px;
    }
    & .select-icon {
      padding: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      right: 0;
      position: absolute;
      pointer-events: none;
      & svg {
        position: relative;
        touch-action: none;
        right: 0;
        transition: 300ms;
      }
    }

    & .MuiMenuItem-root {
      padding: 8px 16px;
      line-height: 20px;
      & svg {
        width: 20px;
        height: 20px;
        margin-right: 12px;
      }
    }
  `}
`;
export const AttachFileButtonMenu = styled(Menu)`
  &.MuiPopover-root {
    z-index: 1300 !important;
  }
  ${({ theme }) => `
  & .MuiPaper-root {
    ${theme.breakpoints.down('xs')} {
      left: 0 !important;
      top: auto !important;
      bottom: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      width: 100%;
      max-width: 100% !important;
    }
    border-radius: 6px;
    width: 256px;
    box-shadow:
      0px 5px 5px -3px rgba(0, 0, 0, 0.1), 0px 8px 10px 1px rgba(0, 0, 0, 0.07), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);

    & .MuiMenu-list {
      padding: '8px 0 !important'
      & .react-contextmenu-item {
         display: flex;
         align-items: center;
         padding: 8px 16px;
         cursor: pointer;
         & svg {
           margin-right: 12px;
         }
      }
    }

    & .MuiMenuItem-root {
      padding: 8px 16px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.1px;
      color: ${colorSet.gray900};
      & svg {
        width: 20px;
        height: 20px;
        margin-right: 12px;
      }
      & .MuiSvgIcon-root {
        font-size: 18px;
        margin-right: 10px;
      }
    }
  }
  `}
`;

export const ContextMenuTable = styled.div`
  .popup {
    list-style-type: none;
    margin: 0;
    outline: none;
    text-align: left;
    overflow: hidden;
    min-width: 343px;
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 10%),
      0px 8px 10px 1px rgb(0 0 0 / 7%), 0px 3px 14px 2px rgb(0 0 0 / 6%);
    border-radius: 8px;
    background-color: #ffffff;
    width: 230px;
    padding: 10px 0;
  }

  .popup li {
    color: #343a40;
    cursor: pointer;
    font-size: 16px;
    font-weight: normal;
    line-height: 22px;
    margin: 0;
    padding: 5px 12px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    padding: 20px 17px;
    border-bottom: 1px solid #f1f3f5;
    &:hover {
      background-color: ${colorSet.gray200};
    }
  }
  .popup li:last-child {
    border-bottom: none;
  }
  .popup li > svg {
    margin-right: 10px;
  }

  .popup li > i {
    margin-right: 8px;
  }
`;
