import i18n from '../i18n';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { LANGUAGE_VALUE } from '@routes/Common/ChangeLanguage/types';
import { FORMAT_DATE_TIME } from '@routes/Common/types';

dayjs.extend(relativeTime);

export function UtcToKst(value: Date): Date {
  const time = value.getTime();
  const KSTOffset = -(value.getTimezoneOffset() * 60 * 1000);
  return new Date(time + KSTOffset);
}

/**
 * UTC Date를 원하는 포맷, KST로 변경해준다.
 * @param value 변환할 원본 데이터
 * @param format 원하는 포맷
 */
export const formatDateUTC = (
  value: string,
  format: string,
  isNonZero?: boolean
): string => {
  // UTC -> KST
  const originDate = new Date(value);
  const time = originDate.getTime();
  const KSTOffset = -(originDate.getTimezoneOffset() * 60 * 1000);
  const date = new Date(time + KSTOffset);

  const year = date.getFullYear();
  let month = '' + (date.getMonth() + 1),
    day = '' + date.getDate(),
    hour = '' + date.getHours(),
    minutes = '' + date.getMinutes();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  if (hour.length < 2) hour = isNonZero ? hour : '0' + hour;
  if (minutes.length < 2) minutes = '0' + minutes;

  const result = format
    .replace('YYYY', String(year))
    .replace('MM', String(month))
    .replace('DD', String(day))
    .replace('HH', String(hour))
    .replace('mm', String(minutes));

  return result;
};

/**
 * @param value 변환할 원본 데이터
 * @param format 원하는 포맷
 */
export const formatDate = (
  value: string | number,
  format: string,
  isNonZero?: boolean
): string => {
  const date = new Date(value);

  const year = date.getFullYear();
  let month = '' + (date.getMonth() + 1),
    day = '' + date.getDate(),
    hour = '' + date.getHours(),
    minutes = '' + date.getMinutes();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  if (hour.length < 2) hour = isNonZero ? hour : '0' + hour;
  if (minutes.length < 2) minutes = '0' + minutes;

  const result = format
    .replace('YYYY', String(year))
    .replace('MM', String(month))
    .replace('DD', String(day))
    .replace('HH', String(hour))
    .replace('mm', String(minutes));

  return result;
};

/**
 * 숫자를 통화 포맷으로 변경해준다.
 * @param value 변환할 원본 데이터
 */
export const formatCurrency = (value?: number): string => {
  if (!value || isNaN(value)) return '';
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

/**
 * fileSize를 맞는 단위로 변경해준다.
 * @param value 변환할 원본 데이터
 */
export const formatFileSize = (value?: number): string => {
  if (!value) return '';

  const unit = ['bytes', 'kb', 'mb', 'gb', 'tb', 'pb'];
  const convert = Math.floor(Math.log(value) / Math.log(1024));
  return `${(value / Math.pow(1024, convert)).toFixed(2)} ${unit[convert]}`;
};

export const diffMinutes = (value: string | number) => {
  const originDate = new Date(value);
  const time = originDate.getTime();
  const KSTOffset = -(originDate.getTimezoneOffset() * 60 * 1000);
  const date = new Date(time + KSTOffset);
  const today = new Date();
  return Math.floor((today.getTime() - date.getTime()) / 1000 / 60);
};

export const timeForToday = (
  value: string,
  type?: string,
  isNonZero?: boolean
) => {
  const today = new Date();
  const todayMidnight = new Date();
  todayMidnight.setHours(0);
  todayMidnight.setMinutes(0);
  todayMidnight.setSeconds(0);
  todayMidnight.setMilliseconds(0);
  // UTC -> KST
  const timeValue = UtcToKst(new Date(value));

  const betweenTime = Math.floor(
    (today.getTime() - timeValue.getTime()) / 1000 / 60
  );

  const betweenTimeDay = Math.floor(betweenTime / 60 / 24);
  const betweenTimeHour = Math.floor(betweenTime / 60);

  if (
    type === 'RequestList' &&
    (todayMidnight.getTime() - timeValue.getTime()) / 1000 / 60 / 60 < 24 &&
    (todayMidnight.getTime() - timeValue.getTime()) / 1000 / 60 / 60 > 0
  ) {
    return '어제';
  }

  if (type === 'Notification') {
    if (betweenTime < 1) return '방금전';

    if (betweenTime < 60) {
      return `${betweenTime}분전`;
    }

    if (betweenTimeHour < 24) {
      return `${betweenTimeHour}시간전`;
    }

    if (betweenTimeDay < 365) {
      return `${betweenTimeDay}일전`;
    }
  }

  if (betweenTimeDay < 1) {
    if (timeValue.getHours() < 12) {
      if (timeValue.getHours() === 0) {
        return formatDateUTC(value, '오전 12:mm', isNonZero);
      }
      return formatDateUTC(value, '오전 HH:mm', isNonZero);
    }

    timeValue.setHours(timeValue.getHours() - 12);
    if (timeValue.getHours() === 0) {
      return formatDate(timeValue.toString(), '오후 12:mm', isNonZero);
    }

    return formatDate(timeValue.toString(), '오후 HH:mm', isNonZero);
  }

  if (betweenTimeDay < 365) return formatDateUTC(value, 'MM월 DD일');

  return formatDateUTC(value, 'YYYY.MM.DD');
};

export const timeForTodayNewVer = (
  value: string | number,
  type?: string,
  isNonZero?: boolean
) => {
  const today = new Date();
  const todayMidnight = new Date();
  todayMidnight.setHours(0);
  todayMidnight.setMinutes(0);
  todayMidnight.setSeconds(0);
  todayMidnight.setMilliseconds(0);
  // UTC -> KST
  const timeValue = new Date(value);

  const betweenTime = Math.floor(
    (today.getTime() - timeValue.getTime()) / 1000 / 60
  );

  const betweenTimeDay = Math.floor(betweenTime / 60 / 24);
  const betweenTimeHour = Math.floor(betweenTime / 60);

  if (
    type === 'RequestList' &&
    (todayMidnight.getTime() - timeValue.getTime()) / 1000 / 60 / 60 < 24 &&
    (todayMidnight.getTime() - timeValue.getTime()) / 1000 / 60 / 60 > 0
  ) {
    return '어제';
  }

  if (type === 'Notification') {
    if (betweenTime < 1) return '방금전';

    if (betweenTime < 60) {
      return `${betweenTime}분전`;
    }

    if (betweenTimeHour < 24) {
      return `${betweenTimeHour}시간전`;
    }

    if (betweenTimeDay < 365) {
      return `${betweenTimeDay}일전`;
    }
  }

  if (betweenTimeDay < 1) {
    if (timeValue.getHours() < 12) {
      if (timeValue.getHours() === 0) {
        return formatDate(value, '오전 12:mm', isNonZero);
      }
      return formatDate(value, '오전 HH:mm', isNonZero);
    }

    timeValue.setHours(timeValue.getHours() - 12);
    if (timeValue.getHours() === 0) {
      return formatDate(timeValue.toString(), '오후 12:mm', isNonZero);
    }

    return formatDate(timeValue.toString(), '오후 HH:mm', isNonZero);
  }

  if (betweenTimeDay < 365) return formatDate(value, 'MM월 DD일');

  return formatDate(value, 'YYYY.MM.DD');
};

export const timeForPM = (value: string) => {
  const result = formatDateUTC(value, 'YYYY.MM.DD');
  // UTC -> KST
  const timeValue = UtcToKst(new Date(value));

  if (timeValue.getHours() < 12) {
    return result + formatDateUTC(value, ' 오전 HH:mm');
  }

  timeValue.setHours(timeValue.getHours() - 12);
  if (timeValue.getHours() === 0) {
    return result + formatDate(timeValue.toString(), ' 오후 12:mm');
  }

  return result + formatDate(timeValue.toString(), ' 오후 HH:mm');
};

export const formatHourAMPM = (date: any) => {
  const newDate = new Date();
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  let toDate = new Date(date);
  const day = toDate.getDate();
  const month = toDate.toDateString().substr(4, 3);
  if (date.getDate() === newDate.getDate()) {
    return hours + ':' + minutes + ' ' + ampm;
  } else if (date.getDate() === newDate.getDate() - 1) {
    return i18n.t('time_stamp.yesterday');
  } else {
    return month + ' ' + day;
  }
};

export const diffHour = (date: any) => {
  const internalDate = dayjs(date).format(FORMAT_DATE_TIME);
  return dayjs().diff(internalDate, 'hours');
};

export const formatTimeStamp = (
  date: any,
  is24hrAfter: boolean,
  is24hrGroupListAfter: boolean,
  is48hrAfter: boolean,
  isAfterAYear: boolean
) => {
  const newDate = new Date();
  const internalDate: any = new Date(date);
  let hours = internalDate.getHours();
  let minutes = internalDate.getMinutes();
  let ampm = hours >= 12 ? i18n.t('time_stamp.pm') : i18n.t('time_stamp.am');
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  let toDate = new Date(date);
  const day = toDate.getDate();
  const month = toDate.toDateString().substr(4, 3);
  const hourText =
    i18n.language === LANGUAGE_VALUE.EN
      ? `${hours}:${minutes} ${ampm}`
      : `${ampm} ${hours}:${minutes}`;
  const monthDayText =
    i18n.language === LANGUAGE_VALUE.EN
      ? `${month} ${day}`
      : `${internalDate.getMonth() + 1}${i18n.t(
          'time_stamp.month'
        )} ${internalDate.getDate()}${i18n.t('time_stamp.day')}`;
  const afterAYearText =
    i18n.language === LANGUAGE_VALUE.EN
      ? `${month} ${day}, ${internalDate.getFullYear()}`
      : `${internalDate.getFullYear()}. ${
          internalDate.getMonth() + 1
        }. ${internalDate.getDate()} `;

  if (Math.floor(diffHour(date) / 24) === 0) {
    return hourText;
  }

  if (
    internalDate.getDate() === newDate.getDate() - 1 &&
    is24hrGroupListAfter
  ) {
    return i18n.t('time_stamp.yesterday');
  }

  if (newDate.getFullYear() - internalDate.getFullYear() > 0 && isAfterAYear) {
    return afterAYearText;
  }

  if (Math.floor(diffHour(date) / 24) >= 1 && is24hrAfter) {
    return monthDayText;
  }

  if (Math.floor(diffHour(date) / 24) >= 2 && is48hrAfter) {
    return monthDayText;
  }

  return hourText;
};

export const repeatForColor = (arr: any, size: any) => {
  let newArr = new Array(size);

  for (let i = 1; i < size; i++) {
    newArr[i] = arr[i % arr.length];
  }

  newArr[0] = arr[0];
  return newArr;
};

export const uniqueArray = (array: any, key: string) => {
  return array
    .map((item: any) => item[key])
    .map(
      (item: any, index: number, finalArray: any) =>
        finalArray.indexOf(item) === index && index
    )
    .filter((obj: any) => array[obj])
    .map((item: any) => array[item]);
};

export const formatDateTimeAgo = (date: any) => {
  return dayjs(date).fromNow();
};
