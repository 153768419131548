import { useMutation } from '@apollo/client';
import { CapaRegisterConnectUserLogo } from '@assets';
import { CustomizedButton } from '@components/Customized';
import AppConfig from '@config/index';
import {
  CapaErrorCode,
  Language,
  useFetchConnectUserQuery
} from '@generated/Common/graphql';
import { CapaActivityType } from '@generated/Common/types';
import {
  CreateGroupWithCapa,
  RegisterConnectUser
} from '@generated/Drive/MyDrive/graphql';
import useCommon from '@hooks/Common/useCommon';
import useAuth from '@hooks/useAuth';
import useUserActionLog, {
  Enum_Action_Button_Enum,
  Enum_Action_Input_Enum,
  Enum_Action_Page_Enum
} from '@hooks/useUserActionLog';
import i18n from '@i18n/index';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { Done } from '@material-ui/icons';
import { LANGUAGE_VALUE } from '@routes/Common/ChangeLanguage/types';
import {
  LANGUAGE_LIST,
  blockSpecialRegex,
  groupPathName,
  loginPathName
} from '@routes/Common/types';
import {
  checkOnlySpaces,
  reconnectSubscriptionClient,
  trimSpaces
} from '@utils/common';
import {
  STORAGE_ACTION,
  clearStorageAll,
  getStorageItem,
  setStorageItem
} from '@utils/storage';
import clsx from 'clsx';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useStyles } from './styles';

interface Props {
  isOpen?: boolean;
  onClose?: () => void;
  callBack?: () => void;
  notRedirect?: boolean;
}

const RegisterUserContainer = (props: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const history = useHistory();
  const classes = useStyles();
  const { isOpen, onClose, callBack, notRedirect } = props;
  const { t } = useTranslation();
  const [nameValue, setNameValue] = useState('');
  const [isChecked, setChecked] = useState<boolean>(false);
  const [registerConnectUser] = useMutation(RegisterConnectUser);
  const [createGroupWithCapa] = useMutation(CreateGroupWithCapa);
  const accessToken = getStorageItem(STORAGE_ACTION.CONNECT_ACCESS_TOKEN);
  const refreshToken = getStorageItem(STORAGE_ACTION.CONNECT_REFRESH_TOKEN);
  const { refetch: fetchConnectUserRefetch } = useFetchConnectUserQuery({
    skip: !accessToken || !refreshToken
  });
  const { search } = useLocation();
  const query = queryString.parse(search);
  const { onInsertUserActionLogOne } = useUserActionLog();
  const languageValue = i18n.language;
  const { onSetShowHideWelcomeDialog, onSetUnReadCountGroupList } = useCommon();
  const { onInitAuthState } = useAuth();
  const [isValidMaxLength, setValidMaxLength] = useState<boolean>(true);
  const [isValidSpecial, setValidSpecial] = useState<boolean>(true);

  const onSubmit = async () => {
    if (blockSpecialRegex.test(nameValue)) {
      setValidSpecial(false);
      return;
    }
    onInsertUserActionLogOne(
      Enum_Action_Button_Enum.Button_RegisterConnect_Done,
      CapaActivityType.ButtonClick
    );
    registerConnectUser({
      variables: {
        userName: trimSpaces(nameValue),
        userLanguage:
          LANGUAGE_LIST.filter((item: any) => item.value === languageValue)[0]
            .name ?? Language.English,
        isWebMember: true
      }
    }).then(async (res) => {
      localStorage.removeItem(STORAGE_ACTION.CONNECT_USER_RESULT);
      if (res.data.registerConnectUser.result === CapaErrorCode.Success) {
        setStorageItem(
          STORAGE_ACTION.CONNECT_ACCESS_TOKEN,
          res.data.registerConnectUser.data.accessToken
        );
        setStorageItem(
          STORAGE_ACTION.CONNECT_REFRESH_TOKEN,
          res.data.registerConnectUser.data.refreshToken
        );
        reconnectSubscriptionClient(
          res.data.registerConnectUser.data.accessToken,
          res.data.registerConnectUser.data.refreshToken
        );

        const fetchConnectUserRefetchResp = await fetchConnectUserRefetch();
        if (
          fetchConnectUserRefetchResp &&
          fetchConnectUserRefetchResp.data &&
          fetchConnectUserRefetchResp.data.fetchConnectUser
        ) {
          const {
            result: fetchConnectUserRefetchRespResult,
            data: fetchConnectUserRefetchRespData
          } = fetchConnectUserRefetchResp.data.fetchConnectUser;

          if (
            fetchConnectUserRefetchRespResult === CapaErrorCode.Success &&
            fetchConnectUserRefetchRespData
          ) {
            setStorageItem(STORAGE_ACTION.LOGGED_IN, 'true');
            onSetShowHideWelcomeDialog({
              isShowWelcomeDialog: true
            });
            onClose && onClose();
            await createGroupWithCapa();
            if (query.chatroomId && query.groupId) {
              return history.go(0);
            }
            callBack && callBack();
            if (notRedirect) return;
            return history.push(groupPathName);
          }
        }
      }
    });
  };

  const onClickLogOut = () => {
    onInitAuthState();
    clearStorageAll();
    history.push(loginPathName);
    onSetUnReadCountGroupList({
      unReadCountGroupList: 0
    });
  };

  useEffect(() => {
    if (nameValue.length > 50) {
      setValidMaxLength(false);
      return;
    }

    if (!blockSpecialRegex.test(nameValue)) {
      setValidSpecial(true);
    }

    setValidMaxLength(true);
  }, [nameValue]);

  useEffect(() => {
    if (isOpen)
      onInsertUserActionLogOne(
        Enum_Action_Page_Enum.Page_RegisterConnect,
        CapaActivityType.PageVisit
      );
  }, [isOpen]);

  return (
    <Dialog
      classes={{
        paper: classes.paper
      }}
      open={isOpen!}
      onClick={(e) => {
        e.stopPropagation();
      }}
      fullScreen={isMobile}
    >
      {isMobile ? (
        <DialogTitle classes={{ root: classes.rootTitle }} disableTypography>
          <CustomizedButton
            type="submit"
            size="small"
            className={classes.logOutButton}
            onClick={() => onClickLogOut()}
          >
            {t('register_connect_user.log_out')}
          </CustomizedButton>
        </DialogTitle>
      ) : null}

      <DialogContent className={classes.csDialogContent}>
        <img
          className={classes.image}
          src={CapaRegisterConnectUserLogo}
          alt="logo"
        />
        <div className={classes.title}>
          <Trans i18nKey={'register_connect_user.introducing'} />
        </div>
        <div className={classes.content}>
          {t('register_connect_user.capa_cloud_has_been_renewed')}
        </div>
        <div className={classes.content}>
          {t('register_connect_user.please_enter_your_name')}
        </div>
        <div className={classes.name}>{t('register_connect_user.name')}</div>
        <TextField
          value={nameValue ?? undefined}
          placeholder=""
          variant="outlined"
          size="small"
          onChange={(event) => setNameValue && setNameValue(event.target.value)}
          onFocus={() => {
            onInsertUserActionLogOne(
              Enum_Action_Input_Enum.Input_RegisterConnect_Name,
              CapaActivityType.actionInput
            );
          }}
          className={
            !isValidMaxLength ||
            (nameValue.length > 0 && checkOnlySpaces(nameValue)) ||
            !isValidSpecial
              ? classes.error
              : ''
          }
          fullWidth
        />
        {!isValidMaxLength ||
        (nameValue.length > 0 && checkOnlySpaces(nameValue)) ||
        !isValidSpecial ? (
          <p className={classes.validMaxLength}>
            {t('account_page.valid_username_max_length')}
          </p>
        ) : null}
        <div className={classes.checkboxWrapper}>
          <Done
            className={clsx(
              classes.doneIcon,
              isChecked ? classes.activeColorDoneIcon : classes.colorDoneIcon
            )}
            onClick={() => setChecked(!isChecked)}
          />
          {!languageValue || languageValue === LANGUAGE_VALUE.KO ? (
            <>
              <div
                className={classes.connectPoliciesText}
                onClick={() => {
                  window.open(AppConfig.capaConnectsServicePoliciesUrl);
                }}
              >
                {t('register_connect_user.capa_connect_service_policies')}
              </div>
              <div className={classes.iAgreeTo}>
                {t('register_connect_user.i_agree_to')}
              </div>
            </>
          ) : (
            <>
              <div className={clsx(classes.iAgreeTo, 'mr-4')}>
                {t('register_connect_user.i_agree_to')}
              </div>
              <div
                className={classes.policiesText}
                onClick={() => {
                  window.open(AppConfig.capaConnectsServicePoliciesUrl);
                }}
              >
                {t('register_connect_user.capa_connect_service_policies')}
              </div>
            </>
          )}
        </div>
      </DialogContent>

      <DialogActions classes={{ root: classes.action }}>
        <div className={classes.wrapperBtn}>
          <CustomizedButton
            type="submit"
            variant="contained"
            color="primary"
            onClick={onSubmit}
            disabled={
              !isChecked || checkOnlySpaces(nameValue) || !isValidMaxLength
            }
          >
            {t('register_connect_user.start_button')}
          </CustomizedButton>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default RegisterUserContainer;
