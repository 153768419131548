import * as Apollo from '@apollo/client';
import {
  IBMAdd,
  IBMCAD,
  IBMCheckbox,
  IBMCheckboxChecked,
  IBMCheckboxUndeterminate,
  IBMChevronBreadcrumb,
  IBMDocumentAdd,
  IBMFolder,
  IBMFolderAdd,
  IBMMultiUser
} from '@assets';
import {
  CustomizedCheckBox,
  CustomizedTypography
} from '@components/Customized';
import IconThumbnail from '@components/IconThumbnail/IconThumbnail';
import Loading from '@components/LoadingInComponent';
import { UploadStatusPopper } from '@components/Popper';
import Portal from '@components/Portal';
import { useSnackbar } from '@components/Provider';
import { CapaConnectMyFile, CapaErrorCode } from '@generated/Common/graphql';
import { CapaActivityType } from '@generated/Common/types';
import {
  MoveConnectFile,
  SubscribeFileStatusUpdate,
  useCreateConnectFolderMutation,
  useRenameConnectFileMutation
} from '@generated/Drive/MyDrive/graphql';
import useMyDrive from '@hooks/Drive/MyDrive/useMyDrive';
import useAuth from '@hooks/useAuth';
import useDashboard from '@hooks/useDashboard';
import useUserActionLog, {
  Enum_Action_Button_Enum
} from '@hooks/useUserActionLog';
import {
  Breadcrumbs,
  Checkbox,
  IconButton,
  Link,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import MuiMenuItem from '@material-ui/core/MenuItem';
import { InboxOutlined } from '@material-ui/icons';
import { DRAWING_FILE_TYPES } from '@routes/Common/Dropzone/types';
import DetailFile from '@routes/Common/LayoutDashboard/DetailFile';
import {
  CAPA_FILE_CONVERT_STATUS,
  DEFAULT_ITEMS_LIMIT,
  KEY_TYPE,
  myDrivePathName
} from '@routes/Common/types';
import { IS_MAC_OS, isChildrenPage } from '@routes/Common/utils';
import DashboardHeaderTable from '@routes/Dashboard/DashboardHeadeTable';
import FolderFormDialog from '@routes/Dashboard/Dialog/FolderForm/FolderForm';
import ShareContainerDialog from '@routes/Dashboard/Dialog/Share/ShareContainer';
import { LINK_STATUS } from '@routes/Dashboard/Dialog/Share/ShareLink/types';
import {
  SHARE_DIALOG_SCREEN,
  SHARE_FILES_TO_VALUE
} from '@routes/Dashboard/Dialog/Share/types';
import Drag from '@routes/Dashboard/Draggable/Drag';
import Drop from '@routes/Dashboard/Draggable/Drop';
import { DROP_EFFECT } from '@routes/Dashboard/Draggable/types';
import TabPanelControl from '@routes/Dashboard/TabPanel/TabPanelControl';
import {
  DASHBOARD_DROPZONE_WRAPPER_ID,
  LIST_CONTROL_TYPE,
  LIST_CONTROL_TYPE_MULTI_FOLDER,
  LIST_TABLE_HEADER
} from '@routes/Dashboard/types';
import ViewerFile from '@routes/Viewer/ViewerFile';
import { convertSubstring } from '@utils/file';
import { formatFileSize, formatTimeStamp, uniqueArray } from '@utils/formatter';
import { STORAGE_ACTION, getStorageItem } from '@utils/storage';
import clsx from 'clsx';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ContextMenu, ContextMenuTrigger, MenuItem } from 'react-contextmenu';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { LongPressDetectEvents, useLongPress } from 'use-long-press';
import { ReactComponent as IBMInfomation } from '../../assets/icon/IBMInfomation.svg';
import { BottomNavMenu, Dropzone, GnbAppBar, LayoutDashboard } from '../Common';
import { ReactComponent as IBMLink } from './../../assets/icon/IBMLink.svg';
import DashboardAsideMenuPresenter from './DashboardAsideMenuPresenter';
import DashboardEmptyPresenter from './DashboardEmptyPresenter';
import DashboardListControlBtn from './DashboardListControlBtn';
import {
  ContextMenuTable,
  MuiBoxTable,
  MuiBoxTableItem,
  MuiBoxTableItemMobile,
  MuiDiv,
  MuiNewButtonMobile,
  NewButtonMenu,
  useStyles
} from './styles';
import {
  DEFAULT_FOLDER_NAME,
  DEFAULT_ROUTER_MY_DRIVE,
  EMPTY_SIZE_FOLDER,
  EnumDashboardDialogType,
  EnumDashboardListControlType,
  EnumListControl,
  FOLDER_TYPE,
  LIST_CONTROL_TYPE_FOLDER,
  LIST_CONTROL_TYPE_MULTIPLE_FILE,
  LongPressActionTimeOut,
  URL_PARAM
} from './types';

interface Props {
  onDownloadFiles: (files?: Array<CapaConnectMyFile>) => Promise<void>;
  onDeleteFiles: (files?: Array<CapaConnectMyFile>) => Promise<void>;
  callback?: () => void;
}

const DashboardMyDrivePresenter: React.FC<Props> = ({
  onDownloadFiles,
  onDeleteFiles,
  callback
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const [checkedFiles, setCheckedFiles] = useState<Array<CapaConnectMyFile>>(
    []
  );
  const [isSelect, setIsSelect] = useState(false);
  const [isCheckItem, setIsCheckItem] = useState(false);
  const { onRefetchConnectUser } = useAuth();
  const {
    myDrive,
    onSetShowHideFileDetail,
    onSetShowHideMenuCreate,
    onSetShowHideShareDialog,
    onSetShareDialogScreen,
    onSetShareFilesToType,
    onSetRenameSuccessStatus,
    onSetShowFileViewer,
    myDriveState,
    fetchConnectMyDriveLoading,
    onFetchMore,
    onRefetchConnectMyDrive
  } = useMyDrive();
  const [isOpenViewerPDF, setOpenViewerPDF] = useState(false);
  const [isOpenViewerMobile, setOpenViewerMobile] = useState(false);
  const { onUpdateDashboard } = useDashboard();
  const myDrivefiles: any = myDriveState.files ?? [];
  const myDrivePath: any = myDriveState?.path?.filter(
    (item: any) => item.id !== null
  );
  myDrivePath?.unshift(DEFAULT_ROUTER_MY_DRIVE);
  const dropzoneWrapperRef = useRef<any>(null);
  const [moveConnectFile] = Apollo.useMutation(MoveConnectFile);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isOpenFolderDialog = Boolean(anchorEl);
  const [folderName, setFolderName] = useState<string>(DEFAULT_FOLDER_NAME);
  const [isShowHideFolderDialog, setShowHideFolderDialog] =
    useState<boolean>(false);
  const [folderType, setFolderType] = useState<number>(-1);
  const [folderInfo, setFolderInfo] = useState<any>({
    title: `${t('my_drive.new_folder.header')}`,
    submitButtonName: `${t('my_drive.new_folder.create_button')}`
  });
  const { pathname } = useLocation<any>();
  const [pathnameUrl, setPathNameUrl] = useState(
    pathname.split(`${myDrivePathName}/`)
  );

  const [createConnectFolder] = useCreateConnectFolderMutation();
  const [renameConnectFile] = useRenameConnectFileMutation();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedFirstFile, setSelectedFirstFile] = useState<any>(null);
  const [selectedFileMetaKey, setSelectedFileMetaKey] = useState<any>([]);
  const [disbaledRenameSubmitButton, setDisbaledRenameSubmitButton] =
    useState(false);
  const accessToken = getStorageItem(STORAGE_ACTION.CONNECT_ACCESS_TOKEN);
  const { data: subscribeFileStatusUpdate } = Apollo.useSubscription(
    SubscribeFileStatusUpdate,
    {
      skip: !accessToken
    }
  );
  const { onInsertUserActionLogOne } = useUserActionLog();

  const handleClickNewButton = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    onSetShowHideMenuCreate({ isShowHideMenuCreate: true });
  };

  const handleCloseNewButton = () => {
    setAnchorEl(null);
    onSetShowHideMenuCreate({ isShowHideMenuCreate: false });
  };

  const onHideFolderDialog = () => {
    setShowHideFolderDialog(false);
  };

  useEffect(() => {
    const defaultUrl = '';
    const pathNameUrl = pathname.split(`${myDrivePathName}/`);
    setPathNameUrl(pathNameUrl);
    onRefetchConnectMyDrive({
      pathId: (pathNameUrl && pathNameUrl[1]) || defaultUrl
    });
  }, [pathname, history]);

  const [listControl, setListControl] = useState<any>(LIST_CONTROL_TYPE);
  const [enabled, setEnabled] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [isShare, setShare] = useState(false);
  const [isShowDownLoad, setShowDownLoad] = useState(false);

  useEffect(() => {
    if (!isFetching) return;
    setTimeout(() => {
      onFetchMore({
        variables: {
          pathId: pathnameUrl[1] ? pathnameUrl[1] : '',
          limit: DEFAULT_ITEMS_LIMIT,
          offset: myDrivefiles.length
        },
        updateQuery: (prev: any, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return prev;
          }

          const prevFiles = prev.fetchConnectMyDrive.data?.files;
          const moreFiles = fetchMoreResult.fetchConnectMyDrive.data?.files;

          if (fetchMoreResult.fetchConnectMyDrive.data)
            fetchMoreResult.fetchConnectMyDrive.data.files = [
              ...prevFiles!,
              ...moreFiles!
            ];

          return {
            ...fetchMoreResult
          };
        }
      });
      setIsFetching(false);
    }, 500);
  }, [isFetching]);

  useEffect(() => {
    if (
      subscribeFileStatusUpdate &&
      subscribeFileStatusUpdate.subscribeFileStatusUpdate.data.status ===
        CAPA_FILE_CONVERT_STATUS.CONVERTED
    ) {
      onCallback();
    }
  }, [subscribeFileStatusUpdate]);

  const onScrollDropzoneWrapper = () => {
    const currentDropzoneWrapperRef =
      dropzoneWrapperRef && dropzoneWrapperRef.current;
    if (
      Math.ceil(currentDropzoneWrapperRef.scrollTop) +
        currentDropzoneWrapperRef.clientHeight !==
        currentDropzoneWrapperRef.scrollHeight ||
      isFetching
    )
      return;

    setIsFetching(true);
  };

  const callbackLongPress = useCallback(() => {
    setIsCheckItem(true);
    setIsSelect(true);
    setTimeout(() => {
      setEnabled(false);
    }, 500);
  }, [pathname, history]);

  useEffect(() => {
    setEnabled(false);
    setTimeout(() => {
      setEnabled(true);
    }, LongPressActionTimeOut);
  }, [pathname, history]);

  const longPressHandler = useLongPress(enabled ? callbackLongPress : null, {
    threshold: LongPressActionTimeOut,
    captureEvent: true,
    cancelOnMovement: false,
    detect: LongPressDetectEvents.BOTH
  });

  useEffect(() => {
    if (checkedFiles.length > 1 && !checkedFiles.every((file) => file.isFile)) {
      setListControl(LIST_CONTROL_TYPE_MULTI_FOLDER);
    }
    if (checkedFiles.length > 1 && checkedFiles.every((file) => file.isFile)) {
      setListControl(LIST_CONTROL_TYPE_MULTIPLE_FILE);
    }

    // Check for show button share header mobile
    checkedFiles.every((file) => file.isFile)
      ? setShare(true)
      : setShare(false);
  }, [checkedFiles]);

  const [fileUp, setFileUp] = useState<any>({});

  const onConTextMenu = (file: any) => {
    if (!checkedFiles.includes(file) && !file.isFile) {
      setShare(false);
      setShowDownLoad(false);
      setListControl(LIST_CONTROL_TYPE_FOLDER);
    } else if (file.isFile) {
      setShowDownLoad(true);
      setShare(true);
      setListControl(LIST_CONTROL_TYPE);
    }

    const contextMenu: any = document.querySelector('.react-contextmenu');
    contextMenu.style.display = 'block';
    setFileUp(file);
    onSetShowHideFileDetail({
      detailDrive: file
    });
    if (!checkedFiles.includes(file)) setCheckedFiles([...[file]]);
    else
      setCheckedFiles(
        checkedFiles.length > 1 ? [...checkedFiles] : [...[file]]
      );
  };

  const hideContextMenu = () => {
    const contextMenu: any = document.querySelector('.react-contextmenu');
    contextMenu.style.opacity = '0';
    contextMenu.style.display = 'none';
    contextMenu.style.pointerEvents = 'none';
  };
  const showViewerPDF = () => {
    onSetShowFileViewer({ isShowFileViewer: true });
    setOpenViewerPDF(true);
    const elemBody: any = document.querySelector('body');
    elemBody.style.overflow = 'hidden';
  };
  const hideViewerPDF = () => {
    setOpenViewerPDF(false);
    const elemBody: any = document.querySelector('body');
    elemBody.style.overflow = 'auto';
  };
  const handleControl = (type: any, file?: any) => {
    switch (type) {
      case EnumListControl.OPEN: {
        if (!file) return;

        onInsertUserActionLogOne(
          Enum_Action_Button_Enum.Button_MyDrive_File_View,
          CapaActivityType.ButtonClick
        );

        setFileUp(file);

        onSetShowHideFileDetail({
          detailDrive: file
        });

        setTimeout(() => {
          showViewerPDF();
        }, 0);
        return;
      }
      case EnumListControl.SHARE: {
        onInsertUserActionLogOne(
          Enum_Action_Button_Enum.Button_MyDrive_File_Share,
          CapaActivityType.ButtonClick
        );
        onSetShowHideShareDialog({ isShowHideShareDialog: true });
        onSetShareDialogScreen({
          shareDialogScreen: SHARE_DIALOG_SCREEN.SHARE
        });
        onSetShareFilesToType({
          shareFilesToType: SHARE_FILES_TO_VALUE.EMPTY
        });
        return;
      }
      case EnumListControl.RENAME: {
        onInsertUserActionLogOne(
          Enum_Action_Button_Enum.Button_MyDrive_File_Rename,
          CapaActivityType.ButtonClick
        );
        onOpenFolderDialog(FOLDER_TYPE.RENAME);
        return;
      }
      case EnumListControl.DOWNLOAD: {
        if (fileUp.isFile) {
          onOpenDialogHandle(EnumDashboardDialogType.Download, checkedFiles);
        }
        return;
      }
      case EnumListControl.DELETE: {
        onInsertUserActionLogOne(
          Enum_Action_Button_Enum.Button_MyDrive_File_Delete,
          CapaActivityType.ButtonClick
        );
        onOpenDialogHandle(EnumDashboardDialogType.Delete, checkedFiles);
        return;
      }
      default: {
        return;
      }
    }
  };

  const onShowFileViewerMobile = (file: any) => {
    onSetShowHideFileDetail({
      detailDrive: file
    });
    onSetShowFileViewer({ isShowFileViewer: true });
    setOpenViewerMobile(true);
    const elemBody: any = document.querySelector('body');
    elemBody.style.overflow = 'hidden';
  };
  const hideViewerMobile = () => {
    setOpenViewerMobile(false);
    const elemBody: any = document.querySelector('body');
    elemBody.style.overflow = 'auto';
  };

  const onOpenFolderDialog = (type: number) => {
    switch (type) {
      case FOLDER_TYPE.CREATE: {
        setFolderInfo({
          title: `${t('my_drive.new_folder.header')}`,
          submitButtonName: `${t('my_drive.new_folder.create_button')}`
        });
        break;
      }
      case FOLDER_TYPE.RENAME: {
        setFolderName(fileUp.fileName);
        setFolderInfo({
          title: `${t('my_drive.rename.header')}`,
          submitButtonName: `${t('my_drive.rename.save_button')}`
        });
        break;
      }
    }

    setFolderType(type);
    handleCloseNewButton();
    setShowHideFolderDialog(true);
  };

  const onOpenDialogHandle = (
    dialogType: EnumDashboardDialogType,
    files: Array<CapaConnectMyFile>
  ) => {
    if (dialogType === EnumDashboardDialogType.Download) {
      onDownloadFilesHandle(files);
    }
    if (dialogType === EnumDashboardDialogType.Delete) {
      onDeleteFilesHandle(files);
    }
  };

  const linkRouterDetail = (file: any) => {
    setCheckedFiles([]);
    onSetShowHideFileDetail({
      detailDrive: file.id ? file : null
    });
    if (file.id === '') {
      return history.push(`${myDrivePathName}`);
    }
    return history.push(`${myDrivePathName}/${file.id}`);
  };

  const onCallback = async () => {
    setCheckedFiles([]);
    await onRefetchConnectMyDrive({
      pathId: pathnameUrl[1] ? pathnameUrl[1] : ''
    });
    await onRefetchConnectUser();
  };

  const onDownloadFilesHandle = (files: any) => {
    onDownloadFiles(files);
  };

  const onDeleteFilesHandle = async (files: any) => {
    await onDeleteFiles(files);
    await onRefetchConnectMyDrive({
      pathId: pathnameUrl[1] ? pathnameUrl[1] : ''
    });
    setCheckedFiles([]);
    onSetShowHideFileDetail({
      detailDrive: null
    });
  };

  let latestTouchTap = {
    time: 0,
    target: null
  };

  const onMobileDoubleClick = (event: any) => {
    const touchTap = {
      time: new Date().getTime(),
      target: event.currentTarget
    };
    const isFastDblTouchTap =
      touchTap.target === latestTouchTap.target &&
      touchTap.time - latestTouchTap.time < 300;
    latestTouchTap = touchTap;
    return isFastDblTouchTap;
  };

  const onSubmit = () => {
    setDisbaledRenameSubmitButton(true);

    switch (folderType) {
      case FOLDER_TYPE.CREATE: {
        const payload: {
          folderName: string;
          pathId: string;
        } = {
          folderName: folderName,
          pathId: pathnameUrl[1] ? pathnameUrl[1] : ''
        };

        createConnectFolder({ variables: payload }).then((res) => {
          const result = res?.data?.createConnectFolder?.result;
          if (result === CapaErrorCode.Success) {
            enqueueSnackbar(
              `${t('snackbar.new_folder', {
                folderName: folderName
              })}`,
              {
                variant: 'success'
              }
            );
            onHideFolderDialog();
            onRefetchConnectMyDrive({
              pathId: pathnameUrl[1] ? pathnameUrl[1] : ''
            });
          }
        });
        break;
      }
      case FOLDER_TYPE.RENAME: {
        const payload: {
          fileId: string;
          toName: string;
        } = {
          fileId: fileUp && fileUp.id,
          toName: folderName
        };

        renameConnectFile({ variables: payload })
          .then((res) => {
            const result = res?.data?.renameConnectFile?.result;
            if (result === CapaErrorCode.Success) {
              enqueueSnackbar(
                `${t('snackbar.rename', {
                  oldFileName: fileUp.fileName,
                  newFileName: folderName
                })}`,
                {
                  variant: 'success'
                }
              );
              onHideFolderDialog();
              onRefetchConnectMyDrive({
                pathId: pathnameUrl[1] ? pathnameUrl[1] : ''
              });
              onSetRenameSuccessStatus({
                isRenameSuccess: true
              });
            } else {
              enqueueSnackbar(`Rename error`, {
                variant: 'error'
              });
            }
          })
          .catch(() => {
            onHideFolderDialog();
            enqueueSnackbar(`Rename error`, {
              variant: 'error'
            });
          })
          .finally(() => setDisbaledRenameSubmitButton(false));
        break;
      }
    }
  };

  const onShowDetailMobile = (isChecked: boolean, id: string, file: any) => {
    if (file.isFile) {
      if (!isCheckItem) {
        const elemBody: any = document.querySelector('body');
        elemBody.style.overflow = 'hidden';
        onSetShowHideFileDetail({
          detailDrive: file
        });
        if (!isChecked) {
          setCheckedFiles([...[file]]);
        }
      }
    }
  };

  const onClickMuiBoxTableItem = (
    e: any,
    isChecked: boolean,
    id: string,
    file: any,
    isCheckBox?: boolean
  ) => {
    if (file.isFile) {
      setShowDownLoad(true);
      setShare(true);
      setListControl(LIST_CONTROL_TYPE_MULTIPLE_FILE);
    } else {
      setShare(false);
      setShowDownLoad(false);
      setListControl(LIST_CONTROL_TYPE_FOLDER);
    }
    if ((e.metaKey && IS_MAC_OS()) || e.ctrlKey || isCheckBox) {
      setSelectedFirstFile(file);
      if (checkedFiles.length === 0) {
        setSelectedFileMetaKey([file]);
      }
      if (isChecked) {
        setCheckedFiles(checkedFiles.filter((file) => file.id !== id));
      } else {
        setCheckedFiles((oldValue) => [...oldValue, file]);
        setSelectedFileMetaKey((oldValue: any) => [...oldValue, file]);
      }
      onSetShowHideFileDetail({
        detailDrive: file
      });
      return;
    }

    if (e.type === KEY_TYPE.CLICK && !e.shiftKey) {
      setSelectedFileMetaKey([file]);
      setSelectedFirstFile(file);
    }

    if (e.shiftKey) {
      onSetShowHideFileDetail({
        detailDrive: file
      });
      if (checkedFiles.length === 0) {
        setSelectedFirstFile(file);
        setCheckedFiles([...[file]]);
        return;
      }
      let indexOfNew =
        myDrivefiles &&
        myDrivefiles
          ?.map((file: any) => file?.id)
          .indexOf(selectedFirstFile !== null ? selectedFirstFile.id : file.id);
      let indexOfLast = myDrivefiles
        ?.map((file: any) => file?.id)
        .indexOf(file.id);

      const isSelectingForwards = indexOfNew > indexOfLast;
      const start = isSelectingForwards ? indexOfLast : indexOfNew;
      const end = isSelectingForwards ? indexOfNew : indexOfLast;

      setCheckedFiles((oldValue) => {
        return uniqueArray(
          [...selectedFileMetaKey, ...myDrivefiles.slice(start, end + 1)],
          'id'
        );
      });

      return;
    }

    setSelectedFirstFile(file);

    if (checkedFiles.length === 0) {
      setSelectedFileMetaKey([file]);
      onSetShowHideFileDetail({
        detailDrive: null
      });
    }
    if (isChecked) {
      selectedOneFile(file, isChecked, false);
    } else {
      setCheckedFiles([...[file]]);
      selectedOneFile(file, isChecked, false);
    }
  };

  const selectedOneFile = (
    file: any,
    isChecked: boolean,
    isCheckBox?: boolean
  ) => {
    if (isChecked && checkedFiles.length === 1) {
      if (isCheckBox) {
        onSetShowHideFileDetail({
          detailDrive: null
        });
      }
      return;
    }
    onSetShowHideFileDetail({
      detailDrive: file
    });
  };

  const onItemDropped = (folderId: string) => {
    moveConnectFile({
      variables: {
        fileId: checkedFiles[0].id,
        destId: folderId
      }
    }).then((res) => {
      const result = res?.data?.moveConnectFile?.result;
      if (result === CapaErrorCode.Success) {
        setCheckedFiles([]);
        onSetShowHideFileDetail({
          detailDrive: null
        });
        hideContextMenu();
        enqueueSnackbar(t('snackbar.move_connect_to_folder_success'), {
          variant: 'success'
        });
        onRefetchConnectMyDrive({
          pathId: pathnameUrl[1] ? pathnameUrl[1] : ''
        });
      }
    });
  };

  useEffect(() => {
    return () => {
      onSetShowHideShareDialog({ isShowHideShareDialog: false });
    };
  }, []);

  const onCheckedFilesFromControlList = (files: any) => {
    if (files[0]?.id === checkedFiles[0]?.id && matches) return;
    setCheckedFiles(files);
  };

  return (
    <LayoutDashboard
      checkedFiles={checkedFiles}
      isShare={isShare}
      contentShift={!matches}
      asideDrawer={
        matches ? null : (
          <DashboardAsideMenuPresenter
            setCheckedFiles={() => {
              setCheckedFiles([]);
            }}
            setSelectedFileMetaKey={() => {
              setSelectedFileMetaKey([]);
            }}
            setSelectedFirstFile={() => {
              setSelectedFirstFile(null);
            }}
            callback={onCallback}
          />
        )
      }
      matches={matches}
      gnbAppBar={
        <GnbAppBar
          isSelect={isSelect}
          count={checkedFiles.length}
          onSetIsSelect={() => {
            setCheckedFiles([]);
            setIsSelect(false);
            setIsCheckItem(false);
            setEnabled(true);
          }}
          headerName={
            myDrivePath
              ? myDrivePath && myDrivePath[myDrivePath?.length - 1]?.folderName
              : ''
          }
        />
      }
      sideBarRight={
        <div>
          <DetailFile checkedFiles={checkedFiles} />
        </div>
      }
      header={
        !matches ? (
          <div className="header">
            <CustomizedTypography
              component={'div'}
              variant="h3"
              fontWeight="500"
            >
              <Breadcrumbs
                separator={<img src={IBMChevronBreadcrumb} alt="" />}
                aria-label="breadcrumb"
              >
                {myDrivePath &&
                  myDrivePath?.map((item: any, index: number) => {
                    return (
                      <Link
                        key={index}
                        onClick={() => linkRouterDetail(item)}
                        className="cursor-pointer"
                        color="inherit"
                      >
                        {t('my_drive.header')}
                      </Link>
                    );
                  })}
              </Breadcrumbs>
            </CustomizedTypography>

            {checkedFiles.length > 0 && (
              <>
                <DashboardListControlBtn
                  showViewerPDF={showViewerPDF}
                  isShowMoreVertMenu={
                    checkedFiles.length > 0 &&
                    !!checkedFiles?.every((file: any) => file.isFile)
                  }
                  isShowDownLoad={isShowDownLoad}
                  isOpen={true}
                  isShare={isShare}
                  files={checkedFiles}
                  type={EnumDashboardListControlType.ListHeader}
                  callback={onCallback}
                  myDrivefiles={myDrivefiles}
                />
                <div className="line" />
              </>
            )}
            <IconButton
              className={clsx(
                'btn-open-detail',
                myDrive.isShowIsDetailFile ? 'active' : ''
              )}
              onClick={() => {
                onSetShowHideFileDetail({
                  isShowIsDetailFile:
                    getStorageItem(STORAGE_ACTION.IS_OPEN_DETAIL_FILE) !==
                    'true'
                });
                getStorageItem(STORAGE_ACTION.IS_OPEN_DETAIL_FILE)
                  ? localStorage.removeItem(STORAGE_ACTION.IS_OPEN_DETAIL_FILE)
                  : localStorage.setItem(
                      STORAGE_ACTION.IS_OPEN_DETAIL_FILE,
                      'true'
                    );
              }}
            >
              <IBMInfomation />
            </IconButton>
          </div>
        ) : (
          <></>
        )
      }
    >
      <Portal elementId="portal">
        <UploadStatusPopper />
      </Portal>

      <MuiDiv>
        {!matches ? (
          <>
            <MuiBoxTable>
              <div className="table-header">
                <CustomizedTypography
                  component={'div'}
                  variant="body2"
                  color="textSecondary"
                >
                  <div className="checkbox-wrapper">
                    <Checkbox
                      checkedIcon={<img src={IBMCheckboxChecked} alt="" />}
                      icon={<img src={IBMCheckbox} alt="" />}
                      indeterminateIcon={
                        <img src={IBMCheckboxUndeterminate} alt="" />
                      }
                      color="primary"
                      checked={
                        checkedFiles.length > 0 &&
                        myDrivefiles.length === checkedFiles.length
                      }
                      indeterminate={
                        checkedFiles.length > 0 &&
                        myDrivefiles.length !== checkedFiles.length
                      }
                      onDoubleClick={(e) => {
                        e.stopPropagation();
                      }}
                      onClick={() => {
                        if (checkedFiles.length === 0) {
                          const allCheckedFiles = uniqueArray(
                            [...checkedFiles, ...myDrivefiles],
                            'id'
                          );

                          setCheckedFiles(allCheckedFiles);
                          onSetShowHideFileDetail({
                            detailDrive:
                              allCheckedFiles[allCheckedFiles?.length - 1]
                          });
                          return;
                        }
                        onSetShowHideFileDetail({
                          detailDrive: null
                        });
                        setCheckedFiles([]);
                      }}
                    />
                  </div>
                </CustomizedTypography>
                {LIST_TABLE_HEADER.map((item: any, index: number) => (
                  <DashboardHeaderTable item={item} key={index} />
                ))}
              </div>
              {fetchConnectMyDriveLoading ? (
                <Loading
                  open={fetchConnectMyDriveLoading}
                  styles={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: 40
                  }}
                />
              ) : (
                <div
                  className={clsx(
                    classes.dropzoneWrapper,
                    myDrivefiles.length > 0
                      ? classes.dropzoneWrapperHaveItem
                      : classes.dropzoneWrapperNoItem
                  )}
                  onScroll={onScrollDropzoneWrapper}
                  ref={dropzoneWrapperRef}
                  id={DASHBOARD_DROPZONE_WRAPPER_ID}
                >
                  <Dropzone
                    noClick
                    callback={onCallback}
                    locationInfo={{
                      title: `${t('drag_drop_message.upload_to')}`,
                      name: `${t('drag_drop_message.to_my_drive')}`
                    }}
                    locationIcon={<InboxOutlined />}
                    rootMinHeight="inherit"
                    rootHeight="100%"
                    isFolder={false}
                  >
                    {myDrivefiles.length > 0 ? (
                      myDrivefiles?.map((file: any, index: number) => {
                        const id = file.id;
                        const fileName = file.fileName;
                        const fileSize = formatFileSize(
                          file.fileSize
                        ).toUpperCase();
                        const isChecked =
                          checkedFiles.filter((file) => file.id === id).length >
                          0;
                        return file.isFile ? (
                          <Drag
                            key={index}
                            id={id}
                            dataItem={checkedFiles}
                            dropEffect={DROP_EFFECT.LINK}
                            file={file}
                            setCheckedFiles={setCheckedFiles}
                            setSelectedFirstFile={setSelectedFirstFile}
                          >
                            <ContextMenuTrigger id="context-menu" key={index}>
                              <MuiBoxTableItem
                                onContextMenu={() => onConTextMenu(file)}
                                key={id}
                                isChecked={isChecked}
                                onDoubleClick={() => {
                                  // onSetShowHideFileDetail({
                                  //   detailDrive: file
                                  // });
                                  // onInsertUserActionLogOne(
                                  //   Enum_Action_Button_Enum.DoubleClick_MyDrive_File,
                                  //   CapaActivityType.ButtonClick
                                  // );
                                  // showViewerPDF();
                                }}
                                onClick={(e) => {
                                  onInsertUserActionLogOne(
                                    Enum_Action_Button_Enum.DoubleClick_MyDrive_File,
                                    CapaActivityType.ButtonClick
                                  );
                                  hideContextMenu();
                                  onUpdateDashboard({ files: [file] });
                                  onClickMuiBoxTableItem(
                                    e,
                                    isChecked,
                                    id,
                                    file
                                  );
                                }}
                              >
                                <div className="table-item-td-one">
                                  <div className="checkbox-wrapper">
                                    <Checkbox
                                      checkedIcon={
                                        <img src={IBMCheckboxChecked} alt="" />
                                      }
                                      icon={<img src={IBMCheckbox} alt="" />}
                                      checked={checkedFiles
                                        .map((file) => file.id)
                                        .includes(file.id)}
                                      color="primary"
                                      onDoubleClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        onClickMuiBoxTableItem(
                                          e,
                                          isChecked,
                                          id,
                                          file,
                                          true
                                        );
                                        hideContextMenu();
                                        selectedOneFile(file, isChecked, true);
                                      }}
                                    />
                                  </div>
                                  <IconThumbnail file={file} size="small" />
                                  <CustomizedTypography
                                    component={'div'}
                                    variant="body2"
                                    noWrap
                                  >
                                    <div className="table-item-share">
                                      {convertSubstring(
                                        DRAWING_FILE_TYPES
                                      ).includes(
                                        file?.originalFileExt?.toLocaleLowerCase()
                                      ) && (
                                        <img
                                          src={IBMCAD}
                                          alt=""
                                          className="ibm-cad-icon"
                                        />
                                      )}
                                      <div
                                        className="table-item-text"
                                        onClick={() => {
                                          handleControl(
                                            EnumListControl.OPEN,
                                            file
                                          );
                                        }}
                                      >
                                        {fileName}
                                      </div>
                                      {file.isShared && file.isFile && (
                                        <img
                                          className="table-item-share-icon"
                                          src={IBMMultiUser}
                                          alt=""
                                        />
                                      )}
                                      {file?.linkStatus ===
                                        LINK_STATUS.ACTIVE && <IBMLink />}
                                    </div>
                                  </CustomizedTypography>
                                </div>
                                <div className="table-item-td-three">
                                  <CustomizedTypography variant="body2">
                                    {formatTimeStamp(
                                      file.createdAt,
                                      true,
                                      false,
                                      false,
                                      true
                                    )}
                                  </CustomizedTypography>
                                </div>
                                <div className="table-item-td-four">
                                  <CustomizedTypography variant="body2">
                                    {fileSize}
                                  </CustomizedTypography>
                                </div>
                                <div>
                                  <DashboardListControlBtn
                                    showViewerPDF={showViewerPDF}
                                    isShowMoreVertMenu={true}
                                    isShowDownLoad={true}
                                    isOpen={false}
                                    isShare={false}
                                    isListSide={true}
                                    files={[file]}
                                    type={
                                      EnumDashboardListControlType.ListHeader
                                    }
                                    callback={onCallback}
                                    myDrivefiles={myDrivefiles}
                                  />
                                </div>
                              </MuiBoxTableItem>
                            </ContextMenuTrigger>
                          </Drag>
                        ) : (
                          <Dropzone
                            key={index}
                            noClick
                            callback={onCallback}
                            locationInfo={{
                              title: `${t('drag_drop_message.upload_to')}`,
                              name: myDrive.locationName
                            }}
                            isFolder={true}
                            locationIcon={<InboxOutlined />}
                          >
                            <Drag
                              key={index}
                              dataItem={checkedFiles}
                              dropEffect={DROP_EFFECT.LINK}
                              file={file}
                              setCheckedFiles={setCheckedFiles}
                              setSelectedFirstFile={setSelectedFirstFile}
                            >
                              <Drop
                                dropEffect={DROP_EFFECT.LINK}
                                dataItem={checkedFiles}
                                onItemDropped={onItemDropped}
                              >
                                <ContextMenuTrigger id="context-menu">
                                  <MuiBoxTableItem
                                    onContextMenu={() => onConTextMenu(file)}
                                    key={id}
                                    isChecked={isChecked}
                                    onDoubleClick={() => {
                                      onInsertUserActionLogOne(
                                        Enum_Action_Button_Enum.Click_MyDrive_File,
                                        CapaActivityType.ButtonClick
                                      );
                                      linkRouterDetail(file);
                                    }}
                                    onClick={(e) => {
                                      onInsertUserActionLogOne(
                                        Enum_Action_Button_Enum.Click_MyDrive_Folder,
                                        CapaActivityType.ButtonClick
                                      );
                                      hideContextMenu();
                                      onClickMuiBoxTableItem(
                                        e,
                                        isChecked,
                                        id,
                                        file
                                      );
                                    }}
                                  >
                                    <div
                                      className="table-item-td-one"
                                      id={file.id}
                                    >
                                      <div className="checkbox-wrapper">
                                        <Checkbox
                                          checked={checkedFiles
                                            .map((file) => file.id)
                                            .includes(file.id)}
                                          color="primary"
                                          checkedIcon={
                                            <img
                                              src={IBMCheckboxChecked}
                                              alt=""
                                            />
                                          }
                                          icon={
                                            <img src={IBMCheckbox} alt="" />
                                          }
                                          onDoubleClick={(e) => {
                                            e.stopPropagation();
                                          }}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            onClickMuiBoxTableItem(
                                              e,
                                              isChecked,
                                              id,
                                              file,
                                              true
                                            );
                                            hideContextMenu();
                                            selectedOneFile(
                                              file,
                                              isChecked,
                                              true
                                            );
                                          }}
                                        />
                                      </div>
                                      <img src={IBMFolder} alt="" />
                                      <span className="custom-span">
                                        {fileName}
                                      </span>
                                    </div>
                                    <div className="table-item-td-three">
                                      <CustomizedTypography variant="body2">
                                        {formatTimeStamp(
                                          file.createdAt,
                                          true,
                                          false,
                                          false,
                                          true
                                        )}
                                      </CustomizedTypography>
                                    </div>
                                    <div className="table-item-td-four">
                                      <CustomizedTypography variant="body2">
                                        {EMPTY_SIZE_FOLDER}
                                      </CustomizedTypography>
                                    </div>
                                  </MuiBoxTableItem>
                                </ContextMenuTrigger>
                              </Drop>
                            </Drag>
                          </Dropzone>
                        );
                      })
                    ) : (
                      <DashboardEmptyPresenter />
                    )}
                  </Dropzone>
                </div>
              )}
            </MuiBoxTable>
            <ContextMenuTable>
              <ContextMenu id="context-menu" className="wrapper">
                <MenuItem>
                  <ul className="popup">
                    {listControl.map((item: any, index: number) => (
                      <div key={index}>
                        <li
                          key={index}
                          onClick={() => handleControl(item.type)}
                        >
                          <img src={item.icon} alt={t(item.name)} />
                          <div>{t(item.name)}</div>
                        </li>
                        {item.borderBottom && (
                          <div className={classes.lineBottom} />
                        )}
                      </div>
                    ))}
                  </ul>
                </MenuItem>
              </ContextMenu>
            </ContextMenuTable>
          </>
        ) : (
          // Control Hero Mobile
          <>
            {/* {checkedFiles.length > 0 ? (
              <div className="subtitle">
                <DashboardListControlBtn
                  onShowFileViewer={() => {
                    onShowFileViewerMobile(checkedFiles[0]);
                  }}
                  isShowMoreVertMenu={checkedFiles.length > 0}
                  isShowDownLoad={checkedFiles.every(
                    (file: any) => file.isFile
                  )}
                  files={checkedFiles}
                  type={EnumDashboardListControlType.ListHeader}
                  callback={onCallback}
                  isShare={isShare}
                  isDetail={checkedFiles.length == 1}
                  isOpen={
                    checkedFiles.every((file: any) => file.isFile) &&
                    checkedFiles.length === 1
                  }
                  myDrivefiles={myDrivefiles}
                />
              </div>
            ) : null} */}
            {!isChildrenPage(pathname, 'my-drive') && (
              <TabPanelControl urlParam={URL_PARAM.urlMyDrive} />
            )}
            <div
              className={classes.dropzoneWrapperMobile}
              onScroll={onScrollDropzoneWrapper}
              ref={dropzoneWrapperRef}
            >
              <MuiBoxTable>
                {fetchConnectMyDriveLoading ? (
                  <Loading
                    open={fetchConnectMyDriveLoading}
                    styles={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: 40
                    }}
                  />
                ) : myDrivefiles.length > 0 ? (
                  myDrivefiles.map((file: any) => {
                    const id = file.id;
                    const fileName = file.fileName;
                    const fileSize = formatFileSize(
                      file.fileSize
                    ).toUpperCase();
                    const isChecked =
                      checkedFiles.filter((file) => file.id === id).length > 0;
                    const checkedFileIdArr = checkedFiles.map(
                      (file) => file.id
                    );

                    return (
                      <MuiBoxTableItemMobile
                        key={id}
                        isChecked={isChecked}
                        onTouchStart={(e) => {
                          if (matches) {
                            if (onMobileDoubleClick(e)) {
                              linkRouterDetail(file);
                            }
                          }
                          // console.log('click');
                        }}
                      >
                        <button
                          {...longPressHandler}
                          className="button-long-press"
                        >
                          {isCheckItem && (
                            <div className="checkbox-wrapper">
                              <CustomizedCheckBox
                                value={id}
                                values={checkedFileIdArr}
                                size="medium"
                                onChange={(values) =>
                                  setCheckedFiles(
                                    myDrivefiles.filter((file: any) =>
                                      values.includes(file.id)
                                    )
                                  )
                                }
                              />
                            </div>
                          )}

                          <IconThumbnail
                            file={file}
                            size="large"
                            onClick={() =>
                              onShowDetailMobile(isChecked, id, file)
                            }
                          />
                          <div
                            className="table-content"
                            onClick={() => {
                              if (file.isFile) {
                                if (isSelect) return;
                                onUpdateDashboard({ files: [file] });
                                onShowFileViewerMobile(file);
                              } else {
                                linkRouterDetail(file);
                              }
                            }}
                          >
                            <CustomizedTypography
                              component={'div'}
                              variant="body2"
                              gutterBottoms={8}
                              noWrap
                            >
                              {convertSubstring(DRAWING_FILE_TYPES).includes(
                                file?.originalFileExt?.toLocaleLowerCase()
                              ) && (
                                <img
                                  src={IBMCAD}
                                  alt=""
                                  className="ibm-cad-icon"
                                />
                              )}
                              <div className="cs-file-name">{fileName}</div>
                            </CustomizedTypography>
                            <CustomizedTypography
                              component={'div'}
                              variant="caption"
                              color="textSecondary"
                            >
                              <div className="table-item-share-mobile">
                                {file?.linkStatus === LINK_STATUS.ACTIVE && (
                                  <IBMLink
                                    className={
                                      !file.isShared && file.isFile && 'mr-4'
                                    }
                                  />
                                )}
                                {file.isShared && file.isFile && (
                                  <img
                                    className="table-item-share-icon-mobile"
                                    src={IBMMultiUser}
                                    alt=""
                                  />
                                )}
                                <div>
                                  {t('my_drive.mobile.list_item', {
                                    date: formatTimeStamp(
                                      file.createdAt,
                                      true,
                                      false,
                                      false,
                                      true
                                    )
                                  })}
                                </div>
                                {file.isFile && (
                                  <div className="table-item-share-mobile-filesize">
                                    {fileSize}
                                  </div>
                                )}
                              </div>
                            </CustomizedTypography>
                          </div>
                          {!isCheckItem ? (
                            <DashboardListControlBtn
                              onShowFileViewer={() =>
                                onShowFileViewerMobile(file)
                              }
                              isOpen={false}
                              isDetail={true}
                              onSetCheckedFiles={onCheckedFilesFromControlList}
                              isShowDownLoad={!!file?.isFile || false}
                              isShowMoreVertMenu={true}
                              files={[file]}
                              isListSide={true}
                              type={EnumDashboardListControlType.ListItem}
                              callback={onCallback}
                              isShare={false}
                              myDrivefiles={myDrivefiles}
                            />
                          ) : null}
                        </button>
                      </MuiBoxTableItemMobile>
                    );
                  })
                ) : (
                  <DashboardEmptyPresenter />
                )}
              </MuiBoxTable>
            </div>
            <BottomNavMenu callback={onCallback} />
            <MuiNewButtonMobile onClick={handleClickNewButton}>
              <img src={IBMAdd} alt="" />
            </MuiNewButtonMobile>
            <NewButtonMenu
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              open={isOpenFolderDialog}
              onClose={handleCloseNewButton}
              keepMounted
            >
              <MuiMenuItem
                onClick={() => onOpenFolderDialog(FOLDER_TYPE.CREATE)}
              >
                <img src={IBMFolderAdd} alt="" />
                {t('lnb.new_button.folder')}
              </MuiMenuItem>
              <div className={classes.lineBottom} />
              <Dropzone callback={onCallback}>
                <MuiMenuItem onClick={handleCloseNewButton}>
                  <img src={IBMDocumentAdd} alt="" />
                  {t('lnb.new_button.file_upload')}
                </MuiMenuItem>
              </Dropzone>
            </NewButtonMenu>
          </>
        )}
      </MuiDiv>
      {isOpenViewerPDF ? (
        <ViewerFile
          isOpen={isOpenViewerPDF}
          hideViewerPDF={hideViewerPDF}
          fileViewer={checkedFiles}
          callback={onCallback}
        />
      ) : null}
      {isOpenViewerMobile ? (
        <ViewerFile
          isOpen={isOpenViewerMobile}
          hideViewerPDF={hideViewerMobile}
          fileViewer={checkedFiles}
          callback={onCallback}
        />
      ) : null}
      <FolderFormDialog
        dialogInfo={folderInfo}
        folderNameValue={folderName}
        isOpen={isShowHideFolderDialog}
        onClose={onHideFolderDialog}
        setValue={setFolderName}
        onSubmit={onSubmit}
        checkedFiles={checkedFiles}
        disabledSubmitButton={disbaledRenameSubmitButton}
        setDisbaledRenameSubmitButton={() =>
          setDisbaledRenameSubmitButton(false)
        }
      />
      {myDrive.isShowHideShareDialog ? (
        <ShareContainerDialog
          checkedFiles={checkedFiles}
          myDrivefiles={myDrivefiles}
        />
      ) : null}
    </LayoutDashboard>
  );
};

export default DashboardMyDrivePresenter;
