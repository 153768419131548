import React from 'react';
import Loading from '@components/Loading';

interface Props {
  isOpen: boolean;
}

const AuthPresenter: React.FC<Props> = ({ isOpen }) => {
  return <Loading open={isOpen} />;
};

export default AuthPresenter;
