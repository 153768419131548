import AppConfig from '@config/index';
import { CapaErrorCode } from '@generated/Common/graphql';
import { updateUploadData } from '@modules/upload';
import { UploadData, UploadState } from '@modules/upload/types';
import { getTokens } from '@utils/storage';
import axios, { AxiosResponse } from 'axios';
import reduxStore from '../store';

const { store } = reduxStore;

export const APIStorage = axios.create({
  headers: {
    Accept: '*/*',
    'Content-type': 'multipart/form-data',
    'Access-Control-Allow-Origin': '*'
  },
  timeout: 30 * 60 * 1000,
  timeoutErrorMessage: 'timeout'
});

export async function fileUploadApi(
  uploadDatas: UploadData[],
  path: string
): Promise<AxiosResponse<any>[]> {
  /* 리퀘스트에 등록 */
  const requests = uploadDatas.map((fileData) => {
    const formData = new FormData();
    formData.append('files', fileData.file!);
    formData.append('pathId', path!);
    return APIStorage.post(`${AppConfig.fileApiUrl}/connect`, formData, {
      headers: {
        'Authorization': `Bearer ${getTokens().accessToken}`
      },
      cancelToken: fileData.cancelSource.token,
      onUploadProgress: (e: ProgressEvent) => {
        const uploadState = store.getState().upload;
        if (uploadState.length > 0) {
          const uploadData: UploadData | undefined = uploadState.find(
            (data) => data.dataKey === fileData.dataKey
          );
          /* onUploadProgress가 처음 실행된경우 이벤트리스너를 달아줌 */
          if (uploadData && uploadData.state === UploadState.PENDING) {
            e.currentTarget?.addEventListener('error', () => {
              store.dispatch(
                updateUploadData({
                  ...uploadData,
                  state: UploadState.ERROR,
                  isError: true
                })
              );
            });
            e.currentTarget?.addEventListener('timeout', () => {
              store.dispatch(
                updateUploadData({
                  ...uploadData,
                  state: UploadState.TIMEOUT,
                  isError: true
                })
              );
            });
          }
          /* 업로드가 진행중인 경우, state를 Uploading으로 업데이트 하고 loaded 값을 갱신함 */
          if (uploadData) {
            store.dispatch(
              updateUploadData({
                ...uploadData,
                loaded: e.loaded,
                state: UploadState.UPLOADING
              })
            );
          }
        }
      }
    })
      .then((resp) => {
        /* 개별 업로드가 성공한 경우 해당 state를 FINISHED로 수정 */
        if (resp.data.result === CapaErrorCode.Success) {
          const uploadState = store.getState().upload;
          const uploadData = uploadState.find(
            (data) => data.dataKey === fileData.dataKey
          );

          if (uploadData) {
            store.dispatch(
              updateUploadData({
                ...uploadData,
                state: UploadState.FINISHED
              })
            );
          }
        }
        return resp;
      })
      .catch((e) => {
        if (axios.isCancel(e)) return e;
        /* 에러가 발생한 경우 해당 state를 ERROR로 수정 */
        const uploadState = store.getState().upload;
        const uploadData = uploadState.find(
          (data) => data.dataKey === fileData.dataKey
        );
        if (uploadData) {
          store.dispatch(
            updateUploadData({
              ...uploadData,
              state: UploadState.ERROR,
              isError: true
            })
          );
        }

        return e;
      });
  });

  /* 리퀘스트 동시 실행 */
  return await axios.all(requests);
}
export function buildQueries(params: any) {
  let requestString = '';
  Object.keys(params).forEach((key) => {
    if (params[key] && params[key] !== '') {
      requestString += `${key}=${params[key]}&`;
    }
  });
  return requestString;
}
export async function fileDownloadApi(file: any, isChatBox?: boolean) {
  const payload = {
    fileId: isChatBox ? file.fileId : file.id
  };
  buildQueries(payload);
  return await APIStorage.get(
    `${AppConfig.fileApiUrl}/connect?${buildQueries(payload)}`,
    {
      headers: {
        Authorization: `Bearer ${getTokens().accessToken}`
      },
      responseType: 'blob'
    }
  );
}
export async function fileDownloadWithOutAuthApi(file: any) {
  const payload = {
    fileId: file.id
  };
  buildQueries(payload);
  return await APIStorage.get(
    `${AppConfig.fileApiUrl}/connect/without-auth?${buildQueries(payload)}`,
    {
      headers: {
        Authorization: `Bearer ${getTokens().accessToken}`
      },
      responseType: 'blob'
    }
  );
}

export async function groupDetailFileApi(file: any) {
  return await APIStorage.get(
    `${AppConfig.fileApiUrl}/connect?fileId=${file.id}`,
    {
      headers: {
        Authorization: `Bearer ${getTokens().accessToken}`
      },
      responseType: 'blob'
    }
  );
}
export async function groupDetailFileWithOutAuthApi(file: any) {
  return await APIStorage.get(
    `${AppConfig.fileApiUrl}/connect/without-auth?fileId=${file.id}`,
    {
      headers: {
        Authorization: `Bearer ${getTokens().accessToken}`
      },
      responseType: 'blob'
    }
  );
}

export async function newFileUploadApi(
  uploadDatas: UploadData[],
  path: string
): Promise<AxiosResponse<any>[]> {
  const requests = uploadDatas.map((fileData: any) => {
    const formData = new FormData();
    formData.append('files', fileData);
    formData.append('pathId', path!);
    return APIStorage.post(`${AppConfig.fileApiUrl}/connect`, formData, {
      headers: {
        'Authorization': `Bearer ${getTokens().accessToken}`
      }
    })
      .then((resp) => {
        return resp;
      })
      .catch((e) => {
        return e;
      });
  });

  return await axios.all(requests);
}

export async function uploadBasicFile(
  file: File,
  path: string
): Promise<AxiosResponse<any>> {
  const formData = new FormData();
  formData.append('pathId', path!);
  formData.append('file', file);
  return APIStorage.post(`${AppConfig.fileApiUrl}/file`, formData, {
    headers: {
      'Authorization': `Bearer ${getTokens().accessToken}`,
      'Content-Type': 'multipart/form-data'
    }
  })
    .then((resp) => {
      return resp;
    })
    .catch((e) => {
      return e;
    });
}
