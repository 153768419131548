import React from 'react';
import { useUploadFilesButton } from './styles';
import Dropzone from '@routes/Common/Dialog/UploadFiles/Dropzone';
import { ReactComponent as IBMCloud } from '../../../../../assets/icon/IBMCloud.svg';
import { ReactComponent as IBMAttachment } from '../../../../../assets/icon/IBMAttachment.svg';

interface Props {
  onDropAccepted?: (files: any) => void;
  onClickCloudIcon?: () => void;
  isChatBox?: boolean;
}

const UploadFilesButton = (props: Props) => {
  const { onDropAccepted, onClickCloudIcon, isChatBox } = props;
  const classes = useUploadFilesButton({ isChatBox });

  const dropAccepted = (files: any) => {
    onDropAccepted && onDropAccepted(files);
  };

  return (
    <div className={classes.attachmentIconBlock}>
      <Dropzone onDropAccepted={dropAccepted} noDrag={true} noKeyboard={true}>
        <div className="icon-wrapper">
          <IBMAttachment className="cs-icon" />
        </div>
      </Dropzone>
      <div
        className="icon-wrapper"
        onClick={() => onClickCloudIcon && onClickCloudIcon()}
      >
        <IBMCloud className="cs-icon" />
      </div>
    </div>
  );
};

export default UploadFilesButton;
