import React, { useCallback, useEffect, useRef, useState } from 'react';
import { viewerStyle } from './styles';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import ViewerPresenter from '@routes/Viewer/ViewerPresenter';
import {
  EnumDashboardListControlType,
  EnumListControl
} from '@routes/Dashboard/types';
import DashboardListControlBtn from './../Dashboard/DashboardListControlBtn/index';
import { groupDetailFileApi } from '@api/index';
import useMyDrive from '@hooks/Drive/MyDrive/useMyDrive';
import {
  IMAGE_TYPES,
  PDF_TYPE,
  DRAWING_FILE_TYPES,
  NO_SUPORT_DOCUMENT_TYPES,
  SUPORT_DOCUMENT_TYPES
} from '@routes/Common/Dropzone/types';
import { IBMDownloadWhite } from '@assets';
import { blobToBase64, convertSubstring } from '@utils/file';
import { CapaConnectMyFile } from '@generated/Common/graphql';
import { useSnackbar } from '@components/Provider';
import useFiles from '@hooks/useFiles';
import { CustomizedButton } from '@components/Customized';
import { IconButton, useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PDFViewer from '@components/PDFViewer';
import Loading from '@components/LoadingInComponent';
import { ReactComponent as IBMChevronLeft } from '../../assets/icon/IBMChevronLeft.svg';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import useGroup from '@hooks/Group/useGroup';
import { isMobile } from 'react-device-detect';
import OfficeFileViewer from '@components/OfficeFileViewer';

interface Props {
  isOpen?: boolean;
  hideViewerPDF?: () => void;
  fileViewer?: any;
  callback?: () => void;
}
const ViewerFile: React.FC<Props> = ({
  isOpen,
  hideViewerPDF,
  fileViewer,
  callback
}) => {
  const { t } = useTranslation();
  const {
    myDrive,
    onSetShowFileViewer,
    onSetShowHideFileDetail,
    myDriveState
  } = useMyDrive();
  const { groupsDetailState, onFetchGroupDetailData } = useGroup();
  const originalFileExt =
    groupsDetailState?.data?.originalFileExt?.toLocaleLowerCase();
  const classes = viewerStyle();
  const [urlFileViewer, setUrlFileViewer] = useState([]);
  const ref = useRef(null);
  const [imgUrl, setImgUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { onDownloadConnectFile } = useFiles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const myDrivefiles: any = myDriveState.files ?? [];

  const onBack = () => {
    onSetShowFileViewer({ isShowFileViewer: false });
    setUrlFileViewer([]);
    setImgUrl('');
    hideViewerPDF && hideViewerPDF();
  };

  const checkIfClickedOutside = useCallback((e: MouseEvent) => {
    if (!(ref.current! as any).contains(e.target)) {
      onBack();
    }

    return () => {
      // Fix display old image.
      setImgUrl('');
    };
  }, []);

  const onDownloadFiles = (file?: CapaConnectMyFile) => {
    if (file) {
      onDownloadConnectFile(file)
        .then(() => {
          enqueueSnackbar(
            `${t('confirm_dialog.download.success_text', {
              fileName: file.fileName
            })}`,
            {
              variant: 'success'
            }
          );
        })
        .catch(() => {
          enqueueSnackbar(t('snackbar.download_error'), {
            variant: 'error'
          });
        });
    }
  };

  useEffect(() => {
    if (isOpen) {
      onFetchGroupDetailData({
        variables: {
          fileId: myDrive.detailDrive.id || ''
        }
      });
      setIsLoading(true);
      groupDetailFileApi(myDrive.detailDrive).then((res: any) => {
        setIsLoading(false);
        blobToBase64(res.data).then((imageUrl: any) => {
          setImgUrl(imageUrl);
        });
        res.data.arrayBuffer().then((arrayBuffer: any) => {
          setUrlFileViewer(arrayBuffer);
        });
        return;
      });
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isMobile) document.addEventListener('click', checkIfClickedOutside);
    return () => {
      if (!isMobile)
        document.removeEventListener('click', checkIfClickedOutside);
    };
  }, []);

  const onClickEvent = (evt: string) => {
    if (evt === EnumListControl.RENAME) {
      onFetchGroupDetailData({
        variables: {
          fileId: groupsDetailState?.data?.id || ''
        }
      });
    }
    if (evt === EnumListControl.DELETE) {
      if (hideViewerPDF) {
        hideViewerPDF();
      }
    }
  };
  useEffect(() => {
    onSetShowHideFileDetail({
      detailDrive: groupsDetailState?.data
    });
  }, [groupsDetailState]);

  return (
    <>
      <div className={classes.rootViewer}>
        <div className={isOpen ? 'open-modal' : 'hide-modal'}>
          <div className={classes.iconBackDrop}>
            <IconButton onClick={() => onBack()}>
              <IBMChevronLeft />
            </IconButton>
          </div>
          <div
            className="header-viewer"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="text" />
            <div className={classes.nameFile}>
              {groupsDetailState?.data?.fileName}
            </div>
            <div className="text-right" />
          </div>
          <div
            className={classes.optionFile}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {!matches && (
              <IconButton size="small">
                <img
                  src={IBMDownloadWhite}
                  alt="IBMDownloadWhite"
                  onClick={(e) => {
                    e.stopPropagation();
                    onDownloadFiles(myDrive.detailDrive);
                  }}
                />
              </IconButton>
            )}
            <div className="icon-btn-control">
              <DashboardListControlBtn
                isOpenFileViewer={isOpen}
                onClickEvent={onClickEvent}
                isShare={true}
                isShowDownLoad={matches!}
                isShowMoreVertMenu={true}
                files={fileViewer}
                type={EnumDashboardListControlType.ListItem}
                callback={callback}
                myDrivefiles={myDrivefiles}
              />
            </div>
          </div>
          <div
            className={
              convertSubstring(DRAWING_FILE_TYPES).includes(originalFileExt) ||
              convertSubstring(SUPORT_DOCUMENT_TYPES).includes(
                originalFileExt
              ) ||
              convertSubstring(IMAGE_TYPES).includes(originalFileExt)
                ? ''
                : 'viewer-pdf'
            }
            ref={ref}
          >
            {originalFileExt === PDF_TYPE ? (
              <PDFViewer fileUrl={new Uint8Array(urlFileViewer)} />
            ) : null}
            {originalFileExt &&
              myDrive.detailDrive &&
              convertSubstring(SUPORT_DOCUMENT_TYPES).includes(
                originalFileExt
              ) && (
                <div className={classes.fileViewer}>
                  <OfficeFileViewer file={myDrive?.detailDrive} />
                </div>
              )}
            {originalFileExt &&
            convertSubstring(NO_SUPORT_DOCUMENT_TYPES).includes(
              originalFileExt
            ) ? (
              <div className={classes.noPreview}>
                <div className="title">No preview available</div>
                <CustomizedButton
                  className="button"
                  aria-haspopup="true"
                  variant="contained"
                  color="primary"
                  size="medium"
                  onClick={() => onDownloadFiles(myDrive.detailDrive)}
                >
                  Download
                </CustomizedButton>
              </div>
            ) : null}
          </div>
          {convertSubstring(DRAWING_FILE_TYPES).includes(originalFileExt) ? (
            <ViewerPresenter files={fileViewer} />
          ) : null}
          {convertSubstring(IMAGE_TYPES).includes(originalFileExt) ? (
            <div className={classes.imageViewerItem}>
              <div
                className={classes.imageViewer}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {isLoading ? (
                  <Loading open={isLoading} />
                ) : (
                  <TransformWrapper
                    initialScale={1}
                    initialPositionX={0}
                    initialPositionY={0}
                  >
                    {() => (
                      <React.Fragment>
                        <TransformComponent
                          wrapperClass={classes.wrapperClass}
                          contentClass={classes.contentClass}
                        >
                          <img
                            src={imgUrl}
                            alt={myDrive?.detailDrive?.fileName}
                          />
                        </TransformComponent>
                      </React.Fragment>
                    )}
                  </TransformWrapper>
                )}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default ViewerFile;
