import React, { useState } from 'react';
import clsx from 'clsx';
import useUserActionLog, {
  CapaActivityType,
  Enum_Action_Button_Enum
} from '@hooks/useUserActionLog';

import Portal from '@components/Portal';
import NotificationContainer from '@components/Notifications';
import { Tooltip, Badge, IconButton } from '@material-ui/core';
import useMyDrive from '@hooks/Drive/MyDrive/useMyDrive';
import { useLocation } from 'react-router-dom';
import useCommon from '@hooks/Common/useCommon';
import { useTranslation } from 'react-i18next';
import { BellIcon, BellIconWhite } from '@assets';
import { useStyleExtraBtn } from './styles';

const NotificationMenuPresenter: React.FC = () => {
  const classes = useStyleExtraBtn();
  const { onInsertUserActionLogOne } = useUserActionLog();
  const { pathname } = useLocation();
  const { myDrive } = useMyDrive();
  const { common, onShowHideNumberNotice } = useCommon();
  const { t } = useTranslation();

  const [notificationOpen, setNotificationOpen] = useState(false);

  const handleNotification = ({ open }: { open: boolean }) => {
    onInsertUserActionLogOne(
      Enum_Action_Button_Enum.Button_Gnb_Notification,
      CapaActivityType.ButtonClick
    );
    setNotificationOpen(open);
  };

  return (
    <div className={clsx(classes.root, 'gnb-notification-menu')}>
      <Portal elementId="portal">
        <NotificationContainer
          open={notificationOpen}
          onClose={handleNotification}
        />
      </Portal>
      <IconButton className={classes.toolTip} size="small">
        <Tooltip
          title={t('tooltip.notifications') || 'Notifications'}
          className={myDrive.totalNotification === 0 ? classes.hideTotal : ''}
        >
          <Badge
            invisible={!common.isShowNumberNotification}
            badgeContent={myDrive.totalNotification}
            onClick={async (e) => {
              e.stopPropagation();
              handleNotification({ open: !notificationOpen });
              onShowHideNumberNotice({
                isShowNumberNotification: false
              });
            }}
          >
            <img
              className={clsx(
                classes.bellIcon,
                notificationOpen ? classes.notificationBoxShadow : ''
              )}
              src={pathname === '/' ? BellIconWhite : BellIcon}
              alt="BellIcon"
            />
          </Badge>
        </Tooltip>
      </IconButton>
    </div>
  );
};

export default NotificationMenuPresenter;
