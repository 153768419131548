import { makeStyles } from '@material-ui/core';
import { colorSet } from '@components/Provider';

export const useStyles = makeStyles((theme) => ({
  changeLanguageButton: {
    padding: '6px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 500,
    cursor: 'pointer',
    marginRight: 20,
    borderRadius: 8,
    backgroundColor: colorSet.gray000,
    color: colorSet.gray800,
    '&:hover': {
      boxShadow: 'inset 999px 999px 0px rgba(0, 0, 0, 0.04)'
    }
  },
  landingPageBackgroundColorOpen: {
    backgroundColor: colorSet.gray1100
  },
  landingPageBackgroundColor: {
    backgroundColor: colorSet.gray2000
  },
  landingPageBoxShadowOpen: {
    boxShadow: 'inset 999px 999px 0px rgba(0, 0, 0, 0.08)'
  },
  changeLanguageLandingPageButton: {
    padding: '6px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 500,
    cursor: 'pointer',
    marginRight: 20,
    borderRadius: 8,
    color: colorSet.gray000,
    '&:hover': {
      backgroundColor: '#141515 !important'
    }
  },
  changeLanguageIcon: {
    marginRight: 7,
    width: 21,
    height: 21
  },
  languageText: {
    whiteSpace: 'nowrap'
  },
  customPopover: {
    '& .MuiPopover-paper': {
      boxShadow:
        '0px 8px 16px 2px rgba(97, 97, 97, 0.1), 0px 16px 32px 2px rgba(97, 97, 97, 0.1)'
    },
    [theme.breakpoints.down('xs')]: {
      background: `${colorSet.backgroundDrop}`,
      '& .MuiPopover-paper': {
        boxShadow: 'none',
        background: 'transparent',
        minHeight: 'auto'
      }
    }
  },
  csDialogContentPopup: {
    width: 242,
    padding: 15,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      background: `${colorSet.gray000}`,
      position: 'fixed',
      left: 0,
      bottom: 0,
      lineHeight: '150%',
      padding: '20px 15px',
      '&:nth-child(1)': {
        bottom: 64,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8
      }
    },
    '.MuiPopover-root': {
      background: `${colorSet.backgroundDrop}`
    },
    '& .MuiTypography-body1': {
      marginBottom: 15,
      display: 'flex',
      flexDirection: 'row',
      cursor: 'pointer',
      color: `${colorSet.gray900}`,
      fontSize: 16,
      lineHeight: '150%'
    },
    '& .spacing': {
      marginLeft: 24
    },
    '& .name': {
      marginLeft: 6,
      '& p': {
        color: `${colorSet.gray700}`
      }
    },
    '&:hover': {
      backgroundColor: colorSet.gray100
    }
  },
  checkIcon: {
    width: 20,
    height: 20,
    color: colorSet.primary500
  }
}));
