import React from 'react';
import { useStyles } from './styles';
import { IBMNoThumbnailImage } from '@assets';
import { ReactComponent as IBMCAD } from '../../../assets/icon/IBMCAD.svg';

interface Props {
  thumbnailUrl?: string;
  size?: number;
  error: boolean;
  isCAD?: boolean;
  setError: () => void;
}

const ThumbnailPresenter: React.FC<Props> = ({
  thumbnailUrl,
  size,
  error,
  isCAD,
  setError
}) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <img
        src={error ? IBMNoThumbnailImage : thumbnailUrl}
        alt=""
        width={size}
        height={size}
        onError={() => setError()}
      />
      {isCAD && <IBMCAD className="ibm-cad" />}
    </div>
  );
};

export default ThumbnailPresenter;
