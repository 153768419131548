import React, { useMemo } from 'react';
import jwtDecode from 'jwt-decode';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
  CapaErrorCode,
  ChangePasswordInput,
  useChangePasswordMutation
} from '@generated/Common/graphql';
import { STORAGE_ACTION, clearStorageAll } from '@utils/storage';
import useAuth from '@hooks/useAuth';
import { CustomizedTypography, CustomizedButton } from '@components/Customized';
import { useSnackbar } from '@components/Provider';
import { Box, makeStyles } from '@material-ui/core';
import { StyledInput } from './styles';
import { useTranslation } from 'react-i18next';

const PasswordEdit: React.FC = () => {
  const { t } = useTranslation();
  const { register, handleSubmit, formState } = useForm<ChangePasswordInput>({
    mode: 'onChange'
  });
  const { enqueueSnackbar } = useSnackbar();
  const { inputList, inputEntry, buttonGroup } = useStyles();
  const history = useHistory();
  const [changePassword] = useChangePasswordMutation();
  const { onInitAuthState } = useAuth();
  const userEmail = useMemo(() => {
    const accessToken = localStorage.getItem(
      STORAGE_ACTION.CONNECT_ACCESS_TOKEN
    );
    if (accessToken) {
      const decodedToken: { aud: string } = jwtDecode(accessToken);
      return decodedToken.aud;
    }
    return '';
  }, []);

  const onSubmit = (data: ChangePasswordInput) => {
    changePassword({
      variables: { ...data, newPasswordConfirm: data.newPassword }
    })
      .then((resp) => {
        const result = resp.data?.changePassword.result;
        const errorMessage = resp.data?.changePassword.errorMessage;
        const { Success, ParmeterValidationError, PasswordIncorrect } =
          CapaErrorCode;

        switch (result) {
          case Success:
            enqueueSnackbar(
              `비밀번호가 수정되었습니다. 수정하신 비밀번호로 다시 로그인 해주세요.`,
              {
                variant: 'success'
              }
            );
            clearStorageAll();
            onInitAuthState();
            history.replace('/login');
            break;
          case PasswordIncorrect:
            enqueueSnackbar(`비밀번호가 일치하지 않습니다.`, {
              variant: 'error'
            });
            break;
          case ParmeterValidationError: {
            if (errorMessage?.indexOf('must match') !== -1) {
              enqueueSnackbar(
                `신규 비밀번호와 신규 비밀번호 확인 값이 일치하지 않습니다.`,
                {
                  variant: 'error'
                }
              );
            } else {
              enqueueSnackbar(
                `비밀번호는 영문+숫자 조합으로  8글자 이상 16글자 이하로 입력해주세요.`,
                {
                  variant: 'error'
                }
              );
            }
            break;
          }
          default:
            enqueueSnackbar(
              `가입되지 않은 이메일이거나 비밀번호가 일치하지 않습니다. 계정 관련 문의는 고객센터(support@capa.ai)로 연락 주세요.`,
              { variant: 'error' }
            );
        }
      })
      .catch(() => {
        return enqueueSnackbar(
          `가입되지 않은 이메일이거나 비밀번호가 일치하지 않습니다. 계정 관련 문의는 고객센터(support@capa.ai)로 연락 주세요.`,
          { variant: 'error' }
        );
      });
  };

  return (
    <Box className={inputList}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box className={inputEntry}>
          <CustomizedTypography
            className="passwordEditLabel"
            variant="body1"
            color="textSecondary"
          >
            {t('account_page.email')}
          </CustomizedTypography>
          <StyledInput
            size="small"
            variant="outlined"
            fullWidth
            type={'text'}
            defaultValue={userEmail}
            inputProps={{ defaultValue: userEmail }}
            disabled
          />
        </Box>
        <Box className={inputEntry}>
          <CustomizedTypography
            className="passwordEditLabel"
            variant="body1"
            color="textSecondary"
          >
            현재 비밀번호
          </CustomizedTypography>
          <StyledInput
            type={'password'}
            size="small"
            variant="outlined"
            fullWidth
            placeholder="현재 비밀번호"
            autoComplete="new-password"
            inputProps={{ name: 'password', ref: register({ required: true }) }}
          />
        </Box>
        <Box className={inputEntry}>
          <CustomizedTypography
            className="passwordEditLabel"
            variant="body1"
            color="textSecondary"
          >
            신규 비밀번호
          </CustomizedTypography>
          <StyledInput
            type={'password'}
            size="small"
            variant="outlined"
            fullWidth
            placeholder="신규 비밀번호 (영문, 숫자조합 8자 이상)"
            autoComplete="new-password"
            inputProps={{
              name: 'newPassword',
              ref: register({ required: true })
            }}
          />
        </Box>
        <Box className={buttonGroup}>
          <CustomizedButton
            variant="outlined"
            color="default"
            type="button"
            onClick={() => {
              history.push('/mypage/account');
            }}
          >
            {t('account_page.cancel')}
          </CustomizedButton>
          <CustomizedButton
            variant="contained"
            color="primary"
            type="submit"
            disabled={!formState.isValid}
          >
            {t('account_page.submit')}
          </CustomizedButton>
        </Box>
      </form>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  inputList: {
    dispaly: 'flex',
    flexDirection: 'column'
  },
  inputEntry: {
    dispaly: 'flex',
    flexDirection: 'column',
    '& .passwordEditLabel': {
      marginBottom: 12
    },
    '&:not(:last-child)': {
      marginBottom: 24
    }
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      '&:not(:first-child)': {
        marginLeft: 8
      }
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      '& button': {
        flex: 1
      }
    }
  }
}));

export default PasswordEdit;
