import colorSet from './colorSet';
import { makeStyles } from '@material-ui/core';

const snackbarStyles = makeStyles((theme) => ({
  // Mui 스타일이 !important로 적용되어 있어
  // custom 스타일에 !important 추가하지 않으면 적용 되지 않음
  contentRoot: {
    color: `${colorSet.gray000} !important`,
    backgroundColor: `${colorSet.gray900} !important`,
    borderRadius: '8px',
    paddingTop: 0,
    paddingBottom: 0,
    '& #notistack-snackbar': {
      [theme.breakpoints.down('xs')]: {
        width: '80%'
      }
    }
  },
  variantSuccess: {
    color: `${colorSet.gray000} !important`,
    backgroundColor: `${colorSet.gray900} !important`
  },
  variantError: {
    color: `${colorSet.gray000} !important`,
    backgroundColor: `${colorSet.gray900} !important`
  },
  closeIcon: {
    color: colorSet.gray000,
    fontSize: 20
  }
}));

export default snackbarStyles;
