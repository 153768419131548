import React from 'react';

interface Props {
  linkFileOffice?: string;
}

const OfficeFileViewerPresenter = (props: Props) => {
  const { linkFileOffice } = props;
  return (
    <iframe
      src={linkFileOffice}
      width="100%"
      height="100%"
      title="Office File viewer"
    ></iframe>
  );
};

export default OfficeFileViewerPresenter;
