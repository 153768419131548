import React, { useState } from 'react';
import AppConfig from '@config/index';
import ThumbnailPresenter from './ThumbnailPresenter';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { convertSubstring } from '@utils/file';
import { DRAWING_FILE_TYPES } from '@routes/Common/Dropzone/types';

interface Props {
  file?: any;
  size?: number;
  sizeWidth?: number;
}

const ThumbnailContainer = (props: Props) => {
  const { file, size, sizeWidth } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [error, setError] = useState(false);
  const thumbnailSize = isMobile
    ? sizeWidth
      ? sizeWidth
      : 62
    : size
    ? size
    : 72;

  const thumbnailUrl = `${AppConfig.capaViewerApi}/${file?.thumbnail}`;

  const isCAD = convertSubstring(DRAWING_FILE_TYPES).includes(
    file?.originalFileExt?.toLocaleLowerCase()
  );

  return (
    <ThumbnailPresenter
      thumbnailUrl={thumbnailUrl}
      size={thumbnailSize}
      error={error}
      isCAD={isCAD}
      setError={() => setError(true)}
    />
  );
};

export default ThumbnailContainer;
