import { ActionType } from 'typesafe-actions';
import * as actions from './actions';

export type ICommonState = {
  isOpenFeedback?: any;
  isFeedback?: any;
  isShowNumberNotification?: boolean;
  isShowWelcomeDialog?: boolean;
  unReadCountGroupList?: number;
  isDeleteChatFileSuccess?: boolean;
};

export const initialCommonState: ICommonState = {
  isOpenFeedback: false,
  isFeedback: false,
  isShowNumberNotification: true,
  isShowWelcomeDialog: false,
  unReadCountGroupList: 0,
  isDeleteChatFileSuccess: false
};

export type CommonAction = ActionType<typeof actions>;
