import { makeStyles } from '@material-ui/core';
import { colorSet } from '@components/Provider';

export const useStyleExtraBtn = makeStyles((theme) => ({
  root: {
    color: colorSet.gray900
  },
  toolTip: {
    padding: 0,
    '& button': {
      width: 32,
      height: 32
    },
    '&.MuiIconButton-root': {
      '&:hover': {
        backgroundColor: `${colorSet.gray000} !important`
      }
    },

    '& .MuiBadge-badge': {
      fontSize: 9,
      whiteSpace: 'nowrap'
    }
  },
  hideTotal: {
    '& .MuiBadge-anchorOriginTopRightRectangle': {
      display: 'none'
    }
  },
  bellIcon: {
    borderRadius: '100%',
    '&:hover': {
      boxShadow: 'inset 999px 999px 0px rgba(0, 0, 0, 0.04)'
    }
  },
  notificationBoxShadow: {
    boxShadow: 'inset 999px 999px 0px rgba(0, 0, 0, 0.08)'
  }
}));
