import React, { useEffect, useState } from 'react';
import { useStyles, AttachFileButtonMenu, FilterSearch } from './styles';
import { ArrowBackIosOutlined, Add } from '@material-ui/icons';
import {
  FILE_LIST_CONTROL_VALUE,
  FILE_LIST_CONTROL_OPTIONS,
  FILE_TYPE_FILTER,
  SORT_BY
} from './types';
import { CustomizedTypography } from '@components/Customized';
import clsx from 'clsx';
import useMyDrive from '@hooks/Drive/MyDrive/useMyDrive';
import {
  RequestDownloadPermission,
  UnshareFileFromGroup,
  AddConnectFiles,
  useShareFilesToGroupMutation,
  SubscribeConnectUpdateData,
  SubscribeFileStatusUpdate,
  CapaFileConvertStatus
} from '@generated/Drive/MyDrive/graphql';
import { useHistory, useLocation } from 'react-router-dom';
import { CapaErrorCode } from '@generated/Common/graphql';
import UploadFiles from '@routes/Common/Dialog/UploadFiles/UploadFiles';
import { useSnackbar } from '@components/Provider';
import { useTranslation } from 'react-i18next';
import { MenuItem } from '@material-ui/core';
import {
  Button,
  IconButton,
  Select,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { useStyles as useUploadFileStyles } from '@routes/Common/Dialog/UploadFiles/styles';
import {
  CAPAConnectSharedFileType,
  CAPA_FILE_CONVERT_STATUS,
  CONNECT_UPDATE_TYPE,
  HAS_COMMENT,
  PERMISSION_DOWNLOAD
} from '@routes/Common/types';
import useFiles from '@hooks/useFiles';
import { useMutation, useSubscription } from '@apollo/client';
import { UPLOAD_FILE_TAB } from '@routes/Common/Dialog/UploadFiles/types';
import { newFileUploadApi } from '@api/index';
import UploadFilesContainer from '@routes/Common/Dialog/UploadFiles/Mobile/UploadFilesContainer';
import { CREATE_GROUP_SCREEN } from '@routes/Group/Dialog/types';
import UploadFilesFrom from '@routes/Common/Dialog/UploadFiles/Mobile/UploadFilesFrom';
import useGroup from '@hooks/Group/useGroup';
import { ADD_FILES_FROM_VALUE } from '@routes/Common/Dialog/UploadFiles/Mobile/types';
import { convertSubstring, getFileExtension } from '@utils/file';
import {
  DOCUMENT_TYPES_FILE,
  DRAWING_FILE_TYPES,
  IMAGE_TYPES,
  PDF_TYPE,
  VIDEO_TYPES
} from '@routes/Common/Dropzone/types';
import { IBMFile, IBMPDF } from '@assets';
import AppConfig from '@config/index';
import useDashboard from '@hooks/useDashboard';
import {
  HOOPS_VIEWER_IFRAME_ID,
  HOOPS_VIEWER_MESSAGE
} from '@routes/Viewer/types';
import DownloadPermissionDialog from '@routes/Dashboard/Dialog/DownloadPermission/DownloadPermission';
import Loading from '@components/LoadingInComponent';
import { getStorageItem, STORAGE_ACTION } from '@utils/storage';
import { checkShareTimes } from '@routes/Dashboard/Dialog/Share/utils';
import useCommon from '@hooks/Common/useCommon';
import { Tooltip } from '@material-ui/core';
import { Thumbnail } from '@routes/Common';
import { ReactComponent as IBMOverflowMenuHorizontal } from '../../../assets/icon/IBMOverflowMenuHorizontal.svg';
import { ReactComponent as IBMSettingsCheck } from '../../../assets/icon/IBMSettingsCheck.svg';
import { ReactComponent as IBMDownload } from '../../../assets/icon/IBMDownload.svg';
import { ReactComponent as IBMCloudUpload } from '../../../assets/icon/IBMCloudUpload.svg';
import { ReactComponent as IBMArrowDown } from '../../../assets/icon/IBMArrowDown.svg';
import { ReactComponent as IBMChevronLeft } from '../../../assets/icon/IBMChevronLeft.svg';
import { ReactComponent as IBMSearch } from '../../../assets/icon/IBMSearch.svg';
import { ReactComponent as IBMSettingsAdjust } from '../../../assets/icon/IBMSettingsAdjust.svg';
import { ReactComponent as IBMClose } from '../../../assets/icon/IBMClose.svg';
import { ReactComponent as IBMChevronDown } from '../../../assets/icon/IBMChevronDown.svg';
import { ReactComponent as IBMCheckMark } from '../../../assets/icon/IBMCheckMark.svg';
import { ReactComponent as IBMCAD } from '../../../assets/icon/IBMCAD.svg';
import { ReactComponent as IBMCrowdReport } from '../../../assets/icon/IBMCrowdReport.svg';
import { ReactComponent as IBMDocumentUnknown } from '../../../assets/icon/IBMDocumentUnknown.svg';
import i18n from '@i18n/index';
import { LANGUAGE_VALUE } from '@routes/Common/ChangeLanguage/types';
import { formatFileSize } from '@utils/formatter';
import useGroupRoom from '@hooks/GroupRoom/useGroupRoom';

interface Props {
  fileId?: string;
  isChatBotGroup: boolean;
}

const FileList: React.FC<Props> = ({ fileId, isChatBotGroup }) => {
  const { search } = useLocation();
  const groupId = new URLSearchParams(search).get('groupId');
  const classes = useStyles();
  const uploadFileClasses = useUploadFileStyles();
  const [activeFileId, setActiveFileId] = useState<any>(null);
  const [activeFile, setActiveFile] = useState<any>(null);
  const {
    myDrive,
    onSetAddFilesFromType,
    onSetFirstSelectedDrawFile,
    onSetShowHideFileDetail,
    onSetShowHideDownloadPermissionDialog,
    onSetActiveFileIdInGroupRoom,
    onSetUploadFileFromComputerStatus,
    onSetSelectedGroupShared,
    onSetShowHideRequestForDownload,
    onToggleGroupFiles
  } = useMyDrive();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const history = useHistory();
  const { common, openFeedbackDialog, onSetDeleteChatBotSuccessStatus } =
    useCommon();
  const { onFetchGroupFilesData, groupDataFilesState } = useGroupRoom();
  const [ShareFilesToGroup] = useShareFilesToGroupMutation();
  const { enqueueSnackbar } = useSnackbar();
  const [isShowHideUploadFilesDialog, setShowHideUploadFilesDialog] =
    useState(false);
  const [
    isShowHideUploadFilesToFileListMobileDialog,
    setShowHideUploadFilesToFileListMobileDialog
  ] = useState(false);
  const [unshareFileFromGroup] = useMutation(UnshareFileFromGroup);
  const [addConnectFiles] = useMutation(AddConnectFiles);
  const [requestDownloadPermission] = useMutation(RequestDownloadPermission);
  const userInfo = getStorageItem(STORAGE_ACTION.USER_INFO);
  const { onDownloadConnectFile } = useFiles();

  const [attachFileMenuAnchorEl, setAttachFileMenuAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const isOpenAttachFileMenu = Boolean(attachFileMenuAnchorEl);
  const [anchorElFilter, setAnchorElFilter] =
    React.useState<null | HTMLElement>(null);
  const isOpenFilter = Boolean(anchorElFilter);

  const { t } = useTranslation();
  const { group, onSetCreateGroupScreen } = useGroup();
  const storageUrl = `${AppConfig.capaViewerApi}`;
  const { onUpdateDashboard } = useDashboard();
  const [isRefetchGroup, setIsRefetchGroup] = useState<boolean>(false);
  const [showControlList, setShowControlList] = useState<any>([]);
  const [keyword, setKeyword] = useState<string>('');
  const [fileType, setFileType] = useState<string>(
    CAPAConnectSharedFileType.ALL
  );
  const [fileTypeSubmit, setFileTypeSubmit] = useState<string>(
    CAPAConnectSharedFileType.ALL
  );
  const [isSearchMode, setIsSearchMode] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<string>(SORT_BY.DATE_ASC);
  const [isFirstLoad, setFirstLoadStatus] = useState<boolean>(true);

  const [internalGroupFilesState, setInternalGroupFilesState] = useState<any>(
    []
  );

  const handleClickFilter = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElFilter(event.currentTarget);
  };
  const handleCloseFilter = () => {
    setFileType(fileTypeSubmit);
    setAnchorElFilter(null);
  };
  const handleClickOpenPopUp = (
    event: React.MouseEvent<HTMLElement>,
    fileId: any
  ) => {
    setAttachFileMenuAnchorEl(event.currentTarget);
  };

  const onClickActiveFile = (file: any) => {
    setActiveFile(file);
    setActiveFileId(file.id);
  };

  useEffect(() => {
    if (fileTypeSubmit) {
      onFilter(sortBy, fileTypeSubmit, keyword);
    }
  }, [fileTypeSubmit, sortBy]);

  useEffect(() => {
    if (!common.isDeleteChatFileSuccess) return;

    setActiveFile(null);
    setActiveFileId('');
    onSetShowHideFileDetail({
      detailDrive: ''
    });
    onSetDeleteChatBotSuccessStatus({
      isDeleteChatFileSuccess: false
    });
  }, [common.isDeleteChatFileSuccess]);

  useEffect(() => {
    if (groupDataFilesState?.data?.length > 0) {
      if (
        isFirstLoad &&
        (history.location.search.includes('firstItem') ||
          Object.keys(myDrive.detailDrive).length === 0)
      ) {
        const activeFirstFile =
          groupDataFilesState?.data?.filter(
            (item: any) => item?.isFirstShared
          )[0] ?? null;
        if (activeFirstFile) {
          setActiveFileId(activeFirstFile?.id);
          setActiveFile(activeFirstFile);
          setFirstLoadStatus(false);
        } else if (!activeFirstFile && isChatBotGroup) {
          setActiveFileId(groupDataFilesState?.data[0]?.id);
          setActiveFile(groupDataFilesState?.data[0]);
        }
      } else {
        setActiveFileId(myDrive?.detailDrive?.id);
        setActiveFile(myDrive.detailDrive);
      }
      setInternalGroupFilesState(groupDataFilesState?.data);
    } else if (groupDataFilesState?.data?.length == 0) {
      setInternalGroupFilesState([]);
      onSetShowHideFileDetail({
        detailDrive: ''
      });
    }
  }, [groupDataFilesState, isChatBotGroup]);

  useEffect(() => {
    if (myDrive.isUploadFileFromComputer) {
      const fakeFiles = myDrive.internalFileFromComputer.map((file: any) => {
        const internalOriginalFileExt = getFileExtension(file.name);
        return {
          id: null,
          fileId: null,
          originalFileExt: internalOriginalFileExt,
          fileName: file.name,
          email: userInfo.email,
          thumbnail: null,
          isFile: true,
          groupId: groupId,
          drawingFile: null,
          connectUserName: userInfo.userName,
          status: convertSubstring(DRAWING_FILE_TYPES).includes(
            internalOriginalFileExt.toLocaleLowerCase()
          )
            ? null
            : CapaFileConvertStatus.Processing,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString()
        };
      });
      setInternalGroupFilesState((prevState: any) => {
        return [...prevState, ...fakeFiles];
      });
    } else {
      setInternalGroupFilesState(groupDataFilesState?.data);
    }
  }, [myDrive.isUploadFileFromComputer]);

  useEffect(() => {
    return () => {
      setActiveFileId('');
    };
  }, []);

  useSubscription(SubscribeFileStatusUpdate, {
    onSubscriptionData: (subscribeFileStatusUpdate) => {
      if (
        subscribeFileStatusUpdate &&
        subscribeFileStatusUpdate?.subscriptionData?.data
          ?.subscribeFileStatusUpdate?.data?.status ===
          CAPA_FILE_CONVERT_STATUS.CONVERTED
      ) {
        onFilter();
      }
    }
  });

  useEffect(() => {
    if (fileId && groupDataFilesState?.data) {
      const activeFileName = groupDataFilesState?.data?.filter(
        (file: any) => file.id === fileId
      );

      const payload = {
        groupId: groupId,
        connectFileId: fileId
      };
      onSetSelectedGroupShared({ selectedGroupShared: payload });

      if (history.location.search.includes('showDownloadPermission')) {
        onSetShowHideDownloadPermissionDialog({
          isShowHideDownloadPermissionDialog: true
        });
      }

      setActiveFileId(activeFileName[0]?.id);
      setActiveFile(activeFileName[0]);
    }
  }, [fileId, groupDataFilesState?.data]);

  useSubscription(SubscribeConnectUpdateData, {
    onSubscriptionData: (subscriptionData) => {
      const subscribeConnectUpdateData =
        subscriptionData?.subscriptionData?.data;
      if (subscribeConnectUpdateData) {
        switch (
          subscribeConnectUpdateData?.subscribeConnectUpdateData?.data?.type
        ) {
          case CONNECT_UPDATE_TYPE.GROUP_UPDATE: {
            onFilter();
            break;
          }
          case CONNECT_UPDATE_TYPE.FILE_UPDATE: {
            onFilter();
            break;
          }
          case CONNECT_UPDATE_TYPE.MEMBER_UPDATE: {
            onFilter();
            break;
          }
        }
      }
    }
  });

  useEffect(() => {
    if (isRefetchGroup) {
      onFilter();
      setActiveFile(null);
      setActiveFileId('');
      setIsRefetchGroup(false);
    }
  }, [isRefetchGroup]);

  useEffect(() => {
    if (activeFileId) {
      onSetActiveFileIdInGroupRoom({
        activeFileIdInGroupRoom: activeFileId
      });
      if (
        myDrive.isFirstSelectedDrawFile &&
        convertSubstring(DRAWING_FILE_TYPES).includes(
          activeFile?.originalFileExt?.toLocaleLowerCase()
        )
      ) {
        if (activeFile && document.getElementById(HOOPS_VIEWER_IFRAME_ID)) {
          const viewerFrame = document.getElementById(
            HOOPS_VIEWER_IFRAME_ID
          ) as HTMLIFrameElement;

          if (viewerFrame.contentWindow) {
            viewerFrame.contentWindow.postMessage(
              {
                message: HOOPS_VIEWER_MESSAGE.LOAD.LOAD_MODEL,
                data: {
                  modelId: `${AppConfig.capaViewerApi}/${activeFile.drawingFile}`
                }
              },
              '*'
            );
          }
        }
      }

      onSetShowHideFileDetail({
        detailDrive: activeFile
      });
      onSetFirstSelectedDrawFile({ isFirstSelectedDrawFile: false });

      onUpdateDashboard({ files: [activeFile] });
    }
  }, [activeFileId, activeFile]);

  useEffect(() => {
    if (myDrive.activeFileIdInGroupRoom) {
      const payload = {
        groupId: groupId,
        connectFileId: groupDataFilesState?.data?.filter(
          (file: any) => file.id === myDrive.activeFileIdInGroupRoom
        )[0]?.id
      };
      onSetSelectedGroupShared({ selectedGroupShared: payload });
      groupDataFilesState?.data?.forEach((file: any) => {
        if (file.id === myDrive.activeFileIdInGroupRoom) {
          setActiveFileId(file.id);
          setActiveFile(file);
        }
      });
    }
  }, [myDrive.activeFileIdInGroupRoom]);

  const onCloseUploadFilesDialog = () => {
    setShowHideUploadFilesToFileListMobileDialog(false);
    setShowHideUploadFilesDialog(false);
    onSetAddFilesFromType({ addFilesFromType: ADD_FILES_FROM_VALUE.EMPTY });
    onSetCreateGroupScreen({
      createGroupScreen: CREATE_GROUP_SCREEN.CREATE_GROUP
    });
  };

  const onAddFile = () => {
    if (
      myDrive.uploadFileTab === UPLOAD_FILE_TAB.FROM_COMPUTER ||
      myDrive.addFilesFromType === ADD_FILES_FROM_VALUE.MY_DEVICE
    ) {
      shareFileToGroupFromComputer();
      return;
    }

    const selectedFilesUploadInChatIds = myDrive.selectedFilesUploadInChat.map(
      (file: any) => file.id
    );

    const payload: any = {
      groupId: groupId,
      connectFileIds: selectedFilesUploadInChatIds
    };

    ShareFilesToGroup({ variables: payload }).then((res) => {
      const internalConnectFileIds = selectedFilesUploadInChatIds.filter(
        (id: any) =>
          groupDataFilesState?.data
            ?.map((groupFile: any) => groupFile.id)
            .includes(id)
      );

      const result = res?.data?.shareFilesToGroup?.result;
      onCloseUploadFilesDialog();
      const successMessage =
        myDrive.selectedFilesUploadInChat.length === 1
          ? t('snackbar.shared_file_complete')
          : t('snackbar.shared_files_complete');

      if (result === CapaErrorCode.Success) {
        if (internalConnectFileIds.length === 0) {
          enqueueSnackbar(
            t('snackbar.this_file_is_already_shared_to_this_group'),
            {
              variant: 'success'
            }
          );
        } else {
          enqueueSnackbar(successMessage, {
            variant: 'success'
          });
        }

        onFilter();

        checkShareTimes(() => {
          openFeedbackDialog({
            isOpenFeedback: true,
            isFeedback: true
          });
        });
      } else {
        enqueueSnackbar(t('snackbar.shared_file_error'), {
          variant: 'success'
        });
      }
    });
  };

  const shareFileToGroupFromComputer = () => {
    const path = '';
    onCloseUploadFilesDialog();
    onSetUploadFileFromComputerStatus({
      isUploadFileFromComputer: true
    });
    newFileUploadApi(myDrive.internalFileFromComputer, path).then(
      async (responses) => {
        const fileIds = responses.map((res) => res.data.data.data[0].id);

        const payload: any = {
          groupId: groupId,
          connectFileIds: fileIds
        };
        ShareFilesToGroup({ variables: payload }).then((res) => {
          onSetUploadFileFromComputerStatus({
            isUploadFileFromComputer: false
          });
          const result = res?.data?.shareFilesToGroup?.result;
          const successMessage =
            myDrive.internalFileFromComputer.length === 1
              ? t('snackbar.shared_file_complete')
              : t('snackbar.shared_files_complete');

          if (result === CapaErrorCode.Success) {
            enqueueSnackbar(successMessage, {
              variant: 'success'
            });
            checkShareTimes(() => {
              openFeedbackDialog({
                isOpenFeedback: true,
                isFeedback: true
              });
            });
          } else {
            enqueueSnackbar(t('snackbar.shared_file_error'), {
              variant: 'success'
            });
          }
        });
      }
    );
  };

  useEffect(() => {
    onFilter();
  }, [groupId]);

  useEffect(() => {
    setShowControlList([
      FILE_LIST_CONTROL_VALUE.DOWNLOAD,
      FILE_LIST_CONTROL_VALUE.REMOVE
    ]);
  }, [activeFile]);

  const onClickControlButton = (value: any, file?: any) => {
    setAttachFileMenuAnchorEl(null);
    const selectedGroupSharedPayload = {
      groupId: groupId,
      connectFileId: file?.id
    };

    onSetSelectedGroupShared({
      selectedGroupShared: selectedGroupSharedPayload
    });

    switch (value) {
      case FILE_LIST_CONTROL_VALUE.REMOVE: {
        onDelete();
        break;
      }
      case FILE_LIST_CONTROL_VALUE.DOWNLOAD: {
        if (file.permission === PERMISSION_DOWNLOAD.DENIED) {
          onSetShowHideRequestForDownload({
            isShowHideRequestForDownload: true
          });
          return;
        }
        onDownloadFiles(file);
        break;
      }
      case FILE_LIST_CONTROL_VALUE.REQUEST_FOR_DOWNLOAD: {
        onRequestForDownload();
        break;
      }
      case FILE_LIST_CONTROL_VALUE.FILE_SETTING: {
        onSetShowHideDownloadPermissionDialog({
          isShowHideDownloadPermissionDialog: true
        });
        break;
      }
      case FILE_LIST_CONTROL_VALUE.ADD_TO_MY_DRIVE: {
        if (file.permission === PERMISSION_DOWNLOAD.DENIED) {
          onSetShowHideRequestForDownload({
            isShowHideRequestForDownload: true
          });
          return;
        }
        addConnectFiles({
          variables: {
            connectFileIds: [file.id]
          }
        }).then((res: any) => {
          const { result } = res.data.addConnectFiles;
          if (result === CapaErrorCode.Success) {
            enqueueSnackbar(
              t('snackbar.add_to_my_drive_success', {
                fileName: file.fileName
              }),
              {
                variant: 'success'
              }
            );
          } else if (result === CapaErrorCode.AlreadyHaveFileInDrive) {
            enqueueSnackbar(
              t('snackbar.already_have_file_in_drive', {
                variant: 'success'
              })
            );
          }
        });
        break;
      }
    }
  };

  const onRequestForDownload = () => {
    requestDownloadPermission({
      variables: {
        groupId: groupId,
        connectFileId: activeFile.id
      }
    }).then((res: any) => {
      if (res.data.requestDownloadPermission.result === CapaErrorCode.Success) {
        onFilter();

        enqueueSnackbar(t('snackbar.you_sent_a_request_for_download'), {
          variant: 'success'
        });
      } else {
        onSetShowHideRequestForDownload({
          isShowHideRequestForDownload: false
        });
      }
    });
  };

  const onDownloadFiles = (file: any) => {
    if (file) {
      onDownloadConnectFile(file)
        .then(() => {
          enqueueSnackbar(
            `${t('confirm_dialog.download.success_text', {
              fileName: file.fileName
            })}`,
            {
              variant: 'success'
            }
          );
        })
        .catch(() => {
          enqueueSnackbar(`${t('confirm_dialog.download.error_text')}`, {
            variant: 'error'
          });
        });
    }
  };

  const onDelete = () => {
    let payload: any = {
      groupId: groupId
    };

    const internalActiveMessage =
      myDrive.chatFilesOrigin?.data?.filter((item: any) =>
        item.chatFiles?.some((file: any) => file.fileId === activeFile?.id)
      )[0] ?? [];

    const chatFilesOrigin =
      (internalActiveMessage && internalActiveMessage?.chatFiles) ?? null;

    if (chatFilesOrigin && chatFilesOrigin?.length > 1) {
      payload.connectFileIds = [activeFile?.id];
    } else if (chatFilesOrigin && chatFilesOrigin?.length === 1) {
      payload.connectFileIds = [activeFile?.id];
      payload.messageIds = [internalActiveMessage && internalActiveMessage?.id];
    }

    unshareFileFromGroup({
      variables: payload
    }).then((res) => {
      const result = res?.data?.unshareFileFromGroup?.result;
      if (result === CapaErrorCode.Success) {
        onSetShowHideFileDetail({
          detailDrive: ''
        });
        enqueueSnackbar(t('snackbar.delete_file_complete'), {
          variant: 'success'
        });
        onFilter();
        setActiveFile(null);
        setActiveFileId('');
      }
    });
  };

  const onFilter = (
    sortByFilter?: string,
    fileTypeSubmitFilter?: string,
    keywordFilter?: string
  ) => {
    onFetchGroupFilesData({
      variables: {
        groupId: groupId,
        sortFileType: sortByFilter ?? sortBy,
        fileType: fileTypeSubmitFilter ?? fileTypeSubmit ?? undefined,
        keyword: keywordFilter ?? keyword ?? undefined
      }
    });
    if (fileTypeSubmit != CAPAConnectSharedFileType.ALL || keyword != '')
      setIsSearchMode(true);
    else setIsSearchMode(false);
  };
  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      onFilter(sortBy, fileTypeSubmit, keyword);
      if (keyword) setIsSearchMode(true);
      else if (fileTypeSubmit == CAPAConnectSharedFileType.ALL)
        setIsSearchMode(false);
    }
  };
  const handleChangeType = (event: any) => {
    setFileType(event.target.value as string);
  };
  const resetFilter = () => {
    setFileTypeSubmit(CAPAConnectSharedFileType.ALL);
    setFileType(CAPAConnectSharedFileType.ALL);
    setKeyword('');
    onFilter(sortBy, CAPAConnectSharedFileType.ALL, '');
    setIsSearchMode(false);
  };
  return (
    <>
      <div className={classes.root}>
        <div
          className={clsx(
            classes.collapseButton,
            myDrive.isShowGroupFiles && 'expanded'
          )}
          onClick={() =>
            onToggleGroupFiles({ isShowGroupFiles: !myDrive.isShowGroupFiles })
          }
        >
          <IBMChevronLeft />
        </div>
        <div className={classes.groupWraper}>
          <div className={classes.groupFile}>{t('group_room.files')}</div>
          {isChatBotGroup ? null : (
            <Button
              className={classes.sortBy}
              onClick={() => {
                setSortBy((value) =>
                  value === SORT_BY.DATE_ASC
                    ? SORT_BY.DATE_DESC
                    : SORT_BY.DATE_ASC
                );
                onFilter(sortBy, fileTypeSubmit, keyword);
              }}
            >
              <span>{t('group_room.date_shared')}</span>
              <IBMArrowDown
                className={sortBy === SORT_BY.DATE_DESC ? '' : 'desc'}
              />
            </Button>
          )}
        </div>
        <div className={classes.groupSearch}>
          <div className="icon_search">
            <IBMSearch />
          </div>
          <div className="input_search">
            <input
              placeholder={t('group_room.placeholder_search_files')}
              value={keyword}
              onChange={(event) => setKeyword(event.target.value)}
              onKeyDown={handleKeyDown}
            />
          </div>
          {keyword && isSearchMode && (
            <IconButton className="icon_filter" onClick={resetFilter}>
              <IBMClose />
            </IconButton>
          )}
          <IconButton
            className={clsx(
              'icon_filter',
              fileTypeSubmit !== CAPAConnectSharedFileType.ALL && 'active'
            )}
            aria-controls="filter-search"
            onClick={handleClickFilter}
          >
            <IBMSettingsAdjust />
          </IconButton>
          <FilterSearch
            id="filter-search"
            anchorEl={anchorElFilter}
            open={isOpenFilter}
            onClose={handleCloseFilter}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <div className="title">{t('group_room.file_type')}</div>
            <Select
              className="select-type"
              variant="outlined"
              margin="dense"
              value={fileType}
              displayEmpty
              fullWidth
              onChange={handleChangeType}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left'
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left'
                },
                classes: { paper: classes.selectMenu },
                getContentAnchorEl: null
              }}
              IconComponent={(props) => (
                <div className="select-icon">
                  <IBMChevronDown {...props} />
                </div>
              )}
            >
              {FILE_TYPE_FILTER.map((option, index) => {
                return (
                  <MenuItem
                    key={index}
                    value={option.value}
                    className={classes.selectOption}
                  >
                    {t(option.label)}
                    <IBMCheckMark
                      className={clsx(classes.selectOptionSelected, 'selected')}
                    />
                  </MenuItem>
                );
              })}
            </Select>

            <Button
              className={classes.apply}
              onClick={() => {
                setAnchorElFilter(null);
                setFileTypeSubmit(fileType);
              }}
            >
              {t('group_room.apply_filter')}
            </Button>
          </FilterSearch>
        </div>
        {isSearchMode && (
          <>
            <div className={classes.searchResultTitle}>
              {t('group_room.search_result')}
            </div>
            <div className={classes.filterChips}>
              {FILE_TYPE_FILTER.filter(
                (option: any) =>
                  option.value == fileTypeSubmit &&
                  option.value !== CAPAConnectSharedFileType.ALL
              ).map((item, index) => {
                return (
                  <div key={index} className={classes.filterChipItem}>
                    {t(item.label)}
                    <IconButton
                      className="close"
                      onClick={() => {
                        setFileTypeSubmit(CAPAConnectSharedFileType.ALL);
                        setFileType(CAPAConnectSharedFileType.ALL);
                        if (keyword == '') {
                          setIsSearchMode(false);
                          return;
                        }
                        onFilter(sortBy, fileTypeSubmit, keyword);
                      }}
                    >
                      <IBMClose />
                    </IconButton>
                  </div>
                );
              })}
            </div>
          </>
        )}
        {groupDataFilesState?.data === undefined ? (
          <Loading
            styles={{ height: 'calc(100vh - 165px)' }}
            open={groupDataFilesState?.data === undefined}
          />
        ) : (
          <div
            className={clsx(
              classes.listItem,
              isSearchMode ? 'search-mode' : '',
              fileTypeSubmit != CAPAConnectSharedFileType.ALL
                ? 'has-filter-type'
                : ''
            )}
          >
            {internalGroupFilesState &&
              internalGroupFilesState.map((file: any, index: number) => {
                const internalDate = new Date(file.createdAt);
                const day = internalDate.getDate();
                const month = internalDate.toDateString().substr(4, 3);
                const status = file.status;
                const timeShared =
                  i18n.language === LANGUAGE_VALUE.EN
                    ? `${month} ${day}`
                    : `${internalDate.getMonth() + 1}${i18n.t(
                        'time_stamp.month'
                      )} ${internalDate.getDate()}${i18n.t('time_stamp.day')}`;
                return (
                  <div key={index}>
                    <div
                      className={clsx(
                        classes.item,
                        file?.id === activeFileId ? classes.activeFile : ''
                      )}
                      onClick={() => {
                        if (file && (status === 'CONVERTED' || !status)) {
                          onClickActiveFile(file);
                        } else {
                          enqueueSnackbar(t('snackbar.status_converting'), {
                            variant: 'default'
                          });
                        }
                      }}
                    >
                      {file?.hasCommentThread == HAS_COMMENT.YES && (
                        <div className="comment-icon">
                          <IBMCrowdReport />
                        </div>
                      )}
                      <div className={clsx(classes.image, 'image-icon')}>
                        {convertSubstring(DRAWING_FILE_TYPES).includes(
                          file?.originalFileExt?.toLocaleLowerCase()
                        ) && (
                          <>
                            {file?.thumbnail ? (
                              <>
                                <img
                                  className="image-file"
                                  src={`${storageUrl}/${file?.thumbnail}`}
                                  alt="drawingImage"
                                />
                                <IBMCAD className="badge" />
                              </>
                            ) : (
                              <>
                                {file.status !==
                                  CapaFileConvertStatus.Converting && (
                                  <div className={classes.loadingWrapper}>
                                    <IBMDocumentUnknown />
                                  </div>
                                )}
                              </>
                            )}
                          </>
                        )}

                        {file?.status === CapaFileConvertStatus.Processing ||
                        file.status === CapaFileConvertStatus.Converting ? (
                          <div className={classes.loadingWrapper}>
                            <Loading
                              size={32}
                              open={true}
                              loadingWrapperClass={classes.loading}
                            />
                          </div>
                        ) : (
                          <>
                            {file?.originalFileExt?.toLocaleLowerCase() ===
                              PDF_TYPE && (
                              <div className="icon-svg-file-rar">
                                <img src={IBMPDF} alt="IBMPDF" />
                              </div>
                            )}
                            {[
                              ...convertSubstring(DOCUMENT_TYPES_FILE),
                              ...convertSubstring(VIDEO_TYPES)
                            ].includes(
                              file?.originalFileExt?.toLocaleLowerCase()
                            ) && (
                              <div className="icon-svg-file-rar">
                                <img src={IBMFile} alt="IBMFile" />
                              </div>
                            )}

                            {convertSubstring(IMAGE_TYPES).includes(
                              file?.originalFileExt?.toLocaleLowerCase()
                            ) && (
                              <div className="icon-svg">
                                <Thumbnail width={42} file={file} />
                              </div>
                            )}
                          </>
                        )}
                      </div>
                      <div className="table-content">
                        <Tooltip title={file.fileName}>
                          <div>
                            <CustomizedTypography
                              variant="body2"
                              gutterBottoms={2}
                              noWrap
                            >
                              {file.fileName}
                            </CustomizedTypography>
                          </div>
                        </Tooltip>
                        <CustomizedTypography
                          component={'div'}
                          variant="caption"
                          color="textSecondary"
                        >
                          <div className={classes.fileInfo}>
                            <span className={classes.fileSize}>
                              {formatFileSize(file.fileSize).toUpperCase()}
                            </span>
                            <span className={classes.dot}>·</span>
                            <span className={classes.sharedTime}>
                              {t('group_room.shared_on', {
                                time: timeShared
                              })}
                            </span>
                          </div>
                        </CustomizedTypography>
                        <Tooltip title={file.connectUserName}>
                          <div>
                            <CustomizedTypography
                              component={'div'}
                              gutterBottoms={2}
                              variant="caption"
                              color="textSecondary"
                            >
                              <div className={classes.fileInfo}>
                                {file.connectUserName}
                              </div>
                            </CustomizedTypography>
                          </div>
                        </Tooltip>
                      </div>
                      <div
                        className={clsx(
                          'menu-function',
                          activeFile?.id == file?.id &&
                            isOpenAttachFileMenu &&
                            'show-more'
                        )}
                      >
                        {file.email === userInfo.email ? (
                          <>
                            <IconButton
                              className="menu-function-item"
                              onClick={() =>
                                onClickControlButton(
                                  FILE_LIST_CONTROL_VALUE.FILE_SETTING,
                                  file
                                )
                              }
                            >
                              <IBMSettingsCheck />
                            </IconButton>
                            <IconButton
                              className="menu-function-item"
                              onClick={(event: any) => {
                                if (file.id) handleClickOpenPopUp(event, file);
                              }}
                            >
                              <IBMOverflowMenuHorizontal />
                            </IconButton>
                          </>
                        ) : (
                          <>
                            <IconButton
                              className="menu-function-item"
                              onClick={() =>
                                onClickControlButton(
                                  FILE_LIST_CONTROL_VALUE.DOWNLOAD,
                                  file
                                )
                              }
                            >
                              <IBMDownload />
                            </IconButton>
                            <IconButton
                              className="menu-function-item"
                              onClick={() =>
                                onClickControlButton(
                                  FILE_LIST_CONTROL_VALUE.ADD_TO_MY_DRIVE,
                                  file
                                )
                              }
                            >
                              <IBMCloudUpload />
                            </IconButton>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            {internalGroupFilesState &&
              internalGroupFilesState.length == 0 &&
              (isSearchMode ? (
                <div className={classes.emptyFilesShared}>
                  <div className={classes.emptyFilesSearchTitle}>
                    {t('group_room.search_result_empty')}
                  </div>
                </div>
              ) : (
                <div className={classes.emptyFilesShared}>
                  <div className={classes.emptyFilesTitle}>
                    {t('group_room.empty_files_share')}
                  </div>
                  <div className={classes.emptyFilesDescription}>
                    {t('group_room.drop_file_here')}
                  </div>
                </div>
              ))}
          </div>
        )}

        <AttachFileButtonMenu
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          anchorEl={attachFileMenuAnchorEl}
          getContentAnchorEl={null}
          open={isOpenAttachFileMenu}
          onClose={() => {
            setAttachFileMenuAnchorEl(null);
          }}
          keepMounted
          className={classes.menuMore}
        >
          {FILE_LIST_CONTROL_OPTIONS.map((option, index) => {
            return (
              <div key={index}>
                {showControlList.includes(option.value) && (
                  <>
                    <MenuItem
                      key={index}
                      onClick={() =>
                        onClickControlButton(option.value, activeFile)
                      }
                    >
                      <option.icon className="icon" />
                      {t(option.name)}
                    </MenuItem>
                    {option.borderBottom && (
                      <div className={classes.lineBottom} />
                    )}
                  </>
                )}
              </div>
            );
          })}
        </AttachFileButtonMenu>
      </div>

      {matches ? (
        <>
          <UploadFilesContainer
            isShowHideUploadFilesMobileDialog={
              group.createGroupScreen ===
                CREATE_GROUP_SCREEN.ADD_FILES_CONTAINER_MOBILE &&
              isShowHideUploadFilesToFileListMobileDialog
            }
            onClose={onCloseUploadFilesDialog}
          />
          <UploadFilesFrom
            isShowHideUploadFilesFromDialog={
              group.createGroupScreen ===
                CREATE_GROUP_SCREEN.ADD_FILES_FROM_MOBILE &&
              isShowHideUploadFilesToFileListMobileDialog
            }
            submitText={t('upload_files_modal.add_button')}
            onSubmit={onAddFile}
            onClose={onCloseUploadFilesDialog}
          />
        </>
      ) : (
        <UploadFiles
          onSubmit={onAddFile}
          onClose={onCloseUploadFilesDialog}
          submitText={t('upload_files_modal.add_button')}
          isShowHideUploadFilesDialog={isShowHideUploadFilesDialog}
          dialogTitleNode={
            matches ? (
              <ArrowBackIosOutlined
                className={uploadFileClasses.arrowBackIosOutlinedIcon}
                onClick={onCloseUploadFilesDialog}
              />
            ) : (
              <>
                <div className={uploadFileClasses.cloudUploadIconWrapper}>
                  <Add className={uploadFileClasses.cloudUploadAddIcon} />
                </div>
                {t('file_list.add_files_to')}
                <span className={uploadFileClasses.groupNameText}>
                  {groupDataFilesState?.data?.groupName}
                </span>
              </>
            )
          }
        />
      )}
      <DownloadPermissionDialog groupData={groupDataFilesState?.data} />
    </>
  );
};
export default FileList;
