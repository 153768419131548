import React, { useEffect } from 'react';
import GnbAppBarPresenter from './GnbAppBarPresenter';
import { redirectToGroupListNotConnect } from '@utils/common';
import { useHistory } from 'react-router-dom';

interface Props {
  isSelect?: boolean;
  count?: number;
  onSetIsSelect?: () => void;
  headerName?: string;
}

const GnbAppBarContainer: React.FC<Props> = ({
  isSelect,
  count,
  onSetIsSelect,
  headerName
}) => {
  const history = useHistory();

  useEffect(() => {
    redirectToGroupListNotConnect(history);
  }, []);
  return (
    <GnbAppBarPresenter
      isSelect={isSelect}
      count={count}
      onSetIsSelect={onSetIsSelect}
      headerName={headerName!}
    />
  );
};

export default GnbAppBarContainer;
