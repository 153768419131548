import React from 'react';
import DialogLayoutPresenter from './DialogLayoutPresenter';

interface Props {
  open: boolean;
  titleIcon?: React.ReactNode;
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  content?: React.ReactNode;
  footerBtn?: React.ReactNode;
  onClose?: () => void;
}

const DialogLayoutContainer: React.FC<Props> = (props) => {
  return <DialogLayoutPresenter {...props} />;
};

export default DialogLayoutContainer;
