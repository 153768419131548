import { colorSet } from '@components/Provider';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => {
  return {
    wrapper: {
      flexWrap: 'nowrap',
      position: 'fixed',
      top: 61,
      height: 600,
      right: 32,
      maxWidth: 358,
      [theme.breakpoints.down('xs')]: {
        top: 49,
        right: 16,
        maxWidth: 343
      },
      zIndex: 5000,
      backgroundColor: colorSet.gray000,
      borderRadius: 4,
      flexDirection: 'column',
      boxShadow:
        '0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12)',
      transition: 'height 0.7s ease-in-out'
    },

    wrapperHide: {
      display: 'none',
      flexWrap: 'nowrap',
      position: 'fixed',
      top: 61,
      height: 600,
      right: 32,
      maxWidth: 358,
      [theme.breakpoints.down('xs')]: {
        top: 49,
        right: 16,
        maxWidth: 343
      },
      zIndex: 5000,
      backgroundColor: colorSet.gray000,
      borderRadius: 4,
      flexDirection: 'column',
      boxShadow:
        '0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12)',
      transition: 'height 0.7s ease-in-out'
    },

    normalWrapper: {
      width: '100%',
      borderTop: '1px solid #F1F3F5'
    },

    header: {
      flexDirection: 'column',
      width: '100%',
      justifyContent: 'space-between',
      borderBottom: `1px solid ${colorSet.backgroundDark}`
    },
    headerNoBorder: {
      flexDirection: 'column',
      width: '100%',
      justifyContent: 'space-between'
    },
    content: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      width: '100%',
      height: '100%'
    },
    title: {
      color: colorSet.gray900,
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '24px',
      padding: '16px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    settingBtn: {
      color: colorSet.primary700,
      '& .MuiButton-label': {
        color: colorSet.gray900,
        textTransform: 'initial',
        fontSize: 14,
        lineHeight: '20.02px',
        letterSpacing: '-0.1px',
        '& svg': {
          marginLeft: 8,
          fontSize: 18
        }
      }
    },
    iconCheck: {
      color: colorSet.gray900,
      marginRight: 5
    },
    subtitle: {
      color: colorSet.gray600,
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '20px',
      margin: '0px 16px 12px 16px'
    },

    notification: {
      height: 480,
      overflow: 'auto',

      '&::-webkit-scrollbar': {
        width: 4
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: colorSet.gray600,
        borderRadius: 8
      }
    },

    notificationFullPage: {
      height: 'calc(100vh - 68px - 57px)',
      overflow: 'auto',
      width: '100%',

      '&::-webkit-scrollbar': {
        width: 4
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: colorSet.gray600,
        borderRadius: 8
      }
    },

    notificationList: {
      padding: 16,
      cursor: 'pointer',
      borderTop: `1px solid ${colorSet.gray200}`,
      background: 'transparent',

      '&:first-child': {
        border: 'none'
      },

      '&:hover, &:active, &:focus, &:visited': {
        background: 'transparent'
      }
    },
    unreadNotification: {
      background: colorSet.primary150
    },
    notificationTop: {
      display: 'flex'
    },
    notificationTitle: {
      flex: '1 0 auto',
      '& span': {
        fontSize: 12,
        borderRadius: 8,
        display: 'inline-block',
        padding: '4px 8px',
        fontWeight: 500
      },
      '& .colorGroup': {
        color: colorSet.primary500,
        background: colorSet.primary200
      },
      '& .colorComment': {
        color: colorSet.successMain,
        background: colorSet.successLight
      },
      '& .colorFile': {
        color: colorSet.warningDark,
        background: colorSet.warningLight
      }
    },
    notificationDate: {
      fontSize: 12,
      color: colorSet.gray600
    },
    notificationDesc: {
      marginTop: 10,
      fontSize: 16,
      color: colorSet.gray900,
      fontWeight: 'bold',
      lineHeight: '150%',
      wordBreak: 'break-word',

      '& .text-capitalize': {
        textTransform: 'capitalize'
      }
    },
    notificationName: {
      marginTop: 5,
      fontSize: 13,
      color: colorSet.gray700
    },
    emptyImage: {
      marginBottom: 24
    },
    emptyWrapper: {
      height: '100%'
    },
    loadingWrapper: {
      width: '100%',
      textAlign: 'center',
      paddingTop: 20
    }
  };
});

export const useNotiMessageStyles = makeStyles((theme) => {
  return {
    chip: {
      height: 24,
      borderRadius: 4,
      padding: '0px 8px',
      '& .MuiChip-label': {
        fontSize: 12,
        fontWeight: 500,
        lineHeight: '20px'
      }
    },
    notificationBox: {
      width: '100%',
      border: `1px solid ${colorSet.backgroundBase}`,
      padding: 16,
      marginBottom: 8,
      cursor: 'pointer',
      '& .MuiButton-label': {
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column'
      }
    },
    titleText: {
      fontWeight: 700,
      fontSize: 14,
      lineHeight: '20px',
      letterSpacing: '-0.1px',
      margin: '12px 0px 8px 0px'
    },
    contentText: {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '20px',
      letterSpacing: '-0.1px',
      whiteSpace: 'pre-line',
      textTransform: 'none',
      '&>span': {
        fontWeight: 500,
        fontSize: 14,
        lineHeight: '20px',
        letterSpacing: '-0.1px'
      }
    },
    time: {
      fontWeight: 400,
      fontSize: 12,
      lineHeight: '20px',
      color: colorSet.gray600,
      float: 'right'
    }
  };
});
