export const DROP_EFFECT = {
  ALL: 'all',
  MOVE: 'move',
  COPY: 'copy',
  LINK: 'link',
  COPY_MOVE: 'copyMove',
  COPY_LINK: 'copyLink',
  LINK_MOVE: 'linkMove',
  NONE: 'none'
};
