import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';
import { colorSet } from '@components/Provider';

const getDropzoneBackgroundColor = (props: any) => {
  if (props.isDragReject && !props.isGroupRoomPathName) {
    return colorSet.primary250;
  }
  return '';
};

const getDropzoneBorder = (props: any) => {
  if (props.isDragReject && !props.isGroupRoomPathName) {
    return `1px solid ${colorSet.primary450}`;
  }
  return 'none';
};

export const DropzoneContainer = styled.div`
  background-color: ${(props) => getDropzoneBackgroundColor(props)};
  border: ${(props) => getDropzoneBorder(props)};
`;
export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    outline: 'none'
  },
  rootParent: {
    height: '100%',
    outline: 'none'
  },
  wrapperOverlap: {
    position: 'fixed',
    width: '100%',
    height: 'calc(100vh - 60px)',
    top: 60,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(75, 136, 255, 0.1)',
    zIndex: 200000,
    cursor: 'pointer',
    border: '6px solid #4B88FF'
  },
  groupNameRootParent: {
    height: 'calc(100% - 142px)',
    [theme.breakpoints.down('xs')]: {
      height: 'calc(100% - 60px)'
    }
  },
  locationBox: {
    backgroundColor: colorSet.primary450,
    position: 'fixed',
    maxWidth: 280,
    bottom: '16px',
    left: '50%',
    boxShadow:
      '0px 5px 5px -3px rgba(0, 0, 0, 0.1), 0px 8px 10px 1px rgba(0, 0, 0, 0.07), 0px 3px 14px 2px rgba(0, 0, 0, 0.06)',
    borderRadius: ' 8px',
    color: '#ffffff',
    textAlign: 'center',
    padding: 16
  },
  groupNameLocationBox: {
    backgroundColor: colorSet.primary450,
    position: 'absolute',
    maxWidth: 260,
    bottom: 30,
    zIndex: 200000,
    transform: 'translate(-50%, 0px)',
    left: '50%',
    margin: 'auto',
    boxShadow:
      '0px 5px 5px -3px rgba(0, 0, 0, 0.1), 0px 8px 10px 1px rgba(0, 0, 0, 0.07), 0px 3px 14px 2px rgba(0, 0, 0, 0.06)',
    borderRadius: 8,
    color: '#ffffff',
    textAlign: 'center',
    padding: '16px 16px 23px 16px'
  },
  locationTitle: {
    letterSpacing: '-0.1px',
    fontWeight: 400,
    lineHeight: '138%',
    paddingBottom: 5,
    fontSize: 13
  },
  locationNameRow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  locationName: {
    fontSize: 16,
    fontWeight: 700,
    letterSpacing: '-0.1px',
    lineHeight: '150%'
  },
  haveLocationIcon: {
    marginLeft: 10
  },
  noLocationIcon: {
    marginLeft: 0
  }
}));
