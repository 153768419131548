import React from 'react';
import { Link } from 'react-router-dom';
import { LogoBeta } from '@assets';
import { ReactComponent as CapaLogoWhite } from '../../assets/icon/CapaLogoWhite.svg';
import { ReactComponent as CapaLogoWhiteKo } from '../../assets/icon/CapaLogoWhiteKo.svg';

import i18n from '@i18n/index';
import { LANGUAGE_VALUE } from '@routes/Common/ChangeLanguage/types';
import NotFoundContent from '@routes/NotFound/NotFoundContent';
import { useStylesNotFound } from './styles';

const NotFound = () => {
  const classes = useStylesNotFound();
  const languageValue = i18n.language;
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Link className={classes.logo} to={'/'} rel="noopener noreferrer">
          {languageValue === LANGUAGE_VALUE.KO ? (
            <CapaLogoWhiteKo />
          ) : (
            <CapaLogoWhite />
          )}
          <img src={LogoBeta} className="beta" alt="LogoBeta" />
        </Link>
      </div>
      <div className={classes.main}>
        <NotFoundContent />
      </div>
    </div>
  );
};

export default NotFound;
