import React, { useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AppBar, Toolbar } from '@material-ui/core';

import { CapaLogoBeta } from '@assets';
import StagingChipPresenter from '../StagingChipPresenter';
import { useStylesLayout } from './useStyles';

interface Props {
  GnbMenu?: React.ReactNode;
}

const LayoutPresenter: React.FC<Props> = ({ GnbMenu, children }) => {
  const history = useHistory();
  const classes = useStylesLayout();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.root}>
      <AppBar elevation={0} position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.logo}>
            <img
              src={CapaLogoBeta}
              alt="Logo"
              onClick={() => history.push('/')}
            />
            <StagingChipPresenter />
          </div>
          <div className={classes.menuWrapper}>{GnbMenu}</div>
        </Toolbar>
      </AppBar>
      <main className={classes.content}>
        <div className={classes.drawerHeader} />
        {children}
      </main>
    </div>
  );
};

export default LayoutPresenter;
