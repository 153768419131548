import React, { useEffect, useRef } from 'react';
import NotificationPresenter from './NotificationPresenter';

export interface NotificationProps {
  open: boolean;
  onClose: ({ open }: { open: boolean }) => void;
  isFullPage?: boolean;
  onShowDownloadApp?: () => void;
}

const NotifiacationContainer = (props: NotificationProps) => {
  const divEl = useRef<any>(null);

  const startDate = new Date();
  startDate.setHours(0);
  startDate.setMinutes(0, 0, 0);
  startDate.setDate(startDate.getDate() - 6);

  const handleClose = (e: MouseEvent) => {
    if (props.open && !divEl.current?.contains(e.target as any)) {
      props.onClose({ open: false });
    }
  };

  useEffect(() => {
    window.addEventListener('click', (e) => handleClose(e));
    return () => {
      window.removeEventListener('click', (e) => handleClose(e));
    };
    //eslint-disable-next-line
  }, [props.open]);

  return (
    <NotificationPresenter
      divRef={divEl}
      open={props.open}
      onClose={props.onClose}
      isFullPage={props.isFullPage}
      onShowDownloadApp={props.onShowDownloadApp}
    />
  );
};

export default NotifiacationContainer;
