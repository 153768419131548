import { createReducer } from 'typesafe-actions';
import { MyDetailAction, MyDriveState, initialMyDriveState } from './types';
import {
  INIT_DETAIL_STATE,
  SET_FILE_DETAIL,
  SET_PATH,
  SET_SHOW_HIDE_MENU_CREATE,
  SET_LOCATION_NAME,
  SET_SHOW_HIDE_SHARE_DIALOG,
  SET_SHARE_FILES_TO_TYPE,
  SET_SHARE_DIALOG_SCREEN,
  SET_VALUE_GROUP_NAME_DIALOG,
  SET_SHOW_HIDE_UPLOAD_FROM_MY_DRIVE_DIALOG,
  SET_SHOW_HIDE_DOWNLOAD_PERMISSION_DIALOG,
  SET_ID_GROUP_NAME,
  SET_SELECTED_GROUP_SHARED,
  SET_SHOW_FILE_VIEWER,
  SET_SHOW_HIDE_CONFIRM_DIALOG,
  SET_SELECTED_FILES_UPLOAD_IN_CHAT,
  SET_CHAT_ROOM_ID_GROUP_NAME,
  SET_UPLOAD_FILE_TAB,
  SET_INTERNAL_FILE_FROM_COMPUTER,
  SET_ADD_FILES_FROM_TYPE,
  SET_TOGGLE_GROUP_FILES,
  SET_TOGGLE_REMOVE_GROUP_FILES,
  SET_FIRST_SELECTED_DRAW_FILE,
  SET_FILE_NAME_IN_GROUP_ROOM,
  SET_ACTION_ANNOTATION_SUCCESS,
  SET_ACTIVE_FILE_ID_IN_GROUP_ROOM,
  SET_TOTAL_NOTIFICATION,
  SET_SELECT_DRAW_FILE_FROM_COMMENT_LIST,
  SET_RENAME_SUCCESS_STATUS,
  SET_UPLOAD_FILE_FROM_COMPUTER_STATUS,
  SET_DRAG_DROP_FILES_TO_GROUP_ROOM,
  SET_MY_DRIVE_PAGE_LOADING,
  SET_SUCCESSFULLY_SHARE_CONTENT,
  SET_SHOW_HIDE_REQUEST_FOR_DOWNLOAD
} from './actions';

export const myDrive = createReducer<MyDriveState, MyDetailAction>(
  initialMyDriveState,
  {
    [SET_FILE_DETAIL]: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    },
    [SET_SHOW_HIDE_MENU_CREATE]: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    },
    [SET_PATH]: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    },
    [SET_LOCATION_NAME]: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    },
    [SET_SHOW_HIDE_SHARE_DIALOG]: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    },
    [SET_SHARE_FILES_TO_TYPE]: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    },
    [SET_SHARE_DIALOG_SCREEN]: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    },
    [SET_VALUE_GROUP_NAME_DIALOG]: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    },
    [SET_SHOW_HIDE_UPLOAD_FROM_MY_DRIVE_DIALOG]: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    },
    [SET_SHOW_HIDE_DOWNLOAD_PERMISSION_DIALOG]: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    },
    [SET_ID_GROUP_NAME]: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    },
    [SET_CHAT_ROOM_ID_GROUP_NAME]: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    },
    [SET_SELECTED_GROUP_SHARED]: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    },

    [SET_SHOW_FILE_VIEWER]: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    },
    [SET_SHOW_HIDE_CONFIRM_DIALOG]: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    },
    [SET_SELECTED_FILES_UPLOAD_IN_CHAT]: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    },
    [SET_INTERNAL_FILE_FROM_COMPUTER]: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    },
    [SET_UPLOAD_FILE_TAB]: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    },
    [SET_ADD_FILES_FROM_TYPE]: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    },
    [SET_TOGGLE_GROUP_FILES]: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    },
    [SET_TOGGLE_REMOVE_GROUP_FILES]: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    },
    [SET_FIRST_SELECTED_DRAW_FILE]: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    },
    [SET_FILE_NAME_IN_GROUP_ROOM]: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    },
    [SET_ACTION_ANNOTATION_SUCCESS]: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    },
    [SET_ACTIVE_FILE_ID_IN_GROUP_ROOM]: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    },
    [SET_TOTAL_NOTIFICATION]: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    },
    [SET_SELECT_DRAW_FILE_FROM_COMMENT_LIST]: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    },
    [SET_RENAME_SUCCESS_STATUS]: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    },
    [SET_UPLOAD_FILE_FROM_COMPUTER_STATUS]: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    },
    [SET_DRAG_DROP_FILES_TO_GROUP_ROOM]: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    },
    [SET_MY_DRIVE_PAGE_LOADING]: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    },
    [SET_SUCCESSFULLY_SHARE_CONTENT]: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    },
    [SET_SHOW_HIDE_REQUEST_FOR_DOWNLOAD]: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    },
    [INIT_DETAIL_STATE]: () => initialMyDriveState
  }
);
