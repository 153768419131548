import React, { useEffect, useState } from 'react';
import { StyledInput, usernameEditStyles } from './styles';
import { CustomizedButton, CustomizedTypography } from '@components/Customized';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { UpdateConnectUser } from '@generated/Drive/MyDrive/graphql';
import { CapaErrorCode } from '@generated/Common/graphql';
import useAuth from '@hooks/useAuth';
import { checkOnlySpaces, trimSpaces } from '@utils/common';
import { blockSpecialRegex } from '@routes/Common/types';

const UsernameEdit = () => {
  const { userState } = useAuth();
  const { t } = useTranslation();
  const history = useHistory();
  const classes = usernameEditStyles();
  const [value, setValue] = useState(userState?.data?.userName ?? '');
  const [updateConnectUser] = useMutation(UpdateConnectUser);
  const [isValidMaxLength, setValidMaxLength] = useState<boolean>(true);
  const [isValidSpecial, setValidSpecial] = useState<boolean>(true);

  useEffect(() => {
    if (!userState) return;

    setValue(userState?.data?.userName ?? '');
  }, [userState]);

  useEffect(() => {
    if (value.length > 50) {
      setValidMaxLength(false);
      return;
    }

    if (!blockSpecialRegex.test(value)) {
      setValidSpecial(true);
    }

    setValidMaxLength(true);
  }, [value]);

  const goToMyPage = () => {
    history.push('/mypage/account');
  };

  const onSave = () => {
    if (blockSpecialRegex.test(value)) {
      setValidSpecial(false);
      return;
    }
    updateConnectUser({
      variables: {
        username: trimSpaces(value)
      }
    }).then((res: any) => {
      if (res.data.updateConnectUser.result === CapaErrorCode.Success) {
        goToMyPage();
      }
    });
  };

  return (
    <>
      <CustomizedTypography variant="body1" className={classes.label}>
        {t('account_page.name')}
      </CustomizedTypography>
      <Box className={classes.customInput}>
        <StyledInput
          className={
            !isValidMaxLength ||
            (value.length > 0 && checkOnlySpaces(value)) ||
            !isValidSpecial
              ? classes.error
              : ''
          }
          variant="outlined"
          size="small"
          fullWidth
          placeholder={t('account_page.name')}
          value={value}
          onChange={(event) => setValue && setValue(event.target.value)}
        />
        {!isValidMaxLength ||
        (value.length > 0 && checkOnlySpaces(value)) ||
        !isValidSpecial ? (
          <p className={classes.validMaxLength}>
            {t('account_page.valid_username_max_length')}
          </p>
        ) : null}
      </Box>
      <Box className={classes.buttonGroup}>
        <CustomizedButton
          variant="outlined"
          color="default"
          type="button"
          className="cancel-button"
          onClick={() => goToMyPage()}
        >
          {t('account_page.cancel')}
        </CustomizedButton>
        <CustomizedButton
          variant="contained"
          color="primary"
          type="submit"
          disabled={!isValidMaxLength || checkOnlySpaces(value)}
          onClick={() => onSave()}
        >
          {t('account_page.done_button')}
        </CustomizedButton>
      </Box>
    </>
  );
};

export default UsernameEdit;
