import React, { useEffect, useState } from 'react';
import { useStyles } from './styles';
import { CloseOutlined } from '@material-ui/icons';
import { DownloadAppBannerIcon, DownloadAppBannerLanding } from '@assets';
import { useTranslation } from 'react-i18next';
import { CustomizedButton } from '@components/Customized';
import { getStorageItem, setStorageItem, STORAGE_ACTION } from '@utils/storage';
import { isMobile, isAndroid, isIOS } from 'react-device-detect';
import AppConfig from '@config/index';
import clsx from 'clsx';

interface Props {
  isLandingPage?: boolean;
}

const DownloadAppBannerPresenter = (props: Props) => {
  const { isLandingPage } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const closeDownloadAppBannerTime = getStorageItem(
    STORAGE_ACTION.CLOSE_DOWNLOAD_APP_BANNER_TIME
  )
    ? getStorageItem(STORAGE_ACTION.CLOSE_DOWNLOAD_APP_BANNER_TIME)
    : null;
  const [isOpen, setOpen] = useState(true);
  const showDownloadAppBannerMilliseconds = 21600000;

  const onSubmit = () => {
    if (isIOS) {
      window.location.href = `${AppConfig.downloadIOSUrl}`;
    } else if (isAndroid) {
      window.location.href = `${AppConfig.downloadAndroidUrl}`;
    }
  };

  const openDialog = () => {
    setOpen(true);
    setStorageItem(STORAGE_ACTION.CLOSE_DOWNLOAD_APP_BANNER_TIME, '');
  };

  const onClickClose = () => {
    setOpen(false);
    setStorageItem(STORAGE_ACTION.CLOSE_DOWNLOAD_APP_BANNER_TIME, new Date());

    setTimeout(() => {
      openDialog();
    }, showDownloadAppBannerMilliseconds);
  };

  useEffect(() => {
    const dateNew: any = new Date();

    if (closeDownloadAppBannerTime !== null) {
      if (
        dateNew.getTime() - Date.parse(closeDownloadAppBannerTime) >=
        showDownloadAppBannerMilliseconds
      ) {
        openDialog();
      } else {
        setOpen(false);
      }
    } else {
      openDialog();
    }
  }, []);

  if (!isMobile) return null;

  return (
    <>
      {!isOpen ? null : (
        <div
          className={clsx(
            isLandingPage ? classes.landingWrapper : classes.wrapper,
            'download-app-banner'
          )}
        >
          <CloseOutlined
            className={classes.closeIcon}
            onClick={() => onClickClose()}
          />
          <img
            src={
              isLandingPage ? DownloadAppBannerLanding : DownloadAppBannerIcon
            }
            className={classes.logo}
            alt=""
          />
          <div className={classes.capaConnect}>
            {t('download_app_banner.capa_connect')}
          </div>
          <CustomizedButton
            type="submit"
            color="primary"
            onClick={onSubmit}
            className={clsx(
              classes.downloadButton,
              isLandingPage
                ? classes.landingDownloadColor
                : classes.downloadColor
            )}
          >
            {t('download_app_banner.download')}
          </CustomizedButton>
        </div>
      )}
    </>
  );
};

export default DownloadAppBannerPresenter;
