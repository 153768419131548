import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import {
  CONNECT_USER_FILE_STATUS,
  LINK_SETTINGS_OPTIONS
} from '@routes/Dashboard/Dialog/Share/ShareLink/types';
import { Popover, Typography } from '@material-ui/core';
import { CheckOutlined } from '@material-ui/icons';
import { IBMSecurity, IBMDownload, IBMCaretDown, IBMCaretUp } from '@assets';
import { useMutation } from '@apollo/client';
import { UpdateConnectUserFilePermission } from '@generated/Drive/MyDrive/graphql';
import { CapaErrorCode } from '@generated/Common/graphql';
import { CapaActivityType } from '@generated/Common/types';
import { myDrivePathName } from '@routes/Common/types';
import { useLocation } from 'react-router-dom';
import useUserActionLog from '@hooks/useUserActionLog';
import clsx from 'clsx';
import useMyDrive from '@hooks/Drive/MyDrive/useMyDrive';

interface Props {
  checkedFiles?: any;
  permission?: any;
  isNewGroup?: any;
  setPermission?: (permission: any) => void;
  isShowUploadFiles?: boolean;
}

const LinkSettings = (props: Props) => {
  const {
    checkedFiles,
    permission,
    isNewGroup,
    setPermission,
    isShowUploadFiles
  } = props;
  const { onInsertUserActionLogOne } = useUserActionLog();
  const classes = useStyles({ isNewGroup });
  const { t } = useTranslation();
  const [linkSettingsValue, setLinkSettingsValue] = useState<any>(
    isNewGroup ? CONNECT_USER_FILE_STATUS.DOWNLOADABLE : permission
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'popoverId' : undefined;
  const [updateConnectUserFilePermission] = useMutation(
    UpdateConnectUserFilePermission,
    {
      fetchPolicy: 'no-cache'
    }
  );
  const { onSetShowHideFileDetail, onRefetchConnectMyDrive } = useMyDrive();
  const { pathname } = useLocation<any>();
  const pathNameUrl = pathname.split(`${myDrivePathName}/`);

  useEffect(() => {
    if (isNewGroup) return;
    setLinkSettingsValue(permission);
  }, [permission]);

  const handleClosePopUp = () => {
    setAnchorEl(null);
  };

  const refetchConnectMyDrive = () => {
    onRefetchConnectMyDrive({
      pathId: pathNameUrl[1] ? pathNameUrl[1] : ''
    }).then((res: any) => {
      if (res?.data?.fetchConnectMyDrive?.result === CapaErrorCode.Success) {
        onSetShowHideFileDetail({
          detailDrive: res?.data?.fetchConnectMyDrive?.data?.files?.find(
            (file: any) => file?.id === checkedFiles[0]?.id
          )
        });
      }
    });
  };

  const onClickOption = (value: any) => {
    setLinkSettingsValue(value);
    setPermission && setPermission(value);
    if (!isNewGroup) {
      updateConnectUserFilePermission({
        variables: {
          connectFileIds: checkedFiles?.map((file: any) => file.id) || [],
          permission:
            value === CONNECT_USER_FILE_STATUS.VIEWABLE
              ? CONNECT_USER_FILE_STATUS.VIEWABLE
              : CONNECT_USER_FILE_STATUS.DOWNLOADABLE
        }
      }).then((res: any) => {
        if (
          res?.data?.updateConnectUserFilePermission?.result ===
          CapaErrorCode.Success
        ) {
          refetchConnectMyDrive();
        }
      });
    }
    handleClosePopUp();
  };

  const linkSettingsInfo = useMemo(() => {
    return LINK_SETTINGS_OPTIONS.filter(
      (option: any) => option.value === linkSettingsValue
    )[0];
  }, [linkSettingsValue]);

  return (
    <>
      <div
        className={clsx(
          classes.linkSettings,
          isShowUploadFiles
            ? classes.showUploadFilesMarginTop
            : classes.hideUploadFilesMarginTop
        )}
      >
        <img
          src={
            linkSettingsValue === CONNECT_USER_FILE_STATUS.VIEWABLE
              ? IBMSecurity
              : IBMDownload
          }
          alt=""
        />
        <div
          className={clsx('name', open ? 'box-shadow-name' : '')}
          onClick={(event: any) => setAnchorEl(event.currentTarget)}
        >
          {t(linkSettingsInfo?.name)}
          <img src={open ? IBMCaretUp : IBMCaretDown} alt="" />
        </div>
      </div>
      <div className={classes.descriptionLink}>
        {t(linkSettingsInfo?.description)}
      </div>
      <Popover
        className={classes.customPopover}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePopUp}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <div className={classes.csDialogContentPopup}>
          {LINK_SETTINGS_OPTIONS.map((option: any, index: number) => (
            <Typography
              key={index}
              onClick={() => {
                onClickOption(option.value);
                onInsertUserActionLogOne(
                  option.log,
                  CapaActivityType.ButtonClick
                );
              }}
              className={classes.itemPopup}
            >
              <div className="name">
                <div className="text">{t(option.name)}</div>
              </div>
              {linkSettingsValue === option.value ? (
                <CheckOutlined className={classes.iconCheck} />
              ) : null}
            </Typography>
          ))}
        </div>
      </Popover>
    </>
  );
};

export default LinkSettings;
