import React from 'react';
import MobileAsideMenuPresenter from './MobileAsideMenuPresenter';

interface Props {
  open: boolean;
  onClose: () => void;
}

const MobileAsideMenuContainer: React.FC<Props> = ({ open, onClose }) => {
  return <MobileAsideMenuPresenter open={open} onClose={onClose} />;
};

export default MobileAsideMenuContainer;
