import * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

export type ActivateAdvertisinBoInput = {
  /** advertising id */
  advertisingId: Scalars['String'];
};

export type AddConnectFilesInput = {
  connectFileIds: Array<Scalars['String']>;
};

/** 유저 광고 활동 로그 */
export type AdsActivityLogInput = {
  /** ads impression id */
  adsImpressionId: Scalars['String'];
  /** 아이피 */
  ip: Scalars['String'];
  /** url */
  url: Scalars['String'];
  /** 이메일 */
  email?: Maybe<Scalars['String']>;
  /** 캐파 서비스 타입 */
  service?: Maybe<CapaServiceType>;
  /** 활동 타입 */
  activityType: CapaActivityType;
};

/** input for allow partner to download rfq files */
export type AllowRfqDrawingFileDownloadInput = {
  /** rfq id */
  rfqId: Scalars['String'];
  /** partner id */
  quotationPartnerId: Scalars['String'];
  /** status for file download */
  status?: Maybe<CapaQuotationRfqAllowOpenFileStatus>;
};

export type AnnotationComment = {
  __typename?: 'AnnotationComment';
  id?: Maybe<Scalars['String']>;
  isParent?: Maybe<Scalars['Boolean']>;
  comment?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  status?: Maybe<CapaQuotationSharedFileAnnotationStatus>;
  createdAt?: Maybe<Scalars['String']>;
};

export type AnnotationCommentBo = {
  __typename?: 'AnnotationCommentBO';
  id?: Maybe<Scalars['String']>;
  isParent?: Maybe<Scalars['Boolean']>;
  comment?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  edited?: Maybe<Scalars['Boolean']>;
  status?: Maybe<CapaQuotationSharedFileAnnotationStatus>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

/** ITP event reward */
export type ApproveItpEventInput = {
  /** ITP event id */
  itpEventId: Scalars['String'];
};

/** 파트너 이메일로 승인 */
export type ApprovedQuotationPartnerByEmailInput = {
  /** 이메일 */
  email: Scalars['String'];
};

export type Authentication = {
  __typename?: 'Authentication';
  /** JWT */
  accessToken: Scalars['String'];
  tokenType: Scalars['String'];
  expiresIn: Scalars['Float'];
  /** JWT */
  refreshToken: Scalars['String'];
  redirectUrl?: Maybe<Scalars['String']>;
};

/** 캐파 로그인 */
export type AuthenticationInput = {
  /** 이메일 */
  email: Scalars['String'];
  /** 비밀번호 */
  password: Scalars['String'];
  /** 캐파 서비스 타입 */
  service: CapaServiceType;
  /** device token */
  deviceToken?: Maybe<Scalars['String']>;
  /** user language */
  userLanguage?: Maybe<Language>;
};

export type AuthenticationOutput = {
  __typename?: 'AuthenticationOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<Authentication>;
};

export enum BackOfficeAccess {
  AccountManagement = 'ACCOUNT_MANAGEMENT',
  DashboardRead = 'DASHBOARD_READ',
  RfqRead = 'RFQ_READ',
  RfqStop = 'RFQ_STOP',
  RfqComment = 'RFQ_COMMENT',
  PartnerRead = 'PARTNER_READ',
  PartnerModify = 'PARTNER_MODIFY',
  PartnerAdd = 'PARTNER_ADD',
  PartnerRemove = 'PARTNER_REMOVE',
  PartnerApprove = 'PARTNER_APPROVE',
  PartnerComment = 'PARTNER_COMMENT',
  ServiceRatingRead = 'SERVICE_RATING_READ',
  PartnerRatingRead = 'PARTNER_RATING_READ',
  StampEventRead = 'STAMP_EVENT_READ',
  StampEventApprove = 'STAMP_EVENT_APPROVE',
  StampEventDownload = 'STAMP_EVENT_DOWNLOAD',
  StampEventComment = 'STAMP_EVENT_COMMENT',
  ClientRead = 'CLIENT_READ',
  ClientComment = 'CLIENT_COMMENT'
}

export enum BackOfficeAdminRole {
  Admin = 'ADMIN',
  Xaccount = 'XACCOUNT',
  Noedit = 'NOEDIT',
  Vietnam = 'VIETNAM'
}

export enum BackOfficeUserDeptType {
  Dev = 'DEV',
  Pd = 'PD',
  Crm = 'CRM',
  Rnd = 'RND'
}

export enum BackOfficeUserStatus {
  /** 가입 */
  Registered = 'REGISTERED',
  /** 탈퇴 */
  Withdraw = 'WITHDRAW'
}

export type BankBookFile = {
  __typename?: 'BankBookFile';
  fileId: Scalars['String'];
  fileName: Scalars['String'];
  fileExtension: Scalars['String'];
};

export type BatchShutDownQuotationPartnerChatRoomsInput = {
  serviceType: CapaQuotationServiceType;
};

export type Capa3DPos = {
  __typename?: 'CAPA3DPos';
  x: Scalars['Float'];
  y: Scalars['Float'];
  z: Scalars['Float'];
};

export type Capa3DPosInput = {
  x: Scalars['Float'];
  y: Scalars['Float'];
  z: Scalars['Float'];
};

/** 액티비티 타입 */
export enum CapaActivityType {
  PageVisit = 'PAGE_VISIT',
  ButtonClick = 'BUTTON_CLICK',
  LinkClick = 'LINK_CLICK',
  ActionInput = 'ACTION_INPUT'
}

/** 광고 노출 정보 */
export type CapaAdsActivityLog = {
  __typename?: 'CAPAAdsActivityLog';
  /** 광고 노출 정보 id */
  count: Scalars['Float'];
  /** 광고 타입 (상단 배너, 카드 등등) */
  service: CapaServiceType;
  /** 광고 타입 (상단 배너, 카드 등등) */
  activityType: CapaActivityType;
};

export type CapaAdsActivityLogsOutput = {
  __typename?: 'CAPAAdsActivityLogsOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<Array<CapaAdsActivityLog>>;
};

/** 광고 노출 정보 */
export type CapaAdsImpressionBo = {
  __typename?: 'CAPAAdsImpressionBO';
  /** 광고 노출 정보 id */
  id: Scalars['ID'];
  /** 광고 정보 id */
  advertismentId: Scalars['ID'];
  /** 광고 이미지 id */
  fileId: Scalars['ID'];
  /** 광고 이미지 백그라운드 컬러 */
  backgroundColor?: Maybe<Scalars['String']>;
  /** 광고 타입 (상단 배너, 카드 등등) */
  type: CapaAdvertisementType;
  /** 광고 타이틀 */
  title: Scalars['String'];
  /** 광고 내용 */
  contents: Scalars['String'];
  /** 광고 링크 */
  linkUrl: Scalars['String'];
  /** 광고 종료일 */
  expiredAt?: Maybe<Scalars['String']>;
  /** create at */
  createdAt: Scalars['String'];
  /** update at */
  updatedAt: Scalars['String'];
};

export type CapaAdsImpressionsBoOutput = {
  __typename?: 'CAPAAdsImpressionsBOOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<Array<CapaAdsImpressionBo>>;
};

/** 광고 정보 */
export type CapaAdvertisementBo = {
  __typename?: 'CAPAAdvertisementBO';
  /** 광고 정보 id */
  id: Scalars['ID'];
  /** 회사명 */
  organizationName: Scalars['String'];
  /** 담당자명 */
  managerName: Scalars['String'];
  /** 전화번호 */
  contact: Scalars['String'];
  /** 이메일 */
  email: Scalars['String'];
  /** 현재 광고 상태 */
  status: CapaAdvertisementStatus;
  /** create at */
  createdAt: Scalars['String'];
  /** update at */
  updatedAt: Scalars['String'];
  /** 광고 노출 정보 */
  adsImpressions?: Maybe<Array<CapaAdsImpressionBo>>;
};

export enum CapaAdvertisementStatus {
  /** 등록 */
  Registered = 'REGISTERED',
  /** 승인 */
  Approved = 'APPROVED',
  /** 만료 */
  Expired = 'EXPIRED',
  /** 삭제 */
  Deleted = 'DELETED'
}

/** 광고 노출 타입 */
export enum CapaAdvertisementType {
  /** 상단 배너 */
  TopBanner = 'TOP_BANNER',
  /** 채팅방 카드 */
  ChatRoomCard = 'CHAT_ROOM_CARD'
}

export type CapaAdvertisementsBoOutput = {
  __typename?: 'CAPAAdvertisementsBOOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<Array<CapaAdvertisementBo>>;
};

export type CapaAdvertising = {
  __typename?: 'CAPAAdvertising';
  /** advertising id */
  id: Scalars['String'];
  /** ads Impression list */
  adsImpressions: Array<CapaAdvertisingAdsImpression>;
  /** 광고 타입 (배너, 카드) */
  type: CapaAdvertisementType;
  /** 호스팅할 서비스 타입 (배너, 카드) */
  serviceType: CapaServiceType;
};

export type CapaAdvertisingAdsImpression = {
  __typename?: 'CAPAAdvertisingAdsImpression';
  /** ads impression id */
  id: Scalars['String'];
  /** ads impression id */
  advertismentId: Scalars['String'];
  /** file id */
  fildId: Scalars['String'];
  /** backgroundColor */
  backgroundColor: Scalars['String'];
  /** title */
  title: Scalars['String'];
  /** contents */
  contents: Scalars['String'];
  /** linkUrl */
  linkUrl: Scalars['String'];
  /** expiredAt */
  expiredAt?: Maybe<Scalars['String']>;
};

export type CapaAdvertisingBo = {
  __typename?: 'CAPAAdvertisingBO';
  /** advertising id */
  id: Scalars['String'];
  /** ads Impression list */
  adsImpressionIds: Array<Scalars['String']>;
  /** 광고 타입 (배너, 카드) */
  type: CapaAdvertisementType;
  /** 호스팅할 서비스 타입 (배너, 카드) */
  serviceType: CapaServiceType;
};

export type CapaAdvertisingsBoOutput = {
  __typename?: 'CAPAAdvertisingsBOOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<Array<CapaAdvertisingBo>>;
};

export type CapaAdvertisingsOutput = {
  __typename?: 'CAPAAdvertisingsOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<Array<CapaAdvertising>>;
};

/** 엠베서더 목록 */
export type CapaAmbassadorList = {
  __typename?: 'CAPAAmbassadorList';
  /** ambassador id */
  id?: Maybe<Scalars['ID']>;
  /** 협회명 */
  organizationName?: Maybe<Scalars['String']>;
};

export type CapaAvailableReview = {
  __typename?: 'CAPAAvailableReview';
  chatRoomId: Scalars['String'];
  quotationPartnerId: Scalars['String'];
  profileId?: Maybe<Scalars['String']>;
  /** 파트너 회사명 */
  organizationName?: Maybe<Scalars['String']>;
  isComplete: Scalars['Boolean'];
  /** 발주 발행일 */
  purchaseOrderCreatedAt?: Maybe<Scalars['String']>;
};

export enum CapaboBypassSearchUserType {
  Client = 'CLIENT',
  Partner = 'PARTNER'
}

/** 결제 취소 및 환불 정보 */
export type CapaboCancelPaymentList = {
  __typename?: 'CAPABOCancelPaymentList';
  /** 취소 및 환불 사유 */
  cancelReason?: Maybe<Scalars['String']>;
  /** 취소 및 환불 금액 */
  cancelAmount?: Maybe<Scalars['Float']>;
  /** 취소 및 환불 요청일 */
  cancelRequestedAt?: Maybe<Scalars['String']>;
  /** 취소 및 환불 승인일 */
  cancelConfirmedAt?: Maybe<Scalars['String']>;
  /** 취소 및 횐불 승인자 */
  cancelApprover?: Maybe<CapaServiceType>;
  /** 취소승인한 BO ID */
  approverbackOfficeId?: Maybe<Scalars['String']>;
  /** 취소승인한 BO 담당자 이름 */
  approverbackOfficeName?: Maybe<Scalars['String']>;
  /** 가상계좌 미입금 취소 여부 */
  isNotPaidCanceled?: Maybe<Scalars['Boolean']>;
};

/** BO 결제 정보 조회 */
export type CapaboFetchTransactionHistoryInput = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  keyword?: Maybe<Scalars['String']>;
  searchType?: Maybe<PaymentHistorySearchTypeBo>;
  searchDateType?: Maybe<PaymentHistorySearchDateTypeBo>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  /** 테스트 포함 여부 */
  includeTest?: Maybe<Scalars['Boolean']>;
  searchPaymentStatus?: Maybe<PaymentHistorySearchStatusTypeBo>;
  searchRfqServiceType?: Maybe<CapaQuotationNewServiceType>;
};

/** 파트너 가입 히스토리 */
export type CapaboPartnerHistoryModel = {
  __typename?: 'CAPABOPartnerHistoryModel';
  id: Scalars['String'];
  quotationPartnerId: Scalars['String'];
  /** 유저 id */
  resultType: CapaQuotationPartnerStatus;
  rejectReason?: Maybe<Scalars['String']>;
  rejectType?: Maybe<CapaQuotationPartnerRejectType>;
  manager?: Maybe<Scalars['String']>;
  managerBOId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  /** 파트너 인증 히스토리 타입 */
  historyType?: Maybe<CapaBackOfficeQuotationPartnerHistoryType>;
};

export type CapaboProcessingDetailItem = {
  __typename?: 'CAPABOProcessingDetailItem';
  /** 이벤트 생성일 */
  eventCreatedAt: Scalars['String'];
  /** CAPAPurchaseOrderStatus */
  status: CapaPurchaseOrderStatus;
};

export type CapaboProcessingItem = {
  __typename?: 'CAPABOProcessingItem';
  /** purchaseOrderId */
  purchaseOrderId: Scalars['String'];
  /** BO 제조시작 탭 전용 purchaseorder status */
  status?: Maybe<CapaboProcessingPurchaseOrderStatus>;
  /** 결제수단 */
  paymentMethod: CapaPaymentMethodType;
  /** 결제금액 */
  paidAmount: Scalars['Float'];
  purchaseOrderStatus: CapaPurchaseOrderStatus;
  /** 발주서 작성일 */
  purchaseOrderCreatedAt: Scalars['String'];
  /** 결제 취소 및 환불 정보 */
  capaBOCancelPaymentList?: Maybe<Array<CapaboCancelPaymentList>>;
  /** 배송방식 */
  shippingMethod?: Maybe<CapaShippingMethodType>;
  /** 기타 배송 방식 */
  etcShippingMethod?: Maybe<Scalars['String']>;
  /** 배송 정보 입력일 */
  shippingCreatedAt?: Maybe<Scalars['String']>;
  /** 자동 지급 승인일 */
  confirmPurchaseExpectedDate?: Maybe<Scalars['String']>;
  /** 자동 지급 승인 연장일 */
  delayedConfirmPurchaseExpectedDate?: Maybe<Scalars['String']>;
  /** 정산일 (실제 파트너에게 정산 = 지급일) */
  payoutedAt?: Maybe<Scalars['String']>;
  /** 정산금액 (실제 파트너에게 정산 = 지급 대행 요청 금액 */
  payoutedAmount?: Maybe<Scalars['Float']>;
  /** 결제 실패시 결제 시도 금액 */
  paymentFailedAmount?: Maybe<Scalars['Float']>;
  /** 결제 성공 시간 */
  paidAt?: Maybe<Scalars['String']>;
  /** 발주/결제 상태 변경 시점 정보 , 승인자 정보 */
  processingDetailItems: Array<CapaboProcessingDetailItem>;
};

export type CapaboProcessingOutput = {
  __typename?: 'CAPABOProcessingOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaboProcessingProjects>;
};

/** BO 제조 시작 목록 */
export type CapaboProcessingProject = {
  __typename?: 'CAPABOProcessingProject';
  /** rfqId */
  rfqId: Scalars['String'];
  /** quotationId */
  quotationId: Scalars['String'];
  /** chatRoomId */
  chatRoomId: Scalars['String'];
  /** partnerId */
  partnerId: Scalars['String'];
  /** 주문 요청일 */
  rfqCreatedAt: Scalars['String'];
  /** 견적서들의 작성일 */
  quotationCreatedDates: Array<Scalars['String']>;
  /** 프로젝트명 */
  projectName: Scalars['String'];
  /** capa service type */
  serviceType?: Maybe<CapaQuotationServiceType>;
  /** 고객명 */
  clientName: Scalars['String'];
  /** 고객 전화 */
  clientContact: Scalars['String'];
  /** 파트너명 */
  partnerName: Scalars['String'];
  /** 파트너 전화 */
  partnerContact: Scalars['String'];
  processingList?: Maybe<Array<CapaboProcessingItem>>;
  /** transactionId */
  paymentTransactionId: Scalars['String'];
  /** 테스트 RFQ */
  isTest?: Maybe<Scalars['Boolean']>;
};

/** BO 제조 시작 목록 및 프로젝트 수 */
export type CapaboProcessingProjects = {
  __typename?: 'CAPABOProcessingProjects';
  data?: Maybe<Array<CapaboProcessingProject>>;
  totalCount?: Maybe<Scalars['Float']>;
  cancelRequestedCount?: Maybe<Scalars['Float']>;
};

/** bo 제조목록탭 전용 purchaseorder status */
export enum CapaboProcessingPurchaseOrderStatus {
  /** 발주/결제중 */
  ReadyOrPaid = 'READY_OR_PAID',
  /** 결제취소요청 */
  CancelRequested = 'CANCEL_REQUESTED',
  /** 결제취소완료 */
  CancelConfirmed = 'CANCEL_CONFIRMED',
  /** 제조중 */
  Processing = 'PROCESSING',
  /** 환불요청 */
  RefundRequested = 'REFUND_REQUESTED',
  /** 환불완료 */
  RefundConfirmed = 'REFUND_CONFIRMED',
  /** 배송중 */
  Shipping = 'SHIPPING',
  /** 배송중(연장) */
  ShippingExtended = 'SHIPPING_EXTENDED',
  /** 제조완료 */
  PurchaseConfirmed = 'PURCHASE_CONFIRMED',
  /** 결제실패 */
  Failed = 'FAILED'
}

export type CapaBackOffice = {
  __typename?: 'CAPABackOffice';
  /** capa backoffice user id */
  id: Scalars['ID'];
  email: Scalars['String'];
  role: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  nickName?: Maybe<Scalars['String']>;
  dept: Scalars['String'];
  task?: Maybe<Scalars['String']>;
  accessList?: Maybe<Array<BackOfficeAccess>>;
  status?: Maybe<BackOfficeUserStatus>;
};

/** BackOffice 커넥트 그룹 필터타입 */
export enum CapaBackOfficeConnectGroupsFilterType {
  /** 전체 */
  Default = 'DEFAULT',
  /** 실사용 그룹 */
  Activate = 'ACTIVATE',
  /** 테스트 그룹 */
  Test = 'TEST'
}

/** BO 제조 시작 목록 요청 타입 */
export enum CapaBackOfficeFetchProcessingReqType {
  /** 전체 목록 출력 */
  Default = 'DEFAULT',
  /** 필터 탭 (취소 및 환불 요청 관리, ...) */
  Filter = 'FILTER'
}

/** 파트너 목록 필터 */
export enum CapaBackOfficeQuotationPartnerFilterType {
  /** 가입 승인 대기 */
  JoinRegister = 'JOIN_REGISTER',
  /** 가입 승인 거절 */
  JoinRejected = 'JOIN_REJECTED',
  /** 가입 승인 완료 */
  JoinApproved = 'JOIN_APPROVED',
  /** 계좌 승인 대기 */
  AccountRegister = 'ACCOUNT_REGISTER',
  /** 계좌 승인 거절 */
  AccountRejected = 'ACCOUNT_REJECTED',
  /** 가입/계좌 승인 완료 */
  JoinAndAccountApproved = 'JOIN_AND_ACCOUNT_APPROVED',
  /** 탈퇴 */
  Deleted = 'DELETED'
}

export enum CapaBackOfficeQuotationPartnerHistoryType {
  /** 가입 */
  Join = 'JOIN',
  /** 계좌 */
  Submall = 'SUBMALL',
  /** BO계좌등록 */
  BoSubmall = 'BO_SUBMALL'
}

export enum CapaBackOfficeQuotationUserHistoryType {
  /** 환불계좌 등록 */
  RefundBankAccount = 'REFUND_BANK_ACCOUNT'
}

export type CapaBackOfficeRole = {
  __typename?: 'CAPABackOfficeRole';
  role: Scalars['String'];
  accessList?: Maybe<Array<BackOfficeAccess>>;
};

/** 관리자 권한 발주서 상태 변경 요청 타입 */
export enum CapaBackOfficeUpdatePurchaseOrderStatusReqType {
  /** 취소 및 환불 승인 */
  Confirm = 'CONFIRM',
  /** 계속 진행 통보 */
  Progress = 'PROGRESS'
}

export type CapaBackOfficeUserOutput = {
  __typename?: 'CAPABackOfficeUserOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaBackOffice>;
};

/** BackOffice 유저 필터타입 */
export enum CapaBackOfficeUsersFilterType {
  /** 전체 */
  Default = 'DEFAULT',
  /** 실사용자 계정 */
  Activate = 'ACTIVATE',
  /** 테스트 계정 */
  Test = 'TEST'
}

export type CapaBackOfficeUsersOutput = {
  __typename?: 'CAPABackOfficeUsersOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<Array<CapaBackOffice>>;
};

/** 은행 코드 */
export enum CapaBankCode {
  /** KB국민은행 */
  KbBank = 'KB_BANK',
  /** SC제일은행 */
  ScBank = 'SC_BANK',
  /** 경남은행 */
  KyongnamBank = 'KYONGNAM_BANK',
  /** 광주은행 */
  KwangjuBank = 'KWANGJU_BANK',
  /** 기업은행 */
  Ibk = 'IBK',
  /** 농협 */
  NhBank = 'NH_BANK',
  /** 대구은행 */
  DaeguBank = 'DAEGU_BANK',
  /** 부산은행 */
  BusanBank = 'BUSAN_BANK',
  /** 산업은행 */
  Kdb = 'KDB',
  /** 새마을금고 */
  Kfcc = 'KFCC',
  /** 수협 */
  ShBank = 'SH_BANK',
  /** 신한은행 */
  ShinhanBank = 'SHINHAN_BANK',
  /** 신협 */
  CreditUnionBank = 'CREDIT_UNION_BANK',
  /** 외환은행 */
  Keb = 'KEB',
  /** 우리은행 */
  WooriBank = 'WOORI_BANK',
  /** 우체국 */
  PostBank = 'POST_BANK',
  /** 전북 */
  JeonbukBank = 'JEONBUK_BANK',
  /** 카카오뱅크 */
  KakaoBank = 'KAKAO_BANK',
  /** 케이뱅크 */
  KBank = 'K_BANK',
  /** 토스뱅크 */
  TossBank = 'TOSS_BANK',
  /** 하나은행_서울은행 */
  HanaSeoulBank = 'HANA_SEOUL_BANK',
  /** 한국씨티은행_한미은행 */
  CityHanmiBank = 'CITY_HANMI_BANK'
}

/** 엠베서더 */
export type CapaBoAmbassador = {
  __typename?: 'CAPABoAmbassador';
  /** friends id */
  id: Scalars['ID'];
  /** 유형 */
  friendsType: Scalars['Float'];
  /** 엠베서더 회사명 */
  organizationName?: Maybe<Scalars['String']>;
  /** 담당자명 */
  managerName: Scalars['String'];
  /** 담당자 이메일 */
  email: Scalars['String'];
  /** 담당자 휴대폰 번호 */
  cellphoneNumber: Scalars['String'];
  /** 사업자 등록 번호 */
  companyRegistrationNumber?: Maybe<Scalars['String']>;
  /** 엠베서더 등록일 */
  createdAt: Scalars['String'];
  /** status (1 승인대기 / 2 승인 / 4 거절 / 6 탈퇴) */
  statusTypeId: Scalars['Float'];
  /** 전체 초대 수 (직접 초대한 캐파 회원 수 + 자식 프렌즈가 초대한 캐파 회원 수) */
  totalInviteCnt: Scalars['Float'];
  /** 직접 초대 캐파 회원 수 */
  directInviteCnt: Scalars['Float'];
  /** 간접 초대 캐파 회원 수 */
  indirectInviteCnt: Scalars['Float'];
  /** 총 리워드 (누적 정산 + 미정산 총합) */
  totalReward: Scalars['Float'];
  /** 정산 리워드 */
  settledReward: Scalars['Float'];
  /** 미정산 리워드 */
  unsettledReward: Scalars['Float'];
  /** 정산 요청 여부 */
  isRequestSettlement?: Maybe<Scalars['Boolean']>;
  /** 탈퇴 신청 여부 */
  isRequestWithdraw: Scalars['Boolean'];
  /** 최근 초대일 */
  lastInvitedAt?: Maybe<Scalars['String']>;
  /** bank status type id */
  bankStatusTypeId: Scalars['Float'];
};

/** 프렌즈 상세 */
export type CapaBoAmbassadorDetail = {
  __typename?: 'CAPABoAmbassadorDetail';
  /** friends id */
  id?: Maybe<Scalars['ID']>;
  /** email */
  email?: Maybe<Scalars['String']>;
  /** 담당자명 */
  managerName?: Maybe<Scalars['String']>;
  /** 유형 */
  friendsType?: Maybe<Scalars['Float']>;
  /** 회사명(소속) */
  organizationName?: Maybe<Scalars['String']>;
  /** 산업군 */
  industry?: Maybe<Scalars['String']>;
  /** 담당자 휴대폰 번호 */
  cellphoneNumber?: Maybe<Scalars['String']>;
  /** 사업자 등록 번호 */
  companyRegistrationNumber?: Maybe<Scalars['String']>;
  /** 사업자등록증 file id */
  registrationFileId?: Maybe<Scalars['String']>;
  /** 사업자등록증 file name */
  registrationFileName?: Maybe<Scalars['String']>;
  /** 통장사본 file id */
  bankbookFileId?: Maybe<Scalars['String']>;
  /** 통장사본 file name */
  bankbookFileName?: Maybe<Scalars['String']>;
  /** 은행코드 */
  bankcode?: Maybe<Scalars['String']>;
  /** 계좌번호 */
  accountNumber?: Maybe<Scalars['String']>;
  /** 예금주 */
  accountHolder?: Maybe<Scalars['String']>;
  /** 엠베서더 등록일 */
  createdAt?: Maybe<Scalars['String']>;
  /** 초대 캐파 회원 수 */
  inviteUserCnt?: Maybe<Scalars['Float']>;
  /** 초대 프렌즈 수 */
  inviteFriendsCnt?: Maybe<Scalars['Float']>;
  /** 자식 프렌즈가 초대한 캐파 회원 */
  babyFriendsInviteUserCnt?: Maybe<Scalars['Float']>;
  /** 총 리워드 */
  totalReward?: Maybe<Scalars['Float']>;
  /** 정산 리워드 */
  settledReward?: Maybe<Scalars['Float']>;
  /** 미정산 리워드 */
  unsettledReward?: Maybe<Scalars['Float']>;
  /** 정산 요청 여부 */
  isRequestSettlement: Scalars['Boolean'];
  /** 탈퇴 신청 여부 */
  isRequestWithdraw: Scalars['Boolean'];
  /** 고객 회원가입 페이지 조회수 */
  clientViewCount?: Maybe<Scalars['Float']>;
  /** 파트너 회원가입 페이지 조회수 */
  partnerViewCount?: Maybe<Scalars['Float']>;
  /** 정산 계좌 status */
  bankStatusTypeId: Scalars['Float'];
  /** 프렌즈 status type id */
  statusTypeId: Scalars['Float'];
};

export type CapaBoAmbassadorDetailOutput = {
  __typename?: 'CAPABoAmbassadorDetailOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaBoAmbassadorDetail>;
};

export type CapaBoAmbassadorInvitationStatus = {
  __typename?: 'CAPABoAmbassadorInvitationStatus';
  /** 초대 수 */
  totalInviteCnt?: Maybe<Scalars['Float']>;
  /** 정산 리워드 */
  settledReward?: Maybe<Scalars['Float']>;
  /** 미정산 리워드 */
  unsettledReward?: Maybe<Scalars['Float']>;
  /** 고객 초대 목록 */
  invitationUserList?: Maybe<Array<InviteUserList>>;
  /** 프렌즈 초대 목록 */
  invitationFriendsList?: Maybe<Array<InviteFriendsList>>;
};

export type CapaBoAmbassadorInvitationStatusOutput = {
  __typename?: 'CAPABoAmbassadorInvitationStatusOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaBoAmbassadorInvitationStatus>;
};

export type CapaBoAmbassadors = {
  __typename?: 'CAPABoAmbassadors';
  totalCnt?: Maybe<Scalars['Float']>;
  friends?: Maybe<Array<CapaBoAmbassador>>;
};

export type CapaBoAmbassadorsOutput = {
  __typename?: 'CAPABoAmbassadorsOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaBoAmbassadors>;
};

/** 코멘트 */
export type CapaBoComment = {
  __typename?: 'CAPABoComment';
  /** bo user name */
  name?: Maybe<Scalars['String']>;
  /** bo user nickName */
  nickName?: Maybe<Scalars['String']>;
  /** comment */
  comment?: Maybe<Scalars['String']>;
  /** 코멘트 생성일 */
  createdAt?: Maybe<Scalars['String']>;
};

export type CapaBoCommentQuotationUsers = {
  __typename?: 'CAPABoCommentQuotationUsers';
  /** quotation user id */
  quotationUserId?: Maybe<Scalars['ID']>;
  /** 유저별 comments */
  comments?: Maybe<Array<CapaBoComment>>;
};

export type CapaBoCommentsOutput = {
  __typename?: 'CAPABoCommentsOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaBoCommentQuotationUsers>;
};

export type CapaBoFriendsInviteUserActivity = {
  __typename?: 'CAPABoFriendsInviteUserActivity';
  /** 고객 활동 */
  user: UserActivity;
  /** 파트너 활동 */
  partner: PartnerActivity;
};

export type CapaBoFriendsInviteUserActivityOutput = {
  __typename?: 'CAPABoFriendsInviteUserActivityOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaBoFriendsInviteUserActivity>;
};

/** 캐파 BO 메모 정보 */
export type CapaBoMemo = {
  __typename?: 'CAPABoMemo';
  id: Scalars['ID'];
  back_office_id: Scalars['ID'];
  /** 유저 id */
  user_id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** nick_name */
  nick_name: Scalars['String'];
  /** memo */
  memo: Scalars['String'];
  /** role */
  role: Scalars['String'];
  /** 메모 생성일 */
  created_at: Scalars['String'];
};

export type CapaBoMemosOutput = {
  __typename?: 'CAPABoMemosOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<Array<CapaBoMemo>>;
};

export type CapaBypassComplaintInput = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  /** 유저 타입 */
  complaintUserType: Array<CapaboBypassSearchUserType>;
  /** 불편사항 타입 */
  complaintType: Array<CapaBypassComplaintType>;
};

export enum CapaBypassComplaintType {
  /** 통화필요 */
  RequiredCallNumber = 'REQUIRED_CALL_NUMBER',
  /** 캐파 채팅 불편사항 */
  ChatComplaint = 'CHAT_COMPLAINT',
  /** 연락처 교환 x */
  NotExchangeCallNumber = 'NOT_EXCHANGE_CALL_NUMBER',
  /** 어떤 설정도 하지 않음. */
  NoAction = 'NO_ACTION'
}

export type CapacxManagementRequiredRfq = {
  __typename?: 'CAPACXManagementRequiredRfq';
  /** rfq id */
  rfqId?: Maybe<Scalars['String']>;
  /** 견적요청일 */
  createdAt?: Maybe<Scalars['String']>;
  /** 서비스타입 */
  serviceType?: Maybe<CapaQuotationServiceType>;
  /** 프로젝트명 */
  projectName?: Maybe<Scalars['String']>;
  /** 총 수량 */
  totalQuantity?: Maybe<Scalars['Float']>;
  /** RFQ 상태 */
  status?: Maybe<CapaRfqStatus>;
  /** 조회수 */
  viewCount?: Maybe<Scalars['Float']>;
  /** 조회파트너수 */
  viewPartnerCount?: Maybe<Scalars['Float']>;
};

export type CapacxManagementRequiredUserBo = {
  __typename?: 'CAPACXManagementRequiredUserBO';
  quotationUserId: Scalars['String'];
  /** 고객명 */
  managerName?: Maybe<Scalars['String']>;
  /** 회사명 */
  organizationName?: Maybe<Scalars['String']>;
  /** 고객유형 */
  bizType?: Maybe<CapaQuotationUserBizType>;
  /** 이메일 */
  email?: Maybe<Scalars['String']>;
  /** 휴대전화 */
  cellphoneNumber?: Maybe<Scalars['String']>;
  /** CX 관리 대상 rfq 목록 */
  requiredManagementRfqs?: Maybe<Array<CapacxManagementRequiredRfq>>;
  /** 코멘트 수 */
  commentCount?: Maybe<Scalars['Float']>;
};

export type CapacxManagementRequiredUsersBo = {
  __typename?: 'CAPACXManagementRequiredUsersBO';
  requiredManagementUsers?: Maybe<Array<CapacxManagementRequiredUserBo>>;
  count?: Maybe<Scalars['Float']>;
  unReadCount?: Maybe<Scalars['Float']>;
};

export type CapacxManagementRequiredUsersBoOutput = {
  __typename?: 'CAPACXManagementRequiredUsersBOOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapacxManagementRequiredUsersBo>;
};

export type CapaCanEditRefundAccountOutput = {
  __typename?: 'CAPACanEditRefundAccountOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CanEditRefundAccountModel>;
};

/** 캐파 견적 서비스 타입 */
export enum CapaCapabilitiesStatusType {
  /** 활성 */
  Active = 'ACTIVE',
  /** 비활성 */
  Inactive = 'INACTIVE',
  /** 보류중 */
  Pending = 'PENDING'
}

/** 챗 첨부 파일 */
export type CapaChatFile = {
  __typename?: 'CAPAChatFile';
  fileId?: Maybe<Scalars['ID']>;
  /** 파일 이름 */
  fileName?: Maybe<Scalars['String']>;
};

export type CapaChatMessageRead = {
  __typename?: 'CAPAChatMessageRead';
  lastChatMessageId?: Maybe<Scalars['ID']>;
};

export type CapaChatMessagesReadOutput = {
  __typename?: 'CAPAChatMessagesReadOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaChatMessageRead>;
};

export type CapaChatRoom = {
  __typename?: 'CAPAChatRoom';
  id: Scalars['ID'];
};

export type CapaChatRoomUser = {
  __typename?: 'CAPAChatRoomUser';
  id: Scalars['ID'];
  /** 유저 타입 */
  userType: CapaChatRoomUserType;
};

export enum CapaChatRoomUserType {
  /** 주인 */
  Owner = 'OWNER',
  /** 멤버 */
  Member = 'MEMBER',
  /** 손님 */
  Guest = 'GUEST'
}

/** 캐파 클러우드 유저 정보 */
export type CapaCloudUser = {
  __typename?: 'CAPACloudUser';
  /** capa cloud user id */
  id: Scalars['ID'];
  /** capa user email */
  email: Scalars['String'];
  /** 사용중인 드라이브 용량 (단위: byte) */
  usedSize: Scalars['Float'];
  /** 사용가능한 드라이브 용량 (단위: byte) */
  availSize: Scalars['Float'];
  /** 전체 드라이브 용량 (단위: byte) */
  driveSize: Scalars['Float'];
  /** [필수] 캐파 서비스 이용약관 동의 */
  serviceAgreed?: Maybe<Scalars['Boolean']>;
  /** [필수] 개인정보 수집 및 활용 동의 */
  privacyAgreed?: Maybe<Scalars['Boolean']>;
  /** [선택] 프로모션 정보 수신 동의 */
  promotionAgreed?: Maybe<Scalars['Boolean']>;
};

/** 캐파 BO 클러우드 유저 정보 */
export type CapaCloudUserBo = {
  __typename?: 'CAPACloudUserBO';
  /** capa user email */
  email: Scalars['String'];
  /** 사용중인 드라이브 용량 (단위: byte) */
  usedSize: Scalars['Float'];
  /** 전체 드라이브 용량 (단위: byte) */
  driveSize: Scalars['Float'];
  /** 클라우드 유저 생성일 */
  createdAt: Scalars['String'];
};

export type CapaCloudUserOutput = {
  __typename?: 'CAPACloudUserOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaCloudUser>;
};

export type CapaCloudUsersBoOutput = {
  __typename?: 'CAPACloudUsersBOOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<Array<CapaCloudUserBo>>;
};

export type CapaComplaintQuotationChatMessageModel = {
  __typename?: 'CAPAComplaintQuotationChatMessageModel';
  receiveComplaintQuotationChatMessageModels: Array<CapaReceiveComplaintQuotationChatMessageModel>;
  totalCnt?: Maybe<Scalars['Float']>;
};

export type CapaComplaintRfqInfoModel = {
  __typename?: 'CAPAComplaintRfqInfoModel';
  rfqId: Scalars['String'];
  projectName?: Maybe<Scalars['String']>;
};

export type CapaComplaintUserInfoModel = {
  __typename?: 'CAPAComplaintUserInfoModel';
  userId: Scalars['String'];
  /** 유저 타입 */
  complaintUserType?: Maybe<CapaboBypassSearchUserType>;
  userName?: Maybe<Scalars['String']>;
};

export type CapaConnectAnnotation = {
  __typename?: 'CAPAConnectAnnotation';
  id?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['String']>;
  chatRoomId?: Maybe<Scalars['String']>;
  groupUserId?: Maybe<Scalars['String']>;
  connectFileId?: Maybe<Scalars['String']>;
  connectFileName?: Maybe<Scalars['String']>;
  drawingFile?: Maybe<Scalars['String']>;
  anchorPos?: Maybe<Capa3DPos>;
  handlePos?: Maybe<Capa3DPos>;
  nodeId?: Maybe<Scalars['Float']>;
  comments?: Maybe<Array<CapaConnectAnnotationComment>>;
  hasNew?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type CapaConnectAnnotationBo = {
  __typename?: 'CAPAConnectAnnotationBO';
  id?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['String']>;
  chatRoomId?: Maybe<Scalars['String']>;
  groupUserId?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  connectFileId?: Maybe<Scalars['String']>;
  connectFileName?: Maybe<Scalars['String']>;
  drawingFile?: Maybe<Scalars['String']>;
  anchorPos?: Maybe<Capa3DPos>;
  handlePos?: Maybe<Capa3DPos>;
  nodeId?: Maybe<Scalars['Float']>;
  comments?: Maybe<Array<CapaConnectAnnotationCommentBo>>;
  hasNew?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type CapaConnectAnnotationComment = {
  __typename?: 'CAPAConnectAnnotationComment';
  id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  edited?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type CapaConnectAnnotationCommentBo = {
  __typename?: 'CAPAConnectAnnotationCommentBO';
  id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  edited?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type CapaConnectAnnotationOutput = {
  __typename?: 'CAPAConnectAnnotationOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaConnectAnnotation>;
};

export type CapaConnectAnnotationsOutput = {
  __typename?: 'CAPAConnectAnnotationsOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<Array<CapaConnectAnnotation>>;
};

export type CapaConnectChatFile = {
  __typename?: 'CAPAConnectChatFile';
  fileId?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  /** 파일크기 byte */
  fileSize?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['ID']>;
  linkStatus?: Maybe<ConnectUserFileLinkStatus>;
  filePermission?: Maybe<ConnectUserFileStatus>;
  thumbnailUri?: Maybe<Scalars['String']>;
  /** original ext */
  originalFileExt?: Maybe<Scalars['String']>;
  permission?: Maybe<CapaQuotationRfqAllowOpenFileStatus>;
};

export type CapaConnectChatMessage = {
  __typename?: 'CAPAConnectChatMessage';
  id?: Maybe<Scalars['ID']>;
  chatRoomId?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['String']>;
  senderId?: Maybe<Scalars['String']>;
  senderName?: Maybe<Scalars['String']>;
  fileOwnerName?: Maybe<Scalars['String']>;
  fileOwnerEmail?: Maybe<Scalars['String']>;
  senderEmail?: Maybe<Scalars['String']>;
  message?: Maybe<CapaConnectChatMessageUnion>;
  /** 안읽은수 */
  unReadCount?: Maybe<Scalars['Float']>;
  chatFiles?: Maybe<Array<CapaConnectChatFile>>;
  /** 생성일 */
  createdAt?: Maybe<Scalars['String']>;
};

export type CapaConnectChatMessageAnnotation = {
  __typename?: 'CAPAConnectChatMessageAnnotation';
  messageType: CapaConnectChatMessageType;
  annotationId?: Maybe<Scalars['String']>;
  commentId?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
};

export type CapaConnectChatMessageFileShared = {
  __typename?: 'CAPAConnectChatMessageFileShared';
  messageType: CapaConnectChatMessageType;
  userName?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
};

export type CapaConnectChatMessageInvited = {
  __typename?: 'CAPAConnectChatMessageInvited';
  messageType: CapaConnectChatMessageType;
  userName?: Maybe<Scalars['String']>;
};

export type CapaConnectChatMessageLeave = {
  __typename?: 'CAPAConnectChatMessageLeave';
  messageType: CapaConnectChatMessageType;
  userName?: Maybe<Scalars['String']>;
};

export type CapaConnectChatMessageMessage = {
  __typename?: 'CAPAConnectChatMessageMessage';
  messageType: CapaConnectChatMessageType;
  message?: Maybe<Scalars['String']>;
  fileCount?: Maybe<Scalars['Float']>;
};

export type CapaConnectChatMessageRemoved = {
  __typename?: 'CAPAConnectChatMessageRemoved';
  messageType: CapaConnectChatMessageType;
  userName?: Maybe<Scalars['String']>;
};

export enum CapaConnectChatMessageType {
  System = 'SYSTEM',
  Message = 'MESSAGE',
  Annotation = 'ANNOTATION',
  Invite = 'INVITE',
  Leave = 'LEAVE',
  RemoveFromGroup = 'REMOVE_FROM_GROUP',
  FileShared = 'FILE_SHARED',
  Tutorial = 'TUTORIAL'
}

export type CapaConnectChatMessageUnion =
  | CapaConnectChatMessageMessage
  | CapaConnectChatMessageInvited
  | CapaConnectChatMessageRemoved
  | CapaConnectChatMessageFileShared
  | CapaConnectChatMessageLeave
  | CapaConnectChatMessageAnnotation
  | CapaConnectChatTutorialMessage;

export type CapaConnectChatMessagesOutput = {
  __typename?: 'CAPAConnectChatMessagesOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<Array<CapaConnectChatMessage>>;
};

export type CapaConnectChatRoom = {
  __typename?: 'CAPAConnectChatRoom';
  chatRoomId?: Maybe<Scalars['String']>;
  chatRoomStatus?: Maybe<CapaConnectChatRoomStatus>;
  chatMessages?: Maybe<Array<CapaConnectChatMessage>>;
  /** 생성일 */
  createdAt?: Maybe<Scalars['String']>;
  /** 수정일 */
  updatedAt?: Maybe<Scalars['String']>;
};

/** 챗룸 상태 */
export enum CapaConnectChatRoomStatus {
  Opened = 'OPENED',
  Closed = 'CLOSED'
}

export type CapaConnectChatTutorialMessage = {
  __typename?: 'CAPAConnectChatTutorialMessage';
  messageType: CapaConnectChatMessageType;
  message?: Maybe<Scalars['String']>;
  uiType?: Maybe<Scalars['String']>;
  fileCount?: Maybe<Scalars['Float']>;
};

/** 캐파 커넥트 유저 정보 */
export type CapaConnectContactUser = {
  __typename?: 'CAPAConnectContactUser';
  /** capa cloud user id */
  id: Scalars['ID'];
  /** capa user email */
  email: Scalars['String'];
  /** nick name */
  nickName?: Maybe<Scalars['String']>;
  /** memo */
  memo?: Maybe<Scalars['String']>;
  /** isCapaUser */
  isCapaUser?: Maybe<Scalars['Boolean']>;
};

export type CapaConnectContactUsersOutput = {
  __typename?: 'CAPAConnectContactUsersOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<Array<CapaConnectContactUser>>;
};

export type CapaConnectFeedback = {
  __typename?: 'CAPAConnectFeedback';
  /** 피드백 ID */
  id?: Maybe<Scalars['ID']>;
  connectUserId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  /** 피드백 관련 파일 */
  files?: Maybe<Array<CapaFile>>;
  rating?: Maybe<Scalars['Float']>;
  feedback?: Maybe<Scalars['String']>;
  /** 생성일 */
  createdAt?: Maybe<Scalars['String']>;
  /** 수정일 */
  updatedAt?: Maybe<Scalars['String']>;
  /** 테스트 유무 */
  isTest: Scalars['Boolean'];
};

export type CapaConnectFeedbacksBoOutput = {
  __typename?: 'CAPAConnectFeedbacksBOOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<Array<CapaConnectFeedback>>;
};

export type CapaConnectFileDetail = {
  __typename?: 'CAPAConnectFileDetail';
  /** 커넥트파일아이디 */
  id: Scalars['String'];
  /** 파일네임 */
  fileName: Scalars['String'];
  /** 파일패스 */
  filePath: Scalars['String'];
  /** thumbnail */
  thumbnail?: Maybe<Scalars['String']>;
  /** isFile */
  isFile?: Maybe<Scalars['Boolean']>;
  linkStatus?: Maybe<ConnectUserFileLinkStatus>;
  filePermission?: Maybe<ConnectUserFileStatus>;
  /** original ext */
  originalFileExt?: Maybe<Scalars['String']>;
  groups?: Maybe<Array<CapaConnectGroup>>;
};

export type CapaConnectFileDetailOutput = {
  __typename?: 'CAPAConnectFileDetailOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaConnectFileDetail>;
};

export type CapaConnectFilePermission = {
  __typename?: 'CAPAConnectFilePermission';
  fileId: Scalars['String'];
  fileName: Scalars['String'];
  email: Scalars['String'];
  connectUserId?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  isCapaUser?: Maybe<Scalars['Boolean']>;
  accessLevel?: Maybe<Scalars['String']>;
  groupUserId?: Maybe<Scalars['String']>;
  permission?: Maybe<CapaQuotationRfqAllowOpenFileStatus>;
};

export type CapaConnectFilePermissionOutput = {
  __typename?: 'CAPAConnectFilePermissionOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<Array<CapaConnectFilePermission>>;
};

/** 내드리이브 */
export type CapaConnectFolder = {
  __typename?: 'CAPAConnectFolder';
  /** 커넥트 파일 ID */
  id?: Maybe<Scalars['ID']>;
  /** 파일명 */
  folderName: Scalars['String'];
};

export type CapaConnectGroup = {
  __typename?: 'CAPAConnectGroup';
  /** 그룹 ID */
  id?: Maybe<Scalars['ID']>;
  /** 파일명 */
  groupName?: Maybe<Scalars['String']>;
  /** 파일명 */
  chatRoomId?: Maybe<Scalars['String']>;
  /** 커넥트유저네임 */
  accessLevel: CapaConnectGroupAccessLevel;
  /** 그룹멤버 */
  users?: Maybe<Array<CapaConnectGroupUser>>;
  /** 그룹공유파일들 */
  files?: Maybe<Array<CapaConnectSharedFile>>;
  message?: Maybe<CapaQuotationChatMessageUnion>;
  unReadCount?: Maybe<Scalars['Float']>;
  /** 생성일 (업로드 일시) */
  createdAt?: Maybe<Scalars['String']>;
  /** 수정일 */
  updatedAt?: Maybe<Scalars['String']>;
};

export enum CapaConnectGroupAccessLevel {
  Owner = 'OWNER',
  Manager = 'MANAGER',
  Member = 'MEMBER'
}

export type CapaConnectGroupBo = {
  __typename?: 'CAPAConnectGroupBO';
  /** 그룹 ID */
  id?: Maybe<Scalars['ID']>;
  /** 파일명 */
  groupName?: Maybe<Scalars['String']>;
  chatRoomId?: Maybe<Scalars['String']>;
  /** 그룹멤버 */
  users?: Maybe<Array<CapaConnectGroupUserBo>>;
  /** 가입 구성원 수 */
  registeredUsers?: Maybe<Scalars['Float']>;
  /** 그룹공유파일들 */
  files?: Maybe<Array<CapaConnectGroupFileBo>>;
  /** 채팅 개수 */
  totalChats?: Maybe<Scalars['Float']>;
  /** 마지막 채팅시간 */
  lastMessageAt?: Maybe<Scalars['String']>;
  /** 그룹 상태 */
  status?: Maybe<CapaConnectGroupStatus>;
  /** 그룹 주석 */
  annotations?: Maybe<Array<CapaConnectAnnotationBo>>;
  /** 생성일 (업로드 일시) */
  createdAt?: Maybe<Scalars['String']>;
  /** 수정일 */
  updatedAt?: Maybe<Scalars['String']>;
  /** 테스트 그룹 유무 */
  isTest: Scalars['Boolean'];
};

export type CapaConnectGroupBoOutput = {
  __typename?: 'CAPAConnectGroupBOOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaConnectGroupBo>;
};

export type CapaConnectGroupChatRoom = {
  __typename?: 'CAPAConnectGroupChatRoom';
  /** 그룹 ID */
  groupId?: Maybe<Scalars['ID']>;
  /** 그룹 이름 */
  groupName: Scalars['String'];
  /** 챗룸 아이디 */
  chatRoomId: Scalars['String'];
  /** 썸네일 */
  thumbnail?: Maybe<Scalars['String']>;
  originalFileExt?: Maybe<Scalars['String']>;
  lastMessage?: Maybe<CapaConnectChatMessageUnion>;
  senderEmail?: Maybe<Scalars['String']>;
  senderName?: Maybe<Scalars['String']>;
  totalMembers?: Maybe<Scalars['Float']>;
  unReadCount?: Maybe<Scalars['Float']>;
  accessLevel?: Maybe<CapaConnectGroupAccessLevel>;
  /** 그룹멤버 */
  users?: Maybe<Array<CapaConnectGroupUser>>;
  /** 생성일 (업로드 일시) */
  createdAt?: Maybe<Scalars['String']>;
  /** 수정일 */
  updatedAt?: Maybe<Scalars['String']>;
};

export type CapaConnectGroupChatRoomOutput = {
  __typename?: 'CAPAConnectGroupChatRoomOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaConnectGroupChatRoom>;
};

export type CapaConnectGroupChatRoomsOutput = {
  __typename?: 'CAPAConnectGroupChatRoomsOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<Array<CapaConnectGroupChatRoom>>;
};

export type CapaConnectGroupFileBo = {
  __typename?: 'CAPAConnectGroupFileBO';
  /** 커넥트파일아이디 */
  id: Scalars['String'];
  /** 주인 user ID */
  userId?: Maybe<Scalars['ID']>;
  /** 파일 주인 이메일 */
  email: Scalars['String'];
  /** 파일 주인 유저네임 */
  connectUserName?: Maybe<Scalars['String']>;
  fileId?: Maybe<Scalars['String']>;
  /** 파일 주인 유저네임 */
  fileName?: Maybe<Scalars['String']>;
  fileExtension?: Maybe<Scalars['String']>;
  /** 파일 용량 in bytes */
  fileSize?: Maybe<Scalars['Float']>;
  isFile?: Maybe<Scalars['Boolean']>;
  /** 공유 시간 (업로드 일시) */
  createdAt?: Maybe<Scalars['String']>;
  /** 수정일 */
  updatedAt?: Maybe<Scalars['String']>;
};

export type CapaConnectGroupOutput = {
  __typename?: 'CAPAConnectGroupOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaConnectGroup>;
};

export enum CapaConnectGroupStatus {
  Registered = 'REGISTERED',
  Deleted = 'DELETED'
}

export type CapaConnectGroupUser = {
  __typename?: 'CAPAConnectGroupUser';
  /** 그룹 유저 ID */
  id?: Maybe<Scalars['ID']>;
  userId?: Maybe<Scalars['ID']>;
  /** 그룹 ID */
  groupId?: Maybe<Scalars['ID']>;
  /** 이메일 */
  email: Scalars['String'];
  /** 커넥트유저네임 */
  connectUserName?: Maybe<Scalars['String']>;
  /** 캐파유저냐 */
  isCapaUser?: Maybe<Scalars['Boolean']>;
  /** 커넥트유저네임 */
  accessLevel: CapaConnectGroupAccessLevel;
  /** 생성일 (업로드 일시) */
  createdAt?: Maybe<Scalars['String']>;
  /** 수정일 */
  updatedAt?: Maybe<Scalars['String']>;
};

export enum CapaConnectGroupUserAction {
  Update = 'UPDATE',
  Remove = 'REMOVE'
}

export type CapaConnectGroupUserBo = {
  __typename?: 'CAPAConnectGroupUserBO';
  /** 그룹 유저 ID */
  id?: Maybe<Scalars['ID']>;
  userId?: Maybe<Scalars['ID']>;
  /** 그룹 ID */
  groupId?: Maybe<Scalars['ID']>;
  /** 이메일 */
  email: Scalars['String'];
  /** 커넥트유저네임 */
  connectUserName?: Maybe<Scalars['String']>;
  /** 캐파유저냐 */
  isCapaUser?: Maybe<Scalars['Boolean']>;
  /** 커넥트 그룹 권한 */
  accessLevel: CapaConnectGroupAccessLevel;
  /** 생성일 (그룹 초대 시점) */
  createdAt?: Maybe<Scalars['String']>;
  /** 수정일 */
  updatedAt?: Maybe<Scalars['String']>;
  /** 커넥트유저상태 */
  status?: Maybe<CapaConnectUserStatus>;
  /** 테스트 계정 유무 */
  isTest: Scalars['Boolean'];
};

export type CapaConnectGroupsBoOutput = {
  __typename?: 'CAPAConnectGroupsBOOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<Array<CapaConnectGroupBo>>;
};

export type CapaConnectGroupsOutput = {
  __typename?: 'CAPAConnectGroupsOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<Array<CapaConnectGroup>>;
};

export type CapaConnectIssue = {
  __typename?: 'CAPAConnectIssue';
  /** 이슈 ID */
  id?: Maybe<Scalars['ID']>;
  connectUserId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  /** 이슈 관련 파일 */
  files?: Maybe<Array<CapaFile>>;
  issue?: Maybe<Scalars['String']>;
  /** 생성일 */
  createdAt?: Maybe<Scalars['String']>;
  /** 수정일 */
  updatedAt?: Maybe<Scalars['String']>;
  /** 테스트 유무 */
  isTest: Scalars['Boolean'];
};

export type CapaConnectIssuesBoOutput = {
  __typename?: 'CAPAConnectIssuesBOOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<Array<CapaConnectIssue>>;
};

export type CapaConnectMyDrive = {
  __typename?: 'CAPAConnectMyDrive';
  files?: Maybe<Array<CapaConnectMyFile>>;
  path?: Maybe<Array<CapaConnectFolder>>;
};

/** 내드리이브 */
export type CapaConnectMyDrivesOutput = {
  __typename?: 'CAPAConnectMyDrivesOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaConnectMyDrive>;
};

/** 내파일들 */
export type CapaConnectMyFile = {
  __typename?: 'CAPAConnectMyFile';
  /** 커넥트 파일 ID */
  id?: Maybe<Scalars['ID']>;
  /** 파일경로 */
  filePath: Scalars['String'];
  /** 파일명 */
  fileName: Scalars['String'];
  /** 파일확장자 */
  fileExtension?: Maybe<Scalars['String']>;
  /** file owner username */
  fileOwnerUsername?: Maybe<Scalars['String']>;
  /** file owner email */
  fileOwnerEmail?: Maybe<Scalars['String']>;
  /** 파일이냐 아니냐 */
  isFile: Scalars['Boolean'];
  /** 공유이냐 아니냐 */
  isShared: Scalars['Boolean'];
  /** 파일크기 byte */
  fileSize?: Maybe<Scalars['Float']>;
  linkStatus?: Maybe<ConnectUserFileLinkStatus>;
  filePermission?: Maybe<ConnectUserFileStatus>;
  /** original ext */
  originalFileExt?: Maybe<Scalars['String']>;
  /** 파일 변환 상태 */
  status?: Maybe<CapaFileConvertStatus>;
  /** 실제 파일 ID */
  fileId?: Maybe<Scalars['String']>;
  /** 썸네일 */
  thumbnail?: Maybe<Scalars['String']>;
  /** 썸네일 */
  thumbnailUri?: Maybe<Scalars['String']>;
  /** 도면 파일 뷰어 URL */
  drawingFile?: Maybe<Scalars['String']>;
  /** 생성일 (업로드 일시) */
  createdAt?: Maybe<Scalars['String']>;
  /** 수정일 */
  updatedAt?: Maybe<Scalars['String']>;
};

export type CapaConnectOnlineUser = {
  __typename?: 'CAPAConnectOnlineUser';
  email?: Maybe<Scalars['String']>;
};

export type CapaConnectOnlineUsersOutput = {
  __typename?: 'CAPAConnectOnlineUsersOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<Array<CapaConnectOnlineUser>>;
};

/** 캐파 커넥트 유저 정보 */
export type CapaConnectSearchUser = {
  __typename?: 'CAPAConnectSearchUser';
  /** capa user email */
  email: Scalars['String'];
  /** capa cloud user id */
  userId?: Maybe<Scalars['String']>;
  /** capa cloud user id */
  connectUserId?: Maybe<Scalars['String']>;
  /** name */
  userName?: Maybe<Scalars['String']>;
};

export type CapaConnectSearchUsersOutput = {
  __typename?: 'CAPAConnectSearchUsersOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<Array<CapaConnectSearchUser>>;
};

export type CapaConnectSharedFile = {
  __typename?: 'CAPAConnectSharedFile';
  /** 커넥트 파일 ID */
  id?: Maybe<Scalars['ID']>;
  /** 파일경로 */
  filePath: Scalars['String'];
  /** 파일명 */
  fileName: Scalars['String'];
  /** 파일확장자 */
  fileExtension?: Maybe<Scalars['String']>;
  /** file owner username */
  fileOwnerUsername?: Maybe<Scalars['String']>;
  /** file owner email */
  fileOwnerEmail?: Maybe<Scalars['String']>;
  /** 파일이냐 아니냐 */
  isFile: Scalars['Boolean'];
  /** 공유이냐 아니냐 */
  isShared: Scalars['Boolean'];
  /** 파일크기 byte */
  fileSize?: Maybe<Scalars['Float']>;
  linkStatus?: Maybe<ConnectUserFileLinkStatus>;
  filePermission?: Maybe<ConnectUserFileStatus>;
  /** original ext */
  originalFileExt?: Maybe<Scalars['String']>;
  /** 파일 변환 상태 */
  status?: Maybe<CapaFileConvertStatus>;
  /** 실제 파일 ID */
  fileId?: Maybe<Scalars['String']>;
  /** 썸네일 */
  thumbnail?: Maybe<Scalars['String']>;
  /** 썸네일 */
  thumbnailUri?: Maybe<Scalars['String']>;
  /** 도면 파일 뷰어 URL */
  drawingFile?: Maybe<Scalars['String']>;
  /** 생성일 (업로드 일시) */
  createdAt?: Maybe<Scalars['String']>;
  /** 수정일 */
  updatedAt?: Maybe<Scalars['String']>;
  /** 그룹 ID */
  groupId?: Maybe<Scalars['ID']>;
  /** 이메일 */
  email: Scalars['String'];
  /** 커넥트유저네임 */
  connectUserName?: Maybe<Scalars['String']>;
  permission?: Maybe<CapaQuotationRfqAllowOpenFileStatus>;
  hasCommentThread?: Maybe<ConnectSharedFileCommentThread>;
  isFirstShared?: Maybe<Scalars['Boolean']>;
};

export enum CapaConnectSharedFileType {
  All = 'ALL',
  Cad = 'CAD',
  Video = 'VIDEO',
  Photo = 'PHOTO',
  Document = 'DOCUMENT',
  Other = 'OTHER'
}

export type CapaConnectSharedFilesOutput = {
  __typename?: 'CAPAConnectSharedFilesOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<Array<CapaConnectSharedFile>>;
};

export type CapaConnectTutorial = {
  __typename?: 'CAPAConnectTutorial';
  message: Scalars['String'];
  fileId: Scalars['String'];
};

export type CapaConnectUpdate = {
  __typename?: 'CAPAConnectUpdate';
  email?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['String']>;
  modelId?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  nodeId?: Maybe<Scalars['Float']>;
  anchorPos?: Maybe<Capa3DPos>;
  handlePos?: Maybe<Capa3DPos>;
  annotationId?: Maybe<Scalars['String']>;
  commentId?: Maybe<Scalars['String']>;
  authorId?: Maybe<Scalars['String']>;
  authorName?: Maybe<Scalars['String']>;
  authorEmail?: Maybe<Scalars['String']>;
  edited?: Maybe<Scalars['Boolean']>;
  timestamp?: Maybe<Scalars['String']>;
  /** 업데이트 타입 */
  type?: Maybe<CapaConnectUpdateType>;
};

export type CapaConnectUpdateOutput = {
  __typename?: 'CAPAConnectUpdateOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaConnectUpdate>;
};

export enum CapaConnectUpdateType {
  ChatUpdate = 'CHAT_UPDATE',
  GroupUpdate = 'GROUP_UPDATE',
  MemberUpdate = 'MEMBER_UPDATE',
  FileUpdate = 'FILE_UPDATE',
  AnnotationAdd = 'ANNOTATION_ADD',
  AnnotationUpdate = 'ANNOTATION_UPDATE',
  AnnotationDelete = 'ANNOTATION_DELETE',
  AnnotationOpen = 'ANNOTATION_OPEN',
  AnnotationCommentAdd = 'ANNOTATION_COMMENT_ADD',
  AnnotationCommentUpdate = 'ANNOTATION_COMMENT_UPDATE',
  AnnotationCommentDelete = 'ANNOTATION_COMMENT_DELETE'
}

/** 캐파 커넥트 유저 정보 */
export type CapaConnectUser = {
  __typename?: 'CAPAConnectUser';
  /** capa cloud user id */
  id: Scalars['ID'];
  /** capa user email */
  email: Scalars['String'];
  /** name */
  userName?: Maybe<Scalars['String']>;
  /** userId */
  userId?: Maybe<Scalars['String']>;
  chatRoomUserId?: Maybe<Scalars['String']>;
  notificationFilter: CapaConnectUserNotificationFilter;
  /** 사용중인 드라이브 용량 (단위: byte) */
  usedSize: Scalars['Float'];
  /** 사용가능한 드라이브 용량 (단위: byte) */
  availSize: Scalars['Float'];
  /** 전체 드라이브 용량 (단위: byte) */
  driveSize: Scalars['Float'];
  /** [필수] 캐파 서비스 이용약관 동의 */
  serviceAgreed?: Maybe<Scalars['Boolean']>;
  /** [필수] 개인정보 수집 및 활용 동의 */
  privacyAgreed?: Maybe<Scalars['Boolean']>;
  /** [선택] 프로모션 정보 수신 동의 */
  promotionAgreed?: Maybe<Scalars['Boolean']>;
};

/** 캐파 BO 커넥트 유저 정보 */
export type CapaConnectUserBo = {
  __typename?: 'CAPAConnectUserBO';
  /** capa backoffice connect user id */
  id: Scalars['ID'];
  /** capa backoffice user id */
  userId: Scalars['ID'];
  /** capa connect user email */
  email: Scalars['String'];
  /** capa connect user name */
  userName?: Maybe<Scalars['String']>;
  /** 상태 CAPAConnectUserStatus */
  status: CapaConnectUserStatus;
  /** 가입 경로 */
  route?: Maybe<Array<CapaInflowRoute>>;
  /** 사용중인 드라이브 용량 (단위: byte) */
  usedSize: Scalars['Float'];
  /** 전체 드라이브 용량 (단위: byte) */
  driveSize: Scalars['Float'];
  /** 커넥트 유저 생성일 */
  createdAt: Scalars['String'];
  /** 커넥트 유저 마지막 로그인 */
  lastLoginAt?: Maybe<Scalars['String']>;
  /** 커넥트 업로드 파일 개수 */
  totalFiles?: Maybe<Scalars['Float']>;
  /** 커넥트 공유한 파일 개수 */
  totalSharedFiles?: Maybe<Scalars['Float']>;
  files?: Maybe<Array<CapaConnectMyFile>>;
  /** 커넥트 그룹 개수 */
  totalGroups?: Maybe<Scalars['Float']>;
  /** 커넥트 생성한 그룹 개수 */
  totalOwnerGroups?: Maybe<Scalars['Float']>;
  /** 커넥트 참여 그룹 개수 */
  totalMemberGroups?: Maybe<Scalars['Float']>;
  /** 커넥트 그룹 */
  groups?: Maybe<Array<CapaConnectUserGroupBo>>;
  /** 테스트 계정 유무 */
  isTest: Scalars['Boolean'];
};

export type CapaConnectUserBoOutput = {
  __typename?: 'CAPAConnectUserBOOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaConnectUserBo>;
};

export type CapaConnectUserFile = {
  __typename?: 'CAPAConnectUserFile';
  id?: Maybe<Scalars['ID']>;
  linkStatus?: Maybe<ConnectUserFileLinkStatus>;
  filePermission?: Maybe<ConnectUserFileStatus>;
};

/** 내파일들 */
export type CapaConnectUserFileBo = {
  __typename?: 'CAPAConnectUserFileBO';
  /** 커넥트 파일 ID */
  id?: Maybe<Scalars['ID']>;
  /** 파일명 */
  fileName: Scalars['String'];
  /** 파일확장자 */
  fileExtension?: Maybe<Scalars['String']>;
  /** file owner username */
  fileOwnerUsername?: Maybe<Scalars['String']>;
  /** file owner email */
  fileOwnerEmail?: Maybe<Scalars['String']>;
  /** 파일이냐 아니냐 */
  isFile: Scalars['Boolean'];
  /** 파일크기 byte */
  fileSize?: Maybe<Scalars['Float']>;
  linkStatus?: Maybe<ConnectUserFileLinkStatus>;
  filePermission?: Maybe<ConnectUserFileStatus>;
  /** 파일 변환 상태 */
  status?: Maybe<CapaFileConvertStatus>;
  /** 실제 파일 ID */
  fileId?: Maybe<Scalars['String']>;
  /** Test 계정 유무 */
  isTest: Scalars['Boolean'];
  /** 방문자 수 */
  visitorsCount?: Maybe<Scalars['Float']>;
  /** 생성일 (업로드 일시) */
  createdAt?: Maybe<Scalars['String']>;
  /** 수정일 */
  updatedAt?: Maybe<Scalars['String']>;
};

export type CapaConnectUserFileLinksBo = {
  __typename?: 'CAPAConnectUserFileLinksBO';
  totalCount?: Maybe<Scalars['Float']>;
  files?: Maybe<Array<CapaConnectUserFileBo>>;
  activeFilesCount?: Maybe<Scalars['Float']>;
};

export type CapaConnectUserFileOutput = {
  __typename?: 'CAPAConnectUserFileOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaConnectUserFile>;
};

export type CapaConnectUserGroupBo = {
  __typename?: 'CAPAConnectUserGroupBO';
  /** capa backoffice connect group id */
  id: Scalars['ID'];
  groupName?: Maybe<Scalars['String']>;
  /** 주석 개수 */
  totalGroupAnnotations?: Maybe<Scalars['Float']>;
  /** 주석 댓글 개수 */
  totalGroupAnnotationComments?: Maybe<Scalars['Float']>;
  /** 커넥트유저네임 */
  accessLevel: CapaConnectGroupAccessLevel;
  status?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
};

/** notification filter */
export type CapaConnectUserNotificationFilter = {
  __typename?: 'CAPAConnectUserNotificationFilter';
  chatPush?: Maybe<Scalars['Boolean']>;
  groupPush?: Maybe<Scalars['Boolean']>;
  groupEmail?: Maybe<Scalars['Boolean']>;
  commentThreadPush?: Maybe<Scalars['Boolean']>;
  commentThreadEmail?: Maybe<Scalars['Boolean']>;
  filePush?: Maybe<Scalars['Boolean']>;
  fileEmail?: Maybe<Scalars['Boolean']>;
};

export type CapaConnectUserOutput = {
  __typename?: 'CAPAConnectUserOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaConnectUser>;
};

export enum CapaConnectUserStatus {
  /** 가입 */
  Registered = 'REGISTERED',
  /** 탈퇴 */
  Deleted = 'DELETED'
}

export type CapaConnectUsersBoOutput = {
  __typename?: 'CAPAConnectUsersBOOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<Array<CapaConnectUserBo>>;
};

/** 캐파 RFQ 상담 시작 방식 */
export enum CapaConsultingStartMethod {
  /** old rfq - 견적서 없이 상담 가능 */
  OldConsulting = 'OLD_CONSULTING',
  /** 견적서 있어야만 상담 가능 */
  QuotationRequired = 'QUOTATION_REQUIRED',
  /** 견적서 없이 회사소개서만으로도 상담 가능 */
  PartnerIntroductionAvailable = 'PARTNER_INTRODUCTION_AVAILABLE'
}

export type CapaDataCount = {
  __typename?: 'CAPADataCount';
  count: Scalars['Float'];
};

export type CapaDesignDetail = {
  __typename?: 'CAPADesignDetail';
  /** 요청 작업 */
  services?: Maybe<Array<CapaDesignService>>;
};

export type CapaDesignService = {
  __typename?: 'CAPADesignService';
  serviceType?: Maybe<CapaDesignServiceType>;
};

/** design service type */
export enum CapaDesignServiceType {
  /** 제품 디자인 */
  MechanicalDesign = 'MECHANICAL_DESIGN',
  /** 기구 설계  */
  ProductDesign = 'PRODUCT_DESIGN'
}

/** 방해금지 기간 설정 */
export type CapaDoNotDisturbFilter = {
  __typename?: 'CAPADoNotDisturbFilter';
  isActive?: Maybe<Scalars['Boolean']>;
  /** 시작 시간 */
  from?: Maybe<Scalars['Float']>;
  /** 끝나는 시간 */
  to?: Maybe<Scalars['Float']>;
};

/** 방해금지 기간 설정 */
export type CapaDoNotDisturbFilterInput = {
  isActive?: Maybe<Scalars['Boolean']>;
  /** 시작 시간 */
  from?: Maybe<Scalars['Float']>;
  /** 끝나는 시간 */
  to?: Maybe<Scalars['Float']>;
};

/** 에러코드 */
export enum CapaErrorCode {
  /** 성공 */
  Success = 'SUCCESS',
  /** 통합 유저를 찾을 수 없습니다. */
  UserNotFound = 'USER_NOT_FOUND',
  /** 비밀번호가 일치하지 않습니다. */
  PasswordIncorrect = 'PASSWORD_INCORRECT',
  /** 중복된 이메일 입니다. */
  DuplicateEmail = 'DUPLICATE_EMAIL',
  /** 검증 코드가 일치하지 않습니다. */
  CodeMismatch = 'CODE_MISMATCH',
  /** 토큰이 일치하지 않습니다. */
  TokenMismatch = 'TOKEN_MISMATCH',
  EmailCodeMismatch = 'EMAIL_CODE_MISMATCH',
  CellPhoneCodeMismatch = 'CELL_PHONE_CODE_MISMATCH',
  /** 유효하지 않은 추천인 ID 입니다. */
  InvalidReferralId = 'INVALID_REFERRAL_ID',
  /** 동의가 필요합니다. */
  NotAgreed = 'NOT_AGREED',
  /** 토큰이 만료되었습니다. */
  TokenExpired = 'TOKEN_EXPIRED',
  /** 유효하지 않은 토큰 입니다. */
  InvalidToken = 'INVALID_TOKEN',
  /** SQL문에서 에러가 발생하였습니다. */
  QueryError = 'QUERY_ERROR',
  /** 허가 되지 않은 접근입니다. */
  Unauthorized = 'UNAUTHORIZED',
  /** 잘못된 요청입니다. */
  BadRequest = 'BAD_REQUEST',
  /** 유효하지 않은 파라미터 입니다. */
  ParmeterValidationError = 'PARMETER_VALIDATION_ERROR',
  /** 채팅 유저가 존재하지 않습니다. */
  ChatUserNotFound = 'CHAT_USER_NOT_FOUND',
  /** 클라우드 사용자가 이미 존재 합니다. */
  CloudUserExists = 'CLOUD_USER_EXISTS',
  /** 클라우드 사용자를 찾을 수 없습니다. */
  CloudUserNotFound = 'CLOUD_USER_NOT_FOUND',
  /** 커넥트 사용자가 이미 존재 합니다. */
  ConnectUserExists = 'CONNECT_USER_EXISTS',
  /** 커넥트 사용자를 찾을 수 없습니다. */
  ConnectUserNotFound = 'CONNECT_USER_NOT_FOUND',
  /** 고객이 이미 존재 합니다. */
  QuotationUserExists = 'QUOTATION_USER_EXISTS',
  /** 고객을 찾을 수 없습니다. */
  QuotationUserNotFound = 'QUOTATION_USER_NOT_FOUND',
  QuotationUserRegistrationFailed = 'QUOTATION_USER_REGISTRATION_FAILED',
  /** 파트너를 찾을 수 없습니다. */
  QuotationPartnerNotFound = 'QUOTATION_PARTNER_NOT_FOUND',
  /** 파트너의 유저를 찾을 수 없습니다. */
  QuotationPartnerUserNotFound = 'QUOTATION_PARTNER_USER_NOT_FOUND',
  /** 이미 파트너가 존재합니다. */
  QuotationPartnerExists = 'QUOTATION_PARTNER_EXISTS',
  /** 권한이 없습니다. */
  NotAllowedUser = 'NOT_ALLOWED_USER',
  /** 중복된 사업자 등록 번호 입니다. */
  DuplicateCompanyRegistrationNo = 'DUPLICATE_COMPANY_REGISTRATION_NO',
  ValidCompanyRegistrationNo = 'VALID_COMPANY_REGISTRATION_NO',
  /** 유효하지 않은 사업자 등록 번호 입니다. */
  InavlidCompanyRegistrationNo = 'INAVLID_COMPANY_REGISTRATION_NO',
  /** 사업자 등록증을 입력해야 합니다. */
  NoCompanyRegistrationFile = 'NO_COMPANY_REGISTRATION_FILE',
  NoPortfolioImage = 'NO_PORTFOLIO_IMAGE',
  NoMatchPortfolioImageLength = 'NO_MATCH_PORTFOLIO_IMAGE_LENGTH',
  /** 파일을 찾을 수 없습니다. */
  FileNotFound = 'FILE_NOT_FOUND',
  NotExistPdfFile = 'NOT_EXIST_PDF_FILE',
  NotExistImagePortfolio = 'NOT_EXIST_IMAGE_PORTFOLIO',
  FileExists = 'FILE_EXISTS',
  /** 도면 파일 변환에 실패 하였습니다. */
  FileConversionFailed = 'FILE_CONVERSION_FAILED',
  /** 클라우드 용량이 부족합니다. */
  NotEnoughCloudStorage = 'NOT_ENOUGH_CLOUD_STORAGE',
  /** 커넥트 용량이 부족합니다. */
  NotEnoughConnectStorage = 'NOT_ENOUGH_CONNECT_STORAGE',
  /** 에러 */
  Error = 'ERROR',
  /** ITP_EVENT_EXIST */
  ItpEventExist = 'ITP_EVENT_EXIST',
  /** ITP_EVENT_NOT_FOUND */
  ItpEventNotFound = 'ITP_EVENT_NOT_FOUND',
  JsbEventExist = 'JSB_EVENT_EXIST',
  JsbEventNotFound = 'JSB_EVENT_NOT_FOUND',
  /** ALREADY_APPROVED */
  AlreadyApproved = 'ALREADY_APPROVED',
  /** BACKOFFICE_NOT_FOUND */
  BackofficeNotFound = 'BACKOFFICE_NOT_FOUND',
  /** CHATROOM_NOT_FOUND */
  ChatroomNotFound = 'CHATROOM_NOT_FOUND',
  QuotationChatroomNotFound = 'QUOTATION_CHATROOM_NOT_FOUND',
  /** CHATROOM_NOT_OPENED */
  ChatroomNotOpened = 'CHATROOM_NOT_OPENED',
  ChatroomAlreadyOpened = 'CHATROOM_ALREADY_OPENED',
  /** NO_CHAT_USERS */
  NoChatUsers = 'NO_CHAT_USERS',
  /** CHATROOM_EXISTS */
  ChatroomExists = 'CHATROOM_EXISTS',
  /** RFQ_NOT_FOUND */
  RfqNotFound = 'RFQ_NOT_FOUND',
  /** RFQ_QUOTATION_OR_QUOTATION_PARTNER_INTRODUCTION_NOT_FOUND */
  RfqQuotationOrQuotationPartnerIntroductionNotFound = 'RFQ_QUOTATION_OR_QUOTATION_PARTNER_INTRODUCTION_NOT_FOUND',
  /** RFQ_QUOTATION_AND_QUOTATION_PARTNER_INTRODUCTION_NOT_FOUND */
  RfqQuotationAndQuotationPartnerIntroductionNotFound = 'RFQ_QUOTATION_AND_QUOTATION_PARTNER_INTRODUCTION_NOT_FOUND',
  /** RFQ_QUOTATION_NOT_FOUND_PARTNER_INTRODUCTION_FOUND */
  RfqQuotationNotFoundPartnerIntroductionFound = 'RFQ_QUOTATION_NOT_FOUND_PARTNER_INTRODUCTION_FOUND',
  /** RFQ_QUOTATION_NOT_FOUND */
  RfqQuotationNotFound = 'RFQ_QUOTATION_NOT_FOUND',
  RfqNotQuotationUser = 'RFQ_NOT_QUOTATION_USER',
  /** RFQ_QUOTATION_ALREADY_REGISTERED */
  RfqQuotationAlreadyRegistered = 'RFQ_QUOTATION_ALREADY_REGISTERED',
  /** RFQ_QUOTATION_ALREADY_CONFIRMED */
  RfqQuotationAlreadyConfirmed = 'RFQ_QUOTATION_ALREADY_CONFIRMED',
  /** RFQ_QUOTATION_NOT_CONFIRMED */
  RfqQuotationNotConfirmed = 'RFQ_QUOTATION_NOT_CONFIRMED',
  /** RFQ_QUOTATION_PARTNER_INTRODUCTION_NOT_FOUND */
  RfqQuotationPartnerIntroductionNotFound = 'RFQ_QUOTATION_PARTNER_INTRODUCTION_NOT_FOUND',
  /** RFQ_FILTER_NOT_PROVIDE_SERVICE_TYPE */
  RfqFilterNotProvideServiceType = 'RFQ_FILTER_NOT_PROVIDE_SERVICE_TYPE',
  /** RFQ_PURCHASE_ORDER_NOT_FOUND */
  RfqPurchaseOrderNotFound = 'RFQ_PURCHASE_ORDER_NOT_FOUND',
  /** RFQ_PURCHASE_ORDER_ALREADY_CONFIRMED */
  RfqPurchaseOrderAlreadyConfirmed = 'RFQ_PURCHASE_ORDER_ALREADY_CONFIRMED',
  /** RFQ_PURCHASE_ORDER_ALREADY_REGISTERED */
  RfqPurchaseOrderAlreadyRegistered = 'RFQ_PURCHASE_ORDER_ALREADY_REGISTERED',
  /** INVALID_RFQ_STATUS */
  InvalidRfqStatus = 'INVALID_RFQ_STATUS',
  /** SEND_MESSAGE_FAILED */
  SendMessageFailed = 'SEND_MESSAGE_FAILED',
  /** READ_MESSAGE_FAILED */
  ReadMessageFailed = 'READ_MESSAGE_FAILED',
  /** FETCH_MESSAGES_FAILED */
  FetchMessagesFailed = 'FETCH_MESSAGES_FAILED',
  /** CANNOT_CREATE_CHATROOM */
  CannotCreateChatroom = 'CANNOT_CREATE_CHATROOM',
  /** INVALID_NOTIFICATION_SEQ */
  InvalidNotificationSeq = 'INVALID_NOTIFICATION_SEQ',
  /** DUPLICATE_RFQ_ID */
  DuplicateRfqId = 'DUPLICATE_RFQ_ID',
  /** RFQ_ALREADY_CONFIRMED */
  RfqAlreadyConfirmed = 'RFQ_ALREADY_CONFIRMED',
  /** RFQ_ALREADY_STOPPED */
  RfqAlreadyStopped = 'RFQ_ALREADY_STOPPED',
  RfqAlreadyComplete = 'RFQ_ALREADY_COMPLETE',
  /** 결제 프로세스 진행중입니다. */
  RfqPaymentInProgress = 'RFQ_PAYMENT_IN_PROGRESS',
  RfqNotConfirmed = 'RFQ_NOT_CONFIRMED',
  /** CANNOT_CHANGE_RFQ_FILE_OPEN_STATUS */
  CannotChangeRfqFileOpenStatus = 'CANNOT_CHANGE_RFQ_FILE_OPEN_STATUS',
  AlreadyEvaluated = 'ALREADY_EVALUATED',
  AlreadyRated = 'ALREADY_RATED',
  RfqNotComplete = 'RFQ_NOT_COMPLETE',
  PassedReviewPeriod = 'PASSED_REVIEW_PERIOD',
  NotReviewReplyPeriod = 'NOT_REVIEW_REPLY_PERIOD',
  AlreadyReplied = 'ALREADY_REPLIED',
  NoMessage = 'NO_MESSAGE',
  NotReviewed = 'NOT_REVIEWED',
  InvalidPath = 'INVALID_PATH',
  GroupNotExists = 'GROUP_NOT_EXISTS',
  GroupAlreadyExists = 'GROUP_ALREADY_EXISTS',
  CannotInviteSelf = 'CANNOT_INVITE_SELF',
  DestinationNotFound = 'DESTINATION_NOT_FOUND',
  DestinationIsFile = 'DESTINATION_IS_FILE',
  InvalidDestination = 'INVALID_DESTINATION',
  SameDestination = 'SAME_DESTINATION',
  NotSendPartnerMessage = 'NOT_SEND_PARTNER_MESSAGE',
  DuplicateGroupName = 'DUPLICATE_GROUP_NAME',
  ConnectUserNotInGroup = 'CONNECT_USER_NOT_IN_GROUP',
  InvalidAccessLevel = 'INVALID_ACCESS_LEVEL',
  NotFoundPartnerChatRoom = 'NOT_FOUND_PARTNER_CHAT_ROOM',
  RfqUpdateNotComplete = 'RFQ_UPDATE_NOT_COMPLETE',
  IsNotExistPurchaseOrder = 'IS_NOT_EXIST_PURCHASE_ORDER',
  IsNotExistRfqQuotation = 'IS_NOT_EXIST_RFQ_QUOTATION',
  IsExistSignature = 'IS_EXIST_SIGNATURE',
  IsExistCompanyRegistration = 'IS_EXIST_COMPANY_REGISTRATION',
  InvalidAnnotation = 'INVALID_ANNOTATION',
  InvalidComment = 'INVALID_COMMENT',
  InvalidGroupUser = 'INVALID_GROUP_USER',
  InvalidFileOwner = 'INVALID_FILE_OWNER',
  /** MAKE_PDF_ERROR */
  MakePdfError = 'MAKE_PDF_ERROR',
  RfqTransactionNotFound = 'RFQ_TRANSACTION_NOT_FOUND',
  /** 허브스팟 사용자가 이미 존재합니다. */
  HubspotUserExists = 'HUBSPOT_USER_EXISTS',
  /** 이미 구독중입니다. */
  AlreadySubscribed = 'ALREADY_SUBSCRIBED',
  NotRegisterQuotationUser = 'NOT_REGISTER_QUOTATION_USER',
  NotUpdateQuotationUser = 'NOT_UPDATE_QUOTATION_USER',
  NotRegisterQuotationPartner = 'NOT_REGISTER_QUOTATION_PARTNER',
  NotUpdateQuotationPartner = 'NOT_UPDATE_QUOTATION_PARTNER',
  NotSaveRfqFilter = 'NOT_SAVE_RFQ_FILTER',
  NotRegisterRfqrfq = 'NOT_REGISTER_RFQRFQ',
  NotRequestAdjustRfqQuotation = 'NOT_REQUEST_ADJUST_RFQ_QUOTATION',
  NotConfirmRfqQuotation = 'NOT_CONFIRM_RFQ_QUOTATION',
  NotRegisterRequestRfqPurchaseorder = 'NOT_REGISTER_REQUEST_RFQ_PURCHASEORDER',
  NotRequestAdjustRfqPurchaseOrder = 'NOT_REQUEST_ADJUST_RFQ_PURCHASE_ORDER',
  NotRequestConfirmRfqTransaction = 'NOT_REQUEST_CONFIRM_RFQ_TRANSACTION',
  NotConfirmRfqTransaction = 'NOT_CONFIRM_RFQ_TRANSACTION',
  NotRequestCompleteRfqTransaction = 'NOT_REQUEST_COMPLETE_RFQ_TRANSACTION',
  NotCompleteRfqTransaction = 'NOT_COMPLETE_RFQ_TRANSACTION',
  NotReadRfqQuotation = 'NOT_READ_RFQ_QUOTATION',
  NotReadRfqPurchaseOrder = 'NOT_READ_RFQ_PURCHASE_ORDER',
  NotExpireRfq = 'NOT_EXPIRE_RFQ',
  NotEvaluateRfqPartners = 'NOT_EVALUATE_RFQ_PARTNERS',
  NotCreateSimpleQuotation = 'NOT_CREATE_SIMPLE_QUOTATION',
  NotCreateDetailedQuotation = 'NOT_CREATE_DETAILED_QUOTATION',
  NotCreatePurchaseOrder = 'NOT_CREATE_PURCHASE_ORDER',
  NotCreatePurchaseOrderWithItem = 'NOT_CREATE_PURCHASE_ORDER_WITH_ITEM',
  NotRegisterRequestRfqPurchaseOrderWithItem = 'NOT_REGISTER_REQUEST_RFQ_PURCHASE_ORDER_WITH_ITEM',
  NotRegisterRequestRfqQuotation = 'NOT_REGISTER_REQUEST_RFQ_QUOTATION',
  NotRequestAdjustRfqPurchaseOrderWithItem = 'NOT_REQUEST_ADJUST_RFQ_PURCHASE_ORDER_WITH_ITEM',
  NotConfirmRfqPurchaseOrderWithItem = 'NOT_CONFIRM_RFQ_PURCHASE_ORDER_WITH_ITEM',
  RequestRfqDrawingFileDownload = 'REQUEST_RFQ_DRAWING_FILE_DOWNLOAD',
  NotRallowRfqDrawingFileDownload = 'NOT_RALLOW_RFQ_DRAWING_FILE_DOWNLOAD',
  NotToggleFavorite = 'NOT_TOGGLE_FAVORITE',
  NotReviewRfq = 'NOT_REVIEW_RFQ',
  NotReplyRfqReview = 'NOT_REPLY_RFQ_REVIEW',
  NotReadAllManagement = 'NOT_READ_ALL_MANAGEMENT',
  NotUpdateRfqStatus = 'NOT_UPDATE_RFQ_STATUS',
  NotCreateQuotationChatRoom = 'NOT_CREATE_QUOTATION_CHAT_ROOM',
  NotSendQuotationChatMessage = 'NOT_SEND_QUOTATION_CHAT_MESSAGE',
  NotFetchQuotationChatRoom = 'NOT_FETCH_QUOTATION_CHAT_ROOM',
  NotQuitQuotationChatRoom = 'NOT_QUIT_QUOTATION_CHAT_ROOM',
  NotReadQuotationChatMessage = 'NOT_READ_QUOTATION_CHAT_MESSAGE',
  NotVerifyCompanyRegistrationNumber = 'NOT_VERIFY_COMPANY_REGISTRATION_NUMBER',
  NotSuggestService = 'NOT_SUGGEST_SERVICE',
  NotRateQuotationService = 'NOT_RATE_QUOTATION_SERVICE',
  NotFetchQuotationServiceRating = 'NOT_FETCH_QUOTATION_SERVICE_RATING',
  AlreadyHaveFileInDrive = 'ALREADY_HAVE_FILE_IN_DRIVE',
  ClossingBusiness = 'CLOSSING_BUSINESS',
  IsNotExistCompanyInfo = 'IS_NOT_EXIST_COMPANY_INFO',
  NiceDnbCompanyOverViewError = 'NICE_DNB_COMPANY_OVER_VIEW_ERROR',
  NiceDnbIsClosedBusinessError = 'NICE_DNB_IS_CLOSED_BUSINESS_ERROR',
  NiceDnbAccssTokenError = 'NICE_DNB_ACCSS_TOKEN_ERROR',
  SharpImageResizeError = 'SHARP_IMAGE_RESIZE_ERROR',
  /** 이미 존재하는 PCB 요청서입니다. */
  DuplicatePcbRfq = 'DUPLICATE_PCB_RFQ',
  DuplicateDesignRfq = 'DUPLICATE_DESIGN_RFQ',
  PcbPdfError = 'PCB_PDF_ERROR',
  ConnectContactUserAlreadyExists = 'CONNECT_CONTACT_USER_ALREADY_EXISTS',
  /** GROUP_USER_ALREADY_EXISTS */
  GroupUserAlreadyExists = 'GROUP_USER_ALREADY_EXISTS',
  /** CANNOT_ADD_THEMSELF_TO_CONTACT_LIST */
  CannotAddThemselfToContactList = 'CANNOT_ADD_THEMSELF_TO_CONTACT_LIST',
  /** CANNOT_INVITE_CAPA_OFFICIAL */
  CannotInviteCapaOfficial = 'CANNOT_INVITE_CAPA_OFFICIAL',
  /** CONNECT_FILE_CAN_NOT_BE_DOWNLOADED */
  ConnectFileCanNotBeDownloaded = 'CONNECT_FILE_CAN_NOT_BE_DOWNLOADED',
  /** 현재 서버 배포중 입니다. */
  DeployingServer = 'DEPLOYING_SERVER',
  NotExistQuotationServiceType = 'NOT_EXIST_QUOTATION_SERVICE_TYPE',
  NotMatchUser = 'NOT_MATCH_USER',
  NotMatchPartnerUserType = 'NOT_MATCH_PARTNER_USER_TYPE',
  NotMatchFileType = 'NOT_MATCH_FILE_TYPE',
  NotCloseAllChatRooms = 'NOT_CLOSE_ALL_CHAT_ROOMS',
  NotAllServiceTypesIncluded = 'NOT_ALL_SERVICE_TYPES_INCLUDED',
  NotServiceTypeIncluded = 'NOT_SERVICE_TYPE_INCLUDED',
  /** 활성화 되지 않은 서비스 타입입니다. */
  NotActiveServiceType = 'NOT_ACTIVE_SERVICE_TYPE',
  /** 가공 방식이 포함되지 않았습니다. */
  NotIncludedDetailedService = 'NOT_INCLUDED_DETAILED_SERVICE',
  InActiveServiceIncluded = 'IN_ACTIVE_SERVICE_INCLUDED',
  OverFileSize = 'OVER_FILE_SIZE',
  MigrationAlreadyCompleted = 'MIGRATION_ALREADY_COMPLETED',
  NotIncludePortfolioFile = 'NOT_INCLUDE_PORTFOLIO_FILE',
  /** FILE_OWNER_NOT_FOUND */
  FileOwnerNotFound = 'FILE_OWNER_NOT_FOUND',
  /** 이미 소개서를 보냈습니다 */
  AlreadySendIntroduction = 'ALREADY_SEND_INTRODUCTION',
  /** 광고 정보를 찾지 못함 */
  AdvertisementNotFound = 'ADVERTISEMENT_NOT_FOUND',
  /** 광고 정보를 찾지 못함 */
  AdvertisingNotFound = 'ADVERTISING_NOT_FOUND',
  NotFoundQuotationAnnotationSharedFileId = 'NOT_FOUND_QUOTATION_ANNOTATION_SHARED_FILE_ID',
  NotFoundQuotationFile = 'NOT_FOUND_QUOTATION_FILE',
  ProvideOnlyOneFileId = 'PROVIDE_ONLY_ONE_FILE_ID',
  NotFoundAnnotation = 'NOT_FOUND_ANNOTATION',
  NotFoundAnnotationParentComment = 'NOT_FOUND_ANNOTATION_PARENT_COMMENT',
  NotFoundAnnotationComment = 'NOT_FOUND_ANNOTATION_COMMENT',
  NotMatchDrawingFile = 'NOT_MATCH_DRAWING_FILE',
  NotMatchChatRoomMessageFile = 'NOT_MATCH_CHAT_ROOM_MESSAGE_FILE',
  NotFoundChatRoom = 'NOT_FOUND_CHAT_ROOM',
  /** 바이패스 관련 고객 불만 정보가 존재하지 않습니다. */
  BypassComplaintNotExists = 'BYPASS_COMPLAINT_NOT_EXISTS',
  NotFountChatRoomUser = 'NOT_FOUNT_CHAT_ROOM_USER',
  NotFoundChatRoomMessageFile = 'NOT_FOUND_CHAT_ROOM_MESSAGE_FILE',
  /** 어노테이션 부모 댓글은 삭제할 수 없습니다. */
  ParentCommentCannotDelete = 'PARENT_COMMENT_CANNOT_DELETE',
  /** 한개 이상의 파라미터 값이 필요합니다. */
  MoreThanOneParameterValueRequired = 'MORE_THAN_ONE_PARAMETER_VALUE_REQUIRED',
  /** 어노테이션이 일치하지 않습니다. */
  NotMatchAnnotation = 'NOT_MATCH_ANNOTATION',
  FetchAnnotationNotFound = 'FETCH_ANNOTATION_NOT_FOUND',
  RequiredDetailedRequest = 'REQUIRED_DETAILED_REQUEST',
  /** 상담리뷰가 존재하지 않습니다. */
  ConsultingReviewNotFound = 'CONSULTING_REVIEW_NOT_FOUND',
  /** 상담리뷰가 이미 존재합니다. */
  ConsultingReviewAlreadyExists = 'CONSULTING_REVIEW_ALREADY_EXISTS',
  /** 거래리뷰가 존재하지 않습니다. */
  TransactionReviewNotFound = 'TRANSACTION_REVIEW_NOT_FOUND',
  /** 거래리뷰가 이미 존재합니다. */
  TransactionReviewAlreadyExists = 'TRANSACTION_REVIEW_ALREADY_EXISTS',
  /** 상담리뷰 작성할 수 있는 시간을 초과하였습니다. */
  ConsultingReviewTimeExceed = 'CONSULTING_REVIEW_TIME_EXCEED',
  /** 거래리뷰 작성할 수 있는 시간을 초과하였습니다. */
  TransactionReviewTimeExceed = 'TRANSACTION_REVIEW_TIME_EXCEED',
  /** ICX (industrial complex) 정보가 없습니다. */
  IcxNotExist = 'ICX_NOT_EXIST',
  /** 거부된 발주서입니다. */
  PurchaseOrderRejected = 'PURCHASE_ORDER_REJECTED',
  /** can't configure firebase admin */
  FirebaseConfigurationError = 'FIREBASE_CONFIGURATION_ERROR',
  /** 거래리뷰 요청을 이미 전송했습니다. */
  AlreadyRequestRegisterTransactionReview = 'ALREADY_REQUEST_REGISTER_TRANSACTION_REVIEW',
  /** BO 유저를 찾을 수 없습니다. */
  BoUserNotFound = 'BO_USER_NOT_FOUND',
  /** 이미 탈퇴한 BO 회원입니다. */
  AlreadyWithdrawnBoUser = 'ALREADY_WITHDRAWN_BO_USER',
  /** 거절됀 사용자 */
  UserRejected = 'USER_REJECTED',
  /** 동일 정보의 엠베서더가 이미 존재합니다. */
  AmbassadorAlreadyExists = 'AMBASSADOR_ALREADY_EXISTS',
  SameEmailFriendsAlreadyExists = 'SAME_EMAIL_FRIENDS_ALREADY_EXISTS',
  SameEinFriendsAlreadyExists = 'SAME_EIN_FRIENDS_ALREADY_EXISTS',
  SamePhoneFriendsAlreadyExists = 'SAME_PHONE_FRIENDS_ALREADY_EXISTS',
  /** 엠베서더를 찾을 수 없습니다. */
  AmbassadorNotFound = 'AMBASSADOR_NOT_FOUND',
  /** 유효하지 않은 엠베서더 ID 입니다. */
  InvalidAmbassadorId = 'INVALID_AMBASSADOR_ID',
  IamportGetTokenError = 'IAMPORT_GET_TOKEN_ERROR',
  IamportGetPaymentDataError = 'IAMPORT_GET_PAYMENT_DATA_ERROR',
  EscrowError = 'ESCROW_ERROR',
  EscrowTokenError = 'ESCROW_TOKEN_ERROR',
  EscrowShippingError = 'ESCROW_SHIPPING_ERROR',
  /** 배송정보를 찾을 수 없습니다. */
  ShippingNotFound = 'SHIPPING_NOT_FOUND',
  /** 이미 예상 배송정보가 등록되었습니다. */
  ShippingAlreadyRegisted = 'SHIPPING_ALREADY_REGISTED',
  /** 해당 MERCHATN_UID에 해당되는 거래건이 없습니다 */
  NoPaymentOfMerchantUid = 'NO_PAYMENT_OF_MERCHANT_UID',
  /** 거래 금액이 일치하지 않습니다. */
  DismatchPaymentAmount = 'DISMATCH_PAYMENT_AMOUNT',
  /** 에스크로 및 결제 input에 null인 값이 있습니다. */
  PaymentInputNonNullable = 'PAYMENT_INPUT_NON_NULLABLE',
  /** 에스크로 및 결제 input에 invalid값이 있습니다.  */
  PaymentInvalidInput = 'PAYMENT_INVALID_INPUT',
  /** 서브몰 미등록 파트너 */
  SubmallNotFound = 'SUBMALL_NOT_FOUND',
  /** 배송등록 불가능 - 가능한 스텝이 아님 */
  RegisterShippingDisableForStep = 'REGISTER_SHIPPING_DISABLE_FOR_STEP',
  /** 제조 시작이 불가능합니다. */
  CannotStartProcessing = 'CANNOT_START_PROCESSING',
  /** 아직 결제되지 않았습니다. */
  BeforePayment = 'BEFORE_PAYMENT',
  /** 사업자등록번호가 일치하지 않습니다. */
  CompanyRegistrationNumberMismatch = 'COMPANY_REGISTRATION_NUMBER_MISMATCH',
  /** 이미 등록된 서브몰이 있습니다. */
  AlreadySubmallExists = 'ALREADY_SUBMALL_EXISTS',
  /** 나이스페이 api not success */
  PaymentApiResultNotSuccess = 'PAYMENT_API_RESULT_NOT_SUCCESS',
  /** 나이스페이 api success */
  PaymentApiResultSuccess = 'PAYMENT_API_RESULT_SUCCESS',
  /** puchase order 상태 값으로 변경이 불가능 합니다 */
  InvalidStatusToUpdatePurchaseOrder = 'INVALID_STATUS_TO_UPDATE_PURCHASE_ORDER',
  /** cancel payment가 존재하지 않습니다 */
  CancelPaymentNotFound = 'CANCEL_PAYMENT_NOT_FOUND',
  /** PG사 정보와 CAPA DB가 매칭이 되지 않습니다. */
  DismatchPaymentInfo = 'DISMATCH_PAYMENT_INFO',
  /** 결제 정보를 찾을 수 없습니다. */
  PaymentNotFound = 'PAYMENT_NOT_FOUND',
  /** 이미 환불되었습니다. */
  AlreadyRefunded = 'ALREADY_REFUNDED',
  /** 환불 요청이 불가능합니다. */
  CannotRequestRefund = 'CANNOT_REQUEST_REFUND',
  /** 환불 계좌가 존재하지 않습니다 */
  RefundBankNotFound = 'REFUND_BANK_NOT_FOUND',
  /** 잘 못된 은행 코드입니다 */
  InvalidBankCode = 'INVALID_BANK_CODE',
  /** 잘 못된 pg 코드입니다 */
  InvalidPgProvider = 'INVALID_PG_PROVIDER',
  /** 파트너가 배송지 입력을 하지 않았습니다.  */
  ShippingNotRegistered = 'SHIPPING_NOT_REGISTERED',
  /** 이미 구매확정을 연기했습니다.  */
  AlreadyExtendedConfirmPurchase = 'ALREADY_EXTENDED_CONFIRM_PURCHASE',
  /** 아임포트 혹은 나이스페이 api 호출 실패 */
  PaymenyApiCallFail = 'PAYMENY_API_CALL_FAIL',
  /** 잘못된 결제 상태 */
  InvalidPaymentStatus = 'INVALID_PAYMENT_STATUS',
  /** 본인인증이 되지 않은 유저 */
  UnverifiedUser = 'UNVERIFIED_USER',
  /** 유효하지 않는 계좌 */
  InvalidAccount = 'INVALID_ACCOUNT',
  /** 예금주가 일치하지 않습니다. */
  AccountHolderMismatch = 'ACCOUNT_HOLDER_MISMATCH',
  /** 환불계좌 정보를 찾을수 없습니다. */
  NotFoundRefundAccount = 'NOT_FOUND_REFUND_ACCOUNT',
  /** 이미 거절된 서브몰입니다. */
  AlreadySubmallRejected = 'ALREADY_SUBMALL_REJECTED',
  /** 사업자 등록증이 존재하지 않습니다. */
  QuotationPartnerRegistrationNotFound = 'QUOTATION_PARTNER_REGISTRATION_NOT_FOUND',
  /** 통장 사본이 존재하지 않습니다. */
  QuotationPartnerBankbookNotFound = 'QUOTATION_PARTNER_BANKBOOK_NOT_FOUND',
  /** 이미 테이블에 등록된 결제 건 입니다. */
  AlreadyRegisteredPayment = 'ALREADY_REGISTERED_PAYMENT',
  CannotCancelPayment = 'CANNOT_CANCEL_PAYMENT',
  FailedIamportCancelRequest = 'FAILED_IAMPORT_CANCEL_REQUEST',
  InvalidQuotationCost = 'INVALID_QUOTATION_COST',
  /** 잘 못된 quotation */
  InvalidQuotation = 'INVALID_QUOTATION',
  /** deetailed quotation not found */
  DetailedQuotationNotFound = 'DETAILED_QUOTATION_NOT_FOUND',
  /** 이미 마감된 견적서입니다. */
  QuotationAlreadyClosed = 'QUOTATION_ALREADY_CLOSED',
  /** 타 업체에 발주됐습니다. */
  QuotationOtherCompanyOrder = 'QUOTATION_OTHER_COMPANY_ORDER',
  /** 최소 결제비용 충족을 못했습니다. 개발은 1,000 운영은 2,000 */
  NotMeetingMinimumCost = 'NOT_MEETING_MINIMUM_COST',
  /** 약관 동의가 필요합니다. */
  TermsNotAgreed = 'TERMS_NOT_AGREED',
  /** 최근 견적서로만 발주 가능합니다. */
  NotLatestQuotation = 'NOT_LATEST_QUOTATION',
  /** 마감된 견적 요청서입니다. */
  RfqExpired = 'RFQ_EXPIRED',
  /** 환불계좌정보가 이미 존재 합니다. */
  RefundBankInfoExists = 'REFUND_BANK_INFO_EXISTS'
}

export type CapaFetchAvailableFilterRfq = {
  __typename?: 'CAPAFetchAvailableFilterRfq';
  id: Scalars['String'];
  isFavorite?: Maybe<Scalars['Boolean']>;
  thumbnailId?: Maybe<Scalars['String']>;
  projectName: Scalars['String'];
  organizationName?: Maybe<Scalars['String']>;
  managerName?: Maybe<Scalars['String']>;
  shortAddress?: Maybe<Scalars['String']>;
  serviceType: CapaQuotationServiceType;
  quantity?: Maybe<Scalars['Float']>;
  uniqueViews?: Maybe<Scalars['Float']>;
  chatRooms?: Maybe<Scalars['Float']>;
  isNew: Scalars['Boolean'];
  isSendQuotation: Scalars['Boolean'];
  isSendIntroduction: Scalars['Boolean'];
  createdAt: Scalars['String'];
  rfqStatus: CapaRfqStatus;
  detailedServiceType?: Maybe<CapaQuotationDetailedServiceType>;
  pageViews?: Maybe<Scalars['Float']>;
  eventType?: Maybe<CapaQuotationRfqEventType>;
  isTest: Scalars['Boolean'];
  currentSituation?: Maybe<QuotationPartnerRfqCurrentSituationType>;
  rfqPurchaseOrderId?: Maybe<Scalars['String']>;
  rfqPurchaseOrderQuotationPartnerId?: Maybe<Scalars['String']>;
  rfqPurchaseOrderQuotationId?: Maybe<Scalars['String']>;
  purchaseOrderStatus?: Maybe<CapaPurchaseOrderStatus>;
  /** rfq 작성한 유저의 프로필 이미지 */
  userProfileId?: Maybe<Scalars['String']>;
  quotationPartnerId?: Maybe<Scalars['String']>;
  purchaseOrderQuotationPartnerId?: Maybe<Scalars['String']>;
};

export type CapaFetchAvailableFilterRfqs = {
  __typename?: 'CAPAFetchAvailableFilterRfqs';
  count: Scalars['Float'];
  rfqs?: Maybe<Array<CapaFetchAvailableFilterRfq>>;
};

export type CapaFetchAvailableFilterRfqsOutput = {
  __typename?: 'CAPAFetchAvailableFilterRfqsOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaFetchAvailableFilterRfqs>;
};

/** CAPAFetchQuotationPartnerProjectManagementDetail */
export type CapaFetchQuotationPartnerProjectManagementDetail = {
  __typename?: 'CAPAFetchQuotationPartnerProjectManagementDetail';
  /** 도면 관리 */
  drawingFile?: Maybe<CapaRfqPartnerManagementElement>;
  /** 견적서 */
  quotation?: Maybe<CapaRfqPartnerManagementElement>;
  /** 발주서 */
  purchaseOrder?: Maybe<CapaRfqPartnerManagementElement>;
};

/** CAPAFetchQuotationPartnerProjectManagementDetailOuput */
export type CapaFetchQuotationPartnerProjectManagementDetailOutput = {
  __typename?: 'CAPAFetchQuotationPartnerProjectManagementDetailOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaFetchQuotationPartnerProjectManagementDetail>;
};

export type CapaFetchQuotationPartnerProjectManagementRfqs = {
  __typename?: 'CAPAFetchQuotationPartnerProjectManagementRfqs';
  count: Scalars['Float'];
  managements: Array<CapaQuotationPartnerProjectManagement>;
};

/** CAPAFetchQuotationPartnerProjectManagementRfqsOutput */
export type CapaFetchQuotationPartnerProjectManagementRfqsOutput = {
  __typename?: 'CAPAFetchQuotationPartnerProjectManagementRfqsOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaFetchQuotationPartnerProjectManagementRfqs>;
};

/** CAPAFetchQuotationUserProjectManagementDetail */
export type CapaFetchQuotationUserProjectManagementDetail = {
  __typename?: 'CAPAFetchQuotationUserProjectManagementDetail';
  /** 도면 관리 */
  drawingFile?: Maybe<CapaRfqManagementElement>;
  /** 견적서 */
  quotation?: Maybe<CapaRfqManagementElement>;
  /** 발주서 */
  purchaseOrder?: Maybe<CapaRfqManagementElement>;
};

/** CAPARfqProjectManagementOutput */
export type CapaFetchQuotationUserProjectManagementDetailOuput = {
  __typename?: 'CAPAFetchQuotationUserProjectManagementDetailOuput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaFetchQuotationUserProjectManagementDetail>;
};

export type CapaFetchQuotationUserProjectManagementRfqs = {
  __typename?: 'CAPAFetchQuotationUserProjectManagementRfqs';
  count: Scalars['Float'];
  managements: Array<CapaQuotationUserProjectManagement>;
};

/** CAPAFetchQuotationUserProjectManagementListOutput */
export type CapaFetchQuotationUserProjectManagementRfqsOutput = {
  __typename?: 'CAPAFetchQuotationUserProjectManagementRfqsOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaFetchQuotationUserProjectManagementRfqs>;
};

export type CapaFetchRfqStatus = {
  __typename?: 'CAPAFetchRfqStatus';
  /** rfq 상태값 */
  rfqStatus: CapaRfqStatus;
};

export type CapaFile = {
  __typename?: 'CAPAFile';
  fileId: Scalars['ID'];
  fileName?: Maybe<Scalars['String']>;
  fileExtension?: Maybe<Scalars['String']>;
  fileMimetype?: Maybe<Scalars['String']>;
  /** 파일 용량 in bytes */
  fileSize?: Maybe<Scalars['Float']>;
  /** 파일 변환 상태 */
  status?: Maybe<CapaFileConvertStatus>;
  /** old File System id */
  oldFileId?: Maybe<Scalars['String']>;
};

/** 도면 파일 변환 처리 상태 */
export enum CapaFileConvertStatus {
  /** 변환요청 */
  Processing = 'PROCESSING',
  /** 큐등록 */
  Queued = 'QUEUED',
  /** 변환중 */
  Converting = 'CONVERTING',
  /** 변환완료 */
  Converted = 'CONVERTED',
  /** 변환실패 */
  Failed = 'FAILED'
}

export type CapaFileStatus = {
  __typename?: 'CAPAFileStatus';
  fileId: Scalars['ID'];
  /** 파일 변환 상태 */
  status?: Maybe<CapaFileConvertStatus>;
};

export type CapaFileStatusOutput = {
  __typename?: 'CAPAFileStatusOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaFileStatus>;
};

/** CAPAFilterableServiceType */
export type CapaFilterableServiceType = {
  cnc?: Maybe<CapaServiceTypeDetailFilter>;
  printing?: Maybe<CapaServiceTypeDetailFilter>;
  sheetMetal?: Maybe<CapaServiceTypeDetailFilter>;
  inj?: Maybe<CapaServiceTypeDetailFilter>;
  casting?: Maybe<CapaServiceTypeDetailFilter>;
  mechanicalDesign?: Maybe<CapaServiceTypeDetailFilter>;
  productDesign?: Maybe<CapaServiceTypeDetailFilter>;
  design?: Maybe<CapaServiceTypeDetailFilter>;
  pcb?: Maybe<CapaServiceTypeDetailFilter>;
};

/** 캐파 견적 파트너가 보는 채팅방룸 */
export type CapaInProgressQuotationPartnerChatRoom = {
  __typename?: 'CAPAInProgressQuotationPartnerChatRoom';
  organizationName?: Maybe<Scalars['String']>;
  quotationUserStatus: CapaQuotationUserStatus;
  managerName?: Maybe<Scalars['String']>;
  projectName: Scalars['String'];
  rfqId: Scalars['String'];
  chatRoomId: Scalars['String'];
  quotaionUserId: Scalars['String'];
  chatRoomStatus: CapaQuotationChatRoomStatus;
  createdAt?: Maybe<Scalars['String']>;
  serviceType: CapaQuotationServiceType;
  /** 수량 */
  quantity?: Maybe<Scalars['Float']>;
  /** detailedServiceType */
  detailedServiceType?: Maybe<Array<CapaQuotationDetailedServiceType>>;
  thumbnailId?: Maybe<Scalars['String']>;
  status: CapaRfqConsultingChatStatusType;
};

export type CapaInProgressQuotationPartnerChatRooms = {
  __typename?: 'CAPAInProgressQuotationPartnerChatRooms';
  count: Scalars['Float'];
  chatRooms?: Maybe<Array<CapaInProgressQuotationPartnerChatRoom>>;
};

/** 캐파 견적 파트너가 보는 채팅방 chatRooms Output */
export type CapaInProgressQuotationPartnerChatRoomsOutput = {
  __typename?: 'CAPAInProgressQuotationPartnerChatRoomsOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaInProgressQuotationPartnerChatRooms>;
};

/** 유입경로 */
export enum CapaInflowRoute {
  /** SNS 광고(인스타, 페이스북 등) */
  Sns = 'SNS',
  /** 인터넷 검색 */
  Internet = 'INTERNET',
  /** 크리에이터블 홈페이지 및 블로그 */
  Creatable = 'CREATABLE',
  /** 지인 및 기관 추천 */
  Recommendation = 'RECOMMENDATION',
  /** 홍보 기사 */
  Article = 'ARTICLE',
  /** 이메일 광고 */
  Email = 'EMAIL',
  /** 전화 광고 */
  Phone = 'PHONE',
  /** 문자 광고 */
  Sms = 'SMS',
  /** 카페 등 커뮤니티 */
  Community = 'COMMUNITY',
  /** 오프라인 영업 */
  Offline = 'OFFLINE',
  /** 지면 광고 (우편, 브로셔 등) */
  Paper = 'PAPER',
  /** 서비스설명회 (온라인포함) */
  Presentation = 'PRESENTATION',
  /** 인터넷 광고(구글/네이버 등/0 */
  InternetAdd = 'INTERNET_ADD',
  /** 카파 블로그 */
  CapaBlog = 'CAPA_BLOG',
  /** 언론 보도 */
  MediaReport = 'MEDIA_REPORT',
  /** 우편물 */
  Dm = 'DM',
  /** 홍보 현수막 */
  Placard = 'PLACARD',
  /** 기타 */
  Etc = 'ETC'
}

/** ITP 이벤트 신청 목록 BO */
export type CapaItpEventBo = {
  __typename?: 'CAPAItpEventBO';
  /** ITP 이벤트 아이디 */
  id?: Maybe<Scalars['ID']>;
  /** ITP RFQ 프로젝트 이름 */
  projectName?: Maybe<Scalars['String']>;
  /** ITP RFQ QUOTATION USER 아이디 */
  quotationUserId?: Maybe<Scalars['String']>;
  /** ITP RFQ QUOTATION USER 고객 이름 */
  quotationUserName?: Maybe<Scalars['String']>;
  /** ITP RFQ 매칭 파트너 이름 */
  quotationPartnerOrganizationName?: Maybe<Scalars['String']>;
  /** ITP RFQ 거래 완료 금액 */
  completeCost?: Maybe<Scalars['String']>;
  /** ITP 이벤트 증빙자료 */
  files?: Maybe<Array<CapaFile>>;
  /** ITP RFQ 고객 이메일 */
  email?: Maybe<Scalars['String']>;
  /** ITP RFQ 고객 전화번호 */
  cellphoneNumber?: Maybe<Scalars['String']>;
  /** ITP 이벤트 신청 승인 날짜 */
  approvedAt?: Maybe<Scalars['String']>;
  /** ITP 이벤트 신청 승인 BO 아이디 */
  approvedById?: Maybe<Scalars['String']>;
  /** ITP 이벤트 신청 승인 BO 이름 */
  approvedByName?: Maybe<Scalars['String']>;
  /** ITP 이벤트 officecon에 보낸 날짜 */
  sentAt?: Maybe<Scalars['String']>;
  /** ITP 이벤트 신청 승인 BO 아이디 */
  sentById?: Maybe<Scalars['String']>;
  /** ITP 이벤트 신청 승인 BO 이름 */
  sentByName?: Maybe<Scalars['String']>;
};

/** ITP 이벤트 증빙용 파일 */
export type CapaItpEventFile = {
  __typename?: 'CAPAItpEventFile';
  /** ITP 이벤트 증빙 파일 아이디 */
  fileId?: Maybe<Scalars['ID']>;
  /** 파일 이름 */
  fileName?: Maybe<Scalars['String']>;
};

/** ITP 이벤트 목록 표출 종류 */
export enum CapaItpEventListTypeEnum {
  /** 전체 목록 표출 */
  All = 'ALL',
  /** 확인전 목록 표출 */
  Ready = 'READY',
  /** 승인 ITP 이벤트 신청 목록 표출 */
  Approved = 'APPROVED',
  /** 거절 ITP 이벤트 신청 목록 표출 */
  Reject = 'REJECT',
  /** 전송완료 ITP 이벤트 신청 목록 표출 */
  Sent = 'SENT'
}

/** ITP 이벤트 신청 목록 BO */
export type CapaItpEventXlsxDownload = {
  __typename?: 'CAPAItpEventXlsxDownload';
  /** ITP 이벤트 아이디 */
  id?: Maybe<Scalars['ID']>;
  /** ITP RFQ 고객 이메일 */
  email?: Maybe<Scalars['String']>;
  /** ITP RFQ 고객 전화번호 */
  cellphoneNumber?: Maybe<Scalars['String']>;
};

export type CapaItpEventsBoOutput = {
  __typename?: 'CAPAItpEventsBOOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<Array<CapaItpEventBo>>;
};

/** Jsb 이벤트 신청 목록 BO */
export type CapaJsbEventBo = {
  __typename?: 'CAPAJsbEventBO';
  /** Jsb 이벤트 아이디 */
  id?: Maybe<Scalars['ID']>;
  /** Jsb RFQ 프로젝트 이름 */
  projectName?: Maybe<Scalars['String']>;
  /** Jsb RFQ QUOTATION USER 아이디 */
  quotationUserId?: Maybe<Scalars['String']>;
  /** Jsb RFQ QUOTATION USER 고객 이름 */
  quotationUserName?: Maybe<Scalars['String']>;
  /** Jsb RFQ 매칭 파트너 이름 */
  quotationPartnerOrganizationName?: Maybe<Scalars['String']>;
  /** Jsb RFQ 거래 완료 금액 */
  completeCost?: Maybe<Scalars['String']>;
  /** Jsb 은행 */
  bank?: Maybe<Scalars['String']>;
  /** Jsb 계좌 */
  account?: Maybe<Scalars['String']>;
  /** Jsb 이벤트 증빙자료 */
  files?: Maybe<Array<CapaFile>>;
  /** Jsb RFQ 고객 이메일 */
  email?: Maybe<Scalars['String']>;
  /** Jsb RFQ 고객 전화번호 */
  cellphoneNumber?: Maybe<Scalars['String']>;
  /** Jsb 이벤트 신청 승인 날짜 */
  approvedAt?: Maybe<Scalars['String']>;
  /** Jsb 이벤트 신청 승인 BO 아이디 */
  approvedById?: Maybe<Scalars['String']>;
  /** Jsb 이벤트 신청 승인 BO 이름 */
  approvedByName?: Maybe<Scalars['String']>;
};

/** JSB 이벤트 증빙용 파일 */
export type CapaJsbEventFile = {
  __typename?: 'CAPAJsbEventFile';
  /** JSB 이벤트 증빙 파일 아이디 */
  fileId?: Maybe<Scalars['ID']>;
  /** 파일 이름 */
  fileName?: Maybe<Scalars['String']>;
};

/** JSB 이벤트 목록 표출 종류 */
export enum CapaJsbEventListTypeEnum {
  /** 전체 목록 표출 */
  All = 'ALL',
  /** 확인전 목록 표출 */
  Ready = 'READY',
  /** 승인 JSB 이벤트 신청 목록 표출 */
  Approved = 'APPROVED',
  /** 거절 JSB 이벤트 신청 목록 표출 */
  Reject = 'REJECT'
}

/** JSB 이벤트 신청 목록 BO */
export type CapaJsbEventXlsxDownload = {
  __typename?: 'CAPAJsbEventXlsxDownload';
  /** JSB 이벤트 아이디 */
  id?: Maybe<Scalars['ID']>;
  /** JSB RFQ 고객 이메일 */
  email?: Maybe<Scalars['String']>;
  /** JSB RFQ 고객 전화번호 */
  cellphoneNumber?: Maybe<Scalars['String']>;
  /** JSB RFQ 고객 은행 */
  bank?: Maybe<Scalars['String']>;
  /** JSB RFQ 고객 계좌 */
  account?: Maybe<Scalars['String']>;
};

export type CapaJsbEventsBoOutput = {
  __typename?: 'CAPAJsbEventsBOOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<Array<CapaJsbEventBo>>;
};

/** 내드리이브 */
export type CapaMyDrive = {
  __typename?: 'CAPAMyDrive';
  /** 파일 ID */
  fileId: Scalars['ID'];
  /** 파일명 */
  fileName: Scalars['String'];
  /** 파일크기 byte */
  fileSize: Scalars['Float'];
  /** 파일 변환 상태 */
  status?: Maybe<CapaFileConvertStatus>;
  /** 썸네일 */
  thumbnails?: Maybe<Scalars['String']>;
  /** 도면 파일 뷰어 URL */
  viewerUrl?: Maybe<Scalars['String']>;
  /**
   * Legacy 파일 ID
   * @deprecated Legacy
   */
  oldFileId?: Maybe<Scalars['String']>;
  /** 생성일 (업로드 일시) */
  createdAt: Scalars['String'];
  /** 수정일 */
  updatedAt: Scalars['String'];
};

/** 내드리이브 */
export type CapaMyDrivesOutput = {
  __typename?: 'CAPAMyDrivesOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<Array<CapaMyDrive>>;
};

export type CapaNewChatMessage = {
  __typename?: 'CAPANewChatMessage';
  id?: Maybe<Scalars['ID']>;
};

export type CapaNotification = {
  __typename?: 'CAPANotification';
  seq: Scalars['Float'];
  serviceType: CapaServiceType;
  data?: Maybe<CapaNotificationUnion>;
  read?: Maybe<Scalars['Boolean']>;
  /** 생성일 (업로드 일시) */
  createdAt: Scalars['String'];
};

export type CapaNotificationConnect = {
  __typename?: 'CAPANotificationConnect';
  notificationType: CapaNotificationType;
  groupId?: Maybe<Scalars['String']>;
  groupName?: Maybe<Scalars['String']>;
  chatRoomId?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  groupUserId?: Maybe<Scalars['String']>;
  accessLevel?: Maybe<CapaConnectGroupAccessLevel>;
  fileName?: Maybe<Scalars['String']>;
  connectFileId?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  oldGroupName?: Maybe<Scalars['String']>;
  annotationId?: Maybe<Scalars['String']>;
  commentId?: Maybe<Scalars['String']>;
  toBeShown?: Maybe<Scalars['String']>;
};

export type CapaNotificationDefault = {
  __typename?: 'CAPANotificationDefault';
  notificationType: CapaNotificationType;
  chatRoomId?: Maybe<Scalars['String']>;
};

export type CapaNotificationRfq = {
  __typename?: 'CAPANotificationRfq';
  notificationType: CapaNotificationType;
  serviceType?: Maybe<CapaQuotationServiceType>;
  rfqId?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  adjustRequest?: Maybe<Scalars['String']>;
  totalAmount?: Maybe<Scalars['String']>;
  managerName?: Maybe<Scalars['String']>;
  chatRoomId?: Maybe<Scalars['String']>;
  /** 환불 금액 */
  refundAmt?: Maybe<Scalars['Float']>;
  /** 정산 완료 금액 */
  depositAmt?: Maybe<Scalars['Float']>;
  /** 예상 지급승인(구매확정)날짜  */
  expectedConfirmPurchaseDate?: Maybe<Scalars['DateTime']>;
};

export enum CapaNotificationType {
  AllowRequest = 'ALLOW_REQUEST',
  AllowResponse = 'ALLOW_RESPONSE',
  Announcement = 'ANNOUNCEMENT',
  EventInvitation = 'EVENT_INVITATION',
  EventRfqConfirm = 'EVENT_RFQ_CONFIRM',
  ItpEventRequestAvailable = 'ITP_EVENT_REQUEST_AVAILABLE',
  RfqComplete = 'RFQ_COMPLETE',
  RfqCompleteRequest = 'RFQ_COMPLETE_REQUEST',
  RfqConfirm = 'RFQ_CONFIRM',
  RfqConfirmRequest = 'RFQ_CONFIRM_REQUEST',
  RfqExpiration = 'RFQ_EXPIRATION',
  RfqExpirationConfirm = 'RFQ_EXPIRATION_CONFIRM',
  RfqExpirationClient = 'RFQ_EXPIRATION_CLIENT',
  RfqExpirationWarning = 'RFQ_EXPIRATION_WARNING',
  RfqRegistered = 'RFQ_REGISTERED',
  RfqReviewDone = 'RFQ_REVIEW_DONE',
  RfqReviewReplyDone = 'RFQ_REVIEW_REPLY_DONE',
  RfqDownloadAccess = 'RFQ_DOWNLOAD_ACCESS',
  RfqDownloadGranted = 'RFQ_DOWNLOAD_GRANTED',
  RfqAdjustQuotation = 'RFQ_ADJUST_QUOTATION',
  RfqPurchaseOrder = 'RFQ_PURCHASE_ORDER',
  RfqRequestQuotation = 'RFQ_REQUEST_QUOTATION',
  QuotationChatRoomStatusOpen = 'QUOTATION_CHAT_ROOM_STATUS_OPEN',
  QuotationChatMessage = 'QUOTATION_CHAT_MESSAGE',
  StampEvent = 'STAMP_EVENT',
  StampInvitationEvent = 'STAMP_INVITATION_EVENT',
  StampWelcomeEvent = 'STAMP_WELCOME_EVENT',
  ConnectInviteUser = 'CONNECT_INVITE_USER',
  ConnectLeaveGroup = 'CONNECT_LEAVE_GROUP',
  ConnectChangeGroupName = 'CONNECT_CHANGE_GROUP_NAME',
  ConnectAccessChange = 'CONNECT_ACCESS_CHANGE',
  ConnectRemoveFromGroup = 'CONNECT_REMOVE_FROM_GROUP',
  ConnectDeleteGroup = 'CONNECT_DELETE_GROUP',
  ConnectDownloadAccess = 'CONNECT_DOWNLOAD_ACCESS',
  ConnectDownloadGranted = 'CONNECT_DOWNLOAD_GRANTED',
  ConnectFileShared = 'CONNECT_FILE_SHARED',
  ConnectAnnotationComment = 'CONNECT_ANNOTATION_COMMENT',
  ConnectNewChat = 'CONNECT_NEW_CHAT',
  PurchaseOrderRegisterRequest = 'PURCHASE_ORDER_REGISTER_REQUEST',
  AwaitingPayment = 'AWAITING_PAYMENT',
  NotPaidCanceled = 'NOT_PAID_CANCELED',
  RejectRfqPurchaseOrderVirtualAccount = 'REJECT_RFQ_PURCHASE_ORDER_VIRTUAL_ACCOUNT',
  RejectRfqPurchaseOrderCreditCard = 'REJECT_RFQ_PURCHASE_ORDER_CREDIT_CARD',
  CancelConfirmedVirtualAccount = 'CANCEL_CONFIRMED_VIRTUAL_ACCOUNT',
  CancelConfirmedCreditCard = 'CANCEL_CONFIRMED_CREDIT_CARD',
  RefundConfirmedVirtualAccount = 'REFUND_CONFIRMED_VIRTUAL_ACCOUNT',
  RefundConfirmedCreditCard = 'REFUND_CONFIRMED_CREDIT_CARD',
  InduceConfirmPurchase = 'INDUCE_CONFIRM_PURCHASE',
  ApprovedPayment = 'APPROVED_PAYMENT',
  CancelRequested = 'CANCEL_REQUESTED',
  CancelConfirmed = 'CANCEL_CONFIRMED',
  RefundRequested = 'REFUND_REQUESTED',
  RefundConfirmed = 'REFUND_CONFIRMED',
  DelayedConfirmPurchase = 'DELAYED_CONFIRM_PURCHASE',
  StartProcessing = 'START_PROCESSING',
  StartShipping = 'START_SHIPPING',
  PurchaseConfirmed = 'PURCHASE_CONFIRMED'
}

export type CapaNotificationUnion =
  | CapaNotificationDefault
  | CapaNotificationRfq
  | CapaNotificationConnect;

export type CapaNotifications = {
  __typename?: 'CAPANotifications';
  count: Scalars['Float'];
  notifications: Array<CapaNotification>;
};

export type CapaNotificationsOutput = {
  __typename?: 'CAPANotificationsOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaNotifications>;
};

/** Payment PG providers */
export enum CapapgProviders {
  Nicepay = 'NICEPAY',
  TestMock = 'TEST_MOCK'
}

export type CapaPartnerBo = {
  __typename?: 'CAPAPartnerBO';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  capabilities?: Maybe<CapaQuotationPartnerCapability>;
  managerName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  contact?: Maybe<Scalars['String']>;
  registrationFileId?: Maybe<Scalars['String']>;
  consultingCount?: Maybe<Scalars['Float']>;
  /** 견적서로 제안 수 */
  quotationSuggestionCount?: Maybe<Scalars['Float']>;
  /** 소개서로 제안 수 */
  introductionSuggestionCount?: Maybe<Scalars['Float']>;
  /** 견적 수 = 상담 시작 방식 및 상담 진행 여부 상관 없이 견적서 1회 이상 발송한 프로젝트 수 */
  rfqReceivedQuotationCount?: Maybe<Scalars['Float']>;
  /** 안전 결제 수 */
  paymentCount?: Maybe<Scalars['Float']>;
  lastLoginAt?: Maybe<Scalars['String']>;
  quotationPartnerStatus?: Maybe<CapaQuotationPartnerStatus>;
  submallStatus?: Maybe<CapaSubmallStatus>;
  route?: Maybe<Array<CapaInflowRoute>>;
  /** 추천 기관명/회사명 */
  referralCompany?: Maybe<Scalars['String']>;
};

export type CapaPartnersAndCount = {
  __typename?: 'CAPAPartnersAndCount';
  partners?: Maybe<Array<CapaPartnerBo>>;
  count?: Maybe<Scalars['Float']>;
};

export type CapaPartnersBoOutput = {
  __typename?: 'CAPAPartnersBOOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaPartnersAndCount>;
  count?: Maybe<Scalars['Float']>;
};

export type CapaPaymentAccountHolderModel = {
  __typename?: 'CAPAPaymentAccountHolderModel';
  /** 예금주 */
  accountHolder?: Maybe<Scalars['String']>;
};

/** 결제 취소 status */
export enum CapaPaymentCancelStatus {
  /** 가상계좌 미입금 취소 */
  NotPaidCanceled = 'NOT_PAID_CANCELED',
  /** 결제 취소 요청 */
  CancelRequested = 'CANCEL_REQUESTED',
  /** 결제 취소 완료 */
  CancelConfirmed = 'CANCEL_CONFIRMED',
  /** 발주서 거부로 인한 결제 취소 */
  RejectOrderByPartner = 'REJECT_ORDER_BY_PARTNER',
  /** 환불 요청 (파트너 승인 전) */
  RefundRequested = 'REFUND_REQUESTED',
  /** 환불 승인 (파트너 승인은 완료되었지만, 실제로 고객에게 아직 환불 이루어지지 않은 경우) */
  RefundConfirmed = 'REFUND_CONFIRMED',
  /** BO 취소 승인 통보 */
  CancelConfirmedBo = 'CANCEL_CONFIRMED_BO',
  /** BO 환불 승인 통보 */
  RefundConfirmedBo = 'REFUND_CONFIRMED_BO',
  /** BO 계속 진행 통보 */
  NotifyProgressBo = 'NOTIFY_PROGRESS_BO',
  /** 결제 취소 실패 */
  Failed = 'FAILED'
}

export type CapaPaymentCardInfo = {
  __typename?: 'CAPAPaymentCardInfo';
  /** 카드사 코드번호(금융결제원 표준코드번호) */
  cardCode: Scalars['String'];
  /** 카드사 명칭 - (신용카드 결제 건의 경우) */
  cardName: Scalars['String'];
  /** 할부개월 수(0이면 일시불) */
  cardQuota: Scalars['Float'];
  /** 결제에 사용된 마스킹된 카드번호. 7~12번째 자리를 마스킹하는 것이 일반적이지만, PG사의 정책/설정에 따라 다소 차이가 있을 수 있음 */
  cardNumber?: Maybe<Scalars['String']>;
  /** 카드유형 */
  cardType: Scalars['Float'];
};

export type CapaPaymentFetchDepositModel = {
  __typename?: 'CAPAPaymentFetchDepositModel';
  transactionIds: Array<Scalars['String']>;
};

export enum CapaPaymentMethodType {
  /** 신용카드 */
  CreditCard = 'CREDIT_CARD',
  /** 가상계좌 */
  VirtualAccount = 'VIRTUAL_ACCOUNT',
  /** 포인트 */
  Point = 'POINT'
}

/** Payment status */
export enum CapaPaymentStatus {
  /** 결제 대기(가상계좌 발급 후 입금 대기, 소액결제) */
  Ready = 'READY',
  /** 결제완료(신용계좌 결제 완료, 가상계좌 입금 완료) */
  Paid = 'PAID',
  /** 배송지 등록 완료 - 실제 api */
  Delivered = 'DELIVERED',
  PurchaseConfirmed = 'PURCHASE_CONFIRMED',
  /** 정산 완료 */
  Settled = 'SETTLED',
  /** 나이스페이 콘솔에서 취소 */
  Cancelled = 'CANCELLED',
  /** 뭔가 좋지 않을 일... ex) 가상계좌를 발급했지만, 입금을 하지 않아 실패 처리되는 경우 */
  Failed = 'FAILED',
  /** 취소 실패 (파트너의 승인아래 환불하고자 했는데, 실패하는 경우) */
  CancelFailed = 'CANCEL_FAILED',
  /** 환불 성공 */
  Refunded = 'REFUNDED',
  /** 지급 대기중  */
  PayoutPending = 'PAYOUT_PENDING',
  /** 지급 대행 완료 */
  PayoutCompleted = 'PAYOUT_COMPLETED',
  /** 고객에게 포인트 지급 완료 */
  PayoutPointCompleted = 'PAYOUT_POINT_COMPLETED'
}

export type CapaPcbDetail = {
  __typename?: 'CAPAPcbDetail';
  /** 요청 작업 */
  services?: Maybe<Array<CapaPcbService>>;
  /** 제작 파일 */
  fabricationFiles?: Maybe<Array<CapaQuotationRfqFabricationFile>>;
  /** 조립 파일 */
  assemblyFiles?: Maybe<Array<CapaQuotationRfqAssemblyFile>>;
  /** 제작 수량 */
  fabricationQuantity?: Maybe<Scalars['Float']>;
  /** 조립 수량 */
  assemblyQuantity?: Maybe<Scalars['Float']>;
  /** 조립 방식 */
  assemblyMethod?: Maybe<Array<CapaRfqPcbassemblyMethod>>;
  /** 메탈마스크 제공 여부 */
  metalMaskAvailablity?: Maybe<Scalars['Boolean']>;
  /** 자재 방법 */
  materialSupplyMethod?: Maybe<CapaRfqPcbMaterialSupplyMethodType>;
};

export type CapaPcbService = {
  __typename?: 'CAPAPcbService';
  serviceType?: Maybe<CapaPcbServiceType>;
};

/** pcb service type */
export enum CapaPcbServiceType {
  /** 설계 */
  CircuitDesign = 'CIRCUIT_DESIGN',
  /** 제작 */
  CircuitFabrication = 'CIRCUIT_FABRICATION',
  /** 조립 */
  CircuitAssembly = 'CIRCUIT_ASSEMBLY'
}

export type CapaProjectManagementReceivedQuotation = {
  __typename?: 'CAPAProjectManagementReceivedQuotation';
  rfqId?: Maybe<Scalars['String']>;
  quotationId?: Maybe<Scalars['String']>;
  quotationType?: Maybe<CapaRfqQuotationType>;
  partnerId?: Maybe<Scalars['String']>;
  quotationPartnerStatus: CapaQuotationPartnerStatus;
  chatRoomId?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  confirmCount?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  deliveryCost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['String']>;
  read?: Maybe<Scalars['Boolean']>;
  isAdjusted?: Maybe<Scalars['Boolean']>;
};

export type CapaProjectManagementReceivedQuotationOutput = {
  __typename?: 'CAPAProjectManagementReceivedQuotationOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<Array<CapaProjectManagementReceivedQuotation>>;
};

/** equipmentRfqfilter 정보 입력 */
export type CapaPromotionLinkInput = {
  capaBlogLink: Scalars['String'];
};

export type CapaPromotionModel = {
  __typename?: 'CAPAPromotionModel';
  id?: Maybe<Scalars['String']>;
  quotationPartnerId: Scalars['String'];
  capaBlogLink: Scalars['String'];
  status: CapaPromotionStatus;
};

export enum CapaPromotionStatus {
  Registered = 'REGISTERED',
  Deleted = 'DELETED'
}

/** CAPAPurchaseOrderModel */
export type CapaPurchaseOrderModel = {
  __typename?: 'CAPAPurchaseOrderModel';
  purchaseOrderId?: Maybe<Scalars['String']>;
  purchaseOrderStatus: CapaPurchaseOrderStatus;
  partnerOrganizationName?: Maybe<Scalars['String']>;
  partnerManagerName?: Maybe<Scalars['String']>;
  partnerFullAddress?: Maybe<Scalars['String']>;
  partnerLogoFileId?: Maybe<Scalars['String']>;
  clientOrganizationName?: Maybe<Scalars['String']>;
  clientFullAddress?: Maybe<Scalars['String']>;
  clientLogoFileId?: Maybe<Scalars['String']>;
  clientManagerName?: Maybe<Scalars['String']>;
  /** 기획에서 해당 enum과 같이 요청하는 태그 표현 */
  projectPurchaseOrderManageStatusType?: Maybe<ProjectPurchaseOrderManageStatusType>;
  paymentManageModelList?: Maybe<Array<CapaQuotationPaymentManageModel>>;
  /** rfq 생성일 */
  rfqCreatedAt?: Maybe<Scalars['String']>;
  /** rfq 서비스 */
  serviceType: CapaQuotationServiceType;
  /** rfq 프로젝트 이름 */
  projectName?: Maybe<Scalars['String']>;
  /** 제조 수량 */
  quantity?: Maybe<Scalars['Float']>;
};

/** CAPA PURCHASE ORDER STATUS */
export enum CapaPurchaseOrderStatus {
  /** 결제 도입 전 발주서 */
  Created = 'CREATED',
  /** 결제대기(가상계좌 발급 후 입금 대기, 소액결제) */
  ProcessPayment = 'PROCESS_PAYMENT',
  /** 결제완료(신용계좌 결제 완료, 가상계좌 입금 완료) */
  Paid = 'PAID',
  /** 가상계좌 미입금 취소 */
  NotPaidCanceled = 'NOT_PAID_CANCELED',
  /** 제조중 */
  Processing = 'PROCESSING',
  /** 배송중, capa에서 취급하는 배송중 */
  Shipping = 'SHIPPING',
  /** 구매확정 */
  PurchaseConfirmed = 'PURCHASE_CONFIRMED',
  /** 취소 요청 (제조시작 전) */
  CancelRequested = 'CANCEL_REQUESTED',
  /** 취소 확정 (제조시작 전) */
  CancelConfirmed = 'CANCEL_CONFIRMED',
  /** 환불요청 (제조시작 후) */
  RefundRequested = 'REFUND_REQUESTED',
  /** 파트너와 고객 양방 승인하에 환불확정 (제조시작 후) */
  RefundConfirmed = 'REFUND_CONFIRMED',
  /** 파트너 일방 환불 (제조시작 전) */
  RejectOrderByPartner = 'REJECT_ORDER_BY_PARTNER',
  /** 관리자 콘솔에서 취소 */
  CancelledByAdmin = 'CANCELLED_BY_ADMIN',
  /** 발주서가 처음에 생성되었을때 가지는 기본값.백엔드 내부에서 사용되는 상태값으로, 유저에게는 해당 상태를 가진 발주서가 노출되지 않습니다. */
  PaymentFailed = 'PAYMENT_FAILED'
}

export type CapaQuotatationAnnotation = {
  __typename?: 'CAPAQuotatationAnnotation';
  id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  chatRoomId?: Maybe<Scalars['String']>;
  quotationSharedFileId?: Maybe<Scalars['String']>;
  anchorPos?: Maybe<Capa3DPos>;
  handlePos?: Maybe<Capa3DPos>;
  nodeId?: Maybe<Scalars['Float']>;
  comments?: Maybe<Array<CapaQuotationAnnotationComment>>;
  isNew?: Maybe<Scalars['Boolean']>;
  status?: Maybe<CapaQuotationSharedFileAnnotationStatus>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type CapaQuotationActivity = {
  __typename?: 'CAPAQuotationActivity';
  confirmed?: Maybe<Scalars['Float']>;
  complete?: Maybe<Scalars['Float']>;
  reviewed?: Maybe<Scalars['Float']>;
};

export type CapaQuotationActivityOutput = {
  __typename?: 'CAPAQuotationActivityOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaQuotationActivity>;
};

export type CapaQuotationAnnotationComment = {
  __typename?: 'CAPAQuotationAnnotationComment';
  /** commentId */
  id?: Maybe<Scalars['String']>;
  /** annotationId */
  annotationId?: Maybe<Scalars['String']>;
  /** capaFileId */
  fileId?: Maybe<Scalars['String']>;
  edited?: Maybe<Scalars['Boolean']>;
  status?: Maybe<CapaQuotationSharedFileAnnotationStatus>;
  userId?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  isParent?: Maybe<Scalars['Boolean']>;
  userName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type CapaQuotationAnnotationCommentMessage = {
  __typename?: 'CAPAQuotationAnnotationCommentMessage';
  messageType: CapaQuotationChatMessageType;
  annotation?: Maybe<CapaQuotationAnnotationComment>;
};

export type CapaQuotationAnnotationOutput = {
  __typename?: 'CAPAQuotationAnnotationOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaQuotatationAnnotation>;
};

export type CapaQuotationBypassMessage = {
  __typename?: 'CAPAQuotationBypassMessage';
  messageType: CapaQuotationChatMessageType;
  bypass?: Maybe<CapaQuotationBypassSendMessageTemplateModel>;
};

export type CapaQuotationBypassMessageDeleteMessage = {
  __typename?: 'CAPAQuotationBypassMessageDeleteMessage';
  messageType: CapaQuotationChatMessageType;
  /** chat room message id */
  id?: Maybe<Scalars['String']>;
};

export type CapaQuotationBypassSendMessageTemplateModel = {
  __typename?: 'CAPAQuotationBypassSendMessageTemplateModel';
  /** chat room message id */
  id?: Maybe<Scalars['String']>;
};

/** Capability 정보 입력 */
export type CapaQuotationCapabilityInput = {
  /** 캐파 견적 서비스 정보 입력 */
  services?: Maybe<Array<CapaQuotationServiceInput>>;
};

/** capability notification filter */
export type CapaQuotationCapabilityNotificationFilter = {
  __typename?: 'CAPAQuotationCapabilityNotificationFilter';
  email?: Maybe<Scalars['Boolean']>;
  sms?: Maybe<Scalars['Boolean']>;
  push?: Maybe<Scalars['Boolean']>;
  service?: Maybe<CapaQuotationServiceType>;
  detailedServices?: Maybe<Array<CapaQuotationDetailedServiceType>>;
  optionalServices?: Maybe<Array<CapaQuotationServiceType>>;
};

/** rfq notification for selected capability */
export type CapaQuotationCapabilityNotificationFilterInput = {
  /** 이메일 수신 여부 */
  email?: Maybe<Scalars['Boolean']>;
  /** 카카오 (실패시 SMS) 수신 여부 */
  sms?: Maybe<Scalars['Boolean']>;
  /** Push Notification 수신 여부 */
  push?: Maybe<Scalars['Boolean']>;
  service?: Maybe<CapaQuotationServiceType>;
  detailedServices?: Maybe<Array<CapaQuotationDetailedServiceType>>;
  optionalServices?: Maybe<Array<CapaQuotationServiceType>>;
};

export type CapaQuotationChatFile = {
  __typename?: 'CAPAQuotationChatFile';
  fileId?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  fileSize?: Maybe<Scalars['Float']>;
};

export type CapaQuotationChatMessage = {
  __typename?: 'CAPAQuotationChatMessage';
  id?: Maybe<Scalars['ID']>;
  senderId?: Maybe<Scalars['String']>;
  senderUserType?: Maybe<CapaQuotationUserType>;
  organizationName?: Maybe<Scalars['String']>;
  managerName?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  message?: Maybe<CapaQuotationChatMessageUnion>;
  /** 안읽은수 */
  unReadCount?: Maybe<Scalars['Float']>;
  chatFiles?: Maybe<Array<CapaQuotationChatFile>>;
  /** 생성일 */
  createdAt?: Maybe<Scalars['String']>;
  /** 상태값 */
  status?: Maybe<Scalars['String']>;
};

export type CapaQuotationChatMessageDetailedQuotation = {
  __typename?: 'CAPAQuotationChatMessageDetailedQuotation';
  messageType: CapaQuotationChatMessageType;
  quotation?: Maybe<CapaQuotationDetailedQuotation>;
};

export type CapaQuotationChatMessageMessage = {
  __typename?: 'CAPAQuotationChatMessageMessage';
  messageType: CapaQuotationChatMessageType;
  message?: Maybe<Scalars['String']>;
};

export type CapaQuotationChatMessageRfq = {
  __typename?: 'CAPAQuotationChatMessageRfq';
  messageType: CapaQuotationChatMessageType;
  rfq?: Maybe<CapaQuotationChatRfq>;
};

export type CapaQuotationChatMessageSimpleQuotation = {
  __typename?: 'CAPAQuotationChatMessageSimpleQuotation';
  messageType: CapaQuotationChatMessageType;
  quotation?: Maybe<CapaQuotationSimpleQuotation>;
};

export enum CapaQuotationChatMessageType {
  AllowRequest = 'ALLOW_REQUEST',
  AllowResponse = 'ALLOW_RESPONSE',
  CompleteRequest = 'COMPLETE_REQUEST',
  ConfirmRequest = 'CONFIRM_REQUEST',
  DateDivider = 'DATE_DIVIDER',
  SimpleQuotation = 'SIMPLE_QUOTATION',
  DetailedQuotation = 'DETAILED_QUOTATION',
  RfqComplete = 'RFQ_COMPLETE',
  RfqStopped = 'RFQ_STOPPED',
  RfqConfirm = 'RFQ_CONFIRM',
  RfqExpirationConfirm = 'RFQ_EXPIRATION_CONFIRM',
  RfqReopen = 'RFQ_REOPEN',
  QuitChatroomPartner = 'QUIT_CHATROOM_PARTNER',
  QuitChatroomClient = 'QUIT_CHATROOM_CLIENT',
  ChatStart = 'CHAT_START',
  System = 'SYSTEM',
  Message = 'MESSAGE',
  QuotationConsultingStart = 'QUOTATION_CONSULTING_START',
  IntroductionConsultingStart = 'INTRODUCTION_CONSULTING_START',
  QuotationCreated = 'QUOTATION_CREATED',
  QuotationAdjustRequest = 'QUOTATION_ADJUST_REQUEST',
  AdjustedQuotation = 'ADJUSTED_QUOTATION',
  QuotationAgreement = 'QUOTATION_AGREEMENT',
  PurchaseOrderConsultingStart = 'PURCHASE_ORDER_CONSULTING_START',
  PurchaseOrderCreated = 'PURCHASE_ORDER_CREATED',
  PurchaseOrderAdjustRequest = 'PURCHASE_ORDER_ADJUST_REQUEST',
  AdjustedPurchaseOrder = 'ADJUSTED_PURCHASE_ORDER',
  PurchaseOrderAgreement = 'PURCHASE_ORDER_AGREEMENT',
  RfqQuotationRegisterRequest = 'RFQ_QUOTATION_REGISTER_REQUEST',
  PurchaseOrderRegisterRequest = 'PURCHASE_ORDER_REGISTER_REQUEST',
  Withdraw = 'WITHDRAW',
  InduceQuotationRequest = 'INDUCE_QUOTATION_REQUEST',
  InducePurchaseOrderIssue = 'INDUCE_PURCHASE_ORDER_ISSUE',
  RejectRfqPurchaseOrder = 'REJECT_RFQ_PURCHASE_ORDER',
  QuotationAnnotationComment = 'QUOTATION_ANNOTATION_COMMENT',
  QuotationAnnotationReComment = 'QUOTATION_ANNOTATION_RE_COMMENT',
  BypassPrevention = 'BYPASS_PREVENTION',
  Deleted = 'DELETED',
  BypassDeleted = 'BYPASS_DELETED',
  InduceRegisterTransactionReview = 'INDUCE_REGISTER_TRANSACTION_REVIEW',
  RequestRegisterTransactionReview = 'REQUEST_REGISTER_TRANSACTION_REVIEW',
  InduceRequestRegisterTransactionReview = 'INDUCE_REQUEST_REGISTER_TRANSACTION_REVIEW',
  AwaitingPayment = 'AWAITING_PAYMENT',
  ApprovedPayment = 'APPROVED_PAYMENT',
  FailedPayment = 'FAILED_PAYMENT',
  CancelRequested = 'CANCEL_REQUESTED',
  CancelConfirmed = 'CANCEL_CONFIRMED',
  RejectedPurchaseOrder = 'REJECTED_PURCHASE_ORDER',
  RefundRequested = 'REFUND_REQUESTED',
  RefundConfirmed = 'REFUND_CONFIRMED',
  StartProcessing = 'START_PROCESSING',
  StartShipping = 'START_SHIPPING',
  InduceConfirmPurchase = 'INDUCE_CONFIRM_PURCHASE',
  DelayedConfirmPurchase = 'DELAYED_CONFIRM_PURCHASE',
  PurchaseConfirmed = 'PURCHASE_CONFIRMED',
  SubmallRegistrationRequest = 'SUBMALL_REGISTRATION_REQUEST'
}

export type CapaQuotationChatMessageUnion =
  | CapaQuotationChatMessageMessage
  | CapaQuotationChatMessageSimpleQuotation
  | CapaQuotationChatMessageDetailedQuotation
  | CapaQuotationChatMessageRfq
  | CapaQuotationAnnotationCommentMessage
  | CapaQuotationBypassMessage
  | CapaQuotationBypassMessageDeleteMessage;

export type CapaQuotationChatMessagesOutput = {
  __typename?: 'CAPAQuotationChatMessagesOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<Array<CapaQuotationChatMessage>>;
};

export type CapaQuotationChatRfq = {
  __typename?: 'CAPAQuotationChatRfq';
  rfqId?: Maybe<Scalars['String']>;
  userOrganizationName?: Maybe<Scalars['String']>;
  userManagerName?: Maybe<Scalars['String']>;
  partnerOrganizationName?: Maybe<Scalars['String']>;
  partnerManagerName?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  quotationId?: Maybe<Scalars['String']>;
  purchaseOrderId?: Maybe<Scalars['String']>;
  adjustRequest?: Maybe<Scalars['String']>;
  event?: Maybe<CapaQuotationRfqEventType>;
  rejectedReason?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  isSendRequest?: Maybe<Scalars['Boolean']>;
  /** 결제 대금 */
  purchaseAmount?: Maybe<Scalars['Float']>;
  /** 지급 승인 (구매확정) 예정일 */
  expectedConfirmPurchaseDate?: Maybe<Scalars['String']>;
};

export type CapaQuotationChatRoom = {
  __typename?: 'CAPAQuotationChatRoom';
  chatRoomId?: Maybe<Scalars['String']>;
  chatRoomStatus?: Maybe<CapaQuotationChatRoomStatus>;
  partnerStatus?: Maybe<CapaQuotationPartnerUserStatus>;
  clientStatus?: Maybe<CapaQuotationUserStatus>;
  /** Legacy */
  chatMessages?: Maybe<Array<CapaQuotationChatMessage>>;
  /** Legacy */
  chatMessagesCount?: Maybe<Scalars['Float']>;
  rfqId?: Maybe<Scalars['String']>;
  rfqStatus?: Maybe<CapaRfqStatus>;
  /** old rfq 여부 */
  isOld?: Maybe<Scalars['Boolean']>;
  /** 견적서 발행 여부 */
  hasQuotation?: Maybe<Scalars['Boolean']>;
  /** 견적서 확정 여부 */
  isQuotationConfirmed?: Maybe<Scalars['Boolean']>;
  /** 발주서 발행 여부 */
  hasPurchaseOrder?: Maybe<Scalars['Boolean']>;
  /** 발주서 확정 여부 */
  isPurchaseOrderConfirmed?: Maybe<Scalars['Boolean']>;
  quotationPartnerId?: Maybe<Scalars['String']>;
  chatFiles?: Maybe<Array<CapaQuotationChatFile>>;
  /** 고객이 가장 최근 메세지 읽은 시간 */
  clientLastSeenAt?: Maybe<Scalars['String']>;
  /** 가장 최근 메세지 읽은 시간 */
  partnerLastSeenAt?: Maybe<Scalars['String']>;
  /** 리뷰 작성 여부 */
  hasReview?: Maybe<Scalars['Boolean']>;
  /** 진행중인 결제 건이 있는지, 있다면, 견적서 재발행 불가 */
  hasProgressingPayment?: Maybe<Scalars['Boolean']>;
  /** 생성일 */
  createdAt?: Maybe<Scalars['String']>;
  /** 수정일 */
  updatedAt?: Maybe<Scalars['String']>;
};

export type CapaQuotationChatRoomAnnotationComment = {
  __typename?: 'CAPAQuotationChatRoomAnnotationComment';
  isNew?: Maybe<Scalars['Boolean']>;
  userId?: Maybe<Scalars['String']>;
  status?: Maybe<CapaQuotationSharedFileAnnotationStatus>;
  commentUsers?: Maybe<Array<CommentUser>>;
  fileName?: Maybe<Scalars['String']>;
  quotationSharedFileId?: Maybe<Scalars['String']>;
  fileId?: Maybe<Scalars['String']>;
  annotationId?: Maybe<Scalars['String']>;
  comments?: Maybe<Array<AnnotationComment>>;
  userName?: Maybe<Scalars['String']>;
  lastComment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type CapaQuotationChatRoomAnnotationCommentBo = {
  __typename?: 'CAPAQuotationChatRoomAnnotationCommentBO';
  userId?: Maybe<Scalars['String']>;
  status?: Maybe<CapaQuotationSharedFileAnnotationStatus>;
  commentUsers?: Maybe<Array<CommentUserBo>>;
  comments?: Maybe<Array<AnnotationCommentBo>>;
  annotationId?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  fileId?: Maybe<Scalars['String']>;
  quotationSharedFileId?: Maybe<Scalars['String']>;
  lastComment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type CapaQuotationChatRoomAnnotationCommentsOutput = {
  __typename?: 'CAPAQuotationChatRoomAnnotationCommentsOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<Array<CapaQuotationChatRoomAnnotationComment>>;
};

export type CapaQuotationChatRoomOutput = {
  __typename?: 'CAPAQuotationChatRoomOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaQuotationChatRoom>;
};

export type CapaQuotationChatRoomSharedFiles = {
  __typename?: 'CAPAQuotationChatRoomSharedFiles';
  fileName?: Maybe<Scalars['String']>;
  fileId?: Maybe<Scalars['String']>;
  quotationSharedFileId?: Maybe<Scalars['String']>;
  quotationSharedFileSize?: Maybe<Scalars['Float']>;
  quotationSharedFileType?: Maybe<CapaQuotationChatRoomSharedFileType>;
  annotationStatus?: Maybe<CapaQuotationChatRoomSharedFileAnnotationStatus>;
};

export type CapaQuotationChatRoomSharedFilesOutput = {
  __typename?: 'CAPAQuotationChatRoomSharedFilesOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<Array<CapaQuotationChatRoomSharedFiles>>;
};

/** 챗룸 상태 */
export enum CapaQuotationChatRoomStatus {
  /** 채팅방 오픈 상태 */
  Opened = 'OPENED',
  /** 채팅방에 파트너만 들어와있는 상태 */
  OpenedPartner = 'OPENED_PARTNER',
  /** 채팅방 종료 상태 */
  Stopped = 'STOPPED',
  ClosedConfirm = 'CLOSED_CONFIRM',
  ClosedClient = 'CLOSED_CLIENT',
  ClosedPartner = 'CLOSED_PARTNER',
  /** 고객이 채팅하기 시작 전에 파트너가 서비스 타입을 OFF하면서 채팅방을 모두 종료한 경우 */
  ClosedBeforeConsulting = 'CLOSED_BEFORE_CONSULTING'
}

/** 채팅방 필터링 타입 */
export enum CapaQuotationChatRoomsFilterType {
  /** 기본 채팅방 */
  Default = 'DEFAULT',
  /** 견적 상담 상태 채팅방 */
  Consulting = 'CONSULTING',
  /** 발주 상담 상태 채팅방 */
  Confirmed = 'CONFIRMED'
}

export type CapaQuotationChatRoomsTotalCount = {
  __typename?: 'CAPAQuotationChatRoomsTotalCount';
  /** 캐파 서비스 */
  capaService?: Maybe<CapaServiceType>;
  /** 채팅방의 개수 */
  totalCount: Scalars['Float'];
};

export type CapaQuotationCreatePurchaseOrder = {
  __typename?: 'CAPAQuotationCreatePurchaseOrder';
  purchaseOrderId: Scalars['String'];
};

export type CapaQuotationCreatePurchaseOrderOutput = {
  __typename?: 'CAPAQuotationCreatePurchaseOrderOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaQuotationCreatePurchaseOrder>;
};

export type CapaQuotationDetailedMaterial = {
  __typename?: 'CAPAQuotationDetailedMaterial';
  material?: Maybe<CapaQuotationMaterial>;
  detailedMaterials?: Maybe<Array<CapaQuotationDetailedMaterialType>>;
};

/** 세부 재료 */
export enum CapaQuotationDetailedMaterialType {
  /** ABS */
  Abs = 'ABS',
  /** 아크릴 (Acrylic) */
  Acryl = 'ACRYL',
  /** MC Nylon */
  McNylon = 'MC_NYLON',
  /** MC Nylon 66 */
  McNylon_66 = 'MC_NYLON_66',
  /** 폴리카보네이트 (PC) */
  Pc = 'PC',
  /** 폴리에틸렌 (PE) */
  Pe = 'PE',
  /** 폴리프로필렌 (PP) */
  Pp = 'PP',
  /** 아세탈 (POM) */
  AcetalPom = 'ACETAL_POM',
  /** PEEK */
  Peek = 'PEEK',
  /** 베이클라이트(Bakelite) */
  Bakelite = 'BAKELITE',
  /** 테플론 (PTFE) */
  Teflon = 'TEFLON',
  /** 2024-T3 */
  Aluminum_2024T3 = 'ALUMINUM_2024_T3',
  /** 6061-T6 */
  Aluminum_6061T6 = 'ALUMINUM_6061_T6',
  /** 6063 */
  Aluminum_6063 = 'ALUMINUM_6063',
  /** 7050 */
  Aluminum_7050 = 'ALUMINUM_7050',
  /** 7075-T6 */
  Aluminum_7075T6 = 'ALUMINUM_7075_T6',
  /** 7075-T7351 */
  Aluminum_7075T7351 = 'ALUMINUM_7075_T7351',
  /** MIC-6 */
  AluminumMic_6 = 'ALUMINUM_MIC_6',
  /** SS 15-5 */
  Ss15_5 = 'SS15_5',
  /** SS 17-4 */
  Ss17_4 = 'SS17_4',
  /** SS 18-8 */
  Ss18_8 = 'SS18_8',
  /** SS 303 */
  Ss303 = 'SS303',
  /** SS 304 */
  Ss304 = 'SS304',
  /** SS 316/316L */
  Ss316Ss316L = 'SS316_SS316L',
  /** SS 416 */
  Ss416 = 'SS416',
  /** SS 420 */
  Ss420 = 'SS420',
  /** SS 235(SS 330) */
  Ss235Ss330 = 'SS235_SS330',
  /** SS 275(SS 400) */
  Ss275Ss400 = 'SS275_SS400',
  /** SS 315(SS 490) */
  Ss315Ss490 = 'SS315_SS490',
  /** SS 410(SS 540) */
  Ss410Ss540 = 'SS410_SS540',
  /** SS 450(SS 590) */
  Ss450Ss590 = 'SS450_SS590',
  /** SS 550 */
  Ss550 = 'SS550',
  /** Copper 101 */
  Copper_101 = 'COPPER_101',
  /** Copper 260(Brass) */
  Copper_260Brass = 'COPPER_260_BRASS',
  /** Copper C110 */
  CopperC110 = 'COPPER_C110',
  /** Copper C360(Brass) */
  CopperC360Brass = 'COPPER_C360_BRASS',
  /** Copper C932(Bronze) */
  CopperC932Bronze = 'COPPER_C932_BRONZE',
  /** S10C(SM10C) */
  S10CSm10C = 'S10C_SM10C',
  /** S15C(SM15C) */
  S15CSm15C = 'S15C_SM15C',
  /** S20C(SM20C) */
  S20CSm20C = 'S20C_SM20C',
  /** S25C(SM25C) */
  S25CSm25C = 'S25C_SM25C',
  /** S30C(SM30C) */
  S30CSm30C = 'S30C_SM30C',
  /** S35C(SM35C) */
  S35CSm35C = 'S35C_SM35C',
  /** S40C(SM40C) */
  S40CSm40C = 'S40C_SM40C',
  /** S45C(SM45C) */
  S45CSm45C = 'S45C_SM45C',
  /** S50C(SM50C) */
  S50CSm50C = 'S50C_SM50C',
  /** S55C(SM55C) */
  S55CSm55C = 'S55C_SM55C',
  /** 일반 황동 */
  GeneralBrass = 'GENERAL_BRASS',
  /** 일반 청동 */
  GeneralBronze = 'GENERAL_BRONZE',
  /** SCM415 */
  Scm_415 = 'SCM_415',
  /** SCM420 */
  Scm_420 = 'SCM_420',
  /** SCM440 */
  Scm_440 = 'SCM_440',
  /** ASA */
  Asa = 'ASA',
  /** HDPE */
  Hdpe = 'HDPE',
  /** LDPE */
  Ldpe = 'LDPE',
  /** Nylon(PA 나일론) */
  NylonPa = 'NYLON_PA',
  /** PBT */
  Pbt = 'PBT',
  /** 트라이탄 (PCT) */
  Pct = 'PCT',
  /** PEI */
  Pei = 'PEI',
  /** PET */
  Pet = 'PET',
  /** PMMA */
  Pmma = 'PMMA',
  /** 폴리스티렌(PS) */
  Ps = 'PS',
  /** PVC */
  Pvc = 'PVC',
  /** TPE */
  Tpe = 'TPE',
  /** TPU */
  Tpu = 'TPU',
  /** 알루미늄 합금 */
  AluminumAlloy = 'ALUMINUM_ALLOY',
  /** 아연 합금 */
  ZincAlloy = 'ZINC_ALLOY',
  /** 마그네슘 합금 */
  MagnesiumAlloy = 'MAGNESIUM_ALLOY',
  /** 파트너 추천이 필요합니다 */
  Recommend = 'RECOMMEND',
  /** 기타(직접입력) */
  Etc = 'ETC'
}

export type CapaQuotationDetailedPcbAssemblyItem = {
  __typename?: 'CAPAQuotationDetailedPcbAssemblyItem';
  /** fileId */
  fileId?: Maybe<Scalars['String']>;
  /** BOM FILE 이름 */
  fileName?: Maybe<Scalars['String']>;
  /** 수량 */
  quantity: Scalars['Float'];
  /** 총액 */
  cost: Scalars['Float'];
  /** 기타 */
  etc?: Maybe<Scalars['String']>;
};

export type CapaQuotationDetailedPcbFabricationItem = {
  __typename?: 'CAPAQuotationDetailedPcbFabricationItem';
  /** fileId */
  fileId?: Maybe<Scalars['String']>;
  /** GERBER FILE 이름 */
  fileName?: Maybe<Scalars['String']>;
  /** 수량 */
  quantity: Scalars['Float'];
  /** 두께 */
  thickness?: Maybe<Scalars['Float']>;
  /** 총액 */
  cost: Scalars['Float'];
  /** 기타 */
  etc?: Maybe<Scalars['String']>;
};

export type CapaQuotationDetailedQuotation = {
  __typename?: 'CAPAQuotationDetailedQuotation';
  /** rfq quotation id */
  id: Scalars['String'];
  rfqId: Scalars['String'];
  chatRoomId?: Maybe<Scalars['String']>;
  type: CapaRfqQuotationType;
  projectName?: Maybe<Scalars['String']>;
  serviceType?: Maybe<CapaQuotationServiceType>;
  detailServiceType?: Maybe<CapaQuotationDetailedServiceType>;
  optionalServices?: Maybe<Array<CapaQuotationRfqQuotationOptionalService>>;
  /** 고객측 정보 */
  publish?: Maybe<CapaQuotationDetailedQuotationPublish>;
  /** 파트너측 정보 */
  supply?: Maybe<CapaQuotationDetailedQuotationSupply>;
  /** 납품 가능일 */
  dueDate?: Maybe<Scalars['String']>;
  /** 견적서 유효기간 */
  expiration?: Maybe<Scalars['String']>;
  items?: Maybe<Array<CapaQuotationDetailedQuotationItem>>;
  pcbFabricationItems?: Maybe<Array<CapaQuotationDetailedPcbFabricationItem>>;
  pcbAssemblyItems?: Maybe<Array<CapaQuotationDetailedPcbAssemblyItem>>;
  /** 공급가액 */
  quotation?: Maybe<Scalars['Float']>;
  /** 배송비 */
  deliveryCost?: Maybe<Scalars['Float']>;
  /** 배송비 포함여부 */
  excludeDeliveryFee?: Maybe<Scalars['Boolean']>;
  pcb?: Maybe<CapaPcbDetail>;
  design?: Maybe<CapaDesignDetail>;
  /** 최대 시안 수정 횟수 */
  maxDraftModificationCount?: Maybe<Scalars['Float']>;
  /** 수정 범위 및 추가 과금 기준 */
  draftModificationTerms?: Maybe<Scalars['String']>;
  read?: Maybe<Scalars['Boolean']>;
  /** 견적서 확정 */
  isConfirmed?: Maybe<Scalars['Boolean']>;
  /** 견적 설명 */
  etc?: Maybe<Scalars['String']>;
  /** 수정 요청서 읽음 및 내용 */
  adjustRequest?: Maybe<CapaRfqAdjustRequestObject>;
  createdAt?: Maybe<Scalars['String']>;
  /** 가장 최신 여부 */
  latest?: Maybe<Scalars['Boolean']>;
  /** 가견적 여부 */
  isBeforeConsulting?: Maybe<Scalars['Boolean']>;
};

export type CapaQuotationDetailedQuotationItem = {
  __typename?: 'CAPAQuotationDetailedQuotationItem';
  fileId?: Maybe<Scalars['String']>;
  /** 재료 이름 */
  fileName?: Maybe<Scalars['String']>;
  /** 수량 */
  quantity: Scalars['Float'];
  material?: Maybe<Scalars['String']>;
  /** 총액 */
  cost: Scalars['Float'];
  /** 견적 설명 */
  etc?: Maybe<Scalars['String']>;
};

export type CapaQuotationDetailedQuotationItemInput = {
  fileId?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  material?: Maybe<Scalars['String']>;
  /** 총액 */
  cost: Scalars['Float'];
  etc?: Maybe<Scalars['String']>;
};

export type CapaQuotationDetailedQuotationOutput = {
  __typename?: 'CAPAQuotationDetailedQuotationOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaQuotationDetailedQuotation>;
};

/** 조립 견적 정보 */
export type CapaQuotationDetailedQuotationPcbAssemblyItemInput = {
  fileId?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  /** 총액 */
  cost: Scalars['Float'];
  etc?: Maybe<Scalars['String']>;
};

/** 제작 견정 정보 Item */
export type CapaQuotationDetailedQuotationPcbFabricationItemInput = {
  fileId?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  /** 두께 */
  thickness?: Maybe<Scalars['Float']>;
  /** 총액 */
  cost: Scalars['Float'];
  etc?: Maybe<Scalars['String']>;
};

export type CapaQuotationDetailedQuotationPublish = {
  __typename?: 'CAPAQuotationDetailedQuotationPublish';
  /** 고객 상태 */
  quotationUserStatus?: Maybe<CapaQuotationUserStatus>;
  /** 고객 회사명 */
  organizationName?: Maybe<Scalars['String']>;
  /** 고객 담당자명 */
  managerName?: Maybe<Scalars['String']>;
};

export type CapaQuotationDetailedQuotationSupply = {
  __typename?: 'CAPAQuotationDetailedQuotationSupply';
  quotationPartnerId: Scalars['String'];
  quotationPartnerStatus?: Maybe<CapaQuotationPartnerStatus>;
  /** 파트너 회사명 */
  organizationName?: Maybe<Scalars['String']>;
  companyRegistrationNumber?: Maybe<Scalars['Float']>;
  /** 담당자명 */
  managerName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
};

export type CapaQuotationDetailedQuotationsOutput = {
  __typename?: 'CAPAQuotationDetailedQuotationsOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<Array<CapaQuotationDetailedQuotation>>;
};

export type CapaQuotationDetailedService = {
  __typename?: 'CAPAQuotationDetailedService';
  type?: Maybe<CapaQuotationDetailedServiceType>;
  materials?: Maybe<Array<CapaQuotationDetailedMaterial>>;
};

/** 캐파 견적 상세 서비스 정보 입력 */
export type CapaQuotationDetailedServiceInput = {
  /** 캐파 견적 상세 서비스 타입 */
  type?: Maybe<CapaQuotationDetailedServiceType>;
  /** 재료 정보 입력 */
  materials?: Maybe<Array<CapaQuotationMaterialInput>>;
};

/** 캐파 견적 상세 서비스 타입 */
export enum CapaQuotationDetailedServiceType {
  /** 밀링 (Milling) */
  Milling = 'MILLING',
  /** 선반 (Turning) */
  Turning = 'TURNING',
  /** 머시닝 센터 (Machining Center) */
  MachiningCenter = 'MACHINING_CENTER',
  /** 드릴링 (Drilling) */
  Drilling = 'DRILLING',
  /** SLA */
  Sla = 'SLA',
  /** FDM */
  Fdm = 'FDM',
  /** Polyjet */
  Polyjet = 'POLYJET',
  /** MJF */
  Mjf = 'MJF',
  /** SLS */
  Sls = 'SLS',
  /** SLM/DMLS */
  SlmDmls = 'SLM_DMLS',
  /** 일반 사출 (Injection Molding) */
  InjectionMolding = 'INJECTION_MOLDING',
  /** 이중 사출 (Double-Shot Injection Molding) */
  DoubleShotInjectionMolding = 'DOUBLE_SHOT_INJECTION_MOLDING',
  /** 블로우 사출 (Blow Injection Molding) */
  BlowInjectionMolding = 'BLOW_INJECTION_MOLDING',
  /** 인서트 사출 (Insert Injection Molding) */
  InsertInjectionMolding = 'INSERT_INJECTION_MOLDING',
  /** 프레스 (Press) */
  Press = 'PRESS',
  /** 레이저 컷팅 (Laser Cutting) */
  LaserCutting = 'LASER_CUTTING',
  /** 워터젯 컷팅 (Waterjet Cutting) */
  WaterjetCutting = 'WATERJET_CUTTING',
  /** 사형 주조 (Sand Casting) */
  SandCasting = 'SAND_CASTING',
  /** 쉘 주조 (Shell Moulding) */
  ShellCasting = 'SHELL_CASTING',
  /** 인베스트먼트 주조 (Investment Casting) */
  InvestmentCasting = 'INVESTMENT_CASTING',
  /** 다이캐스팅(Die Casting) */
  DieCasting = 'DIE_CASTING',
  /** 원심 주조(Centrifugal Casting) */
  CentrifugalCasting = 'CENTRIFUGAL_CASTING',
  Recommend = 'RECOMMEND',
  CircuitDesign = 'CIRCUIT_DESIGN',
  CircuitFabrication = 'CIRCUIT_FABRICATION',
  CircuitAssembly = 'CIRCUIT_ASSEMBLY',
  MechanicalDesign = 'MECHANICAL_DESIGN',
  ProductDesign = 'PRODUCT_DESIGN',
  Prototyping = 'PROTOTYPING',
  Etc = 'ETC'
}

/** 재료 */
export enum CapaQuotationMaterial {
  /** 플라스틱 (Plastic) */
  Plastic = 'PLASTIC',
  /** 알루미늄 (Aluminum */
  Aluminum = 'ALUMINUM',
  /** 스테인리스 강(Stainless Steel) */
  StainlessSteel = 'STAINLESS_STEEL',
  /** 강철 (Steel) */
  Steel = 'STEEL',
  /** 구리 (Copper) */
  Copper = 'COPPER',
  /** 탄소강 (Carbon Steel) */
  CarbonSteel = 'CARBON_STEEL',
  /** 황동 (Brass) */
  Brass = 'BRASS',
  /** 청동 (Bronze) */
  Bronze = 'BRONZE',
  /** 합금강 (Steel Alloy) */
  SteelAlloy = 'STEEL_ALLOY',
  /** 레진 (흰색) */
  ResinWhite = 'RESIN_WHITE',
  /** 레진 (투명) */
  ResinTransparent = 'RESIN_TRANSPARENT',
  /** 레진 (반투명) */
  ResinTranslucent = 'RESIN_TRANSLUCENT',
  /** PLA */
  Pla = 'PLA',
  /** ABS(산업용) */
  AbsIndustrial = 'ABS_INDUSTRIAL',
  /** Vero (멀티 컬러) */
  Vero = 'VERO',
  /** 고무 유사 */
  RubberLike = 'RUBBER_LIKE',
  /** PA12 */
  Pa12 = 'PA12',
  /** 금속 (Metal) */
  MetalInj = 'METAL_INJ',
  /** 아연 강 (Galvanized Steel)) */
  GalvanizedSteel = 'GALVANIZED_STEEL',
  /** 주철 (Iron) */
  Iron = 'IRON',
  /** 주강 (Steel) */
  SteelCasting = 'STEEL_CASTING',
  /** 알루미늄 합금 (Aluminum Alloy) */
  AluminumAlloy = 'ALUMINUM_ALLOY',
  /** 아연 합금 (Zinc Alloy) */
  ZincAlloy = 'ZINC_ALLOY',
  /** 마그네슘 합금 (Magnesium Alloy) */
  MagnesiumAlloy = 'MAGNESIUM_ALLOY',
  /** 동 합금 (Copper Alloy) */
  CopperAlloy = 'COPPER_ALLOY',
  /** 파트너 추천이 필요합니다 */
  Recommend = 'RECOMMEND',
  /** 기타(직접입력) */
  Etc = 'ETC'
}

/** 재료 정보 입력 */
export type CapaQuotationMaterialInput = {
  /** 재료 */
  material?: Maybe<CapaQuotationMaterial>;
  /** 세부 재료 */
  detailedMaterials?: Maybe<Array<CapaQuotationDetailedMaterialType>>;
};

export type CapaQuotationMessaegeSearch = {
  __typename?: 'CAPAQuotationMessaegeSearch';
  rfqId?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  managerName?: Maybe<Scalars['String']>;
  message?: Maybe<CapaQuotationChatMessageUnion>;
  createdAt?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  eventType?: Maybe<CapaQuotationRfqEventType>;
};

export type CapaQuotationMyReceivedQuotation = {
  __typename?: 'CAPAQuotationMyReceivedQuotation';
  rfqId?: Maybe<Scalars['String']>;
  quotationId?: Maybe<Scalars['String']>;
  quotationType?: Maybe<CapaRfqQuotationType>;
  partnerId?: Maybe<Scalars['String']>;
  chatRoomId?: Maybe<Scalars['String']>;
  chatRoomStatus?: Maybe<CapaQuotationChatRoomStatus>;
  chatRoomCreatedAt?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  confirmCount?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  deliveryCost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['String']>;
  read?: Maybe<Scalars['Boolean']>;
  /** 수정된 견적서 여부 */
  isAdjusted?: Maybe<Scalars['Boolean']>;
  /** 발주서(업체 확정) 발행 여부 */
  isTransaction?: Maybe<Scalars['Boolean']>;
  /** 견적서를 보낸 파트너의 상태 */
  quotationPartnerStatus?: Maybe<CapaQuotationPartnerStatus>;
  /** 특정 rfq가 파트너로부터 수령한 것 */
  rfqReceivedType?: Maybe<CapaRfqReceivedType>;
  /** 발주서 상태 */
  purchaseOrderStatus?: Maybe<CapaPurchaseOrderStatus>;
  satisfactioPoint?: Maybe<Scalars['Float']>;
  reviewCnt?: Maybe<Scalars['Float']>;
};

export type CapaQuotationMyReceivedQuotationsOutput = {
  __typename?: 'CAPAQuotationMyReceivedQuotationsOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<Array<CapaQuotationMyReceivedQuotation>>;
};

export type CapaQuotationMyRfqReview = {
  __typename?: 'CAPAQuotationMyRfqReview';
  rfqId: Scalars['String'];
  thumbnailId?: Maybe<Scalars['String']>;
  serviceType?: Maybe<CapaQuotationServiceType>;
  projectName?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  publicComment?: Maybe<Scalars['String']>;
  privateComment?: Maybe<Scalars['String']>;
  replyComment?: Maybe<Scalars['String']>;
  repliedAt?: Maybe<Scalars['String']>;
  quality?: Maybe<Scalars['Float']>;
  dueDate?: Maybe<Scalars['Float']>;
};

export type CapaQuotationMyRfqReviewOutput = {
  __typename?: 'CAPAQuotationMyRfqReviewOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<Array<CapaQuotationMyRfqReview>>;
};

/** 캐파 실제 사용 서비스 타입 */
export enum CapaQuotationNewServiceType {
  All = 'ALL',
  /** CNC */
  Cnc = 'CNC',
  /** 3D 프린터 */
  Printing = 'PRINTING',
  /** 금형사출 */
  Inj = 'INJ',
  /** 판금가공 */
  SheetMetal = 'SHEET_METAL',
  /** 주조 */
  Casting = 'CASTING',
  /** 디자인/설계 */
  Design = 'DESIGN',
  /** 전자회로 */
  Pcb = 'PCB'
}

export type CapaQuotationOptionalService = {
  __typename?: 'CAPAQuotationOptionalService';
  serviceType?: Maybe<CapaQuotationServiceType>;
  quantity?: Maybe<Scalars['Float']>;
};

export type CapaQuotationPartner = {
  __typename?: 'CAPAQuotationPartner';
  id: Scalars['ID'];
  email: Scalars['String'];
  status: CapaQuotationPartnerStatus;
  capabilities: CapaQuotationPartnerCapability;
  organizationName: Scalars['String'];
  fullAddress: Scalars['String'];
  shortAddress: Scalars['String'];
  companyRegistrationNumber: Scalars['String'];
  companyIntroduction?: Maybe<Scalars['String']>;
  foundedIn?: Maybe<Scalars['String']>;
  numberEmployees?: Maybe<Scalars['String']>;
  equipment?: Maybe<Scalars['String']>;
  serviceHours?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  managerName: Scalars['String'];
  department?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  contact: Scalars['String'];
  notificationFilter: CapaQuotationPartnerNotificationFilter;
  rfqFilter?: Maybe<Scalars['String']>;
  /** [필수] 캐파 서비스 이용약관 동의 */
  serviceAgreed?: Maybe<Scalars['Boolean']>;
  /** [필수] 개인정보 수집 및 활용 동의 */
  privacyAgreed?: Maybe<Scalars['Boolean']>;
  /** [선택] 프로모션 정보 수신 동의 */
  promotionAgreed?: Maybe<Scalars['Boolean']>;
  /** 추천인 ID */
  referralUserId?: Maybe<Scalars['String']>;
  /** ITP 대상 여부 */
  isItp: Scalars['Boolean'];
  /** 생성일 */
  createdAt: Scalars['String'];
  /** 수정일 */
  updatedAt: Scalars['String'];
  /** 파트너 업로드 파일 */
  files?: Maybe<Array<CapaQuotationPartnerFile>>;
};

export type CapaQuotationPartnerAvailableRequestReview = {
  __typename?: 'CAPAQuotationPartnerAvailableRequestReview';
  rfqId: Scalars['String'];
  /** 파트너 유저 아이디 */
  quotationPartnerUserId: Scalars['String'];
  chatRoomId: Scalars['String'];
  projectName: Scalars['String'];
  managerName: Scalars['String'];
  organizationName: Scalars['String'];
  purchaseOrderValue: Scalars['Float'];
  serviceType?: Maybe<CapaQuotationServiceType>;
  /** 발주 완료일 */
  purchaseOrderCreatedAt?: Maybe<Scalars['String']>;
  thumbnailId?: Maybe<Scalars['String']>;
  isSendRequest?: Maybe<Scalars['Boolean']>;
};

/** 파트너가 리뷰요청 가능한 목록 */
export type CapaQuotationPartnerAvailableRequestReviewsOutput = {
  __typename?: 'CAPAQuotationPartnerAvailableRequestReviewsOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<Array<CapaQuotationPartnerAvailableRequestReview>>;
};

export type CapaQuotationPartnerBo = {
  __typename?: 'CAPAQuotationPartnerBO';
  id: Scalars['ID'];
  email: Scalars['String'];
  status: CapaQuotationPartnerStatus;
  capabilities: CapaQuotationPartnerCapability;
  organizationName: Scalars['String'];
  fullAddress: Scalars['String'];
  shortAddress: Scalars['String'];
  companyRegistrationNumber: Scalars['String'];
  companyIntroduction?: Maybe<Scalars['String']>;
  foundedIn?: Maybe<Scalars['String']>;
  numberEmployees?: Maybe<Scalars['String']>;
  equipment?: Maybe<Scalars['String']>;
  serviceHours?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  managerName: Scalars['String'];
  department?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  contact: Scalars['String'];
  notificationFilter: CapaQuotationPartnerNotificationFilter;
  rfqFilter?: Maybe<Scalars['String']>;
  /** [필수] 캐파 서비스 이용약관 동의 */
  serviceAgreed?: Maybe<Scalars['Boolean']>;
  /** [필수] 개인정보 수집 및 활용 동의 */
  privacyAgreed?: Maybe<Scalars['Boolean']>;
  /** [선택] 프로모션 정보 수신 동의 */
  promotionAgreed?: Maybe<Scalars['Boolean']>;
  /** 추천인 ID */
  referralUserId?: Maybe<Scalars['String']>;
  /** ITP 대상 여부 */
  isItp: Scalars['Boolean'];
  /** CAPA 서비스를 어떻게 알게 되셨나요? (복수 선택 가능) */
  inflowRoute?: Maybe<Array<CapaInflowRoute>>;
  /** 생성일 */
  createdAt: Scalars['String'];
  /** 수정일 */
  updatedAt: Scalars['String'];
  /** 탈퇴 사유 */
  withdrawnReason?: Maybe<CapaWithdrawnQuotationPartnerReason>;
  /** 파트너 업로드 파일 */
  files?: Maybe<Array<CapaQuotationPartnerFile>>;
};

export type CapaQuotationPartnerCapability = {
  __typename?: 'CAPAQuotationPartnerCapability';
  services?: Maybe<Array<CapaQuotationService>>;
};

/** 캐파 견적 파트너가 보는 채팅방룸 */
export type CapaQuotationPartnerChatRoom = {
  __typename?: 'CAPAQuotationPartnerChatRoom';
  profileId?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  quotationUserStatus: CapaQuotationUserStatus;
  managerName?: Maybe<Scalars['String']>;
  projectName: Scalars['String'];
  rfqId: Scalars['String'];
  chatRoomId: Scalars['String'];
  quotaionUserId: Scalars['String'];
  chatRoomStatus: CapaQuotationChatRoomStatus;
  /** 안읽은수 */
  unReadCount?: Maybe<Scalars['Float']>;
  lastMessage?: Maybe<CapaQuotationChatMessageUnion>;
  /** 마지막 메세지 전송시간, */
  lastMessageCreatedAt?: Maybe<Scalars['String']>;
  serviceType: CapaQuotationServiceType;
  detailedServiceType?: Maybe<CapaQuotationDetailedServiceType>;
  thumbnailId?: Maybe<Scalars['String']>;
  rfqStatus: CapaRfqStatus;
  shortAddress?: Maybe<Scalars['String']>;
  rfqCreatedAt?: Maybe<Scalars['String']>;
  purchaseOrderStatus?: Maybe<CapaPurchaseOrderStatus>;
  /** 기획에서 요구하는 상태 를 지정해서 반환. */
  searchProjectStatusTypeEnum?: Maybe<ProjectStatusType>;
  quantity?: Maybe<Scalars['String']>;
  currentChatroomsCount?: Maybe<Scalars['String']>;
  quotationPartnerId?: Maybe<Scalars['String']>;
  purchaseOrderQuotationPartnerId?: Maybe<Scalars['String']>;
};

export type CapaQuotationPartnerChatRooms = {
  __typename?: 'CAPAQuotationPartnerChatRooms';
  totalChatRoomsCount: Scalars['Float'];
  currentChatRoomCount?: Maybe<Scalars['Float']>;
  chatRooms?: Maybe<Array<CapaQuotationPartnerChatRoom>>;
  /** 읽지 않은 메세지 수 */
  totalUnReadMessagesCount?: Maybe<
    Array<CapaQuotationPartnerUnReadMessagesCount>
  >;
};

/** 캐파 견적 파트너가 보는 채팅방 chatRooms Output */
export type CapaQuotationPartnerChatRoomsOutput = {
  __typename?: 'CAPAQuotationPartnerChatRoomsOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaQuotationPartnerChatRooms>;
};

/** CAPAQuotationPartnerEquipment */
export type CapaQuotationPartnerEquipment = {
  __typename?: 'CAPAQuotationPartnerEquipment';
  /** id */
  id: Scalars['String'];
  /** 장비명 */
  equipmentName: Scalars['String'];
  /** 수량 */
  quantity: Scalars['Float'];
  /** 상세 정보 */
  description?: Maybe<Scalars['String']>;
  /** 파트너 파일 업로드 정보 입력 */
  file?: Maybe<CapaQuotationPartnerStrategyFile>;
  /** 생성 날짜 */
  createdAt?: Maybe<Scalars['String']>;
};

export type CapaQuotationPartnerFile = {
  __typename?: 'CAPAQuotationPartnerFile';
  fileId?: Maybe<Scalars['ID']>;
  fileName?: Maybe<Scalars['String']>;
  fileType?: Maybe<CapaQuotationPartnerFileType>;
};

/** 파트너 파일 타입 */
export enum CapaQuotationPartnerFileType {
  /** 회사 로고 */
  Logo = 'LOGO',
  /** 사업자 등록증 */
  Registration = 'REGISTRATION',
  /** 포트폴리오 이미지 */
  PortfolioImage = 'PORTFOLIO_IMAGE',
  /** 포트폴리오 파일 */
  PortfolioFile = 'PORTFOLIO_FILE',
  /** 파트너 파일 및 동영상 */
  PartnerFile = 'PARTNER_FILE',
  /** 커버 이미지 */
  ImageCover = 'IMAGE_COVER'
}

/** CAPAQuotationPartnerGNB */
export type CapaQuotationPartnerGnb = {
  __typename?: 'CAPAQuotationPartnerGNB';
  /** 새로 들어온 rfq개수 */
  newRfqCount: Scalars['Float'];
  /** 모든 채팅방 unread chat count */
  newChatCount: Scalars['Float'];
  /** 견적 상태 채팅방 unread chat count */
  newConsultingChatCount: Scalars['Float'];
  /** 발주 상태 채팅방 unread chat count */
  newConfirmedChatCount: Scalars['Float'];
  newProjectManagementCount: Scalars['Float'];
  newPaymentManagementCount?: Maybe<Scalars['Float']>;
};

/** CAPAQuotationPartnerGNBOutput */
export type CapaQuotationPartnerGnbOutput = {
  __typename?: 'CAPAQuotationPartnerGNBOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaQuotationPartnerGnb>;
};

/** 캐파 파트너 이미지 포토폴리오 타입 */
export enum CapaQuotationPartnerImagePortfolioProduceStepType {
  /** 시제품 제작 */
  Prototyping = 'PROTOTYPING',
  /** 소량 생산 */
  SmallProduction = 'SMALL_PRODUCTION',
  /** 대량 생산 */
  MassProduction = 'MASS_PRODUCTION'
}

/** notification filter */
export type CapaQuotationPartnerNotificationFilter = {
  __typename?: 'CAPAQuotationPartnerNotificationFilter';
  chatEmail?: Maybe<Scalars['Boolean']>;
  chatSMS?: Maybe<Scalars['Boolean']>;
  chatPush?: Maybe<Scalars['Boolean']>;
  rfqEmail?: Maybe<Scalars['Boolean']>;
  rfqSMS?: Maybe<Scalars['Boolean']>;
  rfqPush?: Maybe<Scalars['Boolean']>;
  capabilityFilters?: Maybe<Array<CapaQuotationCapabilityNotificationFilter>>;
  /** 방해금지 시간 */
  doNotDisturb?: Maybe<CapaDoNotDisturbFilter>;
  /** 프로젝트 이메일 알림 */
  projectEmail?: Maybe<Scalars['Boolean']>;
  /** 프로젝트 카카오 (실패시 SMS) 알림 */
  projectSMS?: Maybe<Scalars['Boolean']>;
  /** 프로젝트 (Push) 알림 */
  projectPush?: Maybe<Scalars['Boolean']>;
  /** 프로젝트 알림 설정 */
  projectFilters?: Maybe<Array<CapaRfqProjectFilter>>;
  /** 캐파 소식 및 혜택 이메일 알림 */
  newsEmail?: Maybe<Scalars['Boolean']>;
  /** 캐파 소식 및 혜택 카카오 (실패시 SMS) 알림 */
  newsSMS?: Maybe<Scalars['Boolean']>;
};

/** notification filter */
export type CapaQuotationPartnerNotificationFilterInput = {
  /** email yes/no for new chat messages */
  chatEmail?: Maybe<Scalars['Boolean']>;
  /** sms yes/no for new chat messages */
  chatSMS?: Maybe<Scalars['Boolean']>;
  /** push notification yes/no for new chat messages */
  chatPush?: Maybe<Scalars['Boolean']>;
  /** email yes/no for rfq registration for all capabilities */
  rfqEmail?: Maybe<Scalars['Boolean']>;
  /** sms yes/no for rfq registration for all capabilities */
  rfqSMS?: Maybe<Scalars['Boolean']>;
  /** push notification yes/no for rfq registration for all capabilities */
  rfqPush?: Maybe<Scalars['Boolean']>;
  capabilityFilters?: Maybe<
    Array<CapaQuotationCapabilityNotificationFilterInput>
  >;
  /** 방해금지 시간 */
  doNotDisturb?: Maybe<CapaDoNotDisturbFilterInput>;
  /** 프로젝트 이메일 알림 */
  projectEmail?: Maybe<Scalars['Boolean']>;
  /** 프로젝트 카카오 (실패시 SMS) 알림 */
  projectSMS?: Maybe<Scalars['Boolean']>;
  /** 프로젝트 Push 알림 */
  projectPush?: Maybe<Scalars['Boolean']>;
  /** 프로젝트 알림 설정 */
  projectFilters?: Maybe<Array<CapaRfqProjectFilterInput>>;
  /** 캐파 소식 및 혜택 이메일 알림 */
  newsEmail?: Maybe<Scalars['Boolean']>;
  /** 캐파 소식 및 혜택 카카오 (실패시 SMS) 알림 */
  newsSMS?: Maybe<Scalars['Boolean']>;
};

export type CapaQuotationPartnerOutput = {
  __typename?: 'CAPAQuotationPartnerOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaQuotationPartner>;
};

export type CapaQuotationPartnerPageViewCountModel = {
  __typename?: 'CAPAQuotationPartnerPageViewCountModel';
  pageView: Scalars['String'];
};

/** CAPAQuotationPartnerPortfolio */
export type CapaQuotationPartnerPortfolio = {
  __typename?: 'CAPAQuotationPartnerPortfolio';
  id: Scalars['String'];
  /** 프로젝트 명 */
  projectName: Scalars['String'];
  /** 고객 */
  client?: Maybe<Scalars['String']>;
  /** 상세 정보 */
  description?: Maybe<Scalars['String']>;
  /** 파트너 캐파빌리티 jsonb array of { service_type, process_method, material, detailed_material, etc } */
  capabilities?: Maybe<Array<CapaQuotationPartnerPortfolioService>>;
  /** 제품 용도 */
  projectPurpose?: Maybe<Array<CapaQuotationProductPurpose>>;
  /** 제품 종류 */
  productType?: Maybe<Scalars['String']>;
  /** 수량 */
  quantity?: Maybe<Scalars['Float']>;
  /** 제조 단계 */
  produceStep?: Maybe<CapaQuotationPartnerImagePortfolioProduceStepType>;
  /** 파트너 파일 업로드 정보 입력 */
  files: Array<CapaQuotationPartnerStrategyFile>;
};

/** 캐파 견적 서비스 정보 입력 */
export type CapaQuotationPartnerPortfolioService = {
  __typename?: 'CAPAQuotationPartnerPortfolioService';
  /** 캐파 견적 서비스 타입 */
  service?: Maybe<CapaQuotationServiceType>;
  /** 캐파 견적 상세 서비스 정보 입력 */
  detailedServices?: Maybe<Array<CapaQuotationDetailedServiceType>>;
};

/** CAPAQuotationPartnerProjectManagement */
export type CapaQuotationPartnerProjectManagement = {
  __typename?: 'CAPAQuotationPartnerProjectManagement';
  rfqId: Scalars['String'];
  serviceType: CapaQuotationServiceType;
  projectName: Scalars['String'];
  quotationUserStatus?: Maybe<CapaQuotationUserStatus>;
  organizationName?: Maybe<Scalars['String']>;
  managerName?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  type?: Maybe<CapaRfqProjectManagementType>;
  isNew: Scalars['Boolean'];
  isConfirmedAnotherCompany: Scalars['Boolean'];
  chatRoomId: Scalars['String'];
  createdAt: Scalars['String'];
};

export type CapaQuotationPartnerProjectParticipationStatusBo = {
  __typename?: 'CAPAQuotationPartnerProjectParticipationStatusBO';
  id?: Maybe<Scalars['String']>;
  rfqId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  serviceType?: Maybe<CapaQuotationServiceType>;
  projectName?: Maybe<Scalars['String']>;
  quotation?: Maybe<Scalars['Float']>;
  quotationCreatedDates?: Maybe<Array<Scalars['String']>>;
  chatRoomStatus?: Maybe<CapaQuotationChatRoomStatus>;
  lastMessageAt?: Maybe<Scalars['String']>;
  hasPurchaseOrder?: Maybe<Scalars['Boolean']>;
};

export enum CapaQuotationPartnerRejectType {
  /** 고객이 잘못 가입함 */
  JoinMistake = 'JOIN_MISTAKE',
  /** 파트너 요건 불충 */
  Insufficient = 'INSUFFICIENT',
  /** 연락이 되지 않음 */
  CantInTouch = 'CANT_IN_TOUCH',
  /** ETC */
  Etc = 'ETC'
}

/** 파트너가 받은 리뷰 목록 */
export type CapaQuotationPartnerRfqReviewOutput = {
  __typename?: 'CAPAQuotationPartnerRfqReviewOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaQuotationPartnerRfqReviewResult>;
};

export type CapaQuotationPartnerRfqReviewResult = {
  __typename?: 'CAPAQuotationPartnerRfqReviewResult';
  reviewAveragePoint?: Maybe<Scalars['Float']>;
  reviewCount?: Maybe<Scalars['Int']>;
  /** 별점 5점 개수 */
  reviewPointRatio?: Maybe<CapaReviewPointRatio>;
  reviews?: Maybe<Array<CapaRfqReview>>;
};

export enum CapaQuotationPartnerStatus {
  /** 승인 */
  Approved = 'APPROVED',
  /** 등록 */
  Registered = 'REGISTERED',
  /** 삭제 */
  Deleted = 'DELETED',
  /** 거절 */
  Rejected = 'REJECTED'
}

/** CAPAQuotationPartnerStrategy */
export type CapaQuotationPartnerStrategy = {
  __typename?: 'CAPAQuotationPartnerStrategy';
  id: Scalars['ID'];
  email: Scalars['String'];
  status: CapaQuotationPartnerStatus;
  capabilities?: Maybe<CapaQuotationPartnerCapability>;
  organizationName?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  shortAddress?: Maybe<Scalars['String']>;
  detailAddress?: Maybe<Scalars['String']>;
  companyRegistrationNumber: Scalars['String'];
  companyIntroduction?: Maybe<Scalars['String']>;
  foundedIn?: Maybe<Scalars['String']>;
  numberEmployees?: Maybe<Scalars['String']>;
  serviceHours?: Maybe<Scalars['String']>;
  managerName: Scalars['String'];
  department?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  contact?: Maybe<Scalars['String']>;
  notificationFilter: CapaQuotationPartnerNotificationFilter;
  rfqFilter?: Maybe<Scalars['String']>;
  /** [필수] 캐파 서비스 이용약관 동의 */
  serviceAgreed?: Maybe<Scalars['Boolean']>;
  /** [필수] 개인정보 수집 및 활용 동의 */
  privacyAgreed?: Maybe<Scalars['Boolean']>;
  /** [필수] 결제 서비스 이용약관 동의 */
  paymentAgreed?: Maybe<Scalars['Boolean']>;
  /** [선택] 프로모션 정보 수신 동의 */
  promotionAgreed?: Maybe<Scalars['Boolean']>;
  /** 추천인 ID */
  referralUserId?: Maybe<Scalars['String']>;
  /** ITP 대상 여부 */
  isItp: Scalars['Boolean'];
  /** 생성일 */
  createdAt: Scalars['String'];
  /** 수정일 */
  updatedAt: Scalars['String'];
  /** 파트너 로고 파일 */
  logo?: Maybe<CapaQuotationPartnerStrategyFile>;
  /** 파트너 커버 파일 */
  cover?: Maybe<CapaQuotationPartnerStrategyFile>;
  /** 파트너 업로드 파일 ex) 사진 및 동영상 관련 */
  partnerFiles?: Maybe<Array<CapaQuotationPartnerStrategyFile>>;
  portfolios?: Maybe<Array<CapaQuotationPartnerPortfolio>>;
  equipments?: Maybe<Array<CapaQuotationPartnerEquipment>>;
  /** 인증 관련 정보 */
  certifications?: Maybe<Array<CapaQuotationPartnerTechnologyModel>>;
  /** 특허 관련 정보 */
  patents?: Maybe<Array<CapaQuotationPartnerTechnologyModel>>;
  /** 공장 주소 */
  factoryAddress?: Maybe<Scalars['String']>;
  /** 공장 주소 */
  factoryDetailAddress?: Maybe<Scalars['String']>;
  /** 평균 응답 시간 */
  averageResponseTime?: Maybe<Scalars['String']>;
  /** 완료 거래 수 */
  transactionCount?: Maybe<Scalars['Float']>;
  /** 캐파 블로그 및 홍보 관련 */
  promotionModels?: Maybe<Array<CapaPromotionModel>>;
  /** 파트너 페이지 조회수 */
  partnerPageViewCount?: Maybe<Scalars['Float']>;
  /** 사업자 등록증 자료 */
  registrations: Array<CapaQuotationPartnerStrategyFile>;
  /** 리뷰 */
  reviews?: Maybe<CapaQuotationPartnerRfqReviewResult>;
  /** 서브몰 등록 여부 */
  haveSubmall: Scalars['Boolean'];
};

export type CapaQuotationPartnerStrategyBo = {
  __typename?: 'CAPAQuotationPartnerStrategyBO';
  id: Scalars['ID'];
  email: Scalars['String'];
  status: CapaQuotationPartnerStatus;
  capabilities?: Maybe<CapaQuotationPartnerCapability>;
  organizationName?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  shortAddress?: Maybe<Scalars['String']>;
  detailAddress?: Maybe<Scalars['String']>;
  companyRegistrationNumber: Scalars['String'];
  companyIntroduction?: Maybe<Scalars['String']>;
  foundedIn?: Maybe<Scalars['String']>;
  numberEmployees?: Maybe<Scalars['String']>;
  equipment?: Maybe<Scalars['String']>;
  serviceHours?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  managerName: Scalars['String'];
  department?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  contact?: Maybe<Scalars['String']>;
  notificationFilter: CapaQuotationPartnerNotificationFilter;
  rfqFilter?: Maybe<Scalars['String']>;
  /** [필수] 캐파 서비스 이용약관 동의 */
  serviceAgreed?: Maybe<Scalars['Boolean']>;
  /** [필수] 개인정보 수집 및 활용 동의 */
  privacyAgreed?: Maybe<Scalars['Boolean']>;
  /** [선택] 프로모션 정보 수신 동의 */
  promotionAgreed?: Maybe<Scalars['Boolean']>;
  /** 추천인 ID */
  referralUserId?: Maybe<Scalars['String']>;
  /** ITP 대상 여부 */
  isItp: Scalars['Boolean'];
  /** CAPA 서비스를 어떻게 알게 되셨나요? (복수 선택 가능) */
  inflowRoute?: Maybe<Array<CapaInflowRoute>>;
  /** 추천 회사명/기관명 */
  referralCompany?: Maybe<Scalars['String']>;
  /** 생성일 */
  createdAt: Scalars['String'];
  /** 수정일 */
  updatedAt: Scalars['String'];
  /** 탈퇴 사유 */
  withdrawnReason?: Maybe<CapaWithdrawnQuotationPartnerReason>;
  /** 파트너 로고 파일 */
  logo?: Maybe<CapaQuotationPartnerStrategyFile>;
  /** 파트너 커버 파일 */
  cover?: Maybe<CapaQuotationPartnerStrategyFile>;
  /** 파트너 업로드 파일 ex) 사진 및 동영상 관련 */
  partnerFiles?: Maybe<Array<CapaQuotationPartnerStrategyFile>>;
  portfolios?: Maybe<Array<CapaQuotationPartnerPortfolio>>;
  equipments?: Maybe<Array<CapaQuotationPartnerEquipment>>;
  /** 인증 관련 정보 */
  certifications?: Maybe<Array<CapaQuotationPartnerTechnologyModel>>;
  /** 특허 관련 정보 */
  patents?: Maybe<Array<CapaQuotationPartnerTechnologyModel>>;
  /** 공장 주소 */
  factoryAddress?: Maybe<Scalars['String']>;
  factoryDetailAddress?: Maybe<Scalars['String']>;
  /** 평균 응답 시간 */
  averageResponseTime?: Maybe<Scalars['String']>;
  /** 완료 거래 수 */
  transactionCount?: Maybe<Scalars['Float']>;
  /** 캐파 블로그 및 홍보 관련 */
  promotionModels?: Maybe<Array<CapaPromotionModel>>;
  /** 파트너 페이지 조회수 */
  partnerPageViewCount?: Maybe<Scalars['Float']>;
  /** 파트너 승인 날짜 */
  approvedAt?: Maybe<Scalars['String']>;
  /** 파트너 승인 담당자 이름 */
  approver?: Maybe<Scalars['String']>;
  /** 파트너 승인 담당자 BO id */
  approverBOId?: Maybe<Scalars['ID']>;
  /** 사업자 등록증 자료 */
  registrations: Array<CapaQuotationPartnerStrategyFile>;
  /** 사업자 등록증 자료 */
  introductionFile?: Maybe<CapaQuotationPartnerStrategyFile>;
  partnerHistoryModels?: Maybe<Array<CapaboPartnerHistoryModel>>;
  /** 통장 사본 */
  bankbooks?: Maybe<Array<CapaQuotationPartnerStrategyFile>>;
  /** 정산 은행 코드 */
  bankCode?: Maybe<CapaBankCode>;
  /** 정산 계좌번호(`-`제외) */
  accountNumber?: Maybe<Scalars['String']>;
  /** 예금주명 */
  accountHolder?: Maybe<Scalars['String']>;
  /** submallStatus */
  submallStatus?: Maybe<CapaSubmallStatus>;
  /** 상호명 */
  submallName?: Maybe<Scalars['String']>;
};

export type CapaQuotationPartnerStrategyBoOutput = {
  __typename?: 'CAPAQuotationPartnerStrategyBOOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaQuotationPartnerStrategyBo>;
};

export type CapaQuotationPartnerStrategyFile = {
  __typename?: 'CAPAQuotationPartnerStrategyFile';
  /** strategy file id */
  id: Scalars['ID'];
  /** fileId */
  fileId?: Maybe<Scalars['ID']>;
  /** videoThumbnailFileId */
  videoThumbnailFileId?: Maybe<Scalars['ID']>;
  /** fileName */
  fileName?: Maybe<Scalars['String']>;
  /** fileType */
  fileType?: Maybe<CapaQuotationPartnerStrategyFileType>;
  /** mimeType */
  fileMimetype?: Maybe<Scalars['String']>;
  /** fileSize */
  fileSize?: Maybe<Scalars['String']>;
  /** fileExtension */
  fileExtension?: Maybe<Scalars['String']>;
  /** youtubeURL */
  youtubeURL?: Maybe<Scalars['String']>;
};

/** 파트너 기술 관련 파일 상태 */
export enum CapaQuotationPartnerStrategyFileType {
  /** 인증 관련 파일 */
  CertificationFile = 'CERTIFICATION_FILE',
  /** 특허 관련 파일 */
  PatentFile = 'PATENT_FILE',
  /** 이미지 포토폴리오 관련 파일 */
  PortfolioFile = 'PORTFOLIO_FILE',
  /** 장비 관련 파일 */
  EquipmentFile = 'EQUIPMENT_FILE',
  /** 로고 파일 */
  Logo = 'LOGO',
  /** 사업자등록증 파일 */
  Registration = 'REGISTRATION',
  /** 파트너 파일(사진 및 동영상 파일) */
  PartnerFile = 'PARTNER_FILE',
  /** 유튜브 URL */
  YoutubeUrl = 'YOUTUBE_URL',
  /** 커버이미지 */
  ImageCover = 'IMAGE_COVER',
  /** 통장 사본 */
  Bankbook = 'BANKBOOK',
  /** 회사 소개서 */
  Intoduction = 'INTODUCTION'
}

/** 파트너 파일 정보 입력 */
export type CapaQuotationPartnerStrategyFileUploadInput = {
  /** fk 캐파 파일 아이디 */
  fileId?: Maybe<Scalars['String']>;
  /** videoThumbnailFileId  아이디 */
  videoThumbnailFileId?: Maybe<Scalars['String']>;
  /** youtube url */
  youtubeURL?: Maybe<Scalars['String']>;
};

/** CAPAQuotationPartnerStrategyOutput */
export type CapaQuotationPartnerStrategyOutput = {
  __typename?: 'CAPAQuotationPartnerStrategyOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaQuotationPartnerStrategy>;
};

/** 파트너 파일 정보 입력 */
export type CapaQuotationPartnerStrategyRegistrationFileUploadInput = {
  /** fk 캐파 파일 아이디 */
  fileId?: Maybe<Scalars['String']>;
};

export type CapaQuotationPartnerTechnologyInput = {
  /** fk 캐파 파일 아이디 */
  fileId: Scalars['String'];
  /** 인증 /특허 명 */
  technicalName: Scalars['String'];
  /** 발급 기관 */
  certificationAuthority: Scalars['String'];
  /** 유효 기간 시작일 */
  expirationPeriodStart?: Maybe<Scalars['String']>;
  /** 유효 기간 종료일 */
  expirationPeriodEnd?: Maybe<Scalars['String']>;
  /** 설명 */
  description?: Maybe<Scalars['String']>;
};

export type CapaQuotationPartnerTechnologyModel = {
  __typename?: 'CAPAQuotationPartnerTechnologyModel';
  /** capa_quotation_partner_technology 테이블 id */
  id: Scalars['ID'];
  /** fk CAPAQuotationPartnerEntity#id */
  quotationPartnerId: Scalars['ID'];
  /** fk CAPAQuotationPartnerStrategyFileEntity#id */
  strategyFileId?: Maybe<Scalars['ID']>;
  /** file */
  file?: Maybe<CapaQuotationPartnerStrategyFile>;
  /** 인증 /특허 명 */
  technicalName?: Maybe<Scalars['String']>;
  /** 발급 기관 */
  certificationAuthority?: Maybe<Scalars['String']>;
  /** 유효 기간 시작일 */
  expirationPeriodStart?: Maybe<Scalars['String']>;
  /** 유효 기간 종료일 */
  expirationPeriodEnd?: Maybe<Scalars['String']>;
  /** 설명 */
  description?: Maybe<Scalars['String']>;
  /** 파일 종류 ex) 인증 / 특허 */
  technologyType: CapaQuotationPartnerTechnologyType;
};

/** 파트너 기술 관련 파일 상태 */
export enum CapaQuotationPartnerTechnologyType {
  /** 인증 */
  Certification = 'CERTIFICATION',
  /** 특허 */
  Patent = 'PATENT'
}

/** 파트너가 읽지 않은 메세지들의 수 (채팅방의 진척도에 따라 구분) */
export type CapaQuotationPartnerUnReadMessagesCount = {
  __typename?: 'CAPAQuotationPartnerUnReadMessagesCount';
  /** 해당 채팅방의 진척도 */
  chatRoomFilter?: Maybe<CapaQuotationChatRoomsFilterType>;
  /** 읽지 않은 메세지들의 총 수  */
  total?: Maybe<Scalars['Float']>;
};

export enum CapaQuotationPartnerUserStatus {
  /** 승인 */
  Approved = 'APPROVED',
  /** 맴버가입됨 */
  Applied = 'APPLIED',
  /** 삭제 */
  Deleted = 'DELETED',
  /** 거절 */
  Rejected = 'REJECTED'
}

export type CapaQuotationPartnersProjectParticipationStatusBo = {
  __typename?: 'CAPAQuotationPartnersProjectParticipationStatusBO';
  projectParticipationStatus?: Maybe<
    Array<CapaQuotationPartnerProjectParticipationStatusBo>
  >;
  quotationSuggestionCount?: Maybe<Scalars['Float']>;
  introductionSuggestionCount?: Maybe<Scalars['Float']>;
  rfqReceivedQuotationCount?: Maybe<Scalars['Float']>;
  openedChatroomCount?: Maybe<Scalars['Float']>;
  closedChatroomCount?: Maybe<Scalars['Float']>;
  purchaseOrderCount?: Maybe<Scalars['Float']>;
  rejectedPurchaseOrderCount?: Maybe<Scalars['Float']>;
  paymentCount?: Maybe<Scalars['Float']>;
};

export type CapaQuotationPartnersProjectParticipationStatusBoOutput = {
  __typename?: 'CAPAQuotationPartnersProjectParticipationStatusBOOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaQuotationPartnersProjectParticipationStatusBo>;
};

export type CapaQuotationPaymentManage = {
  __typename?: 'CAPAQuotationPaymentManage';
  purchaseOrderInfoList?: Maybe<Array<CapaPurchaseOrderModel>>;
  estimatedAmount?: Maybe<Scalars['Float']>;
  completionAmount?: Maybe<Scalars['Float']>;
  totalCount?: Maybe<Scalars['Float']>;
  point?: Maybe<Scalars['Float']>;
};

/** CAPAQuotationPaymentManageModel */
export type CapaQuotationPaymentManageModel = {
  __typename?: 'CAPAQuotationPaymentManageModel';
  id: Scalars['String'];
  quotationId: Scalars['String'];
  /** quotation user id */
  quotationUserId: Scalars['String'];
  /** quotation partner user id */
  quotationPartnerUserId: Scalars['String'];
  /** quotation partner id */
  quotationPartnerId: Scalars['String'];
  /** rfq id */
  rfqId: Scalars['String'];
  /** purchase order id */
  purchaseOrderId?: Maybe<Scalars['String']>;
  /** total amount */
  totalAmount: Scalars['Float'];
  /** commission */
  commission?: Maybe<Scalars['Float']>;
  paymentMethod: CapaPaymentMethodType;
  status: CapaPaymentStatus;
  isRead: Scalars['Boolean'];
  virtualAccountId?: Maybe<Scalars['String']>;
  /** 결제 승인 날짜 */
  paidAt?: Maybe<Scalars['String']>;
  /** 지급승인(구매확정) 날짜 */
  confirmedAt?: Maybe<Scalars['String']>;
  /**
   * 결제망 이용료
   *     - VAT 포함 3.63% 만큼 수수료를 받는데..
   *     가상의 1000원을 카드결제시  1000 ->   1.1 % 인 11원 (나이스페이에 지불) → pgUsageFee
   *                           1000 →    2.53% 인 25원 (캐파에게 지불) → capaUsageFee
   *     -  가상계좌시 무조건 결제망 수수료를 1100원으로 잡고
   *     330원 차감→  pgUsageFee  ( 300원 (가상계좌생성비용 )+ 30 (부가세) 차감)
   *     770원 → capaUsageFee ( 1100 - 330 )
   */
  capaUsageFee?: Maybe<Scalars['Float']>;
  /**
   * 결제망 이용료
   *     - VAT 포함 3.63% 만큼 수수료를 받는데..
   *     가상의 1000원을 카드결제시  1000 ->   1.1 % 인 11원 (나이스페이에 지불) → pgUsageFee
   *                           1000 →    2.53% 인 25원 (캐파에게 지불) → capaUsageFee
   *     -  가상계좌시 무조건 결제망 수수료를 1100원으로 잡고
   *     330원 차감→  pgUsageFee  ( 300원 (가상계좌생성비용 )+ 30 (부가세) 차감)
   *     770원 → capaUsageFee ( 1100 - 330 )
   */
  pgUsageFee?: Maybe<Scalars['Float']>;
  /** 파트너에게 지급되는 돈 payOut = totalAmount - commission - capaUsageFee - pgUsageFee  */
  payOut?: Maybe<Scalars['Float']>;
  cancelId?: Maybe<Scalars['String']>;
  cancelStatus?: Maybe<CapaPaymentCancelStatus>;
  cancelAmount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<Scalars['String']>;
  /** 결제 취소확정일 */
  cancelConfirmedAt?: Maybe<Scalars['String']>;
  /** 생성일 */
  createdAt?: Maybe<Scalars['String']>;
  /** 수정일 */
  updatedAt?: Maybe<Scalars['String']>;
  thumbnailId?: Maybe<Scalars['String']>;
  chatRoomId?: Maybe<Scalars['String']>;
  virtualBankCode?: Maybe<CapaBankCode>;
  virtualAccountNumber?: Maybe<Scalars['String']>;
  /** 해당 발주건에 대한 리뷰 존재 유무 */
  hasReview: Scalars['Boolean'];
  /** 배송 지연 이유 */
  extendReason?: Maybe<Scalars['String']>;
  /** 자동 구매확정 예정일 */
  registerExpectedDate?: Maybe<Scalars['String']>;
  /** 카드번호 */
  cardNumber?: Maybe<Scalars['String']>;
  /** 카드이름 */
  cardName?: Maybe<Scalars['String']>;
  /** 구매확정일 연장 10영업일 + 6영업일 */
  extendedPurchaseConfirmDate?: Maybe<Scalars['String']>;
};

export type CapaQuotationPaymentManageModelOutput = {
  __typename?: 'CAPAQuotationPaymentManageModelOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaQuotationPaymentManage>;
};

/** Product Purpose */
export enum CapaQuotationProductPurpose {
  AutomobileTransportation = 'AUTOMOBILE_TRANSPORTATION',
  ManufacturinEquipmentIndustrialMachineAndParts = 'MANUFACTURIN_EQUIPMENT_INDUSTRIAL_MACHINE_AND_PARTS',
  RoboticsDroneIotSmartDevice = 'ROBOTICS_DRONE_IOT_SMART_DEVICE',
  MedicalHealthcare = 'MEDICAL_HEALTHCARE',
  SemiconductorElectronicBoards = 'SEMICONDUCTOR_ELECTRONIC_BOARDS',
  OpticsMaterials = 'OPTICS_MATERIALS',
  HouseholdAppliance = 'HOUSEHOLD_APPLIANCE',
  ExperimentInspectionDiagnosisMeasuring = 'EXPERIMENT_INSPECTION_DIAGNOSIS_MEASURING',
  EnergyBatteries = 'ENERGY_BATTERIES',
  BroadcastCommunicationMultimedia = 'BROADCAST_COMMUNICATION_MULTIMEDIA',
  ResearchAndDevelopment = 'RESEARCH_AND_DEVELOPMENT',
  Aerospace = 'AEROSPACE',
  MilitarySafety = 'MILITARY_SAFETY',
  OceanIndustryShipbuilding = 'OCEAN_INDUSTRY_SHIPBUILDING',
  AgricultureFarming = 'AGRICULTURE_FARMING',
  GadgetsPartsComponents = 'GADGETS_PARTS_COMPONENTS',
  KitchenFood = 'KITCHEN_FOOD',
  EducationToys = 'EDUCATION_TOYS',
  SportsOutdoors = 'SPORTS_OUTDOORS',
  FashionBeauty = 'FASHION_BEAUTY',
  InteriorLiving = 'INTERIOR_LIVING',
  Furniture = 'FURNITURE',
  Pets = 'PETS',
  PropsOfficeSupplies = 'PROPS_OFFICE_SUPPLIES',
  ArtsExhibition = 'ARTS_EXHIBITION',
  ArchitectureConstruction = 'ARCHITECTURE_CONSTRUCTION',
  PersonalHobby = 'PERSONAL_HOBBY',
  Etc = 'ETC'
}

/** 캐파 견적 서비스 타입 */
export enum CapaQuotationRecommendation {
  /** 강도가 중요해요 */
  Strength = 'STRENGTH',
  /** 열 변형이 적어야해요 */
  ThermalStrain = 'THERMAL_STRAIN',
  /** 내부식성이 중요해요 */
  CorrosionResistant = 'CORROSION_RESISTANT',
  /** 가벼워야해요 */
  Light = 'LIGHT',
  /** 상담 후 결정할게요 */
  DecideAfterConsulting = 'DECIDE_AFTER_CONSULTING',
  /** 기타(직접입력) */
  Etc = 'ETC',
  /** 표면품질이 중요해요 */
  SurfaceQuality = 'SURFACE_QUALITY',
  /** 유연성이 중요해요 */
  Flexibility = 'FLEXIBILITY',
  /** 공차가 중요해요 */
  Allowance = 'ALLOWANCE',
  /** 내충격성이 중요해요 */
  ShockResistance = 'SHOCK_RESISTANCE',
  /** 탄성이 높아야해요 */
  Elasticity = 'ELASTICITY'
}

/** CAPA RFQ Allow Open FILE Status */
export enum CapaQuotationRfqAllowOpenFileStatus {
  /** allowed */
  Allow = 'ALLOW',
  /** denied */
  Denied = 'DENIED',
  /** request */
  Request = 'REQUEST'
}

/** CAPAQuotationRfqAssemblyFile */
export type CapaQuotationRfqAssemblyFile = {
  __typename?: 'CAPAQuotationRfqAssemblyFile';
  fileId: Scalars['String'];
  fileName: Scalars['String'];
  quantity: Scalars['Float'];
  fileSize?: Maybe<Scalars['Float']>;
};

export type CapaQuotationRfqAvailableRfq = {
  __typename?: 'CAPAQuotationRfqAvailableRfq';
  id: Scalars['String'];
  isFavorite?: Maybe<Scalars['Boolean']>;
  thumbnailId?: Maybe<Scalars['String']>;
  projectName: Scalars['String'];
  organizationName?: Maybe<Scalars['String']>;
  managerName?: Maybe<Scalars['String']>;
  shortAddress?: Maybe<Scalars['String']>;
  serviceType: CapaQuotationServiceType;
  quantity?: Maybe<Scalars['Float']>;
  uniqueViews?: Maybe<Scalars['Float']>;
  chatRooms?: Maybe<Scalars['Float']>;
  isNew: Scalars['Boolean'];
  createdAt: Scalars['String'];
  status: CapaRfqStatus;
  detailedServiceType?: Maybe<CapaQuotationDetailedServiceType>;
  pageViews?: Maybe<Scalars['Float']>;
  eventType?: Maybe<CapaQuotationRfqEventType>;
};

export type CapaQuotationRfqAvailableRfqs = {
  __typename?: 'CAPAQuotationRfqAvailableRfqs';
  count: Scalars['Float'];
  rfqs?: Maybe<Array<CapaQuotationRfqAvailableRfq>>;
};

export type CapaQuotationRfqAvailableRfqsOutput = {
  __typename?: 'CAPAQuotationRfqAvailableRfqsOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaQuotationRfqAvailableRfqs>;
};

export type CapaQuotationRfqDetail = {
  __typename?: 'CAPAQuotationRfqDetail';
  id: Scalars['String'];
  status?: Maybe<CapaRfqStatus>;
  isOldRfq?: Maybe<Scalars['Boolean']>;
  projectName?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['String']>;
  detailedRequest?: Maybe<Scalars['String']>;
  serviceType?: Maybe<CapaQuotationServiceType>;
  detailedServiceType?: Maybe<CapaQuotationDetailedServiceType>;
  optionalServices?: Maybe<Array<CapaQuotationOptionalService>>;
  dueNegotiable?: Maybe<Scalars['Boolean']>;
  maxEstimatedBudget?: Maybe<Scalars['Float']>;
  minEstimatedBudget?: Maybe<Scalars['Float']>;
  budgetOpen?: Maybe<Scalars['Boolean']>;
  projectPurpose?: Maybe<CapaQuotationProductPurpose>;
  projectPurposeDetail?: Maybe<Scalars['String']>;
  publicOpen?: Maybe<Scalars['Boolean']>;
  eventType?: Maybe<CapaQuotationRfqEventType>;
  manufacturingStage?: Maybe<CapaRfqManufacturingStage>;
  drawingFiles?: Maybe<Array<CapaQuotationRfqDrawingFile>>;
  referenceFiles?: Maybe<Array<CapaQuotationRfqReferenceFile>>;
  pcb?: Maybe<CapaPcbDetail>;
  design?: Maybe<CapaDesignDetail>;
  createdAt: Scalars['String'];
  consultingStartMethod?: Maybe<CapaConsultingStartMethod>;
  transaction?: Maybe<CapaQuotationRfqTransaction>;
  owner?: Maybe<CapaQuotationUser>;
  quotation?: Maybe<CapaQuotationRfqQuotation>;
  chatRoom?: Maybe<CapaQuotationChatRoom>;
  canDownload?: Maybe<Scalars['Boolean']>;
  favorite?: Maybe<Scalars['Boolean']>;
  isSendQuotation?: Maybe<Scalars['Boolean']>;
  isSendIntroduction?: Maybe<Scalars['Boolean']>;
};

export type CapaQuotationRfqDetailOutput = {
  __typename?: 'CAPAQuotationRfqDetailOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaQuotationRfqDetail>;
};

export type CapaQuotationRfqDrawingFile = {
  __typename?: 'CAPAQuotationRfqDrawingFile';
  fileId: Scalars['String'];
  fileName?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  material?: Maybe<CapaQuotationMaterial>;
  detailedMaterial?: Maybe<CapaQuotationDetailedMaterialType>;
  etc?: Maybe<Scalars['String']>;
  recommendation?: Maybe<Array<CapaQuotationRecommendation>>;
  fileSize?: Maybe<Scalars['Float']>;
};

/** CAPA RFQ EVENT TYPE */
export enum CapaQuotationRfqEventType {
  /** 일반적인 rfq */
  General = 'GENERAL',
  /** ITP 이벤트 타입 */
  Itp = 'ITP',
  /** 졸사빠 이벤트 타입 */
  Jsb = 'JSB',
  /** ITPX졸사빠 이벤트 타입 */
  Itpjsb = 'ITPJSB'
}

/** CAPAQuotationRfqFabricationFile */
export type CapaQuotationRfqFabricationFile = {
  __typename?: 'CAPAQuotationRfqFabricationFile';
  fileId: Scalars['String'];
  fileName: Scalars['String'];
  quantity: Scalars['Float'];
  thickness: Scalars['Float'];
  fileSize?: Maybe<Scalars['Float']>;
};

/** RfqFilter 정보 입력 */
export type CapaQuotationRfqFilterInput = {
  /** 캐파 견적 서비스 정보 입력 */
  services?: Maybe<Array<CapaRfqFilterQuotationServiceInput>>;
};

export type CapaQuotationRfqMyCompleteRfq = {
  __typename?: 'CAPAQuotationRfqMyCompleteRfq';
  id: Scalars['String'];
  projectName: Scalars['String'];
  organizationName: Scalars['String'];
  managerName: Scalars['String'];
  serviceType: CapaQuotationServiceType;
  thumbnailId?: Maybe<Scalars['String']>;
  confirmedAt: Scalars['String'];
  transactionComplete?: Maybe<Scalars['Boolean']>;
  completeAt?: Maybe<Scalars['String']>;
  reviewed: Scalars['Boolean'];
};

export type CapaQuotationRfqMyCompleteRfqOutput = {
  __typename?: 'CAPAQuotationRfqMyCompleteRfqOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<Array<CapaQuotationRfqMyCompleteRfq>>;
};

export type CapaQuotationRfqMyRfq = {
  __typename?: 'CAPAQuotationRfqMyRfq';
  id: Scalars['String'];
  projectName: Scalars['String'];
  organizationName?: Maybe<Scalars['String']>;
  shortAddress?: Maybe<Scalars['String']>;
  serviceType: CapaQuotationServiceType;
  detailedServiceType?: Maybe<CapaQuotationDetailedServiceType>;
  createdAt?: Maybe<Scalars['String']>;
  status: CapaRfqStatus;
  thumbnailId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  chatRoomStatus?: Maybe<CapaQuotationChatRoomStatus>;
  message?: Maybe<CapaQuotationChatMessageUnion>;
  unReadCount?: Maybe<Scalars['Float']>;
  eventType?: Maybe<CapaQuotationRfqEventType>;
};

export type CapaQuotationRfqMyRfqs = {
  __typename?: 'CAPAQuotationRfqMyRfqs';
  count: Scalars['Float'];
  rfqs?: Maybe<Array<CapaQuotationRfqMyRfq>>;
};

export type CapaQuotationRfqMyRfqsOutput = {
  __typename?: 'CAPAQuotationRfqMyRfqsOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaQuotationRfqMyRfqs>;
};

export type CapaQuotationRfqPartner = {
  __typename?: 'CAPAQuotationRfqPartner';
  chatRoomId: Scalars['String'];
  quotationPartnerId: Scalars['String'];
  organizationName?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  hasEvaluated?: Maybe<Scalars['Boolean']>;
  isNew?: Maybe<Scalars['Boolean']>;
  permission?: Maybe<CapaQuotationRfqAllowOpenFileStatus>;
  downloaded?: Maybe<Scalars['Boolean']>;
};

export type CapaQuotationRfqPartnerEvaluation = {
  __typename?: 'CAPAQuotationRfqPartnerEvaluation';
  kind?: Maybe<Scalars['Float']>;
  quick?: Maybe<Scalars['Float']>;
  detailed?: Maybe<Scalars['Float']>;
  helpful?: Maybe<Scalars['Float']>;
};

export type CapaQuotationRfqPartnerEvaluationOutput = {
  __typename?: 'CAPAQuotationRfqPartnerEvaluationOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaQuotationRfqPartnerEvaluation>;
};

export type CapaQuotationRfqPartnersOutput = {
  __typename?: 'CAPAQuotationRfqPartnersOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<Array<CapaQuotationRfqPartner>>;
};

export type CapaQuotationRfqPurchaseOrder = {
  __typename?: 'CAPAQuotationRfqPurchaseOrder';
  id: Scalars['String'];
  rfqId: Scalars['String'];
  projectName: Scalars['String'];
  chatRoomId?: Maybe<Scalars['String']>;
  /** 파트너 정보 */
  receiver?: Maybe<CapaQuotationRfqPurchaseOrderReceiver>;
  /** 고객정보 */
  publish?: Maybe<CapaQuotationRfqPurchaseOrderPublish>;
  pcb?: Maybe<CapaPcbDetail>;
  /** (견적서) */
  items?: Maybe<Array<CapaQuotationDetailedQuotationItem>>;
  /** pcb -제작 */
  pcbFabricationItems?: Maybe<Array<CapaQuotationDetailedPcbFabricationItem>>;
  /** pcb - 조립 */
  pcbAssemblyItems?: Maybe<Array<CapaQuotationDetailedPcbAssemblyItem>>;
  /** 공급가액(견적서) */
  quotation?: Maybe<Scalars['Float']>;
  /** 배송비(견적서) */
  deliveryCost?: Maybe<Scalars['Float']>;
  /** 최대 시안 수정 횟수(견적서) */
  maxDraftModificationCount?: Maybe<Scalars['Float']>;
  /** 수정 범위 및 추가 과금 기준(견적서) */
  draftModificationTerms?: Maybe<Scalars['String']>;
  /** 검수 기간 */
  inspectionPeriod?: Maybe<Scalars['Float']>;
  /** 검수 항목 */
  inspections?: Maybe<Array<CapaQuotationRfqPurchaseOrderInspection>>;
  /** 납품일 */
  shipDate?: Maybe<Scalars['String']>;
  /** 납품주소 */
  shipAddress?: Maybe<Scalars['String']>;
  /** 납품상세주소 */
  shipDetailAddress?: Maybe<Scalars['String']>;
  /** 이관 정보 */
  transfer?: Maybe<CapaRfqPurchaseOrderTransfer>;
  /** 결제 정보 */
  payments?: Maybe<Array<CapaQuotationRfqPurchaseOrderProvision>>;
  read?: Maybe<Scalars['Boolean']>;
  /** 발주서 확정 */
  isConfirmed?: Maybe<Scalars['Boolean']>;
  /** 수정 요청서 읽음 및 내용 */
  adjustRequest?: Maybe<CapaRfqAdjustRequestObject>;
  isRejected?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['String']>;
  quotationCreatedAt?: Maybe<Scalars['String']>;
  /** 최신 발주서 */
  latest?: Maybe<Scalars['Boolean']>;
  /** 발주서 상태 */
  status: CapaPurchaseOrderStatus;
  /** 결제 대금 */
  paymentAmount?: Maybe<Scalars['Float']>;
  /** 취소,환불,거절 정보 */
  paymentCancelData?: Maybe<FetchPurchaseOrderPaymentCancelData>;
  /** 결제 정보 */
  paymentDataList?: Maybe<Array<FetchPurchaseOrderPaymentData>>;
  /** 배송 정보 */
  shippingDataList?: Maybe<Array<ShippingDataModel>>;
  detailService?: Maybe<CapaQuotationDetailedServiceType>;
  etc?: Maybe<Scalars['String']>;
};

export type CapaQuotationRfqPurchaseOrderInspection = {
  __typename?: 'CAPAQuotationRfqPurchaseOrderInspection';
  /** 검수 조건 */
  inspectionType?: Maybe<CapaRfqPurchaseOrderInspectionType>;
  /** 검수 요청 사항 */
  inspectionRequest?: Maybe<Scalars['String']>;
};

export type CapaQuotationRfqPurchaseOrderOutput = {
  __typename?: 'CAPAQuotationRfqPurchaseOrderOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaQuotationRfqPurchaseOrder>;
};

export type CapaQuotationRfqPurchaseOrderProvision = {
  __typename?: 'CAPAQuotationRfqPurchaseOrderProvision';
  /** 지급 날짜 */
  provisionDate?: Maybe<Scalars['String']>;
  /** 지급 비율 */
  provisionPercent?: Maybe<Scalars['Float']>;
  /** 지급 금액 */
  provisionCost?: Maybe<Scalars['Float']>;
  /** 지급 타입 */
  provisionType?: Maybe<CapaRfqPurchaseOrderPaymentProvisionType>;
  /** 기타(직접입력) */
  provisionEtc?: Maybe<Scalars['String']>;
};

export type CapaQuotationRfqPurchaseOrderPublish = {
  __typename?: 'CAPAQuotationRfqPurchaseOrderPublish';
  /** 고객 상태 */
  quotationUserStatus?: Maybe<CapaQuotationUserStatus>;
  /** 고객 회사명 */
  organizationName?: Maybe<Scalars['String']>;
  /** 담당자명 */
  managerName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  /** 사업자등록번호 */
  companyRegistrationNumber?: Maybe<Scalars['String']>;
};

export type CapaQuotationRfqPurchaseOrderReceiver = {
  __typename?: 'CAPAQuotationRfqPurchaseOrderReceiver';
  /** 파트너 id */
  quotationPartnerId: Scalars['String'];
  /** 파트너 상태 */
  quotationPartnerStatus: CapaQuotationPartnerStatus;
  /** 파트너 회사명 */
  organizationName?: Maybe<Scalars['String']>;
  /** 파트너 주소 */
  address?: Maybe<Scalars['String']>;
  /** 담당자명 */
  managerName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  /** 사업자등록번호 */
  companyRegistrationNumber?: Maybe<Scalars['String']>;
};

export type CapaQuotationRfqPurchaseOrdersOutput = {
  __typename?: 'CAPAQuotationRfqPurchaseOrdersOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<Array<CapaQuotationRfqPurchaseOrder>>;
};

export type CapaQuotationRfqQuotation = {
  __typename?: 'CAPAQuotationRfqQuotation';
  id: Scalars['ID'];
  rfqId?: Maybe<Scalars['String']>;
  messageId?: Maybe<Scalars['String']>;
  quotationPartnerId?: Maybe<Scalars['String']>;
  /** 견적가 */
  quotation: Scalars['Float'];
  excludeDeliveryFee?: Maybe<Scalars['Boolean']>;
};

export type CapaQuotationRfqQuotationOptionalService = {
  __typename?: 'CAPAQuotationRfqQuotationOptionalService';
  serviceType?: Maybe<CapaQuotationServiceType>;
};

export type CapaQuotationRfqReferenceFile = {
  __typename?: 'CAPAQuotationRfqReferenceFile';
  fileId: Scalars['String'];
  fileName: Scalars['String'];
  fileSize?: Maybe<Scalars['Float']>;
};

export type CapaQuotationRfqReview = {
  __typename?: 'CAPAQuotationRfqReview';
  dueDate?: Maybe<Scalars['Float']>;
  quality?: Maybe<Scalars['Float']>;
  publicComment?: Maybe<Scalars['String']>;
  privateComment?: Maybe<Scalars['String']>;
  replyComment?: Maybe<Scalars['String']>;
  repliedAt?: Maybe<Scalars['String']>;
};

export type CapaQuotationRfqSearch = {
  __typename?: 'CAPAQuotationRfqSearch';
  rfqId?: Maybe<Scalars['String']>;
  thumbnailId?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  shortAddress?: Maybe<Scalars['String']>;
  serviceType?: Maybe<CapaQuotationServiceType>;
  detailedServiceType?: Maybe<CapaQuotationDetailedServiceType>;
  detailedRequest?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  chatRooms?: Maybe<Scalars['Float']>;
  eventType?: Maybe<CapaQuotationRfqEventType>;
};

export type CapaQuotationRfqTransaction = {
  __typename?: 'CAPAQuotationRfqTransaction';
  rfqId: Scalars['String'];
  quotationPartnerId: Scalars['String'];
  organizationName?: Maybe<Scalars['String']>;
  isItp?: Maybe<Scalars['Boolean']>;
  transactionComplete?: Maybe<Scalars['Boolean']>;
  completeCost?: Maybe<Scalars['Float']>;
  completeAt?: Maybe<Scalars['String']>;
};

export type CapaQuotationSearch = {
  __typename?: 'CAPAQuotationSearch';
  rfqs: Array<CapaQuotationRfqSearch>;
  messages: Array<CapaQuotationMessaegeSearch>;
};

export type CapaQuotationSearchOutput = {
  __typename?: 'CAPAQuotationSearchOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaQuotationSearch>;
};

export type CapaQuotationService = {
  __typename?: 'CAPAQuotationService';
  service?: Maybe<CapaQuotationServiceType>;
  status: CapaCapabilitiesStatusType;
  detailedServices?: Maybe<Array<CapaQuotationDetailedService>>;
  optionalServices?: Maybe<Array<CapaQuotationServiceType>>;
};

/** 캐파 견적 서비스 정보 입력 */
export type CapaQuotationServiceInput = {
  /** 캐파 견적 서비스 타입 */
  service?: Maybe<CapaQuotationServiceType>;
  /** 해당 serviceType의 상태값 */
  status: CapaCapabilitiesStatusType;
  /** 캐파 견적 상세 서비스 정보 입력 */
  detailedServices?: Maybe<Array<CapaQuotationDetailedServiceInput>>;
  /** 추가 서비스 타입 (벤딩, 용접, 시제품, 제품디자인 */
  optionalServices?: Maybe<Array<CapaQuotationServiceType>>;
};

export type CapaQuotationServiceRating = {
  __typename?: 'CAPAQuotationServiceRating';
  rated: Scalars['Boolean'];
  rating?: Maybe<Scalars['Float']>;
  comment?: Maybe<Scalars['String']>;
};

export type CapaQuotationServiceRatingOutput = {
  __typename?: 'CAPAQuotationServiceRatingOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaQuotationServiceRating>;
};

/** 캐파 견적 서비스 타입 */
export enum CapaQuotationServiceType {
  /** CNC */
  Cnc = 'CNC',
  /** 3D 프린터 */
  Printing = 'PRINTING',
  /** 금형사출 */
  Inj = 'INJ',
  /** 판금가공 */
  SheetMetal = 'SHEET_METAL',
  /** 기구설계 */
  MechanicalDesign = 'MECHANICAL_DESIGN',
  /** 주조 */
  Casting = 'CASTING',
  /** 용접 판금 가공 */
  Weilding = 'WEILDING',
  /** 벤딩 판금 가공 */
  Bending = 'BENDING',
  /** 제품 디자인 설계 */
  ProductDesign = 'PRODUCT_DESIGN',
  /** 시제품 제작 설계 */
  Prototyping = 'PROTOTYPING',
  /** 전자회로 */
  Pcb = 'PCB',
  /** 디자인/설계 */
  Design = 'DESIGN'
}

/** CAPAQuotationSharedFileAnnotationStatus */
export enum CapaQuotationSharedFileAnnotationStatus {
  /** REGISTERED */
  Registered = 'REGISTERED',
  /** DELETED */
  Deleted = 'DELETED'
}

export type CapaQuotationSimpleQuotation = {
  __typename?: 'CAPAQuotationSimpleQuotation';
  quotation?: Maybe<Scalars['Float']>;
  excludeDeliveryFee?: Maybe<Scalars['Boolean']>;
  etc?: Maybe<Scalars['String']>;
};

export type CapaQuotationSpecificAnnotationComment = {
  __typename?: 'CAPAQuotationSpecificAnnotationComment';
  id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  isParent?: Maybe<Scalars['Boolean']>;
  comment?: Maybe<Scalars['String']>;
  status?: Maybe<CapaQuotationSharedFileAnnotationStatus>;
  edited?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type CapaQuotationUpdate = {
  __typename?: 'CAPAQuotationUpdate';
  email?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  chatRoomId?: Maybe<Scalars['String']>;
  quotationSharedFileId?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  nodeId?: Maybe<Scalars['Float']>;
  anchorPos?: Maybe<Capa3DPos>;
  handlePos?: Maybe<Capa3DPos>;
  annotationId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  commentId?: Maybe<Scalars['String']>;
  annotationCreatedAt?: Maybe<Scalars['String']>;
  annotationUpdatedAt?: Maybe<Scalars['String']>;
  commentCreatedAt?: Maybe<Scalars['String']>;
  commentUpdatedAt?: Maybe<Scalars['String']>;
  status?: Maybe<CapaQuotationSharedFileAnnotationStatus>;
  /** 업데이트 타입 */
  type?: Maybe<CapaQuotationUpdateType>;
};

export type CapaQuotationUpdateOutput = {
  __typename?: 'CAPAQuotationUpdateOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaQuotationUpdate>;
};

export enum CapaQuotationUpdateType {
  AnnotationAdd = 'ANNOTATION_ADD',
  AnnotationUpdate = 'ANNOTATION_UPDATE',
  AnnotationDelete = 'ANNOTATION_DELETE',
  AnnotationOpen = 'ANNOTATION_OPEN',
  AnnotationCommentAdd = 'ANNOTATION_COMMENT_ADD',
  AnnotationCommentUpdate = 'ANNOTATION_COMMENT_UPDATE',
  AnnotationCommentDelete = 'ANNOTATION_COMMENT_DELETE'
}

export type CapaQuotationUser = {
  __typename?: 'CAPAQuotationUser';
  id: Scalars['ID'];
  email: Scalars['String'];
  /** 상태 CAPAQuotationUserStatus */
  status: CapaQuotationUserStatus;
  /** 이름 (담당자명) */
  managerName?: Maybe<Scalars['String']>;
  /** 휴대폰 번호 */
  cellphoneNumber?: Maybe<Scalars['String']>;
  /** 주소 */
  fullAddress?: Maybe<Scalars['String']>;
  /** 짧은 주소 */
  shortAddress?: Maybe<Scalars['String']>;
  /** 사업자등록번호 */
  companyRegistrationNumber?: Maybe<Scalars['String']>;
  /** 사업자 여부 */
  bizType?: Maybe<CapaQuotationUserBizType>;
  notificationFilter?: Maybe<CapaQuotationUserNotificationFilter>;
  /** 회사 / 단체명 */
  organizationName?: Maybe<Scalars['String']>;
  /** 회사소개 */
  companyIntroduction?: Maybe<Scalars['String']>;
  /** 웹사이트 */
  website?: Maybe<Scalars['String']>;
  /** 부서 */
  department?: Maybe<Scalars['String']>;
  /** 직함 */
  position?: Maybe<Scalars['String']>;
  /** [필수] 캐파 서비스 이용약관 동의 */
  serviceAgreed?: Maybe<Scalars['Boolean']>;
  /** [필수] 개인정보 수집 및 활용 동의 */
  privacyAgreed?: Maybe<Scalars['Boolean']>;
  /** [선택] 프로모션 정보 수신 동의 */
  promotionAgreed?: Maybe<Scalars['Boolean']>;
  /** [필수] 결제 서비스 이용 약관 동의 */
  paymentAgreed?: Maybe<Scalars['Boolean']>;
  /** 추천인 ID */
  referralUserId?: Maybe<Scalars['String']>;
  /** 생성일 */
  createdAt: Scalars['String'];
  /** 수정일 */
  updatedAt: Scalars['String'];
  files?: Maybe<Array<CapaQuotationUserFile>>;
  /** 본인인증 상태 */
  verificationType?: Maybe<CapaQuotationUserVerificationType>;
  /** 본인인증 이름 */
  verificationName?: Maybe<Scalars['String']>;
  /** 본인인증 생년월일 */
  verificationBirthDate?: Maybe<Scalars['String']>;
  /** 본인의 환불계좌 정보 */
  myRefundAccount?: Maybe<FetchMyAccountModel>;
};

export type CapaQuotationUserBo = {
  __typename?: 'CAPAQuotationUserBO';
  id: Scalars['ID'];
  email: Scalars['String'];
  /** 상태 CAPAQuotationUserStatus */
  status: CapaQuotationUserStatus;
  /** 이름 (담당자명) */
  managerName?: Maybe<Scalars['String']>;
  /** 휴대폰 번호 */
  cellphoneNumber?: Maybe<Scalars['String']>;
  /** 주소 */
  fullAddress?: Maybe<Scalars['String']>;
  /** 짧은 주소 */
  shortAddress?: Maybe<Scalars['String']>;
  /** 사업자등록번호 */
  companyRegistrationNumber?: Maybe<Scalars['String']>;
  /** 사업자 여부 */
  bizType?: Maybe<CapaQuotationUserBizType>;
  /** 회사 / 단체명 */
  organizationName?: Maybe<Scalars['String']>;
  /** 회사소개 */
  companyIntroduction?: Maybe<Scalars['String']>;
  /** 웹사이트 */
  website?: Maybe<Scalars['String']>;
  /** 부서 */
  department?: Maybe<Scalars['String']>;
  /** 직함 */
  position?: Maybe<Scalars['String']>;
  /** [필수] 캐파 서비스 이용약관 동의 */
  serviceAgreed?: Maybe<Scalars['Boolean']>;
  /** [필수] 개인정보 수집 및 활용 동의 */
  privacyAgreed?: Maybe<Scalars['Boolean']>;
  /** [선택] 프로모션 정보 수신 동의 */
  promotionAgreed?: Maybe<Scalars['Boolean']>;
  /** 추천인 ID */
  referralUserId?: Maybe<Scalars['String']>;
  /** CAPA 서비스를 어떻게 알게 되셨나요? (복수 선택 가능) */
  inflowRoute?: Maybe<Array<CapaInflowRoute>>;
  /** 추천 회사명/기관명 */
  referralCompany?: Maybe<Scalars['String']>;
  /** 생성일 */
  createdAt: Scalars['String'];
  /** 수정일 */
  updatedAt: Scalars['String'];
  /** 탈퇴이유 */
  withdrawnReason?: Maybe<CapaWithdrawnQuotationUserReason>;
  files?: Maybe<Array<CapaQuotationUserFile>>;
  refundAccounts?: Maybe<Array<CapaQuotationUserRefundBankAccountModel>>;
  /** 본인인증 상태 */
  verificationType?: Maybe<CapaQuotationUserVerificationType>;
  /** 본인인증 이름 */
  verificationName?: Maybe<Scalars['String']>;
  /** 본인인증 생년월일 */
  verificationBirthDate?: Maybe<Scalars['String']>;
  /** 유저 환불계좌 및 활동 정보 */
  userHistoryModelList?: Maybe<Array<CapaQuotationUserHistoryBoModel>>;
};

export type CapaQuotationUserBoOutput = {
  __typename?: 'CAPAQuotationUserBOOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaQuotationUserBo>;
};

/** 사업자 여부 */
export enum CapaQuotationUserBizType {
  /** 사업자등록번호가 있음 */
  Biz = 'BIZ',
  /** 법인사업자 */
  Corporate = 'CORPORATE',
  /** 개인사업자 */
  SelfEmployed = 'SELF_EMPLOYED',
  /** 사업자등록번호가 없음 [단체] */
  Organization = 'ORGANIZATION',
  /** 사업자등록번호가 없음 [개인] */
  Individual = 'INDIVIDUAL'
}

export type CapaQuotationUserCertificationModel = {
  __typename?: 'CAPAQuotationUserCertificationModel';
  /** 아임포트 SMS본인인증처리 고유번호 */
  impUid: Scalars['String'];
  /** 가맹점 주문번호 */
  merchantUid?: Maybe<Scalars['String']>;
  /** PG사 SMS본인인증결과 고유번호 */
  pgTid?: Maybe<Scalars['String']>;
  /** SMS본인인증 제공 PG사 명칭. danal(다날) , */
  pgProvider: Scalars['String'];
  /** 인증결과-실명 */
  name?: Maybe<Scalars['String']>;
  /** 인증결과-성별. male:남성, female:여성 , */
  gender?: Maybe<Scalars['String']>;
  /** birthday 를 사용해주세요. 관련공지내용 인증결과-생년월일 UNIX timestamp. 연, 월, 일만 의미있고 시,분,초는 의미없음 , */
  birth?: Maybe<Scalars['String']>;
  /** 인증결과-생년월일 ISO8601 형식의 문자열. YYYY-MM-DD 10자리 문자열 , */
  birthday?: Maybe<Scalars['String']>;
  /** 인증결과-외국인여부. true:외국인, false:내국인. 다날 본인인증서비스 계약시 외국인 구분기능 추가 요청을 해주셔야 사용이 가능합니다. */
  foreigner: Scalars['String'];
  /** 인증결과-사용된 휴대폰번호(신용카드 본인인증의 경우 해당사항없음). 특수기호없이 숫자로만 구성된 휴대폰번호가 전달됨. 통신사 사전승인이 이뤄지지 않으면 phone 속성은 존재하지 않습니다. 통신사 사전승인이 필요하므로 cs@iamport.kr 로 다날 CPID 와 함께 사용승인 요청주시면 안내도와드리겠습니다. */
  phone?: Maybe<Scalars['String']>;
  /** 아임포트 SMS본인인증처리 고유번호 */
  carrier?: Maybe<Scalars['String']>;
  /** 인증성공여부 */
  certified?: Maybe<Scalars['String']>;
  /** 인증처리시각 UNIX timestamp */
  certifiedAt?: Maybe<Scalars['String']>;
  /** 개인 고유구분 식별키(다날 매뉴얼 기준 CI) */
  uniqueKey?: Maybe<Scalars['String']>;
  /** 가맹점 내 개인 고유구분 식별키(다날 매뉴얼 기준 DI). 본인인증 PG MID별로 할당되는 개인 식별키 */
  uniqueInSite?: Maybe<Scalars['String']>;
  /** 본인인증 프로세스가 진행된 웹 페이지의 URL */
  origin?: Maybe<Scalars['String']>;
  /** true:외국인, false:내국인. 다날 본인인증서비스 계약시 외국인 구분기능 추가 요청을 해주셔야 사용이 가능합니다. */
  foreignerV2?: Maybe<Scalars['String']>;
};

export type CapaQuotationUserChatRoom = {
  __typename?: 'CAPAQuotationUserChatRoom';
  chatRoomId?: Maybe<Scalars['ID']>;
  chatRoomStatus?: Maybe<CapaQuotationChatRoomStatus>;
  rfqId?: Maybe<Scalars['ID']>;
  isOldRfq?: Maybe<Scalars['Boolean']>;
  profileId?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  serviceType?: Maybe<CapaQuotationServiceType>;
  thumbnailId?: Maybe<Scalars['String']>;
  quotationPartnerId?: Maybe<Scalars['String']>;
  quotationPartnerStatus?: Maybe<Scalars['String']>;
  unReadCount?: Maybe<Scalars['Float']>;
  lastMessage?: Maybe<CapaQuotationChatMessageUnion>;
  lastMessageCreatedAt?: Maybe<Scalars['String']>;
  rfqStatus?: Maybe<CapaRfqStatus>;
  rfqCreatedAt?: Maybe<Scalars['String']>;
  managerName?: Maybe<Scalars['String']>;
  shortAddress?: Maybe<Scalars['String']>;
  purchaseOrderStatus?: Maybe<CapaPurchaseOrderStatus>;
  /** 기획에서 요구하는 상태 를 지정해서 반환. */
  searchProjectStatusTypeEnum?: Maybe<ProjectStatusType>;
  quantity?: Maybe<Scalars['String']>;
  purchaseOrderQuotationPartnerId?: Maybe<Scalars['String']>;
};

export type CapaQuotationUserChatRooms = {
  __typename?: 'CAPAQuotationUserChatRooms';
  totalChatRoomsCount?: Maybe<Scalars['Float']>;
  currentChatRoomCount?: Maybe<Scalars['Float']>;
  chatRooms?: Maybe<Array<CapaQuotationUserChatRoom>>;
  /** 읽지 않은 메세지 수 */
  totalUnReadMessagesCount?: Maybe<Array<CapaQuotationUserUnReadMessagesCount>>;
};

export type CapaQuotationUserChatRoomsOutput = {
  __typename?: 'CAPAQuotationUserChatRoomsOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaQuotationUserChatRooms>;
};

export type CapaQuotationUserFile = {
  __typename?: 'CAPAQuotationUserFile';
  fileId?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  fileType?: Maybe<CapaQuotationUserFileType>;
};

/** 캐파 견적 유저 파일 종류 */
export enum CapaQuotationUserFileType {
  /** 프로필 */
  Profile = 'PROFILE'
}

export type CapaQuotationUserGnb = {
  __typename?: 'CAPAQuotationUserGNB';
  /** 읽지 않은 견적서 및 회사소개서 수 */
  newQuotationAndIntroductionCount: Scalars['Float'];
  /** 모든 채팅방 unread chat count */
  newChatCount: Scalars['Float'];
  /** 견적 상태 채팅방 unread chat count */
  newConsultingChatCount: Scalars['Float'];
  /** 발주 상태 채팅방 unread chat count */
  newConfirmedChatCount: Scalars['Float'];
  newProjectManagementCount: Scalars['Float'];
  newPaymentManagementCount?: Maybe<Scalars['Float']>;
};

export type CapaQuotationUserGnbOutput = {
  __typename?: 'CAPAQuotationUserGNBOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaQuotationUserGnb>;
};

export type CapaQuotationUserHistoryBoModel = {
  __typename?: 'CAPAQuotationUserHistoryBOModel';
  id: Scalars['String'];
  quotationUserId: Scalars['String'];
  refundBankAccountId?: Maybe<Scalars['String']>;
  historyType?: Maybe<CapaBackOfficeQuotationUserHistoryType>;
  manager?: Maybe<Scalars['String']>;
  managerBOId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

/** notification filter */
export type CapaQuotationUserNotificationFilter = {
  __typename?: 'CAPAQuotationUserNotificationFilter';
  /** 방해금지 시간 */
  doNotDisturb?: Maybe<CapaDoNotDisturbFilter>;
  /** 프로젝트 이메일 알림 */
  projectEmail?: Maybe<Scalars['Boolean']>;
  /** 프로젝트 카카오 (실패시 SMS) 알림 */
  projectSMS?: Maybe<Scalars['Boolean']>;
  /** 프로젝트 알림 설정 */
  projectFilters?: Maybe<Array<CapaRfqProjectFilter>>;
  chatEmail?: Maybe<Scalars['Boolean']>;
  chatSMS?: Maybe<Scalars['Boolean']>;
  chatPush?: Maybe<Scalars['Boolean']>;
  /** 캐파 소식 및 혜택 이메일 알림 */
  newsEmail?: Maybe<Scalars['Boolean']>;
  /** 캐파 소식 및 혜택 카카오 (실패시 SMS) 알림 */
  newsSMS?: Maybe<Scalars['Boolean']>;
};

/** notification filter */
export type CapaQuotationUserNotificationFilterInput = {
  /** 방해금지 시간 */
  doNotDisturb?: Maybe<CapaDoNotDisturbFilterInput>;
  /** 프로젝트 이메일 알림 */
  projectEmail?: Maybe<Scalars['Boolean']>;
  /** 프로젝트 카카오 (실패시 SMS) 알림 */
  projectSMS?: Maybe<Scalars['Boolean']>;
  /** 프로젝트 알림 설정 */
  projectFilters?: Maybe<Array<CapaRfqProjectFilterInput>>;
  /** email yes/no for new chat messages */
  chatEmail?: Maybe<Scalars['Boolean']>;
  /** sms yes/no for new chat messages */
  chatSMS?: Maybe<Scalars['Boolean']>;
  /** push notification yes/no for new chat messages */
  chatPush?: Maybe<Scalars['Boolean']>;
  /** 캐파 소식 및 혜택 이메일 알림 */
  newsEmail?: Maybe<Scalars['Boolean']>;
  /** 캐파 소식 및 혜택 카카오 (실패시 SMS) 알림 */
  newsSMS?: Maybe<Scalars['Boolean']>;
};

export type CapaQuotationUserOutput = {
  __typename?: 'CAPAQuotationUserOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaQuotationUser>;
};

/** CAPAQuotationUserProjectManagementList */
export type CapaQuotationUserProjectManagement = {
  __typename?: 'CAPAQuotationUserProjectManagement';
  rfqId: Scalars['String'];
  serviceType: CapaQuotationServiceType;
  projectName: Scalars['String'];
  thumbnail?: Maybe<Scalars['String']>;
  type?: Maybe<CapaRfqProjectManagementType>;
  isNew: Scalars['Boolean'];
  partnerId: Scalars['String'];
  chatRoomId: Scalars['String'];
  createdAt: Scalars['String'];
};

export type CapaQuotationUserRefundBankAccountModel = {
  __typename?: 'CAPAQuotationUserRefundBankAccountModel';
  id: Scalars['String'];
  quotationUserId: Scalars['String'];
  seq: Scalars['Float'];
  bankCode: CapaBankCode;
  account: Scalars['String'];
  holderName: Scalars['String'];
  holderPhone: Scalars['String'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type CapaQuotationUserRefundBankAccountModelsOutput = {
  __typename?: 'CAPAQuotationUserRefundBankAccountModelsOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<Array<CapaQuotationUserRefundBankAccountModel>>;
};

export type CapaQuotationUserRfq = {
  __typename?: 'CAPAQuotationUserRfq';
  rfqId?: Maybe<Scalars['String']>;
  rfqStatus?: Maybe<CapaRfqStatus>;
  projectName?: Maybe<Scalars['String']>;
  serviceType?: Maybe<CapaQuotationServiceType>;
  createdAt?: Maybe<Scalars['String']>;
  thumbnailId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  protoQuantity?: Maybe<Scalars['Float']>;
  uniqueViews?: Maybe<Scalars['Float']>;
  receivedQuotationCount?: Maybe<Scalars['Float']>;
  newQuotationCount?: Maybe<Scalars['Float']>;
  newIntroductionCount?: Maybe<Scalars['Float']>;
  eventType?: Maybe<CapaQuotationRfqEventType>;
  itpEventRegistered?: Maybe<Scalars['Boolean']>;
  itpEventSent?: Maybe<Scalars['Boolean']>;
  isItpPartner?: Maybe<Scalars['Boolean']>;
  jsbEventRegistered?: Maybe<Scalars['Boolean']>;
  jsbEventSent?: Maybe<Scalars['Boolean']>;
  /** 발주서(업체 확정) 발행 여부 */
  isTransaction?: Maybe<Scalars['Boolean']>;
  /** 상담 시작 방식 */
  consultingStartMethod?: Maybe<CapaConsultingStartMethod>;
  purchaseOrderId?: Maybe<Scalars['String']>;
  /** 발주서 상태 */
  purchaseOrderStatus?: Maybe<CapaPurchaseOrderStatus>;
  /** 기획에서 요구하는 상태 를 지정해서 반환. */
  searchProjectStatusTypeEnum?: Maybe<ProjectStatusType>;
  /** 파트너 로고  */
  profileId?: Maybe<Scalars['String']>;
  quotationPartnerId?: Maybe<Scalars['String']>;
  purchaseOrderQuotationPartnerId?: Maybe<Scalars['String']>;
};

/** 고객이 리뷰 가능한 목록 */
export type CapaQuotationUserRfqAvailableReviewOutput = {
  __typename?: 'CAPAQuotationUserRfqAvailableReviewOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaQuotationUserRfqAvailableReviewsResult>;
};

export type CapaQuotationUserRfqAvailableReviewsResult = {
  __typename?: 'CAPAQuotationUserRfqAvailableReviewsResult';
  rfqs: Array<CapaRfqAvailableReviews>;
  availableReviewCount: Scalars['Float'];
};

export type CapaQuotationUserRfqBo = {
  __typename?: 'CAPAQuotationUserRfqBO';
  id?: Maybe<Scalars['String']>;
  rfqId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  serviceType?: Maybe<CapaQuotationServiceType>;
  dueDate?: Maybe<Scalars['String']>;
  status?: Maybe<CapaRfqStatus>;
  openedChatRooms?: Maybe<Scalars['Float']>;
  closedChatRooms?: Maybe<Scalars['Float']>;
  partnersSentQuotation?: Maybe<Scalars['Float']>;
  hasPurchaseOrder?: Maybe<Scalars['Boolean']>;
  isConfirmed?: Maybe<Scalars['Boolean']>;
  isComplete?: Maybe<Scalars['Boolean']>;
  intoductionSuggestionCount?: Maybe<Scalars['Float']>;
  quotationSuggestionCount?: Maybe<Scalars['Float']>;
  totalSuggetionCount?: Maybe<Scalars['Float']>;
};

export type CapaQuotationUserRfqDetail = {
  __typename?: 'CAPAQuotationUserRfqDetail';
  id: Scalars['String'];
  status?: Maybe<CapaRfqStatus>;
  isOldRfq?: Maybe<Scalars['Boolean']>;
  projectName?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['String']>;
  detailedRequest?: Maybe<Scalars['String']>;
  serviceType?: Maybe<CapaQuotationServiceType>;
  detailedServiceType?: Maybe<CapaQuotationDetailedServiceType>;
  optionalServices?: Maybe<Array<CapaQuotationOptionalService>>;
  dueNegotiable?: Maybe<Scalars['Boolean']>;
  maxEstimatedBudget?: Maybe<Scalars['Float']>;
  minEstimatedBudget?: Maybe<Scalars['Float']>;
  budgetOpen?: Maybe<Scalars['Boolean']>;
  projectPurpose?: Maybe<CapaQuotationProductPurpose>;
  projectPurposeDetail?: Maybe<Scalars['String']>;
  publicOpen?: Maybe<Scalars['Boolean']>;
  eventType?: Maybe<CapaQuotationRfqEventType>;
  manufacturingStage?: Maybe<CapaRfqManufacturingStage>;
  drawingFiles?: Maybe<Array<CapaQuotationRfqDrawingFile>>;
  referenceFiles?: Maybe<Array<CapaQuotationRfqReferenceFile>>;
  pcb?: Maybe<CapaPcbDetail>;
  design?: Maybe<CapaDesignDetail>;
  createdAt: Scalars['String'];
  consultingStartMethod?: Maybe<CapaConsultingStartMethod>;
  transaction?: Maybe<CapaQuotationRfqTransaction>;
  chatRoom?: Maybe<Array<CapaQuotationChatRoom>>;
  review?: Maybe<CapaQuotationRfqReview>;
  itpEvent?: Maybe<CapaItpEventBo>;
  jsbEvent?: Maybe<CapaJsbEventBo>;
};

export type CapaQuotationUserRfqDetailOutput = {
  __typename?: 'CAPAQuotationUserRfqDetailOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaQuotationUserRfqDetail>;
};

/** 고객이 작성한 리뷰 목록 */
export type CapaQuotationUserRfqReviewsOutput = {
  __typename?: 'CAPAQuotationUserRfqReviewsOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<Array<CapaRfqReview>>;
};

export type CapaQuotationUserRfqs = {
  __typename?: 'CAPAQuotationUserRfqs';
  count?: Maybe<Scalars['Float']>;
  rfqs?: Maybe<Array<CapaQuotationUserRfq>>;
};

export type CapaQuotationUserRfqsBoOutput = {
  __typename?: 'CAPAQuotationUserRfqsBOOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<Array<CapaQuotationUserRfqBo>>;
};

export type CapaQuotationUserRfqsOutput = {
  __typename?: 'CAPAQuotationUserRfqsOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaQuotationUserRfqs>;
};

export enum CapaQuotationUserStatus {
  Registered = 'REGISTERED',
  Deleted = 'DELETED'
}

export enum CapaQuotationUserType {
  Client = 'CLIENT',
  Partner = 'PARTNER'
}

/** 파트너가 읽지 않은 메세지들의 수 (채팅방의 진척도에 따라 구분) */
export type CapaQuotationUserUnReadMessagesCount = {
  __typename?: 'CAPAQuotationUserUnReadMessagesCount';
  /** 해당 채팅방의 진척도 */
  chatRoomFilter?: Maybe<CapaQuotationChatRoomsFilterType>;
  /** 읽지 않은 메세지들의 총 수  */
  total?: Maybe<Scalars['Float']>;
};

export enum CapaQuotationUserVerificationType {
  /** 본인 인증 완료 상태 */
  Verified = 'VERIFIED',
  /** 본인 미인증 상태 */
  Unverified = 'UNVERIFIED'
}

/** 서비스 평가 목록 및 총 개수 */
export type CapaRatingsAndCount = {
  __typename?: 'CAPARatingsAndCount';
  ratings?: Maybe<Array<CapaServiceRatingBo>>;
  count?: Maybe<Scalars['Float']>;
};

export type CapaReceiveComplaintQuotationChatMessageModel = {
  __typename?: 'CAPAReceiveComplaintQuotationChatMessageModel';
  rfqInfo: CapaComplaintRfqInfoModel;
  userInfo: CapaComplaintUserInfoModel;
  message?: Maybe<Scalars['String']>;
  /** 생성일 */
  createdAt: Scalars['String'];
  complaintType: CapaBypassComplaintType;
  complaint?: Maybe<Scalars['String']>;
  chatRoomId: Scalars['String'];
};

export type CapaReceiveComplaintQuotationChatMessageOutput = {
  __typename?: 'CAPAReceiveComplaintQuotationChatMessageOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaComplaintQuotationChatMessageModel>;
};

/** 추천인 코드 정보 */
export type CapaReferral = {
  __typename?: 'CAPAReferral';
  /** 추천인 코드 */
  referralId: Scalars['ID'];
};

/** 추천인 코드 정보 */
export type CapaReferralOut = {
  __typename?: 'CAPAReferralOut';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaReferral>;
};

/** 리뷰 평점 비율 */
export type CapaReviewPointRatio = {
  __typename?: 'CAPAReviewPointRatio';
  one: Scalars['Float'];
  two: Scalars['Float'];
  three: Scalars['Float'];
  four: Scalars['Float'];
  five: Scalars['Float'];
};

export type CapaReviewableRfq = {
  __typename?: 'CAPAReviewableRfq';
  id: Scalars['String'];
  projectName: Scalars['String'];
  organizationName?: Maybe<Scalars['String']>;
  completeAt?: Maybe<Scalars['String']>;
  thumbnailId?: Maybe<Scalars['String']>;
};

export type CapaReviewableRfqsOutput = {
  __typename?: 'CAPAReviewableRfqsOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<Array<CapaReviewableRfq>>;
};

export type CapaRfqAdjustRequestObject = {
  __typename?: 'CAPARfqAdjustRequestObject';
  read?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
};

export enum CapaRfqAdjustRequestType {
  /** QUOTATION */
  Quotation = 'QUOTATION',
  /** PURCHASE_ORDER */
  PurchaseOrder = 'PURCHASE_ORDER'
}

export type CapaRfqAvailableReviews = {
  __typename?: 'CAPARfqAvailableReviews';
  rfqId: Scalars['String'];
  serviceType?: Maybe<CapaQuotationServiceType>;
  projectName: Scalars['String'];
  /** rfq 생성일 */
  rfqCreatedAt?: Maybe<Scalars['String']>;
  thumbnailId?: Maybe<Scalars['String']>;
  reviews: Array<CapaAvailableReview>;
};

export type CapaRfqBo = {
  __typename?: 'CAPARfqBO';
  rfqId: Scalars['ID'];
  createdAt: Scalars['String'];
  serviceType?: Maybe<CapaQuotationServiceType>;
  projectPurpose?: Maybe<CapaQuotationProductPurpose>;
  projectName?: Maybe<Scalars['String']>;
  managerName?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  /** 총 수량 */
  totalCount?: Maybe<Scalars['Float']>;
  status: CapaRfqStatus;
  viewCount?: Maybe<Scalars['Float']>;
  chatRoomCount?: Maybe<Scalars['Float']>;
  /** 견적 수 */
  quotationCount?: Maybe<Scalars['Float']>;
  /** 견적서로 상담 제안한 수 */
  quotationSuggestionCount?: Maybe<Scalars['Float']>;
  /** 소개서로 상담 제안한 수 */
  introductionSuggestionCount?: Maybe<Scalars['Float']>;
  /** 전체(견적 + 상담) 상담 제안한 수 */
  totalSuggestionCount?: Maybe<Scalars['Float']>;
  /** 발주 유무 */
  hasPurchaseOrder?: Maybe<Scalars['Boolean']>;
};

export type CapaRfqChatRoomsBo = {
  __typename?: 'CAPARfqChatRoomsBO';
  id?: Maybe<Scalars['String']>;
  rfqId?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  managerName?: Maybe<Scalars['String']>;
  quotationPartnerId?: Maybe<Scalars['String']>;
  quotationCreatedDates?: Maybe<Array<Scalars['String']>>;
  purchaseOrderCreatedDates?: Maybe<Array<Scalars['String']>>;
  quotation?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['String']>;
  isConfirmed?: Maybe<Scalars['Boolean']>;
  isComplete?: Maybe<Scalars['Boolean']>;
  annotationsCommentCount?: Maybe<Scalars['Float']>;
  annotations?: Maybe<Array<CapaQuotationChatRoomAnnotationCommentBo>>;
  chatRoomStatus?: Maybe<CapaQuotationChatRoomStatus>;
  hasPurchaseOrder?: Maybe<Scalars['Boolean']>;
  /** 발주 거부 사유 */
  purchaseOrderRejectedReason?: Maybe<Scalars['String']>;
};

/** 발주서 상태 */
export enum CapaRfqConsultingChatStatusType {
  /** 상담전 */
  BeforeConsulting = 'BEFORE_CONSULTING',
  /** 상담중 */
  Consulting = 'CONSULTING',
  /** 견적서 확정 */
  QuotationConfirmed = 'QUOTATION_CONFIRMED',
  /** 발주서 발행 */
  PurchaseOrderIssued = 'PURCHASE_ORDER_ISSUED'
}

export type CapaRfqDetailBo = {
  __typename?: 'CAPARfqDetailBO';
  id: Scalars['ID'];
  projectName?: Maybe<Scalars['String']>;
  status: CapaRfqStatus;
  quotationUserId?: Maybe<Scalars['String']>;
  managerName?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['String']>;
  dueNegotiable?: Maybe<Scalars['Boolean']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  chatRooms?: Maybe<Array<CapaRfqChatRoomsBo>>;
  projectPurpose?: Maybe<CapaQuotationProductPurpose>;
  projectPurposeDetail?: Maybe<Scalars['String']>;
  maxBudget?: Maybe<Scalars['Float']>;
  minBudget?: Maybe<Scalars['Float']>;
  budgetOpen?: Maybe<Scalars['Boolean']>;
  serviceType?: Maybe<CapaQuotationServiceType>;
  detailedServiceType?: Maybe<CapaQuotationDetailedServiceType>;
  optionalServices?: Maybe<Array<CapaQuotationOptionalService>>;
  drawingFiles?: Maybe<Array<CapaQuotationRfqDrawingFile>>;
  pcb?: Maybe<CapaPcbDetail>;
  design?: Maybe<CapaDesignDetail>;
  detailedRequest?: Maybe<Scalars['String']>;
  referenceFiles?: Maybe<Array<CapaQuotationRfqReferenceFile>>;
  /** 상담시작 유형 */
  consultingStartMethod?: Maybe<CapaConsultingStartMethod>;
};

export type CapaRfqDetailBoOutput = {
  __typename?: 'CAPARfqDetailBOOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaRfqDetailBo>;
};

/** 캐파 견적 상세 서비스 정보 입력 */
export type CapaRfqFilterQuotationDetailedServiceInput = {
  /** 캐파 견적 상세 서비스 타입 */
  type?: Maybe<CapaQuotationDetailedServiceType>;
  /** 재료 정보 입력 */
  materials?: Maybe<Array<CapaRfqFilterQuotationMaterialInput>>;
};

/** 재료 정보 입력 */
export type CapaRfqFilterQuotationMaterialInput = {
  /** 재료 */
  material?: Maybe<CapaQuotationMaterial>;
  /** 세부 재료 */
  detailedMaterials?: Maybe<Array<CapaQuotationDetailedMaterialType>>;
};

/** 캐파 견적 서비스 정보 입력 */
export type CapaRfqFilterQuotationServiceInput = {
  /** 캐파 견적 서비스 타입 */
  service?: Maybe<CapaQuotationServiceType>;
  /** 캐파 견적 상세 서비스 정보 입력 */
  detailedServices?: Maybe<Array<CapaRfqFilterQuotationDetailedServiceInput>>;
  /** 추가 서비스 타입 (벤딩, 용접, 시제품, 제품디자인 */
  optionalServices?: Maybe<Array<CapaQuotationServiceType>>;
};

/** CAPARfqManagementElement */
export type CapaRfqManagementElement = {
  __typename?: 'CAPARfqManagementElement';
  type?: Maybe<CapaRfqProjectManagementType>;
  isNew?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['String']>;
};

/** 제작단계 Manufacturing Stage */
export enum CapaRfqManufacturingStage {
  /** 시험 생산(Pilot Production) */
  StagePilotProduction = 'STAGE_PILOT_PRODUCTION',
  /** 제품 생산(Production) */
  StageProduction = 'STAGE_PRODUCTION',
  /** 시제품 제작(Prototype) */
  StagePrototype = 'STAGE_PROTOTYPE'
}

/** CAPARfqPartnerManagementElement */
export type CapaRfqPartnerManagementElement = {
  __typename?: 'CAPARfqPartnerManagementElement';
  type?: Maybe<CapaRfqProjectManagementType>;
  isNew?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['String']>;
};

/** pcb 조립 방식 */
export enum CapaRfqPcbAssemblyMethodType {
  /** Surface Mounted Technology - 표면실장기술 */
  Smt = 'SMT',
  /** 수땜 - 손 납땜 */
  Soldering = 'SOLDERING'
}

/** pcb 자제 방법 */
export enum CapaRfqPcbMaterialSupplyMethodType {
  /** 시급 */
  CustomerSupply = 'CUSTOMER_SUPPLY',
  /** 도급 */
  SelfSupply = 'SELF_SUPPLY',
  /** 부분도급 */
  PartialSelfSupply = 'PARTIAL_SELF_SUPPLY'
}

export type CapaRfqPcbassemblyMethod = {
  __typename?: 'CAPARfqPcbassemblyMethod';
  methodType?: Maybe<CapaRfqPcbAssemblyMethodType>;
};

/** 프로젝트 알림 설정 */
export type CapaRfqProjectFilter = {
  __typename?: 'CAPARfqProjectFilter';
  /** 이메일 수신 여부 */
  email?: Maybe<Scalars['Boolean']>;
  /** 카카오 (실패시 SMS) 수신 여부 */
  sms?: Maybe<Scalars['Boolean']>;
  /** 견적서 도착, 견적서 수정요청, 회사소개서 도착, 발주서 도착, 발주거절 */
  service?: Maybe<CapaRfqProjectManagementType>;
};

/** 프로젝트 알림 설정 */
export type CapaRfqProjectFilterInput = {
  /** 이메일 수신 여부 */
  email?: Maybe<Scalars['Boolean']>;
  /** 카카오 (실패시 SMS) 수신 여부 */
  sms?: Maybe<Scalars['Boolean']>;
  /** 발주서 도착 or 견적서 수정요청 도착 */
  service?: Maybe<CapaRfqProjectManagementType>;
};

/** CAPA Rfq Purchase Order Inspection Type(발주서 검수 타입) */
export enum CapaRfqPurchaseOrderInspectionType {
  /** 치수 검사 */
  Dimension = 'DIMENSION',
  /** 기능 검사 */
  Functionalty = 'FUNCTIONALTY',
  /** 외관 검사 */
  Surface = 'SURFACE',
  /** 기타 */
  Etc = 'ETC'
}

/** CAPA Rfq Purchase Order Payment Provision Type(발주서 결제 지급 정보 타입) */
export enum CapaRfqPurchaseOrderPaymentProvisionType {
  /** 계약체결 시 */
  AtContract = 'AT_CONTRACT',
  /** 납품 직후 */
  AfterShip = 'AFTER_SHIP',
  /** 납품 및 검수 직후 */
  AfterShipInspection = 'AFTER_SHIP_INSPECTION',
  /** 계약 종료 시 */
  AtContractTermination = 'AT_CONTRACT_TERMINATION',
  /** 기타 */
  Etc = 'ETC'
}

export type CapaRfqPurchaseOrderTransfer = {
  __typename?: 'CAPARfqPurchaseOrderTransfer';
  /** 이관 조건 */
  transferTerms?: Maybe<CapaRfqPurchaseOrderTransferTermsType>;
  /** 이관 보관 기간(N개월) */
  transferStorage?: Maybe<Scalars['Float']>;
};

/** CAPA Rfq Purchase Order TransferTerms Type(발주서 이관 조건 타입) */
export enum CapaRfqPurchaseOrderTransferTermsType {
  /** 납품 시 이관 */
  TransferAtShip = 'TRANSFER_AT_SHIP',
  /** 납품 후 일정 기간 보관 */
  StorageAfterShip = 'STORAGE_AFTER_SHIP'
}

export type CapaRfqQuotationPartnerSendIntroduction = {
  __typename?: 'CAPARfqQuotationPartnerSendIntroduction';
  /** id */
  id: Scalars['String'];
  /** seq */
  seq: Scalars['Float'];
  /** rfqId */
  rfqId: Scalars['String'];
  /** issuerId */
  issuerId: Scalars['String'];
  /** isSendQuotation */
  isSendQuotation?: Maybe<Scalars['String']>;
  /** read */
  read: Scalars['Boolean'];
  /** createdAt */
  createdAt: Scalars['DateTime'];
  /** updatedAt */
  updatedAt: Scalars['DateTime'];
  /** projectName */
  projectName: Scalars['String'];
  /** userId */
  userId: Scalars['String'];
  /** organizationName */
  organizationName: Scalars['String'];
};

/** CAPA Rfq Quotation Type (견적서 타입) */
export enum CapaRfqQuotationType {
  /** SIMPLE */
  Simple = 'SIMPLE',
  /** DETAIL */
  Detail = 'DETAIL'
}

/** 특정 rfq가 파트너로부터 수령한 것 */
export enum CapaRfqReceivedType {
  /** 견적서만 수령 */
  OnlyQuotation = 'ONLY_QUOTATION',
  /** 회사 소개서만 수령 */
  OnlyIntroduction = 'ONLY_INTRODUCTION',
  /** 견적서와 회사 소개서 수령 */
  QuotationAndIntroduction = 'QUOTATION_AND_INTRODUCTION'
}

export type CapaRfqReview = {
  __typename?: 'CAPARfqReview';
  /** review id */
  id: Scalars['String'];
  projectName: Scalars['String'];
  organizationName?: Maybe<Scalars['String']>;
  managerName?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  reviewComment: Scalars['String'];
  reviewPoint: Scalars['Float'];
  serviceType: CapaQuotationServiceType;
  reviewFiles?: Maybe<Array<CapaRfqReviewFile>>;
  reviewFileCount?: Maybe<Scalars['Float']>;
  indueDate: Scalars['Boolean'];
  reviewedAt?: Maybe<Scalars['String']>;
  /** 발주액 */
  purchaseOrderValue: Scalars['Float'];
  isEditable: Scalars['Boolean'];
};

/** 리뷰들과 리뷰 총 개수 */
export type CapaRfqReviewAndCount = {
  __typename?: 'CAPARfqReviewAndCount';
  reviews?: Maybe<Array<CapaRfqReviewBo>>;
  count?: Maybe<Scalars['Float']>;
  reviewAveragePoint?: Maybe<Scalars['Float']>;
};

export type CapaRfqReviewBo = {
  __typename?: 'CAPARfqReviewBO';
  reviewerName: Scalars['String'];
  reviewerCompanyName: Scalars['String'];
  reviewerEmail?: Maybe<Scalars['String']>;
  reviewerPhoneNumber?: Maybe<Scalars['String']>;
  partnerCompanyName?: Maybe<Scalars['String']>;
  projectName: Scalars['String'];
  reviewId: Scalars['ID'];
  reviewComment: Scalars['String'];
  reviewPoint: Scalars['Float'];
  inDueDate: Scalars['Boolean'];
  reviewFiles?: Maybe<Array<CapaRfqReviewFile>>;
};

export type CapaRfqReviewChip = {
  __typename?: 'CAPARfqReviewChip';
  chipId: Scalars['ID'];
};

/** 리뷰 파일 */
export type CapaRfqReviewFile = {
  __typename?: 'CAPARfqReviewFile';
  fileId?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
};

/** 리뷰 종류 */
export enum CapaRfqReviewType {
  /** 상담 리뷰 */
  OnlyConsulting = 'ONLY_CONSULTING',
  /** 거래 리뷰 */
  OnlyTransaction = 'ONLY_TRANSACTION',
  /** 상담 + 거래 리뷰 */
  ConsultingAndTransaction = 'CONSULTING_AND_TRANSACTION'
}

export type CapaRfqReviewsBoOutput = {
  __typename?: 'CAPARfqReviewsBOOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaRfqReviewAndCount>;
};

/** CAPA RFQ STATUS */
export enum CapaRfqStatus {
  /** 유효 rfq  */
  Available = 'AVAILABLE',
  /** 거래완료 --> 견적요청목록(fetchRfqsBO input) 에서 사용 X */
  Complete = 'COMPLETE',
  /** 발주 - 특정파트너와 연결이된 상태 --> 견적요청목록(fetchRfqsBO input) 에서는 사용 X */
  Confirmed = 'CONFIRMED',
  /** 90일이 지나 만료된 견적(원래 EXPIRED) + EXPIRED_BY_CLIENT + EXPIRED_BY_REWRITE + CONFIRMED + COMPLETE */
  Expired = 'EXPIRED',
  /** 클라이언트가 만료처리 --> 견적요청목록(fetchRfqsBO input) 에서는 사용 X */
  ExpiredByClient = 'EXPIRED_BY_CLIENT',
  /** Cient가 아직 not verified */
  NotVerified = 'NOT_VERIFIED',
  /** CRM의 판단에 따라 중지됨 */
  Stopped = 'STOPPED',
  /** 재작성으로 인해 마감됨 --> 견적요청목록(fetchRfqsBO input) 에서는 사용 X */
  ExpiredByRewrite = 'EXPIRED_BY_REWRITE',
  /** TEST_RFQ */
  TestRfq = 'TEST_RFQ'
}

/** 검색한 rfg 결과 및 개수 */
export type CapaRfqsAndCount = {
  __typename?: 'CAPARfqsAndCount';
  rfqs?: Maybe<Array<CapaRfqBo>>;
  count?: Maybe<Scalars['Float']>;
};

export type CapaRfqsBoOutput = {
  __typename?: 'CAPARfqsBOOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaRfqsAndCount>;
};

/** 파트너 목록에서 특정 파트너 찾기 위한 필드 값 */
export enum CapaSearchPartnersBoFieldType {
  /** 회사명 */
  OrganizationName = 'ORGANIZATION_NAME',
  /** 담당자명 */
  ManagerName = 'MANAGER_NAME',
  /** 담당자 이메일 */
  Email = 'EMAIL',
  /** 담당자 휴대폰 번호 */
  Contact = 'CONTACT'
}

/** 검색 필드 */
export enum CapaSearchQuotationUsersBoFieldType {
  /** 회사명 */
  CompanyName = 'COMPANY_NAME',
  /** 고객명 */
  ClientName = 'CLIENT_NAME',
  /** 고객 이메일 */
  ClientEmail = 'CLIENT_EMAIL',
  /** 휴대폰번호 */
  Contact = 'CONTACT'
}

/** 검색 필드 */
export enum CapaSearchRfqsBoFieldType {
  /** 프로젝트명 */
  ProjectName = 'PROJECT_NAME',
  /** 고객명 */
  ClientName = 'CLIENT_NAME',
  /** 고객이메일 */
  ClientEmail = 'CLIENT_EMAIL',
  /** 회사명 */
  CompanyName = 'COMPANY_NAME'
}

export type CapaSearchUsersBo = {
  __typename?: 'CAPASearchUsersBo';
  users?: Maybe<Array<CapaUserBo>>;
  count?: Maybe<Scalars['Float']>;
};

export type CapaServiceRatingBo = {
  __typename?: 'CAPAServiceRatingBO';
  /** 구분 */
  userType?: Maybe<CapaQuotationUserType>;
  userId?: Maybe<Scalars['String']>;
  /** 피드백 시간 */
  createdAt?: Maybe<Scalars['String']>;
  managerName?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  /** 평가 지수 */
  rating?: Maybe<Scalars['Float']>;
  /** 기타 의견 */
  comment?: Maybe<Scalars['String']>;
};

export type CapaServiceRatingBoOutput = {
  __typename?: 'CAPAServiceRatingBOOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaRatingsAndCount>;
};

export type CapaServiceSuggestionBo = {
  __typename?: 'CAPAServiceSuggestionBO';
  /** 구분 */
  userType?: Maybe<CapaQuotationUserType>;
  /** 피드백 시간 */
  createdAt?: Maybe<Scalars['String']>;
  managerName?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  /** 희망 서비스 */
  suggestion?: Maybe<Scalars['String']>;
};

export type CapaServiceSuggestionBoOutput = {
  __typename?: 'CAPAServiceSuggestionBOOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaSuggestionsAndCount>;
};

/** 캐파 서비스 타입 */
export enum CapaServiceType {
  /** 클라우드 서비스 */
  Cloud = 'CLOUD',
  /** 캐파 고객 (견적) 서비스 */
  Quotation = 'QUOTATION',
  /** 캐파 파트너 (견적) 서비스 */
  QuotationPartner = 'QUOTATION_PARTNER',
  /** 캐파 커넥트 서비스 */
  Connect = 'CONNECT',
  /** 캐파 백오피스 */
  Backoffice = 'BACKOFFICE',
  Super = 'SUPER'
}

export type CapaServiceTypeStatistics = {
  __typename?: 'CAPAServiceTypeStatistics';
  /** 요청 수량 */
  quantity: Scalars['Float'];
  /** 거래 금액 */
  amount?: Maybe<Scalars['Float']>;
};

export type CapaServiceTypeStatisticsOutput = {
  __typename?: 'CAPAServiceTypeStatisticsOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaServiceTypeStatistics>;
};

export enum CapaShippingMethodType {
  /** 택배 */
  Package = 'PACKAGE',
  /** 퀵서비스 */
  QuickService = 'QUICK_SERVICE',
  /** 직접 배달 */
  SelfPackage = 'SELF_PACKAGE',
  /** 고객 직접수령 */
  PickUp = 'PICK_UP',
  /** 배송없음 */
  NoPackage = 'NO_PACKAGE',
  /** 기타 */
  Etc = 'ETC'
}

/** 파트너목록 정렬 필드 */
export enum CapaSortPartnersBoFieldType {
  /** 마지막 로그인 */
  LastLoginAt = 'LAST_LOGIN_AT',
  /** 가입일 */
  CreatedAt = 'CREATED_AT',
  /** 전체 제안 프로젝트 수 (견적서 제안 + 소개서 제안) */
  TotalSuggestionCount = 'TOTAL_SUGGESTION_COUNT',
  /** 견적서로 제안한 프로젝트 수 */
  QuotationSuggestionCount = 'QUOTATION_SUGGESTION_COUNT',
  /** 소개서로 제안한 프로젝트 수 */
  IntroductionSuggestionCount = 'INTRODUCTION_SUGGESTION_COUNT',
  /** 견적 수 = 상담 시작 방식 및 상담 진행 여부 상관 없이 견적서 1회 이상 발송한 프로젝트 수 */
  RfqReceivedQuotationCount = 'RFQ_RECEIVED_QUOTATION_COUNT',
  /** 안전결제 수 */
  PaymentCount = 'PAYMENT_COUNT'
}

/** 견적요청목록 정렬 필드 */
export enum CapaSortRfqsBoFieldType {
  /** 주문 요청 일 */
  CreatedAt = 'CREATED_AT',
  /** 총수량 */
  TotalCount = 'TOTAL_COUNT',
  /** 조회수 */
  ViewCount = 'VIEW_COUNT',
  /** 전체(견적 + 소개서) 제안 파트너 수 */
  TotalSuggestionCount = 'TOTAL_SUGGESTION_COUNT',
  /** 연결된 상담 수 */
  ChatroomCount = 'CHATROOM_COUNT'
}

/** 견적요청목록 정렬 필드 */
export enum CapaSortType {
  /** 오름차순 */
  Asc = 'ASC',
  /** 내림차순 */
  Desc = 'DESC'
}

/** 유저목록 정렬 필드 */
export enum CapaSortUsersBoFieldType {
  /** 회원가입일 */
  CreatedAt = 'CREATED_AT',
  /** 견적요청개수 */
  RfqCount = 'RFQ_COUNT'
}

export enum CapaSubmallRejectType {
  /** 고객이 잘못 가입함 */
  JoinMistake = 'JOIN_MISTAKE',
  /** 파트너 요건 불충 */
  Insufficient = 'INSUFFICIENT',
  /** 연락이 되지 않음 */
  CantInTouch = 'CANT_IN_TOUCH',
  /** ETC */
  Etc = 'ETC'
}

export type CapaSubmallRejectedReason = {
  __typename?: 'CAPASubmallRejectedReason';
  /** reject type */
  rejectType: CapaSubmallRejectType;
  /** 취소 상세 사유 */
  reason?: Maybe<Scalars['String']>;
};

/** CAPASubmallStatus */
export enum CapaSubmallStatus {
  /** 계좌 승인 대기 */
  Registered = 'REGISTERED',
  /** 계좌 승인 완료 */
  Approved = 'APPROVED',
  /** 계좌 승인 거절 */
  Rejected = 'REJECTED'
}

export type CapaSubscribeNotifications = {
  __typename?: 'CAPASubscribeNotifications';
  count: Scalars['Float'];
};

export type CapaSubscribeNotificationsOutput = {
  __typename?: 'CAPASubscribeNotificationsOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaSubscribeNotifications>;
};

/** 서비스 제안 목록 및 총 개수 */
export type CapaSuggestionsAndCount = {
  __typename?: 'CAPASuggestionsAndCount';
  suggestions?: Maybe<Array<CapaServiceSuggestionBo>>;
  count?: Maybe<Scalars['Float']>;
};

export type CapaTransactionHistoryBo = {
  __typename?: 'CAPATransactionHistoryBO';
  paymentId: Scalars['String'];
  paidAt?: Maybe<Scalars['String']>;
  status: CapaPaymentStatus;
  /** 지급승인(구매확정) 날짜 */
  confirmedAt?: Maybe<Scalars['String']>;
  /** 지급대행 지시일(실제 파트너에게 정산 = 지급일) */
  payoutedAt?: Maybe<Scalars['String']>;
  /** total amount */
  totalAmount: Scalars['Float'];
  /** 캐파 결제망 이용료 수익 (VAT포함) */
  capaUsageFee?: Maybe<Scalars['Float']>;
  /** pg 결제망 이용료 (PG수수료 (VAT포함) */
  pgUsageFee?: Maybe<Scalars['Float']>;
  /** 결제관리 수수료수익 (VAT포함) */
  commission?: Maybe<Scalars['Float']>;
  /** 파트너에게 정산해주는 금액 */
  payoutAmount?: Maybe<Scalars['Float']>;
  paymentMethod: CapaPaymentMethodType;
  quotationUserManagerName: Scalars['String'];
  quotationPartnerOrganizationName: Scalars['String'];
  rfqId: Scalars['String'];
  projectName: Scalars['String'];
  companyRegistrationNumber: Scalars['String'];
  /** capa_payment_cancel ConfirmedAt */
  cancelConfirmedAt?: Maybe<Scalars['String']>;
  cancelStatus?: Maybe<CapaPaymentCancelStatus>;
  /** 포인트 */
  point?: Maybe<Scalars['Float']>;
  serviceType?: Maybe<CapaQuotationServiceType>;
  /** 캐파에게 지급대행 지시일 */
  payoutedToCapaAt?: Maybe<Scalars['String']>;
};

export type CapaTransactionHistoryCountBo = {
  __typename?: 'CAPATransactionHistoryCountBO';
  /** 총 결제 건수    */
  transactionCount?: Maybe<Scalars['Float']>;
  /** 총 결제금액  */
  totalAmout?: Maybe<Scalars['Float']>;
  /** PG+캐파 결제망이용료 (VAT 포함)  */
  usageFee?: Maybe<Scalars['Float']>;
  /** 캐파 결제망이용료수익(VAT 포함)  */
  capaUsageFee?: Maybe<Scalars['Float']>;
  /** 결제관리수수료수익(VAT 포함)  */
  commission?: Maybe<Scalars['Float']>;
  /** 결제취소/환불 건수  */
  cancelCount?: Maybe<Scalars['Float']>;
  /** 결제취소/환불금액 */
  totalCancelAmout?: Maybe<Scalars['Float']>;
  /** 총 수익 합계(결제금액-결제취소/환불금액) */
  profit?: Maybe<Scalars['Float']>;
  /** 포인트 합계 */
  point?: Maybe<Scalars['Float']>;
};

export type CapaTransactionHistoryModelBo = {
  __typename?: 'CAPATransactionHistoryModelBO';
  transactionCount: CapaTransactionHistoryCountBo;
  transactionList?: Maybe<Array<CapaTransactionHistoryBo>>;
};

export type CapaTransactionHistoryModelBoOutput = {
  __typename?: 'CAPATransactionHistoryModelBOOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaTransactionHistoryModelBo>;
};

export type Capauuid = {
  __typename?: 'CAPAUUID';
  uuid: Scalars['String'];
};

export type CapauuidOutput = {
  __typename?: 'CAPAUUIDOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<Capauuid>;
};

export type CapaUser = {
  __typename?: 'CAPAUser';
  id: Scalars['ID'];
  email: Scalars['String'];
  status: CapaUserStatus;
  lastLoginAt?: Maybe<Scalars['String']>;
  lastLogoutAt?: Maybe<Scalars['String']>;
  /** 생성일 */
  createdAt: Scalars['String'];
  /** 수정일 */
  updatedAt: Scalars['String'];
};

export type CapaUserBo = {
  __typename?: 'CAPAUserBO';
  id: Scalars['String'];
  managerName?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  bizType?: Maybe<CapaQuotationUserBizType>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  rfqCount?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['String']>;
  companyRegistrationNumber?: Maybe<Scalars['Boolean']>;
  status?: Maybe<CapaQuotationUserStatus>;
  route?: Maybe<Array<CapaInflowRoute>>;
  /** 추천 기관명/회사명 */
  referralCompany?: Maybe<Scalars['String']>;
};

/** 유저 현황 */
export enum CapaUserStatus {
  /** 등록 */
  Registered = 'REGISTERED',
  /** 탈퇴 */
  Deleted = 'DELETED'
}

export type CapaUsersBoOutput = {
  __typename?: 'CAPAUsersBOOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaSearchUsersBo>;
};

export type CapaUsersOutput = {
  __typename?: 'CAPAUsersOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<Array<CapaUser>>;
};

export type CapaVerificationModel = {
  __typename?: 'CAPAVerificationModel';
  /** string 타입의 아임포트 SMS본인인증처리 고유번호 */
  impUid: Scalars['String'];
  /** string 타입의 가맹점 주문번호 */
  merchantUid?: Maybe<Scalars['String']>;
  /** PG사 SMS본인인증결과 고유번호 */
  pgTid?: Maybe<Scalars['String']>;
  /** SMS본인인증 제공 PG사 명칭. danal(다날) */
  pgProvider: Scalars['String'];
  /** 인증결과-실명 */
  name?: Maybe<Scalars['String']>;
  /** 인증결과-성별. male:남성, female:여성  */
  gender?: Maybe<Scalars['String']>;
  /** (deprecated) birthday 를 사용해주세요. 관련공지내용 인증결과-생년월일 UNIX timestamp. 연, 월, 일만 의미있고 시,분,초는 의미없음 */
  birth?: Maybe<Scalars['String']>;
  /** 인증결과-생년월일 ISO8601 형식의 문자열. YYYY-MM-DD 10자리 문자열 */
  birthday?: Maybe<Scalars['String']>;
  /** 인증결과-외국인여부. true:외국인, false:내국인. 다날 본인인증서비스 계약시 외국인 구분기능 추가 요청을 해주셔야 사용이 가능합니다. */
  foreigner: Scalars['Boolean'];
  /** 인증결과-사용된 휴대폰번호(신용카드 본인인증의 경우 해당사항없음). 특수기호없이 숫자로만 구성된 휴대폰번호가 전달됨. 통신사 사전승인이 이뤄지지 않으면 phone 속성은 존재하지 않습니다. 통신사 사전승인이 필요하므로 cs@iamport.kr 로 다날 CPID 와 함께 사용승인 요청주시면 안내도와드리겠습니다. */
  phone?: Maybe<Scalars['String']>;
  /** 인증결과-사용된 휴대폰번호의 통신사(신용카드 본인인증의 경우 해당사항없음). 통신사 사전승인이 이뤄지지 않으면 carrier 속성은 존재하지 않습니다. 통신사 사전승인이 필요하므로 cs@iamport.kr 로 다날 CPID 와 함께 사용승인 요청주시면 안내도와드리겠습니다. = [SKT, KT, LGT, SKT_MVNO, KT_MVNO, LGT_MVNO] */
  carrier?: Maybe<Scalars['String']>;
  /** 인증성공여부 */
  certified?: Maybe<Scalars['Boolean']>;
  /** 인증처리시각 UNIX timestamp */
  certifiedAt?: Maybe<Scalars['Float']>;
  /** 개인 고유구분 식별키(다날 매뉴얼 기준 CI) */
  uniqueKey?: Maybe<Scalars['String']>;
  /** 가맹점 내 개인 고유구분 식별키(다날 매뉴얼 기준 DI). 본인인증 PG MID별로 할당되는 개인 식별키 */
  uniqueInSite?: Maybe<Scalars['String']>;
  /** 본인인증 프로세스가 진행된 웹 페이지의 URL */
  origin?: Maybe<Scalars['String']>;
  /** nullable한 인증결과-외국인여부. true:외국인, false:내국인. 다날 본인인증서비스 계약시 외국인 구분기능 추가 요청을 해주셔야 사용이 가능합니다. */
  foreignerV2?: Maybe<Scalars['Boolean']>;
};

/** 고객 탈퇴 사유 */
export enum CapaWithdrawClientReasonType {
  /** 원하는 파트너를 찾지 못해서 */
  NoMatchedPartner = 'NO_MATCHED_PARTNER',
  /** 더 이상 제조 파트너 찾기에 대한 수요가 없어서 */
  NoNeedToSearchPartner = 'NO_NEED_TO_SEARCH_PARTNER',
  /** 불필요한 알람이 많이 와서 */
  UselessAlarm = 'USELESS_ALARM',
  /** 개인정보 유출 불안 */
  PersonalInformationLeakage = 'PERSONAL_INFORMATION_LEAKAGE',
  /** 이직, 퇴사, 폐업 등 개인적인 사유 */
  PrivacyIssue = 'PRIVACY_ISSUE',
  /** 파트너 품질이 불안 */
  ConcernedAboutQuality = 'CONCERNED_ABOUT_QUALITY',
  /** 에이팀벤처스 서비스 응대가 불만 */
  AteamServiceProblem = 'ATEAM_SERVICE_PROBLEM',
  /** 기타 */
  Etc = 'ETC'
}

export type CapaWithdrawOutput = {
  __typename?: 'CAPAWithdrawOutput';
  consultingChatRooms?: Maybe<CapaWithdrawnChatRoomsUnion>;
  consultingChatRoomsCount: Array<CapaQuotationChatRoomsTotalCount>;
};

/** 고객 탈퇴 사유 */
export enum CapaWithdrawPartnerReasonType {
  /** 원하는 전문 서비스가 없어서 */
  NoMatchedService = 'NO_MATCHED_SERVICE',
  /** 원하는 고객을 만나지 못해서 */
  NoMatchedClient = 'NO_MATCHED_CLIENT',
  /** 불필요한 알람이 많이 와서 */
  UselessAlarm = 'USELESS_ALARM',
  /** 제조 고객 찾기에 대한 수요가 없어져서 */
  NoNeedToSearchClient = 'NO_NEED_TO_SEARCH_CLIENT',
  /** 이직, 퇴사, 폐업 등 개인적인 사유 */
  PrivacyIssue = 'PRIVACY_ISSUE',
  /** 기타 */
  Etc = 'ETC'
}

/** BO에서 보여주기 위한 탈퇴 사유 */
export enum CapaWithdrawReasonType {
  /** 원하는 파트너를 찾지 못해서 */
  NoMatchedPartner = 'NO_MATCHED_PARTNER',
  /** 더 이상 제조 파트너 찾기에 대한 수요가 없어서 */
  NoNeedToSearchPartner = 'NO_NEED_TO_SEARCH_PARTNER',
  /** 불필요한 알람이 많이 와서 */
  UselessAlarm = 'USELESS_ALARM',
  /** 개인정보 유출 불안 */
  PersonalInformationLeakage = 'PERSONAL_INFORMATION_LEAKAGE',
  /** 이직, 퇴사, 폐업 등 개인적인 사유 */
  PrivacyIssue = 'PRIVACY_ISSUE',
  /** 파트너 품질이 불안 */
  ConcernedAboutQuality = 'CONCERNED_ABOUT_QUALITY',
  /** 에이팀벤처스 서비스 응대가 불만 */
  AteamServiceProblem = 'ATEAM_SERVICE_PROBLEM',
  /** 기타 */
  Etc = 'ETC',
  /** 원하는 전문 서비스가 없어서 */
  NoMatchedService = 'NO_MATCHED_SERVICE',
  /** 원하는 고객을 만나지 못해서 */
  NoMatchedClient = 'NO_MATCHED_CLIENT',
  /** 제조 고객 찾기에 대한 수요가 없어져서 */
  NoNeedToSearchClient = 'NO_NEED_TO_SEARCH_CLIENT'
}

export type CapaWithdrawnChatRoomsOutput = {
  __typename?: 'CAPAWithdrawnChatRoomsOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaWithdrawOutput>;
};

export type CapaWithdrawnChatRoomsUnion =
  | CapaWithdrawnUserChatRooms
  | CapaWithdrawnPartnerChatRooms
  | CapaWithdrawnConnectGroupChatRooms;

/** 캐파 커넥트 그룹 채팅방 */
export type CapaWithdrawnConnectGroupChatRoom = {
  __typename?: 'CAPAWithdrawnConnectGroupChatRoom';
  /** 그룹 ID */
  groupId: Scalars['ID'];
  /** 그룹 이름 */
  groupName: Scalars['String'];
  /** 챗룸 아이디 */
  chatRoomId: Scalars['ID'];
  /** 썸네일 */
  thumbnail?: Maybe<Scalars['ID']>;
  /** 그룹멤버 */
  users?: Maybe<Array<CapaWithdrawnConnectGroupUser>>;
};

export type CapaWithdrawnConnectGroupChatRooms = {
  __typename?: 'CAPAWithdrawnConnectGroupChatRooms';
  capaServiceType: CapaServiceType;
  chatRooms?: Maybe<Array<CapaWithdrawnConnectGroupChatRoom>>;
};

/** 탈퇴하고자 하는 유저의 커넥트 그룹 */
export type CapaWithdrawnConnectGroupUser = {
  __typename?: 'CAPAWithdrawnConnectGroupUser';
  /** 그룹 유저 ID */
  id?: Maybe<Scalars['ID']>;
  /** 커넥트 유저 아이디 */
  userId?: Maybe<Scalars['ID']>;
  /** 그룹 ID */
  groupId?: Maybe<Scalars['ID']>;
  /** 이메일 */
  email: Scalars['String'];
  /** 커넥트유저네임 */
  connectUserName?: Maybe<Scalars['String']>;
  /** 캐파유저냐 */
  isCapaUser?: Maybe<Scalars['Boolean']>;
};

/** 캐파 파트너스의 채팅방 */
export type CapaWithdrawnPartnerChatRoom = {
  __typename?: 'CAPAWithdrawnPartnerChatRoom';
  organizationName?: Maybe<Scalars['String']>;
  managerName?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  chatRoomId: Scalars['ID'];
  thumbnailId?: Maybe<Scalars['ID']>;
  rfqId?: Maybe<Scalars['String']>;
};

export type CapaWithdrawnPartnerChatRooms = {
  __typename?: 'CAPAWithdrawnPartnerChatRooms';
  capaServiceType: CapaServiceType;
  chatRooms?: Maybe<Array<CapaWithdrawnPartnerChatRoom>>;
};

/** 탈퇴 이유 */
export type CapaWithdrawnQuotationPartnerReason = {
  __typename?: 'CAPAWithdrawnQuotationPartnerReason';
  /** 커파 서비스 중 어디서 탈퇴를 진행했는지 */
  serviceType: CapaServiceType;
  /** 탈퇴 사유 */
  reason?: Maybe<CapaWithdrawReasonType>;
  /** 탈퇴 상세 사유 */
  detailedReason?: Maybe<Scalars['String']>;
  /** 기타 탈퇴 사유 */
  etcReason?: Maybe<Scalars['String']>;
  /** 탈퇴 일시 */
  withdrawnAt: Scalars['String'];
  /** 탈퇴 BO 담당자 성함 */
  withdrawerName?: Maybe<Scalars['String']>;
};

/** 탈퇴 이유 */
export type CapaWithdrawnQuotationUserReason = {
  __typename?: 'CAPAWithdrawnQuotationUserReason';
  /** 커파 서비스 중 어디서 탈퇴를 진행했는지 */
  serviceType: CapaServiceType;
  /** 탈퇴 사유 */
  reason?: Maybe<CapaWithdrawReasonType>;
  /** 탈퇴 상세 사유 */
  detailedReason?: Maybe<Scalars['String']>;
  /** 기타 탈퇴 사유 */
  etcReason?: Maybe<Scalars['String']>;
  /** 탈퇴 일시 */
  withdrawnAt: Scalars['String'];
  /** 탈퇴 BO 담당자 성함 */
  withdrawerName?: Maybe<Scalars['String']>;
};

/** 캐파 클라이언트 채팅방 */
export type CapaWithdrawnUserChatRoom = {
  __typename?: 'CAPAWithdrawnUserChatRoom';
  chatRoomId: Scalars['ID'];
  isOldRfq?: Maybe<Scalars['Boolean']>;
  profileId?: Maybe<Scalars['ID']>;
  projectName?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  thumbnailId?: Maybe<Scalars['ID']>;
  rfqId?: Maybe<Scalars['String']>;
};

export type CapaWithdrawnUserChatRooms = {
  __typename?: 'CAPAWithdrawnUserChatRooms';
  capaServiceType: CapaServiceType;
  chatRooms?: Maybe<Array<CapaWithdrawnUserChatRoom>>;
};

export type CanEditRefundAccountModel = {
  __typename?: 'CanEditRefundAccountModel';
  canEditRefundAccount: Scalars['Boolean'];
};

export type CancelRequestPaymentAgentInput = {
  mid: Scalars['String'];
  settlmntDt: Scalars['String'];
  subId: Scalars['String'];
  seq: Scalars['String'];
};

export enum CapaQuotationChatRoomSharedFileAnnotationStatus {
  /** ON */
  On = 'ON',
  /** OFF */
  Off = 'OFF',
  /** NONE */
  None = 'NONE'
}

export enum CapaQuotationChatRoomSharedFileType {
  /** QUOTATION_FILE */
  QuotationFile = 'QUOTATION_FILE',
  /** CHAT_MESSAGE_FILE */
  ChatMessageFile = 'CHAT_MESSAGE_FILE'
}

export enum CapaQuotationChatRoomSharedFilesFilterType {
  /** ALL_FILE */
  AllFile = 'ALL_FILE',
  /** QUOTATION_FILE */
  QuotationFile = 'QUOTATION_FILE',
  /** CHAT_MESSAGE_FILE */
  ChatMessageFile = 'CHAT_MESSAGE_FILE'
}

export type CapaQuotationChatRoomSpecificAnnotation = {
  __typename?: 'CapaQuotationChatRoomSpecificAnnotation';
  isNew?: Maybe<Scalars['Boolean']>;
  userId?: Maybe<Scalars['String']>;
  status?: Maybe<CapaQuotationSharedFileAnnotationStatus>;
  anchorPos?: Maybe<Capa3DPos>;
  handlePos?: Maybe<Capa3DPos>;
  nodeId?: Maybe<Scalars['Float']>;
  commentUsers?: Maybe<Array<CommentUser>>;
  comments?: Maybe<Array<CapaQuotationSpecificAnnotationComment>>;
  createdAt?: Maybe<Scalars['String']>;
  annotationId?: Maybe<Scalars['String']>;
};

export type CapaQuotationChatRoomSpecificAnnotationsOutput = {
  __typename?: 'CapaQuotationChatRoomSpecificAnnotationsOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<Array<CapaQuotationChatRoomSpecificAnnotation>>;
};

/** Capa Rfq Project Management Type */
export enum CapaRfqProjectManagementType {
  /** 도면 권한 요청 */
  AllowDrawingFileRequest = 'ALLOW_DRAWING_FILE_REQUEST',
  /** 파트너가 견적서를 보냈을떄 */
  NewRfqQuotation = 'NEW_RFQ_QUOTATION',
  NewQuotationPartnerIntroduction = 'NEW_QUOTATION_PARTNER_INTRODUCTION',
  /** 파트너가 수정 견적서를 보냈을때 */
  AdjustedQuotation = 'ADJUSTED_QUOTATION',
  RefusedPurchaseOrder = 'REFUSED_PURCHASE_ORDER',
  /** 파트너가 발주서 수정 요청 */
  PurchaseOrderAdjustRequest = 'PURCHASE_ORDER_ADJUST_REQUEST',
  /** 파트너가 발주서를 확정했을때 */
  PurchaseOrderConfirmed = 'PURCHASE_ORDER_CONFIRMED',
  /** 고객이 도면 다운로드 허용시 */
  AllowDrawingFileResponse = 'ALLOW_DRAWING_FILE_RESPONSE',
  /** 고객이 도면 다운로드 제한시 */
  AllowDrawingFileDenied = 'ALLOW_DRAWING_FILE_DENIED',
  /** 고객이 견적서 수정 요청 */
  QuotationAdjustRequest = 'QUOTATION_ADJUST_REQUEST',
  /** 고객이 발주서 발행했을때 */
  NewRfqPurchaseOrder = 'NEW_RFQ_PURCHASE_ORDER',
  /** 고객이 발주서를 수정 발행 했을때 */
  AdjustedPurchaseOrder = 'ADJUSTED_PURCHASE_ORDER',
  /** 고객이 견적서를 보고 채팅하기를 클릭했을때 */
  QuotationConsultingStart = 'QUOTATION_CONSULTING_START'
}

/** CapaServiceTypeDetailFilter */
export type CapaServiceTypeDetailFilter = {
  /** detailedServiceTypes */
  detailedServiceTypes?: Maybe<Array<CapaQuotationDetailedServiceType>>;
  /** materials */
  materials?: Maybe<Array<CapaQuotationMaterial>>;
  /** detailedMaterials */
  detailedMaterials?: Maybe<Array<CapaQuotationDetailedMaterialType>>;
  /** optionalServices */
  optionalServices?: Maybe<Array<CapaQuotationServiceType>>;
};

export type ChangeChatMessageInvisibleInput = {
  chatRoomId?: Maybe<Scalars['String']>;
  senderId?: Maybe<Scalars['String']>;
  messageType?: Maybe<CapaQuotationChatMessageType>;
  fieldToChange?: Maybe<Scalars['String']>;
  valueToChange?: Maybe<Scalars['String']>;
};

export type ChangeChatRoomStatusInput = {
  chatRoomId?: Maybe<Scalars['String']>;
  quotationId?: Maybe<Scalars['String']>;
};

/** 이메일 갱신 */
export type ChangeEmailInput = {
  /** 이메일 */
  newEmail: Scalars['String'];
  /** 인증번호 */
  code: Scalars['String'];
};

/** 비밀번호 변경하기 */
export type ChangePasswordInput = {
  /** 비밀번호 */
  password: Scalars['String'];
  /** 새 비밀번호 */
  newPassword: Scalars['String'];
  /** 새 비밀번호 확인 */
  newPasswordConfirm: Scalars['String'];
  service?: Maybe<CapaServiceType>;
};

export type ChatFileUploadInput = {
  fileId: Scalars['String'];
  fileName?: Maybe<Scalars['String']>;
};

export type CheckBalanceInput = {
  /** 상점 ID */
  mid: Scalars['String'];
};

export type CheckConnectDuplicateGroupNameInput = {
  groupName: Scalars['String'];
};

export type CloudUser = {
  __typename?: 'CloudUser';
  id: Scalars['ID'];
};

export type CommentUser = {
  __typename?: 'CommentUser';
  userName?: Maybe<Scalars['String']>;
};

export type CommentUserBo = {
  __typename?: 'CommentUserBO';
  userName?: Maybe<Scalars['String']>;
};

export type CompleteRfqTransactionInput = {
  quotationPartnerId: Scalars['String'];
  rfqId: Scalars['String'];
  completeCost: Scalars['Float'];
};

export type ConfirmEscrowPurchaseInput = {
  /** payment table pk ID */
  paymentId: Scalars['String'];
  /** 나이스페이 모달에서 결제할때 입력하는 인증번호 (휴대폰번호 or 사업자번호) -> BuyerAuthNum */
  escrowVerifiedPW: Scalars['String'];
};

export type ConfirmRfqPurchaseOrderInput = {
  rfqId: Scalars['String'];
  purchaseOrderId: Scalars['String'];
};

export type ConfirmRfqQuotationInput = {
  rfqId: Scalars['String'];
  quotationPartnerId: Scalars['String'];
  quotationId?: Maybe<Scalars['String']>;
};

export type ConfirmRfqTransactionInput = {
  quotationPartnerId: Scalars['String'];
  rfqId: Scalars['String'];
};

export enum ConnectFileSortType {
  Latest = 'LATEST',
  Oldest = 'OLDEST'
}

export enum ConnectNotificationType {
  ChatPush = 'CHAT_PUSH',
  GroupPush = 'GROUP_PUSH',
  CommentThreadPush = 'COMMENT_THREAD_PUSH',
  FilePush = 'FILE_PUSH',
  GroupEmail = 'GROUP_EMAIL',
  CommentThreadEmail = 'COMMENT_THREAD_EMAIL',
  FileEmail = 'FILE_EMAIL'
}

/** ConnectSharedFileCommentThread */
export enum ConnectSharedFileCommentThread {
  /** yes */
  Yes = 'YES',
  /** no */
  No = 'NO'
}

export type ConnectUser = {
  __typename?: 'ConnectUser';
  id: Scalars['ID'];
  userName?: Maybe<Scalars['String']>;
};

/** Connect activete link status */
export enum ConnectUserFileLinkStatus {
  /** active */
  Active = 'ACTIVE',
  /** deactive */
  Deactive = 'DEACTIVE'
}

/** ConnectUserFileStatus status */
export enum ConnectUserFileStatus {
  /** viewable */
  Viewable = 'VIEWABLE',
  /** downloadable */
  Downloadable = 'DOWNLOADABLE'
}

export type CreateAdsImpressionBoInput = {
  /** advertisement id */
  advertisementId: Scalars['String'];
  /** image file id */
  fileId: Scalars['String'];
  /** image background color */
  backgroundColor?: Maybe<Scalars['String']>;
  /** 광고 타이틀 */
  title: Scalars['String'];
  /** 광고 내용 */
  contents: Scalars['String'];
  /** 광고 타입 (배너, 카드) */
  advertisementType: CapaAdvertisementType;
  /** 광고 링크 주소 */
  linkUrl: Scalars['String'];
  /** 광고 종료 날짜 */
  expiredAt?: Maybe<Scalars['String']>;
};

export type CreateAdvertisementBoInput = {
  /** 회사명 */
  organizationName: Scalars['String'];
  /** 담당자명 */
  managerName: Scalars['String'];
  /** 연락처 */
  contact: Scalars['String'];
  /** 이메일 */
  email: Scalars['String'];
};

export type CreateAdvertisingBoInput = {
  /** 노출 광고 id 리스트 */
  adsImpressionIds: Array<Scalars['String']>;
  /** 광고 타입 (배너, 카드) */
  advertisementType: CapaAdvertisementType;
  /** 호스팅할 서비스 타입 (배너, 카드) */
  serviceType: CapaServiceType;
};

export type CreateConnectAnnotationCommentInput = {
  groupId: Scalars['String'];
  connectSharedFileId: Scalars['String'];
  annotationId: Scalars['String'];
  comment: Scalars['String'];
};

export type CreateConnectAnnotationInput = {
  groupId: Scalars['String'];
  connectSharedFileId: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
  anchorPos?: Maybe<Capa3DPosInput>;
  handlePos?: Maybe<Capa3DPosInput>;
  nodeId?: Maybe<Scalars['Float']>;
};

export type CreateConnectContactUserInput = {
  contactUserEmail: Scalars['String'];
  nickName?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
};

export type CreateConnectFeedbackInput = {
  /** 1 ~ 5 */
  rating: Scalars['Float'];
  /** feedBack message */
  feedBack?: Maybe<Scalars['String']>;
  fileIds?: Maybe<Array<Scalars['String']>>;
};

export type CreateConnectGroup = {
  __typename?: 'CreateConnectGroup';
  id: Scalars['String'];
  chatRoomId: Scalars['String'];
};

export type CreateConnectGroupInput = {
  groupName?: Maybe<Scalars['String']>;
};

export type CreateConnectGroupOutput = {
  __typename?: 'CreateConnectGroupOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CreateConnectGroup>;
};

export type CreateConnectIssueInput = {
  /** issue message */
  issue: Scalars['String'];
  fileIds?: Maybe<Array<Scalars['String']>>;
};

export type CreateDetailedQuotationInput = {
  rfqId: Scalars['String'];
  partnerId: Scalars['String'];
  optionalServices?: Maybe<Array<RfqQuotationOptionalServiceInput>>;
  /** 공급가액 */
  quotation?: Maybe<Scalars['Float']>;
  /** 배송비 */
  deliveryCost?: Maybe<Scalars['Float']>;
  /** false : include delevery fee, true : exclude delevery fee */
  excludeDeliveryFee?: Maybe<Scalars['Boolean']>;
  /** 최대 시안 수정 횟수 */
  maxDraftModificationCount?: Maybe<Scalars['Float']>;
  /** 수정 범위 및 추가 과금 기준 */
  draftModificationTerms?: Maybe<Scalars['String']>;
  /** 견적 설명 */
  etc?: Maybe<Scalars['String']>;
  /** 납품 가능일 */
  dueDate?: Maybe<Scalars['String']>;
  /** 견적서 유효기간 */
  expiration?: Maybe<Scalars['String']>;
  /** 공급자 담당자명 */
  managerName?: Maybe<Scalars['String']>;
  /** 공급자 연락처 */
  phone?: Maybe<Scalars['String']>;
  /** 공급자 이메일 */
  quotationEmail: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  items?: Maybe<Array<CapaQuotationDetailedQuotationItemInput>>;
  pcbFabricationItems?: Maybe<
    Array<CapaQuotationDetailedQuotationPcbFabricationItemInput>
  >;
  pcbAssemblyItems?: Maybe<
    Array<CapaQuotationDetailedQuotationPcbAssemblyItemInput>
  >;
};

/** 고객 환불계좌 BO 등록 */
export type CreateMyRefundAccountBoInput = {
  /** 환불계좌 등록할 고객의 이메일 */
  targetEmail: Scalars['String'];
  bankCode: CapaBankCode;
  /** 계좌 번호 (- 제외) */
  account: Scalars['String'];
  /** 계좌 예금주명과 법인 본인인증자 이름이 다를때 강제 환불계좌 등록 */
  isForce?: Maybe<Scalars['Boolean']>;
};

/** 고객 환불계좌 등록 */
export type CreateMyRefundAccountInput = {
  bankCode: CapaBankCode;
  /** 계좌 번호 (- 제외) */
  account: Scalars['String'];
  /** 계좌 예금주명과 법인 본인인증자 이름이 다를때 강제 환불계좌 등록 */
  isForce?: Maybe<Scalars['Boolean']>;
};

export type CreateNoticeScheduleInput = {
  /** 점검 시작 시간 */
  startTime: Scalars['String'];
  /** 점검 종료 시간 */
  endTime: Scalars['String'];
  /** 사전점검 시작 시간 */
  previewStartTime?: Maybe<Scalars['String']>;
  /** 사전점검 종료 시간 */
  previewEndTime?: Maybe<Scalars['String']>;
  /** client partner connect 인지 */
  pageType: NoticeScheduleNoticePageType;
  /** 긴급점검 / 사전점검 등 */
  noticeType: NoticeScheduleNoticeType;
};

export type CreatePaymentInput = {
  purchaseOrderId: Scalars['String'];
  /** quotation partner id */
  partnerId: Scalars['String'];
  rfqId: Scalars['String'];
  pgProvider: CapapgProviders;
  /** CreatePaymentInput에서는 impUid를 transactionId로 받고 있음 */
  transactionId: Scalars['String'];
  refundAccountId?: Maybe<Scalars['String']>;
  /** 채팅방 상태 */
  chatRoomStatus?: Maybe<CapaQuotationChatRoomStatus>;
};

export type CreatePurchaseOrderInput = {
  quotationId: Scalars['String'];
  /** 발주처 담당자명 */
  managerName: Scalars['String'];
  /** 이관 조건 */
  transferTerms?: Maybe<CapaRfqPurchaseOrderTransferTermsType>;
  /** 이관 보관 기간(N개월) */
  transferStorage?: Maybe<Scalars['Float']>;
  /** 납품일 */
  shipDate: Scalars['String'];
  /** 납품주소 */
  shipAddress: Scalars['String'];
  /** 납품상세주소 */
  shipDetailAddress?: Maybe<Scalars['String']>;
};

export type CreateQuotationAnnotationCommentInput = {
  annotationId: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
};

export type CreateQuotationAnnotationInput = {
  chatRoomId: Scalars['String'];
  quotationSharedFileId: Scalars['String'];
  filterType: CapaQuotationChatRoomSharedFileType;
  comment?: Maybe<Scalars['String']>;
  anchorPos?: Maybe<Capa3DPosInput>;
  handlePos?: Maybe<Capa3DPosInput>;
  nodeId?: Maybe<Scalars['Float']>;
};

export type CreateQuotationChatRoomInput = {
  rfqId?: Maybe<Scalars['String']>;
};

export type CreateSimpleQuotationInput = {
  rfqId: Scalars['String'];
  partnerId: Scalars['String'];
  quotation?: Maybe<Scalars['Float']>;
  /** false : include delevery fee, true : exclude delevery fee */
  excludeDeliveryFee?: Maybe<Scalars['Boolean']>;
  etc?: Maybe<Scalars['String']>;
};

/** 구매확정(지급승인) 지연 */
export type DelayConfirmEscrowPurchaseInput = {
  /** 발주서 id */
  purchaseOrderId: Scalars['String'];
  /** 지급일 연장 사유 */
  extendReason?: Maybe<Scalars['String']>;
};

/** 고객 환불계좌 삭제 */
export type DeleteMyRefundAccountInput = {
  /** 수정할 환불계좌 id */
  refundBankAccountId?: Maybe<Scalars['String']>;
};

export type DeleteQuotationChatMessageInput = {
  deleteTargetMsgId: Scalars['String'];
};

export type DesignServiceInput = {
  serviceType: CapaDesignServiceType;
};

export enum EscrowPaymentRequestType {
  /** 구매확정 */
  ConfirmPurchase = 'CONFIRM_PURCHASE',
  /** 구매확정 거절 */
  RefusePurchase = 'REFUSE_PURCHASE',
  /** 배송등록 */
  RegisterShipping = 'REGISTER_SHIPPING'
}

export type EvaluateRfqPartnerInput = {
  quotationPartnerId: Scalars['String'];
  kind?: Maybe<Scalars['Boolean']>;
  quick?: Maybe<Scalars['Boolean']>;
  detailed?: Maybe<Scalars['Boolean']>;
  helpful?: Maybe<Scalars['Boolean']>;
  etc?: Maybe<Scalars['String']>;
};

export type EvaluateRfqPartnersInput = {
  rfqId: Scalars['String'];
  partners: Array<EvaluateRfqPartnerInput>;
};

export type ExpireRfqInput = {
  rfqId: Scalars['String'];
};

export type FactoronBizFetchIcxInput = {
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  factoronBizSearchType?: Maybe<FactoronBizSearchType>;
};

/** FactoronBizSearchType */
export enum FactoronBizSearchType {
  /** 회사명 */
  CompanyName = 'COMPANY_NAME',
  /** 공장대표주소 (도로명) */
  FactoryAddressRoadnam = 'FACTORY_ADDRESS_ROADNAM',
  /** 업종명 */
  IndustryName = 'INDUSTRY_NAME',
  /** 관할조직명 */
  JurisdictionOrganizationName = 'JURISDICTION_ORGANIZATION_NAME',
  /** 생산품 */
  Product = 'PRODUCT',
  /** 공장크기 */
  FactorySize = 'FACTORY_SIZE'
}

export type FavoriteRfqInput = {
  rfqId: Scalars['String'];
};

export type FetchActiveAdvertisingBoInput = {
  /** 광고 타입 (배너, 카드) */
  advertisementTypes: Array<CapaAdvertisementType>;
  /** 호스팅할 서비스 타입 (배너, 카드) */
  serviceType: CapaServiceType;
};

export type FetchActiveAdvertisingInput = {
  /** 광고 타입 (배너, 카드) */
  advertisementTypes: Array<CapaAdvertisementType>;
  /** 호스팅할 서비스 타입 (배너, 카드) */
  serviceType: CapaServiceType;
};

export type FetchAdsActivityLogsInput = {
  /** asd impression id */
  adsImpressionId: Scalars['String'];
};

export type FetchAdsImpressionsBoInput = {
  /** adsImpression ids */
  adsImpressionIds: Array<Scalars['String']>;
};

export type FetchAdvertisementAdsImpressionsBoInput = {
  /** advertisement id */
  advertisementId: Scalars['String'];
};

export type FetchAdvertisementsBoInput = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};

export type FetchAdvertisingsBoInput = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};

export type FetchAllIcxInput = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  icxRegion: Array<IcxRegion>;
  industrialComplexList: Array<IndustrialComplexName>;
  industryCategory: Array<IndustrialSectorsCategory>;
  sectors: Array<IndustrialSectorsName>;
  companyScale: Array<IndustrialCompanyScale>;
  searchKeyword?: Maybe<Scalars['String']>;
  employeesCountSortType?: Maybe<CapaSortType>;
  industrialBizSearchType?: Maybe<IndustrialBizSearchType>;
};

/** FetchAvailableFilterRfqsInput */
export type FetchAvailableFilterRfqsInput = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  serviceType?: Maybe<CapaFilterableServiceType>;
  isFavorite?: Maybe<Scalars['Boolean']>;
  productPurposes?: Maybe<Array<CapaQuotationProductPurpose>>;
  /** 내가 견적서 작성한 RFQ중에서 검색할 키워드 */
  myKeyword?: Maybe<Scalars['String']>;
  /** 내가 견적서 작성한 RFQ 목록 확인 여부 */
  isMyRFQQuotation?: Maybe<Scalars['Boolean']>;
  /** 검색할 상태 타입 */
  searchStatus?: Maybe<QuotationPartnerRfqCurrentSituationType>;
};

export type FetchBoAmbassadorDetailInput = {
  friendsId: Scalars['String'];
};

export type FetchBoAmbassadorInvitationStatusInput = {
  friendsId: Scalars['String'];
  /** 캐파 회원 C / 프렌즈 F */
  type: Scalars['String'];
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};

export type FetchBoAmbassadorsInput = {
  /** 검색 타입 */
  searchType?: Maybe<FetchBoAmbassadorListSearchType>;
  /** 검색어 */
  searchText?: Maybe<Scalars['String']>;
  /** 정렬 타입 */
  sortType?: Maybe<FetchBoAmbassadorListSortType>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};

export type FetchBoFriendsInviteUserActivityInput = {
  userId: Scalars['String'];
};

export type FetchBoTestUsersInput = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};

export type FetchBoUsersInput = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};

/** 프렌즈 목록 검색 타입 */
export enum FetchBoAmbassadorListSearchType {
  /** EMAIL */
  E = 'E',
  /** COMPANY */
  C = 'C'
}

/** 프렌즈 목록 정렬 타입 */
export enum FetchBoAmbassadorListSortType {
  /** 전체 초대 수 오름차순 / TOTAL_USER_ASC */
  Tua = 'TUA',
  /** 전체 초대 수 내림차순 / TOTAL_USER_DESC */
  Tud = 'TUD',
  /** 직접 초대 회원 수 오름차순 / DIRECT_USER_ASC */
  Dua = 'DUA',
  /** 직접 초대 회원 수 내림차순 / DIRECT_USER_DESC */
  Dud = 'DUD',
  /** 간접 초대 회원 수 오름차순 / INDIRECT_USER_ASC */
  Iua = 'IUA',
  /** 간접 초대 회원 수 내림차순 / INDIRECT_USER_DESC */
  Iud = 'IUD',
  /** 정산 리워드 오름차순 / SETTLED_REWARD_ASC */
  Sra = 'SRA',
  /** 정산 리워드 내림차순 / SETTLED_REWARD_DESC */
  Srd = 'SRD',
  /** 미정산 리워드 오름차순 / UNSETTLED_REWARD_ASC */
  Ura = 'URA',
  /** 미정산 리워드 내림차순 / UNSETTLED_REWARD_DESC */
  Urd = 'URD',
  /** 등록일 오름차순 / CREATE_DATE_ASC */
  Cda = 'CDA',
  /** 등록일 내림차순 / CREATE_DATE_DESC */
  Cdd = 'CDD',
  /** 정산 요청 오름차순 / MONEY_REQUEST_ASC */
  Mra = 'MRA',
  /** 정산 요청 내림차순 / MONEY_REQUEST_DESC */
  Mrd = 'MRD',
  /** 탈퇴 신청 오름차순 / WITHDRAW_REQUEST_ASC */
  Wra = 'WRA',
  /** 탈퇴 신청 내림차순 / WITHDRAW_REQUEST_DESC */
  Wrd = 'WRD'
}

export type FetchBoMemosInput = {
  /** 유저 id */
  userId: Scalars['String'];
  /** 유저 타입 */
  userType: CapaServiceType;
};

export type FetchCxManagementRequiredUsersBoInput = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};

export type FetchCapaConnectSharedFileInput = {
  groupId: Scalars['String'];
  sortFileType?: Maybe<ConnectFileSortType>;
  fileType?: Maybe<CapaConnectSharedFileType>;
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};

export type FetchCapabilityInput = {
  /** 캐파 견적 서비스 타입 */
  serviceType?: Maybe<Array<CapaQuotationServiceType>>;
};

export type FetchCommentsBoInput = {
  /** quotation user id */
  quotationUserId: Scalars['String'];
};

export type FetchConfirmDetailedQuotationInput = {
  rfqId: Scalars['String'];
  quotationPartnerId: Scalars['String'];
};

export type FetchConfirmPurchaseOrderInput = {
  rfqId: Scalars['String'];
  quotationPartnerId: Scalars['String'];
};

export type FetchConnectAnnotationsInput = {
  groupId: Scalars['String'];
  connectSharedFileId?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};

export type FetchConnectChatRoomInput = {
  chatRoomId: Scalars['String'];
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};

export type FetchConnectFeedbacksBoInput = {
  filter?: Maybe<CapaBackOfficeUsersFilterType>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};

export type FetchConnectFileDetailInput = {
  fileId: Scalars['String'];
};

export type FetchConnectGroupChatRoomInput = {
  id: Scalars['String'];
};

export type FetchConnectGroupChatRoomsInput = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};

export type FetchConnectGroupFeedbacksBoInput = {
  groupId?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};

export type FetchConnectGroupFilePermissionInput = {
  groupId: Scalars['String'];
  connectFileId: Scalars['String'];
};

export type FetchConnectGroupInput = {
  groupId: Scalars['String'];
  sortFileType?: Maybe<ConnectFileSortType>;
  fileType?: Maybe<CapaConnectSharedFileType>;
  keyword?: Maybe<Scalars['String']>;
};

export type FetchConnectGroupIssuesBoInput = {
  groupId?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};

export type FetchConnectGroupsBoInput = {
  filter?: Maybe<CapaBackOfficeConnectGroupsFilterType>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};

export type FetchConnectGroupsInput = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};

export type FetchConnectIssuesBoInput = {
  filter?: Maybe<CapaBackOfficeUsersFilterType>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};

export type FetchConnectMyDriveInput = {
  isLatest?: Maybe<Scalars['Boolean']>;
  pathId?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};

export type FetchConnectUserContactListInput = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  contactUserEmail?: Maybe<Scalars['String']>;
};

export type FetchConnectUserFeedbacksBoInput = {
  connectUserId: Scalars['String'];
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};

export type FetchConnectUserFileLinkBoOutput = {
  __typename?: 'FetchConnectUserFileLinkBOOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaConnectUserFileLinksBo>;
};

export type FetchConnectUserFileLinksBoInput = {
  filter?: Maybe<CapaBackOfficeUsersFilterType>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};

export type FetchConnectUserIssuesBoInput = {
  connectUserId: Scalars['String'];
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};

export type FetchConnectUserLinkFile = {
  __typename?: 'FetchConnectUserLinkFile';
  files?: Maybe<Array<CapaConnectMyFile>>;
};

/** 내 정보 */
export type FetchConnectUserLinkFileInput = {
  /** if status logged in */
  email?: Maybe<Scalars['String']>;
  /** shareLink */
  shareLink: Array<Scalars['String']>;
  /** 아이피 */
  ip: Scalars['String'];
};

export type FetchConnectUserLinkFileOutput = {
  __typename?: 'FetchConnectUserLinkFileOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<FetchConnectUserLinkFile>;
};

export type FetchConnectUsersBoInput = {
  filter?: Maybe<CapaBackOfficeUsersFilterType>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};

export type FetchDetailedQuotationInput = {
  rfqId: Scalars['String'];
  quotationId: Scalars['String'];
};

export type FetchFileConversionStatusInput = {
  fileId: Scalars['String'];
};

/** fetchInProgressQuotationPartnerChatRoomsInput */
export type FetchInProgressQuotationPartnerChatRoomsInput = {
  serviceType: CapaQuotationServiceType;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};

/** itp 이벤트 목록 표출 BO */
export type FetchItpEventsBoInput = {
  /** itp 이벤트 목록 타입 */
  listType?: Maybe<CapaItpEventListTypeEnum>;
  /** 고객 아이디 Filter */
  quotationUserId?: Maybe<Scalars['String']>;
};

/** jsb 이벤트 목록 표출 BO */
export type FetchJsbEventsBoInput = {
  /** jsb 이벤트 목록 타입 */
  listType?: Maybe<CapaJsbEventListTypeEnum>;
  /** 고객 아이디 Filter */
  quotationUserId?: Maybe<Scalars['String']>;
};

export type FetchMyAccountModel = {
  __typename?: 'FetchMyAccountModel';
  id: Scalars['String'];
  bankCode: CapaBankCode;
  account: Scalars['String'];
  holderName: Scalars['String'];
};

export type FetchMyCompleteRfqsInput = {
  complete: Scalars['Boolean'];
};

/** 캐파 클라우드 회원가입 */
export type FetchMyDriveInput = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};

export type FetchMyReceivedQuotationsInput = {
  rfqId?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};

export type FetchMyRfqQuotationsInput = {
  rfqId: Scalars['String'];
  quotationPartnerId: Scalars['String'];
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};

export type FetchMySubmallInput = {
  partnerId: Scalars['String'];
};

export type FetchNotificationInput = {
  /** 캐파 서비스 타입 */
  service: CapaServiceType;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  lastSeq?: Maybe<Scalars['Float']>;
};

export type FetchOneIcxInput = {
  id: Scalars['String'];
};

export type FetchPartnerRfqReviewsInput = {
  quotationPartnerId: Scalars['String'];
};

export type FetchPartnersInput = {
  status?: Maybe<CapaBackOfficeQuotationPartnerFilterType>;
  serviceType?: Maybe<CapaQuotationServiceType>;
  companyRegistration?: Maybe<Scalars['Boolean']>;
  isItp?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  searchField?: Maybe<CapaSearchPartnersBoFieldType>;
  searchItem?: Maybe<Scalars['String']>;
  /** 정렬필드 */
  sortField?: Maybe<CapaSortPartnersBoFieldType>;
  /** 정렬 차순 - asc,desc */
  sortType?: Maybe<CapaSortType>;
  includeTest?: Maybe<Scalars['Boolean']>;
};

export type FetchPaymentAgentResultInput = {
  mid?: Maybe<Scalars['String']>;
  /** 원하는 날짜 조회 YYYYMMDD 형식 */
  settlmntDt?: Maybe<Scalars['String']>;
  subId?: Maybe<Scalars['String']>;
};

export type FetchPaymentManageByPartnerIdInput = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  /** 프로젝트이름,고객닉네임,회사명,제조카테고리,주소 검색어 */
  searchKeyword?: Maybe<Scalars['String']>;
  /** 발주상태 타입 */
  searchType?: Maybe<ProjectPurchaseOrderManageStatusType>;
};

export type FetchPaymentManageByQuotationUserIdInput = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  /** 프로젝트이름,파트너 이름,제조카테고리 검색어 */
  searchKeyword?: Maybe<Scalars['String']>;
  /** 발주상태 타입 */
  searchType?: Maybe<ProjectPurchaseOrderManageStatusType>;
};

/** BO 제조 시작 목록 */
export type FetchProcessingBoInput = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  /** BO 제조 시작 목록 요청 타입 */
  reqType?: Maybe<CapaBackOfficeFetchProcessingReqType>;
  /** 목록 탭 필터 */
  filterStatus?: Maybe<Array<CapaPurchaseOrderStatus>>;
  /** 발주서 생성날짜 (ex ISOString 형태로 받음 ), 프로젝트명, 고객명, 고객전화, 파트너명 */
  searchKeyword?: Maybe<Scalars['String']>;
  /** 제조목록 검색 타입 */
  searchType?: Maybe<FetchProcessingBoSearchType>;
  /** 제조목록 검색 타입 */
  isLongLivedCancelRequest?: Maybe<Scalars['Boolean']>;
  /** 테스트 포함 여부 */
  includeTest?: Maybe<Scalars['Boolean']>;
};

/** 제조목록 검색 타입 */
export enum FetchProcessingBoSearchType {
  /** 발주서 생성날짜 */
  PurchaseOrderCreationDate = 'PURCHASE_ORDER_CREATION_DATE',
  /** 프로젝트명 */
  ProjectName = 'PROJECT_NAME',
  /** 고객 회사명 */
  UserOrganizationName = 'USER_ORGANIZATION_NAME',
  /** 고객명 */
  UserManagerName = 'USER_MANAGER_NAME',
  /** 고객전화 */
  UserPhone = 'USER_PHONE',
  /** 파트너명 */
  PartnerOrganizationName = 'PARTNER_ORGANIZATION_NAME'
}

/** FetchProjectManagementReceivedQuotationsInput */
export type FetchProjectManagementReceivedQuotationsInput = {
  rfqId?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};

export type FetchPurchaseOrderInput = {
  purchaseOrderId: Scalars['String'];
};

export type FetchPurchaseOrderPaymentCancelData = {
  __typename?: 'FetchPurchaseOrderPaymentCancelData';
  /** 취소 사유 */
  reason?: Maybe<Scalars['String']>;
  /** 취소 금액 */
  amount: Scalars['Float'];
};

export type FetchPurchaseOrderPaymentData = {
  __typename?: 'FetchPurchaseOrderPaymentData';
  quotationId: Scalars['String'];
  /** quotation user id */
  quotationUserId: Scalars['String'];
  /** quotation partner user id */
  quotationPartnerUserId: Scalars['String'];
  /** quotation partner id */
  quotationPartnerId: Scalars['String'];
  /** rfq id */
  rfqId: Scalars['String'];
  /** purchase order id */
  purchaseOrderId?: Maybe<Scalars['String']>;
  /** total amount */
  totalAmount: Scalars['Float'];
  pgProvider: CapapgProviders;
  paymentMethod: CapaPaymentMethodType;
  createdAt: Scalars['String'];
  /** 결제 승인 날짜 */
  paidAt?: Maybe<Scalars['String']>;
  /** 지급승인(구매확정) 날짜 */
  confirmedAt?: Maybe<Scalars['String']>;
  virtualAccount?: Maybe<VirtualAccountInfo>;
  cardInfo?: Maybe<CapaPaymentCardInfo>;
};

export type FetchPurchaseOrdersBoInput = {
  rfqId: Scalars['String'];
  quotationPartnerId: Scalars['String'];
  purchaseOrderCreatedAt?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};

export type FetchPurchaseOrdersInput = {
  rfqId: Scalars['String'];
  quotationPartnerId: Scalars['String'];
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};

/** QuotationChatRoomSharedFilesInput */
export type FetchQuotationChatRoomAnnotationCommentInput = {
  chatRoomId: Scalars['String'];
};

export type FetchQuotationChatRoomInput = {
  chatRoomId: Scalars['String'];
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  lastMessageId?: Maybe<Scalars['String']>;
};

/** QuotationChatRoomSharedFilesInput */
export type FetchQuotationChatRoomSharedFilesInput = {
  chatRoomId: Scalars['String'];
  filterType: CapaQuotationChatRoomSharedFilesFilterType;
};

/** fetchQuotationChatRoomSpecificAnnotationInput */
export type FetchQuotationChatRoomSpecificAnnotationBoInput = {
  userId: Scalars['String'];
  chatRoomId: Scalars['String'];
  quotationSharedFileId: Scalars['String'];
  filterType: CapaQuotationChatRoomSharedFileType;
};

/** fetchQuotationChatRoomSpecificAnnotationInput */
export type FetchQuotationChatRoomSpecificAnnotationInput = {
  chatRoomId: Scalars['String'];
  quotationSharedFileId: Scalars['String'];
  filterType: CapaQuotationChatRoomSharedFileType;
};

export type FetchQuotationPartnerActivityInput = {
  quotationPartnerId?: Maybe<Scalars['String']>;
};

export type FetchQuotationPartnerBoByEmailInput = {
  quotationPartnerEmail: Scalars['String'];
};

export type FetchQuotationPartnerChatRoomsInput = {
  /**   CONSULTING,CONFIRMED 사용 안할예정 */
  chatRoomsFilter?: Maybe<CapaQuotationChatRoomsFilterType>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  /** 상대방 이름 (닉네임/회사명), 프로젝트 이름, 제조 카테고리 */
  searchKeyword?: Maybe<Scalars['String']>;
  /** rfq상태 , 발주 상태 제조상태 검색 위한 Enum */
  searchStatusType?: Maybe<ProjectStatusType>;
};

/** FetchQuotationPartnerManagementRfqsInput */
export type FetchQuotationPartnerManagementRfqsInput = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};

/** FetchQuotationPartnerProjectManagementDetailInput */
export type FetchQuotationPartnerProjectManagementDetailInput = {
  rfqId: Scalars['String'];
};

export type FetchQuotationPartnerRfqReviewInput = {
  quotationPartnerId?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};

export type FetchQuotationPartnerStrategyBoInput = {
  quotationPartnerId: Scalars['String'];
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};

export type FetchQuotationUserBoByEmailInput = {
  quotationUserEmail: Scalars['String'];
};

export type FetchQuotationUserBoInput = {
  quotationUserId: Scalars['String'];
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};

export type FetchQuotationUserChatRoomsInput = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  /** 상대방 이름 (닉네임/회사명), 프로젝트 이름, 제조 카테고리 */
  searchKeyword?: Maybe<Scalars['String']>;
  /** rfq상태 , 발주 상태 제조상태 검색 위한 Enum */
  searchStatusType?: Maybe<ProjectStatusType>;
};

/** FetchQuotationUserProjectManagementDetailInput */
export type FetchQuotationUserProjectManagementDetailInput = {
  rfqId: Scalars['String'];
};

/** FetchQuotationUserProjectManagementRfqsInput */
export type FetchQuotationUserProjectManagementRfqsInput = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};

export type FetchQuotationUserRfqReviewsInput = {
  quotationUserId?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};

export type FetchQuotationUserRfqsBoInput = {
  quotationUserId?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};

export type FetchQuotationUserRfqsInput = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  /** 프로젝트이름,고객닉네임,회사명,제조카테고리,주소 검색어 */
  searchKeyword?: Maybe<Scalars['String']>;
  /** 채팅목록/고객rfq상태 , 발주 상태 제조상태 검색 위한 Enum */
  searchType?: Maybe<ProjectStatusType>;
};

/** 캐파 커넥트 유저 정보 */
export type FetchRecommendedContactList = {
  __typename?: 'FetchRecommendedContactList';
  /** capa user email */
  email: Scalars['String'];
  /** nick name */
  nickName?: Maybe<Scalars['String']>;
  isCapaUser?: Maybe<Scalars['Boolean']>;
};

export type FetchRecommendedContactListsOutput = {
  __typename?: 'FetchRecommendedContactListsOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<Array<FetchRecommendedContactList>>;
};

export type FetchRecommendedContactUserListInput = {
  chatRoomUserId: Scalars['String'];
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};

export type FetchRfqInput = {
  rfqId: Scalars['String'];
};

export type FetchRfqPartnerEvaluationInput = {
  quotationPartnerId?: Maybe<Scalars['String']>;
};

export type FetchRfqQuotationsBoInput = {
  rfqId: Scalars['String'];
  quotationPartnerId: Scalars['String'];
  quotationCreatedAt?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};

export type FetchRfqReviewBoInput = {
  quotationPartnerId?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  includeTest?: Maybe<Scalars['Boolean']>;
};

export type FetchRfqsInput = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  serviceType?: Maybe<CapaQuotationServiceType>;
  detailedServiceTypes?: Maybe<Array<CapaQuotationDetailedServiceType>>;
  materials?: Maybe<Array<CapaQuotationMaterial>>;
  detailedMaterials?: Maybe<Array<CapaQuotationDetailedMaterialType>>;
  productPurposes?: Maybe<Array<CapaQuotationProductPurpose>>;
  eventType?: Maybe<CapaQuotationRfqEventType>;
  optionalServices?: Maybe<Array<CapaQuotationServiceType>>;
  favorite?: Maybe<Scalars['Boolean']>;
  inludeTest?: Maybe<Scalars['Boolean']>;
  /** rfq 상태 */
  status?: Maybe<CapaRfqStatus>;
  /** 검색필드 */
  searchField?: Maybe<CapaSearchRfqsBoFieldType>;
  /** 검색아이템 */
  searchItem?: Maybe<Scalars['String']>;
  /** 정렬필드 */
  sortField?: Maybe<CapaSortRfqsBoFieldType>;
  /** 정렬 차순 - asc,desc */
  sortType?: Maybe<CapaSortType>;
};

export type FetchServiceRatingBoInput = {
  userType?: Maybe<CapaQuotationUserType>;
  ratingOffset?: Maybe<Scalars['Float']>;
  ratingLimit?: Maybe<Scalars['Float']>;
  includeTest?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};

export type FetchServiceSuggestionBoInput = {
  userType?: Maybe<CapaQuotationUserType>;
  includeTest?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};

export type FetchServiceTypeStatisticsInput = {
  serviceType: CapaQuotationServiceType;
};

export type FetchUsersInput = {
  companyRegistrationNumber?: Maybe<Scalars['Boolean']>;
  includeTest?: Maybe<Scalars['Boolean']>;
  bizType?: Maybe<CapaQuotationUserBizType>;
  status?: Maybe<CapaQuotationUserStatus>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  /** 검색필드 */
  searchField?: Maybe<CapaSearchQuotationUsersBoFieldType>;
  /** 검색아이템 */
  searchItem?: Maybe<Scalars['String']>;
  /** 정렬필드 */
  sortField?: Maybe<CapaSortUsersBoFieldType>;
  /** 정렬 차순 - asc,desc */
  sortType?: Maybe<CapaSortType>;
};

export type FetchWithdrawnChatRoomsInput = {
  chatRoomsFilter?: Maybe<CapaQuotationChatRoomsFilterType>;
  /** 캐파 서비스 */
  serviceType?: Maybe<CapaServiceType>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};

export type IcxAuthentication = {
  __typename?: 'IcxAuthentication';
  /** JWT */
  accessToken: Scalars['String'];
  tokenType: Scalars['String'];
  expiresIn: Scalars['Float'];
  /** JWT */
  refreshToken: Scalars['String'];
  redirectUrl?: Maybe<Scalars['String']>;
};

/** icx 로그인 */
export type IcxAuthenticationInput = {
  /** Ateam에서 지정해준 비밀번호 */
  password: Scalars['String'];
  /** device token */
  deviceToken?: Maybe<Scalars['String']>;
};

export type IcxAuthenticationOutput = {
  __typename?: 'IcxAuthenticationOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<IcxAuthentication>;
};

export type IcxDetatilModel = {
  __typename?: 'IcxDetatilModel';
  id?: Maybe<Scalars['String']>;
  basicResearchId?: Maybe<Scalars['String']>;
  basicListStandard?: Maybe<Scalars['String']>;
  basicNote?: Maybe<Scalars['String']>;
  basicCity?: Maybe<Scalars['String']>;
  basicCounty?: Maybe<Scalars['String']>;
  basicFactoryManagementNumber?: Maybe<Scalars['String']>;
  basicListId?: Maybe<Scalars['String']>;
  basicComplexId?: Maybe<Scalars['String']>;
  basicMapNumber?: Maybe<Scalars['String']>;
  basicAreaNumber?: Maybe<Scalars['String']>;
  basicSerialNumber?: Maybe<Scalars['String']>;
  basicCompanyName?: Maybe<Scalars['String']>;
  basicComplexName?: Maybe<Scalars['String']>;
  basicKnowledgeIndustryCenter?: Maybe<Scalars['String']>;
  basicComplexAbbreviatedName?: Maybe<Scalars['String']>;
  basicHeadquarters?: Maybe<Scalars['String']>;
  basicComplexDivision?: Maybe<Scalars['String']>;
  basicCompanyScale?: Maybe<Scalars['String']>;
  basicIndustryCode?: Maybe<Scalars['String']>;
  basicIndustryMiddleCategory?: Maybe<Scalars['String']>;
  basicIndustrial?: Maybe<Scalars['String']>;
  basicIndustryCategory?: Maybe<Scalars['String']>;
  keyresultsProduce_2020?: Maybe<Scalars['String']>;
  keyresultsProduce_2019?: Maybe<Scalars['String']>;
  keyresultsExport_2020?: Maybe<Scalars['String']>;
  keyresultsExport_2019?: Maybe<Scalars['String']>;
  keyresultsEmploy_2020?: Maybe<Scalars['String']>;
  keyresultsEmploy_2019?: Maybe<Scalars['String']>;
  fullinvestigationCompanyName?: Maybe<Scalars['String']>;
  fullinvestigationName_1?: Maybe<Scalars['String']>;
  fullinvestigationGender_1?: Maybe<Scalars['String']>;
  fullinvestigationName_2?: Maybe<Scalars['String']>;
  fullinvestigationGender_2?: Maybe<Scalars['String']>;
  fullinvestigationStreetAddress?: Maybe<Scalars['String']>;
  fullinvestigationNumberAddress?: Maybe<Scalars['String']>;
  fullinvestigationDivision?: Maybe<Scalars['String']>;
  fullinvestigationCorporationNumber?: Maybe<Scalars['String']>;
  fullinvestigationYear?: Maybe<Scalars['String']>;
  fullinvestigationMonth?: Maybe<Scalars['String']>;
  fullinvestigationRegistrationNumber?: Maybe<Scalars['String']>;
  fullinvestigationWhether?: Maybe<Scalars['String']>;
  fullinvestigationAddress?: Maybe<Scalars['String']>;
  fullinvestigationCompanyScale?: Maybe<Scalars['String']>;
  fullinvestigationStockMarket?: Maybe<Scalars['String']>;
  fullinvestigationStockKosdaq?: Maybe<Scalars['String']>;
  fullinvestigationOutwardAppearance?: Maybe<Scalars['String']>;
  fullinvestigationNormal?: Maybe<Scalars['String']>;
  fullinvestigationCall?: Maybe<Scalars['String']>;
  fullinvestigationFax?: Maybe<Scalars['String']>;
  fullinvestigationEmail?: Maybe<Scalars['String']>;
  workplaceWhetherMine?: Maybe<Scalars['String']>;
  workplaceWhetherRent?: Maybe<Scalars['String']>;
  workplaceRentCompanyName?: Maybe<Scalars['String']>;
  workplaceYear?: Maybe<Scalars['String']>;
  workplaceMonth?: Maybe<Scalars['String']>;
  workplaceRegistrationStatus?: Maybe<Scalars['String']>;
  workplaceStartYear?: Maybe<Scalars['String']>;
  workplaceStartMonth?: Maybe<Scalars['String']>;
  workplaceOnlyOneCompany?: Maybe<Scalars['String']>;
  workplaceOverseasCompany?: Maybe<Scalars['String']>;
  workplaceManyCompany?: Maybe<Scalars['String']>;
  workplaceCountryName_1?: Maybe<Scalars['String']>;
  workplaceCountryName_2?: Maybe<Scalars['String']>;
  workplaceCountryName_3?: Maybe<Scalars['String']>;
  workplaceCountryName_4?: Maybe<Scalars['String']>;
  workplaceCountryName_5?: Maybe<Scalars['String']>;
  workplaceUsa?: Maybe<Scalars['String']>;
  workplaceChina?: Maybe<Scalars['String']>;
  workplaceJapan?: Maybe<Scalars['String']>;
  workplaceSoutheastAsia?: Maybe<Scalars['String']>;
  workplaceEurope?: Maybe<Scalars['String']>;
  workplaceMiddleEast?: Maybe<Scalars['String']>;
  workplaceLatinAmerica?: Maybe<Scalars['String']>;
  workplaceEtc?: Maybe<Scalars['String']>;
  workplaceNone?: Maybe<Scalars['String']>;
  workplaceEmployeesNumber?: Maybe<Scalars['String']>;
  workplaceAllCompanyName?: Maybe<Scalars['String']>;
  workplaceCurrnetBusiness?: Maybe<Scalars['String']>;
  workplaceCity?: Maybe<Scalars['String']>;
  workplaceCounty?: Maybe<Scalars['String']>;
  workplaceBusinessStatus?: Maybe<Scalars['String']>;
  workplaceIndustrialComplexBusinessCount?: Maybe<Scalars['String']>;
  activitiesProductName_1?: Maybe<Scalars['String']>;
  activitiesProductType_1?: Maybe<Scalars['String']>;
  activitiesIndustryClassification_1?: Maybe<Scalars['String']>;
  activitiesSectors_1?: Maybe<Scalars['String']>;
  activitiesProductName_2?: Maybe<Scalars['String']>;
  activitiesProductType_2?: Maybe<Scalars['String']>;
  activitiesIndustryClassification_2?: Maybe<Scalars['String']>;
  activitiesSectors_2?: Maybe<Scalars['String']>;
  activitiesProductName_3?: Maybe<Scalars['String']>;
  activitiesProductType_3?: Maybe<Scalars['String']>;
  activitiesIndustryClassification_3?: Maybe<Scalars['String']>;
  activitiesSectors_3?: Maybe<Scalars['String']>;
  activityCharacteristics_1?: Maybe<Scalars['String']>;
  activityCharacteristics_2?: Maybe<Scalars['String']>;
  activityCharacteristics_3?: Maybe<Scalars['String']>;
  activityCharacteristics_4?: Maybe<Scalars['String']>;
  activityCharacteristics_5?: Maybe<Scalars['String']>;
  activityCharacteristics_6?: Maybe<Scalars['String']>;
  activityCharacteristics_7?: Maybe<Scalars['String']>;
  purchasesLargeBusiness?: Maybe<Scalars['String']>;
  purchasesSmallBusiness?: Maybe<Scalars['String']>;
  purchasesGovernment?: Maybe<Scalars['String']>;
  purchasesUniversity?: Maybe<Scalars['String']>;
  purchasesHeadquarters?: Maybe<Scalars['String']>;
  purchasesOversearImport?: Maybe<Scalars['String']>;
  purchasesIndividualUnspecifiedMajority?: Maybe<Scalars['String']>;
  purchasesOthers?: Maybe<Scalars['String']>;
  purchasesBeforePurchase?: Maybe<Scalars['String']>;
  purchasesNote?: Maybe<Scalars['String']>;
  purchasesSeoul?: Maybe<Scalars['String']>;
  purchasesBusan?: Maybe<Scalars['String']>;
  purchasesTegu?: Maybe<Scalars['String']>;
  purchasesIncheon?: Maybe<Scalars['String']>;
  purchasesGwangju?: Maybe<Scalars['String']>;
  purchasesDaejon?: Maybe<Scalars['String']>;
  purchasesUlsan?: Maybe<Scalars['String']>;
  purchasesSejong?: Maybe<Scalars['String']>;
  purchasesKyonggi?: Maybe<Scalars['String']>;
  purchasesKangwon?: Maybe<Scalars['String']>;
  purchasesChungbuk?: Maybe<Scalars['String']>;
  purchasesChungnam?: Maybe<Scalars['String']>;
  purchasesJeonbuk?: Maybe<Scalars['String']>;
  purchasesChonnam?: Maybe<Scalars['String']>;
  purchasesKyongbok?: Maybe<Scalars['String']>;
  purchasesKyongnam?: Maybe<Scalars['String']>;
  purchasesJeju?: Maybe<Scalars['String']>;
  purchasesIndustrialComplex1?: Maybe<Scalars['String']>;
  purchasesIndustrialComplex2?: Maybe<Scalars['String']>;
  purchasesIndustrialComplex3?: Maybe<Scalars['String']>;
  purchasesOthers2?: Maybe<Scalars['String']>;
  vendorLargeBusiness?: Maybe<Scalars['String']>;
  vendorSmallBusiness?: Maybe<Scalars['String']>;
  vendorGovernment?: Maybe<Scalars['String']>;
  vendorUniversity?: Maybe<Scalars['String']>;
  vendorHeadquarters?: Maybe<Scalars['String']>;
  vendorOversearDirectExport?: Maybe<Scalars['String']>;
  vendorInvidualUnspecifiedMajority?: Maybe<Scalars['String']>;
  vendorOthers?: Maybe<Scalars['String']>;
  vendorBeforePurchase?: Maybe<Scalars['String']>;
  vendorNote?: Maybe<Scalars['String']>;
  vendorSeoul?: Maybe<Scalars['String']>;
  vendorBusan?: Maybe<Scalars['String']>;
  vendorTegu?: Maybe<Scalars['String']>;
  vendorIncheon?: Maybe<Scalars['String']>;
  vendorGwangju?: Maybe<Scalars['String']>;
  vendorDaejon?: Maybe<Scalars['String']>;
  vendorUlsan?: Maybe<Scalars['String']>;
  vendorSejong?: Maybe<Scalars['String']>;
  vendorKyonggi?: Maybe<Scalars['String']>;
  vendorKangwon?: Maybe<Scalars['String']>;
  vendorChungbuk?: Maybe<Scalars['String']>;
  vendorChungnam?: Maybe<Scalars['String']>;
  vendorJeonbuk?: Maybe<Scalars['String']>;
  vendorChonnam?: Maybe<Scalars['String']>;
  vendorKyongbok?: Maybe<Scalars['String']>;
  vendorKyongnam?: Maybe<Scalars['String']>;
  vendorJeju?: Maybe<Scalars['String']>;
  vendorIndustrialComplex1?: Maybe<Scalars['String']>;
  vendorIndustrialComplex2?: Maybe<Scalars['String']>;
  vendorIndustrialComplex3?: Maybe<Scalars['String']>;
  vendorOthers2?: Maybe<Scalars['String']>;
  rndWhether?: Maybe<Scalars['String']>;
  rndInvestment_2020?: Maybe<Scalars['String']>;
  rndPatentApplication_2020?: Maybe<Scalars['String']>;
  rndPatentRegistration_2020?: Maybe<Scalars['String']>;
  rndInvestment_2019?: Maybe<Scalars['String']>;
  rndPatentApplication_2019?: Maybe<Scalars['String']>;
  rndPatentRegistration_2019?: Maybe<Scalars['String']>;
  solarInstalledOrNot?: Maybe<Scalars['String']>;
  solarInstalledCapacity?: Maybe<Scalars['String']>;
  safetyManagerPresence?: Maybe<Scalars['String']>;
  safetyManagerName?: Maybe<Scalars['String']>;
  safetyManagerPosition?: Maybe<Scalars['String']>;
  safetyManagerPhoneNumber?: Maybe<Scalars['String']>;
  safetyIndustrialRiskAres?: Maybe<Scalars['String']>;
  safetyFirstPlace?: Maybe<Scalars['String']>;
  safetySecondPlace?: Maybe<Scalars['String']>;
  employees2020FullTimeTotal?: Maybe<Scalars['String']>;
  employees2020FullTimeRnd?: Maybe<Scalars['String']>;
  employees2020FullTimeSkilledLocals?: Maybe<Scalars['String']>;
  employees2020FullTimeSkilledForeigner?: Maybe<Scalars['String']>;
  employees2020FullTimeOthers?: Maybe<Scalars['String']>;
  employees2020FullTimeWomanTotal?: Maybe<Scalars['String']>;
  employees2020FullTimeWomanRnd?: Maybe<Scalars['String']>;
  employees2020FullTimeLocalWoman?: Maybe<Scalars['String']>;
  employees2020FullTimeForeignerWoman?: Maybe<Scalars['String']>;
  employees2020FullTimeWomanOthers?: Maybe<Scalars['String']>;
  employees2020_15_19Total?: Maybe<Scalars['String']>;
  employees2020_15_19Rnd?: Maybe<Scalars['String']>;
  employees2020_15_19SkilledLocals?: Maybe<Scalars['String']>;
  employees2020_15_19SkilledForeigner?: Maybe<Scalars['String']>;
  employees2020_15_19Others?: Maybe<Scalars['String']>;
  employees2020_20_29Total?: Maybe<Scalars['String']>;
  employees2020_20_29Rnd?: Maybe<Scalars['String']>;
  employees2020_20_29SkilledLocals?: Maybe<Scalars['String']>;
  employees2020_20_29SkilledForeigners?: Maybe<Scalars['String']>;
  employees2020_20_29Others?: Maybe<Scalars['String']>;
  employees2020_30_34Total?: Maybe<Scalars['String']>;
  employees2020_30_34Rnd?: Maybe<Scalars['String']>;
  employees2020_30_34SkilledLocals?: Maybe<Scalars['String']>;
  employees2020_30_34SkilledForeigners?: Maybe<Scalars['String']>;
  employees2020_30_34Others?: Maybe<Scalars['String']>;
  employees2020_35_39Total?: Maybe<Scalars['String']>;
  employees2020_35_39Rnd?: Maybe<Scalars['String']>;
  employees2020_35_39SkilledLocals?: Maybe<Scalars['String']>;
  employees2020_35_39SkilledForeigners?: Maybe<Scalars['String']>;
  employees2020_35_39Others?: Maybe<Scalars['String']>;
  employees2020_40_49Total?: Maybe<Scalars['String']>;
  employees2020_40_49Rnd?: Maybe<Scalars['String']>;
  employees2020_40_49SkilledLocals?: Maybe<Scalars['String']>;
  employees2020_40_49SkilledForeigners?: Maybe<Scalars['String']>;
  employees2020_40_49Others?: Maybe<Scalars['String']>;
  employees2020_50_59Total?: Maybe<Scalars['String']>;
  employees2020_50_59Rnd?: Maybe<Scalars['String']>;
  employees2020_50_59SkilledLocals?: Maybe<Scalars['String']>;
  employees2020_50_59SkilledForeigners?: Maybe<Scalars['String']>;
  employees2020_50_59Others?: Maybe<Scalars['String']>;
  employees2020Above_60Total?: Maybe<Scalars['String']>;
  employees2020Above_60Rnd?: Maybe<Scalars['String']>;
  employees2020Above_60SkilledLocals?: Maybe<Scalars['String']>;
  employees2020Above_60SkilledForeigner?: Maybe<Scalars['String']>;
  employees2020Above_60Others?: Maybe<Scalars['String']>;
  thirdPartyEmployees2020DispatchedTotal?: Maybe<Scalars['String']>;
  thirdPartyEmployees2020DispatchedRnd?: Maybe<Scalars['String']>;
  thirdPartyEmployees2020DispatchedSkilledLocal?: Maybe<Scalars['String']>;
  thirdPartyEmployees2020DispatchedSkilledForeigner?: Maybe<Scalars['String']>;
  thirdPartyEmployees2020DispatchedOthers?: Maybe<Scalars['String']>;
  thirdPartyEmployees2020DispatchedWomenTotal?: Maybe<Scalars['String']>;
  thirdPartyEmployees2020DispatchedWomenRnd?: Maybe<Scalars['String']>;
  thirdPartyEmployees2020DispatchedLocalWomenProduction?: Maybe<
    Scalars['String']
  >;
  thirdPartyEmployees2020DispatchedForeignerWomenProduction?: Maybe<
    Scalars['String']
  >;
  thirdPartyEmployees2020DispatchedWomenOthers?: Maybe<Scalars['String']>;
  employees2019FullTimeTotal?: Maybe<Scalars['String']>;
  employees2019FullTimeRnd?: Maybe<Scalars['String']>;
  employees2019FullTimeSkilledLocals?: Maybe<Scalars['String']>;
  employees2019FullTimeSkilledForeigner?: Maybe<Scalars['String']>;
  employees2019FullTimeOthers?: Maybe<Scalars['String']>;
  employees2019FullTimeWomanTotal?: Maybe<Scalars['String']>;
  employees2019FullTimeWomanRnd?: Maybe<Scalars['String']>;
  employees2019FullTimeLocalWoman?: Maybe<Scalars['String']>;
  employees2019FullTimeForeignerWoman?: Maybe<Scalars['String']>;
  employees2019FullTimeWomanOthers?: Maybe<Scalars['String']>;
  employees2019_15_19Total?: Maybe<Scalars['String']>;
  employees2019_15_19Rnd?: Maybe<Scalars['String']>;
  employees2019_15_19SkilledLocals?: Maybe<Scalars['String']>;
  employees2019_15_19SkilledForeigner?: Maybe<Scalars['String']>;
  employees2019_15_19Others?: Maybe<Scalars['String']>;
  employees2019_20_29Total?: Maybe<Scalars['String']>;
  employees2019_20_29Rnd?: Maybe<Scalars['String']>;
  employees2019_20_29SkilledLocals?: Maybe<Scalars['String']>;
  employees2019_20_29SkilledForeigners?: Maybe<Scalars['String']>;
  employees2019_20_29Others?: Maybe<Scalars['String']>;
  employees2019_30_34Total?: Maybe<Scalars['String']>;
  employees2019_30_34Rnd?: Maybe<Scalars['String']>;
  employees2019_30_34SkilledLocals?: Maybe<Scalars['String']>;
  employees2019_30_34SkilledForeigners?: Maybe<Scalars['String']>;
  employees2019_30_34Others?: Maybe<Scalars['String']>;
  employees2019_35_39Total?: Maybe<Scalars['String']>;
  employees2019_35_39Rnd?: Maybe<Scalars['String']>;
  employees2019_35_39SkilledLocals?: Maybe<Scalars['String']>;
  employees2019_35_39SkilledForeigners?: Maybe<Scalars['String']>;
  employees2019_35_39Others?: Maybe<Scalars['String']>;
  employees2019_40_49Total?: Maybe<Scalars['String']>;
  employees2019_40_49Rnd?: Maybe<Scalars['String']>;
  employees2019_40_49SkilledLocals?: Maybe<Scalars['String']>;
  employees2019_40_49SkilledForeigners?: Maybe<Scalars['String']>;
  employees2019_40_49Others?: Maybe<Scalars['String']>;
  employees2019_50_59Others?: Maybe<Scalars['String']>;
  employees2019_50_59Rnd?: Maybe<Scalars['String']>;
  employees2019_50_59SkilledLocals?: Maybe<Scalars['String']>;
  employees2019_50_59SkilledForeigners?: Maybe<Scalars['String']>;
  employees2019_50_59Others2?: Maybe<Scalars['String']>;
  employees2019Above_60Total?: Maybe<Scalars['String']>;
  employees2019Above_60Rnd?: Maybe<Scalars['String']>;
  employees2019Above_60SkilledLocals?: Maybe<Scalars['String']>;
  employees2019Above_60SkilledForeigner?: Maybe<Scalars['String']>;
  employees2019Above_60Others?: Maybe<Scalars['String']>;
  thirdPartyEmployees2019DispatchedTotal?: Maybe<Scalars['String']>;
  thirdPartyEmployees2019DispatchedRnd?: Maybe<Scalars['String']>;
  thirdPartyEmployees2019DispatchedSkilledLocal?: Maybe<Scalars['String']>;
  thirdPartyEmployees2019DispatchedSkilledForeigner?: Maybe<Scalars['String']>;
  thirdPartyEmployees2019DispatchedOthers?: Maybe<Scalars['String']>;
  thirdPartyEmployees2019DispatchedWomenTotal?: Maybe<Scalars['String']>;
  thirdPartyEmployees2019DispatchedWomenRnd?: Maybe<Scalars['String']>;
  thirdPartyEmployees2019DispatchedLocalWomenProduction?: Maybe<
    Scalars['String']
  >;
  thirdPartyEmployees2019DispatchedForeignerWomenProduction?: Maybe<
    Scalars['String']
  >;
  thirdPartyEmployees2019DispatchedWomenOthers?: Maybe<Scalars['String']>;
  productionPerformanceResponseCriteria_1?: Maybe<Scalars['String']>;
  productionPerformanceResponseCriteria_2?: Maybe<Scalars['String']>;
  productionPerformanceIndustrialComplexBusinessCount?: Maybe<
    Scalars['String']
  >;
  productionPerformance_2020Cost?: Maybe<Scalars['String']>;
  productionPerformance_2019Cost?: Maybe<Scalars['String']>;
  exportStatusExportPerformanceIn_19_20?: Maybe<Scalars['String']>;
  exportStatusNoExport?: Maybe<Scalars['String']>;
  exportStatusNotExported?: Maybe<Scalars['String']>;
  exportStatusOthers?: Maybe<Scalars['String']>;
  exportStatusOtherBusiness?: Maybe<Scalars['String']>;
  exportPerformance2020Absence?: Maybe<Scalars['String']>;
  exportPerformance2020ExportCost?: Maybe<Scalars['String']>;
  exportPerformance2019Absence?: Maybe<Scalars['String']>;
  exportPerformance2019ExportCost?: Maybe<Scalars['String']>;
  exportCountryUsa?: Maybe<Scalars['String']>;
  exportCountryChina?: Maybe<Scalars['String']>;
  exportCountryJapan?: Maybe<Scalars['String']>;
  exportCountrySoutheastAsia?: Maybe<Scalars['String']>;
  exportCountryEurope?: Maybe<Scalars['String']>;
  exportCountryMiddleEast?: Maybe<Scalars['String']>;
  exportCountryLatinAmerica?: Maybe<Scalars['String']>;
  exportCountryOthers?: Maybe<Scalars['String']>;
  exportCountryNote?: Maybe<Scalars['String']>;
  respondantRespondantName?: Maybe<Scalars['String']>;
  respondantDepartment?: Maybe<Scalars['String']>;
  respondantRole?: Maybe<Scalars['String']>;
  respondantPhoneNumber?: Maybe<Scalars['String']>;
  respondantFaxNumber?: Maybe<Scalars['String']>;
  respondantEmail?: Maybe<Scalars['String']>;
  respondantAgreeTerms?: Maybe<Scalars['String']>;
  respondantMonth?: Maybe<Scalars['String']>;
  respondantDate?: Maybe<Scalars['String']>;
  respondantRespondant?: Maybe<Scalars['String']>;
  respondantUserSignature?: Maybe<Scalars['String']>;
};

export type IcxFactoron = {
  __typename?: 'IcxFactoron';
  icxModelList?: Maybe<Array<IcxFactoronModel>>;
  count?: Maybe<Scalars['Float']>;
};

export type IcxFactoronModel = {
  __typename?: 'IcxFactoronModel';
  id: Scalars['String'];
  no?: Maybe<Scalars['String']>;
  industrialComplex?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  representativeName?: Maybe<Scalars['String']>;
  birthOrRegistrationNumber?: Maybe<Scalars['String']>;
  industrialCenterName?: Maybe<Scalars['String']>;
  factoryControlNumber?: Maybe<Scalars['String']>;
  factoryAddressRoadName?: Maybe<Scalars['String']>;
  factoryAddressNo?: Maybe<Scalars['String']>;
  companyRegistrationNumber?: Maybe<Scalars['String']>;
  representativeIndustryCode?: Maybe<Scalars['String']>;
  representativeIndustryNumber?: Maybe<Scalars['String']>;
  sectors?: Maybe<Scalars['String']>;
  industryName?: Maybe<Scalars['String']>;
  jurisdictionOrganizationName?: Maybe<Scalars['String']>;
  jurisdictionOrganizationCode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  establishment?: Maybe<Scalars['String']>;
  moveInType?: Maybe<Scalars['String']>;
  classificationOfHoldings?: Maybe<Scalars['String']>;
  applicationDate?: Maybe<Scalars['String']>;
  firstApprovalDate?: Maybe<Scalars['String']>;
  approvalClassification?: Maybe<Scalars['String']>;
  approvalDate?: Maybe<Scalars['String']>;
  firstRegistrationDate?: Maybe<Scalars['String']>;
  registrationCategory?: Maybe<Scalars['String']>;
  registrationDate?: Maybe<Scalars['String']>;
  registrationNumber?: Maybe<Scalars['String']>;
  changeReportDate?: Maybe<Scalars['String']>;
  businessType?: Maybe<Scalars['String']>;
  contact?: Maybe<Scalars['String']>;
  faxNumber?: Maybe<Scalars['String']>;
  maleEmployeeCount?: Maybe<Scalars['String']>;
  femaleEmployeeCount?: Maybe<Scalars['String']>;
  maleForeigner?: Maybe<Scalars['String']>;
  femaleForeigner?: Maybe<Scalars['String']>;
  employeesCount?: Maybe<Scalars['String']>;
  product?: Maybe<Scalars['String']>;
  mainMaterial?: Maybe<Scalars['String']>;
  businessStartDate?: Maybe<Scalars['String']>;
  factoryCompletionDate?: Maybe<Scalars['String']>;
  estimatedConstructionStartDate?: Maybe<Scalars['String']>;
  estimatedConstructionCompletionDate?: Maybe<Scalars['String']>;
  factorySize?: Maybe<Scalars['String']>;
  useArea?: Maybe<Scalars['String']>;
  landCategory?: Maybe<Scalars['String']>;
  landArea?: Maybe<Scalars['String']>;
  manufacturingFacilityArea?: Maybe<Scalars['String']>;
  ancillaryFacilityArea?: Maybe<Scalars['String']>;
  officeArea?: Maybe<Scalars['String']>;
  warehouseArea?: Maybe<Scalars['String']>;
  horizontalProjectionArea?: Maybe<Scalars['String']>;
  buildingArea?: Maybe<Scalars['String']>;
  airQuality?: Maybe<Scalars['String']>;
  waterQuality?: Maybe<Scalars['String']>;
  isNoiseVibration?: Maybe<Scalars['String']>;
  livingWater?: Maybe<Scalars['String']>;
  industrialWater?: Maybe<Scalars['String']>;
  power?: Maybe<Scalars['String']>;
  oil?: Maybe<Scalars['String']>;
  gas?: Maybe<Scalars['String']>;
  etcOil?: Maybe<Scalars['String']>;
  equityMillion?: Maybe<Scalars['String']>;
  debtCapitalMillion?: Maybe<Scalars['String']>;
  foreignInvestmentRatio?: Maybe<Scalars['String']>;
  foreignInvestment?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  representativeEmail?: Maybe<Scalars['String']>;
  representativePhoneNumber?: Maybe<Scalars['String']>;
  factoryWebsite?: Maybe<Scalars['String']>;
  factoryZipCode?: Maybe<Scalars['String']>;
  representativeZipCode?: Maybe<Scalars['String']>;
  representativeAddress?: Maybe<Scalars['String']>;
  reasonChange?: Maybe<Scalars['String']>;
  factoryManagementNumberOld?: Maybe<Scalars['String']>;
  expirationPeriod?: Maybe<Scalars['String']>;
  factoryCondition?: Maybe<Scalars['String']>;
  freeEconomicZone?: Maybe<Scalars['String']>;
};

export type IcxFactoronModelOutput = {
  __typename?: 'IcxFactoronModelOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<IcxFactoron>;
};

export type IcxInnoBiz = {
  __typename?: 'IcxInnoBiz';
  icxModelList?: Maybe<Array<IcxInnoBizModel>>;
  count?: Maybe<Scalars['Float']>;
};

export type IcxInnoBizModel = {
  __typename?: 'IcxInnoBizModel';
  id: Scalars['String'];
  no?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  representativeName?: Maybe<Scalars['String']>;
  representativeEmai?: Maybe<Scalars['String']>;
  representativeContact?: Maybe<Scalars['String']>;
  employeesCount?: Maybe<Scalars['String']>;
  relatedIndustry?: Maybe<Scalars['String']>;
  industryCode?: Maybe<Scalars['String']>;
  product?: Maybe<Scalars['String']>;
  companyContact?: Maybe<Scalars['String']>;
};

export type IcxInnoBizModelOutput = {
  __typename?: 'IcxInnoBizModelOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<IcxInnoBiz>;
};

export type IcxMainBiz = {
  __typename?: 'IcxMainBiz';
  icxModelList?: Maybe<Array<IcxMainBizModel>>;
  count?: Maybe<Scalars['Float']>;
};

export type IcxMainBizModel = {
  __typename?: 'IcxMainBizModel';
  id: Scalars['String'];
  no?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  representativeName?: Maybe<Scalars['String']>;
  industryCode?: Maybe<Scalars['String']>;
  industryName?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  certificationExpirationDate?: Maybe<Scalars['String']>;
};

export type IcxMainBizModelOutput = {
  __typename?: 'IcxMainBizModelOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<IcxMainBiz>;
};

export type IcxMiniCluster = {
  __typename?: 'IcxMiniCluster';
  icxModelList?: Maybe<Array<IcxMiniClusterModel>>;
  count?: Maybe<Scalars['Float']>;
};

export type IcxMiniClusterFetchIcxInput = {
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  miniClusterSearchType?: Maybe<MiniClusterSearchType>;
};

export type IcxMiniClusterModel = {
  __typename?: 'IcxMiniClusterModel';
  id?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  base?: Maybe<Scalars['String']>;
  miniClusterName?: Maybe<Scalars['String']>;
  enterpriseType?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  businessRegistrationNumber?: Maybe<Scalars['String']>;
  industryUniversityInstituteCollaboration?: Maybe<Scalars['String']>;
  companyRegistrationDate?: Maybe<Scalars['String']>;
  memberName?: Maybe<Scalars['String']>;
  mailAddress?: Maybe<Scalars['String']>;
  registrationDate?: Maybe<Scalars['String']>;
  contact?: Maybe<Scalars['String']>;
};

export type IcxMiniClusterModelOutput = {
  __typename?: 'IcxMiniClusterModelOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<IcxMiniCluster>;
};

export type IcxModel = {
  __typename?: 'IcxModel';
  icxDetatilModelList?: Maybe<Array<IcxDetatilModel>>;
  icxDetatilModelCount?: Maybe<Scalars['Float']>;
};

export type IcxModelOutput = {
  __typename?: 'IcxModelOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<IcxDetatilModel>;
};

export type IcxModelsOutput = {
  __typename?: 'IcxModelsOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<IcxModel>;
};

/** 시 도 */
export enum IcxRegion {
  Seoul = 'Seoul',
  Busan = 'Busan',
  Incheon = 'Incheon',
  Daegu = 'Daegu',
  GwangJu = 'GwangJu',
  DaeJeon = 'DaeJeon',
  Ulsan = 'Ulsan',
  Gyeonggi = 'Gyeonggi',
  Gangwon = 'Gangwon',
  Chungbuk = 'Chungbuk',
  Chungnam = 'Chungnam',
  Jeonbuk = 'Jeonbuk',
  Jeonnam = 'Jeonnam',
  Geyongnam = 'Geyongnam',
  Geyongbuk = 'Geyongbuk',
  Jeju = 'Jeju',
  /** NULL */
  Null = 'NULL'
}

/** 토큰 갱신 */
export type IcxTokenRefreshInput = {
  /** refresh 토큰 */
  refreshToken: Scalars['String'];
};

export type IcxVentureBiz = {
  __typename?: 'IcxVentureBiz';
  icxModelList?: Maybe<Array<IcxVentureBizModel>>;
  count?: Maybe<Scalars['Float']>;
};

export type IcxVentureBizModel = {
  __typename?: 'IcxVentureBizModel';
  id: Scalars['String'];
  no?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  contact?: Maybe<Scalars['String']>;
  relatedIndustry?: Maybe<Scalars['String']>;
  industryName?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
};

export type IcxVentureBizModelOutput = {
  __typename?: 'IcxVentureBizModelOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<IcxVentureBiz>;
};

/** IndustrialBizSearchType */
export enum IndustrialBizSearchType {
  /** 업체명 */
  BasicCompanyName = 'BASIC_COMPANY_NAME',
  /** 지역 */
  BasicCity = 'BASIC_CITY',
  /** 단지 */
  BasicComplexName = 'BASIC_COMPLEX_NAME',
  /** 제조업 */
  BasicIndustryCategory = 'BASIC_INDUSTRY_CATEGORY',
  /** 업종 */
  BasicIndustrial = 'BASIC_INDUSTRIAL',
  /** 회사 규모 */
  FullinvestigationCompanyScale = 'FULLINVESTIGATION_COMPANY_SCALE',
  /** 직원수 */
  Employees_2020FullTimeTotal = 'EMPLOYEES_2020_FULL_TIME_TOTAL'
}

/** 기업 규모 */
export enum IndustrialCompanyScale {
  MajorCompany = 'MAJOR_COMPANY',
  MidSizedCompany = 'MID_SIZED_COMPANY',
  SmallCompany = 'SMALL_COMPANY',
  /** NULL */
  Null = 'NULL'
}

/** 단지명 */
export enum IndustrialComplexName {
  /** 남동국가산업단지 */
  IndustrialComplex_1 = 'INDUSTRIAL_COMPLEX_1',
  /** 광양국가산업단지 */
  IndustrialComplex_2 = 'INDUSTRIAL_COMPLEX_2',
  /** 광주월전중소협력단지형외국인투자지역 */
  IndustrialComplex_3 = 'INDUSTRIAL_COMPLEX_3',
  /** 광주첨단과학국가산업단지 */
  IndustrialComplex_4 = 'INDUSTRIAL_COMPLEX_4',
  /** 구미국가산업단지 */
  IndustrialComplex_5 = 'INDUSTRIAL_COMPLEX_5',
  /** 구미외국인투자지역 */
  IndustrialComplex_6 = 'INDUSTRIAL_COMPLEX_6',
  /** 국가식품클러스터국가산업단지 */
  IndustrialComplex_7 = 'INDUSTRIAL_COMPLEX_7',
  /** 국가식품클러스터식품산업단지중소협력단지형외국인투자지역 */
  IndustrialComplex_8 = 'INDUSTRIAL_COMPLEX_8',
  /** 군산2국가산업단지 */
  IndustrialComplex_9 = 'INDUSTRIAL_COMPLEX_9',
  /** 군산국가산업단지 */
  IndustrialComplex_10 = 'INDUSTRIAL_COMPLEX_10',
  /** 김해GoldenRoot일반산업단지 */
  IndustrialComplex_11 = 'INDUSTRIAL_COMPLEX_11',
  /** 달성2차외국인투자지역 */
  IndustrialComplex_12 = 'INDUSTRIAL_COMPLEX_12',
  /** 달성2차일반산업단지 */
  IndustrialComplex_13 = 'INDUSTRIAL_COMPLEX_13',
  /** 대구국가산업단지 */
  IndustrialComplex_14 = 'INDUSTRIAL_COMPLEX_14',
  /** 대불국가산업단지 */
  IndustrialComplex_15 = 'INDUSTRIAL_COMPLEX_15',
  /** 대불외국인투자지역 */
  IndustrialComplex_16 = 'INDUSTRIAL_COMPLEX_16',
  /** 명지녹산국가산업단지 */
  IndustrialComplex_17 = 'INDUSTRIAL_COMPLEX_17',
  /** 문막중소협력단지형외국인투자지역 */
  IndustrialComplex_18 = 'INDUSTRIAL_COMPLEX_18',
  /** 반월국가산업단지 */
  IndustrialComplex_19 = 'INDUSTRIAL_COMPLEX_19',
  /** 부산신평장림일반산업단지(협업단지) */
  IndustrialComplex_20 = 'INDUSTRIAL_COMPLEX_20',
  /** 북평국가산업단지 */
  IndustrialComplex_21 = 'INDUSTRIAL_COMPLEX_21',
  /** 빛그린국가산업단지 */
  IndustrialComplex_22 = 'INDUSTRIAL_COMPLEX_22',
  /** 서울디지털국가산업단지 */
  IndustrialComplex_23 = 'INDUSTRIAL_COMPLEX_23',
  /** 시화국가산업단지 */
  IndustrialComplex_24 = 'INDUSTRIAL_COMPLEX_24',
  /** 시화멀티테크노밸리 */
  IndustrialComplex_25 = 'INDUSTRIAL_COMPLEX_25',
  /** 아산국가산업단지(포승지구) */
  IndustrialComplex_26 = 'INDUSTRIAL_COMPLEX_26',
  /** 여수국가산업단지 */
  IndustrialComplex_27 = 'INDUSTRIAL_COMPLEX_27',
  /** 오송생명과학단지 */
  IndustrialComplex_28 = 'INDUSTRIAL_COMPLEX_28',
  /** 온산국가산업단지 */
  IndustrialComplex_29 = 'INDUSTRIAL_COMPLEX_29',
  /** 울산미포국가산업단지 */
  IndustrialComplex_30 = 'INDUSTRIAL_COMPLEX_30',
  /** 익산국가산업단지 */
  IndustrialComplex_31 = 'INDUSTRIAL_COMPLEX_31',
  /** 장성동화농공단지 */
  IndustrialComplex_32 = 'INDUSTRIAL_COMPLEX_32',
  /** 장성삼계농공단지 */
  IndustrialComplex_33 = 'INDUSTRIAL_COMPLEX_33',
  /** 창원국가산업단지 */
  IndustrialComplex_34 = 'INDUSTRIAL_COMPLEX_34',
  /** 청주오송제2생명과학일반산업단지(충북경제자유구역 오송바이오폴리스지구) */
  IndustrialComplex_35 = 'INDUSTRIAL_COMPLEX_35',
  /** 한국수출산업(부평)국가산업단지 */
  IndustrialComplex_36 = 'INDUSTRIAL_COMPLEX_36',
  /** 한국수출산업(주안)국가산업단지 */
  IndustrialComplex_37 = 'INDUSTRIAL_COMPLEX_37',
  /** 석문국가산업단지 */
  IndustrialComplex_38 = 'INDUSTRIAL_COMPLEX_38',
  /** 아산국가산업단지(고대부곡지구) */
  IndustrialComplex_39 = 'INDUSTRIAL_COMPLEX_39',
  /** 충주외국인투자지역 */
  IndustrialComplex_40 = 'INDUSTRIAL_COMPLEX_40',
  /** 파주탄현중소기업전용국가산업단지 */
  IndustrialComplex_41 = 'INDUSTRIAL_COMPLEX_41',
  /** 원주문막반계일반산업단지 */
  IndustrialComplex_42 = 'INDUSTRIAL_COMPLEX_42',
  /** 북평일반산업단지 */
  IndustrialComplex_43 = 'INDUSTRIAL_COMPLEX_43',
  /** 양주홍죽일반산업단지 */
  IndustrialComplex_44 = 'INDUSTRIAL_COMPLEX_44',
  /** 송산2외국인투자지역 */
  IndustrialComplex_45 = 'INDUSTRIAL_COMPLEX_45',
  /** 사천제2일반산업단지 */
  IndustrialComplex_46 = 'INDUSTRIAL_COMPLEX_46',
  /** 송산2-1외국인투자지역 */
  IndustrialComplex_47 = 'INDUSTRIAL_COMPLEX_47',
  /** 사천제1일반산업단지 */
  IndustrialComplex_48 = 'INDUSTRIAL_COMPLEX_48',
  /** 사천외국인투자지역 */
  IndustrialComplex_49 = 'INDUSTRIAL_COMPLEX_49',
  /** 아산제2테크노밸리일반산업단지 */
  IndustrialComplex_50 = 'INDUSTRIAL_COMPLEX_50',
  /** 이천장호원일반산업단지 */
  IndustrialComplex_51 = 'INDUSTRIAL_COMPLEX_51',
  /** 천안외국인투자지역 */
  IndustrialComplex_52 = 'INDUSTRIAL_COMPLEX_52',
  /** 사천임대전용산업단지 */
  IndustrialComplex_53 = 'INDUSTRIAL_COMPLEX_53',
  /** 천안5산단외국인투자지역 */
  IndustrialComplex_54 = 'INDUSTRIAL_COMPLEX_54',
  /** 진해국가산업단지 */
  IndustrialComplex_55 = 'INDUSTRIAL_COMPLEX_55',
  /** 진천산수외국인투자지역 */
  IndustrialComplex_56 = 'INDUSTRIAL_COMPLEX_56',
  /** 이천대월일반산업단지 */
  IndustrialComplex_57 = 'INDUSTRIAL_COMPLEX_57',
  /** 인주외국인투자지역 */
  IndustrialComplex_58 = 'INDUSTRIAL_COMPLEX_58',
  /** 포항국가산업단지 */
  IndustrialComplex_59 = 'INDUSTRIAL_COMPLEX_59',
  /** 장항국가생태산업단지 */
  IndustrialComplex_60 = 'INDUSTRIAL_COMPLEX_60',
  /** 오창외국인투자지역 */
  IndustrialComplex_61 = 'INDUSTRIAL_COMPLEX_61',
  /** 포항블루밸리국가산업단지 */
  IndustrialComplex_62 = 'INDUSTRIAL_COMPLEX_62',
  /** 안정국가산업단지 */
  IndustrialComplex_63 = 'INDUSTRIAL_COMPLEX_63',
  /** 송산2-2외국인투자지역 */
  IndustrialComplex_64 = 'INDUSTRIAL_COMPLEX_64',
  /** NULL */
  Null = 'NULL'
}

/** 업종_대분류 */
export enum IndustrialSectorsCategory {
  Produce = 'PRODUCE',
  NonManufacturing = 'NON_MANUFACTURING',
  /** NULL */
  Null = 'NULL'
}

/** 업종 */
export enum IndustrialSectorsName {
  /** 01. 음식료 */
  SectorName_1 = 'SECTOR_NAME_1',
  /** 02. 섬유의복 */
  SectorName_2 = 'SECTOR_NAME_2',
  /** 03. 목재종이 */
  SectorName_3 = 'SECTOR_NAME_3',
  /** 04. 석유화학 */
  SectorName_4 = 'SECTOR_NAME_4',
  /** 05. 비금속 */
  SectorName_5 = 'SECTOR_NAME_5',
  /** 06. 철강 */
  SectorName_6 = 'SECTOR_NAME_6',
  /** 07. 기계 */
  SectorName_7 = 'SECTOR_NAME_7',
  /** 08. 전기전자 */
  SectorName_8 = 'SECTOR_NAME_8',
  /** 09. 운송장비 */
  SectorName_9 = 'SECTOR_NAME_9',
  /** 10. (제조)기타 */
  SectorName_10 = 'SECTOR_NAME_10',
  /** 11. 정보통신업 */
  SectorName_11 = 'SECTOR_NAME_11',
  /** 12. */
  SectorName_12 = 'SECTOR_NAME_12',
  /** 13. 출판업 */
  SectorName_13 = 'SECTOR_NAME_13',
  /** 14. (비제조)기타 */
  SectorName_14 = 'SECTOR_NAME_14',
  /** NULL */
  Null = 'NULL'
}

export type InnoBizFetchIcxInput = {
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  innoBizSearchType?: Maybe<InnoBizSearchType>;
  employeesCountSortType?: Maybe<CapaSortType>;
};

/** InnoBizSearchType */
export enum InnoBizSearchType {
  /** 업체명 */
  CompanyName = 'COMPANY_NAME',
  /** 주소 */
  Address = 'ADDRESS',
  /** 종업원수 */
  EmployeesCount = 'EMPLOYEES_COUNT',
  /** 업종 */
  RelatedIndustry = 'RELATED_INDUSTRY',
  /** 업종코드 */
  IndustryCode = 'INDUSTRY_CODE',
  /** 주생산품 */
  RepresentativeName = 'REPRESENTATIVE_NAME',
  /** 연락처 */
  CompanyContact = 'COMPANY_CONTACT'
}

export type InsertCommentBoInput = {
  /** quotation user id */
  quotationUserId: Scalars['String'];
  /** back office user id */
  backOfficeId: Scalars['String'];
  /** comment */
  comment: Scalars['String'];
};

export type InsertMemoBoInput = {
  /** user id */
  id: Scalars['String'];
  /** back office user id */
  back_office_id: Scalars['String'];
  /** memo */
  memo: Scalars['String'];
  /** type */
  type: Scalars['String'];
};

export type InviteConnectGroupUserInput = {
  email: Scalars['String'];
  accessLevel: CapaConnectGroupAccessLevel;
};

export type InviteConnectGroupUsersInput = {
  groupId: Scalars['String'];
  inviteUsers: Array<InviteConnectGroupUserInput>;
};

export type InviteFriendsList = {
  __typename?: 'InviteFriendsList';
  /** friends id */
  friendsId?: Maybe<Scalars['ID']>;
  /** 이름 */
  name?: Maybe<Scalars['String']>;
  /** 초대한 캐파 회원 수 */
  totalInviteCnt?: Maybe<Scalars['Float']>;
  /** 휴대폰 번호 */
  cellphoneNumber?: Maybe<Scalars['String']>;
  /** 회사명(소속) */
  organizationName?: Maybe<Scalars['String']>;
  /** 산업군 */
  industry?: Maybe<Scalars['String']>;
  /** 가입일 */
  createDate?: Maybe<Scalars['String']>;
  /** 승인일 */
  approveDate?: Maybe<Scalars['String']>;
  /** 총 리워드 */
  totalReward?: Maybe<Scalars['Float']>;
};

export type InviteUserList = {
  __typename?: 'InviteUserList';
  /** user id */
  userId?: Maybe<Scalars['ID']>;
  /** quotation user id */
  quotationUserId?: Maybe<Scalars['ID']>;
  /** 이름 */
  name?: Maybe<Scalars['String']>;
  /** 휴대폰 번호 */
  cellphoneNumber?: Maybe<Scalars['String']>;
  /** 회사명(소속) */
  organizationName?: Maybe<Scalars['String']>;
  /** 산업군 (파트너만 있는 값, 3.0에서는 따로 저장하고 있지 않아서 무조건 빈 값임) */
  industry?: Maybe<Scalars['String']>;
  /** 가입일 */
  createDate?: Maybe<Scalars['String']>;
  /** 승인일 (파트너만 있는 값) */
  approveDate?: Maybe<Scalars['String']>;
  /** 유입경로 */
  route?: Maybe<Array<CapaInflowRoute>>;
  /** 총 리워드 */
  totalReward?: Maybe<Scalars['Float']>;
};

export type IsUsedPayment = {
  __typename?: 'IsUsedPayment';
  isUsedPayment: Scalars['Boolean'];
};

/** ITP 이벤트 증빙 파일 */
export type ItpEventFileUploadInput = {
  /** fk ITP 이벤트 증빙 파일 아이디 */
  fileId: Scalars['String'];
};

/** JSB 이벤트 증빙 파일 */
export type JsbEventFileUploadInput = {
  /** fk JSB 이벤트 증빙 파일 아이디 */
  fileId: Scalars['String'];
};

export enum Language {
  Korean = 'KOREAN',
  English = 'ENGLISH',
  Chinese = 'CHINESE',
  Vietnam = 'VIETNAM'
}

export type LeaveConnectGroupInput = {
  groupId: Scalars['String'];
};

export type MainBizFetchIcxInput = {
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  mainBizSearchType?: Maybe<MainBizSearchType>;
};

/** MainBizSearchType */
export enum MainBizSearchType {
  /** 사업자명 */
  CompanyName = 'COMPANY_NAME',
  /** 대표자명 */
  RepresentativeName = 'REPRESENTATIVE_NAME',
  /** 업종코드 */
  IndustryCode = 'INDUSTRY_CODE',
  /** 업종명 */
  IndustryName = 'INDUSTRY_NAME',
  /** 지역 */
  Region = 'REGION',
  /** 소재지 */
  Location = 'LOCATION'
}

export type ManageConnectGroupUserInput = {
  groupId: Scalars['String'];
  userEmail: Scalars['String'];
  action: CapaConnectGroupUserAction;
  accessLevel?: Maybe<CapaConnectGroupAccessLevel>;
};

export type ManageDownloadPermissionInput = {
  /** 그룹ID */
  groupId: Scalars['String'];
  groupUserId?: Maybe<Scalars['String']>;
  connectFileId?: Maybe<Scalars['String']>;
  status?: Maybe<CapaQuotationRfqAllowOpenFileStatus>;
};

export type ManageQuotationPartnerAccountBoInput = {
  /** 서브몰 계좌 등록할 고객의 이메일 */
  targetEmail: Scalars['String'];
  /** quotation partner id */
  partnerId: Scalars['String'];
  /** 서브몰 이름 (상호명) */
  submallName: Scalars['String'];
  /** 대표자명 */
  managerName: Scalars['String'];
  /** 통장 사본 fileId */
  bankbookFileId: Scalars['String'];
  /** 정산 은행 코드 */
  bankCode: CapaBankCode;
  /** 정산 계좌번호(`-`제외) */
  accountNumber: Scalars['String'];
  /** 예금주명 */
  accountHolder: Scalars['String'];
};

export type ManageQuotationPartnerAccountInput = {
  /** quotation partner id */
  partnerId: Scalars['String'];
  /** 서브몰 이름 (상호명) */
  submallName: Scalars['String'];
  /** 대표자명 */
  managerName: Scalars['String'];
  /** 통장 사본 fileId */
  bankbookFileId: Scalars['String'];
  /** 정산 은행 코드 */
  bankCode: CapaBankCode;
  /** 정산 계좌번호(`-`제외) */
  accountNumber: Scalars['String'];
  /** 예금주명 */
  accountHolder: Scalars['String'];
};

/** MiniClusterSearchType */
export enum MiniClusterSearchType {
  /** 지역 */
  Region = 'REGION',
  /** 거점 */
  Base = 'BASE',
  /** 미니클러스터 명 */
  MiniClusterName = 'MINI_CLUSTER_NAME',
  /** 기업 유형 */
  EnterPriseType = 'ENTER_PRISE_TYPE',
  /** 기업명 */
  CompanyName = 'COMPANY_NAME',
  /** 산학 연구분 */
  IndustryUniversityInstituteCollaboration = 'INDUSTRY_UNIVERSITY_INSTITUTE_COLLABORATION'
}

export type MoveConnectFileInput = {
  fileId: Scalars['String'];
  destId?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * 캐파 유저 통합 회원 가입
   * @deprecated change name @see {registerUser, RegisterCapaUserInput}
   */
  register: AuthenticationOutput;
  /** 캐파 유저 통합 회원 가입 */
  registerUser: AuthenticationOutput;
  /** capa_user & capa_quotation_user 통합 회원가입 */
  registerIntegratedUser: AuthenticationOutput;
  /** 약관 동의하지 않은 유저를 위한 약관 동의 api */
  updateTerms: Output;
  /** Authorization: Bearer 토큰 갱신 */
  refresh: AuthenticationOutput;
  /** 이메일 인증 요청 */
  requestEmailVerificationCode: Output;
  /** 비밀번호 찾기/재설정 */
  requestResetPassword: Output;
  /** 비밀번호 변경하기 */
  changePassword: Output;
  /** 이메일 수정 */
  changeEmail: Output;
  /** 추천 아이디 코드 발급 */
  requestReferralId: CapaReferralOut;
  /** 핸드폰 인증 요청 */
  requestCellPhoneVerificationCode: Output;
  /** 핸드폰 인증 요청 */
  verifyCellPhoneVerificationCode: Output;
  /** 고객 회원 탈퇴 */
  withdrawQuotationUser: Output;
  /** 파트너 회원 탈퇴 */
  withdrawQuotationPartner: Output;
  /** 엠베서더 주도 신규 회원 가입 페이지 조회수 증가 */
  updateAmbassadorSignUpPageViewCountByIp: Output;
  registerQuotationUserBO: Output;
  updateQuotationUserBO: Output;
  withdrawQuotationUserBO: Output;
  registerQuotationPartnerBO: CapaQuotationPartnerOutput;
  updateQuotationPartnerBO: Output;
  withdrawQuotationPartnerBO: Output;
  insertBOMemo: Output;
  deleteBOMemo: Output;
  approvedQuotationPartnerByEmail: Output;
  updateBOTestUser: Output;
  createNoticeScheduleBO: NoticeScheduleOutput;
  updateNoticeScheduleBO: NoticeScheduleOutput;
  insertCommentBO: Output;
  registerBackOfficeUser: Output;
  updateBackOfficeUser: Output;
  withdrawBackOfficeUser: Output;
  updateRfqStatusBO: Output;
  updateQuotationPartnerStatusBO: Output;
  registerAmbassadorBO: Output;
  updateAmbassadorBO: Output;
  updateFriendsStatus: Output;
  updateFriendsSettlementAccountStatus: Output;
  migration: Output;
  updateSubmallStatusBO: Output;
  updatePurchaseOrderStatusBO: Output;
  createRefundAccountBO: Output;
  manageQuotationPartnerAccountBO: Output;
  updateBOTestConnectGroup: Output;
  createAdvertisementBO: Output;
  createAdsImpressionBO: Output;
  createAdvertisingBO: Output;
  activateAdvertisingBO: Output;
  registerQuotationUser: AuthenticationOutput;
  registerQuotationPartner: AuthenticationOutput;
  suggestService: Output;
  sendQuotationChatMessage: CapaQuotationChatRoomOutput;
  quitQuotationChatRoom: Output;
  readQuotationChatMessage: Output;
  subscribeCAPANews: Output;
  updateBypassChatComplaint: Output;
  deleteQuotationChatMessage: Output;
  createQuotationAnnotation: CapaQuotationAnnotationOutput;
  createQuotationAnnotationComment: CapaQuotationAnnotationOutput;
  removeQuotationAnnotation: Output;
  removeQuotationAnnotationComment: Output;
  updateQuotationAnnotation: Output;
  readQuotationAnnotationComment: Output;
  updateQuotationAnnotationComment: Output;
  updatePurchaseOrderStatus: Output;
  sendSystemChatMessage: Output;
  reopenRfqTransaction: Output;
  updateQuotationPartner: UpdateCapaQuotationPartnerStrategyOutput;
  createQuotationChatRoom: CapaQuotationChatRoomOutput;
  rateQuotationServicePartner: Output;
  batchShutDownQuotationPartnerChatRooms: Output;
  saveCapabilities: Output;
  saveRfqFilter: Output;
  rejectRfqPurchaseOrder: Output;
  manageQuotationPartnerAccount: Output;
  updateQuotationUser: CapaQuotationUserOutput;
  rateQuotationServiceUser: Output;
  changeChatRoomStatusOpen: Output;
  changeQuotationMessageInvisible: Output;
  delayConfirmEscrowPurchase: Output;
  createMyRefundAccountByQuotationUser: Output;
  deleteMyRefundAccountByQuotationUser: Output;
  updateIdentityVerification: Output;
  /** 캐파 클라우드 회원가입 */
  registerCloudUser: AuthenticationOutput;
  /**
   * 캐파 클라우드 파일 삭제
   * rest api 와 동일 기능
   * [Delete <URL>/cloud/:fileId]
   */
  removeCloudFile: Output;
  registerConnectUser: AuthenticationOutput;
  updateConnectUser: CapaConnectUserOutput;
  removeConnectUser: Output;
  createConnectFolder: Output;
  removeConnectFile: Output;
  renameConnectFile: Output;
  requestDownloadPermission: Output;
  manageDownloadPermission: Output;
  moveConnectFile: Output;
  addConnectFiles: Output;
  createGroup: CreateConnectGroupOutput;
  createGroupWithCapa: CreateConnectGroupOutput;
  inviteGroupMembers: Output;
  manageGroupMember: Output;
  removeGroup: Output;
  renameGroupName: Output;
  exitGroup: Output;
  shareFilesToGroup: Output;
  unshareFileFromGroup: Output;
  checkDuplicateGroupName: Output;
  leaveGroup: Output;
  createConnectAnnotation: CapaConnectAnnotationOutput;
  createConnectAnnotationComment: CapaConnectAnnotationOutput;
  updateConnectAnnotation: CapaConnectAnnotationOutput;
  updateConnectAnnotationComment: CapaConnectAnnotationOutput;
  removeConnectAnnotation: Output;
  removeConnectAnnotationComment: CapaConnectAnnotationOutput;
  sendAnnotationCommentToChat: Output;
  readConnectAnnotationComment: Output;
  sendConnectChatMessage: Output;
  sendConnectChatMessageToCapa: Output;
  readConnectChatMessage: CapaChatMessagesReadOutput;
  createConnectContactUser: Output;
  updateConnectContactUser: Output;
  removeConnectContactUser: Output;
  updateConnectUserFilePermission: Output;
  updateConnectUserFileLinkStatus: UpdateConnectUserFileLinkOutput;
  addConnectLinkFiles: Output;
  createConnectFeedback: Output;
  createConnectIssue: Output;
  registerItpEvent: Output;
  approveItpEvent: Output;
  registerJsbEvent: Output;
  registerRFQ: Output;
  requestAdjustRfqQuotation: Output;
  confirmRfqQuotation: Output;
  confirmRfqTransaction: Output;
  completeRfqTransaction: Output;
  readRfqQuotation: Output;
  createPurchaseOrder: CapaQuotationCreatePurchaseOrderOutput;
  expireRfq: Output;
  evaluateRfqPartners: Output;
  allowRfqDrawingFileDownload: Output;
  reviewRfq: Output;
  readAllManagement: Output;
  readManagement: Output;
  registerRequestRfqQuotation: Output;
  registerRfqReview: Output;
  updateRfqReview: Output;
  requestRegisterTransactionReview: Output;
  createPaymentAfterCreatePurchaseOrder: Output;
  expireRfqTest: Output;
  registerRequestRfqPurchaseOrder: Output;
  requestAdjustRfqPurchaseOrder: Output;
  confirmRfqPurchaseOrder: Output;
  requestAdjustRfqPurchaseOrderWithItem: Output;
  confirmRfqPurchaseOrderWithItem: Output;
  requestConfirmRfqTransaction: Output;
  requestCompleteRfqTransaction: Output;
  readRfqPurchaseOrder: Output;
  createSimpleQuotation: CapaQuotationChatRoomOutput;
  createDetailedQuotation: CapaQuotationChatRoomOutput;
  requestRfqDrawingFileDownload: Output;
  toggleFavorite: Output;
  replyRfqReview: Output;
  sendQuotationPartnerIntroduction: Output;
  readRfqAdjustRequest: Output;
  /** 사용자 활동 로그 입력 완료 */
  writeUserActivityLog: Output;
  /** 광고 활동 로그 입력 */
  writeAdsActivityLog: Output;
  readNotification: Output;
  clearNewNotification: Output;
  /** Authorization: Bearer 토큰 갱신 */
  icxRefreshAccessToken: IcxAuthenticationOutput;
};

export type MutationRegisterArgs = {
  input: RegistrationInput;
};

export type MutationRegisterUserArgs = {
  input: RegisterCapaUserInput;
};

export type MutationRegisterIntegratedUserArgs = {
  input: RegisterIntegratedUserInput;
};

export type MutationUpdateTermsArgs = {
  input: UpdateCapaUserTermsInput;
};

export type MutationRefreshArgs = {
  input: TokenRefreshInput;
};

export type MutationRequestEmailVerificationCodeArgs = {
  input: RequestEmailVerificationCodeInput;
};

export type MutationRequestResetPasswordArgs = {
  input: RequestResetPasswordInput;
};

export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};

export type MutationChangeEmailArgs = {
  input: ChangeEmailInput;
};

export type MutationRequestReferralIdArgs = {
  input: RequestReferralInput;
};

export type MutationRequestCellPhoneVerificationCodeArgs = {
  input: RequestCellPhoneVerificationCodeInput;
};

export type MutationVerifyCellPhoneVerificationCodeArgs = {
  input: VerifyCellPhoneVerificationCodeInput;
};

export type MutationWithdrawQuotationUserArgs = {
  input: WithdrawQuotationUserInput;
};

export type MutationWithdrawQuotationPartnerArgs = {
  input: WithdrawQuotationPartnerInput;
};

export type MutationUpdateAmbassadorSignUpPageViewCountByIpArgs = {
  input: UpdateAmbassadorSignUpPageViewCountByIpInput;
};

export type MutationRegisterQuotationUserBoArgs = {
  input: UpdateQuotationUserBoInput;
};

export type MutationUpdateQuotationUserBoArgs = {
  input: UpdateQuotationUserBoInput;
};

export type MutationWithdrawQuotationUserBoArgs = {
  input: WithdrawQuotationUserBoInput;
};

export type MutationRegisterQuotationPartnerBoArgs = {
  input: RegisterQuotationPartnerBoInput;
};

export type MutationUpdateQuotationPartnerBoArgs = {
  input: UpdateQuotationPartnerBoStrategyInput;
};

export type MutationWithdrawQuotationPartnerBoArgs = {
  input: WithdrawQuotationPartnerBoInput;
};

export type MutationInsertBoMemoArgs = {
  input: InsertMemoBoInput;
};

export type MutationDeleteBoMemoArgs = {
  input: InsertMemoBoInput;
};

export type MutationApprovedQuotationPartnerByEmailArgs = {
  input: ApprovedQuotationPartnerByEmailInput;
};

export type MutationUpdateBoTestUserArgs = {
  input: UpdateBoTestUserInput;
};

export type MutationCreateNoticeScheduleBoArgs = {
  input: CreateNoticeScheduleInput;
};

export type MutationUpdateNoticeScheduleBoArgs = {
  input: UpdateNoticeScheduleInput;
};

export type MutationInsertCommentBoArgs = {
  input: InsertCommentBoInput;
};

export type MutationRegisterBackOfficeUserArgs = {
  input: RegisterBackOfficeUserInput;
};

export type MutationUpdateBackOfficeUserArgs = {
  input: UpdateBackOfficeUserInput;
};

export type MutationWithdrawBackOfficeUserArgs = {
  input: WithdrawBackOfficeUserInput;
};

export type MutationUpdateRfqStatusBoArgs = {
  input: UpdateRfqStatusInput;
};

export type MutationUpdateQuotationPartnerStatusBoArgs = {
  input: UpdateQuotationPartnerStatusInput;
};

export type MutationRegisterAmbassadorBoArgs = {
  input: RegisterAmbassadorBoInput;
};

export type MutationUpdateAmbassadorBoArgs = {
  input: UpdateAmbassadorBoInput;
};

export type MutationUpdateFriendsStatusArgs = {
  input: UpdateFriendsStatusInput;
};

export type MutationUpdateFriendsSettlementAccountStatusArgs = {
  input: UpdateFriendsSettlementAccountStatusInput;
};

export type MutationUpdateSubmallStatusBoArgs = {
  input: UpdateSubmallStatusBoInput;
};

export type MutationUpdatePurchaseOrderStatusBoArgs = {
  input: UpdatePurchaseOrderStatusBoInput;
};

export type MutationCreateRefundAccountBoArgs = {
  input: CreateMyRefundAccountBoInput;
};

export type MutationManageQuotationPartnerAccountBoArgs = {
  input: ManageQuotationPartnerAccountBoInput;
};

export type MutationUpdateBoTestConnectGroupArgs = {
  input: UpdateBoTestConnectGroupInput;
};

export type MutationCreateAdvertisementBoArgs = {
  input: CreateAdvertisementBoInput;
};

export type MutationCreateAdsImpressionBoArgs = {
  input: CreateAdsImpressionBoInput;
};

export type MutationCreateAdvertisingBoArgs = {
  input: CreateAdvertisingBoInput;
};

export type MutationActivateAdvertisingBoArgs = {
  input: ActivateAdvertisinBoInput;
};

export type MutationRegisterQuotationUserArgs = {
  input: RegisterQuotationUserInput;
};

export type MutationRegisterQuotationPartnerArgs = {
  input: RegisterQuotationPartnerInput;
};

export type MutationSuggestServiceArgs = {
  input: SuggestServiceInput;
};

export type MutationSendQuotationChatMessageArgs = {
  input: SendQuotationChatMessageInput;
};

export type MutationQuitQuotationChatRoomArgs = {
  input: QuitQuotationChatRoomInput;
};

export type MutationReadQuotationChatMessageArgs = {
  input: ReadQuotationChatRoomMessageInput;
};

export type MutationSubscribeCapaNewsArgs = {
  input: SubscribeCapaNewsInput;
};

export type MutationUpdateBypassChatComplaintArgs = {
  input: UpdateReceiveComplaintQuotationChatMessageInput;
};

export type MutationDeleteQuotationChatMessageArgs = {
  input: DeleteQuotationChatMessageInput;
};

export type MutationCreateQuotationAnnotationArgs = {
  input: CreateQuotationAnnotationInput;
};

export type MutationCreateQuotationAnnotationCommentArgs = {
  input: CreateQuotationAnnotationCommentInput;
};

export type MutationRemoveQuotationAnnotationArgs = {
  input: RemoveQuotationAnnotationInput;
};

export type MutationRemoveQuotationAnnotationCommentArgs = {
  input: RemoveQuotationAnnotationCommentInput;
};

export type MutationUpdateQuotationAnnotationArgs = {
  input: UpdateQuotationAnnotationInput;
};

export type MutationReadQuotationAnnotationCommentArgs = {
  input: ReadQuotationAnnotationCommentInput;
};

export type MutationUpdateQuotationAnnotationCommentArgs = {
  input: UpdateQuotationAnnotationCommentInput;
};

export type MutationUpdatePurchaseOrderStatusArgs = {
  input: UpdatePurchaseOrderStatusInput;
};

export type MutationSendSystemChatMessageArgs = {
  input: SendSystemChatMessageInput;
};

export type MutationReopenRfqTransactionArgs = {
  input: UpdateReopenRfqTransactionInput;
};

export type MutationUpdateQuotationPartnerArgs = {
  input: UpdateQuotationPartnerStrategyInput;
};

export type MutationCreateQuotationChatRoomArgs = {
  input: CreateQuotationChatRoomInput;
};

export type MutationRateQuotationServicePartnerArgs = {
  input: QuotationServiceRatingInput;
};

export type MutationBatchShutDownQuotationPartnerChatRoomsArgs = {
  input: BatchShutDownQuotationPartnerChatRoomsInput;
};

export type MutationSaveCapabilitiesArgs = {
  input: SaveRfqCapabilitiesInput;
};

export type MutationSaveRfqFilterArgs = {
  input: SaveRfqFilterInput;
};

export type MutationRejectRfqPurchaseOrderArgs = {
  input: RejectRfqPurchaseOrderInput;
};

export type MutationManageQuotationPartnerAccountArgs = {
  input: ManageQuotationPartnerAccountInput;
};

export type MutationUpdateQuotationUserArgs = {
  input: UpdateQuotationUserInput;
};

export type MutationRateQuotationServiceUserArgs = {
  input: QuotationServiceRatingInput;
};

export type MutationChangeChatRoomStatusOpenArgs = {
  input: ChangeChatRoomStatusInput;
};

export type MutationChangeQuotationMessageInvisibleArgs = {
  input: ChangeChatMessageInvisibleInput;
};

export type MutationDelayConfirmEscrowPurchaseArgs = {
  input: DelayConfirmEscrowPurchaseInput;
};

export type MutationCreateMyRefundAccountByQuotationUserArgs = {
  input: CreateMyRefundAccountInput;
};

export type MutationDeleteMyRefundAccountByQuotationUserArgs = {
  input: DeleteMyRefundAccountInput;
};

export type MutationUpdateIdentityVerificationArgs = {
  input: UpdateIdentityVerificationInput;
};

export type MutationRemoveCloudFileArgs = {
  input: RequestFileInput;
};

export type MutationRegisterConnectUserArgs = {
  input: RegisterConnectUserInput;
};

export type MutationUpdateConnectUserArgs = {
  input: UpdateConnectUserInput;
};

export type MutationRemoveConnectUserArgs = {
  input: RemoveConnectUserInput;
};

export type MutationCreateConnectFolderArgs = {
  input: RegisterConnectUserFolderInput;
};

export type MutationRemoveConnectFileArgs = {
  input: RemoveConnectFileInput;
};

export type MutationRenameConnectFileArgs = {
  input: RenameConnectFileInput;
};

export type MutationRequestDownloadPermissionArgs = {
  input: RequestDownloadPermissionInput;
};

export type MutationManageDownloadPermissionArgs = {
  input: ManageDownloadPermissionInput;
};

export type MutationMoveConnectFileArgs = {
  input: MoveConnectFileInput;
};

export type MutationAddConnectFilesArgs = {
  input: AddConnectFilesInput;
};

export type MutationCreateGroupArgs = {
  input: CreateConnectGroupInput;
};

export type MutationInviteGroupMembersArgs = {
  input: InviteConnectGroupUsersInput;
};

export type MutationManageGroupMemberArgs = {
  input: ManageConnectGroupUserInput;
};

export type MutationRemoveGroupArgs = {
  input: RemoveConnectGroupInput;
};

export type MutationRenameGroupNameArgs = {
  input: RenameConnectGroupInput;
};

export type MutationExitGroupArgs = {
  input: FetchConnectGroupInput;
};

export type MutationShareFilesToGroupArgs = {
  input: ShareConnectFilesInput;
};

export type MutationUnshareFileFromGroupArgs = {
  input: UnshareConnectFilesInput;
};

export type MutationCheckDuplicateGroupNameArgs = {
  input: CheckConnectDuplicateGroupNameInput;
};

export type MutationLeaveGroupArgs = {
  input: LeaveConnectGroupInput;
};

export type MutationCreateConnectAnnotationArgs = {
  input: CreateConnectAnnotationInput;
};

export type MutationCreateConnectAnnotationCommentArgs = {
  input: CreateConnectAnnotationCommentInput;
};

export type MutationUpdateConnectAnnotationArgs = {
  input: UpdateConnectAnnotationInput;
};

export type MutationUpdateConnectAnnotationCommentArgs = {
  input: UpdateConnectAnnotationCommentInput;
};

export type MutationRemoveConnectAnnotationArgs = {
  input: RemoveConnectAnnotationInput;
};

export type MutationRemoveConnectAnnotationCommentArgs = {
  input: RemoveConnectAnnotationCommentInput;
};

export type MutationSendAnnotationCommentToChatArgs = {
  input: SendAnnotationCommentToChatInput;
};

export type MutationReadConnectAnnotationCommentArgs = {
  input: ReadConnectAnnotationCommentInput;
};

export type MutationSendConnectChatMessageArgs = {
  input: SendConnectChatMessageInput;
};

export type MutationSendConnectChatMessageToCapaArgs = {
  input: SendConnectChatMessageToCapaInput;
};

export type MutationReadConnectChatMessageArgs = {
  input: ReadConnectChatRoomMessageInput;
};

export type MutationCreateConnectContactUserArgs = {
  input: CreateConnectContactUserInput;
};

export type MutationUpdateConnectContactUserArgs = {
  input: CreateConnectContactUserInput;
};

export type MutationRemoveConnectContactUserArgs = {
  input: RemoveConnectContactUserInput;
};

export type MutationUpdateConnectUserFilePermissionArgs = {
  input: UpdateConnectUserFilePermissionInput;
};

export type MutationUpdateConnectUserFileLinkStatusArgs = {
  input: UpdateConnectUserFileLinkInput;
};

export type MutationAddConnectLinkFilesArgs = {
  input: AddConnectFilesInput;
};

export type MutationCreateConnectFeedbackArgs = {
  input: CreateConnectFeedbackInput;
};

export type MutationCreateConnectIssueArgs = {
  input: CreateConnectIssueInput;
};

export type MutationRegisterItpEventArgs = {
  input: RegisterItpEventInput;
};

export type MutationApproveItpEventArgs = {
  input: ApproveItpEventInput;
};

export type MutationRegisterJsbEventArgs = {
  input: RegisterJsbEventInput;
};

export type MutationRegisterRfqArgs = {
  input: RegisterRfqInput;
};

export type MutationRequestAdjustRfqQuotationArgs = {
  input: RequestAdjustRfqQuotationInput;
};

export type MutationConfirmRfqQuotationArgs = {
  input: ConfirmRfqQuotationInput;
};

export type MutationConfirmRfqTransactionArgs = {
  input: ConfirmRfqTransactionInput;
};

export type MutationCompleteRfqTransactionArgs = {
  input: CompleteRfqTransactionInput;
};

export type MutationReadRfqQuotationArgs = {
  input: ReadRfqQuotationInput;
};

export type MutationCreatePurchaseOrderArgs = {
  input: CreatePurchaseOrderInput;
};

export type MutationExpireRfqArgs = {
  input: ExpireRfqInput;
};

export type MutationEvaluateRfqPartnersArgs = {
  input: EvaluateRfqPartnersInput;
};

export type MutationAllowRfqDrawingFileDownloadArgs = {
  input: AllowRfqDrawingFileDownloadInput;
};

export type MutationReviewRfqArgs = {
  input: ReviewRfqInput;
};

export type MutationReadAllManagementArgs = {
  input: ReadAllManagementInput;
};

export type MutationReadManagementArgs = {
  input: ReadManagementInput;
};

export type MutationRegisterRequestRfqQuotationArgs = {
  input: RegisterRequestRfqQuotationInput;
};

export type MutationRegisterRfqReviewArgs = {
  input: RegisterRfqReviewInput;
};

export type MutationUpdateRfqReviewArgs = {
  input: UpdateRfqReviewInput;
};

export type MutationRequestRegisterTransactionReviewArgs = {
  input: RequestRegisterTransactionReviewInput;
};

export type MutationCreatePaymentAfterCreatePurchaseOrderArgs = {
  input: CreatePaymentInput;
};

export type MutationExpireRfqTestArgs = {
  input: ExpireRfqInput;
};

export type MutationRegisterRequestRfqPurchaseOrderArgs = {
  input: RegisterRequestRfqPurchaseOrderInput;
};

export type MutationRequestAdjustRfqPurchaseOrderArgs = {
  input: RequestAdjustRfqPurchaseOrderInput;
};

export type MutationConfirmRfqPurchaseOrderArgs = {
  input: ConfirmRfqPurchaseOrderInput;
};

export type MutationRequestAdjustRfqPurchaseOrderWithItemArgs = {
  input: RequestAdjustRfqPurchaseOrderInput;
};

export type MutationConfirmRfqPurchaseOrderWithItemArgs = {
  input: ConfirmRfqPurchaseOrderInput;
};

export type MutationRequestConfirmRfqTransactionArgs = {
  input: RequestConfirmRfqTransactionInput;
};

export type MutationRequestCompleteRfqTransactionArgs = {
  input: RequestConfirmRfqTransactionInput;
};

export type MutationReadRfqPurchaseOrderArgs = {
  input: ReadRfqPurchaseOrderInput;
};

export type MutationCreateSimpleQuotationArgs = {
  input: CreateSimpleQuotationInput;
};

export type MutationCreateDetailedQuotationArgs = {
  input: CreateDetailedQuotationInput;
};

export type MutationRequestRfqDrawingFileDownloadArgs = {
  input: RequestRfqDrawingFileDownloadInput;
};

export type MutationToggleFavoriteArgs = {
  input: FavoriteRfqInput;
};

export type MutationReplyRfqReviewArgs = {
  input: ReplyRfqReviewInput;
};

export type MutationSendQuotationPartnerIntroductionArgs = {
  input: SendQuotationPartnerIntroductionInput;
};

export type MutationReadRfqAdjustRequestArgs = {
  input: ReadRfqAdjustRequestInput;
};

export type MutationWriteUserActivityLogArgs = {
  input: UserActivityLogInput;
};

export type MutationWriteAdsActivityLogArgs = {
  input: AdsActivityLogInput;
};

export type MutationReadNotificationArgs = {
  input: ReadNotificationInput;
};

export type MutationClearNewNotificationArgs = {
  input: ReadNotificationInput;
};

export type MutationIcxRefreshAccessTokenArgs = {
  input: IcxTokenRefreshInput;
};

/** NiceDnbAccssToken */
export type NiceDnbAccssToken = {
  __typename?: 'NiceDnbAccssToken';
  /** JWT */
  accessToken: Scalars['String'];
  tokenType: Scalars['String'];
  /** expiresIn */
  expiresIn: Scalars['Float'];
  /** scope */
  scope: Scalars['String'];
};

/** NiceDnbCompanyInformation */
export type NiceDnbCompanyInformation = {
  __typename?: 'NiceDnbCompanyInformation';
  /** 기업 이름 */
  cmpNm?: Maybe<Scalars['String']>;
  /** 기업 규모 */
  cmpSclNm?: Maybe<Scalars['String']>;
  /** 비즈니스 타입 */
  bzTypNm?: Maybe<Scalars['String']>;
  /** 비즈니스 컨텐츠 이름 */
  bzcndNm?: Maybe<Scalars['String']>;
  /** 대표자 이름 */
  ceoNm?: Maybe<Scalars['String']>;
  /** 설립 일자 */
  estbDate?: Maybe<Scalars['String']>;
  /** homepageUrl */
  homepageUrl?: Maybe<Scalars['String']>;
  /** 직원수 */
  empCnt?: Maybe<Scalars['String']>;
  /** 주소 */
  adr?: Maybe<Scalars['String']>;
  /** 상세 주소 */
  dtlAdr?: Maybe<Scalars['String']>;
  /** 우편 번호 */
  zip?: Maybe<Scalars['String']>;
  /** 사업자 번호 */
  bizNo?: Maybe<Scalars['String']>;
  /** 폐업일 */
  statOcrDate?: Maybe<Scalars['String']>;
  /** 휴폐업구분 */
  clsbzNm?: Maybe<Scalars['String']>;
};

/** NiceDnbCompanyInformationInput */
export type NiceDnbCompanyInformationInput = {
  /** 기업 이름 */
  cmpNm?: Maybe<Scalars['String']>;
  /** 기업 규모 */
  cmpSclNm?: Maybe<Scalars['String']>;
  /** 비즈니스 타입 */
  bzTypNm?: Maybe<Scalars['String']>;
  /** 비즈니스 컨텐츠 이름 */
  bzcndNm?: Maybe<Scalars['String']>;
  /** 대표자 이름 */
  ceoNm?: Maybe<Scalars['String']>;
  /** 설립 일자 */
  estbDate?: Maybe<Scalars['String']>;
  /** homepageUrl */
  homepageUrl?: Maybe<Scalars['String']>;
  /** 직원수 */
  empCnt?: Maybe<Scalars['String']>;
  /** 주소 */
  adr?: Maybe<Scalars['String']>;
  /** 상세 주소 */
  dtlAdr?: Maybe<Scalars['String']>;
  /** 우편 번호 */
  zip?: Maybe<Scalars['String']>;
  /** 사업자등록번호 */
  bizNo?: Maybe<Scalars['String']>;
  /** 폐업일 */
  statOcrDate?: Maybe<Scalars['String']>;
  /** 휴폐업구분 */
  clsbzNm?: Maybe<Scalars['String']>;
};

/** NiceDnbCompanyOverView */
export type NiceDnbCompanyOverView = {
  __typename?: 'NiceDnbCompanyOverView';
  /** 기업 이름 */
  cmpNm: Scalars['String'];
  /** 기업 규모 */
  cmpSclNm: Scalars['String'];
  /** 비즈니스 타입 */
  bzTypNm: Scalars['String'];
  /** 비즈니스 컨텐츠 이름 */
  bzcndNm: Scalars['String'];
  /** 대표자 이름 */
  ceoNm: Scalars['String'];
  /** 설립 일자 */
  estbDate: Scalars['String'];
  /** homepageUrl */
  homepageUrl: Scalars['String'];
  /** 직원수 */
  empCnt: Scalars['String'];
  /** 주소 */
  adr: Scalars['String'];
  /** 상세 주소 */
  dtlAdr: Scalars['String'];
  /** 우편 번호 */
  zip: Scalars['String'];
  /** 사업자 번호 */
  bizNo: Scalars['String'];
};

/** NiceDnbIsClosedBusiness */
export type NiceDnbIsClosedBusiness = {
  __typename?: 'NiceDnbIsClosedBusiness';
  /** 폐업일 */
  statOcrDate: Scalars['String'];
  /** 휴폐업구분 */
  clsbzNm: Scalars['String'];
};

export type NoticeSchedule = {
  __typename?: 'NoticeSchedule';
  id: Scalars['String'];
  /** 점검 시작 시간 */
  startTime: Scalars['String'];
  /** 점검 종료 시간 */
  endTime: Scalars['String'];
  /** 사전점검 시작 시간 */
  previewStartTime?: Maybe<Scalars['String']>;
  /** 사전점검 종료 시간 */
  previewEndTime?: Maybe<Scalars['String']>;
  /** client partner connect 인지 */
  pageType: NoticeScheduleNoticePageType;
  /** 긴급점검 / 사전점검 */
  noticeType: NoticeScheduleNoticeType;
  /** 시작예정 / 시작중 / 종료 */
  status: NoticeScheduleNoticeStatus;
};

export enum NoticeScheduleNoticePageType {
  Capa = 'CAPA',
  Connect = 'CONNECT'
}

export enum NoticeScheduleNoticeStatus {
  NotYetStart = 'NOT_YET_START',
  Starting = 'STARTING',
  End = 'END'
}

export enum NoticeScheduleNoticeType {
  EmergencyNotice = 'EMERGENCY_NOTICE',
  PreviewNotice = 'PREVIEW_NOTICE'
}

export type NoticeScheduleOutput = {
  __typename?: 'NoticeScheduleOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<NoticeSchedule>;
};

/** Default Output */
export type Output = {
  __typename?: 'Output';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
};

export type PartnerActivity = {
  __typename?: 'PartnerActivity';
  /** quotation 수 */
  quotationCnt: Scalars['Float'];
  /** chat 수 */
  chatCnt: Scalars['Float'];
  /** purchase order 수 */
  purchaseOrderCnt: Scalars['Float'];
  /** 정산 완료 금액 */
  totalAmount: Scalars['Float'];
};

export type PartnerCapabilityOutput = {
  __typename?: 'PartnerCapabilityOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaQuotationPartnerCapability>;
};

export type PaymentData = {
  __typename?: 'PaymentData';
  amount: Scalars['Float'];
  impUid?: Maybe<Scalars['String']>;
  pgProvider: Scalars['String'];
  merchantId?: Maybe<Scalars['String']>;
  transactionId: Scalars['String'];
  paymentMethod: CapaPaymentMethodType;
  virtualAccount?: Maybe<VirtualAccountInfo>;
  cardInfo?: Maybe<CapaPaymentCardInfo>;
  payoutSeq?: Maybe<Scalars['Float']>;
  status: CapaPaymentStatus;
  paidAt?: Maybe<Scalars['String']>;
};

/** PaymentHistorySearchDateTypeBO */
export enum PaymentHistorySearchDateTypeBo {
  /** 전체 */
  All = 'ALL',
  /** 일별 */
  Day = 'DAY',
  /** 주별 */
  Week = 'WEEK',
  /** 월별 */
  Month = 'MONTH',
  /** 연도별 */
  Year = 'YEAR'
}

/** PaymentHistorySearchStatusTypeBO */
export enum PaymentHistorySearchStatusTypeBo {
  /** 전체 */
  All = 'ALL',
  /** 취소 확정 */
  CancelConfirmed = 'CANCEL_CONFIRMED',
  /** 환불 확정 */
  RefundConfirmed = 'REFUND_CONFIRMED',
  /** 발주서 거절 */
  RejectOrderByPartner = 'REJECT_ORDER_BY_PARTNER',
  /** 구매 확정 */
  PurchaseConfirmed = 'PURCHASE_CONFIRMED'
}

/** PaymentHistorySearchTypeBO */
export enum PaymentHistorySearchTypeBo {
  /** 고객명 */
  QuotationUserName = 'QUOTATION_USER_NAME',
  /** 프로젝트명 */
  ProjectName = 'PROJECT_NAME',
  /** 파트너명 */
  QuotationPartnerName = 'QUOTATION_PARTNER_NAME',
  /** 사업자번호 */
  RegistrationNumber = 'REGISTRATION_NUMBER'
}

export type PcbAssemblyMethodInput = {
  methodType: CapaRfqPcbAssemblyMethodType;
};

export type PcbServiceInput = {
  serviceType: CapaPcbServiceType;
};

export enum ProjectPurchaseOrderManageStatusType {
  /** 전체 목록 표출 */
  All = 'ALL',
  /** 결제_발주중 */
  OrderInprogress = 'ORDER_INPROGRESS',
  /** 결제_발주중단 */
  OrderSuspended = 'ORDER_SUSPENDED',
  /** 제조중 */
  Processing = 'PROCESSING',
  /** 제조중단 */
  ProcessInterruption = 'PROCESS_INTERRUPTION',
  /** 배송중 */
  Shipping = 'SHIPPING',
  /** 거래완료 */
  ConfirmPurchaseOrder = 'CONFIRM_PURCHASE_ORDER'
}

export enum ProjectStatusType {
  /** 전체 목록 표출 */
  All = 'ALL',
  /** 견적비교중 */
  QuotationComparison = 'QUOTATION_COMPARISON',
  /** 견적비교마감 */
  QuotationComparisonClosing = 'QUOTATION_COMPARISON_CLOSING',
  /** 결제_발주중 */
  OrderInprogress = 'ORDER_INPROGRESS',
  /** 결제_발주중단 */
  OrderSuspended = 'ORDER_SUSPENDED',
  /** 제조중 */
  Processing = 'PROCESSING',
  /** 제조중단 */
  ProcessInterruption = 'PROCESS_INTERRUPTION',
  /** 배송중 */
  Shipping = 'SHIPPING',
  /** 거래완료 */
  ConfirmPurchaseOrder = 'CONFIRM_PURCHASE_ORDER',
  /** 타업체발주  */
  OtherCompanyOrder = 'OTHER_COMPANY_ORDER'
}

export type Query = {
  __typename?: 'Query';
  /** 캐파 유저 통합 로그인 */
  authenticate: AuthenticationOutput;
  /** 캐파서비스 확인 checkRegisteredCapaService */
  checkRegisteredCapaService: RegisteredCapaServicesOutput;
  /** 이메일 인증 확인 */
  verifyEmailVerificationCode: Output;
  /** 디바이스 토큰 삭제 */
  removeDeviceToken: Output;
  authenticateBO: AuthenticationOutput;
  fetchMyBackOfficeUser: CapaBackOfficeUserOutput;
  fetchItpEventsBO: CapaItpEventsBoOutput;
  fetchJsbEventsBO: CapaJsbEventsBoOutput;
  fetchCloudUsersBO: CapaCloudUsersBoOutput;
  fetchBoMemo: CapaBoMemosOutput;
  fetchPartnersBO: CapaPartnersBoOutput;
  fetchUsersBO: CapaUsersBoOutput;
  fetchRfqsBO: CapaRfqsBoOutput;
  fetchRfqDetailBO: CapaRfqDetailBoOutput;
  fetchQuotationUserRfqsBO: CapaQuotationUserRfqsBoOutput;
  fetchQuotationChatMessagesBO: CapaQuotationChatRoomOutput;
  fetchQuotationPartnerProjectParticipationStatusBO: CapaQuotationPartnersProjectParticipationStatusBoOutput;
  fetchQuotationUserBO: CapaQuotationUserBoOutput;
  fetchQuotationPartnerBO: CapaQuotationPartnerStrategyBoOutput;
  fetchRfqQuotationsBO: CapaQuotationDetailedQuotationsOutput;
  fetchPurchaseOrdesBO: CapaQuotationRfqPurchaseOrdersOutput;
  fetchServiceSuggestionBO: CapaServiceSuggestionBoOutput;
  fetchServiceRatingBO: CapaServiceRatingBoOutput;
  fetchCXManagementRequiredUsersBO: CapacxManagementRequiredUsersBoOutput;
  fetchBOTestUsers: CapaUsersOutput;
  getNoticeScheduleBO: NoticeScheduleOutput;
  fetchCommentBO: CapaBoCommentsOutput;
  fetchBypassComplaintBO: CapaReceiveComplaintQuotationChatMessageOutput;
  fetchQuotationChatRoomSpecificAnnotationsBO: CapaQuotationChatRoomSpecificAnnotationsOutput;
  fetchRfqReviewBO: CapaRfqReviewsBoOutput;
  fetchBOUsers: CapaBackOfficeUsersOutput;
  fetchBOAmbassadors: CapaBoAmbassadorsOutput;
  fetchBOAmbassadorDetail: CapaBoAmbassadorDetailOutput;
  fetchBOAmbassadorInvitationStatus: CapaBoAmbassadorInvitationStatusOutput;
  fetchBOFriendsInviteUserActivity: CapaBoFriendsInviteUserActivityOutput;
  fetchProcessingBO: CapaboProcessingOutput;
  fetchTransactionHistoryBO: CapaTransactionHistoryModelBoOutput;
  fetchQuotationUserByEmailBO: CapaQuotationUserBoOutput;
  fetchQuotationPartnerByEmailBO: CapaQuotationPartnerStrategyBoOutput;
  fetchConnectFileBO: CapaConnectFileDetailOutput;
  fetchConnectUsersBO: CapaConnectUsersBoOutput;
  fetchConnectUserBO: CapaConnectUserBoOutput;
  fetchConnectGroupsBO: CapaConnectGroupsBoOutput;
  fetchConnectGroupBO: CapaConnectGroupBoOutput;
  fetchConnectChatMessagesBO: CapaConnectChatMessagesOutput;
  fetchConnectFeedbacksBO: CapaConnectFeedbacksBoOutput;
  fetchConnectUserFeedbacksBO: CapaConnectFeedbacksBoOutput;
  fetchConnectGroupFeedbacksBO: CapaConnectFeedbacksBoOutput;
  fetchConnectIssuesBO: CapaConnectIssuesBoOutput;
  fetchConnectUserIssuesBO: CapaConnectIssuesBoOutput;
  fetchConnectGroupIssuesBO: CapaConnectIssuesBoOutput;
  fetchConnectUserFileLinksBO: FetchConnectUserFileLinkBoOutput;
  fetchAdvertisementsBO: CapaAdvertisementsBoOutput;
  fetchAdsImpressionsBO: CapaAdsImpressionsBoOutput;
  fetchAdvertisementAdsImpressionsBO: CapaAdsImpressionsBoOutput;
  fetchActiveAdvertisingBO: CapaAdvertisingsOutput;
  fetchAdvertisingsBO: CapaAdvertisingsBoOutput;
  fetchAdsActivityLogs: CapaAdsActivityLogsOutput;
  fetchCapability: PartnerCapabilityOutput;
  verifyCompanyRegistrationNumber: Output;
  fetchQuotationUser: CapaQuotationUserOutput;
  fetchMyQuotationPartner: CapaQuotationPartnerStrategyOutput;
  fetchQuotationPartner: CapaQuotationPartnerStrategyOutput;
  fetchWithdrawnQuotationPartner: CapaQuotationPartnerStrategyOutput;
  fetchQuotationChatMessages: CapaQuotationChatMessagesOutput;
  fetchQuotationChatRoom: CapaQuotationChatRoomOutput;
  fetchQuotationPartnerActivity: CapaQuotationActivityOutput;
  fetchRfqQuotationPdf: CapaQuotationDetailedQuotationOutput;
  fetchWithdrawnChatRooms: CapaWithdrawnChatRoomsOutput;
  fetchQuotationChatRoomSharedFiles: CapaQuotationChatRoomSharedFilesOutput;
  fetchQuotationChatRoomAnnotationComments: CapaQuotationChatRoomAnnotationCommentsOutput;
  fetchQuotationChatRoomSpecificAnnotations: CapaQuotationChatRoomSpecificAnnotationsOutput;
  fetchQuotationServicePartnerRating: CapaQuotationServiceRatingOutput;
  fetchQuotationPartnerChatRooms: CapaQuotationPartnerChatRoomsOutput;
  fetchInProgressQuotationPartnerChatRooms: CapaInProgressQuotationPartnerChatRoomsOutput;
  fetchMySubmall: SubmallDataModelOutput;
  fetchMyQuotationUser: CapaQuotationUserOutput;
  fetchQuotationUserChatRooms: CapaQuotationUserChatRoomsOutput;
  fetchQuotationServiceUserRating: CapaQuotationServiceRatingOutput;
  fetchServiceTypeStatistics: CapaServiceTypeStatisticsOutput;
  fetchMyRefundBankAccounts: CapaQuotationUserRefundBankAccountModelsOutput;
  canEditRefundAccount: CapaCanEditRefundAccountOutput;
  fetchMyPaymentDataByPartner: CapaQuotationPaymentManageModelOutput;
  fetchMyPaymentDataByQuotationUser: CapaQuotationPaymentManageModelOutput;
  fetchCloudUser: CapaCloudUserOutput;
  fetchMyDrive: CapaMyDrivesOutput;
  fetchFileConversionStatus: CapaFileStatusOutput;
  fetchConnectUser: CapaConnectUserOutput;
  searchUser: CapaConnectSearchUsersOutput;
  fetchConnectMyDrive: CapaConnectMyDrivesOutput;
  fetchConnectFileDetail: CapaConnectFileDetailOutput;
  fetchFilePermission: CapaConnectFilePermissionOutput;
  searchFile: CapaConnectMyDrivesOutput;
  fetchCapaConnectSharedFile: CapaConnectSharedFilesOutput;
  fetchGroups: CapaConnectGroupsOutput;
  fetchGroup: CapaConnectGroupOutput;
  searchGroups: CapaConnectGroupChatRoomsOutput;
  fetchConnectAnnotations: CapaConnectAnnotationsOutput;
  fetchConnectChatMessages: CapaConnectChatMessagesOutput;
  fetchConnectGroupChatRooms: CapaConnectGroupChatRoomsOutput;
  fetchConnectGroupChatRoom: CapaConnectGroupChatRoomOutput;
  fetchConnectUserContactList: CapaConnectContactUsersOutput;
  fetchRecommendedContactUsersList: FetchRecommendedContactListsOutput;
  fetchConnectUserLinkFiles: FetchConnectUserLinkFileOutput;
  requestRfqId: CapauuidOutput;
  fetchQuotationUserRfqs: CapaQuotationUserRfqsOutput;
  fetchMyReceivedQuotations: CapaQuotationMyReceivedQuotationsOutput;
  fetchProjectManagementReceivedQuotations: CapaProjectManagementReceivedQuotationOutput;
  fetchQuotationUserGNB: CapaQuotationUserGnbOutput;
  fetchQuotationUserRfqDetail: CapaQuotationUserRfqDetailOutput;
  fetchRfqPartners: CapaQuotationRfqPartnersOutput;
  fetchPartnerRfqReviews: CapaQuotationMyRfqReviewOutput;
  fetchRfqReviews: CapaQuotationMyRfqReviewOutput;
  fetchMyReviewableRfqs: CapaReviewableRfqsOutput;
  /** 고객의 프로젝트 관리 Viewer */
  fetchQuotationUserProjectManagementDetail: CapaFetchQuotationUserProjectManagementDetailOuput;
  fetchQuotationUserProjectManagementRfqs: CapaFetchQuotationUserProjectManagementRfqsOutput;
  fetchQuotationUserRfqAvailableReviews: CapaQuotationUserRfqAvailableReviewOutput;
  fetchQuotationUserRfqReviews: CapaQuotationUserRfqReviewsOutput;
  fetchAvailableRfqs: CapaQuotationRfqAvailableRfqsOutput;
  fetchAvailableFilterRfqs: CapaFetchAvailableFilterRfqsOutput;
  fetchQuotationPartnerProjectManagementRfqs: CapaFetchQuotationPartnerProjectManagementRfqsOutput;
  fetchQuotationPartnerProjectManagementDetail: CapaFetchQuotationPartnerProjectManagementDetailOutput;
  fetchMyConsultingRfqs: CapaQuotationRfqMyRfqsOutput;
  fetchMyConfirmedRfqs: CapaQuotationRfqMyRfqsOutput;
  fetchQuotationPartnerGNB: CapaQuotationPartnerGnbOutput;
  fetchRfqDetail: CapaQuotationRfqDetailOutput;
  fetchMyRfqReviews: CapaQuotationMyRfqReviewOutput;
  searchRfq: CapaQuotationSearchOutput;
  fetchMyCompleteRfqs: CapaQuotationRfqMyCompleteRfqOutput;
  fetchQuotationPartnerRfqReview: CapaQuotationPartnerRfqReviewOutput;
  fetchQuotationPartnerAvailableRequestReviews: CapaQuotationPartnerAvailableRequestReviewsOutput;
  fetchRfqPartnerEvaluation: CapaQuotationRfqPartnerEvaluationOutput;
  fetchDetailedQuotation: CapaQuotationDetailedQuotationOutput;
  fetchMyRfqQuotations: CapaQuotationDetailedQuotationsOutput;
  fetchConfirmDetailedQuotation: CapaQuotationDetailedQuotationOutput;
  fetchPurchaseOrder: CapaQuotationRfqPurchaseOrderOutput;
  fetchPurchaseOrders: CapaQuotationRfqPurchaseOrdersOutput;
  fetchConfirmPurchaseOrder: CapaQuotationRfqPurchaseOrderOutput;
  /** 광고 활동 로그 입력 */
  fetchActiveAdvertising: CapaAdvertisingsOutput;
  fetchCAPANotification: CapaNotificationsOutput;
  fetchAllIcxInfo: IcxModelsOutput;
  fetchOneIcxInfo: IcxModelOutput;
  icxAuthenticate: IcxAuthenticationOutput;
  fetchMainBiz: IcxMainBizModelOutput;
  fetchFactoron: IcxFactoronModelOutput;
  fetchInnoBiz: IcxInnoBizModelOutput;
  fetchVentureBiz: IcxVentureBizModelOutput;
  fetchMiniCluster: IcxMiniClusterModelOutput;
  /** 에스크로 - 배송 등록 */
  registerShippingEscrow: Output;
  /** 지급대행 - 잔액 조회 */
  checkBalance: Output;
  /** 에스크로 - 구매확정 */
  confirmEscrowPurchase: Output;
  /** 에스크로 - 구매거절 */
  refuseEscrowPurchase: Output;
  /** 지급대행 - 서브몰 등록 */
  registerSubmall: Output;
  /** 지급대행 - 지급대행 요청 */
  requestPaymentAgent: Output;
  cancelRequestPaymentAgent: Output;
  fetchPaymentAgentResult: Output;
};

export type QueryAuthenticateArgs = {
  input: AuthenticationInput;
};

export type QueryCheckRegisteredCapaServiceArgs = {
  emails: Array<Scalars['String']>;
};

export type QueryVerifyEmailVerificationCodeArgs = {
  input: VerifyEmailVerificationCodeInput;
};

export type QueryRemoveDeviceTokenArgs = {
  input: RemoveDeviceTokenInput;
};

export type QueryAuthenticateBoArgs = {
  input: AuthenticationInput;
};

export type QueryFetchItpEventsBoArgs = {
  input: FetchItpEventsBoInput;
};

export type QueryFetchJsbEventsBoArgs = {
  input: FetchJsbEventsBoInput;
};

export type QueryFetchBoMemoArgs = {
  input: FetchBoMemosInput;
};

export type QueryFetchPartnersBoArgs = {
  input: FetchPartnersInput;
};

export type QueryFetchUsersBoArgs = {
  input: FetchUsersInput;
};

export type QueryFetchRfqsBoArgs = {
  input: FetchRfqsInput;
};

export type QueryFetchRfqDetailBoArgs = {
  input: FetchRfqInput;
};

export type QueryFetchQuotationUserRfqsBoArgs = {
  input: FetchQuotationUserRfqsBoInput;
};

export type QueryFetchQuotationChatMessagesBoArgs = {
  input: FetchQuotationChatRoomInput;
};

export type QueryFetchQuotationPartnerProjectParticipationStatusBoArgs = {
  input: FetchQuotationPartnerProjectParticipationStatusBoInput;
};

export type QueryFetchQuotationUserBoArgs = {
  input: FetchQuotationUserBoInput;
};

export type QueryFetchQuotationPartnerBoArgs = {
  input: FetchQuotationPartnerStrategyBoInput;
};

export type QueryFetchRfqQuotationsBoArgs = {
  input: FetchRfqQuotationsBoInput;
};

export type QueryFetchPurchaseOrdesBoArgs = {
  input: FetchPurchaseOrdersBoInput;
};

export type QueryFetchServiceSuggestionBoArgs = {
  input: FetchServiceSuggestionBoInput;
};

export type QueryFetchServiceRatingBoArgs = {
  input: FetchServiceRatingBoInput;
};

export type QueryFetchCxManagementRequiredUsersBoArgs = {
  input: FetchCxManagementRequiredUsersBoInput;
};

export type QueryFetchBoTestUsersArgs = {
  input: FetchBoTestUsersInput;
};

export type QueryFetchCommentBoArgs = {
  input: FetchCommentsBoInput;
};

export type QueryFetchBypassComplaintBoArgs = {
  input: CapaBypassComplaintInput;
};

export type QueryFetchQuotationChatRoomSpecificAnnotationsBoArgs = {
  input: FetchQuotationChatRoomSpecificAnnotationBoInput;
};

export type QueryFetchRfqReviewBoArgs = {
  input: FetchRfqReviewBoInput;
};

export type QueryFetchBoUsersArgs = {
  input: FetchBoUsersInput;
};

export type QueryFetchBoAmbassadorsArgs = {
  input: FetchBoAmbassadorsInput;
};

export type QueryFetchBoAmbassadorDetailArgs = {
  input: FetchBoAmbassadorDetailInput;
};

export type QueryFetchBoAmbassadorInvitationStatusArgs = {
  input: FetchBoAmbassadorInvitationStatusInput;
};

export type QueryFetchBoFriendsInviteUserActivityArgs = {
  input: FetchBoFriendsInviteUserActivityInput;
};

export type QueryFetchProcessingBoArgs = {
  input: FetchProcessingBoInput;
};

export type QueryFetchTransactionHistoryBoArgs = {
  input: CapaboFetchTransactionHistoryInput;
};

export type QueryFetchQuotationUserByEmailBoArgs = {
  input: FetchQuotationUserBoByEmailInput;
};

export type QueryFetchQuotationPartnerByEmailBoArgs = {
  input: FetchQuotationPartnerBoByEmailInput;
};

export type QueryFetchConnectFileBoArgs = {
  connectFileId: Scalars['String'];
};

export type QueryFetchConnectUsersBoArgs = {
  input: FetchConnectUsersBoInput;
};

export type QueryFetchConnectUserBoArgs = {
  connectUserId: Scalars['String'];
};

export type QueryFetchConnectGroupsBoArgs = {
  input: FetchConnectGroupsBoInput;
};

export type QueryFetchConnectGroupBoArgs = {
  groupId: Scalars['String'];
};

export type QueryFetchConnectChatMessagesBoArgs = {
  input: FetchConnectChatRoomInput;
};

export type QueryFetchConnectFeedbacksBoArgs = {
  input: FetchConnectFeedbacksBoInput;
};

export type QueryFetchConnectUserFeedbacksBoArgs = {
  input: FetchConnectUserFeedbacksBoInput;
};

export type QueryFetchConnectGroupFeedbacksBoArgs = {
  input: FetchConnectGroupFeedbacksBoInput;
};

export type QueryFetchConnectIssuesBoArgs = {
  input: FetchConnectIssuesBoInput;
};

export type QueryFetchConnectUserIssuesBoArgs = {
  input: FetchConnectUserIssuesBoInput;
};

export type QueryFetchConnectGroupIssuesBoArgs = {
  input: FetchConnectGroupIssuesBoInput;
};

export type QueryFetchConnectUserFileLinksBoArgs = {
  input: FetchConnectUserFileLinksBoInput;
};

export type QueryFetchAdvertisementsBoArgs = {
  input: FetchAdvertisementsBoInput;
};

export type QueryFetchAdsImpressionsBoArgs = {
  input: FetchAdsImpressionsBoInput;
};

export type QueryFetchAdvertisementAdsImpressionsBoArgs = {
  input: FetchAdvertisementAdsImpressionsBoInput;
};

export type QueryFetchActiveAdvertisingBoArgs = {
  input: FetchActiveAdvertisingBoInput;
};

export type QueryFetchAdvertisingsBoArgs = {
  input: FetchAdvertisingsBoInput;
};

export type QueryFetchAdsActivityLogsArgs = {
  input: FetchAdsActivityLogsInput;
};

export type QueryFetchCapabilityArgs = {
  input: FetchCapabilityInput;
};

export type QueryVerifyCompanyRegistrationNumberArgs = {
  input: VerifyCompanyRegistrationNumberInput;
};

export type QueryFetchQuotationUserArgs = {
  quotationUserId: Scalars['String'];
};

export type QueryFetchQuotationPartnerArgs = {
  quotationPartnerId: Scalars['String'];
};

export type QueryFetchWithdrawnQuotationPartnerArgs = {
  quotationPartnerId: Scalars['String'];
};

export type QueryFetchQuotationChatMessagesArgs = {
  input: FetchQuotationChatRoomInput;
};

export type QueryFetchQuotationChatRoomArgs = {
  input: FetchQuotationChatRoomInput;
};

export type QueryFetchQuotationPartnerActivityArgs = {
  input?: Maybe<FetchQuotationPartnerActivityInput>;
};

export type QueryFetchRfqQuotationPdfArgs = {
  input?: Maybe<RequestRfqQuotationPdfInput>;
};

export type QueryFetchWithdrawnChatRoomsArgs = {
  input: FetchWithdrawnChatRoomsInput;
};

export type QueryFetchQuotationChatRoomSharedFilesArgs = {
  input: FetchQuotationChatRoomSharedFilesInput;
};

export type QueryFetchQuotationChatRoomAnnotationCommentsArgs = {
  input: FetchQuotationChatRoomAnnotationCommentInput;
};

export type QueryFetchQuotationChatRoomSpecificAnnotationsArgs = {
  input: FetchQuotationChatRoomSpecificAnnotationInput;
};

export type QueryFetchQuotationPartnerChatRoomsArgs = {
  input: FetchQuotationPartnerChatRoomsInput;
};

export type QueryFetchInProgressQuotationPartnerChatRoomsArgs = {
  input: FetchInProgressQuotationPartnerChatRoomsInput;
};

export type QueryFetchMySubmallArgs = {
  input: FetchMySubmallInput;
};

export type QueryFetchQuotationUserChatRoomsArgs = {
  input?: Maybe<FetchQuotationUserChatRoomsInput>;
};

export type QueryFetchServiceTypeStatisticsArgs = {
  input: FetchServiceTypeStatisticsInput;
};

export type QueryFetchMyPaymentDataByPartnerArgs = {
  input: FetchPaymentManageByPartnerIdInput;
};

export type QueryFetchMyPaymentDataByQuotationUserArgs = {
  input: FetchPaymentManageByQuotationUserIdInput;
};

export type QueryFetchMyDriveArgs = {
  input: FetchMyDriveInput;
};

export type QueryFetchFileConversionStatusArgs = {
  input: FetchFileConversionStatusInput;
};

export type QuerySearchUserArgs = {
  input: SearchConnectUsersInput;
};

export type QueryFetchConnectMyDriveArgs = {
  input: FetchConnectMyDriveInput;
};

export type QueryFetchConnectFileDetailArgs = {
  input: FetchConnectFileDetailInput;
};

export type QueryFetchFilePermissionArgs = {
  input: FetchConnectGroupFilePermissionInput;
};

export type QuerySearchFileArgs = {
  input: SearchConnectFileInput;
};

export type QueryFetchCapaConnectSharedFileArgs = {
  input: FetchCapaConnectSharedFileInput;
};

export type QueryFetchGroupsArgs = {
  input: FetchConnectGroupsInput;
};

export type QueryFetchGroupArgs = {
  input: FetchConnectGroupInput;
};

export type QuerySearchGroupsArgs = {
  input: SearchConnectGroupsInput;
};

export type QueryFetchConnectAnnotationsArgs = {
  input: FetchConnectAnnotationsInput;
};

export type QueryFetchConnectChatMessagesArgs = {
  input: FetchConnectChatRoomInput;
};

export type QueryFetchConnectGroupChatRoomsArgs = {
  input: FetchConnectGroupChatRoomsInput;
};

export type QueryFetchConnectGroupChatRoomArgs = {
  input: FetchConnectGroupChatRoomInput;
};

export type QueryFetchConnectUserContactListArgs = {
  input: FetchConnectUserContactListInput;
};

export type QueryFetchRecommendedContactUsersListArgs = {
  input: FetchRecommendedContactUserListInput;
};

export type QueryFetchConnectUserLinkFilesArgs = {
  input: FetchConnectUserLinkFileInput;
};

export type QueryFetchQuotationUserRfqsArgs = {
  input: FetchQuotationUserRfqsInput;
};

export type QueryFetchMyReceivedQuotationsArgs = {
  input: FetchMyReceivedQuotationsInput;
};

export type QueryFetchProjectManagementReceivedQuotationsArgs = {
  input: FetchProjectManagementReceivedQuotationsInput;
};

export type QueryFetchQuotationUserRfqDetailArgs = {
  input: FetchRfqInput;
};

export type QueryFetchRfqPartnersArgs = {
  input: FetchRfqInput;
};

export type QueryFetchPartnerRfqReviewsArgs = {
  input: FetchPartnerRfqReviewsInput;
};

export type QueryFetchQuotationUserProjectManagementDetailArgs = {
  input: FetchQuotationUserProjectManagementDetailInput;
};

export type QueryFetchQuotationUserProjectManagementRfqsArgs = {
  input: FetchQuotationUserProjectManagementRfqsInput;
};

export type QueryFetchQuotationUserRfqAvailableReviewsArgs = {
  input: FetchQuotationUserRfqReviewsInput;
};

export type QueryFetchQuotationUserRfqReviewsArgs = {
  input: FetchQuotationUserRfqReviewsInput;
};

export type QueryFetchAvailableRfqsArgs = {
  input: FetchRfqsInput;
};

export type QueryFetchAvailableFilterRfqsArgs = {
  input: FetchAvailableFilterRfqsInput;
};

export type QueryFetchQuotationPartnerProjectManagementRfqsArgs = {
  input: FetchQuotationPartnerManagementRfqsInput;
};

export type QueryFetchQuotationPartnerProjectManagementDetailArgs = {
  input: FetchQuotationPartnerProjectManagementDetailInput;
};

export type QueryFetchMyConsultingRfqsArgs = {
  input: FetchRfqsInput;
};

export type QueryFetchMyConfirmedRfqsArgs = {
  input: FetchRfqsInput;
};

export type QueryFetchQuotationPartnerGnbArgs = {
  input: FetchRfqsInput;
};

export type QueryFetchRfqDetailArgs = {
  input: FetchRfqInput;
};

export type QuerySearchRfqArgs = {
  input: QuotationKeywordSearchInput;
};

export type QueryFetchMyCompleteRfqsArgs = {
  input: FetchMyCompleteRfqsInput;
};

export type QueryFetchQuotationPartnerRfqReviewArgs = {
  input: FetchQuotationPartnerRfqReviewInput;
};

export type QueryFetchQuotationPartnerAvailableRequestReviewsArgs = {
  input: FetchQuotationPartnerRfqReviewInput;
};

export type QueryFetchRfqPartnerEvaluationArgs = {
  input?: Maybe<FetchRfqPartnerEvaluationInput>;
};

export type QueryFetchDetailedQuotationArgs = {
  input: FetchDetailedQuotationInput;
};

export type QueryFetchMyRfqQuotationsArgs = {
  input: FetchMyRfqQuotationsInput;
};

export type QueryFetchConfirmDetailedQuotationArgs = {
  input: FetchConfirmDetailedQuotationInput;
};

export type QueryFetchPurchaseOrderArgs = {
  input: FetchPurchaseOrderInput;
};

export type QueryFetchPurchaseOrdersArgs = {
  input: FetchPurchaseOrdersInput;
};

export type QueryFetchConfirmPurchaseOrderArgs = {
  input: FetchConfirmPurchaseOrderInput;
};

export type QueryFetchActiveAdvertisingArgs = {
  input: FetchActiveAdvertisingInput;
};

export type QueryFetchCapaNotificationArgs = {
  input: FetchNotificationInput;
};

export type QueryFetchAllIcxInfoArgs = {
  input: FetchAllIcxInput;
};

export type QueryFetchOneIcxInfoArgs = {
  input: FetchOneIcxInput;
};

export type QueryIcxAuthenticateArgs = {
  input: IcxAuthenticationInput;
};

export type QueryFetchMainBizArgs = {
  input: MainBizFetchIcxInput;
};

export type QueryFetchFactoronArgs = {
  input: FactoronBizFetchIcxInput;
};

export type QueryFetchInnoBizArgs = {
  input: InnoBizFetchIcxInput;
};

export type QueryFetchVentureBizArgs = {
  input: VentureBizFetchIcxInput;
};

export type QueryFetchMiniClusterArgs = {
  input: IcxMiniClusterFetchIcxInput;
};

export type QueryRegisterShippingEscrowArgs = {
  input: RegisterExpectedDeliveryDateInput;
};

export type QueryCheckBalanceArgs = {
  input: CheckBalanceInput;
};

export type QueryConfirmEscrowPurchaseArgs = {
  input: ConfirmEscrowPurchaseInput;
};

export type QueryRefuseEscrowPurchaseArgs = {
  input: RefuseEscrowPurchaseInput;
};

export type QueryRegisterSubmallArgs = {
  input: RegisterSubmallInput;
};

export type QueryRequestPaymentAgentArgs = {
  input: RequestPaymentAgentInput;
};

export type QueryCancelRequestPaymentAgentArgs = {
  input: CancelRequestPaymentAgentInput;
};

export type QueryFetchPaymentAgentResultArgs = {
  input: FetchPaymentAgentResultInput;
};

export type QuitQuotationChatRoomInput = {
  chatRoomId: Scalars['String'];
};

export type QuotationKeywordSearchInput = {
  keyword: Scalars['String'];
  confirmed: Scalars['Boolean'];
  eventType?: Maybe<CapaQuotationRfqEventType>;
};

export type QuotationPartner = {
  __typename?: 'QuotationPartner';
  id: Scalars['ID'];
};

/** 파트너 파일 정보 입력 */
export type QuotationPartnerFileUploadInput = {
  /** fk 캐파 클라우드 파일 아이디 */
  fileId: Scalars['String'];
  /** 타입 CAPAQuotationPartnerFileType */
  fileType: CapaQuotationPartnerFileType;
};

/** 파트너가 고객찾기에서 rfq상태 , 발주 상태 제조상태 검색 위한 Enum */
export enum QuotationPartnerRfqCurrentSituationType {
  /** 전체 목록 표출 */
  All = 'ALL',
  /** 나와 상담중  */
  ConsultingWithMe = 'CONSULTING_WITH_ME',
  /** 타업체발주  */
  OtherCompanyOrder = 'OTHER_COMPANY_ORDER',
  /** 타업체상담중  */
  ConsultingWithOtherCompany = 'CONSULTING_WITH_OTHER_COMPANY',
  /** 견적비교 마감  */
  RequestFinish = 'REQUEST_FINISH',
  /** 회사소개서보냄  */
  SendCompanyIntroduction = 'SEND_COMPANY_INTRODUCTION',
  /** 견적서 발행함 */
  SendQuotation = 'SEND_QUOTATION',
  /** 결제/발주중 */
  OrderInprogress = 'ORDER_INPROGRESS',
  /** 결제/발주중단 */
  OrderSuspended = 'ORDER_SUSPENDED',
  /** 제조중 */
  Processing = 'PROCESSING',
  /** 제조중단 */
  ProcessInterruption = 'PROCESS_INTERRUPTION',
  /** 배송중 */
  Shipping = 'SHIPPING',
  /** 거래완료 */
  ConfirmPurchaseOrder = 'CONFIRM_PURCHASE_ORDER'
}

export type QuotationServiceRatingInput = {
  /** 점수 */
  rating: Scalars['Float'];
  /** 커멘트 */
  comment?: Maybe<Scalars['String']>;
};

export type QuotationUser = {
  __typename?: 'QuotationUser';
  id: Scalars['ID'];
};

/** 고객 파일 정보 입력 */
export type QuotationUserFileUploadInput = {
  /** fk 파일 아이디 */
  fileId: Scalars['String'];
  /** 타입 CAPAQuotationUserFileType */
  fileType: CapaQuotationUserFileType;
};

/** ReadAllManagementInput */
export type ReadAllManagementInput = {
  rfqId: Scalars['String'];
  type: CapaRfqProjectManagementType;
};

export type ReadConnectAnnotationCommentInput = {
  annotationId: Scalars['String'];
};

export type ReadConnectChatRoomMessageInput = {
  chatRoomId: Scalars['String'];
};

/** ReadManagementInput */
export type ReadManagementInput = {
  rfqId: Scalars['String'];
  type: CapaRfqProjectManagementType;
  partnerId?: Maybe<Scalars['String']>;
};

export type ReadNotificationInput = {
  /** 캐파 서비스 타입 */
  service: CapaServiceType;
  seq?: Maybe<Scalars['Float']>;
};

export type ReadQuotationAnnotationCommentInput = {
  annotationId: Scalars['String'];
};

export type ReadQuotationChatRoomMessageInput = {
  chatRoomId: Scalars['String'];
};

export type ReadRfqPurchaseOrderInput = {
  rfqId: Scalars['String'];
  quotationPartnerId: Scalars['String'];
};

export type ReadRfqQuotationInput = {
  rfqId: Scalars['String'];
  partnerId: Scalars['String'];
};

export type RefuseEscrowPurchaseInput = {
  TID?: Maybe<Scalars['String']>;
  MID?: Maybe<Scalars['String']>;
  ReqType?: Maybe<EscrowPaymentRequestType>;
  EdiDate?: Maybe<Scalars['String']>;
  MallIp?: Maybe<Scalars['String']>;
  CharSet?: Maybe<Scalars['String']>;
  EdiType?: Maybe<Scalars['String']>;
  BuyerAuthNum?: Maybe<Scalars['String']>;
  RejectReason?: Maybe<Scalars['String']>;
  RefundAccount?: Maybe<Scalars['String']>;
  RefundBankCode?: Maybe<Scalars['String']>;
  RefundName?: Maybe<Scalars['String']>;
};

export type RegistTbUtmInput = {
  utmSource?: Maybe<Scalars['String']>;
  utmMedium?: Maybe<Scalars['String']>;
  utmCampaign?: Maybe<Scalars['String']>;
  utmTerm?: Maybe<Scalars['String']>;
  utmContent?: Maybe<Scalars['String']>;
};

/** BO 엠버서더 등록 */
export type RegisterAmbassadorBoInput = {
  /** 엠베서더 회사명 */
  organizationName: Scalars['String'];
  /** 비밀번호 */
  password: Scalars['String'];
  /** 담당자명 */
  managerName: Scalars['String'];
  /** 담당자 이메일 */
  email: Scalars['String'];
  /** 담당자 휴대폰 번호 */
  cellphoneNumber: Scalars['String'];
  /** 사업자 등록번호 */
  companyRegistrationNumber: Scalars['String'];
  /** 사업자 등록증 파일 id */
  einFileId?: Maybe<Scalars['String']>;
  /** 엠베서더 등록일 */
  createdAt?: Maybe<Scalars['String']>;
};

/** BO 회원가입 */
export type RegisterBackOfficeUserInput = {
  /** 이메일 */
  email: Scalars['String'];
  /** 비밀번호 */
  password: Scalars['String'];
  /** 역할 */
  role: BackOfficeAdminRole;
  /** 이름 */
  name: Scalars['String'];
  /** 영어닉네임 */
  nickName: Scalars['String'];
  /** 부서 */
  dept: BackOfficeUserDeptType;
  /** 직무 */
  task: Scalars['String'];
  /** 상태 */
  status?: Maybe<BackOfficeUserStatus>;
};

/** 캐파 회원가입 */
export type RegisterCapaUserInput = {
  /** 이메일 */
  email: Scalars['String'];
  /** 인증번호 */
  code: Scalars['String'];
  /** 비밀번호 */
  password: Scalars['String'];
  /** 비밀번호 확인 */
  passwordConfirm: Scalars['String'];
  /** CAPA 서비스를 어떻게 알게 되셨나요? (복수 선택 가능) */
  inflowRoute: Array<CapaInflowRoute>;
  /** 추천 회사명/기관명 */
  referralCompany?: Maybe<Scalars['String']>;
  /** [필수] 캐파 서비스 이용약관 동의 */
  serviceAgree: Scalars['Boolean'];
  /** [필수] 개인정보 수집 및 활용 동의 */
  privacyAgree: Scalars['Boolean'];
  /** [선택] 프로모션 정보 수신 동의 */
  promotionAgree?: Maybe<Scalars['Boolean']>;
  /** [필수] 결제 서비스 약관 동의 */
  paymentAgree?: Maybe<Scalars['Boolean']>;
  /** 캐파 서비스 타입 */
  service: CapaServiceType;
  /** 추천인 ID */
  referralUserId?: Maybe<Scalars['String']>;
  /** 엠버서더 ID */
  ambassadorId?: Maybe<Scalars['String']>;
  /** utm 정보 */
  registTbUtmInfo?: Maybe<RegistTbUtmInput>;
};

export type RegisterConnectUserFolderInput = {
  pathId?: Maybe<Scalars['String']>;
  folderName: Scalars['String'];
};

export type RegisterConnectUserInput = {
  userName: Scalars['String'];
  userLanguage: Language;
  isWebMember?: boolean;
};

export type RegisterExpectedDeliveryDateInput = {
  quotationPartnerId: Scalars['String'];
  purchaseOrderId: Scalars['String'];
  /** 상점IP 서버주소 */
  MallIp?: Maybe<Scalars['String']>;
  /**
   * 배송업체명.
   * 배송지 등록을 위해 반드시 필요한 정보지만 나이스페이측 택배사 리스트 업데이트 이슈로 인해 optional.
   * 이 데이터는 배송정보db에 저장,
   * registerShippingEscrow(에스크로 배송 등록)시 db에 저장된 값이 null 이라면
   * 백엔드단에서 NicepayShippingCompany enum중 실제 존재하는 데이터를 임의로 설정 해주어야한다.
   */
  shippingCompany?: Maybe<Scalars['String']>;
  /** 배송지 주소 */
  shippingAddress: Scalars['String'];
  /** 송장번호, '-' 특수문자 없이 입력 */
  invoiceNumber?: Maybe<Scalars['String']>;
  /** 등록자이름 */
  registerName: Scalars['String'];
  /** 배송 방식 */
  shippingMethod: CapaShippingMethodType;
  /** 기타 배송방식 */
  etcShippingMethod?: Maybe<Scalars['String']>;
};

/** 카파 User 통합 회원가입 */
export type RegisterIntegratedUserInput = {
  /** 이메일 */
  email: Scalars['String'];
  /** 이메일 인증번호 */
  emailCode: Scalars['String'];
  /** 휴대폰 인증번호 */
  cellphoneCode: Scalars['String'];
  /** 비밀번호 */
  password: Scalars['String'];
  /** 비밀번호 확인 */
  passwordConfirm: Scalars['String'];
  /** 사업자 기업 정보 관련 */
  companyInfo?: Maybe<NiceDnbCompanyInformationInput>;
  /** 회사 / 단체명 */
  organizationName?: Maybe<Scalars['String']>;
  /** 이름 (담당자명) */
  managerName: Scalars['String'];
  /** 휴대폰 번호 */
  cellphoneNumber: Scalars['String'];
  /** CAPA 서비스를 어떻게 알게 되셨나요? (복수 선택 가능) */
  inflowRoute?: Maybe<Array<CapaInflowRoute>>;
  /** [필수] 카파 서비스 이용약관 동의 */
  serviceAgree: Scalars['Boolean'];
  /** [필수] 개인정보 수집 및 활용 동의 */
  privacyAgree: Scalars['Boolean'];
  /** [선택] 프로모션 정보 수신 동의 */
  promotionAgree?: Maybe<Scalars['Boolean']>;
  /** 카파 서비스 타입 */
  service: CapaServiceType;
  /** 주소 */
  fullAddress?: Maybe<Scalars['String']>;
  /** 짧은 주소 */
  shortAddress?: Maybe<Scalars['String']>;
  /** 사업자 여부 */
  bizType: CapaQuotationUserBizType;
  /** 회사소개 */
  companyIntroduction?: Maybe<Scalars['String']>;
  /** 웹사이트 */
  website?: Maybe<Scalars['String']>;
  /** 부서 */
  department?: Maybe<Scalars['String']>;
  /** 직함 */
  position?: Maybe<Scalars['String']>;
  /** 견적 유저 파일 업로드 정보 입력 */
  files?: Maybe<Array<QuotationUserFileUploadInput>>;
  /** 추천인 ID */
  referralUserId?: Maybe<Scalars['String']>;
};

/** ITP 이벤트 RFQ 거래완료 보상 신청 */
export type RegisterItpEventInput = {
  /** rfq 아이디 */
  rfqId: Scalars['String'];
  /** 연락처 */
  cellphoneNumber: Scalars['String'];
  /** ITP 이벤트 거래완료 증빙 자료 */
  files: Array<ItpEventFileUploadInput>;
  /** OFFICECON 정보제공 동의여부 */
  officeconAgreed: Scalars['Boolean'];
  /** 거래완료 금액 */
  completeCost: Scalars['Float'];
  /** rfq 프로젝트 이름 */
  projectName: Scalars['String'];
  /** Quotation 파트너 id */
  quotationPartnerId: Scalars['String'];
};

/** ITP 이벤트 RFQ 거래완료 보상 신청 */
export type RegisterJsbEventInput = {
  /** rfq 아이디 */
  rfqId: Scalars['String'];
  /** 연락처 */
  cellphoneNumber: Scalars['String'];
  /** ITP 이벤트 거래완료 증빙 자료 */
  files: Array<JsbEventFileUploadInput>;
  /** 거래완료 금액 */
  completeCost: Scalars['Float'];
  /** 은행 */
  bank: Scalars['String'];
  /** 계좍 */
  account: Scalars['String'];
  /** rfq 프로젝트 이름 */
  projectName: Scalars['String'];
  /** Quotation 파트너 id */
  quotationPartnerId: Scalars['String'];
};

export type RegisterQuotationPartnerBoInput = {
  /** 이메일 */
  email: Scalars['String'];
  /** 비밀번호 */
  password: Scalars['String'];
  /** CAPA 서비스를 어떻게 알게 되셨나요? (복수 선택 가능) */
  inflowRoute: Array<CapaInflowRoute>;
  /** [선택] 프로모션 정보 수신 동의 */
  promotionAgree?: Maybe<Scalars['Boolean']>;
  /** 파트너 캐파빌리티 jsonb array of { service_type, process_method, material, detailed_material, etc } */
  capabilities: CapaQuotationCapabilityInput;
  /** 회사명 */
  organizationName: Scalars['String'];
  /** 주소 */
  fullAddress: Scalars['String'];
  /** 구 까지만 보여주는 주소 */
  shortAddress: Scalars['String'];
  /** 사업자 등록번호 */
  companyRegistrationNumber: Scalars['String'];
  /** 회사소개 */
  companyIntroduction?: Maybe<Scalars['String']>;
  /** 설립년도 */
  foundedIn?: Maybe<Scalars['String']>;
  /** 규모 (직원수) */
  numberEmployees?: Maybe<Scalars['String']>;
  /** 장비 */
  equipment?: Maybe<Scalars['String']>;
  /** 대응 시간 */
  serviceHours?: Maybe<Scalars['String']>;
  /** 웹사이트 */
  website?: Maybe<Scalars['String']>;
  /** 담당자명 */
  managerName: Scalars['String'];
  /** 부서 */
  department: Scalars['String'];
  /** 직위 */
  position: Scalars['String'];
  /** 연락처 */
  contact: Scalars['String'];
  /** 파트너 파일 업로드 정보 입력 */
  files?: Maybe<Array<QuotationPartnerFileUploadInput>>;
  /** 파트너 기술관련 파일 업로드 정보 입력 */
  strategyFiles?: Maybe<Array<CapaQuotationPartnerStrategyFileUploadInput>>;
  /** 공장 주소 */
  factoryAddress?: Maybe<Scalars['String']>;
};

/** 파트너 정보 입력 */
export type RegisterQuotationPartnerEquipmentInput = {
  /** fk 캐파 파일 아이디 */
  fileId?: Maybe<Scalars['String']>;
  /** 장비 명 */
  equipmentName: Scalars['String'];
  /** 설명 */
  description?: Maybe<Scalars['String']>;
  /** 수량 */
  quantity: Scalars['Float'];
};

/** 파트너 정보 입력 */
export type RegisterQuotationPartnerInput = {
  /** 파트너 캐파빌리티 jsonb array of { service_type, process_method, material, detailed_material, etc } */
  capabilities: CapaQuotationCapabilityInput;
  /** 회사명 */
  organizationName: Scalars['String'];
  /** 주소 */
  fullAddress: Scalars['String'];
  /** 구 까지만 보여주는 주소 */
  shortAddress: Scalars['String'];
  /** detail address */
  detailAddress?: Maybe<Scalars['String']>;
  /** 사업자 등록번호 */
  companyRegistrationNumber: Scalars['String'];
  /** 회사소개 */
  companyIntroduction?: Maybe<Scalars['String']>;
  /** 설립년도 */
  foundedIn?: Maybe<Scalars['String']>;
  /** 규모 (직원수) */
  numberEmployees?: Maybe<Scalars['String']>;
  /** 장비 */
  equipment?: Maybe<Scalars['String']>;
  /** 대응 시간 */
  serviceHours?: Maybe<Scalars['String']>;
  /** 웹사이트 */
  website?: Maybe<Scalars['String']>;
  /** 담당자명 */
  managerName: Scalars['String'];
  /** 부서 */
  department: Scalars['String'];
  /** 직위 */
  position: Scalars['String'];
  /** 연락처 */
  contact: Scalars['String'];
  /** 사업자 등록증 파일 */
  registrations: Array<CapaQuotationPartnerStrategyFileUploadInput>;
  /** 회사 소개서 파일 */
  introductionFileId?: Maybe<Scalars['String']>;
  /** 공장 주소 */
  factoryAddress?: Maybe<Scalars['String']>;
  /** factory detail address */
  factoryDetailAddress?: Maybe<Scalars['String']>;
};

/** RegisterQuotationPartnerPortfolioCapabilitiesInput */
export type RegisterQuotationPartnerPortfolioCapabilitiesInput = {
  service?: Maybe<CapaQuotationServiceType>;
  detailedServices?: Maybe<Array<CapaQuotationDetailedServiceType>>;
};

/** 파트너 정보 입력 */
export type RegisterQuotationPartnerPortfolioInput = {
  /** 파트너 파일 업로드 정보 입력 */
  files: Array<CapaQuotationPartnerStrategyFileUploadInput>;
  /** 프로젝트 명 */
  projectName: Scalars['String'];
  /** 고객 */
  client?: Maybe<Scalars['String']>;
  /** 상세 정보 */
  description?: Maybe<Scalars['String']>;
  /** 제품 용도 */
  projectPurpose?: Maybe<Array<CapaQuotationProductPurpose>>;
  /** 제품 용도 */
  capabilities?: Maybe<
    Array<RegisterQuotationPartnerPortfolioCapabilitiesInput>
  >;
  /** 제품 종류 */
  productType?: Maybe<Scalars['String']>;
  /** 수량 */
  quantity?: Maybe<Scalars['Float']>;
  /** 제조 단계 */
  produceStep?: Maybe<CapaQuotationPartnerImagePortfolioProduceStepType>;
};

/** 매칭 필수정보 입력 / 기본 정보 */
export type RegisterQuotationUserInput = {
  /** 이름 (담당자명) */
  managerName: Scalars['String'];
  /** 휴대폰 번호 */
  cellphoneNumber: Scalars['String'];
  /** 주소 */
  fullAddress?: Maybe<Scalars['String']>;
  /** 짧은 주소 */
  shortAddress?: Maybe<Scalars['String']>;
  /** 사업자등록번호 */
  companyRegistrationNumber?: Maybe<Scalars['String']>;
  /** 사업자 여부 */
  bizType: CapaQuotationUserBizType;
  /** 회사 / 단체명 */
  organizationName?: Maybe<Scalars['String']>;
  /** 회사소개 */
  companyIntroduction?: Maybe<Scalars['String']>;
  /** 웹사이트 */
  website?: Maybe<Scalars['String']>;
  /** 부서 */
  department?: Maybe<Scalars['String']>;
  /** 직함 */
  position?: Maybe<Scalars['String']>;
  /** 견적 유저 파일 업로드 정보 입력 */
  files?: Maybe<Array<QuotationUserFileUploadInput>>;
  /** 추천인 ID */
  referralUserId?: Maybe<Scalars['String']>;
};

export type RegisterRequestRfqPurchaseOrderInput = {
  rfqId: Scalars['String'];
};

export type RegisterRequestRfqQuotationInput = {
  rfqId: Scalars['String'];
  partnerId: Scalars['String'];
};

export type RegisterRfqDesignInput = {
  /** design 단계 - 제품 디자인, 기구 설계 */
  services: Array<DesignServiceInput>;
};

export type RegisterRfqInput = {
  rfqId: Scalars['String'];
  serviceType: CapaQuotationServiceType;
  detailedServiceType?: Maybe<CapaQuotationDetailedServiceType>;
  /** pcb 서비스 이용 */
  pcb?: Maybe<RegisterRfqPcbInput>;
  /** pcb 서비스 이용 */
  design?: Maybe<RegisterRfqDesignInput>;
  drawingFiles?: Maybe<Array<RfqDrawingFileInput>>;
  /** 상세요청사항 */
  detailedRequest?: Maybe<Scalars['String']>;
  referenceFiles?: Maybe<Array<RfqReferenceFileInput>>;
  projectName: Scalars['String'];
  dueDate: Scalars['String'];
  dueNegotiable: Scalars['Boolean'];
  maxEstimatedBudget: Scalars['Float'];
  minEstimatedBudget: Scalars['Float'];
  budgetOpen: Scalars['Boolean'];
  projectPurpose: CapaQuotationProductPurpose;
  projectPurposeDetail: Scalars['String'];
  optionalServices?: Maybe<Array<RfqOptionalServiceInput>>;
  publicOpen: Scalars['Boolean'];
  eventType?: Maybe<CapaQuotationRfqEventType>;
  manufacturingStage?: Maybe<CapaRfqManufacturingStage>;
  /** 재작성 베이스 rfqId */
  preRfqId?: Maybe<Scalars['String']>;
  /** 상담 시작 방식 */
  consultingStartMethod?: Maybe<CapaConsultingStartMethod>;
};

export type RegisterRfqPcbInput = {
  /** pcb 단계 - 설계, 제작, 조립 */
  services: Array<PcbServiceInput>;
  /** 설계시 제작 수량 */
  fabricationQuantity?: Maybe<Scalars['Float']>;
  /** 거버파일 - pcb 제작을 위한 파일 */
  fabricationFiles?: Maybe<Array<RfqPcbFabricationFileInput>>;
  /** 설계시 조립 수량 */
  assemblyQuantity?: Maybe<Scalars['Float']>;
  /** BOM 파일 - pcb 조립을 위한 파일 */
  assemblyFiles?: Maybe<Array<RfqPcbAssemblyFileInput>>;
  /** 조립 방식 */
  assemblyMethod?: Maybe<Array<PcbAssemblyMethodInput>>;
  /** 메탈마스크 제공 여부 */
  metalMaskAvailablity?: Maybe<Scalars['Boolean']>;
  /** 자재 방법 */
  materialSupplyMethod?: Maybe<CapaRfqPcbMaterialSupplyMethodType>;
};

export type RegisterRfqReviewInput = {
  /** rfq id */
  rfqId: Scalars['String'];
  /** quotation Partner id */
  quotationPartnerId: Scalars['String'];
  /** 리뷰 타입 */
  rfqReviewType?: Maybe<CapaRfqReviewType>;
  /** transaction satisfaction comment */
  transactionSatisfactionPoint?: Maybe<Scalars['Float']>;
  /** transaction satisfaction comment */
  transactionSatisfactionComment?: Maybe<Scalars['String']>;
  reviewFiles?: Maybe<Array<RfqTransactionReviewFileInput>>;
  /** 납기일 준수 여부 */
  inDueDate?: Maybe<Scalars['Boolean']>;
};

export type RegisterSubmallInput = {
  quotationPartnerId: Scalars['String'];
  /** 서브몰id(파트너가 원하는) - 영문자,숫자조합 */
  submallId: Scalars['String'];
  /** 서브몰 이름 (상호명) */
  submallName: Scalars['String'];
  /** 정산 은행 코드 */
  bankCode: CapaBankCode;
  /** 정산 계좌번호(`-`제외) */
  bankAccount: Scalars['String'];
  /** 예금주명 */
  accountHolder: Scalars['String'];
  /** 사업자 등록번호 */
  submallRegistrationNumber: Scalars['String'];
  /** 필수값. 요청타입 (0:신규, 1:수정) */
  reqType?: Maybe<SubmallRequestType>;
};

export type RegisteredCapaService = {
  __typename?: 'RegisteredCapaService';
  email: Scalars['String'];
  scopes?: Maybe<Array<CapaServiceType>>;
  quotationUser?: Maybe<QuotationUser>;
  quotationParnter?: Maybe<QuotationPartner>;
  connectUser?: Maybe<ConnectUser>;
  cloudUser?: Maybe<CloudUser>;
};

export type RegisteredCapaServicesOutput = {
  __typename?: 'RegisteredCapaServicesOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<Array<RegisteredCapaService>>;
};

/** @deprecated @see {RegisterCapaUserInput} 캐파 회원가입 */
export type RegistrationInput = {
  /** 이메일 */
  email: Scalars['String'];
  /** 인증번호 */
  code: Scalars['String'];
  /** 비밀번호 */
  password: Scalars['String'];
  /** 비밀번호 확인 */
  passwordConfirm: Scalars['String'];
  /** CAPA 서비스를 어떻게 알게 되셨나요? (복수 선택 가능) */
  inflowRoute: Array<CapaInflowRoute>;
  /** 추천 회사명/기관명 */
  referralCompany?: Maybe<Scalars['String']>;
  /** [필수] 캐파 서비스 이용약관 동의 */
  serviceAgree: Scalars['Boolean'];
  /** [필수] 개인정보 수집 및 활용 동의 */
  privacyAgree: Scalars['Boolean'];
  /** [선택] 프로모션 정보 수신 동의 */
  promotionAgree?: Maybe<Scalars['Boolean']>;
  /** [필수] 결제 서비스 약관 동의 */
  paymentAgree?: Maybe<Scalars['Boolean']>;
  /** 캐파 서비스 타입 */
  service: CapaServiceType;
  /** 추천인 ID */
  referralUserId?: Maybe<Scalars['String']>;
  /** 엠버서더 ID */
  ambassadorId?: Maybe<Scalars['String']>;
  /** utm 정보 */
  registTbUtmInfo?: Maybe<RegistTbUtmInput>;
  isWebMember?: boolean;
};

export type RejectRfqPurchaseOrderInput = {
  /** rfq id */
  rfqId: Scalars['String'];
  /** 발주서 id */
  purchaseOrderId: Scalars['String'];
  /** 거부 이유 */
  rejectReason?: Maybe<Scalars['String']>;
};

/** BO 서브몰 승인 거절 */
export type RejectSubmallInput = {
  /** reject type */
  rejectType: CapaSubmallRejectType;
  /** 취소 상세 사유 */
  reason?: Maybe<Scalars['String']>;
};

export type RemoveConnectAnnotationCommentInput = {
  groupId: Scalars['String'];
  connectSharedFileId: Scalars['String'];
  annotationId: Scalars['String'];
  commentId: Scalars['String'];
};

export type RemoveConnectAnnotationInput = {
  groupId: Scalars['String'];
  connectSharedFileId: Scalars['String'];
  annotationId: Scalars['String'];
};

export type RemoveConnectContactUserInput = {
  contactUserEmails: Array<Scalars['String']>;
};

export type RemoveConnectFileInput = {
  /** 파일 */
  fileIds: Array<Scalars['String']>;
  /** isFolder or not */
  isFolder?: Maybe<Scalars['Boolean']>;
  /** filePath */
  filePath?: Maybe<Scalars['String']>;
};

export type RemoveConnectGroupInput = {
  groupId: Scalars['String'];
  archive?: Maybe<Scalars['Boolean']>;
};

export type RemoveConnectUserInput = {
  userId: Scalars['String'];
};

/** 디바이스 토큰 삭제 */
export type RemoveDeviceTokenInput = {
  /** 이메일 */
  email: Scalars['String'];
  /** device token */
  deviceToken?: Maybe<Scalars['String']>;
};

export type RemoveQuotationAnnotationCommentInput = {
  annotationCommentId: Scalars['String'];
};

export type RemoveQuotationAnnotationInput = {
  annotationId: Scalars['String'];
};

export type RenameConnectFileInput = {
  /** 파일 path */
  fileId?: Maybe<Scalars['String']>;
  /** 새 파일 이름 */
  toName: Scalars['String'];
};

export type RenameConnectGroupInput = {
  /** 그룹ID */
  groupId?: Maybe<Scalars['String']>;
  /** 새 그룹 이름 */
  toName?: Maybe<Scalars['String']>;
};

export type ReplyRfqReviewInput = {
  rfqId: Scalars['String'];
  reply: Scalars['String'];
};

export type RequestAdjustRfqPurchaseOrderInput = {
  rfqId?: Maybe<Scalars['String']>;
  /** 수정 요청 내용 */
  adjustRequest?: Maybe<Scalars['String']>;
};

export type RequestAdjustRfqQuotationInput = {
  rfqId?: Maybe<Scalars['String']>;
  quotationPartnerId?: Maybe<Scalars['String']>;
  /** 수정 요청 내용 */
  adjustRequest?: Maybe<Scalars['String']>;
};

/** 이메일 인증 코드 */
export type RequestCellPhoneVerificationCodeInput = {
  /** 휴대폰 번호 */
  cellphoneNumber: Scalars['String'];
};

export type RequestConfirmRfqTransactionInput = {
  rfqId?: Maybe<Scalars['String']>;
};

export type RequestDownloadPermissionInput = {
  /** 그룹ID */
  groupId: Scalars['String'];
  connectFileId?: Maybe<Scalars['String']>;
};

/** 이메일 인증 코드 */
export type RequestEmailVerificationCodeInput = {
  /** 이메일 */
  email: Scalars['String'];
  /** 캐파 서비스 타입 */
  service: CapaServiceType;
};

export type RequestFileInput = {
  /** 파일 id */
  fileId: Scalars['String'];
};

export type RequestPaymentAgent = {
  mid?: Maybe<Scalars['String']>;
  settlmntDt?: Maybe<Scalars['String']>;
  subId?: Maybe<Scalars['String']>;
  settlmntAmt?: Maybe<Scalars['Float']>;
  dupChkYn?: Maybe<Scalars['String']>;
  accountDesc?: Maybe<Scalars['String']>;
  /** transaction id */
  transactionId: Scalars['String'];
};

export type RequestPaymentAgentInput = {
  data?: Maybe<Array<RequestPaymentAgent>>;
};

/** 캐파 추천인 코드 발행 */
export type RequestReferralInput = {
  /** 캐파 서비스 타입 */
  service: CapaServiceType;
};

export type RequestRefundInput = {
  /** 환불 사유 */
  reason: Scalars['String'];
  /** 환불 금액 */
  amount?: Maybe<Scalars['Float']>;
};

export type RequestRegisterTransactionReviewInput = {
  rfqId: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
};

/** 비밀번호 재설정 */
export type RequestResetPasswordInput = {
  /** 이메일 */
  email: Scalars['String'];
  service?: Maybe<CapaServiceType>;
};

export type RequestRfqDrawingFileDownloadInput = {
  rfqId?: Maybe<Scalars['String']>;
};

export type RequestRfqQuotationPdfInput = {
  /** 견적서 id */
  quotationId: Scalars['String'];
};

export type ReviewRfqInput = {
  rfqId: Scalars['String'];
  quality: Scalars['Float'];
  dueDate: Scalars['Float'];
  publicComment?: Maybe<Scalars['String']>;
  privateComment?: Maybe<Scalars['String']>;
};

export type RfqDrawingFileInput = {
  fileId: Scalars['String'];
  fileName: Scalars['String'];
  quantity: Scalars['Float'];
  material: CapaQuotationMaterial;
  detailedMaterial?: Maybe<CapaQuotationDetailedMaterialType>;
  etc?: Maybe<Scalars['String']>;
  recommendation?: Maybe<Array<CapaQuotationRecommendation>>;
};

export type RfqOptionalServiceInput = {
  serviceType: CapaQuotationServiceType;
  quantity?: Maybe<Scalars['Float']>;
};

/** pcb 조립을 위한 파일 */
export type RfqPcbAssemblyFileInput = {
  fileId: Scalars['String'];
  fileName: Scalars['String'];
  quantity: Scalars['Float'];
};

/** pcb 제작을 위한 파일 */
export type RfqPcbFabricationFileInput = {
  fileId: Scalars['String'];
  fileName: Scalars['String'];
  quantity: Scalars['Float'];
  thickness: Scalars['Float'];
};

export type RfqQuotationOptionalServiceInput = {
  serviceType: CapaQuotationServiceType;
};

export type RfqReferenceFileInput = {
  fileId: Scalars['String'];
  fileName: Scalars['String'];
};

export type RfqTransactionReviewFileInput = {
  fileId: Scalars['String'];
  fileName: Scalars['String'];
};

export type SavePayment = {
  __typename?: 'SavePayment';
  id: Scalars['String'];
};

export type SaveRfqCapabilitiesInput = {
  serviceType: CapaQuotationServiceType;
  status: CapaCapabilitiesStatusType;
  /** capabilities */
  capabilities: CapaQuotationCapabilityInput;
};

export type SaveRfqFilterInput = {
  filter?: Maybe<CapaQuotationRfqFilterInput>;
};

export type SearchConnectFileInput = {
  pathId?: Maybe<Scalars['String']>;
  keyword: Scalars['String'];
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};

export type SearchConnectGroupsInput = {
  groupName: Scalars['String'];
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};

export type SearchConnectUsersInput = {
  keyword: Scalars['String'];
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};

export type SendAnnotationCommentToChatInput = {
  commentId: Scalars['String'];
};

export type SendConnectChatMessageInput = {
  chatRoomId?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  /** 파트너 파일 업로드 */
  fileIds?: Maybe<Array<Scalars['String']>>;
};

export type SendConnectChatMessageToCapaInput = {
  chatRoomId?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  /** 파트너 파일 업로드 */
  fileIds?: Maybe<Array<Scalars['String']>>;
};

export type SendQuotationChatMessageInput = {
  chatRoomId?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  /** 파트너 파일 업로드 정보 입력 */
  files?: Maybe<Array<ChatFileUploadInput>>;
  messageType?: Maybe<CapaQuotationChatMessageType>;
};

export type SendSystemChatMessageInput = {
  chatRoomId?: Maybe<Scalars['String']>;
  messageType?: Maybe<CapaQuotationChatMessageType>;
};

export type ShareConnectFilesInput = {
  /** 그룹ID */
  groupId: Scalars['String'];
  /** fileIds */
  connectFileIds: Array<Scalars['String']>;
  permission?: Maybe<CapaQuotationRfqAllowOpenFileStatus>;
};

/** 배송 정보 */
export type ShippingDataModel = {
  __typename?: 'ShippingDataModel';
  /** 배송등록 예정 건 */
  id: Scalars['String'];
  /** 배송업체 */
  shippingCompany?: Maybe<Scalars['String']>;
  /** 송장번호 */
  invoiceNumber?: Maybe<Scalars['String']>;
  /** 지급승인 연장 여부 */
  isExtended: Scalars['Boolean'];
  /** 지급일 연장 사유 */
  extendReason?: Maybe<Scalars['String']>;
  /** 배송등록 여부 */
  isRegistered: Scalars['Boolean'];
  /** 배송등록 예정일(YYYYMMDD) 00:00 + 6영업일 고로 자동 구매확정일을 의미 */
  registerExpectedDate: Scalars['String'];
  /** 구매확정일 연장 10영업일 + 6영업일 */
  extendedPurchaseConfirmDate: Scalars['String'];
};

export type StibeeCapaUser = {
  __typename?: 'StibeeCAPAUser';
  email: Scalars['String'];
  clientManagerName: Scalars['String'];
  partnerManagerName: Scalars['String'];
  connectManagerName: Scalars['String'];
  clientOrganizationName: Scalars['String'];
  partnerOrganizationName: Scalars['String'];
  connectOrganizationName: Scalars['String'];
};

export type SubmallDataModel = {
  __typename?: 'SubmallDataModel';
  /** id */
  id: Scalars['ID'];
  /** quotation partner id */
  partnerId: Scalars['String'];
  /** 서브몰 id */
  submallId: Scalars['String'];
  /** 서브몰 이름 */
  submallName: Scalars['String'];
  /** 서브몰 사업자 등록 번호, 수정불가 */
  submallRegistrationNumber: Scalars['String'];
  /** 계좌은행코드 */
  bankCode: CapaBankCode;
  /** 계좌번호(`-`제외) */
  accountNumber: Scalars['String'];
  /** 예금주 */
  accountHolder: Scalars['String'];
  status: CapaSubmallStatus;
  managerName: Scalars['String'];
  approvedAt?: Maybe<Scalars['String']>;
  rejectedAt?: Maybe<Scalars['String']>;
  rejectedReason?: Maybe<CapaSubmallRejectedReason>;
  bankBookFile?: Maybe<BankBookFile>;
};

export type SubmallDataModelOutput = {
  __typename?: 'SubmallDataModelOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<SubmallDataModel>;
};

export enum SubmallRequestType {
  /** 기존 서브몰 수정 */
  Update = 'UPDATE',
  /** 신규 서브몰 등록 */
  Create = 'CREATE'
}

export type SubscribeCapaNewsInput = {
  /** 구독 대상 이메일 */
  subscriberEmail: Scalars['String'];
  /** 사용자 이름 */
  userName: Scalars['String'];
};

export type SubscribeConnectChatRoomInput = {
  chatRoomId: Scalars['String'];
};

export type SubscribeConnectUserFileInput = {
  /** fileId */
  fileId: Scalars['String'];
};

export type SubscribeFileConversionStatusInput = {
  fileId: Scalars['String'];
};

export type SubscribeNotificationInput = {
  serviceType: CapaServiceType;
};

export type SubscribeQuotationChatRoomInput = {
  chatRoomId: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  subscribeQuotationChatMessage: CapaQuotationChatMessagesOutput;
  subscribeQuotationChatRoom: CapaQuotationChatRoomOutput;
  subscribeQuotationUpdateData: CapaQuotationUpdateOutput;
  subscribeFileStatusUpdate: CapaFileStatusOutput;
  subscribeFileConversionStatus: CapaFileStatusOutput;
  subscribeConnectChatMessages: CapaConnectChatMessagesOutput;
  subscribeConnectUpdateData: CapaConnectUpdateOutput;
  subscribeConnectOnlineUsersData: CapaConnectOnlineUsersOutput;
  subscribeConnectUserFileStatus: CapaConnectUserFileOutput;
  subscribeCAPANotification: CapaSubscribeNotificationsOutput;
};

export type SubscriptionSubscribeQuotationChatMessageArgs = {
  input: SubscribeQuotationChatRoomInput;
};

export type SubscriptionSubscribeQuotationChatRoomArgs = {
  input: SubscribeQuotationChatRoomInput;
};

export type SubscriptionSubscribeQuotationUpdateDataArgs = {
  input: SubscribeQuotationChatRoomInput;
};

export type SubscriptionSubscribeFileConversionStatusArgs = {
  input: SubscribeFileConversionStatusInput;
};

export type SubscriptionSubscribeConnectChatMessagesArgs = {
  input: SubscribeConnectChatRoomInput;
};

export type SubscriptionSubscribeConnectUserFileStatusArgs = {
  input: SubscribeConnectUserFileInput;
};

export type SubscriptionSubscribeCapaNotificationArgs = {
  input: SubscribeNotificationInput;
};

/** 서비스 제안 */
export type SuggestServiceInput = {
  /** 이메일 */
  email?: Maybe<Scalars['String']>;
  /** 제안 */
  suggestion?: Maybe<Scalars['String']>;
  userType?: Maybe<Scalars['String']>;
};

/** 토큰 갱신 */
export type TokenRefreshInput = {
  /** refresh 토큰 */
  refreshToken: Scalars['String'];
};

export type UnshareConnectFilesInput = {
  /** 그룹ID */
  groupId: Scalars['String'];
  groupName?: Maybe<Scalars['String']>;
  chatRoomId?: Maybe<Scalars['String']>;
  /** fileIds */
  connectFileIds?: Maybe<Array<Scalars['String']>>;
  /** messageIds */
  messageIds?: Maybe<Array<Scalars['String']>>;
  removeAllMyFile?: Maybe<Scalars['Boolean']>;
};

/** BO 엠버서더 정보 수정 */
export type UpdateAmbassadorBoInput = {
  /** 수정 대상 엠베서더 id */
  id: Scalars['String'];
  /** 엠베서더 회사명 */
  organizationName?: Maybe<Scalars['String']>;
  /** 비밀번호 */
  password?: Maybe<Scalars['String']>;
  /** 담당자명 */
  managerName?: Maybe<Scalars['String']>;
  /** 담당자 이메일 */
  email?: Maybe<Scalars['String']>;
  /** 담당자 휴대폰 번호 */
  cellphoneNumber?: Maybe<Scalars['String']>;
  /** 사업자 등록번호 */
  companyRegistrationNumber?: Maybe<Scalars['String']>;
  /** 사업자 등록증 파일 id */
  einFileId?: Maybe<Scalars['String']>;
};

export type UpdateAmbassadorSignUpPageViewCountByIpInput = {
  ambassadorId: Scalars['String'];
  userType: CapaQuotationUserType;
};

export type UpdateBoTestConnectGroupInput = {
  groupId: Scalars['String'];
  /** 테스트 그룹 True/False */
  isTest: Scalars['Boolean'];
};

export type UpdateBoTestUserInput = {
  /** 이메일 */
  email: Scalars['String'];
  /** 테스트 유저 True/False */
  isTest: Scalars['Boolean'];
};

/** BO 회원정보 수정 */
export type UpdateBackOfficeUserInput = {
  /** 수정 대상 회원 id */
  id: Scalars['String'];
  /** 수정 대상 회원 이메일 */
  email: Scalars['String'];
  /** 역할 */
  role?: Maybe<BackOfficeAdminRole>;
  /** 이름 */
  name?: Maybe<Scalars['String']>;
  /** 영어닉네임 */
  nickName?: Maybe<Scalars['String']>;
  /** 부서 */
  dept?: Maybe<BackOfficeUserDeptType>;
  /** 직무 */
  task?: Maybe<Scalars['String']>;
};

export type UpdateCapaQuotationPartnerStrategy = {
  __typename?: 'UpdateCAPAQuotationPartnerStrategy';
  id: Scalars['ID'];
  email: Scalars['String'];
  status: CapaQuotationPartnerStatus;
  capabilities?: Maybe<CapaQuotationPartnerCapability>;
  organizationName?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  shortAddress?: Maybe<Scalars['String']>;
  detailAddress?: Maybe<Scalars['String']>;
  companyRegistrationNumber: Scalars['String'];
  companyIntroduction?: Maybe<Scalars['String']>;
  foundedIn?: Maybe<Scalars['String']>;
  numberEmployees?: Maybe<Scalars['String']>;
  serviceHours?: Maybe<Scalars['String']>;
  managerName: Scalars['String'];
  department?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  contact?: Maybe<Scalars['String']>;
  notificationFilter: CapaQuotationPartnerNotificationFilter;
  rfqFilter?: Maybe<Scalars['String']>;
  /** [필수] 캐파 서비스 이용약관 동의 */
  serviceAgreed?: Maybe<Scalars['Boolean']>;
  /** [필수] 개인정보 수집 및 활용 동의 */
  privacyAgreed?: Maybe<Scalars['Boolean']>;
  /** [필수] 결제 서비스 이용약관 동의 */
  paymentAgreed?: Maybe<Scalars['Boolean']>;
  /** [선택] 프로모션 정보 수신 동의 */
  promotionAgreed?: Maybe<Scalars['Boolean']>;
  /** 추천인 ID */
  referralUserId?: Maybe<Scalars['String']>;
  /** ITP 대상 여부 */
  isItp: Scalars['Boolean'];
  /** 생성일 */
  createdAt: Scalars['String'];
  /** 수정일 */
  updatedAt: Scalars['String'];
  /** 파트너 업로드 파일 ex) 사진 및 동영상 관련 */
  logo?: Maybe<CapaQuotationPartnerStrategyFile>;
  /** 커버 이미지 */
  cover?: Maybe<CapaQuotationPartnerStrategyFile>;
  /** 포트폴리오 */
  portfolios?: Maybe<Array<CapaQuotationPartnerPortfolio>>;
  /** 장비 */
  equipments?: Maybe<Array<CapaQuotationPartnerEquipment>>;
  /** 파트너 업로드 파일 ex) 사진 및 동영상 관련 */
  partnerFiles?: Maybe<Array<CapaQuotationPartnerStrategyFile>>;
  /** 인증 관련 정보 */
  certifications?: Maybe<Array<CapaQuotationPartnerTechnologyModel>>;
  /** 특허 관련 정보 */
  patents?: Maybe<Array<CapaQuotationPartnerTechnologyModel>>;
  /** 공장 주소 */
  factoryAddress?: Maybe<Scalars['String']>;
  factoryDetailAddress?: Maybe<Scalars['String']>;
  partnerPageViewCount: Scalars['Float'];
  transactionCount: Scalars['Float'];
  /** 캐파 블로그 및 홍보 관련 */
  promotionModels?: Maybe<Array<CapaPromotionModel>>;
  /** 평균 응답 시간 */
  averageResponseTime?: Maybe<Scalars['String']>;
  /** 사업자 등록증 자료 */
  registrations: Array<CapaQuotationPartnerStrategyFile>;
};

export type UpdateCapaQuotationPartnerStrategyOutput = {
  __typename?: 'UpdateCAPAQuotationPartnerStrategyOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<UpdateCapaQuotationPartnerStrategy>;
};

/** 약관 동의하지 않은 유저를 위한 약관 동의 api */
export type UpdateCapaUserTermsInput = {
  /** [필수] 캐파 서비스 이용약관 동의 */
  serviceAgree: Scalars['Boolean'];
  /** [필수] 개인정보 수집 및 활용 동의 */
  privacyAgree: Scalars['Boolean'];
  /** [선택] 프로모션 정보 수신 동의 */
  promotionAgree?: Maybe<Scalars['Boolean']>;
  /** [필수] 결제 서비스 약관 동의 */
  paymentAgree?: Maybe<Scalars['Boolean']>;
};

export type UpdateConnectAnnotationCommentInput = {
  groupId: Scalars['String'];
  connectSharedFileId: Scalars['String'];
  annotationId: Scalars['String'];
  commentId: Scalars['String'];
  comment: Scalars['String'];
  edited: Scalars['Boolean'];
};

export type UpdateConnectAnnotationInput = {
  groupId: Scalars['String'];
  connectSharedFileId: Scalars['String'];
  annotationId: Scalars['String'];
  anchorPos?: Maybe<Capa3DPosInput>;
  handlePos?: Maybe<Capa3DPosInput>;
  nodeId?: Maybe<Scalars['Float']>;
};

export type UpdateConnectUserFileLink = {
  __typename?: 'UpdateConnectUserFileLink';
  shareLink: Scalars['String'];
  linkStatus: ConnectUserFileLinkStatus;
  filePermission: ConnectUserFileStatus;
};

/** 내 정보 */
export type UpdateConnectUserFileLinkInput = {
  /** fileIds */
  connectFileIds: Array<Scalars['String']>;
  linkStatus: ConnectUserFileLinkStatus;
};

export type UpdateConnectUserFileLinkOutput = {
  __typename?: 'UpdateConnectUserFileLinkOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<UpdateConnectUserFileLink>;
};

/** 내 정보 */
export type UpdateConnectUserFilePermissionInput = {
  /** fileIds */
  connectFileIds: Array<Scalars['String']>;
  permission: ConnectUserFileStatus;
};

/** 내 정보 */
export type UpdateConnectUserInput = {
  /** 이름 (username) */
  username?: Maybe<Scalars['String']>;
  /** language */
  userLanguage?: Maybe<Language>;
  /** notification filter */
  notificationFilterType?: Maybe<ConnectNotificationType>;
  notificationFilterValue?: Maybe<Scalars['Boolean']>;
};

export type UpdateFriendsSettlementAccountStatusInput = {
  friendsId: Scalars['String'];
  /** 승인 1 / 거절 4 */
  statusTypeId: Scalars['Float'];
};

export type UpdateFriendsStatusInput = {
  friendsId: Scalars['String'];
  /** 1 승인 또는 탈퇴거절, 4 승인거절, 6 탈퇴승인 */
  statusTypeId: Scalars['Float'];
  /** 탈퇴 승인시 (statusTypeId = 6) 필수값 */
  boUserId?: Maybe<Scalars['String']>;
  /** 승인 거절 시 (statusTypeId = 4) 필수값 */
  rejectReason?: Maybe<Scalars['String']>;
};

export type UpdateIdentityVerificationInput = {
  impUid: Scalars['String'];
};

export type UpdateNoticeScheduleInput = {
  id: Scalars['String'];
  /** 점검 시작 시간 */
  startTime: Scalars['String'];
  /** 점검 종료 시간 */
  endTime: Scalars['String'];
  /** 사전점검 시작 시간 */
  previewStartTime?: Maybe<Scalars['String']>;
  /** 사전점검 종료 시간 */
  previewEndTime?: Maybe<Scalars['String']>;
  /** client partner connect 인지 */
  pageType: NoticeScheduleNoticePageType;
  /** 긴급점검 / 임시점검 / 사전점검 등 */
  noticeType: NoticeScheduleNoticeType;
  /** 시작예정 / 시작중 / 종료 */
  status?: Maybe<NoticeScheduleNoticeStatus>;
};

/** BO 관리자 권한 발주서 상태 변경 (계속진행통보, 취소/환불 승인 ... */
export type UpdatePurchaseOrderStatusBoInput = {
  purchaseOrderId: Scalars['String'];
  reqType: CapaBackOfficeUpdatePurchaseOrderStatusReqType;
};

export type UpdatePurchaseOrderStatusInput = {
  /** 카파 서비스 타입 */
  service: CapaServiceType;
  /** purchase order id */
  purchaseOrderId: Scalars['String'];
  /** 변경할 발주서 status (to-be) */
  statusToChange: CapaPurchaseOrderStatus;
  /** 배송지 등록 */
  registerExpectedDeliveryDate?: Maybe<RegisterExpectedDeliveryDateInput>;
  /** 환불 사유 및 환불 금액 */
  requestRefund?: Maybe<RequestRefundInput>;
  /** 나이스페이 모달에서 결제할때 입력하는 인증번호 (휴대폰번호 or 사업자번호) -> BuyerAuthNum */
  escrowVerifiedPW?: Maybe<Scalars['String']>;
  /** BO 관리자 여부 */
  isAdmin?: Maybe<Scalars['Boolean']>;
};

export type UpdateQuotationAnnotationCommentInput = {
  annotationId: Scalars['String'];
  annotationCommentId: Scalars['String'];
  comment: Scalars['String'];
};

export type UpdateQuotationAnnotationInput = {
  annotationId: Scalars['String'];
  anchorPos?: Maybe<Capa3DPosInput>;
  handlePos?: Maybe<Capa3DPosInput>;
};

export type UpdateQuotationPartnerBoStrategyInput = {
  /** 이메일 */
  email?: Maybe<Scalars['String']>;
  /** 비밀번호 */
  password?: Maybe<Scalars['String']>;
  /** 유저 상태 */
  status: CapaUserStatus;
  /** CAPA 서비스를 어떻게 알게 되셨나요? (복수 선택 가능) */
  inflowRoute?: Maybe<Array<CapaInflowRoute>>;
  /** [선택] 프로모션 정보 수신 동의 */
  promotionAgree?: Maybe<Scalars['Boolean']>;
  /** 파트너 상태 */
  partnerStatus: CapaQuotationPartnerStatus;
  /** 파트너 캐파빌리티 jsonb array of { service_type, process_method, material, detailed_material, etc } */
  capabilities?: Maybe<CapaQuotationCapabilityInput>;
  /** 회사명 */
  organizationName?: Maybe<Scalars['String']>;
  /** 주소 */
  fullAddress?: Maybe<Scalars['String']>;
  /** 구 까지만 보여주는 주소 */
  shortAddress?: Maybe<Scalars['String']>;
  /** 사업자 등록번호 */
  companyRegistrationNumber?: Maybe<Scalars['String']>;
  /** 회사소개 */
  companyIntroduction?: Maybe<Scalars['String']>;
  /** 설립년도 */
  foundedIn?: Maybe<Scalars['String']>;
  /** 규모 (직원수) */
  numberEmployees?: Maybe<Scalars['String']>;
  /** 장비 */
  equipment?: Maybe<Scalars['String']>;
  /** 대응 시간 */
  serviceHours?: Maybe<Scalars['String']>;
  /** 웹사이트 */
  website?: Maybe<Scalars['String']>;
  /** 담당자명 */
  managerName?: Maybe<Scalars['String']>;
  /** 부서 */
  department?: Maybe<Scalars['String']>;
  /** 직위 */
  position?: Maybe<Scalars['String']>;
  /** 연락처 */
  contact?: Maybe<Scalars['String']>;
  /** 연락처 */
  notificationFilter?: Maybe<CapaQuotationPartnerNotificationFilterInput>;
  /** 파트너 파일 업로드 정보 입력 */
  files?: Maybe<Array<QuotationPartnerFileUploadInput>>;
  /** 파트너 기술관련 파일 업로드 정보 입력 */
  strategyFiles?: Maybe<Array<CapaQuotationPartnerStrategyFileUploadInput>>;
  /** 공장 주소 */
  factoryAddress?: Maybe<Scalars['String']>;
  /** 파트너 홍보 관련 유튜브 영상 링크 목록 */
  promotionLinks?: Maybe<Array<CapaPromotionLinkInput>>;
};

export type UpdateQuotationPartnerStatusInput = {
  quotationPartnerId: Scalars['String'];
  status: CapaQuotationPartnerStatus;
  rejectType?: Maybe<CapaQuotationPartnerRejectType>;
  rejectReason?: Maybe<Scalars['String']>;
};

export type UpdateQuotationPartnerStrategyInput = {
  /** 파트너 캐파빌리티 jsonb array of { service_type, process_method, material, detailed_material, etc } */
  capabilities?: Maybe<CapaQuotationCapabilityInput>;
  /** 회사명 */
  organizationName?: Maybe<Scalars['String']>;
  /** 주소 */
  fullAddress?: Maybe<Scalars['String']>;
  /** 구 까지만 보여주는 주소 */
  shortAddress?: Maybe<Scalars['String']>;
  /** detail address */
  detailAddress?: Maybe<Scalars['String']>;
  /** 사업자 등록번호 */
  companyRegistrationNumber?: Maybe<Scalars['String']>;
  /** 회사소개 */
  companyIntroduction?: Maybe<Scalars['String']>;
  /** 설립년도 */
  foundedIn?: Maybe<Scalars['String']>;
  /** 규모 (직원수) */
  numberEmployees?: Maybe<Scalars['String']>;
  /** 대응 시간 */
  serviceHours?: Maybe<Scalars['String']>;
  /** 담당자명 */
  managerName?: Maybe<Scalars['String']>;
  /** 부서 */
  department?: Maybe<Scalars['String']>;
  /** 직위 */
  position?: Maybe<Scalars['String']>;
  /** 연락처 */
  contact?: Maybe<Scalars['String']>;
  /** rfqFilter */
  rfqFilter?: Maybe<CapaQuotationCapabilityInput>;
  /** notificationFilter */
  notificationFilter?: Maybe<CapaQuotationPartnerNotificationFilterInput>;
  /** 파트너 로고 파일 */
  logo?: Maybe<CapaQuotationPartnerStrategyRegistrationFileUploadInput>;
  /** 포트폴리오 */
  portfolios?: Maybe<Array<RegisterQuotationPartnerPortfolioInput>>;
  /** 사진 및 동영상 */
  partnerFiles?: Maybe<Array<CapaQuotationPartnerStrategyFileUploadInput>>;
  /** 사업자 등록증 */
  registrations?: Maybe<Array<CapaQuotationPartnerStrategyFileUploadInput>>;
  /** 장비 */
  equipments?: Maybe<Array<RegisterQuotationPartnerEquipmentInput>>;
  /** 인증 기술 관련 정보 목록 */
  certificationFiles?: Maybe<Array<CapaQuotationPartnerTechnologyInput>>;
  /** 특허 기술 관련 정보 목록 */
  patentFiles?: Maybe<Array<CapaQuotationPartnerTechnologyInput>>;
  /** 커버 이미지 */
  coverFile?: Maybe<CapaQuotationPartnerStrategyFileUploadInput>;
  /** [필수] 캐파 서비스 이용약관 동의 */
  serviceAgree?: Maybe<Scalars['Boolean']>;
  /** [필수] 개인정보 수집 및 활용 동의 */
  privacyAgree?: Maybe<Scalars['Boolean']>;
  /** [필수] 결제 서비스 이용약관 */
  paymentAgree?: Maybe<Scalars['Boolean']>;
  /** [선택] 프로모션 정보 수신 동의 */
  promotionAgree?: Maybe<Scalars['Boolean']>;
  /** 공장 위치 */
  factoryAddress?: Maybe<Scalars['String']>;
  /** factory detail address */
  factoryDetailAddress?: Maybe<Scalars['String']>;
};

/** 내 정보 */
export type UpdateQuotationUserBoInput = {
  /** 이메일 */
  email?: Maybe<Scalars['String']>;
  /** 비밀번호 */
  password?: Maybe<Scalars['String']>;
  /** 유저 상태 */
  status: CapaUserStatus;
  /** CAPA 서비스를 어떻게 알게 되셨나요? (복수 선택 가능) */
  inflowRoute?: Maybe<Array<CapaInflowRoute>>;
  /** [선택] 프로모션 정보 수신 동의 */
  promotionAgree?: Maybe<Scalars['Boolean']>;
  /** 유저 상태 */
  userStatus: CapaQuotationUserStatus;
  /** 이름 (담당자명) */
  managerName?: Maybe<Scalars['String']>;
  /** 휴대폰 번호 */
  cellphoneNumber?: Maybe<Scalars['String']>;
  /** 주소 */
  fullAddress?: Maybe<Scalars['String']>;
  /** 짧은 주소 */
  shortAddress?: Maybe<Scalars['String']>;
  /** 사업자등록번호 */
  companyRegistrationNumber?: Maybe<Scalars['String']>;
  /** 사업자 여부 */
  bizType?: Maybe<CapaQuotationUserBizType>;
  /** 회사 / 단체명 */
  organizationName?: Maybe<Scalars['String']>;
  /** 회사소개 */
  companyIntroduction?: Maybe<Scalars['String']>;
  /** 웹사이트 */
  website?: Maybe<Scalars['String']>;
  /** 부서 */
  department?: Maybe<Scalars['String']>;
  /** 직함 */
  position?: Maybe<Scalars['String']>;
  /** 프로필 사진 설정 */
  files?: Maybe<Array<QuotationUserFileUploadInput>>;
};

/** 내 정보 */
export type UpdateQuotationUserInput = {
  /** 캐파 소식 및 혜택 수신동의 */
  emailreceive?: Maybe<Scalars['Boolean']>;
  /** 이름 (담당자명) */
  managerName?: Maybe<Scalars['String']>;
  /** 휴대폰 번호 */
  cellphoneNumber?: Maybe<Scalars['String']>;
  /** 주소 */
  fullAddress?: Maybe<Scalars['String']>;
  /** 짧은 주소 */
  shortAddress?: Maybe<Scalars['String']>;
  /** 사업자등록번호 */
  companyRegistrationNumber?: Maybe<Scalars['String']>;
  /** 사업자 여부 */
  bizType?: Maybe<CapaQuotationUserBizType>;
  /** 회사 / 단체명 */
  organizationName?: Maybe<Scalars['String']>;
  /** 회사소개 */
  companyIntroduction?: Maybe<Scalars['String']>;
  /** 웹사이트 */
  website?: Maybe<Scalars['String']>;
  /** 부서 */
  department?: Maybe<Scalars['String']>;
  /** 직함 */
  position?: Maybe<Scalars['String']>;
  /** 프로필 사진 설정 */
  files?: Maybe<Array<QuotationUserFileUploadInput>>;
  /** [필수] 캐파 서비스 이용약관 동의 */
  serviceAgree?: Maybe<Scalars['Boolean']>;
  /** [필수] 개인정보 수집 및 활용 동의 */
  privacyAgree?: Maybe<Scalars['Boolean']>;
  /** [필수] 결제 서비스 이용약관 동의 */
  paymentAgree?: Maybe<Scalars['Boolean']>;
  /** [선택] 프로모션 정보 수신 동의 */
  promotionAgree?: Maybe<Scalars['Boolean']>;
  /** 고객 알림 설정 */
  notificationFilter?: Maybe<CapaQuotationUserNotificationFilterInput>;
};

export type UpdateReceiveComplaintQuotationChatMessageInput = {
  /** 바이패스 의심되는 chat message id */
  chatMessageId?: Maybe<Scalars['String']>;
  /** 유저 불편 사항 */
  complaint?: Maybe<Scalars['String']>;
  /** 유저 불편 사항 타입 */
  complaintType?: Maybe<CapaBypassComplaintType>;
};

export type UpdateReopenRfqTransactionInput = {
  /** purchase order id */
  purchaseOrderId: Scalars['String'];
};

export type UpdateRfqReviewInput = {
  /** review id */
  reviewId: Scalars['String'];
  /** 리뷰 타입 */
  rfqReviewType?: Maybe<CapaRfqReviewType>;
  /** transaction satisfaction comment */
  transactionSatisfactionPoint?: Maybe<Scalars['Float']>;
  /** transaction satisfaction comment */
  transactionSatisfactionComment?: Maybe<Scalars['String']>;
  reviewFiles?: Maybe<Array<RfqTransactionReviewFileInput>>;
  /** 납기일 준수 여부 */
  inDueDate?: Maybe<Scalars['Boolean']>;
};

export type UpdateRfqStatusInput = {
  rfqId: Scalars['String'];
  rfqStatus: CapaRfqStatus;
};

/** BO 서브몰 상태 변경 */
export type UpdateSubmallStatusBoInput = {
  /** fk CAPAQuotationPartnerEntity#id */
  quotationPartnerId: Scalars['String'];
  /** 변경할 서브몰 status (to-be) */
  statusToChange: CapaSubmallStatus;
  /** BO 서브몰 승인 거절 */
  rejectSubmall?: Maybe<RejectSubmallInput>;
};

export type UserActivity = {
  __typename?: 'UserActivity';
  /** rfq 수 */
  rfqCnt: Scalars['Float'];
  /** chat 수 */
  chatCnt: Scalars['Float'];
  /** purchase order 수 */
  purchaseOrderCnt: Scalars['Float'];
  /** 정산 완료 금액 */
  totalAmount: Scalars['Float'];
};

/** 유저 활동 로그 */
export type UserActivityLogInput = {
  /** 이메일 */
  email?: Maybe<Scalars['String']>;
  /** 활동 타입 */
  activityType?: Maybe<CapaActivityType>;
  /** 활동 id */
  activitiId?: Maybe<Scalars['String']>;
  /** 아이피 */
  ip?: Maybe<Scalars['String']>;
  /** 운영체제 */
  os?: Maybe<Scalars['String']>;
  /** 기기 종류 */
  deviceType?: Maybe<Scalars['String']>;
  /** 브라우저 종류 */
  browser?: Maybe<Scalars['String']>;
  /** 캐파 서비스 타입 */
  service?: Maybe<CapaServiceType>;
};

export type VentureBizFetchIcxInput = {
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  ventureBizSearchType?: Maybe<VentureBizSearchType>;
};

/** VentureBizSearchType */
export enum VentureBizSearchType {
  /** 기업명 */
  CompanyName = 'COMPANY_NAME',
  /** 전화번호 */
  Contact = 'CONTACT',
  /** 관련업종 */
  RelatedIndustry = 'RELATED_INDUSTRY',
  /** 업종 1 */
  IndustryName = 'INDUSTRY_NAME',
  /** 지역 */
  Region = 'REGION'
}

/** 이메일 인증 */
export type VerifyCellPhoneVerificationCodeInput = {
  /** 휴대폰 번호 */
  cellphoneNumber: Scalars['String'];
  /** 인증번호 */
  code: Scalars['String'];
};

/** 사업자 등록 번호 확인 */
export type VerifyCompanyRegistrationNumberInput = {
  /** 사업자 등록 번호 */
  companyRegistrationNumber: Scalars['String'];
};

/** 이메일 인증 */
export type VerifyEmailVerificationCodeInput = {
  /** 이메일 */
  email: Scalars['String'];
  /** 인증번호 */
  code: Scalars['String'];
};

export type VirtualAccountInfo = {
  __typename?: 'VirtualAccountInfo';
  accountNumber: Scalars['String'];
  bankCode: CapaBankCode;
  bankName: Scalars['String'];
  bankHolder?: Maybe<Scalars['String']>;
  dueDate: Scalars['Float'];
  issuedAt: Scalars['Float'];
};

/** BO 회원 탈퇴 */
export type WithdrawBackOfficeUserInput = {
  /** 탈퇴 대상 회원 id */
  id: Scalars['String'];
  /** 탈퇴 대상 회원 이메일 */
  email: Scalars['String'];
};

/** 고객 탈퇴 사유 */
export type WithdrawClientReasonInput = {
  /** serviceType */
  serviceType: CapaServiceType;
  /** 고객탈퇴사유 */
  reason: CapaWithdrawClientReasonType;
  /** 탈퇴 상세 사유 */
  detailedReason?: Maybe<Scalars['String']>;
  /** 기타 탈퇴 사유 */
  etcReason?: Maybe<Scalars['String']>;
};

/** 파트너 탈퇴 사유 */
export type WithdrawPartnerReasonInput = {
  /** serviceType */
  serviceType: CapaServiceType;
  /** 파트너 탈퇴 사유 */
  reason: CapaWithdrawPartnerReasonType;
  /** 탈퇴 상세 사유 */
  detailedReason?: Maybe<Scalars['String']>;
  /** 기타 탈퇴 사유 */
  etcReason?: Maybe<Scalars['String']>;
};

export type WithdrawQuotationPartnerBoInput = {
  /** 이메일 */
  email: Scalars['String'];
  /** 탈퇴 사유 */
  withdrawReason: WithdrawPartnerReasonInput;
};

/** 파트너 회원 탈퇴 */
export type WithdrawQuotationPartnerInput = {
  /** 탈퇴하고자 하는 파트너의 이메일 */
  email: Scalars['String'];
  /** 탈퇴 사유 */
  withdrawReason: WithdrawPartnerReasonInput;
};

/** 강제 탈퇴 */
export type WithdrawQuotationUserBoInput = {
  /** 이메일 */
  email: Scalars['String'];
  /** 탈퇴 사유 */
  withdrawReason: WithdrawClientReasonInput;
};

/** 고객 회원 탈퇴 */
export type WithdrawQuotationUserInput = {
  /** 탈퇴하고자 하는 고객의 이메일 */
  email: Scalars['String'];
  /** 탈퇴 사유 */
  withdrawReason: WithdrawClientReasonInput;
};

export type FetchCapacxManagementRequiredUsersBo = {
  __typename?: 'fetchCAPACXManagementRequiredUsersBO';
  quotationUserId: Scalars['String'];
  /** 고객명 */
  managerName?: Maybe<Scalars['String']>;
  /** 회사명 */
  organizationName?: Maybe<Scalars['String']>;
  /** 고객유형 */
  bizType?: Maybe<CapaQuotationUserBizType>;
  /** 이메일 */
  email?: Maybe<Scalars['String']>;
  /** 휴대전화 */
  cellphoneNumber?: Maybe<Scalars['String']>;
  /** rfq id */
  rfqId?: Maybe<Scalars['String']>;
  /** 견적요청일 */
  createdAt?: Maybe<Scalars['String']>;
  /** 서비스타입 */
  serviceType?: Maybe<CapaQuotationServiceType>;
  /** 프로젝트명 */
  projectName?: Maybe<Scalars['String']>;
  /** 총 수량 */
  totalQuantity?: Maybe<Scalars['Float']>;
  /** RFQ 상태 */
  status?: Maybe<CapaRfqStatus>;
  /** 조회수 */
  viewCount?: Maybe<Scalars['Float']>;
  /** 조회파트너수 */
  viewPartnerCount?: Maybe<Scalars['Float']>;
  /** 코멘트수 */
  commentCount?: Maybe<Scalars['Float']>;
};

export type FetchQuotationPartnerProjectParticipationStatusBoInput = {
  quotationPartnerId?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};

/** readRfqAdjustRequestInput */
export type ReadRfqAdjustRequestInput = {
  email?: Maybe<Scalars['String']>;
  /** 업데이트할 adjustRequest Id */
  id: Scalars['String'];
  type: CapaRfqAdjustRequestType;
};

export type SendQuotationPartnerIntroductionInput = {
  rfqId: Scalars['String'];
  partnerId?: Maybe<Scalars['String']>;
};

export type WithdrawQuotationUserMutationVariables = Exact<{
  input: WithdrawQuotationUserInput;
}>;

export type WithdrawQuotationUserMutation = { __typename?: 'Mutation' } & {
  withdrawQuotationUser: { __typename?: 'Output' } & Pick<
    Output,
    'result' | 'errorMessage'
  >;
};

export const WithdrawQuotationUserDocument = gql`
  mutation withdrawQuotationUser($input: WithdrawQuotationUserInput!) {
    withdrawQuotationUser(input: $input) {
      result
      errorMessage
    }
  }
`;
export type WithdrawQuotationUserMutationFn = Apollo.MutationFunction<
  WithdrawQuotationUserMutation,
  WithdrawQuotationUserMutationVariables
>;

/**
 * __useWithdrawQuotationUserMutation__
 *
 * To run a mutation, you first call `useWithdrawQuotationUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWithdrawQuotationUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [withdrawQuotationUserMutation, { data, loading, error }] = useWithdrawQuotationUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWithdrawQuotationUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    WithdrawQuotationUserMutation,
    WithdrawQuotationUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    WithdrawQuotationUserMutation,
    WithdrawQuotationUserMutationVariables
  >(WithdrawQuotationUserDocument, options);
}
export type WithdrawQuotationUserMutationHookResult = ReturnType<
  typeof useWithdrawQuotationUserMutation
>;
export type WithdrawQuotationUserMutationResult =
  Apollo.MutationResult<WithdrawQuotationUserMutation>;
export type WithdrawQuotationUserMutationOptions = Apollo.BaseMutationOptions<
  WithdrawQuotationUserMutation,
  WithdrawQuotationUserMutationVariables
>;
