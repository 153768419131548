import { ActionType } from 'typesafe-actions';
import * as actions from './actions';

export interface SharedWithGroupsState {
  selectedGroupFiles?: any;
}

export const initialSharedWithGroupsState: SharedWithGroupsState = {
  selectedGroupFiles: []
};

export type SharedWithGroupsAction = ActionType<typeof actions>;
