import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import AppConfig from '@config/index';
import { CapaConnectMyFile, CapaErrorCode } from '@generated/Common/graphql';
import useFiles from '@hooks/useFiles';
import { useSnackbar } from '@components/Provider';
import { IconButton, Button, useMediaQuery, useTheme } from '@material-ui/core';
import { useStylesViewer } from './styles';
import i18n from '@i18n/index';
import { LogoBeta } from '@assets';
import { ReactComponent as CapaLogoWhite } from '../../assets/icon/CapaLogoWhite.svg';
import { ReactComponent as CapaLogoWhiteKo } from '../../assets/icon/CapaLogoWhiteKo.svg';
import { ReactComponent as IBMClose } from '../../assets/icon/IBMClose.svg';
import { LANGUAGE_VALUE } from '@routes/Common/ChangeLanguage/types';
import { ReactComponent as IBMDownload } from '../../assets/icon/IBMDownload.svg';
import { ReactComponent as IBMCloudUpload } from '../../assets/icon/IBMCloudUpload.svg';
import { Link, useLocation, useParams } from 'react-router-dom';
import Loading from '@components/LoadingInComponent';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { blobToBase64, convertSubstring } from '@utils/file';
import { groupDetailFileWithOutAuthApi } from '@api/index';
import {
  DRAWING_FILE_TYPES,
  IMAGE_TYPES,
  NO_SUPORT_DOCUMENT_TYPES,
  PDF_TYPE,
  SUPORT_DOCUMENT_TYPES
} from '@routes/Common/Dropzone/types';
import PDFViewer from '@components/PDFViewer';
import { CustomizedButton } from '@components/Customized';
import IconThumbnail from '@components/IconThumbnail/IconThumbnail';
import { useTranslation } from 'react-i18next';
import { AddConnectLinkFiles } from '@generated/Drive/MyDrive/graphql';
import * as Apollo from '@apollo/client';
import LoginDialog from '@routes/Login/Dialog/LoginDialog';
import history from '@utils/history';
import { filesPathName } from '@routes/Common/types';
import queryString from 'query-string';
import SignUpDialog from '@routes/SignUp/Dialog/SignUpDialog';
import { CONNECT_USER_FILE_STATUS } from '@routes/Dashboard/Dialog/Share/ShareLink/types';
import ForgotPassDialog from '@routes/Login/Dialog/ForgotPassDialog';
import ShareContainerDialog from '@routes/Dashboard/Dialog/Share/ShareContainer';
import {
  SHARE_DIALOG_SCREEN,
  SHARE_FILES_TO_VALUE
} from '@routes/Dashboard/Dialog/Share/types';
import useMyDrive from '@hooks/Drive/MyDrive/useMyDrive';
import RegisterUserContainer from '@routes/Common/Dialog/RegisterUser/RegisterUserContainer';
import useCommon from '@hooks/Common/useCommon';
import WelcomeContainer from '@routes/Common/Dialog/Welcome/WelcomeContainer';
import { ReactComponent as IBMChevronLeft } from '../../assets/icon/IBMChevronLeft.svg';
import { ReactComponent as IBMAddCommentRight } from '../../assets/icon/IBMAddCommentRight.svg';
import useUserActionLog, {
  CapaActivityType,
  Enum_Action_Button_Enum,
  Enum_Action_Page_Enum
} from '@hooks/useUserActionLog';
import OfficeFileViewer from '@components/OfficeFileViewer';
interface Props {
  files: Array<CapaConnectMyFile>;
  isLoggedIn: boolean;
}
const ViewerPresenter: React.FC<Props> = (props: Props) => {
  const { onSetShowHideWelcomeDialog } = useCommon();
  const { onInsertUserActionLogOne } = useUserActionLog();

  const { files, isLoggedIn } = props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStylesViewer({
    multifile: files.length > 1 ? true : false
  });
  const { fileIds } = useParams<{ fileIds?: string }>();
  const [addConnectLinkFiles] = Apollo.useMutation(AddConnectLinkFiles);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { onDownloadWithoutAuthConnectFile } = useFiles();
  const [selectedFile, setSelectedFile] = useState<CapaConnectMyFile>();
  const [selectedFileExt, setSelectedFileExt] = useState('');
  const [showLoginDialog, setShowLoginDialog] = useState(false);
  const [showForgotPass, setShowForgotPass] = useState(false);
  const [showWelcome, setShowWelcome] = useState(true);
  const [showSignUpDialog, setShowSignUpDialog] = useState(false);
  const [urlFileViewer, setUrlFileViewer] = useState([]);
  const [imgUrl, setImgUrl] = useState('');
  const languageValue = i18n.language;
  const [isLoading, setIsLoading] = useState(false);
  const [isShowRegisterDialog, setShowRegisterDialog] = useState(false);

  const {
    myDrive,
    onSetShowHideShareDialog,
    onSetShareDialogScreen,
    onSetShareFilesToType
  } = useMyDrive(!isLoggedIn);

  useEffect(() => {
    if (files && files.length > 0) {
      setSelectedFile(files[0]);
      setSelectedFileExt(
        files[0].originalFileExt ? files[0].originalFileExt.toLowerCase() : ''
      );
    }
  }, [files]);
  useEffect(() => {
    if (selectedFile) {
      setSelectedFileExt(
        selectedFile?.originalFileExt
          ? selectedFile?.originalFileExt?.toLowerCase()
          : ''
      );
      if (!convertSubstring(DRAWING_FILE_TYPES).includes(selectedFileExt)) {
        setIsLoading(true);
        groupDetailFileWithOutAuthApi(selectedFile).then((res: any) => {
          setIsLoading(false);
          blobToBase64(res.data).then((imageUrl: any) => {
            setImgUrl(imageUrl);
          });
          res.data.arrayBuffer().then((arrayBuffer: any) => {
            setUrlFileViewer(arrayBuffer);
          });
          return;
        });
      }
    }
  }, [selectedFile]);
  const onDownloadFiles = (file?: CapaConnectMyFile) => {
    if (file) {
      onDownloadWithoutAuthConnectFile(file)
        .then(() => {
          enqueueSnackbar(
            t('snackbar.download_file_complete', {
              fileName: file.fileName
            }),
            {
              variant: 'success'
            }
          );
        })
        .catch(() => {
          enqueueSnackbar(t('snackbar.download_file_complete_error'), {
            variant: 'error'
          });
        });
    }
  };
  const { search } = useLocation<any>();
  const query = queryString.parse(search);
  const onAddToMyDrive = (file?: CapaConnectMyFile) => {
    if (file) {
      addConnectLinkFiles({
        variables: {
          connectFileIds: [file.id]
        }
      }).then((res: any) => {
        const { result } = res.data.addConnectLinkFiles;
        if (result === CapaErrorCode.Success) {
          enqueueSnackbar(
            t('snackbar.add_to_my_drive_success', {
              fileName: file.fileName
            }),
            {
              variant: 'success'
            }
          );
        } else if (result === CapaErrorCode.AlreadyHaveFileInDrive) {
          enqueueSnackbar(
            t('snackbar.already_have_file_in_drive', {
              variant: 'success'
            })
          );
        }
      });
    }
  };

  const onClickChatWithFileOwner = () => {
    onSetShowHideShareDialog({ isShowHideShareDialog: true });
    onSetShareDialogScreen({
      shareDialogScreen: SHARE_DIALOG_SCREEN.CREATE_NEW_GROUP
    });
    onSetShareFilesToType({
      shareFilesToType: SHARE_FILES_TO_VALUE.EMPTY
    });
  };

  const onLoginSuccess = (fetchConnectUserResult: any) => {
    setShowLoginDialog(false);
    if (fetchConnectUserResult === CapaErrorCode.Success) {
      onEndDialog();
    } else if (fetchConnectUserResult === CapaErrorCode.NotAllowedUser) {
      setShowRegisterDialog(true);
    }
  };
  const onEndDialog = () => {
    if (query.download) {
      onDownloadFiles(selectedFile);
      history.push({
        pathname: `${filesPathName}/${fileIds}`,
        state: { prevLocation: window.location.href }
      });
      return;
    }
    if (query.addToMyDrive) {
      onAddToMyDrive(selectedFile);
      history.push({
        pathname: `${filesPathName}/${fileIds}`,
        state: { prevLocation: window.location.href }
      });
    }
    if (query.chatWithFileOwner) {
      onClickChatWithFileOwner();
    }
  };

  useEffect(() => {
    onInsertUserActionLogOne(
      Enum_Action_Page_Enum.Page_FileShareLink,
      CapaActivityType.PageVisit
    );
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Link
          className={clsx(classes.logo, 'md-hidden')}
          to={'/'}
          target={!isLoggedIn ? '_blank' : ''}
          rel="noopener noreferrer"
        >
          {languageValue === LANGUAGE_VALUE.KO ? (
            <CapaLogoWhiteKo />
          ) : (
            <CapaLogoWhite />
          )}
          <img src={LogoBeta} className="beta" alt="LogoBeta" />
        </Link>
        <div className={classes.fileName}>
          <span>{selectedFile?.fileName ?? 'FileName'}</span>
        </div>
        <div className={classes.menuWrapper}>
          <Button
            className={classes.btnChat}
            onClick={() => {
              onInsertUserActionLogOne(
                Enum_Action_Button_Enum.Button_FileShareLink_Chat,
                CapaActivityType.ButtonClick
              );
              if (isLoggedIn) onClickChatWithFileOwner();
              else {
                history.push({
                  pathname: `${filesPathName}/${fileIds}`,
                  search: `?chatWithFileOwner=true`,
                  state: { prevLocation: window.location.href }
                });
                setShowLoginDialog(true);
              }
            }}
          >
            {t('fileshare.chat_with_owner', {
              fileOwner: selectedFile?.fileOwnerUsername
            })}
          </Button>
          {selectedFile?.filePermission ==
            CONNECT_USER_FILE_STATUS.DOWNLOADABLE && (
            <div className={classes.feature}>
              <IconButton
                onClick={() => {
                  onInsertUserActionLogOne(
                    Enum_Action_Button_Enum.Button_FileShareLink_Download,
                    CapaActivityType.ButtonClick
                  );
                  if (isLoggedIn) onDownloadFiles(selectedFile);
                  else {
                    history.push({
                      pathname: `${filesPathName}/${fileIds}`,
                      search: `?download=true`,
                      state: { prevLocation: window.location.href }
                    });
                    setShowLoginDialog(true);
                  }
                }}
              >
                <IBMDownload />
              </IconButton>
              <IconButton
                onClick={() => {
                  onInsertUserActionLogOne(
                    Enum_Action_Button_Enum.Button_FileShareLink_AddToDrive,
                    CapaActivityType.ButtonClick
                  );
                  if (isLoggedIn) onAddToMyDrive(selectedFile);
                  else {
                    setShowLoginDialog(true);
                    history.push({
                      pathname: `${filesPathName}/${fileIds}`,
                      search: `?addToMyDrive=true`,
                      state: { prevLocation: window.location.href }
                    });
                  }
                }}
              >
                <IBMCloudUpload />
              </IconButton>
            </div>
          )}
        </div>
      </div>
      <div className={classes.main}>
        {!isLoggedIn && showWelcome && (
          <div className={classes.welcome}>
            <IconButton
              className={classes.welcomeClose}
              onClick={() => setShowWelcome(false)}
            >
              <IBMClose />
            </IconButton>
            <div className={classes.logo}>
              {languageValue === LANGUAGE_VALUE.KO ? (
                <CapaLogoWhiteKo />
              ) : (
                <CapaLogoWhite />
              )}
              <img src={LogoBeta} className="beta" alt="LogoBeta" />
            </div>
            <div className={classes.textWelcome}>{t('fileshare.welcome')}</div>
            <div className={classes.text}>{t('fileshare.create_account')}</div>

            <Button
              className={classes.btnSignUp}
              onClick={() => setShowSignUpDialog(true)}
            >
              {t('fileshare.button_signup')}
            </Button>
          </div>
        )}
        <div className={classes.fileViewer}>
          {convertSubstring(DRAWING_FILE_TYPES).includes(selectedFileExt) && (
            <iframe
              title="viewer"
              width="100%"
              height="100%"
              src={`${AppConfig.capaViewerUrl}/viewer.html?modelId=${AppConfig.capaViewerApi}/${selectedFile?.drawingFile}&lang=${languageValue}`}
            />
          )}
          {convertSubstring(IMAGE_TYPES).includes(selectedFileExt) && (
            <div
              className={classes.imageViewer}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {isLoading ? (
                <Loading open={isLoading} />
              ) : (
                <TransformWrapper
                  initialScale={1}
                  initialPositionX={0}
                  initialPositionY={0}
                >
                  {() => (
                    <React.Fragment>
                      <TransformComponent
                        wrapperClass={classes.wrapperClass}
                        contentClass={classes.contentClass}
                      >
                        <img src={imgUrl} alt={selectedFile?.fileName} />
                      </TransformComponent>
                    </React.Fragment>
                  )}
                </TransformWrapper>
              )}
            </div>
          )}

          {selectedFileExt === PDF_TYPE && (
            <div className={classes.viewerPdf}>
              <PDFViewer
                permission={selectedFile?.filePermission}
                fileUrl={new Uint8Array(urlFileViewer)}
              />
            </div>
          )}
          {selectedFileExt &&
            convertSubstring(SUPORT_DOCUMENT_TYPES).includes(
              selectedFileExt
            ) && <OfficeFileViewer file={selectedFile} />}
          {selectedFileExt &&
            convertSubstring(NO_SUPORT_DOCUMENT_TYPES).includes(
              selectedFileExt
            ) && (
              <div className={classes.noPreview}>
                <div className="title">No preview available</div>
                {selectedFile?.filePermission ==
                  CONNECT_USER_FILE_STATUS.DOWNLOADABLE && (
                  <CustomizedButton
                    className="button"
                    aria-haspopup="true"
                    variant="contained"
                    color="primary"
                    size="medium"
                  >
                    Download
                  </CustomizedButton>
                )}
              </div>
            )}
        </div>
        {files && files.length > 1 && (
          <div className={classes.thumbnailList}>
            {files?.map((file: any, index: number) => {
              return (
                <div
                  className={clsx(
                    classes.thumbnail,
                    file.fileId === selectedFile?.fileId ? 'selected' : ''
                  )}
                  key={file.fileId}
                  onClick={() => {
                    setSelectedFile(file);
                  }}
                >
                  <IconThumbnail file={file} size="large" />
                </div>
              );
            })}
          </div>
        )}
      </div>
      <LoginDialog
        open={showLoginDialog}
        onClose={() => {
          setShowLoginDialog(false);
          history.push({
            pathname: `${filesPathName}/${fileIds}`
          });
        }}
        onSuccess={onLoginSuccess}
        onClickSignUp={() => {
          setShowSignUpDialog(true);
          setShowLoginDialog(false);
        }}
        onClickForgotPass={() => {
          setShowForgotPass(true);
          setShowLoginDialog(false);
        }}
      />
      <SignUpDialog
        open={showSignUpDialog}
        onClose={() => {
          setShowSignUpDialog(false);
          history.push({
            pathname: `${filesPathName}/${fileIds}`
          });
        }}
        onGotoLogin={() => {
          setShowSignUpDialog(false);
          setShowLoginDialog(true);
        }}
        onSuccess={() => {
          setShowSignUpDialog(false);
          onSetShowHideWelcomeDialog({
            isShowWelcomeDialog: true
          });
        }}
      />
      <ForgotPassDialog
        open={showForgotPass}
        onClose={() => {
          setShowForgotPass(false);
          history.push({
            pathname: `${filesPathName}/${fileIds}`
          });
        }}
        onGotoLogin={() => {
          setShowForgotPass(false);
          setShowLoginDialog(true);
        }}
        onSuccess={() => {
          setShowForgotPass(false);
          setShowLoginDialog(true);
        }}
      />
      <RegisterUserContainer
        isOpen={isShowRegisterDialog}
        callBack={() => {
          onSetShowHideWelcomeDialog({
            isShowWelcomeDialog: true
          });
          setShowRegisterDialog(false);
        }}
        notRedirect={true}
      />
      {myDrive.isShowHideShareDialog ? (
        <ShareContainerDialog
          checkedFiles={[selectedFile]}
          fileOwner={selectedFile}
          isFilesPage={true}
          createNewGroupHeader={
            <>
              {matches ? (
                <IBMChevronLeft
                  className={classes.backIconMobile}
                  onClick={() => {
                    onSetShowHideShareDialog({
                      isShowHideShareDialog: false
                    });
                  }}
                />
              ) : (
                <div className={classes.backIconWrapper}>
                  <IBMAddCommentRight />
                </div>
              )}
              <div className={classes.createNewGroupTitle}>
                {t('create_new_group_modal.header_title')}
              </div>
            </>
          }
        />
      ) : null}
      <WelcomeContainer
        onClose={() => {
          onEndDialog();
        }}
      />
    </div>
  );
};

export default ViewerPresenter;
