import { makeStyles } from '@material-ui/core';
import { colorSet } from '@components/Provider';
import styled from 'styled-components';

export const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: 520,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: 0,
      height: '100%',
      maxHeight: '100%',
      maxWidth: '100%',
      borderRadius: 0
    },
    borderRadius: '16px',
    '& .ml-10': {
      marginLeft: 10
    },
    '& .star-rounded-icon': {
      margin: '0 8'
    },
    '& .empty-block': {
      height: 20
    }
  },
  rootDialog: {
    zIndex: '10000 !important' as unknown as 10000
  },
  rootTitle: {
    padding: '30px 32px 24px 30px',
    display: 'flex',
    fontSize: '25px',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: '12px 16px',
      border: `1px solid ${colorSet.gray300}`,
      textAlign: 'center',
      fontSize: 16,
      position: 'relative'
    }
  },
  arrowBackIosOutlinedIcon: {
    width: 14,
    color: colorSet.gray100,
    [theme.breakpoints.down('xs')]: {
      color: colorSet.gray900,
      fontWeight: 500,
      position: 'absolute',
      left: 16,
      top: 10
    }
  },
  closeIcon: {
    color: colorSet.gray900,
    fontWeight: 500,
    position: 'absolute',
    left: 16,
    top: 10
  },
  customTitle: {
    [theme.breakpoints.down('xs')]: {
      color: colorSet.gray900,
      fontWeight: 500
    }
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  cancelBtn: {
    fontSize: 24,
    marginLeft: 'auto',
    cursor: 'pointer'
  },
  content: {
    padding: '0px 24px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px 12px'
    }
  },
  action: {
    padding: '32px 32px 24px 32px'
  },
  wrapperBtn: {
    display: 'flex',

    '& button': {
      width: '100%',
      textTransform: 'none',

      '&:first-child': {
        marginRight: 10,
        background: colorSet.gray200,
        color: colorSet.gray900
      },

      '&.Mui-disabled': {
        background: colorSet.gray200,
        color: colorSet.gray500
      }
    },
    width: '100%',

    [theme.breakpoints.down('xs')]: {
      width: '100%',
      '& button': {
        width: 'calc(100% / 2)'
      },
      '& button + button': {
        marginLeft: 6
      }
    }
  },
  csDialogText: {
    color: colorSet.gray700,
    fontSize: '16px'
  },
  csDialogContent: {
    padding: 0,
    fontSize: 14,
    paddingTop: 12,
    marginLeft: 32,
    marginRight: 32,
    overflowY: 'unset',
    [theme.breakpoints.down('xs')]: {
      padding: '24px 16px',
      margin: 0
    },
    '& li': {
      paddingLeft: 12,
      '&::marker': {
        fontSize: 11
      }
    }
  },

  linkEmail: {
    color: colorSet.primary500
  },

  labelText: {
    marginBottom: 10,
    fontWeight: 500,
    fontSize: 16,
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
      textAlign: 'center'
    }
  },

  firstTitle: {
    fontWeight: 700,
    marginRight: 6,
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
      width: 'max-content'
    }
  },

  noteWrapper: {
    background: colorSet.gray100,
    borderRadius: 8,
    padding: 20,
    fontSize: 16,
    lineHeight: '24px',
    color: colorSet.gray700,
    fontWeight: 400,
    wordBreak: 'break-word',
    [theme.breakpoints.down('xs')]: {
      fontSize: 14
    }
  }
}));

export const RatingStyle = styled.div`
  background: ${colorSet.gray100};
  border-radius: 8px;
  padding: 20px 10px;
  text-align: center;

  .MuiRating-sizeLarge {
    font-size: 50px;
    justify-content: center;
  }

  .MuiRating-iconEmpty {
    color: ${colorSet.gray400};
  }
`;

export const UploadScreenshotWrapper = styled.div`
  margin: 10px 0 10px;
  text-align: center;

  input {
    display: none;
  }

  .customized-button {
    text-transform: unset;
    color: ${colorSet.gray900};
    background: ${colorSet.gray100};
  }
`;

export const ScreenshotListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-wrap: wrap;
  max-height: 140px;
  overflow: auto;
  margin-bottom: 10px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${colorSet.gray600};
    border-radius: 8px;
  }
`;

export const ScreenshotItem = styled.div`
  width: 100%;
  height: 48px;
  margin-bottom: 10px;
  position: relative;
  border-bottom: 1px solid ${colorSet.gray200};
  display: flex;
  align-items: center;

  .icon-remove {
    position: absolute;
    background: ${colorSet.gray800};
    border-radius: 50%;
    right: 4px;
    top: 50%;
    cursor: pointer;
    transform: translateY(-50%);
    height: 21px;
    width: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
