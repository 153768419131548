import React, { useEffect } from 'react';
import ActionButtonPresenter from './ActionButtonPresenter';
import { useMutation } from '@apollo/client';
import {
  AddConnectFiles,
  UnshareFileFromGroup
} from '@generated/Drive/MyDrive/graphql';
import { CapaErrorCode } from '@generated/Common/graphql';
import { useSnackbar } from '@components/Provider';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import useMyDrive from '@hooks/Drive/MyDrive/useMyDrive';
import { DOWNLOAD_PERMISSION_STATUS } from '@routes/Dashboard/types';
import useCommon from '@hooks/Common/useCommon';

interface Props {
  message?: any;
  messageDataOrigin?: any;
  userEmail?: string;
  clickDownload?: () => void;
}

const ActionButtonContainer = (props: Props) => {
  const { t } = useTranslation();
  const { message, userEmail, clickDownload, messageDataOrigin } = props;
  const file = message?.chatFiles[0] ?? null;
  const [unshareFileFromGroup] = useMutation(UnshareFileFromGroup);
  const [addConnectFiles] = useMutation(AddConnectFiles);
  const { enqueueSnackbar } = useSnackbar();
  const { search } = useLocation<any>();
  const groupId = new URLSearchParams(search).get('groupId');
  const {
    myDrive,
    onSetShowHideDownloadPermissionDialog,
    onSetActiveFileIdInGroupRoom,
    onSetShowHideRequestForDownload,
    onSetShowHideFileDetail
  } = useMyDrive();
  const { onSetDeleteChatBotSuccessStatus } = useCommon();

  const onClickDownloadAccess = () => {
    onSetActiveFileIdInGroupRoom({
      activeFileIdInGroupRoom: message.chatFiles[0].fileId
    });
    onSetShowHideDownloadPermissionDialog({
      isShowHideDownloadPermissionDialog: true
    });
  };
  const internalMessageDataOrigin = [...messageDataOrigin] ?? [];

  const onClickRemove = () => {
    onSetActiveFileIdInGroupRoom({
      activeFileIdInGroupRoom: message.chatFiles[0].fileId
    });

    const chatFilesOrigin =
      internalMessageDataOrigin?.filter(
        (item: any) => item?.id === message?.id
      )[0]?.chatFiles ?? null;

    let payload: any = {
      groupId: groupId
    };

    if (chatFilesOrigin && chatFilesOrigin?.length > 1) {
      payload.connectFileIds = [file?.fileId];
    } else if (chatFilesOrigin && chatFilesOrigin?.length === 1) {
      payload.connectFileIds = [file?.fileId];
      payload.messageIds = [message?.id];
    }

    unshareFileFromGroup({
      variables: payload
    }).then((res) => {
      const result = res?.data?.unshareFileFromGroup?.result;
      if (result === CapaErrorCode.Success) {
        onSetDeleteChatBotSuccessStatus({ isDeleteChatFileSuccess: true });
        onSetActiveFileIdInGroupRoom({
          activeFileIdInGroupRoom: ''
        });
        onSetShowHideFileDetail({
          detailDrive: ''
        });
        enqueueSnackbar(t('snackbar.delete_file_complete'), {
          variant: 'success'
        });
      }
    });
  };

  useEffect(() => {
    if (myDrive.detailDrive)
      onSetShowHideFileDetail({
        chatFilesOrigin: {
          data: messageDataOrigin
        }
      });
  }, [myDrive.detailDrive, messageDataOrigin, message]);

  const onClickDownload = () => {
    clickDownload && clickDownload();
  };

  const onClickAddToMyDrive = () => {
    onSetActiveFileIdInGroupRoom({
      activeFileIdInGroupRoom: message.chatFiles[0].fileId
    });
    if (file?.permission !== DOWNLOAD_PERMISSION_STATUS.ALLOW) {
      onSetShowHideRequestForDownload({
        isShowHideRequestForDownload: true
      });
      return;
    }
    addConnectFiles({
      variables: {
        connectFileIds: [file?.fileId]
      }
    }).then((res: any) => {
      const { result } = res.data.addConnectFiles;
      if (result === CapaErrorCode.Success) {
        enqueueSnackbar(
          t('snackbar.add_to_my_drive_success', {
            fileName: file?.fileName
          }),
          {
            variant: 'success'
          }
        );
      } else if (result === CapaErrorCode.AlreadyHaveFileInDrive) {
        enqueueSnackbar(
          t('snackbar.already_have_file_in_drive', {
            variant: 'success'
          })
        );
      }
    });
  };

  return (
    <ActionButtonPresenter
      message={message}
      userEmail={userEmail}
      clickDownload={onClickDownload}
      clickDownloadAccess={onClickDownloadAccess}
      clickRemove={onClickRemove}
      clickAddToMyDrive={onClickAddToMyDrive}
    />
  );
};

export default ActionButtonContainer;
