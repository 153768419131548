import { makeStyles } from '@material-ui/core';
import { colorSet } from '@components/Provider';

export const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: 'none',
    maxWidth: 476,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: 0,
      height: '100%',
      maxHeight: '100%',
      maxWidth: '100%',
      borderRadius: 0
    },
    maxHeight: 640,
    borderRadius: '16px'
  },
  rootTitle: {
    padding: '30px 32px 24px 30px',
    display: 'flex',
    fontSize: '20px',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: '12px 16px',
      border: `1px solid ${colorSet.gray300}`,
      display: 'inline-block',
      textAlign: 'center',
      fontSize: 16
    }
  },
  arrowBackIosOutlinedIcon: {
    width: 14,
    color: colorSet.gray100,
    [theme.breakpoints.down('xs')]: {
      color: colorSet.gray900,
      fontWeight: 500,
      position: 'absolute',
      left: 16,
      top: 10
    }
  },
  closeIcon: {
    color: colorSet.gray900,
    fontWeight: 500,
    position: 'absolute',
    left: 16,
    top: 10
  },
  customTitle: {
    [theme.breakpoints.down('xs')]: {
      color: colorSet.gray900,
      fontWeight: 500
    }
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  cancelBtn: {
    fontSize: 24,
    marginLeft: 'auto',
    cursor: 'pointer'
  },
  content: {
    padding: '0px 24px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px 12px'
    }
  },
  action: {
    padding: 32,
    [theme.breakpoints.down('xs')]: {
      padding: '24px 15px 24px 15px',
      margin: 0
    }
  },
  wrapperBtn: {
    display: 'flex',
    width: '100%',
    '& button': {
      textTransform: 'none',
      marginLeft: 'auto',
      width: '100%',
      '&:first-child': {
        marginRight: 8
      }
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      '& button': {
        width: '100%'
      }
    }
  },
  checkedText: {
    paddingLeft: 30,
    fontSize: 14,
    lineHeight: '143%',
    wordBreak: 'break-word'
  },
  csDialogText: {
    color: colorSet.gray700,
    fontSize: '16px'
  },
  csDialogContent: {
    padding: 0,
    fontSize: 14,
    marginLeft: 32,
    marginRight: 32,
    overflow: 'hidden',
    wordBreak: 'break-word',
    [theme.breakpoints.down('xs')]: {
      padding: '12px 16px',
      margin: 0
    },
    '& li': {
      paddingLeft: 12,
      '&::marker': {
        fontSize: 11
      }
    }
  },
  checkbox: {
    marginTop: 12,
    '& .MuiFormControlLabel-label': {
      fontSize: 16,
      color: colorSet.gray900,
      fontWeight: 'bold'
    }
  },
  marginBottomText: {
    marginBottom: 8,
    fontSize: 16,
    color: colorSet.gray700,
    wordBreak: 'break-word',
    lineHeight: '150%'
  },
  marginTextContent: {
    fontSize: 16,
    color: colorSet.gray700,
    wordBreak: 'break-word',
    lineHeight: '150%'
  },
  firstTitle: {
    wordBreak: 'break-word',
    fontSize: 25,
    fontWeight: 'bold',
    marginRight: 6,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16
    }
  }
}));
