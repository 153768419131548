import React, { useEffect } from 'react';
import { useStyles } from './styles';
import { LayoutDashboard, GnbAppBar } from '../Common';
import { useMediaQuery, useTheme } from '@material-ui/core';
import AsideMenuPresenter from './AsideMenuPresenter';
import CloseIcon from '@material-ui/icons/Close';
import { CapaAddtoGroup, CapaCreateGroup, CapaDelete } from '@assets';
import DataTable from './DataTable';
import useMyContacts from '@hooks/Contacts/useMyContacts';
import { useTranslation } from 'react-i18next';
import useMyDrive from '@hooks/Drive/MyDrive/useMyDrive';

const ContactsPresenter: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const { onSetShowHideShareDialog } = useMyDrive();

  useEffect(() => {
    return () => {
      onSetShowHideShareDialog({ isShowHideShareDialog: false });
    };
  }, []);

  const { myContacts, onSetStateContact } = useMyContacts();
  return (
    <LayoutDashboard
      contentShift={!matches}
      asideDrawer={matches ? null : <AsideMenuPresenter />}
      matches={matches}
      gnbAppBar={
        matches && myContacts?.dataCheckedContacts?.length ? (
          <div className={classes.headerFeatureFixed}>
            <div className={classes.headerFeature}>
              <div className={classes.headerFeatureLeft}>
                <div
                  onClick={() =>
                    onSetStateContact({
                      dataCheckedContacts: []
                    })
                  }
                  className={classes.headerFeatureClose}
                >
                  <CloseIcon />
                </div>
                <div className={classes.headerFeatureNumberChecked}>
                  {myContacts?.dataCheckedContacts?.length}{' '}
                  {t('contact.feature.selected')}
                </div>
              </div>
              <div className={classes.headerFeatureGroup}>
                <img
                  src={CapaAddtoGroup}
                  alt="capaAddToGroup"
                  onClick={() =>
                    onSetStateContact({
                      isShowHideExistingGroups: true
                    })
                  }
                />
                <img
                  src={CapaCreateGroup}
                  alt="capaCreateGroup"
                  onClick={() =>
                    onSetStateContact({
                      isShowHideCreateNewGroup: true
                    })
                  }
                />
                <img
                  src={CapaDelete}
                  alt="capaDelete"
                  onClick={() =>
                    onSetStateContact({
                      dataDeleteDialog: myContacts?.dataCheckedContacts
                    })
                  }
                />
              </div>
            </div>
          </div>
        ) : (
          <GnbAppBar />
        )
      }
    >
      <DataTable />
    </LayoutDashboard>
  );
};

export default ContactsPresenter;
