import styled from 'styled-components';
import { colorSet } from '@components/Provider';
import { Box, Button, Menu } from '@material-ui/core';
import { drawerWidth } from '@routes/Common/types';

export const MuiDiv = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 122px);
  max-width: calc(100vw - ${drawerWidth});

  ${({ theme }) => `
      ${theme.breakpoints.down('xs')} {
        max-width: unset;
      }
    `}

  & .icon-svg {
    display: flex;
    min-width: 36px;
    justify-content: center;
    & img {
      width: 27px;
      height: auto;
    }
  }
  & .icon-svg-file-rar {
    display: flex;
    min-width: 36px;
    justify-content: center;
    & img {
      width: 24px;
      height: auto;
    }
  }
  &.connect-empty {
    min-height: calc(100vh - 182px) !important;
    ${({ theme }) => `
    ${theme.breakpoints.down('xs')} {
      min-height: calc(100vh - 251px) !important;
      }
    `}
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 144px;
      width: 144px;
      margin-bottom: 36px;
    }
    .you-can-upload-text {
      color: ${colorSet.gray600};
      font-size: 16px;
      line-height: 150%;
      text-align: center;
      letter-spacing: -0.1px;
    }
    .no-data-icon {
      -webkit-user-drag: none;
      -khtml-user-drag: none;
      -moz-user-drag: none;
      -o-user-drag: none;
      user-drag: none;
    }
  }
  .subtitle {
    height: 60px;
    font-size: 20px;
    font-weight: 700;
    line-height: 60px;
    padding-left: 20px;
    & .cursor-pointer {
      cursor: pointer;
    }

    ${({ theme }) => `
      ${theme.breakpoints.down('xs')} {
        height: 48px;
        margin-bottom: 0px;
        position: fixed;
        top: 0px;
        z-index: 1200;
        right: 0;
        padding: 24px 16px;
      }
    `}

    .label-group {
      cursor: pointer;
      display: flex;
      align-items: center;
      .MuiSvgIcon-root {
        color: ${colorSet.gray900};
        margin-right: 4px;
      }
    }
  }
`;

export const MuiBoxTable = styled(Box)`
  .icon-svg-mobile {
    flex: 0 0 auto;
    width: 66px;
    & img {
      width: 100%;
      height: auto;
    }
  }
  ${({ theme }) => `
    ${theme.breakpoints.down('xs')} {
      padding-bottom: 57px;
    }
  `}
  .table-header {
    border-top: 1px solid ${colorSet.gray200};
    border-bottom: 1px solid ${colorSet.gray200};
    padding: 20px 24px;
    padding-left: 18px;
    display: flex;
    align-items: center;
    height: 44px;
    color: ${colorSet.gray700};
    & .icon-up {
      & svg {
        font-size: 18px;
        margin-bottom: -3px;
        margin-left: 5px;
        cursor: pointer;
      }
    }

    .table-header-th-one {
      width: 300px;
    }
    .table-header-th-two {
      width: 400px;
    }
    .table-header-th-three {
      width: calc(100% - 300px - 400px);
    }
    .table-header-th-four {
      width: 148px;
    }
    .table-header-th-five {
      width: 84px;
    }

    .table-header-th-group-name {
      width: calc(100% - 220px - 288px);
    }
    .table-header-th-my-access {
      width: 220px;
    }
    .table-header-th-shared-with {
      width: 288px;
    }

    .table-header-th-file-name {
      width: calc(100% - 260px - 140px - 140px);
    }

    .table-header-th-file-owner {
      width: 260px;
    }

    .table-header-th-shared-date {
      width: 140px;
    }

    .table-header-th-file-size {
      width: 140px;
    }

    .table-header-feature {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
    .table-header-feature-left {
      display: flex;
      align-items: center;
    }
    .table-header-feature-dropdown {
      margin-right: 10px;
      button {
        border: none;
        cursor: pointer;
        background: none;
        display: flex;
        align-item: center;
      }
    }
    .table-header-number_checked {
      font-size: 16px;
      color: ${colorSet.primary500};
    }
    .table-header-feature-group {
      display: flex;
      align-items: center;
      img {
        cursor: pointer;
        margin-right: 15px;
      }
    }
  }
  .table-content {
    display: block;
    overflow-x: auto;
  }
`;

interface IMuiBoxTableItemProps {
  isChecked?: boolean;
}

export const MuiBoxTableItem = styled.div.attrs(
  (props: IMuiBoxTableItemProps) => props
)`
  background-color: ${(props) =>
    props.isChecked ? `${colorSet.primary100}` : ''};
  border-bottom: 1px solid ${colorSet.gray200};
  display: flex;
  align-items: center;
  user-select: none;
  .table-item-td-one {
    width: 300px;
    display: flex;
    align-items: center;
    .table-item-avatar {
      padding: 12px 0 12px 20px;
      margin-right: 15px;
      min-height: 60px;
      .custom-avatar {
        width: 36px;
        height: 36px;
        margin-left: 0 !important;
        margin-right: 6px !important;
        text-transform: uppercase;
        border: none !important;
        font-size: 14px !important;
      }
    }
    .table-item-text {
      font-size: 14px;
      width: 100%;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .table-item-td-two {
    padding: 10px 20px;
    min-height: 60px;
    display: flex;
    align-items: center;
    width: 400px;
  }
  .table-item-td-three {
    padding: 10px 20px;
    min-height: 60px;
    display: flex;
    align-items: center;
    width: calc(100% - 300px - 400px);
    justify-content: space-between;
    .table-item-td-feature {
      display: flex;
      .custom-add-button {
        background: ${colorSet.gray200};
        border-radius: 8px;
        color: ${colorSet.gray900};
        min-width: 88px !important;
        height: 32px !important;
        text-transform: none;
        font-weight: 500;
        .custom-add-icon {
          margin-right: 4px;
          width: 16px;
          height: 16px;
        }
      }
    }
    .table-item-td-memo {
      max-width: calc(100% - 70px);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    img {
      width: 32px;
      height: 32px;
      opacity: 80%;
    }
    img:hover {
      opacity: 100%;
    }
  }
  .table-item-td-four {
    display: flex;
    align-items: center;
    width: 148px;
  }
  .table-item-td-five {
    display: flex;
    align-items: center;
    width: 84px;
  }
  .table-header-feature {
    display: flex;
    align-items: center;
  }
`;

export const NewButtonMenu = styled(Menu)`
  &.MuiPopover-root {
    z-index: 3000 !important;
  }
  ${({ theme }) => `
  & .MuiPaper-root {
    ${theme.breakpoints.down('xs')} {
      left: 0 !important;
      top: auto !important;
      bottom: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      width: 100%;
      max-width: 100% !important;
    }
    border-radius: 6px;
    margin-top: ${theme.spacing(1)};
    min-width: 343px;
    box-shadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px';
  }
  `}
`;

export const MuiNewButtonMobile = styled(Button)`
  position: fixed;
  bottom: 73px;
  right: 16px;
  width: 54px;
  height: 51px;
  img {
    width: 32px;
    height: 32px;
  }
`;

export const MuiBoxTableItemMobile = styled.div.attrs(
  (props: IMuiBoxTableItemProps) => props
)`
  padding: 8px 16px;
  display: flex;
  cursor: pointer;
  margin-bottom: 8px;

  .custom-add-button {
    min-width: 44px !important;
    height: 32px !important;
    .custom-add-icon {
      width: 20px;
      height: 20px;
    }
  }

  .table-contents {
    display: grid;
    margin-left: 16px;
    width: 100%;

    .MuiTypography-colorTextSecondary {
      color: ${colorSet.gray700};
    }
    .customized-typography {
      text-align: left;
    }
  }
  .folder-icon {
    color: ${colorSet.gray600};
  }
  .checkbox-wrapper {
    display: flex;
    align-items: center;
    margin-right: 8px;
  }
  .more-vert-menu {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
  .item-wrapper {
    display: flex;
    cursor: pointer;
    width: 100%;
    background: transparent;
    border: none;
    padding: 0;
    align-items: center;
  }
  .table-item-share-mobile {
    display: flex;
    align-items: center;
  }
  .table-item-share-icon-mobile {
    color: ${colorSet.gray550};
    margin-right: 5px;
    font-size: 20px;
  }
`;
