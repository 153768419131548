import { makeStyles, Menu } from '@material-ui/core';
import { colorSet } from '@components/Provider';
import styled from 'styled-components';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: 336,
    height: 'calc(100vh - 60px)',
    position: 'relative',
    boxShadow:
      '0px 8px 16px 2px rgba(97, 97, 97, 0.1), 0px 16px 32px 2px rgba(97, 97, 97, 0.1)',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  groupWraper: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 6px 8px 12px',
    [theme.breakpoints.down('xs')]: {
      paddingTop: 0
    }
  },
  groupFile: {
    flex: '1 0 auto',
    fontSize: 14,
    fontWeight: 'bold',
    color: colorSet.gray900,
    '& span': {
      fontWeight: 'normal',
      color: colorSet.gray700
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16
    }
  },
  collapseButton: {
    position: 'absolute',
    borderTop: `1px solid ${colorSet.gray400}`,
    borderRight: `1px solid ${colorSet.gray400}`,
    borderBottom: `1px solid ${colorSet.gray400}`,
    top: '50%',
    transform: 'translate(0, -50%)',
    cursor: 'pointer',
    backgroundColor: colorSet.gray000,
    borderRadius: '0px 8px 8px 0px',
    right: -28,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 28,
    height: 56,
    '& svg': {
      transition: '0.3s',
      transform: 'scaleX(-1)'
    },
    '&.expanded svg': {
      transform: 'scaleX(1)'
    },
    '&:hover': {
      boxShadow: 'inset 999px 999px 0px rgba(0, 0, 0, 0.08)'
    }
  },
  loadingWrapper: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    alignItems: 'center'
  },
  listItem: {
    height: 'calc(100vh - (50px + 60px))',
    [theme.breakpoints.down('xs')]: {
      height: '70vh'
    },
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: 4
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: colorSet.gray600,
      borderRadius: 8
    }
  },
  item: {
    cursor: 'pointer',
    padding: '12px 16px 16px 12px',
    borderBottom: `1px solid ${colorSet.gray400}`,
    '&:hover': {
      backgroundColor: colorSet.gray100
    }
  },
  activeItem: {
    backgroundColor: colorSet.gray200
  },
  customAvatar: {
    width: 32,
    height: 32,
    marginLeft: '0 !important',
    marginRight: '6px !important',
    border: 'none !important',
    textTransform: 'uppercase',
    fontSize: 16
  },
  avatar: {
    position: 'relative',
    flex: '1 0 auto',
    display: 'flex'
  },
  active: {
    position: 'absolute',
    content: '',
    width: 4,
    height: 4,
    borderRadius: '100%',
    left: '-8px',
    top: 17,
    background: colorSet.pink300
  },
  moreIcon: {
    cursor: 'pointer',
    color: colorSet.gray550
  },
  avatarTop: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8
  },
  text: {
    fontSize: 13,
    color: colorSet.gray700,
    marginBottom: 8,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  desc: {
    fontWeight: 500,
    marginBottom: 8,
    fontSize: 13,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '& span:first-child': {
      color: colorSet.gray900,
      marginRight: 8
    },
    '& span:last-child': {
      color: colorSet.gray500,
      fontWeight: 'normal'
    }
  },
  subText: {
    marginBottom: 8,
    fontSize: 14,
    lineHeight: '143%',
    color: colorSet.gray900
  },
  replies: {
    fontSize: 13,
    color: colorSet.gray500,
    letterSpacing: '-0.1px'
  }
}));

export const AttachFileButtonMenu = styled(Menu)`
  ${({ theme }) => `
  & .MuiPaper-root {
    ${theme.breakpoints.down('xs')} {
      left: 0 !important;
      top: auto !important;
      bottom: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      width: 100%;
      max-width: 100% !important;
    }
    border-radius: 6px;
    min-width: 260px;
    box-shadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px';

    & .MuiMenu-list {
      padding: 0;
    }

    & .MuiMenuItem-root {
      padding-top: 20px;
      padding-bottom: 20px;

      & .MuiSvgIcon-root {
        font-size: 18px;
        margin-right: 10px;
      }
    }
  }
  `}
`;
