import React from 'react';
import { useStyles } from './styles';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CustomizedButton } from '@components/Customized';

interface Props {
  isOpen?: boolean;
  onClose?: () => void;
}

const LeaveLastMemberGroupModal = (props: Props) => {
  const classes = useStyles();
  const { isOpen, onClose } = props;
  const { t } = useTranslation();

  const onSubmit = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <>
      <Dialog
        classes={{
          paper: classes.paper
        }}
        open={isOpen!}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <DialogTitle classes={{ root: classes.rootTitle }} disableTypography>
          <span className={classes.firstTitle}>
            {t('leave_last_member_group.title')}
          </span>
        </DialogTitle>

        <DialogContent className={classes.csDialogContent}>
          <div className={classes.content}>
            {t('leave_last_member_group.description')}
          </div>
        </DialogContent>

        <DialogActions classes={{ root: classes.action }}>
          <div className={classes.wrapperBtn}>
            <CustomizedButton
              type="submit"
              color="default"
              variant="contained"
              onClick={onSubmit}
            >
              {t('leave_last_member_group.cancel_button')}
            </CustomizedButton>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LeaveLastMemberGroupModal;
