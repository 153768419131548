import { makeStyles } from '@material-ui/core';
import { colorSet } from '@components/Provider';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: colorSet.primary500,
    padding: '16px 12px',
    width: '100%',
    position: 'sticky',
    bottom: 0,
    zIndex: 100,
    display: 'flex',
    alignItems: 'center',
    height: 76,
    color: colorSet.gray000
  },
  landingWrapper: {
    backgroundColor: colorSet.gray1000,
    padding: '16px 12px',
    position: 'sticky',
    bottom: 0,
    zIndex: 100,
    display: 'flex',
    alignItems: 'center',
    height: 76,
    color: colorSet.gray000
  },
  closeIcon: {
    color: colorSet.gray000,
    marginRight: 12
  },
  logo: {
    width: 44,
    height: 44
  },
  capaConnect: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '150%',
    letterSpacing: '-0.1px',
    marginLeft: 8
  },
  downloadButton: {
    backgroundColor: colorSet.gray000,
    height: '32px !important',
    padding: '6px 9px !important',
    marginLeft: 'auto',
    textTransform: 'none',
    minWidth: 'auto !important',
    '&:hover': {
      backgroundColor: colorSet.gray000,
      color: colorSet.primary500
    }
  },
  landingDownloadColor: {
    color: colorSet.gray900
  },
  downloadColor: {
    color: colorSet.primary500
  }
}));
