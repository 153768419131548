import {
  ICommonState,
  updateCommonState,
  showHideNumberNotice,
  setShowHideWelcomeDialog,
  setUnReadCountGroupList,
  setDeleteChatBotSuccessStatus
} from '@modules/common';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@modules/index';

export default function useCommon() {
  const { common } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  const openFeedbackDialog = (params: ICommonState) =>
    dispatch(updateCommonState(params));

  const onShowHideNumberNotice = (params: ICommonState) =>
    dispatch(showHideNumberNotice(params));

  const onSetShowHideWelcomeDialog = (params: ICommonState) =>
    dispatch(setShowHideWelcomeDialog(params));

  const onSetUnReadCountGroupList = (params: ICommonState) =>
    dispatch(setUnReadCountGroupList(params));

  const onSetDeleteChatBotSuccessStatus = (params: ICommonState) =>
    dispatch(setDeleteChatBotSuccessStatus(params));

  return {
    common,
    openFeedbackDialog,
    onShowHideNumberNotice,
    onSetShowHideWelcomeDialog,
    onSetUnReadCountGroupList,
    onSetDeleteChatBotSuccessStatus
  };
}
