import { CapaServiceType } from '@generated/Common/graphql';
import * as Sentry from '@sentry/react';
import { JwtPayload } from 'jwt-decode';

export const setSentryUserInfo = (
  decodedJwtToken?: JwtPayload,
  apiName?: string
) => {
  if (!decodedJwtToken) return;

  const email = decodedJwtToken?.aud?.toString() ?? '';

  const userinfo = {
    email: email,
    decodedJwt: decodedJwtToken
  };

  Sentry.setTag('Email', email);
  Sentry.setUser(userinfo);

  Sentry.setTag('Service', CapaServiceType.Connect);
  Sentry.setTag('Api', apiName);
};
