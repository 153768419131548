import { createReducer } from 'typesafe-actions';
import { UploadData, initialUploadState, UploadAction } from './types';
import {
  GET_UPLOAD_DATA,
  ADD_UPLOAD_DATA,
  UPDATE_UPLOAD_DATA,
  CANCEL_UPLOAD_DATA,
  INIT_UPLOAD_DATA
} from './actions';

export const upload = createReducer<UploadData[], UploadAction>(
  initialUploadState,
  {
    [GET_UPLOAD_DATA]: (state, action) => [...state, ...action.payload],
    [ADD_UPLOAD_DATA]: (state, action) => [...state, ...action.payload],
    [UPDATE_UPLOAD_DATA]: (state, action) =>
      updateState({ state, payload: action.payload }),
    [CANCEL_UPLOAD_DATA]: (state, action) =>
      updateState({ state, payload: action.payload }),
    [INIT_UPLOAD_DATA]: () => initialUploadState
  }
);

const updateState = ({
  state,
  payload
}: {
  state: UploadData[];
  payload: UploadData;
}) => {
  const newState = [...state];
  const targetFile = payload.dataKey;
  const targetIndex = state.findIndex(
    (fileData) => fileData.dataKey === targetFile
  );

  if (targetIndex !== -1) {
    newState[targetIndex] = payload;
  }

  return newState;
};
