import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { AppleStoreIcon, GooglePlayIcon } from '@assets';
import { colorSet } from '@components/Provider';
import AppConfig from '@config/index';
import { GOOGLE_PLAY, APPLE_STORE, GOOGLE, APPLE } from './types';
import useUserActionLog, {
  CapaActivityType,
  Enum_Action_Button_Enum
} from '@hooks/useUserActionLog';

type CustomizedButtonDownloadAppProps = {
  store: string;
  theme?: 'dark ' | 'light';
};

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    background: '#212529e6',
    color: colorSet.gray000,
    height: 48,
    borderRadius: 8,
    textTransform: 'unset',
    fontWeight: 400,
    width: 192,
    fontSize: 16,
    lineHeight: 24,
    overflow: 'hidden',

    '&:hover': {
      background: '#464D53'
    },

    [theme.breakpoints.down('xs')]: {
      width: 163,
      '&:hover': {
        background: '#212529e6'
      }
    },

    '& img': {
      marginRight: 10,
      width: 24
    }
  }
}));

function CustomizedButtonDownloadApp({
  store,
  theme,
  ...rest
}: CustomizedButtonDownloadAppProps) {
  const classes = useStyles();
  const { onInsertUserActionLogOne } = useUserActionLog();

  const handleClick = () => {
    if (store === APPLE) {
      onInsertUserActionLogOne(
        Enum_Action_Button_Enum.Button_Landing_Apple_Last_CTA,
        CapaActivityType.ButtonClick
      );
      window.open(`${AppConfig.downloadIOSUrl}`);
    } else if (store === GOOGLE) {
      onInsertUserActionLogOne(
        Enum_Action_Button_Enum.Button_Landing_Android_CTA,
        CapaActivityType.ButtonClick
      );
      window.open(`${AppConfig.downloadAndroidUrl}`);
    }
  };

  if (store === APPLE)
    return (
      <Button {...rest} className={classes.button} onClick={handleClick}>
        <img src={AppleStoreIcon} alt="" srcSet="" />
        {APPLE_STORE}
      </Button>
    );
  if (store === GOOGLE)
    return (
      <Button {...rest} className={classes.button} onClick={handleClick}>
        <img src={GooglePlayIcon} alt="" srcSet="" />
        {GOOGLE_PLAY}
      </Button>
    );

  return <></>;
}

export default CustomizedButtonDownloadApp;
