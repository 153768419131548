import React from 'react';
import { isServiceNoticeCheck } from '@utils/notice';
import PreNoticePresenter from '@components/Dialog/PreNoticePresenter';
import ServiceUrgentNoticePresenter from '@components/Dialog/ServiceUrgentNoticePresenter';
import LayoutDashboardPresenter from './LayoutDashboardPresenter';

interface Props {
  contentShift?: boolean;
  asideDrawer?: React.ReactNode;
  sideBarRight?: React.ReactNode;
  matches?: boolean;
  extraBtn?: React.ReactNode;
  gnbAppBar?: React.ReactNode;
  header?: React.ReactNode;
  checkedFiles?: any;
  isShare?: boolean;
}

const LayoutDashboardContainer: React.FC<Props> = ({
  contentShift,
  asideDrawer,
  sideBarRight,
  extraBtn,
  gnbAppBar,
  children,
  header,
  checkedFiles,
  isShare
}) => {
  const isServiceNotice = isServiceNoticeCheck();
  return (
    <LayoutDashboardPresenter
      checkedFiles={checkedFiles}
      isShare={isShare}
      contentShift={contentShift}
      asideDrawer={asideDrawer}
      sideBarRight={sideBarRight}
      extraBtn={extraBtn}
      gnbAppBar={gnbAppBar}
      header={header}
    >
      {isServiceNotice.isOpenPreNotice && <PreNoticePresenter />}
      {isServiceNotice.isOpenUrgentNotice && <ServiceUrgentNoticePresenter />}
      {children}
    </LayoutDashboardPresenter>
  );
};

export default LayoutDashboardContainer;
