import i18n from '../../i18n';
import { blockSpecialRegex } from '@routes/Common/types';
import { nameRegex, passwordRegex } from '@utils/regex';

const pleaseEnterYourNameTextI18n =
  i18n.t('sign_up.please_enter_your_name') || '';

const validUsernameMaxLengthI18n =
  i18n.t('sign_up.valid_username_max_length') || '';

export const validationForm = {
  email: {
    required: { value: true, message: i18n.t('sign_up.required_email') },
    pattern: {
      value:
        /[-A-Za-z0-9_]+[-A-Za-z0-9_.]*[@]{1}[-A-Za-z0-9_]+[-A-Za-z0-9_.]*[.]{1}[A-Za-z]{1,5}/,
      message: i18n.t('sign_up.valid_email')
    }
  },
  code: {
    required: { value: true, message: i18n.t('sign_up.code') }
  },
  userName: {
    required: {
      value: true,
      message: pleaseEnterYourNameTextI18n
    },
    pattern: {
      value: nameRegex,
      message: '한글 또는 영어로만 작성 가능하며 특수문자가 포함될 수 없습니다.'
    },
    maxLength: {
      value: 50,
      message: validUsernameMaxLengthI18n
    },
    validate: {
      onlySpace: (value: any) =>
        typeof value === 'string'
          ? value.trim().length > 0 || pleaseEnterYourNameTextI18n
          : true,
      specialText: (value: any) => {
        return typeof value === 'string'
          ? !blockSpecialRegex.test(value) || validUsernameMaxLengthI18n
          : true;
      }
    }
  },
  password: {
    required: { value: true, message: i18n.t('sign_up.required_password') },
    pattern: {
      value: /^[a-zA-Z0-9]*$/,
      message: i18n.t('sign_up.validation_pattern_password')
    },
    validate: {
      specialText: (value: string) => {
        return passwordRegex.test(value);
      }
    }
  },
  inflowRoute: { required: true },
  privacyAgree: { required: true },
  serviceAgree: { required: true },
  promotionAgree: {}
};

export type TDialogKind =
  | 'serviceAgree'
  | 'consentAgree'
  | 'privacyAgree'
  | 'promotionAgree'
  | undefined;

export interface AccountReq {
  email?: string;
  code?: string;
  password?: string;
  userName?: string;
  inflowRoute?: Array<string>;
  serviceAgree?: boolean;
  privacyAgree?: boolean;
  promotionAgree?: boolean;
}
