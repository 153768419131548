import { createReducer } from 'typesafe-actions';
import {
  SharedWithGroupsAction,
  SharedWithGroupsState,
  initialSharedWithGroupsState
} from './types';
import {
  SET_SELECTED_GROUP_FILES,
  INIT_SHARED_WITH_GROUPS_STATE
} from './actions';

export const sharedWithGroups = createReducer<
  SharedWithGroupsState,
  SharedWithGroupsAction
>(initialSharedWithGroupsState, {
  [SET_SELECTED_GROUP_FILES]: (state, action) => {
    return {
      ...state,
      ...action.payload
    };
  },
  [INIT_SHARED_WITH_GROUPS_STATE]: () => initialSharedWithGroupsState
});
