import { Box, makeStyles } from '@material-ui/core';
import { colorSet } from '@components/Provider';
import styled from 'styled-components';

export const useStyles = makeStyles((theme) => ({
  controlButtonList: {
    color: colorSet.gray900
  },
  capaIcon: {
    marginLeft: 22,
    cursor: 'pointer'
  },
  noDataWrapper: {
    height: `calc(100vh - 121px)`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& img': {
      '-webkit-user-drag': 'none',
      '-khtml-user-drag': 'none',
      '-moz-user-drag': 'none',
      '-o-user-drag': 'none',
      userDrag: 'none'
    }
  },
  noDataBox: {
    textAlign: 'center'
  },
  noDataTitle: {
    fontWeight: 'bold',
    fontSize: 20,
    lineHeight: '160%',
    letterSpacing: '-0.1px',
    marginTop: 36,
    marginBottom: 8
  },
  noDataSubTitle: {
    fontSize: 16,
    lineHeight: '150%',
    letterSpacing: '-0.1px',
    color: colorSet.gray600
  },
  submitButton: {
    marginTop: 32,
    textTransform: 'none',
    padding: '6px 27px !important'
  }
}));

export const MuiDiv = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 140px);
  &.connect-empty {
    align-items: center;
    margin-top: 100px;
    ${({ theme }) => `
      ${theme.breakpoints.down('xs')} {
        margin-top: 53px;
      }
    `}
    img {
      height: 144px;
      width: 144px;
      margin-bottom: 36px;
    }
  }
  .subtitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 42px;
    padding: 30px 0;
    padding-left: 20px;

    & .cursor-pointer {
      cursor: pointer;
    }

    ${({ theme }) => `
      ${theme.breakpoints.down('xs')} {
        position: fixed;
        top: 0px;
        z-index: 1200;
        right: 0;
      }
    `}

    .MuiBreadcrumbs-li {
      font-size: 20px;
      font-weight: 500;
      color: ${colorSet.gray900};
      &:nth-child(1) {
        font-weight: bold;
        .MuiLink-underlineHover:hover {
          text-decoration: none !important;
        }
      }
      &:last-child {
        pointer-events: none;
        cursor: auto;
      }
    }
    .label-group {
      cursor: pointer;
      display: flex;
      align-items: center;
      .MuiSvgIcon-root {
        color: ${colorSet.gray900};
        margin-right: 4px;
      }
    }
    ${({ theme }) => `
      ${theme.breakpoints.down('xs')} {
        height: 56px;
        margin-bottom: 0px;
        padding: 0px 16px;
      }
    `}
  }
`;

export const MuiBoxShareTable = styled(Box)`
  ${({ theme }) => `
    ${theme.breakpoints.down('xs')} {
      padding-bottom: 57px;
      width: 100vw;
      overflow-x: hidden;
    }
  `}
  .table-header {
    border-top: 1px solid ${colorSet.gray200};
    border-bottom: 1px solid ${colorSet.gray200};
    padding: 20px 8px;
    padding-left: 20px;
    display: flex;
    align-items: center;
    height: 44px;
    color: ${colorSet.gray700};

    .table-header-th-one {
      width: calc(100% - 120px - 148px);
    }
    .table-header-th-two {
      width: 130px;
    }
    .table-header-th-three {
      width: 120px;
    }
    .table-header-th-four {
      width: 148px;
    }
    .table-header-th-five {
      width: 84px;
    }

    .table-header-th-group-name {
      width: calc(100% - 220px - 288px);
    }
    .table-header-th-my-access {
      width: 220px;
    }
    .table-header-th-shared-with {
      width: 288px;
    }

    .table-header-th-file-name {
      width: calc(100% - 260px - 140px - 140px);
    }

    .table-header-th-file-owner {
      width: 260px;
    }

    .table-header-th-shared-date {
      width: 140px;
    }

    .table-header-th-file-size {
      width: 140px;
    }
  }
`;

export const MuiBoxTableItemShareMobile = styled(Box)`
  padding: 16px;
  border-bottom: 1px solid ${colorSet.gray200};
  &:first-child {
    border-top: 1px solid ${colorSet.gray200};
  }
  display: flex;
  cursor: pointer;

  .table-content {
    display: grid;
    margin-left: 16px;
    width: 100%;

    .MuiTypography-colorTextSecondary {
      color: ${colorSet.gray700};
    }
    .customized-typography {
      text-align: left;
    }
    .content {
      text-transform: capitalize;
    }
  }
  .content-right {
    color: ${colorSet.gray700};
    font-size: 12px;
  }
  .folder-icon {
    color: ${colorSet.gray600};
  }
  .checkbox-wrapper {
    display: flex;
    align-items: center;
    margin-right: 8px;
  }
  .more-vert-menu {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
  .button-long-press {
    display: flex;
    cursor: pointer;
    width: 100%;
    background: transparent;
    border: none;
    padding: 0;
    align-items: center;
  }
  .table-item-share-mobile {
    display: flex;
    align-items: center;
  }
  .table-item-share-icon-mobile {
    color: ${colorSet.gray550};
    margin-right: 5px;
    font-size: 20px;
  }
  .folder-shared-icon {
    color: ${colorSet.gray600};
    width: 36px !important;
    height: 36px;
  }
`;

export const MuiBoxTableShareItemMobile = styled(Box)`
  padding: 16px;
  border-bottom: 1px solid ${colorSet.gray200};
  &:first-child {
    border-top: 1px solid ${colorSet.gray200};
  }
  display: flex;
  cursor: pointer;
  width: 100vw;
  overflow: hidden;

  .table-content {
    display: grid;
    margin-left: 16px;
    width: 100%;

    .file-name {
      display: flex;
      align-items: center;

      .ibm-cad-icon {
        margin-right: 4px;
      }

      .cs-file-name {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .MuiTypography-colorTextSecondary {
      color: ${colorSet.gray700};
    }
    .customized-typography {
      text-align: left;
    }
  }
  .folder-icon {
    color: ${colorSet.gray600};
  }
  .checkbox-wrapper {
    display: flex;
    align-items: center;
    margin-right: 8px;
  }
  .more-vert-menu {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
  .button-long-press {
    display: flex;
    cursor: pointer;
    width: 100%;
    background: transparent;
    border: none;
    padding: 0;
    align-items: center;
  }
  .table-item-share-mobile {
    display: flex;
    align-items: center;
  }
  .table-item-share-icon-mobile {
    color: ${colorSet.gray550};
    margin-right: 5px;
    font-size: 20px;
  }

  .d-none {
    display: none !important;
  }
`;
