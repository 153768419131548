import { CapaConnectMyFile } from '@generated/Common/graphql';
import { ActionType } from 'typesafe-actions';
import * as actions from './actions';

export interface DashboardState {
  files?: Array<CapaConnectMyFile>;
}

export const initialDashboardState: DashboardState = {};

export type DashboardAction = ActionType<typeof actions>;
