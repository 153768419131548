import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  BottomNavigation,
  Badge,
  useTheme,
  useMediaQuery,
  ButtonBase
} from '@material-ui/core';
import * as Apollo from '@apollo/client';
import {
  groupPathName,
  myDrivePathName,
  activityPathName,
  sharedWithGroupsPathName,
  myContactsPathName,
  myContactsRecommendedPathName,
  CONNECT_UPDATE_TYPE
} from '@routes/Common/types';
import useMyDrive from '@hooks/Drive/MyDrive/useMyDrive';
import {
  SubscribeCAPANotification,
  SubscribeConnectUpdateData
} from '@generated/Drive/MyDrive/graphql';
import NavItem from './NavItem';
import { NavWrapper } from './styles';
import useCommon from '@hooks/Common/useCommon';
import {
  SERVICE,
  DEFAULT_ITEMS_LIMIT,
  DEFAULT_ITEMS_OFFSET,
  DEFAULT_LAST_SEQ
} from '@components/Notifications/types';
import {
  DriveMobile,
  DriveMobileActive,
  GroupsMobile,
  GroupsMobileActive,
  ContactsMobile,
  ActivityMobile,
  ActivityMobileActive,
  MoreMobile,
  MoreMobileActive,
  ContactsMobileActive
} from '@assets';
import { useSubscription } from '@apollo/client';
import useGroup from '@hooks/Group/useGroup';
import { useStylesBottomNav } from './styles';
import useNotification from '@hooks/useNotification';

interface Props {
  callback?: (filesId: any) => void;
}

const BottomNavMenuPresenter: React.FC<Props> = ({ callback }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStylesBottomNav();
  const { t } = useTranslation();
  const { pathname } = useLocation<any>();
  const history = useHistory();
  const { onSetTotalNotification, myDrive } = useMyDrive();
  const { onFetchConnectGroupChatRooms, connectGroupChatRooms } = useGroup();
  const { common, onShowHideNumberNotice, onSetUnReadCountGroupList } =
    useCommon();
  const { onFetchCAPANotification, connectNotification } = useNotification();
  const connectGroupChatRoomsData =
    connectGroupChatRooms?.fetchConnectGroupChatRooms?.data || [];

  const fetchConnectGroupChatRooms = () => {
    onFetchConnectGroupChatRooms({
      variables: {
        limit: DEFAULT_ITEMS_LIMIT,
        offset: DEFAULT_ITEMS_OFFSET
      }
    });
  };

  useEffect(() => {
    if (connectGroupChatRoomsData?.length > 0) {
      let total = 0;
      connectGroupChatRoomsData?.forEach((item: any) => {
        total += item.unReadCount;
      });
      onSetUnReadCountGroupList({
        unReadCountGroupList: total
      });
    }
  }, [connectGroupChatRoomsData]);

  const { data: subscribeCAPANotification } = Apollo.useSubscription(
    SubscribeCAPANotification,
    {
      variables: { serviceType: 'CONNECT' }
    }
  );

  useSubscription(SubscribeConnectUpdateData, {
    onSubscriptionData: (subscriptionData) => {
      switch (
        subscriptionData?.subscriptionData?.data?.subscribeConnectUpdateData
          ?.data?.type
      ) {
        case CONNECT_UPDATE_TYPE.CHAT_UPDATE:
        case CONNECT_UPDATE_TYPE.MEMBER_UPDATE:
        case CONNECT_UPDATE_TYPE.GROUP_UPDATE: {
          fetchConnectGroupChatRooms();
          break;
        }
      }
    },
    skip: !isMobile
  });

  useEffect(() => {
    fetchConnectGroupChatRooms();
    onFetchCAPANotification({
      variables: {
        limit: DEFAULT_ITEMS_LIMIT,
        offset: DEFAULT_ITEMS_OFFSET,
        service: SERVICE.CONNECT,
        lastSeq: DEFAULT_LAST_SEQ
      }
    });
  }, []);

  useEffect(() => {
    if (
      connectNotification &&
      connectNotification?.fetchCAPANotification &&
      connectNotification?.fetchCAPANotification?.data &&
      connectNotification?.fetchCAPANotification?.data?.notifications.length > 0
    )
      onSetTotalNotification({
        totalNotification:
          connectNotification?.fetchCAPANotification?.data?.count || 0
      });
  }, [connectNotification?.fetchCAPANotification?.data?.notifications]);

  useEffect(() => {
    if (subscribeCAPANotification) {
      onShowHideNumberNotice({
        isShowNumberNotification: true
      });
      onSetTotalNotification({
        totalNotification:
          subscribeCAPANotification?.subscribeCAPANotification?.data?.count
      });
    }
  }, [subscribeCAPANotification]);

  const onClickHandle = (value: string) => {
    if (value !== 'upload') {
      history.push(value);
    }
  };
  return (
    <BottomNavigation
      className={classes.root}
      value={pathname}
      onChange={(event, newValue) => {
        onClickHandle(newValue);
      }}
      showLabels
    >
      <NavWrapper>
        <ButtonBase component="div" centerRipple={true} focusRipple={true}>
          <NavItem
            path={[myDrivePathName, sharedWithGroupsPathName]}
            icon={<img src={DriveMobile} alt="DriveMobile" />}
            activeIcon={<img src={DriveMobileActive} alt="DriveMobileActive" />}
            label={t('gnb.drive')}
            redirectTo={myDrivePathName}
          />
        </ButtonBase>
        <ButtonBase component="div" centerRipple={true} focusRipple={true}>
          <NavItem
            path={groupPathName}
            icon={
              <Badge
                showZero={false}
                invisible={common.unReadCountGroupList! === 0}
                badgeContent={common.unReadCountGroupList!}
                color="error"
                max={999}
              >
                <img src={GroupsMobile} alt="GroupsMobile" />
              </Badge>
            }
            activeIcon={
              <Badge
                showZero={false}
                invisible={common.unReadCountGroupList! === 0}
                badgeContent={common.unReadCountGroupList!}
                color="error"
                max={999}
              >
                <img src={GroupsMobileActive} alt="GroupsMobileActive" />
              </Badge>
            }
            label={t('gnb.group')}
            redirectTo={groupPathName}
          />
        </ButtonBase>
        <ButtonBase component="div" centerRipple={true} focusRipple={true}>
          <NavItem
            path={[myContactsPathName, myContactsRecommendedPathName]}
            icon={<img src={ContactsMobile} alt="ContactsMobile" />}
            activeIcon={
              <img src={ContactsMobileActive} alt="ContactsMobileActive" />
            }
            label={t('gnb.contacts')}
            redirectTo={myContactsPathName}
          />
        </ButtonBase>
        <ButtonBase component="div" centerRipple={true} focusRipple={true}>
          <NavItem
            path={activityPathName}
            icon={
              <Badge
                showZero={false}
                invisible={
                  !common.isShowNumberNotification || !myDrive.totalNotification
                }
                badgeContent={myDrive.totalNotification}
                color="error"
              >
                <img src={ActivityMobile} alt="ActivityMobile" />
              </Badge>
            }
            activeIcon={
              <Badge
                showZero={false}
                invisible={
                  !common.isShowNumberNotification || !myDrive.totalNotification
                }
                badgeContent={myDrive.totalNotification}
                color="error"
              >
                <img src={ActivityMobileActive} alt="ActivityMobileActive" />
              </Badge>
            }
            label={t('gnb.activity')}
            redirectTo={activityPathName}
          />
        </ButtonBase>
        <ButtonBase component="div" centerRipple={true} focusRipple={true}>
          <NavItem
            path="/mypage"
            icon={<img src={MoreMobile} alt="MoreMobile" />}
            activeIcon={<img src={MoreMobileActive} alt="MoreMobileActive" />}
            label={t('gnb.more')}
            redirectTo="/mypage"
          />
        </ButtonBase>
      </NavWrapper>
    </BottomNavigation>
  );
};

export default BottomNavMenuPresenter;
