import dayjs from 'dayjs';

export const formatMoreUser = (val: any) => {
  if (val && val.length > 3) {
    return `+${val.length - 4}`;
  }
  return '';
};

export const formatDateToString = (date: string) => {
  return dayjs(date).format('MMM DD, YYYY');
};

export const IS_MAC_OS = () => {
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const platform = window.navigator.platform;
  return macosPlatforms.indexOf(platform) !== -1;
};

export const isChildrenPage = (pathname: string, parent: string) => {
  const pathNameSplit = pathname.split('/').filter((path: string) => !!path);
  return pathNameSplit.length > 1 && pathNameSplit.includes(parent);
};
