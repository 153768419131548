import { createReducer } from 'typesafe-actions';
import { GroupAction, GroupState, initialGroupState } from './types';
import {
  SET_CREATE_GROUP_SCREEN,
  INIT_GROUP_STATE,
  SET_SELECTED_GROUP
} from './actions';

export const group = createReducer<GroupState, GroupAction>(initialGroupState, {
  [SET_CREATE_GROUP_SCREEN]: (state, action) => {
    return {
      ...state,
      ...action.payload
    };
  },
  [SET_SELECTED_GROUP]: (state, action) => {
    return {
      ...state,
      ...action.payload
    };
  },
  [INIT_GROUP_STATE]: () => initialGroupState
});
