import { makeStyles } from '@material-ui/core';
import { colorSet } from '@components/Provider';

export const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 2001,
    [theme.breakpoints.down('xs')]: {
      width: '100% !important',
      bottom: '-4px !important',
      left: '0 !important',
      position: 'fixed !important',
      top: 'unset !important',
      transform: 'none !important'
    }
  },
  boxRoot: {
    minWidth: 343,
    borderRadius: 8,
    backgroundColor: colorSet.gray000,
    padding: '8px 0px',
    boxShadow:
      ' 0px 5px 5px -3px rgba(0, 0, 0, 0.1), 0px 8px 10px 1px rgba(0, 0, 0, 0.07), 0px 3px 14px 2px rgba(0, 0, 0, 0.06)'
  },
  list: {
    padding: 0
  },
  lineBottom: {
    margin: '8px 0',
    borderBottom: `1px solid ${colorSet.gray200}`
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 16px',
    cursor: 'pointer',
    height: 44,
    '& p': {
      fontSize: 14,
      fontWeight: '500'
    },
    '& img': {
      marginRight: 12,
      [theme.breakpoints.down('xs')]: {
        width: 24,
        height: 24
      }
    },
    '&:hover': {
      backgroundColor: colorSet.gray100
    }
  },
  menuIcon: {
    cursor: 'pointer'
  },
  customSvg: {
    '& svg': {
      fill: `${colorSet.gray550} !important`
    }
  },
  backdrop: {
    zIndex: 1999,
    [theme.breakpoints.down('xs')]: {
      background: `${colorSet.gray2000}`,
      opacity: '0.5 !important'
    }
  }
}));
