import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import { SHARE_DIALOG_SCREEN } from '@routes/Dashboard/Dialog/Share/types';
import useMyDrive from '@hooks/Drive/MyDrive/useMyDrive';
import {
  DialogActions,
  TextField,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import useUserActionLog, {
  CapaActivityType,
  Enum_Action_Button_Enum,
  Enum_Action_Input_Enum
} from '@hooks/useUserActionLog';
import { IBMTagGroup, IBMMultiUser, IBMDocumentAttachment } from '@assets';
import Members from '@routes/Dashboard/Dialog/Share/CreateNewGroup/Members';
import ShareLink from '@routes/Dashboard/Dialog/Share/ShareLink/ShareLink';
import { CustomizedButton } from '@components/Customized';
import {
  CapaErrorCode,
  useCreateGroupNameMutation,
  useInviteGroupMembersNameMutation
} from '@generated/Common/graphql';
import { useShareFilesToGroupMutation } from '@generated/Drive/MyDrive/graphql';
import { checkShareTimes } from '@routes/Dashboard/Dialog/Share/utils';
import useCommon from '@hooks/Common/useCommon';
import { CONNECT_USER_FILE_STATUS } from '@routes/Dashboard/Dialog/Share/ShareLink/types';
import { DOWNLOAD_PERMISSION_STATUS } from '@routes/Dashboard/types';
import useAuth from '@hooks/useAuth';
import { getGroupNameByUsernameOrEmail } from '@utils/common';
import Attachments from '@routes/Dashboard/Dialog/Share/CreateNewGroup/Attachments';
import Dropzone from '@routes/Common/Dialog/UploadFiles/Dropzone';
import InsertFromMyDriveContainer from '@routes/Dashboard/Dialog/Share/CreateNewGroup/InsertFromMyDriveContainer';
import { newFileUploadApi } from '@api/index';
import { uniqueArray } from '@utils/formatter';
import { LIMIT_CHARACTERS_GROUP_NAME } from '@routes/Common/types';
import Loading from '@components/LoadingInComponent';
import { colorSet } from '@components/Provider';
import UploadFilesButton from '@routes/Dashboard/Dialog/Share/CreateNewGroup/UploadFilesButton';
import clsx from 'clsx';

interface Props {
  checkedFiles?: any;
  checkedUsers?: any;
  fileOwner?: any;
  isShowUploadFiles?: boolean;
  isFilesPage?: boolean;
  createNewGroupHeader?: React.ReactNode;
}

const CreateNewGroup = (props: Props) => {
  const {
    checkedFiles,
    checkedUsers,
    fileOwner,
    createNewGroupHeader,
    isFilesPage,
    isShowUploadFiles
  } = props;
  const { t } = useTranslation();
  const {
    onSetShareDialogScreen,
    onSetValueGroupNameDialog,
    onSetShowHideShareDialog,
    onSetIdGroupName,
    onSetchatRoomIdGroupName,
    onSetSuccessfullyShareContent
  } = useMyDrive();
  const { onInsertUserActionLogOne } = useUserActionLog();
  const [groupName, setGroupName] = useState<string>('');
  const [createGroupNameMutation] = useCreateGroupNameMutation();
  const [inviteGroupMembersNameMutation] = useInviteGroupMembersNameMutation();
  const [ShareFilesToGroup] = useShareFilesToGroupMutation();
  const { openFeedbackDialog } = useCommon();
  const [internalMemberList, setInternalMemberList] = useState<any>([]);
  const [fileSettings, setFileSettings] = useState<any>(
    CONNECT_USER_FILE_STATUS.DOWNLOADABLE
  );
  const { userState } = useAuth();
  const [isLoading, setLoading] = useState<any>(false);
  const [internalFiles, setInternalFiles] = useState<any>([]);
  const [isShowHideUploadFilesDialog, setShowHideUploadFilesDialog] =
    useState<boolean>(false);
  const [internalIsDragDrop, setInternalIsDragDrop] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const setMemberList = (memberList: any) => {
    setInternalMemberList(memberList);
  };

  const shareFilesToGroup = (payload: any) => {
    ShareFilesToGroup({ variables: payload }).then((res) => {
      const result = res?.data?.shareFilesToGroup?.result;
      if (result === CapaErrorCode.Success) {
        setLoading(false);
        onSetSuccessfullyShareContent({
          successfullyShareContent: 'share_modal.successfully.group_created'
        });
        onSetShareDialogScreen({
          shareDialogScreen: SHARE_DIALOG_SCREEN.SUCCESSFULLY_SHARED
        });
        checkShareTimes(() => {
          openFeedbackDialog({
            isOpenFeedback: true,
            isFeedback: true
          });
        });
      }
    });
  };

  const shareFilesToGroupNormal = (groupId: string) => {
    const internalPermission: string =
      isFilesPage && isFilesPage
        ? checkedFiles[0]?.filePermission
        : fileSettings;
    const payload: any = {
      groupId: groupId,
      connectFileIds: checkedFiles?.map((file: any) => file.id),
      permission:
        internalPermission === CONNECT_USER_FILE_STATUS.VIEWABLE
          ? DOWNLOAD_PERMISSION_STATUS.DENIED
          : DOWNLOAD_PERMISSION_STATUS.ALLOW
    };
    shareFilesToGroup(payload);
  };

  const shareFilesToGroupHasUploadFiles = (groupId: string) => {
    const fileFromComputer =
      internalFiles?.filter((file: any) => file.isFromComputer) ?? [];
    const fileFromMyDrive =
      internalFiles?.filter((file: any) => !file.isFromComputer) ?? [];
    if (fileFromComputer?.length > 0) {
      newFileUploadApi(
        fileFromComputer?.map((item: any) => item?.file),
        ''
      ).then(async (responses) => {
        const fileIds = responses.map((res) => res.data.data.data[0].id);

        const payload: any = {
          groupId: groupId,
          connectFileIds: [
            ...fileIds,
            ...internalFiles
              ?.filter((file: any) => !file.isFromComputer)
              ?.map((item: any) => item?.id)
          ],
          permission:
            fileSettings === CONNECT_USER_FILE_STATUS.VIEWABLE
              ? DOWNLOAD_PERMISSION_STATUS.DENIED
              : DOWNLOAD_PERMISSION_STATUS.ALLOW
        };
        shareFilesToGroup(payload);
      });
    } else {
      const payload: any = {
        groupId: groupId,
        connectFileIds: fileFromMyDrive?.map((file: any) => file.id),
        permission:
          fileSettings === CONNECT_USER_FILE_STATUS.VIEWABLE
            ? DOWNLOAD_PERMISSION_STATUS.DENIED
            : DOWNLOAD_PERMISSION_STATUS.ALLOW
      };
      shareFilesToGroup(payload);
    }
  };

  const onSetPermission = (permission: any) => {
    setFileSettings(permission);
  };

  const onSubmit = () => {
    setLoading(true);
    onInsertUserActionLogOne(
      Enum_Action_Button_Enum.Button_CreateGroup_AddMembersModal_Create,
      CapaActivityType.ButtonClick
    );
    let membersName: any = internalMemberList?.map((member: any) => {
      return {
        connectUserName: member.userName,
        email: member.email
      };
    });

    membersName.unshift({
      connectUserName: userState?.data?.userName,
      email: userState?.data?.email
    });

    createGroupNameMutation({
      variables: {
        groupName:
          groupName.trim() || getGroupNameByUsernameOrEmail(membersName)
      }
    }).then((res: any) => {
      const result = res.data.createGroup;
      if (result.result === CapaErrorCode.Success) {
        onSetValueGroupNameDialog({
          valueGroupNameDialog: groupName.trim()
            ? groupName.trim()
            : getGroupNameByUsernameOrEmail(membersName)
        });
        onSetIdGroupName({ idGroupName: result.data.id });
        onSetchatRoomIdGroupName({
          chatRoomIdGroupName: result.data.chatRoomId
        });
        let payload = {
          groupId: result.data.id || '',
          inviteUsers: internalMemberList?.map((member: any) => {
            return {
              email: member.email,
              accessLevel: member.accessLevel
            };
          })
        };
        inviteGroupMembersNameMutation({
          variables: payload
        }).then((res: any) => {
          if (res.data.inviteGroupMembers.result === CapaErrorCode.Success) {
            if (isShowUploadFiles) {
              shareFilesToGroupHasUploadFiles(result.data.id);
            } else {
              shareFilesToGroupNormal(result.data.id);
            }
          }
        });
      }
    });
  };

  const onDropAccepted = (files: any) => {
    const internalFiles = files?.map((file: any) => {
      return {
        file,
        isFromComputer: true,
        fileName: file?.name
      };
    });
    setInternalFiles((prevState: any) => [...prevState, ...internalFiles]);
  };

  const setInternalFilesFromAttachments = (files: any) => {
    setInternalFiles(files);
  };

  const onSetShowHideUploadFilesDialog = (isShowHide: boolean) => {
    setShowHideUploadFilesDialog(isShowHide);
  };

  const onSetFilesUpload = (files: any) => {
    setInternalFiles((prevState: any) => {
      return [
        ...prevState.filter((prev: any) => prev.isFromComputer),
        ...uniqueArray(
          [...files, ...prevState.filter((prev: any) => !prev.isFromComputer)],
          'id'
        )
      ];
    });
  };

  const setIsDragDrop = (isDragDrop: any) => {
    setInternalIsDragDrop(isDragDrop);
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        {createNewGroupHeader}
        {isMobile && isShowUploadFiles ? (
          <UploadFilesButton
            onDropAccepted={onDropAccepted}
            onClickCloudIcon={() => setShowHideUploadFilesDialog(true)}
          />
        ) : null}
      </div>
      <Dropzone
        noClick={true}
        noKeyboard={true}
        noDrag={!isShowUploadFiles}
        onDropAccepted={onDropAccepted}
        setIsDragDrop={setIsDragDrop}
      >
        <div className={classes.content}>
          {internalIsDragDrop ? (
            <div className={classes.backdrop}>
              <div className="content">
                <img src={IBMDocumentAttachment} alt="" />
                <div className={classes.dropFilesHereText}>
                  {t('create_new_group_modal.drop_files_here')}
                </div>
              </div>
            </div>
          ) : null}
          <div className={classes.labelGroup}>
            <div className={classes.label}>
              <img src={IBMTagGroup} alt="" />
              <div className="text">
                {t('create_new_group_modal.group_name')}
              </div>
            </div>
            <TextField
              value={groupName ?? undefined}
              placeholder={t('group_name_modal.placeholder')}
              variant="outlined"
              size="small"
              fullWidth
              onChange={(event) =>
                setGroupName && setGroupName(event.target.value)
              }
              onFocus={() => {
                onInsertUserActionLogOne(
                  Enum_Action_Input_Enum.Input_GroupNameModal_GroupName,
                  CapaActivityType.actionInput
                );
              }}
              className={classes.groupNameInput}
            />
          </div>
          <div className={clsx(classes.labelGroup, classes.membersText)}>
            <div className={classes.label}>
              <img src={IBMMultiUser} alt="" className="member-img" />
              <div className="text">{t('create_new_group_modal.members')}</div>
            </div>
            <Members
              setMemberList={setMemberList}
              checkedUsers={checkedUsers}
              fileOwner={fileOwner}
            />
          </div>
          {isShowUploadFiles ? (
            <div className={classes.uploadFilesWrapper}>
              <div className="header">
                <div className="attachments-text">
                  {t('share_modal.share_link.attachments')}
                </div>
                {!isMobile ? (
                  <UploadFilesButton
                    onDropAccepted={onDropAccepted}
                    onClickCloudIcon={() => setShowHideUploadFilesDialog(true)}
                  />
                ) : null}
              </div>
              <Attachments
                setInternalFiles={setInternalFilesFromAttachments}
                checkedFiles={internalFiles}
                setPermission={onSetPermission}
                isShowUploadFiles={isShowUploadFiles}
              />
            </div>
          ) : (
            <ShareLink
              leftHeader={
                <div className={classes.attachments}>
                  {t('share_modal.share_link.attachments')}
                </div>
              }
              fileOwner={fileOwner}
              isNewGroup={true}
              checkedFiles={checkedFiles}
              setPermission={onSetPermission}
            />
          )}
        </div>
      </Dropzone>
      <DialogActions classes={{ root: classes.groupsAction }}>
        <div className={classes.buttonWrapper}>
          <CustomizedButton
            variant="contained"
            color="default"
            onClick={() => {
              onSetShowHideShareDialog({ isShowHideShareDialog: false });
            }}
          >
            {t('groups_you_are_joined.cancel_button')}
          </CustomizedButton>
          <CustomizedButton
            type="submit"
            variant="contained"
            color="primary"
            disabled={
              isLoading || groupName.length > LIMIT_CHARACTERS_GROUP_NAME
            }
            onClick={() => {
              onSubmit();
            }}
          >
            {isLoading ? (
              <Loading
                open={true}
                size={16}
                color={colorSet.gray700}
                styles={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
              />
            ) : (
              t('groups_you_are_joined.done_button')
            )}
          </CustomizedButton>
        </div>
      </DialogActions>
      {isShowHideUploadFilesDialog ? (
        <InsertFromMyDriveContainer
          setShowHideUploadFilesDialog={onSetShowHideUploadFilesDialog}
          isShowHideUploadFilesDialog={isShowHideUploadFilesDialog}
          setFilesUpload={onSetFilesUpload}
          filesUploaded={
            internalFiles?.filter((file: any) => !file.isFromComputer) ?? []
          }
        />
      ) : null}
    </div>
  );
};

export default CreateNewGroup;
