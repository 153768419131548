import React, { useCallback, useEffect, useRef, useState } from 'react';
import { BottomNavMenu, GnbAppBar, LayoutDashboard } from '@routes/Common';
import {
  Backdrop,
  useMediaQuery,
  useTheme,
  Popper,
  Box,
  List,
  ListItem,
  Popover
} from '@material-ui/core';
import { useStyles } from './styles';
import { AddOutlined } from '@material-ui/icons';
import { CustomizedButton } from '@components/Customized';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import useMyDrive from '@hooks/Drive/MyDrive/useMyDrive';
import useGroup from '@hooks/Group/useGroup';
import { useSnackbar } from '@components/Provider';
import DeleteGroupContainer from '@routes/Group/Dialog/DeleteGroup/DeleteGroupContainer';
import LeaveGroupModal from '@routes/Dashboard/Dialog/Share/LeaveGroup/LeaveGroupModal';
import useDebounce from '@hooks/useDebounce';
import { useHistory, useLocation } from 'react-router-dom';
import useSharedWithGroups from '@hooks/Drive/MyDrive/useSharedWithGroups';
import Loading from '@components/LoadingInComponent';
import { formatTimeStamp } from '@utils/formatter';
import {
  ACCESS_LEVEL_VALUE,
  SHARE_DIALOG_SCREEN
} from '@routes/Dashboard/Dialog/Share/types';
import {
  CONNECT_UPDATE_TYPE,
  groupRoomPathName,
  notFoundPathName,
  chatBotEmail,
  CHAT_BOT_GROUP_MESSAGE_VALUE,
  DEFAULT_ITEMS_LIMIT,
  CHAT_BOT_NAME,
  CAPA_FILE_CONVERT_STATUS
} from '@routes/Common/types';
import {
  SubscribeConnectUpdateData,
  SubscribeFileStatusUpdate
} from '@generated/Drive/MyDrive/graphql';
import { useSubscription } from '@apollo/client';
import LeaveLastMemberGroupModal from '@routes/Dashboard/Dialog/LeaveLastMemberGroup/LeaveLastMemberGroupModal';
import useUserActionLog, {
  CapaActivityType,
  Enum_Action_Button_Enum,
  Enum_Action_Input_Enum,
  Enum_Action_Page_Enum
} from '@hooks/useUserActionLog';
import { LongPressDetectEvents, useLongPress } from 'use-long-press';
import { LongPressActionTimeOut } from '@routes/Dashboard/types';
import Thumbnail from '@routes/Group/Thumbnail/index';
import useAuth from '@hooks/useAuth';
import { CapaErrorCode } from '@generated/Common/graphql';
import { Tooltip } from '@material-ui/core';
import { CapaGroupChatBot, IBMClose } from '@assets';
import RegisterUserContainer from '@routes/Common/Dialog/RegisterUser/RegisterUserContainer';
import { getStorageItem, STORAGE_ACTION } from '@utils/storage';
import queryString from 'query-string';
import WelcomeContainer from '@routes/Common/Dialog/Welcome/WelcomeContainer';
import {
  getGroupListUsers,
  getGroupNameByUsernameOrEmail
} from '@utils/common';
import ShareContainerDialog from '@routes/Dashboard/Dialog/Share/ShareContainer';
import { ReactComponent as IBMChevronLeft } from '../../assets/icon/IBMChevronLeft.svg';
import { ReactComponent as IBMAddCommentRight } from '../../assets/icon/IBMAddCommentRight.svg';
import { ReactComponent as IBMSearch } from '../../assets/icon/IBMSearch.svg';
import { ReactComponent as IBMOverflowMenuHorizontal } from '../../assets/icon/IBMOverflowMenuHorizontal.svg';
import { ReactComponent as IBMDelete } from '../../assets/icon/IBMDelete.svg';
import { ReactComponent as IBMExit } from '../../assets/icon/IBMExit.svg';
import { ReactComponent as IBMCloseEl } from '../../assets/icon/IBMClose.svg';
import AsideMenu from '@routes/Group/AsideMenu';
import {
  ContextMenu,
  MenuItem as ContextMenuItem,
  ContextMenuTrigger
} from 'react-contextmenu';
import { ContextMenuTable } from '@routes/Dashboard/styles';

const GroupContainer: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const [isSearch, setIsSearch] = useState(false);
  const [value, setValue] = useState('');
  const {
    group,
    onSetSelectedGroup,
    onFetchConnectGroupChatRooms,
    connectGroupChatRooms,
    connectGroupChatRoomsLoading,
    onFetchMoreConnectGroupChatRooms,
    onSearchGroups,
    connectSearchGroup,
    connectSearchGroupLoading,
    onFetchMoreSearchGroup
  } = useGroup();
  const [isShowHideDeleteGroupDialog, setShowHideDeleteGroupDialog] =
    useState(false);
  const [isShowHideLeaveGroupDialog, setShowHideLeaveGroupDialog] =
    useState(false);
  const { groupDataState, fetchGroupLoading } = useSharedWithGroups();
  const [groupList, setGroupList] = useState<any>([]);
  const [groupItem, setGroupItem] = useState({ accessLevel: null });
  const history = useHistory();
  const {
    myDrive,
    onSetShareDialogScreen,
    onSetShowHideShareDialog,
    onSetShowHideFileDetail
  } = useMyDrive();
  const groupListRef = useRef(null);
  const { search, state } = useLocation<any>();
  const [isOpenLeaveLastMemberGroup, setOpenLeaveLastMemberGroup] =
    useState(false);
  const { onInsertUserActionLogOne } = useUserActionLog();
  const [isOpenMenuListMobile, setOpenMenuListMobile] =
    useState<boolean>(false);
  const { userState } = useAuth();
  const groupNameQuery = new URLSearchParams(search).get('groupName');
  const confirmDeleteQuery = new URLSearchParams(search).get('confirmDelete');
  const { enqueueSnackbar } = useSnackbar();
  const [isFetching, setIsFetching] = useState(false);
  const wrapperRef = useRef<any>(null);
  const query = queryString.parse(search);
  const [isShowHideRegisterDialog, setShowHideRegisterDialog] = useState(false);
  const [hoverItem, setHoverItem] = useState<any>(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'popoverId' : undefined;

  const handleClickOpenPopup = (event: any) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopup = () => {
    setAnchorEl(null);
    onSetSelectedGroup({
      selectedGroup: []
    });
  };

  useSubscription(SubscribeFileStatusUpdate, {
    onSubscriptionData: (subscribeFileStatusUpdate) => {
      if (
        subscribeFileStatusUpdate &&
        subscribeFileStatusUpdate?.subscriptionData?.data
          ?.subscribeFileStatusUpdate?.data?.status ===
          CAPA_FILE_CONVERT_STATUS.CONVERTED
      ) {
        refetchConnectGroupChatRooms();
      }
    }
  });

  useEffect(() => {
    if (state && state.isSignUp && state.isSignUp === 'true') {
      window.history.pushState({ isSignUp: undefined }, '');
      enqueueSnackbar(t('snackbar.register_success'), {
        variant: 'success'
      });
    }
  }, [state]);

  useSubscription(SubscribeConnectUpdateData, {
    onSubscriptionData: (subscriptionData) => {
      const subscribeConnectUpdateData =
        subscriptionData?.subscriptionData?.data;
      if (subscribeConnectUpdateData) {
        switch (
          subscribeConnectUpdateData?.subscribeConnectUpdateData?.data?.type
        ) {
          case CONNECT_UPDATE_TYPE.MEMBER_UPDATE:
          case CONNECT_UPDATE_TYPE.CHAT_UPDATE:
          case CONNECT_UPDATE_TYPE.FILE_UPDATE:
          case CONNECT_UPDATE_TYPE.GROUP_UPDATE: {
            onFetchConnectGroupChatRooms({
              variables: {
                limit: 40,
                offset: 0
              }
            });
            break;
          }
        }
      }
    }
  });

  const hideContextMenu = () => {
    if (!matches) return;
    const contextMenu: any = document.querySelector('.react-contextmenu');
    contextMenu.style.opacity = '0';
    contextMenu.style.display = 'none';
    contextMenu.style.pointerEvents = 'none';
  };

  const onConTextMenu = (item: any) => {
    if (!matches) return;
    setGroupItem(item);
    const contextMenu: any = document.querySelector('.react-contextmenu');
    contextMenu.style.display = 'block';
    if (
      group.selectedGroup.filter((group: any) => group.groupId === item.groupId)
        .length > 0
    ) {
      return;
    }

    onSetSelectedGroup({
      selectedGroup: [...[item]]
    });
  };

  const removeGroupSuccess = () => {
    onFetchConnectGroupChatRooms({
      variables: {
        limit: 40,
        offset: 0
      }
    });
  };

  useEffect(() => {
    onInsertUserActionLogOne(
      Enum_Action_Page_Enum.Page_GroupList,
      CapaActivityType.PageVisit
    );
    refetchConnectGroupChatRooms();

    const connectUserResultStorage = getStorageItem(
      STORAGE_ACTION.CONNECT_USER_RESULT
    )
      ? getStorageItem(STORAGE_ACTION.CONNECT_USER_RESULT)
      : '';

    if (
      connectUserResultStorage === CapaErrorCode.NotAllowedUser ||
      (query.isOpenInputUsername &&
        connectUserResultStorage === CapaErrorCode.NotAllowedUser)
    ) {
      setShowHideRegisterDialog(true);
    }
    onSetSelectedGroup({
      selectedGroup: []
    });

    return () => {
      onSetShowHideShareDialog({ isShowHideShareDialog: false });
    };
  }, []);

  const refetchConnectGroupChatRooms = () => {
    onFetchConnectGroupChatRooms({
      variables: {
        limit: 40,
        offset: 0
      }
    });
  };

  const debouncedSearchGroup = useDebounce(value, 500);

  const callbackLongPress = useCallback(() => {
    setOpenMenuListMobile(true);
  }, []);

  const longPressHandler = useLongPress(callbackLongPress, {
    onStart: () => hideContextMenu(),
    threshold: LongPressActionTimeOut,
    captureEvent: true,
    cancelOnMovement: false,
    detect: LongPressDetectEvents.BOTH
  });

  useEffect(() => {
    if (debouncedSearchGroup) {
      onInsertUserActionLogOne(
        Enum_Action_Button_Enum.Button_GroupList_Search,
        CapaActivityType.ButtonClick
      );
      onSearchGroups({
        variables: {
          groupName: debouncedSearchGroup,
          limit: 40,
          offset: 0
        }
      });
    }
  }, [debouncedSearchGroup]);

  useEffect(() => {
    if (connectGroupChatRooms) {
      setGroupList(connectGroupChatRooms?.fetchConnectGroupChatRooms?.data);
    }
  }, [connectGroupChatRooms]);

  useEffect(() => {
    if (debouncedSearchGroup && connectSearchGroup) {
      setGroupList(connectSearchGroup?.searchGroups?.data);
    } else {
      setGroupList(connectGroupChatRooms?.fetchConnectGroupChatRooms?.data);
    }
  }, [debouncedSearchGroup, connectSearchGroup]);

  useEffect(() => {
    if (history.location.search.includes('confirmLeave')) {
      history.push({
        pathname: `${notFoundPathName}`,
        search: search,
        state: { prevLocation: window.location.href }
      });
    }
    if (confirmDeleteQuery === 'true' && groupNameQuery) {
      enqueueSnackbar(
        t('snackbar.delete_group_success', {
          groupName: groupNameQuery
        }),
        {
          variant: 'success'
        }
      );
    }
  }, [history]);

  const onScrollWrapper = () => {
    const currentWrapperRef = wrapperRef && wrapperRef.current;
    if (
      Math.ceil(currentWrapperRef.scrollTop) +
        currentWrapperRef.clientHeight !==
        currentWrapperRef.scrollHeight ||
      isFetching
    )
      return;

    setIsFetching(true);
  };

  useEffect(() => {
    if (!isFetching) return;
    setTimeout(() => {
      if (value.length > 0 && isSearch && onFetchMoreSearchGroup) {
        onFetchMoreSearchGroup({
          variables: {
            groupName: debouncedSearchGroup,
            limit: DEFAULT_ITEMS_LIMIT,
            offset: groupList?.length
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) {
              return prev;
            }

            const prevResult = prev.searchGroups?.data;
            const moreResult = fetchMoreResult.searchGroups?.data;

            if (fetchMoreResult.searchGroups.data)
              fetchMoreResult.searchGroups.data = [
                ...prevResult!,
                ...moreResult!
              ];

            setGroupList((prevResult: any) => [...prevResult, ...moreResult!]);

            return {
              ...fetchMoreResult
            };
          }
        });
        setIsFetching(false);
        return;
      }

      if (onFetchMoreConnectGroupChatRooms) {
        onFetchMoreConnectGroupChatRooms({
          variables: {
            limit: DEFAULT_ITEMS_LIMIT,
            offset: groupList?.length
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) {
              return prev;
            }

            const prevResult = prev.fetchConnectGroupChatRooms?.data;
            const moreResult = fetchMoreResult.fetchConnectGroupChatRooms.data;

            if (fetchMoreResult.fetchConnectGroupChatRooms.data)
              fetchMoreResult.fetchConnectGroupChatRooms.data = [
                ...prevResult!,
                ...moreResult!
              ];

            setGroupList((prevResult: any) => [...prevResult, ...moreResult!]);

            return {
              ...fetchMoreResult
            };
          }
        });
      }
      setIsFetching(false);
    }, 500);
  }, [isFetching]);

  useEffect(() => {
    if (
      groupDataState &&
      groupDataState.result === CapaErrorCode.GroupNotExists
    )
      return history.push(notFoundPathName);
  }, [groupDataState]);

  const onClickNewGroup = () => {
    setIsSearch(false);
    onInsertUserActionLogOne(
      Enum_Action_Button_Enum.Button_GroupList_CreateGroup,
      CapaActivityType.ButtonClick
    );
    onSetShowHideShareDialog({
      isShowHideShareDialog: true
    });
    onSetShareDialogScreen({
      shareDialogScreen: SHARE_DIALOG_SCREEN.CREATE_NEW_GROUP
    });
  };

  useEffect(() => {
    if (
      myDrive.isShowHideShareDialog &&
      myDrive.shareDialogScreen === SHARE_DIALOG_SCREEN.SUCCESSFULLY_SHARED
    ) {
      setTimeout(() => {
        onFetchConnectGroupChatRooms({
          variables: {
            limit: 40,
            offset: 0
          }
        });
      }, 500);
    }
  }, [myDrive.shareDialogScreen]);

  const actionItemOptionsEl = () => {
    return (
      <ul className="popup">
        {groupItem.accessLevel === ACCESS_LEVEL_VALUE.OWNER ? (
          <li onClick={() => setShowHideDeleteGroupDialog(true)}>
            <IBMDelete />
            <div className="text">{t('group.title_delete_group')}</div>
          </li>
        ) : null}
        {groupItem.accessLevel === ACCESS_LEVEL_VALUE.OWNER ? (
          <div className="divider" />
        ) : null}
        <li
          onClick={() => {
            if (groupItem.accessLevel === ACCESS_LEVEL_VALUE.OWNER) {
              setOpenLeaveLastMemberGroup(true);
            } else {
              setShowHideLeaveGroupDialog(true);
            }
          }}
        >
          <IBMExit />
          <div className="text">{t('group.title_leave_group')}</div>
        </li>
      </ul>
    );
  };

  return !fetchGroupLoading ? (
    <LayoutDashboard
      contentShift={!matches}
      matches={matches}
      gnbAppBar={!matches ? <GnbAppBar /> : null}
      asideDrawer={
        matches ? null : <AsideMenu clickNewGroup={onClickNewGroup} />
      }
    >
      <div className={classes.header}>
        {matches && (
          <div className={classes.icon}>
            <AddOutlined
              className="add"
              onClick={() => {
                onClickNewGroup();
              }}
            />
          </div>
        )}
        {isSearch ? (
          <div className={classes.searchItem}>
            <div className="search">
              <input
                value={value}
                type="text"
                placeholder={t('group.search')}
                autoFocus
                required
                onFocus={() => {
                  onInsertUserActionLogOne(
                    Enum_Action_Input_Enum.Input_GroupList_Search,
                    CapaActivityType.actionInput
                  );
                }}
                onChange={(event) => setValue && setValue(event.target.value)}
              />
              <IBMSearch
                className={clsx(value ? 'activeIcon iconSearch' : 'iconSearch')}
              />
              <IBMCloseEl
                className={clsx(
                  value ? 'iconClear' : 'hideIcon',
                  'ibm-close-input'
                )}
                onClick={() => {
                  onInsertUserActionLogOne(
                    Enum_Action_Button_Enum.Button_GroupList_Search_Cancel,
                    CapaActivityType.ButtonClick
                  );
                  setValue('');
                  setGroupList(
                    connectGroupChatRooms?.fetchConnectGroupChatRooms?.data
                  );
                }}
              />
            </div>
            <img
              src={IBMClose}
              alt=""
              className={classes.closeSearch}
              onClick={() => {
                onInsertUserActionLogOne(
                  Enum_Action_Button_Enum.Button_GroupList_Search_Delete,
                  CapaActivityType.ButtonClick
                );
                setValue('');
                setIsSearch(false);
                setGroupList(
                  connectGroupChatRooms?.fetchConnectGroupChatRooms?.data
                );
              }}
            />
          </div>
        ) : (
          <div className={classes.headerLeft}>
            <div className={classes.title}>
              <div className={classes.selected}>{t('tabs_menu.group')}</div>
            </div>
            {matches && (
              <IBMAddCommentRight
                onClick={() => {
                  onClickNewGroup();
                }}
              />
            )}
            <Tooltip title={t('tooltip.search') || 'Search'}>
              <IBMSearch
                onClick={() => {
                  onInsertUserActionLogOne(
                    Enum_Action_Input_Enum.Input_GroupList_Search,
                    CapaActivityType.actionInput
                  );
                  setIsSearch(true);
                }}
              />
            </Tooltip>
          </div>
        )}
      </div>
      <div className={classes.root} onScroll={onScrollWrapper} ref={wrapperRef}>
        <div className={classes.wrapper}>
          <div className={classes.listItem} ref={groupListRef}>
            <Loading
              open={connectSearchGroupLoading || connectGroupChatRoomsLoading}
              styles={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: 60
              }}
            />
            {!connectSearchGroupLoading &&
            !connectGroupChatRoomsLoading &&
            groupList &&
            groupList.length > 0 ? (
              groupList.map((item: any, index: number) => {
                const isChecked =
                  group.selectedGroup.filter(
                    (group: any) => group.groupId === item.groupId
                  ).length > 0;

                const isChatBotGroup = item.users.some(
                  (user: any) => user.email === chatBotEmail
                );

                let chatBotLastMessage = '';

                if (
                  item?.lastMessage?.message ===
                  CHAT_BOT_GROUP_MESSAGE_VALUE.CAD_FILE_MESSAGE
                ) {
                  chatBotLastMessage = t('chat_room.chat_bot.cad_file_message');
                } else if (item?.lastMessage?.fileCount > 0) {
                  chatBotLastMessage = t('chat_room.sent_a_file');
                }

                const normalLastMessage =
                  item?.lastMessage?.fileCount > 0
                    ? t('chat_room.sent_a_file')
                    : item?.lastMessage?.message;

                const isHoverItem = hoverItem === item?.groupId;

                return (
                  <ContextMenuTrigger id="react-contextmenu" key={index}>
                    <div
                      key={index}
                      className={classes.cursorPointer}
                      onTouchStart={() => {
                        if (isHoverItem && !matches) return;
                        if (matches) hideContextMenu();
                        onSetSelectedGroup({
                          selectedGroup: [...[item]]
                        });
                      }}
                      onMouseEnter={() => {
                        if (matches) return;
                        setHoverItem(item?.groupId);
                      }}
                      onMouseLeave={() => {
                        if (matches) return;
                        setHoverItem(null);
                      }}
                    >
                      <div
                        {...longPressHandler}
                        onContextMenu={() => {
                          if (matches) return;
                          onConTextMenu(item);
                        }}
                        className={clsx(
                          item.unReadCount && item.unReadCount > 0
                            ? classes.item
                            : classes.unReadCountItem,
                          isChecked ? classes.checkedItem : '',
                          classes.hoverItem
                        )}
                        onClick={(e) => {
                          onInsertUserActionLogOne(
                            Enum_Action_Button_Enum.Click_GroupList_Group,
                            CapaActivityType.ButtonClick
                          );
                          onSetShowHideFileDetail({
                            detailDrive: ''
                          });
                          hideContextMenu();
                          history.push({
                            pathname: `${groupRoomPathName}`,
                            search: `?chatroomId=${item.chatRoomId}&groupId=${item.groupId}&firstItem=true`
                          });
                          if (isChecked) {
                            onSetSelectedGroup({
                              selectedGroup: []
                            });

                            return;
                          }
                          onSetSelectedGroup({
                            selectedGroup: [...[item]]
                          });
                        }}
                      >
                        {isChatBotGroup ? (
                          <Tooltip title={CHAT_BOT_NAME}>
                            <div className={classes.image}>
                              <img
                                className={classes.groupChatBotImage}
                                src={CapaGroupChatBot}
                                alt="CapaGroupChatBot"
                              />
                            </div>
                          </Tooltip>
                        ) : (
                          <div className={classes.image}>
                            <Thumbnail file={item} />
                          </div>
                        )}
                        <div className={classes.text}>
                          <Tooltip
                            title={
                              item.groupName
                                ? item.groupName
                                : getGroupNameByUsernameOrEmail(
                                    item?.users,
                                    true
                                  )
                            }
                            placement="bottom-start"
                          >
                            <div className="group-name-text">
                              {item.groupName
                                ? item.groupName
                                : getGroupNameByUsernameOrEmail(item?.users)}
                            </div>
                          </Tooltip>
                          <div className="member-wrapper">
                            <div className="users">
                              {isChatBotGroup
                                ? `${t('group.capa_connect')}, ${
                                    userState?.data?.userName
                                  }`
                                : getGroupListUsers(item?.users)}
                            </div>
                            {item?.users?.length > 3 ? (
                              <div className="etc">{t('group.etc')}</div>
                            ) : null}
                            <div className="dot">·</div>
                            <div className="members-count">
                              {item?.users?.length}{' '}
                              {item?.users?.length > 1
                                ? t('group.members')
                                : t('group.member')}
                            </div>
                          </div>
                          <div className="text-subTitle">
                            <div
                              className={clsx(
                                'sender-name',
                                item?.unReadCount > 0 ? 'primary-color' : ''
                              )}
                            >
                              {isChatBotGroup
                                ? t('group.capa_connect')
                                : item.senderName}
                            </div>

                            {item && item.lastMessage && (
                              <span
                                className={clsx(
                                  isChatBotGroup
                                    ? 'chat-bot-group-max-width'
                                    : '',
                                  item?.unReadCount > 0 ? 'primary-color' : ''
                                )}
                              >
                                {': '}
                                {isChatBotGroup
                                  ? chatBotLastMessage
                                  : normalLastMessage}
                              </span>
                            )}

                            <div className="date-time">
                              {item?.lastMessage?.fileCount > 0 && (
                                <div className="dot">·</div>
                              )}
                              {formatTimeStamp(
                                item.updatedAt,
                                false,
                                true,
                                true,
                                true
                              )}
                            </div>
                          </div>
                        </div>
                        <div className={classes.right}>
                          {(isHoverItem || isChecked) && !matches ? (
                            <div
                              className={clsx(
                                'more-vert',
                                isChecked && 'more-vert-checked'
                              )}
                              onClick={(event) => {
                                if (isChecked) {
                                  onSetSelectedGroup({
                                    selectedGroup: []
                                  });

                                  return;
                                }
                                onSetSelectedGroup({
                                  selectedGroup: [...[item]]
                                });
                                setGroupItem(item);
                                handleClickOpenPopup(event);
                              }}
                            >
                              <IBMOverflowMenuHorizontal />
                            </div>
                          ) : item.unReadCount && item.unReadCount > 0 ? (
                            <div className="total">{item.unReadCount}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </ContextMenuTrigger>
                );
              })
            ) : (
              <div>
                {isSearch &&
                value &&
                !connectSearchGroupLoading &&
                !connectGroupChatRoomsLoading ? (
                  <div className={classes.noSearch}>
                    {t('group.no_search', { keySearch: value })}
                  </div>
                ) : (
                  <div
                    className={clsx(
                      classes.noData,
                      connectSearchGroupLoading ||
                        connectGroupChatRoomsLoading ||
                        isSearch
                        ? classes.noneDisplay
                        : ''
                    )}
                  >
                    <div className="title">{t('group.no_data.title')}</div>
                    <div className="subTitle">
                      {t('group.no_data.sub_title')}
                    </div>
                    <CustomizedButton
                      className={classes.button}
                      aria-haspopup="true"
                      variant="contained"
                      color="primary"
                      size="medium"
                      onClick={() => {
                        onInsertUserActionLogOne(
                          Enum_Action_Button_Enum.Button_GroupList_CreateGroup,
                          CapaActivityType.ButtonClick
                        );
                        onSetShowHideShareDialog({
                          isShowHideShareDialog: true
                        });
                        onSetShareDialogScreen({
                          shareDialogScreen:
                            SHARE_DIALOG_SCREEN.CREATE_NEW_GROUP
                        });
                      }}
                    >
                      {t('group.no_data.create_button')}
                    </CustomizedButton>
                  </div>
                )}
              </div>
            )}
          </div>

          <Backdrop
            className={classes.backDrop}
            open={matches && isOpenMenuListMobile}
            invisible
            onClick={() => {
              setOpenMenuListMobile(false);
            }}
          />
          <Popper
            className={classes.rootPopupMobile}
            open={matches && isOpenMenuListMobile}
            transition
            placement="bottom-end"
          >
            <Box className={classes.boxRoot}>
              <List className={classes.list}>
                <ListItem className={classes.headerPopper} button>
                  <div>
                    {group.selectedGroup.length > 0
                      ? group.selectedGroup[0].groupName
                      : ''}
                  </div>
                </ListItem>
                {group?.selectedGroup[0]?.accessLevel ===
                ACCESS_LEVEL_VALUE.OWNER ? (
                  <ListItem
                    onClick={() => setShowHideDeleteGroupDialog(true)}
                    className={classes.listItemPopper}
                    button
                  >
                    <IBMDelete />
                    <div>{t('group.title_delete_group')}</div>
                  </ListItem>
                ) : null}
                <ListItem
                  onClick={() => {
                    if (
                      group?.selectedGroup[0]?.accessLevel ===
                      ACCESS_LEVEL_VALUE.OWNER
                    ) {
                      setOpenLeaveLastMemberGroup(true);
                    } else {
                      setShowHideLeaveGroupDialog(true);
                    }
                  }}
                  className={classes.listItemPopper}
                  button
                >
                  <IBMExit />
                  <div>{t('group.title_leave_group')}</div>
                </ListItem>
              </List>
            </Box>
          </Popper>

          <Popover
            className={classes.customPopover}
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClosePopup}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <ul className="popup">
              {groupItem.accessLevel === ACCESS_LEVEL_VALUE.OWNER ? (
                <li onClick={() => setShowHideDeleteGroupDialog(true)}>
                  <IBMDelete />
                  <div className="text">{t('group.title_delete_group')}</div>
                </li>
              ) : null}
              {groupItem.accessLevel === ACCESS_LEVEL_VALUE.OWNER ? (
                <div className="divider" />
              ) : null}
              <li
                onClick={() => {
                  if (groupItem.accessLevel === ACCESS_LEVEL_VALUE.OWNER) {
                    setOpenLeaveLastMemberGroup(true);
                  } else {
                    setShowHideLeaveGroupDialog(true);
                  }
                }}
              >
                <IBMExit />
                <div className="text">{t('group.title_leave_group')}</div>
              </li>
            </ul>
          </Popover>

          <ContextMenuTable>
            <ContextMenu id="react-contextmenu">
              <ContextMenuItem>{actionItemOptionsEl}</ContextMenuItem>
            </ContextMenu>
          </ContextMenuTable>

          <DeleteGroupContainer
            isShowHideDialog={isShowHideDeleteGroupDialog}
            clickSubmit={handleClosePopup}
            onCloseDialog={() => {
              setShowHideDeleteGroupDialog(false);
              setOpenMenuListMobile(false);
              handleClosePopup();
            }}
            removeGroupSuccess={removeGroupSuccess}
            groupId={group?.selectedGroup[0]?.groupId}
            groupName={
              group.selectedGroup.length > 0
                ? group.selectedGroup[0].groupName
                : ''
            }
          />
          <LeaveGroupModal
            groupId={group?.selectedGroup[0]?.groupId}
            isOpen={isShowHideLeaveGroupDialog}
            clickSubmit={handleClosePopup}
            onClose={() => {
              setShowHideLeaveGroupDialog(false);
              setOpenMenuListMobile(false);
              handleClosePopup();
            }}
            refetchConnectGroupChatRooms={refetchConnectGroupChatRooms}
          />

          <LeaveLastMemberGroupModal
            isOpen={isOpenLeaveLastMemberGroup}
            onClose={() => {
              setOpenLeaveLastMemberGroup(false);
              setOpenMenuListMobile(false);
              handleClosePopup();
            }}
          />
        </div>
      </div>
      <BottomNavMenu />
      {myDrive.isShowHideShareDialog ? (
        <ShareContainerDialog
          isShowUploadFiles={true}
          createNewGroupHeader={
            <>
              {matches ? (
                <IBMChevronLeft
                  className={classes.backIconMobile}
                  onClick={() => {
                    onSetShowHideShareDialog({
                      isShowHideShareDialog: false
                    });
                  }}
                />
              ) : (
                <div className={classes.backIconWrapper}>
                  <IBMAddCommentRight />
                </div>
              )}
              <div className={classes.createNewGroupTitle}>
                {t('create_new_group_modal.header_title')}
              </div>
            </>
          }
        />
      ) : null}
      <RegisterUserContainer
        isOpen={isShowHideRegisterDialog}
        onClose={() => {
          setShowHideRegisterDialog(false);
        }}
        callBack={() => {
          refetchConnectGroupChatRooms();
        }}
      />
      <WelcomeContainer />
    </LayoutDashboard>
  ) : (
    <></>
  );
};

export default GroupContainer;
