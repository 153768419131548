import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { colorSet } from '@components/Provider';

export const useStyleSelect = makeStyles((theme: Theme) =>
  createStyles({
    selectRoot: {
      '&.placeholder': {
        color: colorSet.gray500,
        fontSize: 16,
        fontWeight: 400,
        lineHeight: '24px',
        display: 'flex',
        alignItems: 'center',
        letterSpacing: '-0.1px'
      },
      '&:focus': {
        backgroundColor: 'transparent'
      }
    },
    agreeAll: {
      '& p': {
        fontWeight: 500
      }
    },
    selected: {},
    selectGroup: {
      '&::-webkit-scrollbar': {
        width: 8
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: colorSet.gray400,
        borderRadius: 8
      },
      '& ul': {
        [theme.breakpoints.down('xs')]: {
          maxHeight: 144
        }
      }
    },
    rootMenuItem: {
      fontSize: 14,
      padding: '6px 12px',
      '&:first-child': {
        display: 'none'
      },
      '&$selected': {
        backgroundColor: colorSet.primary100
      },
      '&:focus': {
        backgroundColor: colorSet.primary100
      }
    },
    formSignUp: {
      '& .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${colorSet.gray400}`
      }
    },
    cursorPointer: {
      cursor: 'pointer'
    },
    usernameInput: {
      height: 48,
      marginBottom: 38
    },
    pleaseSelect: {
      height: 48,
      marginBottom: 38
    },
    doneButton: {
      textTransform: 'none'
    },
    title: {
      fontSize: 32,
      fontWeight: 700,
      textAlign: 'center',
      letterSpacing: '-0.1px',
      marginBottom: 32,
      color: colorSet.gray900,
      [theme.breakpoints.down('xs')]: {
        lineHeight: '160%',
        fontSize: 25,
        marginBottom: 24
      }
    },
    verifyButton: {
      textTransform: 'none',
      minWidth: 120
    },
    footerWrapper: {
      height: 61,
      marginTop: 56,
      display: 'flex',
      paddingLeft: 16,
      [theme.breakpoints.down('xs')]: {
        height: 49,
        paddingLeft: 6,
        marginTop: 40
      }
    },
    goLogin: {
      marginTop: 16,
      fontSize: 16,
      cursor: 'pointer',
      textAlign: 'center',
      display: 'none',
      lineHeight: '24px'
    }
  })
);
