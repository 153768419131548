import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
  CapaLogoBetaKo,
  CapaLogoBeta,
  IBMCheckboxChecked,
  IBMCheckbox
} from '@assets';
import useUserActionLog, {
  Enum_Action_Page_Enum,
  CapaActivityType,
  Enum_Action_Input_Enum,
  Enum_Action_Button_Enum
} from '@hooks/useUserActionLog';
import { CustomizedTypography, CustomizedButton } from '@components/Customized';
import {
  TextField,
  Divider,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import { MuiContainer, MuiPaper } from './styles';
import queryString from 'query-string';
import { singUpPathName, REDIRECT_TO } from '@routes/Common/types';
import { useTranslation } from 'react-i18next';
import { LANGUAGE_VALUE } from '@routes/Common/ChangeLanguage/types';
import i18n from '@i18n/index';
import { passwordRegex } from '@utils/regex';

interface Props {
  onSubmit: (req: Inputs) => void;
  onClick: () => void;
  onClickForgotPass?: () => void;
  onClickSignUp?: () => void;
}

export interface Inputs {
  email: string;
  password: string;
  newPassword?: string;
  isSaved?: boolean;
}

const LoginPresenter: React.FC<Props> = ({
  onSubmit,
  onClick,
  onClickForgotPass,
  onClickSignUp
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { onInsertUserActionLogOne } = useUserActionLog();
  const [isSaved, setIsSaved] = useState(false);
  const { register, handleSubmit, errors } = useForm<Inputs>();

  const { search } = useLocation();
  const query = queryString.parse(search);
  const emailQuery =
    queryString.parse(search, { decode: false })?.email || null;
  const languageValue = i18n.language;

  const handleOnSubmit = (inputs: Inputs) => {
    if (emailQuery !== null) {
      const internalInputs: any = {
        ...inputs,
        email: emailQuery || ''
      };
      onSubmit({ ...internalInputs, isSaved: isSaved });

      return;
    }
    onSubmit({ ...inputs, isSaved: isSaved });
  };

  const validationForm = {
    email: {
      required: { value: true, message: t('sign_up.required_email') },
      pattern: {
        value:
          /[-A-Za-z0-9_]+[-A-Za-z0-9_.]*[@]{1}[-A-Za-z0-9_]+[-A-Za-z0-9_.]*[.]{1}[A-Za-z]{1,5}/,
        message: t('sign_up.valid_email')
      }
    },
    password: {
      required: {
        value: true,
        message: t('sign_up.required_password')
      },
      pattern: {
        value: passwordRegex,
        message: t('sign_up.password_not_valid')
      }
    }
  };

  useEffect(() => {
    onInsertUserActionLogOne(
      Enum_Action_Page_Enum.Page_Login,
      CapaActivityType.PageVisit
    );
  }, [onInsertUserActionLogOne]);

  return (
    <MuiContainer className="login-container">
      <img
        className="capa-logo"
        src={
          languageValue === LANGUAGE_VALUE.EN ? CapaLogoBeta : CapaLogoBetaKo
        }
        alt="Logo"
        onClick={onClick}
      />
      <MuiPaper className="login-paper" elevation={0}>
        <CustomizedTypography variant="h3" fontWeight="bold" gutterBottoms={20}>
          {t('log_in.log_in')}
        </CustomizedTypography>
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <TextField
            placeholder={t('sign_up.email_placeholder')}
            variant="outlined"
            error={errors.email ? true : false}
            helperText={errors.email && errors.email.message}
            inputProps={{
              name: 'email',
              ref: register(validationForm.email)
            }}
            size="small"
            onFocus={() => {
              onInsertUserActionLogOne(
                Enum_Action_Input_Enum.Input_Login_ID,
                CapaActivityType.actionInput
              );
            }}
            defaultValue={emailQuery ? emailQuery : ''}
            disabled={emailQuery !== null}
            fullWidth
          />
          <TextField
            type={'password'}
            placeholder={t('log_in.password')}
            variant="outlined"
            error={!errors.email && errors.password ? true : false}
            helperText={
              !errors.email && errors.password && errors.password.message
            }
            inputProps={{
              name: 'password',
              ref: register(validationForm.password)
            }}
            onFocus={() => {
              onInsertUserActionLogOne(
                Enum_Action_Input_Enum.Input_Login_PW,
                CapaActivityType.actionInput
              );
            }}
            size="small"
            fullWidth
          />
          <FormControlLabel
            checked={isSaved}
            className="custom-checkbox"
            control={
              <Checkbox
                checkedIcon={
                  <img src={IBMCheckboxChecked} alt="IBMCheckboxChecked" />
                }
                icon={<img src={IBMCheckbox} alt="IBMCheckbox" />}
                color="primary"
              />
            }
            label={t('log_in.stay_logged_in')}
            onChange={(e, checked) => {
              setIsSaved(checked);
            }}
          />
          <CustomizedButton
            type="submit"
            color="primary"
            variant="contained"
            size="large"
            fullWidth
            className="log-in-button"
          >
            {t('log_in.log_in')}
          </CustomizedButton>
        </form>
      </MuiPaper>
      <div className="text-btn-group">
        {onClickForgotPass ? (
          <CustomizedButton
            variant="text"
            size="large"
            color="default"
            onClick={onClickForgotPass}
            className="forget-button"
          >
            {t('log_in.forgot_password')}
          </CustomizedButton>
        ) : (
          <CustomizedButton
            variant="text"
            size="large"
            color="default"
            onClick={() => {
              onInsertUserActionLogOne(
                Enum_Action_Button_Enum.Button_Login_ResetPW,
                CapaActivityType.ButtonClick
              );
              history.push('/login/requestpassword');
            }}
            className="forget-button"
          >
            {t('log_in.forgot_password')}
          </CustomizedButton>
        )}
        <Divider className="divider" orientation="vertical" />
        {onClickSignUp ? (
          <CustomizedButton
            variant="text"
            size="large"
            color="default"
            onClick={onClickSignUp}
            className="signup-button"
          >
            {t('log_in.sign_up')}
          </CustomizedButton>
        ) : (
          <CustomizedButton
            variant="text"
            size="large"
            color="default"
            onClick={() => {
              onInsertUserActionLogOne(
                Enum_Action_Button_Enum.Button_Login_GotoSignup,
                CapaActivityType.ButtonClick
              );
              if (query.redirect === REDIRECT_TO.GROUP_ROOM) {
                return history.push({
                  pathname: `${singUpPathName}`,
                  search: `?redirect=${REDIRECT_TO.GROUP_ROOM}&chatroomId=${query.chatroomId}&groupId=${query.groupId}`,
                  state: { prevLocation: window.location.href }
                });
              }
              history.push(singUpPathName);
            }}
            className="signup-button"
          >
            {t('log_in.sign_up')}
          </CustomizedButton>
        )}
      </div>
    </MuiContainer>
  );
};

export default LoginPresenter;
