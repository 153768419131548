import type { SVGProps } from 'react';
import React from 'react';

const IBMShare = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.625 1.625L15.875 3.875C16.375 4.375 16.375 5.125 15.875 5.625L6.5 15H2.5V11L11.875 1.625C12.375 1.125 13.125 1.125 13.625 1.625ZM15 4.75L12.75 2.5L10.875 4.375L13.125 6.625L15 4.75ZM3.75 11.5V13.75H6L12.25 7.5L10 5.25L3.75 11.5ZM1.25 17.5V16.25H18.75V17.5H1.25Z"
      fill={props.color ?? '#343A40'}
    />
  </svg>
);

export default IBMShare;
