import { useCallback, useMemo } from 'react';
import { FetchGroup } from '@generated/Drive/MyDrive/graphql';
import {
  SharedWithGroupsState,
  setSelectedGroupFiles
} from '@modules/Drive/SharedWithGroups';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@modules/index';
import * as Apollo from '@apollo/client';

export default function useSharedWithGroups() {
  const { sharedWithGroups } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  const [fetchGroupData, { data: groupData, loading: fetchGroupLoading }] =
    Apollo.useLazyQuery(FetchGroup, { fetchPolicy: 'no-cache' });

  const onSetSelectedGroupFiles = (params: SharedWithGroupsState) =>
    dispatch(setSelectedGroupFiles(params));

  const groupDataState = useMemo(() => {
    if (groupData && groupData?.fetchGroup) {
      return {
        data: groupData?.fetchGroup.data ?? undefined,
        isLoading: fetchGroupLoading,
        result: groupData?.fetchGroup?.result
      };
    }
    return {
      data: undefined,
      isLoading: fetchGroupLoading,
      result: groupData?.fetchGroup?.result
    };
  }, [groupData, fetchGroupLoading]);

  const onFetchGroupData = useCallback(fetchGroupData, [fetchGroupData]);

  return {
    sharedWithGroups,
    groupDataState,
    onFetchGroupData,
    onSetSelectedGroupFiles,
    fetchGroupLoading
  };
}
