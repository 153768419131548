import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useUserActionLog, {
  Enum_Action_Page_Enum,
  CapaActivityType
} from '@hooks/useUserActionLog';
import { AsideMenu, MobileMyPageMenu } from './components';
import AccountPage, { AccountEditPage } from './Account';
import { Box, useTheme, useMediaQuery } from '@material-ui/core';
import NotificationSettings from '@routes/MyPage/NotificationSettings/NotificationSettings';
import { useStyles } from './styles';

type PageType = 'account' | 'edit' | 'notification-settings';
type infoType = 'email' | 'password';

export type ParamType = { page?: PageType; info?: infoType };

const MyPagePresenter: React.FC = () => {
  const { page, info } = useParams<ParamType>();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const isMobileMenu = matches && !page;
  const classes = useStyles({ isMobileMenu });
  const { onInsertUserActionLogOne } = useUserActionLog();
  const pageDistributor: Record<PageType, React.ReactNode> = {
    account: <AccountPage />,
    edit: <AccountEditPage infoType={info} />,
    'notification-settings': <NotificationSettings />
  };

  useEffect(() => {
    const pageAction = !page
      ? Enum_Action_Page_Enum.Page_MyPage
      : info === 'email'
      ? Enum_Action_Page_Enum.Page_MyPage_Edit_Email
      : Enum_Action_Page_Enum.Page_MyPage_Edit_Password;

    onInsertUserActionLogOne(pageAction, CapaActivityType.PageVisit);
  }, [onInsertUserActionLogOne, page, info]);

  return (
    <>
      <Box className={classes.wrapper}>
        <Box className={classes.container}>
          {matches ? <></> : <AsideMenu />}
          {page ? (
            pageDistributor[page]
          ) : matches ? (
            <MobileMyPageMenu />
          ) : (
            <AccountPage />
          )}
        </Box>
      </Box>
    </>
  );
};

export default MyPagePresenter;
