import * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';
import { CapaConnectGroupAccessLevel } from '@generated/Drive/MyDrive/graphql';
import { CapaInflowRoute, CapaQuotationUserFileType } from './enum';
import {
  ApproveItpEventInput,
  ApprovedQuotationPartnerByEmailInput,
  Authentication,
  AuthenticationInput,
  AuthenticationOutput,
  CapaActivityType,
  CapaConnectUser,
  CapaConnectUserOutput,
  CapaConnectUsersBoOutput,
  CapaItpEventsBoOutput,
  Exact,
  Maybe,
  Scalars
} from './types';
const defaultOptions = {};

export enum Language {
  Korea = 'KOREAN',
  English = 'ENGLISH',
  Chinese = 'CHINESE',
  Vietnam = 'VIETNAM'
}

export enum StatusCode {
  Success = 200
}
export enum CapaErrorCode {
  Success = 'SUCCESS',
  UserNotFound = 'USER_NOT_FOUND',
  PasswordIncorrect = 'PASSWORD_INCORRECT',
  DuplicateEmail = 'DUPLICATE_EMAIL',
  CodeMismatch = 'CODE_MISMATCH',
  InvalidReferralId = 'INVALID_REFERRAL_ID',
  NotAgreed = 'NOT_AGREED',
  TokenExpired = 'TOKEN_EXPIRED',
  InvalidToken = 'INVALID_TOKEN',
  QueryError = 'QUERY_ERROR',
  Unauthorized = 'UNAUTHORIZED',
  BadRequest = 'BAD_REQUEST',
  ParmeterValidationError = 'PARMETER_VALIDATION_ERROR',
  ConnectUserExists = 'CONNECT_USER_EXISTS',
  ConnectUserNotFound = 'CONNECT_USER_NOT_FOUND',
  QuotationUserExists = 'QUOTATION_USER_EXISTS',
  QuotationUserNotFound = 'QUOTATION_USER_NOT_FOUND',
  QuotationUserRegistrationFailed = 'QUOTATION_USER_REGISTRATION_FAILED',
  QuotationPartnerNotFound = 'QUOTATION_PARTNER_NOT_FOUND',
  QuotationPartnerUserNotFound = 'QUOTATION_PARTNER_USER_NOT_FOUND',
  QuotationPartnerExists = 'QUOTATION_PARTNER_EXISTS',
  NotAllowedUser = 'NOT_ALLOWED_USER',
  DuplicateCompanyRegistrationNo = 'DUPLICATE_COMPANY_REGISTRATION_NO',
  InavlidCompanyRegistrationNo = 'INAVLID_COMPANY_REGISTRATION_NO',
  NoCompanyRegistrationFile = 'NO_COMPANY_REGISTRATION_FILE',
  FileNotFound = 'FILE_NOT_FOUND',
  FileConversionFailed = 'FILE_CONVERSION_FAILED',
  NotEnoughCloudStorage = 'NOT_ENOUGH_CONNECT_STORAGE',
  ContactUserAlreadyExists = 'CONNECT_CONTACT_USER_ALREADY_EXISTS',
  Error = 'ERROR',
  ItpEventExist = 'ITP_EVENT_EXIST',
  ItpEventNotFound = 'ITP_EVENT_NOT_FOUND',
  AlreadyApproved = 'ALREADY_APPROVED',
  BackofficeNotFound = 'BACKOFFICE_NOT_FOUND',
  GroupNotExists = 'GROUP_NOT_EXISTS',
  AlreadyHaveFileInDrive = 'ALREADY_HAVE_FILE_IN_DRIVE'
}

export type CapaFile = {
  __typename?: 'CAPAFile';
  fileId: Scalars['ID'];
  fileName?: Maybe<Scalars['String']>;
  fileExtension?: Maybe<Scalars['String']>;
  fileMimetype?: Maybe<Scalars['String']>;
  fileSize?: Maybe<Scalars['Float']>;
  status?: Maybe<CapaFileConvertStatus>;
  oldFileId?: Maybe<Scalars['String']>;
};

export enum CapaFileConvertStatus {
  Processing = 'PROCESSING',
  Converted = 'CONVERTED',
  Coverting = 'CONVERTING',
  Failed = 'FAILED'
}

export type CapaItpEventFile = {
  __typename?: 'CAPAItpEventFile';
  fileId?: Maybe<Scalars['ID']>;
  fileName?: Maybe<Scalars['String']>;
};

export enum CapaItpEventListTypeEnum {
  All = 'ALL',
  Ready = 'READY',
  Approved = 'APPROVED',
  Reject = 'REJECT',
  Sent = 'SENT'
}

export enum ConnectUserFileLinkStatus {
  Active = 'ACTIVE',
  Deactive = 'DEACTIVE'
}

export enum ConnectUserFileStatus {
  Viewable = 'VIEWABLE',
  Downloadable = 'DOWNLOADABLE'
}

export type CapaItpEventXlsxDownload = {
  __typename?: 'CAPAItpEventXlsxDownload';
  id?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
  cellphoneNumber?: Maybe<Scalars['String']>;
};

export type CapaConnectMyFile = {
  __typename?: 'CAPAConnectMyFile';
  id: Scalars['ID'];
  fileId: Scalars['ID'];
  fileName: Scalars['String'];
  fileSize: Scalars['Float'];
  status?: Maybe<CapaFileConvertStatus>;
  thumbnail?: Maybe<Scalars['String']>;
  fileOwnerUsername?: Maybe<Scalars['String']>;
  drawingFile?: Maybe<Scalars['String']>;
  filePath?: Maybe<Scalars['String']>;
  isFile?: Maybe<Scalars['Boolean']>;
  isShared?: Maybe<Scalars['Boolean']>;
  thumbnailUri?: Maybe<Scalars['String']>;
  originalFileExt?: Maybe<Scalars['String']>;
  linkStatus?: Maybe<ConnectUserFileLinkStatus>;
  filePermission?: Maybe<ConnectUserFileStatus>;
  oldFileId?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type CapaConnectFolder = {
  __typename?: 'CAPAConnectFolder';
  id: Scalars['ID'];
  folderName: Scalars['String'];
};

export type CapaConnectMyDrive = {
  __typename?: 'CAPAConnectMyDrive';
  files?: Maybe<Array<CapaConnectMyFile>>;
  path?: Maybe<Array<CapaConnectFolder>>;
};

export type CapaConnectMyDrivesOutput = {
  __typename?: 'CAPAConnectMyDrivesOutput';
  result: CapaErrorCode;
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<Array<CapaConnectMyDrive>>;
};

export type CapaQuotationCapabilityInput = {
  services?: Maybe<Array<CapaQuotationServiceInput>>;
};

export type CapaQuotationCapabilityNotificationFilter = {
  __typename?: 'CAPAQuotationCapabilityNotificationFilter';
  email?: Maybe<Scalars['Boolean']>;
  sms?: Maybe<Scalars['Boolean']>;
  service?: Maybe<CapaQuotationServiceType>;
  detailedServices?: Maybe<Array<CapaQuotationDetailedServiceType>>;
  optionalServices?: Maybe<Array<CapaQuotationServiceType>>;
};

export type CapaQuotationCapabilityNotificationFilterInput = {
  email?: Maybe<Scalars['Boolean']>;
  sms?: Maybe<Scalars['Boolean']>;
  service?: Maybe<CapaQuotationServiceType>;
  detailedServices?: Maybe<Array<CapaQuotationDetailedServiceType>>;
  optionalServices?: Maybe<Array<CapaQuotationServiceType>>;
};

export type CapaQuotationDetailedMaterial = {
  __typename?: 'CAPAQuotationDetailedMaterial';
  material?: Maybe<CapaQuotationMaterial>;
  detailedMaterials?: Maybe<Array<CapaQuotationDetailedMaterialType>>;
};

export enum CapaQuotationDetailedMaterialType {
  Abs = 'ABS',
  Acryl = 'ACRYL',
  McNylon = 'MC_NYLON',
  McNylon_66 = 'MC_NYLON_66',
  Pc = 'PC',
  Pe = 'PE',
  Pp = 'PP',
  AcetalPom = 'ACETAL_POM',
  Peek = 'PEEK',
  Bakelite = 'BAKELITE',
  Teflon = 'TEFLON',
  Aluminum_2024T3 = 'ALUMINUM_2024_T3',
  Aluminum_6061T6 = 'ALUMINUM_6061_T6',
  Aluminum_6063 = 'ALUMINUM_6063',
  Aluminum_7050 = 'ALUMINUM_7050',
  Aluminum_7075T6 = 'ALUMINUM_7075_T6',
  Aluminum_7075T7351 = 'ALUMINUM_7075_T7351',
  AluminumMic_6 = 'ALUMINUM_MIC_6',
  Ss15_5 = 'SS15_5',
  Ss17_4 = 'SS17_4',
  Ss18_8 = 'SS18_8',
  Ss303 = 'SS303',
  Ss304 = 'SS304',
  Ss316Ss316L = 'SS316_SS316L',
  Ss416 = 'SS416',
  Ss420 = 'SS420',
  Ss235Ss330 = 'SS235_SS330',
  Ss275Ss400 = 'SS275_SS400',
  Ss315Ss490 = 'SS315_SS490',
  Ss410Ss540 = 'SS410_SS540',
  Ss450Ss590 = 'SS450_SS590',
  Ss550 = 'SS550',
  Copper_101 = 'COPPER_101',
  Copper_260Brass = 'COPPER_260_BRASS',
  CopperC110 = 'COPPER_C110',
  CopperC360Brass = 'COPPER_C360_BRASS',
  CopperC932Bronze = 'COPPER_C932_BRONZE',
  S10CSm10C = 'S10C_SM10C',
  S15CSm15C = 'S15C_SM15C',
  S20CSm20C = 'S20C_SM20C',
  S25CSm25C = 'S25C_SM25C',
  S30CSm30C = 'S30C_SM30C',
  S35CSm35C = 'S35C_SM35C',
  S40CSm40C = 'S40C_SM40C',
  S45CSm45C = 'S45C_SM45C',
  S50CSm50C = 'S50C_SM50C',
  S55CSm55C = 'S55C_SM55C',
  GeneralBrass = 'GENERAL_BRASS',
  GeneralBronze = 'GENERAL_BRONZE',
  Scm_415 = 'SCM_415',
  Scm_420 = 'SCM_420',
  Scm_440 = 'SCM_440',
  Asa = 'ASA',
  Hdpe = 'HDPE',
  Ldpe = 'LDPE',
  NylonPa = 'NYLON_PA',
  Pbt = 'PBT',
  Pct = 'PCT',
  Pei = 'PEI',
  Pet = 'PET',
  Pmma = 'PMMA',
  Ps = 'PS',
  Pvc = 'PVC',
  Tpe = 'TPE',
  Tpu = 'TPU',
  AluminumAlloy = 'ALUMINUM_ALLOY',
  ZincAlloy = 'ZINC_ALLOY',
  MagnesiumAlloy = 'MAGNESIUM_ALLOY',
  Recommend = 'RECOMMEND',
  Etc = 'ETC'
}

export type CapaQuotationDetailedService = {
  __typename?: 'CAPAQuotationDetailedService';
  type?: Maybe<CapaQuotationDetailedServiceType>;
  materials?: Maybe<Array<CapaQuotationDetailedMaterial>>;
};

export type CapaQuotationDetailedServiceInput = {
  type?: Maybe<CapaQuotationDetailedServiceType>;
  materials?: Maybe<Array<CapaQuotationMaterialInput>>;
};

export enum CapaQuotationDetailedServiceType {
  Milling = 'MILLING',
  Turning = 'TURNING',
  MachiningCenter = 'MACHINING_CENTER',
  Drilling = 'DRILLING',
  Sla = 'SLA',
  Fdm = 'FDM',
  Polyjet = 'POLYJET',
  Mjf = 'MJF',
  Sls = 'SLS',
  SlmDmls = 'SLM_DMLS',
  InjectionMolding = 'INJECTION_MOLDING',
  DoubleShotInjectionMolding = 'DOUBLE_SHOT_INJECTION_MOLDING',
  BlowInjectionMolding = 'BLOW_INJECTION_MOLDING',
  InsertInjectionMolding = 'INSERT_INJECTION_MOLDING',
  Press = 'PRESS',
  LaserCutting = 'LASER_CUTTING',
  WaterjetCutting = 'WATERJET_CUTTING',
  SandCasting = 'SAND_CASTING',
  ShellCasting = 'SHELL_CASTING',
  InvestmentCasting = 'INVESTMENT_CASTING',
  DieCasting = 'DIE_CASTING',
  CentrifugalCasting = 'CENTRIFUGAL_CASTING',
  Recommend = 'RECOMMEND',
  Etc = 'ETC'
}

export enum CapaQuotationMaterial {
  Plastic = 'PLASTIC',
  Aluminum = 'ALUMINUM',
  StainlessSteel = 'STAINLESS_STEEL',
  Steel = 'STEEL',
  Copper = 'COPPER',
  CarbonSteel = 'CARBON_STEEL',
  Brass = 'BRASS',
  Bronze = 'BRONZE',
  SteelAlloy = 'STEEL_ALLOY',
  ResinWhite = 'RESIN_WHITE',
  ResinTransparent = 'RESIN_TRANSPARENT',
  ResinTranslucent = 'RESIN_TRANSLUCENT',
  Pla = 'PLA',
  AbsIndustrial = 'ABS_INDUSTRIAL',
  Vero = 'VERO',
  RubberLike = 'RUBBER_LIKE',
  Pa12 = 'PA12',
  MetalInj = 'METAL_INJ',
  GalvanizedSteel = 'GALVANIZED_STEEL',
  Iron = 'IRON',
  SteelCasting = 'STEEL_CASTING',
  AluminumAlloy = 'ALUMINUM_ALLOY',
  ZincAlloy = 'ZINC_ALLOY',
  MagnesiumAlloy = 'MAGNESIUM_ALLOY',
  CopperAlloy = 'COPPER_ALLOY',
  Recommend = 'RECOMMEND',
  Etc = 'ETC'
}

export type CapaQuotationMaterialInput = {
  material?: Maybe<CapaQuotationMaterial>;
  detailedMaterials?: Maybe<Array<CapaQuotationDetailedMaterialType>>;
};

export type CapaQuotationPartner = {
  __typename?: 'CAPAQuotationPartner';
  id: Scalars['ID'];
  email: Scalars['String'];
  status: CapaQuotationPartnerStatus;
  capabilities: CapaQuotationPartnerCapability;
  companyName: Scalars['String'];
  fullAddress: Scalars['String'];
  shortAddress: Scalars['String'];
  companyRegistrationNumber: Scalars['String'];
  companyIntroduction?: Maybe<Scalars['String']>;
  foundedIn?: Maybe<Scalars['String']>;
  numberEmployees?: Maybe<Scalars['String']>;
  equipment?: Maybe<Scalars['String']>;
  serviceHours?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  managerName: Scalars['String'];
  department?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  contact: Scalars['String'];
  notificationFilter: CapaQuotationPartnerNotificationFilter;
  serviceAgreed?: Maybe<Scalars['Boolean']>;
  privacyAgreed?: Maybe<Scalars['Boolean']>;
  promotionAgreed?: Maybe<Scalars['Boolean']>;
  referralUserId?: Maybe<Scalars['String']>;
  isItp: Scalars['Boolean'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  files?: Maybe<Array<CapaQuotationPartnerFile>>;
};

export type CapaQuotationPartnerCapability = {
  __typename?: 'CAPAQuotationPartnerCapability';
  services?: Maybe<Array<CapaQuotationService>>;
};

export type CapaQuotationPartnerFile = {
  __typename?: 'CAPAQuotationPartnerFile';
  fileId?: Maybe<Scalars['ID']>;
  fileName?: Maybe<Scalars['String']>;
  fileType?: Maybe<CapaQuotationPartnerFileType>;
};

export enum CapaQuotationPartnerFileType {
  Logo = 'LOGO',
  Registration = 'REGISTRATION',
  PortfolioImage = 'PORTFOLIO_IMAGE',
  PortfolioFile = 'PORTFOLIO_FILE',
  ImageCover = 'IMAGE_COVER'
}

export type CapaQuotationPartnerNotificationFilter = {
  __typename?: 'CAPAQuotationPartnerNotificationFilter';
  chatEmail?: Maybe<Scalars['Boolean']>;
  chatSMS?: Maybe<Scalars['Boolean']>;
  rfqEmail?: Maybe<Scalars['Boolean']>;
  rfqSMS?: Maybe<Scalars['Boolean']>;
  capabilityFilters: Array<CapaQuotationCapabilityNotificationFilter>;
};

export type CapaQuotationPartnerNotificationFilterInput = {
  chatEmail?: Maybe<Scalars['Boolean']>;
  chatSMS?: Maybe<Scalars['Boolean']>;
  rfqEmail?: Maybe<Scalars['Boolean']>;
  rfqSMS?: Maybe<Scalars['Boolean']>;
  capabilityFilters?: Maybe<
    Array<CapaQuotationCapabilityNotificationFilterInput>
  >;
};

export type CapaQuotationPartnerOutput = {
  __typename?: 'CAPAQuotationPartnerOutput';
  result: CapaErrorCode;
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaQuotationPartner>;
};

export enum CapaQuotationPartnerStatus {
  Approved = 'APPROVED',
  Registered = 'REGISTERED',
  Deleted = 'DELETED'
}

export type CapaQuotationPartnersRfq = {
  __typename?: 'CAPAQuotationPartnersRFQ';
  id: Scalars['ID'];
  unseen: Scalars['Boolean'];
  projectName: Scalars['String'];
  companyName: Scalars['String'];
  shortAddress: Scalars['String'];
  serviceType: CapaQuotationServiceType;
  detailedServiceType: CapaQuotationDetailedServiceType;
  status: CapaQuotationRfqStatus;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  thumbnail: CapaQuotationUserFile;
  quantity: Scalars['Int'];
  chatRooms: Scalars['Int'];
  consultingCount: Scalars['Int'];
  isFavorite: Scalars['Boolean'];
  lastMessage: Scalars['String'];
};

export enum CapaQuotationRfqStatus {
  Available = 'AVAILABLE',
  Expired = 'EXPIRED',
  Stopped = 'STOPPED',
  Deleted = 'DELETED'
}

export type CapaQuotationService = {
  __typename?: 'CAPAQuotationService';
  service?: Maybe<CapaQuotationServiceType>;
  detailedServices?: Maybe<Array<CapaQuotationDetailedService>>;
  optionalServices?: Maybe<Array<CapaQuotationServiceType>>;
};

export type CapaQuotationServiceInput = {
  service?: Maybe<CapaQuotationServiceType>;
  detailedServices?: Maybe<Array<CapaQuotationDetailedServiceInput>>;
  optionalServices?: Maybe<Array<CapaQuotationServiceType>>;
};

export enum CapaQuotationServiceType {
  Cnc = 'CNC',
  Printing = 'PRINTING',
  Inj = 'INJ',
  SheetMetal = 'SHEET_METAL',
  MechanicalDesign = 'MECHANICAL_DESIGN',
  Casting = 'CASTING',
  Weilding = 'WEILDING',
  Bending = 'BENDING',
  ProductDesign = 'PRODUCT_DESIGN',
  Prototyping = 'PROTOTYPING'
}

export type CapaQuotationUser = {
  __typename?: 'CAPAQuotationUser';
  id: Scalars['ID'];
  email: Scalars['String'];
  status: CapaQuotationUserStatus;
  managerName?: Maybe<Scalars['String']>;
  cellphoneNumber?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  shortAddress?: Maybe<Scalars['String']>;
  companyRegistrationNumber?: Maybe<Scalars['String']>;
  bizType?: Maybe<CapaQuotationUserBizType>;
  organizationName?: Maybe<Scalars['String']>;
  companyIntroduction?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  serviceAgreed?: Maybe<Scalars['Boolean']>;
  privacyAgreed?: Maybe<Scalars['Boolean']>;
  promotionAgreed?: Maybe<Scalars['Boolean']>;
  referralUserId?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  files?: Maybe<Array<CapaQuotationUserFile>>;
};

export enum CapaQuotationUserBizType {
  Biz = 'BIZ',
  Corporate = 'CORPORATE',
  SelfEmployed = 'SELF_EMPLOYED',
  Organization = 'ORGANIZATION',
  Individual = 'INDIVIDUAL'
}

export type CapaQuotationUserFile = {
  __typename?: 'CAPAQuotationUserFile';
  fileId?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  fileType?: Maybe<CapaQuotationUserFileType>;
};

export type CapaQuotationUserOutput = {
  __typename?: 'CAPAQuotationUserOutput';
  result: CapaErrorCode;
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaQuotationUser>;
};

export enum CapaQuotationUserStatus {
  Registered = 'REGISTERED',
  Deleted = 'DELETED'
}

export type CapaReferral = {
  __typename?: 'CAPAReferral';
  referralId: Scalars['ID'];
};

export type CapaReferralOut = {
  __typename?: 'CAPAReferralOut';
  result: CapaErrorCode;
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaReferral>;
};

export enum CapaServiceType {
  Cloud = 'CLOUD',
  Quotation = 'QUOTATION',
  QuotationPartner = 'QUOTATION_PARTNER',
  Connect = 'CONNECT',
  Backoffice = 'BACKOFFICE'
}

export type CapaUser = {
  __typename?: 'CAPAUser';
  id: Scalars['ID'];
  email: Scalars['String'];
  status: CapaUserStatus;
  lastLoginAt?: Maybe<Scalars['String']>;
  lastLogoutAt?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export enum CapaUserStatus {
  Registered = 'REGISTERED',
  Deleted = 'DELETED'
}

export type ChangeEmailInput = {
  newEmail: Scalars['String'];
  code: Scalars['String'];
};

export type ChangePasswordInput = {
  password: Scalars['String'];
  newPassword: Scalars['String'];
  newPasswordConfirm: Scalars['String'];
};

export type FetchCapabilityInput = {
  serviceType?: Maybe<Array<CapaQuotationServiceType>>;
};

export type FetchItpEventsBoInput = {
  listType?: Maybe<CapaItpEventListTypeEnum>;
  quotationUserId?: Maybe<Scalars['String']>;
};

export type FetchMyDriveInput = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};

export type ItpEventFileUploadInput = {
  fileId: Scalars['String'];
};

export enum KickoutReason {
  ClientNotPartner = 'CLIENT_NOT_PARTNER',
  Etc = 'ETC',
  NoMatchingClient = 'NO_MATCHING_CLIENT',
  NoMatchingRfq = 'NO_MATCHING_RFQ',
  TooManyNotification = 'TOO_MANY_NOTIFICATION'
}

export type Mutation = {
  __typename?: 'Mutation';
  register: AuthenticationOutput;
  registerUser: AuthenticationOutput;
  updateUser: AuthenticationOutput;
  refresh: AuthenticationOutput;
  requestEmailVerificationCode: Output;
  requestResetPassword: Output;
  changePassword: Output;
  changeEmail: Output;
  requestReferralId: CapaReferralOut;
  registerConnectUser: AuthenticationOutput;
  removeConnectFile: Output;
  registerItpEvent: Output;
  approveItpEvent: Output;
  registerQuotationUser: AuthenticationOutput;
  updateQuotationUser: CapaQuotationUserOutput;
  registerQuotationPartner: AuthenticationOutput;
  updateQuotationPartner: CapaQuotationPartnerOutput;
  approvedQuotationPartnerByEmail: Output;
  registerQuotationUserBO: Output;
  updateQuotationUserBO: Output;
  withdrawQuotationUserBO: Output;
  registerQuotationPartnerBO: Output;
  updateQuotationPartnerBO: Output;
  withdrawQuotationPartnerBO: Output;
  suggestService: Output;
  switchService: AuthenticationOutput;
  createFormGroupName: Output;
  inviteGroupMembers: Output;
  writeUserActivityLog: Output;
};

export type MutationRegisterArgs = {
  input: RegistrationInput;
};

export type MutationRegisterUserArgs = {
  input: RegisterCapaUserInput;
};

export type MutationUpdateUserArgs = {
  input: UpdateCapaUserInput;
};

export type MutationRefreshArgs = {
  input: TokenRefreshInput;
};

export type MutationRequestEmailVerificationCodeArgs = {
  input: RequestEmailVerificationCodeInput;
};

export type MutationRequestResetPasswordArgs = {
  input: RequestResetPasswordInput;
};

export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};

export type MutationChangeEmailArgs = {
  input: ChangeEmailInput;
};

export type MutationRequestReferralIdArgs = {
  input: RequestReferralInput;
};

export type MutationRemoveConnectFileArgs = {
  input: RequestFileInput;
};

export type MutationRegisterItpEventArgs = {
  input: RegisterItpEventInput;
};

export type MutationApproveItpEventArgs = {
  input: ApproveItpEventInput;
};

export type MutationRegisterQuotationUserArgs = {
  input: RegisterQuotationUserInput;
};

export type MutationUpdateQuotationUserArgs = {
  input: UpdateQuotationUserInput;
};

export type MutationRegisterQuotationPartnerArgs = {
  input: RegisterQuotationPartnerInput;
};

export type MutationUpdateQuotationPartnerArgs = {
  input: UpdateQuotationPartnerInput;
};

export type MutationApprovedQuotationPartnerByEmailArgs = {
  input: ApprovedQuotationPartnerByEmailInput;
};

export type MutationRegisterQuotationUserBoArgs = {
  input: UpdateQuotationUserBoInput;
};

export type MutationUpdateQuotationUserBoArgs = {
  input: UpdateQuotationUserBoInput;
};

export type MutationWithdrawQuotationUserBoArgs = {
  input: WithdrawQuotationUserBoInput;
};

export type MutationRegisterQuotationPartnerBoArgs = {
  input: RegisterQuotationPartnerBoInput;
};

export type MutationUpdateQuotationPartnerBoArgs = {
  input: UpdateQuotationPartnerBoInput;
};

export type MutationWithdrawQuotationPartnerBoArgs = {
  input: WithdrawQuotationPartnerBoInput;
};

export type MutationSuggestServiceArgs = {
  input: SuggestServiceInput;
};

export type MutationSwitchServiceArgs = {
  input: SwitchServiceInput;
};

export type MutationWriteUserActivityLogArgs = {
  input: UserActivityLogInput;
};

/** Default Output */
export type Output = {
  __typename?: 'Output';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
};

export type PartnerCapabilityOutput = {
  __typename?: 'PartnerCapabilityOutput';
  /** CAPA ERROR CODE */
  result: CapaErrorCode;
  /** errorMessage */
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaQuotationPartnerCapability>;
};

export type Query = {
  __typename?: 'Query';
  /** 캐파 유저 통합 로그인 */
  authenticate: AuthenticationOutput;
  /** 이메일 인증 확인 */
  verifyEmailVerificationCode: Output;
  fetchConnectUser: CapaConnectUserOutput;
  fetchConnectUsersBO: CapaConnectUsersBoOutput;
  fetchConnectMyDrive: CapaConnectMyDrivesOutput;
  fetchItpEventsBO: CapaItpEventsBoOutput;
  fetchCapability: PartnerCapabilityOutput;
  verifyCompanyRegistrationNumber: Output;
  fetchMyQuotationUser: CapaQuotationUserOutput;
  fetchQuotationUser: CapaQuotationUserOutput;
  fetchMyQuotationPartner: CapaQuotationPartnerOutput;
  fetchQuotationPartner: CapaQuotationPartnerOutput;
};

export type QueryAuthenticateArgs = {
  input: AuthenticationInput;
};

export type QueryVerifyEmailVerificationCodeArgs = {
  input: VerifyEmailVerificationCodeInput;
};

export type QueryFetchMyDriveArgs = {
  input: FetchMyDriveInput;
};

export type QueryFetchItpEventsBoArgs = {
  input: FetchItpEventsBoInput;
};

export type QueryFetchCapabilityArgs = {
  input: FetchCapabilityInput;
};

export type QueryVerifyCompanyRegistrationNumberArgs = {
  input: VerifyCompanyRegistrationNumberInput;
};

export type QueryFetchQuotationUserArgs = {
  quotationUserId: Scalars['String'];
};

export type QueryFetchQuotationPartnerArgs = {
  quotationPartnerId: Scalars['String'];
};

export type QuotationPartnerFileUploadInput = {
  fileId: Scalars['String'];
  fileType: CapaQuotationPartnerFileType;
};

export type QuotationUserFileUploadInput = {
  fileId: Scalars['String'];
  fileType: CapaQuotationUserFileType;
};

export type RegisterCapaUserInput = {
  email: Scalars['String'];
  code: Scalars['String'];
  password: Scalars['String'];
  passwordConfirm: Scalars['String'];
  inflowRoute: Array<CapaInflowRoute>;
  serviceAgree: Scalars['Boolean'];
  privacyAgree: Scalars['Boolean'];
  promotionAgree?: Maybe<Scalars['Boolean']>;
  service: CapaServiceType;
  referralUserId?: Maybe<Scalars['String']>;
};

export type RegisterItpEventInput = {
  rfqId: Scalars['String'];
  cellphoneNumber: Scalars['String'];
  files: Array<ItpEventFileUploadInput>;
  officeconAgreed: Scalars['Boolean'];
  completeCost: Scalars['Float'];
  projectName: Scalars['String'];
  quotationPartnerId: Scalars['String'];
};

export type RegisterQuotationPartnerBoInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  inflowRoute: Array<CapaInflowRoute>;
  promotionAgree?: Maybe<Scalars['Boolean']>;
  capabilities: CapaQuotationCapabilityInput;
  companyName: Scalars['String'];
  fullAddress: Scalars['String'];
  shortAddress: Scalars['String'];
  companyRegistrationNumber: Scalars['String'];
  companyIntroduction?: Maybe<Scalars['String']>;
  foundedIn?: Maybe<Scalars['String']>;
  numberEmployees?: Maybe<Scalars['String']>;
  equipment?: Maybe<Scalars['String']>;
  serviceHours?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  managerName: Scalars['String'];
  department: Scalars['String'];
  position: Scalars['String'];
  contact: Scalars['String'];
  files?: Maybe<Array<QuotationPartnerFileUploadInput>>;
};

export type RegisterQuotationPartnerInput = {
  capabilities: CapaQuotationCapabilityInput;
  companyName: Scalars['String'];
  fullAddress: Scalars['String'];
  shortAddress: Scalars['String'];
  companyRegistrationNumber: Scalars['String'];
  companyIntroduction?: Maybe<Scalars['String']>;
  foundedIn?: Maybe<Scalars['String']>;
  numberEmployees?: Maybe<Scalars['String']>;
  equipment?: Maybe<Scalars['String']>;
  serviceHours?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  managerName: Scalars['String'];
  department: Scalars['String'];
  position: Scalars['String'];
  contact: Scalars['String'];
  files: Array<QuotationPartnerFileUploadInput>;
};

export type RegisterQuotationUserInput = {
  managerName: Scalars['String'];
  cellphoneNumber: Scalars['String'];
  fullAddress: Scalars['String'];
  shortAddress: Scalars['String'];
  companyRegistrationNumber?: Maybe<Scalars['String']>;
  bizType: CapaQuotationUserBizType;
  organizationName?: Maybe<Scalars['String']>;
  companyIntroduction?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  files?: Maybe<Array<QuotationUserFileUploadInput>>;
  referralUserId?: Maybe<Scalars['String']>;
};

export type RegistrationInput = {
  email: Scalars['String'];
  code: Scalars['String'];
  password: Scalars['String'];
  passwordConfirm: Scalars['String'];
  inflowRoute: Array<CapaInflowRoute>;
  serviceAgree: Scalars['Boolean'];
  privacyAgree: Scalars['Boolean'];
  promotionAgree?: Maybe<Scalars['Boolean']>;
  service: CapaServiceType;
  referralUserId?: Maybe<Scalars['String']>;
  isWebMember?: boolean;
};

export type RequestEmailVerificationCodeInput = {
  email: Scalars['String'];
  service: CapaServiceType;
};

export type RequestFileInput = {
  fileId: Scalars['String'];
};

export type RequestReferralInput = {
  service: CapaServiceType;
};

export type RequestResetPasswordInput = {
  email: Scalars['String'];
};

export type SuggestServiceInput = {
  email?: Maybe<Scalars['String']>;
  suggestion?: Maybe<Scalars['String']>;
};

export type SwitchServiceInput = {
  service: CapaServiceType;
};

export type TokenRefreshInput = {
  refreshToken: Scalars['String'];
};

export type UpdateCapaUserInput = {
  password?: Maybe<Scalars['String']>;
  newPassword?: Maybe<Scalars['String']>;
  newPasswordConfirm?: Maybe<Scalars['String']>;
  status?: Maybe<CapaUserStatus>;
  inflowRoute?: Maybe<Array<CapaInflowRoute>>;
  serviceAgree?: Maybe<Scalars['Boolean']>;
  privacyAgree?: Maybe<Scalars['Boolean']>;
  promotionAgree?: Maybe<Scalars['Boolean']>;
  service: CapaServiceType;
};

export type UpdateQuotationPartnerBoInput = {
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  status: CapaUserStatus;
  inflowRoute?: Maybe<Array<CapaInflowRoute>>;
  promotionAgree?: Maybe<Scalars['Boolean']>;
  partnerStatus: CapaQuotationPartnerStatus;
  capabilities?: Maybe<CapaQuotationCapabilityInput>;
  companyName?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  shortAddress?: Maybe<Scalars['String']>;
  companyRegistrationNumber?: Maybe<Scalars['String']>;
  companyIntroduction?: Maybe<Scalars['String']>;
  foundedIn?: Maybe<Scalars['String']>;
  numberEmployees?: Maybe<Scalars['String']>;
  equipment?: Maybe<Scalars['String']>;
  serviceHours?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  managerName?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  contact?: Maybe<Scalars['String']>;
  notificationFilter?: Maybe<CapaQuotationPartnerNotificationFilterInput>;
  files?: Maybe<Array<QuotationPartnerFileUploadInput>>;
};

export type UpdateQuotationPartnerInput = {
  capabilities?: Maybe<CapaQuotationCapabilityInput>;
  companyName?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  shortAddress?: Maybe<Scalars['String']>;
  companyRegistrationNumber?: Maybe<Scalars['String']>;
  companyIntroduction?: Maybe<Scalars['String']>;
  foundedIn?: Maybe<Scalars['String']>;
  numberEmployees?: Maybe<Scalars['String']>;
  equipment?: Maybe<Scalars['String']>;
  serviceHours?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  managerName?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  contact?: Maybe<Scalars['String']>;
  notificationFilter?: Maybe<CapaQuotationPartnerNotificationFilterInput>;
  files?: Maybe<Array<QuotationPartnerFileUploadInput>>;
  serviceAgree?: Maybe<Scalars['Boolean']>;
  privacyAgree?: Maybe<Scalars['Boolean']>;
  promotionAgree?: Maybe<Scalars['Boolean']>;
};

export type UpdateQuotationUserBoInput = {
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  status: CapaUserStatus;
  inflowRoute?: Maybe<Array<CapaInflowRoute>>;
  promotionAgree?: Maybe<Scalars['Boolean']>;
  userStatus: CapaQuotationUserStatus;
  managerName?: Maybe<Scalars['String']>;
  cellphoneNumber?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  shortAddress?: Maybe<Scalars['String']>;
  companyRegistrationNumber?: Maybe<Scalars['String']>;
  bizType?: Maybe<CapaQuotationUserBizType>;
  organizationName?: Maybe<Scalars['String']>;
  companyIntroduction?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  files?: Maybe<Array<QuotationUserFileUploadInput>>;
};

export type UpdateQuotationUserInput = {
  managerName?: Maybe<Scalars['String']>;
  cellphoneNumber?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  shortAddress?: Maybe<Scalars['String']>;
  companyRegistrationNumber?: Maybe<Scalars['String']>;
  bizType?: Maybe<CapaQuotationUserBizType>;
  organizationName?: Maybe<Scalars['String']>;
  companyIntroduction?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  files?: Maybe<Array<QuotationUserFileUploadInput>>;
  serviceAgree?: Maybe<Scalars['Boolean']>;
  privacyAgree?: Maybe<Scalars['Boolean']>;
  promotionAgree?: Maybe<Scalars['Boolean']>;
};

export type UserActivityLogInput = {
  email?: Maybe<Scalars['String']>;
  activityType?: Maybe<CapaActivityType>;
  activitiId?: Maybe<Scalars['String']>;
  ip?: Maybe<Scalars['String']>;
  os?: Maybe<Scalars['String']>;
  deviceType?: Maybe<Scalars['String']>;
  browser?: Maybe<Scalars['String']>;
  service?: Maybe<CapaServiceType>;
};

export type VerifyCompanyRegistrationNumberInput = {
  companyRegistrationNumber: Scalars['String'];
};

export type VerifyEmailVerificationCodeInput = {
  email: Scalars['String'];
  code: Scalars['String'];
};

export type WithdrawQuotationPartnerBoInput = {
  email: Scalars['String'];
  kickoutReason?: Maybe<Array<KickoutReason>>;
  kickoutReasonEtc?: Maybe<Scalars['String']>;
};

export type WithdrawQuotationUserBoInput = {
  email: Scalars['String'];
  kickoutReason?: Maybe<Array<KickoutReason>>;
  kickoutReasonEtc?: Maybe<Scalars['String']>;
};

export type AuthenticateQueryVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;

export type AuthenticateQuery = { __typename?: 'Query' } & {
  authenticate: { __typename?: 'AuthenticationOutput' } & Pick<
    AuthenticationOutput,
    'result' | 'errorMessage'
  > & {
      data?: Maybe<
        { __typename?: 'Authentication' } & Pick<
          Authentication,
          | 'accessToken'
          | 'tokenType'
          | 'expiresIn'
          | 'refreshToken'
          | 'redirectUrl'
        >
      >;
    };
};

export type RefreshAuthenticateMutationVariables = Exact<{
  refreshToken: Scalars['String'];
}>;

export type RefreshAuthenticateMutation = { __typename?: 'Mutation' } & {
  refresh: { __typename?: 'AuthenticationOutput' } & Pick<
    AuthenticationOutput,
    'result' | 'errorMessage'
  > & {
      data?: Maybe<
        { __typename?: 'Authentication' } & Pick<
          Authentication,
          | 'accessToken'
          | 'tokenType'
          | 'expiresIn'
          | 'refreshToken'
          | 'redirectUrl'
        >
      >;
    };
};

export type SwitchServiceMutationVariables = Exact<{
  service: CapaServiceType;
}>;

export type SwitchServiceMutation = { __typename?: 'Mutation' } & {
  switchService: { __typename?: 'AuthenticationOutput' } & Pick<
    AuthenticationOutput,
    'errorMessage' | 'result'
  > & {
      data?: Maybe<
        { __typename?: 'Authentication' } & Pick<
          Authentication,
          'accessToken' | 'refreshToken'
        >
      >;
    };
};

export type ChangeEmailMutationVariables = Exact<{
  newEmail: Scalars['String'];
  code: Scalars['String'];
}>;

export type ChangeEmailMutation = { __typename?: 'Mutation' } & {
  changeEmail: { __typename?: 'Output' } & Pick<
    Output,
    'result' | 'errorMessage'
  >;
};

export type FetchConnectUserQueryVariables = Exact<{ [key: string]: never }>;

export type FetchConnectUserQuery = { __typename?: 'Query' } & {
  fetchConnectUser: { __typename?: 'CAPAConnectUserOutput' } & Pick<
    CapaConnectUserOutput,
    'result' | 'errorMessage'
  > & {
      data?: Maybe<
        { __typename?: 'CAPAConnectUser' } & Pick<
          CapaConnectUser,
          | 'id'
          | 'email'
          | 'usedSize'
          | 'availSize'
          | 'driveSize'
          | 'serviceAgreed'
          | 'privacyAgreed'
          | 'promotionAgreed'
          | 'userName'
          | 'notificationFilter'
          | 'chatRoomUserId'
        >
      >;
    };
};

export type FetchMyDriveQueryVariables = Exact<{
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  pathId: Scalars['String'];
}>;

export type InviteConnectGroupUserInput = {
  email: Scalars['String'];
  accessLevel: Maybe<CapaConnectGroupAccessLevel>;
};

export type FetchMyDriveFormGroupNameQueryVariables = Exact<{
  groupName: Scalars['String'];
}>;

export type FetchMyDriveInviteGroupMembersQueryVariables = Exact<{
  groupId: Scalars['ID'];
  inviteUsers: Maybe<Array<InviteConnectGroupUserInput>>;
}>;

export type FetchMyDriveQuery = { __typename?: 'Query' } & {
  fetchConnectMyDrive: { __typename?: 'CAPAConnectMyDrivesOutput' } & Pick<
    CapaConnectMyDrivesOutput,
    'result' | 'errorMessage' | 'data'
  > & {
      data?: Maybe<
        { __typename?: 'CAPAConnectMyDrive' } & Pick<
          CapaConnectMyDrive,
          'files' | 'path'
        >
      >;
    } & {
      files?: Maybe<
        { __typename?: 'CAPAConnectMyFile' } & Pick<
          CapaConnectMyFile,
          | 'fileId'
          | 'fileName'
          | 'fileSize'
          | 'status'
          | 'thumbnail'
          | 'isFile'
          | 'drawingFile'
          | 'createdAt'
          | 'updatedAt'
          | 'filePath'
          | 'id'
          | 'isShared'
          | 'linkStatus'
          | 'filePermission'
          | 'originalFileExt'
          | 'thumbnailUri'
        >
      >;
    } & {
      path?: Maybe<
        { __typename?: 'CAPAConnectFolder' } & Pick<
          CapaConnectFolder,
          'id' | 'folderName'
        >
      >;
    };
};

export type RemoveConnectFileMutationVariables = Exact<{
  fileIds?: Maybe<Scalars['String'][]>;
  isFolder?: Scalars['Boolean'];
  filePath?: Maybe<Scalars['String']>;
}>;

export type RemoveConnectFileMutation = { __typename?: 'Mutation' } & {
  removeConnectFile: { __typename?: 'Output' } & Pick<
    Output,
    'result' | 'errorMessage'
  >;
};

export type RequestResetPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type RequestResetPasswordMutation = { __typename?: 'Mutation' } & {
  requestResetPassword: { __typename?: 'Output' } & Pick<
    Output,
    'result' | 'errorMessage'
  >;
};

export type RequestCreateGroupNameMutation = { __typename?: 'Mutation' } & {
  createFormGroupName: { __typename?: 'Output' } & Pick<
    Output,
    'result' | 'errorMessage'
  >;
};

export type RequestInviteGroupMembersNameMutation = {
  __typename?: 'Mutation';
} & {
  inviteGroupMembers: { __typename?: 'Output' } & Pick<
    Output,
    'result' | 'errorMessage'
  >;
};

export type ChangePasswordMutationVariables = Exact<{
  password: Scalars['String'];
  newPassword: Scalars['String'];
  newPasswordConfirm: Scalars['String'];
}>;

export type ChangePasswordMutation = { __typename?: 'Mutation' } & {
  changePassword: { __typename?: 'Output' } & Pick<
    Output,
    'result' | 'errorMessage'
  >;
};

export type RegisterUserMutationVariables = Exact<{
  input: RegistrationInput;
}>;

export type RegisterUserMutation = { __typename?: 'Mutation' } & {
  register: { __typename?: 'AuthenticationOutput' } & Pick<
    AuthenticationOutput,
    'result' | 'errorMessage'
  > & {
      data?: Maybe<
        { __typename?: 'Authentication' } & Pick<
          Authentication,
          | 'accessToken'
          | 'tokenType'
          | 'expiresIn'
          | 'refreshToken'
          | 'redirectUrl'
        >
      >;
    };
};

export type RegisterConnectUserMutationVariables = Exact<{
  userName: Scalars['String'];
  userLanguage: Maybe<Language>;
  isWebMember?: boolean;
}>;

export type RegisterConnectUserMutation = { __typename?: 'Mutation' } & {
  registerConnectUser: { __typename?: 'AuthenticationOutput' } & Pick<
    AuthenticationOutput,
    'result' | 'errorMessage'
  > & {
      data?: Maybe<
        { __typename?: 'Authentication' } & Pick<
          Authentication,
          | 'accessToken'
          | 'refreshToken'
          | 'redirectUrl'
          | 'tokenType'
          | 'expiresIn'
        >
      >;
    };
};

export type RequestEmailVerificationCodeMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type RequestEmailVerificationCodeMutation = {
  __typename?: 'Mutation';
} & {
  requestEmailVerificationCode: { __typename?: 'Output' } & Pick<
    Output,
    'result' | 'errorMessage'
  >;
};

export type VerifyEmailVerificationCodeQueryVariables = Exact<{
  email: Scalars['String'];
  code: Scalars['String'];
}>;

export type VerifyEmailVerificationCodeQuery = { __typename?: 'Query' } & {
  verifyEmailVerificationCode: { __typename?: 'Output' } & Pick<
    Output,
    'result' | 'errorMessage'
  >;
};

export type WriteUserActivityLogMutationVariables = Exact<{
  input: UserActivityLogInput;
}>;

export type WriteUserActivityLogMutation = { __typename?: 'Mutation' } & {
  writeUserActivityLog: { __typename?: 'Output' } & Pick<
    Output,
    'result' | 'errorMessage'
  >;
};

export const AuthenticateDocument = gql`
  query authenticate($email: String!, $password: String!) {
    authenticate(
      input: { email: $email, password: $password, service: CLOUD }
    ) {
      result
      errorMessage
      data {
        accessToken
        tokenType
        expiresIn
        refreshToken
        redirectUrl
      }
    }
  }
`;

/**
 * __useAuthenticateQuery__
 *
 * To run a query within a React component, call `useAuthenticateQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthenticateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthenticateQuery({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useAuthenticateQuery(
  baseOptions: Apollo.QueryHookOptions<
    AuthenticateQuery,
    AuthenticateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AuthenticateQuery, AuthenticateQueryVariables>(
    AuthenticateDocument,
    options
  );
}
export function useAuthenticateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AuthenticateQuery,
    AuthenticateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AuthenticateQuery, AuthenticateQueryVariables>(
    AuthenticateDocument,
    options
  );
}
export type AuthenticateQueryHookResult = ReturnType<
  typeof useAuthenticateQuery
>;
export type AuthenticateLazyQueryHookResult = ReturnType<
  typeof useAuthenticateLazyQuery
>;
export type AuthenticateQueryResult = Apollo.QueryResult<
  AuthenticateQuery,
  AuthenticateQueryVariables
>;
export const RefreshAuthenticateDocument = gql`
  mutation refreshAuthenticate($refreshToken: String!) {
    refresh(input: { refreshToken: $refreshToken }) {
      result
      errorMessage
      data {
        accessToken
        tokenType
        expiresIn
        refreshToken
        redirectUrl
      }
    }
  }
`;
export type RefreshAuthenticateMutationFn = Apollo.MutationFunction<
  RefreshAuthenticateMutation,
  RefreshAuthenticateMutationVariables
>;

/**
 * __useRefreshAuthenticateMutation__
 *
 * To run a mutation, you first call `useRefreshAuthenticateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshAuthenticateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshAuthenticateMutation, { data, loading, error }] = useRefreshAuthenticateMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useRefreshAuthenticateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefreshAuthenticateMutation,
    RefreshAuthenticateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RefreshAuthenticateMutation,
    RefreshAuthenticateMutationVariables
  >(RefreshAuthenticateDocument, options);
}
export type RefreshAuthenticateMutationHookResult = ReturnType<
  typeof useRefreshAuthenticateMutation
>;
export type RefreshAuthenticateMutationResult =
  Apollo.MutationResult<RefreshAuthenticateMutation>;
export type RefreshAuthenticateMutationOptions = Apollo.BaseMutationOptions<
  RefreshAuthenticateMutation,
  RefreshAuthenticateMutationVariables
>;
export const SwitchServiceDocument = gql`
  mutation switchService($service: CAPAServiceType!) {
    switchService(input: { service: $service }) {
      errorMessage
      result
      data {
        accessToken
        refreshToken
      }
    }
  }
`;
export type SwitchServiceMutationFn = Apollo.MutationFunction<
  SwitchServiceMutation,
  SwitchServiceMutationVariables
>;

/**
 * __useSwitchServiceMutation__
 *
 * To run a mutation, you first call `useSwitchServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwitchServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [switchServiceMutation, { data, loading, error }] = useSwitchServiceMutation({
 *   variables: {
 *      service: // value for 'service'
 *   },
 * });
 */
export function useSwitchServiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SwitchServiceMutation,
    SwitchServiceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SwitchServiceMutation,
    SwitchServiceMutationVariables
  >(SwitchServiceDocument, options);
}
export type SwitchServiceMutationHookResult = ReturnType<
  typeof useSwitchServiceMutation
>;
export type SwitchServiceMutationResult =
  Apollo.MutationResult<SwitchServiceMutation>;
export type SwitchServiceMutationOptions = Apollo.BaseMutationOptions<
  SwitchServiceMutation,
  SwitchServiceMutationVariables
>;
export const ChangeEmailDocument = gql`
  mutation changeEmail($newEmail: String!, $code: String!) {
    changeEmail(input: { newEmail: $newEmail, code: $code }) {
      result
      errorMessage
    }
  }
`;
export type ChangeEmailMutationFn = Apollo.MutationFunction<
  ChangeEmailMutation,
  ChangeEmailMutationVariables
>;

/**
 * __useChangeEmailMutation__
 *
 * To run a mutation, you first call `useChangeEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeEmailMutation, { data, loading, error }] = useChangeEmailMutation({
 *   variables: {
 *      newEmail: // value for 'newEmail'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useChangeEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeEmailMutation,
    ChangeEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChangeEmailMutation, ChangeEmailMutationVariables>(
    ChangeEmailDocument,
    options
  );
}
export type ChangeEmailMutationHookResult = ReturnType<
  typeof useChangeEmailMutation
>;
export type ChangeEmailMutationResult =
  Apollo.MutationResult<ChangeEmailMutation>;
export type ChangeEmailMutationOptions = Apollo.BaseMutationOptions<
  ChangeEmailMutation,
  ChangeEmailMutationVariables
>;
export const FetchConnectUserDocument = gql`
  query fetchConnectUser {
    fetchConnectUser {
      result
      errorMessage
      data {
        id
        email
        usedSize
        availSize
        driveSize
        serviceAgreed
        privacyAgreed
        promotionAgreed
        userName
        chatRoomUserId
        notificationFilter {
          chatPush
          groupPush
          groupEmail
          commentThreadPush
          commentThreadEmail
          filePush
          fileEmail
        }
      }
    }
  }
`;

/**
 * __useFetchConnectUserQuery__
 *
 * To run a query within a React component, call `useFetchConnectUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchConnectUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchConnectUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchConnectUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchConnectUserQuery,
    FetchConnectUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchConnectUserQuery, FetchConnectUserQueryVariables>(
    FetchConnectUserDocument,
    options
  );
}
export function useFetchConnectUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchConnectUserQuery,
    FetchConnectUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchConnectUserQuery,
    FetchConnectUserQueryVariables
  >(FetchConnectUserDocument, options);
}
export type FetchConnectUserQueryHookResult = ReturnType<
  typeof useFetchConnectUserQuery
>;
export type FetchConnectUserLazyQueryHookResult = ReturnType<
  typeof useFetchConnectUserLazyQuery
>;
export type FetchConnectUserQueryResult = Apollo.QueryResult<
  FetchConnectUserQuery,
  FetchConnectUserQueryVariables
>;
export const FetchMyDriveDocument = gql`
  query fetchConnectMyDrive($limit: Float, $offset: Float, $pathId: String) {
    fetchConnectMyDrive(
      input: { limit: $limit, offset: $offset, pathId: $pathId }
    ) {
      result
      errorMessage
      data {
        files {
          id
          fileId
          isShared
          fileName
          fileSize
          originalFileExt
          filePath
          status
          thumbnail
          drawingFile
          isFile
          linkStatus
          filePermission
          thumbnailUri
          createdAt
          updatedAt
        }
        path {
          id
          folderName
        }
      }
    }
  }
`;

/**
 * __useFetchMyDriveQuery__
 *
 * To run a query within a React component, call `useFetchMyDriveQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMyDriveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMyDriveQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useFetchMyDriveQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchMyDriveQuery,
    FetchMyDriveQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchMyDriveQuery, FetchMyDriveQueryVariables>(
    FetchMyDriveDocument,
    options
  );
}
export function useFetchMyDriveLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchMyDriveQuery,
    FetchMyDriveQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchMyDriveQuery, FetchMyDriveQueryVariables>(
    FetchMyDriveDocument,
    options
  );
}
export type FetchMyDriveQueryHookResult = ReturnType<
  typeof useFetchMyDriveQuery
>;
export type FetchMyDriveLazyQueryHookResult = ReturnType<
  typeof useFetchMyDriveLazyQuery
>;
export type FetchMyDriveQueryResult = Apollo.QueryResult<
  FetchMyDriveQuery,
  FetchMyDriveQueryVariables
>;
export const RemoveConnectFileDocument = gql`
  mutation removeConnectFile(
    $fileIds: [String!]!
    $isFolder: Boolean
    $filePath: String
  ) {
    removeConnectFile(
      input: { fileIds: $fileIds, isFolder: $isFolder, filePath: $filePath }
    ) {
      result
      errorMessage
    }
  }
`;
export type RemoveConnectFileMutationFn = Apollo.MutationFunction<
  RemoveConnectFileMutation,
  RemoveConnectFileMutationVariables
>;

/**
 * __useRemoveConnectFileMutation__
 *
 * To run a mutation, you first call `useRemoveConnectFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveConnectFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeConnectFileMutation, { data, loading, error }] = useRemoveConnectFileMutation({
 *   variables: {
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useRemoveConnectFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveConnectFileMutation,
    RemoveConnectFileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveConnectFileMutation,
    RemoveConnectFileMutationVariables
  >(RemoveConnectFileDocument, options);
}

export type RemoveConnectFileMutationHookResult = ReturnType<
  typeof useRemoveConnectFileMutation
>;

export type RemoveConnectFileMutationResult =
  Apollo.MutationResult<RemoveConnectFileMutation>;
export type RemoveConnectFileMutationOptions = Apollo.BaseMutationOptions<
  RemoveConnectFileMutation,
  RemoveConnectFileMutationVariables
>;
export const RequestResetPasswordDocument = gql`
  mutation requestResetPassword($email: String!) {
    requestResetPassword(input: { email: $email }) {
      result
      errorMessage
    }
  }
`;
export type RequestResetPasswordMutationFn = Apollo.MutationFunction<
  RequestResetPasswordMutation,
  RequestResetPasswordMutationVariables
>;

/**
 * __useRequestResetPasswordMutation__
 *
 * To run a mutation, you first call `useRequestResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestResetPasswordMutation, { data, loading, error }] = useRequestResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRequestResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestResetPasswordMutation,
    RequestResetPasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestResetPasswordMutation,
    RequestResetPasswordMutationVariables
  >(RequestResetPasswordDocument, options);
}
export type RequestResetPasswordMutationHookResult = ReturnType<
  typeof useRequestResetPasswordMutation
>;
export type RequestResetPasswordMutationResult =
  Apollo.MutationResult<RequestResetPasswordMutation>;
export type RequestResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  RequestResetPasswordMutation,
  RequestResetPasswordMutationVariables
>;
export const ChangePasswordDocument = gql`
  mutation changePassword(
    $password: String!
    $newPassword: String!
    $newPasswordConfirm: String!
  ) {
    changePassword(
      input: {
        password: $password
        newPassword: $newPassword
        newPasswordConfirm: $newPasswordConfirm
      }
    ) {
      result
      errorMessage
    }
  }
`;
export type ChangePasswordMutationFn = Apollo.MutationFunction<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *      newPassword: // value for 'newPassword'
 *      newPasswordConfirm: // value for 'newPasswordConfirm'
 *   },
 * });
 */
export function useChangePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >(ChangePasswordDocument, options);
}
export type ChangePasswordMutationHookResult = ReturnType<
  typeof useChangePasswordMutation
>;
export type ChangePasswordMutationResult =
  Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;
export const RegisterUserDocument = gql`
  mutation registerUser($input: RegistrationInput!) {
    register(input: $input) {
      result
      errorMessage
      data {
        accessToken
        tokenType
        expiresIn
        refreshToken
        redirectUrl
      }
    }
  }
`;
export type RegisterUserMutationFn = Apollo.MutationFunction<
  RegisterUserMutation,
  RegisterUserMutationVariables
>;

/**
 * __useRegisterUserMutation__
 *
 * To run a mutation, you first call `useRegisterUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserMutation, { data, loading, error }] = useRegisterUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterUserMutation,
    RegisterUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegisterUserMutation,
    RegisterUserMutationVariables
  >(RegisterUserDocument, options);
}
export type RegisterUserMutationHookResult = ReturnType<
  typeof useRegisterUserMutation
>;
export type RegisterUserMutationResult =
  Apollo.MutationResult<RegisterUserMutation>;
export type RegisterUserMutationOptions = Apollo.BaseMutationOptions<
  RegisterUserMutation,
  RegisterUserMutationVariables
>;
export const RegisterConnectUserDocument = gql`
  mutation registerConnectUser(
    $userName: String!
    $userLanguage: Language!
    $isWebMember: Boolean!
  ) {
    registerConnectUser(
      input: {
        userName: $userName
        userLanguage: $userLanguage
        isWebMember: $isWebMember
      }
    ) {
      result
      errorMessage
      data {
        accessToken
        refreshToken
        redirectUrl
        tokenType
        expiresIn
      }
    }
  }
`;
export type RegisterConnectUserMutationFn = Apollo.MutationFunction<
  RegisterConnectUserMutation,
  RegisterConnectUserMutationVariables
>;

/**
 * __useRegisterConnectUserMutation__
 *
 * To run a mutation, you first call `useRegisterConnectUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterConnectUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerConnectUserMutation, { data, loading, error }] = useRegisterConnectUserMutation({
 *   variables: {
 *   },
 * });
 */
export function useRegisterConnectUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterConnectUserMutation,
    RegisterConnectUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegisterConnectUserMutation,
    RegisterConnectUserMutationVariables
  >(RegisterConnectUserDocument, options);
}
export type RegisterConnectUserMutationHookResult = ReturnType<
  typeof useRegisterConnectUserMutation
>;
export type RegisterConnectUserMutationResult =
  Apollo.MutationResult<RegisterConnectUserMutation>;
export type RegisterConnectUserMutationOptions = Apollo.BaseMutationOptions<
  RegisterConnectUserMutation,
  RegisterConnectUserMutationVariables
>;
export const RequestEmailVerificationCodeDocument = gql`
  mutation requestEmailVerificationCode($email: String!) {
    requestEmailVerificationCode(input: { email: $email, service: CLOUD }) {
      result
      errorMessage
    }
  }
`;
export type RequestEmailVerificationCodeMutationFn = Apollo.MutationFunction<
  RequestEmailVerificationCodeMutation,
  RequestEmailVerificationCodeMutationVariables
>;

/**
 * __useRequestEmailVerificationCodeMutation__
 *
 * To run a mutation, you first call `useRequestEmailVerificationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestEmailVerificationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestEmailVerificationCodeMutation, { data, loading, error }] = useRequestEmailVerificationCodeMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRequestEmailVerificationCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestEmailVerificationCodeMutation,
    RequestEmailVerificationCodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestEmailVerificationCodeMutation,
    RequestEmailVerificationCodeMutationVariables
  >(RequestEmailVerificationCodeDocument, options);
}
export type RequestEmailVerificationCodeMutationHookResult = ReturnType<
  typeof useRequestEmailVerificationCodeMutation
>;
export type RequestEmailVerificationCodeMutationResult =
  Apollo.MutationResult<RequestEmailVerificationCodeMutation>;
export type RequestEmailVerificationCodeMutationOptions =
  Apollo.BaseMutationOptions<
    RequestEmailVerificationCodeMutation,
    RequestEmailVerificationCodeMutationVariables
  >;
export const VerifyEmailVerificationCodeDocument = gql`
  query verifyEmailVerificationCode($email: String!, $code: String!) {
    verifyEmailVerificationCode(input: { email: $email, code: $code }) {
      result
      errorMessage
    }
  }
`;

/**
 * __useVerifyEmailVerificationCodeQuery__
 *
 * To run a query within a React component, call `useVerifyEmailVerificationCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailVerificationCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyEmailVerificationCodeQuery({
 *   variables: {
 *      email: // value for 'email'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useVerifyEmailVerificationCodeQuery(
  baseOptions: Apollo.QueryHookOptions<
    VerifyEmailVerificationCodeQuery,
    VerifyEmailVerificationCodeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    VerifyEmailVerificationCodeQuery,
    VerifyEmailVerificationCodeQueryVariables
  >(VerifyEmailVerificationCodeDocument, options);
}
export function useVerifyEmailVerificationCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VerifyEmailVerificationCodeQuery,
    VerifyEmailVerificationCodeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    VerifyEmailVerificationCodeQuery,
    VerifyEmailVerificationCodeQueryVariables
  >(VerifyEmailVerificationCodeDocument, options);
}
export type VerifyEmailVerificationCodeQueryHookResult = ReturnType<
  typeof useVerifyEmailVerificationCodeQuery
>;
export type VerifyEmailVerificationCodeLazyQueryHookResult = ReturnType<
  typeof useVerifyEmailVerificationCodeLazyQuery
>;
export type VerifyEmailVerificationCodeQueryResult = Apollo.QueryResult<
  VerifyEmailVerificationCodeQuery,
  VerifyEmailVerificationCodeQueryVariables
>;
export const WriteUserActivityLogDocument = gql`
  mutation writeUserActivityLog($input: UserActivityLogInput!) {
    writeUserActivityLog(input: $input) {
      result
      errorMessage
    }
  }
`;
export type WriteUserActivityLogMutationFn = Apollo.MutationFunction<
  WriteUserActivityLogMutation,
  WriteUserActivityLogMutationVariables
>;

/**
 * __useWriteUserActivityLogMutation__
 *
 * To run a mutation, you first call `useWriteUserActivityLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWriteUserActivityLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [writeUserActivityLogMutation, { data, loading, error }] = useWriteUserActivityLogMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWriteUserActivityLogMutation(
  baseOptions?: Apollo.MutationHookOptions<
    WriteUserActivityLogMutation,
    WriteUserActivityLogMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    WriteUserActivityLogMutation,
    WriteUserActivityLogMutationVariables
  >(WriteUserActivityLogDocument, options);
}
export type WriteUserActivityLogMutationHookResult = ReturnType<
  typeof useWriteUserActivityLogMutation
>;
export type WriteUserActivityLogMutationResult =
  Apollo.MutationResult<WriteUserActivityLogMutation>;
export type WriteUserActivityLogMutationOptions = Apollo.BaseMutationOptions<
  WriteUserActivityLogMutation,
  WriteUserActivityLogMutationVariables
>;

export const CreateGroupName = gql`
  mutation createGroup($groupName: String!) {
    createGroup(input: { groupName: $groupName }) {
      result
      errorMessage
      data {
        id
        chatRoomId
      }
    }
  }
`;

export function useCreateGroupNameMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestCreateGroupNameMutation,
    FetchMyDriveFormGroupNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestCreateGroupNameMutation,
    FetchMyDriveFormGroupNameQueryVariables
  >(CreateGroupName, options);
}

export const InviteGroupMembers = gql`
  mutation inviteGroupMembers(
    $groupId: String!
    $inviteUsers: [InviteConnectGroupUserInput!]!
  ) {
    inviteGroupMembers(
      input: { groupId: $groupId, inviteUsers: $inviteUsers }
    ) {
      result
      errorMessage
    }
  }
`;

export function useInviteGroupMembersNameMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestInviteGroupMembersNameMutation,
    FetchMyDriveInviteGroupMembersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestInviteGroupMembersNameMutation,
    FetchMyDriveInviteGroupMembersQueryVariables
  >(InviteGroupMembers, options);
}

// Declare API createConnectFeedback

export type CreateConnectFeedbackMutation = {
  __typename?: 'Mutation';
} & {
  createConnectFeedback: { __typename?: 'Output' } & Pick<
    Output,
    'result' | 'errorMessage'
  >;
};

export type CreateConnectFeedbackMutationVariables = Exact<{
  rating: Scalars['Float'];
  feedBack: Scalars['String'];
  fileIds: Maybe<[Scalars['String']]>;
}>;

export const CreateConnectFeedback = gql`
  mutation createConnectFeedback(
    $rating: Float!
    $feedBack: String!
    $fileIds: [String!]
  ) {
    createConnectFeedback(
      input: { rating: $rating, feedBack: $feedBack, fileIds: $fileIds }
    ) {
      result
      errorMessage
    }
  }
`;

export function useCreateConnectFeedback(
  baseOptions?: Apollo.MutationHookOptions<
    CreateConnectFeedbackMutation,
    CreateConnectFeedbackMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateConnectFeedbackMutation,
    CreateConnectFeedbackMutationVariables
  >(CreateConnectFeedback, options);
}

// Declare API creatConnectIssue

export type CreateConnectIssueMutation = {
  __typename?: 'Mutation';
} & {
  createConnectIssue: { __typename?: 'Output' } & Pick<
    Output,
    'result' | 'errorMessage'
  >;
};

export type CreateConnectIssueMutationVariables = Exact<{
  issue: Scalars['String'];
  fileIds: Maybe<[Scalars['String']]>;
}>;

export const CreateConnectIssue = gql`
  mutation createConnectIssue($issue: String!, $fileIds: [String!]) {
    createConnectIssue(input: { issue: $issue, fileIds: $fileIds }) {
      result
      errorMessage
    }
  }
`;

export function useCreateConnectIssue(
  baseOptions?: Apollo.MutationHookOptions<
    CreateConnectIssueMutation,
    CreateConnectIssueMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateConnectIssueMutation,
    CreateConnectIssueMutationVariables
  >(CreateConnectIssue, options);
}
