import React from 'react';
import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.tooltip + 999,
    color: theme.palette.primary.main
  }
}));

interface Props {
  open: boolean;
}

const LoadingPresenter: React.FC<Props> = ({ open }) => {
  const { backdrop } = useStyles();
  if (!open) return null;

  return (
    <Backdrop className={backdrop} open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default LoadingPresenter;
