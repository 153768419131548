import { STORAGE_ACTION } from './../../../utils/storage';
import { ActionType } from 'typesafe-actions';
import * as actions from './actions';
import { SHARE_DIALOG_SCREEN } from '@routes/Dashboard/Dialog/Share/types';
import { UPLOAD_FILE_TAB } from '@routes/Common/Dialog/UploadFiles/types';
import { ADD_FILES_FROM_VALUE } from '@routes/Common/Dialog/UploadFiles/Mobile/types';

export interface MyDriveState {
  detailDrive?: any;
  isShowIsDetailFile?: boolean;
  isShowHideMenuCreate?: boolean;
  path?: string;
  locationName?: string;
  isShowHideShareDialog?: boolean;
  shareFilesToType?: any;
  shareDialogScreen?: any;
  valueGroupNameDialog?: string;
  isShowHideUploadFromMyDriveDialog?: boolean;
  isShowFileGroup?: boolean;
  isShowHideDownloadPermissionDialog?: boolean;
  idGroupName?: string;
  chatRoomIdGroupName?: string;
  selectedGroupShared?: any;
  isShowFileViewer?: boolean;
  isShowHideConfirmDialog?: boolean;
  selectedFilesUploadInChat?: any;
  internalFileFromComputer?: any;
  uploadFileTab?: number;
  addFilesFromType?: any;
  isShowGroupFiles?: boolean;
  isShowRemoveGroupFiles?: boolean;
  isFirstSelectedDrawFile?: boolean;
  activeFileNameInGroupRoom?: string;
  isActionAnnotationSuccess?: boolean;
  activeFileIdInGroupRoom?: string;
  totalNotification?: number;
  selectDrawFileFromCommentList?: any;
  isRenameSuccess?: boolean;
  isUploadFileFromComputer?: boolean;
  isDragDropFilesToGroupRoom?: boolean;
  isMyDrivePageLoading?: boolean;
  successfullyShareContent?: any;
  isShowHideRequestForDownload?: boolean;
  chatFilesOrigin?: any;
}

export const initialMyDriveState: MyDriveState = {
  isShowHideMenuCreate: false,
  isShowIsDetailFile:
    localStorage.getItem(STORAGE_ACTION.IS_OPEN_DETAIL_FILE) === 'true'
      ? true
      : false,
  path: '',
  detailDrive: {},
  locationName: '',
  isShowHideShareDialog: false,
  valueGroupNameDialog: '',
  shareFilesToType: null,
  shareDialogScreen: SHARE_DIALOG_SCREEN.SHARE,
  idGroupName: '',
  chatRoomIdGroupName: '',
  isShowHideUploadFromMyDriveDialog: false,
  isShowHideDownloadPermissionDialog: false,
  isShowFileGroup: false,
  selectedGroupShared: {},
  isShowFileViewer: false,
  isShowHideConfirmDialog: false,
  selectedFilesUploadInChat: [],
  internalFileFromComputer: [],
  uploadFileTab: UPLOAD_FILE_TAB.FROM_MY_DRIVE,
  addFilesFromType: ADD_FILES_FROM_VALUE.EMPTY,
  isShowGroupFiles: true,
  isShowRemoveGroupFiles: false,
  isFirstSelectedDrawFile: false,
  activeFileNameInGroupRoom: '',
  isActionAnnotationSuccess: false,
  activeFileIdInGroupRoom: '',
  totalNotification: undefined,
  selectDrawFileFromCommentList: {
    annotationId: '',
    commentId: ''
  },
  isRenameSuccess: false,
  isUploadFileFromComputer: false,
  isDragDropFilesToGroupRoom: false,
  isMyDrivePageLoading: false,
  successfullyShareContent: '',
  isShowHideRequestForDownload: false,
  chatFilesOrigin: []
};

export type MyDetailAction = ActionType<typeof actions>;
