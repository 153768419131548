import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@modules/index';
import {
  MyDriveState,
  setFileDetail,
  setShowHideMenuCreate,
  setPath,
  setLocationName,
  setShowHideShareDialog,
  setShareFilesToType,
  setShareDialogScreen,
  setValueGroupNameDialog,
  setShowHideUploadFromMyDriveDialog,
  setShowHideDownloadPermissionDialog,
  setIdGroupName,
  setChatRoomIdGroupName,
  setSelectedGroupShared,
  setShowFileViewer,
  setShowHideConfirmDialog,
  setSelectedFilesUploadInChat,
  setUploadFileTab,
  setInternalFileFromComputer,
  setAddFilesFromType,
  setToggleGroupFiles,
  setToggleRemoveGroupFiles,
  setFirstSelectedDrawFile,
  setFileNameInGroupRoom,
  setActionAnnotationSuccess,
  setActiveFileIdInGroupRoom,
  setTotalNotification,
  setSelectDrawFileFromCommentList,
  setRenameSuccessStatus,
  setUploadFileFromComputerStatus,
  setDragDropFilesToGroupRoom,
  setMyDrivePageLoading,
  setSuccessfullyShareContent,
  setShowHideRequestForDownload
} from '@modules/Drive/MyDrive';
import * as Apollo from '@apollo/client';

import {
  FetchConnectMyDriveUploadFileInChat,
  SearchUser,
  FetchFilePermission
} from '@generated/Drive/MyDrive/graphql';
import { CapaErrorCode, useFetchMyDriveQuery } from '@generated/Common/graphql';
import { getStorageItem, STORAGE_ACTION } from '@utils/storage';

export default function useMyDrive(isHaveNotLoginFilesPage?: boolean) {
  const { myDrive } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const accessToken = getStorageItem(STORAGE_ACTION.CONNECT_ACCESS_TOKEN);

  const {
    data: fetchConnectMyDriveData,
    loading: fetchConnectMyDriveLoading,
    refetch: refetcMyDrive,
    fetchMore: onFetchMore
  } = useFetchMyDriveQuery({
    errorPolicy: 'all',
    skip: !accessToken || isHaveNotLoginFilesPage
  });

  const [
    fetchFilePermission,
    { loading: fetchFilePermissionLoading, data: fetchFilePermissionData }
  ] = Apollo.useLazyQuery(FetchFilePermission, {
    fetchPolicy: 'no-cache'
  });
  const {
    refetch: fetchConnectMyDriveUploadFileInChat,
    data: connectMyDriveUploadFileInChat,
    loading: connectMyDriveUploadFileInChatLoading,
    fetchMore: onFetchMoreConnectMyDriveFileUploadInChat
  } = Apollo.useQuery(FetchConnectMyDriveUploadFileInChat, {
    fetchPolicy: 'network-only',
    skip: isHaveNotLoginFilesPage
  });

  const [searchUser, { data: searchUserData, loading: searchUserDataLoading }] =
    Apollo.useLazyQuery(SearchUser, {
      fetchPolicy: 'no-cache'
    });

  const onSetShowHideFileDetail = (params: MyDriveState) =>
    dispatch(setFileDetail(params));

  const onSetShowHideMenuCreate = (params: MyDriveState) =>
    dispatch(setShowHideMenuCreate(params));

  const onSetPath = (params: MyDriveState) => dispatch(setPath(params));

  const onSetLocationName = useCallback(
    (params: MyDriveState) => dispatch(setLocationName(params)),
    [dispatch]
  );

  const onSetShowHideShareDialog = (params: MyDriveState) =>
    dispatch(setShowHideShareDialog(params));

  const onSetShareFilesToType = (params: MyDriveState) =>
    dispatch(setShareFilesToType(params));

  const onSetShareDialogScreen = (params: MyDriveState) =>
    dispatch(setShareDialogScreen(params));

  const onSetValueGroupNameDialog = (params: MyDriveState) =>
    dispatch(setValueGroupNameDialog(params));

  const onSetIdGroupName = (params: MyDriveState) =>
    dispatch(setIdGroupName(params));

  const onSetchatRoomIdGroupName = (params: MyDriveState) =>
    dispatch(setChatRoomIdGroupName(params));

  const onSetShowHideUploadFromMyDriveDialog = (params: MyDriveState) =>
    dispatch(setShowHideUploadFromMyDriveDialog(params));

  const onSetShowHideDownloadPermissionDialog = (params: MyDriveState) =>
    dispatch(setShowHideDownloadPermissionDialog(params));

  const onSetSelectedGroupShared = (params: MyDriveState) =>
    dispatch(setSelectedGroupShared(params));

  const onSetShowFileViewer = (params: MyDriveState) =>
    dispatch(setShowFileViewer(params));

  const onSetShowHideConfirmDialog = (params: MyDriveState) =>
    dispatch(setShowHideConfirmDialog(params));

  const onSetSelectedFilesUploadInChat = (params: MyDriveState) =>
    dispatch(setSelectedFilesUploadInChat(params));

  const onSetInternalFileFromComputer = (params: MyDriveState) =>
    dispatch(setInternalFileFromComputer(params));

  const onSetUploadFileTab = (params: MyDriveState) =>
    dispatch(setUploadFileTab(params));

  const onSetAddFilesFromType = (params: MyDriveState) =>
    dispatch(setAddFilesFromType(params));

  const onToggleGroupFiles = (params: MyDriveState) =>
    dispatch(setToggleGroupFiles(params));

  const onToggleRemoveGroupFiles = (params: MyDriveState) =>
    dispatch(setToggleRemoveGroupFiles(params));

  const onSetFirstSelectedDrawFile = (params: MyDriveState) =>
    dispatch(setFirstSelectedDrawFile(params));

  const onSetActionAnnotationSuccess = (params: MyDriveState) =>
    dispatch(setActionAnnotationSuccess(params));

  const onSetFileNameInGroupRoom = (params: MyDriveState) =>
    dispatch(setFileNameInGroupRoom(params));

  const onSetActiveFileIdInGroupRoom = (params: MyDriveState) =>
    dispatch(setActiveFileIdInGroupRoom(params));

  const onSetTotalNotification = (params: MyDriveState) =>
    dispatch(setTotalNotification(params));

  const onSelectDrawFileFromCommentList = (params: MyDriveState) =>
    dispatch(setSelectDrawFileFromCommentList(params));

  const onSetRenameSuccessStatus = (params: MyDriveState) =>
    dispatch(setRenameSuccessStatus(params));

  const onSetUploadFileFromComputerStatus = (params: MyDriveState) =>
    dispatch(setUploadFileFromComputerStatus(params));

  const onSetDragDropFilesToGroupRoom = (params: MyDriveState) =>
    dispatch(setDragDropFilesToGroupRoom(params));

  const onSetMyDrivePageLoading = (params: MyDriveState) =>
    dispatch(setMyDrivePageLoading(params));

  const onSetSuccessfullyShareContent = (params: MyDriveState) =>
    dispatch(setSuccessfullyShareContent(params));

  const onSetShowHideRequestForDownload = (params: MyDriveState) =>
    dispatch(setShowHideRequestForDownload(params));

  const onRefetchConnectMyDrive = useCallback(refetcMyDrive, [refetcMyDrive]);

  const filePermissionState = useMemo(() => {
    if (
      fetchFilePermissionData &&
      fetchFilePermissionData?.fetchFilePermission
    ) {
      return {
        data: fetchFilePermissionData?.fetchFilePermission.data ?? undefined,
        isLoading: fetchFilePermissionLoading
      };
    }

    return {
      data: undefined,
      isLoading: fetchFilePermissionLoading
    };
  }, [fetchFilePermissionData, fetchFilePermissionLoading]);

  const connectMyDriveFileUploadInChatState = useMemo(() => {
    if (
      connectMyDriveUploadFileInChat &&
      connectMyDriveUploadFileInChat.fetchConnectMyDrive &&
      connectMyDriveUploadFileInChat.fetchConnectMyDrive.result ===
        CapaErrorCode.Success
    ) {
      return {
        result:
          connectMyDriveUploadFileInChat.fetchConnectMyDrive.result ??
          undefined,
        files:
          (
            connectMyDriveUploadFileInChat.fetchConnectMyDrive.data?.files || []
          ).sort((files: any, folder: any) => files.isFile - folder.isFile) ??
          [],
        path:
          connectMyDriveUploadFileInChat.fetchConnectMyDrive.data?.path ??
          undefined,
        isLoading: false
      };
    }

    return {
      result: undefined,
      files: [],
      path: [],
      isLoading: true
    };
  }, [connectMyDriveUploadFileInChat]);

  const searchUserState = useMemo(() => {
    if (searchUserData && searchUserData?.searchUser) {
      return {
        data: searchUserData?.searchUser.data ?? [],
        isLoading: searchUserDataLoading
      };
    }
    return {
      data: [],
      isLoading: searchUserDataLoading
    };
  }, [searchUserData]);

  const myDriveState = useMemo(() => {
    if (
      fetchConnectMyDriveData &&
      fetchConnectMyDriveData.fetchConnectMyDrive &&
      fetchConnectMyDriveData.fetchConnectMyDrive.result ===
        CapaErrorCode.Success
    ) {
      return {
        result: fetchConnectMyDriveData.fetchConnectMyDrive.result ?? undefined,
        files:
          (fetchConnectMyDriveData.fetchConnectMyDrive.data?.files || []).sort(
            (files: any, folder: any) => files.isFile - folder.isFile
          ) ?? undefined,
        path:
          fetchConnectMyDriveData.fetchConnectMyDrive.data?.path ?? undefined,
        isLoading: fetchConnectMyDriveLoading
      };
    }

    return {
      result: undefined,
      files: [],
      path: [],
      isLoading: fetchConnectMyDriveLoading
    };
    // eslint-disable-next-line
  }, [fetchConnectMyDriveData]);

  const onFetchFilePermission = useCallback(fetchFilePermission, [
    fetchFilePermission
  ]);

  const onFetchConnectMyDriveFileUploadInChat = useCallback(
    fetchConnectMyDriveUploadFileInChat,
    [fetchConnectMyDriveUploadFileInChat]
  );

  const onSearchUser = useCallback(searchUser, [searchUser]);

  return {
    myDrive,
    myDriveState,
    onRefetchConnectMyDrive,
    fetchConnectMyDriveLoading,
    onFetchMore,
    onSetShowHideFileDetail,
    onSetShowHideMenuCreate,
    onSetPath,
    onSetLocationName,
    onSetShowHideShareDialog,
    onSetShareFilesToType,
    onSetShareDialogScreen,
    onSetValueGroupNameDialog,
    onSetShowHideUploadFromMyDriveDialog,
    onSetShowHideDownloadPermissionDialog,
    onSetIdGroupName,
    onSetchatRoomIdGroupName,
    onFetchFilePermission,
    filePermissionState,
    onSetSelectedGroupShared,
    onSetShowFileViewer,
    onSetShowHideConfirmDialog,
    onFetchConnectMyDriveFileUploadInChat,
    connectMyDriveFileUploadInChatState,
    onFetchMoreConnectMyDriveFileUploadInChat,
    onSetSelectedFilesUploadInChat,
    onSetInternalFileFromComputer,
    onSetUploadFileTab,
    onSetAddFilesFromType,
    onToggleGroupFiles,
    onSearchUser,
    searchUserState,
    onToggleRemoveGroupFiles,
    onSetFirstSelectedDrawFile,
    onSetFileNameInGroupRoom,
    onSetActionAnnotationSuccess,
    onSetActiveFileIdInGroupRoom,
    onSetTotalNotification,
    onSelectDrawFileFromCommentList,
    connectMyDriveUploadFileInChatLoading,
    onSetRenameSuccessStatus,
    onSetUploadFileFromComputerStatus,
    onSetDragDropFilesToGroupRoom,
    onSetMyDrivePageLoading,
    onSetSuccessfullyShareContent,
    onSetShowHideRequestForDownload
  };
}
