import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { formatFileSize } from '@utils/formatter';
import useAuth from '@hooks/useAuth';
import { CustomizedButton, CustomizedTypography } from '@components/Customized';
import { Dropzone } from '../Common';
import {
  Drawer,
  Box,
  Divider,
  LinearProgress,
  MenuItem
} from '@material-ui/core';
import { CloudOutlined } from '@material-ui/icons';
import FolderFormDialog from '@routes/Dashboard/Dialog/FolderForm/FolderForm';
import { DEFAULT_FOLDER_NAME } from '@routes/Dashboard/types';
import { useDashboardAsideMenuPresenterStyles, NewButtonMenu } from './styles';
import { useCreateConnectFolderMutation } from '@generated/Drive/MyDrive/graphql';
import { CapaErrorCode } from '@generated/Common/graphql';
import { CapaActivityType } from '@generated/Common/types';
import { useSnackbar } from '@components/Provider';
import {
  myDrivePathName,
  sharedWithGroupsPathName
} from '@routes/Common/types';
import useSharedWithGroups from '@hooks/Drive/MyDrive/useSharedWithGroups';
import { useTranslation } from 'react-i18next';
import useUserActionLog, {
  Enum_Action_Button_Enum
} from '@hooks/useUserActionLog';
import {
  IBMAdd,
  IBMAddDisabled,
  IBMCloud,
  IBMCloudActive,
  IBMDocumentAdd,
  IBMFolderAdd,
  IBMFolderShared,
  IBMFolderSharedActive
} from '@assets';
import LinkDownloadApp from '@components/LinkDownloadApp';
import useMyDrive from '@hooks/Drive/MyDrive/useMyDrive';

interface Props {
  callback?: () => void;
  setCheckedFiles?: () => void;
  setSelectedFileMetaKey?: () => void;
  setSelectedFirstFile?: () => void;
  uploadFileToGroupCallback?: (fileIds: any) => void;
  isChatBotGroup?: boolean;
  isLoadingEnteredGroupPage?: boolean;
}

const DashboardAsideMenuPresenter: React.FC<Props> = ({
  callback,
  setCheckedFiles,
  uploadFileToGroupCallback,
  setSelectedFileMetaKey,
  setSelectedFirstFile,
  isChatBotGroup,
  isLoadingEnteredGroupPage
}) => {
  const { t } = useTranslation();
  const classes = useDashboardAsideMenuPresenterStyles();
  const { pathname } = useLocation<any>();
  const history = useHistory();
  const { userState } = useAuth();
  const { onRefetchConnectMyDrive } = useMyDrive();
  const driveSize = userState.data?.driveSize ?? 0;
  const driveSizeUnit = formatFileSize(driveSize).toUpperCase();
  const usedSize = userState.data?.usedSize ?? 0;
  const usedSizeUnit =
    usedSize === 0 ? '0바이트' : formatFileSize(usedSize).toUpperCase();
  const progressCount = Math.ceil((usedSize / driveSize) * 100);
  const [isShowHideFolderDialog, setShowHideFolderDialog] =
    useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isOpenFolderDialog = Boolean(anchorEl);
  const [folderName, setFolderName] = useState<string>(DEFAULT_FOLDER_NAME);
  const [createConnectFolder] = useCreateConnectFolderMutation();
  const { enqueueSnackbar } = useSnackbar();
  const dashboardAsideMenuPresenterWrapperRef = useRef(null);
  const [activeLeftMenu, setActiveLeftMenu] = useState(pathname);
  const { onSetSelectedGroupFiles } = useSharedWithGroups();
  const hasSharedWithGroupsIdPath = pathname.split('/').length > 2;
  const [disbaledRenameSubmitButton, setDisbaledRenameSubmitButton] =
    useState(false);
  const { onInsertUserActionLogOne } = useUserActionLog();

  const useClickInside = (ref: any) => {
    useEffect(() => {
      const handleClickInside = (event: any) => {
        if (ref.current && ref.current.contains(event.target)) {
          onSetSelectedGroupFiles({ selectedGroupFiles: [] });

          const contextMenu: any = document.querySelector('.react-contextmenu');

          if (contextMenu) {
            contextMenu.style.opacity = '0';
            contextMenu.style.display = 'none';
            contextMenu.style.pointerEvents = 'none';
          }
        }
      };

      document.addEventListener('mousedown', handleClickInside);
      return () => {
        document.removeEventListener('mousedown', handleClickInside);
      };
    }, [ref]);
  };

  useClickInside(dashboardAsideMenuPresenterWrapperRef);

  const handleClickNewButton = (event: React.MouseEvent<HTMLElement>) => {
    onInsertUserActionLogOne(
      Enum_Action_Button_Enum.Button_Mydrive_New,
      CapaActivityType.ButtonClick
    );
    setAnchorEl(event.currentTarget);
  };

  const handleCloseNewButton = () => {
    setAnchorEl(null);
  };

  const onOpenFolderDialog = () => {
    handleCloseNewButton();
    setShowHideFolderDialog(true);
  };

  const onHideFolderDialog = () => {
    setShowHideFolderDialog(false);
  };

  const onCreateFolder = () => {
    setDisbaledRenameSubmitButton(true);
    const pathnameUrl = history.location.pathname.split(`${myDrivePathName}/`);
    const payload: {
      folderName: string;
      pathId: string;
    } = {
      folderName: folderName,
      pathId: pathnameUrl[1] ? pathnameUrl[1] : ''
    };

    createConnectFolder({ variables: payload })
      .then((res) => {
        const result = res?.data?.createConnectFolder?.result;
        if (result === CapaErrorCode.Success) {
          enqueueSnackbar(
            `${t('snackbar.new_folder', {
              folderName
            })}`,
            {
              variant: 'success'
            }
          );
          onHideFolderDialog();
          onRefetchConnectMyDrive({
            pathId: pathnameUrl[1] ? pathnameUrl[1] : ''
          });
        }

        if (result === CapaErrorCode.QueryError) {
          enqueueSnackbar(res?.data?.createConnectFolder?.errorMessage, {
            variant: 'error'
          });
        }
      })
      .finally(() => setDisbaledRenameSubmitButton(false));
  };

  const onUploadFileToGroupCallback = (fileIds: any) => {
    if (uploadFileToGroupCallback) {
      uploadFileToGroupCallback(fileIds);
    }
  };

  return (
    <Drawer
      ref={dashboardAsideMenuPresenterWrapperRef}
      variant="permanent"
      anchor="left"
      open={true}
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <div className={classes.drawerHeaderMargin} />
      <div
        className={clsx(
          classes.drawerContentItem,
          'upload-btn',
          pathname === sharedWithGroupsPathName ? 'no-event' : ''
        )}
      >
        {activeLeftMenu.split('/')[1] ===
        sharedWithGroupsPathName.split('/')[1] ? (
          <Dropzone
            noClick={isChatBotGroup}
            callback={onUploadFileToGroupCallback}
            noDrag={isChatBotGroup}
          >
            <CustomizedButton
              className={classes.newButton}
              aria-haspopup="true"
              variant="contained"
              color="primary"
              size="medium"
              startIcon={
                <img
                  src={
                    !hasSharedWithGroupsIdPath ||
                    isChatBotGroup ||
                    isLoadingEnteredGroupPage
                      ? IBMAddDisabled
                      : IBMAdd
                  }
                  alt="IBMAdd"
                />
              }
              disabled={
                !hasSharedWithGroupsIdPath ||
                isChatBotGroup ||
                isLoadingEnteredGroupPage
              }
              onClick={() => {
                onInsertUserActionLogOne(
                  Enum_Action_Button_Enum.Button_SharedDrive_New,
                  CapaActivityType.ButtonClick
                );
              }}
            >
              {t('lnb.new')}
            </CustomizedButton>
          </Dropzone>
        ) : (
          <CustomizedButton
            className={classes.newButton}
            aria-haspopup="true"
            variant="contained"
            color="primary"
            size="medium"
            startIcon={<img src={IBMAdd} alt="IBMAdd" />}
            onClick={handleClickNewButton}
          >
            {t('lnb.new')}
          </CustomizedButton>
        )}

        <NewButtonMenu
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          open={isOpenFolderDialog}
          onClose={handleCloseNewButton}
          keepMounted
          classes={{
            paper: classes.PopupNew
          }}
        >
          <Dropzone callback={callback}>
            <MenuItem
              onClick={() => {
                onInsertUserActionLogOne(
                  Enum_Action_Button_Enum.Button_MyDrive_New_File,
                  CapaActivityType.ButtonClick
                );
                handleCloseNewButton();
              }}
            >
              <img src={IBMDocumentAdd} alt="IBMDocumentAdd" />
              {t('lnb.new_button.file_upload')}
            </MenuItem>
          </Dropzone>
          <div className={classes.lineSpace} />
          <MenuItem
            onClick={() => {
              onInsertUserActionLogOne(
                Enum_Action_Button_Enum.Button_Mydrive_New_Folder,
                CapaActivityType.ButtonClick
              );
              onOpenFolderDialog();
            }}
          >
            <img src={IBMFolderAdd} alt="IBMFolderAdd" />
            {t('lnb.new_button.folder')}
          </MenuItem>
        </NewButtonMenu>

        <FolderFormDialog
          dialogInfo={{
            title: t('my_drive.new_folder.header'),
            submitButtonName: t('my_drive.new_folder.create_button')
          }}
          folderNameValue={folderName}
          isOpen={isShowHideFolderDialog}
          onClose={onHideFolderDialog}
          setValue={setFolderName}
          onSubmit={onCreateFolder}
          disabledSubmitButton={disbaledRenameSubmitButton}
          setDisbaledRenameSubmitButton={() =>
            setDisbaledRenameSubmitButton(false)
          }
        />
      </div>
      <Box
        className={clsx(
          classes.drawerContentItem,
          'connect-files',
          activeLeftMenu.split('/')[1] === myDrivePathName.split('/')[1]
            ? 'selected'
            : ''
        )}
        onClick={() => {
          onInsertUserActionLogOne(
            Enum_Action_Button_Enum.Button_MyDrive_LNB_MyDrive,
            CapaActivityType.ButtonClick
          );
          history.push(myDrivePathName);
          setActiveLeftMenu(myDrivePathName);
        }}
      >
        {activeLeftMenu.split('/')[1] === myDrivePathName.split('/')[1] ? (
          <img src={IBMCloudActive} alt="IBMCloudActive" />
        ) : (
          <img src={IBMCloud} alt="IBMCloud" />
        )}
        <CustomizedTypography
          className={clsx(classes.drawerContentItemText)}
          variant="body1"
          fontWeight="500"
          color={
            activeLeftMenu.split('/')[1] === myDrivePathName.split('/')[1]
              ? 'primary'
              : 'textPrimary'
          }
        >
          {t('lnb.my_drive')}
        </CustomizedTypography>
      </Box>
      <div className={classes.lineSpace} />
      <Box
        className={clsx(
          classes.drawerContentItem,
          'connect-files',
          activeLeftMenu.split('/')[1] ===
            sharedWithGroupsPathName.split('/')[1]
            ? 'selected'
            : ''
        )}
        onClick={() => {
          onInsertUserActionLogOne(
            Enum_Action_Button_Enum.Button_MyDrive_LNB_SharedDrive,
            CapaActivityType.ButtonClick
          );
          history.push(sharedWithGroupsPathName);
          setActiveLeftMenu(sharedWithGroupsPathName);
        }}
      >
        {activeLeftMenu.split('/')[1] ===
        sharedWithGroupsPathName.split('/')[1] ? (
          <img src={IBMFolderSharedActive} alt="IBMFolderSharedActive" />
        ) : (
          <img src={IBMFolderShared} alt="IBMFolderShared" />
        )}
        <CustomizedTypography
          className={clsx(classes.drawerContentItemText)}
          variant="body1"
          fontWeight="500"
          color={
            activeLeftMenu.split('/')[1] ===
            sharedWithGroupsPathName.split('/')[1]
              ? 'primary'
              : 'textPrimary'
          }
        >
          {t('lnb.shared_with_groups')}
        </CustomizedTypography>
      </Box>

      {/*Hide for BETA 1 */}
      <Box display="none">
        <Divider className={classes.divider} />
        <Box className={clsx(classes.drawerContentItem, 'storage-capacity')}>
          <CloudOutlined />
          <CustomizedTypography variant="body1" fontWeight="500">
            {t('lnb.storage')}
          </CustomizedTypography>
        </Box>
        <Box
          className={clsx(
            classes.drawerContentItem,
            'storage-capacity-progress'
          )}
        >
          <LinearProgress variant="determinate" value={progressCount} />
        </Box>
        <Box
          className={clsx(
            classes.drawerContentItem,
            'storage-capacity-progress-count'
          )}
        >
          <CustomizedTypography variant="body2">
            {t('lnb.capacity', {
              available: usedSizeUnit,
              capacity: driveSizeUnit
            })}
          </CustomizedTypography>
        </Box>
      </Box>
      <LinkDownloadApp />
    </Drawer>
  );
};

export default DashboardAsideMenuPresenter;
