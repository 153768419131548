import { createReducer } from 'typesafe-actions';
import { IAuthState, AuthAction, initialAuthState } from './types';
import { UPDATE_AUTH_STATE, INIT_AUTH_STATE } from './actions';
import { STORAGE_ACTION } from '@utils/storage';

export const auth = createReducer<IAuthState, AuthAction>(initialAuthState, {
  [UPDATE_AUTH_STATE]: (state, action) => {
    const { accessToken, refreshToken } = action.payload;

    if (accessToken && refreshToken) {
      localStorage.setItem(STORAGE_ACTION.CONNECT_ACCESS_TOKEN, accessToken);
      localStorage.setItem(STORAGE_ACTION.CONNECT_REFRESH_TOKEN, refreshToken);
    }

    return {
      ...state,
      ...action.payload
    };
  },
  [INIT_AUTH_STATE]: () => initialAuthState
});
