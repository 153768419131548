import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Loading from '@components/LoadingInComponent';
import HeaderTable from '@routes/Contacts/HeaderTable';
import {
  useMediaQuery,
  useTheme,
  Link,
  Breadcrumbs,
  Popover,
  Box,
  List,
  ListItem,
  Tooltip
} from '@material-ui/core';
import { CustomizedTypography } from '@components/Customized';
import { uniqueArray } from '@utils/formatter';
import {
  CapaEdit,
  CapaDelete,
  CapaAddtoGroupBlue,
  CapaDown,
  CapaCreateGroupBlue,
  CapaDeleteBlue,
  IBMAdd,
  IBMChevronBreadcrumb
} from '@assets';

import { LIST_TABLE_HEADER } from '@routes/Contacts/types';
import { useTranslation } from 'react-i18next';
import {
  MuiDiv,
  MuiBoxTable,
  MuiBoxTableItem,
  MuiBoxTableItemMobile,
  MuiNewButtonMobile,
  useStyles,
  useDataTable
} from './styles';

import useMyContacts from '@hooks/Contacts/useMyContacts';
import EmptyPresenter from './EmptyPresenter';
import AvatarCheckbox from '@components/AvatarCheckbox';
import { DEFAULT_ITEMS_LIMIT, myContactsPathName } from '@routes/Common/types';
import { KEY_TYPE } from '@routes/Common/types';
import DetailContactDialog from './Dialog/DetailContact';
import useMyDrive from '@hooks/Drive/MyDrive/useMyDrive';
import { Checkbox } from '@material-ui/core';
import FormContactDialog from './Dialog/FormContact';
import DeleteContactDialog from './Dialog/DeleteContact';
import ExistingGroups from '@routes/Contacts/Dialog/ExistingGroups/ExistingGroups';
import { isChildrenPage } from '@routes/Common/utils';
import TabPanelControl from '@routes/Contacts/TabPanel/TabPanelControl';
import { URL_PARAM } from '@routes/Contacts/types';
import { BottomNavMenu } from '@routes/Common';
import clsx from 'clsx';
import { SHARE_DIALOG_SCREEN } from '@routes/Dashboard/Dialog/Share/types';
import ShareContainerDialog from '@routes/Dashboard/Dialog/Share/ShareContainer';
import { ReactComponent as IBMChevronLeft } from '../../assets/icon/IBMChevronLeft.svg';
import { ReactComponent as IBMAddCommentRight } from '../../assets/icon/IBMAddCommentRight.svg';
interface Props {
  callback?: () => void;
}

const DataTable: React.FC<Props> = ({ callback }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const classesDatatable = useDataTable();
  const theme = useTheme();
  const history = useHistory();
  const {
    myContacts,
    onFetchMyContacts,
    myContactsList,
    onSetStateContact,
    myContactsListLoading,
    onFetchMoreMyContacts
  } = useMyContacts();
  const { myDrive, onSetShareDialogScreen, onSetShowHideShareDialog } =
    useMyDrive();
  const [myContactsListData, setMyContactsListData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const wrapperRef = useRef<any>(null);

  const { onSetShowHideFileDetail } = useMyDrive();

  const onScrollWrapper = () => {
    const currentWrapperRef = wrapperRef && wrapperRef.current;
    if (
      Math.ceil(currentWrapperRef.scrollTop) +
        currentWrapperRef.clientHeight !==
        currentWrapperRef.scrollHeight ||
      isFetching
    )
      return;

    setIsFetching(true);
  };

  useEffect(() => {
    if (!isFetching || onFetchMoreMyContacts === undefined) return;
    setTimeout(() => {
      onFetchMoreMyContacts({
        variables: {
          limit: DEFAULT_ITEMS_LIMIT,
          offset: myContactsList?.fetchConnectUserContactList?.data?.length
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return prev;
          }
          const prevContact = prev.fetchConnectUserContactList.data;
          const moreContact = fetchMoreResult.fetchConnectUserContactList.data;

          if (fetchMoreResult.fetchConnectUserContactList.data)
            fetchMoreResult.fetchConnectUserContactList.data = [
              ...prevContact!,
              ...moreContact!
            ];
          setMyContactsListData(
            fetchMoreResult.fetchConnectUserContactList.data
          );
          return {
            ...fetchMoreResult
          };
        }
      });
      setIsFetching(false);
    }, 500);
  }, [isFetching]);

  useEffect(() => {
    onSetShowHideFileDetail({
      detailDrive: {}
    });
    onSetStateContact({
      dataDetailContact: undefined,
      dataCheckedContacts: [],
      dataCreateEditDialog: false,
      dataDeleteDialog: [],
      dataHoverContactID: undefined,
      isShowHideExistingGroups: false,
      isShowHideCreateNewGroup: false
    });
    onFetchMyContacts({
      variables: {
        offset: 0,
        limit: DEFAULT_ITEMS_LIMIT
      }
    });

    return () => {
      onSetShowHideShareDialog({ isShowHideShareDialog: false });
    };
  }, []);

  useEffect(() => {
    setMyContactsListData(
      myContactsList?.fetchConnectUserContactList?.data ?? []
    );
  }, [myContactsList]);

  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [selectedFirstContact, setSelectedFirstContact] = useState<any>(null);
  const [selectedContactMetaKey, setSelectedContactMetaKey] = useState<any>([]);
  const { pathname } = useLocation<any>();

  const onClickContactItem = (contact: any) => {
    onSetStateContact({
      dataDetailContact: contact
    });
  };

  const onRefreshList = () => {
    onFetchMyContacts({
      variables: {
        offset: 0,
        limit: DEFAULT_ITEMS_LIMIT
      }
    });
  };

  const onClickMuiBoxTableItem = (
    e: any,
    isChecked: boolean,
    id: string,
    contact: any
  ) => {
    if (e.type === KEY_TYPE.CLICK && !e.shiftKey) {
      setSelectedContactMetaKey([contact]);
      setSelectedFirstContact(contact);
    }

    if (e.shiftKey) {
      if (myContacts?.dataCheckedContacts?.length === 0) {
        setSelectedFirstContact(contact);
        onSetStateContact({
          dataCheckedContacts: [...[contact]]
        });
        return;
      }
      let indexOfNew = myContactsListData
        .map((contact: any, index: number) => contact.id)
        .indexOf(
          selectedFirstContact !== null ? selectedFirstContact.id : contact.id
        );
      let indexOfLast = myContactsListData
        .map((contact: any, index: number) => contact.id)
        .indexOf(contact.id);

      const isSelectingForwards = indexOfNew > indexOfLast;
      const start = isSelectingForwards ? indexOfLast : indexOfNew;
      const end = isSelectingForwards ? indexOfNew : indexOfLast;

      onSetStateContact({
        dataCheckedContacts: uniqueArray(
          [
            ...selectedContactMetaKey,
            ...myContactsListData.slice(start, end + 1)
          ],
          'id'
        )
      });

      return;
    }

    if (isChecked) {
      onSetStateContact({
        dataCheckedContacts: myContacts?.dataCheckedContacts?.filter(
          (contact) => contact.id !== id
        )
      });
    } else {
      onSetStateContact({
        dataCheckedContacts: [
          ...(myContacts?.dataCheckedContacts ?? []),
          contact
        ]
      });
      setSelectedContactMetaKey((oldValue: any) => [...oldValue, contact]);
    }
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const handleClickOpenPopUp = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  return (
    <MuiDiv>
      {!isMobile ? (
        <>
          <div>
            <div className="subtitle">
              <CustomizedTypography
                component={'div'}
                variant="h3"
                fontWeight="500"
              >
                <Breadcrumbs
                  separator={
                    <img
                      src={IBMChevronBreadcrumb}
                      alt="IBMChevronBreadcrumb"
                    />
                  }
                  aria-label="breadcrumb"
                >
                  <Link
                    className="cursor-pointer"
                    color="inherit"
                    onClick={() => {
                      history.push(myContactsPathName);
                    }}
                  >
                    {t('contact.my_contacts.title')}
                  </Link>
                </Breadcrumbs>
              </CustomizedTypography>
            </div>
          </div>
          <MuiBoxTable>
            {myContactsListData.length > 0 ? (
              <div className="table-header">
                {myContacts?.dataCheckedContacts?.length == 0 ? (
                  LIST_TABLE_HEADER.map((item: any, index: number) => (
                    <HeaderTable item={item} key={index} />
                  ))
                ) : (
                  <div className={classes.headerFeature}>
                    <div className={classes.headerFeatureLeft}>
                      <div
                        onClick={() =>
                          onSetStateContact({
                            dataCheckedContacts: []
                          })
                        }
                      >
                        <Tooltip title={t('tooltip.unselect') || 'Unselect'}>
                          {myContactsListData.length ===
                          myContacts?.dataCheckedContacts?.length ? (
                            <Checkbox color="primary" checked={true} />
                          ) : (
                            <Checkbox
                              color="primary"
                              checked={true}
                              indeterminate
                            />
                          )}
                        </Tooltip>
                      </div>

                      <div className={classes.headerFeatureDropdown}>
                        <button
                          aria-describedby="popover_check"
                          onClick={(e) => {
                            handleClickOpenPopUp(e);
                          }}
                        >
                          <img src={CapaDown} alt="capaDown" />
                        </button>
                        <Popover
                          id="popover_check"
                          open={open}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left'
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left'
                          }}
                          classes={{
                            paper: classes.headerFeatureDropdownBox
                          }}
                        >
                          <Box minWidth={180} borderRadius={8}>
                            <List
                              component="nav"
                              aria-label="main mailbox folders"
                            >
                              <ListItem
                                button
                                onClick={() => {
                                  onSetStateContact({
                                    dataCheckedContacts: myContactsListData
                                  });
                                  setAnchorEl(null);
                                }}
                              >
                                <div
                                  className={classes.headerFeatureDropdownItem}
                                >
                                  {t('contact.feature.seletec_all')}
                                </div>
                              </ListItem>
                              <ListItem
                                button
                                onClick={() => {
                                  onSetStateContact({
                                    dataCheckedContacts: []
                                  });
                                  setAnchorEl(null);
                                }}
                              >
                                <div
                                  className={classes.headerFeatureDropdownItem}
                                >
                                  {t('contact.feature.unseletec_all')}
                                </div>
                              </ListItem>
                            </List>
                          </Box>
                        </Popover>
                      </div>
                      <div className={classes.headerFeatureGroup}>
                        <Tooltip
                          title={
                            t('tooltip.invite_to_group') || 'Invite to group'
                          }
                        >
                          <div
                            className={classes.headerFeatureItem}
                            onClick={() =>
                              onSetStateContact({
                                isShowHideExistingGroups: true
                              })
                            }
                          >
                            <img
                              src={CapaAddtoGroupBlue}
                              alt="capaAddToGroup"
                            />
                          </div>
                        </Tooltip>
                        <Tooltip
                          title={t('tooltip.create_group') || 'Create group'}
                        >
                          <div
                            className={classes.headerFeatureItem}
                            onClick={() => {
                              onSetShowHideShareDialog({
                                isShowHideShareDialog: true
                              });
                              onSetShareDialogScreen({
                                shareDialogScreen:
                                  SHARE_DIALOG_SCREEN.CREATE_NEW_GROUP
                              });
                            }}
                          >
                            <img
                              src={CapaCreateGroupBlue}
                              alt="capaCreateGroupBlue"
                            />
                          </div>
                        </Tooltip>
                        <Tooltip title={t('tooltip.delete') || 'Delete'}>
                          <div
                            className={classes.headerFeatureItem}
                            onClick={() =>
                              onSetStateContact({
                                dataDeleteDialog:
                                  myContacts?.dataCheckedContacts
                              })
                            }
                          >
                            <img src={CapaDeleteBlue} alt="capaDeleteBlue" />
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                    <div className={classes.headerFeatureNumberChecked}>
                      {myContacts?.dataCheckedContacts?.length}{' '}
                      {t('contact.feature.selected')}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              ''
            )}
            {myContactsListLoading ? (
              <Loading
                open={myContactsListLoading}
                styles={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: 40
                }}
              />
            ) : (
              <div
                className={clsx(classesDatatable.table, 'table-content')}
                onScroll={onScrollWrapper}
                ref={wrapperRef}
              >
                {myContactsListData.length > 0 ? (
                  myContactsListData.map((contact: any, index: number) => {
                    const id = contact.id;
                    const userName = contact.nickName;
                    const email = contact.email;
                    const memo = contact.memo;
                    const isChecked = myContacts?.dataCheckedContacts?.find(
                      (contact) => contact.id === id
                    )
                      ? true
                      : false;
                    const isHover = myContacts.dataHoverContactID === id;
                    return (
                      <MuiBoxTableItem
                        key={id}
                        isChecked={isChecked}
                        onMouseEnter={() => {
                          onSetStateContact({
                            dataHoverContactID: id
                          });
                        }}
                        onMouseLeave={() => {
                          onSetStateContact({
                            dataHoverContactID: undefined
                          });
                        }}
                      >
                        <div className="table-item-td-one">
                          <div
                            onClick={(e) => {
                              onClickMuiBoxTableItem(e, isChecked, id, contact);
                            }}
                            className="table-item-avatar"
                          >
                            <AvatarCheckbox
                              item={contact}
                              isHover={isHover}
                              isChecked={isChecked}
                            />
                          </div>
                          <CustomizedTypography
                            component={'div'}
                            noWrap
                            className="table-item-text"
                            onClick={() => {
                              onClickContactItem(contact);
                            }}
                          >
                            {userName}
                          </CustomizedTypography>
                        </div>
                        <div
                          className="table-item-td-two"
                          onClick={() => {
                            onClickContactItem(contact);
                          }}
                        >
                          <CustomizedTypography
                            className="table-item-text"
                            component={'div'}
                          >
                            {email}
                          </CustomizedTypography>
                        </div>
                        <CustomizedTypography
                          component={'div'}
                          className={
                            isHover
                              ? 'is-hover table-item-td-three'
                              : 'table-item-td-three'
                          }
                        >
                          <div
                            className="table-item-text table-item-td-memo"
                            onClick={() => {
                              onClickContactItem(contact);
                            }}
                          >
                            {memo}
                          </div>
                          <div className="table-item-td-feature">
                            <img
                              src={CapaEdit}
                              onClick={() =>
                                onSetStateContact({
                                  dataCreateEditDialog: contact
                                })
                              }
                              alt="capaEdit"
                            />
                            <img
                              src={CapaDelete}
                              onClick={() =>
                                onSetStateContact({
                                  dataDeleteDialog: [contact]
                                })
                              }
                              alt="capaDelete"
                            />
                          </div>
                        </CustomizedTypography>
                      </MuiBoxTableItem>
                    );
                  })
                ) : (
                  <EmptyPresenter />
                )}
              </div>
            )}
          </MuiBoxTable>
        </>
      ) : (
        <>
          {!isChildrenPage(pathname, 'my-contacts') && (
            <div className={clsx('table-header', 'fixed-header')}>
              <TabPanelControl urlParam={URL_PARAM.myContactsType} />
            </div>
          )}

          {myContactsListLoading ? (
            <Loading
              open={myContactsListLoading}
              styles={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: 40
              }}
            />
          ) : (
            <div
              className={classesDatatable.tableWrapper}
              onScroll={onScrollWrapper}
              ref={wrapperRef}
            >
              {myContactsListData && myContactsListData.length > 0 ? (
                myContactsListData?.map((contact: any) => {
                  const id = contact.id;
                  const username = contact.nickName;
                  const email = contact.email;
                  const isChecked = myContacts?.dataCheckedContacts?.find(
                    (contact) => contact.id === id
                  )
                    ? true
                    : false;
                  const classItem = isChecked
                    ? 'table_item selected'
                    : 'table_item';
                  const isHover = myContacts.dataHoverContactID === id;
                  return (
                    <MuiBoxTableItemMobile key={id} className={classItem}>
                      <div
                        className={
                          isChecked ? 'item-wrapper selected' : 'item-wrapper'
                        }
                      >
                        <div
                          onClick={(e) => {
                            onClickMuiBoxTableItem(e, isChecked, id, contact);
                          }}
                          className="table-item-avatar"
                        >
                          <AvatarCheckbox
                            item={contact}
                            isHover={isHover}
                            isChecked={isChecked}
                          />
                        </div>
                        <div
                          className="table-contents"
                          onClick={() => {
                            onClickContactItem(contact);
                          }}
                        >
                          <CustomizedTypography
                            variant="body2"
                            gutterBottoms={2}
                            noWrap
                          >
                            {username}
                          </CustomizedTypography>
                          <CustomizedTypography
                            variant="caption"
                            color="textSecondary"
                            gutterBottoms={2}
                          >
                            {email}
                          </CustomizedTypography>
                        </div>
                      </div>
                    </MuiBoxTableItemMobile>
                  );
                })
              ) : (
                <EmptyPresenter />
              )}
            </div>
          )}
          <BottomNavMenu />
          <MuiNewButtonMobile
            onClick={() =>
              onSetStateContact({
                dataCreateEditDialog: []
              })
            }
          >
            <img src={IBMAdd} alt="IBMAdd" />
          </MuiNewButtonMobile>
        </>
      )}
      <DetailContactDialog
        contact={myContacts.dataDetailContact}
        onClose={() =>
          onSetStateContact({
            dataDetailContact: undefined
          })
        }
      />
      <FormContactDialog
        onCallback={() => {
          onRefreshList();
        }}
      />
      <DeleteContactDialog
        onClose={() => {
          onSetStateContact({
            dataDetailContact: undefined
          });
        }}
        onCallback={() => {
          onRefreshList();
          onSetStateContact({
            dataCheckedContacts: []
          });
        }}
      />
      <ExistingGroups
        checkedUsers={myContacts?.dataCheckedContacts?.map(
          (contact: any) => contact.email
        )}
        isOpen={myContacts.isShowHideExistingGroups}
        onClose={() =>
          onSetStateContact({
            isShowHideExistingGroups: false
          })
        }
      />
      {myDrive.isShowHideShareDialog ? (
        <ShareContainerDialog
          checkedUsers={
            myContacts.dataDetailContact
              ? [myContacts.dataDetailContact]
              : myContacts?.dataCheckedContacts
          }
          isShowUploadFiles={true}
          createNewGroupHeader={
            <>
              {isMobile ? (
                <IBMChevronLeft
                  className={classes.backIconMobile}
                  onClick={() => {
                    onSetShowHideShareDialog({
                      isShowHideShareDialog: false
                    });
                  }}
                />
              ) : (
                <div className={classes.backIconWrapper}>
                  <IBMAddCommentRight />
                </div>
              )}
              <div className={classes.createNewGroupTitle}>
                {t('create_new_group_modal.header_title')}
              </div>
            </>
          }
        />
      ) : null}
    </MuiDiv>
  );
};

export default DataTable;
