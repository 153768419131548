import React, {
  useEffect,
  useRef,
  useState,
  useLayoutEffect,
  useMemo
} from 'react';
import { useStyles } from '@routes/GroupRoom/styles';
import { AddCircleOutlineRounded, Send } from '@material-ui/icons';
import { Button, TextField, useMediaQuery, useTheme } from '@material-ui/core';
import { CustomizedButton } from '@components/Customized';
import { DOWNLOAD_PERMISSION_STATUS, KEY_CODE } from '@routes/Dashboard/types';
import {
  DAY_NAME,
  DEFAULT_ITEMS_LIMIT,
  FORMAT_DATE,
  MESSAGE_TYPE_NAME,
  MONTH_NAME,
  CAPA_FILE_CONVERT_STATUS,
  CONNECT_UPDATE_TYPE,
  MESSAGE_TYPE,
  chatBotEmail,
  CHAT_BOT_NAME
} from '@routes/Common/types';
import TextMessage from '@routes/GroupRoom/Messages/TextMessage';
import ImageMessage from '@routes/GroupRoom/Messages/ImageMessage';
import FileMessage from '@routes/GroupRoom/Messages/FileMessage';
import { checkShareTimes } from '@routes/Dashboard/Dialog/Share/utils';
import clsx from 'clsx';
import useMyDrive from '@hooks/Drive/MyDrive/useMyDrive';
import {
  ReadConnectChatMessage,
  SubscribeConnectChatMessages,
  SubscribeConnectUpdateData,
  SubscribeFileStatusUpdate,
  useSendConnectChatMessageMutation,
  useShareFilesToGroupMutation
} from '@generated/Drive/MyDrive/graphql';
import { formatDate, formatTimeStamp, uniqueArray } from '@utils/formatter';
import { CapaErrorCode } from '@generated/Common/graphql';
import { CapaActivityType } from '@generated/Common/types';
import { IMAGE_TYPES } from '@routes/Common/Dropzone/types';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from '@components/Provider';
import { useTranslation } from 'react-i18next';
import { UPLOAD_FILE_TAB } from '@routes/Common/Dialog/UploadFiles/types';
import { newFileUploadApi } from '@api/index';
import UploadFilesContainer from '@routes/Common/Dialog/UploadFiles/Mobile/UploadFilesContainer';
import { CREATE_GROUP_SCREEN } from '@routes/Group/Dialog/types';
import { ADD_FILES_FROM_VALUE } from '@routes/Common/Dialog/UploadFiles/Mobile/types';
import useGroup from '@hooks/Group/useGroup';
import UploadFilesFrom from '@routes/Common/Dialog/UploadFiles/Mobile/UploadFilesFrom';
import { getStorageItem, STORAGE_ACTION } from '@utils/storage';
import { useMutation, useSubscription } from '@apollo/client';
import useUserActionLog, {
  Enum_Action_Button_Enum
} from '@hooks/useUserActionLog';
import useSharedWithGroups from '@hooks/Drive/MyDrive/useSharedWithGroups';
import { convertSubstring, getFileExtension } from '@utils/file';
import useCommon from '@hooks/Common/useCommon';
import dayjs from 'dayjs';
import { Tooltip } from '@material-ui/core';
import { CapaGroupChatBot } from '@assets';
import ButtonMessage from '@routes/GroupRoom/Messages/ButtonMessage';
import useAuth from '@hooks/useAuth';
import CapaAvatar from '@components/CapaAvatar';
import Loading from '@components/LoadingInComponent';
import InsertFromMyDriveContainer from '@routes/Dashboard/Dialog/Share/CreateNewGroup/InsertFromMyDriveContainer';
import UploadFilesButton from '@routes/Dashboard/Dialog/Share/CreateNewGroup/UploadFilesButton';
import useFiles from '@hooks/useFiles';
import useGroupRoom from '@hooks/GroupRoom/useGroupRoom';

interface Props {
  isChatBotGroup: boolean;
  openDownloadAppBanner?: (event: any) => void;
}

const ChatMessages = (props: Props) => {
  const { isChatBotGroup, openDownloadAppBanner } = props;
  const [readConnectChatMessage] = useMutation(ReadConnectChatMessage);
  const { t } = useTranslation();
  const { search } = useLocation<any>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();
  const { openFeedbackDialog } = useCommon();
  const [messages, setMessages] = useState<string>('');
  const chatBoxRef = useRef<any>(null);
  const {
    myDrive,
    onSetAddFilesFromType,
    onSetUploadFileFromComputerStatus,
    onSetSelectedFilesUploadInChat,
    onSetActiveFileIdInGroupRoom,
    onSetShowHideRequestForDownload
  } = useMyDrive();
  const {
    onFetchConnectChatMessages,
    connectChatMessages,
    subscribeChatMessageToMore,
    onFetchMoreConnectChatMessages
  } = useGroupRoom();
  const { group, onSetCreateGroupScreen } = useGroup();
  const [
    isShowHideUploadFilesMobileDialog,
    setShowHideUploadFilesMobileDialog
  ] = useState(false);

  const [sendConnectChatMessage] = useSendConnectChatMessageMutation();
  const [
    internalConnectChatMessagesState,
    setInternalConnectChatMessagesState
  ] = useState<any>([]);
  const chatroomId = new URLSearchParams(search).get('chatroomId');
  const groupId = new URLSearchParams(search).get('groupId');
  const chatBoxWrapperRef = useRef<any>(null);
  const lastMessageRef = useRef<any>(null);
  const [lastMessageIndex, setLastMessageIndex] = useState<any>(null);
  const [isFetching, setIsFetching] = useState(false);
  const { userState } = useAuth();
  const [userEmail, setUserEmail] = useState(
    getStorageItem(STORAGE_ACTION.USER_INFO).email
  );
  const [ShareFilesToGroup] = useShareFilesToGroupMutation();
  const { onInsertUserActionLogOne } = useUserActionLog();
  const { onFetchGroupData } = useSharedWithGroups();
  const [originMessagesLength, setOriginMessagesLength] = useState<number>(0);
  const [isSend, setSendState] = useState<boolean>(false);
  const [
    isShowHideInsertFromMyDriveDialog,
    setShowHideInsertFromMyDriveDialog
  ] = useState<boolean>(false);
  const { onDownloadConnectFile } = useFiles();
  const { enqueueSnackbar } = useSnackbar();
  const [uploadFileData, setUploadFileData] = useState<any>(null);

  useEffect(() => {
    if (userState?.data) {
      setUserEmail(userState?.data?.email);
    }
  }, [userState]);

  useSubscription(SubscribeConnectUpdateData, {
    onSubscriptionData: (subscriptionData) => {
      const subscribeConnectUpdateData =
        subscriptionData?.subscriptionData?.data;
      if (subscribeConnectUpdateData) {
        switch (
          subscribeConnectUpdateData?.subscribeConnectUpdateData?.data?.type
        ) {
          case CONNECT_UPDATE_TYPE.CHAT_UPDATE: {
            if (!isChatBotGroup && uploadFileData === null) {
              onFetchConnectChatMessages({
                variables: {
                  chatRoomId: chatroomId,
                  limit: DEFAULT_ITEMS_LIMIT,
                  offset: 0
                }
              });
            }
            break;
          }
          case CONNECT_UPDATE_TYPE.MEMBER_UPDATE: {
            onFetchConnectChatMessages({
              variables: {
                chatRoomId: chatroomId,
                limit: DEFAULT_ITEMS_LIMIT,
                offset: 0
              }
            });
            break;
          }
          case CONNECT_UPDATE_TYPE.FILE_UPDATE: {
            onFetchGroupData({
              variables: {
                groupId: groupId
              }
            });
            onFetchConnectChatMessages({
              variables: {
                chatRoomId: chatroomId,
                limit: DEFAULT_ITEMS_LIMIT,
                offset: 0
              }
            });
            break;
          }
        }
      }
    }
  });

  useSubscription(SubscribeFileStatusUpdate, {
    onSubscriptionData: (subscribeFileStatusUpdate) => {
      if (
        subscribeFileStatusUpdate &&
        subscribeFileStatusUpdate?.subscriptionData?.data
          ?.subscribeFileStatusUpdate?.data?.status ===
          CAPA_FILE_CONVERT_STATUS.CONVERTED
      ) {
        onFetchConnectChatMessages({
          variables: {
            chatRoomId: chatroomId,
            limit: DEFAULT_ITEMS_LIMIT,
            offset: 0
          }
        });
      }
    }
  });

  const scrollToBottom = () => {
    setTimeout(() => {
      if (chatBoxRef && chatBoxRef.current) {
        chatBoxRef.current.scrollIntoView({
          behavior: 'auto'
        });
      }
    }, 300);
  };

  const readMessages = () => {
    readConnectChatMessage({
      variables: {
        chatRoomId: chatroomId
      }
    });
  };

  useEffect(() => {
    if (chatroomId) {
      onFetchConnectChatMessages({
        variables: {
          chatRoomId: chatroomId,
          limit: DEFAULT_ITEMS_LIMIT,
          offset: 0
        }
      });
    }
  }, [chatroomId]);

  useEffect(() => {
    setInternalConnectChatMessagesState([]);
    onFetchGroupData({
      variables: {
        groupId: groupId
      }
    });
  }, []);

  useEffect(() => {
    if (
      connectChatMessages &&
      connectChatMessages?.fetchConnectChatMessages &&
      connectChatMessages?.fetchConnectChatMessages?.data &&
      connectChatMessages?.fetchConnectChatMessages?.data.length > 0 &&
      !isFetching
    ) {
      if (
        connectChatMessages?.fetchConnectChatMessages?.data.length !==
        originMessagesLength
      ) {
        readMessages();
      }
      setInternalConnectChatMessagesState(
        prepareConnectChatMessagesState(
          connectChatMessages?.fetchConnectChatMessages?.data.reverse() ?? []
        )
      );
      scrollToBottom();
    } else if (
      connectChatMessages &&
      connectChatMessages?.fetchConnectChatMessages &&
      connectChatMessages?.fetchConnectChatMessages?.data &&
      connectChatMessages?.fetchConnectChatMessages?.data.length === 0 &&
      uploadFileData === null
    ) {
      setInternalConnectChatMessagesState([]);
    }

    if (uploadFileData !== null) setUploadFileData(null);
  }, [connectChatMessages?.fetchConnectChatMessages?.data]);

  const prepareConnectChatMessagesState = (data: any) => {
    if (isChatBotGroup) return data;

    setOriginMessagesLength(data?.length);
    let result = [...data];

    result.forEach((item: any) => {
      if (item?.chatFiles?.length > 1) {
        item?.chatFiles.forEach((file: any, index: number, object: any) => {
          if (item?.chatFiles?.length > 0) {
            result.push({
              chatFiles: [file],
              chatRoomId: item.chatRoomId,
              createdAt: item.createdAt,
              groupId: item.groupId,
              id: item.id,
              message: item.message,
              senderEmail: item.senderEmail,
              senderId: item.senderId,
              senderName: item.senderName,
              unReadCount: item.unReadCount,
              __typename: item.__typename
            });
          }
        });
      }
    });

    return result
      .sort((a: any, b: any) =>
        dayjs(a.createdAt).isAfter(dayjs(b.createdAt)) ? 1 : -1
      )
      ?.filter(
        (item: any) => item.chatFiles === null || item.chatFiles.length < 2
      );
  };

  useEffect(() => {
    if (
      internalConnectChatMessagesState &&
      internalConnectChatMessagesState.length > 0 &&
      !isFetching
    ) {
      scrollToBottom();
    }
  }, [internalConnectChatMessagesState]);

  useLayoutEffect(() => {
    if (isChatBotGroup || uploadFileData !== null) return;
    if (subscribeChatMessageToMore !== undefined) {
      subscribeChatMessageToMore({
        document: SubscribeConnectChatMessages,
        variables: { chatRoomId: chatroomId },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData) return prev;

          const prevMessages = prev.fetchConnectChatMessages.data;
          const moreMessages =
            subscriptionData.data.subscribeConnectChatMessages?.data?.filter(
              (item: any) =>
                item?.message?.message !== null ||
                (isChatBotGroup &&
                  item?.message?.messageType === MESSAGE_TYPE.TUTORIAL)
            );

          if (moreMessages[0]?.senderEmail === chatBotEmail) return prev;

          if (prevMessages)
            prev.fetchConnectChatMessages.data = [
              ...prevMessages!,
              ...moreMessages!
            ];
          prev.fetchConnectChatMessages.data.reverse();

          if (
            moreMessages[0]?.chatFiles === null &&
            moreMessages[0]?.message?.message === ''
          ) {
            return prev;
          }

          setInternalConnectChatMessagesState((prevMessages: any) => [
            ...prevMessages,
            ...moreMessages!
          ]);

          return prev;
        }
      });
    }
  }, [subscribeChatMessageToMore]);

  useEffect(() => {
    if (!isFetching) return;

    setTimeout(() => {
      if (onFetchMoreConnectChatMessages) {
        onFetchMoreConnectChatMessages({
          variables: {
            chatRoomId: chatroomId,
            limit: DEFAULT_ITEMS_LIMIT,
            offset: connectChatMessages?.fetchConnectChatMessages?.data.length
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (
              !fetchMoreResult ||
              fetchMoreResult.fetchConnectChatMessages?.data?.length === 0
            ) {
              setIsFetching(false);
              return prev;
            }

            const prevMessages = prev.fetchConnectChatMessages.data;
            const moreMessages =
              fetchMoreResult.fetchConnectChatMessages?.data.reverse();

            if (prevMessages)
              prev.fetchConnectChatMessages.data = [
                ...moreMessages!,
                ...prevMessages!
              ];

            setLastMessageIndex(
              [...moreMessages!, ...prevMessages!].length -
                internalConnectChatMessagesState.length
            );

            if (
              lastMessageRef &&
              lastMessageRef.current &&
              moreMessages.length > 0
            ) {
              lastMessageRef.current.scrollIntoView({
                block: 'start',
                inline: 'start'
              });
            }

            setInternalConnectChatMessagesState((prevMessage: any) => [
              ...moreMessages,
              ...prevMessage
            ]);

            setIsFetching(false);

            return prev;
          }
        });
      }
    }, 500);
  }, [isFetching]);

  useEffect(() => {
    if (
      lastMessageRef &&
      lastMessageRef.current &&
      !isFetching &&
      lastMessageIndex > 0
    ) {
      lastMessageRef.current.scrollIntoView({
        block: 'start',
        inline: 'start'
      });
    }
  }, [lastMessageIndex, isFetching]);

  const handleClickAttachFileButton = () => {
    onInsertUserActionLogOne(
      Enum_Action_Button_Enum.Button_UploadFileModal_Add,
      CapaActivityType.ButtonClick
    );
    if (isMobile) {
      onSetCreateGroupScreen({
        createGroupScreen: CREATE_GROUP_SCREEN.ADD_FILES_CONTAINER_MOBILE
      });
      setShowHideUploadFilesMobileDialog(true);
      return;
    }
  };

  const onCloseUploadFilesDialog = () => {
    setShowHideUploadFilesMobileDialog(false);
    onSetAddFilesFromType({ addFilesFromType: ADD_FILES_FROM_VALUE.EMPTY });
    onSetCreateGroupScreen({
      createGroupScreen: CREATE_GROUP_SCREEN.CREATE_GROUP
    });
  };

  const onSend = () => {
    if (messages.match(/^ *$/) !== null || messages.trim() === '') {
      setMessages('');
      return;
    }
    setSendState(true);
    sendConnectChatMessage({
      variables: {
        chatRoomId: chatroomId,
        message: messages,
        fileIds: []
      }
    }).then((res) => {
      const result = res?.data?.sendConnectChatMessage?.result;
      if (result === CapaErrorCode.Success) {
        setMessages('');
      }
      setSendState(false);
    });
  };

  const onScrollChatBox = () => {
    const currentChatBoxWrapperRef =
      chatBoxWrapperRef && chatBoxWrapperRef.current;
    if (currentChatBoxWrapperRef.scrollTop !== 0 || isFetching) return;

    setIsFetching(true);
  };

  const refetchMessage = () => {
    setInternalConnectChatMessagesState(
      connectChatMessages?.fetchConnectChatMessages?.data ?? []
    );
    scrollToBottom();
  };

  const onAddFile = () => {
    if (
      myDrive.uploadFileTab === UPLOAD_FILE_TAB.FROM_COMPUTER ||
      myDrive.addFilesFromType === ADD_FILES_FROM_VALUE.MY_DEVICE
    ) {
      uploadFileInChatFromComputer();
      return;
    }

    const selectedFilesUploadInChatIds = myDrive.selectedFilesUploadInChat.map(
      (file: any) => file.id
    );

    const payload: any = {
      groupId: groupId,
      connectFileIds: selectedFilesUploadInChatIds
    };

    ShareFilesToGroup({ variables: payload }).then((res) => {
      const result = res?.data?.shareFilesToGroup?.result;
      if (result === CapaErrorCode.Success) {
        checkShareTimes(() => {
          openFeedbackDialog({
            isOpenFeedback: true,
            isFeedback: true
          });
        });

        onCloseUploadFilesDialog();
        scrollToBottom();
      }
    });
  };

  const uploadFileInChatFromComputer = () => {
    onCloseUploadFilesDialog();
    onSetUploadFileFromComputerStatus({
      isUploadFileFromComputer: true
    });
    setUploadFileData(myDrive.internalFileFromComputer);
    newFileUploadApi(myDrive.internalFileFromComputer, '').then(
      async (responses) => {
        const fileIds = responses.map((res) => res.data.data.data[0].id);

        const payload: any = {
          groupId: groupId,
          connectFileIds: fileIds
        };

        ShareFilesToGroup({ variables: payload }).then((res) => {
          onSetUploadFileFromComputerStatus({
            isUploadFileFromComputer: false
          });
          const result = res?.data?.shareFilesToGroup?.result;
          if (result === CapaErrorCode.Success) {
            onFetchConnectChatMessages({
              variables: {
                chatRoomId: chatroomId,
                limit: DEFAULT_ITEMS_LIMIT,
                offset: 0
              }
            });
            checkShareTimes(() => {
              openFeedbackDialog({
                isOpenFeedback: true,
                isFeedback: true
              });
            });
            scrollToBottom();
          }
        });
      }
    );
  };

  const onOpenDownloadAppBanner = (event: any) => {
    openDownloadAppBanner && openDownloadAppBanner(event);
  };

  const showInsertFileSuccess = (filesLength?: number) => {
    if (filesLength && filesLength === 1) {
      enqueueSnackbar(t('snackbar.shared_file_complete'), {
        variant: 'success'
      });
      return;
    }

    if (filesLength && filesLength > 1) {
      enqueueSnackbar(t('snackbar.shared_files_complete'), {
        variant: 'success'
      });
    }
  };

  useEffect(() => {
    if (uploadFileData === null) return;

    const fileMessageUpload = {
      chatFiles: uploadFileData?.map((file: any) => {
        return {
          fileId: '',
          fileName: file?.name,
          fileSize: file?.size,
          isUpload: true,
          originalFileExt: getFileExtension(file?.name)?.substr(1)
        };
      }),
      createdAt: new Date().toISOString(),
      message: {
        message: null,
        messageType: MESSAGE_TYPE.MESSAGE,
        __typename: MESSAGE_TYPE_NAME.MESSAGE
      },
      senderEmail: userEmail,
      unReadCount: 0
    };
    setInternalConnectChatMessagesState(
      prepareConnectChatMessagesState([
        ...internalConnectChatMessagesState,
        fileMessageUpload
      ])
    );
  }, [uploadFileData]);

  const onDropAccepted = (files: any) => {
    setUploadFileData(files);
    newFileUploadApi(files, '').then(async (responses) => {
      const fileIds = responses.map((res) => res.data.data.data[0].id);

      const payload: any = {
        groupId: groupId,
        connectFileIds: fileIds
      };

      ShareFilesToGroup({ variables: payload }).then((res) => {
        const result = res?.data?.shareFilesToGroup?.result;
        if (result === CapaErrorCode.Success) {
          onFetchConnectChatMessages({
            variables: {
              chatRoomId: chatroomId,
              limit: DEFAULT_ITEMS_LIMIT,
              offset: 0
            }
          });
          showInsertFileSuccess(fileIds?.length);
          checkShareTimes(() => {
            openFeedbackDialog({
              isOpenFeedback: true,
              isFeedback: true
            });
          });
          scrollToBottom();
        }
      });
    });
  };

  const uploadFilesFromCloud = () => {
    const selectedFilesUploadInChatIds = myDrive.selectedFilesUploadInChat.map(
      (file: any) => file.id
    );

    const payload: any = {
      groupId: groupId,
      connectFileIds: selectedFilesUploadInChatIds
    };

    ShareFilesToGroup({ variables: payload }).then((res) => {
      const result = res?.data?.shareFilesToGroup?.result;
      if (result === CapaErrorCode.Success) {
        showInsertFileSuccess(selectedFilesUploadInChatIds?.length);
        checkShareTimes(() => {
          openFeedbackDialog({
            isOpenFeedback: true,
            isFeedback: true
          });
        });

        onSetSelectedFilesUploadInChat({
          selectedFilesUploadInChat: []
        });
        scrollToBottom();
      }
    });
  };

  const onSetShowHideInsertFromMyDriveDialog = (isShowHide: boolean) => {
    setShowHideInsertFromMyDriveDialog(isShowHide);
  };

  const onClickDownloadFile = (message: any) => {
    onSetActiveFileIdInGroupRoom({
      activeFileIdInGroupRoom: message?.chatFiles[0]?.fileId
    });
    if (
      message?.chatFiles[0]?.permission !== DOWNLOAD_PERMISSION_STATUS.ALLOW
    ) {
      onSetShowHideRequestForDownload({
        isShowHideRequestForDownload: true
      });
      return;
    }
    if (message?.chatFiles[0]) {
      onDownloadConnectFile(message?.chatFiles[0], true)
        .then(() => {
          enqueueSnackbar(
            `${t('confirm_dialog.download.success_text', {
              fileName: message?.chatFiles[0].fileName
            })}`,
            {
              variant: 'success'
            }
          );
        })
        .catch(() => {
          enqueueSnackbar(`${t('confirm_dialog.download.error_text')}`, {
            variant: 'error'
          });
        });
    }
  };

  const messagesList = useMemo(() => {
    return internalConnectChatMessagesState !== undefined
      ? internalConnectChatMessagesState.map((message: any, index: number) => {
          const time = formatTimeStamp(
            message.createdAt,
            false,
            false,
            false,
            false
          );
          const beginDayArray = uniqueArray(
            internalConnectChatMessagesState,
            'createdAt'
          ).map((item: any) => formatDate(item.createdAt, FORMAT_DATE));
          let beginDayArrayIndex: any = [];

          beginDayArray.forEach((date: any) => {
            beginDayArrayIndex.push(
              internalConnectChatMessagesState.findIndex((message: any) => {
                return formatDate(message.createdAt, FORMAT_DATE) === date;
              })
            );
          });

          const isImage =
            message.chatFiles !== null &&
            message?.chatFiles?.some((file: any) =>
              convertSubstring(IMAGE_TYPES).includes(
                file?.originalFileExt?.toLocaleLowerCase()
              )
            );

          const isRegroupFirstMessage =
            message.senderEmail ===
              (internalConnectChatMessagesState &&
                internalConnectChatMessagesState[index - 1] &&
                internalConnectChatMessagesState[index - 1].senderEmail) &&
            !beginDayArrayIndex.includes(index) &&
            dayjs(message.createdAt).minute() ===
              dayjs(
                internalConnectChatMessagesState[index - 1]?.createdAt
              ).minute();

          const isRegroupLastMessage =
            message.senderEmail ===
              (internalConnectChatMessagesState &&
                internalConnectChatMessagesState[index + 1] &&
                internalConnectChatMessagesState[index + 1].senderEmail) &&
            dayjs(message.createdAt).minute() ===
              dayjs(
                internalConnectChatMessagesState[index + 1]?.createdAt
              ).minute();

          const isTutorialMessage =
            message.message.messageType === MESSAGE_TYPE.TUTORIAL;

          let thankYouButtonMessageIndex = 0;

          let internalIsClickedThankYouButton = false;

          if (isChatBotGroup) {
            thankYouButtonMessageIndex =
              internalConnectChatMessagesState.findIndex((message: any) => {
                return message.message.messageType === MESSAGE_TYPE.TUTORIAL;
              });

            internalIsClickedThankYouButton =
              thankYouButtonMessageIndex !==
              internalConnectChatMessagesState.length - 1;
          }

          const isCurrentUser = message?.fileOwnerEmail
            ? message?.fileOwnerEmail === userEmail
            : message.senderEmail === userEmail;

          const fileOwnerNameDisplay =
            message?.fileOwnerName ?? message?.fileOwnerEmail;
          const senderNameDisplay = message?.senderName ?? message?.senderEmail;

          return (
            <>
              {index === lastMessageIndex - 1 ? (
                <div ref={lastMessageRef} />
              ) : null}

              {beginDayArrayIndex.includes(index) ? (
                <div className={classes.beginDay}>
                  {DAY_NAME[new Date(message.createdAt).getDay()]}
                  {', '}
                  {MONTH_NAME[new Date(message.createdAt).getMonth()]}{' '}
                  {formatDate(message.createdAt, 'DD')},{' '}
                  {new Date(message.createdAt).getFullYear()}
                </div>
              ) : null}
              {(message.message.__typename === MESSAGE_TYPE_NAME.MESSAGE &&
                (message.chatFiles || message.message.message)) ||
              (message.message.__typename === MESSAGE_TYPE_NAME.TUTORIAL &&
                !internalIsClickedThankYouButton &&
                userEmail !== undefined) ? (
                <div
                  className={clsx(
                    classes.textMessageWrapper,
                    isCurrentUser ? classes.textMessageMarginLeft : '',
                    isChatBotGroup
                      ? classes.chatBoxTextMessageMarginBottom
                      : classes.textMessageMarginBottom
                  )}
                >
                  {isCurrentUser ||
                  isRegroupFirstMessage ? null : isChatBotGroup ? (
                    <Tooltip title={CHAT_BOT_NAME}>
                      <img
                        className={classes.groupChatBotAvatar}
                        src={CapaGroupChatBot}
                        alt=""
                      />
                    </Tooltip>
                  ) : (
                    <CapaAvatar
                      item={{
                        isCapaUser: true,
                        connectUserName:
                          message?.fileOwnerName ?? message?.senderName,
                        email: message?.fileOwnerEmail ?? message?.senderEmail
                      }}
                      size="xSmall"
                      showTooltip={true}
                    />
                  )}

                  <div
                    className={clsx(
                      isCurrentUser || isRegroupFirstMessage
                        ? ''
                        : classes.managerBox,
                      isRegroupFirstMessage
                        ? classes.marginManagerRegroupBox
                        : classes.managerBox
                    )}
                  >
                    {isCurrentUser || isRegroupFirstMessage ? null : (
                      <div className={classes.managerName}>
                        {fileOwnerNameDisplay
                          ? fileOwnerNameDisplay
                          : senderNameDisplay}
                      </div>
                    )}
                    <div className={classes.messageBox}>
                      {isCurrentUser &&
                      !isTutorialMessage &&
                      !isRegroupLastMessage ? (
                        <div
                          className={clsx(
                            classes.messageTime,
                            classes.userMessageTime
                          )}
                        >
                          {message.unReadCount > 0 && !isChatBotGroup ? (
                            <div className={classes.userReadNumber}>
                              {message.unReadCount}
                            </div>
                          ) : null}
                          {time}
                        </div>
                      ) : null}
                      {isTutorialMessage ? (
                        <ButtonMessage
                          message={message}
                          buttonMessageCallback={() => {
                            setInternalConnectChatMessagesState([]);
                            onFetchConnectChatMessages({
                              variables: {
                                chatRoomId: chatroomId,
                                limit: DEFAULT_ITEMS_LIMIT,
                                offset: 0
                              }
                            });
                          }}
                        />
                      ) : message.chatFiles === null ? (
                        <TextMessage
                          message={message}
                          isChatBotGroup={isChatBotGroup}
                        />
                      ) : isImage ? (
                        <ImageMessage
                          messageDataOrigin={
                            connectChatMessages?.fetchConnectChatMessages?.data
                          }
                          message={message}
                          userEmail={userEmail}
                          onClickDownloadFile={onClickDownloadFile}
                          openDownloadAppBanner={onOpenDownloadAppBanner}
                        />
                      ) : (
                        <FileMessage
                          messageDataOrigin={
                            connectChatMessages?.fetchConnectChatMessages?.data
                          }
                          onClickDownloadFile={onClickDownloadFile}
                          message={message}
                          userEmail={userEmail}
                          refetchMessage={refetchMessage}
                          openDownloadAppBanner={onOpenDownloadAppBanner}
                        />
                      )}
                      {isCurrentUser || isRegroupLastMessage ? null : (
                        <div
                          className={clsx(
                            classes.messageTime,
                            classes.managerMessageTime
                          )}
                        >
                          {message.unReadCount > 0 && !isChatBotGroup ? (
                            <div className={classes.managerReadNumber}>
                              {message.unReadCount}
                            </div>
                          ) : null}
                          {time}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : null}

              {message.message.__typename === MESSAGE_TYPE_NAME.INVITED ? (
                <div className={classes.notificationMessage}>
                  {t('chat_room.member_invited_to_the_group', {
                    username: message.message.userName
                  })}
                </div>
              ) : null}

              {message.message.__typename === MESSAGE_TYPE_NAME.REMOVED ? (
                <div className={classes.notificationMessage}>
                  {t('chat_room.member_removed_from_the_group', {
                    username: message.message.userName
                  })}
                </div>
              ) : null}

              {message.message.__typename === MESSAGE_TYPE_NAME.LEAVE ? (
                <div className={classes.notificationMessage}>
                  {t('chat_room.member_left_the_group', {
                    username: message.message.userName
                  })}
                </div>
              ) : null}

              {message.message.__typename === MESSAGE_TYPE_NAME.FILE_SHARED ? (
                <div className={classes.notificationMessage}>
                  {t('chat_room.file_shared_to_the_group', {
                    fileName: message.message.fileName
                  })}
                </div>
              ) : null}
            </>
          );
        })
      : null;
  }, [internalConnectChatMessagesState]);

  return (
    <div className={classes.chatMessageWrapper}>
      {isFetching && internalConnectChatMessagesState !== undefined ? (
        <Loading
          open={true}
          size={18}
          styles={{
            height: 0,
            top: 14
          }}
        />
      ) : null}
      <div
        className={classes.chatBox}
        ref={chatBoxWrapperRef}
        onScroll={onScrollChatBox}
      >
        {messagesList}
        <div ref={chatBoxRef} />
      </div>

      <div className={classes.actionWrapper}>
        {isMobile ? (
          <>
            <Button
              className={classes.attachFileButton}
              startIcon={
                <AddCircleOutlineRounded
                  className={classes.addCircleOutlineRoundedIcon}
                />
              }
              disabled={isChatBotGroup}
              onClick={handleClickAttachFileButton}
            />
            <TextField
              multiline
              className={clsx(
                classes.messageTextField,
                isChatBotGroup ? classes.disabledMessageTextField : ''
              )}
              placeholder={
                isChatBotGroup
                  ? t('chat_room.chat_bot.chat_is_unavailable')
                  : t('chat_room.chat_box_placeholder')
              }
              value={messages ?? undefined}
              onChange={(event) =>
                setMessages && setMessages(event.target.value)
              }
              disabled={isChatBotGroup}
            />
            {isChatBotGroup ? (
              <></>
            ) : (
              <Send
                className={clsx(
                  classes.sendButtonMobile,
                  !messages.trim() || isSend
                    ? classes.disabledSendButtonMobile
                    : classes.csSendButtonMobile
                )}
                onClick={onSend}
              />
            )}
          </>
        ) : (
          <>
            <div className={classes.actionBar}>
              {isChatBotGroup ? null : (
                <UploadFilesButton
                  onDropAccepted={onDropAccepted}
                  onClickCloudIcon={() =>
                    setShowHideInsertFromMyDriveDialog(true)
                  }
                  isChatBox={true}
                />
              )}
            </div>
            <div className={classes.sendMessageBox}>
              <TextField
                className={classes.messageTextField}
                placeholder={
                  isChatBotGroup
                    ? t('chat_room.chat_bot.chat_is_unavailable')
                    : t('chat_room.chat_box_placeholder')
                }
                value={messages ?? undefined}
                onChange={(event) =>
                  setMessages && setMessages(event.target.value)
                }
                onKeyDown={(e) => {
                  if (e.keyCode === KEY_CODE.ENTER && e.shiftKey) return;
                  if (e.keyCode === KEY_CODE.ENTER) e.preventDefault();
                  if (e.keyCode === KEY_CODE.ENTER && !isSend) {
                    onSend && onSend();
                  }
                }}
                disabled={isChatBotGroup}
                multiline
              />
              {isChatBotGroup ? null : (
                <CustomizedButton
                  className={classes.sendButton}
                  aria-haspopup="true"
                  variant="contained"
                  size="small"
                  onClick={onSend}
                  disabled={!messages.trim() || isSend}
                >
                  {t('chat_room.send_button')}
                </CustomizedButton>
              )}
            </div>
          </>
        )}
      </div>

      {isShowHideInsertFromMyDriveDialog ? (
        <InsertFromMyDriveContainer
          setShowHideUploadFilesDialog={onSetShowHideInsertFromMyDriveDialog}
          isShowHideUploadFilesDialog={isShowHideInsertFromMyDriveDialog}
          uploadFilesFromChatBox={uploadFilesFromCloud}
          filesUploaded={[]}
        />
      ) : null}

      {isMobile ? (
        <>
          <UploadFilesContainer
            isShowHideUploadFilesMobileDialog={
              group.createGroupScreen ===
                CREATE_GROUP_SCREEN.ADD_FILES_CONTAINER_MOBILE &&
              isShowHideUploadFilesMobileDialog
            }
            onClose={onCloseUploadFilesDialog}
          />
          <UploadFilesFrom
            isShowHideUploadFilesFromDialog={
              group.createGroupScreen ===
                CREATE_GROUP_SCREEN.ADD_FILES_FROM_MOBILE &&
              isShowHideUploadFilesMobileDialog
            }
            submitText={t('upload_files_modal.add_button')}
            onSubmit={onAddFile}
            onClose={onCloseUploadFilesDialog}
          />
        </>
      ) : null}
    </div>
  );
};

export default ChatMessages;
