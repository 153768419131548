import React from 'react';
import {
  useRequestResetPasswordMutation,
  CapaErrorCode
} from '@generated/Common/graphql';
import { clearStorageAll } from '@utils/storage';
import { useSnackbar } from '@components/Provider';
import Loading from '@components/Loading';
import RequestPasswordPresenter from './RequestPasswordPresenter';
import { Inputs } from './RequestPasswordPresenter';
import { useTranslation } from 'react-i18next';

interface Props {
  onSuccess?: () => void;
  onGotoLogin?: () => void;
}
const RequestPasswordContainer: React.FC<Props> = (props: Props) => {
  const { onSuccess, onGotoLogin } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [requestResetPassword, { loading }] = useRequestResetPasswordMutation();

  const onSubmit = (req: Inputs) => {
    const trimmedEmail = req.email.trim();
    requestResetPassword({ variables: { email: trimmedEmail } })
      .then((resp) => {
        if (
          resp.data &&
          resp.data.requestResetPassword &&
          resp.data.requestResetPassword.result === CapaErrorCode.Success
        ) {
          clearStorageAll();
          enqueueSnackbar(t('reset_pass.success'), { variant: 'success' });
          onSuccess && onSuccess();
        } else {
          enqueueSnackbar(t('reset_pass.no_account'), { variant: 'error' });
        }
      })
      .catch(() => {
        enqueueSnackbar(t('reset_pass.no_account'), { variant: 'error' });
      });
  };

  return (
    <React.Fragment>
      <Loading open={loading} />
      <RequestPasswordPresenter onSubmit={onSubmit} onGotoLogin={onGotoLogin} />
    </React.Fragment>
  );
};

export default RequestPasswordContainer;
