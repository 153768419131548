import React from 'react';
import clsx from 'clsx';
import { colorSet } from '@components/Provider';
import { Box, CircularProgress, makeStyles } from '@material-ui/core';

const CustomizedCircularProgress = ({ value = 0 }: { value?: number }) => {
  const { container, progressCircle, grayTrack } = useStyles();

  return (
    <Box className={container}>
      {/* background gray track */}
      <CircularProgress
        className={clsx([progressCircle, grayTrack])}
        variant="determinate"
        value={100}
        size={20}
        thickness={7}
      />

      {/* primary circle for real progress ratio */}
      <CircularProgress
        className={progressCircle}
        variant="determinate"
        color="primary"
        value={value}
        size={20}
        thickness={7}
      />
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    width: 24,
    height: 24,
    position: 'relative'
  },
  progressCircle: {
    margin: 2,
    position: 'absolute',
    top: 0,
    left: 0
  },
  grayTrack: {
    '& svg': {
      color: colorSet.gray200
    }
  }
}));

export default CustomizedCircularProgress;
