import React, { useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
  Switch
} from '@material-ui/core';
import { useStyles } from './styles';
import useMyDrive from '@hooks/Drive/MyDrive/useMyDrive';
import { SettingsOutlined, CloseOutlined } from '@material-ui/icons';
import clsx from 'clsx';
import { DOWNLOAD_PERMISSION_STATUS } from '@routes/Dashboard/types';
import {
  CapaQuotationRfqAllowOpenFileStatus,
  SubscribeConnectUpdateData,
  useManageDownloadPermissionMutation
} from '@generated/Drive/MyDrive/graphql';
import { CapaErrorCode } from '@generated/Common/graphql';
import { CapaActivityType } from '@generated/Common/types';
import { useSnackbar } from '@components/Provider';
import { useTranslation } from 'react-i18next';
import useUserActionLog, {
  Enum_Action_Button_Enum,
  Enum_Action_Page_Enum
} from '@hooks/useUserActionLog';
import { CONNECT_UPDATE_TYPE } from '@routes/Common/types';
import * as Apollo from '@apollo/client';
import { getDisplayedAvatarString } from '@utils/common';
import CapaAvatar from '@components/CapaAvatar';
import { useLocation } from 'react-router-dom';
import useAuth from '@hooks/useAuth';
import Loading from '@components/LoadingInComponent';

interface Props {
  onRefetchFileDetail?: () => void;
  groupData?: any;
}

const DownloadPermissionDialog: React.FC<Props> = ({
  onRefetchFileDetail,
  groupData
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const {
    myDrive,
    onSetShowHideDownloadPermissionDialog,
    onFetchFilePermission,
    filePermissionState
  } = useMyDrive();

  const [manageDownloadPermission] = useManageDownloadPermissionMutation();
  const { onInsertUserActionLogOne } = useUserActionLog();
  const { search } = useLocation();
  const groupId = new URLSearchParams(search).get('groupId');
  const { userState } = useAuth();

  const onClose = () => {
    onSetShowHideDownloadPermissionDialog({
      isShowHideDownloadPermissionDialog: false
    });
  };

  const handleChange = (member: any) => {
    onInsertUserActionLogOne(
      Enum_Action_Button_Enum.Toggle_DownloadPermission_MemberAccess,
      CapaActivityType.ButtonClick
    );
    const payload: {
      groupId: string;
      groupUserId: string;
      connectFileId: string;
      status: CapaQuotationRfqAllowOpenFileStatus;
    } = {
      groupId: myDrive.selectedGroupShared.groupId,
      groupUserId: member.groupUserId,
      connectFileId: myDrive.selectedGroupShared.connectFileId,
      status:
        member.permission === CapaQuotationRfqAllowOpenFileStatus.Allow
          ? CapaQuotationRfqAllowOpenFileStatus.Denied
          : CapaQuotationRfqAllowOpenFileStatus.Allow
    };

    manageDownloadPermission({ variables: payload }).then((res) => {
      const result = res?.data?.manageDownloadPermission?.result;
      let snackbarMessage = '';

      if (
        [
          DOWNLOAD_PERMISSION_STATUS.REQUEST,
          DOWNLOAD_PERMISSION_STATUS.DENIED
        ].includes(member.permission)
      ) {
        snackbarMessage = t('snackbar.change_to_allowed', {
          managerName:
            member.userName !== null ? member.userName : member.email,
          fileName: member.fileName
        });
      } else if (member.permission === DOWNLOAD_PERMISSION_STATUS.ALLOW) {
        snackbarMessage = t('snackbar.change_to_not_allowed', {
          userName: member.userName !== null ? member.userName : member.email,
          fileName: member.fileName
        });
      }

      if (result === CapaErrorCode.Success) {
        onFetchFilePermission({
          variables: myDrive.selectedGroupShared
        });
        enqueueSnackbar(snackbarMessage, {
          variant: 'success'
        });
        if (onRefetchFileDetail) {
          onRefetchFileDetail();
        }
      }
    });
  };

  Apollo.useSubscription(SubscribeConnectUpdateData, {
    onSubscriptionData: (subscriptionData) => {
      const subscribeConnectUpdateData =
        subscriptionData?.subscriptionData?.data;
      if (subscribeConnectUpdateData) {
        switch (
          subscribeConnectUpdateData?.subscribeConnectUpdateData?.data?.type
        ) {
          case CONNECT_UPDATE_TYPE.MEMBER_UPDATE: {
            if (myDrive.isShowHideDownloadPermissionDialog) {
              onFetchFilePermission({
                variables: {
                  groupId:
                    subscribeConnectUpdateData?.subscribeConnectUpdateData?.data
                      ?.groupId,
                  connectFileId: myDrive.activeFileIdInGroupRoom
                }
              });
            }
            break;
          }
        }
      }
    }
  });

  useEffect(() => {
    if (myDrive.isShowHideDownloadPermissionDialog) {
      onInsertUserActionLogOne(
        Enum_Action_Page_Enum.Page_DownloadPermission,
        CapaActivityType.PageVisit
      );

      const variables = groupId
        ? {
            groupId: groupId,
            connectFileId: myDrive.activeFileIdInGroupRoom
          }
        : myDrive.selectedGroupShared;

      onFetchFilePermission({
        variables: variables
      });
    }
  }, [myDrive.isShowHideDownloadPermissionDialog]);

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={myDrive.isShowHideDownloadPermissionDialog!}
      onClick={(e) => {
        e.stopPropagation();
      }}
      onClose={onClose}
      disableEscapeKeyDown={true}
    >
      <DialogTitle classes={{ root: classes.rootTitle }} disableTypography>
        {matches ? (
          <CloseOutlined
            className={classes.arrowBackIosOutlinedIcon}
            onClick={onClose}
          />
        ) : (
          <div
            className={clsx(
              classes.settingsOutlinedWrapper,
              classes.pointerCursor
            )}
          >
            <SettingsOutlined className={classes.settingsOutlinedIcon} />
          </div>
        )}

        <div>{t('download_permission.header')}</div>
      </DialogTitle>
      <DialogContent className={classes.csDialogContent}>
        {filePermissionState.isLoading ? (
          <Loading
            open={true}
            styles={{
              display: 'flex',
              justifyContent: 'center',
              minHeight: 100
            }}
          />
        ) : (
          <>
            {' '}
            {matches ? null : (
              <div className={classes.groupInfo}>
                <div>{myDrive.selectedGroupShared.groupName}</div>
                <div>
                  {filePermissionState?.data &&
                    filePermissionState?.data?.length - 1}
                  {filePermissionState &&
                  filePermissionState.data &&
                  filePermissionState.data.length - 1 > 1
                    ? `${t('download_permission.group_members_number')}`
                    : `${t('download_permission.group_member_number')}`}
                </div>
              </div>
            )}
            <div className={classes.memberList}>
              {filePermissionState?.data
                ?.filter((item: any) => item?.email !== userState?.data?.email)
                ?.map((member: any, index: number) => {
                  return (
                    <div className={classes.memberWrapper} key={index}>
                      <div className={classes.memberNameWrapper}>
                        <CapaAvatar item={member} />
                        <div className={classes.memberEmailName}>
                          <div className={classes.managerName}>
                            {getDisplayedAvatarString(
                              member.userName,
                              member.email
                            )}
                          </div>
                          <div className={classes.memberEmail}>
                            {member.email ? member.email : member.userName}
                          </div>
                        </div>
                      </div>

                      <div className={classes.memberStatus}>
                        {matches ? null : (
                          <>
                            {member.permission ===
                            DOWNLOAD_PERMISSION_STATUS.ALLOW ? (
                              <div className={classes.allowedText}>
                                {t('download_permission.access_status.allowed')}
                              </div>
                            ) : (
                              <div className={classes.notAllowedText}>
                                {t(
                                  'download_permission.access_status.not_allowed'
                                )}
                              </div>
                            )}
                          </>
                        )}
                        <Switch
                          defaultChecked={
                            member.permission ===
                            DOWNLOAD_PERMISSION_STATUS.ALLOW
                          }
                          onChange={() => {
                            handleChange(member);
                          }}
                          inputProps={{ 'aria-label': 'controlled' }}
                          color="primary"
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default DownloadPermissionDialog;
