import React from 'react';
import { useHistory } from 'react-router-dom';
import useUserActionLog, {
  Enum_Action_Button_Enum,
  CapaActivityType
} from '@hooks/useUserActionLog';
import { CustomizedTypography } from '@components/Customized';
import { colorSet } from '@components/Provider';
import { Box, IconButton, makeStyles } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { ACCOUNT_INFO_TYPE } from '@routes/MyPage/Account/types';

interface AccountInfoListEntryProps {
  title: string;
  value: string;
}

const AccountInfoListEntry: React.FC<AccountInfoListEntryProps> = ({
  title,
  value
}) => {
  const { t } = useTranslation();
  const { container, infoBox, editButton } = useStyles();
  const history = useHistory();
  const { onInsertUserActionLogOne } = useUserActionLog();

  return (
    <Box className={container}>
      <Box className={infoBox}>
        <CustomizedTypography variant="body1" className="title">
          {title === ACCOUNT_INFO_TYPE.EMAIL
            ? t('account_page.email')
            : title === ACCOUNT_INFO_TYPE.PASSWORD
            ? t('account_page.password')
            : t('account_page.name')}
        </CustomizedTypography>
        <CustomizedTypography
          variant="body1"
          fontWeight="500"
          className="value"
        >
          {value}
        </CustomizedTypography>
      </Box>

      <IconButton
        className={editButton}
        onClick={() => {
          if (title === ACCOUNT_INFO_TYPE.EMAIL) {
            onInsertUserActionLogOne(
              Enum_Action_Button_Enum.Button_MyPage_Edit_Email,
              CapaActivityType.ButtonClick
            );
          }

          if (title === ACCOUNT_INFO_TYPE.PASSWORD) {
            onInsertUserActionLogOne(
              Enum_Action_Button_Enum.Button_MyPage_Edit_Password,
              CapaActivityType.ButtonClick
            );
          }

          history.push(`/mypage/edit/${title}`);
        }}
      >
        <ChevronRight />
      </IconButton>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    borderBottom: `1px solid ${colorSet.gray200}`,
    marginBottom: 24
  },
  infoBox: {
    display: 'flex',
    flexDirection: 'column',
    '& .title': {
      marginBottom: 8,
      color: colorSet.gray600
    },
    '& .value': {
      marginBottom: 20
    }
  },
  editButton: {
    padding: '12px 26px',
    color: colorSet.gray900
  }
}));

export default AccountInfoListEntry;
