import { useWatch, Control } from 'react-hook-form';
import { AccountReq } from './types';

const useValidCheck = (
  control: Control<AccountReq>,
  fieldArr: Array<keyof AccountReq>
) => {
  const data = useWatch({
    control,
    name: fieldArr
  });

  const getIsDisabledBtn = () => {
    return fieldArr.some((item) => {
      return !data[item] || data[item].length < 1;
    });
  };

  return { getIsDisabledBtn, currentState: data };
};

export default useValidCheck;
