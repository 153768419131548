import { useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
import { FetchCAPANotification } from '@generated/Drive/MyDrive/graphql';

export default function useNotification() {
  const [
    fetchCAPANotification,
    {
      data: connectNotification,
      loading: connectNotificationLoading,
      subscribeToMore: subscribeNotificationToMore,
      fetchMore: onFetchMoreConnectNotification
    }
  ] = useLazyQuery(FetchCAPANotification, {
    fetchPolicy: 'no-cache'
  });

  const onFetchCAPANotification = useCallback(fetchCAPANotification, [
    fetchCAPANotification
  ]);

  return {
    onFetchCAPANotification,
    connectNotificationLoading,
    connectNotification,
    onFetchMoreConnectNotification,
    subscribeNotificationToMore
  };
}
