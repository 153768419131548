import { makeStyles } from '@material-ui/core';
import { colorSet } from '@components/Provider';

export const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: 520,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: 0,
      height: '100%',
      maxHeight: '100%',
      maxWidth: '100%',
      borderRadius: 0
    },
    borderRadius: '16px'
  },
  addMembersToWrapper: {
    maxWidth: 520,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: 0,
      height: '100%',
      maxHeight: '100%',
      maxWidth: '100%',
      borderRadius: 0
    },
    borderRadius: '16px'
  },
  rootDialog: {
    display: 'none'
  },
  successFullyPaper: {
    maxWidth: 520,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: 0,
      height: '100%',
      maxHeight: '100%',
      maxWidth: '100%',
      borderRadius: 0
    },
    maxHeight: 593,
    borderRadius: '16px'
  },
  groupSuccessContent: {
    fontSize: 25,
    lineHeight: '160%',
    fontWeight: 400,
    color: colorSet.gray900,
    '& strong': {
      fontWeight: 700
    }
  },
  backIconWrapper: {
    backgroundColor: colorSet.primary450,
    borderRadius: 12,
    marginRight: 12,
    width: 36,
    height: 36,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: colorSet.primary600
    },
    '& svg path': {
      fill: `${colorSet.gray000} !important`
    }
  },
  backIconMobile: {
    position: 'absolute',
    left: 16
  },
  shareToOption: {
    fontSize: 14,
    '& strong': {
      fontWeight: 700
    }
  },
  rootTitle: {
    padding: '24px 24px 12px 24px',
    display: 'flex',
    fontSize: '25px',
    fontWeight: 'bold',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: '12px 16px',
      border: `1px solid ${colorSet.gray300}`,
      display: 'inline-block',
      textAlign: 'center',
      fontSize: 16
    }
  },
  existingGroupRootTitle: {
    padding: '24px 24px 20px 24px',
    display: 'flex',
    fontSize: '25px',
    fontWeight: 'bold',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: '12px 16px',
      border: `1px solid ${colorSet.gray300}`,
      display: 'inline-block',
      textAlign: 'center',
      fontSize: 16
    }
  },
  closeIcon: {
    color: colorSet.gray900,
    fontWeight: 500,
    position: 'absolute',
    left: 16,
    top: 10,
    [theme.breakpoints.down('xs')]: {
      top: 14
    }
  },
  customTitle: {
    fontSize: 18,
    fontWeight: 700,
    lineHeight: '143%',
    letterSpacing: '-0.1px',
    [theme.breakpoints.down('xs')]: {
      color: colorSet.gray900,
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '150%'
    }
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  createNewGroupTitle: {
    fontWeight: 700,
    fontSize: 20,
    lineHeight: '140%',
    letterSpacing: '-0.1px',
    [theme.breakpoints.down('xs')]: {
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '150%',
      letterSpacing: '-0.1px'
    }
  },
  cancelBtn: {
    fontSize: 24,
    marginLeft: 'auto',
    cursor: 'pointer'
  },
  content: {
    padding: '0px 24px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px 12px'
    }
  },
  action: {
    padding: '20px 32px 24px 32px'
  },
  groupsAction: {
    padding: '20px 24px 24px 24px',
    [theme.breakpoints.down('xs')]: {
      padding: 16
    }
  },
  groupsMobileButtonWrapper: {
    display: 'flex',
    '& button': {
      textTransform: 'none',
      marginLeft: 'auto',
      height: '40px !important',
      minWidth: '108px !important',
      fontSize: '14px !important',
      '&:first-child': {
        marginRight: 8
      }
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      '& button': {
        width: '100%',
        marginRight: 0,
        '&:first-child': {
          marginRight: '0 !important'
        }
      }
    }
  },
  wrapperBtn: {
    display: 'flex',
    '& button': {
      textTransform: 'none',
      marginLeft: 'auto',
      '&:first-child': {
        marginRight: 8
      }
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      '& button': {
        width: 'calc(100% / 2)'
      },
      '& button + button': {
        marginLeft: 6
      }
    }
  },
  csDialogText: {
    color: colorSet.gray900,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '143%',
    letterSpacing: '-0.1px',
    padding: '0 24px',
    [theme.breakpoints.down('xs')]: {
      fontWeight: 500
    }
  },
  shareFilesToBoxWrapper: {
    padding: '0 24px'
  },
  csDialogContent: {
    padding: 0,
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      padding: '16px 0 0 0',
      margin: 0
    }
  },
  existingGroupsDialogContent: {
    padding: '0 24px',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      padding: '16px 16px 0 16px',
      margin: 0
    }
  },
  shareFilesToBox: {
    marginTop: 12,
    marginBottom: 24,
    display: 'inline-flex',
    borderRadius: '12px',
    border: `1px solid ${colorSet.gray400}`,
    padding: '13px 18px',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      '&:first-child': {
        marginTop: 16,
        marginRight: 0
      },
      width: '100%',
      marginTop: 8,
      marginBottom: 0
    },
    '&:hover': {
      border: `1px solid ${colorSet.primary450}`,
      boxShadow: 'inset 999px 999px 0px rgba(75, 136, 255, 0.12)',
      '& .share-files-to-icon': {
        color: colorSet.primary500
      },
      '& svg path': {
        fill: `${colorSet.primary450} !important`
      }
    },
    '& .icon-wrapper': {
      display: 'flex',
      borderRadius: '8px',
      marginRight: 8,
      width: 20,
      height: 20,
      justifyContent: 'center',
      alignItems: 'center',
      '& svg path': {
        fill: `${colorSet.gray2000} !important`
      }
    },
    '& .share-files-to-icon': {
      color: colorSet.gray400
    }
  },
  disabledBox: {
    backgroundColor: colorSet.gray100
  },
  shareGroupAddIconWrapper: {
    backgroundColor: colorSet.primary450,
    borderRadius: 12,
    marginRight: 12,
    width: 36,
    height: 36,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg path': {
      fill: `${colorSet.gray000} !important`
    }
  },
  shareLinkTitle: {
    fontWeight: 700,
    fontSize: 18,
    lineHeight: '140%',
    letterSpacing: '-0.1px',
    [theme.breakpoints.down('xs')]: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '143%'
    }
  },
  shareGroupAddIcon: {
    color: colorSet.gray100
  },
  activeShareFilesToBox: {
    backgroundColor: colorSet.primary100,
    border: `1px solid ${colorSet.primary450}`
  },
  shareFilesName: {
    fontWeight: 'bold',
    lineHeight: '24px'
  },
  arrowBackIosOutlinedWrapper: {
    backgroundColor: colorSet.primary450,
    borderRadius: 12,
    marginRight: 16,
    width: 36,
    height: 36,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: colorSet.primary600
    }
  },
  arrowBackIosOutlinedIcon: {
    width: 14,
    color: colorSet.gray100,
    [theme.breakpoints.down('xs')]: {
      color: colorSet.gray900,
      fontWeight: 500,
      position: 'absolute',
      left: 16,
      top: 14
    }
  },
  searchInputWrapper: {
    boxShadow: 'none',
    backgroundColor: colorSet.gray100,
    borderRadius: 8,
    padding: '6px 15px',
    marginBottom: 16,
    position: 'relative'
  },
  searchExistingInputWrapper: {
    boxShadow: 'none',
    backgroundColor: colorSet.gray100,
    borderRadius: 8,
    padding: '8px 15px',
    marginBottom: 20,
    position: 'relative',
    display: 'flex',
    alignItems: 'center'
  },
  searchIconWrapper: {
    display: 'inline-flex',
    verticalAlign: 'middle'
  },
  searchCloseIconWrapper: {
    display: 'inline-flex',
    verticalAlign: 'middle',
    position: 'absolute',
    right: 16,
    top: 10
  },
  searchInputBase: {
    width: '92%',
    paddingLeft: 10,
    paddingRight: 12
  },
  customSearchIcon: {
    cursor: 'pointer',
    color: colorSet.gray500
  },
  customExistingSearchIcon: {
    width: 20,
    height: 20,
    cursor: 'pointer',
    color: colorSet.gray500
  },
  searchResultWrapper: {
    maxHeight: 324,
    minHeight: 324,
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: 4
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: colorSet.gray600,
      borderRadius: 8
    },
    [theme.breakpoints.down('xs')]: {
      maxHeight: 'calc(100vh - 240px)',
      overflow: 'auto'
    }
  },
  searchGroupResultWrapper: {
    maxHeight: 312,
    minHeight: 312,
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: 4
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: colorSet.gray600,
      borderRadius: 8
    },
    [theme.breakpoints.down('xs')]: {
      maxHeight: 'calc(100vh - 240px)',
      overflow: 'auto'
    }
  },
  searchResult: {
    display: 'flex',
    width: '100%',
    padding: '12px 16px',
    border: `1px solid ${colorSet.gray300}`,
    borderRadius: 12,
    marginTop: 8,
    '&:nth-child(1)': {
      marginTop: 0
    }
  },
  searchGroupResult: {
    display: 'flex',
    width: '100%',
    padding: '11px 16px',
    border: `1px solid ${colorSet.gray300}`,
    borderRadius: 12,
    marginTop: 8,
    '&:nth-child(1)': {
      marginTop: 0
    }
  },
  hoverItem: {
    '&:hover': {
      border: `1px solid ${colorSet.primary450}`
    },
    [theme.breakpoints.down('xs')]: {
      '&:hover': {
        border: `1px solid ${colorSet.gray300}`
      }
    }
  },
  searchResultThumbnailWrapper: {
    width: 44,
    minWidth: 44,
    height: 44,
    border: `1px solid ${colorSet.gray500}`,
    borderRadius: 12,
    marginRight: 16
  },
  image: {
    marginRight: 16,
    [theme.breakpoints.down('xs')]: {
      marginRight: 16,
      userSelect: 'none'
    }
  },
  groupInfo: {
    color: colorSet.gray900,
    display: 'grid',
    alignItems: 'center',
    width: '100%'
  },
  groupName: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '150%',
    letterSpacing: '-0.1px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: {
      fontSize: 15,
      lineHeight: '140%'
    }
  },
  customRadio: {
    marginLeft: 'auto',
    paddingRight: 0,
    color: colorSet.gray400,
    '&.Mui-checked': {
      color: `${colorSet.primary450} !important`
    }
  },
  avatarGroupWrapper: {
    display: 'flex',
    marginLeft: 'auto',
    alignItems: 'center'
  },
  members: {
    fontWeight: 400,
    fontSize: 13,
    lineHeight: '144%',
    letterSpacing: '-0.3px'
  },
  customAvt: {
    width: '36px',
    height: '36px',
    marginLeft: '0 !important',
    marginRight: '6px !important',
    textTransform: 'uppercase',
    border: 'none !important'
  },
  customNotCapaAvt: {
    width: '36px',
    height: '36px',
    marginLeft: '0 !important',
    marginRight: '6px !important',
    background: '#fff',
    textTransform: 'uppercase',
    color: colorSet.gray500,
    border: `1px dashed ${colorSet.gray500}`
  },
  avatarGroup: {
    '& .MuiAvatar-root': {
      fontSize: 16,
      fontWeight: 500
    },
    '& .MuiAvatarGroup-avatar': {
      width: '36px',
      height: '36px',
      marginLeft: '0 !important',
      marginRight: '6px !important',
      border: 'none !important',
      textTransform: 'uppercase'
    }
  },
  shareFilesToBoxWidthMobile: {
    width: '100%'
  },
  shareFilesToBoxWidth: {
    width: 232
  },
  shareToExisting: {
    marginRight: 8
  },
  shareToExistingMobile: {
    marginRight: 0
  },
  noResultText: {
    display: 'flex',
    justifyContent: 'center',
    color: colorSet.gray900,
    marginTop: 30
  },
  disabledItem: {
    pointerEvents: 'none',
    border: `1px solid ${colorSet.gray300}`,
    opacity: 0.3
  },
  activeSearchResult: {
    border: `1px solid ${colorSet.primary450}`
  },
  pointerCursor: {
    cursor: 'pointer'
  }
}));
