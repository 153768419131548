import { createAction } from 'typesafe-actions';
import { DashboardState } from './types';

export const INIT_DASHBOARD_STATE = 'INIT_DASHBOARD_STATE';
export const initDashboardState = createAction(INIT_DASHBOARD_STATE)();

export const UPDATE_DASHBOARD_STATE = 'UPDATE_DASHBOARD_STATE';
export const updateDashboardState = createAction(
  UPDATE_DASHBOARD_STATE
)<DashboardState>();
