import React from 'react';
import { Thumbnail } from '@routes/Common';
import {
  DOCUMENT_TYPES_FILE,
  DRAWING_FILE_TYPES,
  IMAGE_TYPES,
  PDF_TYPE,
  VIDEO_TYPES
} from '@routes/Common/Dropzone/types';
import { convertSubstring } from '@utils/file';
import { IBMFile, IBMFolder, IBMPDF, IBMVideo } from '@assets';
import { IconThumbnailWrapper } from './styles';

type IconThumbnailProps = {
  file: any;
  size: string;
  isInsertFromMyDrive?: boolean;
  onClick?: () => void;
};

const IconThumbnail = (props: IconThumbnailProps) => {
  const { file, size, isInsertFromMyDrive, onClick } = props;
  return (
    <IconThumbnailWrapper onClick={onClick}>
      {!file.isFile ? (
        <img className="folder-icon" src={IBMFolder} alt="IBMFolder" />
      ) : (
        <>
          {convertSubstring(DRAWING_FILE_TYPES).includes(
            file?.originalFileExt?.toLocaleLowerCase()
          ) && (
            <Thumbnail file={file} isInsertFromMyDrive={isInsertFromMyDrive} />
          )}

          {file?.originalFileExt?.toLocaleLowerCase() === PDF_TYPE && (
            <div className={`icon-svg-${size}`}>
              <img src={IBMPDF} alt="IBMPDF" />
            </div>
          )}
          {convertSubstring(IMAGE_TYPES).includes(
            file?.originalFileExt?.toLocaleLowerCase()
          ) && (
            <Thumbnail file={file} isInsertFromMyDrive={isInsertFromMyDrive} />
          )}
          {convertSubstring(DOCUMENT_TYPES_FILE).includes(
            file?.originalFileExt?.toLocaleLowerCase()
          ) && (
            <div className={`icon-svg-${size}`}>
              <img src={IBMFile} alt="IBMFile" />
            </div>
          )}
          {convertSubstring(VIDEO_TYPES).includes(
            file?.originalFileExt?.toLocaleLowerCase()
          ) && (
            <div className={`icon-svg-${size}`}>
              <img src={IBMVideo} alt="IBMVideo" />
            </div>
          )}
        </>
      )}
    </IconThumbnailWrapper>
  );
};

export default IconThumbnail;
