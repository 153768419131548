import i18n from '../../../../../i18n';

export const ADD_FILES_FROM_VALUE = {
  EMPTY: null,
  MY_DEVICE: 1,
  MY_DRIVE: 2
};

export const ADD_FILES_FROM_OPTIONS = [
  {
    value: ADD_FILES_FROM_VALUE.MY_DEVICE,
    name: i18n.t('upload_files_modal.my_device')
  },
  {
    value: ADD_FILES_FROM_VALUE.MY_DRIVE,
    name: i18n.t('upload_files_modal.my_drive_mobile')
  }
];
