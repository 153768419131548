import React, { useEffect } from 'react';
import { CapaConnectMyFile, CapaErrorCode } from '@generated/Common/graphql';
import { CapaActivityType } from '@generated/Common/types';
import useFiles from '@hooks/useFiles';
import useAuth from '@hooks/useAuth';
import { useSnackbar } from '@components/Provider';
import Loading from '@components/Loading';
import DashboardMyDrivePresenter from './DashboardMyDrivePresenter';
import { useTranslation } from 'react-i18next';
import useMyDrive from '@hooks/Drive/MyDrive/useMyDrive';
import useUserActionLog, {
  Enum_Action_Page_Enum
} from '@hooks/useUserActionLog';
import WelcomeContainer from '@routes/Common/Dialog/Welcome/WelcomeContainer';
import { isMobile } from 'react-device-detect';

const DashboardMyDriveContainer: React.FC = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { onDownloadConnectFile, onRemoveConnectFile } = useFiles();
  const { onRefetchConnectUser } = useAuth();
  const { myDrive, onSetMyDrivePageLoading, onSetShowHideFileDetail } =
    useMyDrive();
  const { onInsertUserActionLogOne } = useUserActionLog();

  const onDownloadFiles = async (files?: Array<CapaConnectMyFile>) => {
    onSetMyDrivePageLoading({
      isMyDrivePageLoading: true
    });
    if (!files || !files.length) return;
    const newFiles = Array.from(new Set(files));
    if (newFiles.length === 1) {
      onDownloadConnectFile(files[0])
        .then(() => {
          onSetMyDrivePageLoading({
            isMyDrivePageLoading: false
          });
          enqueueSnackbar(
            files.length > 1
              ? t('snackbar.download_files_complete')
              : t('snackbar.download_file_complete', {
                  fileName: files[0].fileName
                }),
            {
              variant: 'success'
            }
          );
        })
        .catch(() => {
          onSetMyDrivePageLoading({
            isMyDrivePageLoading: false
          });
          enqueueSnackbar(t('snackbar.download_file_complete_error'), {
            variant: 'error'
          });
        });
    } else {
      newFiles.forEach((file) => {
        onDownloadConnectFile(file)
          .then(() => {
            onSetMyDrivePageLoading({
              isMyDrivePageLoading: false
            });
          })
          .catch(() => {
            onSetMyDrivePageLoading({
              isMyDrivePageLoading: false
            });
            enqueueSnackbar(t('snackbar.download_file_complete_error'), {
              variant: 'error'
            });
          });
      });
      enqueueSnackbar(t('snackbar.download_files_complete'), {
        variant: 'success'
      });
    }
  };

  const onDeleteFiles = async (files?: Array<CapaConnectMyFile>) => {
    if (files) {
      onSetMyDrivePageLoading({
        isMyDrivePageLoading: true
      });
      const resp = await onRemoveConnectFile(files);
      onSetMyDrivePageLoading({
        isMyDrivePageLoading: false
      });
      if (
        resp &&
        resp.data?.removeConnectFile.result === CapaErrorCode.Success
      ) {
        if (files.every((file: any) => file.isFile) && files.length > 1) {
          enqueueSnackbar(t('snackbar.delete_files_complete'), {
            variant: 'success'
          });
        }

        if (files.every((file: any) => file.isFile) && files.length === 1) {
          enqueueSnackbar(
            t('snackbar.delete_file', {
              fileName: files[0].fileName
            }),
            {
              variant: 'success'
            }
          );
        }

        if (files.every((file: any) => !file.isFile) && files.length === 1) {
          enqueueSnackbar(t('snackbar.delete_folder'), {
            variant: 'success'
          });
        }

        if (files.every((file: any) => !file.isFile) && files.length > 1) {
          enqueueSnackbar(t('snackbar.delete_multiple_folders'), {
            variant: 'success'
          });
        }

        await onRefetchConnectUser();
      } else {
        enqueueSnackbar(t('snackbar.delete_file_complete_error'), {
          variant: 'error'
        });
      }
    }
  };

  useEffect(() => {
    onInsertUserActionLogOne(
      Enum_Action_Page_Enum.Page_Mydrive,
      CapaActivityType.PageVisit
    );
    if (!isMobile)
      onSetShowHideFileDetail({
        detailDrive: ''
      });
  }, []);

  return (
    <React.Fragment>
      <Loading open={myDrive.isMyDrivePageLoading!} />
      <DashboardMyDrivePresenter
        onDownloadFiles={onDownloadFiles}
        onDeleteFiles={onDeleteFiles}
      />
      <WelcomeContainer />
    </React.Fragment>
  );
};

export default DashboardMyDriveContainer;
