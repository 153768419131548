import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useStyles } from './styles';
import {
  groupRoomPathName,
  PERMISSION_DOWNLOAD,
  SHARED_WITH_GROUP_FILE_CONTROL_OPTIONS,
  SHARED_WITH_GROUP_FILE_CONTROL_VALUE,
  SHARED_WITH_GROUP_FILE_CONTROL_ALLOW,
  SHARED_WITH_GROUP_FILE_CONTROL_NOT_OWNER_NOT_ALLOW,
  SHARED_WITH_GROUP_FILE_CONTROL_ONLY_GO_TO_GROUP,
  SHARED_WITH_GROUP_FILE_CONTROL_ALLOW_WITH_OUT_OWNER
} from '@routes/Common/types';
import { useSnackbar } from '@components/Provider';
import { CapaConnectMyFile, CapaErrorCode } from '@generated/Common/graphql';
import { CapaActivityType } from '@generated/Common/types';
import useMyDrive from '@hooks/Drive/MyDrive/useMyDrive';
import useFiles from '@hooks/useFiles';
import useAuth from '@hooks/useAuth';
import * as Apollo from '@apollo/client';
import {
  RequestDownloadPermission,
  UnshareFileFromGroup
} from '@generated/Drive/MyDrive/graphql';
import useSharedWithGroups from '@hooks/Drive/MyDrive/useSharedWithGroups';
import { useTranslation } from 'react-i18next';
import useDashboard from '@hooks/useDashboard';
import useUserActionLog, {
  Enum_Action_Button_Enum
} from '@hooks/useUserActionLog';
import { Tooltip } from '@material-ui/core';

interface Props {
  isShowPopUp: boolean;
  showIcon?: boolean;
  onClosePoup?: () => void;
  onClose?: () => void;
  refetchGroup?: () => void;
}

const ControlButtonList: React.FC<Props> = ({
  isShowPopUp,
  showIcon,
  refetchGroup
}) => {
  const history = useHistory();
  const { pathname } = useLocation<any>();
  const classes = useStyles();
  const { userState } = useAuth();
  const userEmail = userState?.data?.email;
  const hasGroupIdPath = pathname.split('/').length > 2;
  const { enqueueSnackbar } = useSnackbar();
  const { onDownloadConnectFile, onDownloadZipConnectFile } = useFiles();
  const [unshareFileFromGroup] = Apollo.useMutation(UnshareFileFromGroup);
  const [listGroupOption, setListGroupOption] = useState(
    SHARED_WITH_GROUP_FILE_CONTROL_ALLOW
  );
  const { sharedWithGroups, onFetchGroupData, groupDataState } =
    useSharedWithGroups();
  const { onSetShowHideDownloadPermissionDialog, onSetSelectedGroupShared } =
    useMyDrive();
  const { t } = useTranslation();
  const [requestDownloadPermission] = Apollo.useMutation(
    RequestDownloadPermission
  );
  const { onUpdateDashboard } = useDashboard();
  const { onSetShowHideFileDetail } = useMyDrive();
  const { onInsertUserActionLogOne } = useUserActionLog();

  useEffect(() => {
    if (hasGroupIdPath) {
      onFetchGroupData({
        variables: {
          groupId: pathname.split('/')[2]
        }
      });
    }
  }, [hasGroupIdPath]);

  const onClickControlButton = (value: any) => {
    switch (value) {
      case SHARED_WITH_GROUP_FILE_CONTROL_VALUE.REMOVE: {
        handleRemoveFile();
        break;
      }
      case SHARED_WITH_GROUP_FILE_CONTROL_VALUE.DOWNLOAD: {
        if (showIcon) {
          onInsertUserActionLogOne(
            Enum_Action_Button_Enum.Button_SharedDrive_GroupFolder_File_DropMenu_Download,
            CapaActivityType.ButtonClick
          );
        } else {
          onInsertUserActionLogOne(
            Enum_Action_Button_Enum.Button_SharedDrive_GroupFolder_File_Download,
            CapaActivityType.ButtonClick
          );
        }
        onDownloadFiles(sharedWithGroups.selectedGroupFiles);
        break;
      }
      case SHARED_WITH_GROUP_FILE_CONTROL_VALUE.GO_TO_GROUP: {
        if (showIcon) {
          onInsertUserActionLogOne(
            Enum_Action_Button_Enum.Button_SharedDrive_GroupFolder_File_DropMenu_GoToGroup,
            CapaActivityType.ButtonClick
          );
        } else {
          onInsertUserActionLogOne(
            Enum_Action_Button_Enum.Button_SharedDrive_GroupFolder_File_GoToGroup,
            CapaActivityType.ButtonClick
          );
        }
        onUpdateDashboard({ files: [sharedWithGroups.selectedGroupFiles[0]] });
        onSetShowHideFileDetail({
          detailDrive: sharedWithGroups.selectedGroupFiles[0]
        });
        history.push({
          pathname: `${groupRoomPathName}`,
          search: `?chatroomId=${groupDataState?.data?.chatRoomId}&groupId=${groupDataState?.data?.id}`,
          state: { prevLocation: window.location.href }
        });
        break;
      }
      case SHARED_WITH_GROUP_FILE_CONTROL_VALUE.REQUEST_DOWNLOAD_ACCESS: {
        requestDownloadPermission({
          variables: {
            groupId: groupDataState?.data?.id,
            connectFileId: sharedWithGroups.selectedGroupFiles[0].id
          }
        }).then((res: any) => {
          if (
            res.data.requestDownloadPermission.result === CapaErrorCode.Success
          ) {
            onFetchGroupData({
              variables: {
                groupId: groupDataState?.data?.id
              }
            });

            enqueueSnackbar(t('snackbar.you_sent_a_request_for_download'), {
              variant: 'success'
            });
          }
        });
        break;
      }

      case SHARED_WITH_GROUP_FILE_CONTROL_VALUE.DOWNLOAD_PERMISSION: {
        const payload = {
          groupId: groupDataState?.data?.id,
          groupName: groupDataState?.data?.groupName,
          connectFileId: sharedWithGroups.selectedGroupFiles[0].id
        };
        onSetSelectedGroupShared({ selectedGroupShared: payload });
        onSetShowHideDownloadPermissionDialog({
          isShowHideDownloadPermissionDialog: true
        });
      }
    }
  };

  const onDownloadFiles = async (files: any) => {
    if (files) {
      if (files.length === 1 && files[0].isFile) {
        onDownloadConnectFile(files[0])
          .then(() => {
            enqueueSnackbar(
              `${t('confirm_dialog.download.success_text', {
                fileName: files[0].fileName
              })}`,
              {
                variant: 'success'
              }
            );
          })
          .catch(() => {
            enqueueSnackbar(`${t('confirm_dialog.download.error_text')}`, {
              variant: 'error'
            });
          });
      } else {
        onDownloadZipConnectFile(files)
          .then(() => {
            enqueueSnackbar(
              files.length > 1
                ? t('snackbar.download_files_complete')
                : t('snackbar.download_file_complete', {
                    fileName: files[0].fileName
                  }),
              {
                variant: 'success'
              }
            );
          })
          .catch(() => {
            enqueueSnackbar(t('snackbar.download_file_complete_error'), {
              variant: 'error'
            });
          });
      }
    }
  };

  const handleRemoveFile = () => {
    const selectedFileIds = sharedWithGroups?.selectedGroupFiles?.map(
      (item: CapaConnectMyFile) => item.id
    );

    unshareFileFromGroup({
      variables: {
        groupId: groupDataState?.data?.id,
        connectFileIds: selectedFileIds
      }
    }).then((res) => {
      const result = res?.data?.unshareFileFromGroup?.result;
      if (result === CapaErrorCode.Success) {
        if (refetchGroup) {
          refetchGroup();
        }
        enqueueSnackbar(
          sharedWithGroups?.selectedGroupFiles.length > 1
            ? t('snackbar.delete_files_complete')
            : t('snackbar.delete_file_complete'),
          {
            variant: 'success'
          }
        );
      }
    });
  };

  const isFileOwner = (file: any) => {
    return file.email === userEmail;
  };

  useEffect(() => {
    if (sharedWithGroups.selectedGroupFiles.length === 1)
      handleRenderOptionSingleFile();
    else handleRenderOptionMultiFile();
  }, [sharedWithGroups, isShowPopUp]);

  const handleRenderOptionSingleFile = () => {
    if (
      sharedWithGroups.selectedGroupFiles[0]?.permission ===
        PERMISSION_DOWNLOAD.ALLOW &&
      isFileOwner(sharedWithGroups.selectedGroupFiles[0])
    ) {
      return setListGroupOption(SHARED_WITH_GROUP_FILE_CONTROL_OPTIONS);
    }

    if (
      sharedWithGroups.selectedGroupFiles[0]?.permission ===
        PERMISSION_DOWNLOAD.ALLOW &&
      !isFileOwner(sharedWithGroups.selectedGroupFiles[0])
    ) {
      return setListGroupOption(
        SHARED_WITH_GROUP_FILE_CONTROL_ALLOW_WITH_OUT_OWNER
      );
    }

    if (isFileOwner(sharedWithGroups.selectedGroupFiles[0])) {
      return setListGroupOption(SHARED_WITH_GROUP_FILE_CONTROL_ALLOW);
    }

    if (
      sharedWithGroups.selectedGroupFiles[0]?.permission ===
        PERMISSION_DOWNLOAD.DENIED ||
      PERMISSION_DOWNLOAD.REQUEST
    ) {
      return setListGroupOption(
        SHARED_WITH_GROUP_FILE_CONTROL_NOT_OWNER_NOT_ALLOW
      );
    }

    return setListGroupOption(
      SHARED_WITH_GROUP_FILE_CONTROL_ALLOW_WITH_OUT_OWNER
    );
  };

  const handleRenderOptionMultiFile = () => {
    if (
      sharedWithGroups.selectedGroupFiles.every(
        (file: any) => file?.permission === PERMISSION_DOWNLOAD.ALLOW
      )
    ) {
      return setListGroupOption(SHARED_WITH_GROUP_FILE_CONTROL_ALLOW);
    }

    if (
      !sharedWithGroups.selectedGroupFiles.every(
        (file: any) => file?.permission === PERMISSION_DOWNLOAD.ALLOW
      )
    ) {
      return setListGroupOption(
        SHARED_WITH_GROUP_FILE_CONTROL_ONLY_GO_TO_GROUP
      );
    }

    if (
      sharedWithGroups.selectedGroupFiles[0]?.permission ===
        PERMISSION_DOWNLOAD.DENIED ||
      PERMISSION_DOWNLOAD.REQUEST
    ) {
      return setListGroupOption(
        SHARED_WITH_GROUP_FILE_CONTROL_NOT_OWNER_NOT_ALLOW
      );
    }
  };

  return (
    <>
      {isShowPopUp ? (
        <ul className="popup">
          {listGroupOption.map((option, index) => {
            return (
              <li
                key={index}
                onClick={() => onClickControlButton(option.value)}
              >
                {showIcon ? <option.icon className="icon" /> : null}
                {t(option.name)}
              </li>
            );
          })}
        </ul>
      ) : (
        <div className={classes.controlButtonList}>
          {listGroupOption.map((option) => {
            return (
              <Tooltip title={t(option.tooltip) || ''}>
                <option.icon
                  className={classes.capaIcon}
                  onClick={() => onClickControlButton(option.value)}
                />
              </Tooltip>
            );
          })}
        </div>
      )}
    </>
  );
};

export default ControlButtonList;
