import { useCallback, useMemo } from 'react';
import {
  GroupState,
  setCreateGroupScreen,
  setSelectedGroup
} from '@modules/Group';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@modules/index';
import * as Apollo from '@apollo/client';
import {
  FetchConnectGroupChatRooms,
  SearchGroups,
  useFetchGroupsDetailLazyQuery,
  useFetchGroupsQuery
} from '@generated/Drive/MyDrive/graphql';
import { CapaErrorCode } from '@generated/Common/graphql';

export default function useGroup(isHaveNotLoginFilesPage?: boolean) {
  const { group } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  const {
    data: fetchGroupsData,
    loading: fetchGroupsLoading,
    refetch: refetchGroupsData,
    fetchMore: onFetchMore
  } = useFetchGroupsQuery({
    skip: isHaveNotLoginFilesPage
  });

  const [
    fetchGroupDetail,
    { loading: fetchGroupsDetailLoading, data: fetchGroupsDetailData }
  ] = useFetchGroupsDetailLazyQuery({
    fetchPolicy: 'no-cache'
  });

  const [
    fetchConnectGroupChatRooms,
    {
      data: connectGroupChatRooms,
      loading: connectGroupChatRoomsLoading,
      fetchMore: onFetchMoreConnectGroupChatRooms
    }
  ] = Apollo.useLazyQuery(FetchConnectGroupChatRooms, {
    fetchPolicy: 'no-cache'
  });

  const [
    searchGroups,
    {
      data: connectSearchGroup,
      loading: connectSearchGroupLoading,
      fetchMore: onFetchMoreSearchGroup
    }
  ] = Apollo.useLazyQuery(SearchGroups, {
    fetchPolicy: 'no-cache'
  });

  const onSetCreateGroupScreen = (params: GroupState) =>
    dispatch(setCreateGroupScreen(params));

  const onSetSelectedGroup = (params: GroupState) =>
    dispatch(setSelectedGroup(params));

  const onFetchConnectGroupChatRooms = useCallback(fetchConnectGroupChatRooms, [
    fetchConnectGroupChatRooms
  ]);

  const onSearchGroups = useCallback(searchGroups, [searchGroups]);

  const onFetchGroupDetailData = useCallback(fetchGroupDetail, [
    fetchGroupDetail
  ]);

  const onRefetchGroupsData = useCallback(refetchGroupsData, [
    refetchGroupsData
  ]);

  const groupsDetailState = useMemo(() => {
    if (
      fetchGroupsDetailData &&
      fetchGroupsDetailData?.fetchConnectFileDetail
    ) {
      return {
        data: fetchGroupsDetailData?.fetchConnectFileDetail.data ?? undefined,
        isLoading: fetchGroupsDetailLoading
      };
    }
    return {
      data: undefined,
      isLoading: fetchGroupsDetailLoading
    };
  }, [fetchGroupsDetailData]);

  const groupsState = useMemo(() => {
    if (
      fetchGroupsData &&
      fetchGroupsData?.fetchGroups &&
      fetchGroupsData?.fetchGroups.result === CapaErrorCode.Success
    ) {
      return {
        data:
          fetchGroupsData?.fetchGroups?.data!.sort((a: any, b: any) => {
            const dateA: any = new Date(a.createdAt);
            const dateB: any = new Date(b.createdAt);
            return dateB - dateA;
          }) ?? undefined,
        isLoading: fetchGroupsLoading
      };
    }

    return {
      data: undefined,
      isLoading: fetchGroupsLoading
    };
  }, [fetchGroupsData, fetchGroupsLoading]);

  return {
    fetchGroupsData,
    group,
    onSetCreateGroupScreen,
    onSetSelectedGroup,
    onFetchConnectGroupChatRooms,
    onFetchMoreConnectGroupChatRooms,
    connectGroupChatRooms,
    connectGroupChatRoomsLoading,
    onSearchGroups,
    connectSearchGroup,
    connectSearchGroupLoading,
    onFetchMoreSearchGroup,
    groupsDetailState,
    fetchGroupsDetailLoading,
    onFetchGroupDetailData,
    groupsState,
    onRefetchGroupsData,
    onFetchMore
  };
}
