import { colorSet } from '@components/Provider';
import {
  drawerWidth,
  contentHeaderHeight,
  contentMobileHeaderHeight
} from '../types';
import { makeStyles, createStyles, createMuiTheme } from '@material-ui/core';

export const groupListTheme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1095,
      xl: 1536
    }
  }
});

export const useStylesLayout = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex'
    },
    main: {
      flexGrow: 1
    },
    loading: {
      position: 'relative',
      paddingTop: 20
    },
    nonSelect: {
      fontWeight: 400,
      fontSize: 14,
      textAlign: 'center',
      lineHeight: '20px',
      width: '100%',
      height: 'calc(100vh - 177px)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: '300px',
      color: colorSet.gray700
    },
    contentHeader: {
      height: contentHeaderHeight,
      [theme.breakpoints.down('xs')]: {
        height: contentMobileHeaderHeight
      }
    },
    content: {
      backgroundColor: colorSet.gray000,
      overflow: 'hidden',
      [theme.breakpoints.down('xs')]: {
        padding: 0
      },
      '& .header': {
        display: 'flex',
        height: 56,
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 8px',
        margin: '0 8px',
        borderBottom: `1px solid ${colorSet.gray300}`,
        '& .cursor-pointer': {
          cursor: 'pointer'
        },
        '& .MuiBreadcrumbs-li': {
          fontWeight: 400,
          fontSize: 20,
          lineHeight: '160%',
          letterSpacing: '-0.1px',
          color: colorSet.gray900,
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          maxWidth: 300,
          whiteSpace: 'nowrap',
          '& .MuiLink-underlineHover': {
            padding: '0 8px',
            borderRadius: 8,
            display: 'flex',
            alignItems: 'center',
            '&:hover': {
              textDecoration: 'none !important',
              boxShadow: 'inset 999px 999px 0px rgba(0, 0, 0, 0.08)'
            }
          },
          '&:last-child': {
            fontWeight: 700,
            cursor: 'auto'
          }
        },
        '& .MuiBreadcrumbs-separator': {
          marginLeft: 0,
          marginRight: 0
        },
        '& .btn-open-detail': {
          padding: 6,
          '&.active svg path': {
            fill: colorSet.primary450
          }
        },
        '& .line': {
          borderRight: `1px solid ${colorSet.gray300}`,
          marginRight: 8,
          height: 20
        }
      }
    },
    contentShift: {
      marginLeft: drawerWidth,
      maxWidth: `calc(100% - ${drawerWidth}px)`,
      [theme.breakpoints.down('xs')]: {
        maxWidth: '100%',
        padding: 16
      }
    },
    customNav: {
      marginLeft: '0 !important',
      maxWidth: '100%',
      [groupListTheme.breakpoints.down('md')]: {
        maxWidth: 'calc(100% - 248px)',
        marginLeft: '248px !important'
      },
      [theme.breakpoints.down('xs')]: {
        marginLeft: '0 !important',
        maxWidth: '100%'
      }
    },
    mainContent: {
      display: 'flex',
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        display: 'block'
      }
    },
    mainContentLeft: {
      flex: '1 0 auto',
      width: '100%',
      '& .sticky-header': {
        position: 'sticky',
        top: 61,
        background: `${colorSet.gray000}`,
        zIndex: 999
      },
      '& .recommended-content': {
        [theme.breakpoints.down('xs')]: {
          marginTop: 16,
          height: 'calc(100vh - 188px)',
          overflow: 'auto'
        }
      },
      [theme.breakpoints.down('xs')]: {
        flex: 'auto'
      },
      [theme.breakpoints.up('md')]: {
        width: '50%'
      }
    },
    mainContentRight: {
      border: `1px solid ${colorSet.gray300}`,
      borderTop: 0,
      width: 0,
      height: 'calc(100vh - 117px)',
      overflowY: 'auto',
      overflowX: 'hidden',
      transition: '300ms',
      position: 'relative',
      [theme.breakpoints.down('xs')]: {
        position: 'fixed',
        right: 0,
        overflow: 'hidden',
        zIndex: 1200,
        height: '100%',
        background: `${colorSet.gray000}`,
        top: 0
      },
      '&.active': {
        width: 328,
        [theme.breakpoints.down('xs')]: {
          width: '100%'
        }
      },
      '&::-webkit-scrollbar': {
        width: 4
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: colorSet.gray600,
        borderRadius: 8
      }
    },
    contentGroup: {
      minWidth: 328
    },
    mainContentRightNodata: {
      width: '100%',
      height: '100%'
    },
    manageLink: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '20px 16px 0',
      cursor: 'pointer',
      '&:hover': {
        '& .title': {
          textDecoration: 'underline'
        },
        '& .icon-link': {
          background: colorSet.primary600
        }
      },
      '& .icon-link': {
        padding: '7px 8px 8px 8px',
        background: colorSet.primary450,
        borderRadius: 8,
        display: 'block',
        marginRight: 12
      },
      '& .content': {
        display: 'flex',
        alignItems: 'center'
      },

      '& .title': {
        fontWeight: 500,
        fontSize: 14,
        lineHeight: '20px',
        letterSpacing: '-0.1px',
        color: colorSet.gray900
      },
      '& .description': {
        fontWeight: 400,
        fontSize: 13,
        lineHeight: '18px',
        letterSpacing: '-0.1px',
        color: colorSet.gray700
      },
      '& .icon-right': {}
    },
    mainContentRightNodataImage: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      height: '100%'
    },
    mainContentRightNodataText: {
      color: `${colorSet.gray500}`,
      fontWeight: 500,
      fontSize: 16,
      textAlign: 'center',
      padding: '20px 50px',
      lineHeight: '1.6'
    },
    mainContentRightItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '19.5px 16px 19.5px',
      position: 'sticky',
      zIndex: 2,
      borderTop: 'none',
      top: 0,
      width: 328,
      height: 59,
      background: `${colorSet.gray000}`,
      [theme.breakpoints.down('xs')]: {
        position: 'inherit !important',
        width: 'auto',
        padding: '16px 10px 16px',
        height: 64
      },
      '& .file-icon svg': {
        marginRight: 12,
        flexShrink: 0,
        [theme.breakpoints.down('xs')]: {
          marginLeft: 12
        }
      },
      '& .file-icon img': {
        marginRight: 12,
        flexShrink: 0,
        [theme.breakpoints.down('xs')]: {
          marginLeft: 12
        }
      },
      '& .weird': {
        marginRight: 12,
        flexShrink: 0,
        [theme.breakpoints.down('xs')]: {
          marginLeft: 12
        }
      }
    },
    mainContentRightTitle: {
      color: `${colorSet.gray900}`,
      fontWeight: 500,
      fontSize: 18,
      lineHeight: '25px',
      letterSpacing: '-0.1px',
      borderTop: 'none',
      borderLeft: 'none',
      width: 'calc(100% - 32px)',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      [theme.breakpoints.down('xs')]: {
        display: 'block',
        fontSize: 16,
        lineHeight: '24px'
      }
    },
    mainContentRightTitleIconLeft: {
      cursor: 'pointer',
      display: 'none',
      [theme.breakpoints.down('xs')]: {
        display: 'block',
        padding: 6
      }
    },
    mainContentRightTitleIcon: {
      cursor: 'pointer',
      display: 'block',
      padding: 6,
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      }
    },
    mainContentRightImage: {
      height: 260,
      padding: '0 0',
      overflow: 'hidden',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& .folder-icon': {
        color: `${colorSet.gray600}`,
        width: 88
      },
      [theme.breakpoints.down('xs')]: {
        border: `1px solid ${colorSet.gray200}`,
        marginTop: 0,
        borderTop: 'none'
      },
      '& .weird': {
        width: 88,
        height: 88
      }
    },
    mainContentRightImg: {
      height: '100%',
      objectFit: 'contain',
      width: '100%'
    },
    mainContentRightImgPDF: {
      height: 'auto',
      width: '88px',
      maxWidth: 'calc( 100% - 20px)'
    },
    mainContentRightImgDrawing: {
      height: 'auto'
    },
    rootMobile: {
      [theme.breakpoints.down('xs')]: {
        zIndex: 1300,
        position: 'fixed',
        inset: 0
      }
    },
    mainContentRightGroup: {
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        borderTop: 'none'
      }
    },
    mainContentRightGroupList: {
      paddingTop: 12,
      paddingBottom: 12,
      paddingLeft: 16,
      paddingRight: 16,
      '&:last-child': {
        borderBottom: `none`
      },
      [theme.breakpoints.down('xs')]: {
        marginBottom: 0
      }
    },
    cursorPointer: {
      cursor: 'pointer'
    },
    noDrop: {
      cursor: 'no-drop'
    },
    mainContentRightGroupTitle: {
      color: `${colorSet.gray900}`,
      fontSize: 14,
      fontWeight: 'normal',
      padding: '20px 16px 0'
    },
    mainContentRightGroupListItem: {
      overflow: 'auto',
      minHeight: 'calc( 100vh - 544px)',
      '&.is-share': {
        minHeight: 'calc( 100vh - 602px)'
      },
      '&::-webkit-scrollbar': {
        width: 4
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: colorSet.gray600,
        borderRadius: 8
      },
      [theme.breakpoints.down('xs')]: {
        height: 350
      }
    },
    mainContentRightGroupSubTile: {
      color: `${colorSet.gray600}`,
      fontSize: 13,
      fontWeight: 400,
      lineHeight: '17px',
      marginTop: 8
    },
    mainContentRightGroupName: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& .MuiButtonBase-root': {
        padding: 6,
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.08)'
        }
      }
    },
    mainContentRightGroupNameTile: {
      color: `${colorSet.gray900}`,
      fontSize: 16,
      lineHeight: '24px',
      flex: '1 0 auto',
      marginBottom: 8,
      fontWeight: 'bold',
      cursor: 'pointer'
    },
    mainContentRightGroupImage: {
      marginTop: 0,
      width: '100%',
      display: 'flex',
      cursor: 'pointer',
      alignItems: 'center'
    },
    customAvt: {
      cursor: 'pointer',
      width: 32,
      height: 32,
      marginLeft: '0 !important',
      marginRight: '6px !important',
      textTransform: 'uppercase',
      border: 'none !important',
      fontSize: 16
    },
    customNotCapaAvt: {
      cursor: 'pointer',
      width: '36px',
      height: '36px',
      marginLeft: '0 !important',
      marginRight: '6px !important',
      background: '#fff',
      textTransform: 'uppercase',
      color: colorSet.gray500,
      border: `1px dashed ${colorSet.gray500}`,
      fontSize: 16
    },
    mainContentRightGroupHover: {
      position: 'relative',
      marginRight: 6,
      '&:last-child': {
        marginRight: 0
      },
      '& .custom-hover': {
        display: 'none'
      },
      '&:hover': {
        '& .custom-hover': {
          width: 216,
          display: 'block',
          position: 'absolute',
          bottom: -53,
          left: 0,
          background: `${colorSet.gray900}`,
          borderRadius: 8,
          padding: 8,
          color: `${colorSet.gray000}`,
          fontSize: 14,
          lineHeight: 1.2
        }
      }
    },
    customAvatar: {
      width: 32,
      height: 32,
      border: `2px solid ${colorSet.gray000}`,
      marginLeft: '0 !important',
      marginRight: '6px !important',
      textTransform: 'uppercase',
      fontSize: 12,
      backgroundColor: colorSet.gray700
    },
    mainContentRightGroupImg: {
      marginRight: 8
    },
    csDialogContentPopup: {
      width: 260,
      padding: '15px 12px',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        background: `${colorSet.gray000}`,
        position: 'fixed',
        left: 0,
        bottom: 0,
        borderRadius: '8px 8px 0 0'
      },
      '.MuiPopover-root': {
        background: `${colorSet.backgroundDrop}`
      },
      '& .MuiAvatar-root': {
        marginRight: 10
      },
      '& .MuiTypography-body1': {
        marginBottom: 15,
        display: 'flex',
        flexDirection: 'row',
        cursor: 'pointer',
        color: `${colorSet.gray900}`,
        fontSize: 16,
        lineHeight: '150%',
        '&:last-child': {
          marginBottom: 0
        }
      },
      '& .spacing': {
        marginLeft: 24
      },
      '& .name': {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        marginLeft: 6,
        '& p': {
          color: `${colorSet.gray700}`
        }
      },
      '& .email': {
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }
    },
    customPopover: {
      '& .MuiPopover-paper': {
        boxShadow:
          '0px 5px 5px -3px rgba(0, 0, 0, 0.1), 0px 8px 10px 1px rgba(0, 0, 0, 0.07), 0px 3px 14px 2px rgba(0, 0, 0, 0.06)'
      },
      [theme.breakpoints.down('xs')]: {
        background: `${colorSet.backgroundDrop}`,
        '& .MuiPopover-paper': {
          boxShadow: 'none',
          background: 'transparent',
          minHeight: 'auto'
        }
      }
    },
    csDialogContentPopupMore: {
      display: 'flex',
      alignItems: 'center',
      padding: '10px 13px',
      '& .image': {
        marginRight: 10
      },
      '& .text': {
        fontSize: 16,
        color: `${colorSet.gray900}`,
        cursor: 'pointer'
      }
    },
    shareButtonWrapper: {
      position: 'sticky',
      bottom: 0,
      borderTop: `1px solid ${colorSet.gray300}`,
      width: 327,
      backgroundColor: colorSet.gray000,
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      },
      '& .share-button': {
        border: `1px solid ${colorSet.primary450}`,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 8,
        cursor: 'pointer',
        '& svg path': {
          fill: `${colorSet.primary450}`
        },
        '&:hover': {
          boxShadow: 'inset 999px 999px 0px rgba(75, 136, 255, 0.08)'
        }
      },
      '&.is-folder': {
        color: colorSet.gray500,
        '& .share-button': {
          border: `1px solid ${colorSet.gray500}`
        },
        '& .share-text': {
          color: colorSet.gray500
        },
        '& .share-button:hover': {
          boxShadow: 'none'
        },
        '& .share-button svg path': {
          fill: colorSet.gray500
        }
      },
      '& .share-text': {
        color: colorSet.primary450,
        fontWeight: 500,
        fontSize: 16,
        lineHeight: '150%',
        letterSpacing: '-0.1px',
        marginLeft: 8
      }
    },
    shareBlock: {
      height: 72,
      width: '100%',
      padding: 16
    }
  })
);
