import 'core-js';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'proxy-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store, persistor } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import { ApolloProvider } from '@apollo/react-hooks';
import client from './apollo';
import App from './components/App';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import config from './config';

Sentry.init({
  dsn: 'https://6e5e1d3630c74ab49193e54a941db767@o1141124.ingest.sentry.io/4504160439894016',
  environment: config.mode,
  integrations: [new BrowserTracing()],
  enabled:
    config.mode === 'production' && window.location.hostname !== 'localhost',
  attachStacktrace: true,
  normalizeDepth: 10,
  tracesSampleRate: 1.0
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </ApolloProvider>,
  document.getElementById('root')
);
