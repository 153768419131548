import {
  CapaErrorCode,
  CapaFile,
  CapaServiceType
} from '@generated/Common/graphql';

export type Maybe<T> = T | null;

export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};

export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };

export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };

export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type ApproveItpEventInput = {
  itpEventId: Scalars['String'];
};

export type ApprovedQuotationPartnerByEmailInput = {
  email: Scalars['String'];
  sendEmail: Scalars['Boolean'];
};

export type Authentication = {
  __typename?: 'Authentication';
  accessToken: Scalars['String'];
  tokenType: Scalars['String'];
  expiresIn: Scalars['Float'];
  refreshToken: Scalars['String'];
  redirectUrl?: Maybe<Scalars['String']>;
};

export type AuthenticationInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  service: CapaServiceType;
};

export type AuthenticationOutput = {
  __typename?: 'AuthenticationOutput';
  result: CapaErrorCode;
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<Authentication>;
};

export enum CapaActivityType {
  PageVisit = 'PAGE_VISIT',
  ButtonClick = 'BUTTON_CLICK',
  LinkClick = 'LINK_CLICK',
  actionInput = 'ACTION_INPUT'
}

export type CapaConnectUser = {
  __typename?: 'CAPAConnectUser';
  id: Scalars['ID'];
  email: Scalars['String'];
  usedSize: Scalars['Float'];
  availSize: Scalars['Float'];
  driveSize: Scalars['Float'];
  serviceAgreed?: Maybe<Scalars['Boolean']>;
  privacyAgreed?: Maybe<Scalars['Boolean']>;
  promotionAgreed?: Maybe<Scalars['Boolean']>;
  userName: Scalars['String'];
  chatRoomUserId: Scalars['String'];
  notificationFilter: CAPAConnectUserNotificationFilter;
};

export type CAPAConnectUserNotificationFilter = {
  __typename?: 'CAPAConnectUserNotificationFilter';
  chatPush: Scalars['Boolean'];
  groupPush: Scalars['Boolean'];
  groupEmail: Scalars['Boolean'];
  commentThreadPush: Scalars['Boolean'];
  commentThreadEmail: Scalars['Boolean'];
  filePush: Scalars['Boolean'];
  fileEmail: Scalars['Boolean'];
};

export type CapaConnectUserBo = {
  __typename?: 'CAPAConnectUserBO';
  email: Scalars['String'];
  usedSize: Scalars['Float'];
  driveSize: Scalars['Float'];
  createdAt: Scalars['String'];
};

export type CapaConnectUserOutput = {
  __typename?: 'CAPAConnectUserOutput';
  result: CapaErrorCode;
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<CapaConnectUser>;
};

export type CapaConnectUsersBoOutput = {
  __typename?: 'CAPAConnectUsersBOOutput';
  result: CapaErrorCode;
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<Array<CapaConnectUserBo>>;
};

export type CapaItpEventBo = {
  __typename?: 'CAPAItpEventBO';
  id?: Maybe<Scalars['ID']>;
  projectName?: Maybe<Scalars['String']>;
  quotationUserId?: Maybe<Scalars['String']>;
  quotationUserName?: Maybe<Scalars['String']>;
  quotationPartnerOrganizationName?: Maybe<Scalars['String']>;
  completeCost?: Maybe<Scalars['String']>;
  files?: Maybe<Array<CapaFile>>;
  email?: Maybe<Scalars['String']>;
  cellphoneNumber?: Maybe<Scalars['String']>;
  approvedAt?: Maybe<Scalars['String']>;
  approvedById?: Maybe<Scalars['String']>;
  approvedByName?: Maybe<Scalars['String']>;
  sentAt?: Maybe<Scalars['String']>;
  sentById?: Maybe<Scalars['String']>;
  sentByName?: Maybe<Scalars['String']>;
};

export type CapaItpEventsBoOutput = {
  __typename?: 'CAPAItpEventsBOOutput';
  result: CapaErrorCode;
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<Array<CapaItpEventBo>>;
};
