import Login from './LoginContainer';
import ResetPassword from './ResetPasswordContainer';
import RequestPassword from './RequestPasswordContainer';

const components = {
  Login,
  ResetPassword,
  RequestPassword
};
export default components;
