import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import AppConfig from '@config/index';

export const delay = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const fileDownLoad = async (url: string, fileName: string) => {
  const browser = navigator.userAgent.toLowerCase();
  if (browser.indexOf('trident') !== -1 || browser.indexOf('msie') !== -1) {
    const elem = document.getElementsByTagName('body');
    const anchor = document.createElement('a');
    elem[0].appendChild(anchor);
    anchor.setAttribute('href', url);
    anchor.setAttribute('download', fileName);
    anchor.click();

    // Chrome requires the timeout
    await delay(100);
    elem[0].removeChild(anchor);
    return true;
  } else {
    const response = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache'
    });
    if (response.ok) {
      const data = await response.blob();
      saveAs(data, fileName);
    }
    return response.ok;
  }
};

export const fileDownLoadZip = async (
  files: Array<{ url: string; name: string }>
) => {
  const zip = new JSZip();
  const zipFile = zip.folder('files');
  const list = files.map(async (file) => {
    const { name, url } = file;
    const response = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache'
    });
    const data = await response.blob();
    if (zipFile) {
      zipFile.file(name, data);
    }
    return data;
  });

  Promise.all(list).then(() => {
    zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, `files.zip`);
    });
  });
};

export const getFileExtension = (name: any) => {
  if (!name) return '';
  return `.${name.slice(name.lastIndexOf('.') + 1)}`.toLocaleLowerCase();
};

export const blobToBase64 = (blob: any) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

export const convertSubstring = (listFile: any) => {
  return listFile.map((str: any) => str.substring(1));
};

export const urlThumbnail = (thumbnail: any) => {
  if (!thumbnail) {
    return;
  }
  return `${AppConfig.capaViewerApi}/${thumbnail}`;
};
