import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { CustomizedButton, CustomizedTypography } from '@components/Customized';
import { Drawer, Box } from '@material-ui/core';
import {
  IBMAdd,
  MyContacts,
  MyContactsActive,
  RecommendedContact,
  RecommendedContactActive
} from '@assets';
import { useContactsAsideMenuPresenterStyles } from './styles';
import { CapaActivityType } from '@generated/Common/types';
import {
  myContactsPathName,
  myContactsRecommendedPathName
} from '@routes/Common/types';
import { useTranslation } from 'react-i18next';
import useUserActionLog, {
  Enum_Action_Button_Enum
} from '@hooks/useUserActionLog';
import useMyContacts from '@hooks/Contacts/useMyContacts';
import LinkDownloadApp from '@components/LinkDownloadApp';

interface Props {
  callback?: () => void;
}

const AsideMenuPresenter: React.FC<Props> = ({ callback }) => {
  const { t } = useTranslation();
  const classes = useContactsAsideMenuPresenterStyles();
  const { pathname } = useLocation<any>();
  const history = useHistory();
  const [activeLeftMenu, setActiveLeftMenu] = useState(pathname);
  const { onInsertUserActionLogOne } = useUserActionLog();
  const { onSetStateContact } = useMyContacts();

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      open={true}
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <div className={classes.drawerHeaderMargin} />
      <div className={clsx(classes.drawerContentButton)}>
        <CustomizedButton
          className={classes.newButton}
          aria-haspopup="true"
          variant="contained"
          color="primary"
          size="medium"
          startIcon={<img src={IBMAdd} alt="IBMAdd" />}
          onClick={() =>
            onSetStateContact({
              dataCreateEditDialog: []
            })
          }
        >
          {t('contact.new')}
        </CustomizedButton>
      </div>
      <Box
        className={clsx(
          classes.drawerContentItem,
          activeLeftMenu.split('/')[1] === myContactsPathName.split('/')[1]
            ? 'selected'
            : ''
        )}
        onClick={() => {
          onInsertUserActionLogOne(
            Enum_Action_Button_Enum.Button_MyDrive_LNB_MyDrive,
            CapaActivityType.ButtonClick
          );
          history.push(myContactsPathName);
          setActiveLeftMenu(myContactsPathName);
        }}
      >
        <img
          src={
            activeLeftMenu.split('/')[1] === myContactsPathName.split('/')[1]
              ? MyContactsActive
              : MyContacts
          }
        />
        <CustomizedTypography
          className={clsx(classes.drawerContentItemText)}
          variant="body1"
          fontWeight="500"
          color={
            activeLeftMenu.split('/')[1] === myContactsPathName.split('/')[1]
              ? 'primary'
              : 'textPrimary'
          }
        >
          {t('contact.my_contacts.title')}
        </CustomizedTypography>
      </Box>
      <div className={classes.lineSpace} />
      <Box
        className={clsx(
          classes.drawerContentItem,
          activeLeftMenu.split('/')[1] ===
            myContactsRecommendedPathName.split('/')[1]
            ? 'selected'
            : ''
        )}
        onClick={() => {
          onInsertUserActionLogOne(
            Enum_Action_Button_Enum.Button_MyDrive_LNB_SharedDrive,
            CapaActivityType.ButtonClick
          );
          history.push(myContactsRecommendedPathName);
          setActiveLeftMenu(myContactsRecommendedPathName);
        }}
      >
        <img
          src={
            activeLeftMenu.split('/')[1] ===
            myContactsRecommendedPathName.split('/')[1]
              ? RecommendedContactActive
              : RecommendedContact
          }
        />
        <CustomizedTypography
          className={clsx(classes.drawerContentItemText)}
          variant="body1"
          fontWeight="500"
          color={
            activeLeftMenu.split('/')[1] ===
            myContactsRecommendedPathName.split('/')[1]
              ? 'primary'
              : 'textPrimary'
          }
        >
          {t('contact.recommended')}
        </CustomizedTypography>
      </Box>
      <LinkDownloadApp />
    </Drawer>
  );
};

export default AsideMenuPresenter;
