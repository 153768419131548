import React from 'react';
import LayoutPresenter from './LayoutPresenter';

interface Props {
  GnbMenu?: React.ReactNode;
}

const LayoutContainer: React.FC<Props> = ({ GnbMenu, children }) => {
  return <LayoutPresenter GnbMenu={GnbMenu}>{children}</LayoutPresenter>;
};

export default LayoutContainer;
