import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './locales/default.en.json';
import ko from './locales/default.ko.json';
import {
  LANGUAGE_VALUE,
  i18_NEXT_LNG_VALUE
} from '@routes/Common/ChangeLanguage/types';

const options: any = {
  interpolation: {
    escapeValue: false // not needed for react!!
  },
  lng:
    localStorage.getItem(i18_NEXT_LNG_VALUE) ||
    (window.navigator.language === LANGUAGE_VALUE.KO
      ? LANGUAGE_VALUE.KO
      : LANGUAGE_VALUE.EN),
  resources: {
    ko: {
      translations: ko
    },
    en: {
      translations: en
    }
  },
  fallbackLng: LANGUAGE_VALUE.KO,
  ns: ['translations'],
  defaultNS: 'translations',
  supportedLngs: [LANGUAGE_VALUE.KO, LANGUAGE_VALUE.EN],
  react: {
    wait: false,
    bindI18n: 'languageChanged loaded',
    bindStore: 'added removed',
    nsMode: 'default'
  }
};

i18n
  .use(LanguageDetector)
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  .init(options)
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  .then((r) => {});

export default i18n;
