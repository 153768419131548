import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useSnackbar } from '@components/Provider';
import DashboardPresenter from './DashboardPresenter';
import { useTranslation } from 'react-i18next';
import useCommon from '@hooks/Common/useCommon';

const DashboardContainer: React.FC = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useLocation<any>();
  const { openFeedbackDialog } = useCommon();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    //url 링크로 의견작성 모달을 띄워 달라는 요청
    //다른 페이지에서 호출하면 페이지를 해당 페이지를 벗어나게 되는 단점이 있음.
    const params = new URLSearchParams(location.search);
    const feedback = params.get('feedback');

    if (feedback === 'open') {
      openFeedbackDialog({
        isOpenFeedback: true,
        isFeedback: true
      });

      history.replace('/my-drive');
    }

    if (state && state.isSignUp && state.isSignUp === 'true') {
      window.history.pushState({ isSignUp: undefined }, '');
      enqueueSnackbar(t('snackbar.register_success'), {
        variant: 'success'
      });
    }
  }, [state, enqueueSnackbar]);

  return <DashboardPresenter />;
};

export default DashboardContainer;
