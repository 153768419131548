import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { colorSet } from '@components/Provider';
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      maxWidth: 412,
      padding: 0,
      width: '100%',
      '&.delete': {
        maxWidth: 476,
        padding: 7
      },
      [theme.breakpoints.down('xs')]: {
        minWidth: 343
      },
      borderRadius: '16px'
    },
    paperMobile: {
      margin: 16,
      width: '100%',
      borderRadius: 16,
      maxHeight: 'calc(100vh - 130px)'
    },
    paperBottom: {
      padding: 0,
      width: '100%',
      margin: 0,
      bottom: 0,
      right: 0,
      left: 0,
      borderRadius: '16px 16px 0 0'
    },
    scrollPaperBottom: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center'
    },
    DialogContent: {
      position: 'relative',
      padding: '32px 0 6px !important',
      [theme.breakpoints.down('xs')]: {
        padding: '0 16px',
        borderBottom: `1px solid ${colorSet.gray300}`
      },
      '&.pt-t': {
        paddingTop: '8px !important'
      },
      '& .login-container': {
        height: 'auto !important',
        paddingTop: 0,
        '& .capa-logo': {
          cursor: 'pointer'
        }
      },
      '& .titlePage': {
        marginBottom: `20px !important`,
        fontSize: 18,
        lineHeight: '27px'
      },
      '& .footer-auth': {
        display: 'none'
      },
      '& .forgot-container': {
        height: 'auto !important',
        paddingTop: 0,
        '& .capa-logo': {
          cursor: 'pointer'
        },
        '& .go-to-login': {
          textTransform: 'inherit'
        },
        '& .caption': {
          textAlign: 'center'
        },
        '& .MuiTypography-caption': {
          textAlign: 'center',
          display: 'none'
        }
      },
      '& .change-language': {
        display: 'none'
      },
      '& .download-app-banner': {
        display: 'none'
      }
    },
    ContentMain: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      lineHeight: '24px',
      color: colorSet.gray700,
      '&.border-b': {
        borderBottom: `1px solid ${colorSet.gray300}`
      }
    }
  })
);
