import { feedback_icon, report_icon } from '@assets';
import CapaAvatar from '@components/CapaAvatar';
import { CustomizedTypography } from '@components/Customized';
import useCommon from '@hooks/Common/useCommon';
import useAuth from '@hooks/useAuth';
import { Box, MenuItem, MenuList } from '@material-ui/core';
import { AccountCircle, NotificationsNone } from '@material-ui/icons';
import {
  notificationSettingPageType,
  notificationSettingsPathName
} from '@routes/Common/types';
import { getDisplayedAvatarString } from '@utils/common';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useStyles } from './styles';

const AsideMenu: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { pathname } = useLocation();
  // const { onInitAuthState } = useAuth();
  // const { onInsertUserActionLogOne } = useUserActionLog();
  const { userState } = useAuth();
  const { openFeedbackDialog } = useCommon();

  // const logout = () => {
  //   onInsertUserActionLogOne(
  //     Enum_Action_Button_Enum.Button_Gnb_MyPage_Logout,
  //     CapaActivityType.ButtonClick
  //   );
  //   onInitAuthState();
  //   clearStorageAll();
  //   history.push('/login');
  //   onSetUnReadCountGroupList({
  //     unReadCountGroupList: 0
  //   });
  // };

  return (
    <Box className={classes.container}>
      <Box className={classes.avatarWrapper}>
        <CapaAvatar
          item={{
            connectUserName: userState?.data?.userName,
            email: userState?.data?.email,
            isCapaUser: true
          }}
          size="large"
        />
      </Box>
      <div className={classes.username}>
        {getDisplayedAvatarString(
          userState?.data?.userName,
          userState?.data?.email
        )}
      </div>
      <MenuList className={classes.menuList}>
        <MenuItem
          selected={
            pathname === '/mypage' ||
            pathname.includes('account') ||
            pathname.includes('edit')
          }
          onClick={() => {
            history.push('/mypage/account');
          }}
        >
          <AccountCircle fontSize="small" />
          <CustomizedTypography>
            {' '}
            {t('aside_menu.account_setting')}
          </CustomizedTypography>
        </MenuItem>
        <MenuItem
          selected={pathname.includes(notificationSettingPageType)}
          onClick={() => {
            history.push(notificationSettingsPathName);
          }}
        >
          <NotificationsNone fontSize="small" />
          <CustomizedTypography>
            {' '}
            {t('aside_menu.notifications')}
          </CustomizedTypography>
        </MenuItem>

        <MenuItem
          onClick={() => {
            openFeedbackDialog({
              isOpenFeedback: true,
              isFeedback: true
            });
          }}
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '10px'
            }}
          >
            <img src={feedback_icon} width={22} height={22} />
            <CustomizedTypography variant="body2">
              {t('gnb.give_feedback')}
            </CustomizedTypography>
          </Box>
        </MenuItem>

        <MenuItem
          onClick={() => {
            openFeedbackDialog({
              isOpenFeedback: true,
              isFeedback: false
            });
          }}
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '10px'
            }}
          >
            <img src={report_icon} width={22} height={22} />
            <CustomizedTypography variant="body2">
              {t('gnb.report_issues')}
            </CustomizedTypography>
          </Box>
        </MenuItem>

        {/* <MenuItem onClick={logout} className={classes.logOutItem}>
          <CustomizedTypography className={classes.logOut}>
            {t('aside_menu.log_out')}
          </CustomizedTypography>
        </MenuItem> */}
      </MenuList>
    </Box>
  );
};

export default AsideMenu;
