import React from 'react';

export const PrivacyPolicyAgreement = () => {
  return (
    <div className="agreement-content">
      <p className="content">
        주식회사 에이팀벤처스(이하 “회사”라 함)는 회사가 운영하는 캐파 및 관련
        제반 서비스(이하 “서비스”라 함)를 이용하는 이용자들의 개인정보 보호를
        매우 중요시하며, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등
        정보통신 서비스 제공자가 준수하여야 할 관련 법령상의 개인 정보 보호
        규정을 준수하며, 관련 법령에 의거한 개인정보보호방침을 정하여 이용자의
        권익 보호에 최선을 다하고 있습니다.
      </p>
      <br />
      <br />
      <p className="subTitle">제 1 조 (수집하는 개인정보의 항목 및 수집방법)</p>
      <p className="content">
        <br />
        ① 회사는 다음과 같은 항목의 정보를 수집합니다.
        <br />
        1. 성명, 주소, 전자우편주소, 이동전화번호, 비밀번호
        <br />
        2. (결제시) 계좌정보, 신용카드 정보
        <br />
        <br />
        ② 회사는 다음과 같은 방법으로 개인정보를 수집합니다.
        <br />
        1. 홈페이지 및 고객센터를 통한 전화 및 온라인 상담
        <br />
        2. 기타 이벤트 페이지 등을 통한 입력
      </p>
      <br />
      <br />
      <p className="subTitle">제 2 조 (개인정보의 수집이용 목적)</p>
      <p className="content">
        <br />
        ① 회사는 수집된 이용자들의 개인정보를 회원관리, 서비스 제공, 개선, 신규
        서비스 개발 등을 위한 목적을 위해 이용하고 있습니다.
        <br />
        <br />
        1. 회원식별, 가입의사 확인, 본인확인, 중복가입여부 확인, 부정이용 방지
        <br />
        2. 서비스 및 부가 서비스 이용에 대한 요금 결제
        <br />
        3. 신규 서비스 개발, 개인화 서비스 등 다양한 서비스 제공, 문의사항 및
        불만 처리, 공지사항 전달
        <br />
        4. 이벤트/행사 참여 확인, 마케팅 및 광고 등에 활용
        <br />
        5. 서비스 이용 기록, 접속 빈도 및 서비스 이용에 대한 통계, 프라이버시
        보호 측면의 서비스 환경 구축, 서비스 개선에 활용
        <br />
        <br />② 회사는 수집한 개인정보를 이용자들에게 사전에 밝힌 목적 이외에
        다른 목적으로는 사용하지 않으며 외부로 유출하지 않습니다.
      </p>
      <br />
      <br />
      <p className="subTitle">제 3 조 (민감정보의 처리)</p>
      <p className="content">
        <br />
        회사는 이용자의 기본적 인권 침해의 우려가 있는 민감한 개인정보(인종 및
        민족, 사상 및 신조, 출신지 및 본적지, 정치적 성향 및 범죄기록, 건강상태
        및 성생활 등)는 수집하지 않으며 부득이하게 수집해야 할 경우 반드시
        이용자의 사전 동의를 받습니다.
      </p>
      <br />
      <br />
      <p className="subTitle">제 4 조 (개인정보의 보유기간 및 이용기간)</p>
      <p className="content">
        <br />
        ① 이용자의 개인정보는 다음과 같이 개인정보의 수집목적 또는 제공받은
        목적이 달성되면 파기됩니다. 단, 전자상거래 등에서의 소비자보호에 관한
        법률, 상법 등 관련법령의 규정에 의하여 다음과 같이 거래 관련 권리 및
        의무 관계의 확인 등을 이유로 일정 기간 보유하여야 할 필요가 있을
        경우에는 일정 기간 보유합니다.
        <br />
        <br />
        1. 회원가입정보의 경우, 회원가입을 탈퇴하거나 회원자격을 상실시키는
        경우에는 회원가입 탈퇴 또는 회원자격 상실 전에 보유목적, 기간 및
        보유하는 개인정보 항목을 명시하여 동의를 구합니다.
        <br />
        2. 계약 또는 청약철회 등에 관한 기록: 5년
        <br />
        3. 대금결제 및 재화 등의 공급에 관한 기록: 5년
        <br />
        4. 소비자의 불만 또는 분쟁처리에 관한 기록: 3년
        <br />
        5. 표시. 광고에 관한 기록: 6월
        <br />
        <br />② 이용자의 동의를 받아 보유하고 있는 거래정보 등에 대하여 이용자가
        열람을 요구하는 경우 ”회사”는 이를 열람, 확인할 수 있도록 조치를
        취합니다.
      </p>
      <br />
      <br />
      <p className="subTitle">제 5 조 (이용자의 권리와 그 행사방법)</p>
      <p className="content">
        <br />
        이용자는 언제든지 등록되어 있는 자신의 개인정보를 조회하거나 수정할 수
        있으며 가입해지를 요청할 수도 있습니다. 이용자들의 개인정보 조회, 수정을
        위해서는 '개인정보변경'(또는 '회원정보수정' 등)을, 가입해지(동의철회)를
        위해서는 ‘회원탈퇴’를 클릭하여 본인 확인 절차를 거치신 후 직접 열람,
        정정 또는 탈퇴가 가능합니다. 또는 개인정보보호 책임자에게 서면, 전화
        또는 전자우편으로 연락하시면 본인 확인 절차 후 조치를 취합니다. 이용자가
        개인정보의 오류에 대한 정정을 요청한 경우에는 정정을 완료하기 전까지
        당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제
        3자에게 이미 제공한 경우에는 정정 처리결과를 제 3자에게 통지하여 정정이
        이루어지도록 합니다. 회사는 이용자의 요청에 의해 해지 또는 삭제된
        개인정보는 "회사가 수집하는 개인정보의 보유 및 이용기간"에 명시된 바에
        따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고
        있습니다.
      </p>
      <br />
      <br />
      <p className="subTitle">제 6 조 (쿠키의 운영사실 및 거부방법 안내)</p>
      <p className="content">
        <br />
        ① 회사는 이용자에 대한 정보를 저장하고 수시로 찾아내는 쿠키(cookie)를
        사용합니다. 쿠키는 웹사이트가 이용자의 컴퓨터 브라우저(크롬, 인터넷
        익스플로러 등)로 전송하는 소량의 정보입니다. 이용자가 서비스 웹사이트에
        접속을 하면 회사의 컴퓨터는 이용자의 브라우저에 있는 쿠키의 내용을 읽고,
        이용자의 추가정보를 이용자의 컴퓨터에서 찾아 접속에 따른 성명 등의 추가
        입력 없이 서비스를 제공할 수 있습니다. 또한 이용자는 쿠키에 대한
        선택권이 있습니다. 웹브라우저 상단의 도구 &gt; 인터넷옵션 탭(option
        tab)에서 모든 쿠키를 다 받아들이거나, 쿠키가 설치될 때 통지를 보내도록
        하거나, 아니면 모든 쿠키를 거부할 수 있는 선택권을 가질 수 있습니다.
        다만 회사가 제공하는 모든 서비스를 제대로 이용하기 위해서는 쿠키를
        허용해야 하며, 쿠키를 허용하지 않았을 경우에는 서비스가 정상적으로
        제공되지 않을 수 있습니다.
        <br />
        <br />
        ② 회사는 이용자의 편의를 위하여 쿠키를 운영합니다. 회사가 쿠키(cookie)를
        통해 수집한 정보는 다음의 목적을 위해 사용됩니다.
        <br />
        <br />
        1. 개인의 관심 분야에 따라 차별화된 정보를 제공
        <br />
        2. 회원과 비회원의 접속빈도 또는 머문 시간 등을 분석하여 이용자의 취향과
        관심분야를 파악하여 타겟(target) 마케팅에 활용
        <br />
        3. 검색한 정보와 관심 있게 둘러본 콘텐츠들에 대한 자취를 추적하여 다음
        번 검색 때 개인 맞춤 서비스를 제공
        <br />
        4. 이용자들의 습관을 분석하여 서비스 개편 등의 척도
        <br />
        5. 게시판 글 등록
        <br />
        <br />
        ③ 쿠키 설정을 거부하는 방법으로는 이용자가 이용하는 웹 브라우저의 옵션을
        선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을
        거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.
        <br />
        <br />
        설정방법 예 (인터넷 익스플로러의 경우): 웹 브라우저 상단의 도구 &gt;
        인터넷 옵션 &gt; 개인정보 단, 이용자가 쿠키 설치를 거부하였을 경우
        서비스 제공에 어려움이 있을 수 있습니다.
      </p>
      <br />
      <br />
      <p className="subTitle">제 7 조 (비회원의 개인정보관리)</p>
      <p className="content">
        <br />
        ① 회사는 회원 탈퇴를 거쳐 비회원이 된 회원의 기록을 보관할 수 있습니다.
        다만 이 경우 별도의 동의나 거래 관련 기록 보관의 의무와 상충하지 않는
        범위 내에서 특정 개인을 식별할 수 없게 개인정보를 제거함을 원칙으로
        합니다.
        <br />
        <br />② 개인정보가 제거된 비회원의 기록은 서비스 운영과 관련한 용도
        외에는 다른 어떠한 용도로도 사용되지 않습니다.
      </p>
      <br />
      <br />
      <p className="subTitle">제 8 조 (개인정보의 위탁처리)</p>
      <p className="content">
        <br />
        회사는 서비스 향상을 위해서 이용자의 개인정보를 필요한 경우 동의 등 법률
        상의 요건을 구비하여 외부에 수집 · 취급 · 관리 등을 위탁하여 처리할 수
        있으며, 제 3자에게 제공할 수 있습니다. 회사는 개인정보의 처리와 관련하여
        아래와 같이 업무를 위탁하고 있으며, 관계 법령에 따라 위탁계약 시
        개인정보가 안전하게 관리될 수 있도록 필요한 사항을 규정하고 있습니다.
        또한 공유하는 정보는 당해 목적을 달성하기 위하여 필요한 최소한의 정보에
        국한됩니다.
        <br />
        ① 위탁 대상자 : [현재 해당없음] / 위탁업무 내용 : [결제처리]
        <br />
        <br />
        ② 위탁 대상자: [㈜누리고] / 위탁업무 내용: [문자메시지 발송]
        <br />
        <br />
        ③ 위탁 대상자: [The Rocket Science Group LLC.(Mailchimp)] / 위탁업무
        내용: [이메일 발송]
        <br />
        <br />
        ④ 위탁 대상자: [Amazon Web Service, inc. 및 Autodesk Inc. ] / 위탁업무
        내용: [데이터 보관 및 시스템 운영]
        <br />
      </p>
      <br />
      <br />
      <p className="subTitle">제 9 조 (개인정보보호 책임자)</p>
      <p className="content">
        <br />
        회사는 이용자들의 개인정보를 보호하고 개인정보와 관련된 불만 등을
        처리하기 위하여 고객서비스 담당 부서 및 개인정보보호 책임자를 지정하고
        있습니다.
        <br />
        <br />
        ① [고객지원센터] 전화번호 : 02-545-6332 / 이메일 : support@capa.ai
        <br />
        <br />
        ② [개인정보보호 책임자] 성명 : 고산 / 직위 : 대표 / 전화번호:
        02-545-6332 / 이메일 : support@capa.ai
        <br />
        <br />
        ③ 기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에
        문의하시기 바랍니다.
        <br />
        <br />
        1. 개인정보침해신고센터(privacy.kisa.or.kr / 국번없이 118)
        <br />
        <br />
        2. 대검찰청 사이버수사과(www.spo.go.kr / 국번없이 1301)
        <br />
        <br />
        3. 경찰청 사이버안전국(cyberbureau.police.go.kr / 국번없이 182)
      </p>
      <br />
      <br />
      <p className="subTitle">제 10 조 (개인정보처리방침의 개정과 그 공지)</p>
      <p className="content">
        <br />
        회사는 개인정보처리방침 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소
        7일전부터 홈페이지의 초기화면 등을 통해 고지할 것입니다. 다만,
        개인정보의 수집 및 활용, 제 3자 제공 등과 같이 이용자 권리의 중요한
        변경이 있을 경우에는 최소 30일 전에 고지합니다.
      </p>
      <br />
      <p className="title">부칙</p>
      <br />
      <p className="content">
        <br />이 방침은 2021년 1월 1일부터 시행합니다.
      </p>
    </div>
  );
};

export const TermsOfServiceAgreement = () => {
  return (
    <div className="agreement-content">
      <p className="title">제 1 장 총칙</p>
      <br />
      <p className="content">
        제 1 조 (목적)
        <br />
        ① 본 약관은 주식회사 에이팀벤처스(이하 “회사”라 함)가 제공하는 인터넷
        서비스 사이트(이하 “사이트” 또는 “캐파(CAPA)”)를 이용함에 있어 회사와
        이용자의 권리와 의무, 책임사항 및 회원의 서비스 이용절차 등에 관한
        사항을 규정함을 목적으로 합니다. <br />② 본 약관이 규정한 내용 이외의
        회사와 이용자 간의 권리, 의무 및 책임사항에 관하여서는 전기통신사업법
        기타 대한민국의 관련 법령과 상관습에 의합니다
      </p>
      <br />
      <br />
      <p className="content">
        제 2 조 (용어의 정의)
        <br />
        ① 이 약관에서 사용하는 주요한 용어의 정의는 다음과 같습니다. <br />
        1. 회사가 운영하는 사이트는 아래와 같습니다. <br />
        capa.ai, partners.capa.ai, cloud.capa.ai, 추후 회사에서 공지하고
        제공하는 기타 웹사이트, 스마트폰 및 이동통신기기를 통해 제공되는 모바일
        어플리케이션 포함 <br />
        2. 이용자: 캐파에 접속하여 이 약관에 따라 서비스를 받는 회원 및 비회원을
        말합니다. <br />
        3. 회원: 본 약관에 따라 회사와 서비스 이용계약을 체결하고 본인확인
        식별에 필요한 정보를 인증 후 회사가 제공하는 서비스를 이용하는 개인 및
        법인을 말합니다. 회원은 이용하는 서비스에 따라 고객과 파트너로
        나누어집니다. <br />
        4. 고객: 회사에 개인정보를 제공하여 회원등록을 한 자로서, 회사와 서비스
        이용계약을 체결하고 견적 요청서를 작성하는 등 원하는 제조 업체를 찾기
        위해 사이트를 계속적으로 이용할 수 있는 자를 말합니다. <br />
        5. 파트너: 회사에 업체 정보를 제공하여 회원등록을 한 자로서, 회사와
        서비스 이용계약을 체결하고 회원 아이디(ID)를 부여 받아 고객에게 견적을
        보낼 수 있고 유료 구독을 신청하는 등 고객을 찾기 위한 서비스를
        계속적으로 이용할 수 있는 자를 말합니다. <br />
        6. 요청서: 고객이 원하는 제조에 대한 구체적인 정보를 담고 있는 서비스
        요청서입니다. <br />
        7. 견적(견적서): 파트너가 자신이 제공할 수 있는 용역에 관하여 요청
        고객에게 보낼 수 있는 용역 금액 및 내용에 대한 요약본입니다. <br />
        8. 콘텐츠(Contents): 회사가 캐파에서 제공하는 정보, 요청서 작성, 견적
        작성, 요청서 수신, 견적 수신, 프로그램 등
        부호ㆍ문자ㆍ도형ㆍ색채ㆍ음성ㆍ음향ㆍ이미지ㆍ영상 및 복합체의 정보나
        자료를 의미합니다. <br />
        9. ‘CAPA Connect’란 ‘회원’의 파일을 온라인 저장 공간에 저장하고, 다시
        내려 받아 사용하는 서비스를 말합니다.
        <br />
        10. 회원 아이디(이하 “ID”): 회원의 식별과 회원의 서비스 이용을 위하여
        회원이 선정하고 회사가 승인하거나 회사가 회원에게 배정하는 문자 또는
        숫자의 조합을 말합니다. <br />
        11. 비밀번호(Password): 이용자의 신원을 확인하고, 회원의 통신상
        개인정보를 보호하기 위하여 회원이 정한 문자와 숫자의 조합을 말합니다.
        <br />
        12. 비회원: 회원가입을 하지 않고 서비스를 이용하는 자 또는 직접 서비스
        이용을 하지 않더라도 전자우편 및 SMS를 통한 정보의 수신에 동의하고
        정보를 제공받는 이용자를 의미합니다. <br />
        13. 회원 탈퇴: 회원이 이용계약을 종료시키는 행위를 말합니다. <br />
        14. 뉴스레터(Newsletter): 회사가 보내는 캐파 소식이 담긴 이메일을
        의미합니다. <br />
        15. 인터랙션 : 회원 간의 채팅, 전화 등 캐파에서 제공하는 방식을 통해
        상호작용 할 수 있는 행위를 의미 합니다.
      </p>
      <br />
      <br />
      <p className="content">
        제 3 조 (약관의 명시와 설명 및 개정)
        <br />
        ① “회사”는 이 약관의 내용과 상호, 대표자 성명, 영업장 소재지 주소,
        개인정보보호 담당자의 성명, 연락처(전화, 팩스, 전자우편 주소 등) 등을
        이용자가 알 수 있도록 “사이트”의 초기 서비스 화면에 게시합니다. 다만,
        약관의 구체적인 내용은 이용자가 연결화면을 통하여 볼 수 있도록 합니다.
        <br />
        ② 이 약관은 그 내용을 캐파에 게시하거나 이메일 등 기타의 방법으로
        회원에게 공지하고 회원이 이에 동의함으로써 효력이 발생합니다.
        <br />③ 서비스 이용 시 화면에서 제시되는 경고 메시지의 효력은 본 약관의
        효력과 동일합니다.
        <br />
        ④ 회사는 약관의 규제에 관한 법률, 전자거래기본법, 전자서명법, 정보통신망
        이용촉진 및 정보 보호 등에 관한 법률 기타 관련 법령을 위배하지 않는 범위
        내에서 약관을 변경할 수 있으며, 약관 변경 시에는 개정 사유 및 적용
        일자를 명시하여 현행 약관과 함께 적용 일자 7일 전부터 적용일 전날까지
        제2항의 방법으로 공지합니다. 다만, 회원에게 불리하거나 회원의 권리 또는
        의무에 관한 중요 사항의 변경은 최소 30일 전에 홈페이지에 공지하거나
        이메일주소로 고지합니다.
        <br />⑤ 회사는 제2항의 공지 또는 통지를 할 경우 회원이 개정약관에 대해
        동의 또는 거부의 의사표시를 하지 않으면 동의한 것으로 볼 수 있다는
        내용도 함께 공지 또는 통지를 하며, 회원이 이 약관 시행일까지 거부의
        의사표시를 하지 않는다면 개정약관에 동의한 것으로 볼 수 있습니다. 회원이
        개정약관에 대해 동의하지 않는 경우 회사 또는 회원은 서비스 이용계약을
        해지할 수 있습니다.
      </p>
      <br />
      <br />
      <p className="content">
        제 4 조 (약관 외 준칙)
        <br />
        ① 이 약관은 회사가 제공하는 개별서비스에 관한 이용안내(이하 ‘서비스 별
        안내’라 합니다)와 함께 적용합니다. 해당 내용이 이 약관과 상충할 경우에는
        ‘서비스 별 안내’가 우선하여 적용됩니다.
        <br />② 이 약관에 명시되지 않은 사항에 대해서는 전기통신사업법,
        전자거래기본법, 정보통신망법, 기타 관련법령 및 서비스 별 안내의 규정에
        의합니다.
      </p>
      <br />
      <br />
      <p className="title">제 2 장 서비스 이용 계약</p>
      <br />
      <p className="content">
        제 5 조 (이용 계약의 성립)
        <br />
        ① 이용 계약은 서비스 이용 희망자의 이 약관에 대해 동의한다는 의사표시와
        이용 신청에 대한 회사의 승낙으로 성립됩니다.
        <br />② 이 약관에 대한 동의는 이용 신청 당시 링크를 확인함으로써 그
        의사표시를 합니다.
        <br />③ 만 14세 미만의 미성년자의 경우 부모님(법정 대리인)의 동의를
        확인한 후 이용 계약이 성립됩니다.
      </p>
      <br />
      <br />
      <p className="content">
        제 6 조 (이용신청 및 승낙)
        <br />
        ① 이용신청은 이용자(이하 “신청자”)가 약관의 내용에 동의를 하고, 회사가
        정한 양식에 따라 정보(메일주소, 연락처 등)를 기입하여 제출하는 것을
        의미합니다.
        <br />② 이용자의 이용신청에 대하여 회사가 승낙한 경우, 회사는 가입
        정보와 기타 회사가 필요하다고 인정하는 내용을 이용자에게 통지합니다.
        <br />③ 회사는 이용자의 신청에 대해서 이용을 승낙하는 것을 원칙으로
        합니다. 다만, 다음 각 호에 해당하는 신청에 대하여는 승인을 하지 아니할
        수 있습니다.
        <br />
        1. 본인 실명이 아니거나 다른 사람의 명의를 사용하여 신청하였을 때<br />
        2. 허위 정보를 기재하였을 때<br />
        3. 사회의 안녕과 질서 혹은 미풍양속을 저해할 목적으로 신청하였을 때
        <br />
        4. 부정한 용도로 본 서비스를 이용하고자 하는 경우
        <br />
        5. 본 서비스에 피해를 입히는 방법으로 영리를 추구하기 위해 서비스를
        이용하고자 하는 경우
        <br />
        6. 본 서비스와 경쟁관계에 있는 이용자가 신청하는 경우
        <br />
        7. 기타 이용신청자의 귀책사유로 이용승낙이 곤란한 경우
        <br />④ 회사는 서비스 이용신청이 다음 각 호에 해당하는 경우에는 그
        신청에 대하여 승낙 제한사유가 해소될 때까지 승낙을 유보할 수 있습니다.
        <br />
        1. 회사가 설비의 여유가 없는 경우
        <br />
        2. 회사의 기술상 지장이 있는 경우
        <br />
        3. 기타 회사의 귀책사유로 이용승낙이 곤란한 경우
      </p>
      <br />
      <br />
      <p className="content">
        제 7 조 (서비스 이용)
        <br />
        ① 회원의 최초 서비스 이용신청 이후 서비스 제공을 위한 범위에서 사이트가
        신설되는 경우, 신규 사이트의 이용약관에서 달리 명시되지 않는 한 회원은
        관련 사이트의 회원으로 자동 가입됩니다. 이 경우 회사는 신규 사이트의
        서비스 개시 일자, 회원자동가입 사실 기타 관련 정보를 그 개시일 7일
        이전부터 개시 일까지 각 사이트에 공지하거나, 또는 그 개시일 7일 이전까지
        이메일 통지 등으로 각 회원에게 개별 통지합니다.
        <br />② 캐파 서비스 이용은 사이트의 업무상 또는 기술상 특별한 지장이
        없는 한 연중무휴, 1일 24시간을 원칙으로 합니다.
        <br />③ 회사가 캐파에서 제공하는 서비스는 기본적으로 무료입니다. 단,
        별도로 파트너 회원이 특정 서비스를 이용하기 위해서는 요금 결제가 필요할
        수 있습니다.
        <br />④ 유료서비스 이용 요금 및 결제 방식은 해당 서비스에 명시되어 있는
        규정에 따릅니다.
        <br />⑤ 회사는 통신판매중개업자로서 서비스 요금이 무료인 서비스와 일부
        유료서비스 이용과 관련하여 이용자들 사이에서 발생한 거래와 관련된 손해에
        대해서는 회사에 고의 또는 중대한 과실이 있는 경우를 제외하고는 책임을
        지지 않습니다.
      </p>
      <br />
      <br />
      <p className="title">제 3 장 서비스 제공 및 변경</p>
      <br />
      <p className="content">
        제 8 조 (서비스 내용)
        <br />
        회사가 제공하는 서비스의 내용은 다음과 같습니다.
        <br />① 고객의 요청서를 파트너에게 제공
        <br />② 파트너와 고객간의 상담을 위한 인터렉션 툴 제공
        <br />③ CAPA Connect 서비스 <br />
        A. ‘회원’은 ‘서비스’를 통하여 디바이스(PC/모바일 등을 의미하며 이에
        한정하지 않음. 이하 동일)에 있는 콘텐츠 파일을 ‘사이트’에 저장할 수
        있고, 해당 디바이스를 ‘사이트’에 등록할 수 있습니다. <br />
        B. 3G 망, Wi-Fi망, LTE망, LAN 연결 등 유/무선 네트워크가 연결된 모든
        상태에서 ‘사이트’에 콘텐츠를 저장할 수 있습니다.
        <br />
        C. ‘회사’는 ‘회원’에게 일정 용량의 무료 콘텐츠 저장 공간(이하
        ‘스토리지’)을 제공하며, ‘회사’의 사업 상황, ‘회원’의 상태 변경 등의
        사정으로 제공된 스토리지 용량은 변경될 수 있습니다. 제공된 스토리지
        용량을 변경할 경우, ‘회사’는 변경 내용에 관하여 제7조에 따른 방법으로
        사전 또는 사후 통지 절차를 거쳐야 합니다. <br />
        D. ‘회사’에서 진행하는 이벤트를 통하여 ‘회원’은 추가 스토리지 용량 등
        혜택을 제공받을 수 있으나, 혜택 지급 이후 당첨 조건에 부합하지 않는다는
        사정이 확인된 경우에는 혜택이 다시 회수될 수 있습니다.
        <br />
        E. ‘회원’의 스토리지 용량이 축소될 경우, 축소된 용량에 따라 ‘회원’의
        ‘서비스’ 이용에 제한이 발생할 수 있습니다.
        <br />
        F. ‘회원’이 ‘사이트’에 저장되어 있는 콘텐츠를 삭제하여 콘텐츠가
        휴지통으로 이동한 경우, 해당 콘텐츠는 영구적으로 삭제됩니다.
        <br />
        G. ‘회사’는 ‘회원’에게 ‘서비스’ 내 특정 스토리지에 대해서 공유 기능을
        제공합니다. 다만, 저작권 침해 가능성이 있는 콘텐츠의 경우 공유에 제한이
        있을 수 있습니다.
        <br />
        H. ‘회사’는 ‘회원’에게 ‘서비스’ 기능을 제공하기 위하여, ‘회원’의
        콘텐츠의 메타 데이터에 접근하거나 기계적인 도면 변환 기술 방법을 이용해
        ‘회원’의 콘텐츠를 스캔할 수 있습니다.
        <br />
        <br />④ 기타 관련 부수적 서비스
      </p>
      <br />
      <br />
      <p className="content">
        제 9 조 (정보의 제공 및 광고의 게제)
        <br />
        ① 회사는 회원에게 서비스 이용에 필요하다고 인정되는 각종 정보에 대해서
        사이트 및 이메일, SMS, DM 발송 등 각종 매체에 게재하는 방법 등으로
        회원에게 제공할 수 있습니다.
        <br />② 회사는 서비스 개선 및 소개 등을 목적으로 회원의 동의 하에
        추가적인 개인정보를 요청할 수 있습니다.
        <br />③ 회사는 서비스의 운용과 관련하여 사이트, 이메일, SMS, DM 등에
        광고 등을 게재할 수 있습니다.
        <br />④ 회사가 제3항에 따라 광고성 이메일, SMS, DM을 발송하고자 하는
        경우 회사는 이용자로부터 동의를 받으며, 회사와 동일한 서비스 등에 대한
        거래내역이 있는 경우에 한해서는 이용자의 동의를 받기 전이라도 6개월간
        동종의 서비스 등에 대한 광고성 정보를 발송할 수 있습니다.
      </p>
      <br />
      <br />
      <p className="content">
        제 10 조 (서비스 중단)
        <br />
        ① 회사는 정기점검, 보수, 교체 등 회사가 필요한 경우 및 부득이한 사유로
        인하여 서비스 이용에 지장이 있는 경우에는 서비스의 이용의 전부 또는
        일부를 제한하거나 일시 중단할 수 있습니다.
        <br />② 전시, 사변, 천재지변 또는 이에 준하는 국가 비상사태가 발생하거나
        발생할 우려가 있는 경우와 정전, 서비스 이용 폭주 등으로 정상적인
        서비스가 불가능한 경우 등 기타 불가항력적인 사유가 있는 경우에는 서비스
        이용의 전부 또는 일부를 제한하거나 중지할 수 있습니다.
        <br />③ 제1항에 의한 서비스 중단의 경우에는 사이트는 회원에게 제11조의
        방법으로 통지를 합니다. 단, 사이트가 통제할 수 없는 사유로 인한 서비스
        중단으로 사전 통지가 불가능한 경우에는 그러하지 아니합니다.
        <br />④ 회사는 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로
        인하여 이용자 또는 제3자가 입은 손해에 대하여 배상합니다. 단, 회사의
        고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.
      </p>
      <br />
      <br />
      <p className="content">
        제 11 조 (회원에 대한 통지)
        <br />
        ① 회사가 회원에 대한 통지를 하는 경우에는 회원이 서비스 이용 신청 시
        사이트 화면 또는 회원가입 시 제출한 이메일 주소로 할 수 있습니다.
        이용자는 이메일 주소 등이 변경된 경우 회사에 즉시 알려야 하며, 이메일
        주소 변경 등을 하지 않아 통지가 도달하지 않은 데 대하여 회사는 책임을
        부담하지 않습니다.
        <br />② 회사는 회원에게 불리하거나 중대한 사항의 변경이 아닌 불특정 다수
        회원에 대한 통지의 경우 7일 이상 사이트 상에 게시함으로써 개별 통지에
        갈음할 수 있습니다.
      </p>
      <br />
      <br />
      <p className="title">제 4 장 서비스 이용 계약의 해제, 해지</p>
      <br />
      <p className="content">
        제 12 조 (회원탈퇴)
        <br />
        ① 회원이 이용계약을 해지하고자 하는 때에는 회원 본인이 캐파 내의 메뉴
        또는 전화 등의 방법을 이용하여 가입해지를 신청할 수 있으며, 회사는 즉시
        회원 탈퇴 처리를 합니다.
        <br />② 회원 탈퇴가 이루어짐과 동시에 서비스 이용과 관련된 모든 정보는
        삭제됩니다. 이로 인해 발생하는 불이익에 대한 책임은 회원 본인에게
        있습니다.
        <br />③ 회원이 다음 각 호의 사유에 해당하는 경우, 회사는 회원의
        회원자격을 적절한 방법으로 제한 및 정지, 상실시킬 수 있습니다.
        <br />
        1. 가입 신청 시에 허위 내용을 등록한 경우
        <br />
        2. 다른 사람의 ‘서비스’ 이용을 방해하거나 그 정보를 도용하는 등
        전자거래질서를 위협하는 경우
        <br />
        3. 서비스를 이용하여 법령과 본 약관이 금지하거나 공서양속에 반하는
        행위를 하는 경우
        <br />
        4. 회사가 회원 자격을 제재한 이후, 동일한 행위가 2회 이상 반복되거나
        10일 이내에 그 사유가 시정되지 아니하는 경우
        <br />④ 회사가 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이
        경우 회원에게 이를 통지하고, 회원등록 말소 전에 소명할 기회를
        부여합니다.
      </p>
      <br />
      <br />
      <p className="content">
        제 13 조 (회사의 서비스 이용 계약해제, 해지 및 이용제한)
        <br />
        ① 회사는 거래의 안정성을 위해 해당 약관에서 정한 바에 따라 회원 자격을
        정지하거나, 서비스 이용 제한 등의 조치를 취할 수 있습니다.
        <br />② 회사는 다른 이용자의 약관 위반 행위에 가공하거나 공모한 이용자
        또는 약관 위반 행위를 돕거나 그로 인해 부당한 이득을 취한 이용자에
        대해서도 해당 약관 위반 행위에 대한 제재를 적용할 수 있습니다.
        <br />③ 회사가 회원자격을 정지하거나 이용제한 등 기타 필요한 조치를 취할
        경우 이 약관에 특별한 규정이 없는 한 사전에 회원에게 유선 또는 메일 등
        알림을 통해 통보하며 회원이 연락 두절되거나 긴급을 요하는 부득이한 경우
        선조치 후통보 할 수 있습니다.
        <br />④ 계정 공유 중 제 3자가 이용자 본인의 계정을 이용하여 약관 위반
        행위가 발생한다고 하더라도 이용자 본인 및 해당 계정에 제재가 적용될 수
        있습니다.
        <br />⑤ 회원은 회사의 해제·해지 및 이용제한에 대하여 이의신청을 할 수
        있습니다. 회원의 이의신청이 정당한 경우 회사는 즉시 회원이 서비스를
        이용할 수 있도록 필요한 조치를 취합니다.
      </p>
      <br />
      <br />
      <p className="content">
        제 14 조 (회사의 계약해제·해지의 효과)
        <br />
        ① 회원의 귀책사유에 따른 이용계약의 해제·해지시 유료 서비스의 이용
        요금은 일할계산하여 환불 가능합니다.
        <br />② 회원의 귀책사유로 과오금이 발생한 서비스 이용계약을 해제 또는
        해지한 경우 회사는 회원에게 손해배상을 청구할 수 있습니다. 다만, 회원이
        고의 또는 과실 없음을 증명한 경우에는 그러하지 않습니다.
      </p>
      <br />
      <br />
      <p className="title">제 5 장 개인 정보의 처리</p>
      <br />
      <p className="content">
        제 15 조 (회원의 개인정보 보호)
        <br />
        ① 회사는 회원의 개인정보를 보호하기 위하여 정보통신망법 및 개인정보
        보호법 등 관계 법령에서 정하는 바를 준수하며 이용자의 개인 식별이 가능한
        개인정보를 수집하는 때에는 반드시 당해 이용자의 동의를 받습니다.
        <br />② 회사의 개인정보 보호는 서비스 개인정보처리방침에 따릅니다.
      </p>
      <br />
      <br />
      <p className="title">제 6 장 당사자의 의무</p>
      <br />
      <p className="content">
        제 16 조 (회사의 의무)
        <br />
        ① 회사는 회원이 희망한 서비스 제공 개시 일에 특별한 사정이 없는 한
        서비스를 이용할 수 있도록 하여야 합니다.
        <br />② 회사는 계속적이고 안정적인 서비스의 제공을 위하여 설비에 장애가
        생기거나 기계의 결함이 있는 때에는 부득이한 사유가 없는 한 지체 없이
        이를 수리 또는 복구합니다.
        <br />③ 회사는 개인정보 보호를 위해 보안시스템을 구축하며 개인정보
        보호정책을 공시하고 준수합니다.
        <br />④ 회사는 회원으로부터 제기되는 의견이나 불만이 정당하다고
        객관적으로 인정될 경우에는 적절한 절차를 거쳐 즉시 처리하여야 합니다.
        다만, 즉시 처리가 곤란한 경우는 이용자에게 그 사유와 처리일정을
        통보하여야 합니다.
      </p>
      <br />
      <br />
      <p className="content">
        제 17 조 (회원의 의무)
        <br />
        ① 회원은 다음 각 호의 행위를 하여서는 안 됩니다.
        <br />
        1. 회원가입신청 또는 변경 시 허위내용을 등록하는 행위
        <br />
        2. 캐파에 게시된 정보를 허위로 변경하는 행위
        <br />
        3. 회사 기타 제3자의 인격권 또는 지적재산권을 침해하거나 업무를 방해하는
        행위
        <br />
        4. 다른 회원의 ID를 도용하거나 ID를 여러 사람이 공유하는 행위
        <br />
        5. 관련 법령에 의하여 그 전송 또는 게시가 금지되는 정보(컴퓨터 프로그램
        등)의 전송 또는 게시하는 행위
        <br />
        6. 회사의 직원이나 관리자를 가장하거나 사칭하여 또는 타인의 명의를
        오용하여 글을 게시하거나 메일을 발송하는 행위
        <br />
        7. 컴퓨터 소프트웨어, 하드웨어, 전기통신 장비의 정상적인 가동을 방해,
        파괴할 목적으로 고안된 소프트웨어 바이러스, 기타의 다른 컴퓨터 코드,
        파일, 프로그램을 포함하고 있는 자료를 게시하거나 채팅, 전자우편 등으로
        발송하는 행위
        <br />
        8. 다른 회원에 대한 개인정보를 그 동의 없이 수집, 저장, 공개하는 행위
        <br />
        9. 회사가 제공하는 서비스에 정한 약관 기타 서비스 이용에 관한 규정을
        위반하는 행위
        <br />
        10. 외설 또는 폭력적인 메시지ㆍ화상ㆍ음성 기타 공서양속에 반하는 정보를
        공개 또는 게시하는 행위
        <br />
        11. 사실관계를 왜곡하는 정보제공 등 기타 회사가 부적절하다고 판단하는
        행위
        <br />
        12. 기타 관계 법령이나 회사에서 정한 규정에 위배되는 행위
        <br />② 회원이 개인의 정보를 허위 또는 잘못 기재하여 생기는 불이익에
        대한 책임은 회원 본인에게 있으며, 이에 대해 회사는 아무런 책임이
        없습니다.
        <br />③ 회사는 회원이 본 약관 또는 당사 정책에 위반되는 내용의 메시지를
        프로필 영역에 작성하거나 타 회원에게 해당 메시지 내용을 발송하는 경우
        별도의 통지 없이 작성 또는 발송된 내용을 임의로 수정 또는 삭제할 수
        있습니다.
        <br />④ 제1항에 해당하는 행위를 한 회원이 있을 경우 회사는 본 약관
        제12조, 제13조에서 정한 바에 따라 회원의 회원자격을 적절한 방법으로 제한
        및 정지, 상실시킬 수 있으며, 특히 아래 각 호에 해당하는 행위를 할
        경우에는 각각에 대응되는 조치를 취할 수 있습니다. 다만, 회사가 취할 수
        있는 조치는 아래 각 호에 한정되지 아니하며, 회사가 조치를 취할 수 있는
        회원의 불법적인 행위 역시 아래 각 호의 경우에 한정되지 아니합니다.
        <br />
        1. 타 회원들에 대한 무단 마케팅(매칭 대상 서비스에 대한 내용 외 본인의
        영리적 목적을 위한 내용을 무단으로 작성하는 것을 의미하며 광고 등을
        포함): 1년간 모든 기능 사용 정지 등<br />
        2. 타 회원들에 대한 부적절한 대화 시도 및 메시지 발송 등(욕설, 성희롱,
        기타 부적절한 표현을 포함함): 1년간 모든 기능 사용 정지 등<br />
        3. 타 회원들에게 허위사실을 유포하거나 표시광고를 위반하는 경우: 1회
        경고 후 재 적발시 영구 기간 동안 모든 기능 사용 정지(영구 제재) 등<br />
        4. 파트너 회원의 의무 및 서비스 정책 위반: 1년간 모든 기능 사용 정지 등
        <br />⑤ 회원은 그 귀책사유로 인하여 회사나 다른 회원이 입은 손해를
        배상할 책임이 있습니다.
        <br />⑥ 회원은 서비스의 이용과 관련하여 관계법령, 약관, 세부이용지침,
        서비스 이용안내 및 회사가 통지한 공지사항 등을 준수하여야 하며, 이를
        수시로 확인하여야 합니다.
      </p>
      <br />
      <br />
      <p className="content">
        제 18 조 (고객의 의무)
        <br />
        ① 고객은 제공받기 원하는 제조 물품 및 서비스에 대한 내용으로 요청서를
        작성하여 회사에 제공하고 회사가 이를 다수의 파트너 회원에게 전달하는
        것에 대하여 동의합니다.
        <br />② 고객은 허위나 과장된 내용으로 요청서를 작성하거나 또는 제조
        주문을 발주하고자 하는 의사가 없음에도 요청서를 작성할 수 없으며 허위 및
        과장된 내용으로 요청서를 작성함에 따라 적절한 파트너의 대응을 제공받지
        못하거나 법적인 문제가 발생할 경우 이에 대한 책임은 고객에게 있습니다.
        <br />③ 고객은 파트너와 거래를 진행하기 전에 반드시 파트너 회원과 상세한
        견적과 거래의 조건을 정확하게 확인해야 합니다. 발주하려는 제조 주문의
        내용과 거래의 조건을 명확하게 확인하지 않아서 발생한 모든 손실과 손해는
        회사가 책임지지 않습니다.
        <br />④ 고객이 요청서 작성 시 첨부하는 도면 등의 정보가 파트너에게
        전달된 이후 파트너의 과실로 인하여 정보가 유출됨에 따라 고객에게 발생한
        모든 손실과 손해는 해당 파트너 회원의 책임이며 회사는 해당 정보유출에
        대하여 회사의 직접적인 고의 또는 중대한 과실이 개입되지 않는 한 책임지지
        않습니다.
      </p>
      <br />
      <br />
      <p className="content">
        제 19 조 (파트너 회원의 서비스 관련 의무)
        <br />
        ① 파트너 회원은 고객 회원의 요청서에 대한 대응 이후 발생하는 일체의
        업무를 처리하며, 이를 신의성실 하게 이행하여야 할 의무가 있습니다. 또한
        제조 물품의 제공과 관련하여 발생하는 모든 책임과 의무를 부담합니다.
        <br />② 파트너 회원의 상담 내용, 견적 및 프로필 등에 허위 또는 과장,
        기재누락, 오기가 있어서는 아니되며 이를 준수하지 아니하여 발생하는 모든
        법적문제에 대하여는 파트너 회원이 모든 책임을 부담합니다. 파트너 회원은
        해당 견적 및 제공 용역에 대한 진실성과 적법성에 대해 보증합니다.
        <br />③ 파트너 회원은 작성한 견적, 과업의 내용, 납품 시기 등 내용의 변경
        사항이 있을 경우 이를 바로 갱신하여야 할 의무를 집니다.
        <br />④ 파트너 회원은 관련법령 등을 위반하는 불법적인 행위를 하지
        않는다는 점에 대해 보증하며 불법적이거나 본 이용약관을 위반하는 내용으로
        견적 및 허위 내용을 작성하여 고객에게 송부할 수 없습니다.
        <br />⑤ 파트너 회원은 견적을 협의한 고객과의 캐파 서비스에서 제공하는
        메시지 기능이나 전화 등 별도의 연결수단을 통하여 세부사항을 조정할 수
        있습니다. 회사는 고객과 파트너 회원의 연결을 위한 인터렉션 제공 이후에
        양자 간에 발생한 일체의 문제에 대하여 어떠한 책임도 지지 않습니다.
        <br />⑥ 파트너 회원은 요청서의 내용과 도면 등 프로젝트 정보를 안전하게
        관리하여야 하며, 고객의 동의 없이 제3에게 유출하거나 다른 목적으로
        활용해서는 아니되며, 이를 위반하여 문제가 발생할 경우 고객의 피해를
        보상해야 할 뿐 아니라 파트너 회원의 노력과 비용으로 회사를 면책시켜야
        하며 서비스의 대외 이미지 실추 등으로 인하여 회사에 유무형적으로 손해가
        발생할 경우 이를 배상할 책임이 있습니다.
      </p>
      <br />
      <br />
      <p className="content">
        제 20 조 (회원의 ID 및 비밀번호에 대한 의무)
        <br />
        ① 원칙적으로 ID와 비밀번호 및 기타 서비스 사용을 위해 필요한 개인정보에
        관한 관리책임은 회원에게 있습니다.
        <br />② 회원은 자신의 ID 및 비밀번호를 제 3자에게 이용하게 해서는 안
        되며, 그로 인한 모든 책임은 회원에게 있습니다.
        <br />③ 회원이 자신의 ID 및 비밀번호를 도난당하거나 제3자가 사용하고
        있음을 인지한 경우에는 바로 회사에 통보하고 회사의 안내가 있는 경우 그에
        따라야 합니다. 만약 회원이 위 사실을 인지하고도 회사에 대한 통보를
        지체함으로써 발생한 손해에 대해서 회사는 배상할 의무가 없습니다.
      </p>
      <br />
      <br />
      <p className="content">
        제 21 조 (게시물 또는 내용물의 삭제)
        <br />
        ① 회원이 회사에 등록하는 게시물 및 타인 게시물의 활용 등으로 인하여 본인
        또는 타인에게 손해나 기타 문제가 발생하는 경우 회원은 이에 대한 책임을
        지게 되며, 회사는 이에 대하여 책임을 지지 않습니다.
        <br />② 회사는 회원이 게시하거나 등록한 내용이 제17조 제 1항의 규정에
        위반될 경우 사전 통지나 동의 없이 이를 삭제할 수 있습니다. 그러나 회사가
        이러한 정보의 삭제 등을 할 의무를 지는 것은 아닙니다.
      </p>
      <br />
      <br />
      <p className="content">
        제 22 조 (저작권의 귀속 및 권리, 의무)
        <br />
        ① 회사가 작성한 저작물에 대한 저작권 기타 지식재산권은 회사에
        귀속합니다.
        <br />② 회원은 회사의 서비스를 이용함으로써 얻은 정보를 회사의 사전승낙
        없이 복제, 전송, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로
        이용하거나 제3자에게 이용하게 하여서는 안 됩니다.
        <br />③ 회원이 작성한 게시물에 대한 모든 권리 및 책임은 이를 게시한
        회원에게 있습니다.
        <br />④ 회사는 회원 또는 요청자가 사이트에 게시한 다음 각 호의 게시물을
        회사의 서비스 제공과 관련한 마케팅 용도로 사용ㆍ복제ㆍ수정ㆍ출판ㆍ배포
        할 수 있습니다. 단, 회사는 게시물 이용시 파트너 또는 요청자의 개인정보가
        포함되지 않는 범위에서 해당 정보를 처리하고, 최대한 작성자의 권리를
        보호할 수 있도록 노력할 것입니다.
        <br />
        1. 파트너의 프로필 정보
        <br />
        2. 요청자 및 파트너가 작성한 후기(이에 포함된 글 및 사진)
      </p>
      <br />
      <br />
      <p className="content">
        제 23 조 (제 3자의 지식재산권 및 저작권 공지)
        <br />
        ① 회사는 제3자의 지식재산권을 존중하며, 제3자의 지식재산을 반복적으로
        침해하는 이용자가 있는 경우 이용계약을 해지할 수 있습니다.
        <br />② 자신의 지식재산권이 침해되었다고 회사에 통보하는 제 3자의 경우,
        그 사실을 소명하기 위한 다음 정보를 포함하는 서면 통지를 작성하여 회사에
        제공하고 회사에게 그 지식재산권 침해 자료의 복제, 전송을 중단시킬 것을
        요구할 수 있습니다. 다만, 권리주장자가 저작권신탁업자이거나 최근 1년
        이내에 반복적인 침해행위에 대하여 권리자임을 소명할 수 있는 자료를 이미
        제출한 사실이 있는 경우에는 요청서만 제출하여도 됩니다. (저작권법 시행령
        제40조)
        <br />
        1. 자신이 그 지식재산의 권리자로 표시된 증서 사본 또는 그에 상당하는
        자료
        <br />
        2. 자신의 성명 등이나 이명으로서 널리 알려진 것이 표시되어 있는 저작물
        등의 사본 또는 그에 상당하는 자료
        <br />③ 회사는 이용자가 제출 또는 등록한 콘텐츠가 제 3자의 저작권을
        침해했다는 통지를 받은 경우, 저작권법 및 관련 법령에 따라 즉시 해당
        자료의 복제, 전송을 중단시키고 권리주장자와 이용자(콘텐츠 게시자)에게 그
        사실을 통보합니다. (저작권법 제103조 제2항)
        <br />④ 제3항의 통보를 받은 이용자는 자신의 복제, 전송이 정당한 권리에
        의한 것임을 소명하여 그 복제, 전송의 재개를 요구하려는 복제, 전송자는
        회사로부터 복제, 전송의 중단을 통보받은 날로부터 30일 이내에
        문화체육관광부령으로 정하는 재개요청서(전자문서로 된 요청서를
        포함합니다)에 다음 각 호의 어느 하나에 해당하는 소명 자료(전자문서를
        포함합니다)를 첨부하여 “회사”에 제출하여야 합니다. (저작권법 시행령
        제42조)
        <br />
        1. 자신이 그 저작물 등의 권리자로 표시된 저작권 등의 등록증 사본 또는
        그에 상당하는 자료
        <br />
        2. 자신의 성명 등 또는 널리 알려진 이명이 표시되어 있는 그 저작물 등의
        사본 또는 그에 상당하는 자료
        <br />
        3. 저작권 등을 가지고 있는 자로부터 적법하게 복제, 전송의 허락을 받은
        사실을 증명하는 계약서 사본 또는 그에 상당하는 자료
        <br />
        4. 그 저작물 등의 저작재산권의 보호기간이 끝난 경우 그 사실을 확인할 수
        있는 자료
        <br />⑤ 회사는 본 조항과 관련된 제 3자 또는 이용자의 통지가 위에서
        기술한 요구사항에 부합하지 않는 경우 해당 통지는 유효하지 않은 것으로
        간주할 수 있으며, 이에 대해 응하거나 회답해야 할 책임이 없습니다.
      </p>
      <br />
      <br />
      <p className="title">제 7 장 기타</p>
      <br />
      <p className="content">
        제 24 조 (양도의 금지)
        <br />
        회원은 서비스 이용 권리를 타인에게 대여, 양도 또는 증여 등을 할 수
        없으며, 또한 질권의 목적으로도 사용할 수 없습니다.
      </p>
      <br />
      <br />
      <p className="content">
        제 25 조 (면책)
        <br />
        ① 회사는 다음 각 호의 경우에는 고의 또는 중대한 과실이 없는 한
        구매자에게 발생한 손해에 대하여 서비스 제공에 관한 책임이 면제됩니다.
        <br />
        1. 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는
        경우
        <br />
        2. 회원의 귀책사유로 인한 서비스 이용의 장애가 발생한 경우
        <br />
        3. 기간통신사업자가 전기통신서비를 중단하거나 정상적으로 제공하지 아니한
        경우
        <br />② 회사는 회원들의 요청서와 견적을 전달하거나 이와 관련한 부수적인
        서비스를 제공하는 중개 플랫폼으로서 서비스 이용에 따른 회원의 수익을
        보장하거나 책임을 약속하지 않습니다.
        <br />③ 회원 상호 간 서비스를 매개로 하여 거래를 한 경우 이용자 각자의
        의무이행에 대한 책임은 각 이용자에게 있습니다. 따라서 회사는 파트너와
        요청자 간 서비스 이용과정에서 거래와 관련하여 발생한 의무의 불완전 이행,
        이행 지체 등 과업 이행 미비, 사후처리, 대금 정산, 완성품의 하자,
        청약철회, 물품의 반품 등 어떠한 법적 분쟁 및 사후처리에 대해서도
        개입하지 않으며, 회사의 고의 또는 중대한 과실이 개입되어 있지 않는 한
        이에 대하여 책임을 부담하지 않습니다.
        <br />④ 회사는 회원이 서비스에 게재한 정보, 자료, 사실의 신뢰도, 정확성
        등의 내용에 관하여는, 회사가 해당 정보의 허위성을 명백히 인지하였다는
        특별한 사정이 없는 한, 책임을 지지 않습니다. 회사가 본 약관과 관련
        법령에 따른 조치를 취한 경우에도 같습니다.
        <br />⑤ 회사는 무료로 제공하는 서비스에 대하여 회사의 고의 또는 중과실로
        인하여 발생한 손해가 아닌 한 책임을 부담하지 않습니다.
      </p>
      <br />
      <br />
      <p className="content">
        제 26 조 (손해배상)
        <br />
        당사자 일방이 본 계약상 의무를 위반함으로 인하여 상대방에게 손해가
        발생한 경우, 귀책사유 있는 당사자는 상대방이 입은 손해를 배상합니다.
      </p>
      <br />
      <br />
      <p className="content">
        제 27 조 (비밀유지)
        <br />
        ① 당사자는 서비스를 이용하는 과정에서 알게 된 상대방의 정보 또는
        이용자의 정보를 제3자에게 누설, 공개하지 아니합니다.
        <br />② 본 조는 이용계약이 해지되거나 서비스 제공이 중단된 이후에도
        유효합니다.
      </p>
      <br />
      <br />
      <p className="content">
        제 28 조 (고객센터)
        <br />
        ① 회사는 이용자에게 생긴 문제를 해결하기 위해 최선을 다하며 이를 위하여
        이용자로부터 고객센터에 제출되는 불만사항 및 의견은 우선적으로 그 사항을
        처리합니다. 다만, 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와
        처리일정을 즉시 통보합니다.
        <br />② 회사와 이용자 간 분쟁이 발생할 경우 양자 간 합의에 의하여
        처리하는 것을 원칙으로 하며 회사는 이용자 간 발생한 법적 문제에 대하여
        고의 또는 중과실이 없는 한 책임이 없습니다. 그러나 회사는 고객센터를
        통하여 피해 사항을 적극적으로 청취하여 이용자들 간 발생한 문제가
        해결하는 데에 도움이 될 수 있도록 최선을 다합니다.
      </p>
      <br />
      <br />
      <p className="content">
        제 29 조 (재판권 및 준거법)
        <br />
        ① 회사와 이용자간 발생한 분쟁에 관한 소송의 관할은 민사소송법에 따라
        정합니다.
        <br />② 회사와 이용자 간에 제기된 소송에는 대한민국법을 적용합니다.
      </p>
      <br />
      <br />
      <p className="title">부칙</p>
      <br />
      <p className="content">
        제1조 (시행일) 이 약관은 2021년 8월 18일부터 시행합니다.
      </p>
    </div>
  );
};

export const ConsentForMarketing = () => {
  return (
    <div className="agreement-content">
      <p className="content">
        {`
              ㈜에이팀벤처스는 개인정보보호법 및 정보통신망이용촉진및정보보호등에관한법률 등 관계법령에 따라 광고성정보를 전송하기 위해 수신자의 사전 수신동의를 받고 있으며, 광고성정보 수신자 의 수신동의여부를 정기적으로 확인합니다.

              - 전송방법
              고객님의 핸드폰 문자메시지(SMS), Email 등을 통해 전달될 수 있습니다.

              - 전송내용
              발송되는 마케팅 정보는 수신자에게 ㈜에이팀벤처스 및 캐파서비스 관련하여 제공하는 혜택 정보, 각종 이벤트 정보 등 광고성 정보로 관련 법의 규정을 준수하여 발송됩니다. 단, 광고성 정보 이외 의무적으로 안내되어야 하는 정보성 내용은 수신동의 여부와 무관하게 제공됩니다.

              - 철회안내
              고객님은 수신 동의 이후에라도 의사에 따라 동의를 철회할 수 있으며, 수신을 동의하지 않아도 회사가 제공하는 기본적인 서비스를 이용할 수 있으나, 당사의 마케팅 정보를 수신하지 못할 수 있습니다.

              - 수신동의 변경
              캐파서비스 홈페이지 우측 하단의 채널톡을 통해 수신동의를 변경(동의/철회)할 수 있습니다.

              - 개인정보 이용 상세내용
              휴대전화번호,이메일주소 - 혜택 정보,각종 이벤트 정보 등 광고성 정보 제공
          `}
      </p>
    </div>
  );
};

export const ConsentToConnect = () => {
  return (
    <div className="agreement-content">
      <p className="date">시행일자 : 2022년 3월 18일</p>
      <p className="date">공고일자 : 2022년 3월 18일</p>
      <br />
      <p className="title">제 1 장 총칙</p>
      <br />
      <p className="content">
        본 약관은 주식회사 에이팀벤처스(이하 “회사”라 합니다)가{' '}
        <b>CAPA Connect</b>
        웹페이지(<b>connect.capa.ai</b>, 이하 “서비스 페이지”라 합니다) 및
        모바일 어플리케이션(캐파 커넥트, 이하 “앱”이라 합니다)을 통해 제공하는
        캐파 커넥트 서비스 및 관련 제반 서비스(이하 “서비스”라 합니다)의 이용과
        관련하여 회원과 회사의 관계와 권리의무 등을 규정함을 목적으로 합니다.
      </p>
      <br />
      <br />
      <p className="title">제 2 조 (정의)</p>
      <br />
      <p className="content">
        ① 본 약관에서 사용하는 정의는 다음과 같습니다.
        <br /> 1. 서비스: 회사가 제공하는 모든 서비스 및 관련 제반 서비스를
        의미합니다.
        <br /> 2. 회원: 회사와 이용계약을 체결하여 회원등록을 한 자로서,
        계속적으로 서비스를 이용할 수 있는 개인이나 법인을 의미합니다.
        <br /> 3. 그룹: 회원의 초대를 받은 복수의 회원으로 구성된 서비스 내
        모임으로서, 한 그룹의 회원들은 동시에 그룹 내 업로드 된 파일에 접근할 수
        있습니다.
        <br />② 이 약관에서 사용하는 용어의 정의는 제1항에서 정하는 것을
        제외하고는 관련법령에서 정하는 바에 의하며, 관련 법령에서 정하지 않는
        것은 일반적인 상관례에 의합니다.
      </p>
      <br />
      <br />
      <p className="title">제 3 조 (약관의 명시와 개정)</p>
      <br />
      <p className="content">
        ① 회사는 본 약관의 내용과 상호, 영업소 소재지, 대표자의 성명,
        사업자등록번호, 연락처 등을 회원이 쉽게 알 수 있도록 서비스 초기화면에
        게시하거나 기타의 방법으로 회원에게 공지합니다. 다만, 회사는 약관의
        내용을 회원이 연결화면을 통하여 볼 수 있도록 할 수 있습니다.
        <br />
        ② 회사는 「약관의 규제에 관한 법률」, 「전기통신기본법」,
        「전기통신사업법」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」,
        「개인정보 보호법」 등 관련 법령을 위배하지 않는 범위에서 본 약관을
        개정할 수 있습니다.
        <br />
        ③ 회사는 약관이 변경되는 경우에는 적용일자 및 변경사유를 명시하여 현행
        약관과 함께 서비스 페이지와 앱 공지사항 또는 팝업에 그 적용일자로부터
        최소한 7일 이전부터 적용일 후 상당한 기간 동안 게시합니다. 다만, 회원의
        권리, 의무에 중대한 영향을 주는 변경의 경우에는 적용일자 30일 전부터
        SMS, MMS, 이메일을 통하여 회원이 인지할 수 있는 방법으로 개별 통지하도록
        합니다.
        <br />④ 회원은 개정되는 약관의 전체 또는 일부 내용에 동의하지 않을 수
        있습니다. 다만, 회사가 약관 변경의 공지를 통해 개정된 약관의
        적용일자로부터 7일(회원에게 불리하거나 중대한 사항의 변경인 경우에는
        30일) 내에 회원이 동의하지 않는다는 의사를 표시하지 아니하면 승인한
        것으로 본다는 뜻을 고지하였음에도, 회원이 동의하지 않는다는 의사를
        표시하지 않는 경우 약관의 변경에 동의한 것으로 봅니다. 개정/변경 약관에
        대하여 거부의사를 표시한 회원은 이용계약의 해지 또는 회원 탈퇴를 선택할
        수 있습니다.
      </p>
      <br />
      <br />
      <p className="title">제 4 조 (약관 외 준칙)</p>
      <br />
      <p className="content">
        ① 회사는 필요한 경우 본 약관의 하위 규정으로 운영 정책을 정할 수 있으며,
        본 약관과 운영 정책의 내용이 상충되는 경우에는 본 약관의 내용이 우선
        적용됩니다.
        <br />② 본 약관에서 규정하지 않은 사항에 관해서는 「약관의 규제에 관한
        법률」, 「전기통신기본법」, 「전기통신사업법」, 「정보통신망 이용촉진 및
        정보보호 등에 관한 법률」, 「개인정보 보호법」 등의 관계법령에 따릅니다.
      </p>
      <br />
      <br />
      <p className="title">제 5 조 (이용계약의 성립)</p>
      <br />
      <p className="content">
        ① 회사와 회원 사이의 서비스 이용계약(이하 “이용계약”이라 합니다)은
        서비스를 이용하고자 하는 자(이하 “가입신청자”라 합니다)가 회원 가입에
        필요한 정보를 기입한 후 본 약관과 개인정보수집 및 이용 등에 명시적인
        동의의 의사표시를 함으로써 이용신청을 하고, 회사가 이에 대하여 이용
        승낙을 함으로써 성립합니다.
        <br />② 제1항의 이용 승낙은 회사가 본 약관에 대한 동의를 확인하고
        회원가입이 완료되었음을 알리는 문구를 통지함으로써 이루어지며, 이러한
        승낙의 통지가 가입신청자에게 도달하면 이용계약이 성립합니다.
        <br />③ 회사는 만 14세 미만 자의 이용신청을 제한하고 있습니다. 이용신청
        단계에서 만 14세 이상에 해당한다는 안내에 동의표시를 함으로써 회원은 만
        14세 이상임을 진술하고 보증합니다.
        <br />④ 회사는 다음 각 호에 해당하는 신청에 대하여는 가입신청자의
        이용신청에 대하여 승낙하지 않거나, 사후에 이용계약을 해지할 수 있습니다.
        <br />
        1. 가입신청자가 본 약관에 의하여 과거 회원자격을 상실한 사실이 있는
        경우. 다만, 회원자격 상실 후 3년이 경과한 자로서 회사의 재가입 승낙을
        얻은 경우는 예외로 합니다. <br />
        2. 중복 이메일을 사용하는 경우 <br />
        3. 회원의 요청에 의하여 탈퇴한 때로부터 1개월이 경과하지 아니한 경우{' '}
        <br />
        4. 가입신청자가 기입한 회원정보에 명백한 허위 기재, 누락 또는 오기가
        있는 경우 <br />
        5. 만 14세 미만 자인 경우 <br />
        6. 가입신청자의 귀책사유로 인하여 이용신청에 대한 승낙이 불가능하거나
        기타 규정한 제반 사항을 위반하여 신청한 경우
        <br />⑤ 회사는 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상
        문제가 있는 경우에는 승낙을 유보할 수 있습니다.
        <br />⑥ 회사가 본 조 제4항 및 제5항에 따라 가입신청자의 이용신청을
        승낙하지 않거나 유보하는 경우 또는 사후에 이용계약을 해지하는 경우,
        회사는 원칙적으로 그 사실을 해당 사유와 함께 가입신청자에게 통지하여야
        합니다. ⑦ 본 약관은 회원이 본 약관에 동의한 날부터 회원 탈퇴 시까지
        적용하는 것을 원칙으로 합니다. 다만, 본 약관의 일부 조항은 회원 탈퇴
        후에도 유효하게 적용될 수 있습니다.
      </p>
      <br />
      <br />
      <p className="title">제 6 조 (회원의 계정 및 비밀번호)</p>
      <br />
      <p className="content">
        ① 회사는 회원의 계정정보를 통하여 당해 회원의 서비스 이용가능 여부 등
        제반 이용자 관리 업무를 수행합니다.
        <br />
        ② 회원은 자신의 계정정보를 선량한 관리자로서의 주의의무를 다하여
        관리하여야 하며, 회원은 서비스의 이용을 위하여 사용하는 비밀번호에 대한
        보호 및 관리 책임을 부담합니다.
        <br />③ 회원이 자신의 아이디 및 비밀번호의 유출 또는 제3자 사용에 대하여
        인지하고도 비밀번호를 변경하지 아니한 경우 또는 이와 같은 사정을 회사에
        통지하지 않거나 회사의 조치에 응하지 아니한 경우 등 회원이 본 조의
        의무를 준수하지 않아 발생하는 모든 손실이나 손해 등 불이익에 대한 책임은
        회사의 고의 또는 중과실이 없는 한 회원에게 있습니다.
      </p>
      <br />
      <br />
      <p className="title">제 7 조 (회원에 대한 통지)</p>
      <br />
      <p className="content">
        ① 회사는 서비스와 관련하여 회원에게 개별적 통지가 필요한 경우 SMS, MMS
        또는 이메일로 통지할 수 있습니다.
        <br />② 회사는 회원 전체에 대한 통지의 경우 7일 이상 서비스 페이지 또는
        앱 공지사항 페이지에 게시함으로써 제1항의 통지에 갈음할 수 있습니다. 단,
        회원의 권리, 의무에 중대한 영향을 주는 내용을 통지하는 경우에는 적용일자
        30일 전부터 게시하도록 하고, 회원의 이메일 주소로 개별 통지하도록
        합니다.
      </p>
      <br />
      <br />
      <p className="title">제 8 조 (서비스의 제공 및 변경)</p>
      <br />
      <p className="content">
        ① 회사는 회원의 이용신청을 승낙한 시점부터 회원에게 서비스를 제공하며,
        회원은 회사가 제공하는 서비스를 본 약관, 운영정책 등 회사가 정한 규칙에
        따라 이용할 수 있습니다.
        <br />② 회사는 회원의 회원가입을 승낙한 때부터 아래의 내용과 같은
        서비스를 제공하며, 연중 무휴, 1일 24시간 제공함을 원칙으로 합니다.
        <br />
        1. 파일의 업로드 및 열람, 수정 서비스
        <br />
        2. 파일 공유를 위한 그룹 생성 및 관리 서비스
        <br />
        3. 그룹 내 파일 공유 및 주석 달기, 그룹 회원 간 채팅 서비스
        <br />
        4. 그룹 내 다운로드 권한을 부여받은 회원에 한하여 파일의 다운로드 및
        수정 서비스
        <br />
        5. 기타 회사가 자체 개발하거나 다른 회사와의 제휴계약 등을 통하여
        회원에게 제공하는 일체의 서비스
        <br />③ 회사가 회원에게 제공하는 서비스에 대하여 회사는 제작, 변경,
        유지, 보수에 관한 포괄적인 권한을 가집니다. 회사는 새로운 서비스 내용,
        각종 버그 패치 등 서비스의 운영상 또는 기술상 필요한 경우, 제공하고 있는
        서비스의 전부 또는 일부를 상시적으로 수정, 추가, 폐지 등 변경할 수
        있습니다.
        <br />④ 회사는 서비스의 내용이 변경되는 경우 서비스 페이지 및 앱에
        공지하도록 합니다. 다만, 서비스 내용의 변경으로 인하여 회원의 권리,
        의무에 불리하거나 중요한 영향을 미치는 경우에는 해당 내용을 그
        적용일로부터 30일 전에 서비스 페이지 및 앱에 공지하고, 회원의 이메일로
        개별 통지합니다. 위 서비스 내용의 변경으로 본 약관의 변경이 필요한
        경우에는 제3조에서 정한 절차에 따릅니다.
        <br />⑤ 회사는 서비스가 변경된 원인이 회사의 고의 또는 중대한 과실로
        인한 경우를 제외하고는 서비스의 변경으로 발생하는 문제에 대해서 책임을
        부담하지 않습니다.
      </p>
      <br />
      <br />
      <p className="title">제 9 조 (정보의 제공 및 광고의 게재)</p>
      <br />
      <p className="content">
        ① 회사는 회원이 서비스 이용 중 필요하다고 인정되는 다양한 정보를 서비스
        페이지 및 앱에 게시할 수 있습니다.
        <br />② 회사는 다양한 정보를 회원의 명시적인 사전 동의가 있는 경우에는
        이메일이나 서비스 페이지 및 앱 알림 등의 방법으로도 제공할 수 있습니다.
        회원은 법령에 따라 회사에게 부과된 의무이행을 위한 절차안내 등을
        제외하고는 언제든지 이메일 등에 대해서 수신을 거절할 수 있습니다.
        <br />③ 회사는 서비스 운영과 관련하여 서비스 화면, 이메일 등에 광고를
        게재할 수 있습니다. 회사는 명시적인 사전 동의를 한 회원에 대하여는
        광고가 포함된 이메일 등을 발송할 수 있으며, 광고가 포함된 이메일 등을
        수신한 회원은 수신을 거절할 수 있습니다. 회사는 수신거절에 필요한 절차를
        회원에게 제공합니다.
      </p>
      <br />
      <br />
      <p className="title">제 10 조 (지식재산권 등)</p>
      <br />
      <p className="content">
        ① 서비스 및 서비스 내 회사가 제작한 콘텐츠 등에 대한 저작권 및
        지식재산권 등 일체의 권리는 회사에 귀속됩니다.
        <br />② 서비스 내 회원이 작성한 도면 파일 등 콘텐츠의 저작권 및
        지식재산권은 회원에게 귀속됩니다.
        <br />③ 회원은 서비스 내에서 작성한 도면 파일 등 콘텐츠에 관하여 제3자의
        저작권 기타 지식재산권을 침해하지 아니할 것을 보증합니다. 회원이 작성한
        콘텐츠와 관련하여 회사와 제3자가 분쟁이 발생할 경우 회원은 자신의 책임과
        비용으로 회사를 완전히 면책시켜야 합니다. 만일 회사와 제3자간 분쟁으로
        인하여 회사에게 손해 및/또는 지출 비용이 발생할 경우 회원은 회사에게
        일체의 손해 및/또는 비용을 배상하여야 합니다. 단, 회사에게 고의 또는
        중대한 과실이 있는 부분에 대해서는 그러하지 아니합니다.
        <br />④ 회원은 서비스 회원으로서의 지위 및 이용권한을 타인에게
        양도하거나 판매, 담보제공 등 처분하여서는 아니 됩니다.
      </p>
      <br />
      <br />
      <p className="title">제 11 조 (이용계약의 해제·해지 등)</p>
      <br />
      <p className="content">
        ① 회원은 회사가 정한 절차에 따라 탈퇴 신청을 함으로써 이용계약을 해지할
        수 있습니다.
        <br />② 회원이 계약을 해지하는 경우 관련 법령 및 개인정보처리방침에 따라
        회사가 회원정보를 보존하는 경우를 제외하고 회사는 지체없이 회원의
        개인정보를 파기합니다.
        <br />③ 회원의 요청에 따라 삭제된 정보는 복구할 수 없습니다.
        <br />④ 다음 각 호의 사유에 해당하는 경우 회사는 회원의 동의 없이
        이용계약을 해지하거나 서비스 이용을 중지할 수 있습니다.
        <br />
        1. 회원 정보를 허위로 기재한 경우
        <br />
        2. 본 약관 및 관련된 별도의 서비스 준수 사항을 위반한 경우
        <br />
        3. 타인의 지식재산권을 침해한 경우
        <br />
        4. 회사의 영업을 방해하거나 회사가 제공하는 서비스와 동일한 업무를
        행하는 경우
        <br />
        5. 회사 또는 다른 회원의 보안을 위협하는 행위를 한 경우
        <br />
        6. 회원의 개인정보를 본 서비스 외의 목적으로 이용한 경우
        <br />
        7. 기타 관련법령을 위반하거나 부정한 목적으로 본 서비스를 이용한 경우
        <br />② 회사의 이용제한 조치로 강제 탈퇴 되는 경우 회원의 계정에 보유
        중인 파일 등 일체의 콘텐츠는 사전 통지 후 삭제됩니다.
      </p>
      <br />
      <br />
      <p className="title">제 12 조 (회사의 의무)</p>
      <br />
      <p className="content">
        ① 회사는 본 약관 및 관련법령에서 금지하는 행위 및 미풍양속에 반하는
        행위를 하지 않으며, 본 약관에서 정하는 바에 따라 지속적이고 안정적인
        서비스의 제공을 위하여 최선을 다하여 노력합니다.
        <br />② 회사는 회원으로부터 제기되는 의견이나 불만이 정당하다고
        객관적으로 인정될 경우에는 합리적인 기간 내에 신속하게 처리하여야
        합니다. 다만, 처리에 장기간이 소요되는 경우 회원에게 게시판 또는 이메일
        등을 통하여 지체 사유를 안내하고 처리과정 및 처리결과를 전달합니다.
        <br />③ 회사는 천재지변 등 불가항력이나 시스템 장애로 인하여 서비스가
        중단될 경우 빠른 시일 내에 정상적인 서비스를 제공할 수 있도록
        노력합니다.
        <br />④ 회사는 회원이 안전하게 서비스를 이용할 수 있도록
        개인정보(신용정보 포함)를 포함한 일체의 개인정보 보호를 위한
        보안시스템을 갖추어야 하며 개인정보처리방침을 공시하고 준수합니다.
        <br />⑤ 회사는 회원이 사전에 명시적으로 동의하지 않은 영리목적의 광고성
        이메일, 알림 등을 발송하지 않습니다.
      </p>
      <br />
      <br />
      <p className="title">제 13 조 (회원의 의무)</p>
      <br />
      <p className="content">
        ① 회원은 회원가입 신청 또는 회원정보 변경 시 회사에 제공되는 모든 사항을
        사실대로 작성하여야 하며, 허위 또는 타인의 정보를 등록할 경우 회원의
        권리를 주장할 수 없습니다.
        <br />② 회원 서비스 이용과 관련하여 다음 각 호의 행위를 하여서는 안
        됩니다.
        <br />
        1. 회원가입 신청 또는 정보 변경 시 허위 정보를 입력하거나 정보를
        누락하는 행위
        <br />
        2. 회사의 업무를 방해하는 행위
        <br />
        3. 회사 또는 타인의 명예를 훼손하거나 모욕하는 행위
        <br />
        4. 회사 또는 타인의 지식재산권을 침해하는 행위
        <br />
        5. 타인으로 가장하거나 타인과의 관계를 허위로 명시하는 행위, 다른 회원의
        계정 및 비밀번호를 도용, 부정하게 사용하는 행위
        <br />
        6. 서비스를 회사의 동의 없이 상업적 목적으로 이용하는 행위
        <br />
        7. 회사의 직원이나 운영자를 가장, 사칭하거나 또는 타인의 명의를 도용하여
        문서를 등록하거나 메일을 발송하는 행위
        <br />
        8. 회사의 동의 없이 광고, 홍보, 청약에 관한 자료 또는 스팸, 피싱 등의
        메시지를 전송하는 행위
        <br />
        9. 회사가 제공하는 서비스 이용방법에 의하지 아니하고 비정상적인 방법으로
        서비스를 이용하거나 회사의 정보처리시스템에 접근하는 행위
        <br />
        10. 매크로 프로그램 등을 사용하여 서비스를 부정한 방법으로 이용하는 행위
        <br />
        11. 바이러스, 스파이웨어, 애드웨어 등 회사나 다른 회원의 소프트웨어를
        손상시킬 수 있는 컴퓨터 코드, 파일, 소프트웨어 등을 전송, 게시하는 행위
        <br />
        12. 기타 회사의 약관을 위반하는 행위
        <br />
        13. 기타 관련 법령을 위반하거나 미풍양속을 해하는 행위
        <br />③ 회원은 개인정보가 타인에게 노출되지 않도록 철저하게 관리하여야
        하며, 타인이 회원의 계정을 사용하고 있음을 인지한 경우에는 즉시 회사에
        통보하고 적절한 조치를 취해야 하며 회사의 안내가 있는 경우에는 그에
        따라야 합니다.
        <br />④ 회사는 본 조를 위반하여 회원에게 발생한 불이익에 대하여 회사의
        고의 또는 중대한 과실이 없는 한 어떠한 책임도 지지 않습니다.
      </p>
      <br />
      <br />
      <p className="title">제 14 조 (개인정보의 보호 및 관리)</p>
      <br />
      <p className="content">
        ① 회사는 「개인정보 보호법」 등 관계 법령이 정하는 바에 따라 회원의
        개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 사용에 관해서는
        관련 법령 및 회사의 개인정보처리방침을 따릅니다.
        <br />
        {`②\t회원은 가입 신청 시 기재한 정보가 변경된 경우 지체없이 해당 정보를 수정하여야 하며, 회사는 회원이 이를 수정하지 않아 발생한 불이익에 대하여 책임을 지지 않습니다. 회원은 서비스 페이지 및 앱 내 [마이페이지 > 계정 정보 > 회원정보 수정]에서 언제든지 회원 정보를 열람하고 수정할 수 있습니다.`}
        <br />③ 회사는 회원의 개인정보를 본인의 동의 없이 제3자에게 제공, 분실
        또는 유출하여서는 아니됩니다. 단, 회원의 동의를 얻은 경우, 기타 법률에
        특별한 규정이 있는 경우에는 예외로 합니다.
        <br />④ 회사는 회원의 귀책사유로 인하여 노출된 회원의 계정정보를 포함한
        모든 정보에 대하여 책임을 지지 않습니다.
        <br />⑤ 회원은 본 서비스 이용 과정에서 얻게 되는 타인의 개인정보를
        상업적 목적 또는 개인적 목적으로 사용하여서는 아니 됩니다.
      </p>
      <br />
      <br />
      <p className="title">제 15 조 (손해배상)</p>
      <br />
      <p className="content">
        ① 회사가 그에 책임 있는 사유로 본 약관 또는 관련법령을 위반하여 회원에게
        손해를 입힌 경우 그 손해를 배상합니다.
        <br />② 회원은 고의 또는 과실로 본 약관 또는 관련법령을 위반하여 회사
        또는 제3자에게 손해를 입힌 경우 그 손해를 배상하여야 하며, 이로 인하여
        회사와 제3자 간에 분쟁이 발생한 경우 자신의 책임과 비용으로 회사를
        면책하여야 합니다.
      </p>
      <br />
      <br />
      <p className="title">제 16 조 (면책)</p>
      <br />
      <p className="content">
        ① 회사는 천재지변, 전쟁, 기간통신사업자의 서비스 중지 및 기타 이에
        준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 회사의 고의
        또는 중과실이 없는 한 서비스 제공에 대하여 책임 지지 않습니다.
        <br />② 회사는 서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한
        사유로 발생한 손해에 대한 회사의 고의 또는 중과실이 없는 한 책임이
        면제됩니다.
        <br />③ 회사는 회사가 네트워크를 통한 외부의 불법행위를 차단하고자 관련
        법령에 따라 요구되는 보호조치를 이행하였음에도 불구하고,
        분산서비스거부(DDos) 등 네트워크의 안정성을 해치는 행위 또는
        악성프로그램 등에 의하여 발생하는 예기치 못한 회원의 손해에 대하여
        회사의 고의 또는 중과실이 없는 한 책임지지 않습니다.
        <br />④ 회사는 회원의 귀책사유로 인하여 발생한 손해에 대하여는 회사의
        고의 또는 중대한 과실이 없는 한 책임지지 않습니다.
        <br />⑤ 회사가 제공하는 정보와 자료는 개인적 목적 또는 거래의 목적으로
        이용될 수 없습니다. 따라서 회원이 본 서비스를 통하여 얻는 정보를
        이용하여 본 서비스의 목적을 벗어나 사용함으로써 발생하는 손해 또는
        기대이익의 상실에 대하여 회사는 고의 또는 중대한 과실이 없는 한 책임지지
        않습니다.
      </p>
      <br />
      <br />
      <p className="title">제 17 조 (권리의 양도금지)</p>
      <br />
      <p className="content">
        회원은 회사의 서면 동의 없이 이 약관상의 권리와 의무를 제3자에게 양도,
        증여하거나 이를 담보로 제공할 수 없습니다.
      </p>
      <br />
      <br />
      <p className="title">제 18 조 (약관의 해석)</p>
      <br />
      <p className="content">
        본 약관의 해석에 관하여 다툼이 있는 경우 대한민국 법률이 적용되고 이에
        따라 해석됩니다.
      </p>
      <br />
      <br />
      <p className="title">제 19 조 (분쟁의 해결) </p>
      <br />
      <p className="content">
        회원과 회사 사이에 발생한 분쟁에 대해 소송이 제기되는 경우 대한민국법을
        준거법으로 하며, 민사소송법 상의 관할법원에 제소합니다.
      </p>
    </div>
  );
};
