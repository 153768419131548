import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  useAuthenticateQuery,
  useFetchConnectUserQuery,
  CapaErrorCode
} from '@generated/Common/graphql';
import { CapaActivityType } from '@generated/Common/types';
import { STORAGE_ACTION, getStorageItem, setStorageItem } from '@utils/storage';
import { useSnackbar } from '@components/Provider';
import Loading from '@components/Loading';
import LoginPresenter from './LoginPresenter';
import { Inputs } from './LoginPresenter';
import {
  groupRoomPathName,
  myDrivePathName,
  groupPathName,
  REDIRECT_TO
} from '@routes/Common/types';
import queryString from 'query-string';
import DownloadAppBanner from '@components/DownloadAppBanner';
import useUserActionLog, {
  Enum_Action_Button_Enum
} from '@hooks/useUserActionLog';
import ChangeLanguageContainer from '@routes/Common/ChangeLanguage';
import { loginContainerStyles } from './styles';
import { reconnectSubscriptionClient } from '@utils/common';
import clsx from 'clsx';
import useMyDrive from '@hooks/Drive/MyDrive/useMyDrive';
import { useMediaQuery, useTheme } from '@material-ui/core';

interface Props {
  showInModal?: boolean;
  onSuccess?: (fetchConnectUserResult?: any) => void;
  onClickSignUp?: () => void;
  onClickForgotPass?: () => void;
}
const LoginContainer: React.FC<Props> = (props: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { showInModal, onSuccess, onClickSignUp, onClickForgotPass } = props;
  const history = useHistory();
  const classes = loginContainerStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const { search } = useLocation<any>();
  const accessToken = getStorageItem(STORAGE_ACTION.CONNECT_ACCESS_TOKEN);
  const refreshToken = getStorageItem(STORAGE_ACTION.CONNECT_REFRESH_TOKEN);
  const { refetch: authenticateRefetch } = useAuthenticateQuery({
    skip: true
  });
  const { refetch: fetchConnectUserRefetch } = useFetchConnectUserQuery({
    skip: !accessToken || !refreshToken ? true : false
  });
  const query = queryString.parse(search);
  const { onInsertUserActionLogOne } = useUserActionLog();

  useEffect(() => {
    const userInfo = getStorageItem(STORAGE_ACTION.USER_INFO);
    const accessToken = getStorageItem(STORAGE_ACTION.CONNECT_ACCESS_TOKEN);
    const isLoggedIn = !!accessToken && !!(userInfo && userInfo.email);
    if (isLoggedIn) {
      history.push({
        pathname: myDrivePathName,
        state: { prevLocation: window.location.href }
      });
    }
  }, []);

  const goToGroupRoom = () => {
    history.push({
      pathname: `${groupRoomPathName}`,
      search: search,
      state: { prevLocation: window.location.href }
    });
  };
  const { onSetShowHideFileDetail } = useMyDrive(true);

  const goToGroupList = () => {
    history.push({
      pathname: `${groupPathName}`,
      search: search,
      state: { prevLocation: window.location.href }
    });
  };

  const onSubmit = async (req: Inputs) => {
    onInsertUserActionLogOne(
      Enum_Action_Button_Enum.Button_Login_Submit,
      CapaActivityType.ButtonClick
    );
    try {
      const trimmedEmail = req.email.trim();
      setIsLoading(true);
      const authenticateRefetchResp = await authenticateRefetch({
        email: trimmedEmail,
        password: req.password
      });

      if (
        authenticateRefetchResp &&
        authenticateRefetchResp.data &&
        authenticateRefetchResp.data.authenticate
      ) {
        const { result: authRefetchRespResult, data: authFetchRespData } =
          authenticateRefetchResp.data.authenticate;
        if (
          authRefetchRespResult === CapaErrorCode.Success &&
          authFetchRespData
        ) {
          const { accessToken, refreshToken } = authFetchRespData;
          if (accessToken && refreshToken) {
            setStorageItem(STORAGE_ACTION.CONNECT_ACCESS_TOKEN, accessToken);
            setStorageItem(STORAGE_ACTION.CONNECT_REFRESH_TOKEN, refreshToken);
            reconnectSubscriptionClient(accessToken, refreshToken);

            const fetchConnectUserRefetchResp = await fetchConnectUserRefetch();
            setIsLoading(false);
            if (
              fetchConnectUserRefetchResp &&
              fetchConnectUserRefetchResp.data &&
              fetchConnectUserRefetchResp.data.fetchConnectUser
            ) {
              const {
                result: fetchConnectUserRefetchRespResult,
                data: fetchConnectUserRefetchRespData
              } = fetchConnectUserRefetchResp.data.fetchConnectUser;
              if (!isMobile) {
                onSetShowHideFileDetail({
                  isShowIsDetailFile: true
                });
                setStorageItem(STORAGE_ACTION.IS_OPEN_DETAIL_FILE, true);
              }
              if (
                fetchConnectUserRefetchRespResult ===
                CapaErrorCode.NotAllowedUser
              ) {
                setStorageItem(STORAGE_ACTION.LOGGED_IN, 'true');
                setStorageItem(
                  STORAGE_ACTION.CONNECT_USER_RESULT,
                  fetchConnectUserRefetchRespResult
                );
                setStorageItem(
                  STORAGE_ACTION.USER_INFO,
                  JSON.stringify({
                    email: trimmedEmail,
                    isSaved: String(req.isSaved)
                  })
                );
                if (showInModal) {
                  if (onSuccess) {
                    onSuccess && onSuccess(fetchConnectUserRefetchRespResult);
                    return;
                  }
                } else {
                  if (query.redirect === REDIRECT_TO.GROUP_ROOM) {
                    return goToGroupRoom();
                  }
                  return history.push({
                    pathname: groupPathName
                  });
                }
              }

              if (
                fetchConnectUserRefetchRespResult === CapaErrorCode.Success &&
                fetchConnectUserRefetchRespData
              ) {
                setStorageItem(STORAGE_ACTION.LOGGED_IN, 'true');
                setStorageItem(
                  STORAGE_ACTION.USER_INFO,
                  JSON.stringify({
                    email: trimmedEmail,
                    isSaved: String(req.isSaved),
                    userName: fetchConnectUserRefetchRespData.userName
                  })
                );
                if (onSuccess) {
                  onSuccess && onSuccess(fetchConnectUserRefetchRespResult);
                  return;
                }
                if (query.chatroomId) {
                  return goToGroupRoom();
                }
                if (
                  (query.confirmLeave === 'true' && query.groupId) ||
                  query.confirmDelete === 'true'
                ) {
                  return goToGroupList();
                }
                return history.push(myDrivePathName);
              }
            }
          }
        } else {
          setIsLoading(false);
          enqueueSnackbar(
            `가입되지 않은 이메일이거나 비밀번호가 일치하지 않습니다. 계정 관련 문의는 고객센터(support@capa.ai)로 연락 주세요.`,
            {
              variant: 'error'
            }
          );
        }
      }
    } catch (error) {
      setIsLoading(false);
      enqueueSnackbar(
        `가입되지 않은 이메일이거나 비밀번호가 일치하지 않습니다. 계정 관련 문의는 고객센터(support@capa.ai)로 연락 주세요.`,
        { variant: 'error' }
      );
    }
  };

  const onClick = () => history.push('/');

  return (
    <React.Fragment>
      <Loading open={isLoading} />
      <div className={!showInModal ? classes.LoginMain : ''}>
        <LoginPresenter
          onSubmit={onSubmit}
          onClick={onClick}
          onClickSignUp={onClickSignUp}
          onClickForgotPass={onClickForgotPass}
        />
        <div className={clsx(classes.changeLanguageWrapper, 'change-language')}>
          <ChangeLanguageContainer />
          <DownloadAppBanner />
        </div>
      </div>
    </React.Fragment>
  );
};

export default LoginContainer;
