import React from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import { Check } from '@material-ui/icons';

import { LANGUAGE_OPTIONS } from './types';
import i18n from '@i18n/index';
import { useStylesDialogLanguages } from './styles';

interface DialogLanguagesProps {
  onClose: () => void;
  isOpen: boolean;
}

const DialogLanguages = (props: DialogLanguagesProps) => {
  const { isOpen, onClose } = props;
  const classes = useStylesDialogLanguages();

  const languageValue = i18n.language;

  const onClickLanguage = (value: any) => {
    i18n.changeLanguage(value);
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      classes={{
        paper: classes.paper
      }}
    >
      <DialogContent className={classes.csDialogContentModal}>
        {LANGUAGE_OPTIONS.map((language) => (
          <div
            key={language.name}
            className={classes.csDialogContent}
            onClick={() => onClickLanguage(language.value)}
          >
            {language.name}
            {languageValue === language.value ? (
              <div>
                <Check className={classes.checkIcon} />
              </div>
            ) : null}
          </div>
        ))}
      </DialogContent>
    </Dialog>
  );
};

export default DialogLanguages;
