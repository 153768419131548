import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  backdrop: {
    color: theme.palette.primary.main,
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  customLoading: {
    animationDuration: '750ms',
    position: 'absolute'
  }
}));
