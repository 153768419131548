import React, { useLayoutEffect } from 'react';

import SignUpHeaderPresenter from './SignUpHeaderPresenter';
import AccountInfoContainer from './AccountInfoContainer';

const SignUpPresenter: React.FC = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <SignUpHeaderPresenter />
      <AccountInfoContainer />
    </React.Fragment>
  );
};

export default SignUpPresenter;
