import styled, { css } from 'styled-components';
import { Container, Box } from '@material-ui/core';
import { colorSet } from '@components/Provider';

export const MuiContainer = styled(Container)``;

interface MuiBoxProps {
  background_image?: string;
  background_color?: string;
}

export const MuiBox = styled(Box).attrs((props: MuiBoxProps) => props)`
  ${(props) =>
    props.background_image &&
    css`
      background-image: url(${props.background_image});
    `}
  ${(props) =>
    props.background_color &&
    css`
      background-color: ${props.background_color};
    `}
  text-align: center;

  .header-title-text {
    font-size: 72px;
    margin-bottom: 18px;
    line-height: 92px;
    white-space: pre-line;
  }

  .header-subtitle-text {
    font-size: 20px;
    line-height: 32px;
  }

  .goto-sign-up {
    width: 198px;
    font-size: 20px;

    ${({ theme }) => css`
      ${theme.breakpoints.down('sm')} {
        width: 133px;
        font-size: 16px;
        border-radius: 6px;
      }
    `}
  }

  &.home-header {
    padding-top: 104px;

    .goto-sign-up {
      margin: 40px 0 64px 0;
    }

    .header-subtitle-text {
      color: #868e96;
      font-weight: 400;
    }

    ${({ theme }) => css`
      ${theme.breakpoints.down('xs')} {
        padding-top: 50px;

        .header-title-text {
          font-size: 40px;
          margin-bottom: 16px;
          line-height: 48px;
        }

        .header-subtitle-text {
          font-size: 12px;
          max-width: 200px;
          margin: auto;
          line-height: 18px;
        }

        .goto-sign-up {
          margin: 20px 0 30px 0;
        }
      }
    `}
  }

  &.hero-section {
    padding: 20px 0;

    ${({ theme }) => css`
      ${theme.breakpoints.down('xs')} {
        .img-gif {
          padding: 40px;
        }

        .img-gif-mobile {
          position: absolute;
          top: -20px;
          right: 0;
          width: 50%;
        }
      }
    `}
  }

  &.reason-section {
    padding: 200px 0;

    .header-title-text {
      font-size: 80px;
      margin-bottom: 8px;
      line-height: 100px;
      font-family: 'Gellix', sans-serif;
    }

    .sub-title {
      color: ${colorSet.gray600};
      font-size: 12px;
      line-height: 20px;
      font-weight: 400;
      margin-bottom: 30px;
    }

    .main-content {
      color: ${colorSet.gray600};
      font-size: 32px;
      line-height: 50px;
      font-weight: 400;
      white-space: pre-line;
    }
  }

  &.services-section {
    background: #131414;
    padding: 200px 0;

    .header-title {
      font-size: 40px;
      line-height: 64px;
      letter-spacing: 2.5px;
      white-space: pre-line;
      color: ${colorSet.gray000};
      margin-bottom: 70px;
    }

    .MuiGrid-container {
      margin-bottom: 40px;
    }

    ${({ theme }) => css`
      ${theme.breakpoints.down('xs')} {
        padding: 80px 0;

        .header-title {
          text-align: left;
          font-size: 25px;
          line-height: 40px;
          letter-spacing: 0;
          padding: 0 16px;
          margin-bottom: 32px;
        }

        .MuiGrid-container {
          margin-bottom: 0;
        }
      }
    `}
  }

  &.service-signature {
    padding: 215px 25px;

    .info-panel {
      width: 416px;
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .info-label {
        font-size: 20px;
        line-height: 32px;
        font-weight: 500;
        color: ${colorSet.primary500};
        letter-spacing: 3px;
      }

      .info-head-title {
        font-size: 40px;
        line-height: 64px;
        font-weight: 700;
        color: ${colorSet.gray000};
        margin: 20px 0;
        white-space: pre-line;
      }

      .info-sub-title {
        font-size: 20px;
        line-height: 32px;
        font-weight: 500;
        color: ${colorSet.gray700};
        white-space: pre-line;
      }
    }

    .info-thumbnail {
      width: 50%;
      background: linear-gradient(130.65deg, #4b68ff -13.25%, #282a36 79.48%);
      padding: 56px 100px;
      border-radius: 24px;

      ${({ theme }) => `
        ${theme.breakpoints.down('md')} {
          width: 100%;
        }
      `}

      .img-gif {
        box-shadow: 0px 26px 36px -4px rgba(0, 0, 0, 0.2),
          0px 36px 72px 5px rgba(0, 0, 0, 0.14),
          0px 16px 64px 8px rgba(0, 0, 0, 0.12);
      }
    }

    ${({ theme }) => css`
      ${theme.breakpoints.down('xs')} {
        padding: 64px 0;

        .info-panel {
          width: 100%;
          margin: 0 !important;

          .info-label {
            font-size: 16px;
            line-height: 2px;
          }

          .info-head-title {
            font-size: 32px;
            line-height: 48px;
            letter-spacing: 0px;
          }

          .info-sub-title {
            margin-bottom: 40px;
          }
        }

        .info-thumbnail {
          padding: 25px;
          border-radius: 0;
        }
      }
    `}
  }

  &.download-section {
    padding: 200px 0;

    .head-title {
      white-space: pre-line;
      margin-bottom: 48px;
      font-weight: 700;
    }

    .image-download-thumbnail {
      width: 100%;
      max-width: 588px;
    }

    ${({ theme }) => css`
      ${theme.breakpoints.down('xs')} {
        padding: 160px 0;

        button {
          width: 160px;
        }

        .head-title {
          font-weight: 500;
          font-size: 32px;
          line-height: 48px;
        }

        .image-download-thumbnail {
          padding: 20px 25px 0;
        }
      }
    `}
  }

  &.let-us-section {
    padding: 240px 0;

    .head-title {
      margin: 10px 0 68px 0;
    }

    ${({ theme }) => css`
      ${theme.breakpoints.down('xs')} {
        .head-title {
          margin: 10px 0 48px 0;
        }
      }
    `}
  }
`;

export const GnbMenuStyle = styled.div`
  display: flex;
  align-items: center;
  .gnb-drive-button {
    margin-left: 20px;
    ${({ theme }) => `
      ${theme.breakpoints.down('xs')} {
        margin-left: 16px;
      }
    `}
  }
  .gnb-login-button {
    margin-left: 20px;
    ${({ theme }) => `
      ${theme.breakpoints.down('xs')} {
        margin-left: 16px;
      }
    `}
  }
  .gnb-popup-menu {
    margin-left: 20px;
    ${({ theme }) => `
      ${theme.breakpoints.down('xs')} {
        display: none;
      }
    `}
  }
  .gnb-notification-menu {
    margin-left: 16px;
  }

  .gnb-notification-menu .MuiBadge-anchorOriginTopRightRectangle {
    color: ${colorSet.gray000} !important;
    padding: 4px 0px;
    font-size: 9px;
    min-width: 20px;
    border-radius: 9px !important;
    text-align: center;
    height: 17px;
    background: ${colorSet.pink300};
    text-align: center;
  }
`;

export const HomePageWrapper = styled.div`
  background: ${colorSet.gray2000};
  color: ${colorSet.gray100};
  .driver {
    background: ${colorSet.gray900};
  }
`;

export const GifImageWrapper = styled.div`
  position: relative;
  width: 100%;

  .img-gif {
    width: 100%;
    border-radius: 10px;
  }

  .img-gif-mobile {
    position: absolute;
    top: -5px;
    right: -160px;
    width: 100%;
    max-width: 400px;
  }
`;

export const TabsMenuWrapper = styled.div`
  display: flex;
  margin-left: 50px;

  .itemTab {
    color: ${colorSet.gray100};
  }
`;

export const ButtonLoginInHome = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  width: 120px;
  height: 32px;
  background: ${colorSet.gray000};
  color: ${colorSet.gray900};
  border-radius: 8px;
  border: 1px solid ${colorSet.gray600};
  cursor: pointer;
`;
