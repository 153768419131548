import React from 'react';
import { ThemeProvider } from 'styled-components';
import { SnackbarProvider } from 'notistack';
import theme from './styles/theme';
import GlobalStyle from './styles/GlobalStyles';
import snackbarStyles from './styles/snackbarStyles';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';

const ProviderPresenter: React.FC = ({ children }) => {
  const notistackRef: any = React.createRef();
  const onClickCloseButton = (key: any) => () => {
    notistackRef.current.closeSnackbar(key);
  };
  const { closeIcon } = snackbarStyles();

  return (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          ref={notistackRef}
          maxSnack={1}
          classes={{ ...snackbarStyles() }}
          hideIconVariant={true}
          action={(key) => (
            <IconButton onClick={onClickCloseButton(key)}>
              <Close className={closeIcon} />
            </IconButton>
          )}
        >
          <GlobalStyle />
          {children}
        </SnackbarProvider>
      </ThemeProvider>
    </MuiThemeProvider>
  );
};

export default ProviderPresenter;
