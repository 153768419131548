import React from 'react';
import { ThumbnailStyle } from './styles';
import { IBMDocumentUnknown } from '@assets';
import clsx from 'clsx';

interface Props {
  thumbnailUrl?: string;
  width?: number;
  error: boolean;
  isInsertFromMyDrive?: boolean;
  setError: () => void;
}

const ThumbnailPresenter: React.FC<Props> = ({
  thumbnailUrl,
  width,
  error,
  setError,
  isInsertFromMyDrive
}) => {
  return (
    <ThumbnailStyle
      width={`${width}px`}
      is_empty={error}
      isInsertFromMyDrive={isInsertFromMyDrive}
    >
      <img
        className={clsx('thumbnail-img', error && 'unknown')}
        src={error ? IBMDocumentUnknown : thumbnailUrl}
        alt=""
        width={width}
        height={width}
        onError={() => setError()}
      />
    </ThumbnailStyle>
  );
};

export default ThumbnailPresenter;
