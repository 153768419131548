import styled from 'styled-components';
import { colorSet } from '@components/Provider';
import { Box } from '@material-ui/core';

export const PageContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-width: 690px;

  .cursor-pointer {
    cursor: pointer;
  }
  .title {
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    letter-spacing: -0.1px;
    margin-bottom: 12px;
    color: ${colorSet.gray900};
  }
  .sub-title {
    font-size: 14px;
    line-height: 143%;
    letter-spacing: -0.1px;
    color: ${colorSet.gray700};
    font-weight: 400;
  }
  .notification-title-box {
    margin-top: 52px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    color: ${colorSet.gray900};
    ${({ theme }) => `
      ${theme.breakpoints.down('xs')} {
        margin-top: 0;
      }
    `}

    .notification-title {
      font-weight: 700;
      font-size: 20px;
      line-height: 160%;
      ${({ theme }) => `
        ${theme.breakpoints.down('xs')} {
          margin-top: 20px;
          font-size: 16px;
        }
      `}
    }

    .notification-sub-title {
      font-size: 14px;
      line-height: 143%;
      color: ${colorSet.gray700};
      font-weight: 400;
    }
  }

  .notification-type-box {
    padding: 25px 12px 16px 0;
    border-top: 1px solid ${colorSet.gray200};

    .type {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .name {
      font-weight: 700;
      font-size: 14px;
      line-height: 143%;
      letter-spacing: -0.1px;
    }

    .status-box {
      display: flex;
      align-items: center;
      .status {
        color: ${colorSet.gray900};
        font-weight: 400;
        margin-right: 16px;
        font-size: 14px;
        line-height: 143%;
      }
    }

    .description-box {
      display: inline-block;
      width: 70%;
      margin-left: 9px;
      ${({ theme }) => `
        ${theme.breakpoints.down('xs')} {
          margin-left: 0;
        }
      `}
    }

    .description {
      display: inline-block;
      margin-left: 3px;
      font-size: 14px;
      line-height: 143%;
      letter-spacing: -0.1px;
      color: ${colorSet.gray700};
      font-weight: 400;
      ${({ theme }) => `
        ${theme.breakpoints.down('xs')} {
          margin-left: 0;
        }
      `}
    }

    .description-mobile {
      margin-left: 3px;
      font-size: 14px;
      line-height: 143%;
      letter-spacing: -0.1px;
      color: ${colorSet.gray700};
      font-weight: 400;
      ${({ theme }) => `
        ${theme.breakpoints.down('xs')} {
          margin-left: 0;
        }
      `}
    }
  }
`;
