import React, { useEffect, useRef, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField
} from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import { CustomizedButton } from '@components/Customized';
import { useStyles } from './styles';
import { KEY_CODE, MAX_LENGTH_FOLDER_NAME } from '@routes/Dashboard/types';
import { useTranslation } from 'react-i18next';
import useUserActionLog, {
  CapaActivityType,
  Enum_Action_Button_Enum,
  Enum_Action_Input_Enum,
  Enum_Action_Page_Enum
} from '@hooks/useUserActionLog';
import i18n from '@i18n/index';
import { getFileExtension } from '@utils/file';

interface Props {
  isOpen: boolean;
  folderNameValue: string;
  onClose?: () => void;
  onSubmit?: () => void;
  setValue?: (arg0: string) => void;
  dialogInfo: {
    title: string;
    submitButtonName: string;
  };
  checkedFiles?: any;
  disabledSubmitButton?: boolean;
  setDisbaledRenameSubmitButton?: () => void;
}

const FolderFormDialog: React.FC<Props> = ({
  folderNameValue,
  isOpen,
  onClose,
  onSubmit,
  setValue,
  dialogInfo,
  checkedFiles,
  disabledSubmitButton,
  setDisbaledRenameSubmitButton
}) => {
  const classes = useStyles();
  const folderNameTextFieldRef = useRef<any>(null);
  const folderNameTextFieldId = 'folder-name-text-field';
  const { t } = useTranslation();
  const [errorField, setErrorField] = useState(null);
  const { onInsertUserActionLogOne } = useUserActionLog();
  const languageValue = i18n.language;

  useEffect(() => {
    if (isOpen) {
      if (checkedFiles === undefined) {
        onInsertUserActionLogOne(
          Enum_Action_Page_Enum.Page_Mydrive_NewFolderModal,
          CapaActivityType.PageVisit
        );
      }
      if (setDisbaledRenameSubmitButton) setDisbaledRenameSubmitButton();
    } else {
      setTimeout(() => {
        if (setValue) setValue(t('my_drive.new_folder.default_value'));
      }, 200);
    }
    setTimeout(() => {
      if (folderNameTextFieldRef && folderNameTextFieldRef.current) {
        const folderNameTextFieldEl: any = document.getElementById(
          folderNameTextFieldId
        );
        if (
          (checkedFiles && checkedFiles[0]?.isFile) ||
          (checkedFiles && checkedFiles[0]?.originalFileExt)
        ) {
          folderNameTextFieldEl.focus();
          folderNameTextFieldEl.setSelectionRange(
            0,
            folderNameTextFieldEl.value.length -
              getFileExtension(folderNameTextFieldEl.value).length
          );
        } else {
          folderNameTextFieldEl.select();
        }
      }
    }, 0);
  }, [isOpen]);

  useEffect(() => {
    if (setValue) setValue(t('my_drive.new_folder.default_value'));
  }, [languageValue]);

  useEffect(() => {
    if (folderNameValue && folderNameValue.length > MAX_LENGTH_FOLDER_NAME)
      setErrorField(t('my_drive.new_folder.folder_name_too_long'));
    else setErrorField(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderNameValue]);

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={isOpen}
      onClose={() => {
        if (checkedFiles === undefined) {
          onInsertUserActionLogOne(
            Enum_Action_Button_Enum.Button_NewFolderModal_ClickOutsideModal,
            CapaActivityType.ButtonClick
          );
        }
        if (onClose) onClose();
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <DialogTitle classes={{ root: classes.rootTitle }} disableTypography>
        <div>{dialogInfo.title}</div>
        {onClose && <Clear className={classes.cancelBtn} onClick={onClose} />}
      </DialogTitle>
      <DialogContent className={classes.csDialogContent}>
        <TextField
          ref={folderNameTextFieldRef}
          id={folderNameTextFieldId}
          value={folderNameValue ?? undefined}
          placeholder=""
          variant="outlined"
          size="small"
          fullWidth
          onChange={(event) => setValue && setValue(event.target.value)}
          onKeyDown={(e) => {
            if (e.keyCode === KEY_CODE.ENTER) {
              onSubmit && onSubmit();
            }
          }}
          onFocus={() => {
            if (checkedFiles === undefined)
              onInsertUserActionLogOne(
                Enum_Action_Input_Enum.Input_NewFolderModal_Name,
                CapaActivityType.actionInput
              );
          }}
          error={!!errorField}
          helperText={errorField}
        />
      </DialogContent>
      <DialogActions classes={{ root: classes.action }}>
        <div className={classes.wrapperBtn}>
          <CustomizedButton
            variant="contained"
            color="default"
            onClick={() => {
              if (checkedFiles === undefined) {
                onInsertUserActionLogOne(
                  Enum_Action_Button_Enum.Button_NewFolderModal_Cancel,
                  CapaActivityType.ButtonClick
                );
              }
              if (onClose) onClose();
            }}
          >
            {t('my_drive.new_folder.cancel_button')}
          </CustomizedButton>
          <CustomizedButton
            type="submit"
            variant="contained"
            color="primary"
            onClick={() => {
              if (checkedFiles === undefined) {
                onInsertUserActionLogOne(
                  Enum_Action_Button_Enum.Button_NewFolderModal_Create,
                  CapaActivityType.ButtonClick
                );
              }
              if (onSubmit) onSubmit();
            }}
            disabled={!folderNameValue || disabledSubmitButton || !!errorField}
          >
            {dialogInfo.submitButtonName}
          </CustomizedButton>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default FolderFormDialog;
