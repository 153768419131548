import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { CustomizedButton } from '@components/Customized';
import { useStyles } from './styles';
import useMyDrive from '@hooks/Drive/MyDrive/useMyDrive';
import { ArrowBackIosOutlined } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { ADD_FILES_FROM_VALUE } from './types';
import { CREATE_GROUP_SCREEN } from '@routes/Group/Dialog/types';
import useGroup from '@hooks/Group/useGroup';
import UploadFromMyDrive from '@routes/Common/Dialog/UploadFiles/UploadFromMyDrive';
import UploadFromComputer from '@routes/Common/Dialog/UploadFiles/UploadFromComputer';

interface Props {
  onClose?: () => void;
  isShowHideUploadFilesFromDialog?: boolean;
  onSubmit?: () => void;
  submitText?: string;
}

const UploadFilesFrom = (props: Props) => {
  const { onClose, isShowHideUploadFilesFromDialog, onSubmit, submitText } =
    props;
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();
  const { myDrive, onSetAddFilesFromType, onSetSelectedFilesUploadInChat } =
    useMyDrive();
  const { onSetCreateGroupScreen, group } = useGroup();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));

  const onBack = () => {
    onSetCreateGroupScreen({
      createGroupScreen: CREATE_GROUP_SCREEN.ADD_FILES_CONTAINER_MOBILE
    });
    onSetAddFilesFromType({
      addFilesFromType: ADD_FILES_FROM_VALUE.EMPTY
    });
    onSetSelectedFilesUploadInChat({ selectedFilesUploadInChat: [] });
  };

  const disabledFileFromMyDrive = () => {
    let isDisabled = false;

    myDrive.selectedFilesUploadInChat.forEach((file: any) => {
      if (!file.isFile) isDisabled = true;
    });

    return isDisabled || myDrive.selectedFilesUploadInChat.length === 0;
  };

  return (
    <>
      <Dialog
        classes={{
          paper: classes.paper
        }}
        open={isShowHideUploadFilesFromDialog!}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <DialogTitle classes={{ root: classes.rootTitle }} disableTypography>
          {matches ? (
            <ArrowBackIosOutlined
              className={classes.closeIcon}
              onClick={onBack}
            />
          ) : null}

          <div className={classes.customTitle}>Add files</div>
        </DialogTitle>
        <DialogContent className={classes.csDialogContent}>
          {myDrive.addFilesFromType === ADD_FILES_FROM_VALUE.MY_DEVICE ? (
            <UploadFromComputer />
          ) : null}
          {myDrive.addFilesFromType === ADD_FILES_FROM_VALUE.MY_DRIVE ? (
            <UploadFromMyDrive
              isShowHideUploadFilesDialog={
                group.createGroupScreen ===
                CREATE_GROUP_SCREEN.ADD_FILES_FROM_MOBILE
              }
            />
          ) : null}
        </DialogContent>
        <DialogActions classes={{ root: classes.action }}>
          <div className={classes.wrapperBtn}>
            <CustomizedButton
              variant="contained"
              color="default"
              onClick={onClose}
            >
              {t('upload_files_modal.cancel_button')}
            </CustomizedButton>
            <CustomizedButton
              type="submit"
              variant="contained"
              color="primary"
              onClick={onSubmit}
              disabled={
                disabledFileFromMyDrive() &&
                myDrive.internalFileFromComputer.length === 0
              }
            >
              {submitText ? submitText : t('upload_files_modal.next_button')}
            </CustomizedButton>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UploadFilesFrom;
