import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { colorSet } from '@components/Provider';
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      maxWidth: 520,
      padding: 0,
      width: '100%',
      '&.delete': {
        maxWidth: 476,
        padding: 7
      },
      [theme.breakpoints.down('xs')]: {
        minWidth: 343
      },
      borderRadius: '16px'
    },
    paperMobile: {
      padding: 0,
      width: '100%',
      margin: 0,
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      borderRadius: 0,
      height: '100%',
      maxHeight: '100vh'
    },
    paperBottom: {
      padding: 0,
      width: '100%',
      margin: 0,
      bottom: 0,
      right: 0,
      left: 0,
      borderRadius: '16px 16px 0 0'
    },
    scrollPaperBottom: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center'
    },
    ContentTop: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        display: 'grid',
        gridTemplateColumns: '32px 1fr 32px'
      }
    },
    BoxIcon: {
      padding: 8,
      paddingBottom: 7,
      borderRadius: 12,
      marginRight: 16,
      background: colorSet.primary450
    },
    titleRoot: {
      padding: '24px 24px 12px 24px',
      [theme.breakpoints.down('xs')]: {
        padding: '8px 12px',
        marginBottom: 16
      }
    },
    DialogContent: {
      [theme.breakpoints.down('xs')]: {
        padding: '0 16px',
        borderBottom: `1px solid ${colorSet.gray300}`
      },
      '&.pt-t': {
        paddingTop: '8px !important'
      }
    },
    Title: {
      fontSize: 20,
      fontWeight: 'bold',
      [theme.breakpoints.down('xs')]: {
        padding: '8px 12px',
        textAlign: 'center',
        fontWeight: '500',
        fontSize: 16
      }
    },
    ContentMain: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      lineHeight: '24px',
      color: colorSet.gray700,
      '&.border-b': {
        borderBottom: `1px solid ${colorSet.gray300}`
      }
    },
    LabelInput: {
      fontSize: 16,
      marginBottom: 12,
      lineHeight: '24px',
      fontWeight: 400,
      color: colorSet.gray600
    },
    Avatar: {
      margin: '4px auto 12px auto'
    },
    Name: {
      fontSize: 20,
      marginBottom: 2,
      lineHeight: '32px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: '100%',
      textAlign: 'center',
      color: colorSet.gray900
    },
    invalid: {
      color: colorSet.errorMain,
      fontSize: 12,
      lineHeight: '150%',
      marginLeft: 4,
      letterSpacing: '-0.1px'
    },
    Email: {
      fontSize: 16,
      marginBottom: 12,
      lineHeight: '24px',
      width: '100%',
      wordBreak: 'break-all',
      padding: '0 52px',
      textAlign: 'center',
      color: colorSet.gray700
    },
    Memo: {
      width: '100%',
      fontSize: 14,
      marginBottom: 32,
      lineHeight: '25px',
      wordBreak: 'break-word',
      maxHeight: 125,
      textAlign: 'center',
      textOverflow: 'ellipsis',
      overflowY: 'auto',
      lineClamp: 5,
      display: '-webkit-box',
      whiteSpace: 'pre-wrap',
      boxOrient: 'vertical',
      color: colorSet.gray700,

      '&::-webkit-scrollbar': {
        width: 4
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: colorSet.gray600,
        borderRadius: 8
      }
    },
    ContentFeature: {
      display: 'grid',
      gap: 20,
      paddingTop: 27,
      paddingBottom: 27,
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      [theme.breakpoints.down('xs')]: {
        gridTemplateColumns: '1fr 1fr'
      },
      alignItems: 'center'
    },
    ContentFeatureBottom: {
      display: 'grid',
      gap: 0,
      paddingBottom: 8,
      gridTemplateColumns: '1fr',
      alignItems: 'center'
    },
    ContentFeatureItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      cursor: 'pointer',
      fontSize: 13,
      '&.itemBottom': {
        flexDirection: 'row'
      }
    },
    ContentFeatureText: {
      width: '100%',
      textAlign: 'left',
      padding: '22px 0',
      '&.border-b': {
        borderBottom: `1px solid ${colorSet.gray300}`
      }
    },
    Input: {
      marginBottom: 10,
      fontWeight: 400,
      [theme.breakpoints.down('xs')]: {
        marginBottom: 20
      },
      '& .MuiOutlinedInput-root': {
        borderRadius: 8
      },
      '& input': {
        lineHeight: '32px',
        fontWeight: 400,
        fontSize: 14,
        padding: '12px 16px',
        color: colorSet.gray900,
        '&:disabled': {
          color: colorSet.gray600,
          background: '#F8FBFD',
          '&~ fieldset': {
            borderColor: `${colorSet.gray300} !important`
          }
        }
      },
      '& fieldset': {
        borderRadius: 8,
        border: `1px solid ${colorSet.gray300}`
      }
    },
    error: {
      marginBottom: 0,
      '& .MuiOutlinedInput-root': {
        border: `1px solid ${colorSet.errorMain}`
      },
      '& fieldset': {
        border: 'none !important'
      }
    },
    InputArea: {
      fontSize: 14,
      marginBottom: 2,
      lineHeight: '32px',
      fontWeight: 400,
      color: colorSet.gray900,
      borderRadius: 8,
      padding: '8px 16px',
      width: '100%',
      border: `1px solid ${colorSet.gray300}`
    },
    action: {
      display: 'flex',
      padding: 24,
      alignItems: 'flex-end',
      [theme.breakpoints.down('xs')]: {
        padding: 16
      }
    },
    Full: {
      width: '100%'
    },
    wrapperBtn: {
      display: 'grid',
      gap: 10,
      gridTemplateColumns: '1fr',
      justifyContent: 'center',
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      },
      '&.grid-col-2': {
        gridTemplateColumns: '1fr 1fr'
      },
      '& button': {
        textTransform: 'none',
        height: '48px !important',
        width: '100%',
        marginRight: 0
      }
    }
  })
);
