import React from 'react';
import { LayoutDashboard, GnbAppBar } from '../Common';
import { useMediaQuery, useTheme } from '@material-ui/core';
import AsideMenuPresenter from '@routes/Contacts/AsideMenuPresenter';
import DataTable from './DataTable';

const ContactsPresenter = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <LayoutDashboard
      contentShift={!isMobile}
      asideDrawer={isMobile ? null : <AsideMenuPresenter />}
      matches={isMobile}
      gnbAppBar={<GnbAppBar />}
    >
      <DataTable />
    </LayoutDashboard>
  );
};

export default ContactsPresenter;
