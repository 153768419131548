import React from 'react';
import { DeepMap, FieldError } from 'react-hook-form';
import { CustomizedTypography } from '@components/Customized';
import { TextField } from '@material-ui/core';
import { InputItemStyle } from './styles';

interface InputItemProps {
  name: string;
  label?: string;
  subtitle?: string;
  sublabel?: string;
  placeholder?: string;
  inputProps?: any;
  errors?: DeepMap<Record<string, any>, FieldError>;
  description?: string;
  inputRef?: React.Ref<any>;
  maxLength?: number;
  multiline?: boolean;
  onChange?: (value: string) => void;
  onFocus?: () => void;
  value?: string;
  mainClass?: string;
}

export const InputItem = ({
  name,
  label,
  subtitle,
  sublabel,
  placeholder,
  errors,
  description,
  inputRef,
  maxLength,
  multiline,
  onChange,
  onFocus,
  value,
  mainClass
}: InputItemProps) => {
  const error = errors && errors[name];
  const helperText = error && error.message ? error.message : '';

  return (
    <InputItemStyle className={mainClass ?? ''}>
      <CustomizedTypography
        className="item-title"
        variant="body1"
        gutterBottoms={12}
      >
        {label} <span>{sublabel ? sublabel : ''}</span>
        {subtitle && <span className="sub-title">{subtitle}</span>}
      </CustomizedTypography>
      <TextField
        fullWidth
        defaultValue={value ?? undefined}
        type={'password'}
        size="small"
        variant="outlined"
        name={name}
        inputRef={inputRef}
        placeholder={placeholder}
        error={error ? true : false}
        helperText={helperText}
        multiline={multiline}
        rows={multiline ? 4 : ''}
        inputProps={{
          maxLength: maxLength
        }}
        onFocus={onFocus}
        onChange={(e) => {
          if (maxLength && e.target.value.length > maxLength) {
            return null;
          }
          if (onChange) {
            onChange(e.target.value);
          }
        }}
      />
      {description && (
        <CustomizedTypography
          variant="body2"
          className="description"
          color="textSecondary"
        >
          {description}
        </CustomizedTypography>
      )}
    </InputItemStyle>
  );
};
