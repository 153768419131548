import React from 'react';
import { colorSet } from '@components/Provider';
import { CustomizedTypography, CustomizedButton } from '@components/Customized';
import DialogLayout from '@components/Dialog/DialogLayout';
import { makeStyles, useTheme, useMediaQuery } from '@material-ui/core';
import { DeleteOutline } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  titleIcon: {
    display: 'flex',
    padding: 9,
    backgroundColor: colorSet.primary200,
    color: colorSet.primary500,
    borderRadius: 8,
    marginRight: 18,
    [theme.breakpoints.down('xs')]: {
      marginRight: 11,
      padding: 7,
      '& .MuiSvgIcon-root': {
        fontSize: 18
      }
    }
  },
  subTitle: {
    [theme.breakpoints.down('xs')]: {
      marginTop: 12
    }
  },
  content: {
    color: colorSet.gray700
  },
  btnWrapper: {
    display: 'flex',
    width: '100%',
    '& button': {
      width: 'calc(100% / 2)'
    },
    '& button + button': {
      marginLeft: 6
    }
  }
}));

interface Props {
  count: number;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const FileDownloadDialogPresenter: React.FC<Props> = ({
  count,
  isOpen,
  onClose,
  onSubmit
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();

  return (
    <DialogLayout
      open={isOpen}
      titleIcon={
        <div className={classes.titleIcon}>
          <DeleteOutline />
        </div>
      }
      title={
        <CustomizedTypography
          variant={matches ? 'body1' : 'h3'}
          fontWeight="bold"
        >
          파일 다운로드
        </CustomizedTypography>
      }
      subTitle={
        <CustomizedTypography
          className={classes.subTitle}
          variant={matches ? 'body1' : 'h4'}
          fontWeight="500"
          gutterBottoms={matches ? 32 : 48}
        >
          {count}개의 파일을 다운로드 하시겠습니까?
        </CustomizedTypography>
      }
      footerBtn={
        <div className={classes.btnWrapper}>
          <CustomizedButton
            variant="contained"
            color="default"
            size={matches ? 'medium' : 'large'}
            fullWidth
            onClick={onClose}
          >
            취소하기
          </CustomizedButton>
          <CustomizedButton
            variant="contained"
            color="primary"
            size={matches ? 'medium' : 'large'}
            fullWidth
            onClick={onSubmit}
          >
            다운로드
          </CustomizedButton>
        </div>
      }
      onClose={onClose}
    />
  );
};

export default FileDownloadDialogPresenter;
