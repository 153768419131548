import React, { useState } from 'react';
import AppConfig from '@config/index';
import {
  CapaFileConvertStatus,
  CapaConnectMyFile
} from '@generated/Common/graphql';
import ThumbnailDefaultImg from '../assets/ThumbnailDefaultImg.png';
import ThumbnailPresenter from './ThumbnailPresenter';
import { CircularProgress, useMediaQuery, useTheme } from '@material-ui/core';
import { convertSubstring } from '@utils/file';
import { IMAGE_TYPES } from '@routes/Common/Dropzone/types';
import { useLoadingStyles } from '@routes/Common/Thumbnail/styles';

interface Props {
  file?: CapaConnectMyFile;
  service_type?: string;
  width?: number;
  width_mobile?: number;
  isInsertFromMyDrive?: boolean;
}

const ThumbnailContainer: React.FC<Props> = ({
  file,
  width,
  width_mobile,
  isInsertFromMyDrive
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const [error, setError] = useState(file ? false : true);
  const thumbnail_width =
    matches && !isInsertFromMyDrive
      ? width_mobile && !isInsertFromMyDrive
        ? width_mobile
        : 48
      : width && !isInsertFromMyDrive
      ? width
      : 32;

  const storageUrl = `${AppConfig.capaViewerApi}/${
    convertSubstring(IMAGE_TYPES).includes(
      file?.originalFileExt?.toLocaleLowerCase()
    )
      ? file?.thumbnailUri
      : file?.thumbnail
  }`;

  const thumbnailUrl = file ? storageUrl : ThumbnailDefaultImg;

  const classes = useLoadingStyles({ isInsertFromMyDrive });
  if (file?.status === CapaFileConvertStatus.Coverting) {
    return (
      <CircularProgress variant="indeterminate" className={classes.wrapper} />
    );
  }

  return (
    <ThumbnailPresenter
      thumbnailUrl={thumbnailUrl}
      width={thumbnail_width}
      isInsertFromMyDrive={isInsertFromMyDrive}
      error={error}
      setError={() => setError(true)}
    />
  );
};

export default ThumbnailContainer;
