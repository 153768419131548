import { feedback_icon, report_icon, setting_icon } from '@assets';
import CapaAvatar from '@components/CapaAvatar';
import { CustomizedTypography } from '@components/Customized';
import OverflowTip from '@components/OverflowTooltips';
import { colorSet } from '@components/Provider';
import useCommon from '@hooks/Common/useCommon';
import useAuth from '@hooks/useAuth';
import useUserActionLog, {
  CapaActivityType,
  Enum_Action_Button_Enum
} from '@hooks/useUserActionLog';
import {
  Box,
  ClickAwayListener,
  Divider,
  List,
  ListItem,
  Popper,
  makeStyles
} from '@material-ui/core';
import { clearStorageAll } from '@utils/storage';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { WelcomeText } from './styles';

const useStyles = makeStyles({
  root: {
    zIndex: 2000,
    top: '61px !important',
    right: '15px !important',
    left: 'initial !important',
    transform: 'initial !important'
  },
  boxRoot: {
    minWidth: 226,
    height: '100%',
    borderRadius: 8,
    backgroundColor: colorSet.gray000,
    padding: '8px 12px',
    boxShadow:
      '0px 8px 16px 2px rgba(97, 97, 97, 0.1), 0px 16px 32px 2px rgba(97, 97, 97, 0.1)'
  },
  list: {
    padding: 0
  },
  listItem: {
    padding: '10px 8px',
    height: 40,
    borderRadius: 8,
    '&.logout-btn': {
      textAlign: 'center',
      '& .MuiTypography-body2': {
        width: '100%'
      }
    }
  },
  menuIcon: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '& .MuiAvatar-circle': {
      width: 32,
      height: 32,
      '&.MuiAvatar-colorDefault': {
        backgroundColor: 'transparent',
        '& img': {
          width: '100%',
          height: '100%'
        }
      }
    }
  },
  divider: {
    backgroundColor: colorSet.gray200,
    margin: '10px 8px'
  }
});

interface Props {
  callback?: () => void;
}

const GnbPopupMenuPresenter: React.FC<Props> = ({ callback }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const { onInsertUserActionLogOne } = useUserActionLog();
  const { openFeedbackDialog, onSetUnReadCountGroupList } = useCommon();
  const { userState } = useAuth();

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const onClose = () => setOpen(false);

  const onClickHandle = (route: string) => {
    onClose();
    if (route === '/login') {
      clearStorageAll();
    }
    if (callback) {
      callback();
    }
    history.push(route);
  };

  return (
    <React.Fragment>
      <div
        className={clsx(classes.menuIcon, 'gnb-popup-menu')}
        onClick={(e) => {
          if (e.currentTarget) {
            onInsertUserActionLogOne(
              Enum_Action_Button_Enum.Button_Gnb_MyPage,
              CapaActivityType.ButtonClick
            );
            setAnchorEl(e.currentTarget);
            setOpen((prevOpen) => !prevOpen);
          }
        }}
      >
        <CapaAvatar
          item={{
            connectUserName: userState?.data?.userName,
            email: userState?.data?.email,
            isCapaUser: true
          }}
          showTooltip={true}
        />
      </div>
      <Popper
        className={classes.root}
        open={open}
        anchorEl={anchorEl}
        transition
        disablePortal
        placement="bottom-end"
      >
        <ClickAwayListener onClickAway={onClose}>
          <Box className={classes.boxRoot}>
            <WelcomeText>
              <CustomizedTypography variant="body1" fontWeight="500">
                <OverflowTip
                  value={userState?.data?.userName ?? ''}
                  longText={`${t('gnb.welcome')}, ${
                    userState?.data?.userName ?? ''
                  }`}
                  maxWidth={225}
                />
              </CustomizedTypography>
            </WelcomeText>
            <List className={classes.list}>
              <ListItem
                className={classes.listItem}
                button
                onClick={() => {
                  onInsertUserActionLogOne(
                    Enum_Action_Button_Enum.Button_Gnb_MyPage_MyPage,
                    CapaActivityType.ButtonClick
                  );
                  onClickHandle('/mypage');
                }}
              >
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '10px'
                  }}
                >
                  <img src={setting_icon} width={22} height={22} />
                  <CustomizedTypography variant="body2">
                    {t('gnb.account_setting')}
                  </CustomizedTypography>
                </Box>
              </ListItem>
              <Divider className={classes.divider} />
              <ListItem
                className={classes.listItem}
                button
                onClick={() => {
                  openFeedbackDialog({
                    isOpenFeedback: true,
                    isFeedback: true
                  });
                  onClose();
                }}
              >
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '10px'
                  }}
                >
                  <img src={feedback_icon} width={22} height={22} />
                  <CustomizedTypography variant="body2">
                    {t('gnb.give_feedback')}
                  </CustomizedTypography>
                </Box>
              </ListItem>
              <ListItem
                className={classes.listItem}
                button
                onClick={() => {
                  openFeedbackDialog({
                    isOpenFeedback: true,
                    isFeedback: false
                  });
                  onClose();
                }}
              >
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '10px'
                  }}
                >
                  <img src={report_icon} width={22} height={22} />
                  <CustomizedTypography variant="body2">
                    {t('gnb.report_issues')}
                  </CustomizedTypography>
                </Box>
              </ListItem>
              <Divider className={classes.divider} />
              <ListItem
                className={clsx(classes.listItem, 'logout-btn')}
                button
                onClick={() => {
                  onInsertUserActionLogOne(
                    Enum_Action_Button_Enum.Button_Gnb_MyPage_Logout,
                    CapaActivityType.ButtonClick
                  );
                  onClickHandle('/login');
                  onSetUnReadCountGroupList({
                    unReadCountGroupList: 0
                  });
                }}
              >
                <CustomizedTypography
                  variant="body2"
                  fontWeight="500"
                  align="center"
                  color="textSecondary"
                >
                  {t('gnb.log_out')}
                </CustomizedTypography>
              </ListItem>
            </List>
          </Box>
        </ClickAwayListener>
      </Popper>
    </React.Fragment>
  );
};

export default GnbPopupMenuPresenter;
