import React from 'react';
import {
  Dialog,
  DialogContent,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import LoginContainer from '../LoginContainer';

import { useStyles } from './styles';

interface Props {
  open?: any;
  onClose: () => void;
  onSuccess?: (fetchConnectUserResult?: any) => void;
  onClickSignUp?: () => void;
  onClickForgotPass?: () => void;
}

const LoginDialog: React.FC<Props> = ({
  open,
  onClose,
  onSuccess,
  onClickSignUp,
  onClickForgotPass
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        disableEscapeKeyDown={true}
        classes={{ paper: !matches ? classes.paper : classes.paperMobile }}
      >
        <DialogContent className={classes.DialogContent}>
          <LoginContainer
            onSuccess={onSuccess}
            showInModal={true}
            onClickSignUp={onClickSignUp}
            onClickForgotPass={onClickForgotPass}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default LoginDialog;
