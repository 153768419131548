import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';
import { colorSet } from '@components/Provider';

export const DropzoneContainer = styled.div``;
export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  rootParent: {
    height: '100%'
  },
  groupNameRootParent: {
    height: 'calc(100% - 142px)',
    [theme.breakpoints.down('xs')]: {
      height: 'calc(100% - 60px)'
    }
  },
  locationBox: {
    backgroundColor: colorSet.primary450,
    position: 'fixed',
    maxWidth: 280,
    bottom: '16px',
    left: '50%',
    boxShadow:
      '0px 5px 5px -3px rgba(0, 0, 0, 0.1), 0px 8px 10px 1px rgba(0, 0, 0, 0.07), 0px 3px 14px 2px rgba(0, 0, 0, 0.06)',
    borderRadius: ' 8px',
    color: '#ffffff',
    textAlign: 'center',
    padding: 16
  },
  groupNameLocationBox: {
    backgroundColor: colorSet.primary450,
    position: 'sticky',
    maxWidth: 280,
    bottom: 160,
    margin: 'auto',
    boxShadow:
      '0px 5px 5px -3px rgba(0, 0, 0, 0.1), 0px 8px 10px 1px rgba(0, 0, 0, 0.07), 0px 3px 14px 2px rgba(0, 0, 0, 0.06)',
    borderRadius: ' 8px',
    color: '#ffffff',
    textAlign: 'center',
    padding: 16
  },
  locationTitle: {
    paddingBottom: '10px',
    fontSize: '13px'
  },
  locationNameRow: {
    fontSize: '16px',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  locationName: {
    marginLeft: '10px'
  }
}));
