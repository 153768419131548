import { makeStyles } from '@material-ui/core';
import { colorSet } from '@components/Provider';

export const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: 476,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      borderRadius: 0
    },
    borderRadius: '16px'
  },
  rootTitle: {
    padding: 32,
    display: 'flex',
    fontSize: '20px',
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
      marginBottom: 15,
      padding: '12px 16px',
      borderBottom: `1px solid ${colorSet.gray300}`
    }
  },
  subTitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 25,
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-between',
      width: '100%',
      fontSize: 16,
      '& svg': {
        fontSize: 16
      }
    }
  },
  titleIcon: {
    cursor: 'pointer',
    width: '44px',
    height: '44px',
    background: `${colorSet.primary450}`,
    borderRadius: 12,
    marginRight: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      fontSize: 26,
      color: `${colorSet.gray000}`
    }
  },
  name: {
    fontSize: 25,
    color: `${colorSet.gray900}`,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      fontSize: 16,
      width: '100%',
      display: 'block',
      fontWeight: 500
    }
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  cancelBtn: {
    fontSize: 32,
    marginLeft: 'auto',
    cursor: 'pointer'
  },
  content: {
    color: colorSet.gray700,
    lineHeight: '150%',
    fontSize: 16
  },
  action: {
    padding: 32,
    [theme.breakpoints.down('xs')]: {
      padding: '24px 15px 24px 15px',
      margin: 0
    }
  },
  wrapperBtn: {
    display: 'flex',
    width: '100%',
    '& button': {
      textTransform: 'none',
      marginLeft: 'auto',
      width: '100%',
      '&:first-child': {
        marginRight: 8
      }
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      '& button': {
        width: '100%'
      }
    }
  },
  csDialogContent: {
    overflow: 'hidden !important',
    '& .MuiOutlinedInput-input': {
      border: `1px solid ${colorSet.gray400}`,
      borderRadius: 8
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent !important'
    },
    padding: '0 32px',
    [theme.breakpoints.down('xs')]: {
      padding: '0 15px 32px 15px'
    }
  },
  groupName: {
    color: colorSet.gray900,
    fontWeight: 'bold',
    wordBreak: 'break-word'
  },
  checkbox: {
    marginTop: 8,
    '& .MuiFormControlLabel-label': {
      fontSize: 16,
      color: colorSet.gray900,
      fontWeight: 'bold',
      wordBreak: 'break-word'
    }
  },
  ifYouArchiveText: {
    fontSize: 14,
    color: colorSet.gray900,
    lineHeight: '143%',
    marginLeft: 30,
    wordBreak: 'break-word'
  },
  wordBreak: {
    wordBreak: 'break-word'
  }
}));
