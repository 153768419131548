import React, { useState } from 'react';
import { colorSet } from '@components/Provider';
import {
  CustomizedTypography,
  CustomizedButton,
  CustomizedCheckBox
} from '@components/Customized';
import DialogLayout from '@components/Dialog/DialogLayout';
import { TermsOfServiceAgreement } from '@routes/SignUp/DialogTerms/AgreementPresenter';
import { Box, makeStyles, useTheme, useMediaQuery } from '@material-ui/core';
import { NotificationImportantOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  titleIcon: {
    display: 'flex',
    padding: 9,
    backgroundColor: colorSet.primary200,
    color: colorSet.primary500,
    borderRadius: 8,
    marginRight: 18,
    [theme.breakpoints.down('xs')]: {
      marginRight: 11,
      padding: 7,
      '& .MuiSvgIcon-root': {
        fontSize: 18
      }
    }
  },
  subTitle: {
    [theme.breakpoints.down('xs')]: {
      marginTop: 12
    }
  },
  content: {
    color: colorSet.gray700,
    '& .content-terms': {
      padding: '20px 16px',
      borderRadius: 8,
      backgroundColor: colorSet.gray100,
      height: 272,
      marginBottom: 12,
      ...theme.typography.caption
    },
    '& .content-check-box': {
      marginBottom: 32,
      '& .customized-typography': {
        '& span': {
          color: colorSet.primary500
        }
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: 24
      }
    }
  }
}));

interface Props {
  isOpen: boolean;
  onSubmit: () => void;
}

const TermsAgreeDialogPresenter: React.FC<Props> = ({ isOpen, onSubmit }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();
  const [checkedAgree, setCheckedAgree] = useState<Array<string>>([]);

  return (
    <DialogLayout
      open={isOpen}
      titleIcon={
        <div className={classes.titleIcon}>
          <NotificationImportantOutlined />
        </div>
      }
      title={
        <CustomizedTypography
          variant={matches ? 'body1' : 'h3'}
          fontWeight="bold"
        >
          캐파 클라우드 서비스 런칭!
        </CustomizedTypography>
      }
      subTitle={
        <CustomizedTypography
          className={classes.subTitle}
          variant={matches ? 'body2' : 'body1'}
          fontWeight="500"
          gutterBottoms={4}
        >
          서비스 이용에 앞서 캐파 클라우드 이용약관에 동의해주세요.
        </CustomizedTypography>
      }
      content={
        <div className={classes.content}>
          <CustomizedTypography
            variant={matches ? 'subtitle2' : 'body2'}
            color="textSecondary"
            gutterBottoms={12}
          >
            ‘캐파 클라우드’는 도면을 어디서든 손쉽게 관리하고 공유할 수 있는
            도면 전용 클라우드 서비스입니다. 새로운 서비스 제공을 위해 캐파가
            새로운 약관을 준비했습니다.
          </CustomizedTypography>
          <Box className="content-terms" overflow="scroll">
            <TermsOfServiceAgreement />
          </Box>
          <CustomizedCheckBox
            className="content-check-box"
            label={
              <CustomizedTypography variant="body2" fontWeight="500">
                <span>[필수]</span> 캐파 클라우드 서비스 이용약관에 동의합니다.
              </CustomizedTypography>
            }
            value="serviceAgree"
            values={checkedAgree}
            size="small"
            fullwidth
            onChange={(values) => {
              setCheckedAgree(values);
            }}
          />
        </div>
      }
      footerBtn={
        <CustomizedButton
          variant="contained"
          color="primary"
          size={matches ? 'medium' : 'large'}
          disabled={!checkedAgree.includes('serviceAgree')}
          fullWidth
          onClick={onSubmit}
        >
          동의하고 계속 캐파 이용하기
        </CustomizedButton>
      }
    />
  );
};

export default TermsAgreeDialogPresenter;
