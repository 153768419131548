import { makeStyles } from '@material-ui/core';
import { colorSet } from '@components/Provider';

export const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: 'none',
    maxWidth: 461,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: 0,
      height: '100%',
      maxHeight: '100%',
      maxWidth: '100%',
      borderRadius: 0
    },
    maxHeight: 640,
    borderRadius: '16px'
  },
  rootTitle: {
    padding: '30px 32px 32px 30px',
    display: 'flex',
    fontSize: '20px',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: '12px 16px',
      border: `1px solid ${colorSet.gray300}`,
      display: 'inline-block',
      textAlign: 'center',
      fontSize: 16
    }
  },
  action: {
    padding: 32,
    [theme.breakpoints.down('xs')]: {
      padding: '24px 15px 24px 15px',
      margin: 0
    }
  },
  wrapperBtn: {
    display: 'flex',
    width: '100%',
    '& button': {
      textTransform: 'none',
      marginLeft: 'auto',
      width: '100%'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      '& button': {
        width: '100%'
      }
    }
  },
  csDialogContent: {
    padding: 0,
    fontSize: 14,
    marginLeft: 32,
    marginRight: 32,
    overflow: 'hidden',
    wordBreak: 'break-word',
    [theme.breakpoints.down('xs')]: {
      padding: '24px 0',
      margin: 0
    }
  },
  content: {
    marginBottom: 8,
    fontSize: 16,
    color: colorSet.gray700,
    lineHeight: '150%',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center'
    }
  },
  firstTitle: {
    wordBreak: 'break-word',
    lineHeight: '160%',
    fontSize: 25,
    fontWeight: 700,
    marginRight: 6,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16
    }
  }
}));
