import React from 'react';
import { CustomizedTypography } from '@components/Customized';
import { MuiDiv } from './styles';
import { NoDataIcon } from '@assets';
import { useTranslation, Trans } from 'react-i18next';

const DashboardEmptyPresenter: React.FC = () => {
  const { t } = useTranslation();

  return (
    <MuiDiv className="connect-empty">
      <img src={NoDataIcon} alt="NoDataIcon" className="no-data-icon" />
      <CustomizedTypography variant="h4" fontWeight="bold" gutterBottoms={12}>
        {t('my_drive.empty_my_drive.keep_all_your')}
      </CustomizedTypography>
      <div className="you-can-upload-text">
        <Trans i18nKey={'my_drive.empty_my_drive.you_can_upload_files'} />
      </div>
    </MuiDiv>
  );
};

export default DashboardEmptyPresenter;
