import React, { useState } from 'react';
import { colorSet } from '@components/Provider';
import { CustomizedTypography } from '@components/Customized';
import {
  ClickAwayListener,
  makeStyles,
  Tooltip,
  Grid,
  Box
} from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';

interface CustomizedTooltipProps {
  color?: 'gray' | 'primary';
  close_button?: boolean;
  arrow?: boolean;
  show_default: boolean;
  children?: React.ReactNode;
  title: string;
}

const useStyles = makeStyles(() => ({
  tooltip_base: {
    maxWidth: 'none',
    minWidth: 224,
    borderRadius: 8,
    padding: '10px 16px',
    boxShadow:
      '0px 5px 5px -3px rgba(0, 0, 0, 0.1), 0px 8px 10px 1px rgba(0, 0, 0, 0.07), 0px 3px 14px 2px rgba(0, 0, 0, 0.06)'
  },
  color_gray: {
    backgroundColor: colorSet.gray800
  },
  color_primary: {
    backgroundColor: colorSet.primary500
  },
  arrow_base: {
    '&::before': {
      backgroundColor: colorSet.gray800
    }
  },
  bottom: {
    margin: '8px 0'
  },
  iconBox: {
    width: 36,
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: 4
  },
  closeIcon: {
    width: 18,
    height: 18,
    transform: 'translateY(-2px)'
  },
  messageStyle: {
    color: colorSet.gray000,
    whiteSpace: 'pre-wrap'
  }
}));

const CustomizedTooltip = ({
  color,
  close_button,
  arrow,
  show_default,
  children,
  title
}: CustomizedTooltipProps) => {
  const [open, setOpen] = useState<boolean>(show_default || false);
  const {
    tooltip_base,
    arrow_base,
    iconBox,
    closeIcon,
    bottom,
    color_primary,
    color_gray,
    messageStyle
  } = useStyles();
  const close = () => setOpen(false);
  const openSwitch = () => setOpen(!open);

  const message = (
    <Grid container direction="row">
      <Box>
        <CustomizedTypography
          variant="body2"
          fontWeight="500"
          className={messageStyle}
        >
          {title}
        </CustomizedTypography>
      </Box>
      {close_button ? (
        <Box className={iconBox}>
          <CloseOutlined className={closeIcon} />
        </Box>
      ) : (
        <></>
      )}
    </Grid>
  );

  return (
    <ClickAwayListener onClickAway={close}>
      <Tooltip
        disableFocusListener
        disableHoverListener
        disableTouchListener
        arrow={arrow}
        classes={{
          tooltip: `${tooltip_base} ${
            color && color === 'primary' ? color_primary : color_gray
          }`,
          arrow: arrow_base,
          tooltipPlacementBottom: bottom
        }}
        PopperProps={{
          disablePortal: true
        }}
        onClose={close}
        open={open}
        title={message}
      >
        <Box onClick={openSwitch}>{children}</Box>
      </Tooltip>
    </ClickAwayListener>
  );
};

export default CustomizedTooltip;
