import React, { useEffect, useState } from 'react';
import { useStyles } from './styles';
import clsx from 'clsx';
import { NoteAdd, Close } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import Dropzone from '@routes/Common/Dialog/UploadFiles/Dropzone';
import { formatFileSize } from '@utils/formatter';
import useMyDrive from '@hooks/Drive/MyDrive/useMyDrive';

const UploadFromComputer = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [internalFileList, setInternalFileList] = useState<any>([]);
  const [internalIsDragDrop, setInternalIsDragDrop] = useState(false);
  const {
    myDrive,
    onSetSelectedFilesUploadInChat,
    onSetInternalFileFromComputer
  } = useMyDrive();

  const onDropAccepted = (files: any) => {
    setInternalFileList((prevState: any) => [...prevState, ...files]);
  };

  const onClickDeleteFile = (index: number) => {
    if (index > -1) {
      setInternalFileList(
        internalFileList.filter((file: any, i: number) => i !== index)
      );
    }
  };

  const setIsDragDrop = (isDragDrop: any) => {
    setInternalIsDragDrop(isDragDrop);
  };

  useEffect(() => {
    onSetInternalFileFromComputer({
      internalFileFromComputer: internalFileList
    });
  }, [internalFileList]);

  useEffect(() => {
    onSetSelectedFilesUploadInChat({ selectedFilesUploadInChat: [] });
  }, [myDrive.uploadFileTab]);

  return (
    <Dropzone onDropAccepted={onDropAccepted} setIsDragDrop={setIsDragDrop}>
      <div
        className={clsx(
          classes.computerWrapper,
          myDrive.internalFileFromComputer.length > 0
            ? classes.hasFile
            : classes.noFile,
          internalIsDragDrop ? classes.hasDrag : ''
        )}
      >
        {myDrive.internalFileFromComputer.length > 0 ? (
          myDrive.internalFileFromComputer.map((file: any, index: number) => {
            return (
              <div
                key={index}
                className={classes.fileWrapper}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <div>
                  {file.name}
                  <span className={classes.fileSizeText}>
                    {formatFileSize(file.size).toUpperCase()}
                  </span>
                </div>
                <div
                  className={classes.closeWrapper}
                  onClick={() => onClickDeleteFile(index)}
                >
                  <Close />
                </div>
              </div>
            );
          })
        ) : (
          <div>
            <div className={classes.noteAddWrapper}>
              <div className={classes.noteAddIcon}>
                <NoteAdd />
              </div>
            </div>
            <div className={classes.addFileText}>
              {t('upload_files_modal.add_new_files_here')}
            </div>
            <div className={classes.dragFileText}>
              {t('upload_files_modal.drag_files_here')}
            </div>
          </div>
        )}
      </div>
    </Dropzone>
  );
};

export default UploadFromComputer;
