import styled, { css } from 'styled-components';
import { colorSet } from '@components/Provider';
import { Container } from '@material-ui/core';

export const LayoutContainer = styled(Container)`
  min-height: 100vh;
  position: relative;
  padding: 0 !important;
  max-width: 100vw;
  .side-btn-group {
    position: absolute;
    right: 32px;
    top: 72px;
    display: flex;
    button {
      color: ${colorSet.gray600};
      &:last-child {
        color: ${colorSet.gray700};
        margin-left: 16px;
        padding: 0px;
      }
    }
  }
  .logo-register {
    display: none;
  }
  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      padding: 0px;
      padding-bottom: 47px;
    }
  `}
  .divider {
    margin: 32px 0px;
    background-color: ${colorSet.gray200};
  }
`;

export const Header = styled.header`
  max-width: 600px;
  margin: 0px auto;
  text-align: center;
  margin-bottom: 40px;
  background-color: ${colorSet.gray000};
  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      position: fixed;
      top: 0px;
      height: 48px;
      border-bottom: 1px solid ${colorSet.gray400};
      margin-bottom: 0px;
      width: 100%;
      z-index: 1000;
    }
  `}
  .header-title {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .back-btn {
      color: ${colorSet.gray900};
      position: absolute;
      left: 0px;
      cursor: pointer;
    }
    ${({ theme }) => css`
      ${theme.breakpoints.down('xs')} {
        padding: 12px 16px;
        .back-btn {
          left: 16px;
        }
      }
    `}
  }
`;

export const Content = styled.section`
  max-width: 490px;
  margin: 0px auto;

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      padding: 17px 16px 0 16px;
      max-width: 100%;
    }
  `}

  input {
    height: 27px;

    &.Mui-disabled {
      color: ${colorSet.primary400};
    }
  }

  &.space-box {
    height: 126px;

    ${({ theme }) => css`
      ${theme.breakpoints.down('xs')} {
        height: 48px;
      }
    `}
  }
  .view-full-text {
    line-height: 143%;
    display: inline;
    letter-spacing: -0.1px;
    text-transform: inherit;
    color: ${colorSet.gray900};
    text-decoration: underline;
  }
  .content-email {
    margin-bottom: 12px;
    div + div {
      margin-top: 12px;
    }
    button {
      margin-left: 8px;
      white-space: nowrap;
      border-radius: 8px;
    }
    .content-email-item {
      display: flex;
      .MuiInputBase-root.Mui-disabled {
        background: #f8fbfd !important;
      }
      input.Mui-disabled {
        color: ${colorSet.gray600} !important;
      }
      button.Mui-disabled {
        background: ${colorSet.gray200} !important;
      }
    }
    .email-verification-code {
      .content-email-item-description {
        margin-top: 3px;
      }
    }
  }
  .inflow-route-title {
    margin-bottom: 20px;
    span {
      color: ${colorSet.gray600};
      font-weight: normal;
      ${({ theme }) => css`
        ${theme.breakpoints.down('xs')} {
          display: block;
        }
      `}
    }
  }
  .MuiOutlinedInput-root {
    border-radius: 8px;
    color: #343a40;
    font-size: 16px;
    line-height: 24px;
    /* Change the white to any color */
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
      font-size: 16px;
      line-height: 24px;
    }
    input:-webkit-autofill {
      -webkit-text-fill-color: #343a40 !important;
    }
    input:-webkit-autofill::first-line {
      font-size: 16px;
      line-height: 24px;
    }
  }
  .checkbox-agree-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .customized-typography > span {
      color: ${colorSet.primary500};
    }
  }
  .login-btn {
    margin-top: 16px;
    color: ${colorSet.gray700};
  }
`;

export const FooterBtn = styled.div`
  width: 100%;
  display: flex;
  margin-top: 30px;
  button + button {
    margin-left: 16px;
  }
  button {
    border-radius: 8px;
    &:disabled {
      background: ${colorSet.gray200};
    }
  }
  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      margin-top: 44px;
    }
  `}
`;

export const InputItemStyle = styled.div`
  &:not(:last-child) {
    margin-bottom: 38px;
  }
  .item-title {
    display: flex;
    margin-bottom: 12px;
    span {
      color: ${colorSet.gray600};
      font-weight: normal;
    }
    .sub-title {
      margin-left: auto;
      font-size: 12px;
      color: ${colorSet.gray600};
    }
  }
  .description {
    display: block;
    color: ${colorSet.gray600};
    margin-left: 120px;
    margin-top: 8px;
  }
  .MuiSvgIcon-root {
    color: ${colorSet.gray600};
  }
  .remove-red-eye-icon {
    .MuiSvgIcon-root {
      color: ${colorSet.gray700};
    }
  }
`;
