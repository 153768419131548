import React from 'react';
import { UploadState } from '@modules/upload';
import { CustomizedButton, CustomizedTypography } from '@components/Customized';
import CustomizedCircularProgress from './CustomizedCircularProgress';
import { Box } from '@material-ui/core';
import { CheckCircle, ErrorOutlineOutlined } from '@material-ui/icons';
import { useStatusIconStyles } from '@components/Popper/UploadStatusPopper/styles';

interface StatusIconProps {
  uploadState: UploadState;
  progress?: number;
  retry?: () => void;
}

const StatusIcon: React.FC<StatusIconProps> = ({
  uploadState,
  progress,
  retry
}) => {
  const { PENDING, UPLOADING, FINISHED, ERROR, TIMEOUT, OVERSIZE } =
    UploadState;
  const { errorBox, customButton, checkCircleIcon } = useStatusIconStyles();

  switch (uploadState) {
    case PENDING:
      return <CustomizedCircularProgress />;
    case UPLOADING:
      return <CustomizedCircularProgress value={progress} />;
    case FINISHED:
      return <CheckCircle className={checkCircleIcon} />;
    case ERROR || TIMEOUT:
      return (
        <Box className={errorBox}>
          <CustomizedButton
            className={customButton}
            variant="text"
            color="error"
            onClick={retry}
          >
            Try again
          </CustomizedButton>
          <ErrorOutlineOutlined />
        </Box>
      );
    case OVERSIZE:
      return (
        <Box className={errorBox}>
          <CustomizedTypography variant="body2" color="error">
            용량초과
          </CustomizedTypography>
          <ErrorOutlineOutlined />
        </Box>
      );
    default:
      return <CustomizedCircularProgress value={progress} />;
  }
};

export default StatusIcon;
