import {
  CapaCADViewer,
  CapaGroupChat,
  CapaWelcome,
  CapaWelcomeMobile,
  CapaGroupChatMobile,
  CapaCADViewerMobile
} from '@assets';

export const SLIDE_DATA = [
  {
    image: CapaWelcome,
    title: 'welcome.welcome',
    content: 'welcome.hello_welcome_to_connect'
  },
  {
    image: CapaGroupChat,
    title: 'welcome.group_chat',
    content: 'welcome.safely_share_cad_files_to_groups'
  },
  {
    image: CapaCADViewer,
    title: 'welcome.cad_viewer',
    content: 'welcome.view_cad_files_and_participate_in_groups'
  }
];

export const SLIDE_MOBILE_DATA = [
  {
    image: CapaWelcomeMobile,
    title: 'welcome.welcome',
    content: 'welcome.hello_welcome_to_connect'
  },
  {
    image: CapaGroupChatMobile,
    title: 'welcome.group_chat',
    content: 'welcome.safely_share_cad_files_to_groups'
  },
  {
    image: CapaCADViewerMobile,
    title: 'welcome.cad_viewer',
    content: 'welcome.view_cad_files_and_participate_in_groups'
  }
];
