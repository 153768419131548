import React, { useEffect, useRef, useState } from 'react';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Chip,
  Popover,
  Typography,
  useMediaQuery,
  Tooltip
} from '@material-ui/core';
import { CustomizedButton } from '@components/Customized';
import { useStyles } from './styles';
import {
  KeyboardArrowLeft,
  KeyboardArrowDownOutlined,
  CheckOutlined,
  ArrowBackIosOutlined
} from '@material-ui/icons';
import useMyDrive from '@hooks/Drive/MyDrive/useMyDrive';
import {
  SHARE_DIALOG_SCREEN,
  SHARE_FILES_TO_VALUE,
  DEFAULT_SELECTED_MEMBER,
  ACCESS_LEVEL_VALUE,
  ACCESS_LEVEL_NAME,
  ACCESS_LEVEL_OPTIONS,
  ACCESS_LEVEL_SHOW_OPTIONS
} from '@routes/Dashboard/Dialog/Share/types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { theme, useSnackbar } from '@components/Provider';
import {
  CapaErrorCode,
  useCreateGroupNameMutation,
  useInviteGroupMembersNameMutation
} from '@generated/Common/graphql';
import { CapaActivityType } from '@generated/Common/types';
import {
  CheckRegisteredCapaService,
  ManageGroup,
  SearchUser,
  SubscribeConnectUpdateData,
  useShareFilesToGroupMutation
} from '@generated/Drive/MyDrive/graphql';
import * as Apollo from '@apollo/client';
import { checkShareTimes } from '@routes/Dashboard/Dialog/Share/utils';
import { useTranslation } from 'react-i18next';
import { LANGUAGE_VALUE } from '@routes/Common/ChangeLanguage/types';
import ConfirmRemoveDialog from '@routes/Dashboard/Dialog/Share/GroupNameMembers/ConfirmRemoveDialog/ConfirmRemoveDialog';
import useAuth from '@hooks/useAuth';
import useDebounce from '@hooks/useDebounce';
import {
  AUTOCOMPLETE_REASON,
  CONNECT_UPDATE_TYPE,
  DEFAULT_ITEMS_MAX,
  DEFAULT_ITEMS_OFFSET
} from '@routes/Common/types';
import OverflowTip from '@components/OverflowTooltips';
import useCommon from '@hooks/Common/useCommon';
import useUserActionLog, {
  Enum_Action_Button_Enum,
  Enum_Action_Input_Enum,
  Enum_Action_Page_Enum
} from '@hooks/useUserActionLog';
import { getDisplayedAvatarString } from '@utils/common';
import i18n from '@i18n/index';
import { MyContacts, CapaMembers } from '@assets';
import AddContacts from '@routes/Dashboard/Dialog/Share/AddContacts/AddContacts';
import clsx from 'clsx';
import CapaAvatar from '@components/CapaAvatar';

interface Props {
  checkedFiles?: any;
  isGroupMembers?: boolean;
  listGroup?: any;
  onClose?: any;
  groupId?: any;
  refetchGroup?: any;
  groupDataState?: any;
}

const GroupNameMembersForm: React.FC<Props> = ({
  checkedFiles,
  isGroupMembers,
  listGroup,
  onClose,
  groupId,
  refetchGroup,
  groupDataState
}) => {
  const { t } = useTranslation();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const {
    onSetShowHideShareDialog,
    onSetShareDialogScreen,
    onSetValueGroupNameDialog,
    onSetShareFilesToType,
    onSetIdGroupName,
    myDrive,
    onSetchatRoomIdGroupName,
    searchUserState,
    onSearchUser
  } = useMyDrive();
  const classes = useStyles();
  const { openFeedbackDialog } = useCommon();
  const [manageGroupUser] = Apollo.useMutation(ManageGroup);
  const [ShareFilesToGroup] = useShareFilesToGroupMutation();
  const [inviteGroupMembersNameMutation] = useInviteGroupMembersNameMutation();
  const [createGroupNameMutation] = useCreateGroupNameMutation();
  const languageValue = i18n.language;
  const { userState } = useAuth();

  const [anchorEl, setAnchorEl] = useState(null);
  const [listMembers, setListMembers] = useState([]);
  const [isAddMembers, setIsAddMembers] = useState(true);
  const [optionListMembers, setOptionListMembers] = useState([]);
  const [searchOptions, setSearchOptions] = useState([]);
  const [selectedMember, setSelectedMember] = useState(DEFAULT_SELECTED_MEMBER);
  const [manageUser, setManageUser] = useState({
    accessLevel: undefined
  });
  const prevManageUser = useRef(manageUser);

  const [isOpenRemoveEmail, setOpenRemoveEmail] = useState(false);
  const [removeFile, setRemoveFile] = useState({
    accessLevel: undefined
  });
  const [userNameEmail, setUserNameEmail] = useState('');

  const usersFirstOwner = groupDataState?.data?.users[0]?.email;
  const accessLevel = groupDataState?.data?.accessLevel ?? false;
  const userInfo = userState?.data?.email;
  const [internalUserOptions, setInternalUserOptions] = useState<any>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [internalSearchUser] = Apollo.useLazyQuery(SearchUser, {
    fetchPolicy: 'no-cache'
  });
  const { onInsertUserActionLogOne } = useUserActionLog();
  const [isShowHideContactsModal, setShowHideContactsModal] =
    useState<boolean>(false);

  const userMember = groupDataState?.data?.users.filter(
    (item: any) => item.email === userInfo
  );

  const patternValue =
    /[-A-Za-z0-9_]+[-A-Za-z0-9_.]*[@]{1}[-A-Za-z0-9_]+[-A-Za-z0-9_.]*[.]{1}[A-Za-z]{1,5}/;
  const [emailValue, setEmailValue] = useState('');
  const [isDisabledAddButton, setDisabledAddButton] = useState(true);
  const [checkRegisteredCapaService, { data: checkRegisteredCapaServiceData }] =
    Apollo.useLazyQuery(CheckRegisteredCapaService, {
      fetchPolicy: 'no-cache'
    });

  Apollo.useSubscription(SubscribeConnectUpdateData, {
    onSubscriptionData: (subscriptionData) => {
      const subscribeConnectUpdateData =
        subscriptionData?.subscriptionData?.data;
      if (subscribeConnectUpdateData) {
        switch (
          subscribeConnectUpdateData?.subscribeConnectUpdateData?.data?.type
        ) {
          case CONNECT_UPDATE_TYPE.MEMBER_UPDATE: {
            setOptionListMembers([]);
            break;
          }
        }
      }
    }
  });

  useEffect(() => {
    prevManageUser.current = manageUser || {};
  }, [manageUser]);

  useEffect(() => {
    if (isGroupMembers) {
      onInsertUserActionLogOne(
        Enum_Action_Page_Enum.Page_MembersModal,
        CapaActivityType.PageVisit
      );
    }

    internalSearchUser({
      variables: {
        keyword: '',
        limit: DEFAULT_ITEMS_MAX,
        offset: DEFAULT_ITEMS_OFFSET
      }
    });
  }, []);

  const handleClickOpenPopUp = (event: any, item: any, index: number) => {
    if (userMember && userMember[0].accessLevel !== ACCESS_LEVEL_VALUE.OWNER) {
      return;
    }
    setAnchorEl(event.currentTarget);
    const member = { item, index };
    setSelectedMember(member);
    setManageUser(member.item);
  };

  const handleOpenPopUpAddMembers = (event: any, item: any, index: number) => {
    setAnchorEl(event.currentTarget);
    const member = { item, index };
    setSelectedMember(member);
    setManageUser(member.item);
  };
  const handleClosePopUp = () => {
    setAnchorEl(null);
  };

  const handleSelected = (val: any) => {
    let newArr: any = [...optionListMembers];
    if (val.value === ACCESS_LEVEL_VALUE.REMOVE && !isGroupMembers) {
      newArr.splice(selectedMember.index, 1);
      setOptionListMembers(newArr);
      handleClosePopUp();
      return;
    }
    newArr[selectedMember.index] = {
      userName: selectedMember.item.userName,
      email: selectedMember.item.email,
      accessLevel: val.value,
      isCapaUser: selectedMember.item.isCapaUser,
      accessLevelName: val.name
    };
    if (isGroupMembers) {
      if (val.value === ACCESS_LEVEL_VALUE.REMOVE) {
        onInsertUserActionLogOne(
          Enum_Action_Button_Enum.Button_MembersModal_GroupAccess_Remove,
          CapaActivityType.ButtonClick
        );
        setRemoveFile(newArr[selectedMember.index]);
        setUserNameEmail(newArr[selectedMember.index].email);
        setOpenRemoveEmail(true);
      } else {
        handleManageGroupUser(newArr[selectedMember.index]);
      }
    } else {
      setOptionListMembers(newArr);
    }

    handleClosePopUp();
  };

  const onSubmitRemoveEmail = () => {
    setManageUser(removeFile);
    handleManageGroupUser(removeFile);
    setOpenRemoveEmail(false);
    onCloseGroupNameDiaLog();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'popoverId' : undefined;
  const onSubmit = () => {
    if (isGroupMembers) {
      if (onClose) {
        return onClose();
      }
    }

    onInsertUserActionLogOne(
      Enum_Action_Button_Enum.Button_Share_AddMembersModal_Create,
      CapaActivityType.ButtonClick
    );
    createGroupNameMutation({
      variables: {
        groupName: myDrive.valueGroupNameDialog || ''
      }
    }).then((res: any) => {
      const result = res.data.createGroup;
      if (result.result === CapaErrorCode.Success) {
        onSetIdGroupName({ idGroupName: result.data.id });
        onSetchatRoomIdGroupName({
          chatRoomIdGroupName: result.data.chatRoomId
        });
        let payload = {
          groupId: result.data.id || '',
          inviteUsers: optionListMembers.map((member: any) => {
            return {
              email: member.email,
              accessLevel: member.accessLevel
            };
          })
        };
        inviteGroupMembersNameMutation({
          variables: payload
        }).then((res: any) => {
          if (res.data.inviteGroupMembers.result === CapaErrorCode.Success) {
            shareFilesToGroup(result.data.id);
          }
        });
      }
    });
  };

  const shareFilesToGroup = (id: string) => {
    const payload: any = {
      groupId: id,
      connectFileIds: checkedFiles.map((file: any) => file.id)
    };
    ShareFilesToGroup({ variables: payload }).then((res) => {
      const result = res?.data?.shareFilesToGroup?.result;
      if (result === CapaErrorCode.Success) {
        onSetShareDialogScreen({
          shareDialogScreen: SHARE_DIALOG_SCREEN.SUCCESSFULLY_SHARED
        });
        checkShareTimes(() => {
          openFeedbackDialog({
            isOpenFeedback: true,
            isFeedback: true
          });
        });
      }
    });
  };

  const onCloseGroupNameDiaLog = () => {
    if (isGroupMembers) {
      if (onClose) {
        return onClose();
      }
    }
    onInsertUserActionLogOne(
      Enum_Action_Button_Enum.Button_Share_AddMembersModal_Back,
      CapaActivityType.ButtonClick
    );
    onSetShowHideShareDialog({ isShowHideShareDialog: false });
    onSetShareFilesToType({ shareFilesToType: SHARE_FILES_TO_VALUE.EMPTY });
    onSetIdGroupName({ idGroupName: '' });
    onSetValueGroupNameDialog({
      valueGroupNameDialog: ''
    });
  };

  const onCloseScreenDialog = () => {
    if (isGroupMembers) {
      return onCloseGroupNameDiaLog();
    }
    onInsertUserActionLogOne(
      Enum_Action_Button_Enum.Button_Share_AddMembersModal_Cancel,
      CapaActivityType.ButtonClick
    );
    onSetShareDialogScreen({
      shareDialogScreen: SHARE_DIALOG_SCREEN.GROUP_NAME
    });
  };

  const handleClickAddMembers = () => {
    checkRegisteredCapaService({
      variables: {
        emails: listMembers
      }
    });
  };

  useEffect(() => {
    if (
      checkRegisteredCapaServiceData?.checkRegisteredCapaService?.result ===
      CapaErrorCode.Success
    ) {
      const groupMembers: any =
        checkRegisteredCapaServiceData?.checkRegisteredCapaService?.data?.map(
          (item: any) => {
            return {
              userName: item?.connectUser?.userName,
              email: item?.email?.replace(/\s/g, ''),
              accessLevel: ACCESS_LEVEL_VALUE.MANAGER,
              isCapaUser: item?.scopes?.length > 0,
              accessLevelName: ACCESS_LEVEL_NAME.MANAGER
            };
          }
        );

      if (isGroupMembers) {
        handleAddMembers(groupMembers);
      } else {
        handleAddMembersInternal(groupMembers);
      }
    }
  }, [checkRegisteredCapaServiceData]);

  const handleAddMembersInternal = (groupMembers: any) => {
    onInsertUserActionLogOne(
      Enum_Action_Button_Enum.Button_Share_AddMembersModal_Add,
      CapaActivityType.ButtonClick
    );
    let isDuplicateEmail = false;
    optionListMembers.forEach((member: any) => {
      isDuplicateEmail = groupMembers.some(
        (item: any) => member.email === item.email
      );
    });

    if (isDuplicateEmail) {
      enqueueSnackbar(t('snackbar.this_email_already_exists_in_the_group'), {
        variant: 'error'
      });
    }
    setOptionListMembers(
      groupMembers
        .concat(optionListMembers)
        .reduce((members: any, user: any) => {
          if (!members.some((obj: any) => obj.email === user.email)) {
            members.push(user);
          }
          return members;
        }, [])
    );
    setEmailValue('');
    setListMembers([]);
    setSearchOptions([]);
    setInternalUserOptions([]);
    setIsAddMembers(true);
  };

  const handleManageGroupUser = (listMembers: any) => {
    if (ACCESS_LEVEL_VALUE.REMOVE) {
      setManageUser({
        accessLevel: undefined
      });
    }
    let payload = {
      groupId: groupId,
      userEmail: listMembers.email,
      action:
        listMembers.accessLevel === ACCESS_LEVEL_VALUE.REMOVE
          ? ACCESS_LEVEL_VALUE.REMOVE
          : ACCESS_LEVEL_VALUE.UPDATE,
      accessLevel:
        listMembers.accessLevel === ACCESS_LEVEL_VALUE.REMOVE
          ? prevManageUser?.current?.accessLevel
          : listMembers.accessLevel
    };
    manageGroupUser({
      variables: payload
    }).then((res: any) => {
      if (res.data.manageGroupMember.result === CapaErrorCode.Success) {
        if (refetchGroup) {
          setOptionListMembers([]);
          refetchGroup();
          setListMembers([]);
          setSearchOptions([]);
          setIsAddMembers(true);
        }
      }
    });
  };

  const onAddContacts = (contactIds: any) => {
    checkRegisteredCapaService({
      variables: {
        emails: contactIds
      }
    });
  };

  const handleAddMembers = (groupMembers: any) => {
    onInsertUserActionLogOne(
      Enum_Action_Button_Enum.Button_MembersModal_Add,
      CapaActivityType.ButtonClick
    );
    let listMembers = groupMembers.reduce((members: any, user: any) => {
      if (!members.some((obj: any) => obj.email === user.email)) {
        members.push(user);
      }
      return members;
    }, []);

    setDisabledAddButton(true);
    let duplicateResult: any = [];
    let memberResult: any = [];

    let isDuplicateEmail = false;
    optionListMembers.forEach((member: any) => {
      if (listMembers.some((item: any) => member.email === item.email)) {
        isDuplicateEmail = true;
        duplicateResult.push(member);
      }
    });

    if (isDuplicateEmail) {
      enqueueSnackbar(t('snackbar.this_email_already_exists_in_the_group'), {
        variant: 'error'
      });
    }

    if (duplicateResult.length > 0) {
      memberResult = listMembers.filter(
        (member: any) =>
          !duplicateResult.some((result: any) => member.email === result.email)
      );
    } else {
      memberResult = listMembers;
    }

    if (memberResult.length === 0) {
      duplicateResult = [];
      setOptionListMembers([]);
      refetchGroup();
      setListMembers([]);
      setSearchOptions([]);
      setIsAddMembers(true);
      return;
    }

    let payload = {
      groupId: groupId,
      inviteUsers: memberResult.map((member: any) => {
        return {
          email: member.email.replace(/\s/g, ''),
          accessLevel: member.accessLevel
        };
      })
    };
    inviteGroupMembersNameMutation({
      variables: payload
    }).then((res: any) => {
      if (res.data.inviteGroupMembers.result === CapaErrorCode.Success) {
        if (refetchGroup) {
          setEmailValue('');
          setOptionListMembers([]);
          refetchGroup();
          setListMembers([]);
          setSearchOptions([]);
          setIsAddMembers(true);
        }
      }
    });
  };

  const debouncedSearchTerm = useDebounce(emailValue, 500);

  useEffect(() => {
    if (debouncedSearchTerm) {
      onSearchUser({
        variables: {
          keyword: debouncedSearchTerm,
          limit: 40,
          offset: 0
        }
      });
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (searchUserState?.data?.length > 0) {
      setInternalUserOptions(searchUserState?.data);
    }
  }, [searchUserState]);

  useEffect(() => {
    if (emailValue) {
      setDisabledAddButton(!patternValue.test(emailValue));
    } else {
      if (searchOptions.length > 0) {
        setIsAddMembers(false);
      }
      setDisabledAddButton(false);
    }
  }, [emailValue]);

  useEffect(() => {
    if (listGroup && listGroup.length > 0) {
      setOptionListMembers(listGroup.concat(optionListMembers));
    }
  }, [listGroup]);
  return (
    <>
      <div
        className={
          isOpenRemoveEmail ? classes.rootHideModal : classes.rootShowModal
        }
      >
        <DialogTitle classes={{ root: classes.rootTitle }} disableTypography>
          <div className={classes.subTitle}>
            {!matches ? (
              <div
                className={classes.titleIcon}
                onClick={() => {
                  if (isGroupMembers) return;
                  onCloseScreenDialog();
                }}
              >
                {isGroupMembers ? (
                  <img src={CapaMembers} alt="capaMembers" />
                ) : (
                  <KeyboardArrowLeft />
                )}
              </div>
            ) : (
              <ArrowBackIosOutlined onClick={onCloseScreenDialog} />
            )}
            {isGroupMembers ? (
              <span className={classes.name}>
                {t('add_members_modal.members_title')}
              </span>
            ) : (
              <OverflowTip value={myDrive.valueGroupNameDialog || ''}>
                <span className={classes.name}>
                  {languageValue === LANGUAGE_VALUE.EN
                    ? t('add_members_modal.header')
                    : null}{' '}
                  <span className="title-group-name">
                    {myDrive.valueGroupNameDialog}
                  </span>{' '}
                  {languageValue === LANGUAGE_VALUE.KO
                    ? t('add_members_modal.header')
                    : null}
                </span>
              </OverflowTip>
            )}

            {groupDataState && groupDataState.data && accessLevel !== 'MEMBER' && (
              <Tooltip title={t('tooltip.my_contact') || 'My contact'}>
                <div
                  className={classes.icon}
                  onClick={() => setShowHideContactsModal(true)}
                >
                  <img
                    src={MyContacts}
                    alt="capaAddContacts"
                    className={classes.addContactsIcon}
                  />
                </div>
              </Tooltip>
            )}
          </div>
        </DialogTitle>
        <DialogActions className={classes.csDialogContent}>
          {isGroupMembers &&
          userMember &&
          userMember[0].accessLevel !== ACCESS_LEVEL_VALUE.MEMBER ? (
            <div style={{ width: '100%' }}>
              <div className={classes.csDialogSearch}>
                <Autocomplete
                  multiple
                  id="tags-filled"
                  options={internalUserOptions.map((user: any) => user.email)}
                  freeSolo
                  renderTags={(value: any, getTagProps: any) =>
                    value.map((option: any, index: number) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  onChange={(event: any, newValue: any, reason: any) => {
                    onInsertUserActionLogOne(
                      Enum_Action_Button_Enum.Click_MembersModal_Contact,
                      CapaActivityType.ButtonClick
                    );
                    if (reason === AUTOCOMPLETE_REASON.CLEAR) {
                      setEmailValue('');
                    }
                    let isValidEmail = false;

                    newValue.forEach((value: any) => {
                      isValidEmail = patternValue.test(value);
                    });

                    if (newValue.length === 0) {
                      setInternalUserOptions([]);
                      setDisabledAddButton(true);
                    }

                    if (!isValidEmail && newValue.length > 0) {
                      return;
                    }

                    setSearchOptions(newValue);

                    if (newValue && newValue.length > 0) {
                      if (emailValue) {
                        setDisabledAddButton(!patternValue.test(emailValue));
                      }
                      setIsAddMembers(false);
                      setListMembers(newValue);
                      if (reason !== AUTOCOMPLETE_REASON.REMOVE_OPTION) {
                        setEmailValue('');
                      }
                      setInternalUserOptions([]);
                    } else {
                      setInternalUserOptions([]);
                      setListMembers([]);
                      setIsAddMembers(true);
                    }
                  }}
                  inputValue={emailValue}
                  value={searchOptions}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      placeholder={t('add_members_modal.placeholder')}
                      variant="outlined"
                      size="small"
                      fullWidth
                      onChange={(e) => {
                        setEmailValue(e.target.value);

                        if (e.target.value) {
                          setIsAddMembers(false);
                          // @ts-ignore
                          setListMembers([...searchOptions, e.target.value]);
                        } else {
                          setIsAddMembers(true);
                          setListMembers([]);
                        }
                      }}
                    />
                  )}
                  onFocus={() => {
                    onInsertUserActionLogOne(
                      Enum_Action_Input_Enum.Input_MembersModal_Email,
                      CapaActivityType.actionInput
                    );
                  }}
                />
                <CustomizedButton
                  type="submit"
                  variant="outlined"
                  color="primary"
                  disabled={isDisabledAddButton || isAddMembers}
                  onClick={handleClickAddMembers}
                  className={classes.customAddButton}
                >
                  {t('add_members_modal.add_button')}
                </CustomizedButton>
              </div>
              {emailValue && !patternValue.test(emailValue) && (
                <Typography variant="caption" color="error">
                  {t('add_members_modal.email_not_valid')}
                </Typography>
              )}
            </div>
          ) : null}
          {isGroupMembers &&
            userMember &&
            userMember[0].accessLevel === ACCESS_LEVEL_VALUE.MEMBER && (
              <div className={classes.line}></div>
            )}
          {!isGroupMembers && (
            <div className={classes.csDialogSearch}>
              <Autocomplete
                multiple
                id="tags-filled"
                options={internalUserOptions.map((user: any) => user.email)}
                freeSolo
                renderTags={(value: any, getTagProps: any) =>
                  value.map((option: any, index: number) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                onChange={(event: any, newValue: any, reason: any) => {
                  onInsertUserActionLogOne(
                    Enum_Action_Button_Enum.Click_Share_AddMembersModal_Contact,
                    CapaActivityType.ButtonClick
                  );
                  if (reason === AUTOCOMPLETE_REASON.CLEAR) {
                    setEmailValue('');
                  }
                  let isValidEmail = false;

                  newValue.forEach((value: any) => {
                    isValidEmail = patternValue.test(value);
                  });

                  if (newValue.length === 0) {
                    setInternalUserOptions([]);
                    setDisabledAddButton(true);
                  }

                  if (!isValidEmail && newValue.length > 0) {
                    return;
                  }

                  setSearchOptions(newValue);

                  if (newValue && newValue.length > 0) {
                    if (emailValue) {
                      setDisabledAddButton(!patternValue.test(emailValue));
                    }
                    setIsAddMembers(false);
                    setListMembers(newValue);
                    if (reason !== AUTOCOMPLETE_REASON.REMOVE_OPTION) {
                      setEmailValue('');
                    }
                    setInternalUserOptions([]);
                  } else {
                    setInternalUserOptions([]);
                    setListMembers([]);
                    setIsAddMembers(true);
                  }
                }}
                inputValue={emailValue}
                value={searchOptions}
                onFocus={() => {
                  onInsertUserActionLogOne(
                    Enum_Action_Input_Enum.Input_AddMembersModal_Email,
                    CapaActivityType.actionInput
                  );
                }}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    placeholder={t('add_members_modal.placeholder')}
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) => {
                      setEmailValue(e.target.value);

                      if (e.target.value) {
                        setIsAddMembers(false);
                        // @ts-ignore
                        setListMembers([...searchOptions, e.target.value]);
                      } else {
                        setInternalUserOptions([]);
                        setListMembers([]);
                        setIsAddMembers(true);
                      }
                    }}
                  />
                )}
              />
              {isGroupMembers && !matches && (
                <CustomizedButton
                  type="submit"
                  variant="outlined"
                  color="primary"
                  disabled={isDisabledAddButton || isAddMembers}
                  onClick={handleClickAddMembers}
                  className={classes.customAddButton}
                >
                  {t('add_members_modal.add_button')}
                </CustomizedButton>
              )}
              {!isGroupMembers && (
                <CustomizedButton
                  type="submit"
                  variant="outlined"
                  color="primary"
                  disabled={isDisabledAddButton || isAddMembers}
                  onClick={handleClickAddMembers}
                  className={classes.customAddButton}
                >
                  {t('add_members_modal.add_button')}
                </CustomizedButton>
              )}
            </div>
          )}
        </DialogActions>
        <div className={classes.contentWrapper}>
          {optionListMembers &&
            optionListMembers.map((member: any, index: number) => (
              <DialogContent
                key={index}
                className={classes.csDialogContentBottom}
              >
                {isGroupMembers ? (
                  <div className={classes.csDialogContentList}>
                    <div className={classes.avatar}>
                      <CapaAvatar item={member} />
                    </div>
                    <div className={classes.csDialogContentText}>
                      <div className={classes.csConnectUserName}>
                        <div className="name">
                          {getDisplayedAvatarString(
                            member.connectUserName,
                            member.email
                          )}
                        </div>
                        {userInfo === member.email && member.connectUserName ? (
                          <span className="text-you">
                            ({t('group_room.text_you')})
                          </span>
                        ) : (
                          ''
                        )}
                      </div>
                      <div className={classes.csDialogUserName}>
                        {member.email}
                      </div>
                    </div>
                    {member.accessLevel === ACCESS_LEVEL_VALUE.OWNER ? (
                      <div
                        className={classes.csDialogContentTop}
                        aria-describedby={id}
                      >
                        {t(
                          ACCESS_LEVEL_SHOW_OPTIONS.filter(
                            (option: any) => option.value === member.accessLevel
                          )[0]
                            ? ACCESS_LEVEL_SHOW_OPTIONS.filter(
                                (option: any) =>
                                  option.value === member.accessLevel
                              )[0].name
                            : ''
                        )}
                      </div>
                    ) : (
                      <div
                        className={
                          userMember &&
                          userMember[0].accessLevel !== ACCESS_LEVEL_VALUE.OWNER
                            ? classes.csDialogContentTextFile
                            : classes.csDialogContentPosition
                        }
                        aria-describedby={id}
                        onClick={(e) => {
                          onInsertUserActionLogOne(
                            Enum_Action_Button_Enum.Button_MembersModal_GroupAccess,
                            CapaActivityType.ButtonClick
                          );
                          handleClickOpenPopUp(e, member, index);
                        }}
                      >
                        {t(
                          ACCESS_LEVEL_SHOW_OPTIONS.filter(
                            (option: any) => option.value === member.accessLevel
                          )[0]
                            ? ACCESS_LEVEL_SHOW_OPTIONS.filter(
                                (option: any) =>
                                  option.value === member.accessLevel
                              )[0].name
                            : ''
                        )}
                        <KeyboardArrowDownOutlined className="icon" />
                      </div>
                    )}
                  </div>
                ) : (
                  <div className={classes.csDialogContentList}>
                    <div className={classes.avatar}>
                      <CapaAvatar item={member} />
                    </div>
                    <div className={classes.csDialogContentText}>
                      <div className={classes.csConnectUserName}>
                        <div className="name">
                          {getDisplayedAvatarString(
                            member.userName,
                            member.email
                          )}
                        </div>
                        {userInfo === member.email && member.userName ? (
                          <span className="text-you">
                            ({t('group_room.text_you')})
                          </span>
                        ) : (
                          ''
                        )}
                      </div>
                      <div className={classes.csDialogUserName}>
                        {member.email}
                      </div>
                    </div>
                    {usersFirstOwner === member.email ? (
                      <div
                        className={classes.csDialogContentTop}
                        aria-describedby={id}
                      >
                        {member.accessLevel.toLocaleLowerCase()}
                      </div>
                    ) : (
                      <div
                        className={classes.csDialogContentPosition}
                        aria-describedby={id}
                        onClick={(e) => {
                          onInsertUserActionLogOne(
                            Enum_Action_Button_Enum.Button_Share_AddMembersModal_GroupAccess,
                            CapaActivityType.ButtonClick
                          );
                          handleOpenPopUpAddMembers(e, member, index);
                        }}
                      >
                        {t(
                          ACCESS_LEVEL_OPTIONS.filter(
                            (option: any) => option.value === member.accessLevel
                          )[0]
                            ? ACCESS_LEVEL_OPTIONS.filter(
                                (option: any) =>
                                  option.value === member.accessLevel
                              )[0].name
                            : ''
                        )}
                        <KeyboardArrowDownOutlined className="icon" />
                      </div>
                    )}
                  </div>
                )}
              </DialogContent>
            ))}
          <Popover
            className={classes.customPopover}
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClosePopUp}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
          >
            <div className={classes.csDialogContentPopup}>
              {matches && (
                <Typography className={classes.item}>
                  <CapaAvatar item={selectedMember.item} />
                  <div className="name">
                    <div>{selectedMember.item.email}</div>
                  </div>
                </Typography>
              )}
              {ACCESS_LEVEL_OPTIONS.map((accessLevel: any, index: number) => (
                <Typography
                  key={index}
                  onClick={() => handleSelected(accessLevel)}
                  className={classes.itemPopUp}
                >
                  {selectedMember.item.accessLevel === accessLevel.value ? (
                    <CheckOutlined className={classes.iconCheck} />
                  ) : (
                    <div className="spacing" />
                  )}
                  <div className="name">
                    <div className="text">{t(accessLevel.name)}</div>
                  </div>
                </Typography>
              ))}
            </div>
          </Popover>
        </div>
        {isGroupMembers && matches && (
          <DialogActions classes={{ root: classes.action }}>
            <div className={clsx(classes.wrapperBtn, classes.wrapperMemberBtn)}>
              <CustomizedButton
                variant="contained"
                color="default"
                onClick={onCloseGroupNameDiaLog}
              >
                {t('add_members_modal.close_button')}
              </CustomizedButton>
            </div>
          </DialogActions>
        )}
        {!isGroupMembers ? (
          <DialogActions classes={{ root: classes.action }}>
            <div className={classes.wrapperBtn}>
              <CustomizedButton
                variant="contained"
                color="default"
                onClick={onCloseGroupNameDiaLog}
              >
                {t('add_members_modal.cancel_button')}
              </CustomizedButton>
              <CustomizedButton
                type="submit"
                variant="contained"
                color="primary"
                onClick={onSubmit}
                disabled={optionListMembers.length === 0}
              >
                {t('add_members_modal.done_button')}
              </CustomizedButton>
            </div>
          </DialogActions>
        ) : (
          !matches && null
        )}
      </div>
      <ConfirmRemoveDialog
        listMembers={optionListMembers}
        userEmail={userNameEmail}
        onSubmit={onSubmitRemoveEmail}
        isOpen={isOpenRemoveEmail}
        onClose={() => {
          setOpenRemoveEmail(false);
        }}
      />
      <AddContacts
        isOpen={isShowHideContactsModal}
        onClose={() => setShowHideContactsModal(false)}
        onAddContacts={onAddContacts}
        disabledContacts={optionListMembers.map((member: any) => member.email)}
      />
    </>
  );
};

export default GroupNameMembersForm;
