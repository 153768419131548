import { createReducer } from 'typesafe-actions';
import { ICommonState, CommonAction, initialCommonState } from './types';
import {
  UPDATE_COMMON_STATE,
  INIT_COMMON_STATE,
  SHOW_HIDE_NUMBER_NOTICE,
  SET_SHOW_HIDE_WELCOME_DIALOG,
  SET_UNREAD_COUNT_GROUP_LIST,
  SET_DELETE_CHAT_BOT_SUCCESS_STATUS
} from './actions';

export const common = createReducer<ICommonState, CommonAction>(
  initialCommonState,
  {
    [UPDATE_COMMON_STATE]: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    },
    [SHOW_HIDE_NUMBER_NOTICE]: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    },
    [SET_SHOW_HIDE_WELCOME_DIALOG]: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    },
    [SET_UNREAD_COUNT_GROUP_LIST]: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    },
    [SET_DELETE_CHAT_BOT_SUCCESS_STATUS]: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    },
    [INIT_COMMON_STATE]: () => initialCommonState
  }
);
