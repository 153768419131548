import {
  IBMDelete,
  IBMDownload,
  IBMEdit,
  IBMInfomation,
  IBMShare,
  IBMView
} from '@assets';
import { CustomizedTypography } from '@components/Customized';
import { colorSet, useSnackbar } from '@components/Provider';
import { CapaConnectMyFile, CapaErrorCode } from '@generated/Common/graphql';
import { useRenameConnectFileMutation } from '@generated/Drive/MyDrive/graphql';
import useMyDrive from '@hooks/Drive/MyDrive/useMyDrive';
import useUserActionLog, {
  CapaActivityType,
  Enum_Action_Button_Enum
} from '@hooks/useUserActionLog';
import {
  Backdrop,
  Box,
  ClickAwayListener,
  IconButton,
  List,
  ListItem,
  Popper,
  SvgIcon,
  Tooltip,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { PublicOutlined } from '@material-ui/icons';
import { myDrivePathName } from '@routes/Common/types';
import FolderFormDialog from '@routes/Dashboard/Dialog/FolderForm/FolderForm';
import {
  SHARE_DIALOG_SCREEN,
  SHARE_FILES_TO_VALUE
} from '@routes/Dashboard/Dialog/Share/types';
import { STORAGE_ACTION } from '@utils/storage';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ReactComponent as IBMOverflowMenuHorizontal } from '../../../assets/icon/IBMOverflowMenuHorizontal.svg';
import { ReactComponent as IBMOverflowMenuHorizontalWhite } from '../../../assets/icon/IBMOverflowMenuHorizontalWhite.svg';

import {
  DEFAULT_FOLDER_NAME,
  EnumDashboardDialogType,
  EnumDashboardListControlType,
  EnumListControl
} from '../types';
import { useStyles } from './styles';

interface Props {
  onClick: (dialogType: EnumDashboardDialogType) => void;
  type: EnumDashboardListControlType;
  isShowPublic?: boolean;
  isOpen?: boolean;
  isDetail?: boolean;
  isShowDownLoad?: boolean;
  isShare?: boolean;
  isOpenFileViewer?: boolean;
  files: Array<CapaConnectMyFile>;
  onShowFileViewer?: () => void;
  onOpenViewer?: () => void;
  onClickEvent?: (event: string) => void;
  onSetCheckedFiles?: (files: any) => void;
  myDrivefiles?: any;
  isListSide?: boolean;
}

const MoreMenuPopupPresenter: React.FC<Props> = ({
  onClick,
  type,
  isShowPublic,
  isOpen,
  isDetail,
  isShowDownLoad,
  files,
  onShowFileViewer,
  isOpenFileViewer,
  onClickEvent,
  onSetCheckedFiles,
  isShare,
  myDrivefiles,
  onOpenViewer,
  isListSide
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [folderName, setFolderName] = useState<any>(DEFAULT_FOLDER_NAME);
  const { onInsertUserActionLogOne } = useUserActionLog();
  const history = useHistory();
  const [isShowHideFolderDialog, setShowHideFolderDialog] =
    useState<boolean>(false);
  const { onSetShowHideFileDetail } = useMyDrive(true);
  const [renameConnectFile] = useRenameConnectFileMutation();
  const { enqueueSnackbar } = useSnackbar();
  const onClose = () => setOpen(false);
  const {
    onSetShowHideShareDialog,
    onSetShareDialogScreen,
    onSetShareFilesToType,
    onSetRenameSuccessStatus,
    onRefetchConnectMyDrive
  } = useMyDrive();
  const [disbaledRenameSubmitButton, setDisbaledRenameSubmitButton] =
    useState(false);

  const onClickHandle = (value: EnumDashboardDialogType) => {
    onClick(value);
    onClose();
  };

  const onClickOpen = () => {
    onInsertUserActionLogOne(
      Enum_Action_Button_Enum.Button_MyDrive_File_View,
      CapaActivityType.ButtonClick
    );
    onSetCheckedFiles && onSetCheckedFiles(files);
    onClose();
    onOpenViewer && onOpenViewer();
  };

  const onClickShare = () => {
    onInsertUserActionLogOne(
      Enum_Action_Button_Enum.Button_MyDrive_File_Share,
      CapaActivityType.ButtonClick
    );
    onSetCheckedFiles && onSetCheckedFiles(files);
    onClose();
    onSetShowHideShareDialog({ isShowHideShareDialog: true });
    onSetShareDialogScreen({
      shareDialogScreen: SHARE_DIALOG_SCREEN.SHARE
    });
    onSetShareFilesToType({
      shareFilesToType: SHARE_FILES_TO_VALUE.EMPTY
    });
  };

  const onOpenFolderDialog = () => {
    onClose();
    const fileName = myDrivefiles.filter(
      (file: any) => file.id === files[0].id
    )[0]?.fileName;
    setFolderName(fileName);
    setShowHideFolderDialog(true);
  };
  const onSubmit = () => {
    onInsertUserActionLogOne(
      Enum_Action_Button_Enum.Button_MyDrive_File_Rename,
      CapaActivityType.ButtonClick
    );
    setDisbaledRenameSubmitButton(true);
    const pathnameUrl = history.location.pathname.split(`${myDrivePathName}/`);
    const oldFileName = myDrivefiles.filter(
      (file: any) => file.id === files[0].id
    )[0]?.fileName;
    const payload: {
      fileId: string;
      toName: string;
    } = {
      fileId: files && files[0].id,
      toName: folderName
    };

    renameConnectFile({ variables: payload })
      .then((res: any) => {
        const result = res?.data?.renameConnectFile?.result;

        if (result === CapaErrorCode.Success) {
          enqueueSnackbar(
            `${t('snackbar.rename', {
              oldFileName: oldFileName,
              newFileName: folderName
            })}`,
            {
              variant: 'success'
            }
          );
          if (onClickEvent) {
            onClickEvent(EnumListControl.RENAME);
          }
          onSetRenameSuccessStatus({
            isRenameSuccess: true
          });
          onClose();
          setShowHideFolderDialog(false);
          onRefetchConnectMyDrive({
            pathId: pathnameUrl[1] ? pathnameUrl[1] : ''
          });
        }
      })
      .catch(() => {
        setShowHideFolderDialog(false);
        enqueueSnackbar(`Rename error`, {
          variant: 'error'
        });
      });
  };

  return (
    <>
      <Tooltip title={t('tooltip.more') || 'More'}>
        {isListSide ? (
          <IconButton
            className={clsx(
              classes.menuIcon,
              'more-vert-menu',
              'btn-group-item mr-0',
              isOpenFileViewer ? classes.customSvg : ''
            )}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              if (e.currentTarget) {
                setAnchorEl(e.currentTarget);
                setOpen((prevOpen) => !prevOpen);
              }
            }}
          >
            <IBMOverflowMenuHorizontal />
          </IconButton>
        ) : (
          <IconButton
            style={{
              width: '32px',
              height: '32px',
              borderRadius: '8px',
              backgroundColor: colorSet.primary500,
              marginLeft: '8px'
            }}
            className={clsx(
              classes.menuIcon,
              'more-vert-menu',
              'btn-group-item mr-0',
              isOpenFileViewer ? classes.customSvg : ''
            )}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              if (e.currentTarget) {
                setAnchorEl(e.currentTarget);
                setOpen((prevOpen) => !prevOpen);
              }
            }}
          >
            <IBMOverflowMenuHorizontalWhite />
          </IconButton>
        )}
      </Tooltip>
      <FolderFormDialog
        dialogInfo={{
          title: 'Rename',
          submitButtonName: 'Save'
        }}
        folderNameValue={folderName}
        isOpen={isShowHideFolderDialog}
        onClose={() => setShowHideFolderDialog(false)}
        setValue={setFolderName}
        onSubmit={onSubmit}
        checkedFiles={files}
        disabledSubmitButton={disbaledRenameSubmitButton}
        setDisbaledRenameSubmitButton={() =>
          setDisbaledRenameSubmitButton(false)
        }
      />
      <Backdrop
        className={classes.backdrop}
        open={open}
        invisible
        onClick={() => {
          onClose();
        }}
      />
      <Popper
        className={classes.root}
        open={open}
        anchorEl={anchorEl}
        transition
        placement="bottom-end"
      >
        <ClickAwayListener onClickAway={onClose}>
          <Box className={classes.boxRoot}>
            <List className={classes.list}>
              {isListSide && (
                <>
                  <ListItem className={classes.listItem} onClick={onClickShare}>
                    <IconButton style={{ padding: 0, marginRight: '8px' }}>
                      <SvgIcon>
                        <IBMShare />
                      </SvgIcon>
                    </IconButton>
                    <CustomizedTypography variant="body2">
                      {t('my_drive.dropdown_menu.share')}
                    </CustomizedTypography>
                  </ListItem>
                  <ListItem
                    className={classes.listItem}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      onInsertUserActionLogOne(
                        Enum_Action_Button_Enum.Button_MyDrive_File_View,
                        CapaActivityType.ButtonClick
                      );
                      onOpenViewer && onOpenViewer();
                      onClose();
                    }}
                  >
                    <IconButton style={{ padding: 0, marginRight: '8px' }}>
                      <SvgIcon>
                        <IBMView />
                      </SvgIcon>
                    </IconButton>
                    <CustomizedTypography variant="body2">
                      {t('my_drive.dropdown_menu.view')}
                    </CustomizedTypography>
                  </ListItem>
                </>
              )}
              {isOpen && type !== EnumDashboardListControlType.ListHeader && (
                <ListItem
                  className={classes.listItem}
                  button
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    onClickOpen();
                  }}
                >
                  <IconButton style={{ padding: 0, marginRight: '8px' }}>
                    <SvgIcon>
                      <IBMView />
                    </SvgIcon>
                  </IconButton>
                  <CustomizedTypography variant="body2">
                    {t('my_drive.dropdown_menu.open')}
                  </CustomizedTypography>
                </ListItem>
              )}
              {isDetail && isMobile && (
                <ListItem
                  className={classes.listItem}
                  button
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    onSetCheckedFiles && onSetCheckedFiles(files);
                    onClose();
                    onSetShowHideFileDetail({
                      isShowIsDetailFile: true,
                      detailDrive: files[0]
                    });
                    localStorage.setItem(
                      STORAGE_ACTION.IS_OPEN_DETAIL_FILE,
                      'true'
                    );
                  }}
                >
                  <img src={IBMInfomation} alt="IBMInfomation" />
                  <CustomizedTypography variant="body2">
                    {t('my_drive.dropdown_menu.detail')}
                  </CustomizedTypography>
                </ListItem>
              )}
              {(isOpen || isDetail) && isMobile && (
                <div className={classes.lineBottom} />
              )}
              {isShare && type !== EnumDashboardListControlType.ListHeader && (
                <ListItem className={classes.listItem} onClick={onClickShare}>
                  <IconButton style={{ padding: 0, marginRight: '8px' }}>
                    <SvgIcon>
                      <IBMShare />
                    </SvgIcon>
                  </IconButton>
                  <CustomizedTypography variant="body2">
                    {t('my_drive.dropdown_menu.share')}
                  </CustomizedTypography>
                </ListItem>
              )}
              {isShowPublic && (
                <ListItem className={classes.listItem}>
                  <PublicOutlined />
                  <CustomizedTypography variant="body2">
                    {t('my_drive.dropdown_menu.make_public')}
                  </CustomizedTypography>
                </ListItem>
              )}
              {files && files?.length > 1 ? null : (
                <ListItem
                  className={classes.listItem}
                  onClick={onOpenFolderDialog}
                >
                  <IconButton style={{ padding: 0, marginRight: '8px' }}>
                    <SvgIcon>
                      <IBMEdit style={{ marginRight: '8px' }} />
                    </SvgIcon>
                  </IconButton>
                  <CustomizedTypography variant="body2">
                    {t('my_drive.dropdown_menu.rename')}
                  </CustomizedTypography>
                </ListItem>
              )}
              {isShowDownLoad && (
                <ListItem
                  className={classes.listItem}
                  button
                  onClick={() => {
                    if (type === EnumDashboardListControlType.ListHeader) {
                      onInsertUserActionLogOne(
                        Enum_Action_Button_Enum.Button_MyDrive_Checked_MoreMenu_Download,
                        CapaActivityType.ButtonClick
                      );
                    } else {
                      onInsertUserActionLogOne(
                        Enum_Action_Button_Enum.Button_MyDrive_MoreMenu_Download,
                        CapaActivityType.ButtonClick
                      );
                    }
                    onClickHandle(EnumDashboardDialogType.Download);
                  }}
                >
                  <Tooltip title={t('tooltip.download') || 'Download'}>
                    <img src={IBMDownload} alt="IBMDownload" />
                  </Tooltip>
                  <CustomizedTypography variant="body2">
                    {t('my_drive.dropdown_menu.download')}
                  </CustomizedTypography>
                </ListItem>
              )}
              <div className={classes.lineBottom} />
              <ListItem
                className={classes.listItem}
                button
                onClick={() => {
                  if (type === EnumDashboardListControlType.ListHeader) {
                    onInsertUserActionLogOne(
                      Enum_Action_Button_Enum.Button_MyDrive_Checked_MoreMenu_Delete,
                      CapaActivityType.ButtonClick
                    );
                  } else {
                    onInsertUserActionLogOne(
                      Enum_Action_Button_Enum.Button_MyDrive_MoreMenu_Delete,
                      CapaActivityType.ButtonClick
                    );
                  }
                  onClickHandle(EnumDashboardDialogType.Delete);
                }}
              >
                <img src={IBMDelete} alt="IBMDelete" />
                <CustomizedTypography variant="body2">
                  {t('my_drive.dropdown_menu.delete')}
                </CustomizedTypography>
              </ListItem>
            </List>
          </Box>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default MoreMenuPopupPresenter;
