export const IMAGE_TYPES = ['.bmp', '.gif', '.jpg', '.jpeg', '.png'];

export const VIDEO_TYPES = ['.mp4', '.mov', '.avi'];

export const DOCUMENT_TYPES = [
  '.pdf',
  '.doc',
  '.docx',
  '.ppt',
  '.pptx',
  '.hwp',
  '.xls',
  '.xlsx',
  '.txt',
  '.zip'
];

export const DRAWING_FILE_TYPES = [
  '.obj',
  '.stl',
  '.sat',
  '.sab',
  '.dwg',
  '.dxf',
  '.ipt',
  '.model',
  '.session',
  '.dlv',
  '.exp',
  '.catpart',
  '.cgr',
  '.dae',
  '.neu',
  '.prt',
  '.xpr',
  '.fbx',
  '.gltf',
  '.glb',
  '.ifc',
  '.igs',
  '.iges',
  '.jt',
  '.x_b',
  '.x_t',
  '.rvt',
  '.3dm',
  '.par',
  '.psm',
  '.sldprt',
  '.stp',
  '.step'
];

export const acceptedExtensions = [
  ...IMAGE_TYPES,
  ...VIDEO_TYPES,
  ...DOCUMENT_TYPES,
  ...DRAWING_FILE_TYPES
];

export const SUPORT_DOCUMENT_TYPES = [
  '.doc',
  '.docx',
  '.ppt',
  '.pptx',
  '.xls',
  '.xlsx'
];
export const NO_SUPORT_DOCUMENT_TYPES = ['.hwp', '.txt', '.zip'];

export const PDF_TYPE = 'pdf';
export const PDF_EXTENSION = '.pdf';
export const FOLDER = 'folder';

export const DOCUMENT_TYPES_FILE = [
  '.doc',
  '.docx',
  '.ppt',
  '.pptx',
  '.hwp',
  '.xls',
  '.xlsx',
  '.txt',
  '.zip'
];
