import { NODE_ENV } from './../routes/Common/types';
import { useCallback } from 'react';
import { debounce } from 'lodash';
import publicIp from 'public-ip';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import {
  osName,
  osVersion,
  deviceType,
  browserName,
  mobileVendor,
  browserVersion
} from 'react-device-detect';
import * as Amplitude from '@amplitude/node';
import AppConfig from '@config/index';
import {
  useWriteUserActivityLogMutation,
  CapaServiceType
} from '@generated/Common/graphql';
import { CapaActivityType } from '@generated/Common/types';
import { STORAGE_ACTION, getStorageItem } from '@utils/storage';

enum Enum_Action_Page_Enum {
  Page_Landing = 'Page_Landing',
  Page_Login = 'Page_Login',
  Page_Landing_5sec = 'Page_Landing_5sec',
  Page_GroupRoom = 'Page_GroupRoom',
  Page_GroupList = 'Page_GroupList',
  Page_Mydrive = 'Page_Mydrive',
  Page_SharedDrive = 'Page_SharedDrive',
  Page_PasswordRequest = '페이지_비밀번호재설정',
  Page_SignUp = 'Page_Signup',
  Page_RegisterConnect = 'Page_RegisterConnect',
  Page_DownloadPermission = 'Page_DownloadPermission',
  Page_Mydrive_NewFolderModal = 'Page_Mydrive_NewFolderModal',
  Page_UploadFileModal = 'Page_UploadFileModal',
  Page_FileUploadDrawer = 'Page_FileUploadDrawer',
  Page_CreateGroup_GroupNameModal = 'Page_CreateGroup_GroupNameModal',
  Page_CreateGroup_AddMembersModal = 'Page_CreateGroup_AddMembersModal',
  Page_MembersModal = 'Page_MembersModal',
  Page_ShareModal = 'Page_ShareModal',
  Page_Share_MyGroupsModal = 'Page_Share_MyGroupsModal',
  Page_Share_ConfirmationModal = 'Page_Share_ConfirmationModal',
  Page_MyPage = '페이지_계정정보',
  Page_MyPage_Edit_Email = '페이지_계정정보_이메일변경',
  Page_MyPage_Edit_Password = '페이지_계정정보_비밀번호변경',
  Page_FileShareLink = 'Page_FileShareLink'
}

enum Enum_Action_Input_Enum {
  Input_Login_ID = 'Input_Login_ID',
  Input_Login_PW = 'Input_Login_PW',
  Input_GroupList_Search = 'Input_GroupList_Search',
  Input_Signup_Name = 'Input_Signup_Name',
  Input_Signup_PW = 'Input_Signup_PW',
  Input_SignUp_VerifyingCode = 'Input_SignUp_VerifyingCode',
  Input_Signup_Email = 'Input_Signup_Email',
  Input_RegisterConnect_Name = 'Input_RegisterConnect_Name',
  Input_NewFolderModal_Name = 'Input_NewFolderModal_Name',
  Input_GroupNameModal_GroupName = 'Input_GroupNameModal_GroupName',
  Input_AddMembersModal_Email = 'Input_AddMembersModal_Email',
  Input_MembersModal_Email = 'Input_MembersModal_Email'
}

enum Enum_Action_Button_Enum {
  Button_Gnb_Logo = '버튼_GNB_로고이미지',
  Button_Gnb_CheckerBoard = '버튼_GNB_바둑판',
  Button_Gnb_Notification = '버튼_GNB_알림',
  Button_Gnb_CheckerBoard_Capa = '버튼_GNB_바둑판_캐파',
  Button_Gnb_CheckerBoard_Partners = '버튼_GNB_바둑판_캐파파트너스',
  Button_Gnb_CheckerBoard_Cloud = '버튼_GNB_바둑판_캐파클라우드',
  Button_Gnb_Login = '버튼_GNB_로그인',
  Button_Gnb_MyPage = '버튼_GNB_계정정보아이콘',
  Button_Gnb_MyPage_MyPage = '버튼_GNB_계정정보아이콘_계정정보',
  Button_Gnb_MyPage_Logout = '버튼_GNB_계정정보아이콘_로그아웃',
  Button_Gnb_MyDrive = '버튼_GNB_드라이브로이동',

  Button_Landing_UseCloudRightNow1 = '버튼_랜딩_지금바로클라우드사용하기_1',
  Button_Landing_UseCloudRightNow2 = '버튼_랜딩_지금바로클라우드사용하기_2',
  Button_Landing_Blog = '버튼_랜딩_CAPA블로그',
  Button_Landing_TermsOfService = '버튼_랜딩_CAPA이용약관',
  Button_Landing_PrivacyPolicy = '버튼_랜딩_개인정보처리방침',
  Button_Landing_ConsentForMarketing = '버튼_랜딩_개인정보처리방침',
  Button_Landing_Instagram = '버튼_랜딩_인스타그램',
  Button_Landing_Facebook = '버튼_랜딩_페이스북',
  Button_Landing_LinkedIn = '버튼_랜딩_링크드인',

  Button_SignUp_Input_Email = '버튼_회원가입_이메일입력',
  Button_SignUp_SendVerificationCode = '버튼_회원가입_인증번호발송',
  Button_SignUp_Input_VerificationCode = '버튼_회원가입_인증번호입력',
  Button_SignUp_Verification = '버튼_회원가입_인증하기',
  Button_SignUp_Input_Password = '버튼_회원가입_비밀번호입력',
  Button_SignUp_Submit = '버튼_회원가입_회원가입완료',
  Button_SignUp_Login = '버튼_회원가입_바로로그인하기',

  Button_MyPage_Edit_Email = '버튼_계정정보_이메일변경',
  Button_MyPage_Edit_Password = '버튼_계정정보_비밀번호변경',
  Button_MyPage_Edit_Email_Input_NewEmail = '버튼_계정정보_이메일변경_이메일입력',
  Button_MyPage_Edit_Email_SendVerificationCode = '버튼_계정정보_이메일변경_인증번호발송',
  Button_MyPage_Edit_Email_Input_VerificationCode = '버튼_계정정보_이메일변경_인증번호입력',
  Button_MyPage_Edit_Email_Verification = '버튼_계정정보_이메일변경_인증하기',
  Button_MyPage_Edit_Email_Submit = '버튼_계정정보_이메일변경_수정완료',

  Button_MyDrive_Upload = '버튼_내드라이브_도면업로드',
  Button_MyDrive_Download = '버튼_내드라이브_다운로드',
  Button_MyDrive_MoreMenu = '버튼_내드라이브_더보기',
  Button_MyDrive_MoreMenu_Preview = '버튼_내드라이브_더보기_미리보기',
  Button_MyDrive_MoreMenu_Download = '버튼_내드라이브_더보기_다운로드',
  Button_MyDrive_MoreMenu_Delete = '버튼_내드라이브_더보기_삭제',
  Button_MyDrive_Checked_Preview = '버튼_내드라이브_체크_미리보기',
  Button_MyDrive_Checked_Download = '버튼_내드라이브_체크_다운로드',
  Button_MyDrive_Checked_Delete = '버튼_내드라이브_체크_삭제',
  Button_MyDrive_Checked_MoreMenu_Preview = '버튼_내드라이브_더보기_미리보기',
  Button_MyDrive_Checked_MoreMenu_Download = '버튼_내드라이브_더보기_다운로드',
  Button_MyDrive_Checked_MoreMenu_Delete = '버튼_내드라이브_더보기_삭제',
  Button_MyDrive_UploadStatus_Retry = '버튼_내드라이브_업로드현황판_재시도',

  /* Login page */
  Button_Login_Submit = 'Button_Login_Submit',
  Button_Login_GotoSignup = 'Button_Login_GotoSignup',
  Button_Login_ResetPW = 'Button_Login_ResetPW',

  /* Landing page */
  Button_Landing_Hero_CTA = 'Button_Landing_Hero_CTA',
  Button_Landing_Android_CTA = 'Button_Landing_Android_CTA',
  Button_Landing_Apple_Last_CTA = 'Button_Landing_Apple_Last_CTA',
  Button_Landing_Login_Signup_CTA = 'Button_Landing_Login/Signup_CTA',

  /* Group room page */
  Button_UploadFileinChat = 'Button_UploadFileinChat',
  Button_FileList_AddFile = 'Button_FileList_AddFile',
  Button_Members = 'Button_Members',
  Button_CommentList = 'Button_CommentList',
  Button_FileList = 'Button_FileList',

  /* Group list page */
  Button_GroupList_CreateGroup = 'Button_GroupList_CreateGroup',
  Click_GroupList_Group = 'Click_GroupList_Group',
  Button_GroupList_Search_Cancel = 'Button_GroupList_Search_Cancel',
  Button_GroupList_Search_Delete = 'Button_GroupList_Search_Delete',
  Button_GroupList_Search = 'Button_GroupList_Search',

  /* My drive page */
  Button_MyDrive_File_Delete = 'Button_MyDrive_File_Delete',
  Button_MyDrive_File_Rename = 'Button_MyDrive_File_Rename',
  Button_MyDrive_File_DetailDrawer_Close = 'Button_MyDrive_File_DetailDrawer_Close',
  Button_MyDrive_File_DetailDrawer_More_DownloadPermission = 'Button_MyDrive_File_DetailDrawer_More_DownloadPermission',
  Button_MyDrive_File_DetailDrawer_More = 'Button_MyDrive_File_DetailDrawer_More',
  Button_MyDrive_File_DetailDrawer_Group = 'Button_MyDrive_File_DetailDrawer_Group',
  Button_MyDrive_File_Share = 'Button_MyDrive_File_Share',
  DoubleClick_MyDrive_File = 'DoubleClick_MyDrive_File',
  Button_MyDrive_File_View = 'Button_MyDrive_File_View',
  Click_MyDrive_Folder = 'Click_MyDrive_Folder',
  Button_MyDrive_LNB_SharedDrive = 'Button_MyDrive_LNB_SharedDrive',
  Click_MyDrive_File = 'Click_MyDrive_File',
  Button_MyDrive_LNB_MyDrive = 'Button_MyDrive_LNB_MyDrive',
  Button_MyDrive_New_File = 'Button_MyDrive_New_File',
  Button_Mydrive_New_Folder = 'Button_Mydrive_New_Folder',
  Button_Mydrive_New = 'Button_Mydrive_New',

  /* Shared drive page */
  Button_SharedDrive_New = 'Button_SharedDrive_New',
  Button_SharedDrive_GroupFolder_File_DropMenu_Download = 'Button_SharedDrive_GroupFolder_File_DropMenu_Download',
  Button_SharedDrive_GroupFolder_File_DropMenu_GoToGroup = 'Button_SharedDrive_GroupFolder_File_DropMenu_GoToGroup',
  RightClick_SharedDrive_GroupFolder_File = 'RightClick_SharedDrive_GroupFolder_File',
  Button_SharedDrive_GroupFolder_File_Download = 'Button_SharedDrive_GroupFolder_File_Download',
  DoubleClick_SharedDrive_GroupFolder_File = 'DoubleClick_SharedDrive_GroupFolder_File',
  Button_SharedDrive_GroupFolder_File_GoToGroup = 'Button_SharedDrive_GroupFolder_File_GoToGroup',
  Click_SharedDrive_GroupFolder_File = 'Click_SharedDrive_GroupFolder_File',
  DoubleClick_SharedDrive_GroupFolder = 'DoubleClick_SharedDrive_GroupFolder',
  Click_SharedDrive_GroupFolder = 'Click_SharedDrive_GroupFolder',

  /* Singup page */
  Button_SignUp_SendAuthEmail = 'Button_SignUp_SendAuthEmail',
  Button_SignUp_Agree_ServiceAndTerms = 'Button_SignUp_Agree_ServiceAndTerms',
  Button_Gotologin = 'Button_Gotologin',
  Button_Signup_Submit = 'Button_Signup_Submit',

  /* Register modal */
  Button_RegisterConnect_Done = 'Button_RegisterConnect_Done',

  /* Download permission modal */
  Toggle_DownloadPermission_MemberAccess = 'Toggle_DownloadPermission_MemberAccess',

  /* New folder modal */
  Button_NewFolderModal_ClickOutsideModal = 'Button_NewFolderModal_ClickOutsideModal',
  Button_NewFolderModal_Cancel = 'Button_NewFolderModal_Cancel',
  Button_NewFolderModal_Create = 'Button_NewFolderModal_Create',

  /* Upload file modal */
  Button_UploadFileModal_Back = 'Button_UploadFileModal_Back',
  Button_UploadFileModal_Cancel = 'Button_UploadFileModal_Cancel',
  Button_UploadFileModal_Add = 'Button_UploadFileModal_Add',
  Button_UploadFileModal_MyDevice = 'Button_UploadFileModal_MyDevice',
  Button_UploadFileModal_MyDrive_ClickFile = 'Button_UploadFileModal_MyDrive_ClickFile',
  Button_UploadFileModal_MyDrive = 'Button_UploadFileModal_MyDrive',

  /* File upload drawer */
  Button_FileUploadDrawer_Close = 'Button_FileUploadDrawer_Close',
  Button_FileUploadDrawer_Shrink = 'Button_FileUploadDrawer_Shrink',
  Button_FileUploadDrawer_TryAgain = 'Button_FileUploadDrawer_TryAgain',

  /* Group name modal */
  Button_CreateGroup_GroupNameModal_Cancel = 'Button_CreateGroup_GroupNameModal_Cancel',
  Button_CreateGroup_GroupNameModal_Next = 'Button_CreateGroup_GroupNameModal_Next',

  /* Add members modal */
  Button_Share_AddMembersModal_Back = 'Button_Share_AddMembersModal_Back',
  Button_Share_AddMembersModal_Cancel = 'Button_Share_AddMembersModal_Cancel',
  Button_Share_AddMembersModal_Create = 'Button_Share_AddMembersModal_Create',
  Button_Share_AddMembersModal_Add = 'Button_Share_AddMembersModal_Add',
  Button_Share_AddMembersModal_GroupAccess = 'Button_Share_AddMembersModal_GroupAccess',
  Click_Share_AddMembersModal_Contact = 'Click_Share_AddMembersModal_Contact',
  Button_CreateGroup_AddMembersModal_Cancel = 'Button_CreateGroup_AddMembersModal_Cancel',
  Button_CreateGroup_AddMembersModal_Back = 'Button_CreateGroup_AddMembersModal_Back',
  Button_CreateGroup_AddMembersModal_Create = 'Button_CreateGroup_AddMembersModal_Create',
  Button_CreateGroup_AddMembersModal_GroupAccess = 'Button_CreateGroup_AddMembersModal_GroupAccess',
  Button_CreateGroup_AddMembersModal_Add = 'Button_CreateGroup_AddMembersModal_Add',
  Click_CreateGroup_AddMembersModal_Contact = 'Click_CreateGroup_AddMembersModal_Contact',

  /* Members modal */
  Button_MembersModal_GroupAccess_Remove = 'Button_MembersModal_GroupAccess_Remove',
  Button_MembersModal_GroupAccess = 'Button_MembersModal_GroupAccess',
  Button_MembersModal_Add = 'Button_MembersModal_Add',
  Click_MembersModal_Contact = 'Click_MembersModal_Contact',

  /* Share modal */
  Button_ShareModal_NewGroup = 'Button_ShareModal_NewGroup',
  Button_ShareModal_Next = 'Button_ShareModal_Next',
  Button_ShareModal_MyGroups = 'Button_ShareModal_MyGroups',

  /* Share link */
  Button_ShareModal_activeLink = 'Button_ShareModal_activeLink',
  Button_ShareModal_deactiveLink = 'Button_ShareModal_deactiveLink',
  Button_ShareModal_AllowDownload = 'Button_ShareModal_AllowDownload',
  Button_ShareModal_OnlyView = 'Button_ShareModal_OnlyView',

  /* Share my groups modal */
  Button_Share_MyGroupsModal_Group = 'Button_Share_MyGroupsModal_Group',

  /* Confirmation modal */
  Button_Share_ConfirmationModal_GotoGroup = 'Button_Share_ConfirmationModal_GotoGroup',
  Button_Share_ConfirmationModal_CopyLink = 'Button_Share_ConfirmationModal_CopyLink',

  /* Viewer file share link page */
  Button_FileShareLink_Download = 'Button_FileShareLink_Download',
  Button_FileShareLink_AddToDrive = 'Button_FileShareLink_AddToDrive',
  Button_FileShareLink_Chat = 'Button_FileShareLink_Chat'
}

export {
  Enum_Action_Page_Enum,
  Enum_Action_Button_Enum,
  CapaActivityType,
  Enum_Action_Input_Enum
};

export default function useUserActionLog() {
  const cloud = Amplitude.init('1e06602637046d8583c5c8cf8ba53873');
  const [writeUserActivityLog] = useWriteUserActivityLogMutation();
  const accessToken = getStorageItem(STORAGE_ACTION.CONNECT_ACCESS_TOKEN);

  // eslint-disable-next-line
  const onInsertUserActionLogOne = useCallback(
    debounce(
      async (
        action_id:
          | Enum_Action_Page_Enum
          | Enum_Action_Button_Enum
          | Enum_Action_Input_Enum,
        action_type: CapaActivityType
      ) => {
        const decodedJwt = accessToken
          ? jwtDecode<JwtPayload>(accessToken)
          : {};
        const userEmail = decodedJwt.aud
          ? decodedJwt.aud.toString()
          : undefined;
        let ip = '';
        try {
          ip = await publicIp.v4();
        } catch (e) {
          if (AppConfig.env === NODE_ENV.DEVELOPMENT) console.log(e);
        }

        if (
          !AppConfig.baseUrl?.includes('staging') &&
          !AppConfig.baseUrl?.includes('localhost')
        ) {
          cloud.logEvent({
            event_type: `cloud/${action_type}/${action_id}`,
            user_id: userEmail || ip || undefined,
            ip: ip || undefined,
            event_properties: {
              action_type,
              action_id
            },
            platform:
              navigator.platform === 'Win32' ? 'Windows' : navigator.platform,
            os_name: osName,
            os_version: osVersion,
            device_brand: mobileVendor,
            device_model: deviceType,
            user_properties: {
              browser: `${browserName} ${browserVersion}`
            }
          });
        }

        await writeUserActivityLog({
          variables: {
            input: {
              email: userEmail,
              activitiId: action_id,
              activityType: action_type,
              ip: ip ? ip : undefined,
              os: `${osName} ${osVersion}`,
              deviceType: deviceType,
              browser: `${browserName} ${browserVersion}`,
              service: CapaServiceType.Connect
            }
          }
        });
      },
      500
    ),
    [writeUserActivityLog]
  );

  return {
    onInsertUserActionLogOne
  };
}
