import React from 'react';
import LogoGrayImg from './assets/LogoGrayImg.png';
import { CustomizedTypography } from '@components/Customized';
import { MuiBoxFooter } from './styles';
import { useTranslation } from 'react-i18next';

const LoginFooterPresenter: React.FC = () => {
  const { t } = useTranslation();
  return (
    <MuiBoxFooter className="footer-auth">
      <img src={LogoGrayImg} alt="Logo" />
      <CustomizedTypography variant="caption" color="textSecondary">
        {t('log_in.caption_footer')}
      </CustomizedTypography>
    </MuiBoxFooter>
  );
};

export default LoginFooterPresenter;
