import React from 'react';
import CapaAvatar from '@components/CapaAvatar';
import { Checkbox, useMediaQuery, useTheme } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { useStyles } from './styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
interface Props {
  item?: any;
  isChecked?: boolean;
  isHover?: boolean;
}

const AvatarCheckbox = (props: Props) => {
  const { item, isChecked, isHover } = props;
  const theme = useTheme();
  const classes = useStyles();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));

  if (!item) return <></>;

  return (
    <div className={classes.avatarWrapper}>
      {isChecked || isHover ? (
        <Checkbox
          className={classes.checkboxRoot}
          checked={isChecked}
          color="primary"
          icon={
            !matches ? (
              <CheckBoxOutlineBlankIcon fontSize="default" />
            ) : (
              <RadioButtonUncheckedIcon fontSize="default" />
            )
          }
          checkedIcon={
            !matches ? (
              <CheckBoxIcon fontSize="default" />
            ) : (
              <CheckCircleIcon fontSize="default" />
            )
          }
        />
      ) : (
        <CapaAvatar item={item} />
      )}
    </div>
  );
};

export default AvatarCheckbox;
