import { createReducer } from 'typesafe-actions';
import {
  MyContactsAction,
  MyContactsState,
  initialMyContactsState
} from './types';
import { SET_STATE_CONTACT } from './actions';

export const myContacts = createReducer<MyContactsState, MyContactsAction>(
  initialMyContactsState,
  {
    [SET_STATE_CONTACT]: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    }
  }
);
