import React from 'react';
import { useHistory } from 'react-router-dom';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import AppConfig from '@config/index';
import {
  useSwitchServiceMutation,
  CapaServiceType,
  CapaErrorCode
} from '@generated/Common/graphql';
import { JwtPayloadType } from '@modules/auth';
import {
  STORAGE_ACTION,
  getStorageItem,
  clearStorageAll
} from '@utils/storage';
import useUserActionLog, {
  CapaActivityType,
  Enum_Action_Button_Enum
} from '@hooks/useUserActionLog';
import useAuth from '@hooks/useAuth';
import { UserType } from './types';
import GnbCheckerBoardMenuPresenter from './GnbCheckerBoardMenuPresenter';
import { myDrivePathName } from '@routes/Common/types';

const GnbCheckerBoardMenuContainer: React.FC = () => {
  const history = useHistory();
  const { isLoggedIn } = useAuth();
  const { onInsertUserActionLogOne } = useUserActionLog();
  const [switchService] = useSwitchServiceMutation();
  const insertLog = async (action: Enum_Action_Button_Enum) => {
    await onInsertUserActionLogOne(action, CapaActivityType.ButtonClick);
  };

  const onClickHandle = async (service: UserType) => {
    if (service === CapaServiceType.Quotation) {
      await insertLog(Enum_Action_Button_Enum.Button_Gnb_CheckerBoard_Capa);
    } else if (service === CapaServiceType.QuotationPartner) {
      await insertLog(Enum_Action_Button_Enum.Button_Gnb_CheckerBoard_Partners);
    } else {
      await insertLog(Enum_Action_Button_Enum.Button_Gnb_CheckerBoard_Cloud);
    }

    const accessTokenLocal = getStorageItem(
      STORAGE_ACTION.CONNECT_ACCESS_TOKEN
    );
    const refreshTokenLocal = getStorageItem(
      STORAGE_ACTION.CONNECT_REFRESH_TOKEN
    );
    let openUrl =
      service === CapaServiceType.Quotation
        ? `${AppConfig.clientUrl}/auth`
        : `${AppConfig.partnersUrl}/auth`;
    if (isLoggedIn) {
      if (service === CapaServiceType.Cloud) {
        history.push(myDrivePathName);
      } else {
        if (accessTokenLocal && refreshTokenLocal) {
          const decodedJwt = (
            accessTokenLocal ? jwtDecode<JwtPayload>(accessTokenLocal) : {}
          ) as JwtPayloadType;
          const scopes = decodedJwt.scopes ?? [];
          openUrl =
            service === CapaServiceType.Quotation
              ? `${AppConfig.clientUrl}/auth?accessToken=${accessTokenLocal}&refreshToken=${refreshTokenLocal}`
              : `${AppConfig.partnersUrl}/auth?accessToken=${accessTokenLocal}&refreshToken=${refreshTokenLocal}`;
          if (scopes.includes(service)) {
            const resp = await switchService({
              variables: { service: service }
            });
            if (
              resp.data &&
              resp.data.switchService &&
              resp.data.switchService.result === CapaErrorCode.Success &&
              resp.data.switchService.data
            ) {
              const { accessToken, refreshToken } =
                resp.data.switchService.data;
              openUrl =
                service === CapaServiceType.Quotation
                  ? `${AppConfig.clientUrl}/auth?accessToken=${accessToken}&refreshToken=${refreshToken}`
                  : `${AppConfig.partnersUrl}/auth?accessToken=${accessToken}&refreshToken=${refreshToken}`;
            }
            clearStorageAll();
            window.open(openUrl, '_self');
          } else {
            clearStorageAll();
            window.open(openUrl, '_self');
          }
        } else {
          clearStorageAll();
          window.open(openUrl, '_self');
        }
      }
    } else {
      if (service === CapaServiceType.Cloud) {
        history.push('/');
      } else {
        clearStorageAll();
        window.open(openUrl, '_self');
      }
    }
  };

  return <GnbCheckerBoardMenuPresenter onClick={onClickHandle} />;
};

export default GnbCheckerBoardMenuContainer;
