import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  FileIconWrapper: {
    '& img, svg': {
      width: 24,
      height: 24
    }
  }
}));
