// import { GetNotificationsQuery } from '../../generated/graphql';

export interface NotificationProps {
  divRef: React.MutableRefObject<any>;
  open: boolean;
  // data?: GetNotificationsQuery | undefined;
  // refetch: () => Promise<any>;
  // onChangeViewed: (id: string) => void;
  onClose: ({ open }: { open: boolean }) => void;
  isFullPage?: boolean;
  onShowDownloadApp?: () => void;
}

export const COMMENT_TYPE = {
  GROUP: 1,
  COMMENT: 2,
  FILE: 3
};

export const EPOCHS = [
  ['year', 31536000],
  ['month', 2592000],
  ['days', 86400],
  ['hour', 3600],
  ['minutes', 60],
  ['second', 1]
];

export const SERVICE = {
  CONNECT: 'CONNECT'
};

export const GROUP_CONNECT_TYPE = {
  CONNECT_INVITE_USER: 'CONNECT_INVITE_USER',
  CONNECT_LEAVE_GROUP: 'CONNECT_LEAVE_GROUP',
  CONNECT_ACCESS_CHANGE: 'CONNECT_ACCESS_CHANGE',
  CONNECT_REMOVE_FROM_GROUP: 'CONNECT_REMOVE_FROM_GROUP',
  CONNECT_DELETE_GROUP: 'CONNECT_DELETE_GROUP',
  CONNECT_DOWNLOAD_ACCESS: 'CONNECT_DOWNLOAD_ACCESS',
  CONNECT_DOWNLOAD_GRANTED: 'CONNECT_DOWNLOAD_GRANTED',
  CONNECT_FILE_SHARED: 'CONNECT_FILE_SHARED',
  CONNECT_ANNOTATION_COMMENT: 'CONNECT_ANNOTATION_COMMENT',
  CONNECT_NEW_CHAT: 'CONNECT_NEW_CHAT',
  CONNECT_CHANGE_GROUP_NAME: 'CONNECT_CHANGE_GROUP_NAME'
};

export const DEFAULT_ITEMS_LIMIT = 40;
export const DEFAULT_ITEMS_OFFSET = 0;
export const DEFAULT_LAST_SEQ = 0;
export const DO_NOT_SHOW_NOTIFICATION = 'no';
