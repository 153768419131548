import { makeStyles } from '@material-ui/core';
import { colorSet } from '@components/Provider';
import { groupListTheme } from '@routes/Common/LayoutDashboard/useStyles';

export const useStyles = makeStyles((theme) => ({
  root: {
    [groupListTheme.breakpoints.down('md')]: {
      justifyContent: 'inherit'
    },
    display: 'flex',
    justifyContent: 'center',
    overflow: 'auto',
    height: 'calc(100vh - 120px)',
    '&::-webkit-scrollbar': {
      width: 4
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: colorSet.gray600,
      borderRadius: 8
    },
    [theme.breakpoints.down('xs')]: {
      height: 'calc(100vh - 120px - 110px)'
    }
  },
  wrapper: {
    minWidth: 584,
    width: '100%',
    maxWidth: 584,
    [theme.breakpoints.down('xs')]: {
      minWidth: 'auto',
      maxWidth: 'inherit'
    }
  },
  boxRoot: {
    minWidth: 343,
    borderRadius: '16px 16px 0 0',
    backgroundColor: colorSet.gray000,
    padding: '8px 0px',
    boxShadow:
      ' 0px 5px 5px -3px rgba(0, 0, 0, 0.1), 0px 8px 10px 1px rgba(0, 0, 0, 0.07), 0px 3px 14px 2px rgba(0, 0, 0, 0.06)'
  },
  list: {
    padding: 0
  },
  headerPopper: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 16px',
    height: 48,
    borderBottom: `1px solid ${colorSet.gray200}`,
    fontWeight: 500,
    fontSize: 16
  },
  backIconWrapper: {
    backgroundColor: colorSet.primary450,
    borderRadius: 12,
    marginRight: 12,
    width: 36,
    height: 36,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: colorSet.primary600
    },
    '& svg path': {
      fill: `${colorSet.gray000} !important`
    }
  },
  backIconMobile: {
    position: 'absolute',
    left: 16
  },
  createNewGroupTitle: {
    fontWeight: 700,
    fontSize: 20,
    lineHeight: '140%',
    letterSpacing: '-0.1px',
    [theme.breakpoints.down('xs')]: {
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '150%',
      letterSpacing: '-0.1px'
    }
  },
  listItemPopper: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 16px',
    cursor: 'pointer',
    height: 48,
    fontWeight: 500,
    fontSize: 14,
    '& svg': {
      marginRight: 16,
      color: colorSet.gray800
    },
    '&:hover': {
      backgroundColor: colorSet.gray100
    }
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    margin: 'auto',
    width: '100%',
    maxWidth: 584,
    minWidth: 584,
    height: 56,
    borderBottom: `1px solid ${colorSet.gray300}`,
    [theme.breakpoints.down('xs')]: {
      minWidth: 'auto',
      maxWidth: 'auto',
      margin: 'inherit'
    },
    [groupListTheme.breakpoints.down('md')]: {
      margin: 'inherit'
    }
  },
  headerLeft: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: 44,
    '& svg': {
      cursor: 'pointer',
      marginRight: 14,
      [theme.breakpoints.down('xs')]: {
        marginRight: 18
      },
      '& path': {
        fill: `${colorSet.gray900} !important`
      }
    }
  },
  closeSearch: {
    display: 'block',
    marginLeft: 25,
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      marginLeft: 10,
      marginRight: 18
    }
  },
  searchItem: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',

    '& .ibm-close-input': {
      '& path': {
        fill: `${colorSet.gray700} !important`
      }
    },

    '& .search': {
      display: 'inline',
      borderRadius: 8,
      padding: '0px 40px',
      position: 'relative',
      height: 44,
      backgroundColor: colorSet.gray100,
      width: '100%',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        height: 40,
        marginLeft: 16
      },

      '& input': {
        fontSize: 16,
        border: 0,
        width: '90%',
        backgroundColor: 'transparent',
        height: '100%',

        '&:focus': {
          outline: 'none'
        },
        '&::placeholder': {
          color: colorSet.gray550,
          fontSize: 16
        }
      },
      '& button, i': {
        border: 'none',
        background: 'none',
        cursor: 'pointer'
      },
      '& .iconSearch': {
        position: 'absolute',
        left: 15,
        top: 14,
        fontSize: 20,
        [theme.breakpoints.down('xs')]: {
          top: 12
        },
        '& path': {
          fill: `${colorSet.gray550} !important`
        }
      },
      '& .activeIcon': {
        color: colorSet.gray900
      },
      '& select': {
        border: 'none'
      },
      '& .iconClear': {
        position: 'absolute',
        right: 15,
        top: 12,
        [theme.breakpoints.down('xs')]: {
          top: 11
        },
        fontSize: 22,
        color: colorSet.gray500,
        cursor: 'pointer'
      },
      '& .hideIcon': {
        display: 'none'
      }
    }
  },
  icon: {
    [theme.breakpoints.down('xs')]: {
      userSelect: 'none'
    },
    '& svg': {
      cursor: 'pointer',
      fontSize: 28,
      [theme.breakpoints.down('xs')]: {
        userSelect: 'none'
      }
    },
    '& .add': {
      marginRight: 20,
      [theme.breakpoints.down('xs')]: {
        userSelect: 'none',
        position: 'fixed',
        width: 54,
        height: 54,
        bottom: 73,
        right: 5,
        padding: 10,
        color: colorSet.gray000,
        borderRadius: '100%',
        boxShadow: 'none',
        background: colorSet.primary500
      }
    }
  },
  title: {
    flex: '1 0 auto',
    fontSize: 20,
    color: colorSet.gray900,
    fontWeight: 700,
    lineHeight: '160%',
    letterSpacing: '-0.1px',
    cursor: 'pointer',
    marginLeft: 24,
    '& svg': {
      fontSize: 34,
      marginBottom: '-5px'
    }
  },
  selected: {
    cursor: 'pointer',
    alignItems: 'flex-end',
    width: '30%',
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      width: '50%',
      fontSize: 20,
      userSelect: 'none'
    }
  },
  listItem: {
    marginBottom: 50,
    [theme.breakpoints.down('xs')]: {
      marginTop: 4
    }
  },
  item: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 12,
    height: 96,
    padding: '14.5px 8px 14.5px 16px',
    justifyContent: 'space-between',

    [theme.breakpoints.down('xs')]: {
      paddingLeft: 15,
      paddingRight: 15,
      justifyContent: 'inherit'
    }
  },
  defaultImage: {
    width: 68,
    height: 68,
    background: colorSet.gray100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '100%',

    [theme.breakpoints.down('xs')]: {
      width: 48,
      height: 48
    },

    '& img': {
      width: 24,
      height: 24,
      [theme.breakpoints.down('xs')]: {
        userSelect: 'none'
      }
    }
  },
  imageItem: {
    width: 68,
    height: 68,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '100%',

    [theme.breakpoints.down('xs')]: {
      width: 48,
      height: 48
    },

    '& img': {
      width: '100%',
      height: 'auto',
      [theme.breakpoints.down('xs')]: {
        userSelect: 'none'
      }
    }
  },
  image: {
    marginRight: 20,
    width: 72,
    height: 72,
    [theme.breakpoints.down('xs')]: {
      userSelect: 'none',
      width: 62,
      height: 62
    }
  },
  groupChatBotImage: {
    width: 72,
    height: 72,
    marginRight: 20,
    [theme.breakpoints.down('xs')]: {
      width: 62,
      height: 62,
      userSelect: 'none'
    }
  },
  text: {
    display: 'grid',
    alignItems: 'start',
    width: '100%',

    [theme.breakpoints.down('xs')]: {
      userSelect: 'none',
      width: 'calc(100% - 140px)'
    },

    '& .MuiTypography-colorTextPrimary': {
      position: 'relative',
      maxWidth: 'max-content',
      paddingRight: 6,
      fontSize: 16
    },

    '& .group-name-text': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontWeight: 500,
      fontSize: 18,
      lineHeight: '140%',
      letterSpacing: '-0.1px',
      width: 416,
      color: colorSet.gray900,
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        minWidth: '100%'
      }
    },

    '& .member-wrapper': {
      marginTop: 6,
      display: 'flex',
      alignItems: 'center',
      fontWeight: 400,
      fontSize: 13,
      lineHeight: '138%',
      letterSpacing: '-0.1px',
      color: colorSet.gray900,
      maxWidth: 416,
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        minWidth: '100%'
      },
      '& .users': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      },
      '& .etc': {
        marginLeft: 4,
        flexShrink: 0
      },
      '& .dot': {
        margin: '0 2px'
      },
      '& .members-count': {
        flexShrink: 0
      }
    },

    '& .total-members': {
      color: colorSet.gray600,
      fontSize: 16
    },

    '& .text-title': {
      color: colorSet.gray900,
      fontSize: 16,
      fontWeight: 500,

      [theme.breakpoints.down('xs')]: {
        userSelect: 'none'
      },
      '& div': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'block',
        lineHeight: 1.4,
        [theme.breakpoints.down('xs')]: {
          width: '80%',
          userSelect: 'none'
        }
      },
      '& span': {
        [theme.breakpoints.down('xs')]: {
          userSelect: 'none'
        },
        color: colorSet.gray700
      }
    },
    '& .text-subTitle': {
      display: 'flex',
      color: colorSet.gray800,
      alignItems: 'center',
      overflow: 'hidden',
      fontSize: 13,
      fontWeight: 400,
      lineHeight: '138%',
      letterSpacing: '-0.1px',
      textOverflow: 'ellipsis',
      width: 416,
      '& .primary-color': {
        color: colorSet.primary450
      },
      '& .chat-bot-group-max-width': {
        maxWidth: '100%'
      },
      '& .date-time': {
        color: colorSet.gray700,
        display: 'flex',
        flexShrink: 0,
        [theme.breakpoints.down('xs')]: {
          userSelect: 'none'
        },
        '& .dot': {
          margin: '0 2px'
        }
      },
      '& div': {
        [theme.breakpoints.down('xs')]: {
          userSelect: 'none'
        },
        flex: '0 0 auto'
      },
      [theme.breakpoints.down('xs')]: {
        userSelect: 'none',
        width: '100%'
      },
      '& .sender-name': {
        maxWidth: 135,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
      },
      '& span': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'block',
        lineHeight: 1.2,
        [theme.breakpoints.down('xs')]: {
          userSelect: 'none',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: 'block'
        }
      }
    }
  },

  right: {
    minWidth: 56,
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {},
    '& .total': {
      fontSize: 12,
      borderRadius: 12,
      fontWeight: 400,
      background: colorSet.pink300,
      color: colorSet.gray000,
      minWidth: 20,
      minHeight: 20,
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      marginRight: 10,
      [theme.breakpoints.down('xs')]: {
        maxWidth: 'auto',
        userSelect: 'none'
      }
    },
    '& .more-vert': {
      borderRadius: 50,
      width: 40,
      height: 40,
      border: `1px solid ${colorSet.gray200}`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '&:hover': {
        boxShadow: 'inset 999px 99px 0px rgba(0, 0, 0, 0.04)'
      }
    },
    '& .more-vert-checked': {
      boxShadow: 'inset 999px 99px 0px rgba(0, 0, 0, 0.04)'
    }
  },
  hoverItem: {
    '&:hover': {
      '& .group-name-text': {
        textDecoration: 'underline'
      }
    }
  },
  checkedItem: {
    '& .group-name-text': {
      textDecoration: 'underline'
    }
  },
  cursorPointer: {
    cursor: 'pointer'
  },
  unReadCountItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 12,
    padding: '14.5px 8px 14.5px 16px',
    justifyContent: 'space-between',
    height: 96,
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'inherit'
    },
    '& .text-title': {
      fontWeight: 'normal'
    },
    '& .text-subTitle': {
      color: colorSet.gray700,
      fontWeight: 'normal'
    }
  },
  customPopover: {
    '& .MuiPopover-paper': {
      boxShadow:
        '0px 8px 16px 2px rgba(97, 97, 97, 0.1), 0px 16px 32px 2px rgba(97, 97, 97, 0.1)',
      width: 256,
      marginTop: 4,
      borderRadius: 8
    },
    [theme.breakpoints.down('xs')]: {
      background: `${colorSet.backgroundDrop}`,
      '& .MuiPopover-paper': {
        boxShadow: 'none',
        background: 'transparent',
        minHeight: 'auto'
      }
    },
    '& ul': {
      padding: '8px 0',
      '& .divider': {
        borderBottom: `1px solid ${colorSet.gray300}`,
        margin: '8px 0'
      },
      '& li': {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        padding: '8px 0 8px 16px',
        '&:hover': {
          backgroundColor: colorSet.gray100
        },
        '& .text': {
          fontWeight: 500,
          fontSize: 14,
          lineHeight: '143%',
          letterSpacing: '-0.1px',
          marginLeft: 12
        }
      }
    }
  },
  noSearch: {
    marginTop: 10,
    textAlign: 'center',
    fontSize: 16,
    color: colorSet.gray900,
    [theme.breakpoints.down('xs')]: {
      fontSize: 13,
      fontWeight: 400,
      lineHeight: '138%',
      letterSpacing: '-0.1px',
      marginTop: 51
    }
  },
  noneDisplay: {
    display: 'none'
  },
  noData: {
    margin: 'auto',
    marginTop: 275,
    textAlign: 'center',
    width: 212,
    '& .title': {
      fontSize: 16,
      color: colorSet.gray900,
      fontWeight: 500
    },
    '& .subTitle': {
      marginTop: 8,
      fontSize: 16,
      color: colorSet.gray600,
      marginBottom: 32,
      lineHeight: '150%'
    }
  },
  button: {
    textTransform: 'inherit'
  },
  backDrop: {
    zIndex: 1298,
    [theme.breakpoints.down('xs')]: {
      background: colorSet.gray2000,
      opacity: '0.5 !important'
    }
  },
  rootPopupMobile: {
    zIndex: 1300,
    [theme.breakpoints.down('xs')]: {
      width: '100% !important',
      bottom: '-4px !important',
      left: '0 !important',
      top: 'unset !important',
      transform: 'none !important',
      position: 'fixed !important'
    }
  }
}));
