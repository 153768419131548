import React from 'react';
import PropTypes from 'prop-types';
import { DROP_EFFECT } from './types';
import { dropInsideStyle } from './styles';

const DropTarget = (props: any) => {
  const [isOver, setIsOver] = React.useState(false);

  const dragOver = (ev: any) => {
    ev.preventDefault();
    ev.dataTransfer.dropEffect = props.dropEffect;
    setIsOver(true);
  };

  const drop = (ev: any) => {
    let internalFolderId = '';

    const targetEl = ev?.target;
    if (targetEl?.nodeName === 'path') {
      internalFolderId = targetEl?.parentElement?.parentElement?.id;
    } else if (targetEl?.nodeName === 'P') {
      internalFolderId = targetEl?.parentElement?.previousElementSibling?.id;
    } else if (targetEl?.nodeName === 'svg' || targetEl?.nodeName === 'SPAN') {
      internalFolderId = targetEl?.parentElement?.id;
    } else if (targetEl?.className === 'table-item-td-three') {
      internalFolderId = targetEl?.previousElementSibling?.id;
    } else if (targetEl?.className === 'table-item-td-four') {
      internalFolderId =
        targetEl?.previousElementSibling?.previousElementSibling?.id;
    } else {
      internalFolderId = targetEl?.id;
    }

    props.onItemDropped(internalFolderId);
    setIsOver(false);
  };

  const dragLeave = () => setIsOver(false);

  return (
    <div
      onDragOver={dragOver}
      onDrop={drop}
      onDragLeave={dragLeave}
      style={{ ...(isOver ? dropInsideStyle : {}) }}
    >
      {props.children}
    </div>
  );
};

DropTarget.propTypes = {
  onItemDropped: PropTypes.func,
  dropEffect: PropTypes.string,
  dataItem: PropTypes.array,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

DropTarget.defaultProps = {
  dropEffect: DROP_EFFECT.ALL
};

export default DropTarget;
