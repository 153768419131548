import React from 'react';
import { useStylesAttachments } from './styles';
import { IBMAttachment, IBMCAD, IBMClose } from '@assets';
import LinkSettings from '@routes/Dashboard/Dialog/Share/ShareLink/LinkSettings';
import { useTranslation } from 'react-i18next';
import { convertSubstring, getFileExtension } from '@utils/file';
import { DRAWING_FILE_TYPES } from '@routes/Common/Dropzone/types';

interface Props {
  checkedFiles?: any;
  setPermission?: (permission: any) => void;
  isShowUploadFiles?: boolean;
  setInternalFiles?: (files: any) => void;
}

const Attachments = (props: Props) => {
  const { t } = useTranslation();
  const { checkedFiles, setPermission, isShowUploadFiles, setInternalFiles } =
    props;
  const classes = useStylesAttachments();

  const onClickDeleteFile = (index: number) => {
    if (index > -1) {
      setInternalFiles &&
        setInternalFiles(
          checkedFiles.filter((file: any, i: number) => i !== index)
        );
    }
  };

  return checkedFiles?.length > 0 ? (
    <>
      <LinkSettings
        isNewGroup={true}
        isShowUploadFiles={isShowUploadFiles}
        setPermission={setPermission}
      />
      <div className={classes.attachments}>
        {checkedFiles?.length > 0
          ? checkedFiles.map((file: any, index: number) => {
              return (
                <div className={classes.attachment} key={index}>
                  <img
                    src={IBMAttachment}
                    className={classes.attachmentIcon}
                    alt=""
                  />
                  {convertSubstring(DRAWING_FILE_TYPES).includes(
                    file?.originalFileExt?.toLocaleLowerCase()
                  ) ||
                  DRAWING_FILE_TYPES.includes(
                    getFileExtension(file?.fileName)
                  ) ? (
                    <img src={IBMCAD} alt="" className="ibm-cad-icon" />
                  ) : null}
                  <div className="file-name">{file?.fileName}</div>
                  <img
                    onClick={() => onClickDeleteFile(index)}
                    src={IBMClose}
                    className={classes.closeIcon}
                    alt=""
                  />
                </div>
              );
            })
          : null}
      </div>
    </>
  ) : (
    <div className={classes.noAttachmentsText}>
      {t('create_new_group_modal.no_attachments')}
    </div>
  );
};

export default Attachments;
