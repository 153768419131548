import React, { useEffect } from 'react';
import { useStyles } from './styles';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import {
  valueTabsMenu,
  myDrivePathName,
  groupPathName,
  myContactsPathName,
  URL_PATH_NAME,
  CONNECT_UPDATE_TYPE,
  DEFAULT_ITEMS_LIMIT,
  DEFAULT_ITEMS_OFFSET
} from '@routes/Common/types';
import { useSubscription } from '@apollo/client';
import { SubscribeConnectUpdateData } from '@generated/Drive/MyDrive/graphql';
import { useMediaQuery, useTheme } from '@material-ui/core';
import useCommon from '@hooks/Common/useCommon';
import useGroup from '@hooks/Group/useGroup';

const moreThanText = '999+';

const TabsMenu = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { connectGroupChatRooms, onFetchConnectGroupChatRooms } = useGroup();
  const connectGroupChatRoomsData =
    connectGroupChatRooms?.fetchConnectGroupChatRooms?.data || [];
  const { common, onSetUnReadCountGroupList } = useCommon();

  const fetchConnectGroupChatRooms = () => {
    onFetchConnectGroupChatRooms({
      variables: {
        limit: DEFAULT_ITEMS_LIMIT,
        offset: DEFAULT_ITEMS_OFFSET
      }
    });
  };

  useSubscription(SubscribeConnectUpdateData, {
    onSubscriptionData: (subscriptionData) => {
      switch (
        subscriptionData?.subscriptionData?.data?.subscribeConnectUpdateData
          ?.data?.type
      ) {
        case CONNECT_UPDATE_TYPE.CHAT_UPDATE:
        case CONNECT_UPDATE_TYPE.MEMBER_UPDATE:
        case CONNECT_UPDATE_TYPE.GROUP_UPDATE: {
          fetchConnectGroupChatRooms();
          break;
        }
      }
    },
    skip: isMobile
  });

  useEffect(() => {
    if (connectGroupChatRoomsData?.length > 0) {
      let total = 0;
      connectGroupChatRoomsData?.forEach((item: any) => {
        total += item.unReadCount;
      });
      onSetUnReadCountGroupList({
        unReadCountGroupList: total
      });
    }
  }, [connectGroupChatRoomsData]);

  useEffect(() => {
    fetchConnectGroupChatRooms();
  }, []);

  const dataMenu = [
    {
      value: valueTabsMenu.drive,
      url: [URL_PATH_NAME.MY_DRIVE, URL_PATH_NAME.SHARED_WITH_GROUP],
      text: `${t('tabs_menu.drive')}`
    },
    {
      value: valueTabsMenu.group,
      url: [URL_PATH_NAME.GROUP],
      text: `${t('tabs_menu.group')}`
    },
    {
      value: valueTabsMenu.contacts,
      url: [URL_PATH_NAME.MY_CONTACTS, URL_PATH_NAME.RECOMMENDED_CONTACTS],
      text: `${t('tabs_menu.contacts')}`
    }
  ];
  const urlTabsMenu = (value: number) => {
    if (value === valueTabsMenu.drive) {
      history.push({
        pathname: myDrivePathName,
        state: { prevLocation: window.location.href }
      });
    }
    if (value === valueTabsMenu.group) {
      history.push({
        pathname: groupPathName,
        state: { prevLocation: window.location.href }
      });
    }
    if (value === valueTabsMenu.contacts) {
      history.push({
        pathname: myContactsPathName,
        state: { prevLocation: window.location.href }
      });
    }
  };

  return (
    <>
      <div className={classes.rootTabs}>
        {dataMenu &&
          dataMenu.map((item) => (
            <div
              key={item.value}
              className={clsx(
                item.url.includes(history.location.pathname.split('/')[1])
                  ? classes.active
                  : '',
                'itemTab'
              )}
              onClick={() => urlTabsMenu(item.value)}
            >
              {item.text}
              {common.unReadCountGroupList === 0 ||
              item.value !== valueTabsMenu.group ? null : (
                <div className={classes.unReadCountTotal}>
                  {common.unReadCountGroupList! > 999
                    ? moreThanText
                    : common.unReadCountGroupList}
                </div>
              )}
            </div>
          ))}
      </div>
    </>
  );
};

export default TabsMenu;
