import React from 'react';
import { CapaConnectMyFile } from '@generated/Common/graphql';
import ViewerPresenter from './ViewerPresenter';

interface Props {
  files?: Array<CapaConnectMyFile>;
}

const ViewerContainer: React.FC<Props> = ({ files }) => {
  return <ViewerPresenter files={files} />;
};

export default ViewerContainer;
