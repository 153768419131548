import React from 'react';
import { useTranslation } from 'react-i18next';
import useUserActionLog, {
  CapaActivityType,
  Enum_Action_Button_Enum
} from '@hooks/useUserActionLog';
import { useHistory } from 'react-router-dom';
import { singUpPathName } from '@routes/Common/types';
import { colorSet } from '@components/Provider';
import {
  createMuiTheme,
  createStyles,
  makeStyles,
  Theme
} from '@material-ui/core';
const themeLanding = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1024,
      xl: 1536
    }
  }
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerFriud: {
      maxWidth: '100%',
      overflow: 'hidden',
      background: colorSet.primary600,
      paddingLeft: 32,
      paddingRight: 32,
      [themeLanding.breakpoints.down('md')]: {
        paddingLeft: 16,
        paddingRight: 16
      }
    },
    container: {
      width: '100%',
      maxWidth: '1024px',
      margin: 'auto',
      [themeLanding.breakpoints.down('md')]: {
        maxWidth: '960px'
      }
    },
    getStarted: {
      padding: '100px 0',
      [themeLanding.breakpoints.down('xs')]: {
        padding: '120px 0'
      }
    },
    title: {
      width: '100%',
      fontWeight: 700,
      fontSize: 40,
      lineHeight: '64px',
      textAlign: 'center',
      marginBottom: 40,
      whiteSpace: 'break-spaces',
      letterSpacing: '-0.1px',
      color: colorSet.gray000,
      [themeLanding.breakpoints.down('xs')]: {
        fontSize: 28,
        lineHeight: '45px'
      }
    },
    button: {
      fontWeight: 500,
      fontSize: 20,
      width: 198,
      lineHeight: '32px',
      color: colorSet.primary600,
      margin: 'auto',
      padding: '16px 24px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'white',
      border: 'none',
      borderRadius: '8px',
      cursor: 'pointer',
      transition: '300ms all',
      '&:hover': {
        backgroundColor: colorSet.primary200
      },
      [themeLanding.breakpoints.down('xs')]: {
        padding: '12px 24px',
        fontSize: 16,
        width: 144,
        lineHeight: '24px'
      }
    }
  })
);
const GetStarted = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { onInsertUserActionLogOne } = useUserActionLog();
  const history = useHistory();

  const handleGoToSignUp = () => {
    onInsertUserActionLogOne(
      Enum_Action_Button_Enum.Button_Landing_Hero_CTA,
      CapaActivityType.ButtonClick
    );
    history.push(singUpPathName);
  };

  return (
    <div className={classes.containerFriud}>
      <div className={classes.container}>
        <div className={classes.getStarted}>
          <div className={classes.title}>
            {t('landing_page.let_us_section.head_title')}
          </div>
          <button className={classes.button} onClick={handleGoToSignUp}>
            {t('landing_page.shortcut')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default GetStarted;
