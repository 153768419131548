import { createReducer } from 'typesafe-actions';
import {
  RecommendedContactsAction,
  RecommendedContactsState,
  initialRecommendedContactsState
} from './types';
import { SET_RECOMMENTDED_CONTACTS_PAGE_LOADING } from './actions';

export const RecommendedContacts = createReducer<
  RecommendedContactsState,
  RecommendedContactsAction
>(initialRecommendedContactsState, {
  [SET_RECOMMENTDED_CONTACTS_PAGE_LOADING]: (state, action) => {
    return {
      ...state,
      ...action.payload
    };
  }
});
