import { colorSet } from '@components/Provider';
import { makeStyles, createStyles } from '@material-ui/core';
interface StylesViewerProps {
  multifile?: boolean;
}
export const useStylesViewer = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      background: colorSet.gray000
    },
    header: {
      display: 'grid',
      gridTemplateColumns: '300px 1fr 300px',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: 52,
      gap: 20,
      backgroundColor: colorSet.gray2000,
      padding: '0px 10px 0 18px',
      '& .MuiIconButton-root': {
        padding: '6px',
        color: `${colorSet.gray000}`,
        cursor: 'pointer',
        marginRight: 4,
        '&:hover': {
          backgroundColor: 'rgb(159 159 159 / 23%)'
        },
        '&:last-child': {
          marginRight: 0
        }
      },
      '& svg path': {
        fill: colorSet.gray000
      },
      [theme.breakpoints.down('xs')]: {
        gridTemplateColumns: '1fr 75px'
      }
    },
    logo: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      '& svg': {
        height: 16,
        width: 'auto'
      },
      '& img': {
        height: 26,
        marginLeft: 8
      },
      '&.md-hidden': {
        [theme.breakpoints.down('xs')]: {
          display: 'none'
        }
      }
    },
    fileName: {
      display: 'flex',
      maxWidth: '100%',
      alignItems: 'center',
      fontSize: 16,
      overflow: 'hidden',
      lineHeight: '24px',
      color: colorSet.gray000,
      '& span': {
        margin: 'auto',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        [theme.breakpoints.down('xs')]: {
          margin: '0'
        }
      }
    },
    menuWrapper: {
      color: colorSet.gray900,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end'
    },
    btnChat: {
      backgroundColor: colorSet.primary500,
      color: colorSet.gray000,
      borderRadius: 8,
      fontSize: 14,
      lineHeight: '20px',
      textTransform: 'none',
      padding: '6px 14px !important',
      marginRight: '6px !important',
      fontWeight: 500,
      display: 'block',
      '& span': {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
      },
      '&:hover': {
        backgroundColor: `${colorSet.primary450} !important`
      },

      [theme.breakpoints.down('xs')]: {
        position: 'absolute',
        maxWidth: 'calc( 100% - 100px)',
        top: 64,
        padding: '12px 16px !important',
        marginRight: '0 !important',
        left: '50%',
        zIndex: 10,
        transform: 'translate(-50%, 0px)'
      }
    },
    feature: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      marginLeft: '10px !important'
    },
    main: {
      position: 'relative',
      height: 'calc(100vh - 53px)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    welcome: {
      padding: '20px 24px',
      position: 'absolute',
      zIndex: 1200,
      margin: '0 10px',
      top: 24,
      backgroundColor: colorSet.gray000,
      letterSpacing: '-0.1px',
      boxShadow:
        '0px 5px 5px -3px rgba(0, 0, 0, 0.1), 0px 8px 10px 1px rgba(0, 0, 0, 0.07), 0px 3px 14px 2px rgba(0, 0, 0, 0.06)',
      borderRadius: 12,
      display: 'flex',
      alignItems: 'center',
      '& svg path': {
        fill: colorSet.primary500
      },
      [theme.breakpoints.down('xs')]: {
        width: 'calc(100% - 20px)',
        display: 'grid',
        gridTemplateColumns: '1fr',
        bottom: (props: StylesViewerProps) => (props.multifile ? 104 : 10),
        top: 'auto',
        gap: 10
      }
    },
    welcomeClose: {
      display: 'none',
      top: 10,
      right: 10,
      padding: 1,
      position: 'absolute',
      cursor: 'pointer',
      '& svg': {
        width: 30,
        height: 30
      },
      '& path': {
        fill: `${colorSet.gray900} !important`
      },
      [theme.breakpoints.down('xs')]: {
        display: 'block'
      }
    },
    text: {
      fontSize: 16,
      marginLeft: 4,
      lineHeight: '24px',
      [theme.breakpoints.down('xs')]: {
        marginLeft: 0
      }
    },
    textWelcome: {
      fontSize: 16,
      marginLeft: 16,
      lineHeight: '24px',
      fontWeight: 'bold',
      [theme.breakpoints.down('xs')]: {
        marginLeft: 0
      }
    },
    btnSignUp: {
      color: colorSet.primary500,
      backgroundColor: colorSet.gray000,
      borderRadius: 8,
      fontSize: 14,
      letterSpacing: '-0.1px',
      textTransform: 'none',
      lineHeight: '20px',
      padding: '10px 16px !important',
      fontWeight: 500,
      display: 'flex',
      alignItems: 'center',
      marginLeft: 16,
      justifyContent: 'flex-end',
      border: `1px solid ${colorSet.primary500}`,
      '&:hover': {
        color: `${colorSet.primary450} !important`,
        border: `1px solid ${colorSet.primary450}`
      },
      [theme.breakpoints.down('xs')]: {
        marginLeft: 0,
        display: 'block'
      }
    },

    subTitle: {
      padding: '14px 24px',
      height: 78,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      }
    },
    fileViewer: {
      textAlign: 'center',
      width: '100vw',
      overflow: 'hidden',
      margin: 'auto',
      display: 'flex',
      justifyContent: 'center',
      height: (props: StylesViewerProps) =>
        props.multifile ? `calc(100vh - 53px - 89px)` : `calc(100vh - 53px)`
    },
    viewerPdf: {
      display: 'block',
      position: 'relative',
      width: '1000px',
      maxWidth: 'calc(100vh - 230px)',
      margin: 'auto',
      height: 'calc(100vh - 120px)',
      '& .rpv-default-layout__sidebar': {
        display: 'none'
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        maxWidth: '100%',
        marginTop: 60
      },
      '& .rpv-core__doc-error': {
        display: 'none'
      }
    },
    wrapperClass: {
      overflow: 'inherit'
    },
    backIconWrapper: {
      backgroundColor: colorSet.primary450,
      borderRadius: 12,
      marginRight: 12,
      width: 36,
      height: 36,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: colorSet.primary600
      },
      '& svg path': {
        fill: `${colorSet.gray000} !important`
      }
    },
    backIconMobile: {
      position: 'absolute',
      left: 16
    },
    createNewGroupTitle: {
      fontWeight: 700,
      fontSize: 20,
      lineHeight: '140%',
      letterSpacing: '-0.1px',
      [theme.breakpoints.down('xs')]: {
        fontWeight: 500,
        fontSize: 16,
        lineHeight: '150%',
        letterSpacing: '-0.1px'
      }
    },
    contentClass: {
      display: 'flex',
      textAlign: 'center',
      justifyContent: 'center'
    },
    imageViewer: {
      margin: 'auto',
      '& img': {
        maxWidth: 'calc(100vw - 130px)',
        maxHeight: 'calc(100vh - 130px)'
      }
    },
    noPreview: {
      marginTop: 150,
      background: `${colorSet.gray000}`,
      boxShadow:
        '0px 24px 24px -6px rgba(0, 0, 0, 0.2), 0px 24px 56px 4px rgba(0, 0, 0, 0.14), 0px 14px 56px 7px rgba(0, 0, 0, 0.12)',
      borderRadius: 12,
      width: 494,
      maxWidth: 'calc(100% - 30px)',
      padding: '40px',
      color: `${colorSet.gray900}`,
      margin: 'auto',
      textAlign: 'center',
      '& .title': {
        fontSize: 25
      },
      '& .button': {
        textTransform: 'none',
        marginTop: 32,
        fontSize: 16
      }
    },
    thumbnailList: {
      display: 'flex',
      padding: 13,
      background: `${colorSet.gray000}`,
      height: 89,
      zIndex: 2000,
      position: 'absolute',
      bottom: 0,
      width: '100%',

      borderTop: `1px solid ${colorSet.gray200}`
    },
    thumbnail: {
      width: 64,
      height: 64,
      display: 'flex',
      flexShrink: 0,
      marginRight: 8,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      backgroundSize: 'contain',
      borderRadius: 8,
      border: `1px solid ${colorSet.gray200}`,
      '&.selected': {
        border: `1px solid ${colorSet.gray900}`
      }
    }
  })
);
export const useStylesNotFound = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      background: colorSet.gray000
    },
    header: {
      display: 'grid',
      gridTemplateColumns: '250px 1fr 250px',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: 52,
      backgroundColor: colorSet.gray000,
      padding: '0px 10px 0 18px',
      '& img': {
        height: 16,
        '&.beta': {
          height: 26,
          marginLeft: 8
        }
      },
      '& .MuiIconButton-root': {
        padding: '6px',
        color: `${colorSet.gray000}`,
        cursor: 'pointer',
        marginRight: 4,
        '&:hover': {
          backgroundColor: 'rgb(159 159 159 / 23%)'
        },
        '&:last-child': {
          marginRight: 0
        }
      },
      '& svg path': {
        fill: colorSet.gray000
      },
      [theme.breakpoints.down('xs')]: {
        gridTemplateColumns: '1fr 75px'
      }
    },
    logo: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      '& svg path': {
        fill: colorSet.primary500
      },
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      }
    },
    main: {
      position: 'relative',
      height: 'calc(100vh - 53px)'
    },
    btnGoHome: {
      backgroundColor: colorSet.primary500,
      color: colorSet.gray000,
      borderRadius: 8,
      fontSize: 14,
      lineHeight: '20px',
      textTransform: 'none',
      padding: '10px 19px !important',
      fontWeight: 500,
      margin: '40px auto',
      '&:hover': {
        backgroundColor: `${colorSet.primary450} !important`
      }
    }
  })
);
