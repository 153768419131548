import { combineReducers } from 'redux';
import { StateType } from 'typesafe-actions';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import { auth } from './auth';
import { dashboard } from './dashboard';
import { upload } from './upload';
import { myDrive } from './Drive/MyDrive';
import { sharedWithGroups } from './Drive/SharedWithGroups';
import { myContacts } from './Contacts/MyContacts';
import { RecommendedContacts } from './Contacts/RecommendedContacts';
import { group } from './Group';
import { common } from './common';

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['auth', 'dashboard'],
  blacklist: []
};

const rootReducer = combineReducers({
  auth,
  common,
  upload,
  dashboard,
  myDrive,
  sharedWithGroups,
  myContacts,
  RecommendedContacts,
  group
});

export type RootState = StateType<ReturnType<typeof rootReducer>>;

export default persistReducer(persistConfig, rootReducer);
