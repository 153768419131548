import React, { useState } from 'react';
import { Dialog, useMediaQuery, useTheme } from '@material-ui/core';
import { useStyles } from './styles';
import GroupsYouAreJoined from '@routes/Dashboard/Dialog/Share/GroupsYouAreJoined';
import { ArrowBackIosOutlined } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { CapaExistingGroups } from '@assets';
import {
  CapaErrorCode,
  useInviteGroupMembersNameMutation
} from '@generated/Common/graphql';
import { useSnackbar } from '@components/Provider';
import { ACCESS_LEVEL_VALUE } from '@routes/Dashboard/Dialog/Share/types';
import { ADD_TO_GROUP_SCREEN } from '@routes/Contacts/types';
import GroupSuccess from '@routes/Dashboard/Dialog/Share/GroupSuccess/GroupSuccess';
import useMyDrive from '@hooks/Drive/MyDrive/useMyDrive';
import { checkShareTimes } from '@routes/Dashboard/Dialog/Share/utils';
import useCommon from '@hooks/Common/useCommon';
import { Trans } from 'react-i18next';

interface Props {
  checkedUsers?: any;
  isOpen?: boolean;
  onClose?: () => void;
}

const ExistingGroups = (props: Props) => {
  const { checkedUsers, isOpen, onClose } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { enqueueSnackbar } = useSnackbar();
  let addToGroupComponent = <></>;
  const [inviteGroupMembersNameMutation] = useInviteGroupMembersNameMutation();
  const [addToGroupScreen, setAddToGroupScreen] = useState(
    ADD_TO_GROUP_SCREEN.CHOOSE_GROUP
  );
  const {
    myDrive,
    onSetchatRoomIdGroupName,
    onSetIdGroupName,
    onSetValueGroupNameDialog
  } = useMyDrive();
  const { openFeedbackDialog } = useCommon();

  const classes = useStyles();

  const onSubmitGroupsYouAreJoined = (
    activeSearchResult: any,
    activeGroup: any
  ) => {
    const listMembers = checkedUsers.filter((user: any) =>
      activeGroup?.users?.every((item: any) => item.email !== user)
    );

    const duplicateMembers = checkedUsers.filter((user: any) =>
      activeGroup?.users?.some((item: any) => item.email === user)
    );

    const payload = {
      groupId: activeGroup?.id || '',
      inviteUsers: listMembers.map((member: any) => {
        return {
          email: member,
          accessLevel: ACCESS_LEVEL_VALUE.MANAGER
        };
      })
    };

    if (duplicateMembers.length > 0) {
      enqueueSnackbar(t('snackbar.already_invited_to_the_group'), {
        variant: 'success'
      });
    }

    if (listMembers.length === 0) return;

    inviteGroupMembersNameMutation({
      variables: payload
    }).then((res: any) => {
      const result = res?.data?.inviteGroupMembers?.result;
      if (result === CapaErrorCode.Success) {
        onSetIdGroupName({ idGroupName: activeSearchResult });
        onSetchatRoomIdGroupName({
          chatRoomIdGroupName: activeGroup?.chatRoomId
        });
        setAddToGroupScreen(ADD_TO_GROUP_SCREEN.SUCCESSFULLY_SHARED);
        onSetValueGroupNameDialog({
          valueGroupNameDialog: activeGroup?.groupName
        });
      }
      checkShareTimes(() => {
        openFeedbackDialog({
          isOpenFeedback: true,
          isFeedback: true
        });
      });
    });
  };

  switch (addToGroupScreen) {
    case ADD_TO_GROUP_SCREEN.CHOOSE_GROUP: {
      addToGroupComponent = (
        <GroupsYouAreJoined
          dialogTitle={
            <>
              {isMobile ? (
                <ArrowBackIosOutlined
                  className={classes.arrowBackIosOutlinedIcon}
                  onClick={() => onClose && onClose()}
                />
              ) : (
                <div className={classes.arrowBackIosOutlinedWrapper}>
                  <img src={CapaExistingGroups} alt="capaExistingGroups" />
                </div>
              )}
              <div className={classes.customTitle}>
                {t('groups_you_are_joined.choose_group')}
              </div>
            </>
          }
          onClose={() => onClose && onClose()}
          onSubmit={onSubmitGroupsYouAreJoined}
          checkedUsers={checkedUsers}
        />
      );
      break;
    }
    case ADD_TO_GROUP_SCREEN.SUCCESSFULLY_SHARED: {
      addToGroupComponent = (
        <GroupSuccess
          onCloseDialog={() => {
            onClose && onClose();
            setTimeout(() => {
              setAddToGroupScreen(ADD_TO_GROUP_SCREEN.CHOOSE_GROUP);
            }, 200);
          }}
          content={
            <div className={classes.groupSuccessContent}>
              <Trans>
                {t('share_confirmation_modal.successfully_invited', {
                  groupName: myDrive.valueGroupNameDialog
                })}
              </Trans>
            </div>
          }
        />
      );
      break;
    }
  }

  return (
    <Dialog
      classes={{
        paper:
          addToGroupScreen === ADD_TO_GROUP_SCREEN.SUCCESSFULLY_SHARED
            ? classes.successFullyPaper
            : classes.paper
      }}
      open={isOpen!}
      onClick={(e) => {
        e.stopPropagation();
      }}
      onClose={() => onClose && onClose()}
    >
      {addToGroupComponent}
    </Dialog>
  );
};

export default ExistingGroups;
