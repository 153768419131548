export enum CapaQuotationUserFileType {
  Profile = 'PROFILE'
}

export enum CapaInflowRoute {
  Sns = 'SNS',
  Internet = 'INTERNET',
  Creatable = 'CREATABLE',
  Recommendation = 'RECOMMENDATION',
  Article = 'ARTICLE',
  Email = 'EMAIL',
  Phone = 'PHONE',
  Sms = 'SMS',
  Community = 'COMMUNITY',
  Offline = 'OFFLINE',
  Paper = 'PAPER',
  Presentation = 'PRESENTATION',
  Dm = 'DM',
  Placard = 'PLACARD',
  Etc = 'ETC'
}
