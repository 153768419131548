import React from 'react';
import GnbPopupMenuPresenter from './GnbPopupMenuPresenter';

interface Props {
  callback?: () => void;
}

const GnbPopupMenuContainer: React.FC<Props> = ({ callback }) => {
  return <GnbPopupMenuPresenter callback={callback} />;
};

export default GnbPopupMenuContainer;
