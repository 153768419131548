import React, { useEffect } from 'react';
import { useStyles } from './styles';
import { Tab, Box, Tabs, Typography } from '@material-ui/core';
import FileList from '@routes/GroupRoom/FileList/FileList';
import FileComment from '@routes/GroupRoom/comments/FileComment';
import useMyDrive from '@hooks/Drive/MyDrive/useMyDrive';
import { useHistory, useLocation } from 'react-router-dom';
import HelpItem from './HelpItem';
import useUserActionLog, {
  CapaActivityType,
  Enum_Action_Button_Enum
} from '@hooks/useUserActionLog';
import { groupRoomPathName } from '@routes/Common/types';
import { Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IBMCrowdReport } from '../../../assets/icon/IBMCrowdReport.svg';
import { ReactComponent as IBMFolder } from '../../../assets/icon/IBMFolder.svg';
import clsx from 'clsx';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  classes: any;
}

const LIST_TAB_VALUE = {
  FILE: 0,
  COMMENT: 1
};

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, classes, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      className={classes}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography component={'div'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

interface LeftMenuProps {
  isChatBotGroup: boolean;
}

const LeftMenu = (props: LeftMenuProps) => {
  const { isChatBotGroup } = props;
  const history = useHistory();
  const classes = useStyles();
  const [tabActive, setTabActive] = React.useState(LIST_TAB_VALUE.FILE);
  const { myDrive, onToggleGroupFiles } = useMyDrive();
  const { search } = useLocation();
  const [connectFileId, setConnectFileId] = React.useState('');
  const { onInsertUserActionLogOne } = useUserActionLog();
  const queryFileId = new URLSearchParams(search).get('fileId');
  const chatroomId = new URLSearchParams(search).get('chatroomId');
  const groupId = new URLSearchParams(search).get('groupId');
  const { t } = useTranslation();

  useEffect(() => {
    if (queryFileId) setConnectFileId(queryFileId);
  }, [history]);
  useEffect(() => {
    onToggleGroupFiles({ isShowGroupFiles: true });
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabActive(newValue);
    onToggleGroupFiles({ isShowGroupFiles: true });

    if (newValue === LIST_TAB_VALUE.FILE) {
      onInsertUserActionLogOne(
        Enum_Action_Button_Enum.Button_FileList,
        CapaActivityType.ButtonClick
      );
    } else {
      if (history.location.search.includes('showDownloadPermission')) {
        history.push({
          pathname: `${groupRoomPathName}`,
          search: `?chatroomId=${chatroomId}&groupId=${groupId}&firstItem=true`,
          state: { prevLocation: window.location.href }
        });
      }
      onInsertUserActionLogOne(
        Enum_Action_Button_Enum.Button_CommentList,
        CapaActivityType.ButtonClick
      );
    }
  };

  useEffect(() => {
    if (
      myDrive.isDragDropFilesToGroupRoom &&
      tabActive === LIST_TAB_VALUE.COMMENT
    )
      setTabActive(LIST_TAB_VALUE.FILE);
  }, [myDrive.isDragDropFilesToGroupRoom]);

  return (
    <>
      <Box className={classes.root}>
        <Tabs
          className={clsx(
            classes.rootTabs,
            !myDrive.isShowGroupFiles && 'deactive'
          )}
          orientation="vertical"
          variant="scrollable"
          value={tabActive}
          onChange={(e: any, newValue) => {
            handleChange(e, newValue);
          }}
        >
          <Tooltip
            title={t('tooltip.group_file_list') || 'Group file list'}
            placement="right"
          >
            <Tab
              className={clsx(
                classes.rootTabsItem,
                !myDrive.isShowGroupFiles && 'deactive'
              )}
              icon={<IBMFolder />}
              aria-label="phone"
            />
          </Tooltip>
          <Tooltip
            title={t('tooltip.comment_thread_list') || 'Comment thread list'}
            placement="right"
          >
            <Tab
              className={clsx(
                classes.rootTabsItem,
                !myDrive.isShowGroupFiles && 'deactive'
              )}
              icon={<IBMCrowdReport />}
            />
          </Tooltip>
        </Tabs>
        {!isChatBotGroup && <HelpItem />}
        <TabPanel
          value={tabActive}
          index={LIST_TAB_VALUE.FILE}
          classes={clsx('file-group', myDrive.isShowGroupFiles && 'show')}
        >
          <FileList fileId={connectFileId} isChatBotGroup={isChatBotGroup} />
        </TabPanel>
        <TabPanel
          value={tabActive}
          index={LIST_TAB_VALUE.COMMENT}
          classes={clsx('file-group', myDrive.isShowGroupFiles && 'show')}
        >
          <FileComment />
        </TabPanel>
      </Box>
    </>
  );
};

export default LeftMenu;
