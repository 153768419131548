import { getStorageItem, STORAGE_ACTION } from './storage';
import decode, { JwtPayload } from 'jwt-decode';
import { CapaServiceType } from '@generated/Common/graphql';

const jwtDecode = () => {
  const accessToken = getStorageItem(STORAGE_ACTION.CONNECT_ACCESS_TOKEN);

  try {
    if (!accessToken) return undefined;

    return {
      ...decode<JwtPayload & { scopes: CapaServiceType[] }>(accessToken),
      scopes: decode<JwtPayload & { scopes: CapaServiceType[] }>(accessToken)
        .scopes
        ? decode<JwtPayload & { scopes: CapaServiceType[] }>(accessToken)
            ?.scopes
        : []
    };
  } catch {
    return undefined;
  }
};

export default jwtDecode;
