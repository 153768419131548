import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { BottomNavMenu, GnbAppBar, LayoutDashboard } from '@routes/Common';
import DashboardAsideMenuPresenter from '@routes/Dashboard/DashboardAsideMenuPresenter';
import {
  Breadcrumbs,
  Link,
  useMediaQuery,
  useTheme,
  Box,
  Tooltip
} from '@material-ui/core';
import {
  MuiDiv,
  MuiBoxTable,
  MuiBoxTableItem,
  useStyles
} from '@routes/Dashboard/styles';
import { CustomizedTypography } from '@components/Customized';
import { FolderShared } from '@material-ui/icons';
import { MuiBoxTableItemShareMobile, MuiBoxShareTable } from './styles';
import useMyDrive from '@hooks/Drive/MyDrive/useMyDrive';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import { checkShareTimes } from '@routes/Dashboard/Dialog/Share/utils';
import {
  CONNECT_UPDATE_TYPE,
  DEFAULT_ITEMS_LIMIT,
  DEFAULT_ITEMS_OFFSET,
  sharedWithGroupsPathName
} from '@routes/Common/types';
import EnteredGroupList from '@routes/SharedWithGroups/EnteredGroupList';
import ControlButtonList from '@routes/SharedWithGroups/ControlButtonList';
import useSharedWithGroups from '@hooks/Drive/MyDrive/useSharedWithGroups';
import TabPanelControl from '@routes/Dashboard/TabPanel/TabPanelControl';
import { CapaErrorCode } from '@generated/Common/graphql';
import { CapaActivityType } from '@generated/Common/types';
import {
  useShareFilesToGroupMutation,
  SubscribeConnectUpdateData
} from '@generated/Drive/MyDrive/graphql';
import Loading from '@components/LoadingInComponent';
import NoData from './NoData';
import { useTranslation } from 'react-i18next';
import CreateGroupContainerDialog from '@routes/Group/Dialog/CreateGroup/CreateGroupContainer';
import { CREATE_GROUP_SCREEN } from '@routes/Group/Dialog/types';
import { ADD_FILES_FROM_VALUE } from '@routes/Common/Dialog/UploadFiles/Mobile/types';
import useGroup from '@hooks/Group/useGroup';
import { URL_PARAM } from '@routes/Dashboard/types';
import useUserActionLog, {
  Enum_Action_Button_Enum,
  Enum_Action_Page_Enum
} from '@hooks/useUserActionLog';
import OverflowTip from '@components/OverflowTooltips';
import { ACCESS_LEVEL_SHOW_OPTIONS } from '@routes/Dashboard/Dialog/Share/types';
import useCommon from '@hooks/Common/useCommon';
import { isChildrenPage } from '@routes/Common/utils';
import { useSubscription } from '@apollo/client';
import CapaAvatar from '@components/CapaAvatar';
import { IBMChevronBreadcrumb, IBMFolder } from '@assets';
import { getGroupNameByUsernameOrEmail } from '@utils/common';

const SharedWithGroupsContainer = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { pathname } = useLocation<any>();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();
  const hasChildGroupPage = isChildrenPage(pathname, 'shared-with-groups');
  const { onSetSelectedFilesUploadInChat, onSetAddFilesFromType } =
    useMyDrive();
  const { openFeedbackDialog } = useCommon();
  const [selectedGroupName, setSelectedGroupName] = useState('');
  const { sharedWithGroups } = useSharedWithGroups();
  const [ShareFilesToGroup] = useShareFilesToGroupMutation();
  const [isShareFilesToGroupSuccess, setShareFilesToGroupSuccess] =
    useState(false);
  const dropzoneWrapperRef = useRef<any>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [internalGroupsState, setInternalGroupsState] = useState<any>([]);
  const [isShowHideCreateGroupDialog, setShowHideCreateGroupDialog] =
    useState<boolean>(false);
  const {
    onSetCreateGroupScreen,
    groupsState,
    onFetchMore,
    onRefetchGroupsData
  } = useGroup();
  const { onInsertUserActionLogOne } = useUserActionLog();
  const [checkedGroup, setCheckedGroup] = useState<any>([]);
  const [isChatBotGroup, setChatBotGroup] = useState<boolean>(false);
  const [isLoadingChildPage, setLoadingChildPage] = useState<boolean>(true);
  const [subscribeConnectUpdateDataType, setSubscribeConnectUpdateDataType] =
    useState<any>('');

  useSubscription(SubscribeConnectUpdateData, {
    onSubscriptionData: (subscriptionData) => {
      setSubscribeConnectUpdateDataType(
        subscriptionData?.subscriptionData?.data?.subscribeConnectUpdateData
          ?.data?.type
      );
    }
  });

  useEffect(() => {
    switch (subscribeConnectUpdateDataType) {
      case CONNECT_UPDATE_TYPE.MEMBER_UPDATE:
      case CONNECT_UPDATE_TYPE.GROUP_UPDATE: {
        onRefetchGroupsData({
          limit: DEFAULT_ITEMS_LIMIT,
          offset: DEFAULT_ITEMS_OFFSET
        });
        break;
      }
    }
  }, [subscribeConnectUpdateDataType]);

  const onParentCallBack = (
    groupName: any,
    isChatBotGroup: boolean,
    isLoading: boolean
  ) => {
    setSelectedGroupName(groupName);
    setChatBotGroup(isChatBotGroup);
    setLoadingChildPage(isLoading);
  };

  let latestTouchTap = {
    time: 0,
    target: null
  };

  const onMobileDoubleClick = (event: any) => {
    const touchTap = {
      time: new Date().getTime(),
      target: event.currentTarget
    };
    const isFastDblTouchTap =
      touchTap.target === latestTouchTap.target &&
      touchTap.time - latestTouchTap.time < 300;
    latestTouchTap = touchTap;
    return isFastDblTouchTap;
  };

  const onUploadFileToGroupCallback = (fileIds: any) => {
    const payload: any = {
      groupId: pathname.split('/')[2],
      connectFileIds: fileIds
    };

    ShareFilesToGroup({ variables: payload }).then((res) => {
      const result = res?.data?.shareFilesToGroup?.result;
      if (result === CapaErrorCode.Success) {
        setShareFilesToGroupSuccess(true);
        checkShareTimes(() => {
          openFeedbackDialog({
            isOpenFeedback: true,
            isFeedback: true
          });
        });
      }
    });
  };

  const onScrollDropzoneWrapper = () => {
    const currentDropzoneWrapperRef =
      dropzoneWrapperRef && dropzoneWrapperRef.current;
    if (
      Math.ceil(currentDropzoneWrapperRef.scrollTop) +
        currentDropzoneWrapperRef.clientHeight !==
        currentDropzoneWrapperRef.scrollHeight ||
      isFetching
    )
      return;

    setIsFetching(true);
  };

  const onCloseCreateGroupDialog = () => {
    onRefetchGroupsData({
      limit: DEFAULT_ITEMS_LIMIT,
      offset: DEFAULT_ITEMS_OFFSET
    });
    setTimeout(() => {
      onSetSelectedFilesUploadInChat({ selectedFilesUploadInChat: [] });
      setShowHideCreateGroupDialog(false);
    }, 100);
  };

  const onClickCreateGroup = () => {
    setShowHideCreateGroupDialog(true);
    onSetCreateGroupScreen({
      createGroupScreen: CREATE_GROUP_SCREEN.CREATE_GROUP
    });
    onSetAddFilesFromType({
      addFilesFromType: ADD_FILES_FROM_VALUE.EMPTY
    });
  };

  useEffect(() => {
    if (groupsState?.data) {
      setInternalGroupsState(groupsState?.data ?? []);
    }
  }, [groupsState]);

  useEffect(() => {
    onRefetchGroupsData({
      limit: DEFAULT_ITEMS_LIMIT,
      offset: DEFAULT_ITEMS_OFFSET
    });
    onInsertUserActionLogOne(
      Enum_Action_Page_Enum.Page_SharedDrive,
      CapaActivityType.PageVisit
    );
  }, []);

  useEffect(() => {
    if (!isFetching) return;
    setTimeout(() => {
      onFetchMore({
        variables: {
          limit: DEFAULT_ITEMS_LIMIT,
          offset: groupsState?.data!.length
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return prev;
          }
          const prevFiles = prev.fetchGroups.data;
          const moreFiles = fetchMoreResult.fetchGroups.data;

          if (fetchMoreResult.fetchGroups.data)
            fetchMoreResult.fetchGroups.data = [...prevFiles!, ...moreFiles!];
          setInternalGroupsState(fetchMoreResult.fetchGroups.data);
          return {
            ...fetchMoreResult
          };
        }
      });
      setIsFetching(false);
    }, 500);
  }, [isFetching]);

  return (
    <LayoutDashboard
      contentShift={!matches}
      asideDrawer={
        matches ? null : (
          <DashboardAsideMenuPresenter
            uploadFileToGroupCallback={onUploadFileToGroupCallback}
            isChatBotGroup={isChatBotGroup}
            isLoadingEnteredGroupPage={isLoadingChildPage}
          />
        )
      }
      matches={matches}
      gnbAppBar={<GnbAppBar headerName={selectedGroupName} />}
      header={
        !matches ? (
          <div className="header">
            <CustomizedTypography
              component={'div'}
              variant="h3"
              fontWeight="500"
            >
              <Breadcrumbs
                separator={
                  <img src={IBMChevronBreadcrumb} alt="IBMChevronBreadcrumb" />
                }
                aria-label="breadcrumb"
              >
                <Link
                  className="cursor-pointer"
                  color="inherit"
                  onClick={() => {
                    history.push(`${sharedWithGroupsPathName}`);
                  }}
                >
                  {t('shared_with_groups.shared_with_groups')}
                </Link>
                {hasChildGroupPage ? (
                  <Link className="cursor-pointer" color="inherit">
                    <OverflowTip
                      value={selectedGroupName || false}
                      maxWidth={800}
                    >
                      {selectedGroupName}
                    </OverflowTip>
                  </Link>
                ) : null}
              </Breadcrumbs>
            </CustomizedTypography>

            {sharedWithGroups.selectedGroupFiles.length > 0 ? (
              <ControlButtonList
                isShowPopUp={false}
                refetchGroup={() => setShareFilesToGroupSuccess(true)}
              />
            ) : null}
          </div>
        ) : (
          <></>
        )
      }
    >
      <MuiDiv>
        {!matches ? (
          <>
            {internalGroupsState.length > 0 ? (
              <MuiBoxTable>
                {hasChildGroupPage ? (
                  <EnteredGroupList
                    subscribeConnectUpdateDataType={
                      subscribeConnectUpdateDataType
                    }
                    parentCallback={onParentCallBack}
                    isActionStatus={isShareFilesToGroupSuccess}
                    setActionStatus={() => setShareFilesToGroupSuccess(false)}
                    uploadFileToGroupCallback={onUploadFileToGroupCallback}
                  />
                ) : (
                  <>
                    <div className="table-header">
                      <CustomizedTypography
                        className="table-header-th-group-name"
                        variant="body2"
                        color="textSecondary"
                      >
                        {t('shared_with_groups.group_name')}
                      </CustomizedTypography>
                      <CustomizedTypography
                        className="table-header-th-my-access"
                        variant="body2"
                        color="textSecondary"
                      >
                        {t('shared_with_groups.my_access')}
                      </CustomizedTypography>
                      <CustomizedTypography
                        className="table-header-th-shared-with"
                        variant="body2"
                        color="textSecondary"
                      >
                        {t('shared_with_groups.shared_with')}
                      </CustomizedTypography>
                    </div>
                    {groupsState?.isLoading ? (
                      <Loading
                        open={groupsState?.isLoading}
                        styles={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: 70
                        }}
                      />
                    ) : (
                      <div
                        className={classes.dropzoneWrapper}
                        onScroll={onScrollDropzoneWrapper}
                        ref={dropzoneWrapperRef}
                      >
                        {internalGroupsState!.map(
                          (group: any, index: number) => {
                            return (
                              <MuiBoxTableItem
                                key={index}
                                isChecked={checkedGroup?.includes(group)}
                                onClick={() => {
                                  onInsertUserActionLogOne(
                                    Enum_Action_Button_Enum.Click_SharedDrive_GroupFolder,
                                    CapaActivityType.ButtonClick
                                  );
                                  setCheckedGroup([group]);
                                  history.push(
                                    `${sharedWithGroupsPathName}/${group.id}`
                                  );
                                }}
                              >
                                <Tooltip
                                  title={
                                    group.groupName
                                      ? group.groupName
                                      : getGroupNameByUsernameOrEmail(
                                          group?.users,
                                          true
                                        )
                                  }
                                >
                                  <div className="table-item-td-group-name">
                                    <FolderShared className="folder-shared-icon" />
                                    <CustomizedTypography
                                      variant="body2"
                                      noWrap
                                    >
                                      {group.groupName
                                        ? group.groupName
                                        : getGroupNameByUsernameOrEmail(
                                            group?.users
                                          )}
                                    </CustomizedTypography>
                                  </div>
                                </Tooltip>
                                <div className="table-item-td-my-access">
                                  <CustomizedTypography
                                    variant="body2"
                                    className="access-level"
                                  >
                                    {ACCESS_LEVEL_SHOW_OPTIONS.filter(
                                      (option: any) =>
                                        option.value === group.accessLevel
                                    )[0]
                                      ? t(
                                          ACCESS_LEVEL_SHOW_OPTIONS.filter(
                                            (option: any) =>
                                              option.value === group.accessLevel
                                          )[0].name
                                        )
                                      : null}
                                  </CustomizedTypography>
                                </div>
                                <div className="table-item-td-shared-with">
                                  <CustomizedTypography
                                    component={'div'}
                                    variant="body2"
                                  >
                                    <div className={classes.avatarGroupWrapper}>
                                      <AvatarGroup
                                        max={4}
                                        className={classes.avatarGroup}
                                      >
                                        {group?.users!.map(
                                          (user: any, index: number) => {
                                            return (
                                              <CapaAvatar
                                                item={user}
                                                key={index}
                                                showTooltip={true}
                                                size="small"
                                              />
                                            );
                                          }
                                        )}
                                      </AvatarGroup>
                                    </div>
                                  </CustomizedTypography>
                                </div>
                              </MuiBoxTableItem>
                            );
                          }
                        )}
                      </div>
                    )}
                  </>
                )}
              </MuiBoxTable>
            ) : groupsState?.isLoading ? (
              <Loading
                open={groupsState?.isLoading}
                styles={{
                  display: 'flex',
                  justifyContent: 'center',
                  height: 70
                }}
              />
            ) : (
              <NoData
                title={t(
                  'shared_with_groups.no_data.share_your_files_with_others'
                )}
                subTitle={[
                  t('shared_with_groups.no_data.you_can_shared_files'),
                  t('shared_with_groups.no_data.by_creating_a_new_group')
                ]}
                isSharedWithGroup={true}
                clickCreateGroup={() => onClickCreateGroup()}
              />
            )}
          </>
        ) : (
          // Mobile Wrapper
          <>
            {hasChildGroupPage ? (
              <EnteredGroupList
                subscribeConnectUpdateDataType={subscribeConnectUpdateDataType}
                parentCallback={onParentCallBack}
                isActionStatus={isShareFilesToGroupSuccess}
                setActionStatus={() => setShareFilesToGroupSuccess(false)}
                uploadFileToGroupCallback={onUploadFileToGroupCallback}
              />
            ) : (
              <>
                <TabPanelControl urlParam={URL_PARAM.urlShared} />
                <MuiBoxShareTable>
                  {internalGroupsState.length > 0 ? (
                    internalGroupsState.map((group: any, index: number) => {
                      return (
                        <MuiBoxTableItemShareMobile
                          key={index}
                          onTouchStart={(e: any) => {
                            if (matches) {
                              if (onMobileDoubleClick(e)) {
                                history.push(
                                  `${sharedWithGroupsPathName}/${group.id}`
                                );
                              }
                            }
                          }}
                          onClick={() => {
                            onInsertUserActionLogOne(
                              Enum_Action_Button_Enum.Click_SharedDrive_GroupFolder,
                              CapaActivityType.ButtonClick
                            );
                            history.push(
                              `${sharedWithGroupsPathName}/${group.id}`
                            );
                          }}
                        >
                          <button className="button-long-press">
                            <img src={IBMFolder} alt="IBMFolder" />
                            <div className="table-content">
                              <CustomizedTypography
                                variant="body2"
                                noWrap
                                fontWeight="500"
                              >
                                {group.groupName}
                              </CustomizedTypography>
                              <Box
                                display="flex"
                                justifyContent="space-between"
                              >
                                <CustomizedTypography
                                  component={'div'}
                                  variant="caption"
                                  color="textSecondary"
                                  gutterBottoms={2}
                                >
                                  <div className="content">
                                    {group?.accessLevel?.toLocaleLowerCase()}{' '}
                                    {t('shared_with_groups.of_group')}
                                  </div>
                                </CustomizedTypography>
                                <CustomizedTypography
                                  component={'div'}
                                  variant="caption"
                                  color="textSecondary"
                                  gutterBottoms={2}
                                >
                                  <div className="content-right">
                                    {`${group?.users!.length} `}
                                    {group?.users!.length > 1
                                      ? t('shared_with_groups.member')
                                      : t('shared_with_groups.members')}
                                  </div>
                                </CustomizedTypography>
                              </Box>
                            </div>
                          </button>
                        </MuiBoxTableItemShareMobile>
                      );
                    })
                  ) : (
                    <NoData
                      title={t(
                        'shared_with_groups.no_data.share_your_files_with_others'
                      )}
                      subTitle={[
                        t('shared_with_groups.no_data.you_can_shared_files'),
                        t('shared_with_groups.no_data.by_creating_a_new_group')
                      ]}
                      isSharedWithGroup={true}
                      clickCreateGroup={() => onClickCreateGroup()}
                    />
                  )}
                </MuiBoxShareTable>
              </>
            )}
            <BottomNavMenu />
          </>
        )}
      </MuiDiv>

      <CreateGroupContainerDialog
        isShowHideCreateGroupDialog={isShowHideCreateGroupDialog}
        onCloseDialog={onCloseCreateGroupDialog}
      />
    </LayoutDashboard>
  );
};

export default SharedWithGroupsContainer;
