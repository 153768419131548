import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { useStyles } from './styles';
import { colorSet } from '@components/Provider';
import clsx from 'clsx';

interface Props {
  open: boolean;
  styles?: any;
  size?: number;
  loadingWrapperClass?: any;
  color?: any;
}

const Loading: React.FC<Props> = ({
  open,
  styles,
  size,
  loadingWrapperClass,
  color
}) => {
  const { backdrop, customLoading } = useStyles();
  if (!open) return null;

  return (
    <div className={clsx(backdrop, loadingWrapperClass)} style={styles}>
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={customLoading}
        style={{
          color: color ? color : colorSet.primary500
        }}
        size={size ? size : 40}
        thickness={2}
      />
    </div>
  );
};

export default Loading;
