import { feedback_icon } from '@assets';
import useCommon from '@hooks/Common/useCommon';
import { Box } from '@material-ui/core';
import { STORAGE_ACTION, getStorageItem } from '@utils/storage';
import React from 'react';

const FeedBackBtn = () => {
  const { openFeedbackDialog } = useCommon();
  const userInfo = getStorageItem(STORAGE_ACTION.USER_INFO);
  const accessToken = getStorageItem(STORAGE_ACTION.CONNECT_ACCESS_TOKEN);
  const isLoggedIn = !!accessToken && !!(userInfo && userInfo.email);
  return (
    <>
      {isLoggedIn && (
        <Box
          onClick={() =>
            openFeedbackDialog({
              isOpenFeedback: true,
              isFeedback: true
            })
          }
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            position: 'fixed',
            right: '24px',
            bottom: '24px',
            width: '59px',
            height: '59px',
            borderRadius: '24px',
            background: '#FFF',
            boxShadow:
              'rgba(255, 255, 255, 0.2) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.1) 0px 4px 6px, rgba(0, 0, 0, 0.15) 0px 8px 30px'
          }}
        >
          <img
            src={feedback_icon}
            alt="no feedback_icon"
            width={32}
            height={32}
          />
        </Box>
      )}
    </>
  );
};

export default FeedBackBtn;
