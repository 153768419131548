import React from 'react';
import { MuiDiv, useEmptyStyles } from './styles';
import { Trans } from 'react-i18next';
import useMyContacts from '@hooks/Contacts/useMyContacts';

const EmptyPresenter: React.FC = () => {
  const classesEmpty = useEmptyStyles();
  const { onSetStateContact } = useMyContacts();
  return (
    <MuiDiv className="connect-empty">
      <div className={classesEmpty.title}>
        <Trans i18nKey={'contact.empty.empty'} />
      </div>
      <div className={classesEmpty.subTitle}>
        <Trans i18nKey={'contact.empty.add_contact'} />
      </div>
      <button
        className={classesEmpty.button}
        onClick={() =>
          onSetStateContact({
            dataCreateEditDialog: []
          })
        }
      >
        <Trans i18nKey={'contact.new'} />
      </button>
    </MuiDiv>
  );
};

export default EmptyPresenter;
