import { createAction } from 'typesafe-actions';
import { ICommonState } from './types';

export const UPDATE_COMMON_STATE = 'UPDATE_COMMON_STATE';
export const updateCommonState =
  createAction(UPDATE_COMMON_STATE)<ICommonState>();

export const SHOW_HIDE_NUMBER_NOTICE = 'SHOW_HIDE_NUMBER_NOTICE';
export const showHideNumberNotice = createAction(
  SHOW_HIDE_NUMBER_NOTICE
)<ICommonState>();

export const SET_SHOW_HIDE_WELCOME_DIALOG = 'SET_SHOW_HIDE_WELCOME_DIALOG';
export const setShowHideWelcomeDialog = createAction(
  SET_SHOW_HIDE_WELCOME_DIALOG
)<ICommonState>();

export const SET_UNREAD_COUNT_GROUP_LIST = 'SET_UNREAD_COUNT_GROUP_LIST';
export const setUnReadCountGroupList = createAction(
  SET_UNREAD_COUNT_GROUP_LIST
)<ICommonState>();

export const SET_DELETE_CHAT_BOT_SUCCESS_STATUS =
  'SET_DELETE_CHAT_BOT_SUCCESS_STATUS';
export const setDeleteChatBotSuccessStatus = createAction(
  SET_DELETE_CHAT_BOT_SUCCESS_STATUS
)<ICommonState>();

export const INIT_COMMON_STATE = 'INIT_COMMON_STATE';
export const initCommonState = createAction(INIT_COMMON_STATE)();
