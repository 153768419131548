import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { colorSet } from '@components/Provider';

const FullPageEmptyWrapper = styled.div`
  height: calc(100vh - 130px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  & .title {
    font-size: 15px;
    font-weight: 700;
    line-height: 21px;
    text-align: center;
    color: ${colorSet.gray900};
    margin-bottom: 8px;
  }

  & .sub-title {
    font-size: 13px;
    font-weight: 400;
    line-height: 19px;
    text-align: center;
    color: ${colorSet.gray600};
    white-space: pre-line;
  }
`;

function FullPageEmpty() {
  const { t } = useTranslation();

  return (
    <FullPageEmptyWrapper>
      <div className="title">{t('activity_page.no_activity')}</div>
      <div className="sub-title">{t('activity_page.sub_title')}</div>
    </FullPageEmptyWrapper>
  );
}

export default FullPageEmpty;
