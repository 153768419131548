import { groupDetailFileApi } from '@api/index';
import {
  IBMChevronLeft,
  IBMClose,
  IBMFile,
  IBMFolder,
  IBMPDF,
  IBMVideo
} from '@assets';
import CapaAvatar from '@components/CapaAvatar';
import FileIcon from '@components/FileIcon/FileIcon';
import Loading from '@components/LoadingInComponent';
import {
  ConnectUserFileLinkStatus,
  ConnectUserFileStatus
} from '@generated/Common/graphql';
import useMyDrive from '@hooks/Drive/MyDrive/useMyDrive';
import useSharedWithGroups from '@hooks/Drive/MyDrive/useSharedWithGroups';
import useGroup from '@hooks/Group/useGroup';
import useUserActionLog, {
  CapaActivityType,
  Enum_Action_Button_Enum
} from '@hooks/useUserActionLog';
import {
  Avatar,
  IconButton,
  Popover,
  Tooltip,
  Typography
} from '@material-ui/core';
import {
  DRAWING_FILE_TYPES,
  IMAGE_TYPES,
  NO_SUPORT_DOCUMENT_TYPES,
  PDF_TYPE,
  SUPORT_DOCUMENT_TYPES,
  VIDEO_TYPES
} from '@routes/Common/Dropzone/types';
import { useStylesLayout } from '@routes/Common/LayoutDashboard/useStyles';
import { groupRoomPathName } from '@routes/Common/types';
import { formatDateToString, formatMoreUser } from '@routes/Common/utils';
import DownloadPermissionDialog from '@routes/Dashboard/Dialog/DownloadPermission/DownloadPermission';
import {
  SHARE_DIALOG_SCREEN,
  SHARE_FILES_TO_VALUE
} from '@routes/Dashboard/Dialog/Share/types';
import {
  getDisplayedAvatarString,
  getGroupNameByUsernameOrEmail
} from '@utils/common';
import { blobToBase64, convertSubstring, urlThumbnail } from '@utils/file';
import { STORAGE_ACTION, getStorageItem } from '@utils/storage';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ReactComponent as IBMChevronRight } from './../../../assets/icon/IBMChevronRight.svg';
import { ReactComponent as IBMCloud } from './../../../assets/icon/IBMCloud.svg';
import { ReactComponent as IBMDocumentUnknown } from './../../../assets/icon/IBMDocumentUnknown.svg';
import { ReactComponent as IBMLink } from './../../../assets/icon/IBMLink.svg';
import { ReactComponent as IBMSettingsCheck } from './../../../assets/icon/IBMSettingsCheck.svg';
import { ReactComponent as IBMShare } from './../../../assets/icon/IBMShare.svg';
interface Props {
  checkedFiles?: any;
}

const DetailFile = (props: Props) => {
  const { checkedFiles } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStylesLayout();
  const [anchorEl, setAnchorEl] = useState(null);
  const openPopover = Boolean(anchorEl);
  const popoverId = openPopover ? 'popover-user-id' : undefined;
  const [isLoading, setIsLoading] = useState(false);
  const [groupUser, setGroupUser] = useState({ users: [] });
  const [imgUrl, setImgUrl] = useState('');
  const userInfo = getStorageItem(STORAGE_ACTION.USER_INFO);
  const { groupDataState } = useSharedWithGroups();
  const { onInsertUserActionLogOne } = useUserActionLog();

  const handleClickOpenPopUp = (event: any, users: any) => {
    setGroupUser(users);
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClosePopUp = () => {
    setAnchorEl(null);
  };
  const {
    myDrive,
    onSetShowHideFileDetail,
    onSetShowHideDownloadPermissionDialog,
    onSetSelectedGroupShared,
    onSetRenameSuccessStatus,
    onSetShowHideShareDialog,
    onSetShareDialogScreen,
    onSetShareFilesToType
  } = useMyDrive();
  const {
    onFetchGroupDetailData,
    groupsDetailState,
    fetchGroupsDetailLoading
  } = useGroup();
  const originalFileExt =
    groupsDetailState?.data?.originalFileExt?.toLocaleLowerCase();
  const hideDetail = () => {
    onInsertUserActionLogOne(
      Enum_Action_Button_Enum.Button_MyDrive_File_DetailDrawer_Close,
      CapaActivityType.ButtonClick
    );
    onSetShowHideFileDetail({
      isShowIsDetailFile: false
    });
    getStorageItem(STORAGE_ACTION.IS_OPEN_DETAIL_FILE)
      ? localStorage.removeItem(STORAGE_ACTION.IS_OPEN_DETAIL_FILE)
      : localStorage.setItem(STORAGE_ACTION.IS_OPEN_DETAIL_FILE, 'true');
    if (window.innerWidth < 600) {
      const elemBody: any = document.querySelector('body');
      elemBody.style.overflow = 'auto';
    }
  };

  useEffect(() => {
    if (isMobile) {
      onSetShowHideFileDetail({
        isShowIsDetailFile: false
      });
      localStorage.removeItem(STORAGE_ACTION.IS_OPEN_DETAIL_FILE);
    }
  }, []);

  const onOpenDownloadPermissionDialog = (event: any) => {
    onInsertUserActionLogOne(
      Enum_Action_Button_Enum.Button_MyDrive_File_DetailDrawer_More_DownloadPermission,
      CapaActivityType.ButtonClick
    );
    onSetShowHideDownloadPermissionDialog({
      isShowHideDownloadPermissionDialog: true
    });
  };

  const onRefetchFileDetail = () => {
    onFetchGroupDetailData({
      variables: {
        fileId: myDrive.detailDrive.isFile ? myDrive.detailDrive.id : ''
      }
    });
  };

  const onClickShare = () => {
    onSetShowHideShareDialog({ isShowHideShareDialog: true });
    onSetShareDialogScreen({
      shareDialogScreen: SHARE_DIALOG_SCREEN.SHARE
    });
    onSetShareFilesToType({
      shareFilesToType: SHARE_FILES_TO_VALUE.EMPTY
    });
  };

  useEffect(() => {
    if (myDrive && myDrive.detailDrive && myDrive.detailDrive.id) {
      onRefetchFileDetail();
    }
    if (myDrive && myDrive.detailDrive && myDrive.detailDrive.isFile) {
      setIsLoading(true);
      groupDetailFileApi(myDrive.detailDrive).then((res: any) => {
        setIsLoading(false);
        blobToBase64(res.data).then((imageUrl: any) => {
          setImgUrl(imageUrl);
        });
        return;
      });
    }

    return () => {
      // Fix display old image.
      setImgUrl('');
    };
  }, [myDrive.detailDrive]);

  useEffect(() => {
    if (myDrive.isRenameSuccess) {
      onRefetchFileDetail();
      onSetRenameSuccessStatus({
        isRenameSuccess: false
      });
    }
  }, [myDrive.isRenameSuccess]);

  return (
    <div
      className={clsx(
        classes.mainContentRight,
        myDrive.isShowIsDetailFile ? 'active' : ''
      )}
    >
      <div className={classes.contentGroup}>
        <div className={classes.mainContentRightItem}>
          <IconButton
            className={classes.mainContentRightTitleIconLeft}
            onClick={hideDetail}
          >
            <img src={IBMChevronLeft} alt="" />
          </IconButton>
          {!fetchGroupsDetailLoading && (
            <>
              {(groupsDetailState?.data || fetchGroupsDetailLoading) &&
              myDrive.detailDrive ? (
                <>
                  {(convertSubstring(IMAGE_TYPES).includes(originalFileExt) &&
                    imgUrl == null) ||
                  (convertSubstring(DRAWING_FILE_TYPES).includes(
                    originalFileExt
                  ) &&
                    groupsDetailState?.data?.thumbnail == null) ? (
                    <IBMDocumentUnknown className="weird" />
                  ) : (
                    <FileIcon file={groupsDetailState?.data} />
                  )}
                </>
              ) : (
                <div className="file-icon">
                  <IBMCloud />
                </div>
              )}
            </>
          )}
          <div className={classes.mainContentRightTitle}>
            {!fetchGroupsDetailLoading && (
              <>
                {groupsDetailState?.data && myDrive.detailDrive
                  ? groupsDetailState?.data?.fileName
                  : t('lnb.my_drive')}
              </>
            )}
          </div>
          <IconButton
            className={classes.mainContentRightTitleIcon}
            onClick={hideDetail}
          >
            <img src={IBMClose} alt="" />
          </IconButton>
        </div>
        {myDrive.detailDrive && groupsDetailState?.data !== undefined && (
          <div className={classes.mainContentRightImage}>
            {convertSubstring(IMAGE_TYPES).includes(originalFileExt) && (
              <>
                {isLoading ? (
                  <Loading open={isLoading} />
                ) : imgUrl ? (
                  <img
                    className={classes.mainContentRightImg}
                    src={imgUrl}
                    alt={myDrive.detailDrive.fileName}
                  />
                ) : (
                  <IBMDocumentUnknown className="weird" />
                )}
              </>
            )}
            {originalFileExt === PDF_TYPE && (
              <img
                className={classes.mainContentRightImgPDF}
                src={IBMPDF}
                alt=""
              />
            )}
            {convertSubstring(
              NO_SUPORT_DOCUMENT_TYPES.concat(SUPORT_DOCUMENT_TYPES)
            ).includes(originalFileExt) && (
              <img
                className={classes.mainContentRightImgPDF}
                src={IBMFile}
                alt=""
              />
            )}
            {convertSubstring(VIDEO_TYPES).includes(originalFileExt) && (
              <img
                className={classes.mainContentRightImgPDF}
                src={IBMVideo}
                alt=""
              />
            )}
            {convertSubstring(DRAWING_FILE_TYPES).includes(originalFileExt) && (
              <>
                {groupsDetailState?.data?.thumbnail ? (
                  <img
                    className={classes.mainContentRightImgDrawing}
                    src={urlThumbnail(groupsDetailState?.data?.thumbnail)}
                    alt=""
                  />
                ) : (
                  <IBMDocumentUnknown className="weird" />
                )}
              </>
            )}
            {myDrive &&
            myDrive.detailDrive &&
            myDrive.detailDrive.originalFileExt === null ? (
              <img className="folder-icon" src={IBMFolder} alt="" />
            ) : null}
          </div>
        )}
        {fetchGroupsDetailLoading ? (
          <div className={classes.loading}>
            <Loading
              open={fetchGroupsDetailLoading}
              styles={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: 40
              }}
            />
          </div>
        ) : (
          <>
            {groupsDetailState?.data === undefined || !myDrive.detailDrive ? (
              <div className={classes.nonSelect}>
                {t('my_drive.empty_my_drive.non_selected')}
              </div>
            ) : (
              <>
                {groupsDetailState?.data?.linkStatus ==
                  ConnectUserFileLinkStatus.Active && (
                  <div
                    className={classes.manageLink}
                    onClick={() => onClickShare()}
                  >
                    <div className="content">
                      <div className="icon-link">
                        <IBMLink />
                      </div>

                      <div className="name">
                        <div className="title">{t('my_drive.manage_link')}</div>
                        <div className="description">
                          {groupsDetailState?.data?.filePermission ==
                          ConnectUserFileStatus.Downloadable
                            ? t(
                                'share_modal.share_link.link_settings.downloaded.name'
                              )
                            : t(
                                'share_modal.share_link.link_settings.viewed.name'
                              )}
                        </div>
                      </div>
                    </div>
                    <div className="icon-right">
                      <IBMChevronRight />
                    </div>
                  </div>
                )}
                <div className={classes.mainContentRightGroup}>
                  <div className={classes.mainContentRightGroupTitle}>
                    {t('detail_viewer.list_title')}
                  </div>
                  <div
                    className={clsx(
                      classes.mainContentRightGroupListItem,
                      groupsDetailState?.data?.linkStatus ==
                        ConnectUserFileLinkStatus.Active && 'is-share'
                    )}
                  >
                    {groupsDetailState &&
                      groupsDetailState?.data &&
                      groupsDetailState?.data?.groups &&
                      groupsDetailState?.data?.groups?.map(
                        (item: any, index: number) => {
                          let hasUserInGroup = false;
                          item?.users?.forEach((user: any) => {
                            if (userInfo.email === user.email) {
                              hasUserInGroup = true;
                            }
                          });
                          return (
                            <div
                              key={index}
                              className={classes.mainContentRightGroupList}
                              onClick={() => {
                                if (!hasUserInGroup) return;
                                onInsertUserActionLogOne(
                                  Enum_Action_Button_Enum.Button_MyDrive_File_DetailDrawer_Group,
                                  CapaActivityType.ButtonClick
                                );
                                const payload = {
                                  groupId: item.id,
                                  groupName: item.groupName,
                                  chatRoomId: item.chatRoomId,
                                  connectFileId:
                                    myDrive &&
                                    myDrive.detailDrive &&
                                    myDrive.detailDrive.id
                                };
                                onSetSelectedGroupShared({
                                  selectedGroupShared: payload
                                });
                              }}
                            >
                              <Tooltip
                                title={
                                  item?.groupName
                                    ? item?.groupName
                                    : getGroupNameByUsernameOrEmail(
                                        item?.users,
                                        true
                                      )
                                }
                              >
                                <div
                                  className={
                                    classes.mainContentRightGroupNameTile
                                  }
                                  onClick={() =>
                                    history.push({
                                      pathname: `${groupRoomPathName}`,
                                      search: `?chatroomId=${item.chatRoomId}&groupId=${item.id}`,
                                      state: {
                                        prevLocation: window.location.href
                                      }
                                    })
                                  }
                                >
                                  {item.groupName
                                    ? item.groupName
                                    : getGroupNameByUsernameOrEmail(
                                        item?.users
                                      )}
                                </div>
                              </Tooltip>
                              <div
                                className={classes.mainContentRightGroupName}
                              >
                                <div
                                  className={classes.mainContentRightGroupImage}
                                  onClick={() =>
                                    history.push({
                                      pathname: `${groupRoomPathName}`,
                                      search: `?chatroomId=${item.chatRoomId}&groupId=${item.id}`,
                                      state: {
                                        prevLocation: window.location.href
                                      }
                                    })
                                  }
                                >
                                  {item.users &&
                                    item.users
                                      .slice(0, 4)
                                      .map((user: any, userIndex: number) => (
                                        <Tooltip
                                          title={`${getDisplayedAvatarString(
                                            user.connectUserName,
                                            user.email
                                          )} ${
                                            convertSubstring(
                                              DRAWING_FILE_TYPES
                                            ).includes(originalFileExt)
                                              ? t('detail_viewer.can_download')
                                              : ''
                                          }`}
                                          placement="bottom"
                                        >
                                          <div
                                            key={userIndex}
                                            className={
                                              classes.mainContentRightGroupHover
                                            }
                                          >
                                            <CapaAvatar
                                              key={userIndex}
                                              item={user}
                                              size="xSmall"
                                              showTooltip={true}
                                            />
                                          </div>
                                        </Tooltip>
                                      ))}
                                  {item.users && item.users.length > 4 ? (
                                    <div>
                                      <Avatar
                                        onClick={(e) =>
                                          handleClickOpenPopUp(e, item)
                                        }
                                        className={classes.customAvatar}
                                      >
                                        {formatMoreUser(item.users)}
                                      </Avatar>
                                    </div>
                                  ) : null}
                                </div>
                                <Tooltip
                                  title={
                                    t('control_button.download_permission') ||
                                    'Download permission'
                                  }
                                  placement="bottom"
                                >
                                  <IconButton
                                    className={
                                      hasUserInGroup
                                        ? classes.cursorPointer
                                        : classes.noDrop
                                    }
                                    onClick={onOpenDownloadPermissionDialog}
                                  >
                                    <IBMSettingsCheck />
                                  </IconButton>
                                </Tooltip>
                              </div>
                              <div
                                className={classes.mainContentRightGroupSubTile}
                                onClick={() =>
                                  history.push({
                                    pathname: `${groupRoomPathName}`,
                                    search: `?chatroomId=${item.chatRoomId}&groupId=${item.id}`,
                                    state: {
                                      prevLocation: window.location.href
                                    }
                                  })
                                }
                              >
                                {t('detail_viewer.shared_time', {
                                  date: formatDateToString(item.createdAt)
                                })}
                              </div>
                            </div>
                          );
                        }
                      )}
                  </div>
                </div>

                {checkedFiles?.length > 0 && (
                  <div
                    className={clsx(
                      classes.shareButtonWrapper,
                      !checkedFiles?.every((file: any) => file.isFile) &&
                        'is-folder'
                    )}
                  >
                    <div className={classes.shareBlock}>
                      <div
                        className="share-button"
                        onClick={() => {
                          if (myDrive.detailDrive.isFile) onClickShare();
                        }}
                      >
                        <IBMShare />
                        <div className="share-text">
                          {t('detail_viewer.share')}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <Popover
                  className={classes.customPopover}
                  id={popoverId}
                  open={openPopover}
                  anchorEl={anchorEl}
                  onClose={handleClosePopUp}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                >
                  <div className={classes.csDialogContentPopup}>
                    {groupUser?.users &&
                      groupUser?.users
                        .slice(4, groupUser?.users?.length)
                        .map((avatar: any, index: number) => (
                          <Typography>
                            <div className="name" key={index}>
                              <CapaAvatar item={avatar} />
                              <div className="email" title={avatar.email}>
                                {getDisplayedAvatarString(
                                  avatar.connectUserName,
                                  avatar.email
                                )}
                              </div>
                            </div>
                          </Typography>
                        ))}
                  </div>
                </Popover>
              </>
            )}
          </>
        )}
      </div>

      <DownloadPermissionDialog
        onRefetchFileDetail={onRefetchFileDetail}
        groupData={groupDataState?.data}
      />
    </div>
  );
};

export default DetailFile;
