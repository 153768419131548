import React, { useState, useEffect } from 'react';
import ErrorNoticeImg from './assets/errorNotice.png';
import { isServiceNoticeCheck } from '@utils/notice';
import {
  STORAGE_ACTION,
  setStorageItem,
  getStorageItem,
  deleteStorageItem
} from '@utils/storage';
import { colorSet } from '@components/Provider';
import { CustomizedButton, CustomizedTypography } from '@components/Customized';
import {
  Dialog,
  DialogContent,
  useMediaQuery,
  useTheme,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '64px 32px 32px 32px',
    textAlign: 'center',
    zIndex: 3000,
    [theme.breakpoints.down('xs')]: {
      padding: '48px 24px 26px 24px'
    }
  },
  banner: {
    margin: 'auto',
    marginBottom: 32,
    width: 315,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 24,
      width: 215
    }
  },
  content: {
    padding: 0,
    '& b': {
      color: colorSet.gray900,
      '&:last-child': {
        color: colorSet.primary500
      }
    }
  },
  footer: {
    marginTop: 24,
    backgroundColor: colorSet.gray200,
    borderRadius: 4,
    height: 80,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 46,
    '& b': {
      color: colorSet.primary500
    }
  }
}));

const ServiceUrgentNoticePresenter: React.FC = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();
  const isServiceNotice = isServiceNoticeCheck();
  const isNotSeeingAgain = getStorageItem(
    STORAGE_ACTION.NOT_SEEING_AGAIN_URGENT_NOTICE
  );
  const [isOpen, setIsOpen] = useState(
    isNotSeeingAgain === 'true' || !isServiceNotice.isOpenUrgentNotice
      ? false
      : true
  );

  useEffect(() => {
    if (isNotSeeingAgain && !isServiceNotice.isOpenUrgentNotice) {
      deleteStorageItem(STORAGE_ACTION.NOT_SEEING_AGAIN_URGENT_NOTICE);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Dialog open={isOpen} classes={{ paper: classes.paper }}>
      <img className={classes.banner} src={ErrorNoticeImg} alt="notice" />
      <CustomizedTypography variant="h2" fontWeight="bold" gutterBottoms={16}>
        서비스 긴급 점검 안내
      </CustomizedTypography>
      <DialogContent classes={{ root: classes.content }}>
        <CustomizedTypography
          variant={matches ? 'body2' : 'body1'}
          fontWeight="500"
          color="textSecondary"
          gutterBottoms={matches ? 36 : 48}
        >
          서비스 접속지연 및 장애 현상이 발견되어 긴급 서버 대응 중이나
          {!matches && <br />}
          &nbsp;<b>서비스 이용이 불안정할 수 있습니다.</b>
          <br />
          이용에 불편을 드려 죄송하며, 신속하게 정상 서비스가 제공될 수 있도록
          최선을 다해 노력하겠습니다. 서비스 이용 중 불편하신 점 및 장애 사항은
          &nbsp;<b>우측하단 채널톡으로 문의</b>주시면 빠른 조치토록 하겠습니다.
        </CustomizedTypography>
      </DialogContent>
      <CustomizedButton
        variant="contained"
        color="primary"
        size="large"
        fullWidth
        onClick={() => {
          setIsOpen(false);
          setStorageItem(STORAGE_ACTION.NOT_SEEING_AGAIN_URGENT_NOTICE, 'true');
        }}
      >
        확인
      </CustomizedButton>
    </Dialog>
  );
};

export default ServiceUrgentNoticePresenter;
