import React from 'react';
import { colorSet } from '@components/Provider';
import {
  createMuiTheme,
  createStyles,
  makeStyles,
  Theme
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DataServiceItems, ItemProps } from '@routes/Home/types';

const themeLanding = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1024,
      xl: 1536
    }
  }
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerFriud: {
      maxWidth: '100%',
      overflow: 'hidden',
      paddingLeft: 32,
      paddingRight: 32,
      [themeLanding.breakpoints.down('md')]: {
        paddingLeft: 16,
        paddingRight: 16
      }
    },
    container: {
      width: '100%',
      maxWidth: '1024px',
      margin: 'auto',
      [themeLanding.breakpoints.down('md')]: {
        maxWidth: '960px'
      }
    },
    services: {
      padding: '160px 0',
      [themeLanding.breakpoints.down('md')]: {
        padding: '120px 0'
      }
    },
    titleServices: {
      fontWeight: 700,
      fontSize: 32,
      lineHeight: '48px',
      textAlign: 'center',
      whiteSpace: 'break-spaces',
      marginBottom: 70,
      letterSpacing: '-0.1px',
      color: colorSet.gray200,
      [themeLanding.breakpoints.down('xs')]: {
        fontSize: 25,
        lineHeight: '40px',
        textAlign: 'left'
      }
    },
    servicesList: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      gap: 40,
      [themeLanding.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr 1fr'
      },
      [themeLanding.breakpoints.down('xs')]: {
        gridTemplateColumns: '1fr'
      }
    },
    servicesItem: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column'
    },
    sercicesIcon: {
      marginBottom: 25,
      marginRight: '-10px'
    },
    servicesItemText: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column'
    },
    servicesItemTitle: {
      fontWeight: 500,
      fontSize: 18,
      marginBottom: 12,
      color: colorSet.primary450,
      whiteSpace: 'break-spaces',
      lineHeight: '25px'
    },
    servicesItemSubTitle: {
      fontWeight: 400,
      fontSize: 16,
      textAlign: 'center',
      marginBottom: 50,
      whiteSpace: 'break-spaces',
      color: colorSet.gray000,
      lineHeight: '24px'
    },
    imageReason: {
      margin: '0 auto',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      marginBottom: 48,
      justifyContent: 'center'
    }
  })
);

const Services = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.containerFriud}>
      <div className={classes.container}>
        <div className={classes.services}>
          <h2 className={classes.titleServices}>
            {t('landing_page.services_section.head_title')}
          </h2>
          <div className={classes.servicesList}>
            {DataServiceItems.map((item: ItemProps) => (
              <div className={classes.servicesItem} key={item.title}>
                <div className={classes.sercicesIcon}>
                  <img src={item.icon} alt={item.icon} />
                </div>
                <div className={classes.servicesItemText}>
                  <div className={classes.servicesItemTitle}>
                    {t(item.title)}
                  </div>
                  <div className={classes.servicesItemSubTitle}>
                    {t(item.subTitle)}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
