import { colorSet } from '@components/Provider';
import { createMuiTheme, makeStyles } from '@material-ui/core';

export const themeLanding = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1024,
      xl: 1536
    }
  }
});

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 32px',
    justifyContent: 'center',
    [themeLanding.breakpoints.down('sm')]: {
      margin: '0 16px'
    }
  },
  root: {
    maxWidth: 1024,
    margin: '50px 0',
    padding: 0,
    width: '100%',
    [themeLanding.breakpoints.down('md')]: {
      margin: '80px 32px 70px 32px'
    },
    [themeLanding.breakpoints.down('sm')]: {
      margin: '80px 16px 70px 16px'
    }
  },
  logo: {
    height: 24
  },
  mobileTabletWrapper: {
    marginBottom: 56
  },
  brandLogo: {
    marginBottom: 24,

    [themeLanding.breakpoints.down('xs')]: {
      marginBottom: 40
    }
  },

  brandName: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '150%',
    letterSpacing: '-0.1px',
    marginTop: 12,
    color: colorSet.gray000
  },

  timeSheet: {
    color: colorSet.gray700,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    whiteSpace: 'pre-line',
    marginBottom: 16
  },

  linkEmail: {
    color: colorSet.gray700,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '143%',
    letterSpacing: '-0.1px'
  },

  mediaLink: {
    color: colorSet.gray000,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '150%',
    letterSpacing: '-0.1px',
    display: 'inline-flex',
    marginBottom: 12,
    marginRight: -66,
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline'
    }
  },

  boxLink: {
    '& div': {
      minWidth: 205
    }
  },

  office: {
    color: colorSet.gray700,
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '20px',
    marginBottom: 6,
    whiteSpace: 'break-spaces'
  },

  contact: {
    color: colorSet.gray700,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '20px',
    whiteSpace: 'break-spaces'
  },

  iconBtn: {
    color: colorSet.gray000
  },
  instagramIcon: {
    paddingLeft: 0,
    color: colorSet.gray000,
    [themeLanding.breakpoints.down('md')]: {
      paddingLeft: 10
    }
  },

  iconSocialMobileWrapper: {
    margin: '80px 0 27px 0'
  },

  iconSocialMobile: {
    color: colorSet.gray900,
    background: colorSet.gray200,
    marginRight: 12,

    '&:hover': {
      color: colorSet.gray500
    },

    [themeLanding.breakpoints.down('xs')]: {
      '&:hover': {
        background: colorSet.gray200,
        color: colorSet.gray900
      }
    }
  },
  emptyBox: {
    height: 70
  },
  manual: {
    color: colorSet.gray900,
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '24px',
    marginBottom: 16
  },

  moreInfo: {
    color: colorSet.gray700,
    fontSize: 13,
    fontWeight: 400,
    lineHeight: '144%',
    marginTop: 32,
    letterSpacing: '-0.1px'
  }
}));
