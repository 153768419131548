import styled from 'styled-components';
import { colorSet } from '@components/Provider';
import { makeStyles } from '@material-ui/core';

interface IThumbnailProps {
  is_empty?: boolean;
  isInsertFromMyDrive?: boolean;
  width?: string;
}
export const ThumbnailStyle = styled.div.attrs(
  (props: IThumbnailProps) => props
)`
  overflow: hidden;
  cursor: pointer;
  box-sizing: border-box;
  width: ${(props) => (props.width ? props.width : '36px')};
  min-width: ${(props) => (props.width ? props.width : '36px')};
  height: ${(props) => (props.width ? props.width : '36px')};
  border: ${(props) =>
    props.is_empty ? 'none' : `1px solid ${colorSet.gray200}`};
  img {
    width: 100%;
    height: 100%;
  }
  border-radius: 4px;
  ${({ theme }) => `
    ${theme.breakpoints.down('xs')} {
      border-radius: ${(props: any) =>
        props.isInsertFromMyDrive ? '4px' : '100%'};
      border: ${(props: any) =>
        props.isInsertFromMyDrive
          ? `1px solid ${colorSet.gray200}`
          : `2px solid ${colorSet.gray000}`};
    }
  `}
`;

export const useLoadingStyles = makeStyles((theme) => ({
  wrapper: ({ isInsertFromMyDrive }: { isInsertFromMyDrive?: boolean }) => ({
    padding: 7,
    [theme.breakpoints.down('xs')]: {
      minWidth: !isInsertFromMyDrive ? 66 : 38,
      minHeight: !isInsertFromMyDrive ? 66 : 38
    }
  })
}));
