import React from 'react';
import { useParams } from 'react-router-dom';
import TermsPresenter from './TermsPresenter';

const TermsContainer = () => {
  const { urlParam } = useParams<{ urlParam?: string }>();

  return <TermsPresenter urlParam={urlParam} />;
};

export default TermsContainer;
