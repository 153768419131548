import { createAction } from 'typesafe-actions';
import { SharedWithGroupsState } from './types';

export const INIT_SHARED_WITH_GROUPS_STATE = 'INIT_SHARED_WITH_GROUPS_STATE';
export const initSharedWithGroupsState = createAction(
  INIT_SHARED_WITH_GROUPS_STATE
)();

export const SET_SELECTED_GROUP_FILES = 'SET_SELECTED_GROUP_FILES';
export const setSelectedGroupFiles = createAction(
  SET_SELECTED_GROUP_FILES
)<SharedWithGroupsState>();
