import React from 'react';
import clsx from 'clsx';
import { theme as globalTheme, colorSet } from '@components/Provider';
import { ICustomizedCheckBoxProps } from './types';
import {
  FormControlLabel,
  Checkbox,
  createMuiTheme,
  ThemeProvider
} from '@material-ui/core';
import { CustomizedSelect } from './styles';
import { ReactComponent as IBMCheckbox } from './../../assets/icon/IBMCheckbox.svg';
import { ReactComponent as IBMCheckboxChecked } from './../../assets/icon/IBMCheckboxChecked.svg';

const CustomizedCheckBox: React.FC<ICustomizedCheckBoxProps> = (props) => {
  const { onChange, size, gutterBottoms, fullwidth, color, label, ...rest } =
    props;
  let styled = {};
  styled = gutterBottoms ? { ...styled, marginBottom: gutterBottoms } : styled;
  styled = fullwidth ? { ...styled, width: '100%' } : styled;

  const theme = React.useMemo(() => {
    return createMuiTheme({
      ...globalTheme,
      overrides: {
        MuiFormControlLabel: {
          root: {
            margin: 0,
            marginRight: 0,
            marginLeft: 0
          }
        },
        MuiCheckbox: {
          root: {
            marginLeft: -9
          },
          colorSecondary: {
            '&.Mui-checked': {
              color: colorSet.gray900
            }
          }
        }
      }
    });
  }, []);

  const onChangeHandle = (isChecked: boolean) => {
    let data;
    if (isChecked) {
      data = props.values
        ? props.values.concat(props.value as string)
        : [props.value];
    } else {
      data = props.values
        ? props.values.filter((item) => item !== props.value)
        : [];
    }
    onChange(data);
  };

  return (
    <ThemeProvider theme={theme}>
      <CustomizedSelect>
        <FormControlLabel
          onClick={(e) => {
            e.stopPropagation();
          }}
          {...rest}
          className={clsx('customized-checkbox', props.className)}
          label={label ?? ''}
          checked={
            props.values ? props.values.includes(props.value as string) : false
          }
          style={{ ...styled }}
          control={
            <Checkbox
              size={size ?? 'medium'}
              color={color ?? 'primary'}
              checkedIcon={<IBMCheckboxChecked />}
              icon={<IBMCheckbox />}
            />
          }
          onChange={(e, checked) => onChangeHandle(checked)}
        />
      </CustomizedSelect>
    </ThemeProvider>
  );
};

export default CustomizedCheckBox;
