import React from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import Welcome from '@routes/Common/Dialog/Welcome/Welcome';
import WelcomeMobile from '@routes/Common/Dialog/Welcome/WelcomeMoblie';

interface Props {
  onClose?: () => void;
}
const WelcomeContainer = (props: Props) => {
  const { onClose } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  if (isMobile) return <WelcomeMobile />;

  return <Welcome onClose={onClose} />;
};

export default WelcomeContainer;
