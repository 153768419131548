import { makeStyles } from '@material-ui/core';
import { colorSet } from '@components/Provider';

export const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: 640,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: 0,
      height: '100%',
      maxHeight: '100%',
      maxWidth: '100%',
      borderRadius: 0
    },
    maxHeight: 593,
    borderRadius: '16px'
  },
  rootTitle: {
    padding: '24px 32px',
    display: 'flex',
    fontSize: '25px',
    fontWeight: 'bold',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: '12px 16px',
      border: `1px solid ${colorSet.gray300}`,
      display: 'inline-block',
      textAlign: 'center',
      fontSize: 16,
      fontWeight: 500
    }
  },
  closeIcon: {
    color: colorSet.gray900,
    fontWeight: 500,
    position: 'absolute',
    left: 16,
    top: 10
  },
  customTitle: {
    [theme.breakpoints.down('xs')]: {
      color: colorSet.gray900,
      fontWeight: 500
    }
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  content: {
    padding: '0px 24px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px 12px'
    }
  },
  csDialogText: {
    color: colorSet.gray700,
    fontSize: '16px'
  },
  csDialogContent: {
    padding: '0 0 24px 0',
    marginLeft: 32,
    marginRight: 32,
    borderBottom: `1px solid ${colorSet.gray400}`,
    [theme.breakpoints.down('xs')]: {
      padding: '24px 16px',
      margin: 0
    }
  },
  iconWrapper: {
    display: 'inline-block',
    verticalAlign: 'middle',
    padding: '9.5px 10px',
    borderRadius: '8px',
    marginRight: '20px',
    backgroundColor: colorSet.gray100
  },
  settingsOutlinedWrapper: {
    backgroundColor: colorSet.primary500,
    borderRadius: 12,
    marginRight: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 44,
    height: 44
  },
  arrowBackIosOutlinedWrapper: {
    backgroundColor: colorSet.primary500,
    borderRadius: 12,
    padding: '9px 15px',
    marginRight: 16
  },
  arrowBackIosOutlinedIcon: {
    width: 20,
    color: colorSet.gray100,
    [theme.breakpoints.down('xs')]: {
      color: colorSet.gray900,
      fontWeight: 500,
      position: 'absolute',
      left: 16,
      top: 10
    }
  },
  settingsOutlinedIcon: {
    color: colorSet.gray100
  },
  pointerCursor: {
    cursor: 'pointer'
  },
  groupInfo: {
    color: colorSet.gray700,
    fontSize: 16,
    paddingBottom: 24,
    borderBottom: `1px solid ${colorSet.gray200}`,
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 24
  },
  memberList: {
    maxHeight: 410,
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: 4
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: colorSet.gray600,
      borderRadius: 8
    },
    [theme.breakpoints.down('xs')]: {
      maxHeight: 'unset',
      height: '100%'
    }
  },
  memberWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 26,
    '&:first-child': {
      marginTop: 0
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 20
    }
  },
  stringAvatar: {
    marginRight: 12,
    marginLeft: 12,
    textTransform: 'uppercase'
  },
  memberEmail: {
    color: colorSet.gray700
  },
  memberNameWrapper: {
    display: 'flex',
    '& .MuiAvatar-circle': {
      marginRight: 12
    }
  },
  memberEmailName: {
    display: 'grid'
  },
  memberStatus: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  managerName: {
    fontWeight: 500
  },
  allowedText: {
    fontSize: 13
  },
  notAllowedText: {
    fontSize: 13,
    color: colorSet.gray500
  }
}));
