import { makeStyles } from '@material-ui/core';
import { colorSet } from '@components/Provider';

export const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: 480,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      minWidth: 343
    },
    borderRadius: '16px'
  },
  rootTitle: {
    padding: '56px 32px 12px 32px',
    display: 'flex',
    fontSize: '20px',
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
      marginBottom: 15,
      padding: '12px 16px',
      alignItems: 'center'
    }
  },
  rootClose: {
    display: 'flex',
    borderBottom: `1px solid ${colorSet.gray300}`,
    padding: 10,
    margin: -23
  },
  subTitle: {
    fontSize: 25,
    fontWeight: 'bold',
    textAlign: 'center',
    width: '100%',
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: 16
    }
  },
  titleIcon: {
    cursor: 'pointer',
    width: '44px',
    height: '44px',
    background: `${colorSet.primary450}`,
    borderRadius: 12,
    marginRight: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      fontSize: 26,
      color: `${colorSet.gray000}`
    }
  },
  name: {
    fontSize: 25,
    color: `${colorSet.gray900}`
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  cancelBtn: {
    fontSize: 32,
    marginLeft: 'auto',
    cursor: 'pointer'
  },
  content: {
    padding: '0px 32px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px 12px'
    }
  },
  action: {
    padding: '56px 0 24px 0',
    [theme.breakpoints.down('xs')]: {
      margin: 0,
      padding: 16
    }
  },
  wrapperBtn: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    '& button': {
      textTransform: 'none',
      height: '48px !important',
      width: '50%',
      '&:first-child': {
        marginRight: 8
      }
    }
  },
  csDialogContent: {
    textAlign: 'center',
    overflow: 'hidden',
    '& .icon-wrapper': {
      width: 64,
      height: 64,
      borderRadius: 100,
      margin: 'auto',
      [theme.breakpoints.down('xs')]: {
        marginTop: 88
      }
    },
    '& .done-icon-wrapper': {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    '& .done-icon': {
      width: 40,
      height: 40,
      color: colorSet.gray000
    },
    '& .name-email': {
      fontSize: 20,
      color: `${colorSet.gray900}`,
      '& .text-bold-en': {
        fontWeight: 700,
        marginTop: 15
      },
      '& .text-bold-ko': {
        fontWeight: 700,
        marginBottom: 15
      }
    },
    '& .MuiOutlinedInput-input': {
      border: `1px solid ${colorSet.gray400}`,
      borderRadius: 8
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent !important'
    },
    padding: '0 24px',
    [theme.breakpoints.down('xs')]: {
      padding: '0 24px 32px 24px'
    }
  }
}));
