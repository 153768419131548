import { ActionType } from 'typesafe-actions';
import * as actions from './actions';
export interface RecommendedContactsState {
  isRecommendedContactsPageLoading?: boolean;
}

export const initialRecommendedContactsState: RecommendedContactsState = {
  isRecommendedContactsPageLoading: false
};

export type RecommendedContactsAction = ActionType<typeof actions>;
