import { makeStyles } from '@material-ui/core';
import { colorSet } from '@components/Provider';

export const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: 'none',
    maxWidth: 480,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: 0,
      height: '100%',
      maxHeight: '100%',
      maxWidth: '100%',
      borderRadius: 0
    },
    maxHeight: 640,
    borderRadius: '16px'
  },
  rootTitle: {
    padding: '24px',
    display: 'flex',
    fontSize: '20px !important',

    [theme.breakpoints.down('xs')]: {
      padding: '12px 16px',
      border: `1px solid ${colorSet.gray300}`,
      display: 'inline-block',
      textAlign: 'center',
      fontSize: 16
    }
  },
  customTitle: {
    [theme.breakpoints.down('xs')]: {
      color: colorSet.gray900,
      fontWeight: 500
    }
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  cancelBtn: {
    fontSize: 24,
    marginLeft: 'auto',
    cursor: 'pointer'
  },
  content: {
    padding: '0px 24px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px 12px'
    }
  },
  action: {
    marginTop: 30,
    padding: '12px 32px 24px 32px !important'
  },
  wrapperBtn: {
    display: 'flex',
    '& button': {
      width: '100%',
      textTransform: 'none'
    },
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      justifyContent: 'center',

      '& button': {
        width: 'calc(100% / 2)'
      },

      '& button + button': {
        marginLeft: 6
      }
    }
  },
  csDialogText: {
    color: colorSet.gray700,
    fontSize: '16px'
  },
  csDialogContent: {
    padding: 0,
    fontSize: 14,
    marginLeft: 32,
    marginRight: 32,
    overflow: 'unset',

    [theme.breakpoints.down('xs')]: {
      padding: '24px',
      margin: 0
    },
    '& li': {
      paddingLeft: 12,
      '&::marker': {
        fontSize: 11
      }
    }
  },

  marginBottomText: {
    marginBottom: 8,
    fontSize: 16,
    color: colorSet.gray700,
    wordBreak: 'break-word',
    lineHeight: '150%'
  },

  marginTextContent: {
    fontSize: 16,
    color: colorSet.gray700,
    wordBreak: 'break-word',
    lineHeight: '150%'
  },
  firstTitle: {
    wordBreak: 'break-word',
    fontSize: 25,
    fontWeight: 'bold',
    marginRight: 6,

    '& .sub-title': {
      marginTop: 10,
      display: 'block',
      wordBreak: 'break-word',
      lineHeight: '1.3'
    }
  },
  checkboxRemove: {
    display: 'flex',
    marginTop: -12,
    alignItems: 'flex-end'
  },
  removeAllMyFiles: {
    position: 'relative',
    top: 20
  },
  removeFileSubTitle: {
    paddingTop: 6,
    lineHeight: 1.2
  }
}));
