import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { CustomizedButton, CustomizedTypography } from '@components/Customized';
import { Drawer, Box } from '@material-ui/core';
import { IBMAdd } from '@assets';
import { useContactsAsideMenuPresenterStyles } from '@routes/Contacts/styles';
import { CapaActivityType } from '@generated/Common/types';
import { groupPathName } from '@routes/Common/types';
import { useTranslation } from 'react-i18next';
import useUserActionLog, {
  Enum_Action_Button_Enum
} from '@hooks/useUserActionLog';
import LinkDownloadApp from '@components/LinkDownloadApp';
import { ReactComponent as IBMChat } from './../../../assets/icon/IBMChat.svg';

interface Props {
  clickNewGroup?: () => void;
}

const AsideMenuPresenter = (props: Props) => {
  const { clickNewGroup } = props;
  const { t } = useTranslation();
  const classes = useContactsAsideMenuPresenterStyles();
  const { pathname } = useLocation<any>();
  const history = useHistory();
  const [activeLeftMenu, setActiveLeftMenu] = useState(pathname);
  const { onInsertUserActionLogOne } = useUserActionLog();

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      open={true}
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <div className={classes.drawerHeaderMargin} />
      <div className={clsx(classes.drawerContentButton)}>
        <CustomizedButton
          className={classes.newButton}
          aria-haspopup="true"
          variant="contained"
          color="primary"
          size="medium"
          startIcon={<img src={IBMAdd} alt="" />}
          onClick={clickNewGroup}
        >
          {t('group.new_group')}
        </CustomizedButton>
      </div>
      <Box
        className={clsx(
          classes.drawerContentItem,
          activeLeftMenu.split('/')[1] === groupPathName.split('/')[1]
            ? 'selected'
            : ''
        )}
        onClick={() => {
          onInsertUserActionLogOne(
            Enum_Action_Button_Enum.Button_MyDrive_LNB_MyDrive,
            CapaActivityType.ButtonClick
          );
          history.push(groupPathName);
          setActiveLeftMenu(groupPathName);
        }}
      >
        <IBMChat
          className={
            activeLeftMenu.split('/')[1] === groupPathName.split('/')[1]
              ? classes.IBMChatIconActive
              : classes.IBMChatIcon
          }
        />
        <CustomizedTypography
          className={clsx(classes.drawerContentItemText)}
          variant="body1"
          fontWeight="500"
          color={
            activeLeftMenu.split('/')[1] === groupPathName.split('/')[1]
              ? 'primary'
              : 'textPrimary'
          }
        >
          {t('group.groups')}
        </CustomizedTypography>
      </Box>
      <LinkDownloadApp />
    </Drawer>
  );
};

export default AsideMenuPresenter;
