import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Backdrop,
  Popper,
  useMediaQuery,
  useTheme,
  Fab
} from '@material-ui/core';
import {
  ContextMenuTable,
  MuiBoxTableItem,
  useStyles
} from '@routes/Dashboard/styles';
import { CustomizedTypography } from '@components/Customized';
import useSharedWithGroups from '@hooks/Drive/MyDrive/useSharedWithGroups';
import { formatFileSize, formatTimeStamp } from '@utils/formatter';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import ControlButtonList from '@routes/SharedWithGroups/ControlButtonList';
import { MoreVertOutlined, Add } from '@material-ui/icons';
import { IS_MAC_OS } from '@routes/Common/utils';
import { MuiBoxTableShareItemMobile } from './styles';
import NoData from './NoData';
import { useTranslation } from 'react-i18next';
import {
  chatBotEmail,
  CONNECT_UPDATE_TYPE,
  groupRoomPathName,
  sharedWithGroupsPathName
} from '@routes/Common/types';
import { Dropzone } from '@routes/Common';
import useDashboard from '@hooks/useDashboard';
import useMyDrive from '@hooks/Drive/MyDrive/useMyDrive';
import useUserActionLog, {
  CapaActivityType,
  Enum_Action_Button_Enum
} from '@hooks/useUserActionLog';
import IconThumbnai from '@components/IconThumbnail/IconThumbnail';
import DownloadPermissionDialog from '@routes/Dashboard/Dialog/DownloadPermission/DownloadPermission';
import { getStorageItem, STORAGE_ACTION } from '@utils/storage';
import { FILE_OWNER_IS_USER_LOGGED_IN } from './types';
import Loading from '@components/LoadingInComponent';
import { CapaErrorCode } from '@generated/Common/graphql';
import { getGroupNameByUsernameOrEmail } from '@utils/common';
import { convertSubstring } from '@utils/file';
import { DRAWING_FILE_TYPES } from '@routes/Common/Dropzone/types';
import { IBMCAD } from '@assets';
import clsx from 'clsx';
import useGroupRoom from '@hooks/GroupRoom/useGroupRoom';

interface Props {
  parentCallback?: (
    groupName: any,
    isChatBotGroup: boolean,
    isLoading: boolean
  ) => void;
  setActionStatus?: () => void;
  isActionStatus?: boolean;
  uploadFileToGroupCallback?: (fileIds: any) => void;
  subscribeConnectUpdateDataType?: any;
}

const EnteredGroupList: React.FC<Props> = ({
  parentCallback,
  isActionStatus,
  setActionStatus,
  uploadFileToGroupCallback,
  subscribeConnectUpdateDataType
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [isOpenPopup, setOpenPopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const onClose = () => setOpen(false);
  const history = useHistory();
  const { pathname } = useLocation<any>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();
  const userInfo = getStorageItem(STORAGE_ACTION.USER_INFO);
  const { groupDataFilesState, onFetchGroupFilesData } = useGroupRoom();

  const onClosePopup = () => {
    setOpenPopup(false);
    setOpen(false);
  };

  const {
    sharedWithGroups,
    groupDataState,
    onFetchGroupData,
    onSetSelectedGroupFiles
  } = useSharedWithGroups();
  const hasGroupIdPath = pathname.split('/').length > 2;
  const groupFileContextMenuId = 'group-file-context-menu-trigger';
  const { onUpdateDashboard } = useDashboard();
  const { onSetShowHideFileDetail } = useMyDrive();
  const { onInsertUserActionLogOne } = useUserActionLog();

  const hideContextMenu = () => {
    const contextMenu: any = document.querySelector('.react-contextmenu');
    contextMenu.style.opacity = '0';
    contextMenu.style.display = 'none';
    contextMenu.style.pointerEvents = 'none';
  };

  const onConTextMenu = (file: any) => {
    const contextMenu: any = document.querySelector('.react-contextmenu');
    contextMenu.style.display = 'block';
    if (
      sharedWithGroups.selectedGroupFiles.filter(
        (groupFile: any) => groupFile.id === file.id
      ).length > 0
    ) {
      return;
    }

    onSetSelectedGroupFiles({
      selectedGroupFiles: [...[file]]
    });
  };

  const onClickMuiBoxTableItem = (
    e: any,
    isChecked: boolean,
    id: string,
    file: any
  ) => {
    if ((e.metaKey && IS_MAC_OS()) || e.ctrlKey) {
      if (isChecked) {
        onSetSelectedGroupFiles({
          selectedGroupFiles: sharedWithGroups.selectedGroupFiles.filter(
            (groupFile: any) => groupFile.id !== id
          )
        });
      } else {
        onSetSelectedGroupFiles({
          selectedGroupFiles: [...sharedWithGroups.selectedGroupFiles, file]
        });
      }
      return;
    }

    if (e.shiftKey) {
      if (!isChecked)
        onSetSelectedGroupFiles({
          selectedGroupFiles: [...[file]]
        });
      let indexOfNew = groupDataFilesState
        ?.data!.map((file: any) => file.id)
        .indexOf(file.id);
      let indexOfLast = groupDataFilesState
        ?.data!.map((file: any) => file.id)
        .indexOf(
          sharedWithGroups.selectedGroupFiles[
            sharedWithGroups.selectedGroupFiles.length - 1
          ]
            ? sharedWithGroups.selectedGroupFiles[
                sharedWithGroups.selectedGroupFiles.length - 1
              ].id
            : file.id
        );

      const isSelectingForwards = indexOfNew! > indexOfLast!;
      const start = isSelectingForwards ? indexOfLast : indexOfNew;
      const end = isSelectingForwards ? indexOfNew : indexOfLast;
      onSetSelectedGroupFiles({
        selectedGroupFiles: groupDataFilesState?.data?.slice(start, end! + 1)
      });
      return;
    }

    onSetSelectedGroupFiles({
      selectedGroupFiles: [...[file]]
    });
  };

  const onFetchGroup = () => {
    onFetchGroupData({
      variables: {
        groupId: pathname.split('/')[2]
      }
    });
    onFetchGroupFilesData({
      variables: {
        groupId: pathname.split('/')[2]
      }
    });
  };

  useEffect(() => {
    if (isActionStatus) {
      onFetchGroup();
    }
  }, [isActionStatus]);

  useEffect(() => {
    if (hasGroupIdPath) {
      onFetchGroup();
    }
  }, [hasGroupIdPath]);

  useEffect(() => {
    if (
      groupDataState?.result === CapaErrorCode.GroupNotExists ||
      groupDataState?.result === CapaErrorCode.QueryError
    ) {
      history.push(`${sharedWithGroupsPathName}`);
      return;
    }
    if (groupDataState) {
      const groupName: any = groupDataState?.data?.groupName
        ? groupDataState?.data?.groupName
        : getGroupNameByUsernameOrEmail(groupDataState?.data?.users);
      const isChatBotGroup: boolean =
        groupDataState &&
        groupDataState.data &&
        groupDataState.data.users.some(
          (user: any) => user.email === chatBotEmail
        );
      parentCallback &&
        parentCallback(groupName, isChatBotGroup, groupDataState?.isLoading);
      if (setActionStatus) {
        setActionStatus();
      }
    }
  }, [groupDataState]);

  useEffect(() => {
    return () => {
      onSetSelectedGroupFiles({
        selectedGroupFiles: []
      });
    };
  }, []);

  useEffect(() => {
    switch (subscribeConnectUpdateDataType) {
      case CONNECT_UPDATE_TYPE.MEMBER_UPDATE:
      case CONNECT_UPDATE_TYPE.GROUP_UPDATE: {
        onFetchGroup();
        break;
      }
    }
  }, [subscribeConnectUpdateDataType]);

  const renderFileOwner = (file: any) => {
    if (file.email === userInfo.email) return FILE_OWNER_IS_USER_LOGGED_IN;
    return file.connectUserName;
  };

  return (
    <>
      {!isMobile ? (
        <div>
          {groupDataFilesState && groupDataFilesState?.data === null ? (
            <NoData
              title={t('shared_with_groups.no_data.upload_your_files')}
              subTitle={[t('shared_with_groups.no_data.this_folder_is_empty')]}
              isSharedWithGroup={false}
            />
          ) : (
            <>
              <div className="table-header">
                <CustomizedTypography
                  className="table-header-th-file-name"
                  variant="body2"
                  color="textSecondary"
                >
                  {t('shared_with_groups.file_name')}
                </CustomizedTypography>
                <CustomizedTypography
                  className="table-header-th-file-owner"
                  variant="body2"
                  color="textSecondary"
                >
                  {t('shared_with_groups.file_owner')}
                </CustomizedTypography>
                <CustomizedTypography
                  className="table-header-th-shared-date"
                  variant="body2"
                  color="textSecondary"
                >
                  {t('shared_with_groups.shared_date')}
                </CustomizedTypography>
                <CustomizedTypography
                  className="table-header-th-file-size"
                  variant="body2"
                  color="textSecondary"
                >
                  {t('shared_with_groups.file_size')}
                </CustomizedTypography>
              </div>

              <div className={classes.dropzoneWrapper}>
                {groupDataFilesState && groupDataFilesState?.data === null ? (
                  <Loading
                    open={true}
                    styles={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: 40
                    }}
                  />
                ) : (
                  groupDataFilesState?.data?.map((file: any, index: number) => {
                    const isChecked =
                      sharedWithGroups.selectedGroupFiles.filter(
                        (groupFile: any) => groupFile.id === file.id
                      ).length > 0;
                    return (
                      <ContextMenuTrigger
                        id={groupFileContextMenuId}
                        key={index}
                      >
                        <MuiBoxTableItem
                          key={index}
                          isChecked={isChecked}
                          onContextMenu={() => {
                            onInsertUserActionLogOne(
                              Enum_Action_Button_Enum.RightClick_SharedDrive_GroupFolder_File,
                              CapaActivityType.ButtonClick
                            );
                            onConTextMenu(file);
                          }}
                          onClick={(e) => {
                            onInsertUserActionLogOne(
                              Enum_Action_Button_Enum.Click_SharedDrive_GroupFolder_File,
                              CapaActivityType.ButtonClick
                            );
                            hideContextMenu();
                            onClickMuiBoxTableItem(e, isChecked, file.id, file);
                          }}
                          onDoubleClick={() => {
                            onInsertUserActionLogOne(
                              Enum_Action_Button_Enum.DoubleClick_SharedDrive_GroupFolder_File,
                              CapaActivityType.ButtonClick
                            );
                            onUpdateDashboard({
                              files: [sharedWithGroups.selectedGroupFiles[0]]
                            });
                            onSetShowHideFileDetail({
                              detailDrive:
                                sharedWithGroups.selectedGroupFiles[0]
                            });
                            history.push({
                              pathname: `${groupRoomPathName}`,
                              search: `?chatroomId=${groupDataState?.data?.chatRoomId}&groupId=${groupDataState?.data?.id}`,
                              state: { prevLocation: window.location.href }
                            });
                          }}
                        >
                          <div className="table-item-td-file-name">
                            <IconThumbnai file={file} size="small" />
                            {convertSubstring(DRAWING_FILE_TYPES).includes(
                              file?.originalFileExt?.toLocaleLowerCase()
                            ) &&
                              file?.isFile && (
                                <img
                                  src={IBMCAD}
                                  alt=""
                                  className="ibm-cad-icon"
                                />
                              )}
                            <CustomizedTypography variant="body2" noWrap>
                              {file.fileName}
                            </CustomizedTypography>
                          </div>
                          <div className="table-item-td-file-owner">
                            <CustomizedTypography
                              variant="body2"
                              className="access-level"
                            >
                              {renderFileOwner(file)}
                            </CustomizedTypography>
                          </div>
                          <div className="table-item-td-shared-date">
                            <CustomizedTypography variant="body2">
                              {formatTimeStamp(
                                file.createdAt,
                                true,
                                false,
                                false,
                                true
                              )}
                            </CustomizedTypography>
                          </div>
                          <div className="table-item-td-file-size">
                            <CustomizedTypography variant="body2">
                              {formatFileSize(file.fileSize).toUpperCase()}
                            </CustomizedTypography>
                          </div>
                        </MuiBoxTableItem>
                      </ContextMenuTrigger>
                    );
                  })
                )}
              </div>
              <ContextMenuTable>
                <ContextMenu id={groupFileContextMenuId}>
                  <MenuItem>
                    <ControlButtonList
                      showIcon={true}
                      isShowPopUp={true}
                      refetchGroup={() => onFetchGroup()}
                    />
                  </MenuItem>
                </ContextMenu>
              </ContextMenuTable>
            </>
          )}
        </div>
      ) : (
        <div className={classes.uploadButtonMobileWrapper}>
          <div className={classes.buttonUploadMobile}>
            <Dropzone callback={uploadFileToGroupCallback}>
              <Fab
                className="button-action"
                color="primary"
                aria-label="add"
                onClick={() => {
                  onInsertUserActionLogOne(
                    Enum_Action_Button_Enum.Button_SharedDrive_New,
                    CapaActivityType.ButtonClick
                  );
                }}
              >
                <Add />
              </Fab>
            </Dropzone>
          </div>

          <div>
            {groupDataFilesState && groupDataFilesState?.data === null ? (
              <NoData
                title={t('shared_with_groups.no_data.upload_your_files')}
                subTitle={[
                  t('shared_with_groups.no_data.you_can_upload_files'),
                  t('shared_with_groups.no_data.and_share')
                ]}
                isSharedWithGroup={false}
              />
            ) : (
              groupDataFilesState?.data?.map((file: any, index: number) => {
                const isChecked =
                  sharedWithGroups.selectedGroupFiles.filter(
                    (groupFile: any) => groupFile.id === file.id
                  ).length > 0;
                return (
                  <ContextMenuTrigger id={groupFileContextMenuId} key={index}>
                    <MuiBoxTableShareItemMobile
                      key={index}
                      onContextMenu={() => {
                        onInsertUserActionLogOne(
                          Enum_Action_Button_Enum.RightClick_SharedDrive_GroupFolder_File,
                          CapaActivityType.ButtonClick
                        );
                        onConTextMenu(file);
                      }}
                      onClick={(e) => {
                        onInsertUserActionLogOne(
                          Enum_Action_Button_Enum.Click_SharedDrive_GroupFolder_File,
                          CapaActivityType.ButtonClick
                        );
                        hideContextMenu();
                        onClickMuiBoxTableItem(e, isChecked, file.id, file);
                      }}
                      onDoubleClick={() => {
                        onInsertUserActionLogOne(
                          Enum_Action_Button_Enum.DoubleClick_SharedDrive_GroupFolder_File,
                          CapaActivityType.ButtonClick
                        );
                        onUpdateDashboard({
                          files: [sharedWithGroups.selectedGroupFiles[0]]
                        });
                        onSetShowHideFileDetail({
                          detailDrive: sharedWithGroups.selectedGroupFiles[0]
                        });
                        history.push({
                          pathname: `${groupRoomPathName}`,
                          search: `?chatroomId=${groupDataState?.data?.chatRoomId}&groupId=${groupDataState?.data?.id}`,
                          state: { prevLocation: window.location.href }
                        });
                      }}
                    >
                      <button className="button-long-press">
                        <div className={classes.thumbnailMobile}>
                          <IconThumbnai file={file} size="medium" />
                        </div>
                        <div className="table-content">
                          <CustomizedTypography
                            component={'div'}
                            className="file-name"
                            variant="body2"
                            noWrap
                          >
                            {convertSubstring(DRAWING_FILE_TYPES).includes(
                              file?.originalFileExt?.toLocaleLowerCase()
                            ) &&
                              file?.isFile && (
                                <img
                                  src={IBMCAD}
                                  alt=""
                                  className="ibm-cad-icon"
                                />
                              )}
                            <div className="cs-file-name">{file.fileName}</div>
                          </CustomizedTypography>
                          <CustomizedTypography
                            variant="caption"
                            color="textSecondary"
                            gutterBottoms={2}
                          >
                            {formatFileSize(file.fileSize).toUpperCase()}
                          </CustomizedTypography>
                          <CustomizedTypography
                            variant="caption"
                            color="textSecondary"
                          >
                            <div className="table-item-share-mobile">
                              <div>
                                {formatTimeStamp(
                                  new Date(file.createdAt),
                                  true,
                                  false,
                                  false,
                                  true
                                )}
                              </div>
                            </div>
                          </CustomizedTypography>
                        </div>
                        <div>
                          <MoreVertOutlined
                            className={classes.moreVertIcon}
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              onSetSelectedGroupFiles({
                                selectedGroupFiles: [file]
                              });
                              if (e.currentTarget) {
                                setOpenPopup(true);
                                setAnchorEl(e.currentTarget);
                                setOpen(!open);
                              }
                            }}
                          />
                        </div>
                      </button>
                    </MuiBoxTableShareItemMobile>
                  </ContextMenuTrigger>
                );
              })
            )}
          </div>
          <Backdrop
            className={clsx(
              isOpenPopup ? 'back-drop' : 'back-drop d-none',
              classes.zIndexBackdrop
            )}
            open={open}
            onClick={() => {
              onClose();
            }}
          />
          <Popper
            className={
              isOpenPopup
                ? classes.rootPopupMobile
                : `${classes.rootPopupMobile} d-none`
            }
            open={open}
            anchorEl={anchorEl}
            transition
            placement="bottom-end"
          >
            <div className={classes.contentpopupMobile}>
              <ControlButtonList
                isShowPopUp={true}
                showIcon={true}
                onClosePoup={onClosePopup}
                onClose={onClose}
                refetchGroup={() => onFetchGroup()}
              />
            </div>
          </Popper>
          <ContextMenuTable>
            <ContextMenu id={groupFileContextMenuId}>
              <MenuItem>
                <ControlButtonList
                  isShowPopUp={true}
                  refetchGroup={() => onFetchGroup()}
                />
              </MenuItem>
            </ContextMenu>
          </ContextMenuTable>
        </div>
      )}
      <DownloadPermissionDialog groupData={groupDataState?.data} />
    </>
  );
};

export default EnteredGroupList;
