import React from 'react';
import { useStyles } from './styles';
import { IBMAttachment, IBMCAD } from '@assets';
import { convertSubstring } from '@utils/file';
import { DRAWING_FILE_TYPES } from '@routes/Common/Dropzone/types';

interface Props {
  checkedFiles?: any;
  isNewGroup?: boolean;
}

const Attachments = (props: Props) => {
  const { checkedFiles, isNewGroup } = props;
  const classes = useStyles({ isNewGroup });

  return (
    <div className={classes.attachments}>
      {checkedFiles?.length > 0
        ? checkedFiles.map((file: any, index: number) => {
            return (
              <div className={classes.attachment} key={index}>
                <img
                  src={IBMAttachment}
                  className={classes.attachmentIcon}
                  alt=""
                />
                {convertSubstring(DRAWING_FILE_TYPES).includes(
                  file?.originalFileExt?.toLocaleLowerCase()
                ) && <img src={IBMCAD} alt="" className="ibm-cad-icon" />}
                <div className="file-name">{file?.fileName}</div>
              </div>
            );
          })
        : null}
    </div>
  );
};

export default Attachments;
