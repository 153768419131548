import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import clsx from 'clsx';
import LinkSettings from '@routes/Dashboard/Dialog/Share/ShareLink/LinkSettings';
import Attachments from '@routes/Dashboard/Dialog/Share/ShareLink/Attachments';
import { LINK_STATUS } from '@routes/Dashboard/Dialog/Share/ShareLink/types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ReactComponent as IBMClose } from './../../../../../assets/icon/IBMClose.svg';
import { useMutation } from '@apollo/client';
import { UpdateConnectUserFileLinkStatus } from '@generated/Drive/MyDrive/graphql';
import { CapaErrorCode } from '@generated/Common/graphql';
import { CapaActivityType } from '@generated/Common/types';
import Loading from '@components/LoadingInComponent';
import { filesPathName, myDrivePathName } from '@routes/Common/types';
import { useLocation } from 'react-router-dom';
import useUserActionLog, {
  Enum_Action_Button_Enum
} from '@hooks/useUserActionLog';
import useMyDrive from '@hooks/Drive/MyDrive/useMyDrive';

interface Props {
  leftHeader?: React.ReactNode;
  isShowActivateLink?: boolean;
  isShowAttachmentsTitle?: boolean;
  isNewGroup?: boolean;
  checkedFiles?: any;
  myDrivefiles?: any;
  fileOwner?: any;
  setPermission?: (permission: any) => void;
}

const SNACKBAR_TIME_OUT = 5000;

const ShareLink = (props: Props) => {
  const {
    leftHeader,
    isShowActivateLink,
    isShowAttachmentsTitle,
    isNewGroup,
    checkedFiles,
    myDrivefiles,
    fileOwner,
    setPermission
  } = props;
  // @ts-ignore
  const classes = useStyles({ isNewGroup });
  const { t } = useTranslation();
  const [isShowSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [
    updateConnectUserFileLinkStatus,
    { data: connectUserFileLink, loading: isLoading }
  ] = useMutation(UpdateConnectUserFileLinkStatus, {
    fetchPolicy: 'no-cache'
  });
  const { onSetShowHideFileDetail, onRefetchConnectMyDrive } = useMyDrive();
  const { pathname } = useLocation<any>();
  const pathNameUrl = pathname.split(`${myDrivePathName}/`);
  const linkStatusOfCheckedFile: any =
    checkedFiles === undefined
      ? null
      : myDrivefiles?.find((file: any) => file?.id === checkedFiles[0]?.id)
          ?.linkStatus ?? null;
  const [isActivateLink, setActivateLinkStatus] = useState<boolean>(
    linkStatusOfCheckedFile === LINK_STATUS.ACTIVE
  );
  const { onInsertUserActionLogOne } = useUserActionLog();
  const onClickActivateLink = () => {
    onInsertUserActionLogOne(
      !isActivateLink
        ? Enum_Action_Button_Enum.Button_ShareModal_activeLink
        : Enum_Action_Button_Enum.Button_ShareModal_deactiveLink,
      CapaActivityType.ButtonClick
    );
    setActivateLinkStatus(!isActivateLink);
  };

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  const onUpdateConnectUserFileLinkStatus = (callBack?: () => void) => {
    if (isNewGroup) return;
    const isLinkStatusOfFile = linkStatusOfCheckedFile === LINK_STATUS.ACTIVE;
    if (isLinkStatusOfFile === isActivateLink) return;
    updateConnectUserFileLinkStatus({
      variables: {
        connectFileIds: checkedFiles.map((file: any) => file.id) || [],
        linkStatus: isActivateLink ? LINK_STATUS.ACTIVE : LINK_STATUS.DEACTIVE
      }
    }).then((res: any) => {
      if (
        res?.data?.updateConnectUserFileLinkStatus?.result ===
        CapaErrorCode.Success
      ) {
        callBack && callBack();
      }
    });
  };

  const refetchConnectMyDrive = () => {
    onRefetchConnectMyDrive({
      pathId: pathNameUrl[1] ? pathNameUrl[1] : ''
    }).then((res: any) => {
      if (res?.data?.fetchConnectMyDrive?.result === CapaErrorCode.Success) {
        onSetShowHideFileDetail({
          detailDrive: res?.data?.fetchConnectMyDrive?.data?.files?.find(
            (file: any) => file?.id === checkedFiles[0]?.id
          )
        });
      }
    });
  };

  useEffect(() => {
    if (isShowSnackbar) {
      setTimeout(() => {
        setShowSnackbar(false);
      }, SNACKBAR_TIME_OUT);
    }
  }, [isShowSnackbar]);

  useEffect(() => {
    onUpdateConnectUserFileLinkStatus(refetchConnectMyDrive);
  }, [isActivateLink]);

  useEffect(() => {
    onUpdateConnectUserFileLinkStatus();
  }, []);

  const connectUserFileLinkData = useMemo(() => {
    if (
      connectUserFileLink?.updateConnectUserFileLinkStatus?.result ===
      CapaErrorCode.Success
    ) {
      const shareLink = JSON.parse(
        connectUserFileLink?.updateConnectUserFileLinkStatus?.data?.shareLink
      );
      return {
        data:
          {
            ...connectUserFileLink?.updateConnectUserFileLinkStatus?.data,
            link: `${window.location.origin}${filesPathName}/${shareLink?.join(
              ','
            )}`
          } ?? null
      };
    }

    return {
      data: {
        filePermission: myDrivefiles?.filter(
          (file: any) => file?.id === checkedFiles[0]?.id
        )[0]?.filePermission,
        link: `${window.location.origin}${filesPathName}/${checkedFiles[0]?.id}`
      }
    };
  }, [connectUserFileLink]);

  return fileOwner ? (
    <></>
  ) : (
    <div
      className={isNewGroup ? classes.newGroupParentWrapper : classes.wrapper}
    >
      <div className={classes.header}>
        {leftHeader}
        {isShowActivateLink ? (
          <div
            className={clsx(
              isActivateLink ? classes.deactivateLink : classes.activateLink,
              classes.linkButton
            )}
            onClick={onClickActivateLink}
          >
            {isActivateLink
              ? t('share_modal.share_link.deactivate_link')
              : t('share_modal.share_link.activate_link')}
          </div>
        ) : null}
      </div>
      {isLoading ? (
        <Loading
          open={true}
          styles={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: isActivateLink ? 179 : 106
          }}
        />
      ) : (
        <div>
          {isNewGroup ? null : isActivateLink ? (
            <LinkSettings
              checkedFiles={checkedFiles}
              permission={connectUserFileLinkData?.data?.filePermission}
            />
          ) : (
            <div className={classes.descriptionModal}>
              {t('share_modal.share_link.description')}
            </div>
          )}
          {isNewGroup && (
            <LinkSettings
              setPermission={setPermission}
              isNewGroup={isNewGroup}
              checkedFiles={checkedFiles}
              permission={connectUserFileLinkData?.data?.filePermission}
            />
          )}
          {isShowActivateLink && isActivateLink ? (
            <div className={classes.shareStringWrapper}>
              <div className={classes.shareString}>
                {connectUserFileLinkData?.data?.link ?? ''}
              </div>
              <CopyToClipboard
                text={connectUserFileLinkData?.data?.link ?? ''}
                onCopy={() => {
                  setShowSnackbar(true);
                }}
              >
                <div className={classes.copyLink}>
                  {t('share_modal.share_link.copy_link')}
                </div>
              </CopyToClipboard>
            </div>
          ) : null}
          {isShowAttachmentsTitle ? (
            <div className={classes.attachmentTitle}>
              {t('share_modal.share_link.attachments')}
            </div>
          ) : null}
          <Attachments checkedFiles={checkedFiles} isNewGroup={isNewGroup} />
          {isShowSnackbar ? (
            <div className={classes.snackbar}>
              <div className="text">{t('snackbar.share_link_copied')}</div>
              <IBMClose className="ibm-close" onClick={handleCloseSnackbar} />
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default ShareLink;
