import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Divider, MenuList, MenuItem } from '@material-ui/core';
import {
  ChevronRight,
  NotificationsNone,
  Language,
  ReportOutlined,
  FeedbackOutlined
} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import { clearStorageAll } from '@utils/storage';
import useUserActionLog, {
  Enum_Action_Button_Enum,
  CapaActivityType
} from '@hooks/useUserActionLog';
import useAuth from '@hooks/useAuth';
import { CustomizedTypography } from '@components/Customized';
import DialogLanguagesProps from './DialogLanguages';
import { mobileMyPageMenuStyles } from './styles';

import { notificationSettingsPathName } from '@routes/Common/types';
import useCommon from '@hooks/Common/useCommon';
import CapaAvatar from '@components/CapaAvatar';

const MobileMyPageMenu: React.FC = () => {
  const { t } = useTranslation();
  const classes = mobileMyPageMenuStyles();
  const history = useHistory();
  const { onInitAuthState } = useAuth();
  const { openFeedbackDialog, onSetUnReadCountGroupList } = useCommon();
  const [isOpenChangeLanguages, setIsOpenChangeLanguages] = useState(false);
  const { onInsertUserActionLogOne } = useUserActionLog();
  const { userState } = useAuth();

  const logout = () => {
    onInsertUserActionLogOne(
      Enum_Action_Button_Enum.Button_Gnb_MyPage_Logout,
      CapaActivityType.ButtonClick
    );
    onInitAuthState();
    clearStorageAll();
    history.push('/login');
    onSetUnReadCountGroupList({
      unReadCountGroupList: 0
    });
  };

  return (
    <Box className={classes.container}>
      <Box
        className={classes.accountSettingWrapper}
        onClick={() => {
          history.push('/mypage/account');
        }}
      >
        <div className={classes.infoWrapper}>
          <CapaAvatar
            item={{
              connectUserName: userState?.data?.userName,
              email: userState?.data?.email,
              isCapaUser: true
            }}
            size="large"
          />
          <div className="ml-16">
            <div className={classes.username}>
              {userState?.data?.userName ?? ''}
            </div>
            <div className={classes.email}>{userState?.data?.email ?? ''}</div>
          </div>
        </div>
        <ChevronRight />
      </Box>
      <Divider />
      <MenuList className={classes.menuList}>
        <MenuItem
          onClick={() => {
            openFeedbackDialog({
              isOpenFeedback: true,
              isFeedback: true
            });
          }}
        >
          <Box>
            <FeedbackOutlined className="mr-10" />
            <CustomizedTypography>
              {t('gnb.give_feedback')}
            </CustomizedTypography>
          </Box>
          <ChevronRight />
        </MenuItem>
        <MenuItem
          onClick={() => {
            openFeedbackDialog({
              isOpenFeedback: true,
              isFeedback: false
            });
          }}
        >
          <Box>
            <ReportOutlined className="mr-10" />
            <CustomizedTypography>
              {t('gnb.report_issues')}
            </CustomizedTypography>
          </Box>
          <ChevronRight />
        </MenuItem>

        <MenuItem
          onClick={() => {
            history.push(notificationSettingsPathName);
          }}
        >
          <Box>
            <NotificationsNone className="mr-10" />
            <CustomizedTypography>
              {t('aside_menu.notifications')}
            </CustomizedTypography>
          </Box>
          <ChevronRight />
        </MenuItem>
        <MenuItem onClick={() => setIsOpenChangeLanguages(true)}>
          <Box>
            <Language className="mr-10" />
            <CustomizedTypography>
              {t('aside_menu.languages')}
            </CustomizedTypography>
          </Box>
          <ChevronRight />
        </MenuItem>
      </MenuList>
      <div onClick={logout} className={classes.logOut}>
        {t('aside_menu.log_out')}
      </div>
      <DialogLanguagesProps
        isOpen={isOpenChangeLanguages}
        onClose={() => {
          setIsOpenChangeLanguages(false);
        }}
      />
    </Box>
  );
};

export default MobileMyPageMenu;
