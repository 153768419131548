import styled, { css } from 'styled-components';
import { colorSet } from '@components/Provider';
import {
  Container,
  Box,
  makeStyles,
  createStyles,
  Menu,
  Theme
} from '@material-ui/core';
import {
  contentHeaderHeight,
  contentMobileHeaderHeight,
  drawerWidth
} from '@routes/Common/types';

export const MuiDiv = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 122px);
  max-width: calc(100vw - 226px);

  ${({ theme }) => `
      ${theme.breakpoints.down('xs')} {
        max-width: unset;
      }
    `}

  & .icon-svg {
    display: flex;
    min-width: 36px;
    justify-content: center;
    & img {
      width: 27px;
      height: auto;
    }
  }
  & .icon-svg-file-rar {
    display: flex;
    min-width: 36px;
    justify-content: center;
    & img {
      width: 24px;
      height: auto;
    }
  }
  &.connect-empty {
    min-height: calc(100vh - 182px) !important;
    ${({ theme }) => `
    ${theme.breakpoints.down('xs')} {
      min-height: calc(100vh - 251px) !important;
      }
    `}
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 144px;
      width: 144px;
      margin-bottom: 36px;
    }
    .you-can-upload-text {
      color: ${colorSet.gray600};
      font-size: 16px;
      line-height: 150%;
      text-align: center;
      white-space: pre-wrap;
      margin-bottom: 32px;
    }
    .no-data-icon {
      -webkit-user-drag: none;
      -khtml-user-drag: none;
      -moz-user-drag: none;
      -o-user-drag: none;
      user-drag: none;
    }
  }
  .subtitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 42px;
    padding: 30px 0;
    padding-left: 20px;

    & .cursor-pointer {
      cursor: pointer;
    }

    ${({ theme }) => `
      ${theme.breakpoints.down('xs')} {
        height: 48px;
        margin-bottom: 0px;
        position: fixed;
        top: 0px;
        z-index: 1200;
        right: 0;
        padding: 24px 16px;
      }
    `}
    .MuiBreadcrumbs-li {
      font-size: 20px;
      font-weight: 500;
      color: ${colorSet.gray900};
      cursor: pointer;
      &:nth-child(1) {
        font-weight: bold;
        .MuiLink-underlineHover:hover {
          text-decoration: none !important;
        }
      }
      &:last-child {
        pointer-events: none;
        cursor: pointer;
      }
    }
    .label-group {
      cursor: pointer;
      display: flex;
      align-items: center;
      .MuiSvgIcon-root {
        color: ${colorSet.gray900};
        margin-right: 4px;
      }
    }
  }
`;

export const MuiBoxTable = styled(Box)`
  .icon-svg-mobile {
    flex: 0 0 auto;
    width: 66px;
    & img {
      width: 100%;
      height: auto;
    }
  }
  ${({ theme }) => `
    ${theme.breakpoints.down('xs')} {
      padding-bottom: 57px;
    }
  `}
  .fixed-header {
    position: sticky;
    top: 68;
    z-index: 10;
    background: ${colorSet.gray000};
  }
  .table-header {
    border-top: 1px solid ${colorSet.gray200};
    border-bottom: 1px solid ${colorSet.gray200};
    padding: 20px 24px;
    padding-left: 18px;
    display: flex;
    align-items: center;
    height: 44px;
    color: ${colorSet.gray700};
    & .icon-up {
      & svg {
        font-size: 18px;
        margin-bottom: -3px;
        margin-left: 5px;
        cursor: pointer;
      }
    }

    .table-header-th-one {
      width: 300px;
    }
    .table-header-th-two {
      width: 250px;
    }
    .table-header-th-three {
      width: calc(100% - 300px - 250px);
    }
    .table-header-th-four {
      width: 148px;
    }
    .table-header-th-five {
      width: 84px;
    }

    .table-header-th-group-name {
      width: calc(100% - 220px - 288px);
    }
    .table-header-th-my-access {
      width: 220px;
    }
    .table-header-th-shared-with {
      width: 288px;
    }

    .table-header-th-file-name {
      width: calc(100% - 260px - 140px - 140px);
    }

    .table-header-th-file-owner {
      width: 260px;
    }

    .table-header-th-shared-date {
      width: 140px;
    }

    .table-header-th-file-size {
      width: 140px;
    }

    .table-header-feature {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
    .table-header-feature-left {
      display: flex;
      align-items: center;
    }
    .table-header-feature-dropdown {
      margin-right: 10px;
      button {
        border: none;
        cursor: pointer;
        background: none;
        display: flex;
        align-item: center;
      }
    }
    .table-header-number_checked {
      font-size: 16px;
      color: ${colorSet.primary500};
    }
    .table-header-feature-group {
      display: flex;
      align-items: center;
      img {
        cursor: pointer;
        margin-right: 15px;
      }
    }
  }
  .table-content {
    overflow-x: auto;
    height: calc(100vh - 181px);
  }
`;

interface IMuiBoxTableItemProps {
  isChecked?: boolean;
}

export const MuiBoxTableItem = styled.div.attrs(
  (props: IMuiBoxTableItemProps) => props
)`
  background-color: ${(props) =>
    props.isChecked ? `${colorSet.primary100}` : ''};
  border-bottom: 1px solid ${colorSet.gray200};
  display: flex;
  align-items: center;
  user-select: none;
  &:hover {
    background-color: ${colorSet.primary100};
  }
  .table-item-td-one {
    cursor: pointer;
    width: 300px;
    display: flex;
    align-items: center;
    .table-item-avatar {
      padding: 12px 0 12px 20px;
      margin-right: 8px;
      min-height: 60px;
      .custom-avatar {
        width: 36px;
        height: 36px;
        margin-left: 0 !important;
        margin-right: 6px !important;
        text-transform: uppercase;
        border: none !important;
        font-size: 14px !important;
      }
    }
    .table-item-text {
      line-height: 60px;
      width: 100%;
      height: 60px;
    }
  }
  .table-item-text {
    cursor: pointer;
    font-size: 14px;
    width: 100%;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .table-item-td-two {
    padding: 10px 20px;
    min-height: 60px;
    display: flex;
    align-items: center;
    width: 250px;
  }
  .table-item-td-three {
    padding: 0 20px;
    min-height: 60px;
    display: flex;
    align-items: center;
    width: calc(100% - 300px - 250px);
    justify-content: space-between;
    .table-item-td-feature {
      cursor: pointer;
      display: none;
    }
    &:hover .table-item-td-feature,
    &.is-hover .table-item-td-feature {
      display: flex;
    }
    .table-item-td-memo {
      width: 100%;
      height: 60px;
      line-height: 60px;
      max-width: calc(100% - 70px);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    img {
      width: 32px;
      height: 32px;
      opacity: 80%;
    }
    img:hover {
      opacity: 100%;
    }
  }
  .table-item-td-four {
    display: flex;
    align-items: center;
    width: 148px;
  }
  .table-item-td-five {
    display: flex;
    align-items: center;
    width: 84px;
  }
  .table-header-feature {
    display: flex;
    align-items: center;
  }
`;

interface IThumbnailProps {
  is_empty?: boolean;
  width?: string;
}
export const ThumbnailStyle = styled.div.attrs(
  (props: IThumbnailProps) => props
)`
  overflow: hidden;
  cursor: pointer;
  box-sizing: border-box;
  width: ${(props) => (props.width ? props.width : '36px')};
  min-width: ${(props) => (props.width ? props.width : '36px')};
  height: ${(props) => (props.width ? props.width : '36px')};
  border: ${(props) =>
    props.is_empty ? 'none' : `1px solid ${colorSet.gray400}`};
  img {
    width: 100%;
    height: 100%;
  }
  border-radius: 4px;
  background-color: ${colorSet.gray000};
`;

export const MuiContainerHome = styled(Container)``;

interface IMuiBoxHomeHeaderProps {
  background_image?: string;
}

export const MuiBoxHomeHeader = styled(Box).attrs(
  (props: IMuiBoxHomeHeaderProps) => props
)`
  ${(props) =>
    props.background_image &&
    css`
      background-image: url(${props.background_image});
    `}
  max-height: 500px;
  padding: 70px 36px;
  img {
    height: 100%;
    width: 100%;
  }
  .grid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const useContactsAsideMenuPresenterStyles = makeStyles((theme) =>
  createStyles({
    drawerPaper: {
      width: drawerWidth,
      minHeight: '100vh',
      paddingRight: 16,
      paddingBottom: 14,
      borderRight: `1px solid ${colorSet.gray000}`,
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      },
      '& .no-event': {
        pointerEvents: 'none'
      }
    },
    drawerHeaderMargin: {
      height: contentHeaderHeight
    },
    drawerContent: {},
    lineSpace: {
      height: 1,
      width: '100%',
      background: colorSet.gray300,
      margin: '8px 0'
    },
    drawerContentButton: {
      padding: '14px 12px 16px'
    },
    drawerContentItem: {
      display: 'flex',
      alignItems: 'center',
      padding: '10px 12px',
      cursor: 'pointer',
      borderRadius: '0 56px 56px 0',
      color: colorSet.gray900,
      transition: 'all 300ms',
      '& img': {
        marginRight: 8,
        width: 24,
        height: 24
      },
      '&:hover': {
        color: colorSet.gray900,
        backgroundColor: '#EBEBEB'
      },
      '&.selected': {
        color: colorSet.primary450,
        cursor: 'default',
        backgroundColor: colorSet.primary100,
        '& p': {
          color: colorSet.primary450
        }
      }
    },
    IBMChatIconActive: {
      marginRight: 8,
      '& path': {
        fill: `${colorSet.primary450} !important`
      }
    },
    IBMChatIcon: {
      marginRight: 8
    },
    drawerContentItemText: {
      fontSize: 14
    },
    divider: {
      backgroundColor: colorSet.gray400,
      margin: '179px 16px 24px 16px'
    },
    newButton: {
      padding: '0 !important',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minWidth: '156px !important',
      textTransform: 'none',
      backgroundColor: `${colorSet.primary450} !important`,
      '&:hover': {
        backgroundColor: `${colorSet.primary600} !important`
      },
      '& img': {
        width: 24,
        height: 24,
        marginRight: 2
      },
      '& span': {
        fontWeight: 500,
        fontSize: 16,
        lineHeight: '150%',
        letterSpacing: '-0.1px',
        marginRight: 0
      }
    }
  })
);
export const useDataTable = makeStyles((theme) =>
  createStyles({
    table: {
      '&::-webkit-scrollbar': {
        width: 4
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: colorSet.gray600,
        borderRadius: 8
      }
    },
    tableWrapper: {
      marginTop: 16,
      height: 'calc(100vh - 188px)',
      overflow: 'auto'
    }
  })
);
export const useEmptyStyles = makeStyles((theme) =>
  createStyles({
    title: {
      textAlign: 'center',
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '24px',
      marginBottom: 8,
      color: colorSet.gray900,
      [theme.breakpoints.down('xs')]: {
        fontSize: 20,
        lineHeight: '32px'
      }
    },
    subTitle: {
      textAlign: 'center',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '24px',
      marginBottom: 32,
      whiteSpace: 'break-spaces',
      color: colorSet.gray600
    },
    button: {
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '24px',
      border: 'none',
      padding: '12px 19px',
      borderRadius: 8,
      color: colorSet.gray000,
      transition: '300ms all',
      cursor: 'pointer',
      backgroundColor: colorSet.primary450,
      '&:hover': {
        backgroundColor: colorSet.primary500
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 14,
        padding: '10px 20px',
        lineHeight: '20px'
      }
    }
  })
);

export const NewButtonMenu = styled(Menu)`
  &.MuiPopover-root {
    z-index: 3000 !important;
  }
  ${({ theme }) => `
  & .MuiPaper-root {
    ${theme.breakpoints.down('xs')} {
      left: 0 !important;
      top: auto !important;
      bottom: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      width: 100%;
      max-width: 100% !important;
    }
    border-radius: 6px;
    margin-top: ${theme.spacing(1)};
    min-width: 343px;
    box-shadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px';
  }
  `}
`;

export const MuiNewButtonMobile = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 79px;
  right: 14px;
  width: 54px;
  height: 54px;
  border-radius: 100%;
  background-color: ${colorSet.primary450};
  cursor: pointer;
  img {
    width: 32px;
    height: 32px;
  }
`;

export const MuiBoxTableItemMobile = styled.div.attrs(
  (props: IMuiBoxTableItemProps) => props
)`
  padding: 8px 16px;
  display: flex;
  cursor: pointer;
  margin-bottom: 8px;
  &.selected {
    padding: 0px 0 0 8px;
  }

  ${({ theme }) => `
      ${theme.breakpoints.down('xs')} {
        padding: 6px 16px;
      }
    `}

  .custom-add-button {
    min-width: 44px !important;
    height: 32px !important;
    .custom-add-icon {
      width: 20px;
      height: 20px;
    }
  }

  .table-contents {
    display: grid;
    margin-left: 8px;
    width: 100%;

    .MuiTypography-colorTextSecondary {
      color: ${colorSet.gray900};
    }
    .customized-typography {
      text-align: left;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .folder-icon {
    color: ${colorSet.gray600};
  }
  .checkbox-wrapper {
    display: flex;
    align-items: center;
    margin-right: 8px;
  }
  .more-vert-menu {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
  .item-wrapper {
    display: flex;
    cursor: pointer;
    width: 100%;
    background: transparent;
    border: none;
    padding: 0;
    align-items: center;
    &.selected {
      padding: 8px;
      border-radius: 12px 0 0 12px;
      background-color: ${colorSet.primary300};
    }
  }
  .table-item-share-mobile {
    display: flex;
    align-items: center;
  }
  .table-item-share-icon-mobile {
    color: ${colorSet.gray550};
    margin-right: 5px;
    font-size: 20px;
  }
`;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerFeatureFixed: {
      position: 'fixed',
      top: 0,
      zIndex: 1000,
      minHeight: 48,
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        minHeight: 68
      }
    },
    headerFeature: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      backgroundColor: 'white',
      [theme.breakpoints.down('xs')]: {
        minHeight: contentMobileHeaderHeight
      }
    },
    headerFeatureLeft: {
      display: 'flex',
      alignItems: 'center'
    },
    headerFeatureClose: {
      padding: 12
    },
    headerFeatureDropdownBox: {
      borderRadius: '8px',
      boxShadow:
        '0px 5px 5px -3px rgba(0, 0, 0, 0.1), 0px 8px 10px 1px rgba(0, 0, 0, 0.07), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);'
    },
    headerFeatureDropdown: {
      '& button': {
        border: 'none',
        cursor: 'pointer',
        background: 'none',
        display: 'flex',
        padding: '10px 5px 10px 0',
        alignItem: 'center'
      }
    },
    headerFeatureDropdownItem: {
      fontSize: '14px',
      lineHeight: '20px',
      padding: '8px 0',
      fontWeight: 500
    },
    headerFeatureNumberChecked: {
      fontSize: 16,
      color: colorSet.primary500,
      [theme.breakpoints.down('xs')]: {
        fontSize: 20,
        fontWeight: '500',
        color: colorSet.gray900
      }
    },
    headerFeatureGroup: {
      display: 'flex',
      alignItems: 'center'
    },
    headerFeatureItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 9,
      borderRadius: '100%',
      cursor: 'pointer',
      transition: '300ms all',
      '&:hover': {
        background: '#F7FAFF'
      },
      '& img': {
        [theme.breakpoints.down('xs')]: {
          marginRight: 0
        }
      }
    },
    backIconWrapper: {
      backgroundColor: colorSet.primary450,
      borderRadius: 12,
      marginRight: 12,
      width: 36,
      height: 36,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: colorSet.primary600
      },
      '& svg path': {
        fill: `${colorSet.gray000} !important`
      }
    },
    backIconMobile: {
      position: 'absolute',
      left: 16
    },
    createNewGroupTitle: {
      fontWeight: 700,
      fontSize: 20,
      lineHeight: '140%',
      letterSpacing: '-0.1px',
      [theme.breakpoints.down('xs')]: {
        fontWeight: 500,
        fontSize: 16,
        lineHeight: '150%',
        letterSpacing: '-0.1px'
      }
    }
  })
);
