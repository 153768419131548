import React, { useState } from 'react';
import { colorSet } from '@components/Provider';
import {
  createMuiTheme,
  createStyles,
  makeStyles,
  Theme
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ChevronDown } from '../assets';
import { DataFAQItems, ItemFAQ } from '../types';
import clsx from 'clsx';

const themeLanding = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1024,
      xl: 1536
    }
  }
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerFriud: {
      maxWidth: '100%',
      overflow: 'hidden',
      paddingLeft: 32,
      paddingRight: 32,
      [themeLanding.breakpoints.down('md')]: {
        paddingLeft: 16,
        paddingRight: 16
      }
    },
    container: {
      width: '100%',
      maxWidth: '1024px',
      margin: 'auto',
      [themeLanding.breakpoints.down('md')]: {
        maxWidth: '960px'
      }
    },
    faq: {
      padding: '160px 0',
      [themeLanding.breakpoints.down('md')]: {
        padding: '120px 0'
      }
    },
    title: {
      fontWeight: 700,
      fontSize: 32,
      lineHeight: '48px',
      textAlign: 'center',
      marginBottom: 48,
      whiteSpace: 'break-spaces',
      letterSpacing: '-0.1px',
      color: colorSet.gray000,
      [themeLanding.breakpoints.down('md')]: {
        paddingLeft: 32,
        paddingRight: 32
      },
      [themeLanding.breakpoints.down('xs')]: {
        fontSize: 25,
        lineHeight: '40px',
        textAlign: 'left',
        marginBottom: 32,
        paddingLeft: 16,
        paddingRight: 16
      }
    },
    faqList: {
      width: '100%',
      display: 'grid',
      gap: 16,
      gridTemplateColumns: '1fr',
      [themeLanding.breakpoints.down('xs')]: {
        gap: 8
      }
    },
    faqItem: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      fontSize: 20,
      lineHeight: '32px',
      cursor: 'pointer',
      [themeLanding.breakpoints.down('xs')]: {
        fontSize: 16,
        lineHeight: '24px'
      }
    },
    faqItemQuestion: {
      fontWeight: 400,
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      justifyContent: 'space-between',
      padding: 16,
      whiteSpace: 'break-spaces',
      letterSpacing: '-0.1px',
      borderRadius: 16,
      marginBottom: 8,
      '&:hover': {
        backgroundColor: '#141515'
      },
      '& img': {
        transform: 'rotate(0deg)',
        transition: '300ms all'
      },
      '&.open': {
        '& img': {
          transform: 'rotate(180deg)'
        }
      },
      [themeLanding.breakpoints.down('xs')]: {
        alignItems: 'flex-start'
      }
    },
    faqItemQuestionText: {
      display: 'flex',
      alignItems: 'flex-start',
      textAlign: 'left',
      fontWeight: 400,
      justifyContent: 'flex-start'
    },
    faqItemIcon: {
      flexShrink: 0,
      fontWeight: 700,
      fontSize: 20,
      lineHeight: '32px',
      marginRight: 13,
      [themeLanding.breakpoints.down('xs')]: {
        fontSize: 16,
        lineHeight: '24px'
      }
    },

    faqItemAnswer: {
      fontWeight: 400,
      maxHeight: 0,
      fontSize: 16,
      lineHeight: '24px',
      overflow: 'hidden',
      textAlign: 'left',
      whiteSpace: 'break-spaces',
      letterSpacing: '-0.1px',
      transition: '300ms all',
      padding: '0 16px',
      borderRadius: 16,
      display: 'flex',
      alignItems: 'flex-start',
      backgroundColor: '#141515',
      '&.open': {
        padding: 16,
        maxHeight: 500
      }
    }
  })
);
const FAQ = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [idIsOpen, setIdIsOpen] = useState<number[]>([]);

  return (
    <div className={classes.containerFriud}>
      <div className={classes.container}>
        <div className={classes.faq}>
          <h2 className={classes.title}>
            {t('landing_page.faq_section.title')}
          </h2>
          <div className={classes.faqList}>
            {DataFAQItems.map((item: ItemFAQ) => {
              const openClass = idIsOpen.includes(item?.id) ? 'open' : '';
              return (
                <div
                  className={classes.faqItem}
                  key={item.question}
                  onClick={() => {
                    setIdIsOpen(
                      idIsOpen.includes(item?.id)
                        ? idIsOpen.filter((i: number) => i !== item.id) // remove item
                        : [...idIsOpen, item.id] // add item
                    );
                  }}
                >
                  <div className={clsx(classes.faqItemQuestion, openClass)}>
                    <div className={classes.faqItemQuestionText}>
                      <div className={classes.faqItemIcon}>Q</div>
                      {t(item.question)}
                    </div>
                    <img src={ChevronDown} alt="ChevronDown" />
                  </div>
                  <div className={clsx(classes.faqItemAnswer, openClass)}>
                    <div className={classes.faqItemIcon}>A</div>
                    {t(item.answer)}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
