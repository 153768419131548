import { CapaErrorCode } from '@generated/Common/graphql';
import { CapaActivityType } from '@generated/Common/types';
import useAuth from '@hooks/useAuth';
import useUserActionLog, {
  Enum_Action_Button_Enum
} from '@hooks/useUserActionLog';
import { Divider, useMediaQuery } from '@material-ui/core';
import ChangeLanguageContainer from '@routes/Common/ChangeLanguage';
import ConnectGNB from '@routes/Common/ConnectGNB/ConnectGNB';
import RegisterUserContainer from '@routes/Common/Dialog/RegisterUser/RegisterUserContainer';
import { loginPathName } from '@routes/Common/types';
import Carousel from '@routes/Home/Components/Carousel';
import FAQ from '@routes/Home/Components/FAQ';
import GetStarted from '@routes/Home/Components/GetStarted';
import GoToApp from '@routes/Home/Components/GoToApp';
import Hero from '@routes/Home/Components/Hero';
import Services from '@routes/Home/Components/Services';
import { themeLanding } from '@routes/Home/Components/styles';
import { STORAGE_ACTION, getStorageItem, setStorageItem } from '@utils/storage';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Footer from '../Common/Footer';
import GnbPopupMenu from '../Common/GnbPopupMenu';
import NotificationMenu from '../Common/NotificationMenu';
import LayoutPresenter from './LayoutPresenter';
import { ButtonLoginInHome, GnbMenuStyle, HomePageWrapper } from './styles';

const HomePresenter: React.FC = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(themeLanding.breakpoints.down('xs'));
  const isTabletAndDesktop = useMediaQuery(themeLanding.breakpoints.up('sm'));
  const history = useHistory();

  const { userState, isLoggedIn } = useAuth();
  const [isShowHideRegisterDialog, setShowHideRegisterDialog] = useState(false);
  const { onInsertUserActionLogOne } = useUserActionLog();

  useEffect(() => {
    const userInfo = getStorageItem(STORAGE_ACTION.USER_INFO);
    if (
      userInfo &&
      userInfo.email &&
      userState.result === CapaErrorCode.Success
    ) {
      setStorageItem(STORAGE_ACTION.LOGGED_IN, 'true');
    }
  }, [userState]);

  const GnbMenu = () => {
    return (
      <GnbMenuStyle>
        {isLoggedIn && !isMobile && (
          <>
            <NotificationMenu />
            <GnbPopupMenu />
          </>
        )}
        {!isLoggedIn && (
          <>
            {isTabletAndDesktop ? (
              <ChangeLanguageContainer isLandingPage={true} />
            ) : null}
            <ConnectGNB isLandingPage={true} />
            <ButtonLoginInHome
              onClick={() => {
                onInsertUserActionLogOne(
                  Enum_Action_Button_Enum.Button_Landing_Login_Signup_CTA,
                  CapaActivityType.ButtonClick
                );
                history.push(loginPathName);
              }}
            >
              {t('landing_page.log_in_sign_up')}
            </ButtonLoginInHome>
          </>
        )}
      </GnbMenuStyle>
    );
  };

  useEffect(() => {
    const connectUserResultStorage = getStorageItem(
      STORAGE_ACTION.CONNECT_USER_RESULT
    )
      ? getStorageItem(STORAGE_ACTION.CONNECT_USER_RESULT)
      : '';

    if (connectUserResultStorage === CapaErrorCode.NotAllowedUser) {
      setShowHideRegisterDialog(true);
    }
  }, []);

  return (
    <LayoutPresenter GnbMenu={GnbMenu()}>
      <HomePageWrapper>
        <Hero />

        {/* <Reason /> */}

        <Services />

        <Carousel />

        <GoToApp />

        <FAQ />

        <GetStarted />

        <Divider className="driver" />
        <Footer />
      </HomePageWrapper>

      <RegisterUserContainer
        isOpen={isShowHideRegisterDialog}
        onClose={() => {
          setShowHideRegisterDialog(false);
        }}
      />
    </LayoutPresenter>
  );
};

export default HomePresenter;
