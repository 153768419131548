import React, { useEffect, useRef, useState } from 'react';
import { useStyles } from '@routes/GroupRoom/styles';
import useMyDrive from '@hooks/Drive/MyDrive/useMyDrive';
import Loading from '@components/LoadingInComponent';
import AppConfig from '@config/index';
import { useMediaQuery, useTheme } from '@material-ui/core';
import ActionButton from '@routes/GroupRoom/Messages/ActionButton';
import { CapaImage } from '@assets';
import { useTranslation } from 'react-i18next';

interface Props {
  message: any;
  messageDataOrigin?: any;
  userEmail: string;
  openDownloadAppBanner?: (event: any) => void;
  onClickDownloadFile?: (message: any) => void;
}

const ImageMessage = (props: Props) => {
  const { t } = useTranslation();
  const {
    message,
    openDownloadAppBanner,
    userEmail,
    onClickDownloadFile,
    messageDataOrigin
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const imgRef = useRef<any>(null);
  const [imageSrc, setImageSrc] = useState('');
  const { onSetActiveFileIdInGroupRoom } = useMyDrive();
  const [hoverImage, setHoverImage] = useState<any>(null);

  useEffect(() => {
    if (message) {
      console.log('thumb::', message?.chatFiles[0]?.thumbnailUri);
      if (message?.chatFiles[0]?.thumbnailUri) {
        const url = message?.chatFiles[0]?.thumbnailUri.split('.')[0];
        setImageSrc(`${AppConfig.capaViewerApi}/${url}`);
      }
    }
  }, [message]);

  const onClickImage = (event: any) => {
    if (isMobile) {
      openDownloadAppBanner && openDownloadAppBanner(event);
    }
    onSetActiveFileIdInGroupRoom({
      activeFileIdInGroupRoom: message?.chatFiles[0]?.fileId
    });
  };

  const onClickDownload = () => {
    onClickDownloadFile && onClickDownloadFile(message);
  };

  if (!imageSrc)
    return (
      <Loading
        size={20}
        open={!imageSrc}
        styles={{
          display: 'flex',
          justifyContent: 'center'
        }}
      />
    );

  return (
    <div className={classes.imageMessageWrapper}>
      {message?.chatFiles[0]?.isUpload ? (
        <div className="loading-wrapper">
          <Loading
            open={true}
            styles={{
              display: 'flex',
              justifyContent: 'center'
            }}
            size={32}
          />
        </div>
      ) : (
        <>
          <div
            className="image-wrapper"
            onMouseEnter={() => {
              if (isMobile) return;
              setHoverImage(imageSrc);
            }}
            onMouseLeave={() => {
              if (isMobile) return;
              setHoverImage(null);
            }}
            onClick={(event: any) => onClickImage(event)}
          >
            <div
              ref={imgRef}
              style={{
                borderRadius: '10px',
                position: 'relative',
                width: '100%',
                height: '100%',
                backgroundImage: `url(${imageSrc})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center'
              }}
            />
            {hoverImage === imageSrc ? (
              <div className="click-to-see">
                <div className="content">
                  <img src={CapaImage} alt="" />
                  <div className="text">{t('chat_room.click_to_see')}</div>
                </div>
              </div>
            ) : null}
          </div>
          <div className="action-button-wrapper">
            <ActionButton
              messageDataOrigin={messageDataOrigin}
              message={message ?? null}
              userEmail={userEmail}
              clickDownload={onClickDownload}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ImageMessage;
