import { JwtPayload } from 'jwt-decode';
import { ActionType } from 'typesafe-actions';
import * as actions from './actions';

export interface IAuthState {
  email?: string;
  accessToken?: string;
  refreshToken?: string;
  isSaved?: boolean;
}

export type JwtPayloadType = JwtPayload & { scopes: Array<string> };

export const initialAuthState: IAuthState = {};

export type AuthAction = ActionType<typeof actions>;
