import { makeStyles } from '@material-ui/core';
import { colorSet } from '@components/Provider';

export const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: 607,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: 0,
      height: '100%',
      maxHeight: '100%',
      maxWidth: '100%',
      borderRadius: 0
    },
    borderRadius: 16,
    padding: '12px 24px 32px 24px'
  },
  rootTitle: {
    padding: 0,
    height: 48,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& .close-icon': {
      marginLeft: 'auto',
      cursor: 'pointer'
    }
  },
  csDialogContent: {
    padding: 0,
    fontSize: 14,
    overflow: 'hidden',
    '&:first-child': {
      paddingTop: 0
    },
    textAlign: 'center'
  },
  title: {
    color: colorSet.primary450,
    fontWeight: 500,
    fontSize: 13,
    lineHeight: '138%',
    letterSpacing: '-0.1px',
    marginBottom: 12
  },
  customImage: {
    width: '100%',
    height: '100%',
    boxShadow: '0px 24px 56px 4px rgba(0, 0, 0, 0.04)'
  },
  imageWrapper: {
    height: 288,
    margin: '20px 20px 32px 20px'
  },
  content: {
    fontWeight: 400,
    fontSize: 20,
    lineHeight: '160%',
    letterSpacing: '-0.1px'
  },
  icon: {
    width: 12,
    height: 12,
    cursor: 'pointer'
  },
  activeColorIcon: {
    color: colorSet.primary450
  },
  colorIcon: {
    color: colorSet.gray400
  },
  wrapperBtn: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 32,
    marginBottom: 32,
    '& button': {
      width: 136,
      textTransform: 'none',
      '&:first-child': {
        marginRight: 8
      }
    }
  },
  customOutlinedButton: {
    color: colorSet.primary450,
    border: `1px solid ${colorSet.primary450}`
  },
  customContainedButton: {
    backgroundColor: colorSet.primary450
  }
}));

export const useStylesMobile = makeStyles((theme) => ({
  paper: {
    paddingLeft: 24,
    paddingRight: 24
  },
  rootTitle: {
    padding: 0,
    height: 48,
    display: 'flex',
    alignItems: 'center'
  },
  csDialogContent: {
    padding: 0,
    fontSize: 14,
    overflow: 'hidden',
    '&:first-child': {
      paddingTop: 0
    },
    textAlign: 'center',
    marginTop: 44
  },
  title: {
    color: colorSet.primary450,
    fontWeight: 500,
    fontSize: 13,
    lineHeight: '138%',
    letterSpacing: '-0.1px',
    marginBottom: 8
  },
  customImage: {
    width: 220,
    height: 464,
    boxShadow: '0px 8px 16px 2px #6161611A'
  },
  content: {
    fontWeight: 400,
    fontSize: 25,
    lineHeight: '144%',
    letterSpacing: '-0.3px',
    marginBottom: 32
  },
  icon: {
    width: 12,
    height: 12,
    cursor: 'pointer',
    marginBottom: 32,
    marginRight: 4,
    '&:last-child': {
      marginRight: 0
    }
  },
  activeColorIcon: {
    color: colorSet.primary450
  },
  colorIcon: {
    color: colorSet.gray400
  }
}));
