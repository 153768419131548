import React, { useEffect } from 'react';
import useUpload from '@modules/upload/useUpload';
import { UploadData } from '@modules/upload';
import { CustomizedTypography } from '@components/Customized';
import UploadFileListEntry from './UploadFileListEntry';
import {
  Box,
  Popper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import { Close, ExpandLess } from '@material-ui/icons';
import { useStyles } from '@components/Popper/UploadStatusPopper/styles';

const UploadStatusPopper: React.FC = () => {
  const { containerList, summary, summaryContent, popper } = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const {
    uploadStatusOpen,
    setUploadStatusOpen,
    uploadState,
    filteredUploadState,
    successUploadFiles,
    isCompleted,
    onInitUploadData
  } = useUpload();

  useEffect(() => {
    return () => {
      onInitUploadData();
    };
  }, []);

  return (
    <Popper
      open={uploadStatusOpen}
      anchorEl={undefined}
      keepMounted
      className={popper}
    >
      <Accordion defaultExpanded={!matches}>
        <AccordionSummary className={summary}>
          <Box className={summaryContent}>
            <Box className="buttonGroup">
              <ExpandLess className="expandLess" />
              {isCompleted && (
                <IconButton
                  className="closeButton"
                  onClick={(e) => {
                    e.stopPropagation();
                    onInitUploadData();
                    setUploadStatusOpen(false);
                  }}
                >
                  <Close />
                </IconButton>
              )}
            </Box>
            <CustomizedTypography>
              {successUploadFiles.length === filteredUploadState.length
                ? `Uploaded ${successUploadFiles.length} items`
                : `Uploading ${filteredUploadState.length} items`}
            </CustomizedTypography>
          </Box>
        </AccordionSummary>
        <AccordionDetails className={containerList}>
          <Box className="list">
            {uploadState.map((file, index) => {
              return (
                <UploadFileListEntry
                  key={`${file?.file?.name}${index}`}
                  fileState={file as UploadData}
                />
              );
            })}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Popper>
  );
};

export default UploadStatusPopper;
