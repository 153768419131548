import { contentHeaderHeight, contentMobileHeaderHeight } from '../types';
import { colorSet, theme } from '@components/Provider';
import { Box, createStyles, makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const useStylesLayout = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex'
    },
    appBar: {
      minHeight: contentHeaderHeight,
      [theme.breakpoints.down('xs')]: {
        minHeight: contentMobileHeaderHeight
      },
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      backgroundColor: colorSet.gray000,
      borderBottom: `1px solid ${colorSet.gray400}`
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
      minHeight: contentHeaderHeight,
      [theme.breakpoints.down('xs')]: {
        minHeight: contentMobileHeaderHeight
      },
      padding: '0px 24px'
    },
    logo: {
      display: 'flex',
      alignItems: 'center',
      '& img': {
        height: 24,
        [theme.breakpoints.down('xs')]: {
          height: 20
        }
      }
    },
    menuWrapper: {
      display: 'flex',
      alignItems: 'center'
    },
    content: {
      flexGrow: 1,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      minHeight: '100vh'
    },
    drawerHeader: {
      minHeight: contentHeaderHeight,
      [theme.breakpoints.down('xs')]: {
        minHeight: contentMobileHeaderHeight
      }
    }
  })
);

export const MuiBackdrop = styled(Box)`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 3000;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;
