import React, { useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { useStyles } from './styles';
import useMyDrive from '@hooks/Drive/MyDrive/useMyDrive';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { ADD_FILES_FROM_VALUE, ADD_FILES_FROM_OPTIONS } from './types';
import { CREATE_GROUP_SCREEN } from '@routes/Group/Dialog/types';
import useGroup from '@hooks/Group/useGroup';
import { ReactComponent as IBMChevronLeft } from './../../../../../assets/icon/IBMChevronLeft.svg';
import { ReactComponent as IBMChevronRight } from './../../../../../assets/icon/IBMChevronRight.svg';
import { ReactComponent as IBMAttachment } from './../../../../../assets/icon/IBMAttachment.svg';
import { ReactComponent as IBMCloud } from './../../../../../assets/icon/IBMCloud.svg';

interface Props {
  onClose?: () => void;
  onSubmitUploadFiles?: () => void;
  isShowHideUploadFilesMobileDialog?: boolean;
  isAllowEmptyFile?: boolean;
  submitText?: any;
}

const UploadFilesContainer = (props: Props) => {
  const { isShowHideUploadFilesMobileDialog } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();
  const { onSetAddFilesFromType, onSetSelectedFilesUploadInChat } =
    useMyDrive();
  const { group, onSetCreateGroupScreen } = useGroup();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));

  const onClickAddFilesFromBox = (type: any) => {
    onSetAddFilesFromType({ addFilesFromType: type });
    onSetCreateGroupScreen({
      createGroupScreen: CREATE_GROUP_SCREEN.ADD_FILES_FROM_MOBILE
    });
  };

  const onBack = () => {
    onSetCreateGroupScreen({
      createGroupScreen: CREATE_GROUP_SCREEN.CREATE_GROUP
    });
  };

  useEffect(() => {
    if (
      group.createGroupScreen === CREATE_GROUP_SCREEN.ADD_FILES_CONTAINER_MOBILE
    ) {
      onSetSelectedFilesUploadInChat({ selectedFilesUploadInChat: [] });
    }
  }, []);

  return (
    <>
      <Dialog
        classes={{
          paper: classes.paper
        }}
        open={isShowHideUploadFilesMobileDialog!}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <DialogTitle classes={{ root: classes.rootTitle }} disableTypography>
          {matches ? (
            <IBMChevronLeft className={classes.closeIcon} onClick={onBack} />
          ) : null}

          <div className={classes.customTitle}>
            {t('upload_files_modal.attachments')}
          </div>
        </DialogTitle>
        <DialogContent className={classes.csDialogContentUpload}>
          <div>
            {ADD_FILES_FROM_OPTIONS.map((item) => {
              return (
                <div
                  className={clsx(
                    classes.shareFilesToBox,
                    classes.pointerCursor
                  )}
                  onClick={() => onClickAddFilesFromBox(item.value)}
                >
                  <div className={clsx(classes.iconWrapper)}>
                    {item.value === ADD_FILES_FROM_VALUE.MY_DEVICE ? (
                      <IBMAttachment />
                    ) : (
                      <IBMCloud />
                    )}
                    <div className={classes.shareFilesName}>{item.name}</div>
                  </div>
                  <IBMChevronRight className={classes.chevronRight} />
                </div>
              );
            })}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UploadFilesContainer;
