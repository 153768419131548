import { colorSet } from '@components/Provider';
import { Box, makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const useUploadFilesButton = makeStyles((theme) => ({
  attachmentIconBlock: ({ isChatBox }: { isChatBox?: boolean }) => ({
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      right: 16
    },
    '& .icon-wrapper': {
      padding: isChatBox ? '5.5px 6px' : '7.5px 8px',
      [theme.breakpoints.down('xs')]: {
        padding: '5.5px 6px 5.5px 6px'
      },
      '& svg path': {
        fill: isChatBox
          ? `${colorSet.gray700} !important`
          : `${colorSet.gray900} !important`
      },
      cursor: 'pointer',
      userSelect: 'none',
      userDrag: 'none',
      '& .cs-icon': {
        width: 20,
        height: 20,
        userSelect: 'none',
        userDrag: 'none'
      },
      '&:hover': {
        boxShadow: 'inset 999px 999px 0px rgba(0, 0, 0, 0.08)',
        borderRadius: 24,
        [theme.breakpoints.down('xs')]: {
          borderRadius: 0,
          boxShadow: 'none',
          '& svg path': {
            fill: `${colorSet.gray900} !important`
          }
        }
      }
    }
  })
}));

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: '24px 0',
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
      padding: 0,
      borderTop: 'none'
    }
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,
    padding: '0 24px',
    [theme.breakpoints.down('xs')]: {
      padding: '12px 16px',
      borderBottom: `1px solid ${colorSet.gray300}`,
      justifyContent: 'center',
      marginBottom: 16
    }
  },
  closeIcon: {
    color: colorSet.gray900,
    fontWeight: 500,
    position: 'absolute',
    left: 16,
    top: 10,
    [theme.breakpoints.down('xs')]: {
      top: 14
    }
  },
  backIconWrapper: {
    backgroundColor: colorSet.primary450,
    borderRadius: 12,
    marginRight: 12,
    width: 36,
    height: 36,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: colorSet.primary600
    },
    '& svg path': {
      fill: `${colorSet.gray000} !important`
    }
  },
  backIconMobile: {
    position: 'absolute',
    left: 16
  },
  content: {
    maxHeight: 528,
    overflow: 'auto',
    position: 'relative',
    margin: '0 24px',
    '&::-webkit-scrollbar': {
      width: 4
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: colorSet.gray600,
      borderRadius: 8
    },
    [theme.breakpoints.down('xs')]: {
      height: 'calc(100vh - 144px)',
      maxHeight: 'inherit',
      margin: '0 16px'
    }
  },
  backdrop: {
    border: `2px dashed ${colorSet.gray900}`,
    borderRadius: 8,
    background: 'rgba(255, 255, 255, 0.9)',
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .content': {
      textAlign: 'center'
    }
  },
  dropFilesHereText: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '150%',
    letterSpacing: '-0.1px',
    color: colorSet.gray900,
    marginTop: 8
  },
  labelGroup: {
    padding: 0
  },
  membersText: {
    marginTop: 16
  },
  uploadFilesWrapper: {
    padding: '12px 0 8px 0',
    [theme.breakpoints.down('xs')]: {
      padding: '12px 0 6px 0'
    },
    '& .header': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& .attachments-text': {
        fontWeight: 500,
        fontSize: 14,
        lineHeight: '143%',
        letterSpacing: '-0.1px',
        [theme.breakpoints.down('xs')]: {
          fontWeight: 500,
          fontSize: 14,
          lineHeight: '143%'
        }
      }
    }
  },
  title: {
    fontWeight: 700,
    fontSize: 20,
    lineHeight: '140%',
    letterSpacing: '-0.1px',
    [theme.breakpoints.down('xs')]: {
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '150%',
      letterSpacing: '-0.1px'
    }
  },
  groupName: {},
  groupNameInput: {
    '& .MuiOutlinedInput-root': {
      fontSize: 14,
      borderRadius: 8,
      '& input': {
        padding: '11.7px 12px !important'
      }
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: colorSet.gray400,
      '&:hover': {
        borderColor: colorSet.gray900
      }
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: colorSet.gray900
    }
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 11,
    '& .text': {
      fontWeight: 500,
      lineHeight: '143%',
      letterSpacing: '-0.1px',
      marginLeft: 8,
      fontSize: 14
    },
    '& img': {
      userSelect: 'none',
      userDrag: 'none'
    },
    '& .member-img': {
      width: 20,
      height: 20
    }
  },
  attachments: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '143%',
    letterSpacing: '-0.1px'
  },
  groupsAction: {
    borderTop: `1px solid ${colorSet.gray200}`,
    padding: '16px 24px 0 24px',
    [theme.breakpoints.down('xs')]: {
      padding: 16
    }
  },
  buttonWrapper: {
    display: 'flex',
    '& button': {
      textTransform: 'none',
      marginLeft: 'auto',
      height: '40px !important',
      minWidth: '108px !important',
      fontSize: '14px !important',
      '&:first-child': {
        marginRight: 8
      }
    },
    '& .Mui-disabled': {
      backgroundColor: `${colorSet.gray400} !important`
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      '& button': {
        fontSize: '16px !important',
        width: '100% !important',
        height: '48px !important'
      },
      '& button + button': {
        marginLeft: 6
      }
    }
  }
}));

export const useStylesMembers = makeStyles((theme) => ({
  contactButtonWrapper: ({ checkedUsers }: { checkedUsers?: any }) => ({
    width: 40,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${colorSet.gray400}`,
    borderRadius: 8,
    cursor: 'pointer',
    '&:hover': {
      boxShadow: 'inset 999px 999px 0px rgba(0, 0, 0, 0.08)'
    },
    '& svg path': {
      fill: `${
        checkedUsers?.length > 0 ? colorSet.gray600 : colorSet.gray900
      } !important`
    }
  }),
  name: {
    fontSize: 20,
    color: `${colorSet.gray900}`,
    fontWeight: 500,
    '& .title-group-name': {
      fontWeight: 'normal'
    },
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      width: '100%',
      fontSize: 16,
      display: 'block',
      fontWeight: 500
    }
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  cancelBtn: {
    fontSize: 32,
    marginLeft: 'auto',
    cursor: 'pointer'
  },
  content: {
    padding: '0px 32px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px 12px'
    }
  },
  action: {
    padding: '0 24px 24px 24px',
    [theme.breakpoints.down('xs')]: {
      padding: '0 16px 16px 16px',
      position: 'fixed',
      bottom: 0,
      width: '100%',
      background: colorSet.gray000
    }
  },
  customAddButton: {
    minWidth: '88px !important',
    height: '40px !important',
    textTransform: 'none'
  },
  addButton: {
    cursor: 'pointer',
    paddingRight: 10,
    '& p': {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '143%',
      letterSpacing: '-0.1px',
      color: colorSet.primary450
    }
  },
  disabledAddButton: {
    paddingRight: 10,
    pointerEvents: 'none',
    '& p': {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '143%',
      letterSpacing: '-0.1px',
      color: colorSet.gray500
    }
  },
  wrapperBtn: {
    display: 'flex',
    '& button': {
      textTransform: 'none',
      marginLeft: 'auto',
      height: '48px !important',
      width: '132px !important',
      '&:first-child': {
        marginRight: 8
      }
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      '& button': {
        width: '100% !important'
      },
      '& button + button': {
        marginLeft: 6
      }
    }
  },
  actionNoBtn: {
    padding: '14px 0 22px 0',
    margin: '14px 22px 0 22px'
  },
  csDialogContent: {
    '& .MuiOutlinedInput-input': {
      padding: '11.7px 12px !important',
      fontSize: 14,
      '&:focus': {
        border: 'none'
      },
      [theme.breakpoints.down('xs')]: {
        width: 150
      }
    },
    display: 'flex',
    padding: 0,
    [theme.breakpoints.down('xs')]: {
      padding: 0
    },
    '& .MuiAutocomplete-root': {
      flex: '1 0 auto',
      borderRadius: 8,
      [theme.breakpoints.down('xs')]: {
        marginRight: 0
      }
    },
    '& .MuiInputBase-root.Mui-disabled': {
      color: colorSet.gray600,
      background: colorSet.gray100
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: colorSet.gray400,
      borderRadius: 8,
      '&:hover': {
        borderColor: colorSet.gray900
      }
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: colorSet.gray900
    },
    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: colorSet.gray400
    },
    '& .MuiInputBase-root': {
      padding: '0 !important',
      '&:focus': {
        border: `1px solid ${colorSet.primary500} !important`
      }
    },
    '& .MuiTextField-root.MuiFormControl-fullWidth': {
      width: '100%',
      flex: '1 0 auto',
      borderRadius: 8
    }
  },
  customPopover: {
    '& .MuiPopover-paper': {
      marginTop: 4,
      boxShadow:
        '0px 8px 16px 2px rgba(97, 97, 97, 0.1), 0px 16px 32px 2px rgba(97, 97, 97, 0.1)'
    },
    [theme.breakpoints.down('xs')]: {
      background: `${colorSet.backgroundDrop}`,
      '& .MuiPopover-paper': {
        boxShadow: 'none',
        background: 'transparent',
        minHeight: 'auto'
      }
    }
  },
  contentWrapper: {
    overflow: 'auto',
    marginTop: 10,
    '&::-webkit-scrollbar': {
      width: 4
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: colorSet.gray600,
      borderRadius: 8
    }
  },
  csDialogContentBottom: {
    padding: '0 !important',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    '&:first-child': {
      paddingTop: 0,
      [theme.breakpoints.down('xs')]: {
        marginTop: 0
      }
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 15,
      padding: '0 22px',
      alignItems: 'flex-start'
    }
  },
  csDialogContentList: {
    display: 'flex',
    paddingBottom: 8,
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 4,
      display: 'flex'
    }
  },
  csDialogContentImage: {
    flex: '1 0 auto',
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    [theme.breakpoints.down('xs')]: {
      gridRow: 'span 2 / span 2'
    },
    '& .MuiAvatar-root': {
      marginRight: 6
    }
  },
  usernameWrapper: {
    display: 'flex'
  },
  youText: {
    marginLeft: 2,
    fontWeight: 400
  },
  csDialogOwnerName: {
    maxWidth: 245,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '143%',
    letterSpacing: '-0.1px'
  },
  csDialogUserName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  csDialogEmail: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '13px !important',
    fontWeight: 400
  },
  ownerText: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '143%',
    letterSpacing: '-0.1px',
    color: colorSet.gray700,
    marginLeft: 'auto',
    flexShrink: 0,
    marginRight: 8
  },
  csDialogContentText: {
    fontWeight: 500,
    width: 'calc(100% - 185px)',
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    },
    '& div': {
      marginLeft: 2,
      fontSize: 14,
      lineHeight: '150%',
      [theme.breakpoints.down('xs')]: {
        marginLeft: 2
      }
    },
    '& div:first-child': {
      color: `${colorSet.gray900}`
    },
    '& div:last-child': {
      color: `${colorSet.gray700}`
    }
  },
  csDialogContentPosition: ({ checkedUsers }: { checkedUsers?: any }) => ({
    flexShrink: 0,
    color: `${colorSet.gray900}`,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '6px 6px 6px 12px',
    maxWidth: 150,
    marginLeft: 'auto',
    lineHeight: '20px',
    borderRadius: 4,
    fontSize: 14,
    fontWeight: 500,
    '&:hover': {
      boxShadow: 'inset 999px 999px 0px rgba(0, 0, 0, 0.08)'
    },
    '& svg path': {
      fill: `${
        checkedUsers?.length > 0 ? colorSet.gray600 : colorSet.gray900
      } !important`
    },
    '& svg': {
      marginLeft: 7
    }
  }),
  boxShadowContent: {
    boxShadow: 'inset 999px 999px 0px rgba(0, 0, 0, 0.12)'
  },
  csDialogContentPopup: {
    width: 200,
    '& .spacing': {
      marginLeft: 24
    },
    padding: '8px 0',
    '& .spacing-div': {
      height: 8,
      borderBottom: `1px solid ${colorSet.gray200}`
    },
    [theme.breakpoints.down('xs')]: {
      background: `${colorSet.gray000}`,
      borderRadius: 4,
      '& .spacing': {
        marginLeft: 40
      }
    },
    '.MuiPopover-root': {
      background: `${colorSet.backgroundDrop}`
    },
    '& .MuiTypography-body1': {
      padding: 15,
      display: 'flex',
      flexDirection: 'row',
      cursor: 'pointer',
      color: `${colorSet.gray900}`,
      fontSize: 16,
      lineHeight: '150%',
      '&:hover': {
        boxShadow: 'inset 999px 999px 0px rgba(0, 0, 0, 0.08)'
      }
    },
    '& .name': {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '144%',
      letterSpacing: '-0.1px',
      '& p': {
        color: `${colorSet.gray700}`
      }
    }
  },
  itemPopup: {
    height: 32,
    padding: '3px 6.5px 3px 12px !important',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 500,
    fontSize: 14,
    '& .mr-8': {
      marginRight: 8
    }
  },
  removedItem: {
    marginTop: 8,
    alignItems: 'center',
    padding: '23px 15px',
    color: `${colorSet.errorMain} !important`,
    justifyContent: 'inherit !important',
    '& svg path': {
      fill: `${colorSet.errorMain} !important`
    }
  },
  item: {
    alignItems: 'center'
  },
  iconCheck: {
    width: 20,
    height: 20,
    marginRight: 2,
    marginTop: 2,
    color: `${colorSet.primary500}`
  }
}));

export const useStylesAttachments = makeStyles((theme) => ({
  attachments: {
    marginTop: 12,
    [theme.breakpoints.down('xs')]: {
      marginTop: 16
    }
  },
  noAttachmentsText: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '143%',
    letterSpacing: '-0.1px',
    color: colorSet.gray700
  },
  attachment: {
    marginTop: 8,
    display: 'flex',
    padding: '4px 8px',
    backgroundColor: colorSet.gray100,
    borderRadius: 4,
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    alignItems: 'center',
    '& .ibm-cad-icon': {
      marginLeft: 8
    },
    '& .file-name': {
      fontSize: 14,
      marginLeft: 8,
      fontWeight: 400,
      lineHeight: '143%',
      letterSpacing: '-0.1px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }
  },
  attachmentIcon: {
    width: 16,
    height: 16,
    userSelect: 'none',
    userDrag: 'none'
  },
  closeIcon: {
    width: 20,
    height: 20,
    marginLeft: 'auto',
    cursor: 'pointer',
    userSelect: 'none',
    userDrag: 'none'
  }
}));

export const useStylesInsertFromMyDrive = makeStyles((theme) => ({
  paper: {
    maxWidth: 520,
    minWidth: 520,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: 0,
      height: '100%',
      maxHeight: '100%',
      maxWidth: '100%',
      minWidth: '100%',
      borderRadius: 0
    },
    height: 517,
    borderRadius: 12
  },
  rootDialog: {
    zIndex: '10000 !important' as unknown as 10000
  },
  rootTitle: {
    padding: '24px 24px 16px 24px',
    display: 'flex',
    fontSize: 20,
    fontWeight: 700,
    alignItems: 'center',
    lineHeight: '160%',
    letterSpacing: '-0.1px',
    [theme.breakpoints.down('xs')]: {
      padding: '11px 24px',
      border: `1px solid ${colorSet.gray300}`,
      display: 'inline-block',
      textAlign: 'center',
      fontWeight: 500,
      lineHeight: '150%',
      fontSize: 16
    }
  },
  arrowBackIosOutlinedIcon: {
    width: 14,
    color: colorSet.gray100,
    [theme.breakpoints.down('xs')]: {
      color: colorSet.gray900,
      fontWeight: 500,
      position: 'absolute',
      left: 24,
      top: 12
    }
  },
  arrowBackIosOutlinedWrapper: {
    backgroundColor: colorSet.primary450,
    borderRadius: 8,
    marginRight: 12,
    width: 36,
    height: 36,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: colorSet.primary600
    }
  },
  cloudIconWrapper: {
    backgroundColor: colorSet.primary450,
    borderRadius: 8,
    marginRight: 12,
    width: 36,
    height: 36,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      width: 20,
      height: 20,
      '& path': {
        fill: `${colorSet.gray000} !important`
      }
    }
  },
  pointerCursor: {
    cursor: 'pointer'
  },
  csDialogContent: {
    padding: 0,
    overflow: 'hidden'
  },
  action: {
    border: `1px solid ${colorSet.gray200}`,
    padding: '16px 24px 24px 24px',
    [theme.breakpoints.down('xs')]: {
      padding: 16
    }
  },
  wrapperBtn: {
    display: 'flex',
    '& button': {
      textTransform: 'none',
      marginLeft: 'auto',
      height: '40px !important',
      fontSize: '14px !important',
      fontWeight: '500 !important',
      lineHeight: '143% !important',
      letterSpacing: '-0.1px',
      padding: '0 !important',
      minWidth: '108px !important'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      '& button': {
        width: '100%',
        fontSize: '16px !important',
        height: '48px !important'
      },
      '& button + button': {
        marginLeft: 6
      }
    }
  },
  csBreadcrumb: {
    display: 'flex',
    height: 28,
    margin: '0 24px 0 16px',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      margin: '6px 24px 0 16px'
    },
    '& .cs-typography': {
      height: '100%',
      overflow: 'auto'
    },
    '& .cursor-pointer': {
      cursor: 'pointer'
    },
    '& .MuiBreadcrumbs-li': {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '143%',
      letterSpacing: '-0.1px',
      color: colorSet.gray900,
      '& .MuiLink-underlineHover': {
        padding: '4px 8px',
        borderRadius: 8,
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
          textDecoration: 'none !important',
          boxShadow: 'inset 999px 999px 0px rgba(0, 0, 0, 0.08)',
          [theme.breakpoints.down('xs')]: {
            boxShadow: 'none'
          }
        }
      },
      '&:last-child': {
        fontWeight: 700,
        cursor: 'auto'
      }
    },
    '& .MuiBreadcrumbs-separator': {
      marginLeft: 0,
      marginRight: 0
    }
  },
  searchResultWrapper: {
    maxHeight: 288,
    minHeight: 288,
    position: 'relative',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: 4
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: colorSet.gray600,
      borderRadius: 8
    },
    [theme.breakpoints.down('xs')]: {
      maxHeight: 'calc(100vh - 86px - 48px - 40px - 40px - 100px)',
      margin: 0,
      overflow: 'auto'
    }
  },
  checkIconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 10,
    marginLeft: 'auto'
  },
  noDocuments: {
    fontSize: 14,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: 'absolute',
    margin: 'auto',
    fontWeight: 500,
    lineHeight: '143%',
    letterSpacing: '-0.1px',
    color: colorSet.gray2000
  }
}));

export const MuiDiv = styled.div`
  &.cs-breadcrumb {
    overflow: auto;
  }
  &.connect-empty {
    align-items: center;
    margin-top: 100px;
    ${({ theme }) => `
      ${theme.breakpoints.down('xs')} {
        margin-top: 53px;
      }
    `}
    img {
      height: 144px;
      width: 144px;
      margin-bottom: 36px;
    }
  }
  .sub-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    & .cursor-pointer {
      cursor: pointer;
    }

    .MuiBreadcrumbs-li {
      font-size: 20px;
      font-weight: 500;
      color: ${colorSet.gray900};
      &:nth-child(1) {
        font-weight: bold;
        .MuiLink-underlineHover:hover {
          text-decoration: none !important;
        }
      }
      &:last-child {
        pointer-events: none;
        cursor: auto;
      }
    }
    .label-group {
      cursor: pointer;
      display: flex;
      align-items: center;
      .MuiSvgIcon-root {
        color: ${colorSet.gray900};
        margin-right: 4px;
      }
    }
    ${({ theme }) => `
      ${theme.breakpoints.down('xs')} {
        margin-bottom: 24px;
        padding: 0px 16px;
      }
    `}
  }
`;

export const MuiBoxTable = styled(Box)`
  .table-header {
    ${({ theme }) => `
      ${theme.breakpoints.down('xs')} {
        padding: 4px 24px 3px 16px;
      }
    `}
    z-index: 9;
    position: sticky;
    background: ${colorSet.gray000};
    top: 0;
    border-bottom: 1px solid ${colorSet.gray200};
    padding: 4px 40px 3px 16px;
    display: flex;
    align-items: center;
    color: ${colorSet.gray700};
    .checkbox-wrapper {
      margin-right: 12px;
      .MuiButtonBase-root {
        padding: 6px;
      }
      .MuiCheckbox-indeterminate {
        color: ${colorSet.primary450} !important;
      }
      .MuiSvgIcon-root {
        width: 20px !important;
        height: 20px !important;
      }
    }
    .table-header-th-one {
      width: calc(100% - 120px);
    }
    .table-header-th-two {
      width: 120px;
    }
  }
`;

interface IMuiBoxTableItemProps {
  isChecked?: boolean;
  isHover?: boolean;
  isDisabled?: boolean;
}

export const MuiBoxTableItem = styled.div.attrs(
  (props: IMuiBoxTableItemProps) => props
)`
  box-shadow: ${(props) =>
    props.isDisabled
      ? 'inset 999px 999px 0px rgba(75, 136, 255, 0.08)'
      : props.isChecked
      ? `inset 999px 999px 0px rgba(75, 136, 255, 0.08)`
      : ''};
  &:hover {
    box-shadow: ${(props) =>
      props.isHover ? `inset 999px 999px 0px rgba(75, 136, 255, 0.16)` : ''};
  }
  display: flex;
  opacity: ${(props) => (props.isDisabled ? 0.5 : 1)};
  align-items: center;
  user-select: none;
  cursor: pointer;
  padding: 0 24px;
  .table-item-share {
    ${({ theme }) => `
      ${theme.breakpoints.down('xs')} {
        padding-right: 0;
      }
    `}
    width: 100%;
    padding-right: 6px;
    display: flex;
    align-items: center;
    .table-item-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .ibm-cad-icon {
      margin-right: 8px;
    }
    & svg {
      width: 16px;
      height: 16px;
      margin-left: 4px;
      & path {
        fill: ${colorSet.gray2000} !important;
      }
    }
  }
  .table-item-share-icon {
    color: ${colorSet.gray550};
    margin-left: 8px;
  }
  .table-item-td-one {
    ${({ theme }) => `
      ${theme.breakpoints.down('xs')} {
        width: 100%;
      }
    `}
    width: calc(100% - 120px);
    display: flex;
    min-height: 48px;
    align-items: center;
    .checkbox-wrapper {
      margin-right: 12px;
      .MuiButtonBase-root {
        padding: 6px 6px 6px 0;
        margin-left: -2px;
      }
      .MuiSvgIcon-root {
        width: 20px !important;
        height: 20px !important;
      }
    }
    .customized-checkbox {
      margin-right: 10px;
    }
    .MuiTypography-body2 {
      margin-left: 12px;
    }
    .folder-icon {
      color: ${colorSet.gray600};
      font-size: 36.5px;
    }
    & .icon-svg {
      display: flex;
      min-width: 36px;
      justify-content: center;
      & img {
        width: 27px;
        height: auto;
      }
    }
    & .icon-svg-file-rar {
      display: flex;
      min-width: 36px;
      justify-content: center;
      & img {
        width: 24px;
        height: auto;
        object-fit: scale-down;
      }
    }
  }
  .custom-span {
    margin-left: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: ${colorSet.gray900};
    font-size: 14px;
    font-family: Noto Sans KR, sans-serif;
    font-weight: normal;
    line-height: 143%;
    letter-spacing: -0.1px;
  }
  .table-item-td-two {
    display: flex;
    align-items: center;
    min-height: 48px;
    width: 120px;
  }
`;
