import { makeStyles } from '@material-ui/core';
import { colorSet } from '@components/Provider';

export const useStyles = makeStyles((theme) => ({
  popper: {
    'z-index': '1399',
    width: 343,
    top: 'unset !important',
    left: 'unset !important',
    bottom: '32px',
    right: '100px',
    '& .MuiCollapse-wrapper': {
      maxHeight: 308,
      width: '100%',
      overflow: 'scroll',
      '&::-webkit-scrollbar': {
        width: 4
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: colorSet.gray600,
        borderRadius: 8
      }
    },
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100% - 32px)',
      maxWidth: 343,
      bottom: '16px',
      left: '50% !important',
      right: 'unset',
      transform: 'translateX(-50%)'
    }
  },
  summary: {
    backgroundColor: colorSet.gray900,
    padding: '14px 24px !important',
    borderRadius: 4,
    '& .MuiAccordionSummary-content': {
      margin: '0 !important'
    },
    '& p': {
      color: colorSet.gray000,
      lineHeight: '36px'
    },
    '& svg': {
      fill: colorSet.gray000
    },
    '&.Mui-expanded': {
      '& .expandLess': {
        transform: 'rotate(180deg)',
        transition: 'all 0.3s ease'
      }
    }
  },
  summaryContent: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    '& .buttonGroup': {
      display: 'flex',
      justifyContent: 'flex-end',
      '& .expandLess': {
        margin: 6
      },
      '& .closeButton': {
        padding: 0,
        margin: 6
      }
    }
  },
  containerList: {
    padding: '0 !important',
    width: '100%',
    '& .list': {
      width: '100%'
    }
  }
}));

export const useStatusIconStyles = makeStyles((theme) => ({
  errorBox: {
    display: 'flex',
    alignItems: 'center',
    '& button': {
      marginRight: 10,
      '& span': {
        fontWeight: 400
      }
    },
    '& svg': {
      fill: colorSet.errorMain
    },
    '& p': {
      marginRight: 10
    }
  },
  customButton: {
    textTransform: 'none'
  },
  checkCircleIcon: {
    fill: colorSet.successBase
  }
}));
