import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { isArray } from 'lodash';

import { CustomizedTypography } from '@components/Customized';
import { NavItemWrapper, NavItemIcon } from './styles';

type Props = {
  path?: string[] | string;
  icon: React.ReactChild;
  activeIcon: React.ReactChild;
  label?: string;
  redirectTo: string;
  children?: React.ReactChild | React.ReactFragment;
};

function NavItem({
  path,
  icon,
  label,
  redirectTo,
  children,
  activeIcon
}: Props) {
  const [isSelected, setIsSelected] = useState(false);
  const { pathname } = useLocation();
  const isHomePage = !!(pathname === '/');
  const history = useHistory();

  useEffect(() => {
    if (!path) return setIsSelected(false);
    const currentPathSplit = pathname
      .split('/')
      .filter((pathItem) => !!pathItem)[0];

    if (isArray(path)) {
      return setIsSelected(path.includes(`/${currentPathSplit}`));
    }
    setIsSelected(path === `/${currentPathSplit}`);
  }, [path, pathname]);

  return (
    <NavItemWrapper
      isSelected={isSelected}
      isHomePage={isHomePage}
      onClick={() => history.push(redirectTo)}
    >
      <NavItemIcon>{isSelected ? activeIcon : icon}</NavItemIcon>
      <CustomizedTypography variant="caption">{label}</CustomizedTypography>
      {children}
    </NavItemWrapper>
  );
}

export default NavItem;
