import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import ScrollContainer from 'react-indiana-drag-scroll';
import AppConfig from '@config/index';
import { CapaConnectMyFile } from '@generated/Common/graphql';
import useDashboard from '@hooks/useDashboard';
import useFiles from '@hooks/useFiles';
import { colorSet, useSnackbar } from '@components/Provider';
import { CustomizedTypography } from '@components/Customized';
import ThumbnailDefaultImg from '../Common/assets/ThumbnailDefaultImg.png';
import {
  contentHeaderHeight,
  contentMobileHeaderHeight
} from '../Common/types';
import { Box, createStyles, makeStyles } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import i18n from '@i18n/index';
import { useTranslation } from 'react-i18next';
interface StylesViewerProps {
  marginBottom?: string;
}

let intViewportHeight = window.innerHeight;

const useStylesViewer = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      background: `${colorSet.gray000}`
    },
    header: {
      borderBottom: `1px solid ${colorSet.gray400}`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      minHeight: contentHeaderHeight,
      height: contentHeaderHeight,
      backgroundColor: colorSet.gray000,
      '& .MuiSvgIcon-root': {
        fontSize: 24,
        marginLeft: 'auto',
        cursor: 'pointer'
      },
      '& img': {
        cursor: 'pointer',
        height: 24,
        width: 196
      },
      padding: '0px 24px',
      '& .get-app-icon': {
        cursor: 'pointer',
        padding: 9,
        marginRight: 6
      },
      [theme.breakpoints.down('xs')]: {
        height: contentMobileHeaderHeight,
        minHeight: contentMobileHeaderHeight,
        padding: '0px 16px',
        '& .get-app-icon': {
          display: 'none'
        },
        '& img': {
          cursor: 'pointer',
          height: 18,
          width: 147
        }
      }
    },
    menuWrapper: {
      color: colorSet.gray900,
      display: 'flex',
      alignItems: 'center'
    },
    main: {},
    subTitle: {
      padding: '14px 24px',
      height: 78,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& .get-app-icon': {
        cursor: 'pointer',
        padding: 9,
        marginRight: 6,
        display: 'none'
      },
      [theme.breakpoints.down('xs')]: {
        height: 66,
        padding: '12px 16px',
        '& .get-app-icon': {
          display: 'block'
        }
      }
    },
    iframe: {
      height: (props: StylesViewerProps) =>
        props.marginBottom === 'true'
          ? `calc(${intViewportHeight}px - 60px - 78px - 60px)`
          : `calc(${intViewportHeight}px - 60px - 78px)`,
      [theme.breakpoints.down('xs')]: {
        height: (props: StylesViewerProps) =>
          props.marginBottom === 'true'
            ? `calc(${intViewportHeight}px - 48px - 66px - 60px)`
            : `calc(${intViewportHeight}px - 48px - 66px)`
      }
    },
    thumbnailList: {
      display: 'flex',
      flexDirection: 'row',
      maxWidth: '100%',
      whiteSpace: 'nowrap',
      overflowX: 'hidden',
      height: 60,
      borderTop: `1px solid ${colorSet.gray200}`
    },
    thumbnail: {
      width: 60,
      height: 60,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      borderRight: `1px solid ${colorSet.gray200}`,
      '& img': {
        width: 58,
        height: 58
      },
      '&.selected': {
        '& img': {
          border: `1px solid ${colorSet.gray900}`
        }
      }
    }
  })
);

interface Props {
  files?: Array<CapaConnectMyFile>;
}

const ViewerPresenter: React.FC<Props> = () => {
  const { dashboard } = useDashboard();
  const classes = useStylesViewer({
    marginBottom:
      dashboard.files && dashboard.files.length > 1 ? 'true' : undefined
  });
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { onDownloadConnectFile } = useFiles();
  const [selectedFile, setSelectedFile] = useState(
    dashboard.files ? dashboard.files[0] : undefined
  );
  const [selectedFileIndex, setSelectedFileIndex] = useState(1);
  const languageValue = i18n.language;

  useEffect(() => {
    if (dashboard.files) {
      setSelectedFile(dashboard.files[0]);
    }
  }, [dashboard.files]);

  const onDownloadFiles = (file?: CapaConnectMyFile) => {
    if (file) {
      onDownloadConnectFile(file)
        .then(() => {
          enqueueSnackbar(t('snackbar.download_success'), {
            variant: 'success'
          });
        })
        .catch(() => {
          enqueueSnackbar(t('snackbar.download_error'), {
            variant: 'error'
          });
        });
    }
  };

  const ThumbnailImg = ({ src }: { src: string }) => {
    const [error, setError] = useState(false);

    return (
      <img
        title="viewer"
        width="100%"
        height="100%"
        src={error ? ThumbnailDefaultImg : src}
        onError={() => setError(true)}
      />
    );
  };

  return (
    <div className={classes.root}>
      {/*<div className={classes.header}>
        <img
          src={CapaLogo}
          alt="Logo"
          onClick={() => history.push(myDrivePathName)}
        />
        <div className={classes.menuWrapper}>
          <div className="get-app-icon">
            <GetApp onClick={() => onDownloadFiles(selectedFile)} />
          </div>
          <GnbPopupMenu />
        </div>
      </div> */}
      <div className={classes.main}>
        <div className={classes.subTitle}>
          <div className="files-info">
            <CustomizedTypography variant="body1" gutterBottoms={2}>
              {selectedFile?.fileName}
            </CustomizedTypography>
            <CustomizedTypography variant="body1">
              {selectedFileIndex}/{dashboard.files?.length}
            </CustomizedTypography>
          </div>
          <div className="get-app-icon">
            <GetApp onClick={() => onDownloadFiles(selectedFile)} />
          </div>
        </div>
        <div className={classes.iframe}>
          <iframe
            title="viewer"
            width="100%"
            height="100%"
            src={`${AppConfig.capaViewerUrl}/viewer.html?modelId=${AppConfig.capaViewerApi}/${selectedFile?.drawingFile}&lang=${languageValue}`}
          />
        </div>
        {dashboard.files && dashboard.files.length > 1 && (
          <ScrollContainer className={classes.thumbnailList}>
            {dashboard.files?.map((file, index) => {
              const srcUrl = `${AppConfig.capaViewerApi}/${
                file?.fileId + '.png'
              }`;
              return (
                <Box
                  className={clsx(
                    classes.thumbnail,
                    file.fileId === selectedFile?.fileId ? 'selected' : ''
                  )}
                  key={file.fileId}
                  onClick={() => {
                    setSelectedFileIndex(index + 1);
                    setSelectedFile(file);
                  }}
                >
                  <ThumbnailImg src={srcUrl} />
                </Box>
              );
            })}
          </ScrollContainer>
        )}
      </div>
    </div>
  );
};

export default ViewerPresenter;
