import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
  CapaErrorCode,
  useRequestEmailVerificationCodeMutation,
  useChangeEmailMutation,
  ChangeEmailInput
} from '@generated/Common/graphql';
import { clearStorageAll } from '@utils/storage';
import useAuth from '@hooks/useAuth';
import useUserActionLog, {
  Enum_Action_Button_Enum,
  CapaActivityType
} from '@hooks/useUserActionLog';
import { CustomizedButton, CustomizedTypography } from '@components/Customized';
import { colorSet, useSnackbar } from '@components/Provider';
import { StyledInput } from './styles';
import { makeStyles, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const EmailEdit = () => {
  const { t } = useTranslation();
  const { handleSubmit, register, getValues, formState, setError, errors } =
    useForm<ChangeEmailInput>({ mode: 'onChange' });
  const { enqueueSnackbar } = useSnackbar();
  const [requestEmailVerificationCode] =
    useRequestEmailVerificationCodeMutation();
  const [changeEmail] = useChangeEmailMutation();
  const { onInitAuthState } = useAuth();
  const history = useHistory();
  const { onInsertUserActionLogOne } = useUserActionLog();
  const insertLog = (action: Enum_Action_Button_Enum) => {
    onInsertUserActionLogOne(action, CapaActivityType.ButtonClick);
  };

  const {
    label,
    emailInputBox,
    verificationNumberInput,
    buttonGruop,
    defultColorButton
  } = useStyles();
  const [hasEmailValue, setHasEmailValue] = useState<boolean>(false);
  const [codeSent, setCodeSent] = useState<boolean>(false);
  const { Success, DuplicateEmail, CodeMismatch, ParmeterValidationError } =
    CapaErrorCode;

  /* 인증번호 발송/재발송 */
  const sendVerficationCode = () => {
    requestEmailVerificationCode({
      variables: {
        email: getValues('newEmail')
      }
    }).then((resp) => {
      const result = resp.data?.requestEmailVerificationCode?.result;
      switch (result) {
        case Success:
          enqueueSnackbar('인증번호를 발송했습니다.', { variant: 'success' });
          setCodeSent(true);
          break;
        case ParmeterValidationError:
          setError('newEmail', { message: '유효하지 않은 이메일입니다.' });
          break;
        case DuplicateEmail:
          enqueueSnackbar('이미 사용중인 이메일입니다', { variant: 'error' });
          break;
        default:
          enqueueSnackbar('인증번호 발송에 실패했습니다.', {
            variant: 'error'
          });
      }
    });
  };

  /* 제출하기 */
  const onSubmit = (data: ChangeEmailInput) => {
    insertLog(Enum_Action_Button_Enum.Button_MyPage_Edit_Email_Submit);

    if (!data.newEmail) {
      enqueueSnackbar('이메일을 입력해주세요.', { variant: 'error' });
      return;
    }
    changeEmail({ variables: data })
      .then((resp) => {
        const result = resp.data?.changeEmail.result;
        switch (result) {
          case Success:
            enqueueSnackbar(
              '이메일 주소가 수정되었습니다. 수정하신 이메일 주소로 다시 로그인 해주세요.',
              { variant: 'success' }
            );
            clearStorageAll();
            onInitAuthState();
            history.replace('/login');
            break;
          case CodeMismatch:
            enqueueSnackbar('인증번호를 다시 확인해주세요.', {
              variant: 'error'
            });
            break;
          case ParmeterValidationError:
            enqueueSnackbar('인증번호를 다시 확인해주세요.', {
              variant: 'error'
            });
            break;
          default:
            enqueueSnackbar('이메일 수정에 실패했습니다.', {
              variant: 'error'
            });
        }
      })
      .catch(() => {
        enqueueSnackbar('이메일 수정에 실패했습니다.', {
          variant: 'error'
        });
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CustomizedTypography
        variant="body1"
        color="textSecondary"
        className={label}
      >
        {t('account_page.email')}
      </CustomizedTypography>
      <Box className={emailInputBox}>
        <StyledInput
          error={errors.newEmail ? true : false}
          helperText={errors.newEmail?.message}
          variant="outlined"
          size="small"
          fullWidth
          placeholder="이메일을 입력해주세요"
          onFocus={() => {
            insertLog(
              Enum_Action_Button_Enum.Button_MyPage_Edit_Email_Input_NewEmail
            );
          }}
          inputProps={{
            name: 'newEmail',
            ref: register({ required: true })
          }}
          onChange={(e) => {
            e.currentTarget.value
              ? setHasEmailValue(true)
              : setHasEmailValue(false);

            setCodeSent(false);
          }}
        />
        <CustomizedButton
          variant="outlined"
          color={!codeSent ? 'primary' : 'default'}
          className={!codeSent ? undefined : defultColorButton}
          disabled={!hasEmailValue}
          onClick={() => {
            insertLog(
              Enum_Action_Button_Enum.Button_MyPage_Edit_Email_SendVerificationCode
            );
            sendVerficationCode();
          }}
        >
          {!codeSent ? t('account_page.verify') : t('account_page.resend')}
        </CustomizedButton>
      </Box>
      <StyledInput
        className={verificationNumberInput}
        variant="outlined"
        size="small"
        fullWidth
        placeholder={t('account_page.code_placeholder')}
        InputProps={{
          disabled: !codeSent
        }}
        onFocus={() => {
          insertLog(
            Enum_Action_Button_Enum.Button_MyPage_Edit_Email_Input_VerificationCode
          );
        }}
        inputProps={{
          name: 'code',
          ref: register({
            required: true
          })
        }}
      />
      <Box className={buttonGruop}>
        <CustomizedButton
          variant="outlined"
          color="default"
          type="button"
          onClick={() => {
            history.push('/mypage/account');
          }}
        >
          {t('account_page.cancel')}
        </CustomizedButton>
        <CustomizedButton
          variant="contained"
          color="primary"
          type="submit"
          disabled={!formState.isValid}
        >
          {t('account_page.submit')}
        </CustomizedButton>
      </Box>
    </form>
  );
};

const useStyles = makeStyles((theme) => ({
  label: {
    marginBottom: 12
  },
  emailInputBox: {
    display: 'flex',
    width: '100%',
    marginBottom: 12,
    '& button': {
      marginLeft: 8,
      [theme.breakpoints.down('xs')]: {
        padding: 0,
        minWidth: '100px !important'
      }
    }
  },
  verificationNumberInput: {
    marginBottom: 24
  },
  defultColorButton: {
    border: `1px solid ${colorSet.gray600}`,
    color: colorSet.gray700
  },
  buttonGruop: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      '&:not(:last-child)': {
        marginRight: 8
      }
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      '& button': {
        flex: 1
      }
    }
  }
}));

export default EmailEdit;
