import { createAction } from 'typesafe-actions';
import { MyDriveState } from './types';

export const INIT_DETAIL_STATE = 'INIT_DETAIL_STATE';
export const initDetailState = createAction(INIT_DETAIL_STATE)();

export const SET_FILE_DETAIL = 'SET_FILE_DETAIL';
export const setFileDetail = createAction(SET_FILE_DETAIL)<MyDriveState>();

export const SET_SHOW_HIDE_MENU_CREATE = 'SET_SHOW_HIDE_MENU_CREATE';
export const setShowHideMenuCreate = createAction(
  SET_SHOW_HIDE_MENU_CREATE
)<MyDriveState>();

export const SET_PATH = 'SET_PATH';
export const setPath = createAction(SET_PATH)<MyDriveState>();

export const SET_LOCATION_NAME = 'SET_LOCATION_NAME';
export const setLocationName = createAction(SET_LOCATION_NAME)<MyDriveState>();

export const SET_SHOW_HIDE_SHARE_DIALOG = 'SET_SHOW_HIDE_SHARE_DIALOG';
export const setShowHideShareDialog = createAction(
  SET_SHOW_HIDE_SHARE_DIALOG
)<MyDriveState>();

export const SET_SHARE_FILES_TO_TYPE = 'SET_SHARE_FILES_TO_TYPE';
export const setShareFilesToType = createAction(
  SET_SHARE_FILES_TO_TYPE
)<MyDriveState>();

export const SET_SHARE_DIALOG_SCREEN = 'SET_SHARE_DIALOG_SCREEN';
export const setShareDialogScreen = createAction(
  SET_SHARE_DIALOG_SCREEN
)<MyDriveState>();

export const SET_VALUE_GROUP_NAME_DIALOG = 'SET_VALUE_GROUP_NAME_DIALOG';
export const setValueGroupNameDialog = createAction(
  SET_VALUE_GROUP_NAME_DIALOG
)<MyDriveState>();

export const SET_SHOW_HIDE_UPLOAD_FROM_MY_DRIVE_DIALOG =
  'SET_SHOW_HIDE_UPLOAD_FROM_MY_DRIVE_DIALOG';
export const setShowHideUploadFromMyDriveDialog = createAction(
  SET_SHOW_HIDE_UPLOAD_FROM_MY_DRIVE_DIALOG
)<MyDriveState>();

export const SET_SHOW_HIDE_DOWNLOAD_PERMISSION_DIALOG =
  'SET_SHOW_HIDE_DOWNLOAD_PERMISSION_DIALOG';
export const setShowHideDownloadPermissionDialog = createAction(
  SET_SHOW_HIDE_DOWNLOAD_PERMISSION_DIALOG
)<MyDriveState>();

export const SET_ID_GROUP_NAME = 'SET_ID_GROUP_NAME';
export const setIdGroupName = createAction(SET_ID_GROUP_NAME)<MyDriveState>();

export const SET_CHAT_ROOM_ID_GROUP_NAME = 'SET_CHAT_ROOM_ID_GROUP_NAME';
export const setChatRoomIdGroupName = createAction(
  SET_CHAT_ROOM_ID_GROUP_NAME
)<MyDriveState>();

export const SET_SELECTED_GROUP_SHARED = 'SET_SELECTED_GROUP_SHARED';
export const setSelectedGroupShared = createAction(
  SET_SELECTED_GROUP_SHARED
)<MyDriveState>();

export const SET_SHOW_FILE_VIEWER = 'SET_SHOW_FILE_VIEWER';
export const setShowFileViewer =
  createAction(SET_SHOW_FILE_VIEWER)<MyDriveState>();

export const SET_SHOW_HIDE_CONFIRM_DIALOG = 'SET_SHOW_HIDE_CONFIRM_DIALOG';
export const setShowHideConfirmDialog = createAction(
  SET_SHOW_HIDE_CONFIRM_DIALOG
)<MyDriveState>();

export const SET_SELECTED_FILES_UPLOAD_IN_CHAT =
  'SET_SELECTED_FILES_UPLOAD_IN_CHAT';
export const setSelectedFilesUploadInChat = createAction(
  SET_SELECTED_FILES_UPLOAD_IN_CHAT
)<MyDriveState>();

export const SET_INTERNAL_FILE_FROM_COMPUTER =
  'SET_INTERNAL_FILE_FROM_COMPUTER';
export const setInternalFileFromComputer = createAction(
  SET_INTERNAL_FILE_FROM_COMPUTER
)<MyDriveState>();

export const SET_UPLOAD_FILE_TAB = 'SET_UPLOAD_FILE_TAB';
export const setUploadFileTab =
  createAction(SET_UPLOAD_FILE_TAB)<MyDriveState>();

export const SET_ADD_FILES_FROM_TYPE = 'SET_ADD_FILES_FROM_TYPE';
export const setAddFilesFromType = createAction(
  SET_ADD_FILES_FROM_TYPE
)<MyDriveState>();

export const SET_TOGGLE_GROUP_FILES = 'SET_TOGGLE_GROUP_FILES';
export const setToggleGroupFiles = createAction(
  SET_TOGGLE_GROUP_FILES
)<MyDriveState>();

export const SET_TOGGLE_REMOVE_GROUP_FILES = 'SET_TOGGLE_REMOVE_GROUP_FILES';
export const setToggleRemoveGroupFiles = createAction(
  SET_TOGGLE_REMOVE_GROUP_FILES
)<MyDriveState>();

export const SET_FIRST_SELECTED_DRAW_FILE = 'SET_FIRST_SELECTED_DRAW_FILE';
export const setFirstSelectedDrawFile = createAction(
  SET_FIRST_SELECTED_DRAW_FILE
)<MyDriveState>();

export const SET_FILE_NAME_IN_GROUP_ROOM = 'SET_FILE_NAME_IN_GROUP_ROOM';
export const setFileNameInGroupRoom = createAction(
  SET_FILE_NAME_IN_GROUP_ROOM
)<MyDriveState>();

export const SET_ACTION_ANNOTATION_SUCCESS = 'SET_ACTION_ANNOTATION_SUCCESS';
export const setActionAnnotationSuccess = createAction(
  SET_ACTION_ANNOTATION_SUCCESS
)<MyDriveState>();

export const SET_ACTIVE_FILE_ID_IN_GROUP_ROOM =
  'SET_ACTIVE_FILE_ID_IN_GROUP_ROOM';
export const setActiveFileIdInGroupRoom = createAction(
  SET_ACTIVE_FILE_ID_IN_GROUP_ROOM
)<MyDriveState>();

export const SET_TOTAL_NOTIFICATION = 'SET_TOTAL_NOTIFICATION';
export const setTotalNotification = createAction(
  SET_TOTAL_NOTIFICATION
)<MyDriveState>();

export const SET_SELECT_DRAW_FILE_FROM_COMMENT_LIST =
  'SET_SELECT_DRAW_FILE_FROM_COMMENT_LIST';
export const setSelectDrawFileFromCommentList = createAction(
  SET_SELECT_DRAW_FILE_FROM_COMMENT_LIST
)<MyDriveState>();

export const SET_RENAME_SUCCESS_STATUS = 'SET_RENAME_SUCCESS_STATUS';
export const setRenameSuccessStatus = createAction(
  SET_RENAME_SUCCESS_STATUS
)<MyDriveState>();

export const SET_UPLOAD_FILE_FROM_COMPUTER_STATUS =
  'SET_UPLOAD_FILE_FROM_COMPUTER_STATUS';
export const setUploadFileFromComputerStatus = createAction(
  SET_UPLOAD_FILE_FROM_COMPUTER_STATUS
)<MyDriveState>();

export const SET_DRAG_DROP_FILES_TO_GROUP_ROOM =
  'SET_DRAG_DROP_FILES_TO_GROUP_ROOM';
export const setDragDropFilesToGroupRoom = createAction(
  SET_DRAG_DROP_FILES_TO_GROUP_ROOM
)<MyDriveState>();

export const SET_MY_DRIVE_PAGE_LOADING = 'SET_MY_DRIVE_PAGE_LOADING';
export const setMyDrivePageLoading = createAction(
  SET_MY_DRIVE_PAGE_LOADING
)<MyDriveState>();

export const SET_SUCCESSFULLY_SHARE_CONTENT = 'SET_SUCCESSFULLY_SHARE_CONTENT';
export const setSuccessfullyShareContent = createAction(
  SET_SUCCESSFULLY_SHARE_CONTENT
)<MyDriveState>();

export const SET_SHOW_HIDE_REQUEST_FOR_DOWNLOAD =
  'SET_SHOW_HIDE_REQUEST_FOR_DOWNLOAD';
export const setShowHideRequestForDownload = createAction(
  SET_SHOW_HIDE_REQUEST_FOR_DOWNLOAD
)<MyDriveState>();
