import { gql } from '@apollo/client';

export const FetchMyContacts = gql`
  query fetchConnectUserContactList($limit: Float, $offset: Float) {
    fetchConnectUserContactList(input: { limit: $limit, offset: $offset }) {
      result
      errorMessage
      data {
        id
        email
        nickName
        isCapaUser
        memo
      }
    }
  }
`;

export const CreateMyContactUser = gql`
  mutation createConnectContactUser(
    $contactUserEmail: String!
    $nickName: String
    $memo: String
  ) {
    createConnectContactUser(
      input: {
        contactUserEmail: $contactUserEmail
        nickName: $nickName
        memo: $memo
      }
    ) {
      result
      errorMessage
    }
  }
`;
export const EditMyContactUser = gql`
  mutation updateConnectContactUser(
    $contactUserEmail: String!
    $nickName: String
    $memo: String
  ) {
    updateConnectContactUser(
      input: {
        contactUserEmail: $contactUserEmail
        nickName: $nickName
        memo: $memo
      }
    ) {
      result
      errorMessage
    }
  }
`;
export const DeleteMyContactUser = gql`
  mutation removeConnectContactUser(
    $contactUserEmails: [String!]!
  ) {
    removeConnectContactUser(
      input: {
        contactUserEmails: $contactUserEmails
      }
    ) {
      result
      errorMessage
    }
  }
`;

export const FetchRecommendedContactUsersList = gql`
    query fetchRecommendedContactUsersList($chatRoomUserId: String!, $limit: Float, $offset: Float) {
    fetchRecommendedContactUsersList(
      input: { chatRoomUserId: $chatRoomUserId, limit: $limit, offset: $offset }
    ) {
      result
      errorMessage
      data {
        email
        nickName
        isCapaUser
      }
    }
  }
`
