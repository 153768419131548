import React from 'react';
import AsideMenuPresenter from './AsideMenuPresenter';

interface Props {
  clickNewGroup?: () => void;
}

const AsideMenuContainer = (props: Props) => {
  const { clickNewGroup } = props;

  return <AsideMenuPresenter clickNewGroup={clickNewGroup} />;
};

export default AsideMenuContainer;
