import { useCallback, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';
import {
  FetchConnectAnnotations,
  fetchCapaConnectSharedFile,
  FetchConnectChatMessages
} from '@generated/Drive/MyDrive/graphql';
import * as Apollo from '@apollo/client';

export default function useGroupRoom() {
  const [
    fetchConnectChatMessages,
    {
      data: connectChatMessages,
      subscribeToMore: subscribeChatMessageToMore,
      fetchMore: onFetchMoreConnectChatMessages
    }
  ] = useLazyQuery(FetchConnectChatMessages, {
    fetchPolicy: 'no-cache'
  });

  const [
    fetchConnectAnnotations,
    {
      data: connectAnnotations,
      loading: connectAnnotationsLoading,
      fetchMore: onFetchMoreConnectAnnotations
    }
  ] = Apollo.useLazyQuery(FetchConnectAnnotations, {
    fetchPolicy: 'no-cache'
  });

  const onFetchConnectChatMessages = useCallback(fetchConnectChatMessages, [
    fetchConnectChatMessages
  ]);

  const onFetchConnectAnnotations = useCallback(fetchConnectAnnotations, [
    fetchConnectAnnotations
  ]);

  const [
    fetchGroupFilesData,
    { data: groupFilesData, loading: fetchGroupFilesLoading }
  ] = Apollo.useLazyQuery(fetchCapaConnectSharedFile, {
    fetchPolicy: 'no-cache'
  });

  const groupDataFilesState = useMemo(() => {
    if (groupFilesData && groupFilesData?.fetchCapaConnectSharedFile) {
      return {
        data: groupFilesData?.fetchCapaConnectSharedFile.data ?? undefined,
        isLoading: fetchGroupFilesLoading,
        result: groupFilesData?.fetchCapaConnectSharedFile?.result
      };
    }
    return {
      data: undefined,
      isLoading: fetchGroupFilesLoading,
      result: groupFilesData?.fetchCapaConnectSharedFile?.result
    };
  }, [groupFilesData, fetchGroupFilesLoading]);

  const onFetchGroupFilesData = useCallback(fetchGroupFilesData, [
    fetchGroupFilesData
  ]);

  return {
    groupDataFilesState,
    onFetchConnectChatMessages,
    connectChatMessages,
    subscribeChatMessageToMore,
    onFetchMoreConnectChatMessages,
    onFetchGroupFilesData,
    onFetchConnectAnnotations,
    onFetchMoreConnectAnnotations,
    connectAnnotations,
    connectAnnotationsLoading
  };
}
