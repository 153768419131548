import { createMuiTheme, makeStyles } from '@material-ui/core';
import { colorSet } from '@components/Provider';

export const themeLanding = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1024,
      xl: 1536
    }
  }
});

export const carouselUseStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
    justifyItems: 'center',
    marginTop: 120
  },
  image: {
    width: 752,
    height: 418,
    borderRadius: 12
  },
  currentImage: {
    width: 864,
    height: 480,
    borderRadius: 12,
    [themeLanding.breakpoints.down('md')]: {
      maxWidth: 'calc(100vw - 160px)'
    }
  },
  previousSlide: {
    opacity: 0.45,
    '&:hover': {
      opacity: 1
    },
    cursor: 'pointer'
  },
  currentSlide: {
    margin: '0 32px'
  },
  nextSlide: {
    opacity: 0.45,
    '&:hover': {
      opacity: 1
    },
    cursor: 'pointer'
  },
  containerFriud: {
    maxWidth: '100%',
    overflow: 'hidden',
    paddingLeft: 32,
    paddingRight: 32,
    [themeLanding.breakpoints.down('xs')]: {
      paddingLeft: 16,
      paddingRight: 16
    }
  },
  content: {
    width: '100%',
    maxWidth: '1024px',
    margin: '54px auto 160px auto',
    textAlign: 'center'
  },
  action: {
    width: 64,
    height: 64,
    backgroundColor: 'rgba(33, 37, 41, 0.9)',
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    position: 'absolute'
  },
  previous: {
    left: 150
  },
  next: {
    right: 150
  },
  title: {
    fontWeight: 500,
    fontSize: 20,
    letterSpacing: '3px',
    lineHeight: '160%',
    color: colorSet.primary450
  },
  subTitle: {
    marginTop: 32,
    fontWeight: 700,
    fontSize: 25,
    lineHeight: '64px',
    letterSpacing: '-0.1px',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
    color: colorSet.gray000
  },
  contentText: {
    marginTop: 16,
    fontWeight: 400,
    fontSize: 16,
    letterSpacing: '-0.1px',
    lineHeight: '140%',
    color: colorSet.gray000,
    whiteSpace: 'break-spaces'
  }
}));

export const carouselMobileUseStyles = makeStyles((theme) => ({
  wrapper: {
    marginTop: 66,
    marginBottom: 80
  },
  image: {
    width: 311,
    height: 524,
    borderRadius: '16px 16px 0px 0px'
  },
  imgWrapper: {
    background: '#141515',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 36,
    borderRadius: '16px 16px 0px 0px',
    marginTop: 24
  },
  content: {
    padding: '0 16px',
    marginTop: 48
  },
  title: {
    fontWeight: 500,
    fontSize: 13,
    letterSpacing: '3px',
    lineHeight: '160%',
    color: colorSet.primary450
  },
  subTitle: {
    marginTop: 16,
    fontWeight: 700,
    fontSize: 20,
    lineHeight: '160%',
    letterSpacing: '-0.1px',
    color: colorSet.gray000,
    whiteSpace: 'break-spaces'
  },
  contentText: {
    marginTop: 8,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '143%',
    letterSpacing: '-0.1px',
    color: colorSet.gray000
  }
}));

export const carouselTabletUseStyles = makeStyles((theme) => ({
  wrapper: {
    marginTop: 66,
    marginBottom: 80
  },
  image: {
    width: 540,
    height: 300,
    borderRadius: 8
  },
  imgWrapper: {
    background: '#141515',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 90,
    paddingBottom: 90,
    borderRadius: '16px 16px 0px 0px',
    marginTop: 54
  },
  content: {
    padding: '0 16px',
    marginTop: 64
  },
  title: {
    fontWeight: 500,
    fontSize: 13,
    letterSpacing: '3px',
    lineHeight: '160%',
    color: colorSet.primary450
  },
  subTitle: {
    marginTop: 32,
    fontWeight: 700,
    fontSize: 20,
    lineHeight: '160%',
    letterSpacing: '-0.1px',
    color: colorSet.gray000
  },
  contentText: {
    marginTop: 16,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '143%',
    letterSpacing: '-0.1px',
    color: colorSet.gray000,
    whiteSpace: 'break-spaces'
  }
}));
