import { CapaLogoWhite, CapaLogoWhiteKo } from '@assets';
import { CustomizedTypography } from '@components/Customized';
import i18n from '@i18n/index';
import { Box, Container } from '@material-ui/core';
import { LANGUAGE_VALUE } from '@routes/Common/ChangeLanguage/types';
import React from 'react';
import { useStyles } from './styles';

const FooterPresenter = () => {
  const classes = useStyles();
  const languageValue = i18n.language;
  const logo =
    languageValue === LANGUAGE_VALUE.KO ? CapaLogoWhiteKo : CapaLogoWhite;

  return (
    <div className={classes.container}>
      <Container className={classes.root}>
        <Box className={classes.brandLogo}>
          <img src={logo} alt="logo" className={classes.logo} />
        </Box>
        {/* {isMobileAndTablet ? (
          <div className={classes.mobileTabletWrapper}>
            <Box
              className={classes.brandName}
              onClick={() => {
                insertLog(Enum_Action_Button_Enum.Button_Landing_Blog);
                window.open(AppConfig.capaBlogUrl);
              }}
            >
              {t('landing_page.footer.capa_blog')}
            </Box>
            <Box
              className={classes.brandName}
              onClick={() => {
                window.open(AppConfig.capaCareerUrl);
              }}
            >
              {t('landing_page.footer.capa_career')}
            </Box>
            <Box
              className={classes.brandName}
              onClick={() => {
                insertLog(
                  Enum_Action_Button_Enum.Button_Landing_TermsOfService
                );
                window.open(AppConfig.capaConnectsServicePoliciesUrl);
              }}
            >
              {t('landing_page.footer.capa_terms_of_service')}
            </Box>
            <Box
              className={classes.brandName}
              onClick={() => {
                window.open(AppConfig.capaConnectUrl);
              }}
            >
              {t('landing_page.footer.capa_connect_terms_of_service')}
            </Box>
            <Box
              className={classes.brandName}
              onClick={() => {
                insertLog(Enum_Action_Button_Enum.Button_Landing_PrivacyPolicy);
                window.open(AppConfig.privacyUrl);
              }}
            >
              {t('landing_page.footer.privacy_policy')}
            </Box>
            <Box
              className={classes.brandName}
              onClick={() => {
                window.open(AppConfig.marketingUrl);
              }}
            >
              {t(
                'landing_page.footer.consent_to_receive_marketing_information'
              )}
            </Box>
          </div>
        ) : null} */}
        {/* <Grid container>
          <Grid item xs={12} md={6}>
            <Box>
              <Box className={classes.timeSheet}>
                {t('landing_page.time_sheet')}
              </Box>
              <Link className={classes.linkEmail} href="mailto:support@capa.ai">
                support@capa.ai
              </Link>
            </Box>
          </Grid>
          {isMobileAndTablet ? null : (
            <Grid item xs={12} md={6}>
              <Box
                className={classes.boxLink}
                justifyContent="flex-end"
                display={'flex'}
              >
                <div
                  className={classes.mediaLink}
                  onClick={() => {
                    insertLog(Enum_Action_Button_Enum.Button_Landing_Blog);
                    window.open(AppConfig.capaBlogUrl);
                  }}
                >
                  {t('landing_page.footer.capa_blog')}
                </div>
                <div
                  className={classes.mediaLink}
                  onClick={() => {
                    insertLog(
                      Enum_Action_Button_Enum.Button_Landing_TermsOfService
                    );
                    window.open(AppConfig.capaConnectsServicePoliciesUrl);
                  }}
                >
                  {t('landing_page.footer.capa_terms_of_service')}
                </div>
              </Box>
              <Box
                className={classes.boxLink}
                justifyContent="flex-end"
                display={'flex'}
              >
                <div
                  className={classes.mediaLink}
                  onClick={() => {
                    window.open(AppConfig.capaCareerUrl);
                  }}
                >
                  {t('landing_page.footer.capa_career')}
                </div>
                <div
                  className={classes.mediaLink}
                  onClick={() => {
                    window.open(AppConfig.capaConnectUrl);
                  }}
                >
                  {t('landing_page.footer.capa_connect_terms_of_service')}
                </div>
              </Box>
              <Box
                justifyContent="flex-end"
                className={classes.boxLink}
                display={'flex'}
              >
                <div
                  className={classes.mediaLink}
                  onClick={() => {
                    insertLog(
                      Enum_Action_Button_Enum.Button_Landing_PrivacyPolicy
                    );
                    window.open(AppConfig.privacyUrl);
                  }}
                >
                  {t('landing_page.footer.privacy_policy')}
                </div>
              </Box>
              <Box
                justifyContent="flex-end"
                className={classes.boxLink}
                display={'flex'}
              >
                <div
                  className={classes.mediaLink}
                  onClick={() => {
                    window.open(AppConfig.marketingUrl);
                  }}
                >
                  {t(
                    'landing_page.footer.consent_to_receive_marketing_information'
                  )}
                </div>
              </Box>
            </Grid>
          )}
        </Grid> */}
        {/* {isMobileAndTablet ? (
          <Box display={'flex'} className={classes.moreInfo}>
            <Trans i18nKey={'landing_page.footer.more_info'} />
          </Box>
        ) : null} */}
        {/* <Box className={classes.emptyBox} /> */}
        {/* {isMobileAndTablet ? (
          <Box display={'flex'}>
            <SocialNetWork />
            <ChangeLanguageContainer isLandingPage={true} />
          </Box>
        ) : (
          <Box justifyContent="space-between" display={'flex'}>
            <div>
              <Box className={classes.office}>
                {t('landing_page.footer.office')}
              </Box>
              <Box className={classes.contact}>
                {t('landing_page.footer.contact')}
              </Box>
            </div>
            <Box display={'flex'}>
              <ChangeLanguageContainer isLandingPage={true} />
              <SocialNetWork />
            </Box>
          </Box>
        )} */}
        <Box>
          <CustomizedTypography className={classes.contact}>
            contact : business@capa.ai
          </CustomizedTypography>
        </Box>
      </Container>
    </div>
  );
};

export default FooterPresenter;
