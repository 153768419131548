import React, { useEffect } from 'react';
import { useStylesInsertFromMyDrive } from './styles';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { CustomizedButton } from '@components/Customized';
import { ArrowBackIosOutlined } from '@material-ui/icons';
import clsx from 'clsx';
import useMyDrive from '@hooks/Drive/MyDrive/useMyDrive';
import InsertFromMyDrive from '@routes/Dashboard/Dialog/Share/CreateNewGroup/InsertFromMyDrive';
import { ReactComponent as IBMCloud } from '../../../../../assets/icon/IBMCloud.svg';

interface Props {
  isShowHideUploadFilesDialog?: any;
  setShowHideUploadFilesDialog?: (isShowHide: boolean) => void;
  setFilesUpload?: (files: any) => void;
  filesUploaded?: any;
  uploadFilesFromChatBox?: () => void;
}

const InsertFromMyDriveContainer = (props: Props) => {
  const { t } = useTranslation();
  const {
    isShowHideUploadFilesDialog,
    setShowHideUploadFilesDialog,
    setFilesUpload,
    filesUploaded,
    uploadFilesFromChatBox
  } = props;
  const classes = useStylesInsertFromMyDrive();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { myDrive, onSetSelectedFilesUploadInChat } = useMyDrive();

  useEffect(() => {
    if (filesUploaded && filesUploaded?.length > 0) {
      onSetSelectedFilesUploadInChat({
        selectedFilesUploadInChat: filesUploaded
      });
    }
  }, []);

  const onClose = () => {
    onSetSelectedFilesUploadInChat({
      selectedFilesUploadInChat: []
    });
    setShowHideUploadFilesDialog && setShowHideUploadFilesDialog(false);
  };

  const onClickDoneButton = () => {
    setShowHideUploadFilesDialog && setShowHideUploadFilesDialog(false);
    if (uploadFilesFromChatBox) {
      uploadFilesFromChatBox();
      return;
    }
    setFilesUpload && setFilesUpload(myDrive.selectedFilesUploadInChat);
    onSetSelectedFilesUploadInChat({
      selectedFilesUploadInChat: []
    });
  };

  return (
    <>
      <Dialog
        classes={{
          paper: classes.paper,
          root: classes.rootDialog
        }}
        open={isShowHideUploadFilesDialog}
        onClick={(e) => {
          e.stopPropagation();
        }}
        onClose={() => {
          if (uploadFilesFromChatBox) {
            setShowHideUploadFilesDialog && setShowHideUploadFilesDialog(false);
            onSetSelectedFilesUploadInChat({
              selectedFilesUploadInChat: []
            });
          }
        }}
      >
        <DialogTitle classes={{ root: classes.rootTitle }} disableTypography>
          {isMobile ? (
            <>
              <ArrowBackIosOutlined
                className={classes.arrowBackIosOutlinedIcon}
                onClick={() => onClose()}
              />
              <div>{t('create_new_group_modal.insert_from_my_drive')}</div>
            </>
          ) : (
            <>
              {uploadFilesFromChatBox ? (
                <div className={clsx(classes.cloudIconWrapper)}>
                  <IBMCloud className="cs-icon" />
                </div>
              ) : (
                <div
                  className={clsx(
                    classes.arrowBackIosOutlinedWrapper,
                    classes.pointerCursor
                  )}
                  onClick={() => onClose()}
                >
                  <ArrowBackIosOutlined
                    className={classes.arrowBackIosOutlinedIcon}
                  />
                </div>
              )}
              <div>{t('create_new_group_modal.insert_from_my_drive')}</div>
            </>
          )}
        </DialogTitle>
        <DialogContent className={classes.csDialogContent}>
          <InsertFromMyDrive
            isShowHideUploadFilesDialog={isShowHideUploadFilesDialog}
            filesUploaded={filesUploaded}
          />
        </DialogContent>
        <DialogActions classes={{ root: classes.action }}>
          <div className={classes.wrapperBtn}>
            <CustomizedButton
              type="submit"
              variant="contained"
              color="primary"
              onClick={() => {
                onClickDoneButton();
              }}
            >
              {t('groups_you_are_joined.done_button')}
            </CustomizedButton>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InsertFromMyDriveContainer;
