import React, { useEffect, useMemo, useState } from 'react';
import ViewerPresenter from './ViewerPresenter';
import NotFound from './NotFound';
import {
  FetchConnectUserLinkFiles,
  SubscribeConnectUserFileStatus
} from '@generated/Drive/MyDrive/graphql';
import Loading from '@components/LoadingInComponent';
import { LINK_STATUS } from '@routes/Dashboard/Dialog/Share/ShareLink/types';
import { useParams } from 'react-router-dom';
import { useSubscription } from '@apollo/client';
import { CapaErrorCode } from '@generated/Common/graphql';
import publicIp from 'public-ip';
import { getStorageItem, STORAGE_ACTION } from '@utils/storage';
import { useLazyQuery } from '@apollo/client';

const ViewerContainer = () => {
  const accessToken = getStorageItem(STORAGE_ACTION.CONNECT_ACCESS_TOKEN);
  const isLoggedIn = useMemo(() => {
    const userInfo = getStorageItem(STORAGE_ACTION.USER_INFO);
    const result =
      (accessToken ? true : false) &&
      (userInfo && userInfo.email ? true : false);
    return result;
  }, [accessToken]);
  const { fileIds } = useParams<{ fileIds?: string }>();
  const [fetchConnectUserLinkFiles, { data, loading }] = useLazyQuery(
    FetchConnectUserLinkFiles,
    {
      fetchPolicy: 'no-cache'
    }
  );
  const [linkDeactived, setLinkDeactived] = useState(false);

  useEffect(() => {
    if (
      data?.fetchConnectUserLinkFiles?.data?.files[0]?.linkStatus ===
      LINK_STATUS.DEACTIVE
    )
      setLinkDeactived(true);
  }, [data]);

  useEffect(() => {
    publicIp.v4().then((res: any) => {
      if (res) {
        fetchConnectUserLinkFiles({
          variables: {
            shareLink: fileIds?.split(','),
            email: isLoggedIn
              ? getStorageItem(STORAGE_ACTION.USER_INFO)?.email
              : undefined,
            ip: res
          }
        });
      }
    });
  }, []);

  useSubscription(SubscribeConnectUserFileStatus, {
    variables: {
      fileId: data?.fetchConnectUserLinkFiles?.data?.files[0]?.id ?? ''
    },
    onSubscriptionData: (subscriptionData) => {
      if (
        subscriptionData?.subscriptionData?.data?.subscribeConnectUserFileStatus
          ?.data?.linkStatus === LINK_STATUS.DEACTIVE ||
        subscriptionData?.subscriptionData?.data?.subscribeConnectUserFileStatus
          .result == CapaErrorCode.FileNotFound
      )
        setLinkDeactived(true);
      else setLinkDeactived(false);
    }
  });

  if (loading) return <Loading open={true} styles={{ height: '100vh' }} />;
  if (
    linkDeactived ||
    data?.fetchConnectUserLinkFiles?.result == CapaErrorCode.FileNotFound ||
    data?.fetchConnectUserLinkFiles?.data?.files?.length == 0
  )
    return <NotFound />;
  return (
    <ViewerPresenter
      files={data?.fetchConnectUserLinkFiles?.data?.files ?? []}
      isLoggedIn={isLoggedIn}
    />
  );
};

export default ViewerContainer;
