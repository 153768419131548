import { colorSet } from '@components/Provider';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  wrapper: ({ isNewGroup }: { isNewGroup?: boolean }) => ({
    padding: isNewGroup ? '24px 0' : 24,
    borderTop: isNewGroup ? '' : `1px solid ${colorSet.gray300}`,
    [theme.breakpoints.down('xs')]: {
      marginTop: 32,
      paddingTop: 0,
      borderTop: 'none !important'
    }
  }),
  newGroupParentWrapper: ({ isNewGroup }: { isNewGroup?: boolean }) => ({
    padding: isNewGroup ? '12px 0 8px 0' : '12px 24px 8px 24px',
    [theme.breakpoints.down('xs')]: {
      padding: isNewGroup ? '12px 0 6px 0' : '12px 16px 6px 16px'
    }
  }),
  header: {
    display: 'flex',
    alignItems: 'center'
  },
  showUploadFilesMarginTop: {
    marginTop: 4
  },
  hideUploadFilesMarginTop: {
    marginTop: 12
  },
  linkSettings: {
    display: 'flex',
    alignItems: 'center',
    '& .box-shadow-name': {
      boxShadow: 'inset 999px 999px 0px rgba(0, 0, 0, 0.12)'
    },
    '& .name': {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '143%',
      letterSpacing: '-0.1px',
      marginLeft: 8,
      display: 'flex',
      cursor: 'pointer',
      borderRadius: 4,
      padding: '2px 4px',
      '&:hover': {
        boxShadow: 'inset 999px 999px 0px rgba(0, 0, 0, 0.08)'
      },
      '& img': {
        marginLeft: 7
      }
    },
    '& img': {
      userSelect: 'none',
      userDrag: 'none'
    }
  },
  descriptionLink: {
    marginLeft: 32,
    fontSize: 13,
    fontWeight: 400,
    lineHeight: '138%',
    letterSpacing: '-0.1px',
    color: colorSet.gray700
  },
  linkButton: {
    marginLeft: 'auto',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '143%',
    letterSpacing: '-0.1px',
    cursor: 'pointer',
    '&:hover': {
      [theme.breakpoints.down('xs')]: {
        textDecorationLine: 'none'
      },
      textDecorationLine: 'underline'
    }
  },
  activateLink: {
    color: colorSet.primary450
  },
  deactivateLink: {
    color: colorSet.gray900
  },
  shareStringWrapper: {
    marginTop: 16,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row'
  },
  descriptionModal: {
    marginTop: 12,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '143%',
    letterSpacing: '-0.1px'
  },
  shareString: {
    height: 36,
    padding: '8px 12px',
    boxShadow: 'inset 999px 999px 0px rgba(0, 0, 0, 0.02)',
    borderRadius: 4,
    marginRight: 16,
    color: colorSet.primary450,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '143%',
    letterSpacing: '-0.1px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    order: 0,
    flexGrow: 1,
    whiteSpace: 'nowrap'
  },
  copyLink: {
    minWidth: 62,
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '143%',
    letterSpacing: '-0.1px',
    cursor: 'pointer',
    flexShrink: 0,
    color: colorSet.primary450,
    '&:hover': {
      [theme.breakpoints.down('xs')]: {
        textDecorationLine: 'none'
      },
      textDecorationLine: 'underline'
    }
  },
  attachmentTitle: {
    marginTop: 12,
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '143%',
    letterSpacing: '-0.1px'
  },
  snackbar: {
    position: 'absolute',
    bottom: 24,
    left: '25%',
    width: 244,
    padding: '15px 20px',
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'space-between',
    boxShadow:
      '0px 2px 4px -1px rgba(0, 0, 0, 0.1), 0px 4px 5px rgba(0, 0, 0, 0.07), 0px 1px 10px rgba(0, 0, 0, 0.06)',
    background: colorSet.gray900,
    color: colorSet.gray000,
    '& svg path': {
      fill: `${colorSet.gray000} !important`
    },
    '& .ibm-close': {
      cursor: 'pointer'
    },
    '& .text': {
      fontSize: 13,
      fontWeight: 400,
      lineHeight: '138%',
      letterSpacing: '-0.1px'
    },
    [theme.breakpoints.down('xs')]: {
      left: 0,
      bottom: 0,
      width: '100%'
    }
  },
  customPopover: {
    '& .MuiPopover-paper': {
      marginTop: 4,
      boxShadow:
        '0px 8px 16px 2px rgba(97, 97, 97, 0.1), 0px 16px 32px 2px rgba(97, 97, 97, 0.1)'
    },
    [theme.breakpoints.down('xs')]: {
      background: `${colorSet.backgroundDrop}`,
      '& .MuiPopover-paper': {
        boxShadow: 'none',
        background: 'transparent',
        minHeight: 'auto'
      }
    }
  },
  csDialogContentPopup: {
    width: 244,
    '& .spacing': {
      marginLeft: 24
    },
    padding: '8px 0',
    [theme.breakpoints.down('xs')]: {
      background: `${colorSet.gray000}`,
      borderRadius: 4,
      '& p svg': {
        marginRight: 5,
        marginLeft: 10
      },
      '& .spacing': {
        marginLeft: 40
      }
    },
    '.MuiPopover-root': {
      background: `${colorSet.backgroundDrop}`
    },
    '& .MuiTypography-body1': {
      padding: 15,
      display: 'flex',
      flexDirection: 'row',
      cursor: 'pointer',
      color: `${colorSet.gray900}`,
      fontSize: 16,
      lineHeight: '150%',
      '&:hover': {
        boxShadow: 'inset 999px 999px 0px rgba(0, 0, 0, 0.08)'
      }
    },
    '& .name': {
      '& .text': {
        fontSize: 14,
        fontWeight: 500,
        lineHeight: '143%',
        letterSpacing: '-0.1px'
      },
      '& p': {
        color: `${colorSet.gray700}`
      }
    }
  },
  itemPopup: {
    height: 32,
    padding: '3px 6.5px 3px 12px !important',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  iconCheck: {
    width: 20,
    height: 20,
    marginLeft: 2,
    marginTop: 2,
    color: `${colorSet.primary450}`
  },
  attachments: {
    marginTop: 12,
    [theme.breakpoints.down('xs')]: {
      marginTop: 16
    }
  },
  attachment: {
    marginTop: 8,
    display: 'flex',
    padding: '4px 8px',
    backgroundColor: colorSet.gray100,
    borderRadius: 4,
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    alignItems: 'center',
    '& .ibm-cad-icon': {
      marginLeft: 8
    },
    '& .file-name': {
      fontSize: 14,
      marginLeft: 8,
      fontWeight: 400,
      lineHeight: '143%',
      letterSpacing: '-0.1px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }
  },
  attachmentIcon: {
    width: 16,
    height: 16
  }
}));
