import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  makeStyles
} from '@material-ui/core';
import { Clear } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: 476,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      maxWidth: 343
    }
  },
  titleRoot: {
    padding: 32,
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      padding: 24
    }
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  cancelBtn: {
    fontSize: 24,
    marginLeft: 'auto',
    cursor: 'pointer'
  },
  content: {
    padding: '0px 32px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px 24px'
    }
  },
  action: {
    padding: '0px 32px 32px 32px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px 24px 24px 24px'
    }
  }
}));

interface Props {
  open: boolean;
  titleIcon?: React.ReactNode;
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  content?: React.ReactNode;
  footerBtn?: React.ReactNode;
  onClose?: () => void;
}

const DialogLayoutPresenter: React.FC<Props> = ({
  open,
  titleIcon,
  title,
  subTitle,
  content,
  footerBtn,
  onClose
}) => {
  const classes = useStyles();
  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={open}
      onClose={onClose && onClose}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <DialogTitle classes={{ root: classes.titleRoot }} disableTypography>
        <div className={classes.title}>
          {titleIcon ?? null}
          {title}
        </div>
        {onClose && <Clear className={classes.cancelBtn} onClick={onClose} />}
      </DialogTitle>
      <DialogContent classes={{ root: classes.content }}>
        {subTitle}
        {content}
      </DialogContent>
      <DialogActions classes={{ root: classes.action }}>
        {footerBtn}
      </DialogActions>
    </Dialog>
  );
};

export default DialogLayoutPresenter;
