import React, { useState, useEffect } from 'react';
import ErrorNoticeImg from './assets/errorNotice.png';
import { isServiceNoticeCheck } from '@utils/notice';
import {
  STORAGE_ACTION,
  setStorageItem,
  getStorageItem,
  deleteStorageItem
} from '@utils/storage';
import { colorSet } from '@components/Provider';
import { CustomizedButton, CustomizedTypography } from '@components/Customized';
import {
  Dialog,
  DialogContent,
  useMediaQuery,
  useTheme,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '64px 32px 32px 32px',
    textAlign: 'center',
    zIndex: 3000,
    [theme.breakpoints.down('xs')]: {
      padding: '48px 24px 26px 24px'
    }
  },
  banner: {
    margin: 'auto',
    marginBottom: 32,
    width: 315,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 24,
      width: 215
    }
  },
  content: {
    padding: 0
  },
  footer: {
    marginTop: 24,
    backgroundColor: colorSet.gray200,
    borderRadius: 4,
    height: 80,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 46,
    '& b': {
      color: colorSet.primary500
    }
  }
}));

const PreNoticePresenter: React.FC = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();
  const isServiceNotice = isServiceNoticeCheck();
  const isNotSeeingAgain = getStorageItem(
    STORAGE_ACTION.NOT_SEEING_AGAIN_PRE_NOTICE
  );
  const [isOpen, setIsOpen] = useState(
    isNotSeeingAgain === 'true' || !isServiceNotice.isOpenPreNotice
      ? false
      : true
  );

  useEffect(() => {
    if (isNotSeeingAgain && !isServiceNotice.isOpenPreNotice) {
      deleteStorageItem(STORAGE_ACTION.NOT_SEEING_AGAIN_PRE_NOTICE);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Dialog open={isOpen} classes={{ paper: classes.paper }}>
      <img className={classes.banner} src={ErrorNoticeImg} alt="notice" />
      <CustomizedTypography variant="h2" fontWeight="bold" gutterBottoms={16}>
        서비스 점검 사전 안내
      </CustomizedTypography>
      <DialogContent classes={{ root: classes.content }}>
        <CustomizedTypography
          variant={matches ? 'body2' : 'body1'}
          fontWeight="500"
          color="textSecondary"
          gutterBottoms={matches ? 40 : 46}
        >
          서비스를 이용해 주시는 고객님께 감사드립니다.
          {!matches && <br />}
          서비스 점검 시간 동안 사용이 일시 중단될 예정이오니 이용에 참고
          부탁드립니다.
          {!matches && <br />}더 나은 서비스를 제공하기 위한 과정임을 너그럽게
          양해 부탁드립니다.
        </CustomizedTypography>
        <CustomizedTypography
          variant="body1"
          fontWeight="bold"
          gutterBottoms={16}
        >
          서비스 점검 일시
        </CustomizedTypography>
        <div className={classes.footer}>
          <CustomizedTypography
            variant={matches ? 'body1' : 'h4'}
            fontWeight="500"
          >
            {isServiceNotice.yyyymmdd}&nbsp;{matches && <br />}
            <b>
              {isServiceNotice.startDate} ~ {isServiceNotice.endDate}
            </b>
          </CustomizedTypography>
        </div>
      </DialogContent>
      <CustomizedButton
        variant="contained"
        color="primary"
        size="large"
        fullWidth
        onClick={() => {
          setIsOpen(false);
          setStorageItem(STORAGE_ACTION.NOT_SEEING_AGAIN_PRE_NOTICE, 'true');
        }}
      >
        확인
      </CustomizedButton>
    </Dialog>
  );
};

export default PreNoticePresenter;
