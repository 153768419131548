import styled from 'styled-components';

export const IconThumbnailWrapper = styled.div`
  .icon-svg-small {
    flex: 0 0 auto;
    width: 32px;
    & img {
      width: 100%;
      height: auto;
    }
  }

  .icon-svg-large {
    flex: 0 0 auto;
    width: 32px;
    margin: 8px;
    & img {
      width: 100%;
      height: auto;
    }
  }
  .icon-svg-medium {
    flex: 0 0 auto;
    width: 44px;

    & img {
      width: 100%;
      height: auto;
    }
  }
`;
