import React, { useRef, useState } from 'react';
import { useStyles } from './styles';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox
} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useTranslation } from 'react-i18next';
import * as Apollo from '@apollo/client';

import { CustomizedButton } from '@components/Customized';
import { CapaErrorCode } from '@generated/Common/graphql';
import { UnshareFileFromGroup } from '@generated/Drive/MyDrive/graphql';
import { useSnackbar } from '@components/Provider';

interface Props {
  isOpen?: boolean;
  onClose?: () => void;
  groupId?: string;
  groupName?: any;
}

const RemoveFormGroup = (props: Props) => {
  const classes = useStyles();
  const { isOpen, onClose, groupName } = props;
  const { t } = useTranslation();
  const [unshareFileFromGroup] = Apollo.useMutation(UnshareFileFromGroup);
  const { enqueueSnackbar } = useSnackbar();
  const myRefGroupRemove = useRef();
  const [isRemoveFile, setIsRemoveFile] = useState(false);

  const onSubmitRemoveGroup = () => {
    if (!isRemoveFile) {
      onClose && onClose();
      return;
    }
    unshareFileFromGroup({
      variables: {
        groupId: groupName?.id ? groupName?.id : groupName?.groupId,
        chatRoomId: groupName?.id
          ? groupName?.chatRoomId
          : groupName?.chatRoomId,
        groupName: groupName?.id ? groupName?.groupName : groupName?.groupName,
        connectFileIds: [],
        removeAllMyFile: isRemoveFile
      }
    })
      .then((res) => {
        let result = res.data.unshareFileFromGroup.result;
        if (result === CapaErrorCode.Success) {
          onClose && onClose();
        }
      })
      .catch(() => {
        enqueueSnackbar(`${t('snackbar.remove_group_error')}`, {
          variant: 'error'
        });
      });
  };

  return (
    <>
      <Dialog
        classes={{
          paper: classes.paper
        }}
        open={isOpen!}
        onClick={(e) => {
          e.stopPropagation();
        }}
        onClose={onClose}
        ref={myRefGroupRemove}
      >
        <DialogTitle classes={{ root: classes.rootTitle }} disableTypography>
          <span className={classes.firstTitle}>
            <span className={'sub-title'}>
              {t('remove_group_notification.title', {
                groupName: groupName?.groupName
              })}
            </span>
          </span>
        </DialogTitle>

        <DialogContent className={classes.csDialogContent}>
          <div className={classes.marginBottomText}>
            <div className={classes.marginTextContent}>
              {t('remove_group_notification.prepared_features_desc')}{' '}
              {t('remove_group_notification.prepared_features_desc_sub')}
            </div>
          </div>
          <div className={classes.checkboxRemove}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isRemoveFile}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  onChange={(event) => setIsRemoveFile(event.target.checked)}
                />
              }
              label={
                <div className={classes.removeAllMyFiles}>
                  <b>{t('remove_group_notification.remove_all_my_files')}</b>
                  <p className={classes.removeFileSubTitle}>
                    {t('remove_group_notification.remove_file_sub_title')}
                  </p>
                </div>
              }
            />
          </div>
        </DialogContent>

        <DialogActions classes={{ root: classes.action }}>
          <div className={classes.wrapperBtn}>
            <CustomizedButton
              type="submit"
              variant="contained"
              color="primary"
              onClick={onSubmitRemoveGroup}
            >
              {t('remove_group_notification.ok')}
            </CustomizedButton>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RemoveFormGroup;
