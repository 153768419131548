import { makeStyles } from '@material-ui/core';
import { colorSet } from '@components/Provider';

export const useStyles = makeStyles((theme) => ({
  rootPopupMobile: {
    zIndex: 1300,
    [theme.breakpoints.down('xs')]: {
      width: '100% !important',
      bottom: '-4px !important',
      left: '0 !important',
      top: 'unset !important',
      transform: 'none !important',
      position: 'fixed !important',
      height: 'calc(100vh - 96px)'
    }
  },
  backDrop: {
    zIndex: 1298,
    [theme.breakpoints.down('xs')]: {
      background: colorSet.gray2000,
      opacity: '0.5 !important'
    }
  },
  contentPopup: {
    minWidth: 343,
    borderRadius: '16px 16px 0 0',
    backgroundColor: colorSet.gray000,
    padding: '8px 0px',
    height: '100%'
  },
  contentBox: {
    display: 'flex',
    justifyContent: 'center'
  },
  downloadAppBannerIcon: {
    marginTop: 16,
    width: 56,
    height: '100%'
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 'bold',
    alignItems: 'center',
    fontSize: 20,
    lineHeight: '160%',
    letterSpacing: '-0.1px',
    margin: '10px 0'
  },
  subTitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 14,
    lineHeight: '143%',
    letterSpacing: '-0.1px',
    textAlign: 'center',
    marginBottom: 16
  },
  useAppButton: {
    width: '100%',
    textTransform: 'none',
    margin: '0 24px 24px 24px'
  },
  footerTitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 14,
    lineHeight: '143%',
    letterSpacing: '-0.1px',
    color: colorSet.gray700
  }
}));
