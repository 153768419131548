import React, { useState } from 'react';
import { useStyles } from '@routes/GroupRoom/styles';
import { useTranslation } from 'react-i18next';
import { CHAT_BOT_GROUP_MESSAGE_VALUE } from '@routes/Common/types';
import { SendConnectChatMessageToCapa } from '@generated/Drive/MyDrive/graphql';
import { useMutation } from '@apollo/client';
import { CapaErrorCode } from '@generated/Common/graphql';
import { useLocation } from 'react-router-dom';

interface Props {
  message: any;
  buttonMessageCallback: () => void;
}

const ButtonMessage = (props: Props) => {
  const { message, buttonMessageCallback } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  let chatBotGroupMessage = '';
  const { search } = useLocation<any>();
  const [sendConnectChatMessageToCapa] = useMutation(
    SendConnectChatMessageToCapa
  );
  const chatroomId = new URLSearchParams(search).get('chatroomId');
  const [isLoading, setLoading] = useState(false);

  const clickButtonMessage = () => {
    setLoading(true);
    sendConnectChatMessageToCapa({
      variables: {
        message: message.message.message,
        chatRoomId: chatroomId
      }
    }).then((res: any) => {
      if (
        res.data.sendConnectChatMessageToCapa.result === CapaErrorCode.Success
      ) {
        setLoading(false);
        buttonMessageCallback && buttonMessageCallback();
      }
    });
  };

  switch (message.message.message) {
    case CHAT_BOT_GROUP_MESSAGE_VALUE.THANK_YOU_MESSAGE: {
      chatBotGroupMessage = t('chat_room.chat_bot.thank_you_message');
      break;
    }
  }
  return (
    <div className={classes.animationButtonMessage}>
      <span></span>
      <div
        className={classes.buttonMessage}
        onClick={() => {
          if (isLoading) return;
          clickButtonMessage();
        }}
      >
        {chatBotGroupMessage}
      </div>
    </div>
  );
};

export default ButtonMessage;
