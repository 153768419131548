import { colorSet } from '@components/Provider';

export const dragParentEl = `
  position: relative;
  width: 220px;
  height: 80px;
  display: flex;
  align-items: center;
`;

export const dragContentEl = `
  position: relative;
  width: 200px;
  height: 60px;
  background-color: ${colorSet.primary300};
  padding-left: 16px;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: clip;
  white-space: nowrap
`;

export const dragBulletWrapperEl = `
  position: absolute;
  top: 4px;
  right: 4px;
  text-align: center;
  vertical-align: middle;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  display: inline-block;
  background-color: ${colorSet.primary700};
  color: ${colorSet.gray000};
`;

export const dragBulletEl = `
  display: inline-block;
  vertical-align: middle
`;

export const draggingStyle = {
  opacity: 0.25
};

export const dropInsideStyle = {
  backgroundColor: colorSet.primary250,
  border: `1px solid ${colorSet.primary450}`,
  opacity: 1
};
