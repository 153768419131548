import styled from 'styled-components';
import { colorSet } from '@components/Provider';
import { TextField, Box, makeStyles } from '@material-ui/core';

export const PageContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-width: 690px;
  .accountPageTitle {
    margin-bottom: 32px;
    line-height: 48px;
    letter-spacing: -0.1px;
    color: ${colorSet.gray900};
  }
`;

export const StyledInput = styled(TextField)`
  .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${colorSet.gray400};
  }
  .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
    border-color: unset;
  }
  input {
    &.Mui-disabled {
      background: ${colorSet.gray100};
      color: ${colorSet.gray900};
    }
    &::placeholder {
      color: ${colorSet.gray500};
    }
  }
  .remove-red-eye-icon {
    .MuiSvgIcon-root {
      color: ${colorSet.gray700};
    }
  }
`;

export const AccountPageStyles = makeStyles((theme) => ({
  basicInfo: {
    fontWeight: 700,
    fontSize: 25,
    lineHeight: '160%',
    letterSpacing: '-0.1px',
    marginBottom: 10,
    marginTop: 64,
    color: colorSet.gray900,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16
    }
  },
  logInInfo: {
    fontWeight: 700,
    fontSize: 25,
    lineHeight: '160%',
    letterSpacing: '-0.1px',
    marginBottom: 16,
    color: colorSet.gray900,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
      marginTop: 20
    }
  }
}));

export const usernameEditStyles = makeStyles((theme) => ({
  label: {
    marginBottom: 12,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '150%',
    letterSpacing: '-0.1px',
    color: colorSet.gray600,
    [theme.breakpoints.down('xs')]: {
      marginTop: 16
    }
  },
  error: {
    '& .MuiOutlinedInput-root': {
      border: `1px solid ${colorSet.errorMain}`
    },
    '& fieldset': {
      border: 'none !important'
    }
  },
  customInput: {
    width: '100%',
    marginBottom: 24,
    '& input': {
      paddingTop: 11.5,
      paddingBottom: 11.5
    },
    '& button': {
      marginLeft: 8,
      [theme.breakpoints.down('xs')]: {
        padding: 0,
        minWidth: '100px !important'
      }
    }
  },
  validMaxLength: {
    color: colorSet.errorMain,
    marginTop: 4,
    fontSize: 12,
    lineHeight: '150%',
    letterSpacing: '-0.1px'
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& .cancel-button': {
      color: colorSet.gray900
    },
    '& button': {
      textTransform: 'initial',
      '&:not(:last-child)': {
        marginRight: 8
      }
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      '& button': {
        flex: 1
      }
    }
  }
}));
