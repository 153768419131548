import { CapaConnectMyFile } from '@generated/Common/graphql';
import React from 'react';
import {
  EnumDashboardDialogType,
  EnumDashboardListControlType
} from '../types';
import MoreMenuPopupPresenter from './MoreMenuPopupPresenter';

interface Props {
  onClick: (dialogType: EnumDashboardDialogType) => void;
  type: EnumDashboardListControlType;
  isShowPublic?: boolean;
  isShowDownLoad?: boolean;
  isOpen?: boolean;
  isDetail?: boolean;
  isShare?: boolean;
  isOpenFileViewer?: boolean;
  files: Array<CapaConnectMyFile>;
  onShowFileViewer?: () => void;
  onOpenViewer?: () => void;
  onClickEvent?: (event: string) => void;
  onSetCheckedFiles?: (files: any) => void;
  myDrivefiles?: any;
  isListSide?: boolean;
}

const MoreMenuPopupContainer: React.FC<Props> = ({
  onClick,
  type,
  isShowPublic,
  isOpen,
  isDetail,
  isShowDownLoad,
  files,
  onShowFileViewer,
  onClickEvent,
  onSetCheckedFiles,
  isShare,
  isOpenFileViewer,
  myDrivefiles,
  onOpenViewer,
  isListSide
}) => {
  return (
    <MoreMenuPopupPresenter
      isListSide={isListSide}
      onSetCheckedFiles={onSetCheckedFiles}
      onClickEvent={onClickEvent}
      onShowFileViewer={onShowFileViewer}
      files={files}
      onClick={onClick}
      type={type}
      isShowPublic={isShowPublic}
      isShowDownLoad={isShowDownLoad}
      isOpen={isOpen}
      isDetail={isDetail}
      isShare={isShare}
      isOpenFileViewer={isOpenFileViewer}
      myDrivefiles={myDrivefiles}
      onOpenViewer={onOpenViewer}
    />
  );
};

export default MoreMenuPopupContainer;
