import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    borderRadius: 8,
    position: 'relative',
    '& img': {
      borderRadius: 8
    },
    '& .ibm-cad': {
      position: 'absolute',
      width: 28,
      height: 20,
      bottom: 8,
      left: 8,
      [theme.breakpoints.down('xs')]: {
        bottom: 4,
        left: 4
      }
    }
  }
}));
