import React from 'react';
import { useStyles } from './styles';
import { ReactComponent as IBMApplication } from '../../assets/icon/IBMApplication.svg';
import { useTranslation } from 'react-i18next';
import AppConfig from '@config/index';

const LinkDownloadAppPresenter = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.linkApps}>
      <a
        target="_blank"
        className={classes.linkItem}
        href={`${AppConfig.downloadAndroidUrl}`}
        rel="noopener noreferrer"
      >
        <IBMApplication />
        {t('link_download_app.ANDROID')}
      </a>
      <a
        target="_blank"
        className={classes.linkItem}
        href={`${AppConfig.downloadIOSUrl}`}
        rel="noopener noreferrer"
      >
        <IBMApplication />
        {t('link_download_app.IOS')}
      </a>
    </div>
  );
};

export default LinkDownloadAppPresenter;
