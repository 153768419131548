import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { USER_STAYED_PAGE_TIME } from './types';
import { isServiceNoticeCheck } from '@utils/notice';
import PreNoticePresenter from '@components/Dialog/PreNoticePresenter';
import ServiceUrgentNoticePresenter from '@components/Dialog/ServiceUrgentNoticePresenter';
import ServiceUpdateNoticePresenter from '@components/Dialog/ServiceUpdateNoticePresenter';
import HomePresenter from './HomePresenter';
import { myDrivePathName } from '@routes/Common/types';
import useAuth from '@hooks/useAuth';
import useUserActionLog, {
  CapaActivityType,
  Enum_Action_Page_Enum
} from '@hooks/useUserActionLog';
import DownloadAppBanner from '@components/DownloadAppBanner';
import { STORAGE_ACTION, getStorageItem } from '@utils/storage';

const HomeContainer: React.FC = () => {
  const history = useHistory();
  const { isLoggedIn, fetchConnectUserLoading } = useAuth();
  const isServiceNotice = isServiceNoticeCheck();
  const { onInsertUserActionLogOne } = useUserActionLog();
  const accessToken = getStorageItem(STORAGE_ACTION.CONNECT_ACCESS_TOKEN);
  const userInfo = getStorageItem(STORAGE_ACTION.USER_INFO);
  const [isStayedPage5s, setStayedPage5s] = useState<boolean>(false);

  useEffect(() => {
    if (!!accessToken && !!(userInfo && userInfo.email)) {
      history.push(myDrivePathName);
    }
  }, []);
  useEffect(() => {
    onInsertUserActionLogOne(
      Enum_Action_Page_Enum.Page_Landing,
      CapaActivityType.PageVisit
    );

    setTimeout(() => {
      setStayedPage5s(true);
    }, USER_STAYED_PAGE_TIME);

    return () => {
      setStayedPage5s(false);
    };
  }, []);

  useEffect(() => {
    if (isStayedPage5s)
      onInsertUserActionLogOne(
        Enum_Action_Page_Enum.Page_Landing_5sec,
        CapaActivityType.PageVisit
      );
  }, [isStayedPage5s]);

  useEffect(() => {
    if (isLoggedIn) return history.push(myDrivePathName);
  }, [isLoggedIn]);

  return (
    <React.Fragment>
      {isServiceNotice.isOpenPreNotice && <PreNoticePresenter />}
      {isServiceNotice.isOpenUpdateNotice && <ServiceUpdateNoticePresenter />}
      {isServiceNotice.isOpenUrgentNotice && <ServiceUrgentNoticePresenter />}
      {!fetchConnectUserLoading && <HomePresenter />}
      <DownloadAppBanner isLandingPage={true} />
    </React.Fragment>
  );
};

export default HomeContainer;
