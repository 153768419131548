import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import {
  CapaErrorCode,
  useRefreshAuthenticateMutation,
  useFetchConnectUserQuery,
  CapaServiceType
} from '@generated/Common/graphql';
import { STORAGE_ACTION, getStorageItem } from '@utils/storage';
import { RootState } from '@modules/index';
import {
  initAuthState,
  updateAuthState,
  IAuthState,
  JwtPayloadType
} from '@modules/auth';

export default function useAuth() {
  const { auth } = useSelector((state: RootState) => state);
  const accessToken = getStorageItem(STORAGE_ACTION.CONNECT_ACCESS_TOKEN);
  const refreshToken = getStorageItem(STORAGE_ACTION.CONNECT_REFRESH_TOKEN);
  const {
    data: fetchConnectUserData,
    loading: fetchConnectUserLoading,
    refetch: refetchConnectUser
  } = useFetchConnectUserQuery({
    errorPolicy: 'all',
    skip: !accessToken
  });

  const [refreshAuthenticate] = useRefreshAuthenticateMutation();
  const dispatch = useDispatch();
  const onUpdateAuthState = useCallback(
    (params: IAuthState) => dispatch(updateAuthState(params)),
    [dispatch]
  );
  const onInitAuthState = useCallback(
    () => dispatch(initAuthState()),
    [dispatch]
  );
  const onRefetchConnectUser = useCallback(refetchConnectUser, [
    refetchConnectUser
  ]);

  const userState = useMemo(() => {
    if (fetchConnectUserData && fetchConnectUserData.fetchConnectUser) {
      return {
        result: fetchConnectUserData.fetchConnectUser.result ?? undefined,
        data: fetchConnectUserData.fetchConnectUser.data ?? undefined,
        isLoading: fetchConnectUserLoading
      };
    } else {
      return {
        result: undefined,
        data: undefined,
        isLoading: fetchConnectUserLoading
      };
    }
    // eslint-disable-next-line
  }, [fetchConnectUserData, fetchConnectUserLoading]);

  const isLoggedIn = useMemo(() => {
    const userInfo = getStorageItem(STORAGE_ACTION.USER_INFO);
    const result =
      (accessToken ? true : false) &&
      (userInfo && userInfo.email ? true : false) &&
      userState.result === CapaErrorCode.Success;

    return result;
  }, [accessToken, userState]);

  const tokenRefresh = () => {
    refreshAuthenticate({ variables: { refreshToken } }).then((resp) => {
      const result = resp.data?.refresh.result;
      if (result === CapaErrorCode.Success) {
        const [newAccessToken, newRefreshToken] = [
          resp.data?.refresh.data?.accessToken,
          resp.data?.refresh.data?.refreshToken
        ];

        if (newAccessToken && newRefreshToken) {
          localStorage.setItem(
            STORAGE_ACTION.CONNECT_ACCESS_TOKEN,
            newAccessToken
          );
          localStorage.setItem(
            STORAGE_ACTION.CONNECT_REFRESH_TOKEN,
            newRefreshToken
          );
        }
      }
    });
  };

  const isAllowedUser: boolean | undefined = useMemo(() => {
    if (accessToken) {
      if (fetchConnectUserData && fetchConnectUserData.fetchConnectUser) {
        if (
          fetchConnectUserData.fetchConnectUser.data &&
          fetchConnectUserData.fetchConnectUser.result === CapaErrorCode.Success
        ) {
          const decodedJwt = (
            accessToken ? jwtDecode<JwtPayload>(accessToken) : {}
          ) as JwtPayloadType;
          const scopes = decodedJwt.scopes ?? [];
          if (scopes.length > 0) {
            return scopes.includes(CapaServiceType.Cloud);
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }, [accessToken, fetchConnectUserData]);

  return {
    auth,
    userState,
    isLoggedIn,
    isAllowedUser,
    onInitAuthState,
    onUpdateAuthState,
    tokenRefresh,
    onRefetchConnectUser,
    fetchConnectUserLoading
  };
}
