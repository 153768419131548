import React, { useState, useEffect } from 'react';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  useMediaQuery
} from '@material-ui/core';
import { CustomizedButton } from '@components/Customized';
import { useStyles } from './styles';
import { KeyboardArrowLeft, ArrowBackIosOutlined } from '@material-ui/icons';
import useMyDrive from '@hooks/Drive/MyDrive/useMyDrive';
import {
  SHARE_DIALOG_SCREEN,
  SHARE_FILES_TO_VALUE
} from '@routes/Dashboard/Dialog/Share/types';
import { theme } from '@components/Provider';
import { useTranslation } from 'react-i18next';
import { KEY_CODE } from '@routes/Dashboard/types';
import useUserActionLog, {
  CapaActivityType,
  Enum_Action_Button_Enum,
  Enum_Action_Input_Enum,
  Enum_Action_Page_Enum
} from '@hooks/useUserActionLog';
const GroupNameForm = () => {
  const { t } = useTranslation();
  const {
    onSetShowHideShareDialog,
    onSetShareDialogScreen,
    onSetValueGroupNameDialog,
    onSetShareFilesToType,
    onSetIdGroupName,
    myDrive
  } = useMyDrive();
  const classes = useStyles();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const [groupName, setGroupName] = useState<string>('');
  const { onInsertUserActionLogOne } = useUserActionLog();

  useEffect(() => {
    setGroupName(myDrive.valueGroupNameDialog || '');
  }, [myDrive.valueGroupNameDialog]);

  useEffect(() => {
    onInsertUserActionLogOne(
      Enum_Action_Page_Enum.Page_CreateGroup_GroupNameModal,
      CapaActivityType.PageVisit
    );
  }, []);

  const onSubmit = () => {
    onInsertUserActionLogOne(
      Enum_Action_Button_Enum.Button_CreateGroup_GroupNameModal_Next,
      CapaActivityType.ButtonClick
    );
    onSetValueGroupNameDialog({
      valueGroupNameDialog: groupName
    });
    onSetShareDialogScreen({
      shareDialogScreen: SHARE_DIALOG_SCREEN.ADD_MEMBERS_TO
    });
  };
  const onCloseGroupNameDiaLog = () => {
    onInsertUserActionLogOne(
      Enum_Action_Button_Enum.Button_CreateGroup_GroupNameModal_Cancel,
      CapaActivityType.ButtonClick
    );
    onSetShareFilesToType({ shareFilesToType: SHARE_FILES_TO_VALUE.EMPTY });
    onSetShowHideShareDialog({ isShowHideShareDialog: false });
    onSetIdGroupName({ idGroupName: '' });
    onSetValueGroupNameDialog({
      valueGroupNameDialog: ''
    });
  };
  const onCloseScreenDialog = () => {
    onSetShareDialogScreen({
      shareDialogScreen: SHARE_DIALOG_SCREEN.SHARE
    });
    onSetValueGroupNameDialog({
      valueGroupNameDialog: ''
    });
  };

  return (
    <>
      <DialogTitle classes={{ root: classes.rootTitle }} disableTypography>
        <div className={classes.subTitle}>
          {!matches ? (
            <div className={classes.titleIcon} onClick={onCloseScreenDialog}>
              <KeyboardArrowLeft />
            </div>
          ) : (
            <ArrowBackIosOutlined onClick={onCloseScreenDialog} />
          )}
          <span className={classes.name}>{t('group_name_modal.header')}</span>
        </div>
      </DialogTitle>
      <DialogContent className={classes.csDialogContent}>
        <TextField
          autoFocus
          value={groupName ?? undefined}
          placeholder={t('group_name_modal.placeholder')}
          variant="outlined"
          size="small"
          fullWidth
          onChange={(event) => setGroupName && setGroupName(event.target.value)}
          onKeyDown={(e) => {
            if (e.keyCode === KEY_CODE.ENTER) {
              onSubmit && onSubmit();
            }
          }}
          onFocus={() => {
            onInsertUserActionLogOne(
              Enum_Action_Input_Enum.Input_GroupNameModal_GroupName,
              CapaActivityType.actionInput
            );
          }}
        />
      </DialogContent>
      <DialogActions classes={{ root: classes.action }}>
        <div className={classes.wrapperBtn}>
          <CustomizedButton
            variant="contained"
            color="default"
            onClick={onCloseGroupNameDiaLog}
          >
            {t('group_name_modal.cancel_button')}
          </CustomizedButton>
          <CustomizedButton
            type="submit"
            variant="contained"
            color="primary"
            onClick={onSubmit}
            disabled={!groupName}
          >
            {t('group_name_modal.next_button')}
          </CustomizedButton>
        </div>
      </DialogActions>
    </>
  );
};

export default GroupNameForm;
