import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
  useMediaQuery,
  TextareaAutosize,
  TextField
} from '@material-ui/core';
import { CapaAddContact, CapaBackIcon, CapaEditContact } from '@assets';
import { useTranslation } from 'react-i18next';
import useMyContacts from '@hooks/Contacts/useMyContacts';
import { CustomizedButton } from '@components/Customized';
import * as Apollo from '@apollo/client';
import {
  CreateMyContactUser,
  EditMyContactUser
} from '@generated/Contacts/MyContacts/graphql';
import { CapaErrorCode } from '@generated/Common/graphql';
import { useSnackbar } from '@components/Provider';
import { useStyles } from './styles';
import clsx from 'clsx';
import {
  chatBotEmail,
  myContactsPathName,
  myContactsRecommendedPathName
} from '@routes/Common/types';
import { useHistory, useLocation } from 'react-router-dom';

interface Props {
  onClose?: () => void;
  onCallback?: () => void;
}

const FormContactDialog: React.FC<Props> = ({ onClose, onCallback }) => {
  const classes = useStyles();
  const { myContacts, onSetStateContact } = useMyContacts();
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [memo, setMemo] = useState<string>('');
  const [isEdit, setIsEdit] = useState(false);
  const [isDisableSubmit, setIsDisableSubmit] = useState(true);
  const [isFailEmail, setIsFailEmail] = useState(false);
  const [createMyContact] = Apollo.useMutation(CreateMyContactUser);
  const [editMyContact] = Apollo.useMutation(EditMyContactUser);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const re =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  useEffect(() => {
    if (myContacts.dataCreateEditDialog?.id) {
      setIsEdit(true);
      setEmail(myContacts?.dataCreateEditDialog.email ?? '');
      setName(myContacts?.dataCreateEditDialog.nickName ?? '');
      setMemo(myContacts?.dataCreateEditDialog.memo ?? '');
    } else {
      setIsEdit(false);
      setIsDisableSubmit(true);
      setIsFailEmail(false);
      setEmail('');
      setName('');
      setMemo('');
    }
  }, [myContacts.dataCreateEditDialog]);
  const { pathname } = useLocation<any>();
  const history = useHistory();

  useEffect(() => {
    if (email.length > 0) {
      setIsDisableSubmit(false);
    } else {
      setIsDisableSubmit(true);
    }
  }, [email]);

  const onSubmitMyContact = (isShowSnackbar = true) => {
    if (!isEdit) {
      if (
        email.trim().length === 0 ||
        !re.test(email) ||
        email.trim() === chatBotEmail
      ) {
        setIsFailEmail(true);
        return;
      }
      setIsFailEmail(false);
      createMyContact({
        variables: {
          contactUserEmail: email?.trim() ?? undefined,
          nickName: name?.trim() ?? undefined,
          memo: memo ?? undefined
        }
      }).then((res: any) => {
        if (
          res.data.createConnectContactUser.result === CapaErrorCode.Success
        ) {
          onCallback && onCallback();
          onSetStateContact({
            dataCreateEditDialog: false
          });
          if (isShowSnackbar)
            enqueueSnackbar(t('snackbar.contact_user_add_success'), {
              variant: 'success'
            });
          if (pathname.indexOf(myContactsRecommendedPathName) !== -1) {
            history.push({
              pathname: myContactsPathName,
              state: { prevLocation: window.location.href }
            });
          }
        } else if (
          res.data.createConnectContactUser.result ===
          CapaErrorCode.ContactUserAlreadyExists
        ) {
          if (isShowSnackbar)
            enqueueSnackbar(t('snackbar.contact_user_already_exists'), {
              variant: 'error'
            });
        } else {
          if (isShowSnackbar)
            enqueueSnackbar(t('snackbar.contact_user_error'), {
              variant: 'error'
            });
        }
      });
    } else {
      editMyContact({
        variables: {
          id: myContacts?.dataCreateEditDialog?.id,
          contactUserEmail: email?.trim() ?? undefined,
          nickName: name?.trim() ?? undefined,
          memo: memo ?? undefined
        }
      }).then((res: any) => {
        if (
          res.data.updateConnectContactUser.result === CapaErrorCode.Success
        ) {
          onCallback && onCallback();
          onSetStateContact({
            dataCreateEditDialog: false
          });
          if (isShowSnackbar)
            enqueueSnackbar(t('snackbar.contact_user_edit_success'), {
              variant: 'success'
            });
        } else {
          if (isShowSnackbar)
            enqueueSnackbar(t('snackbar.contact_user_error'), {
              variant: 'error'
            });
        }
      });
    }
  };
  return (
    <Dialog
      open={myContacts?.dataCreateEditDialog !== false}
      onClose={() =>
        onSetStateContact({
          dataCreateEditDialog: false
        })
      }
      disableEscapeKeyDown={true}
      classes={{ paper: !matches ? classes.paper : classes.paperMobile }}
    >
      <DialogTitle
        classes={{ root: clsx(classes.titleRoot, 'border-b') }}
        disableTypography
      >
        {!matches ? (
          <div className={classes.ContentTop}>
            <div className={classes.BoxIcon}>
              {!isEdit ? (
                <img src={CapaAddContact} />
              ) : (
                <img src={CapaEditContact} />
              )}
            </div>
            <div className={classes.Title}>
              {!isEdit
                ? t('contact.form.new_contact')
                : t('contact.form.edit_contact')}
            </div>
          </div>
        ) : (
          <div className={classes.ContentTop}>
            <img
              src={CapaBackIcon}
              onClick={() =>
                onSetStateContact({
                  dataCreateEditDialog: false
                })
              }
            />
            <div className={classes.Title}>
              {!isEdit
                ? t('contact.form.new_contact')
                : t('contact.form.edit_contact')}
            </div>
          </div>
        )}
      </DialogTitle>
      <DialogContent className={classes.DialogContent}>
        <div className={classes.ContentMain}>
          <div className={classes.LabelInput}>{t('contact.form.email*')}</div>
          <TextField
            value={email ?? undefined}
            placeholder=""
            variant="outlined"
            size="small"
            autoComplete="off"
            fullWidth
            disabled={isEdit}
            onChange={(event) => setEmail && setEmail(event.target.value)}
            className={clsx(classes.Input, isFailEmail ? classes.error : null)}
          />
          {isFailEmail ? (
            <p className={classes.invalid}>{t('sign_up.valid_email')}</p>
          ) : null}
          {isEdit && (
            <>
              <div className={classes.LabelInput}>{t('contact.form.name')}</div>
              <TextField
                value={name ?? undefined}
                placeholder=""
                variant="outlined"
                size="small"
                autoComplete="off"
                fullWidth
                disabled={isEdit}
                className={classes.Input}
              />
            </>
          )}
          <div className={classes.LabelInput}>{t('contact.form.memo')}</div>
          <TextareaAutosize
            value={memo ?? undefined}
            autoComplete="off"
            rowsMin={3}
            rowsMax={4}
            className={classes.InputArea}
            onChange={(event) => setMemo && setMemo(event.target.value)}
          />
        </div>
      </DialogContent>
      <DialogActions classes={{ root: classes.action }}>
        {!matches ? (
          <div className={clsx(classes.wrapperBtn, 'grid-col-2')}>
            <CustomizedButton
              variant="contained"
              color="default"
              onClick={() =>
                onSetStateContact({
                  dataCreateEditDialog: false
                })
              }
            >
              {t('contact.form.button.cancel')}
            </CustomizedButton>
            <CustomizedButton
              type="submit"
              disabled={isDisableSubmit}
              variant="contained"
              color="primary"
              onClick={() => onSubmitMyContact()}
            >
              {t('contact.form.button.done')}
            </CustomizedButton>
          </div>
        ) : (
          <div className={classes.wrapperBtn}>
            <CustomizedButton
              type="submit"
              disabled={isDisableSubmit}
              variant="contained"
              color="primary"
              onClick={() => onSubmitMyContact()}
            >
              {t('contact.form.button.done')}
            </CustomizedButton>
          </div>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default FormContactDialog;
