const colorSet = {
  primary050: '#F8FBFD',
  primary100: '#F4F8FF',
  primary150: '#F8FAFF',
  primary200: '#E9F1FF',
  primary250: '#EDF3FF',
  primary300: '#CADDFF',
  primary400: '#8EB7FF',
  primary450: '#4B88FF',
  primary500: '#3479FF',
  primary550: '#4F56F1',
  primary600: '#125CED',
  primary700: '#094BCA',
  primary800: '#1042A3',
  primary900: '#12367C',
  primary1000: '#1A2A4A',
  successLight: '#E8F5E9',
  successBase: '#2BC404',
  successMain: '#22C245',
  successDark: '#06A228',
  pink200: '#FF86EC',
  pink300: '#FF7F7C',
  pink500: '#FF007A',
  errorLight: '#fce7e7',
  errorBase: '#F88B88',
  errorMain: '#e53935',
  errorDark: '#c62828',
  warningLight: '#fff5e5',
  warningBase: '#FFDA6B',
  warningMain: '#ffc107',
  warningDark: '#ffa000',
  gray000: '#ffffff',
  gray100: '#F8F9FA',
  gray200: '#F1F3F5',
  gray300: '#E9ECEF',
  gray400: '#DEE2E6',
  gray450: '#DEE2E6',
  gray500: '#CED4DA',
  gray550: '#DADADA',
  gray600: '#ADB5BD',
  gray700: '#868E96',
  gray800: '#495057',
  gray900: '#343A40',
  gray1000: '#212529',
  gray1100: '#181A1A',
  gray2000: '#000000',
  yellow900: '#F4AB3E',
  red500: '#E4371F',
  backgroundLight: '#F8F9FA',
  backgroundBase: '#e5e5e5',
  backgroundDark: '#c2c2c2',
  backgroundDrop: 'rgba(0, 0, 0, 0.5)',
  backgroundDropViewer: 'rgba(0, 0, 0, 0.88)',
  backgrounLinearViewer:
    'linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%)'
};

export default colorSet;
