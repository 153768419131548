import { IBMChevronLeft } from '@assets';
import Loading from '@components/LoadingInComponent';
import useMyDrive from '@hooks/Drive/MyDrive/useMyDrive';
import useGroup from '@hooks/Group/useGroup';
import useUserActionLog, {
  CapaActivityType,
  Enum_Action_Button_Enum,
  Enum_Action_Page_Enum
} from '@hooks/useUserActionLog';
import {
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import {
  DEFAULT_ITEMS_LIMIT,
  DEFAULT_ITEMS_OFFSET,
  chatBotEmail
} from '@routes/Common/types';
import {
  SHARE_DIALOG_SCREEN,
  SHARE_FILES_TO_OPTIONS,
  SHARE_FILES_TO_VALUE
} from '@routes/Dashboard/Dialog/Share/types';
import { STORAGE_ACTION } from '@utils/storage';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ReactComponent as IBMAddComment } from './../../../../assets/icon/IBMAddComment.svg';
import { ReactComponent as IBMChat } from './../../../../assets/icon/IBMChat.svg';
import { ReactComponent as IBMShare } from './../../../../assets/icon/IBMShare.svg';
import { useStyles } from './styles';

interface Props {
  checkedFiles?: any;
  myDrivefiles?: any;
}

const SharePresenterDialog = (props: Props) => {
  // const { checkedFiles, myDrivefiles } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();
  const {
    onSetShowHideShareDialog,
    onSetShareDialogScreen,
    onSetShowHideFileDetail
  } = useMyDrive();
  const { onRefetchGroupsData, groupsState } = useGroup();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const onClose = () => {
    onSetShowHideShareDialog({ isShowHideShareDialog: false });
    if (isMobile) {
      onSetShowHideFileDetail({
        isShowIsDetailFile: false
      });
      localStorage.removeItem(STORAGE_ACTION.IS_OPEN_DETAIL_FILE);
    }
  };
  const { onInsertUserActionLogOne } = useUserActionLog();
  const [groupStateNonChatBot, setGroupStateNonChatBot] = useState<any>([]);

  const onClickShareFilesBox = (shareFileToType: any) => {
    if (shareFileToType === SHARE_FILES_TO_VALUE.A_NEW_GROUP) {
      onInsertUserActionLogOne(
        Enum_Action_Button_Enum.Button_ShareModal_NewGroup,
        CapaActivityType.ButtonClick
      );
      onSetShareDialogScreen({
        shareDialogScreen: SHARE_DIALOG_SCREEN.CREATE_NEW_GROUP
      });
    } else if (shareFileToType === SHARE_FILES_TO_VALUE.GROUPS_YOU_ARE_JOINED) {
      onInsertUserActionLogOne(
        Enum_Action_Button_Enum.Button_ShareModal_MyGroups,
        CapaActivityType.ButtonClick
      );
      onSetShareDialogScreen({
        shareDialogScreen: SHARE_DIALOG_SCREEN.GROUPS_YOU_ARE_JOINED
      });
    }
  };

  useEffect(() => {
    onInsertUserActionLogOne(
      Enum_Action_Page_Enum.Page_ShareModal,
      CapaActivityType.PageVisit
    );
    onRefetchGroupsData({
      limit: DEFAULT_ITEMS_LIMIT,
      offset: DEFAULT_ITEMS_OFFSET
    });
  }, []);

  useEffect(() => {
    if (groupsState?.data) {
      setGroupStateNonChatBot(
        groupsState.data!.filter((group: any) =>
          group.users.every((user: any) => user.email !== chatBotEmail)
        )
      );
    }
  }, [groupsState]);

  return (
    <>
      <DialogTitle classes={{ root: classes.rootTitle }} disableTypography>
        {isMobile ? (
          <img
            src={IBMChevronLeft}
            alt=""
            className={classes.closeIcon}
            onClick={onClose}
          />
        ) : (
          <div className={classes.shareGroupAddIconWrapper}>
            <IBMShare />
          </div>
        )}

        <div className={classes.customTitle}>
          {t(isMobile ? 'share_modal.header_mobile' : 'share_modal.header')}
        </div>
      </DialogTitle>
      <DialogContent className={classes.csDialogContent}>
        {groupsState?.isLoading ? (
          <Loading
            open={true}
            styles={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 150
            }}
          />
        ) : (
          <>
            <span className={classes.csDialogText}>
              {groupStateNonChatBot.length === 0
                ? t('share_modal.description')
                : t('share_modal.share_file_to_groups')}
            </span>
            <div className={classes.shareFilesToBoxWrapper}>
              {SHARE_FILES_TO_OPTIONS.map((item: any, index: number) => {
                return groupStateNonChatBot.length === 0 &&
                  item.value ===
                    SHARE_FILES_TO_VALUE.GROUPS_YOU_ARE_JOINED ? null : (
                  <div
                    key={index}
                    className={clsx(
                      classes.shareFilesToBox,
                      classes.pointerCursor,
                      groupStateNonChatBot.length === 0 || isMobile
                        ? classes.shareFilesToBoxWidthMobile
                        : classes.shareFilesToBoxWidth,
                      item.value ===
                        SHARE_FILES_TO_VALUE.GROUPS_YOU_ARE_JOINED && !isMobile
                        ? classes.shareToExisting
                        : classes.shareToExistingMobile
                    )}
                    onClick={() => {
                      onClickShareFilesBox(item.value);
                    }}
                    onDoubleClick={() => {
                      onClickShareFilesBox(item.value);
                    }}
                  >
                    <div className="icon-wrapper">
                      {item.value ===
                      SHARE_FILES_TO_VALUE.GROUPS_YOU_ARE_JOINED ? (
                        <IBMChat />
                      ) : (
                        <IBMAddComment />
                      )}
                    </div>
                    <div className={classes.shareToOption}>
                      <Trans i18nKey={t(item.name)} />
                    </div>
                  </div>
                );
              })}
            </div>

            {/* {checkedFiles?.length > 1 ? null : (
              <ShareLink
                leftHeader={
                  <>
                    {isMobile ? null : (
                      <div className={classes.shareGroupAddIconWrapper}>
                        <IBMLink />
                      </div>
                    )}
                    <div className={classes.shareLinkTitle}>
                      {t('share_modal.share_link.share_link_title')}
                    </div>
                  </>
                }
                isShowActivateLink={true}
                isShowAttachmentsTitle={!isMobile}
                checkedFiles={checkedFiles}
                myDrivefiles={myDrivefiles}
              />
            )} */}
          </>
        )}
      </DialogContent>
    </>
  );
};

export default SharePresenterDialog;
