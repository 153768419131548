import React, { useEffect, useRef, useState } from 'react';
import {
  DialogContent,
  DialogActions,
  TextField,
  Popover,
  Typography,
  InputAdornment
} from '@material-ui/core';
import { useStylesMembers } from './styles';
import { CheckOutlined } from '@material-ui/icons';
import {
  DEFAULT_SELECTED_MEMBER,
  ACCESS_LEVEL_VALUE,
  ACCESS_LEVEL_NAME,
  ACCESS_LEVEL_OPTIONS
} from '@routes/Dashboard/Dialog/Share/types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useSnackbar } from '@components/Provider';
import { CapaErrorCode } from '@generated/Common/graphql';
import { CapaActivityType } from '@generated/Common/types';
import {
  CheckRegisteredCapaService,
  SearchUser
} from '@generated/Drive/MyDrive/graphql';
import useMyDrive from '@hooks/Drive/MyDrive/useMyDrive';
import { useTranslation } from 'react-i18next';
import useDebounce from '@hooks/useDebounce';
import {
  DEFAULT_ITEMS_MAX,
  DEFAULT_ITEMS_OFFSET,
  KEY_TYPE
} from '@routes/Common/types';
import * as Apollo from '@apollo/client';
import useUserActionLog, {
  Enum_Action_Button_Enum,
  Enum_Action_Input_Enum,
  Enum_Action_Page_Enum
} from '@hooks/useUserActionLog';
import { getDisplayedAvatarString } from '@utils/common';
import AddContacts from '@routes/Dashboard/Dialog/Share/AddContacts/AddContacts';
import CapaAvatar from '@components/CapaAvatar';
import clsx from 'clsx';
import useAuth from '@hooks/useAuth';
import { ReactComponent as IBMDelete } from './../../../../../assets/icon/IBMDelete.svg';
import { ReactComponent as MyContacts } from './../../../../../assets/icon/MyContacts.svg';
import { ReactComponent as IBMCaretDown } from './../../../../../assets/icon/IBMCaretDown.svg';
import { ReactComponent as IBMCaretUp } from './../../../../../assets/icon/IBMCaretUp.svg';
import { uniqueArray } from '@utils/formatter';

interface Props {
  setMemberList?: (optionListMembers: any) => void;
  checkedUsers?: any;
  fileOwner?: any;
}

const Members = (props: Props) => {
  const { setMemberList, checkedUsers, fileOwner } = props;
  const { t } = useTranslation();
  const classes = useStylesMembers({ checkedUsers });
  const [anchorEl, setAnchorEl] = useState(null);
  const [listMembers, setListMembers] = useState<any>([]);
  const [isAddMembers, setIsAddMembers] = useState(true);
  const [optionListMembers, setOptionListMembers] = useState<any>([]);
  const [selectedMember, setSelectedMember] = useState(DEFAULT_SELECTED_MEMBER);
  const [manageUser, setManageUser] = useState({
    accessLevel: undefined
  });
  const prevManageUser = useRef(manageUser);
  const { searchUserState, onSearchUser } = useMyDrive();
  const patternValue =
    /[-A-Za-z0-9_]+[-A-Za-z0-9_.]*[@]{1}[-A-Za-z0-9_]+[-A-Za-z0-9_.]*[.]{1}[A-Za-z]{1,5}/;
  const [emailValue, setEmailValue] = useState('');
  const [isDisabledAddButton, setDisabledAddButton] = useState(true);
  const [internalUserOptions, setInternalUserOptions] = useState<any>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [internalSearchUser] = Apollo.useLazyQuery(SearchUser, {
    fetchPolicy: 'no-cache'
  });
  const { onInsertUserActionLogOne } = useUserActionLog();
  const [checkRegisteredCapaService, { data: checkRegisteredCapaServiceData }] =
    Apollo.useLazyQuery(CheckRegisteredCapaService, {
      fetchPolicy: 'no-cache'
    });
  const [isShowHideContactsModal, setShowHideContactsModal] =
    useState<boolean>(false);
  const { userState } = useAuth();

  useEffect(() => {
    setMemberList && setMemberList(optionListMembers);
  }, [optionListMembers]);

  useEffect(() => {
    if (checkedUsers) {
      const internalCheckedUsers: any = checkedUsers?.map((user: any) => {
        return {
          userName: user.nickName,
          email: user.email,
          accessLevel: ACCESS_LEVEL_VALUE.MANAGER,
          isCapaUser: user.isCapaUser,
          accessLevelName: ACCESS_LEVEL_NAME.MANAGER
        };
      });
      setMemberList && setMemberList(internalCheckedUsers ?? []);
      setOptionListMembers(internalCheckedUsers ?? []);
    }
  }, [checkedUsers]);

  useEffect(() => {
    if (fileOwner && userState?.data?.email !== fileOwner?.fileOwnerEmail) {
      const internalFileOwner: any = {
        userName: fileOwner?.fileOwnerUsername ?? '',
        email: fileOwner?.fileOwnerEmail ?? '',
        accessLevel: ACCESS_LEVEL_VALUE.MANAGER,
        isCapaUser: true,
        accessLevelName: ACCESS_LEVEL_NAME.MANAGER
      };
      setMemberList && setMemberList([internalFileOwner] ?? []);
      setOptionListMembers([internalFileOwner] ?? []);
    }
  }, [fileOwner]);

  useEffect(() => {
    prevManageUser.current = manageUser || {};
  }, [manageUser]);

  useEffect(() => {
    onInsertUserActionLogOne(
      Enum_Action_Page_Enum.Page_CreateGroup_AddMembersModal,
      CapaActivityType.PageVisit
    );
    internalSearchUser({
      variables: {
        keyword: '',
        limit: DEFAULT_ITEMS_MAX,
        offset: DEFAULT_ITEMS_OFFSET
      }
    });
  }, []);

  const handleClickOpenPopUp = (event: any, item: any, index: number) => {
    setAnchorEl(event.currentTarget);
    const member = { item, index };
    setSelectedMember(member);
    setManageUser(member.item);
  };

  const handleClosePopUp = () => {
    setAnchorEl(null);
  };

  const handleSelected = (val: any) => {
    let newArr: any = [...optionListMembers];
    if (val.value === ACCESS_LEVEL_VALUE.REMOVE) {
      newArr.splice(selectedMember.index, 1);
      setOptionListMembers(newArr);
      handleClosePopUp();
      return;
    }
    newArr[selectedMember.index] = {
      userName: selectedMember.item.userName,
      email: selectedMember.item.email,
      accessLevel: val.value,
      isCapaUser: selectedMember.item.isCapaUser,
      accessLevelName: val.name
    };
    setOptionListMembers(newArr);

    handleClosePopUp();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'popoverId' : undefined;

  const onAddContacts = (contactIds: any) => {
    checkRegisteredCapaService({
      variables: {
        emails: contactIds
      }
    });
  };

  const handleClickAddMembers = (value: any) => {
    onInsertUserActionLogOne(
      Enum_Action_Button_Enum.Button_CreateGroup_AddMembersModal_Add,
      CapaActivityType.ButtonClick
    );
    checkRegisteredCapaService({
      variables: {
        emails: value.length > 0 ? value : listMembers
      }
    });
  };

  useEffect(() => {
    if (
      checkRegisteredCapaServiceData?.checkRegisteredCapaService?.result ===
      CapaErrorCode.Success
    ) {
      const groupMembers: any =
        checkRegisteredCapaServiceData?.checkRegisteredCapaService?.data?.map(
          (item: any) => {
            return {
              userName: item?.connectUser?.userName,
              email: item?.email?.replace(/\s/g, ''),
              accessLevel: ACCESS_LEVEL_VALUE.MANAGER,
              isCapaUser: item?.scopes?.length > 0,
              accessLevelName: ACCESS_LEVEL_NAME.MANAGER
            };
          }
        );

      const isDuplicateEmail = optionListMembers.some(
        (member: any) => groupMembers[0].email === member.email
      );

      if (isDuplicateEmail) {
        enqueueSnackbar(t('snackbar.this_email_already_exists_in_the_group'), {
          variant: 'error'
        });
      }

      setOptionListMembers(
        uniqueArray([...optionListMembers, ...groupMembers], 'email')
      );

      setEmailValue('');
      setListMembers([]);
      setInternalUserOptions([]);
      setIsAddMembers(true);
    }
  }, [checkRegisteredCapaServiceData]);

  const debouncedSearchTerm = useDebounce(emailValue, 500);

  useEffect(() => {
    if (debouncedSearchTerm) {
      onSearchUser({
        variables: {
          keyword: debouncedSearchTerm,
          limit: 40,
          offset: 0
        }
      });
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (searchUserState?.data?.length > 0) {
      setInternalUserOptions(searchUserState?.data);
    }
  }, [searchUserState]);

  useEffect(() => {
    if (
      emailValue &&
      (!patternValue.test(emailValue) || emailValue === userState?.data?.email)
    ) {
      setDisabledAddButton(true);
    } else {
      setDisabledAddButton(false);
    }
  }, [emailValue]);

  return (
    <>
      <DialogActions className={classes.csDialogContent}>
        <Autocomplete
          id="add-members"
          options={internalUserOptions.map((user: any) => user.email)}
          getOptionLabel={(option: any) => {
            return option?.length === 0 ? '' : option;
          }}
          freeSolo
          onChange={(event: any, newValue: any) => {
            if (
              event?.type === KEY_TYPE.KEY_DOWN &&
              newValue === userState?.data?.email
            ) {
              return;
            }
            onInsertUserActionLogOne(
              Enum_Action_Button_Enum.Click_CreateGroup_AddMembersModal_Contact,
              CapaActivityType.ButtonClick
            );
            const isValidEmail = patternValue.test(newValue);
            if (!isValidEmail) return;
            setInternalUserOptions([]);
            handleClickAddMembers([newValue]);
          }}
          value={[]}
          inputValue={emailValue}
          disabled={checkedUsers}
          renderInput={(params: any) => (
            <TextField
              {...params}
              placeholder={t('create_new_group_modal.invite_members')}
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) => {
                setEmailValue(e.target.value);

                if (e.target.value) {
                  setIsAddMembers(false);
                  // @ts-ignore
                  setListMembers([e.target.value]);
                } else {
                  setIsAddMembers(true);
                  setListMembers([]);
                }
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={clsx(
                      isDisabledAddButton || isAddMembers
                        ? classes.disabledAddButton
                        : classes.addButton
                    )}
                    onClick={handleClickAddMembers}
                  >
                    {t('add_members_modal.add_button')}
                  </InputAdornment>
                )
              }}
            />
          )}
          disableClearable={true}
          onFocus={() => {
            onInsertUserActionLogOne(
              Enum_Action_Input_Enum.Input_AddMembersModal_Email,
              CapaActivityType.actionInput
            );
          }}
        />
        {checkedUsers ? null : (
          <div
            className={classes.contactButtonWrapper}
            onClick={() => setShowHideContactsModal(true)}
          >
            <MyContacts />
          </div>
        )}
      </DialogActions>
      <div className={classes.contentWrapper}>
        <DialogContent className={classes.csDialogContentBottom}>
          <div className={classes.csDialogContentList}>
            <div className={classes.avatar}>
              <CapaAvatar
                item={{
                  connectUserName: userState?.data?.userName,
                  email: userState?.data?.email,
                  isCapaUser: true
                }}
              />
            </div>
            <div className={classes.csDialogContentText}>
              <div className={classes.usernameWrapper}>
                <span className={classes.csDialogOwnerName}>
                  {getDisplayedAvatarString(
                    userState?.data?.userName,
                    userState?.data?.email
                  )}
                </span>
                <span className={classes.youText}>
                  ({t('group_room.text_you')})
                </span>
              </div>
              <div className={classes.csDialogEmail}>
                {userState?.data?.email}
              </div>
            </div>
            <div className={classes.ownerText}>
              {t('add_members_modal.access_level.owner')}
            </div>
          </div>
        </DialogContent>
        {optionListMembers &&
          optionListMembers.map((member: any, index: number) => (
            <DialogContent
              className={classes.csDialogContentBottom}
              key={index}
            >
              <div className={classes.csDialogContentList}>
                <div className={classes.avatar}>
                  <CapaAvatar item={member} />
                </div>
                <div className={classes.csDialogContentText}>
                  <div className={classes.csDialogUserName}>
                    {getDisplayedAvatarString(member.userName, member.email)}
                  </div>
                  <div className={classes.csDialogEmail}>{member.email}</div>
                </div>
                <div
                  className={clsx(
                    classes.csDialogContentPosition,
                    open && selectedMember.item.email === member.email
                      ? classes.boxShadowContent
                      : ''
                  )}
                  aria-describedby={id}
                  onClick={(e) => {
                    onInsertUserActionLogOne(
                      Enum_Action_Button_Enum.Button_CreateGroup_AddMembersModal_GroupAccess,
                      CapaActivityType.ButtonClick
                    );
                    handleClickOpenPopUp(e, member, index);
                  }}
                >
                  {t(
                    ACCESS_LEVEL_OPTIONS.filter(
                      (option: any) => option.value === member.accessLevel
                    )[0]
                      ? ACCESS_LEVEL_OPTIONS.filter(
                          (option: any) => option.value === member.accessLevel
                        )[0].name
                      : ''
                  )}
                  {open ? <IBMCaretUp /> : <IBMCaretDown />}
                </div>
              </div>
            </DialogContent>
          ))}
        <Popover
          className={classes.customPopover}
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClosePopUp}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
        >
          <div className={classes.csDialogContentPopup}>
            {ACCESS_LEVEL_OPTIONS.map((accessLevel: any, index: number) => (
              <div key={index}>
                <Typography
                  component={'div'}
                  onClick={() => handleSelected(accessLevel)}
                  className={clsx(
                    classes.itemPopup,
                    accessLevel.value === ACCESS_LEVEL_VALUE.REMOVE
                      ? classes.removedItem
                      : ''
                  )}
                >
                  {accessLevel.value === ACCESS_LEVEL_VALUE.REMOVE ? (
                    <IBMDelete className="mr-8" />
                  ) : null}
                  <div className="name">
                    <div>{t(accessLevel.name)}</div>
                    <p>{accessLevel.title}</p>
                  </div>
                  {selectedMember.item.accessLevel === accessLevel.value ? (
                    <CheckOutlined className={classes.iconCheck} />
                  ) : null}
                </Typography>
                {accessLevel.value === ACCESS_LEVEL_VALUE.MEMBER ? (
                  <div className="spacing-div" />
                ) : null}
              </div>
            ))}
          </div>
        </Popover>
      </div>
      <AddContacts
        isOpen={isShowHideContactsModal}
        onClose={() => setShowHideContactsModal(false)}
        onAddContacts={onAddContacts}
        disabledContacts={optionListMembers.map((member: any) => member.email)}
      />
    </>
  );
};

export default Members;
