import React from 'react';
import { colorSet } from '@components/Provider';
import { makeStyles, Theme, Typography } from '@material-ui/core';

interface LabelProps {
  variant?: 'square' | 'round' | 'outlined';
  startIcon?: React.ReactNode;
  color?:
    | 'default'
    | 'heavyPrimary'
    | 'heavyGray'
    | 'heavyGreen'
    | 'heavyRed'
    | 'heavyYellow'
    | 'lightPrimary'
    | 'lightGray'
    | 'lightGreen'
    | 'lightRed'
    | 'lightYellow';
}

const useStyles = makeStyles<Theme, LabelProps>((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    width: 'max-content',
    minHeight: 26,
    minWidth: (props) => (props.startIcon ? 64 : 48),
    padding: (props) => (props.startIcon ? '4px 8px 4px 4px' : '4px 8px'),
    backgroundColor: (props) => {
      if (props.variant !== 'outlined') {
        if (props.color === 'heavyPrimary') return colorSet.primary500;
        if (props.color === 'lightPrimary') return colorSet.primary200;

        if (props.color === 'heavyGray') return colorSet.gray400;
        if (props.color === 'lightGray') return colorSet.gray200;

        if (props.color === 'heavyGreen') return colorSet.successMain;
        if (props.color === 'lightGreen') return colorSet.successLight;

        if (props.color === 'heavyRed') return colorSet.errorMain;
        if (props.color === 'lightRed') return colorSet.errorLight;

        if (props.color === 'heavyYellow') return colorSet.warningMain;
        if (props.color === 'lightYellow') return colorSet.warningLight;
        return colorSet.primary500;
      } else {
        return colorSet.gray000;
      }
    },
    border: (props) => {
      if (props.variant === 'outlined') {
        if (props.color?.includes('Primary'))
          return `1px solid ${colorSet.primary500}`;

        if (props.color?.includes('Gray'))
          return `1px solid ${colorSet.gray400}`;

        if (props.color?.includes('Green'))
          return `1px solid ${colorSet.successMain}`;

        if (props.color?.includes('Red'))
          return `1px solid ${colorSet.errorMain}`;

        if (props.color === 'heavyYellow' || props.color === 'lightYellow')
          return `1px solid ${colorSet.warningMain}`;

        return `1px solid ${colorSet.primary500}`;
      }
      return '';
    },
    borderRadius: (props) => {
      if (props.variant === 'round') return 100;

      return 4;
    }
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    ...theme.typography.caption,
    color: (props) => {
      if (props.variant !== 'outlined') {
        if (props.color === 'heavyPrimary') return colorSet.gray000;
        if (props.color === 'lightPrimary') return colorSet.primary500;

        if (props.color === 'heavyGray') return colorSet.gray900;
        if (props.color === 'lightGray') return colorSet.gray600;

        if (props.color === 'heavyGreen') return colorSet.gray000;
        if (props.color === 'lightGreen') return colorSet.successMain;

        if (props.color === 'heavyRed') return colorSet.gray000;
        if (props.color === 'lightRed') return colorSet.errorMain;

        if (props.color === 'heavyYellow') return colorSet.gray000;
        if (props.color === 'lightYellow') return colorSet.warningMain;
        return colorSet.gray000;
      } else {
        if (props.color?.includes('Primary')) return colorSet.primary500;

        if (props.color?.includes('Gray')) return colorSet.gray900;

        if (props.color?.includes('Green')) return colorSet.successMain;

        if (props.color?.includes('Red')) return colorSet.errorMain;

        if (props.color?.includes('Yellow')) return colorSet.warningMain;

        return colorSet.primary500;
      }
    },

    fontWeight: 500,
    '& svg': {
      width: 16,
      height: 16,
      marginRight: 4
    }
  }
}));

/**
 * @param variant 라벨의 모양
 * @param startIcon 아이콘
 * @param color 라벨의 색상
 * @param children 라벨의 내용
 */

const CustomizedLabel: React.FC<LabelProps> = (props) => {
  const classes = useStyles({
    ...props
  });

  return (
    <Typography className={classes.root}>
      <span className={classes.label}>
        {props.startIcon}
        {props.children}
      </span>
    </Typography>
  );
};

export default CustomizedLabel;
