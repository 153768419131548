import React from 'react';
import { useHistory } from 'react-router-dom';
import { CustomizedButton } from '@components/Customized';
import { useTheme, useMediaQuery, AppBar, Toolbar } from '@material-ui/core';
import useUserActionLog, {
  CapaActivityType,
  Enum_Action_Button_Enum
} from '@hooks/useUserActionLog';
import { CapaLogoBeta, CapaLogoBetaKo } from '@assets';
import StagingChipPresenter from '@routes/Common/StagingChipPresenter';
import { useStylesGnbAppBar } from '@routes/Common/GnbAppBar/useStyles';
import { useTranslation } from 'react-i18next';
import { LANGUAGE_VALUE } from '@routes/Common/ChangeLanguage/types';
import i18n from '@i18n/index';

const SignUpHeaderPresenter: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStylesGnbAppBar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const history = useHistory();
  const { onInsertUserActionLogOne } = useUserActionLog();
  const languageValue = i18n.language;
  const logo =
    languageValue === LANGUAGE_VALUE.KO ? CapaLogoBetaKo : CapaLogoBeta;

  const onClickBackHandle = () => {
    insertLog(Enum_Action_Button_Enum.Button_Gotologin);
    history.push('/login');
  };

  const insertLog = (action: Enum_Action_Button_Enum) => {
    onInsertUserActionLogOne(action, CapaActivityType.ButtonClick);
  };

  return (
    <AppBar elevation={0} position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        {!isMobile && (
          <div
            className={classes.logo}
            onClick={() => {
              insertLog(Enum_Action_Button_Enum.Button_Gnb_Logo);
              history.push('/');
            }}
          >
            <img src={logo} alt="Logo" />
            <StagingChipPresenter />
          </div>
        )}
        <div className={classes.menuWrapper}>
          {isMobile ? (
            <img src={logo} alt="Logo" className={classes.logoMobile} />
          ) : (
            <div className={classes.accountQuestionText}>
              {t('sign_up.have_an_account')}
            </div>
          )}
          <CustomizedButton
            type="submit"
            size="small"
            className={classes.logInButton}
            onClick={() => onClickBackHandle()}
          >
            {t('sign_up.log_in')}
          </CustomizedButton>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default SignUpHeaderPresenter;
