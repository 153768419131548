import styled from 'styled-components';
import { colorSet } from '@components/Provider';
import { Box } from '@material-ui/core';

export const MuiBox = styled(Box)`
  background-color: ${colorSet.gray000};
  border-radius: 8px;
  .terms-content {
    padding: 36px;
    .agreement-content {
      color: ${colorSet.gray900};
      max-height: 100%;
      white-space: wrap;
      overflow-x: hidden;
      overflow-y: scroll;
      .title {
        font-size: 14px;
        font-weight: bold;
        line-height: 21px;
        margin-top: 24px;
      }
      .title:first-child {
        margin-top: 0px;
      }
      .content {
        font-size: 12px;
        line-height: 18px;
      }
      .content.marketing {
        white-space: pre-line;
      }
    }
  }
`;
