import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@modules/index';
import {
  updateDashboardState,
  initDashboardState,
  DashboardState
} from '@modules/dashboard';

export default function useDashboard() {
  const { dashboard } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  const onUpdateDashboard = (params: DashboardState) =>
    dispatch(updateDashboardState(params));

  const onInitDashboard = useCallback(
    () => dispatch(initDashboardState()),
    [dispatch]
  );

  return {
    dashboard,
    onUpdateDashboard,
    onInitDashboard
  };
}
