import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { fileDownloadApi, fileDownloadWithOutAuthApi } from '@api/index';
import {
  useRemoveConnectFileMutation,
  CapaConnectMyFile,
  StatusCode
} from '@generated/Common/graphql';

export default function useFiles() {
  const [removeConnectFile] = useRemoveConnectFileMutation();

  const onDownloadConnectFile = async (
    file: CapaConnectMyFile,
    isChatBox?: boolean
  ) => {
    const resp = await fileDownloadApi(file, isChatBox);
    if (resp.status === StatusCode.Success) {
      let blob = new Blob([resp.data], { type: resp.headers['content-type'] });
      saveAs(blob, file.fileName);
      return true;
    }
    return false;
  };
  const onDownloadWithoutAuthConnectFile = async (file: CapaConnectMyFile) => {
    const resp = await fileDownloadWithOutAuthApi(file);
    if (resp.status === StatusCode.Success) {
      let blob = new Blob([resp.data], { type: resp.headers['content-type'] });
      saveAs(blob, file.fileName);
      return true;
    }
    return false;
  };

  const onDownloadZipConnectFile = async (files: Array<CapaConnectMyFile>) => {
    const zip = new JSZip();
    const zipFile = zip.folder('files');
    const list = files.map(async (file) => {
      const resp = await fileDownloadApi(file);
      let blob = new Blob([resp.data], { type: resp.headers['content-type'] });
      if (zipFile) {
        zipFile.file(file.fileName, blob);
      }
      return resp;
    });

    await Promise.all(list).then(() => {
      zip.generateAsync({ type: 'blob' }).then((content) => {
        saveAs(content, `files.zip`);
      });
    });
  };

  const onRemoveConnectFile = async (files: Array<CapaConnectMyFile>) => {
    return await removeConnectFile({
      variables: {
        fileIds: files.map((file) => file.id || file.fileId),
        isFolder: true,
        filePath: files[0].filePath
      }
    });
  };

  return {
    onDownloadConnectFile,
    onDownloadWithoutAuthConnectFile,
    onDownloadZipConnectFile,
    onRemoveConnectFile
  };
}
