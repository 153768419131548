import React from 'react';
import { useStyles } from '@routes/GroupRoom/styles';
import { formatFileSize } from '@utils/formatter';
import useMyDrive from '@hooks/Drive/MyDrive/useMyDrive';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { IBMDownload } from '@assets';
import ActionButton from '@routes/GroupRoom/Messages/ActionButton';
import { useTranslation } from 'react-i18next';
import Loading from '@components/LoadingInComponent';
import clsx from 'clsx';

interface Props {
  message: any;
  userEmail: string;
  refetchMessage?: () => void;
  openDownloadAppBanner?: (event: any) => void;
  onClickDownloadFile?: (message: any) => void;
  messageDataOrigin?: any;
}

const FileMessage = (props: Props) => {
  const {
    message,
    userEmail,
    openDownloadAppBanner,
    onClickDownloadFile,
    messageDataOrigin
  } = props;
  const classes = useStyles();
  const { onSetActiveFileIdInGroupRoom } = useMyDrive();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { t } = useTranslation();

  const onCLickFile = (event: any) => {
    if (isMobile) {
      openDownloadAppBanner && openDownloadAppBanner(event);
    }
    onSetActiveFileIdInGroupRoom({
      activeFileIdInGroupRoom: message.chatFiles[0].fileId
    });
  };

  const onClickDownload = () => {
    onClickDownloadFile && onClickDownloadFile(message);
  };

  return (
    <div
      className={classes.fileMessageWrapper}
      onClick={(event: any) => onCLickFile(event)}
    >
      <div className={classes.fileContent}>
        <div className={classes.fileTitle}>
          <div className={classes.fileName}>
            {message.chatFiles[0].fileName}
          </div>
          {message?.chatFiles[0]?.isUpload ? (
            <div className="loading-wrapper">
              <Loading
                open={true}
                styles={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
                size={32}
              />
            </div>
          ) : (
            <div
              className="download-button-wrapper"
              onClick={(e) => {
                e.stopPropagation();
                onClickDownload();
              }}
            >
              <img src={IBMDownload} alt="" />
            </div>
          )}
        </div>
        <div
          className={clsx(
            classes.fileSize,
            message?.chatFiles[0]?.isUpload ? '' : 'mb-16'
          )}
        >
          {`${t('chat_room.size')}: ${formatFileSize(
            message.chatFiles[0].fileSize
          ).toUpperCase()}`}
        </div>
        {!message.chatFiles[0].isUpload && (
          <ActionButton
            messageDataOrigin={messageDataOrigin}
            message={message ?? null}
            userEmail={userEmail}
            clickDownload={onClickDownload}
          />
        )}
      </div>
    </div>
  );
};

export default FileMessage;
