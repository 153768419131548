import { CustomizedTypography } from '@components/Customized';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import React from 'react';
import { Button } from '@material-ui/core';
import history from '@utils/history';

const NotFoundContent = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.root}>
      <CustomizedTypography variant="h1" fontWeight="bold" gutterBottoms={12}>
        {t('not_found_page.title')}
      </CustomizedTypography>
      <CustomizedTypography variant="h4" gutterBottoms={40}>
        {t('not_found_page.sub_title')}
      </CustomizedTypography>
      <CustomizedTypography variant="body2" color="textSecondary">
        {t('not_found_page.error_code')}
      </CustomizedTypography>
      <Button className={classes.btnGoHome} onClick={() => history.push('/')}>
        {t('not_found_page.go_home')}
      </Button>
    </div>
  );
};

export default NotFoundContent;
