import React, { useEffect, useState } from 'react';
import { useStyles, AttachFileButtonMenu } from './styles';
import {
  MoreVertOutlined,
  DeleteOutlined,
  CloseOutlined
} from '@material-ui/icons';
import { MenuItem, useMediaQuery, useTheme, Tooltip } from '@material-ui/core';
import { useSnackbar } from '@components/Provider';
import useMyDrive from '@hooks/Drive/MyDrive/useMyDrive';
import useGroupRoom from '@hooks/GroupRoom/useGroupRoom';
import { useLocation } from 'react-router-dom';
import { formatTimeStamp } from '@utils/formatter';
import { useMutation, useSubscription } from '@apollo/client';
import {
  RemoveConnectAnnotation,
  SubscribeConnectUpdateData,
  ReadConnectAnnotationComment
} from '@generated/Drive/MyDrive/graphql';
import { useTranslation } from 'react-i18next';
import { CapaErrorCode } from '@generated/Common/graphql';
import {
  HOOPS_VIEWER_IFRAME_ID,
  HOOPS_VIEWER_MESSAGE
} from '@routes/Viewer/types';
import AppConfig from '@config/index';
import useDashboard from '@hooks/useDashboard';
import Loading from '@components/LoadingInComponent';
import { getStorageItem, STORAGE_ACTION } from '@utils/storage';
import { CONNECT_UPDATE_TYPE } from '@routes/Common/types';
import CapaAvatar from '@components/CapaAvatar';
import { ReactComponent as IBMChevronLeft } from '../../../assets/icon/IBMChevronLeft.svg';
import clsx from 'clsx';

interface Props {
  onClose?: () => void;
}

const FileComment: React.FC<Props> = ({ onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [attachFileMenuAnchorEl, setAttachFileMenuAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const isOpenAttachFileMenu = Boolean(attachFileMenuAnchorEl);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const { search } = useLocation();
  const { t } = useTranslation();
  const [removeConnectAnnotation] = useMutation(RemoveConnectAnnotation);

  const [commentItem, setCommentItem] = useState({
    id: null,
    comments: [{ id: null }]
  });
  const groupId = new URLSearchParams(search).get('groupId');
  const {
    myDrive,
    onSelectDrawFileFromCommentList,
    onSetFirstSelectedDrawFile,
    onSetActionAnnotationSuccess,
    onSetActiveFileIdInGroupRoom,
    onToggleGroupFiles
  } = useMyDrive();
  const {
    onFetchConnectAnnotations,
    connectAnnotations,
    connectAnnotationsLoading,
    groupDataFilesState,
    onFetchGroupFilesData
  } = useGroupRoom();
  const [activeAnnotation, setActiveAnnotation] = useState<any>(null);
  const [activeAnnotationId, setActiveAnnotationId] = useState<any>('');
  const { onUpdateDashboard } = useDashboard();
  const userInfo = getStorageItem(STORAGE_ACTION.USER_INFO);
  const [readConnectAnnotationComment] = useMutation(
    ReadConnectAnnotationComment
  );

  useEffect(() => {
    fetchAnnotations();
  }, [myDrive.isShowFileGroup]);

  const handleClickAttachFileButton = (
    event: React.MouseEvent<HTMLElement>,
    item: any
  ) => {
    setCommentItem(item);
    setAttachFileMenuAnchorEl(event.currentTarget);
  };

  const fetchAnnotations = () => {
    onFetchConnectAnnotations({
      variables: {
        groupId: groupId,
        connectSharedFileId: null
      }
    });
  };

  const removeComment = () => {
    setAttachFileMenuAnchorEl(null);
    removeConnectAnnotation({
      variables: {
        groupId: groupId,
        connectSharedFileId: myDrive.activeFileIdInGroupRoom,
        annotationId: commentItem?.id
      }
    })
      .then((res) => {
        const result = res?.data?.removeConnectAnnotation?.result;
        if (result === CapaErrorCode.Success) {
          enqueueSnackbar(`${t('snackbar.delete_thread_success')}`, {
            variant: 'success'
          });

          fetchAnnotations();
          if (
            document.getElementById(HOOPS_VIEWER_IFRAME_ID) &&
            // @ts-ignore
            document.getElementById(HOOPS_VIEWER_IFRAME_ID).contentWindow
          ) {
            // @ts-ignore
            document
              .getElementById(HOOPS_VIEWER_IFRAME_ID)
              // @ts-ignore
              .contentWindow.postMessage(
                {
                  message: HOOPS_VIEWER_MESSAGE.ANNOTATION.DELETE,
                  data: {
                    annotationId: commentItem?.id
                  }
                },
                '*'
              );
          }
        }
      })
      .catch(() => {
        setAttachFileMenuAnchorEl(null);
        enqueueSnackbar(`${t('snackbar.delete_thread_error')}`, {
          variant: 'error'
        });
      });
  };

  useSubscription(SubscribeConnectUpdateData, {
    onSubscriptionData: (subscriptionData) => {
      const subscribeConnectUpdateData =
        subscriptionData?.subscriptionData?.data;
      if (subscribeConnectUpdateData) {
        switch (
          subscribeConnectUpdateData?.subscribeConnectUpdateData?.data?.type
        ) {
          case CONNECT_UPDATE_TYPE.FILE_UPDATE: {
            onFetchGroupFilesData({
              variables: {
                groupId: groupId
              }
            });
            break;
          }
          case CONNECT_UPDATE_TYPE.ANNOTATION_ADD: {
            fetchAnnotations();
            break;
          }
        }
      }
    }
  });

  useEffect(() => {
    if (activeAnnotation) {
      if (
        groupDataFilesState?.data?.filter(
          (file: any) => file.id === activeAnnotation.connectFileId
        ).length === 0 ||
        groupDataFilesState?.data === null
      ) {
        enqueueSnackbar(`${t('snackbar.removed_file')}`, {
          variant: 'success'
        });
        return;
      }

      const internalActiveAnnotation = {
        ...activeAnnotation,
        id: activeAnnotation?.connectFileId
      };
      onSetActiveFileIdInGroupRoom({
        activeFileIdInGroupRoom: activeAnnotation?.connectFileId
      });
      onSelectDrawFileFromCommentList({
        selectDrawFileFromCommentList: {
          annotationId: activeAnnotation?.id,
          commentId: activeAnnotation?.comments[0]?.id
        }
      });

      if (myDrive.isFirstSelectedDrawFile) {
        if (document.getElementById(HOOPS_VIEWER_IFRAME_ID)) {
          // @ts-ignore
          document
            .getElementById(HOOPS_VIEWER_IFRAME_ID)
            // @ts-ignore
            .contentWindow.postMessage(
              {
                message: HOOPS_VIEWER_MESSAGE.LOAD.LOAD_MODEL,
                data: {
                  modelId: `${AppConfig.capaViewerApi}/${internalActiveAnnotation?.drawingFile}`
                }
              },
              '*'
            );
          return;
        }
      }

      onSetFirstSelectedDrawFile({ isFirstSelectedDrawFile: false });

      onUpdateDashboard({ files: [internalActiveAnnotation] });
    } else {
    }
  }, [activeAnnotation]);

  useEffect(() => {
    if (myDrive.isActionAnnotationSuccess) {
      fetchAnnotations();
      onSetActionAnnotationSuccess({ isActionAnnotationSuccess: false });
    }
  }, [myDrive.isActionAnnotationSuccess]);

  const getUniqueListBy = (arr: any, key: any) => {
    // @ts-ignore
    return [...new Map(arr.map((item: any) => [item[key], item])).values()];
  };

  return (
    <>
      <div className={classes.root}>
        <div
          className={clsx(
            classes.collapseButton,
            myDrive.isShowGroupFiles && 'expanded'
          )}
          onClick={() =>
            onToggleGroupFiles({ isShowGroupFiles: !myDrive.isShowGroupFiles })
          }
        >
          <IBMChevronLeft />
        </div>
        <div className={classes.groupWraper}>
          <div className={classes.groupFile}>
            {t('annotation_comments.title')}{' '}
          </div>

          {matches && (
            <div onClick={onClose}>
              <CloseOutlined />
            </div>
          )}
        </div>
        <div className={classes.listItem}>
          <Loading
            open={connectAnnotationsLoading}
            loadingWrapperClass={classes.loadingWrapper}
          />
          {connectAnnotations &&
            connectAnnotations?.fetchConnectAnnotations &&
            connectAnnotations?.fetchConnectAnnotations?.data?.map(
              (item: any, index: number) => {
                const firstComment = item && item.comments && item.comments[0];
                if (!item.comments) return null;
                return (
                  <div
                    key={index}
                    className={clsx(
                      classes.item,
                      item.id === activeAnnotationId ? classes.activeItem : ''
                    )}
                    id={item.id}
                    onClick={() => {
                      if (item.hasNew) {
                        readConnectAnnotationComment({
                          variables: {
                            annotationId: item.id
                          }
                        }).finally(() => {
                          fetchAnnotations();
                        });
                      }
                      setActiveAnnotation(item);
                      setActiveAnnotationId(item.id);
                    }}
                  >
                    <div className={classes.avatarTop}>
                      <div className={classes.avatar}>
                        {item?.hasNew && <div className={classes.active} />}
                        {item?.comments &&
                          getUniqueListBy(item?.comments, 'email').map(
                            (avatar: any) => (
                              <CapaAvatar
                                item={{
                                  isCapaUser: true,
                                  connectUserName: avatar?.userName,
                                  email: avatar?.email
                                }}
                                size="small"
                                showTooltip={true}
                              />
                            )
                          )}
                      </div>
                      {(item?.comments &&
                        item?.comments[0]?.email !== userInfo.email) ||
                      groupDataFilesState?.data?.filter(
                        (file: any) => file.id === item?.connectFileId
                      ).length === 0 ||
                      groupDataFilesState?.data === null ? null : (
                        <div
                          onClick={(e) => {
                            handleClickAttachFileButton(e, item);
                          }}
                        >
                          <Tooltip title={t('tooltip.more') || 'More'}>
                            <MoreVertOutlined className={classes.moreIcon} />
                          </Tooltip>
                        </div>
                      )}
                    </div>
                    <Tooltip title={item.connectFileName}>
                      <div className={classes.text}>{item.connectFileName}</div>
                    </Tooltip>
                    <div className={classes.desc}>
                      <Tooltip
                        title={
                          firstComment?.userName
                            ? firstComment?.userName
                            : firstComment?.email
                        }
                      >
                        <span>
                          {firstComment?.userName
                            ? firstComment?.userName
                            : firstComment?.email}
                        </span>
                      </Tooltip>
                      <span>
                        {formatTimeStamp(
                          item.updatedAt,
                          false,
                          true,
                          true,
                          true
                        )}
                      </span>
                    </div>
                    <div className={classes.subText}>
                      {firstComment?.comment}
                    </div>
                    {item &&
                    item?.comments &&
                    item?.comments?.length - 1 === 1 ? (
                      <div className={classes.replies}>
                        {t('annotation_comments.reply', {
                          commentCount:
                            item && item?.comments && item?.comments?.length - 1
                        })}
                      </div>
                    ) : item &&
                      item?.comments &&
                      item?.comments?.length - 1 > 1 ? (
                      <div className={classes.replies}>
                        {t('annotation_comments.replies', {
                          commentCount:
                            item && item?.comments && item?.comments?.length - 1
                        })}
                      </div>
                    ) : null}
                  </div>
                );
              }
            )}
        </div>
        <AttachFileButtonMenu
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          anchorEl={attachFileMenuAnchorEl}
          getContentAnchorEl={null}
          open={isOpenAttachFileMenu}
          onClose={() => {
            setAttachFileMenuAnchorEl(null);
          }}
          keepMounted
        >
          {/*<MenuItem onClick={senToChat}>*/}
          {/*  <SendOutlined fontSize="small" />*/}
          {/*  {t('annotation_comments.send_to_chat')}*/}
          {/*</MenuItem>*/}

          <MenuItem onClick={removeComment}>
            <DeleteOutlined fontSize="small" />
            {t('annotation_comments.delete_thread')}
          </MenuItem>
        </AttachFileButtonMenu>
      </div>
    </>
  );
};

export default FileComment;
