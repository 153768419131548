import { colorSet } from '@components/Provider';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    whiteSpace: 'break-spaces',

    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },

  accountQuestionText: {
    color: colorSet.gray600,
    fontSize: 16,
    lineHeight: '150%',
    letterSpacing: '-0.1px',
    marginRight: 16,
    marginLeft: 14
  },

  logInButton: {
    color: colorSet.gray900,
    backgroundColor: colorSet.gray000,
    border: `1px solid ${colorSet.gray600}`,
    borderRadius: 8,
    textTransform: 'none',
    '&:hover': {
      color: colorSet.gray900,
      backgroundColor: colorSet.gray000,
      border: `1px solid ${colorSet.gray600}`,
      borderRadius: 8,
      textTransform: 'none'
    }
  },
  btnGoHome: {
    backgroundColor: colorSet.primary500,
    color: colorSet.gray000,
    borderRadius: 8,
    fontSize: 14,
    textTransform: 'none',
    lineHeight: '20px',
    padding: '10px 19px !important',
    fontWeight: 500,
    margin: '40px auto',
    '&:hover': {
      backgroundColor: `${colorSet.primary450} !important`
    }
  }
}));
