import React from 'react';
import {
  Dialog,
  DialogContent,
  useTheme,
  useMediaQuery
} from '@material-ui/core';

import { useStyles } from './styles';
import AccountInfoContainer from '../AccountInfoContainer';

interface Props {
  open?: any;
  onClose: () => void;
  onSuccess?: () => void;
  onGotoLogin?: () => void;
}

const SignUpDialog: React.FC<Props> = ({
  open,
  onClose,
  onSuccess,
  onGotoLogin
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        disableEscapeKeyDown={true}
        classes={{ paper: !matches ? classes.paper : classes.paperMobile }}
      >
        <DialogContent className={classes.DialogContent}>
          <AccountInfoContainer
            onSuccess={() => onSuccess && onSuccess()}
            showInModal={true}
            onGotoLogin={onGotoLogin}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SignUpDialog;
