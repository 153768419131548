import styled, { css } from 'styled-components';
import { colorSet } from '@components/Provider';
import { Container, Paper, Box, makeStyles } from '@material-ui/core';

export const MuiContainer = styled(Container)`
  min-width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      justify-content: initial;
      padding-top: 40px;
    }
  `}
  
  .submit-button {
    border-radius: 8px;
    margin-top: 7px;
    text-transform: inherit
  }
  .MuiTypography-caption {
    text-align: center
  }
  .MuiTypography-h3 {
    font-weight: 700;
    font-size: 18px;
    line-height: 140%;
  }
  .custom-checkbox {
    margin-bottom: 12px;
    padding-bottom: 8px;
    padding-top: 8px;
    margin-left: 0;
    display: flex !important;
    .MuiCheckbox-root {
      padding: 0px;
      margin-right: 8px;
    }
    .MuiTypography-body1 {
      font-size: 14px !important;
    }
    img{
      width: 24px;
      height: 24px;
    }
  }
  .text-btn-group {
    display: flex;
    margin-top: 16px;
    align-items: center;
    margin-bottom: 24px;
    justify-content: center;
    font-weight: 500;
    .divider {
      background-color: ${colorSet.gray400};
      margin: 0px 10px;
      height: 14px;
    }
    .forget-button {
      text-transform: none !important;
      span {
        text-transform: none !important;
      }
    }
    .signup-button {
      span {
        text-transform: none !important;
        color: ${colorSet.primary500};
        font
      }
    }
  }
  .logo-text {
    color: ${colorSet.primary500};
  }
`;

export const MuiPaper = styled(Paper)`
  max-width: 372px;
  width: 100%;
  padding-top: 16px;
  border-radius: 8px;
  .MuiTextField-root {
    margin-bottom: 13px;
  }
  .MuiOutlinedInput-root {
    border-radius: 8px;
    color: #343a40;
    font-size: 16px;
    line-height: 24px;
    /* Change the white to any color */
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
      font-size: 16px;
      line-height: 24px;
    }
    input:-webkit-autofill {
      -webkit-text-fill-color: #343a40 !important;
    }
    input:-webkit-autofill::first-line {
      font-size: 16px;
      line-height: 24px;
    }
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: #dee2e6;
    border-radius: 8px;
  }
  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      padding: 24px 16px 0 16px;
      max-width: 100%;
    }
  `}
  input {
    height: 27px !important;
  }
  .remove-red-eye-icon {
    .MuiSvgIcon-root {
      color: ${colorSet.gray700};
    }
  }
  .MuiInputBase-root.Mui-disabled {
    background: #f8fbfd !important;
  }
  input.Mui-disabled {
    color: ${colorSet.gray600} !important;
  }
  .log-in-button {
    text-transform: none;
    border-radius: 8px;
  }
`;

export const MuiBoxFooter = styled(Box)`
  max-width: 424px;
  width: 100%;
  border-top: 1px solid #f1f3f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 28px 0px 24px 0px;
  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      padding-bottom: 16px;
    }
  `}
  img {
    margin-bottom: 10px;
    height: 16px;
    cursor: initial;
  }
  margin-top: auto;
`;

export const loginContainerStyles = makeStyles((theme) => ({
  changeLanguageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%'
  },
  LoginMain: {
    display: 'flex',
    flexShrink: 0,
    flexDirection: 'column',
    height: '100vh',
    justifyContent: 'space-beetween'
  }
}));
