import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { colorSet } from '@components/Provider';
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      maxWidth: 412,
      padding: 0,
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        minWidth: 343
      },
      borderRadius: '16px'
    },
    paperMobile: {
      margin: 16,
      width: '100%',
      borderRadius: 16,
      maxHeight: 'calc(100vh - 130px)'
    },
    paperBottom: {
      padding: 0,
      width: '100%',
      margin: 0,
      bottom: 0,
      right: 0,
      left: 0,
      borderRadius: '16px 16px 0 0'
    },
    scrollPaperBottom: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center'
    },
    DialogContent: {
      position: 'relative',
      padding: '34px 20px 30px !important',
      [theme.breakpoints.down('xs')]: {
        padding: '0 16px',
        borderBottom: `1px solid ${colorSet.gray300}`
      },
      '&.pt-t': {
        paddingTop: '8px !important'
      },
      '& .space-box': {
        display: 'none'
      },
      '& .logo-register': {
        display: 'flex',
        alignItem: 'center',
        justifyContent: 'center'
      },
      '& .title_signup': {
        fontSize: 18,
        lineHeight: '25px',
        fontWeight: 700,
        textAlign: 'center',
        letterSpacing: '-0.1px',
        marginTop: 16,
        marginBottom: 16
      },
      '& .change-language': {
        display: 'none'
      },
      '& .input-signup': {
        marginBottom: '16px !important'
      },
      '& .MuiTypography-root': {
        marginBottom: '6px !important'
      },
      '& .button-submit-signup': {
        marginTop: '18px !important'
      },
      '& .group-title': {
        display: 'none'
      },
      '& .go-login': {
        display: 'block',
        fontWeight: '500',
        '&:hover': {
          textDecoration: 'underline'
        }
      },
      '& .signup-content': {
        padding: 0
      }
    },
    ContentMain: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      lineHeight: '24px',
      color: colorSet.gray700,
      '&.border-b': {
        borderBottom: `1px solid ${colorSet.gray300}`
      }
    }
  })
);
