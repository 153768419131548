import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  useTheme,
  useMediaQuery,
  DialogTitle
} from '@material-ui/core';
import CapaAvatar from '@components/CapaAvatar';
import {
  CapaInviteGroup,
  CapaCreateGroup,
  CapaEdit,
  CapaDelete,
  CapaBackIcon,
  CapaMore
} from '@assets';
import { Trans } from 'react-i18next';
import ExistingGroups from '@routes/Contacts/Dialog/ExistingGroups/ExistingGroups';
import useMyContacts from '@hooks/Contacts/useMyContacts';
import clsx from 'clsx';

import { useStyles } from './styles';
import useMyDrive from '@hooks/Drive/MyDrive/useMyDrive';
import { SHARE_DIALOG_SCREEN } from '@routes/Dashboard/Dialog/Share/types';

interface Props {
  contact?: any;
  onClose: () => void;
}

const DetailContactDialog: React.FC<Props> = ({ contact, onClose }) => {
  const classes = useStyles();
  const [isShowHideExistingGroups, setShowHideExistingGroups] =
    useState<boolean>(false);
  const [showDialogMore, setShowDialogMore] = useState(false);
  const { onSetStateContact } = useMyContacts();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const { onSetShareDialogScreen, onSetShowHideShareDialog } = useMyDrive();

  return (
    <>
      <Dialog
        open={contact ? true : false}
        onClose={onClose}
        disableEscapeKeyDown={true}
        classes={{ paper: !matches ? classes.paper : classes.paperMobile }}
      >
        {matches && (
          <DialogTitle classes={{ root: classes.titleRoot }} disableTypography>
            <div className={classes.ContentTop}>
              <img
                src={CapaBackIcon}
                onClick={() =>
                  onSetStateContact({
                    dataDetailContact: undefined
                  })
                }
              />
              <div></div>
              <div>
                <img src={CapaMore} onClick={() => setShowDialogMore(true)} />
              </div>
            </div>
          </DialogTitle>
        )}
        <DialogContent className={classes.DialogContent}>
          <div className={clsx(classes.ContentMain, 'border-b')}>
            <div className={classes.Avatar}>
              <CapaAvatar item={contact} size="large" />
            </div>
            <div className={classes.Name}>{contact?.nickName}</div>
            <div className={classes.Email}>{contact?.email}</div>
            <div className={classes.Memo}>{contact?.memo}</div>
          </div>
          <div className={classes.ContentFeature}>
            <div
              className={classes.ContentFeatureItem}
              onClick={() => setShowHideExistingGroups(true)}
            >
              <img src={CapaInviteGroup} alt="capaInviteGroup" />
              <Trans i18nKey={'contact.feature.invite_group'} />
            </div>
            <div
              className={classes.ContentFeatureItem}
              onClick={() => {
                onSetShowHideShareDialog({
                  isShowHideShareDialog: true
                });
                onSetShareDialogScreen({
                  shareDialogScreen: SHARE_DIALOG_SCREEN.CREATE_NEW_GROUP
                });
              }}
            >
              <img src={CapaCreateGroup} alt="capaCreateGroup" />
              <Trans i18nKey={'contact.feature.create_group'} />
            </div>
            {!matches && (
              <div
                className={classes.ContentFeatureItem}
                onClick={() => {
                  onSetStateContact({
                    dataCreateEditDialog: contact
                  });
                  onClose && onClose();
                }}
              >
                <img src={CapaEdit} alt="capaEdit" />
                <Trans i18nKey={'contact.feature.edit'} />
              </div>
            )}
            {!matches && (
              <div
                className={classes.ContentFeatureItem}
                onClick={() => {
                  onSetStateContact({
                    dataDeleteDialog: [contact]
                  });
                  onClose && onClose();
                }}
              >
                <img src={CapaDelete} alt="capaDelete" />
                <Trans i18nKey={'contact.feature.delete'} />
              </div>
            )}
          </div>
        </DialogContent>
        <ExistingGroups
          checkedUsers={[contact && contact?.email]}
          isOpen={isShowHideExistingGroups}
          onClose={() => setShowHideExistingGroups(false)}
        />
      </Dialog>
      <Dialog
        open={showDialogMore}
        onClose={() => setShowDialogMore(false)}
        disableEscapeKeyDown={true}
        classes={{
          paper: classes.paperBottom,
          scrollPaper: classes.scrollPaperBottom
        }}
      >
        <DialogContent className={clsx(classes.DialogContent, 'pt-t')}>
          <div className={classes.ContentFeatureBottom}>
            <div
              className={clsx(classes.ContentFeatureItem, 'itemBottom')}
              onClick={() => {
                onSetStateContact({
                  dataDetailContact: undefined,
                  dataCreateEditDialog: contact
                });
                setShowDialogMore(false);
                onClose && onClose();
              }}
            >
              <img src={CapaEdit} alt="capaEdit" />
              <div className={clsx(classes.ContentFeatureText, 'border-b')}>
                <Trans i18nKey={'contact.feature.edit'} />
              </div>
            </div>
            <div
              className={clsx(classes.ContentFeatureItem, 'itemBottom')}
              onClick={() => {
                onSetStateContact({
                  dataDeleteDialog: [contact]
                });
                setShowDialogMore(false);
                onClose && onClose();
              }}
            >
              <img src={CapaDelete} alt="capaDelete" />
              <div className={classes.ContentFeatureText}>
                <Trans i18nKey={'contact.feature.delete'} />
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DetailContactDialog;
