import React, { useEffect } from 'react';
import { Dialog, useMediaQuery, useTheme } from '@material-ui/core';
import { useStyles } from './styles';
import SharePresenter from '@routes/Dashboard/Dialog/Share/SharePresenter';
import { SHARE_DIALOG_SCREEN } from '@routes/Dashboard/Dialog/Share/types';
import useMyDrive from '@hooks/Drive/MyDrive/useMyDrive';
import GroupNameForm from '@routes/Dashboard/Dialog/Share/GroupName/GroupNameForm';
import GroupNameMembersForm from '@routes/Dashboard/Dialog/Share/GroupNameMembers/GroupNameMembersForm';
import GroupSuccess from '@routes/Dashboard/Dialog/Share/GroupSuccess/GroupSuccess';
import GroupsYouAreJoined from '@routes/Dashboard/Dialog/Share/GroupsYouAreJoined';
import { ArrowBackIosOutlined } from '@material-ui/icons';
import clsx from 'clsx';
import { Trans, useTranslation } from 'react-i18next';
import { CapaErrorCode } from '@generated/Common/graphql';
import { checkShareTimes } from '@routes/Dashboard/Dialog/Share/utils';
import { useSnackbar } from '@components/Provider';
import {
  DEFAULT_ITEMS_LIMIT,
  DEFAULT_ITEMS_OFFSET,
  myDrivePathName
} from '@routes/Common/types';
import { useShareFilesToGroupMutation } from '@generated/Drive/MyDrive/graphql';
import { useHistory } from 'react-router-dom';
import useCommon from '@hooks/Common/useCommon';
import CreateNewGroup from '@routes/Dashboard/Dialog/Share/CreateNewGroup/CreateNewGroup';
import { getGroupNameByUsernameOrEmail } from '@utils/common';
import { ReactComponent as IBMChevronLeft } from '../../../../assets/icon/IBMChevronLeft.svg';
import useGroup from '@hooks/Group/useGroup';

interface Props {
  checkedFiles?: any;
  checkedUsers?: any;
  fileOwner?: any;
  myDrivefiles?: any;
  isShowUploadFiles?: boolean;
  isFilesPage?: boolean;
  createNewGroupHeader?: React.ReactNode;
}

const ShareContainerDialog = (props: Props) => {
  const {
    checkedFiles,
    checkedUsers,
    fileOwner,
    myDrivefiles,
    createNewGroupHeader,
    isFilesPage,
    isShowUploadFiles
  } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const history = useHistory();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { openFeedbackDialog } = useCommon();

  const classes = useStyles();
  const {
    myDrive,
    onSetShowHideShareDialog,
    onSetShareDialogScreen,
    onSetchatRoomIdGroupName,
    onSetIdGroupName,
    onSetValueGroupNameDialog,
    onSetSuccessfullyShareContent
  } = useMyDrive();
  const { groupsState, onRefetchGroupsData } = useGroup();
  const { enqueueSnackbar } = useSnackbar();
  const { onRefetchConnectMyDrive } = useMyDrive();
  const pathnameUrl = history.location.pathname.split(`${myDrivePathName}/`);
  const [ShareFilesToGroup] = useShareFilesToGroupMutation();

  let shareComponent = <SharePresenter />;

  useEffect(() => {
    onRefetchGroupsData({
      limit: DEFAULT_ITEMS_LIMIT,
      offset: DEFAULT_ITEMS_OFFSET
    });

    return () => {
      onSetSuccessfullyShareContent({
        successfullyShareContent: ''
      });
    };
  }, []);

  const onBackToShareDialog = () => {
    onSetShareDialogScreen({
      shareDialogScreen: SHARE_DIALOG_SCREEN.SHARE
    });
  };

  const onCloseGroupsYouAreJoined = () =>
    onSetShowHideShareDialog({ isShowHideShareDialog: false });

  const onSubmitGroupsYouAreJoined = (
    activeSearchResult: any,
    activeGroup: any
  ) => {
    const fileOwnersUploadInChatIds = checkedFiles.map((file: any) => file.id);

    const payload: any = {
      groupId: activeSearchResult,
      connectFileIds: fileOwnersUploadInChatIds
    };

    const selectedGroupName: any = groupsState?.data!.filter(
      (group: any) => group.id === activeSearchResult
    )[0];

    ShareFilesToGroup({ variables: payload }).then((res: any) => {
      const result = res?.data?.shareFilesToGroup?.result;
      const internalConnectFileIds = fileOwnersUploadInChatIds.filter(
        (id: any) =>
          !activeGroup?.files
            ?.map((groupFile: any) => groupFile.id)
            .includes(id)
      );
      onSetValueGroupNameDialog({
        valueGroupNameDialog: selectedGroupName?.groupName
          ? selectedGroupName?.groupName
          : getGroupNameByUsernameOrEmail(selectedGroupName?.users)
      });
      onRefetchConnectMyDrive({
        pathId: pathnameUrl[1] ? pathnameUrl[1] : ''
      });

      if (internalConnectFileIds.length === 0) {
        enqueueSnackbar(
          t('snackbar.this_file_is_already_shared_to_this_group'),
          {
            variant: 'success'
          }
        );
      } else if (result === CapaErrorCode.Success) {
        onSetSuccessfullyShareContent({
          successfullyShareContent: 'share_modal.successfully.file_shared'
        });
        onSetShareDialogScreen({
          shareDialogScreen: SHARE_DIALOG_SCREEN.SUCCESSFULLY_SHARED
        });
        onSetIdGroupName({ idGroupName: activeSearchResult });
        onSetchatRoomIdGroupName({
          chatRoomIdGroupName: activeGroup?.chatRoomId
        });
        checkShareTimes(() => {
          openFeedbackDialog({
            isOpenFeedback: true,
            isFeedback: true
          });
        });
      }
    });
  };

  const onBackShareModal = () => {
    onSetShareDialogScreen({
      shareDialogScreen: SHARE_DIALOG_SCREEN.SHARE
    });
  };

  switch (myDrive.shareDialogScreen) {
    case SHARE_DIALOG_SCREEN.SHARE: {
      shareComponent = (
        <SharePresenter
          checkedFiles={checkedFiles}
          myDrivefiles={myDrivefiles}
        />
      );
      break;
    }
    case SHARE_DIALOG_SCREEN.GROUP_NAME: {
      shareComponent = <GroupNameForm />;
      break;
    }
    case SHARE_DIALOG_SCREEN.GROUPS_YOU_ARE_JOINED: {
      shareComponent = (
        <GroupsYouAreJoined
          dialogTitle={
            <>
              {isMobile ? (
                <ArrowBackIosOutlined
                  className={classes.arrowBackIosOutlinedIcon}
                  onClick={onBackToShareDialog}
                />
              ) : (
                <div
                  className={clsx(
                    classes.arrowBackIosOutlinedWrapper,
                    classes.pointerCursor
                  )}
                  onClick={onBackToShareDialog}
                >
                  <ArrowBackIosOutlined
                    className={classes.arrowBackIosOutlinedIcon}
                  />
                </div>
              )}
              <div className={classes.customTitle}>
                {t('groups_you_are_joined.header')}
              </div>
            </>
          }
          onClose={onCloseGroupsYouAreJoined}
          onSubmit={onSubmitGroupsYouAreJoined}
        />
      );
      break;
    }
    case SHARE_DIALOG_SCREEN.ADD_MEMBERS_TO: {
      shareComponent = <GroupNameMembersForm checkedFiles={checkedFiles} />;
      break;
    }
    case SHARE_DIALOG_SCREEN.CREATE_NEW_GROUP: {
      shareComponent = (
        <CreateNewGroup
          checkedFiles={checkedFiles}
          isFilesPage={isFilesPage}
          checkedUsers={checkedUsers}
          fileOwner={fileOwner}
          isShowUploadFiles={isShowUploadFiles}
          createNewGroupHeader={
            createNewGroupHeader ? (
              createNewGroupHeader
            ) : (
              <>
                {isMobile ? (
                  <IBMChevronLeft
                    className={classes.backIconMobile}
                    onClick={onBackShareModal}
                  />
                ) : (
                  <div
                    className={classes.backIconWrapper}
                    onClick={onBackShareModal}
                  >
                    <IBMChevronLeft />
                  </div>
                )}
                <div className={classes.createNewGroupTitle}>
                  {t('create_new_group_modal.header_title')}
                </div>
              </>
            )
          }
        />
      );
      break;
    }
    case SHARE_DIALOG_SCREEN.SUCCESSFULLY_SHARED: {
      shareComponent = (
        <GroupSuccess
          onCloseDialog={() =>
            onSetShowHideShareDialog({ isShowHideShareDialog: false })
          }
          content={
            <div className={classes.groupSuccessContent}>
              <Trans>
                {t(myDrive.successfullyShareContent, {
                  groupName: myDrive.valueGroupNameDialog
                })}
              </Trans>
            </div>
          }
        />
      );
      break;
    }
  }

  return (
    <Dialog
      classes={{
        paper:
          myDrive.shareDialogScreen === SHARE_DIALOG_SCREEN.SUCCESSFULLY_SHARED
            ? classes.successFullyPaper
            : myDrive.shareDialogScreen === SHARE_DIALOG_SCREEN.ADD_MEMBERS_TO
            ? classes.addMembersToWrapper
            : classes.paper
      }}
      open={myDrive.isShowHideShareDialog!}
      onClick={(e) => {
        e.stopPropagation();
      }}
      onClose={() => {
        if (myDrive.shareDialogScreen === SHARE_DIALOG_SCREEN.SHARE)
          onSetShowHideShareDialog({ isShowHideShareDialog: false });
      }}
    >
      {shareComponent}
    </Dialog>
  );
};

export default ShareContainerDialog;
