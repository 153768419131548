import { colorSet } from '@components/Provider';
import {
  Box,
  Container,
  Menu,
  createStyles,
  makeStyles
} from '@material-ui/core';
import { contentHeaderHeight, drawerWidth } from '@routes/Common/types';
import styled, { css } from 'styled-components';

export const MuiDiv = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => `
      ${theme.breakpoints.down('xs')} {
        max-width: unset;
      }
    `}

  & .icon-svg {
    display: flex;
    min-width: 36px;
    justify-content: center;
    & img {
      width: 27px;
      height: auto;
    }
  }
  & .icon-svg-file-rar {
    display: flex;
    min-width: 36px;
    justify-content: center;
    & img {
      width: 24px;
      height: auto;
    }
  }
  &.connect-empty {
    min-height: calc(100vh - 160px) !important;
    ${({ theme }) => `
    ${theme.breakpoints.down('xs')} {
      min-height: calc(100vh - 251px) !important;
      }
    `}
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 144px;
      width: 144px;
      margin-bottom: 36px;
    }
    .you-can-upload-text {
      color: ${colorSet.gray600};
      font-size: 16px;
      line-height: 150%;
      text-align: center;
      letter-spacing: -0.1px;
    }
    .no-data-icon {
      -webkit-user-drag: none;
      -khtml-user-drag: none;
      -moz-user-drag: none;
      -o-user-drag: none;
      user-drag: none;
    }
  }
  .subtitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 42px;
    padding: 30px 0;
    padding-left: 20px;

    & .cursor-pointer {
      cursor: pointer;
    }

    ${({ theme }) => `
      ${theme.breakpoints.down('xs')} {
        height: 48px;
        margin-bottom: 0px;
        position: fixed;
        top: 10px;
        z-index: 1200;
        right: 0;
        padding: 24px 16px;
      }
    `}

    .MuiBreadcrumbs-li {
      font-size: 20px;
      font-weight: 500;
      color: ${colorSet.gray900};
      &:nth-child(1) {
        font-weight: bold;
        .MuiLink-underlineHover:hover {
          text-decoration: none !important;
        }
      }
      &:last-child {
        pointer-events: none;
        cursor: auto;
      }
    }
    .label-group {
      cursor: pointer;
      display: flex;
      align-items: center;
      .MuiSvgIcon-root {
        color: ${colorSet.gray900};
        margin-right: 4px;
      }
    }
  }
`;

export const MuiBoxTable = styled(Box)`
  .icon-svg-mobile {
    flex: 0 0 auto;
    width: 66px;
    & img {
      width: 100%;
      height: auto;
    }
  }
  ${({ theme }) => `
    ${theme.breakpoints.down('xs')} {
      padding-bottom: 57px;
    }
  `}
  .table-header {
    border-bottom: 1px solid ${colorSet.gray200};
    padding: 0 16px;
    margin: 0 8px;
    display: flex;
    align-items: center;
    height: 44px;
    color: ${colorSet.gray700};
    & .icon-up {
      & svg {
        font-size: 18px;
        margin-bottom: -3px;
        margin-left: 5px;
        cursor: pointer;
      }
    }
    .checkbox-wrapper {
      margin-right: 12px;
      .MuiButtonBase-root {
        padding: 6px;
      }
      .MuiCheckbox-indeterminate {
        color: ${colorSet.primary450} !important;
      }
      .MuiSvgIcon-root {
        width: 20px !important;
        height: 20px !important;
      }
    }
    .table-header-th-one {
      width: calc(100% - 161px - 148px);
    }
    .table-header-th-two {
      width: 140px;
      flex-shrink: 0;
      padding-right: 20px;
    }
    .table-header-th-three {
      width: 125px;
      flex-shrink: 0;
      padding-right: 20px;
    }
    .table-header-th-four {
      width: 148px;
    }
    .table-header-th-five {
      width: 84px;
    }

    .table-header-th-group-name {
      width: calc(100% - 220px - 288px);
    }
    .table-header-th-my-access {
      width: 220px;
    }
    .table-header-th-shared-with {
      width: 288px;
    }

    .table-header-th-file-name {
      width: calc(100% - 260px - 140px - 140px);
    }

    .table-header-th-file-owner {
      width: 260px;
    }

    .table-header-th-shared-date {
      width: 140px;
    }

    .table-header-th-file-size {
      width: 140px;
    }
  }
`;

interface IMuiBoxTableItemProps {
  isChecked?: boolean;
}

export const MuiBoxTableItem = styled.div.attrs(
  (props: IMuiBoxTableItemProps) => props
)`
  box-shadow: ${(props) =>
    props.isChecked ? `inset 999px 999px 0px rgba(75, 136, 255, 0.08)` : ''};
  border-bottom: 1px solid ${colorSet.gray200};
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
  &:hover {
    box-shadow: inset 999px 999px 0px rgba(75, 136, 255, 0.16);
  }
  .table-item-td-one {
    padding-left: 16px;
    width: calc(100% - 132px - 148px);
    display: flex;
    min-height: 53px;
    align-items: center;
    .checkbox-wrapper {
      margin-right: 12px;
      .MuiSvgIcon-root {
        width: 20px !important;
        height: 20px !important;
      }
    }
    .MuiButtonBase-root {
      padding: 6px;
    }
    .customized-checkbox {
      margin-right: 10px;
    }
    .MuiTypography-body2 {
      margin-left: 12px;
      width: 100%;
    }
    .folder-icon {
      color: ${colorSet.gray600};
      font-size: 36px;
    }
  }
  .custom-span {
    margin-left: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: ${colorSet.gray900};
    font-size: 14px;
    font-family: Noto Sans KR, sans-serif;
    font-weight: normal;
    line-height: 143%;
    letter-spacing: -0.1px;
  }
  .table-item-td-two {
    display: flex;
    align-items: center;
    min-height: 53px;
    min-width: 130px;
    padding-right: 20px;
    flex-shrink: 0;
  }
  .table-item-td-three {
    display: flex;
    align-items: center;
    min-height: 53px;
    min-width: 140px;
    padding-right: 20px;
    flex-shrink: 0;
  }
  .table-item-td-four {
    display: flex;
    align-items: center;
    min-height: 53px;
    min-width: 140px;
    padding-right: 20px;
    flex-shrink: 0;
  }
  .table-item-td-five {
    display: flex;
    align-items: center;
    min-height: 53px;
    min-width: 84px;
  }

  .table-item-share {
    width: 100%;
    padding-right: 20px;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    .table-item-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:hover {
        text-decoration: underline;
      }
    }
    .ibm-cad-icon {
      margin-right: 8px;
    }
    & svg {
      width: 16px;
      height: 16px;
      margin-left: 4px;
      flex-shrink: 0;
      & path {
        fill: ${colorSet.gray2000} !important;
      }
    }
  }
  .table-item-share-icon {
    color: ${colorSet.gray550};
    margin-left: 10px;
  }

  .table-item-td-group-name {
    padding-left: 20px;
    display: flex;
    align-items: center;
    min-height: 53px;
    width: calc(100% - 220px - 288px);
    align-items: center;
    .customized-checkbox {
      margin-right: 10px;
    }
    .MuiTypography-body2 {
      margin-left: 16px;
      width: 100%;
    }
    .folder-icon {
      color: ${colorSet.gray600};
      font-size: 30px;
    }
  }
  .table-item-td-my-access {
    display: flex;
    align-items: center;
    min-height: 53px;
    width: 220px;
    text-transform: lowercase;
  }
  .table-item-td-shared-with {
    display: flex;
    align-items: center;
    min-height: 53px;
    width: 288px;
  }
  .folder-shared-icon {
    color: ${colorSet.gray600};
    width: 36px !important;
    height: 36px;
  }

  .access-level {
    overflow: hidden;
    text-overflow: ellipsis;
    &::first-letter {
      text-transform: none;
    }
  }

  .table-item-td-file-name {
    padding-left: 20px;
    display: flex;
    align-items: center;
    min-height: 53px;
    width: calc(100% - 260px - 140px - 140px);
    align-items: center;
    .customized-checkbox {
      margin-right: 10px;
    }
    .MuiTypography-body2 {
      margin-left: 8px;
    }
    .ibm-cad-icon {
      margin-left: 8px;
    }
  }

  .table-item-td-file-owner {
    display: flex;
    align-items: center;
    min-height: 53px;
    width: 260px;
  }

  .table-item-td-shared-date {
    display: flex;
    align-items: center;
    min-height: 60px;
    width: 140px;
  }

  .table-item-td-file-size {
    display: flex;
    align-items: center;
    min-height: 53px;
    width: 140px;
  }
`;

export const MuiBoxTableItemMobile = styled.div.attrs(
  (props: IMuiBoxTableItemProps) => props
)`
  padding: 6px 16px;
  background-color: ${(props) =>
    props.isChecked ? `${colorSet.primary100}` : ''};
  border-bottom: 1px solid ${colorSet.gray200};
  &:first-child {
    border-top: 1px solid ${colorSet.gray200};
  }
  display: flex;
  cursor: pointer;

  .table-content {
    display: grid;
    margin-left: 16px;
    width: 100%;

    .ibm-cad-icon {
      margin-right: 4px;
    }

    .MuiTypography-colorTextSecondary {
      color: ${colorSet.gray700};
    }
    .customized-typography {
      text-align: left;
      display: flex;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      .cs-file-name {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .folder-icon {
    color: ${colorSet.gray600};
    ${({ theme }) => `
      ${theme.breakpoints.down('xs')} {
        width: 32px;
        height: 32px;
        margin: 8px;
      }
    `}
  }
  .checkbox-wrapper {
    display: flex;
    align-items: center;
    margin-right: 8px;
  }
  .more-vert-menu {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
  .button-long-press {
    display: flex;
    cursor: pointer;
    width: 100%;
    background: transparent;
    border: none;
    padding: 0;
    align-items: center;
  }
  .table-item-share-mobile {
    display: flex;
    align-items: center;
    .mr-4 {
      margin-right: 4px;
    }
    & svg {
      width: 16px;
      height: 16px;
      flex-shrink: 0;
      & path {
        fill: ${colorSet.gray2000} !important;
      }
    }
  }
  .table-item-share-mobile-filesize {
    padding-left: 4px;
  }
  .table-item-share-mobile-filesize:before {
    content: ' · ';
  }
  .table-item-share-icon-mobile {
    color: ${colorSet.gray550};
    margin-right: 4px;
    font-size: 20px;
  }
`;

interface IThumbnailProps {
  is_empty?: boolean;
  width?: string;
}
export const ThumbnailStyle = styled.div.attrs(
  (props: IThumbnailProps) => props
)`
  overflow: hidden;
  cursor: pointer;
  box-sizing: border-box;
  width: ${(props) => (props.width ? props.width : '36px')};
  min-width: ${(props) => (props.width ? props.width : '36px')};
  height: ${(props) => (props.width ? props.width : '36px')};
  border: ${(props) =>
    props.is_empty ? 'none' : `1px solid ${colorSet.gray400}`};
  img {
    width: 100%;
    height: 100%;
  }
  border-radius: 4px;
  background-color: ${colorSet.gray000};
`;

export const MuiContainerHome = styled(Container)``;

interface IMuiBoxHomeHeaderProps {
  background_image?: string;
}

export const MuiBoxHomeHeader = styled(Box).attrs(
  (props: IMuiBoxHomeHeaderProps) => props
)`
  ${(props) =>
    props.background_image &&
    css`
      background-image: url(${props.background_image});
    `}
  max-height: 500px;
  padding: 70px 36px;
  img {
    height: 100%;
    width: 100%;
  }
  .grid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const useDashboardAsideMenuPresenterStyles = makeStyles((theme) =>
  createStyles({
    drawerPaper: {
      width: drawerWidth,
      minHeight: '100vh',
      marginRight: 16,
      marginBottom: 14,
      borderRight: `1px solid ${colorSet.gray000}`,
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      },
      '& .no-event': {
        pointerEvents: 'none'
      }
    },
    drawerHeaderMargin: {
      height: contentHeaderHeight
    },
    drawerContent: {},
    PopupNew: {
      '& .MuiListItem-root': {
        padding: '6px 16px !important',
        fontSize: 14,
        fontWeight: 500,
        lineHeight: '20px',
        '& img': {
          marginRight: 12
        }
      }
    },
    lineSpace: {
      height: 1,
      width: '100%',
      background: colorSet.gray300,
      margin: '8px 0'
    },
    drawerContentItem: {
      display: 'flex',
      alignItems: 'center',
      '&.upload-btn': {
        padding: '14px 12px 16px'
      },
      '&.connect-files': {
        padding: '10px 12px',
        cursor: 'pointer',
        borderRadius: '0 56px 56px 0',
        color: colorSet.gray900,
        transition: 'all 300ms',
        '& img': {
          marginRight: 8
        },
        '&:hover': {
          color: colorSet.gray900,
          backgroundColor: '#EBEBEB'
        },
        '&.selected': {
          color: colorSet.primary450,
          cursor: 'default',
          backgroundColor: colorSet.primary100,
          '& p': {
            color: colorSet.primary450
          }
        }
      },
      '&.storage-capacity': {
        padding: '0px 16px',
        '& img': {
          color: '#dadada',
          marginRight: 16
        }
      },
      '&.storage-capacity-progress': {
        padding: '16px 16px 10px 16px',
        '& .MuiLinearProgress-root': {
          width: '100%',
          backgroundColor: colorSet.gray400,
          borderRadius: 4,
          height: 6
        }
      },
      '&.storage-capacity-progress-count': {
        padding: '0px 16px',
        '& .MuiTypography-root': {
          color: colorSet.gray700
        }
      }
    },
    drawerContentItemText: {
      fontSize: 14
    },
    divider: {
      backgroundColor: colorSet.gray400,
      margin: '179px 16px 24px 16px'
    },
    newButton: {
      textTransform: 'none',
      padding: '0 !important',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minWidth: '156px !important',
      backgroundColor: `${colorSet.primary450} !important`,
      '&.Mui-disabled': {
        backgroundColor: `rgba(0, 0, 0, 0.12) !important`
      },
      '&:hover': {
        backgroundColor: `${colorSet.primary600} !important`
      },
      '& img': {
        width: 24,
        height: 24,
        marginRight: 2
      },
      '& span': {
        fontWeight: 500,
        fontSize: 16,
        lineHeight: '150%',
        letterSpacing: '-0.1px',
        marginRight: 0
      }
    }
  })
);

export const NewButtonMenu = styled(Menu)`
  &.MuiPopover-root {
    z-index: 3000 !important;
  }
  ${({ theme }) => `
  & .MuiPaper-root {
    ${theme.breakpoints.down('xs')} {
      left: 0 !important;
      top: auto !important;
      bottom: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      width: 100%;
      max-width: 100% !important;
    }
    border-radius: 6px;
    margin-top: ${theme.spacing(1)};
    min-width: 256px;
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.1), 0px 8px 10px 1px rgba(0, 0, 0, 0.07), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);

    & .MuiMenu-list {
      padding: '8px 0 !important'
    }

    & .MuiMenuItem-root {
      padding-top: 10px;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      min-height: 36px;
      padding-bottom: 10px;

    }
    & .MuiMenuItem-root img {
      width: 20px;
      height: 20px;
      margin-right: 12px;
      ${theme.breakpoints.down('xs')} {
        width: 24px;
        height: 24px;
      }
    }
  }
  `}
`;

export const ContextMenuTable = styled.div`
  .popup {
    list-style-type: none;
    margin: 0;
    outline: none;
    text-align: left;
    overflow: hidden;
    min-width: 256px;
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 10%),
      0px 8px 10px 1px rgb(0 0 0 / 7%), 0px 3px 14px 2px rgb(0 0 0 / 6%);
    border-radius: 8px;
    background-color: #ffffff;
    width: 230px;
    padding: 8px 0;
  }
  .wrapper {
    z-index: 2;
  }

  .popup li {
    color: ${colorSet.gray900};
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin: 0;
    padding: 5px 12px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    padding: 8px 16px;
    &.border-b {
      border-bottom: 1px solid #f1f3f5;
    }
    &:hover {
      background-color: ${colorSet.gray200};
    }
  }
  .popup li:last-child {
    border-bottom: none;
  }
  .popup li > img {
    margin-right: 10px;
  }

  .popup li > i {
    margin-right: 8px;
  }
`;

export const MuiNewButtonMobile = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 79px;
  right: 14px;
  width: 54px;
  height: 54px;
  border-radius: 100%;
  background-color: ${colorSet.primary450};
  cursor: pointer;
  img {
    width: 32px;
    height: 32px;
  }
`;

export const useStyles = makeStyles((theme) =>
  createStyles({
    dropzoneWrapper: {
      position: 'relative',
      overflow: 'auto',
      height: 'calc(100vh - 181px)',
      paddingRight: 2,
      marginRight: 2,
      marginLeft: 8,
      '&::-webkit-scrollbar': {
        width: 4
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
      },
      '&:hover::-webkit-scrollbar-thumb': {
        backgroundColor: colorSet.gray600
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: colorSet.gray000,
        borderRadius: 2
      }
    },
    dropzoneWrapperMobile: {
      overflow: 'auto',
      height: 'calc(100vh - 124px)'
    },
    dropzoneWrapperHaveItem: {
      height: 'calc(100vh - 160px)'
    },
    dropzoneWrapperNoItem: {
      height: 'calc(100vh - 160px)'
    },
    avatarGroupWrapper: {
      display: 'flex',
      marginLeft: 'auto',
      alignItems: 'center'
    },
    lineBottom: {
      background: colorSet.gray300,
      width: '100%',
      margin: '8px 0',
      height: 1
    },
    customNotCapaAvt: {
      width: 32,
      height: 32,
      marginLeft: '0 !important',
      marginRight: '6px !important',
      background: '#fff',
      textTransform: 'uppercase',
      color: colorSet.gray500,
      border: `1px dashed ${colorSet.gray500}`
    },
    customAvt: {
      width: 32,
      height: 32,
      marginLeft: '0 !important',
      marginRight: '6px !important',
      border: 'none !important',
      textTransform: 'uppercase'
    },
    avatarGroup: {
      '& .MuiAvatar-root': {
        marginRight: '6px !important'
      },
      '& .MuiAvatarGroup-avatar': {
        width: 28,
        height: 28,
        fontSize: 13,
        marginLeft: '0 !important',
        border: 'none !important',
        textTransform: 'uppercase'
      }
    },
    rootPopupMobile: {
      zIndex: 1299,
      [theme.breakpoints.down('xs')]: {
        width: '100% !important',
        bottom: '-4px !important',
        left: '0 !important',
        top: 'unset !important',
        transform: 'none !important',
        position: 'fixed !important',
        '&.d-none': {
          display: 'none !important'
        }
      }
    },
    contentpopupMobile: {
      minWidth: 343,
      borderRadius: 8,
      backgroundColor: colorSet.gray000,
      padding: '8px 0px',
      boxShadow:
        ' 0px 5px 5px -3px rgba(0, 0, 0, 0.1), 0px 8px 10px 1px rgba(0, 0, 0, 0.07), 0px 3px 14px 2px rgba(0, 0, 0, 0.06)',
      '& ul li': {
        cursor: 'pointer',
        height: 64,
        display: 'flex',
        padding: '0px 16px',
        alignItems: 'center',
        '& .icon': {
          marginRight: 15
        }
      }
    },
    thumbnailMobile: {
      minWidth: 66,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    uploadButtonMobileWrapper: {
      paddingBottom: 57
    },
    moreVertIcon: {
      color: colorSet.gray700
    },
    zIndexBackdrop: {
      zIndex: 1200
    },
    buttonUploadMobile: {
      [theme.breakpoints.down('xs')]: {
        position: 'fixed',
        bottom: 70,
        height: 'auto',
        display: 'inline-flex',
        right: 15,
        width: 'max-content',

        '& .button-action': {
          boxShadow: 'none'
        }
      }
    }
  })
);
