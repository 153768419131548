import React from 'react';
import {
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  makeStyles
} from '@material-ui/core';
import { ReactComponent as IBMCaretDown } from './../../assets/icon/IBMCaretDown.svg';

const useStyles = makeStyles({
  root: { width: '100%' },
  paper: {
    boxShadow: 'none'
  },
  summary: {
    padding: 0,
    minHeight: '100%',
    '&$expanded': {
      minHeight: '100%',
      margin: 0,
      marginBottom: 12
    }
  },
  expandIcon: {
    padding: 0,
    margin: 0,
    '& button': {
      height: 38,
      width: 38
    }
  },
  content: {
    margin: 0,
    '&$expanded': {
      margin: 0
    }
  },
  expanded: {
    marginBottom: 0
  },
  detail: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0
  },
  iconCaretDown: {
    padding: 0
  }
});

export interface Props {
  summary: React.ReactNode;
  detail: React.ReactNode;
}

const AccordionPresenter: React.FC<Props> = ({ summary, detail }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Accordion className={classes.paper} defaultExpanded={false}>
        <AccordionSummary
          classes={{
            root: classes.summary,
            content: classes.content,
            expanded: classes.expanded,
            expandIcon: classes.expandIcon
          }}
          expandIcon={
            <IconButton size="medium" className={classes.iconCaretDown}>
              <IBMCaretDown />
            </IconButton>
          }
        >
          {summary}
        </AccordionSummary>
        <AccordionDetails classes={{ root: classes.detail }}>
          {detail}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default AccordionPresenter;
