import React, { useEffect } from 'react';
import { useStyles } from './styles';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
  Typography
} from '@material-ui/core';
import UploadFromMyDrive from '@routes/Common/Dialog/UploadFiles/UploadFromMyDrive';
import UploadFromComputer from '@routes/Common/Dialog/UploadFiles/UploadFromComputer';
import { useTranslation } from 'react-i18next';
import { CustomizedButton } from '@components/Customized';
import useMyDrive from '@hooks/Drive/MyDrive/useMyDrive';
import { UPLOAD_FILE_TAB } from './types';
import useUserActionLog, {
  CapaActivityType,
  Enum_Action_Button_Enum,
  Enum_Action_Page_Enum
} from '@hooks/useUserActionLog';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography component={'div'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

interface UploadfilesProps {
  isShowHideUploadFilesDialog: boolean;
  onSubmit?: () => void;
  onClose?: () => void;
  submitText?: () => string;
  dialogTitleNode?: React.ReactNode;
  allowEmptyFile?: boolean;
}

const UploadFiles = (props: UploadfilesProps) => {
  const classes = useStyles();
  const {
    isShowHideUploadFilesDialog,
    dialogTitleNode,
    onSubmit,
    onClose,
    submitText,
    allowEmptyFile
  } = props;
  const { t } = useTranslation();
  const { myDrive, onSetUploadFileTab, onSetInternalFileFromComputer } =
    useMyDrive();
  const { onInsertUserActionLogOne } = useUserActionLog();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (newValue === UPLOAD_FILE_TAB.FROM_MY_DRIVE) {
      onInsertUserActionLogOne(
        Enum_Action_Button_Enum.Button_UploadFileModal_MyDrive,
        CapaActivityType.ButtonClick
      );
    } else if (newValue === UPLOAD_FILE_TAB.FROM_COMPUTER) {
      onInsertUserActionLogOne(
        Enum_Action_Button_Enum.Button_UploadFileModal_MyDevice,
        CapaActivityType.ButtonClick
      );
    }

    onSetUploadFileTab({ uploadFileTab: newValue });
  };

  const disabledFileFromMyDrive = () => {
    let isDisabled = false;

    myDrive.selectedFilesUploadInChat.forEach((file: any) => {
      if (!file.isFile) isDisabled = true;
    });

    return isDisabled || myDrive.selectedFilesUploadInChat.length === 0;
  };

  useEffect(() => {
    onInsertUserActionLogOne(
      Enum_Action_Page_Enum.Page_UploadFileModal,
      CapaActivityType.PageVisit
    );
    if (myDrive.uploadFileTab === UPLOAD_FILE_TAB.FROM_MY_DRIVE) {
      onSetInternalFileFromComputer({ internalFileFromComputer: [] });
    }
  }, [myDrive.uploadFileTab]);

  useEffect(() => {
    if (isShowHideUploadFilesDialog) {
      onSetUploadFileTab({ uploadFileTab: UPLOAD_FILE_TAB.FROM_MY_DRIVE });
    }
  }, [isShowHideUploadFilesDialog]);

  return (
    <>
      <Dialog
        classes={{
          paper: classes.paper,
          root: classes.rootDialog
        }}
        open={isShowHideUploadFilesDialog}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <DialogTitle classes={{ root: classes.rootTitle }} disableTypography>
          {dialogTitleNode}
        </DialogTitle>

        <DialogContent className={classes.csDialogContent}>
          <Tabs
            value={myDrive.uploadFileTab}
            onChange={(e: any, newValue) => {
              handleChange(e, newValue);
            }}
            className={classes.csTabs}
          >
            <Tab
              label={t('upload_files_modal.my_drive')}
              classes={{
                wrapper: classes.csTabWrapper,
                selected: classes.selectedTab
              }}
            />
            <Tab
              label={t('upload_files_modal.upload_button')}
              classes={{
                wrapper: classes.csTabWrapper,
                selected: classes.selectedTab
              }}
            />
          </Tabs>
          <TabPanel
            value={myDrive.uploadFileTab!}
            index={UPLOAD_FILE_TAB.FROM_MY_DRIVE}
          >
            <UploadFromMyDrive
              isShowHideUploadFilesDialog={isShowHideUploadFilesDialog}
            />
          </TabPanel>
          <TabPanel
            value={myDrive.uploadFileTab!}
            index={UPLOAD_FILE_TAB.FROM_COMPUTER}
          >
            <UploadFromComputer />
          </TabPanel>
        </DialogContent>

        <DialogActions classes={{ root: classes.action }}>
          <div className={classes.wrapperBtn}>
            <CustomizedButton
              variant="contained"
              color="default"
              onClick={() => {
                onClose && onClose();
                onInsertUserActionLogOne(
                  Enum_Action_Button_Enum.Button_UploadFileModal_Cancel,
                  CapaActivityType.ButtonClick
                );
              }}
            >
              {t('upload_files_modal.cancel_button')}
            </CustomizedButton>
            <CustomizedButton
              type="submit"
              variant="contained"
              color="primary"
              disabled={
                allowEmptyFile
                  ? false
                  : disabledFileFromMyDrive() &&
                    myDrive.internalFileFromComputer.length === 0
              }
              onClick={onSubmit}
            >
              {submitText}
            </CustomizedButton>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UploadFiles;
