export const NOTIFICATION_FILTER_TYPE = {
  CHAT_PUSH: 'chatPush',
  GROUP_PUSH: 'groupPush',
  GROUP_EMAIL: 'groupEmail',
  COMMENT_THREAD_PUSH: 'commentThreadPush',
  COMMENT_THREAD_EMAIL: 'commentThreadEmail',
  FILE_PUSH: 'filePush',
  FILE_EMAIL: 'fileEmail'
};

export const NOTIFICATION_TYPE = {
  CHAT_PUSH: 'CHAT_PUSH',
  GROUP_PUSH: 'GROUP_PUSH',
  GROUP_EMAIL: 'GROUP_EMAIL',
  COMMENT_THREAD_PUSH: 'COMMENT_THREAD_PUSH',
  COMMENT_THREAD_EMAIL: 'COMMENT_THREAD_EMAIL',
  FILE_PUSH: 'FILE_PUSH',
  FILE_EMAIL: 'FILE_EMAIL'
};

export const NOTIFICATION_SETTINGS_PUSH_LIST = [
  {
    filterType: NOTIFICATION_FILTER_TYPE.CHAT_PUSH,
    type: NOTIFICATION_TYPE.CHAT_PUSH,
    name: 'notification_settings.chat',
    description: 'notification_settings.chat_push',
    status: false
  },
  {
    filterType: NOTIFICATION_FILTER_TYPE.GROUP_PUSH,
    type: NOTIFICATION_TYPE.GROUP_PUSH,
    name: 'notification_settings.groups',
    description: 'notification_settings.group_push',
    status: false
  },
  {
    filterType: NOTIFICATION_FILTER_TYPE.COMMENT_THREAD_PUSH,
    type: NOTIFICATION_TYPE.COMMENT_THREAD_PUSH,
    name: 'notification_settings.comment_threads',
    description: 'notification_settings.comment_thread_push',
    status: false
  },
  {
    filterType: NOTIFICATION_FILTER_TYPE.FILE_PUSH,
    type: NOTIFICATION_TYPE.FILE_PUSH,
    name: 'notification_settings.files',
    description: 'notification_settings.file_push',
    status: false
  }
];

export const NOTIFICATION_SETTINGS_EMAIL_LIST = [
  {
    filterType: NOTIFICATION_FILTER_TYPE.GROUP_EMAIL,
    type: NOTIFICATION_TYPE.GROUP_EMAIL,
    name: 'notification_settings.group_access',
    description: 'notification_settings.group_email',
    status: false
  },
  {
    filterType: NOTIFICATION_FILTER_TYPE.COMMENT_THREAD_EMAIL,
    type: NOTIFICATION_TYPE.COMMENT_THREAD_EMAIL,
    name: 'notification_settings.comment_threads',
    description: 'notification_settings.comment_thread_email',
    status: false
  },
  {
    filterType: NOTIFICATION_FILTER_TYPE.FILE_EMAIL,
    type: NOTIFICATION_TYPE.FILE_EMAIL,
    name: 'notification_settings.files',
    description: 'notification_settings.file_email',
    status: false
  }
];
