import React from 'react';
import AppConfig from '@config/index';
import { colorSet } from '@components/Provider';
import { Chip, makeStyles, createStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    chip: {
      backgroundColor: colorSet.errorMain,
      marginLeft: 8
    }
  })
);

const StagingChipPresenter = () => {
  const classes = useStyles();
  if (!AppConfig.baseUrl?.includes('staging')) return null;

  return (
    <Chip
      className={classes.chip}
      color="primary"
      label="STAGING"
      size="small"
    />
  );
};

export default StagingChipPresenter;
