import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { CapaLogoBeta, CapaLogoBetaKo } from '@assets';
import useUserActionLog, {
  Enum_Action_Page_Enum,
  CapaActivityType
} from '@hooks/useUserActionLog';
import { CustomizedTypography, CustomizedButton } from '@components/Customized';
import { TextField } from '@material-ui/core';
import { MuiContainer, MuiPaper } from './styles';
import { useTranslation } from 'react-i18next';
import i18n from '@i18n/index';
import { LANGUAGE_VALUE } from '@routes/Common/ChangeLanguage/types';

export interface Inputs {
  email: string;
}

interface Props {
  onSubmit: (req: Inputs) => void;
  onGotoLogin?: () => void;
}

const RequestPasswordPresenter: React.FC<Props> = ({
  onSubmit,
  onGotoLogin
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { onInsertUserActionLogOne } = useUserActionLog();
  const { register, handleSubmit, errors } = useForm<Inputs>();
  const handleOnSubmit = (inputs: Inputs) => onSubmit({ ...inputs });
  const languageValue = i18n.language;

  const validationForm = {
    email: {
      required: { value: true, message: t('sign_up.required_email') },
      pattern: {
        value:
          /[-A-Za-z0-9_]+[-A-Za-z0-9_.]*[@]{1}[-A-Za-z0-9_]+[-A-Za-z0-9_.]*[.]{1}[A-Za-z]{1,5}/,
        message: t('sign_up.validation_pattern_email')
      }
    }
  };

  useEffect(() => {
    onInsertUserActionLogOne(
      Enum_Action_Page_Enum.Page_PasswordRequest,
      CapaActivityType.PageVisit
    );
  }, [onInsertUserActionLogOne]);

  return (
    <MuiContainer className="forgot-container">
      <img
        className="capa-logo"
        src={
          languageValue === LANGUAGE_VALUE.EN ? CapaLogoBeta : CapaLogoBetaKo
        }
        alt="Logo"
        onClick={() => history.push('/')}
      />
      <MuiPaper elevation={0}>
        <CustomizedTypography
          variant="h4"
          fontWeight="bold"
          gutterBottoms={4}
          className="titlePage"
        >
          {t('forgot_pass.forgot_pass')}
        </CustomizedTypography>
        <CustomizedTypography
          variant="caption"
          gutterBottoms={32}
          className="caption"
        >
          {t('forgot_pass.caption')}
        </CustomizedTypography>
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <CustomizedTypography variant="body1" gutterBottoms={8} align="left">
            {t('forgot_pass.email')}
          </CustomizedTypography>
          <TextField
            placeholder={t('sign_up.email_placeholder')}
            variant="outlined"
            error={errors.email ? true : false}
            helperText={errors.email && errors.email.message}
            inputProps={{
              name: 'email',
              ref: register(validationForm.email)
            }}
            size="small"
            fullWidth
          />
          <CustomizedButton
            type="submit"
            fullWidth
            color="primary"
            variant="contained"
            size="large"
            className="submit-button"
          >
            {t('forgot_pass.submit')}
          </CustomizedButton>
        </form>
      </MuiPaper>
      <div className="text-btn-group">
        {onGotoLogin ? (
          <CustomizedButton
            variant="text"
            size="large"
            color="default"
            onClick={onGotoLogin}
            className="go-to-login"
          >
            {t('sign_up.go_to_login')}
          </CustomizedButton>
        ) : (
          <CustomizedButton
            variant="text"
            size="large"
            color="default"
            className="go-to-login"
            onClick={() => history.push('/login')}
          >
            {t('sign_up.go_to_login')}
          </CustomizedButton>
        )}
      </div>
    </MuiContainer>
  );
};

export default RequestPasswordPresenter;
