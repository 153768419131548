import styled from 'styled-components';
import { colorSet } from '@components/Provider';

export const GnbMenuStyle = styled.div`
  display: flex;
  align-items: center;
  color: ${colorSet.gray900};
  font-weight: 500;
  .help-icon {
    cursor: pointer;
  }

  .gnb-popup-menu {
    margin-left: 12px;
  }

  .gnb-checkerboard-menu {
    margin-left: 16px;
  }

  .gnb-notification-menu {
    /* margin-left: 16px; */
  }

  .gnb-notification-menu .MuiBadge-anchorOriginTopRightRectangle {
    color: ${colorSet.gray000} !important;
    padding: 4px 0px;
    font-size: 9px;
    min-width: 20px;
    border-radius: 9px !important;
    text-align: center;
    height: 17px;
    top: 6px;
    right: 6px;
    background: ${colorSet.pink300};
    text-align: center;
  }

  .gnb-drive-button {
    margin-left: 20px;
  }
`;

export const GnbMenuMobileStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${colorSet.gray900};
  width: 100%;
  .aside-menu-btn {
    cursor: pointer;
  }
  .aside-back-btn {
    margin-left: -12px;
  }
  .select-touch-appbar {
    display: flex;
    align-items: center;
    .MuiSvgIcon-root {
      cursor: pointer;
      margin-right: 12px;
    }
  }
`;

export const FolderNameHeader = styled.div`
  display: flex;
  font-size: 16px;
  align-items: center;

  .folder-name {
    flex-shrink: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-left: 10px;
    line-height: 1.5;
  }

  svg {
    cursor: pointer;
  }
`;
