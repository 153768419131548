import { useSnackbar } from '@components/Provider';
import { CapaConnectMyFile, CapaErrorCode } from '@generated/Common/graphql';
import { CapaActivityType } from '@generated/Common/types';
import useMyDrive from '@hooks/Drive/MyDrive/useMyDrive';
import useFiles from '@hooks/useFiles';
import useUserActionLog, {
  Enum_Action_Button_Enum
} from '@hooks/useUserActionLog';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EnumDashboardListControlType } from '../types';
import DashboardListControlBtnPresenter from './DashboardListControlBtnPresenter';

interface Props {
  files: Array<CapaConnectMyFile>;
  type: EnumDashboardListControlType;
  isListSide?: boolean;
  callback?: () => void;
  isShowMoreVertMenu?: boolean;
  isShowDownLoad?: boolean;
  isShowPublic?: boolean;
  isOpen?: boolean;
  isDetail?: boolean;
  isShare?: boolean;
  isOpenFileViewer?: boolean;
  onShowFileViewer?: () => void;
  showViewerPDF?: () => void;
  onClickEvent?: (event: string) => void;
  onSetCheckedFiles?: (files: any) => void;
  myDrivefiles?: any;
}

const DashboardListControlBtnContainer: React.FC<Props> = ({
  isShowPublic,
  isOpen,
  isDetail,
  isShowDownLoad,
  isShowMoreVertMenu,
  files,
  type,
  callback,
  onShowFileViewer,
  onClickEvent,
  showViewerPDF,
  isShare,
  onSetCheckedFiles,
  isOpenFileViewer,
  myDrivefiles,
  isListSide
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { onDownloadConnectFile, onRemoveConnectFile } = useFiles();
  const { onSetMyDrivePageLoading } = useMyDrive();
  const { onInsertUserActionLogOne } = useUserActionLog();

  const onDownloadFiles = async (files?: Array<CapaConnectMyFile>) => {
    onSetMyDrivePageLoading({
      isMyDrivePageLoading: true
    });
    if (!files || !files.length) return;
    const newFiles = Array.from(new Set(files));
    if (newFiles.length === 1) {
      onDownloadConnectFile(files[0])
        .then(() => {
          onSetMyDrivePageLoading({
            isMyDrivePageLoading: false
          });
          enqueueSnackbar(
            files.length > 1
              ? t('snackbar.download_files_complete')
              : t('snackbar.download_file_complete', {
                  fileName: files[0].fileName
                }),
            {
              variant: 'success'
            }
          );
        })
        .catch(() => {
          onSetMyDrivePageLoading({
            isMyDrivePageLoading: false
          });
          enqueueSnackbar(t('snackbar.download_file_complete_error'), {
            variant: 'error'
          });
        });
    } else {
      newFiles.forEach((file) => {
        onDownloadConnectFile(file)
          .then(() => {
            onSetMyDrivePageLoading({
              isMyDrivePageLoading: false
            });
          })
          .catch(() => {
            onSetMyDrivePageLoading({
              isMyDrivePageLoading: false
            });
            enqueueSnackbar(t('snackbar.download_file_complete_error'), {
              variant: 'error'
            });
          });
      });
      enqueueSnackbar(t('snackbar.download_files_complete'), {
        variant: 'success'
      });
    }
  };

  const onDeleteFiles = async (files?: Array<CapaConnectMyFile>) => {
    onInsertUserActionLogOne(
      Enum_Action_Button_Enum.Button_MyDrive_File_Delete,
      CapaActivityType.ButtonClick
    );
    if (files) {
      onSetMyDrivePageLoading({
        isMyDrivePageLoading: true
      });
      onRemoveConnectFile(files)
        .then((res: any) => {
          onSetMyDrivePageLoading({
            isMyDrivePageLoading: false
          });

          if (res?.data?.removeConnectFile.result === CapaErrorCode.Success) {
            callback && callback();
            if (files.every((file: any) => file.isFile) && files.length > 1) {
              enqueueSnackbar(t('snackbar.delete_files_complete'), {
                variant: 'success'
              });
            }

            if (files.every((file: any) => file.isFile) && files.length === 1) {
              enqueueSnackbar(
                t('snackbar.delete_file', {
                  fileName: files[0].fileName
                }),
                {
                  variant: 'success'
                }
              );
            }

            if (
              files.every((file: any) => !file.isFile) &&
              files.length === 1
            ) {
              enqueueSnackbar(t('snackbar.delete_folder'), {
                variant: 'success'
              });
            }

            if (files.every((file: any) => !file.isFile) && files.length > 1) {
              enqueueSnackbar(t('snackbar.delete_multiple_folders'), {
                variant: 'success'
              });
            }

            onSetCheckedFiles && onSetCheckedFiles([]);
          } else if (
            res?.data?.removeConnectFile.result === CapaErrorCode.NotAllowedUser
          ) {
            enqueueSnackbar(t('snackbar.delete_file_complete_error'), {
              variant: 'error'
            });
          }
        })
        .catch(() => {
          onSetMyDrivePageLoading({
            isMyDrivePageLoading: false
          });
          enqueueSnackbar(t('snackbar.delete_file_complete_error'), {
            variant: 'error'
          });
        });
    }
  };

  return (
    <DashboardListControlBtnPresenter
      onSetCheckedFiles={onSetCheckedFiles}
      showViewerPDF={showViewerPDF}
      onClickEvent={onClickEvent}
      onShowFileViewer={onShowFileViewer}
      isOpen={isOpen}
      isDetail={isDetail}
      isShowPublic={isShowPublic}
      isShowDownLoad={isShowDownLoad}
      isShowMoreVertMenu={isShowMoreVertMenu}
      files={files}
      type={type}
      isListSide={isListSide}
      onDownloadFiles={onDownloadFiles}
      onDeleteFiles={onDeleteFiles}
      isShare={isShare}
      isOpenFileViewer={isOpenFileViewer}
      myDrivefiles={myDrivefiles}
    />
  );
};

export default DashboardListControlBtnContainer;
