export const LANGUAGE_VALUE = {
  KO: 'ko',
  EN: 'en',
  ZH: 'zh',
  VI: 'vi'
};

export const LANGUAGE_OPTIONS = [
  {
    value: LANGUAGE_VALUE.KO,
    name: '한국어'
  },
  {
    value: LANGUAGE_VALUE.EN,
    name: 'English'
  }
];

export const i18_NEXT_LNG_VALUE = 'i18nextLng';
