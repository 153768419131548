import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputBase,
  Paper,
  useMediaQuery,
  useTheme,
  Checkbox
} from '@material-ui/core';
import { useStyles } from './styles';
import {
  ArrowBackIosOutlined,
  CancelRounded,
  Search,
  Brightness1Outlined,
  CheckCircle
} from '@material-ui/icons';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { getDisplayedAvatarString } from '@utils/common';
import { CustomizedButton } from '@components/Customized';
import useMyContacts from '@hooks/Contacts/useMyContacts';
import { DEFAULT_ITEMS_MAX, DEFAULT_ITEMS_OFFSET } from '@routes/Common/types';
import Loading from '@components/LoadingInComponent';
import CapaAvatar from '@components/CapaAvatar';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onAddContacts?: (contactIds: any) => void;
  disabledContacts?: any;
}

const AddContacts = (props: Props) => {
  const { isOpen, onClose, onAddContacts, disabledContacts } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [searchText, setSearchText] = useState('');
  const [activeEmails, setActiveEmails] = useState<any>([]);
  const { onFetchMyContacts, myContactsList, myContactsListLoading } =
    useMyContacts();
  const [contactData, setContactData] = useState<any>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [hoverItem, setHoverItem] = useState(null);

  const onSelect = () => {
    onAddContacts && onAddContacts(activeEmails);
    onClose && onClose();
  };

  useEffect(() => {
    if (myContactsList?.fetchConnectUserContactList?.data?.length > 0) {
      setContactData(myContactsList?.fetchConnectUserContactList?.data);
    }
  }, [myContactsList]);

  useEffect(() => {
    if (isOpen) {
      setActiveEmails([]);
      onFetchMyContacts({
        variables: {
          limit: DEFAULT_ITEMS_MAX,
          offset: DEFAULT_ITEMS_OFFSET
        }
      });
    }
  }, [isOpen]);

  return (
    <Dialog
      classes={{
        paper: classes.paper
      }}
      open={isOpen}
      onClick={(e) => {
        e.stopPropagation();
      }}
      onClose={() => {
        onClose && onClose();
      }}
    >
      <DialogTitle classes={{ root: classes.rootTitle }} disableTypography>
        {isMobile ? (
          <ArrowBackIosOutlined
            className={classes.arrowBackIosOutlinedIcon}
            onClick={() => {
              onClose && onClose();
            }}
          />
        ) : (
          <div
            className={clsx(
              classes.arrowBackIosOutlinedWrapper,
              classes.pointerCursor
            )}
            onClick={() => {
              onClose && onClose();
            }}
          >
            <ArrowBackIosOutlined
              className={classes.arrowBackIosOutlinedIcon}
            />
          </div>
        )}

        <div className={classes.customTitle}>
          {t('add_contacts_modal.title')}
        </div>
      </DialogTitle>
      <DialogContent className={classes.csDialogContent}>
        <Paper component="form" className={classes.searchInputWrapper}>
          <div className={classes.searchIconWrapper}>
            <Search className={classes.customSearchIcon} />
          </div>
          <InputBase
            className={classes.searchInputBase}
            placeholder={t('add_contacts_modal.placeholder')}
            value={searchText!}
            onChange={(event) => {
              setLoading(true);
              const internalData =
                myContactsList?.fetchConnectUserContactList?.data!.filter(
                  (data: any) => {
                    return data?.nickName
                      ?.toLowerCase()
                      .includes(event.target.value.toLowerCase());
                  }
                );
              setSearchText(event.target.value);
              setTimeout(() => {
                setLoading(false);
                setContactData(internalData);
              }, 500);
            }}
          />
          {searchText ? (
            <div
              className={classes.searchCloseIconWrapper}
              onClick={() => {
                setContactData(
                  myContactsList?.fetchConnectUserContactList?.data
                );
                setSearchText('');
              }}
            >
              <CancelRounded className={classes.customSearchIcon} />
            </div>
          ) : null}
        </Paper>
        <div className={classes.searchResultWrapper}>
          {isLoading || myContactsListLoading ? (
            <Loading
              open={true}
              styles={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: 40
              }}
            />
          ) : contactData.length > 0 ? (
            contactData.map((item: any, index: number) => {
              const isHover = hoverItem === item.email;
              return (
                <div
                  key={index}
                  className={clsx(
                    classes.searchResult,
                    classes.pointerCursor,
                    activeEmails.includes(item?.email)
                      ? classes.activeResult
                      : '',
                    disabledContacts.includes(item?.email)
                      ? classes.disabledResult
                      : '',
                    isHover && !isMobile ? classes.hoverItem : ''
                  )}
                  onClick={() => {
                    if (disabledContacts.includes(item?.email)) return;
                    if (activeEmails.includes(item?.email)) {
                      setActiveEmails(
                        activeEmails.filter((id: any) => id !== item.email)
                      );
                      return;
                    }
                    setActiveEmails((oldValue: any) => [
                      ...oldValue,
                      item?.email
                    ]);
                  }}
                  onMouseEnter={() => {
                    if (isMobile) return;
                    setHoverItem(item.email);
                  }}
                  onMouseLeave={() => {
                    if (isMobile) return;
                    setHoverItem(null);
                  }}
                >
                  <CapaAvatar item={item} size="mid" />
                  <div className={classes.groupInfo}>
                    <div className={classes.groupName}>
                      {getDisplayedAvatarString(item.nickName, item.email)}
                    </div>
                    <div className={classes.groupName}>{item.email}</div>
                  </div>
                  {disabledContacts.includes(item?.email) ? null : (
                    <Checkbox
                      icon={<Brightness1Outlined />}
                      checkedIcon={<CheckCircle />}
                      className={classes.customCheckbox}
                      checked={activeEmails.includes(item?.email)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  )}
                </div>
              );
            })
          ) : searchText ? (
            <div className={classes.noResultText}>
              {t('add_contacts_modal.empty_result', {
                searchContent: searchText
              })}
            </div>
          ) : (
            <div className={classes.noResultText}>
              {t('add_contacts_modal.no_contacts')}
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions classes={{ root: classes.groupsAction }}>
        <div className={classes.groupsMobileButtonWrapper}>
          <CustomizedButton
            type="submit"
            variant="contained"
            color="primary"
            onClick={() => onSelect()}
            disabled={activeEmails.length === 0}
          >
            {t('add_contacts_modal.select')}
          </CustomizedButton>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default AddContacts;
