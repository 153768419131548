import React from 'react';
import CustomizedButtonDownloadApp from '@components/Customized/CustomizedButtonDownloadApp';
import { DownloadAppThumbnail } from '@routes/Home/assets';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { colorSet } from '@components/Provider';
import {
  createMuiTheme,
  createStyles,
  makeStyles,
  Theme
} from '@material-ui/core';

const themeLanding = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1024,
      xl: 1536
    }
  }
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerFriud: {
      maxWidth: '100%',
      overflow: 'hidden',
      paddingLeft: 32,
      paddingRight: 32,
      [themeLanding.breakpoints.down('md')]: {
        paddingLeft: 16,
        paddingRight: 16
      }
    },
    container: {
      width: '100%',
      maxWidth: '1024px',
      margin: 'auto',
      [themeLanding.breakpoints.down('md')]: {
        maxWidth: '960px'
      }
    },
    goToApp: {
      padding: '160px 0',
      [themeLanding.breakpoints.down('md')]: {
        padding: '120px 0'
      }
    },
    title: {
      width: '100%',
      fontWeight: 700,
      fontSize: 32,
      lineHeight: '48px',
      textAlign: 'center',
      marginBottom: 48,
      whiteSpace: 'break-spaces',
      letterSpacing: '-0.1px',
      color: colorSet.gray000,
      [themeLanding.breakpoints.down('xs')]: {
        fontSize: 25,
        lineHeight: '40px'
      }
    },
    downloadButton: {
      width: '100%',
      display: 'flex',
      marginBottom: 48,
      alignItems: 'center',
      justifyContent: 'center'
    },
    imageApp: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& img': {
        maxWidth: 497
      },
      [themeLanding.breakpoints.down('md')]: {
        '& img': {
          marginTop: 60
        }
      },
      [themeLanding.breakpoints.down('xs')]: {
        '& img': {
          marginTop: 0,
          maxWidth: 320
        }
      }
    }
  })
);

const GoToApp = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <div className={classes.containerFriud}>
      <div className={classes.container}>
        <div className={classes.goToApp}>
          <h2 className={classes.title}>
            {isMobile
              ? t('landing_page.download_section.head_title_mobile')
              : t('landing_page.download_section.head_title_desktop')}
          </h2>
          <div className={classes.downloadButton}>
            <CustomizedButtonDownloadApp store="apple" />
            <CustomizedButtonDownloadApp store="google" />
          </div>
          <div className={classes.imageApp}>
            <img src={DownloadAppThumbnail} alt="DownloadAppThumbnail" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoToApp;
