import React, { useEffect, useRef, useState } from 'react';
import Loading from '@components/LoadingInComponent';
import HeaderTable from '@routes/Contacts/HeaderTable';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { CustomizedButton, CustomizedTypography } from '@components/Customized';
import { CapaUserFollow } from '@assets';
import {
  RECOMMENDED_LIST_TABLE_HEADER,
  URL_PARAM
} from '@routes/Contacts/types';
import { useTranslation } from 'react-i18next';
import {
  MuiDiv,
  MuiBoxTable,
  MuiBoxTableItem,
  MuiBoxTableItemMobile
} from './styles';
import useMyContacts from '@hooks/Contacts/useMyContacts';
import EmptyPresenter from '@routes/Contacts/EmptyPresenter';
import useAuth from '@hooks/useAuth';
import {
  DEFAULT_ITEMS_LIMIT,
  DEFAULT_ITEMS_OFFSET
} from '@components/Notifications/types';
import { CapaErrorCode } from '@generated/Common/graphql';
import { useMutation } from '@apollo/client';
import { CreateMyContactUser } from '@generated/Contacts/MyContacts/graphql';
import { useSnackbar } from '@components/Provider';
import TabPanelControl from '@routes/Contacts/TabPanel/TabPanelControl';
import { BottomNavMenu } from '@routes/Common';
import FormContactDialog from '@routes/Contacts/Dialog/FormContact';
import CapaAvatar from '@components/CapaAvatar';

const DataTable = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    onFetchRecommendedContactUsersList,
    recommendedContactUsersListState,
    onFetchMoreRecommendedContactUsers
  } = useMyContacts();
  const { userState } = useAuth();
  const [createMyContact] = useMutation(CreateMyContactUser);
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [isFetching, setIsFetching] = useState(false);
  const wrapperRef = useRef<any>(null);

  const onScrollWrapper = () => {
    const currentWrapperRef = wrapperRef && wrapperRef.current;
    if (
      Math.ceil(currentWrapperRef.scrollTop) +
        currentWrapperRef.clientHeight !==
        currentWrapperRef.scrollHeight ||
      isFetching
    )
      return;

    setIsFetching(true);
  };

  const fetchRecommendedContactUsersList = () => {
    onFetchRecommendedContactUsersList({
      variables: {
        chatRoomUserId: userState?.data?.chatRoomUserId,
        limit: DEFAULT_ITEMS_LIMIT,
        offset: DEFAULT_ITEMS_OFFSET
      }
    });
  };

  const onClickAdd = (contact: any) => {
    createMyContact({
      variables: {
        contactUserEmail: contact.email ?? undefined,
        nickName: contact.nickName ?? undefined,
        memo: ''
      }
    }).then((res: any) => {
      if (res.data.createConnectContactUser.result === CapaErrorCode.Success) {
        fetchRecommendedContactUsersList();
        enqueueSnackbar(
          t('snackbar.add_contact_recommended_success', {
            username: contact.nickName
          }),
          {
            variant: 'success'
          }
        );
      } else if (
        res.data.createConnectContactUser.result ===
        CapaErrorCode.ContactUserAlreadyExists
      ) {
        enqueueSnackbar(t('snackbar.contact_user_already_exists'), {
          variant: 'error'
        });
      } else {
        enqueueSnackbar(t('snackbar.contact_user_error'), {
          variant: 'error'
        });
      }
    });
  };

  useEffect(() => {
    if (!isFetching || onFetchMoreRecommendedContactUsers === undefined) return;
    setTimeout(() => {
      onFetchMoreRecommendedContactUsers({
        variables: {
          limit: DEFAULT_ITEMS_LIMIT,
          offset: recommendedContactUsersListState?.data?.length
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return prev;
          }
          const prevContact = prev.fetchRecommendedContactUsersList.data;
          const moreContact =
            fetchMoreResult.fetchRecommendedContactUsersList.data;

          if (fetchMoreResult.fetchRecommendedContactUsersList.data)
            fetchMoreResult.fetchRecommendedContactUsersList.data = [
              ...prevContact!,
              ...moreContact!
            ];
          return {
            ...fetchMoreResult
          };
        }
      });
      setIsFetching(false);
    }, 500);
  }, [isFetching]);

  useEffect(() => {
    if (!userState?.data) return;
    fetchRecommendedContactUsersList();
  }, [userState]);

  return (
    <MuiDiv>
      {!isMobile ? (
        <>
          <div className="subtitle">
            {t('contact.recommended_contacts.title')}
          </div>

          <MuiBoxTable>
            <div className="table-header">
              {RECOMMENDED_LIST_TABLE_HEADER.map((item: any, index: number) => (
                <HeaderTable item={item} key={index} />
              ))}
            </div>
            {recommendedContactUsersListState?.data === undefined ||
            !userState?.data ? (
              <Loading
                open={true}
                styles={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: 40
                }}
              />
            ) : (
              <div
                className="table-content"
                onScroll={onScrollWrapper}
                ref={wrapperRef}
              >
                {recommendedContactUsersListState ? (
                  recommendedContactUsersListState?.data.map((contact: any) => {
                    const id = contact.id;
                    const username = contact.nickName;
                    const email = contact.email;
                    return (
                      <MuiBoxTableItem key={id}>
                        <div className="table-item-td-one">
                          <div className="table-item-avatar">
                            <CapaAvatar item={contact} />
                          </div>
                          <CustomizedTypography
                            variant="body2"
                            noWrap
                            className="table-item-text"
                            component={'div'}
                          >
                            {username}
                          </CustomizedTypography>
                        </div>
                        <div className="table-item-td-two">
                          <CustomizedTypography variant="body2" noWrap>
                            {email}
                          </CustomizedTypography>
                        </div>
                        <CustomizedTypography
                          component={'div'}
                          variant="body2"
                          noWrap
                          className={'table-item-td-three'}
                        >
                          <div className="table-item-td-feature">
                            <CustomizedButton
                              variant="contained"
                              color="default"
                              className="custom-add-button"
                              onClick={() => {
                                onClickAdd(contact);
                              }}
                            >
                              <img
                                src={CapaUserFollow}
                                alt="CapaUserFollow"
                                className="custom-add-icon"
                              />
                              {t('contact.recommended_contacts.add')}
                            </CustomizedButton>
                          </div>
                        </CustomizedTypography>
                      </MuiBoxTableItem>
                    );
                  })
                ) : (
                  <EmptyPresenter />
                )}
              </div>
            )}
          </MuiBoxTable>
          <FormContactDialog />
        </>
      ) : (
        <>
          <TabPanelControl urlParam={URL_PARAM.recommendedType} />
          {recommendedContactUsersListState?.data === undefined ||
          !userState?.data ? (
            <Loading
              open={true}
              styles={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: 40
              }}
            />
          ) : (
            <div
              className="recommended-content"
              onScroll={onScrollWrapper}
              ref={wrapperRef}
            >
              {recommendedContactUsersListState ? (
                recommendedContactUsersListState?.data.map((contact: any) => {
                  const id = contact.id;
                  const username = contact.nickName;
                  const email = contact.email;
                  return (
                    <MuiBoxTableItemMobile key={id}>
                      <div className="item-wrapper">
                        <CapaAvatar item={contact} />
                        <div className="table-contents">
                          <CustomizedTypography
                            variant="body2"
                            gutterBottoms={2}
                            noWrap
                          >
                            {username}
                          </CustomizedTypography>
                          <CustomizedTypography
                            variant="caption"
                            color="textSecondary"
                            gutterBottoms={2}
                          >
                            {email}
                          </CustomizedTypography>
                        </div>
                        <CustomizedButton
                          variant="contained"
                          color="default"
                          className="custom-add-button"
                          onClick={() => {
                            onClickAdd(contact);
                          }}
                        >
                          <img
                            src={CapaUserFollow}
                            alt="CapaUserFollow"
                            className="custom-add-icon"
                          />
                        </CustomizedButton>
                      </div>
                    </MuiBoxTableItemMobile>
                  );
                })
              ) : (
                <EmptyPresenter />
              )}
            </div>
          )}
          <BottomNavMenu />
        </>
      )}
    </MuiDiv>
  );
};

export default DataTable;
