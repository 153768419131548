import type { SVGProps } from 'react';
import React from 'react';

const IBMView = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 19 14"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.6665 5.05755C17.326 6.24164 18.6021 7.88603 19.3372 9.7875C19.3869 9.92482 19.3869 10.0752 19.3372 10.2125C18.6021 12.114 17.326 13.7584 15.6665 14.9424C14.007 16.1265 12.0369 16.7984 9.99973 16.875C7.96256 16.7984 5.99248 16.1265 4.33299 14.9424C2.67349 13.7584 1.39733 12.114 0.662234 10.2125C0.612589 10.0752 0.612589 9.92482 0.662234 9.7875C1.39733 7.88603 2.67349 6.24164 4.33299 5.05755C5.99248 3.87347 7.96256 3.20161 9.99973 3.125C12.0369 3.20161 14.007 3.87347 15.6665 5.05755ZM1.91848 10C3.18723 13.1687 6.68723 15.625 9.99973 15.625C13.3122 15.625 16.8122 13.1687 18.081 10C16.8122 6.83125 13.3122 4.375 9.99973 4.375C6.68723 4.375 3.18723 6.83125 1.91848 10ZM7.91635 6.88199C8.53303 6.46993 9.25805 6.25 9.99973 6.25C10.9943 6.25 11.9481 6.64509 12.6514 7.34835C13.3546 8.05161 13.7497 9.00544 13.7497 10C13.7497 10.7417 13.5298 11.4667 13.1177 12.0834C12.7057 12.7001 12.12 13.1807 11.4348 13.4645C10.7496 13.7484 9.99557 13.8226 9.26815 13.6779C8.54072 13.5332 7.87253 13.1761 7.34808 12.6517C6.82364 12.1272 6.46648 11.459 6.32179 10.7316C6.1771 10.0042 6.25136 9.25016 6.53519 8.56494C6.81902 7.87971 7.29966 7.29404 7.91635 6.88199ZM8.61081 12.0787C9.02193 12.3534 9.50528 12.5 9.99973 12.5C10.6628 12.5 11.2987 12.2366 11.7675 11.7678C12.2363 11.2989 12.4997 10.663 12.4997 10C12.4997 9.50555 12.3531 9.0222 12.0784 8.61107C11.8037 8.19995 11.4133 7.87952 10.9564 7.6903C10.4996 7.50108 9.99696 7.45157 9.51201 7.54804C9.02706 7.6445 8.5816 7.8826 8.23197 8.23223C7.88234 8.58186 7.64423 9.02732 7.54777 9.51227C7.45131 9.99723 7.50082 10.4999 7.69004 10.9567C7.87925 11.4135 8.19969 11.804 8.61081 12.0787Z"
      fill={props.color ?? '#343A40'}
    />
  </svg>
);

export default IBMView;
