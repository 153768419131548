import React, { useState, useEffect } from 'react';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  useMediaQuery
} from '@material-ui/core';
import { CustomizedButton } from '@components/Customized';
import { useStyles } from './styles';
import {
  CreateNewFolderOutlined,
  ArrowBackIosOutlined
} from '@material-ui/icons';
import useGroup from '@hooks/Group/useGroup';
import { CREATE_GROUP_SCREEN } from '@routes/Group/Dialog/types';
import { theme } from '@components/Provider';
import { useTranslation } from 'react-i18next';
import useMyDrive from '@hooks/Drive/MyDrive/useMyDrive';
import { KEY_CODE } from '@routes/Dashboard/types';
import { ADD_FILES_FROM_VALUE } from '@routes/Common/Dialog/UploadFiles/Mobile/types';

interface CreateGroupPresenterProps {
  onCloseDialog?: () => void;
}

const CreateGroupPresenter = (props: CreateGroupPresenterProps) => {
  const { t } = useTranslation();
  const { onSetCreateGroupScreen } = useGroup();
  const { onCloseDialog } = props;
  const classes = useStyles();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const [groupName, setGroupName] = useState<string>('');
  const {
    myDrive,
    onSetValueGroupNameDialog,
    onSetIdGroupName,
    onSetAddFilesFromType
  } = useMyDrive();

  const onSubmit = () => {
    onSetValueGroupNameDialog({
      valueGroupNameDialog: groupName
    });
    if (matches) {
      onSetCreateGroupScreen({
        createGroupScreen: CREATE_GROUP_SCREEN.ADD_FILES_CONTAINER_MOBILE
      });
      return;
    }
    onSetCreateGroupScreen({
      createGroupScreen: CREATE_GROUP_SCREEN.ADD_FILES
    });
  };

  const onClose = () => {
    if (onCloseDialog) onCloseDialog();
  };

  useEffect(() => {
    setGroupName(myDrive.valueGroupNameDialog || '');
  }, [myDrive.valueGroupNameDialog]);

  useEffect(() => {
    onSetCreateGroupScreen({
      createGroupScreen: CREATE_GROUP_SCREEN.CREATE_GROUP
    });
    onSetValueGroupNameDialog({
      valueGroupNameDialog: ''
    });
    onSetIdGroupName({ idGroupName: '' });
    onSetAddFilesFromType({ addFilesFromType: ADD_FILES_FROM_VALUE.EMPTY });
  }, []);

  return (
    <>
      <DialogTitle classes={{ root: classes.rootTitle }} disableTypography>
        <div className={classes.subTitle}>
          {!matches ? (
            <div className={classes.titleIcon}>
              <CreateNewFolderOutlined />
            </div>
          ) : (
            <ArrowBackIosOutlined
              onClick={() => {
                if (onCloseDialog) onCloseDialog();
              }}
            />
          )}
          <span className={classes.name}>
            {t('group_name_modal.create_group')}
          </span>
        </div>
      </DialogTitle>
      <DialogContent className={classes.csDialogContent}>
        <TextField
          autoFocus
          value={groupName ?? undefined}
          placeholder={t('group_name_modal.placeholder')}
          variant="outlined"
          size="small"
          fullWidth
          onChange={(event) => setGroupName && setGroupName(event.target.value)}
          onKeyDown={(e) => {
            if (e.keyCode === KEY_CODE.ENTER) {
              onSubmit && onSubmit();
            }
          }}
        />
      </DialogContent>
      <DialogActions classes={{ root: classes.action }}>
        <div className={classes.wrapperBtn}>
          <CustomizedButton
            variant="contained"
            color="default"
            onClick={onClose}
          >
            {t('group_name_modal.cancel_button')}
          </CustomizedButton>
          <CustomizedButton
            type="submit"
            variant="contained"
            color="primary"
            onClick={onSubmit}
            disabled={!groupName}
          >
            {t('group_name_modal.next_button')}
          </CustomizedButton>
        </div>
      </DialogActions>
    </>
  );
};

export default CreateGroupPresenter;
