import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  useChangePasswordMutation,
  CapaErrorCode
} from '@generated/Common/graphql';
import { clearStorageAll } from '@utils/storage';
import { useSnackbar } from '@components/Provider';
import Loading from '@components/Loading';
import ResetPasswordPresenter from './ResetPasswordPresenter';
import { Inputs } from './LoginPresenter';

const ResetPasswordContainer: React.FC = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [changePassword, { loading }] = useChangePasswordMutation();

  const onUpdate = (req: Inputs) => {
    if (req.password && req.newPassword) {
      changePassword({
        variables: {
          password: req.password,
          newPassword: req.newPassword,
          newPasswordConfirm: req.newPassword
        }
      })
        .then((data) => {
          const result = data.data?.changePassword.result;
          if (result === CapaErrorCode.Success) {
            clearStorageAll();
            enqueueSnackbar(`비밀번호가 변경되었습니다.`, {
              variant: 'success'
            });
            history.push('/login');
          } else if (result === CapaErrorCode.PasswordIncorrect) {
            enqueueSnackbar(`비밀번호가 일치하지 않습니다.`, {
              variant: 'error'
            });
          } else if (result === CapaErrorCode.ParmeterValidationError) {
            enqueueSnackbar(
              '비밀번호는 영문+숫자 조합으로  8글자 이상 16글자 이하로 입력해주세요.',
              {
                variant: 'error'
              }
            );
          } else {
            enqueueSnackbar(
              `가입되지 않은 이메일이거나 비밀번호가 일치하지 않습니다. 계정 관련 문의는 고객센터(support@capa.ai)로 연락 주세요.`,
              { variant: 'error' }
            );
          }
        })
        .catch(() => {
          enqueueSnackbar(
            `가입되지 않은 이메일이거나 비밀번호가 일치하지 않습니다. 계정 관련 문의는 고객센터(support@capa.ai)로 연락 주세요.`,
            { variant: 'error' }
          );
        });
    }
  };

  const onClick = () => history.push('/');

  return (
    <React.Fragment>
      <Loading open={loading} />
      <ResetPasswordPresenter onUpdate={onUpdate} onClick={onClick} />
    </React.Fragment>
  );
};

export default ResetPasswordContainer;
