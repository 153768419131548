import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { useStyles } from './styles';
import useGroup from '@hooks/Group/useGroup';
import { ArrowBackIosOutlined } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { CustomizedButton } from '@components/Customized';
import * as Apollo from '@apollo/client';
import { RemoveGroup } from '@generated/Drive/MyDrive/graphql';
import { CapaErrorCode } from '@generated/Common/graphql';
import { useSnackbar } from '@components/Provider';
import { LANGUAGE_VALUE } from '@routes/Common/ChangeLanguage/types';
import i18n from '@i18n/index';

interface CreateGroupProps {
  isShowHideDialog: boolean;
  onCloseDialog?: () => void;
  removeGroupSuccess?: () => void;
  clickSubmit?: () => void;
  groupName?: () => string;
  groupId?: string;
}

const CreateGroupContainerDialog = (props: CreateGroupProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const {
    isShowHideDialog,
    onCloseDialog,
    removeGroupSuccess,
    groupName,
    groupId,
    clickSubmit
  } = props;
  const { group } = useGroup();
  const [removeGroup] = Apollo.useMutation(RemoveGroup);
  const { enqueueSnackbar } = useSnackbar();
  const languageValue = i18n.language;

  const onSubmit = () => {
    clickSubmit && clickSubmit();
    removeGroup({ variables: { groupId: groupId } })
      .then((res: any) => {
        const result = res?.data?.removeGroup?.result;
        if (result === CapaErrorCode.Success) {
          enqueueSnackbar(
            t('snackbar.delete_group_success', {
              groupName: group?.selectedGroup[0]?.groupName
            }),
            {
              variant: 'success'
            }
          );
          if (removeGroupSuccess) removeGroupSuccess();
        } else {
          enqueueSnackbar(t('snackbar.delete_group_error'), {
            variant: 'error'
          });
        }
        if (onCloseDialog) onCloseDialog();
      })
      .catch(() => {
        if (onCloseDialog) onCloseDialog();
        enqueueSnackbar(t('snackbar.delete_group_error'), {
          variant: 'error'
        });
      });
  };

  return (
    <Dialog
      classes={{
        paper: classes.paper
      }}
      open={isShowHideDialog}
      onClick={(e) => {
        e.stopPropagation();
      }}
      fullScreen={matches}
    >
      <DialogTitle classes={{ root: classes.rootTitle }} disableTypography>
        <div className={classes.subTitle}>
          {!matches ? (
            <span>{t('delete_group_dialog.delete_group')}</span>
          ) : (
            <>
              <ArrowBackIosOutlined
                onClick={() => {
                  if (onCloseDialog) onCloseDialog();
                }}
              />
              <span>{t('delete_group_dialog.delete_group')}</span>
              <div />
            </>
          )}
        </div>
      </DialogTitle>

      <DialogContent className={classes.csDialogContent}>
        <div className={classes.content}>
          <div>
            {languageValue === LANGUAGE_VALUE.EN
              ? t('delete_group_dialog.question') + ' '
              : null}
            <span className={classes.groupName}>{groupName}</span>
            {languageValue === LANGUAGE_VALUE.KO
              ? t('delete_group_dialog.question')
              : null}
            ?
          </div>
          <div className={classes.wordBreak}>
            {t('delete_group_dialog.all_share_files')}
          </div>
        </div>
      </DialogContent>

      <DialogActions classes={{ root: classes.action }}>
        <div className={classes.wrapperBtn}>
          <CustomizedButton
            variant="contained"
            color="default"
            onClick={() => {
              if (onCloseDialog) onCloseDialog();
            }}
          >
            {t('delete_group_dialog.cancel_button')}
          </CustomizedButton>
          <CustomizedButton
            type="submit"
            variant="contained"
            color="primary"
            onClick={onSubmit}
          >
            {t('delete_group_dialog.delete_button')}
          </CustomizedButton>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default CreateGroupContainerDialog;
