import React, { useEffect, useState, useRef } from 'react';
import {
  Paper,
  InputBase,
  useMediaQuery,
  useTheme,
  Breadcrumbs,
  Link
} from '@material-ui/core';
import { CustomizedTypography } from '@components/Customized';
import { MuiBoxTable, useStyles, MuiBoxTableItem, MuiDiv } from './styles';
import useMyDrive from '@hooks/Drive/MyDrive/useMyDrive';
import { CancelRounded, Check, Search } from '@material-ui/icons';
import { formatFileSize, uniqueArray, formatTimeStamp } from '@utils/formatter';
import { DEFAULT_ROUTER_MY_DRIVE } from '@routes/Dashboard/types';
import { DEFAULT_ITEMS_LIMIT, KEY_TYPE } from '@routes/Common/types';
import { MuiBoxTableItemMobile } from '@routes/Dashboard/styles';
import { useTranslation } from 'react-i18next';
import { IS_MAC_OS } from '@routes/Common/utils';

import Loading from '@components/LoadingInComponent';
import IconThumbnail from '@components/IconThumbnail/IconThumbnail';
import useUserActionLog, {
  CapaActivityType,
  Enum_Action_Button_Enum
} from '@hooks/useUserActionLog';
import i18n from '@i18n/index';
import { LANGUAGE_VALUE } from '@routes/Common/ChangeLanguage/types';
import { IBMCAD, IBMChevronBreadcrumb } from '@assets';
import { convertSubstring } from '@utils/file';
import { DRAWING_FILE_TYPES } from '@routes/Common/Dropzone/types';

interface Props {
  isShowHideUploadFilesDialog?: boolean;
}

const UploadFromMyDrive: React.FC<Props> = ({
  isShowHideUploadFilesDialog
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const languageValue = i18n.language;
  const {
    myDrive,
    onFetchConnectMyDriveFileUploadInChat,
    connectMyDriveFileUploadInChatState,
    onFetchMoreConnectMyDriveFileUploadInChat,
    onSetSelectedFilesUploadInChat,
    connectMyDriveUploadFileInChatLoading
  } = useMyDrive();
  const [searchText, setSearchText] = useState<string>('');
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const myDrivePath = (connectMyDriveFileUploadInChatState.path ?? []).filter(
    (item: any) => item.id !== null
  );
  myDrivePath.unshift(DEFAULT_ROUTER_MY_DRIVE);
  const [isFetching, setIsFetching] = useState(false);
  const [folderId, setFolderId] = useState('');
  const [myDriveFileUpload, setMyDriveFileUpload] = useState<any>([]);
  const searchResultRef = useRef<any>(null);
  const { t } = useTranslation();
  const [selectedFirstFile, setSelectedFirstFile] = useState<any>(null);
  const [selectedFileMetaKey, setSelectedFileMetaKey] = useState<any>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const { onInsertUserActionLogOne } = useUserActionLog();

  const onSelectOnlyFile = (fileList: any) => {
    const { selectedFilesUploadInChat } = fileList;
    if (!selectedFilesUploadInChat || !selectedFilesUploadInChat.length)
      return onSetSelectedFilesUploadInChat({ selectedFilesUploadInChat: [] });

    onSetSelectedFilesUploadInChat({
      selectedFilesUploadInChat: selectedFilesUploadInChat.filter(
        (file: any) => file.isFile
      )
    });
  };

  const resetMyDrive = () => {
    onSelectOnlyFile({ selectedFilesUploadInChat: [] });
    setSearchText('');
    setMyDriveFileUpload([]);
  };

  const onClickBreadcrumb = (folderId: string) => {
    onFetchConnectMyDriveFileUploadInChat({
      pathId: folderId ? folderId : '',
      limit: 40,
      offset: 0
    });

    onSelectOnlyFile({
      selectedFilesUploadInChat: []
    });
  };

  const onClickMuiBoxTableItem = (
    e: any,
    isChecked: boolean,
    id: string,
    file: any
  ) => {
    if ((e.metaKey && IS_MAC_OS()) || e.ctrlKey) {
      setSelectedFirstFile(file);
      if (myDrive.selectedFilesUploadInChat.length === 0) {
        setSelectedFileMetaKey([file]);
      }
      if (isChecked) {
        onSelectOnlyFile({
          selectedFilesUploadInChat: myDrive.selectedFilesUploadInChat.filter(
            (file: any) => file.id !== id
          )
        });
      } else {
        onSelectOnlyFile({
          selectedFilesUploadInChat: [
            ...myDrive.selectedFilesUploadInChat,
            file
          ]
        });
        setSelectedFileMetaKey([...myDrive.selectedFilesUploadInChat, file]);
      }
      return;
    }

    if (e.type === KEY_TYPE.CLICK && !e.shiftKey) {
      setSelectedFileMetaKey([file]);
      setSelectedFirstFile(file);
    }

    if (e.shiftKey) {
      if (myDrive.selectedFilesUploadInChat.length === 0) {
        setSelectedFirstFile(file);
        onSelectOnlyFile({
          selectedFilesUploadInChat: [...[file]]
        });
        return;
      }

      let indexOfNew = connectMyDriveFileUploadInChatState.files
        .map((file: any) => file.id)
        .indexOf(selectedFirstFile !== null ? selectedFirstFile.id : file.id);
      let indexOfLast = connectMyDriveFileUploadInChatState.files
        .map((file: any) => file.id)
        .indexOf(file.id);

      const isSelectingForwards = indexOfNew! > indexOfLast!;
      const start = isSelectingForwards ? indexOfLast : indexOfNew;
      const end = isSelectingForwards ? indexOfNew : indexOfLast;
      onSelectOnlyFile({
        selectedFilesUploadInChat: uniqueArray(
          [
            ...selectedFileMetaKey,
            ...connectMyDriveFileUploadInChatState.files.slice(start, end! + 1)
          ],
          'id'
        )
      });
      return;
    }

    onSelectOnlyFile({
      selectedFilesUploadInChat: [...[file]]
    });
  };

  const onScrollContent = () => {
    const currentSearchResultRef = searchResultRef && searchResultRef.current;
    if (
      Math.ceil(currentSearchResultRef.scrollTop) +
        currentSearchResultRef.clientHeight !==
        currentSearchResultRef.scrollHeight ||
      isFetching ||
      searchText !== ''
    )
      return;

    setIsFetching(true);
  };

  useEffect(() => {
    if (!isFetching) return;
    setTimeout(() => {
      if (
        onFetchMoreConnectMyDriveFileUploadInChat &&
        isShowHideUploadFilesDialog
      ) {
        onFetchMoreConnectMyDriveFileUploadInChat({
          variables: {
            pathId: folderId,
            limit: DEFAULT_ITEMS_LIMIT,
            offset: connectMyDriveFileUploadInChatState.files.length
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) {
              return prev;
            }

            const prevFiles = prev.fetchConnectMyDrive.data?.files;
            const moreFiles = fetchMoreResult.fetchConnectMyDrive.data?.files;

            if (fetchMoreResult.fetchConnectMyDrive.data)
              fetchMoreResult.fetchConnectMyDrive.data.files = [
                ...prevFiles!,
                ...moreFiles!
              ];

            setMyDriveFileUpload(
              fetchMoreResult.fetchConnectMyDrive.data.files ?? []
            );
            return {
              ...fetchMoreResult
            };
          }
        });
      }
      setIsFetching(false);
    }, 500);
  }, [isFetching]);

  useEffect(() => {
    if (isShowHideUploadFilesDialog) {
      onFetchConnectMyDriveFileUploadInChat({
        pathId: '',
        limit: 40,
        offset: 0
      });
    } else {
      resetMyDrive();
    }
  }, [isShowHideUploadFilesDialog]);

  useEffect(() => {
    if (connectMyDriveFileUploadInChatState.files.length > 0) {
      setMyDriveFileUpload(connectMyDriveFileUploadInChatState.files ?? []);
    }
    setLoading(false);
  }, [connectMyDriveFileUploadInChatState]);

  useEffect(() => {
    setSearchText('');
  }, [myDrive.uploadFileTab]);

  return (
    <>
      <Paper className={classes.searchInputWrapper}>
        <div className={classes.searchIconWrapper}>
          <Search className={classes.customSearchIcon} />
        </div>
        <InputBase
          className={classes.searchInputBase}
          placeholder={t('upload_files_modal.placeholder')}
          value={searchText!}
          onChange={(event) => {
            setLoading(true);
            const internalFileData =
              connectMyDriveFileUploadInChatState.files.filter((file: any) => {
                return file.fileName
                  .toLowerCase()
                  .includes(event.target.value.toLowerCase());
              });
            setSearchText(event.target.value);
            setTimeout(() => {
              setLoading(false);
              setMyDriveFileUpload(internalFileData);
            }, 500);
          }}
        />
        {searchText ? (
          <div
            className={classes.searchCloseIconWrapper}
            onClick={() => {
              setMyDriveFileUpload(connectMyDriveFileUploadInChatState.files);
              setSearchText('');
            }}
          >
            <CancelRounded className={classes.customSearchIcon} />
          </div>
        ) : null}
      </Paper>
      <Loading
        open={isLoading || connectMyDriveUploadFileInChatLoading}
        styles={{
          display: 'flex',
          justifyContent: 'center',
          minHeight: 300
        }}
      />
      {!isLoading && !connectMyDriveUploadFileInChatLoading ? (
        <MuiDiv>
          <div className="sub-title">
            <CustomizedTypography
              component={'div'}
              variant={matches ? 'h6' : 'h3'}
              fontWeight="500"
            >
              <Breadcrumbs
                separator={
                  <img src={IBMChevronBreadcrumb} alt="IBMChevronBreadcrumb" />
                }
                aria-label="breadcrumb"
              >
                {myDrivePath &&
                  myDrivePath.map((item: any, index: number) => (
                    <Link
                      key={index}
                      onClick={() => onClickBreadcrumb(item.id)}
                      className="cursor-pointer"
                      color="inherit"
                    >
                      {languageValue == LANGUAGE_VALUE.KO
                        ? item.folderNameKO
                          ? item.folderNameKO
                          : item.folderName
                        : item.folderName}
                    </Link>
                  ))}
              </Breadcrumbs>
            </CustomizedTypography>
          </div>
        </MuiDiv>
      ) : null}
      {connectMyDriveUploadFileInChatLoading ? null : (
        <div
          className={classes.searchResultWrapper}
          onScroll={onScrollContent}
          ref={searchResultRef}
        >
          {connectMyDriveFileUploadInChatState &&
          connectMyDriveFileUploadInChatState?.files &&
          connectMyDriveFileUploadInChatState?.files?.length > 0 ? (
            myDriveFileUpload.length > 0 && !isLoading ? (
              <MuiBoxTable>
                {matches ? null : (
                  <div className="table-header">
                    <CustomizedTypography
                      className="table-header-th-one"
                      variant="body2"
                      color="textSecondary"
                    >
                      {t('upload_files_modal.column_title.name')}
                    </CustomizedTypography>
                    <CustomizedTypography
                      className="table-header-th-two"
                      variant="body2"
                      color="textSecondary"
                    >
                      {t('upload_files_modal.column_title.upload_date')}
                    </CustomizedTypography>
                    <CustomizedTypography
                      className="table-header-th-three"
                      variant="body2"
                      color="textSecondary"
                    >
                      {t('upload_files_modal.column_title.size')}
                    </CustomizedTypography>
                  </div>
                )}

                {myDriveFileUpload.map((item: any, index: number) => {
                  const isChecked =
                    myDrive.selectedFilesUploadInChat.filter(
                      (file: any) => file.id === item.id
                    ).length > 0;
                  return !matches ? (
                    <MuiBoxTableItem
                      key={index}
                      isChecked={isChecked}
                      onDoubleClick={() => {
                        if (!item.isFile) {
                          setFolderId(item.id);
                          onFetchConnectMyDriveFileUploadInChat({
                            pathId: item.id,
                            limit: 40,
                            offset: 0
                          });
                        }
                      }}
                      onClick={(e) => {
                        if (item.isFile) {
                          onInsertUserActionLogOne(
                            Enum_Action_Button_Enum.Button_UploadFileModal_MyDrive_ClickFile,
                            CapaActivityType.ButtonClick
                          );
                        }
                        onClickMuiBoxTableItem(e, isChecked, item.id, item);
                      }}
                    >
                      <div className="table-item-td-one">
                        <IconThumbnail file={item} size="small" />
                        <CustomizedTypography variant="body2" noWrap>
                          {item.fileName}
                        </CustomizedTypography>
                      </div>
                      <div className="table-item-td-two">
                        <CustomizedTypography variant="body2">
                          {formatTimeStamp(
                            item.createdAt,
                            true,
                            false,
                            false,
                            true
                          )}
                        </CustomizedTypography>
                      </div>
                      <div className="table-item-td-four">
                        <CustomizedTypography variant="body2">
                          {formatFileSize(item.fileSize).toUpperCase()}
                        </CustomizedTypography>
                      </div>
                    </MuiBoxTableItem>
                  ) : (
                    <MuiBoxTableItemMobile
                      key={index}
                      isChecked={isChecked}
                      onDoubleClick={() => {
                        if (!item.isFile) {
                          setFolderId(item.id);
                          onFetchConnectMyDriveFileUploadInChat({
                            pathId: item.id,
                            limit: 40,
                            offset: 0
                          });
                        }
                      }}
                      onClick={(e) => {
                        onClickMuiBoxTableItem(e, isChecked, item.id, item);
                      }}
                    >
                      <div className="table-item-td-one">
                        <IconThumbnail file={item} size="large" />
                      </div>
                      <div className="table-content">
                        <CustomizedTypography
                          variant="body2"
                          component={'div'}
                          noWrap
                        >
                          {convertSubstring(DRAWING_FILE_TYPES).includes(
                            item?.originalFileExt?.toLocaleLowerCase()
                          ) &&
                            item?.isFile && (
                              <img
                                src={IBMCAD}
                                alt=""
                                className="ibm-cad-icon"
                              />
                            )}
                          <div className="cs-file-name">{item.fileName}</div>
                        </CustomizedTypography>
                        <CustomizedTypography
                          variant="caption"
                          color="textSecondary"
                          gutterBottoms={2}
                        >
                          {formatFileSize(item.fileSize).toUpperCase()}
                        </CustomizedTypography>
                        <CustomizedTypography
                          component={'div'}
                          variant="caption"
                          color="textSecondary"
                        >
                          <div className="table-item-share-mobile">
                            <div>
                              {formatTimeStamp(
                                item.createdAt,
                                true,
                                false,
                                false,
                                true
                              )}
                            </div>
                          </div>
                        </CustomizedTypography>
                      </div>
                      {isChecked ? (
                        <div className={classes.checkIconWrapper}>
                          <Check className="check-icon" />
                        </div>
                      ) : null}
                    </MuiBoxTableItemMobile>
                  );
                })}
              </MuiBoxTable>
            ) : (
              !isLoading &&
              searchText && (
                <div className={classes.noResultText}>
                  {t('upload_files_modal.empty_result', {
                    searchContent: searchText
                  })}
                </div>
              )
            )
          ) : (
            <div className={classes.noDocuments}>
              {t('upload_files_modal.no_documents')}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default UploadFromMyDrive;
