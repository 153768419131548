import React from 'react';
import clsx from 'clsx';
import { theme as globalTheme, colorSet } from '@components/Provider';
import { MuiButtonProps, ICustomizedButtonProps } from './types';
import { Button, createMuiTheme, ThemeProvider } from '@material-ui/core';

const CustomizedButton: React.FC<ICustomizedButtonProps> = (props) => {
  const { color, size, variant, ...rest } = props;
  const colorProp =
    color === 'default' || color === 'success' || color === 'error'
      ? 'secondary'
      : !color
      ? 'primary'
      : color;
  const customizedPalette =
    color === 'default' || color === 'success' || color === 'error'
      ? MuiButtonProps[color].palette
      : globalTheme.palette;
  const customizedStyle =
    color === 'default' || color === 'success' || color === 'error'
      ? MuiButtonProps[color].style
      : {};
  const sizeProp = size ?? 'medium';
  const variantProp = variant ?? 'contained';

  const theme = React.useMemo(() => {
    return createMuiTheme({
      ...globalTheme,
      palette: customizedPalette,
      overrides: {
        MuiButton: {
          root: {
            borderRadius: 4,
            flexWrap: 'nowrap',
            '&.medium': {
              height: 48,
              fontSize: 16,
              lineHeight: '24px',
              minWidth: 132,
              fontWeight: '500',
              borderRadius: 8,
              padding: '12px 23px 12px 20px'
            },
            '&.text': {
              height: 20,
              minWidth: 'fit-content',
              padding: 0,
              '&:hover': {
                backgroundColor: colorSet.gray000
              }
            },
            '&$disabled': {
              '&.text': {
                'border': 'none'
              }
            }
          },
          label: {
            display: 'flex',
            alignItems: 'center'
          },
          contained: {
            '&$:disabled': {
              backgroundColor: colorSet.gray200,
              color: colorSet.gray500
            }
          },
          outlined: {
            '&$:disabled': {
              border: `1px solid ${colorSet.gray400}`,
              color: colorSet.gray400
            }
          },
          text: {
            '&:hover': {
              backgroundColor: colorSet.gray000,
              textDecoration: 'underline'
            },
            '&$:disabled': {
              color: colorSet.gray500,
              textDecoration: 'none'
            }
          },
          outlinedPrimary: {
            border: `1px solid ${colorSet.primary500}`
          },
          startIcon: {
            marginRight: 4,
            marginLeft: -3
          },
          endIcon: {
            marginLeft: 4
          },
          sizeLarge: {
            height: 48,
            minWidth: 132,
            fontSize: 16,
            '&.text': {
              height: 24
            }
          },
          sizeSmall: {
            height: 32,
            minWidth: 84,
            fontSize: 14
          },
          ...customizedStyle
        }
      }
    });
    // eslint-disable-next-line
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Button
        {...rest}
        className={clsx(
          'customized-button',
          props.className,
          !props.size || props.size === 'medium' ? 'medium' : '',
          props.variant
        )}
        variant={variantProp}
        color={colorProp}
        size={sizeProp}
        disableTouchRipple={props.variant === 'text'}
        disableElevation
      >
        {props.children}
      </Button>
    </ThemeProvider>
  );
};

export default CustomizedButton;
