import { makeStyles } from '@material-ui/core';
import { colorSet } from '@components/Provider';

export const useStyles = makeStyles((theme) => ({
  actionButtonWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& .cs-button': {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '143%',
      letterSpacing: '-0.1px',
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    '& .black-color': {
      color: colorSet.gray900
    },
    '& .red-color': {
      color: colorSet.errorMain
    },
    '& .primary-color': {
      color: colorSet.primary450
    },
    '& .dot': {
      color: colorSet.gray700,
      margin: '0 4px'
    }
  }
}));
