import { makeStyles } from '@material-ui/core';
import { colorSet } from '@components/Provider';

export const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: 640,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: 0,
      height: '100%',
      maxHeight: '100%',
      maxWidth: '100%',
      borderRadius: 0
    },
    maxHeight: 593,
    borderRadius: '16px'
  },
  successFullyPaper: {
    maxWidth: 480,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: 0,
      height: '100%',
      maxHeight: '100%',
      maxWidth: '100%',
      borderRadius: 0
    },
    maxHeight: 593,
    borderRadius: '16px'
  },
  rootTitle: {
    padding: '24px 32px',
    display: 'flex',
    fontSize: '25px',
    fontWeight: 'bold',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: '0 16px',
      height: 48,
      textAlign: 'center',
      justifyContent: 'center'
    }
  },
  closeIcon: {
    position: 'absolute',
    left: 16,
    top: 10,
    width: 24,
    height: 24,
    [theme.breakpoints.down('xs')]: {
      top: 14
    }
  },
  customTitle: {
    [theme.breakpoints.down('xs')]: {
      color: colorSet.gray900,
      fontSize: 15,
      fontWeight: 500,
      letterSpacing: '-0.1px'
    }
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  cancelBtn: {
    fontSize: 24,
    marginLeft: 'auto',
    cursor: 'pointer'
  },
  content: {
    padding: '0px 24px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px 12px'
    }
  },
  action: {
    padding: '24px 32px 24px 32px'
  },
  wrapperBtn: {
    display: 'flex',
    '& button': {
      textTransform: 'none',
      marginLeft: 'auto',
      '&:first-child': {
        marginRight: 8
      }
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      '& button': {
        width: 'calc(100% / 2)'
      },
      '& button + button': {
        marginLeft: 6
      }
    }
  },
  csDialogText: {
    color: colorSet.gray700,
    fontSize: '16px'
  },
  csDialogContent: {
    padding: '0 0 24px 0',
    marginLeft: 32,
    marginRight: 32,
    borderBottom: `1px solid ${colorSet.gray400}`,
    [theme.breakpoints.down('xs')]: {
      padding: '8px 16px',
      margin: 0
    }
  },
  csDialogContentUpload: {
    padding: '0 0 24px 0',
    marginLeft: 32,
    marginRight: 32,
    borderBottom: `1px solid ${colorSet.gray400}`,
    [theme.breakpoints.down('xs')]: {
      padding: '8px 0',
      margin: 0
    }
  },
  shareFilesToBox: {
    display: 'flex',
    width: '280px',
    borderRadius: '12px',
    '&:first-child': {
      marginRight: '16px'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      padding: '0 14px 0 16px',
      height: 48,
      alignItems: 'center'
    }
  },
  shareGroupAddIconWrapper: {
    backgroundColor: colorSet.primary500,
    borderRadius: 12,
    padding: 10,
    marginRight: 16
  },
  shareGroupAddIcon: {
    color: colorSet.gray100
  },
  activeShareFilesToBox: {
    backgroundColor: colorSet.primary100,
    border: `1px solid ${colorSet.primary450}`
  },
  activeIconWrapper: {
    backgroundColor: `${colorSet.primary200} !important`
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      width: 24,
      height: 24,
      '& path': {
        fill: `${colorSet.gray900} !important`
      }
    }
  },
  activeShareFilesToIcon: {
    color: colorSet.primary500
  },
  shareFilesName: {
    fontWeight: 400,
    fontSize: 15,
    lineHeight: '140%',
    letterSpacing: '-0.3px',
    marginLeft: 16
  },
  chevronRight: {
    marginLeft: 'auto',
    '& path': {
      fill: `${colorSet.gray700} !important`
    }
  },
  arrowBackIosOutlinedWrapper: {
    backgroundColor: colorSet.primary500,
    borderRadius: 12,
    padding: '9px 15px',
    marginRight: 16
  },
  arrowBackIosOutlinedIcon: {
    width: 14,
    color: colorSet.gray100,
    [theme.breakpoints.down('xs')]: {
      color: colorSet.gray900,
      fontWeight: 500,
      position: 'absolute',
      left: 16,
      top: 10
    }
  },
  searchInputWrapper: {
    boxShadow: 'none',
    backgroundColor: colorSet.gray100,
    borderRadius: 8,
    padding: '6px 15px',
    marginBottom: 16,
    position: 'relative'
  },
  searchIconWrapper: {
    display: 'inline-flex',
    verticalAlign: 'middle'
  },
  searchCloseIconWrapper: {
    display: 'inline-flex',
    verticalAlign: 'middle',
    position: 'absolute',
    right: 16,
    top: 10
  },
  searchInputBase: {
    width: '92%',
    paddingLeft: 10,
    paddingRight: 12
  },
  customSearchIcon: {
    cursor: 'pointer',
    color: colorSet.gray500
  },
  searchResultWrapper: {
    maxHeight: 315,
    minHeight: 315,
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: 4
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: colorSet.gray600,
      borderRadius: 8
    }
  },
  searchResult: {
    display: 'inline-flex',
    width: '100%',
    padding: '12px 20px',
    border: `1px solid ${colorSet.gray300}`,
    borderRadius: 12,
    marginTop: 8
  },
  searchResultThumbnailWrapper: {
    width: 44,
    height: 44,
    border: `1px solid ${colorSet.gray500}`,
    borderRadius: 12,
    marginRight: 16
  },
  searchResultGroupName: {
    display: 'inline-flex',
    alignItems: 'center',
    color: colorSet.gray900,
    fontWeight: 'bold'
  },
  avatarGroupWrapper: {
    display: 'flex',
    marginLeft: 'auto',
    alignItems: 'center'
  },
  customAvt: {
    width: '36px',
    height: '36px',
    marginLeft: '0 !important',
    marginRight: '6px !important',
    border: 'none !important'
  },
  avatarGroup: {
    '& .MuiAvatar-root': {
      fontSize: 16,
      fontWeight: 500
    },
    '& .MuiAvatarGroup-avatar': {
      width: '36px',
      height: '36px',
      marginLeft: '0 !important',
      marginRight: '6px !important',
      border: 'none !important',
      textTransform: 'uppercase'
    }
  },
  noResultText: {
    display: 'flex',
    justifyContent: 'center',
    color: colorSet.gray900,
    marginTop: 30
  },
  activeSearchResult: {
    border: `2px solid ${colorSet.primary500}`,
    backgroundColor: colorSet.primary100
  },
  pointerCursor: {
    cursor: 'pointer'
  }
}));
