import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 116,
    marginBottom: 60,
    [theme.breakpoints.down('xs')]: {
      marginTop: 68
    }
  },
  container: ({ isMobileMenu }: { isMobileMenu: boolean }) => ({
    display: 'flex',
    justifyContent: 'center',
    width: isMobileMenu ? '100%' : 'calc(100% - 32px)'
  })
}));
