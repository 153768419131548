import { makeStyles } from '@material-ui/core';
import { colorSet } from '@components/Provider';

export const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: 476,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      minWidth: 343
    },
    borderRadius: '16px'
  },
  rootTitle: {
    padding: 24,
    display: 'flex',
    fontSize: '20px',
    fontWeight: 'bold'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  cancelBtn: {
    fontSize: 24,
    marginLeft: 'auto',
    cursor: 'pointer'
  },
  content: {
    padding: '0px 24px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px 12px'
    }
  },
  action: {
    padding: '0px 24px 24px 24px'
  },
  wrapperBtn: {
    display: 'flex',
    '& button': {
      textTransform: 'none',
      marginLeft: 'auto',
      '&:first-child': {
        marginRight: 8
      }
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      '& button': {
        width: 'calc(100% / 2)'
      },
      '& button + button': {
        marginLeft: 6
      }
    }
  },
  csDialogContent: {
    marginBottom: 24,
    [theme.breakpoints.down('xs')]: {
      padding: '0 24px 0 24px'
    }
  },
  titleIcon: {
    display: 'flex',
    padding: 9,
    backgroundColor: colorSet.errorLight,
    color: colorSet.errorMain,
    borderRadius: 8,
    marginRight: 18,
    [theme.breakpoints.down('xs')]: {
      marginRight: 11,
      padding: 7,
      '& .MuiSvgIcon-root': {
        fontSize: 18
      }
    }
  },
  subTitle: {
    [theme.breakpoints.down('xs')]: {
      marginTop: 12
    }
  },
  contents: {
    color: colorSet.gray700
  },
  btnWrapper: {
    display: 'flex',
    width: '100%',
    '& button': {
      width: 'calc(100% / 2)'
    },
    '& button + button': {
      marginLeft: 6
    }
  }
}));
