import { IBMDelete, IBMEdit, IBMShare, IBMView } from '@assets';
import { colorSet, useSnackbar } from '@components/Provider';
import { CapaConnectMyFile, CapaErrorCode } from '@generated/Common/graphql';
import { useRenameConnectFileMutation } from '@generated/Drive/MyDrive/graphql';
import useMyDrive from '@hooks/Drive/MyDrive/useMyDrive';
import useDashboard from '@hooks/useDashboard';
import useUserActionLog, {
  CapaActivityType,
  Enum_Action_Button_Enum
} from '@hooks/useUserActionLog';
import {
  Box,
  IconButton,
  SvgIcon,
  Tooltip,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { myDrivePathName } from '@routes/Common/types';
import FolderFormDialog from '@routes/Dashboard/Dialog/FolderForm/FolderForm';
import {
  SHARE_DIALOG_SCREEN,
  SHARE_FILES_TO_VALUE
} from '@routes/Dashboard/Dialog/Share/types';
import { DEFAULT_FOLDER_NAME } from '@routes/Dashboard/types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import FileDeleteDialogPresenter from '../Dialog/FileDeleteDialogPresenter';
import FileDownloadDialogPresenter from '../Dialog/FileDownloadDialogPresenter';
import MoreMenuPopup from '../MoreMenuPopup';
import {
  EnumDashboardDialogType,
  EnumDashboardListControlType,
  EnumListControl
} from '../types';
import { StyledDashboardBtnGroup } from './styles';

interface Props {
  files: Array<CapaConnectMyFile>;
  type: EnumDashboardListControlType;
  onDownloadFiles: (files?: Array<CapaConnectMyFile>) => Promise<void>;
  onDeleteFiles: (files?: Array<CapaConnectMyFile>) => Promise<void>;
  isShowMoreVertMenu?: boolean;
  isShowDownLoad?: boolean;
  isShowPublic?: boolean;
  isOpen?: boolean;
  isDetail?: boolean;
  isOpenFileViewer?: boolean;
  onShowFileViewer?: () => void;
  showViewerPDF?: () => void;
  onClickEvent?: (event: string) => void;
  isShare?: boolean;
  onSetCheckedFiles?: (files: any) => void;
  myDrivefiles?: any;
  isListSide?: boolean;
}

const DashboardListControlBtnPresenter: React.FC<Props> = ({
  isOpen,
  isDetail,
  isShowPublic,
  files,
  type,
  onDownloadFiles,
  onDeleteFiles,
  isShowMoreVertMenu,
  isShowDownLoad,
  onShowFileViewer,
  onClickEvent,
  showViewerPDF,
  isShare,
  isOpenFileViewer,
  onSetCheckedFiles,
  myDrivefiles,
  isListSide
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { onUpdateDashboard } = useDashboard();
  const [dialogState, setDialogState] = useState<{
    isOpen: boolean;
    dialogType?: EnumDashboardDialogType;
  }>({ isOpen: false });
  const { onInsertUserActionLogOne } = useUserActionLog();
  const [folderName, setFolderName] = useState<any>(DEFAULT_FOLDER_NAME);
  const [isShowHideFolderDialog, setShowHideFolderDialog] =
    useState<boolean>(false);
  const [renameConnectFile] = useRenameConnectFileMutation();
  const {
    onSetShowHideShareDialog,
    onSetShareDialogScreen,
    onSetShareFilesToType,
    onSetShowHideFileDetail,
    onSetRenameSuccessStatus,
    onRefetchConnectMyDrive
  } = useMyDrive();
  const [disbaledRenameSubmitButton, setDisbaledRenameSubmitButton] =
    useState(false);
  const isShowDeleteButton =
    (files &&
      files?.length > 0 &&
      !!files?.every((file: any) => !file.isFile)) ||
    (files &&
      files?.length > 1 &&
      !files?.every((file: any) => file.isFile) &&
      !files?.every((file: any) => !file.isFile));
  const { t } = useTranslation();

  const onOpenFolderDialog = () => {
    onInsertUserActionLogOne(
      Enum_Action_Button_Enum.Button_MyDrive_File_Rename,
      CapaActivityType.ButtonClick
    );
    const fileName = myDrivefiles?.filter(
      (file: any) => file.id === files[0].id
    )[0]?.fileName;
    setFolderName(fileName);
    setShowHideFolderDialog(true);
  };

  const pathnameUrl = history.location.pathname.split(`${myDrivePathName}/`);

  const onSubmit = () => {
    setDisbaledRenameSubmitButton(true);
    const oldFileName = myDrivefiles?.filter(
      (file: any) => file.id === files[0].id
    )[0]?.fileName;

    const payload: {
      fileId: string;
      toName: string;
    } = {
      fileId: files && files[0].id,
      toName: folderName
    };

    renameConnectFile({ variables: payload })
      .then((res) => {
        const result = res?.data?.renameConnectFile?.result;
        if (result === CapaErrorCode.Success) {
          enqueueSnackbar(
            t('snackbar.rename', {
              oldFileName: oldFileName,
              newFileName: folderName
            }),
            {
              variant: 'success'
            }
          );
          setShowHideFolderDialog(false);
          onSetRenameSuccessStatus({
            isRenameSuccess: true
          });
          onRefetchConnectMyDrive({
            pathId: pathnameUrl[1] ? pathnameUrl[1] : ''
          });
        }
      })
      .catch(() => {
        setShowHideFolderDialog(false);
        enqueueSnackbar(`Rename error`, {
          variant: 'error'
        });
      });
  };

  const onOpenDialogHandle = (dialogType: EnumDashboardDialogType) => {
    const message =
      dialogType === EnumDashboardDialogType.Download
        ? '다운로드'
        : dialogType === EnumDashboardDialogType.Delete
        ? '삭제'
        : '확인';
    if (files.length < 1) {
      enqueueSnackbar(`${message}하실 도면을 1개 이상 선택해주세요.`, {
        variant: 'error'
      });
    } else {
      if (dialogType === EnumDashboardDialogType.Viewer) {
        if (files.length > 30) {
          enqueueSnackbar(
            `한 번에 ${message} 가능한 도면의 개수는 최대 30개입니다.`,
            {
              variant: 'error'
            }
          );
        } else {
          onUpdateDashboard({ files: files });
          if (isMobile) {
            onShowFileViewer && onShowFileViewer();
          } else {
            showViewerPDF && showViewerPDF();
          }
        }
      } else {
        if (dialogType === EnumDashboardDialogType.Download) {
          onDownloadFilesHandle();
        }
        if (dialogType === EnumDashboardDialogType.Delete) {
          onDeleteFilesHandle();
        }
      }
    }
  };

  const onCloseDialogHandle = () => {
    setDialogState({ isOpen: false });
  };

  const onDownloadFilesHandle = async () => {
    setDialogState({ isOpen: false });
    await onDownloadFiles(files);
  };

  const onDeleteFilesHandle = async () => {
    if (onClickEvent) {
      onClickEvent(EnumListControl.DELETE);
    }
    await onDeleteFiles(files);
    onSetCheckedFiles && onSetCheckedFiles([]);
    onSetShowHideFileDetail({
      detailDrive: {}
    });
  };

  const onClickShare = () => {
    onInsertUserActionLogOne(
      Enum_Action_Button_Enum.Button_MyDrive_File_Share,
      CapaActivityType.ButtonClick
    );
    onSetShowHideShareDialog({ isShowHideShareDialog: true });
    onSetShareDialogScreen({
      shareDialogScreen: SHARE_DIALOG_SCREEN.SHARE
    });
    onSetShareFilesToType({
      shareFilesToType: SHARE_FILES_TO_VALUE.EMPTY
    });
  };

  return (
    <StyledDashboardBtnGroup>
      <FileDownloadDialogPresenter
        isOpen={
          dialogState.isOpen &&
          dialogState.dialogType === EnumDashboardDialogType.Download
        }
        count={files.length}
        onSubmit={onDownloadFilesHandle}
        onClose={onCloseDialogHandle}
      />
      <FileDeleteDialogPresenter
        isOpen={
          dialogState.isOpen &&
          dialogState.dialogType === EnumDashboardDialogType.Delete
        }
        count={files.length}
        onSubmit={onDeleteFilesHandle}
        onClose={onCloseDialogHandle}
      />
      <div className="btn-group">
        {files &&
          files?.length === 1 &&
          !!files?.every((file: any) => file.isFile) &&
          type === EnumDashboardListControlType.ListHeader &&
          isOpen && (
            <Tooltip title={t('tooltip.view') || 'View'}>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  borderRadius: '8px',
                  padding: '3px 6px',
                  width: '32px',
                  height: '32px',
                  backgroundColor: colorSet.primary500,
                  cursor: 'pointer'
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  onInsertUserActionLogOne(
                    Enum_Action_Button_Enum.Button_MyDrive_File_View,
                    CapaActivityType.ButtonClick
                  );
                  onOpenDialogHandle(EnumDashboardDialogType.Viewer);
                }}
              >
                <SvgIcon style={{ width: '24px', height: '24px' }}>
                  <IBMView color="#fff" />
                </SvgIcon>
              </Box>
            </Tooltip>
          )}
        {files &&
          files?.length > 0 &&
          !!files?.every((file: any) => file.isFile) &&
          type === EnumDashboardListControlType.ListHeader &&
          isShare && (
            <Tooltip title={t('tooltip.share_to_group') || 'Share to Group'}>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  borderRadius: '8px',
                  padding: '6px 0px 0 6px',
                  width: '32px',
                  height: '32px',
                  backgroundColor: colorSet.primary500,
                  cursor: 'pointer',
                  marginLeft: '8px'
                }}
                onClick={onClickShare}
              >
                <SvgIcon style={{ width: '22px', height: '22px' }}>
                  <IBMShare color="#fff" />
                </SvgIcon>
              </Box>
            </Tooltip>
          )}
        {files &&
          files?.length === 1 &&
          !!files?.every((file: any) => !file.isFile) &&
          !isMobile &&
          type === EnumDashboardListControlType.ListHeader && (
            <>
              <Tooltip
                onClick={onOpenFolderDialog}
                title={t('tooltip.rename') || 'Rename'}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  borderRadius: '8px',
                  padding: '6px 0px 0 6px',
                  width: '32px',
                  height: '32px',
                  backgroundColor: colorSet.primary500,
                  cursor: 'pointer'
                }}
              >
                <SvgIcon>
                  <IBMEdit color="#fff" />
                </SvgIcon>
              </Tooltip>
              <FolderFormDialog
                dialogInfo={{
                  title: 'Rename',
                  submitButtonName: 'Save'
                }}
                folderNameValue={folderName}
                isOpen={isShowHideFolderDialog}
                onClose={() => setShowHideFolderDialog(false)}
                setValue={setFolderName}
                onSubmit={onSubmit}
                checkedFiles={files}
                disabledSubmitButton={disbaledRenameSubmitButton}
                setDisbaledRenameSubmitButton={() =>
                  setDisbaledRenameSubmitButton(false)
                }
              />
            </>
          )}
        {isShowDeleteButton &&
          !isMobile &&
          type === EnumDashboardListControlType.ListHeader && (
            <Tooltip title={t('tooltip.delete') || 'Delete'}>
              <IconButton
                className="btn-group-item"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  onInsertUserActionLogOne(
                    Enum_Action_Button_Enum.Button_MyDrive_File_Delete,
                    CapaActivityType.ButtonClick
                  );
                  onOpenDialogHandle(EnumDashboardDialogType.Delete);
                }}
              >
                <img src={IBMDelete} alt="IBMDelete" />
              </IconButton>
            </Tooltip>
          )}
        {isShowMoreVertMenu && (
          <>
            <MoreMenuPopup
              isListSide={isListSide}
              onSetCheckedFiles={onSetCheckedFiles}
              onClickEvent={onClickEvent}
              onShowFileViewer={onShowFileViewer}
              onOpenViewer={() =>
                onOpenDialogHandle(EnumDashboardDialogType.Viewer)
              }
              files={files}
              isShowDownLoad={isShowDownLoad}
              isOpen={isOpen}
              isDetail={isDetail}
              isShare={isShare}
              isShowPublic={isShowPublic}
              isOpenFileViewer={isOpenFileViewer}
              onClick={(value) => {
                if (type === EnumDashboardListControlType.ListItem) {
                  onInsertUserActionLogOne(
                    Enum_Action_Button_Enum.Button_MyDrive_MoreMenu,
                    CapaActivityType.ButtonClick
                  );
                }
                onOpenDialogHandle(value);
              }}
              type={type}
              myDrivefiles={myDrivefiles}
            />
          </>
        )}
      </div>
    </StyledDashboardBtnGroup>
  );
};

export default DashboardListControlBtnPresenter;
