import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import useMyDrive from '@hooks/Drive/MyDrive/useMyDrive';
import { CustomizedButton } from '@components/Customized';

interface Props {
  onClose?: () => void;
  onSend?: () => void;
}

const RequestForDownloadPresenter = (props: Props) => {
  const { onClose, onSend } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const { myDrive } = useMyDrive();

  return (
    <Dialog
      classes={{
        paper: classes.paper
      }}
      open={myDrive.isShowHideRequestForDownload!}
      onClick={(e) => {
        e.stopPropagation();
      }}
      onClose={onClose}
    >
      <DialogContent classes={{ root: classes.content }}>
        <div className="title">{t('request_for_download_modal.title')}</div>
        <div className="content">{t('request_for_download_modal.content')}</div>
      </DialogContent>
      <DialogActions classes={{ root: classes.action }}>
        <div className={classes.wrapperBtn}>
          <CustomizedButton
            variant="contained"
            color="default"
            className="cancel-button"
            onClick={onClose}
          >
            {t('request_for_download_modal.cancel')}
          </CustomizedButton>
          <CustomizedButton
            type="submit"
            variant="contained"
            color="primary"
            onClick={onSend}
          >
            {t('request_for_download_modal.send')}
          </CustomizedButton>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default RequestForDownloadPresenter;
