import { makeStyles } from '@material-ui/core';
import { colorSet } from '@components/Provider';

export const viewerStyle = makeStyles((theme) => ({
  rootViewer: {
    '& .root-viewer': {
      display: 'none'
    },
    '& .open-modal': {
      width: '100%',
      height: '100vh',
      background: colorSet.backgroundDropViewer,
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: 1299,
      overflow: 'auto',
      display: 'block'
    },
    '& .hide-modal': {
      display: 'none'
    },
    '& .header-viewer': {
      pointerEvents: 'none',
      background: colorSet.backgrounLinearViewer,
      height: 52,
      zIndex: 9999,
      top: 0,
      width: '100%',
      left: 0,
      display: 'flex',
      alignItems: 'center',
      '& .text, .text-right': {
        flex: '25%',
        maxWidth: '25%',
        height: '100%',
        pointerEvents: 'auto'
      },
      '& .text-right': {
        marginRight: 16
      },

      [theme.breakpoints.down('xs')]: {
        padding: '12px 0'
      }
    },
    '& .viewer-pdf': {
      display: 'block',
      position: 'relative',
      zIndex: 2002,
      cursorEvent: 'none',
      width: '1000px',
      maxWidth: 'calc(100% - 100px)',
      margin: 'auto',
      height: 'calc(100vh - 53px)',
      '& .rpv-default-layout__sidebar': {
        display: 'none'
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        maxWidth: '100%',
        marginTop: 60
      },
      '& .rpv-core__doc-error': {
        display: 'none'
      }
    }
  },
  iconBackDrop: {
    position: 'fixed',
    top: 10,
    left: 12,
    zIndex: 99999,
    '& .MuiIconButton-root': {
      padding: '6px',
      color: `${colorSet.gray000}`,
      marginRight: 8,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'rgb(159 159 159 / 23%)'
      }
    },
    '& svg path': {
      fill: `${colorSet.gray000}`
    }
  },
  nameFile: {
    width: '100%',
    maxWidth: '100%',
    color: `${colorSet.gray000}`,
    fontWeight: 500,
    fontSize: 20,
    whiteSpace: 'nowrap',
    textAlign: 'center',
    pointerEvents: 'auto',
    justifyContent: 'center',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    lineHeight: '52px',
    [theme.breakpoints.down('xs')]: {
      fontSize: 16
    }
  },
  optionFile: {
    display: 'flex',
    alignItems: 'center',
    position: 'fixed',
    top: 10,
    zIndex: 99999,
    right: 15,
    '& .MuiIconButton-root': {
      padding: '6px',
      color: `${colorSet.gray000}`,
      marginRight: 8,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'rgb(159 159 159 / 23%)'
      }
    },
    '& svg path': {
      fill: `${colorSet.gray000}`
    },
    [theme.breakpoints.down('xs')]: {
      top: 7,
      right: 10
    }
  },
  imageViewerItem: {
    textAlign: 'center',
    width: '100vw',
    height: 'calc(100vh - 53px)',
    overflow: 'hidden',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
      height: '100vh',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center'
    }
  },
  wrapperClass: {
    overflow: 'inherit'
  },
  contentClass: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center'
  },
  fileViewer: {
    textAlign: 'center',
    width: '100vw',
    overflow: 'hidden',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    height: 'calc(100vh - 53px)'
  },
  imageViewer: {
    margin: 'auto',
    '& img': {
      maxWidth: 'calc(100vw - 130px)',
      maxHeight: 'calc(100vh - 130px)'
    }
  },
  noPreview: {
    marginTop: 100,
    background: `${colorSet.gray900}`,
    boxShadow:
      '0px 24px 24px -6px rgba(0, 0, 0, 0.2), 0px 24px 56px 4px rgba(0, 0, 0, 0.14), 0px 14px 56px 7px rgba(0, 0, 0, 0.12)',
    borderRadius: 12,
    width: 494,
    maxWidth: 'calc(100% - 30px)',
    padding: '40px',
    color: `${colorSet.gray000}`,
    margin: 'auto',
    textAlign: 'center',
    '& .title': {
      fontSize: 25,
      marginBottom: 32
    },
    '& .button': {
      textTransform: 'none',
      fontSize: 16
    }
  }
}));
