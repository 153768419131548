import React, { useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { CapaLogoBetaWhiteKo, CapaLogoBetaWhite } from '@assets';
import { colorSet, theme } from '@components/Provider';
import {
  contentHeaderHeight,
  contentMobileHeaderHeight
} from '../Common/types';
import StagingChipPresenter from '../Common/StagingChipPresenter';
import {
  AppBar,
  Toolbar,
  createStyles,
  makeStyles,
  useMediaQuery
} from '@material-ui/core';
import { TabsMenuWrapper } from './styles';
import useAuth from '@hooks/useAuth';
import TabsMenu from '@routes/Common/tabsHeader/TabsMenu';
import { BottomNavMenu } from '@routes/Common';
import FeedbackAndReport from '@routes/Common/Dialog/FeedbackAndReport';
import i18n from '@i18n/index';
import { LANGUAGE_VALUE } from '@routes/Common/ChangeLanguage/types';

export const useStylesLayout = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      overflow: 'hidden',
      '& .divider': {
        backgroundColor: colorSet.gray400
      }
    },
    appBar: {
      minHeight: contentHeaderHeight,
      [theme.breakpoints.down('sm')]: {
        minHeight: contentMobileHeaderHeight
      },
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      backgroundColor: colorSet.gray2000,
      color: colorSet.gray100
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
      minHeight: contentHeaderHeight,
      padding: '0px 24px',
      [theme.breakpoints.down('sm')]: {
        minHeight: contentMobileHeaderHeight,
        padding: '0px 16px'
      }
    },
    logo: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      '& img': {
        height: 26,
        [theme.breakpoints.down('sm')]: {
          height: 18
        }
      }
    },
    menuWrapper: {
      display: 'flex',
      alignItems: 'center'
    },
    content: {
      width: '100%',
      flexGrow: 1,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      minHeight: '100vh'
    },
    drawerHeader: {
      minHeight: contentHeaderHeight,
      [theme.breakpoints.down('sm')]: {
        minHeight: contentMobileHeaderHeight
      }
    }
  })
);

interface Props {
  GnbMenu?: React.ReactNode;
}

const LayoutPresenter: React.FC<Props> = ({ GnbMenu, children }) => {
  const history = useHistory();
  const classes = useStylesLayout();
  const isMobileXS = useMediaQuery(theme.breakpoints.down('xs'));
  const { isLoggedIn } = useAuth();
  const languageValue = i18n.language;
  const logo =
    languageValue === LANGUAGE_VALUE.KO
      ? CapaLogoBetaWhiteKo
      : CapaLogoBetaWhite;

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.root}>
      <AppBar elevation={0} position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.logo}>
            <img src={logo} alt="Logo" onClick={() => history.push('/')} />
            <StagingChipPresenter />
            {!isMobileXS && isLoggedIn && (
              <TabsMenuWrapper>
                <TabsMenu />
              </TabsMenuWrapper>
            )}
          </div>
          <div className={classes.menuWrapper}>{GnbMenu}</div>
        </Toolbar>
      </AppBar>
      <main className={classes.content}>
        <div className={classes.drawerHeader} />
        {children}
      </main>

      {isMobileXS && isLoggedIn && <BottomNavMenu />}
      <FeedbackAndReport></FeedbackAndReport>
    </div>
  );
};

export default LayoutPresenter;
