import React from 'react';
import { TDialogKind } from '../types';
import DialogTermsPresenter from './DialogTermsPresenter';

interface Props {
  isOpen: boolean;
  kind?: TDialogKind;
  onClose: () => void;
}

const DialogTermsContainer: React.FC<Props> = ({ isOpen, kind, onClose }) => {
  return <DialogTermsPresenter isOpen={isOpen} kind={kind} onClose={onClose} />;
};

export default DialogTermsContainer;
