import React, { useState } from 'react';
import {
  List,
  ListItem,
  Popper,
  Box,
  ClickAwayListener,
  makeStyles
} from '@material-ui/core';
import { colorSet } from '@components/Provider';
import { CustomizedTypography } from '@components/Customized';
import { useTranslation } from 'react-i18next';
import useCommon from '@hooks/Common/useCommon';
import { Tooltip } from '@material-ui/core';
import { ReactComponent as IBMRequestQuote } from '../../../assets/icon/IBMRequestQuote.svg';
import { ReactComponent as IBMInformation } from '../../../assets/icon/IBMInformation.svg';
import { ReactComponent as IBMWarningHex } from '../../../assets/icon/IBMWarningHex.svg';

const useStyles = makeStyles({
  wrapper: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    padding: 14,
    zIndex: 2,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: colorSet.gray200
    }
  },
  root: {
    zIndex: 9999,
    top: '-25px !important',
    right: '15px !important'
  },
  boxRoot: {
    zIndex: 9999,
    minWidth: 256,
    height: '100%',
    borderRadius: 8,
    padding: '8px 0',
    backgroundColor: colorSet.gray000,
    boxShadow:
      '0px 8px 16px 2px rgba(97, 97, 97, 0.1), 0px 16px 32px 2px rgba(97, 97, 97, 0.1)',

    '& .MuiTypography-colorTextPrimary': {
      display: 'flex',
      alignItems: 'center',
      width: '100%'
    },
    '& .icon': {
      marginRight: 12,
      '& svg path': {
        fill: colorSet.gray2000
      }
    }
  },
  list: {
    padding: 0
  },
  listItem: {
    padding: '8px 16px',
    '& .customized-typography': {
      fontWeight: 500
    },
    '&.logout-btn': {
      textAlign: 'center',
      '& .MuiTypography-body2': {
        width: '100%'
      }
    }
  },
  menuIcon: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '& .MuiAvatar-circle': {
      width: 32,
      height: 32,
      color: colorSet.gray000,
      '&.MuiAvatar-colorDefault': {
        backgroundColor: 'transparent',
        '& img': {
          width: '100%',
          height: '100%'
        }
      }
    }
  },
  divider: {
    backgroundColor: colorSet.gray200,
    margin: '10px 8px'
  },
  helpIcon: {}
});

function HelpItem() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { openFeedbackDialog } = useCommon();

  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const onClose = () => setOpen(false);

  return (
    <div
      className={classes.wrapper}
      onClick={(e) => {
        if (e.currentTarget) {
          setAnchorEl(e.currentTarget);
          setOpen((prevOpen) => !prevOpen);
        }
      }}
    >
      <div className={classes.helpIcon}>
        <Tooltip
          title={t('tooltip.help') || 'Feedback & Issues'}
          placement="right"
        >
          <IBMInformation />
        </Tooltip>
      </div>

      <Popper
        className={classes.root}
        open={open}
        anchorEl={anchorEl}
        container={document.body}
        transition
        disablePortal
        placement="right-end"
      >
        <ClickAwayListener onClickAway={onClose}>
          <Box className={classes.boxRoot}>
            <List className={classes.list}>
              <ListItem
                className={classes.listItem}
                button
                onClick={() => {
                  openFeedbackDialog({
                    isOpenFeedback: true,
                    isFeedback: true
                  });
                  onClose();
                }}
              >
                <CustomizedTypography variant="body2">
                  <IBMRequestQuote className="icon" />
                  {t('gnb.give_feedback')}
                </CustomizedTypography>
              </ListItem>
              <ListItem
                className={classes.listItem}
                button
                onClick={() => {
                  openFeedbackDialog({
                    isOpenFeedback: true,
                    isFeedback: false
                  });
                  onClose();
                }}
              >
                <CustomizedTypography variant="body2">
                  <IBMWarningHex className="icon" />
                  {t('gnb.report_issues')}
                </CustomizedTypography>
              </ListItem>
            </List>
          </Box>
        </ClickAwayListener>
      </Popper>
    </div>
  );
}

export default HelpItem;
