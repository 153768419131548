import { makeStyles } from '@material-ui/core';
import { colorSet } from '@components/Provider';

export const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: 424,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: 0,
      height: '100%',
      maxHeight: '100%',
      maxWidth: '100%',
      borderRadius: 0
    },
    maxHeight: 512,
    borderRadius: 16
  },
  rootDialog: {
    zIndex: '10000 !important' as unknown as 10000
  },
  rootTitle: {
    padding: '8px 16px',
    marginLeft: 'auto !important'
  },
  logOutButton: {
    color: colorSet.gray900,
    backgroundColor: colorSet.gray000,
    border: `1px solid ${colorSet.gray600}`,
    borderRadius: 8,
    textTransform: 'none',
    minWidth: '88px !important',
    '&:hover': {
      color: colorSet.gray900,
      backgroundColor: colorSet.gray000,
      border: `1px solid ${colorSet.gray600}`,
      borderRadius: 8,
      textTransform: 'none'
    }
  },
  arrowBackIosOutlinedIcon: {
    width: 14,
    color: colorSet.gray100,
    [theme.breakpoints.down('xs')]: {
      color: colorSet.gray900,
      fontWeight: 500,
      position: 'absolute',
      left: 16,
      top: 10
    }
  },
  closeIcon: {
    color: colorSet.gray900,
    fontWeight: 500,
    position: 'absolute',
    left: 16,
    top: 10
  },
  customTitle: {
    [theme.breakpoints.down('xs')]: {
      color: colorSet.gray900,
      fontWeight: 500
    }
  },
  cancelBtn: {
    fontSize: 24,
    marginLeft: 'auto',
    cursor: 'pointer'
  },
  title: {
    fontWeight: 400,
    fontSize: 25,
    lineHeight: '160%',
    letterSpacing: '-0.1px',
    marginBottom: 16
  },
  content: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '143%',
    letterSpacing: '-0.1px'
  },
  action: {
    padding: '0px !important'
  },
  wrapperBtn: {
    display: 'flex',
    padding: '0 32px 32px 32px',
    '& button': {
      height: '48px !important',
      width: '100%',
      textTransform: 'none'
    },
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      padding: 16,
      borderTop: `1px solid ${colorSet.gray400}`
    },
    '& .Mui-disabled': {
      background: `${colorSet.gray200} !important`,
      color: `${colorSet.gray500} !important`
    }
  },
  csDialogContent: {
    fontSize: 14,
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: {
      padding: '12px 16px 0 16px',
      margin: 0
    },
    '&:first-child': {
      padding: '32px 32px 0 32px'
    },
    '& li': {
      paddingLeft: 12,
      '&::marker': {
        fontSize: 11
      }
    },
    '& .MuiOutlinedInput-inputMarginDense': {
      paddingTop: 14.5,
      paddingBottom: 14.5
    }
  },
  name: {
    marginTop: 44,
    marginBottom: 10,
    fontSize: 13,
    lineHeight: '138%',
    letterSpacing: '-0.1px',
    color: colorSet.gray900
  },
  validMaxLength: {
    color: colorSet.errorMain,
    marginTop: 4,
    fontSize: 12,
    lineHeight: '150%',
    letterSpacing: '-0.1px'
  },
  error: {
    '& .MuiOutlinedInput-root': {
      border: `1px solid ${colorSet.errorMain}`
    },
    '& fieldset': {
      border: 'none !important'
    }
  },
  checkboxWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 28,
    marginBottom: 41,
    '& .mr-4': {
      marginRight: 4
    }
  },
  doneIcon: {
    width: 20.5,
    height: 20.5,
    marginRight: 14.5,
    cursor: 'pointer'
  },
  activeColorDoneIcon: {
    color: colorSet.primary450
  },
  colorDoneIcon: {
    color: colorSet.gray600
  },
  image: {
    marginBottom: 16
  },
  checkbox: {
    marginTop: 12,
    marginRight: 4,
    '& .MuiFormControlLabel-label': {
      fontSize: 14,
      color: colorSet.gray700
    }
  },
  policiesText: {
    textDecorationLine: 'underline',
    cursor: 'pointer',
    fontSize: 13
  },
  connectPoliciesText: {
    textDecorationLine: 'underline',
    cursor: 'pointer',
    fontSize: 13,
    marginRight: 4
  },
  iAgreeTo: {
    fontSize: 13
  },
  marginBottomText: {
    marginBottom: 8
  },
  firstTitle: {
    fontWeight: 500,
    marginRight: 6
  }
}));
