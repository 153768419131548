const imageRegex = /(.*?)\.(png|jpg|jpeg|gif|bmp)$/;
const cncRegex = /(.*?)\.(dwg|dxf|stp|step)$/;
const printingRegex =
  /(.*?)\.(obj|skp|stl|sat|sab|ipt|session|dlv|exp|catpart|cgr|dae|neu|prt|xpr|fbx|gltf|glb|ifc|igs|iges|jt|x_b|x_t|rvt|3dm|par|psm|sldprt|stp|step)$/;
const moldingRegex = /(.*?)\.(stp|step)$/;
const sheetMetalRegex = /(.*?)\.(dwg|dxf)$/;
const castingRegex = /(.*?)\.(dwg|dxf)$/;
const emailRegex =
  /[-A-Za-z0-9_]+[-A-Za-z0-9_.]*[@]{1}[-A-Za-z0-9_]+[-A-Za-z0-9_.]*[.]{1}[A-Za-z]{1,5}/;
const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[0-9])(\W*).{8,}$/;
const inputNumberRegex = /^[1-9][0-9]*$/; //앞자리 0 제외
const phoneRegex = /^[0-9]*$/;
const numberRegex = /[^0]/; //앞자리 0 포함
const hyperlinkRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
const nameRegex = /^[a-zA-Zㄱ-ㅎㅏ-ㅣ가-힣]*$/;

const fileExtensionRegex = {
  cnc: /(.*?)\.(dwg|dxf|stp|step)$/,
  printing:
    /(.*?)\.(obj|skp|stl|sat|sab|ipt|session|dlv|exp|catpart|cgr|dae|neu|prt|xpr|fbx|gltf|glb|ifc|igs|iges|jt|x_b|x_t|rvt|3dm|par|psm|sldprt|stp|step)$/,
  molding: /(.*?)\.(stp|step)$/,
  sheetMetal: /(.*?)\.(dwg|dxf)$/,
  casting: /(.*?)\.(dwg|dxf)$/,
  doc: /(.*?)\.(doc|pdf|docx|ppt|pptx|hwp|xls|xlsx|txt|zip)$/,
  video: /(.*?)\.(mp4|mov|avi)$/
};

export {
  nameRegex,
  imageRegex,
  cncRegex,
  printingRegex,
  moldingRegex,
  sheetMetalRegex,
  castingRegex,
  emailRegex,
  passwordRegex,
  inputNumberRegex,
  phoneRegex,
  numberRegex,
  fileExtensionRegex,
  hyperlinkRegex
};
