import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  avatarWrapper: {
    marginRight: 8
  },
  checkboxRoot: {
    padding: 6,
    [theme.breakpoints.down('xs')]: {
      padding: '10px 12px'
    }
  }
}));
