import React from 'react';
import BottomNavMenuPresenter from './BottomNavMenuPresenter';

interface Props {
  callback?: () => void;
}

const BottomNavMenuContainer: React.FC<Props> = ({ callback }) => {
  return <BottomNavMenuPresenter callback={callback} />;
};

export default BottomNavMenuContainer;
