import React from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { CapaLogoBlueImg } from '@assets';
import { STORAGE_ACTION, getStorageItem } from '@utils/storage';
import { CustomizedTypography, CustomizedButton } from '@components/Customized';
import { Inputs } from './LoginPresenter';
import LoginFooterPresenter from './LoginFooterPresenter';
import { TextField } from '@material-ui/core';
import { MuiContainer, MuiPaper } from './styles';
import { useTranslation } from 'react-i18next';

interface Props {
  onUpdate: (req: Inputs) => void;
  onClick: () => void;
}

const ResetPasswordPresenter: React.FC<Props> = ({ onUpdate, onClick }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const isLoggedIn = getStorageItem(STORAGE_ACTION.LOGGED_IN);
  const userInfo = getStorageItem(STORAGE_ACTION.USER_INFO);
  const { register, handleSubmit, errors } = useForm<Inputs>();
  const handleOnSubmit = (inputs: Inputs) => onUpdate(inputs);

  const validationForm = {
    email: {
      required: { value: true, message: t('sign_up.required_email') },
      pattern: {
        value:
          /[-A-Za-z0-9_]+[-A-Za-z0-9_.]*[@]{1}[-A-Za-z0-9_]+[-A-Za-z0-9_.]*[.]{1}[A-Za-z]{1,5}/,
        message: t('sign_up.valid_email')
      }
    },
    password: {
      required: {
        value: true,
        message: t('sign_up.required_password')
      }
    },
    newPassword: {
      required: {
        value: true,
        message: '신규 비밀번호를 입력해 주세요.'
      },
      pattern: {
        value: /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,}$/,
        message:
          '비밀번호는 영문+숫자 조합으로  8글자 이상 16글자 이하로 입력해주세요.'
      }
    }
  };

  return (
    <MuiContainer>
      <img src={CapaLogoBlueImg} alt="Logo" onClick={onClick} />
      <MuiPaper elevation={0}>
        <CustomizedTypography variant="h4" fontWeight="bold" gutterBottoms={32}>
          비밀번호 변경하기
        </CustomizedTypography>
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <CustomizedTypography variant="body1" align="left" gutterBottoms={8}>
            이메일*
          </CustomizedTypography>
          <TextField
            disabled
            placeholder="이메일"
            variant="outlined"
            error={errors.email ? true : false}
            helperText={errors.email && errors.email.message}
            inputProps={{
              name: 'email',
              ref: register(validationForm.email)
            }}
            defaultValue={
              isLoggedIn === 'true' && userInfo && userInfo.email
                ? userInfo.email
                : ''
            }
            size="small"
            fullWidth
          />
          <CustomizedTypography variant="body1" align="left" gutterBottoms={8}>
            현재 비밀번호 *
          </CustomizedTypography>
          <TextField
            type={'password'}
            placeholder="현재 비밀번호"
            variant="outlined"
            error={errors.password ? true : false}
            helperText={errors.password && errors.password.message}
            inputProps={{
              name: 'password',
              ref: register(validationForm.password)
            }}
            size="small"
            fullWidth
          />
          <CustomizedTypography variant="body1" align="left" gutterBottoms={8}>
            신규 비밀번호 *
          </CustomizedTypography>
          <TextField
            type={'password'}
            placeholder="신규 비밀번호 (영문, 숫자 조합 8자 이상)"
            variant="outlined"
            error={errors.newPassword ? true : false}
            helperText={errors.newPassword && errors.newPassword.message}
            inputProps={{
              name: 'newPassword',
              ref: register(validationForm.newPassword)
            }}
            size="small"
            fullWidth
          />
          <CustomizedButton
            type="submit"
            color="primary"
            variant="contained"
            size="large"
            fullWidth
          >
            비밀번호 변경
          </CustomizedButton>
        </form>
      </MuiPaper>
      <div className="text-btn-group">
        <CustomizedTypography
          variant="body2"
          color="primary"
          fontWeight="500"
          onClick={() => history.push('/login')}
        >
          로그인 화면 가기
        </CustomizedTypography>
      </div>
      <LoginFooterPresenter />
    </MuiContainer>
  );
};

export default ResetPasswordPresenter;
