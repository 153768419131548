import { colorSet } from '@components/Provider';
import styled from 'styled-components';

export const StyledDashboardBtnGroup = styled.div`
  margin-left: auto;
  .btn-group {
    display: flex;
    align-items: center;
    color: ${colorSet.gray700};

    .btn-group-item {
      cursor: pointer;
      margin-right: 8px;
      padding: 6px;
      &:hover {
        background-color: rgb(159 159 159 / 23%);
      }
      ${({ theme }) => `
        ${theme.breakpoints.down('xs')} {
          padding: 12px;
        }
      `}
      .MuiIconButton-root {
        padding: 0;
      }
      &.mr-0 {
        margin-right: 0;
      }
    }

    ${({ theme }) => `
      ${theme.breakpoints.down('xs')} {
        .sx-none {
          display: none;
        }
      }
    `}
  }
`;
