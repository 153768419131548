import React from 'react';
import { TDialogKind } from '../types';
import {
  PrivacyPolicyAgreement,
  TermsOfServiceAgreement,
  ConsentForMarketing,
  ConsentToConnect
} from './AgreementPresenter';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  paper: {
    maxWidth: '490px',
    height: '535px',
    padding: 24,
    paddingBottom: 0,
    borderRadius: 16
  },
  title: {
    padding: 0,
    '& .MuiTypography-root': {
      fontSize: '22px'
    }
  },
  content: {
    height: 415,
    padding: '32px 0px',
    '& .agreement-content': {
      maxHeight: '100%',
      whiteSpace: 'pre-line',
      overflowX: 'hidden',
      overflowY: 'scroll',
      '& .date': {
        textAlign: 'right',
        fontSize: 12
      },
      '& .title': {
        wordBreak: 'break-all',
        fontSize: '20px',
        fontWeight: 'bold',
        lineHeight: '32px'
      },
      '& .subTitle': {
        wordBreak: 'break-all',
        fontSize: '14px',
        fontWeight: 'bold',
        lineHeight: '20px'
      },
      '& .content': {
        wordBreak: 'break-all',
        fontSize: '12px',
        lineHeight: '20px'
      }
    }
  },
  divider: {
    marginTop: '32px'
  }
}));

interface Props {
  isOpen: boolean;
  kind?: TDialogKind;
  onClose: () => void;
}

const DialogTermsPresenter: React.FC<Props> = ({ isOpen, kind, onClose }) => {
  const classes = useStyles();
  const dialogTitle =
    kind === 'privacyAgree'
      ? '개인정보처리방침'
      : kind === 'consentAgree'
      ? '캐파 커넥트 회원 이용약관'
      : kind === 'serviceAgree'
      ? '서비스 이용약관'
      : '마케팅정보수신동의';

  const dialogContent =
    kind === 'serviceAgree' ? (
      <TermsOfServiceAgreement />
    ) : kind === 'consentAgree' ? (
      <ConsentToConnect />
    ) : kind === 'privacyAgree' ? (
      <PrivacyPolicyAgreement />
    ) : (
      <ConsentForMarketing />
    );

  return (
    <Dialog onClose={onClose} open={isOpen} classes={{ paper: classes.paper }}>
      <DialogTitle classes={{ root: classes.title }}>{dialogTitle}</DialogTitle>
      <DialogContent classes={{ root: classes.content }}>
        {dialogContent}
      </DialogContent>
    </Dialog>
  );
};

export default DialogTermsPresenter;
