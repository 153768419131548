const AppConfig = () => {
  const env = process.env.NODE_ENV;
  const mode = process.env.REACT_APP_MODE;
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const graphqlUrl = process.env.REACT_APP_GRAPHQL_URL;
  const capaViewerUrl = process.env.REACT_APP_VIEWER_URL;
  const capaViewerApi = process.env.REACT_APP_VIEWER_API;
  const fileApiUrl = process.env.REACT_APP_FILE_API_URL;
  const clientUrl = process.env.REACT_APP_CLIENT_URL;
  const partnersUrl = process.env.REACT_APP_PARTNERS_URL;
  const downloadIOSUrl = process.env.REACT_APP_DOWNLOAD_IOS_URL;
  const downloadAndroidUrl = process.env.REACT_APP_DOWNLOAD_ANDROID_URL;
  const capaConnectsServicePoliciesUrl =
    process.env.REACT_APP_CAPA_CONNECTS_SERVICE_POLICIES_URL;
  const facebookUrl = process.env.REACT_APP_FACEBOOK_URL;
  const instagramUrl = process.env.REACT_APP_INSTAGRAM_URL;
  const linkedInUrl = process.env.REACT_APP_LINKEDIN_URL;
  const capaBlogUrl = process.env.REACT_APP_CAPA_BLOG_URL;
  const capaConnectUrl = process.env.REACT_APP_CAPA_CONNECT_URL;
  const capaCareerUrl = process.env.REACT_APP_CAPA_CAREER_URL;
  const privacyUrl = process.env.REACT_APP_PRIVACY_URL;
  const marketingUrl = process.env.REACT_APP_MARKETING_URL;
  const officeIframeUrl = process.env.REACT_APP_OFFICE_IFRAME_URL;

  return {
    env,
    mode,
    baseUrl,
    graphqlUrl,
    capaViewerUrl,
    capaViewerApi,
    fileApiUrl,
    clientUrl,
    partnersUrl,
    downloadIOSUrl,
    downloadAndroidUrl,
    capaConnectsServicePoliciesUrl,
    facebookUrl,
    instagramUrl,
    linkedInUrl,
    capaBlogUrl,
    capaConnectUrl,
    capaCareerUrl,
    privacyUrl,
    marketingUrl,
    officeIframeUrl
  };
};

export default AppConfig();
