import React from 'react';
import { useStyles } from './styles';
import { colorSet } from '@components/Provider';
import {
  stringAvatar,
  stringToColor
} from '@routes/Dashboard/Dialog/Share/utils';
import { Avatar, Tooltip } from '@material-ui/core';
import { getDisplayedAvatarString } from '@utils/common';
import { CapaPerson } from '@assets';

interface Props {
  data?: any;
  isChecked?: boolean;
}

const CapaAvatar = (props: Props) => {
  const { data, isChecked } = props;
  const classes = useStyles();

  if (!data) return <></>;

  const borderStyles =
    isChecked && (data?.length === 2 || data?.length === 3)
      ? `2px solid ${colorSet.gray100}`
      : `2px solid ${colorSet.gray000}`;

  return (
    <div
      className={
        data?.length === 2 || data?.length === 3
          ? classes.avatarWrapper
          : data?.length > 3
          ? classes.moreThanFourMembersWrapper
          : classes.avatarWrapper
      }
    >
      {data?.map((item: any, index: number) => {
        const backgroundColor = item.isCapaUser
          ? stringToColor(
              getDisplayedAvatarString(item.connectUserName, item.email)
            )
          : colorSet.gray300;

        return (
          <Tooltip title={item.connectUserName ?? item.email}>
            <Avatar
              key={index}
              {...stringAvatar(
                getDisplayedAvatarString(item.connectUserName, item.email)
              )}
              className={
                data?.length === 1
                  ? classes.oneMember
                  : data?.length === 2
                  ? classes.twoMembers
                  : data?.length === 3
                  ? classes.threeMembers
                  : data?.length > 3
                  ? classes.moreThanFourMembers
                  : ''
              }
              style={{
                backgroundColor: backgroundColor,
                border: borderStyles
              }}
            >
              {item?.isCapaUser ? (
                {
                  ...stringAvatar(
                    getDisplayedAvatarString(item.connectUserName, item.email)
                  )
                }.children
              ) : (
                <img
                  src={CapaPerson}
                  className={
                    data?.length === 1
                      ? classes.onePersonIcon
                      : data?.length === 2
                      ? classes.twoPersonIcon
                      : classes.personIcon
                  }
                  alt="capaPerson"
                />
              )}
            </Avatar>
          </Tooltip>
        );
      })}
    </div>
  );
};

export default CapaAvatar;
