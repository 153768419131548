import React from 'react';
import { useHistory } from 'react-router-dom';
import { CapaLogo } from '@assets';
import { formatFileSize } from '@utils/formatter';
import useAuth from '@hooks/useAuth';
import { CustomizedTypography } from '@components/Customized';
import { colorSet } from '@components/Provider';
import { AsideMenu } from './types';
import GnbCheckerBoardMenu from '../GnbCheckerBoardMenu';
import {
  Drawer,
  Divider,
  LinearProgress,
  makeStyles,
  createStyles
} from '@material-ui/core';
import {
  HomeOutlined,
  InboxOutlined,
  AccountCircleOutlined,
  CloudOutlined,
  Close
} from '@material-ui/icons';
import { myDrivePathName } from '@routes/Common/types';

const useStylesAsideMenu = makeStyles(() =>
  createStyles({
    drawer: {
      flexShrink: 0
    },
    drawerPaper: {
      width: 284,
      minHeight: '100vh'
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: 284,
      position: 'fixed',
      top: 0,
      padding: '20px 16px',
      borderBottom: `1px solid ${colorSet.gray400}`,
      '& img': {
        height: 16
      },
      '& .extra-btn': {
        display: 'flex',
        alignItems: 'center',
        '& .cancel-btn': { marginLeft: 8, cursor: 'pointer' }
      }
    },
    content: {
      marginTop: 56,
      padding: '20px 0px'
    },
    MenuItem: {
      padding: '10px 16px',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      '& .MuiSvgIcon-root': {
        marginRight: 16
      }
    },
    progress: {
      padding: '0px 16px',
      '& .progress-partial': {
        display: 'flex',
        alignItems: 'center',
        '& .MuiSvgIcon-root': {
          marginRight: 16
        }
      },
      '& .MuiLinearProgress-root': {
        margin: '16px 0px 10px 40px',
        width: 'calc(100% - 40px)',
        backgroundColor: colorSet.gray400,
        borderRadius: 4,
        height: 6
      },
      '& .status': {
        marginLeft: 40,
        color: colorSet.gray700
      }
    },
    divider: {
      margin: '32px 0px',
      backgroundColor: colorSet.gray400
    }
  })
);

interface Props {
  open: boolean;
  onClose: () => void;
}
const MobileAsideMenuPresenter: React.FC<Props> = ({ open, onClose }) => {
  const history = useHistory();
  const classes = useStylesAsideMenu();
  const { userState } = useAuth();
  const driveSize = userState.data?.driveSize ?? 0;
  const driveSizeUnit = formatFileSize(driveSize).toUpperCase();
  const usedSize = userState.data?.usedSize ?? 0;
  const usedSizeUnit =
    usedSize === 0 ? '0바이트' : formatFileSize(usedSize).toUpperCase();
  const progressCount = Math.ceil((usedSize / driveSize) * 100);

  const AsideMenuList: Array<AsideMenu> = [
    {
      url: '/',
      icon: <HomeOutlined />,
      label: '홈'
    },
    {
      url: myDrivePathName,
      icon: <InboxOutlined />,
      label: '내 드라이브'
    },
    {
      url: '/mypage',
      icon: <AccountCircleOutlined />,
      label: '내 정보'
    }
  ];

  const onClickHandle = (url: string) => {
    onClose();
    history.push(url);
  };

  return (
    <Drawer
      className={classes.drawer}
      variant="temporary"
      anchor="left"
      open={open}
      classes={{
        paper: classes.drawerPaper
      }}
      onClose={onClose}
    >
      <div className={classes.drawerHeader}>
        <img src={CapaLogo} alt="logo" />
        <div className="extra-btn">
          <GnbCheckerBoardMenu />
          <Close className="cancel-btn" onClick={onClose} />
        </div>
      </div>
      <div className={classes.content}></div>
      {AsideMenuList.map((item, index) => {
        return (
          <div
            key={index}
            className={classes.MenuItem}
            onClick={() => onClickHandle(item.url)}
          >
            {item.icon}
            <CustomizedTypography variant="body1" fontWeight="500">
              {item.label}
            </CustomizedTypography>
          </div>
        );
      })}
      <Divider className={classes.divider} />
      <div className={classes.progress}>
        <div className="progress-partial">
          <CloudOutlined />
          <CustomizedTypography variant="body1" fontWeight="500">
            저장용량
          </CustomizedTypography>
        </div>
        <LinearProgress variant="determinate" value={progressCount} />
        <CustomizedTypography className="status" variant="body2">
          {driveSizeUnit} 중 {usedSizeUnit} 사용
        </CustomizedTypography>
      </div>
    </Drawer>
  );
};

export default MobileAsideMenuPresenter;
