import AppConfig from '@config/index';
import { formatDate } from './formatter';

export const isServiceNoticeCheck = () => {
  const isStaging = AppConfig.baseUrl?.includes('staging') ? true : false;
  const isNonZero = true;
  const toDayDate = new Date();
  const startDateStr = '2021/09/01 18:00:00';
  const endDateStr = '2021/09/01 19:30:00';
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);
  const yyyymmdd = formatDate(startDateStr, 'YYYY년 MM월 DD일', true);
  const isOpenPreNotice = false;
  const isOpenUrgentNotice = false;
  const isOpenUpdateNotice = false;
  const isOpen =
    startDate.getTime() - toDayDate.getTime() < 0 &&
    endDate.getTime() - toDayDate.getTime() > 0;
  let startDateForPM = '';
  let endDateForPM = '';

  if (startDate.getHours() < 12) {
    if (startDate.getHours() === 0) {
      startDateForPM = formatDate(startDateStr, '오전 12:mm', isNonZero);
    } else {
      startDateForPM = formatDate(startDateStr, '오전 HH:mm', isNonZero);
    }
  } else {
    if (startDate.getHours() === 12) {
      startDateForPM = formatDate(startDateStr, '오후 12:mm', isNonZero);
    } else {
      const timeValue = startDate;
      timeValue.setHours(timeValue.getHours() - 12);
      startDateForPM = formatDate(
        timeValue.toString(),
        '오후 HH:mm',
        isNonZero
      );
    }
  }

  if (endDate.getHours() < 12) {
    if (endDate.getHours() === 0) {
      endDateForPM = formatDate(endDateStr, '오전 12:mm', isNonZero);
    } else {
      endDateForPM = formatDate(endDateStr, '오전 HH:mm', isNonZero);
    }
  } else {
    if (endDate.getHours() === 12) {
      endDateForPM = formatDate(endDateStr, '오후 12:mm', isNonZero);
    } else {
      const timeValue = endDate;
      timeValue.setHours(timeValue.getHours() - 12);
      endDateForPM = formatDate(timeValue.toString(), '오후 HH:mm', isNonZero);
    }
  }

  return {
    yyyymmdd: yyyymmdd,
    startDate: startDateForPM,
    endDate: endDateForPM,
    isOpenPreNotice: isOpenPreNotice,
    isOpenUpdateNotice: isOpenUpdateNotice && isOpen && !isStaging,
    isOpenUrgentNotice: isOpenUrgentNotice
  };
};
