import { colorSet, theme } from '@components/Provider';
import { contentHeaderHeight, contentMobileHeaderHeight } from '../types';
import { createStyles, makeStyles } from '@material-ui/core';

export const useStylesGnbAppBar = makeStyles(() =>
  createStyles({
    appBar: {
      minHeight: contentHeaderHeight,
      zIndex: theme.zIndex.drawer + 2,
      [theme.breakpoints.down('xs')]: {
        zIndex: theme.zIndex.drawer - 1,
        minHeight: contentMobileHeaderHeight
      },
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      backgroundColor: colorSet.gray000
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
      minHeight: contentHeaderHeight,
      [theme.breakpoints.down('xs')]: {
        minHeight: contentMobileHeaderHeight,
        borderBottom: 'none'
      },
      borderBottom: `1px solid ${colorSet.gray200}`,
      padding: '0px 16px'
    },
    logo: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      '& img': {
        width: 249,
        height: 26,
        [theme.breakpoints.down('xs')]: {
          height: 18,
          width: 147
        }
      }
    },
    menuTitle: {
      fontSize: 20,
      fontWeight: 'bold',
      width: '100%'
    },
    menuWrapper: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        justifyContent: 'space-between'
      }
    },
    logoMobile: {
      height: 26
    },
    accountQuestionText: {
      color: colorSet.gray600,
      fontSize: 16,
      lineHeight: '150%',
      letterSpacing: '-0.1px',
      marginRight: 16,
      marginLeft: 14
    },
    logInButton: {
      color: colorSet.gray900,
      backgroundColor: colorSet.gray000,
      border: `1px solid ${colorSet.gray600}`,
      borderRadius: 8,
      textTransform: 'none',
      '&:hover': {
        color: colorSet.gray900,
        backgroundColor: colorSet.gray000,
        border: `1px solid ${colorSet.gray600}`,
        borderRadius: 8,
        textTransform: 'none'
      }
    },
    notificationBar: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'space-between'
    },
    notificationTitle: {
      fontSize: 16,
      fontWeight: 500
    },
    contactsTitle: {
      fontSize: 20,
      fontWeight: 700,
      color: colorSet.gray900
    }
  })
);
