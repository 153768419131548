import React, { useEffect, useRef, useState } from 'react';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Chip,
  Popover,
  Typography,
  useMediaQuery,
  Tooltip
} from '@material-ui/core';
import { CustomizedButton } from '@components/Customized';
import { useStyles } from './styles';
import {
  KeyboardArrowLeft,
  KeyboardArrowDownOutlined,
  CheckOutlined,
  ArrowBackIosOutlined
} from '@material-ui/icons';
import useGroup from '@hooks/Group/useGroup';
import {
  DEFAULT_SELECTED_MEMBER,
  ACCESS_LEVEL_VALUE,
  ACCESS_LEVEL_NAME,
  ACCESS_LEVEL_OPTIONS
} from '@routes/Dashboard/Dialog/Share/types';
import { CREATE_GROUP_SCREEN } from '@routes/Group/Dialog/types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { theme, useSnackbar } from '@components/Provider';
import {
  CapaErrorCode,
  useCreateGroupNameMutation,
  useInviteGroupMembersNameMutation
} from '@generated/Common/graphql';
import { CapaActivityType } from '@generated/Common/types';
import {
  CheckRegisteredCapaService,
  SearchUser,
  useShareFilesToGroupMutation
} from '@generated/Drive/MyDrive/graphql';
import { checkShareTimes } from '@routes/Dashboard/Dialog/Share/utils';
import useMyDrive from '@hooks/Drive/MyDrive/useMyDrive';
import { useTranslation } from 'react-i18next';
import { LANGUAGE_VALUE } from '@routes/Common/ChangeLanguage/types';
import { UPLOAD_FILE_TAB } from '@routes/Common/Dialog/UploadFiles/types';
import { newFileUploadApi } from '@api/index';
import useDebounce from '@hooks/useDebounce';
import OverflowTip from '@components/OverflowTooltips';
import {
  AUTOCOMPLETE_REASON,
  DEFAULT_ITEMS_MAX,
  DEFAULT_ITEMS_OFFSET
} from '@routes/Common/types';
import * as Apollo from '@apollo/client';
import useCommon from '@hooks/Common/useCommon';
import useUserActionLog, {
  Enum_Action_Button_Enum,
  Enum_Action_Input_Enum,
  Enum_Action_Page_Enum
} from '@hooks/useUserActionLog';
import { ADD_FILES_FROM_VALUE } from '@routes/Common/Dialog/UploadFiles/Mobile/types';
import { getDisplayedAvatarString } from '@utils/common';
import i18n from '@i18n/index';
import { MyContacts } from '@assets';
import AddContacts from '@routes/Dashboard/Dialog/Share/AddContacts/AddContacts';
import CapaAvatar from '@components/CapaAvatar';

interface Props {
  onClose?: () => void;
  onBack?: () => void;
}

const AddMembers: React.FC<Props> = ({ onBack, onClose }) => {
  const { t } = useTranslation();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const { onSetCreateGroupScreen } = useGroup();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [listMembers, setListMembers] = useState([]);
  const [isAddMembers, setIsAddMembers] = useState(true);
  const [optionListMembers, setOptionListMembers] = useState([]);
  const [searchOptions, setSearchOptions] = useState([]);
  const [selectedMember, setSelectedMember] = useState(DEFAULT_SELECTED_MEMBER);
  const { openFeedbackDialog } = useCommon();
  const [ShareFilesToGroup] = useShareFilesToGroupMutation();
  const [inviteGroupMembersNameMutation] = useInviteGroupMembersNameMutation();
  const [manageUser, setManageUser] = useState({
    accessLevel: undefined
  });
  const prevManageUser = useRef(manageUser);
  const [createGroupNameMutation] = useCreateGroupNameMutation();
  const {
    myDrive,
    onSetIdGroupName,
    onSetchatRoomIdGroupName,
    searchUserState,
    onSearchUser
  } = useMyDrive();
  const languageValue = i18n.language;
  const patternValue =
    /[-A-Za-z0-9_]+[-A-Za-z0-9_.]*[@]{1}[-A-Za-z0-9_]+[-A-Za-z0-9_.]*[.]{1}[A-Za-z]{1,5}/;
  const [emailValue, setEmailValue] = useState('');
  const [isDisabledAddButton, setDisabledAddButton] = useState(true);
  const [internalUserOptions, setInternalUserOptions] = useState<any>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [internalSearchUser] = Apollo.useLazyQuery(SearchUser, {
    fetchPolicy: 'no-cache'
  });
  const { onInsertUserActionLogOne } = useUserActionLog();
  const [checkRegisteredCapaService, { data: checkRegisteredCapaServiceData }] =
    Apollo.useLazyQuery(CheckRegisteredCapaService, {
      fetchPolicy: 'no-cache'
    });
  const [isShowHideContactsModal, setShowHideContactsModal] =
    useState<boolean>(false);

  useEffect(() => {
    prevManageUser.current = manageUser || {};
  }, [manageUser]);

  useEffect(() => {
    onInsertUserActionLogOne(
      Enum_Action_Page_Enum.Page_CreateGroup_AddMembersModal,
      CapaActivityType.PageVisit
    );
    internalSearchUser({
      variables: {
        keyword: '',
        limit: DEFAULT_ITEMS_MAX,
        offset: DEFAULT_ITEMS_OFFSET
      }
    });
  }, []);

  const handleClickOpenPopUp = (event: any, item: any, index: number) => {
    setAnchorEl(event.currentTarget);
    const member = { item, index };
    setSelectedMember(member);
    setManageUser(member.item);
  };

  const handleClosePopUp = () => {
    setAnchorEl(null);
  };

  const handleSelected = (val: any) => {
    let newArr: any = [...optionListMembers];
    if (val.value === ACCESS_LEVEL_VALUE.REMOVE) {
      newArr.splice(selectedMember.index, 1);
      setOptionListMembers(newArr);
      handleClosePopUp();
      return;
    }
    newArr[selectedMember.index] = {
      userName: selectedMember.item.userName,
      email: selectedMember.item.email,
      accessLevel: val.value,
      isCapaUser: selectedMember.item.isCapaUser,
      accessLevelName: val.name
    };
    setOptionListMembers(newArr);

    handleClosePopUp();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'popoverId' : undefined;
  const onSubmit = () => {
    onInsertUserActionLogOne(
      Enum_Action_Button_Enum.Button_CreateGroup_AddMembersModal_Create,
      CapaActivityType.ButtonClick
    );
    createGroupNameMutation({
      variables: {
        groupName: myDrive.valueGroupNameDialog || ''
      }
    }).then((res: any) => {
      const result = res.data.createGroup;
      if (result.result === CapaErrorCode.Success) {
        onSetIdGroupName({ idGroupName: result.data.id });
        onSetchatRoomIdGroupName({
          chatRoomIdGroupName: result.data.chatRoomId
        });
        let payload = {
          groupId: result.data.id || '',
          inviteUsers: optionListMembers.map((member: any) => {
            return {
              email: member.email,
              accessLevel: member.accessLevel
            };
          })
        };
        inviteGroupMembersNameMutation({
          variables: payload
        }).then((res: any) => {
          if (res.data.inviteGroupMembers.result === CapaErrorCode.Success) {
            shareFilesToGroup(result.data.id);
          }
        });
      }
    });
  };

  const onAddContacts = (contactIds: any) => {
    checkRegisteredCapaService({
      variables: {
        emails: contactIds
      }
    });
  };

  const shareFilesToGroup = (id: string) => {
    if (
      myDrive.uploadFileTab === UPLOAD_FILE_TAB.FROM_COMPUTER ||
      myDrive.addFilesFromType === ADD_FILES_FROM_VALUE.MY_DEVICE
    ) {
      shareFileToGroupFromComputer(id);
      return;
    }
    const payload: any = {
      groupId: id,
      connectFileIds: myDrive.selectedFilesUploadInChat.map(
        (file: any) => file.id
      )
    };
    ShareFilesToGroup({ variables: payload }).then((res) => {
      const result = res?.data?.shareFilesToGroup?.result;
      if (result === CapaErrorCode.Success) {
        onSetCreateGroupScreen({
          createGroupScreen: CREATE_GROUP_SCREEN.SUCCESSFULLY_SHARED
        });
        checkShareTimes(() => {
          openFeedbackDialog({
            isOpenFeedback: true,
            isFeedback: true
          });
        });
      }
    });
  };

  const shareFileToGroupFromComputer = (groupId: any) => {
    newFileUploadApi(myDrive.internalFileFromComputer, '').then(
      async (responses) => {
        const fileIds = responses.map((res) => res.data.data.data[0].id);

        const payload: any = {
          groupId: groupId,
          connectFileIds: fileIds
        };
        ShareFilesToGroup({ variables: payload }).then((res) => {
          const result = res?.data?.shareFilesToGroup?.result;

          if (result === CapaErrorCode.Success) {
            onSetCreateGroupScreen({
              createGroupScreen: CREATE_GROUP_SCREEN.SUCCESSFULLY_SHARED
            });
            checkShareTimes(() => {
              openFeedbackDialog({
                isOpenFeedback: true,
                isFeedback: true
              });
            });
          }
        });
      }
    );
  };

  const handleClickAddMembers = () => {
    onInsertUserActionLogOne(
      Enum_Action_Button_Enum.Button_CreateGroup_AddMembersModal_Add,
      CapaActivityType.ButtonClick
    );
    checkRegisteredCapaService({
      variables: {
        emails: listMembers
      }
    });
  };

  useEffect(() => {
    if (
      checkRegisteredCapaServiceData?.checkRegisteredCapaService?.result ===
      CapaErrorCode.Success
    ) {
      const groupMembers: any =
        checkRegisteredCapaServiceData?.checkRegisteredCapaService?.data?.map(
          (item: any) => {
            return {
              userName: item?.connectUser?.userName,
              email: item?.email?.replace(/\s/g, ''),
              accessLevel: ACCESS_LEVEL_VALUE.MANAGER,
              isCapaUser: item?.scopes?.length > 0,
              accessLevelName: ACCESS_LEVEL_NAME.MANAGER
            };
          }
        );

      let isDuplicateEmail = false;
      optionListMembers.forEach((member: any) => {
        isDuplicateEmail = groupMembers.some(
          (item: any) => member.email === item.email
        );
      });

      if (isDuplicateEmail) {
        enqueueSnackbar(t('snackbar.this_email_already_exists_in_the_group'), {
          variant: 'error'
        });
      }

      setOptionListMembers(
        groupMembers
          .concat(optionListMembers)
          .reduce((members: any, user: any) => {
            if (!members.some((obj: any) => obj.email === user.email)) {
              members.push(user);
            }
            return members;
          }, [])
      );
      setEmailValue('');
      setListMembers([]);
      setSearchOptions([]);
      setInternalUserOptions([]);
      setIsAddMembers(true);
    }
  }, [checkRegisteredCapaServiceData]);

  const debouncedSearchTerm = useDebounce(emailValue, 500);

  useEffect(() => {
    if (debouncedSearchTerm) {
      onSearchUser({
        variables: {
          keyword: debouncedSearchTerm,
          limit: 40,
          offset: 0
        }
      });
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (searchUserState?.data?.length > 0) {
      setInternalUserOptions(searchUserState?.data);
    }
  }, [searchUserState]);

  useEffect(() => {
    if (emailValue) {
      setDisabledAddButton(!patternValue.test(emailValue));
    } else {
      if (searchOptions.length > 0) {
        setIsAddMembers(false);
      }
      setDisabledAddButton(false);
    }
  }, [emailValue]);

  return (
    <>
      <DialogTitle classes={{ root: classes.rootTitle }} disableTypography>
        <div className={classes.subTitle}>
          {!matches ? (
            <div
              className={classes.titleIcon}
              onClick={() => {
                if (onBack) onBack();
              }}
            >
              <KeyboardArrowLeft />
            </div>
          ) : (
            <ArrowBackIosOutlined
              onClick={() => {
                if (onBack) onBack();
              }}
            />
          )}
          <OverflowTip value={myDrive.valueGroupNameDialog || ''}>
            <span className={classes.name}>
              {languageValue === LANGUAGE_VALUE.KO ? (
                <span className="title-group-name">
                  {myDrive.valueGroupNameDialog}
                </span>
              ) : null}
              {t('add_members_modal.header')}{' '}
              {languageValue === LANGUAGE_VALUE.EN ? (
                <span className="title-group-name">
                  {myDrive.valueGroupNameDialog}
                </span>
              ) : null}
            </span>
          </OverflowTip>
          <Tooltip title={t('tooltip.my_contact') || 'My contact'}>
            <div
              className={classes.icon}
              onClick={() => setShowHideContactsModal(true)}
            >
              <img
                src={MyContacts}
                alt="capaAddContacts"
                className={classes.addContactsIcon}
              />
            </div>
          </Tooltip>
        </div>
      </DialogTitle>
      <DialogActions className={classes.csDialogContent}>
        <Autocomplete
          multiple
          id="tags-filled"
          options={internalUserOptions.map((user: any) => user.email)}
          freeSolo
          renderTags={(value: any, getTagProps: any) =>
            value.map((option: any, index: number) => (
              <Chip
                variant="outlined"
                label={option}
                {...getTagProps({ index })}
              />
            ))
          }
          onChange={(event: any, newValue: any, reason: any) => {
            onInsertUserActionLogOne(
              Enum_Action_Button_Enum.Click_CreateGroup_AddMembersModal_Contact,
              CapaActivityType.ButtonClick
            );
            if (reason === AUTOCOMPLETE_REASON.CLEAR) {
              setEmailValue('');
            }
            let isValidEmail = false;

            newValue.forEach((value: any) => {
              isValidEmail = patternValue.test(value);
            });

            if (newValue.length === 0) {
              setInternalUserOptions([]);
              setDisabledAddButton(true);
            }

            if (!isValidEmail && newValue.length > 0) {
              return;
            }

            setSearchOptions(newValue);

            if (newValue && newValue.length > 0) {
              if (emailValue) {
                setDisabledAddButton(!patternValue.test(emailValue));
              }
              setIsAddMembers(false);
              setListMembers(newValue);
              if (reason !== AUTOCOMPLETE_REASON.REMOVE_OPTION) {
                setEmailValue('');
              }
              setInternalUserOptions([]);
            } else {
              setInternalUserOptions([]);
              setListMembers([]);
              setIsAddMembers(true);
            }
          }}
          inputValue={emailValue}
          value={searchOptions}
          renderInput={(params: any) => (
            <TextField
              {...params}
              placeholder={t('add_members_modal.placeholder')}
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) => {
                setEmailValue(e.target.value);

                if (e.target.value) {
                  setIsAddMembers(false);
                  // @ts-ignore
                  setListMembers([...searchOptions, e.target.value]);
                } else {
                  setIsAddMembers(true);
                  setListMembers([]);
                }
              }}
            />
          )}
          onFocus={() => {
            onInsertUserActionLogOne(
              Enum_Action_Input_Enum.Input_AddMembersModal_Email,
              CapaActivityType.actionInput
            );
          }}
        />
        <CustomizedButton
          type="submit"
          variant="outlined"
          color="primary"
          disabled={isDisabledAddButton || isAddMembers}
          onClick={handleClickAddMembers}
          className={classes.customAddButton}
        >
          {t('add_members_modal.add_button')}
        </CustomizedButton>
      </DialogActions>
      <div className={classes.contentWrapper}>
        {optionListMembers &&
          optionListMembers.map((member: any, index) => (
            <DialogContent
              className={classes.csDialogContentBottom}
              key={index}
            >
              <div className={classes.csDialogContentList}>
                <div className={classes.avatar}>
                  <CapaAvatar item={member} />
                </div>
                <div className={classes.csDialogContentText}>
                  <div className={classes.csDialogUserName}>
                    {getDisplayedAvatarString(member.userName, member.email)}
                  </div>
                  <div className={classes.csDialogUserName}>{member.email}</div>
                </div>
                <div
                  className={classes.csDialogContentPosition}
                  aria-describedby={id}
                  onClick={(e) => {
                    onInsertUserActionLogOne(
                      Enum_Action_Button_Enum.Button_CreateGroup_AddMembersModal_GroupAccess,
                      CapaActivityType.ButtonClick
                    );
                    handleClickOpenPopUp(e, member, index);
                  }}
                >
                  {t(
                    ACCESS_LEVEL_OPTIONS.filter(
                      (option: any) => option.value === member.accessLevel
                    )[0]
                      ? ACCESS_LEVEL_OPTIONS.filter(
                          (option: any) => option.value === member.accessLevel
                        )[0].name
                      : ''
                  )}
                  <KeyboardArrowDownOutlined />
                </div>
              </div>
            </DialogContent>
          ))}
        <Popover
          className={classes.customPopover}
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClosePopUp}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
        >
          <div className={classes.csDialogContentPopup}>
            {matches && (
              <Typography className={classes.item}>
                <CapaAvatar item={selectedMember.item} />
                <div className="name">
                  <div>{selectedMember.item.email}</div>
                </div>
              </Typography>
            )}
            {ACCESS_LEVEL_OPTIONS.map((accessLevel: any, index) => (
              <Typography
                key={index}
                onClick={() => handleSelected(accessLevel)}
                className={classes.itemPopUp}
              >
                {selectedMember.item.accessLevel === accessLevel.value ? (
                  <CheckOutlined className={classes.iconCheck} />
                ) : (
                  <div className="spacing" />
                )}
                <div className="name">
                  <div>{t(accessLevel.name)}</div>
                  <p>{accessLevel.title}</p>
                </div>
              </Typography>
            ))}
          </div>
        </Popover>
      </div>
      <DialogActions classes={{ root: classes.action }}>
        <div className={classes.wrapperBtn}>
          <CustomizedButton
            variant="contained"
            color="default"
            onClick={() => {
              onInsertUserActionLogOne(
                Enum_Action_Button_Enum.Button_CreateGroup_AddMembersModal_Back,
                CapaActivityType.ButtonClick
              );
              onClose && onClose();
            }}
          >
            {t('add_members_modal.cancel_button')}
          </CustomizedButton>
          <CustomizedButton
            type="submit"
            variant="contained"
            color="primary"
            onClick={onSubmit}
            disabled={optionListMembers.length === 0}
          >
            {t('add_members_modal.done_button')}
          </CustomizedButton>
        </div>
      </DialogActions>
      <AddContacts
        isOpen={isShowHideContactsModal}
        onClose={() => setShowHideContactsModal(false)}
        onAddContacts={onAddContacts}
        disabledContacts={optionListMembers.map((member: any) => member.email)}
      />
    </>
  );
};

export default AddMembers;
