import React, { useEffect, useState } from 'react';
import { generateOfficeURL } from '@utils/common';
import { CapaConnectMyFile } from '@generated/Common/graphql';
import OfficeFileViewerPresenter from './OfficeFileViewerPresenter';

interface Props {
  file?: CapaConnectMyFile;
}

const OfficeFileViewer = (props: Props) => {
  const { file } = props;
  const [linkFileOffice, setLinkFileOffice] = useState('');

  useEffect(() => {
    if (file) {
      setLinkFileOffice(
        generateOfficeURL(file?.id ?? '', file?.originalFileExt ?? '')
      );
    } else {
      setLinkFileOffice('');
    }
  }, [file]);
  return <OfficeFileViewerPresenter linkFileOffice={linkFileOffice} />;
};

export default OfficeFileViewer;
