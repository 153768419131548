import React, { useState } from 'react';
import { Check } from '@material-ui/icons';
import { useStyles } from './styles';
import { Popover } from '@material-ui/core';
import { LANGUAGE_OPTIONS } from './types';
import i18n from '../../../i18n';
import { ChangeLanguageIcon, ChangeLanguageHomeIcon } from '@assets';
import clsx from 'clsx';

interface Props {
  isLandingPage?: boolean;
}

const ChangeLanguageContainer = (props: Props) => {
  const { isLandingPage } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'popoverId' : undefined;
  const languageValue = i18n.language;

  const handleClickOpenPopUp = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopUp = () => {
    setAnchorEl(null);
  };

  const onClickLanguage = (value: any) => {
    i18n.changeLanguage(value);
    handleClosePopUp();
  };

  return (
    <>
      <div
        className={clsx(
          isLandingPage
            ? classes.changeLanguageLandingPageButton
            : classes.changeLanguageButton,
          isLandingPage && open && classes.landingPageBackgroundColorOpen,
          isLandingPage && !open && classes.landingPageBackgroundColor,
          !isLandingPage && open ? classes.landingPageBoxShadowOpen : ''
        )}
        onClick={(e) => handleClickOpenPopUp(e)}
      >
        <img
          src={isLandingPage ? ChangeLanguageHomeIcon : ChangeLanguageIcon}
          alt="changeLanguageIcon"
          className={classes.changeLanguageIcon}
        />
        <div className={classes.languageText}>
          {
            LANGUAGE_OPTIONS.filter(
              (language) => language.value === languageValue
            )[0].name
          }
        </div>
      </div>
      <Popover
        className={classes.customPopover}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePopUp}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {LANGUAGE_OPTIONS.map((language) => (
          <div
            key={language.name}
            className={classes.csDialogContentPopup}
            onClick={() => onClickLanguage(language.value)}
          >
            {language.name}
            {languageValue === language.value ? (
              <div>
                <Check className={classes.checkIcon} />
              </div>
            ) : null}
          </div>
        ))}
      </Popover>
    </>
  );
};

export default ChangeLanguageContainer;
