import React from 'react';
import { colorSet, theme } from '@components/Provider';
import {
  ButtonProps,
  FormControlLabelProps,
  PropTypes
} from '@material-ui/core';

export type TCustomizedButtonColor = 'default' | 'success' | 'error';
export type TButtonColorProp = PropTypes.Color | TCustomizedButtonColor;
export interface ICustomizedButtonProps extends Omit<ButtonProps, 'color'> {
  color?: TButtonColorProp;
  component?: string;
}

export const MuiButtonProps = {
  default: {
    palette: {
      secondary: {
        light: colorSet.gray200,
        main: colorSet.gray300,
        dark: colorSet.gray400,
        contrastText: colorSet.gray700
      }
    },
    style: {
      containedSecondary: {
        '&:hover': {
          color: colorSet.gray800
        }
      },
      outlinedSecondary: {
        color: colorSet.gray700,
        border: `1px solid ${colorSet.gray600}`,
        '&:hover': {
          backgroundColor: colorSet.gray100,
          color: colorSet.gray800,
          border: `1px solid ${colorSet.gray600}`
        },
        '&:disabled': {
          border: `1px solid ${colorSet.gray400}`,
          color: colorSet.gray400
        }
      },
      textSecondary: {
        color: colorSet.gray900
      }
    }
  },
  success: {
    palette: {
      secondary: theme.palette.success
    },
    style: {
      outlinedSecondary: {
        border: `1px solid ${colorSet.successMain}`
      }
    }
  },
  error: {
    palette: { secondary: theme.palette.error },
    style: {
      outlinedSecondary: {
        border: `1px solid ${colorSet.errorMain}`
      }
    }
  }
};

export type TCustomizedCheckBoxColor = 'default' | 'success' | 'error';
export type TCheckBoxColorProp = 'primary' | 'secondary' | 'default';
export interface ICustomizedCheckBoxProps
  extends Omit<
    FormControlLabelProps,
    'control' | 'value' | 'onChange' | 'label'
  > {
  label?: React.ReactNode;
  value: string;
  values?: Array<string>;
  control?: React.ReactElement<any, any>;
  color?: TCheckBoxColorProp;
  fullwidth?: boolean;
  gutterBottoms?: number;
  size?: 'small' | 'medium';
  onChange: (value: Array<string>) => void;
}

export const APPLE_STORE = 'App Store';
export const APPLE = 'apple';
export const GOOGLE_PLAY = 'Google Play';
export const GOOGLE = 'google';
