import { HttpsOutlined } from '@material-ui/icons';
import { ReactComponent as IBMDownload } from '../../../assets/icon/IBMDownload.svg';
import { ReactComponent as IBMSetting } from '../../../assets/icon/IBMSetting.svg';
import { ReactComponent as IBMCloud } from '../../../assets/icon/IBMCloud.svg';
import { ReactComponent as IBMDelete } from '../../../assets/icon/IBMDelete.svg';
import { CAPAConnectSharedFileType } from '@routes/Common/types';

export const FILE_LIST_CONTROL_VALUE = {
  DOWNLOAD: 'DOWNLOAD',
  ADD_TO_MY_DRIVE: 'ADD_TO_MY_DRIVE',
  FILE_SETTING: 'FILE_SETTING',
  REMOVE: 'REMOVE',
  REQUEST_FOR_DOWNLOAD: 'REQUEST_FOR_DOWNLOAD'
};

export const VALUE_TABS_LIST_GROUP = {
  FILE: 0,
  COMMENTS: 1
};

export const FILE_LIST_CONTROL_OPTIONS = [
  {
    value: FILE_LIST_CONTROL_VALUE.REQUEST_FOR_DOWNLOAD,
    name: 'control_button.request_for_download',
    icon: HttpsOutlined,
    borderBottom: false
  },
  {
    value: FILE_LIST_CONTROL_VALUE.FILE_SETTING,
    name: 'control_button.download_permission',
    icon: IBMSetting,
    borderBottom: false
  },
  {
    value: FILE_LIST_CONTROL_VALUE.DOWNLOAD,
    name: 'control_button.download',
    icon: IBMDownload,
    borderBottom: true
  },
  {
    value: FILE_LIST_CONTROL_VALUE.ADD_TO_MY_DRIVE,
    name: 'control_button.add_to_my_drive',
    icon: IBMCloud,
    borderBottom: false
  },
  {
    value: FILE_LIST_CONTROL_VALUE.REMOVE,
    name: 'control_button.remove',
    icon: IBMDelete,
    borderBottom: false
  }
];
export const FILE_TYPE_FILTER = [
  {
    value: CAPAConnectSharedFileType.ALL,
    label: 'file_type.all'
  },
  {
    value: CAPAConnectSharedFileType.CAD,
    label: 'file_type.cads'
  },
  {
    value: CAPAConnectSharedFileType.VIDEO,
    label: 'file_type.videos'
  },
  {
    value: CAPAConnectSharedFileType.PHOTO,
    label: 'file_type.photos'
  },
  {
    value: CAPAConnectSharedFileType.DOCUMENT,
    label: 'file_type.documents'
  }
];

export const ACCESS_LEVEL = {
  OWNER: 'OWNER',
  MANAGER: 'MANAGER',
  MEMBER: 'MEMBER'
};

export const SORT_BY = {
  DATE_ASC: 'LATEST',
  DATE_DESC: 'OLDEST'
};
