import React, { useEffect, useState } from 'react';
import { PageContainer } from './styles';
import NotificationBox from '@routes/MyPage/NotificationSettings/NotificationBox';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@material-ui/core';
import {
  NOTIFICATION_SETTINGS_PUSH_LIST,
  NOTIFICATION_SETTINGS_EMAIL_LIST
} from './types';
import useAuth from '@hooks/useAuth';

const NotificationSettings = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [isCollapsePush, setCollapsePush] = useState<boolean>(false);
  const [isCollapseEmail, setCollapseEmail] = useState<boolean>(false);
  const { onRefetchConnectUser } = useAuth();

  useEffect(() => {
    onRefetchConnectUser();
  }, []);
  return (
    <PageContainer>
      {isMobile ? null : (
        <>
          {' '}
          <div className="title">
            {t('notification_settings.notifications')}
          </div>
          <div className="sub-title">
            {t('notification_settings.sub_title')}
          </div>
        </>
      )}

      <NotificationBox
        boxMarginTop={isMobile ? 0 : 52}
        title={t('notification_settings.push_notifications')}
        subTitle={t('notification_settings.your_push_notifications')}
        data={NOTIFICATION_SETTINGS_PUSH_LIST}
        isCollapse={isCollapsePush}
        setCollapse={() => {
          setCollapsePush(!isCollapsePush);
        }}
      />

      <NotificationBox
        title={t('notification_settings.email_notifications')}
        subTitle={t('notification_settings.your_email_notifications')}
        data={NOTIFICATION_SETTINGS_EMAIL_LIST}
        isCollapse={isCollapseEmail}
        setCollapse={() => {
          setCollapseEmail(!isCollapseEmail);
        }}
      />
    </PageContainer>
  );
};

export default NotificationSettings;
