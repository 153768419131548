import { makeStyles } from '@material-ui/core';
import { colorSet } from '@components/Provider';

export const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: 520,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: 0,
      height: '100%',
      maxHeight: '100%',
      maxWidth: '100%',
      borderRadius: 0
    },
    borderRadius: '16px'
  },
  successFullyPaper: {
    maxWidth: 480,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: 0,
      height: '100%',
      maxHeight: '100%',
      maxWidth: '100%',
      borderRadius: 0
    },
    maxHeight: 593,
    borderRadius: '16px'
  },
  rootDialog: {
    display: 'none'
  },
  rootTitle: {
    padding: '24px 24px 20px 24px',
    display: 'flex',
    fontSize: '25px',
    fontWeight: 'bold',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: '12px 16px',
      border: `1px solid ${colorSet.gray300}`,
      display: 'inline-block',
      textAlign: 'center',
      fontSize: 16
    }
  },
  customTitle: {
    fontSize: 20,
    fontWeight: 700,
    lineHeight: '160%',
    letterSpacing: '-0.1px',
    [theme.breakpoints.down('xs')]: {
      color: colorSet.gray900,
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '150%'
    }
  },
  groupSuccessContent: {
    fontSize: 25,
    lineHeight: '160%',
    fontWeight: 400,
    color: colorSet.gray900,
    '& strong': {
      fontWeight: 700
    }
  },
  arrowBackIosOutlinedWrapper: {
    backgroundColor: colorSet.primary450,
    borderRadius: 12,
    marginRight: 16,
    width: 40,
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  arrowBackIosOutlinedIcon: {
    width: 14,
    color: colorSet.gray100,
    [theme.breakpoints.down('xs')]: {
      color: colorSet.gray900,
      fontWeight: 500,
      position: 'absolute',
      left: 16,
      top: 14
    }
  },
  pointerCursor: {
    cursor: 'pointer'
  }
}));
