import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import AppConfig from '@config/index';
import { CapaErrorCode } from '@generated/Common/graphql';
import useDashboard from '@hooks/useDashboard';
import { useSnackbar } from '@components/Provider';
import {
  HOOPS_VIEWER_MESSAGE,
  HOOPS_VIEWER_IFRAME_ID,
  CAPAConnectAnnotation
} from '@routes/Viewer/types';
import {
  CreateConnectAnnotation,
  CreateConnectAnnotationComment,
  RemoveConnectAnnotationComment,
  RemoveConnectAnnotation,
  UpdateConnectAnnotationComment,
  SubscribeConnectUpdateData,
  ReadConnectAnnotationComment
} from '@generated/Drive/MyDrive/graphql';
import * as Apollo from '@apollo/client';
import useMyDrive from '@hooks/Drive/MyDrive/useMyDrive';
import { useStylesViewer } from './styles';
import { useTranslation } from 'react-i18next';
import {
  stringToColor,
  stringAvatar
} from '@routes/Dashboard/Dialog/Share/utils';
import { CONNECT_UPDATE_TYPE } from '@routes/Common/types';
import { getDisplayedAvatarString } from '@utils/common';
import useAuth from '@hooks/useAuth';
import i18n from '@i18n/index';
import useGroupRoom from '@hooks/GroupRoom/useGroupRoom';

interface Props {
  file?: any;
}

const AnnotationViewer = (props: Props) => {
  const { t } = useTranslation();
  const { dashboard } = useDashboard();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const classes = useStylesViewer({
    marginBottom:
      dashboard.files && dashboard.files.length > 1 ? 'true' : undefined
  });
  const { enqueueSnackbar } = useSnackbar();
  const [selectedFile, setSelectedFile] = useState(
    dashboard.files ? dashboard.files[0] : undefined
  );
  const draftAnnotationRef = useRef<{
    activeFileId: string;
  }>({
    activeFileId: ''
  });
  const { search } = useLocation();
  const groupId = new URLSearchParams(search).get('groupId');
  const [createConnectAnnotation] = Apollo.useMutation(CreateConnectAnnotation);
  const [removeConnectAnnotation] = Apollo.useMutation(RemoveConnectAnnotation);
  const [updateConnectAnnotationComment] = Apollo.useMutation(
    UpdateConnectAnnotationComment
  );
  const [createConnectAnnotationComment] = Apollo.useMutation(
    CreateConnectAnnotationComment
  );
  const [removeConnectAnnotationComment] = Apollo.useMutation(
    RemoveConnectAnnotationComment
  );
  const [readConnectAnnotation] = Apollo.useMutation(
    ReadConnectAnnotationComment
  );
  const queryFileId = new URLSearchParams(search).get('fileId');
  const queryAnnotationId = new URLSearchParams(search).get('annotationId');
  const queryCommentId = new URLSearchParams(search).get('commentId');
  const queryShowDownloadPermission = new URLSearchParams(search).get(
    'showDownloadPermission'
  );
  const { userState } = useAuth();
  const languageValue = i18n.language;

  const {
    myDrive,
    onSetFirstSelectedDrawFile,
    onSetActionAnnotationSuccess,
    onSelectDrawFileFromCommentList
  } = useMyDrive();

  const { connectAnnotations, onFetchConnectAnnotations } = useGroupRoom();

  useEffect(() => {
    if ((connectAnnotations?.fetchConnectAnnotations?.data?.length ?? 0) > 0) {
      connectAnnotations.fetchConnectAnnotations.data.forEach(
        (annotation: CAPAConnectAnnotation) => {
          if (myDrive.activeFileIdInGroupRoom !== annotation.connectFileId)
            return;

          const firstAnnotationComment =
            annotation && annotation?.comments && annotation?.comments[0];

          const viewer = iframeRef.current;

          viewer?.contentWindow?.postMessage(
            {
              message: HOOPS_VIEWER_MESSAGE.ANNOTATION.ADD,
              data: {
                annotationId: annotation.id,
                nodeId: annotation.nodeId,
                anchorPos: annotation.anchorPos,
                handlePos: annotation.handlePos,
                comments: annotation?.comments?.map((comment: any) => {
                  return {
                    commentId: comment.id,
                    comment: comment.comment,
                    authorId: comment.email,
                    authorName: getDisplayedAvatarString(
                      comment.userName,
                      comment.email
                    ),
                    authorInitials: stringAvatar(
                      getDisplayedAvatarString(comment.userName, comment.email)
                    ).children,
                    authorColor: stringToColor(
                      getDisplayedAvatarString(comment.userName, comment.email)
                    ),
                    authorIcon: '',
                    timestamp: new Date(comment.createdAt).getTime(),
                    edited: comment.edited
                  };
                }),
                unread: annotation.hasNew ?? false,
                authorId: firstAnnotationComment?.email,
                authorName: getDisplayedAvatarString(
                  firstAnnotationComment?.userName,
                  firstAnnotationComment?.email
                ),
                authorInitials: stringAvatar(
                  getDisplayedAvatarString(
                    firstAnnotationComment?.userName,
                    firstAnnotationComment?.email
                  )
                ).children,
                authorColor: stringToColor(
                  getDisplayedAvatarString(
                    firstAnnotationComment?.userName,
                    firstAnnotationComment?.email
                  )
                ),
                authorIcon: '',
                timestamp: new Date(annotation?.createdAt).getTime(),
                commentToOpen:
                  (myDrive.selectDrawFileFromCommentList.annotationId ||
                    (!queryShowDownloadPermission && queryFileId)) &&
                  (myDrive.selectDrawFileFromCommentList.annotationId ===
                    annotation.id ||
                    queryAnnotationId === annotation.id)
                    ? myDrive.selectDrawFileFromCommentList.commentId ||
                      queryCommentId
                    : undefined
              }
            },
            '*'
          );
        }
      );

      if (
        myDrive.selectDrawFileFromCommentList.annotationId ||
        (!queryShowDownloadPermission && queryFileId)
      ) {
        onSelectDrawFileFromCommentList({
          selectDrawFileFromCommentList: {
            annotationId: '',
            commentId: ''
          }
        });
      }
    }

    window.addEventListener('message', onMessageReceivedFromIframe);
    return () =>
      window.removeEventListener('message', onMessageReceivedFromIframe);
  }, [connectAnnotations]);

  const onMessageReceivedFromIframe = (e: MessageEvent) => {
    const viewer = iframeRef.current;
    const messageData: any = e?.data?.data ?? null;

    switch (e.data.message) {
      case HOOPS_VIEWER_MESSAGE.LOAD.LOADED_3D_VIEWER: {
        if (viewer && viewer.contentWindow) {
          viewer.contentWindow.postMessage(
            {
              message: HOOPS_VIEWER_MESSAGE.LOAD.LOADED_USER,
              data: {
                userId: userState?.data?.email,
                userName: getDisplayedAvatarString(
                  userState?.data?.userName,
                  userState?.data?.email
                ),
                userInitials: stringAvatar(
                  getDisplayedAvatarString(
                    userState?.data?.userName,
                    userState?.data?.email
                  )
                ).children,
                userColor: stringToColor(
                  getDisplayedAvatarString(
                    userState?.data?.userName,
                    userState?.data?.email
                  )
                ),
                userIcon: ''
              }
            },
            '*'
          );

          if (selectedFile) {
            viewer.contentWindow.postMessage(
              {
                message: HOOPS_VIEWER_MESSAGE.LOAD.LOADED_MODEL,
                data: {
                  modelId: `${AppConfig.capaViewerApi}/${selectedFile.drawingFile}`
                }
              },
              '*'
            );
          }
        }
        break;
      }
      case HOOPS_VIEWER_MESSAGE.LOAD.LOADED_MODEL: {
        onSetFirstSelectedDrawFile({ isFirstSelectedDrawFile: true });
        onFetchConnectAnnotations({
          variables: {
            groupId: groupId,
            connectSharedFileId: null
          }
        });
        break;
      }
      case HOOPS_VIEWER_MESSAGE.ANNOTATION.ADD: {
        const isModelChanged =
          draftAnnotationRef.current.activeFileId !==
          myDrive.activeFileIdInGroupRoom;

        createConnectAnnotation({
          variables: {
            groupId: groupId,
            connectSharedFileId: isModelChanged
              ? draftAnnotationRef.current.activeFileId
              : myDrive.activeFileIdInGroupRoom,
            comment: messageData.comment,
            anchorPos: messageData.anchorPos,
            handlePos: messageData.handlePos,
            nodeId: messageData.nodeId
          }
        })
          .then((res: any) => {
            if (
              res?.data?.createConnectAnnotation?.result ===
              CapaErrorCode.Success
            ) {
              if (isModelChanged) {
                return;
              }
              const commentData =
                res.data.createConnectAnnotation.data.comments[0];
              viewer?.contentWindow?.postMessage(
                {
                  message: HOOPS_VIEWER_MESSAGE.ANNOTATION.ADD,
                  data: {
                    annotationId: res.data.createConnectAnnotation.data.id,
                    nodeId: messageData.nodeId,
                    anchorPos: messageData.anchorPos,
                    handlePos: messageData.handlePos,
                    comments: [
                      {
                        commentId: commentData.id,
                        comment: commentData.comment,
                        authorId: userState?.data?.email,
                        authorName: getDisplayedAvatarString(
                          userState?.data?.userName,
                          userState?.data?.email
                        ),
                        authorInitials: stringAvatar(
                          getDisplayedAvatarString(
                            userState?.data?.userName,
                            userState?.data?.email
                          )
                        ).children,
                        authorColor: stringToColor(
                          getDisplayedAvatarString(
                            userState?.data?.userName,
                            userState?.data?.email
                          )
                        ),
                        authorIcon: '',
                        timestamp: new Date(commentData.createdAt).getTime(),
                        edited: false
                      }
                    ],
                    authorId: userState?.data?.email,
                    authorName: getDisplayedAvatarString(
                      userState?.data?.userName,
                      userState?.data?.email
                    ),
                    authorInitials: stringAvatar(
                      getDisplayedAvatarString(
                        userState?.data?.userName,
                        userState?.data?.email
                      )
                    ).children,
                    authorColor: stringToColor(
                      getDisplayedAvatarString(
                        userState?.data?.userName,
                        userState?.data?.email
                      )
                    ),
                    authorIcon: '',
                    timestamp: new Date().getTime()
                  }
                },
                '*'
              );

              onSetActionAnnotationSuccess({ isActionAnnotationSuccess: true });
            }
          })
          .finally(() => {
            draftAnnotationRef.current.activeFileId = '';
          });
        break;
      }
      case HOOPS_VIEWER_MESSAGE.ANNOTATION.DELETE: {
        removeConnectAnnotation({
          variables: {
            groupId: groupId,
            connectSharedFileId: myDrive.activeFileIdInGroupRoom,
            annotationId: messageData.annotationId
          }
        })
          .then((res) => {
            const result = res?.data?.removeConnectAnnotation?.result;
            if (result === CapaErrorCode.Success) {
              enqueueSnackbar(`${t('snackbar.delete_thread_success')}`, {
                variant: 'success'
              });

              if (viewer?.contentWindow) {
                viewer.contentWindow.postMessage(
                  {
                    message: HOOPS_VIEWER_MESSAGE.ANNOTATION.DELETE,
                    data: {
                      annotationId: messageData.annotationId
                    }
                  },
                  '*'
                );
              }
              onSetActionAnnotationSuccess({ isActionAnnotationSuccess: true });
            }
          })
          .catch(() => {
            enqueueSnackbar(`${t('snackbar.delete_thread_error')}`, {
              variant: 'error'
            });
          });
        break;
      }
      case HOOPS_VIEWER_MESSAGE.ANNOTATION.OPEN: {
        if (!messageData.annotationId) return;

        if (messageData.annotationId.includes('draft')) {
          draftAnnotationRef.current.activeFileId = String(
            myDrive.activeFileIdInGroupRoom
          );
        } else {
          readConnectAnnotation({
            variables: {
              annotationId: messageData.annotationId
            }
          }).catch(() => {
            enqueueSnackbar('failed to read comment');
          });
        }
        break;
      }
      case HOOPS_VIEWER_MESSAGE.COMMENT.ADD: {
        createConnectAnnotationComment({
          variables: {
            groupId: groupId,
            connectSharedFileId: myDrive.activeFileIdInGroupRoom,
            annotationId: messageData.annotationId,
            comment: messageData.comment
          }
        }).then((res: any) => {
          if (
            res?.data?.createConnectAnnotationComment?.result ===
            CapaErrorCode.Success
          ) {
            const commentResults =
              res.data.createConnectAnnotationComment.data.comments.sort(
                (a: any, b: any) => {
                  const dateA: any = new Date(a.createdAt);
                  const dateB: any = new Date(b.createdAt);
                  return dateB - dateA;
                }
              );

            viewer?.contentWindow?.postMessage(
              {
                message: HOOPS_VIEWER_MESSAGE.COMMENT.ADD,
                data: {
                  annotationId: messageData.annotationId,
                  commentId: commentResults[0].id,
                  comment: messageData.comment,
                  authorId: userState?.data?.email,
                  authorName: getDisplayedAvatarString(
                    userState?.data?.userName,
                    userState?.data?.email
                  ),
                  authorInitials: stringAvatar(
                    getDisplayedAvatarString(
                      userState?.data?.userName,
                      userState?.data?.email
                    )
                  ).children,
                  authorColor: stringToColor(
                    getDisplayedAvatarString(
                      userState?.data?.userName,
                      userState?.data?.email
                    )
                  ),
                  authorIcon: '',
                  timestamp: new Date().getTime()
                }
              },
              '*'
            );

            onSetActionAnnotationSuccess({ isActionAnnotationSuccess: true });
          }
        });
        break;
      }
      case HOOPS_VIEWER_MESSAGE.COMMENT.UPDATE: {
        updateConnectAnnotationComment({
          variables: {
            groupId: groupId,
            connectSharedFileId: myDrive.activeFileIdInGroupRoom,
            annotationId: messageData.annotationId,
            commentId: messageData.commentId,
            comment: messageData.comment,
            edited: true
          }
        }).then((res: any) => {
          if (
            res?.data?.updateConnectAnnotationComment.result ===
            CapaErrorCode.Success
          ) {
            viewer?.contentWindow?.postMessage(
              {
                message: HOOPS_VIEWER_MESSAGE.COMMENT.UPDATE,
                data: {
                  annotationId: messageData.annotationId,
                  commentId: messageData.commentId,
                  comment: messageData.comment
                }
              },
              '*'
            );

            onSetActionAnnotationSuccess({ isActionAnnotationSuccess: true });
          }
        });
        break;
      }
      case HOOPS_VIEWER_MESSAGE.COMMENT.DELETE: {
        removeConnectAnnotationComment({
          variables: {
            groupId: groupId,
            connectSharedFileId: myDrive.activeFileIdInGroupRoom,
            annotationId: messageData.annotationId,
            commentId: messageData.commentId
          }
        }).then((res: any) => {
          if (
            res?.data?.removeConnectAnnotationComment?.result ===
            CapaErrorCode.Success
          ) {
            viewer?.contentWindow?.postMessage(
              {
                message: HOOPS_VIEWER_MESSAGE.COMMENT.DELETE,
                data: {
                  annotationId: messageData.annotationId,
                  commentId: messageData.commentId
                }
              },
              '*'
            );

            onSetActionAnnotationSuccess({ isActionAnnotationSuccess: true });
          }
        });
        break;
      }
    }
  };

  Apollo.useSubscription(SubscribeConnectUpdateData, {
    onSubscriptionData: (subscriptionData) => {
      const subscribeConnectUpdateData =
        subscriptionData?.subscriptionData?.data;
      if (subscribeConnectUpdateData) {
        const internalConnectUpdateData =
          subscribeConnectUpdateData?.subscribeConnectUpdateData?.data;
        if (userState?.data?.email === internalConnectUpdateData?.authorEmail)
          return;

        const viewer = iframeRef.current;

        switch (internalConnectUpdateData?.type) {
          case CONNECT_UPDATE_TYPE.ANNOTATION_ADD: {
            viewer?.contentWindow?.postMessage(
              {
                message: HOOPS_VIEWER_MESSAGE.ANNOTATION.ADD,
                data: {
                  annotationId: internalConnectUpdateData?.annotationId,
                  nodeId: internalConnectUpdateData?.nodeId,
                  anchorPos: internalConnectUpdateData?.anchorPos,
                  handlePos: internalConnectUpdateData?.handlePos,
                  unread: true,
                  comments: [
                    {
                      commentId: internalConnectUpdateData?.commentId,
                      comment: internalConnectUpdateData?.comment,
                      authorId: internalConnectUpdateData?.authorEmail,
                      authorName: getDisplayedAvatarString(
                        internalConnectUpdateData?.authorName,
                        internalConnectUpdateData?.authorEmail
                      ),
                      authorInitials: stringAvatar(
                        getDisplayedAvatarString(
                          internalConnectUpdateData?.authorName,
                          internalConnectUpdateData?.authorEmail
                        )
                      ).children,
                      authorColor: stringToColor(
                        getDisplayedAvatarString(
                          internalConnectUpdateData?.authorName,
                          internalConnectUpdateData?.authorEmail
                        )
                      ),
                      authorIcon: '',
                      timestamp: new Date().getTime(),
                      edited: internalConnectUpdateData?.edited
                    }
                  ],
                  authorId: internalConnectUpdateData?.authorEmail,
                  authorName: getDisplayedAvatarString(
                    internalConnectUpdateData?.authorName,
                    internalConnectUpdateData?.authorEmail
                  ),
                  authorInitials: stringAvatar(
                    getDisplayedAvatarString(
                      internalConnectUpdateData?.authorName,
                      internalConnectUpdateData?.authorEmail
                    )
                  ).children,
                  authorColor: stringToColor(
                    getDisplayedAvatarString(
                      internalConnectUpdateData?.authorName,
                      internalConnectUpdateData?.authorEmail
                    )
                  ),
                  authorIcon: '',
                  timestamp: new Date().getTime()
                }
              },
              '*'
            );

            onSetActionAnnotationSuccess({ isActionAnnotationSuccess: true });
            break;
          }
          case CONNECT_UPDATE_TYPE.ANNOTATION_DELETE: {
            viewer?.contentWindow?.postMessage(
              {
                message: HOOPS_VIEWER_MESSAGE.ANNOTATION.DELETE,
                data: {
                  annotationId: internalConnectUpdateData?.annotationId
                }
              },
              '*'
            );

            onSetActionAnnotationSuccess({ isActionAnnotationSuccess: true });
            break;
          }
          case CONNECT_UPDATE_TYPE.ANNOTATION_COMMENT_ADD: {
            viewer?.contentWindow?.postMessage(
              {
                message: HOOPS_VIEWER_MESSAGE.COMMENT.ADD,
                data: {
                  annotationId: internalConnectUpdateData?.annotationId,
                  commentId: internalConnectUpdateData?.commentId,
                  comment: internalConnectUpdateData?.comment,
                  authorId: internalConnectUpdateData?.authorEmail,
                  authorName: getDisplayedAvatarString(
                    internalConnectUpdateData?.authorName,
                    internalConnectUpdateData?.authorEmail
                  ),
                  authorInitials: stringAvatar(
                    getDisplayedAvatarString(
                      internalConnectUpdateData?.authorName,
                      internalConnectUpdateData?.authorEmail
                    )
                  ).children,
                  authorColor: stringToColor(
                    getDisplayedAvatarString(
                      internalConnectUpdateData?.authorName,
                      internalConnectUpdateData?.authorEmail
                    )
                  ),
                  authorIcon: '',
                  timestamp: new Date().getTime()
                }
              },
              '*'
            );

            onSetActionAnnotationSuccess({ isActionAnnotationSuccess: true });
            break;
          }
          case CONNECT_UPDATE_TYPE.ANNOTATION_COMMENT_UPDATE: {
            viewer?.contentWindow?.postMessage(
              {
                message: HOOPS_VIEWER_MESSAGE.COMMENT.UPDATE,
                data: {
                  annotationId: internalConnectUpdateData?.annotationId,
                  commentId: internalConnectUpdateData?.commentId,
                  comment: internalConnectUpdateData?.comment
                }
              },
              '*'
            );

            onSetActionAnnotationSuccess({ isActionAnnotationSuccess: true });
            break;
          }
          case CONNECT_UPDATE_TYPE.ANNOTATION_COMMENT_DELETE: {
            viewer?.contentWindow?.postMessage(
              {
                message: HOOPS_VIEWER_MESSAGE.COMMENT.DELETE,
                data: {
                  annotationId: internalConnectUpdateData?.annotationId,
                  commentId: internalConnectUpdateData?.commentId
                }
              },
              '*'
            );

            onSetActionAnnotationSuccess({ isActionAnnotationSuccess: true });
            break;
          }
        }
      }
    }
  });

  useEffect(() => {
    if (dashboard.files) {
      setSelectedFile(dashboard.files[0]);
    }
  }, [dashboard.files]);

  if (!selectedFile) return null;

  return (
    <div className={classes.root}>
      <div className={classes.main}>
        <div className={classes.iframe}>
          <iframe
            ref={iframeRef}
            id={HOOPS_VIEWER_IFRAME_ID}
            title="viewer"
            width="100%"
            src={`${AppConfig.capaViewerUrl}/viewer.html?anno&modelId=${AppConfig.capaViewerApi}/${selectedFile.drawingFile}&lang=${languageValue}`}
          />
        </div>
      </div>
    </div>
  );
};

export default AnnotationViewer;
