import { createAction } from 'typesafe-actions';
import { UploadData } from './types';

export const GET_UPLOAD_DATA = 'GET_UPLOAD_DATA';
export const getUploadData = createAction(GET_UPLOAD_DATA)<UploadData[]>();

export const ADD_UPLOAD_DATA = 'ADD_UPLOAD_DATA';
export const addUploadData = createAction(ADD_UPLOAD_DATA)<UploadData[]>();

export const UPDATE_UPLOAD_DATA = 'UPDATE_UPLOAD_DATA';
export const updateUploadData = createAction(UPDATE_UPLOAD_DATA)<UploadData>();

export const CANCEL_UPLOAD_DATA = 'CANCEL_UPLOAD_DATA';
export const cancelUploadData = createAction(CANCEL_UPLOAD_DATA)<UploadData>();

export const INIT_UPLOAD_DATA = 'INIT_UPLOAD_DATA';
export const initUploadState = createAction(INIT_UPLOAD_DATA)();
