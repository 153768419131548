import ChannelIoProvider from '@components/Provider/ChannelIoProvider';
import ActivityPage from '@routes/Activity/ActivityContainer';
import Auth from '@routes/Auth';
import {
  REDIRECT_TO,
  activityPathName,
  filesPathName,
  groupPathName,
  groupRoomPathName,
  loginPathName,
  myContactsPathName,
  myContactsRecommendedPathName,
  myDrivePathName,
  notificationSettingsPathName,
  requestPasswordPathName,
  resetPasswordPathName,
  sharedWithGroupsPathName,
  singUpPathName,
  viewerPathName
} from '@routes/Common/types';
import Contacts from '@routes/Contacts';
import Dashboard from '@routes/Dashboard';
import FilesViewer from '@routes/FilesViewer';
import GroupContainer from '@routes/Group/GroupContainer';
import GroupRoom from '@routes/GroupRoom';
import Home from '@routes/Home';
import Login from '@routes/Login';
import MyPage from '@routes/MyPage';
import NotificationSettings from '@routes/MyPage/NotificationSettings/NotificationSettings';
import NotFound from '@routes/NotFound';
import Recommended from '@routes/Recommended';
import SharedWithGroups from '@routes/SharedWithGroups';
import SignUp from '@routes/SignUp';
import Terms from '@routes/Terms';
import Viewer from '@routes/Viewer';
import history from '@utils/history';
import { isServiceNoticeCheck } from '@utils/notice';
import { STORAGE_ACTION, getStorageItem } from '@utils/storage';
import crypto from 'crypto';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import { I18nextProvider } from 'react-i18next';
import { Redirect, Route, Router, Switch, useLocation } from 'react-router-dom';
import i18n from '../../i18n';
interface Props {
  path: string | Array<string>;
  component: React.FC;
  exact?: boolean;
}

const LoginRoute = ({ component: Component, path }: Props) => {
  const userInfo = getStorageItem(STORAGE_ACTION.USER_INFO);
  const accessToken = getStorageItem(STORAGE_ACTION.CONNECT_ACCESS_TOKEN);
  const isLoggedIn = !!accessToken && !!(userInfo && userInfo.email);

  const { search } = useLocation();
  const query = queryString.parse(search);

  if (query.redirect === REDIRECT_TO.GROUP_ROOM && isLoggedIn) {
    return (
      <Redirect
        to={{
          pathname: `${groupRoomPathName}`,
          search: `?chatroomId=${query.chatroomId}&groupId=${query.groupId}`,
          state: { prevLocation: window.location.href }
        }}
      />
    );
  }

  return <Route path={path} exact={true} component={Component} />;
};

const RestrictComponent = ({ component: Component, path }: Props) => {
  const { pathname, search } = useLocation<any>();
  const userInfo = getStorageItem(STORAGE_ACTION.USER_INFO);
  const accessToken = getStorageItem(STORAGE_ACTION.CONNECT_ACCESS_TOKEN);
  const isLoggedIn = !!accessToken && !!(userInfo && userInfo.email);

  const query = queryString.parse(search);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ event: 'page_view' });

    if (!isLoggedIn) return;

    const hashUserId = crypto
      .createHmac('sha256', 'capa4!@#')
      .update(userInfo.email.toString())
      .digest('hex');

    window.dataLayer.push({
      user_id: hashUserId,
      capaUserId: userInfo.email
    });
  }, [pathname, isLoggedIn]);

  if (query.redirect === REDIRECT_TO.GROUP_ROOM && isLoggedIn) {
    return (
      <Redirect
        to={{
          pathname: `${groupRoomPathName}`,
          search: `?chatroomId=${query.chatroomId}&groupId=${query.groupId}`,
          state: { prevLocation: window.location.href }
        }}
      />
    );
  }

  if (pathname.indexOf(singUpPathName) !== -1 && !isLoggedIn) {
    return <Route path={path} exact={true} component={Component} />;
  }

  if (pathname.indexOf(singUpPathName) !== -1 && isLoggedIn) {
    return (
      <Redirect
        to={{
          pathname: '/'
        }}
      />
    );
  }

  if (
    (query.chatroomId ||
      (pathname.indexOf(groupPathName) !== -1 &&
        (query.confirmLeave === 'true' || query.confirmDelete === 'true'))) &&
    !isLoggedIn
  ) {
    return (
      <Redirect
        to={{
          pathname: loginPathName,
          search,
          state: { prevLocation: window.location.href }
        }}
      />
    );
  }

  return isLoggedIn ? (
    <Route path={path} exact={true} component={Component} />
  ) : (
    <Redirect
      to={{
        pathname: '/login',
        state: { from: pathname }
      }}
    />
  );
};

const AppPresenter = () => {
  return (
    <Router history={history}>
      <I18nextProvider i18n={i18n}>
        <ChannelIoProvider>
          <Switch>
            {isServiceNoticeCheck().isOpenUpdateNotice && (
              <Route path={'*'} component={Home} />
            )}
            <Route path="/" exact={true} component={Home} />
            <Route path="/auth" exact={true} component={Auth} />
            <RestrictComponent
              path={[myDrivePathName, `${myDrivePathName}/*`]}
              exact={true}
              component={Dashboard}
            />
            <RestrictComponent
              path={[myContactsPathName, `${myContactsPathName}/*`]}
              exact={true}
              component={Contacts}
            />
            <RestrictComponent
              path={[
                myContactsRecommendedPathName,
                `${myContactsRecommendedPathName}/*`
              ]}
              exact={true}
              component={Recommended}
            />
            <RestrictComponent
              path={[sharedWithGroupsPathName, `${sharedWithGroupsPathName}/*`]}
              exact={true}
              component={SharedWithGroups}
            />
            <RestrictComponent
              path={[groupRoomPathName, `${groupRoomPathName}/*`]}
              exact={true}
              component={GroupRoom}
            />
            <RestrictComponent
              path={[groupPathName, `${groupPathName}/*`]}
              exact={true}
              component={GroupContainer}
            />
            <RestrictComponent
              path={['/mypage', '/mypage/:page', '/mypage/:page/:info']}
              exact={true}
              component={MyPage}
            />
            <RestrictComponent
              path={viewerPathName}
              exact={true}
              component={Viewer}
            />
            <RestrictComponent
              path={activityPathName}
              exact={true}
              component={ActivityPage}
            />
            <Route
              path={['/terms', '/terms/:urlParam']}
              exact={true}
              component={Terms}
            />
            <LoginRoute
              path={loginPathName}
              exact={true}
              component={Login.Login}
            />
            <Route
              path={requestPasswordPathName}
              exact={true}
              component={Login.RequestPassword}
            />
            <RestrictComponent
              path={resetPasswordPathName}
              exact={true}
              component={Login.ResetPassword}
            />
            <RestrictComponent
              path={singUpPathName}
              exact={true}
              component={SignUp}
            />
            <RestrictComponent
              path={notificationSettingsPathName}
              exact={true}
              component={NotificationSettings}
            />
            <Route
              path={[filesPathName, `${filesPathName}/:fileIds`]}
              exact={true}
              component={FilesViewer}
            />
            <Route path={'*'} component={NotFound} />
          </Switch>
        </ChannelIoProvider>
      </I18nextProvider>
    </Router>
  );
};

export default AppPresenter;
