import React, { useEffect, useState } from 'react';
import { useStyles } from '@routes/GroupRoom/styles';
import clsx from 'clsx';
import { getStorageItem, STORAGE_ACTION } from '@utils/storage';
import { useTranslation } from 'react-i18next';
import useAuth from '@hooks/useAuth';
import { CHAT_BOT_GROUP_MESSAGE_VALUE } from '@routes/Common/types';
import DOMPurify from 'dompurify';
interface Props {
  message: any;
  isChatBotGroup: boolean;
}

const TextMessage: React.FC<Props> = ({ message, isChatBotGroup }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { userState } = useAuth();
  const userEmail = getStorageItem(STORAGE_ACTION.USER_INFO).email;
  const [messageUrlified, setMessageUrlified] = useState('');
  let chatBotGroupMessage = '';
  useEffect(() => {
    setMessageUrlified(
      message.message.message?.replace(
        /(https?:\/\/[^\s]+)/g,
        function (url: string) {
          return '<a href="' + url + '" target="_blank">' + url + '</a>';
        }
      )
    );
  }, [message.message.message]);
  switch (message.message.message) {
    case CHAT_BOT_GROUP_MESSAGE_VALUE.WELCOME: {
      chatBotGroupMessage = t('chat_room.chat_bot.welcome_message', {
        username: userState?.data?.userName
      });
      break;
    }
    case CHAT_BOT_GROUP_MESSAGE_VALUE.GUIDEBOOK: {
      chatBotGroupMessage = t('chat_room.chat_bot.guide_book_message');
      break;
    }
    case CHAT_BOT_GROUP_MESSAGE_VALUE.THANK_YOU_MESSAGE: {
      chatBotGroupMessage = t('chat_room.chat_bot.thank_you_message');
      break;
    }
    case CHAT_BOT_GROUP_MESSAGE_VALUE.CLICK_FILE_MESSAGE: {
      chatBotGroupMessage = t('chat_room.chat_bot.click_file_message');
      break;
    }
    case CHAT_BOT_GROUP_MESSAGE_VALUE.CAD_FILE_MESSAGE: {
      chatBotGroupMessage = t('chat_room.chat_bot.cad_file_message');
      break;
    }
  }

  return (
    <div
      className={clsx(
        classes.textMessage,
        message.senderEmail === userEmail
          ? classes.userMessage
          : classes.managerMessage
      )}
      dangerouslySetInnerHTML={{
        __html: isChatBotGroup
          ? chatBotGroupMessage
          : DOMPurify.sanitize(messageUrlified, {
              ALLOWED_TAGS: ['a'],
              ALLOWED_ATTR: ['target', 'href']
            })
      }}
    ></div>
  );
};

export default TextMessage;
