import React, { useState } from 'react';
import { colorSet } from '@components/Provider';
import { CustomizedTypography } from '@components/Customized';
import { GnbAppBar } from '@routes/Common';
import {
  PrivacyPolicyAgreement,
  TermsOfServiceAgreement,
  ConsentForMarketing
} from '@routes/SignUp/DialogTerms/AgreementPresenter';
import FooterPresenter from '../Common/Footer/FooterPresenter';
import { Container, Tab, Tabs, makeStyles } from '@material-ui/core';
import { MuiBox } from './styles';

export const useStyles = makeStyles({
  tabs: {
    '& .MuiTypography-body2': {
      color: colorSet.gray600
    },
    '& .MuiTab-textColorInherit.Mui-selected': {
      '& .MuiTypography-body2': {
        fontWeight: 500,
        color: colorSet.gray900
      }
    },
    '& .MuiTabs-indicator': {
      backgroundColor: colorSet.gray900
    }
  },
  container: {
    marginTop: 64,
    marginBottom: 300
  }
});

interface Props {
  urlParam?: string;
}

const TermsPresenter: React.FC<Props> = ({ urlParam }) => {
  const [value, setValue] = useState(
    urlParam
      ? urlParam === 'privacy'
        ? 1
        : urlParam === 'marketing'
        ? 2
        : 0
      : 0
  );
  const { container, tabs } = useStyles();
  return (
    <>
      <GnbAppBar />
      <Container maxWidth="md" className={container}>
        <MuiBox>
          <Tabs
            className={tabs}
            value={value}
            variant="fullWidth"
            onChange={(event, value) => setValue(value)}
          >
            <Tab
              label={
                <CustomizedTypography variant="body2">
                  서비스 이용약관
                </CustomizedTypography>
              }
            />
            <Tab
              label={
                <CustomizedTypography variant="body2">
                  개인정보처리방침
                </CustomizedTypography>
              }
            />
            <Tab
              label={
                <CustomizedTypography variant="body2">
                  마케팅정보수신동의
                </CustomizedTypography>
              }
            />
          </Tabs>
          <div className="terms-content">
            {value === 0 && <TermsOfServiceAgreement />}
            {value === 1 && <PrivacyPolicyAgreement />}
            {value === 2 && <ConsentForMarketing />}
          </div>
        </MuiBox>
      </Container>
      <FooterPresenter />
    </>
  );
};

export default TermsPresenter;
