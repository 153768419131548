import {
  OpenInBrowser,
  GetAppOutlined,
  SettingsOutlined,
  DeleteOutlineOutlined,
  LockOutlined
} from '@material-ui/icons';
import { LANGUAGE_VALUE } from '@routes/Common/ChangeLanguage/types';
import { Language } from '@generated/Common/graphql';

export const drawerWidth = 248;
export const contentHeaderHeight = 60;
export const contentMobileHeaderHeight = 68;
export const valueTabsMenu = {
  group: 0,
  drive: 1,
  contacts: 2
};

export const myDrivePathName = '/my-drive';
export const groupPathName = '/group';
export const myContactsPathName = '/my-contacts';
export const myContactsRecommendedPathName = '/recommended-contacts';
export const viewerPathName = '/viewer';
export const filesPathName = '/files';
export const loginPathName = '/login';
export const sharedWithGroupsPathName = '/shared-with-groups';
export const groupRoomPathName = '/group-room';
export const resetPasswordPathName = '/login/resetpassword';
export const requestPasswordPathName = '/login/requestpassword';
export const singUpPathName = '/signup';
export const notificationSettingsPathName = '/mypage/notification-settings';
export const notificationSettingPageType = 'notification-settings';
export const activityPathName = '/activity';
export const notFoundPathName = '/404';

export const URL_PATH_NAME = {
  MY_DRIVE: 'my-drive',
  GROUP: 'group',
  MY_CONTACTS: 'my-contacts',
  RECOMMENDED_CONTACTS: 'recommended-contacts',
  SHARED_WITH_GROUP: 'shared-with-groups'
};

export const REDIRECT_TO = {
  GROUP_ROOM: 'groupRoom'
};

export const ASIDE_MENU_PATH_NAME = {
  SHARED_WITH_GROUP: '/shared-with-group',
  PUBLIC: '/public'
};

export const DEFAULT_ITEMS_LIMIT = 40;
export const DEFAULT_ITEMS_OFFSET = 0;
export const DEFAULT_ITEMS_MAX = 99999;

export const SHARED_WITH_GROUP_FILE_CONTROL_VALUE = {
  GO_TO_GROUP: 'GO_TO_GROUP',
  DOWNLOAD: 'DOWNLOAD',
  SETTING: 'SETTING',
  DOWNLOAD_PERMISSION: 'DOWNLOAD_PERMISSION',
  REQUEST_DOWNLOAD_ACCESS: 'REQUEST_DOWNLOAD_ACCESS',
  REMOVE: 'REMOVE'
};

export const PERMISSION_DOWNLOAD = {
  ALLOW: 'ALLOW',
  DENIED: 'DENIED',
  REQUEST: 'REQUEST'
};
export const HAS_COMMENT = {
  NO: 'NO',
  YES: 'YES'
};
export const CAPAConnectSharedFileType = {
  ALL: 'ALL',
  CAD: 'CAD',
  VIDEO: 'VIDEO',
  PDF: 'PDF',
  PHOTO: 'PHOTO',
  DOCUMENT: 'DOCUMENT',
  OTHER: 'OTHER'
};
export const NODE_ENV = {
  DEVELOPMENT: 'development',
  PRODUCTION: 'production',
  QA: 'qa',
  STAGING: 'staging'
};

export const SHARED_WITH_GROUP_FILE_CONTROL_OPTIONS = [
  {
    value: SHARED_WITH_GROUP_FILE_CONTROL_VALUE.GO_TO_GROUP,
    name: 'option_share_width_group.go_to_group',
    tooltip: 'option_share_width_group.open_in_group',
    icon: OpenInBrowser
  },
  {
    value: SHARED_WITH_GROUP_FILE_CONTROL_VALUE.DOWNLOAD_PERMISSION,
    name: 'option_share_width_group.download_permission',
    tooltip: 'option_share_width_group.download_permission',
    icon: SettingsOutlined
  },
  {
    value: SHARED_WITH_GROUP_FILE_CONTROL_VALUE.DOWNLOAD,
    name: 'option_share_width_group.download',
    tooltip: 'option_share_width_group.download',
    icon: GetAppOutlined
  },
  {
    value: SHARED_WITH_GROUP_FILE_CONTROL_VALUE.REMOVE,
    name: 'option_share_width_group.remove',
    tooltip: 'option_share_width_group.remove',
    icon: DeleteOutlineOutlined
  }
];

export const SHARED_WITH_GROUP_FILE_CONTROL_ALLOW = [
  {
    value: SHARED_WITH_GROUP_FILE_CONTROL_VALUE.GO_TO_GROUP,
    name: 'option_share_width_group.go_to_group',
    tooltip: 'option_share_width_group.open_in_group',
    icon: OpenInBrowser
  },
  {
    value: SHARED_WITH_GROUP_FILE_CONTROL_VALUE.DOWNLOAD,
    name: 'option_share_width_group.download',
    tooltip: 'option_share_width_group.download',
    icon: GetAppOutlined
  },
  {
    value: SHARED_WITH_GROUP_FILE_CONTROL_VALUE.REMOVE,
    name: 'option_share_width_group.remove',
    tooltip: 'option_share_width_group.remove',
    icon: DeleteOutlineOutlined
  }
];

export const SHARED_WITH_GROUP_FILE_CONTROL_ALLOW_WITH_OUT_OWNER = [
  {
    value: SHARED_WITH_GROUP_FILE_CONTROL_VALUE.GO_TO_GROUP,
    name: 'option_share_width_group.go_to_group',
    tooltip: 'option_share_width_group.open_in_group',
    icon: OpenInBrowser
  },
  {
    value: SHARED_WITH_GROUP_FILE_CONTROL_VALUE.DOWNLOAD,
    name: 'option_share_width_group.download',
    tooltip: 'option_share_width_group.download',
    icon: GetAppOutlined
  }
];

export const SHARED_WITH_GROUP_FILE_CONTROL_ONLY_GO_TO_GROUP = [
  {
    value: SHARED_WITH_GROUP_FILE_CONTROL_VALUE.GO_TO_GROUP,
    name: 'option_share_width_group.go_to_group',
    tooltip: 'option_share_width_group.open_in_group',
    icon: OpenInBrowser
  }
];

export const SHARED_WITH_GROUP_FILE_CONTROL_NOT_OWNER_NOT_ALLOW = [
  {
    value: SHARED_WITH_GROUP_FILE_CONTROL_VALUE.GO_TO_GROUP,
    name: 'option_share_width_group.go_to_group',
    tooltip: 'option_share_width_group.open_in_group',
    icon: OpenInBrowser
  },
  {
    value: SHARED_WITH_GROUP_FILE_CONTROL_VALUE.REQUEST_DOWNLOAD_ACCESS,
    name: 'option_share_width_group.request_download_access',
    tooltip: 'option_share_width_group.request_for_download',
    icon: LockOutlined
  }
];

export const GROUP_FILE_CONTROL_OPTIONS = [
  {
    value: SHARED_WITH_GROUP_FILE_CONTROL_VALUE.SETTING,
    name: 'option_share_width_group.file_setting',
    tooltip: 'option_share_width_group.file_setting',
    icon: SettingsOutlined
  },
  {
    value: SHARED_WITH_GROUP_FILE_CONTROL_VALUE.DOWNLOAD,
    name: 'option_share_width_group.download',
    tooltip: 'option_share_width_group.download',
    icon: GetAppOutlined
  },
  {
    value: SHARED_WITH_GROUP_FILE_CONTROL_VALUE.REMOVE,
    name: 'option_share_width_group.remove',
    tooltip: 'option_share_width_group.remove',
    icon: DeleteOutlineOutlined
  }
];

export const FORMAT_DATE = 'YYYY/MM/DD';

export const MONTH_NAME = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const DAY_NAME = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

export const KEY_TYPE = {
  CLICK: 'click',
  KEY_DOWN: 'keydown'
};

export const AUTOCOMPLETE_REASON = {
  CLEAR: 'clear',
  REMOVE_OPTION: 'remove-option'
};

export const CONNECT_UPDATE_TYPE = {
  CHAT_UPDATE: 'CHAT_UPDATE',
  GROUP_UPDATE: 'GROUP_UPDATE',
  MEMBER_UPDATE: 'MEMBER_UPDATE',
  FILE_UPDATE: 'FILE_UPDATE',
  ANNOTATION_ADD: 'ANNOTATION_ADD',
  ANNOTATION_UPDATE: 'ANNOTATION_UPDATE',
  ANNOTATION_DELETE: 'ANNOTATION_DELETE',
  ANNOTATION_OPEN: 'ANNOTATION_OPEN',
  ANNOTATION_COMMENT_ADD: 'ANNOTATION_COMMENT_ADD',
  ANNOTATION_COMMENT_UPDATE: 'ANNOTATION_COMMENT_UPDATE',
  ANNOTATION_COMMENT_DELETE: 'ANNOTATION_COMMENT_DELETE'
};

export const MESSAGE_TYPE = {
  SYSTEM: 'SYSTEM',
  MESSAGE: 'MESSAGE',
  ANNOTATION: 'ANNOTATION',
  INVITE: 'INVITE',
  LEAVE: 'LEAVE',
  REMOVE_FROM_GROUP: 'REMOVE_FROM_GROUP',
  FILE_SHARED: 'FILE_SHARED',
  TUTORIAL: 'TUTORIAL'
};

export const MESSAGE_TYPE_NAME = {
  MESSAGE: 'CAPAConnectChatMessageMessage',
  INVITED: 'CAPAConnectChatMessageInvited',
  REMOVED: 'CAPAConnectChatMessageRemoved',
  FILE_SHARED: 'CAPAConnectChatMessageFileShared',
  LEAVE: 'CAPAConnectChatMessageLeave',
  ANNOTATION: 'CAPAConnectChatMessageAnnotation',
  TUTORIAL: 'CAPAConnectChatTutorialMessage'
};

export const CAPA_FILE_CONVERT_STATUS = {
  PROCESSING: 'PROCESSING',
  QUEUED: 'QUEUED',
  CONVERTING: 'CONVERTING',
  CONVERTED: 'CONVERTED',
  FAILED: 'FAILED'
};

export const chatBotSenderName = 'capa_official';
export const chatBotEmail = 'support@capa.ai';

export const CHAT_BOT_GROUP_MESSAGE_VALUE = {
  WELCOME: 'WELCOME_MESSAGE',
  GUIDEBOOK: 'GUIDEBOOK_MESSAGE',
  THANK_YOU_MESSAGE: 'THANK_YOU_MESSAGE',
  CAD_FILE_MESSAGE: 'CAD_FILE_MESSAGE',
  CLICK_FILE_MESSAGE: 'CLICK_FILE_MESSAGE'
};
export const CHAT_BOT_NAME = 'CAPA Connect';

export const blockSpecialRegex = /[~`!@#$%^&*()_={}[\]:;'",.<>+|/\\?-]/;

export const FORMAT_DATE_TIME = 'YYYY-MM-DD';

export const LANGUAGE_LIST = [
  {
    value: LANGUAGE_VALUE.KO,
    name: Language.Korea
  },
  {
    value: LANGUAGE_VALUE.EN,
    name: Language.English
  },
  {
    value: LANGUAGE_VALUE.ZH,
    name: Language.Chinese
  },
  {
    value: LANGUAGE_VALUE.VI,
    name: Language.Vietnam
  }
];

export const LIMIT_CHARACTERS_GROUP_NAME = 20;
