import { makeStyles, Menu } from '@material-ui/core';
import { colorSet } from '@components/Provider';
import styled from 'styled-components';

export const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    maxWidth: '100%',
    height: '100%',
    maxHeight: '100%',
    margin: 0,
    borderRadius: 0,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: 0,
      height: '100%',
      maxHeight: '100%',
      maxWidth: '100%',
      borderRadius: 0
    }
  },
  dialogMembers: {
    width: '100%',
    maxWidth: '100%',
    height: '100%',
    maxHeight: '100%',
    margin: 0,
    borderRadius: 0,
    '& .MuiDialog-paper': {
      maxWidth: '100% !important',
      borderRadius: 16,
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        margin: 0,
        height: '100%',
        maxHeight: '100%',
        maxWidth: '100%',
        borderRadius: 0
      }
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: 0,
      height: '100%',
      maxHeight: '100%',
      maxWidth: '100%',
      borderRadius: 0
    }
  },
  rootDialog: {
    zIndex: '9999 !important' as unknown as 9999
  },
  dialogContent: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      height: 'calc(100vh - 48px)',
      marginTop: 48
    }
  },
  contentViewer: {
    width: 'calc(100% - (710px - 284px))',
    height: 'calc(100vh - 60px)',
    overflow: 'hidden',
    '&.active': {
      width: 'calc(100% - 763px)',
      [theme.breakpoints.down('xs')]: {
        paddingLeft: 20
      }
    }
  },
  rootTitle: {
    height: 60,
    position: 'relative',
    zIndex: 11,
    backgroundColor: colorSet.gray000,
    color: colorSet.gray900,
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 500,
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 8,
    paddingRight: 8,
    boxShadow: '0px 2px 8px -1px rgba(0, 0, 0, 0.08)',
    [theme.breakpoints.down('xs')]: {
      height: 48,
      padding: '2px 6px',
      backgroundColor: colorSet.gray200,
      fontSize: 16,
      position: 'fixed',
      width: '100%',
      zIndex: 2,
      gap: 8,
      top: 0,
      boxShadow: 'none'
    }
  },
  arrowBackIosOutlinedIcon: {
    position: 'relative',
    padding: 6,
    '& svg': {
      width: 24,
      height: 24
    },
    [theme.breakpoints.down('xs')]: {
      padding: 10
    }
  },
  customTitle: {
    display: 'inline-block',
    [theme.breakpoints.down('xs')]: {
      position: 'unset',
      fontWeight: 500,
      fontSize: '15px',
      lineHeight: '21px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textAlign: 'left',
      textOverflow: 'ellipsis',
      display: 'block',
      letterSpacing: '-0.3px',
      width: '100%'
    }
  },
  memberButton: {
    textTransform: 'none',
    backgroundColor: colorSet.primary450,
    borderRadius: 8,
    fontSize: 14,
    padding: '4px 13px',
    lineHeight: '20px'
  },
  buttonSetting: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    justifyContent: 'center',
    position: 'relative',
    padding: 6,
    marginLeft: 16,
    zIndex: 1,
    '& svg': {
      width: 24,
      height: 24
    }
  },
  groupIcon: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiIconButton-root': {
      padding: 10
    },
    '& svg': {
      width: 24,
      height: 24,
      '& path': {
        fill: colorSet.gray900
      }
    }
  },
  PopUp: {
    background: 'rgba(0, 0, 0, 0.5)',
    '& .mr-10': {
      marginRight: 10
    }
  },
  PopUpFile: {
    '& .MuiListItem-root': {
      padding: '15px 0',
      background: colorSet.gray000,
      [theme.breakpoints.down('xs')]: {
        paddingTop: 0
      }
    }
  },
  iconWrapper: {
    display: 'inline-block',
    verticalAlign: 'middle',
    padding: '9.5px 10px',
    borderRadius: '8px',
    marginRight: '20px',
    backgroundColor: colorSet.gray100
  },
  arrowBackIosOutlinedWrapper: {
    backgroundColor: colorSet.primary500,
    borderRadius: 12,
    padding: '9px 15px',
    marginRight: 16
  },
  avatarGroupWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiAvatar-root': {
      marginRight: 6
    }
  },
  csDialogContentPopup: {
    width: 260,
    padding: '15px 12px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      background: `${colorSet.gray000}`,
      position: 'fixed',
      left: 0,
      bottom: 0,
      borderRadius: '8px 8px 0 0'
    },
    '.MuiPopover-root': {
      background: `${colorSet.backgroundDrop}`
    },
    '& .MuiTypography-body1': {
      marginBottom: 15,
      display: 'flex',
      flexDirection: 'row',
      cursor: 'pointer',
      color: `${colorSet.gray900}`,
      fontSize: 16,
      lineHeight: '150%'
    },
    '& .spacing': {
      marginLeft: 24
    },
    '& .name': {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 6,
      '& p': {
        color: `${colorSet.gray700}`
      }
    }
  },
  customPopover: {
    '& .MuiPopover-paper': {
      boxShadow:
        '0px 5px 5px -3px rgba(0, 0, 0, 0.1), 0px 8px 10px 1px rgba(0, 0, 0, 0.07), 0px 3px 14px 2px rgba(0, 0, 0, 0.06)'
    },
    [theme.breakpoints.down('xs')]: {
      background: `${colorSet.backgroundDrop}`,
      '& .MuiPopover-paper': {
        boxShadow: 'none',
        background: 'transparent',
        minHeight: 'auto'
      }
    }
  },
  mainContentRightGroupHover: {
    position: 'relative',
    '& .custom-hover': {
      display: 'none'
    },
    '&:hover': {
      '& .custom-hover': {
        width: 216,
        display: 'block',
        position: 'absolute',
        bottom: -53,
        left: 0,
        background: `${colorSet.gray900}`,
        borderRadius: 8,
        padding: 8,
        color: `${colorSet.gray000}`,
        fontSize: 14,
        lineHeight: 1.2
      }
    }
  },
  avatarGroup: {
    '& .MuiAvatar-root': {
      fontSize: 16,
      fontWeight: 500
    },
    '& .MuiAvatarGroup-avatar': {
      width: '36px',
      height: '36px',
      marginLeft: '0 !important',
      marginRight: '6px !important',
      border: 'none !important',
      textTransform: 'uppercase'
    }
  },
  activeSearchResult: {
    border: `2px solid ${colorSet.primary500}`,
    backgroundColor: colorSet.primary100
  },
  pointerCursor: {
    cursor: 'pointer'
  },
  chatMessageWrapper: {
    position: 'fixed',
    top: 60,
    right: 0,
    width: 375,
    height: 'calc(100vh - 60px)',
    borderLeft: `1px solid ${colorSet.gray400}`,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      height: '100%',
      position: 'inherit',
      width: '100%'
    }
  },
  chatBox: {
    backgroundColor: colorSet.gray200,
    height: '100%',
    padding: '28px 16px 12px 16px',
    display: 'flex',
    flexDirection: 'column',
    scrollBehavior: 'auto',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: 4
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: colorSet.gray600,
      borderRadius: 8
    }
  },
  actionWrapper: {
    marginTop: 'auto',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 60,
      padding: '9px 16px 9px 22px'
    }
  },
  sendButtonMobile: {
    position: 'absolute',
    right: 28
  },
  csSendButtonMobile: {
    color: colorSet.primary500,
    pointerEvents: 'auto'
  },
  disabledSendButtonMobile: {
    color: colorSet.gray600,
    pointerEvents: 'none'
  },
  actionBar: {
    height: 43,
    backgroundColor: colorSet.gray200,
    borderTop: `1px solid ${colorSet.gray400}`,
    borderBottom: `1px solid ${colorSet.gray400}`,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 8
  },
  attachFileButton: {
    width: 9,
    minWidth: 'auto',
    marginLeft: 14,
    padding: 0,
    '&:hover': {
      backgroundColor: colorSet.gray200
    },
    '& .MuiTouchRipple-root': {
      width: 0
    }
  },
  addCircleOutlineRoundedIcon: {
    color: colorSet.gray600,
    marginRight: 22
  },
  attachFileIcon: {
    color: colorSet.gray600,
    width: 20,
    cursor: 'pointer'
  },
  sendMessageBox: {
    height: 100,
    padding: 12,
    display: 'flex',
    alignItems: 'center'
  },
  messageTextField: {
    height: '100%',
    overflow: 'hidden',
    flexGrow: 1,
    paddingRight: 8,
    '& .MuiInputBase-root': {
      alignItems: 'start',
      fontSize: 14,
      height: '100%',
      '&::before': {
        display: 'none'
      },
      '&::after': {
        display: 'none'
      }
    },
    [theme.breakpoints.down('xs')]: {
      height: 44,
      border: `1px solid ${colorSet.gray400}`,
      borderRadius: 16,
      '& .MuiInputBase-root': {
        paddingLeft: 16,
        paddingRight: 30,
        height: '100%',
        alignItems: 'center'
      }
    }
  },
  disabledMessageTextField: {
    backgroundColor: colorSet.gray100,
    overflow: 'hidden'
  },
  sendButton: {
    width: 68,
    height: 68,
    borderRadius: 16,
    textTransform: 'none',
    '&.Mui-disabled': {
      backgroundColor: colorSet.gray100,
      border: `1px solid ${colorSet.gray400}`
    }
  },
  textMessageWrapper: {
    display: 'flex',
    fontSize: 14,
    '&:first-child': {
      marginTop: 'auto'
    },
    '&:last-child': {
      marginBottom: 0
    }
  },
  textMessageMarginLeft: {
    marginLeft: 'auto'
  },
  chatBoxTextMessageMarginBottom: {
    marginBottom: 12
  },
  textMessageMarginBottom: {
    marginBottom: 16
  },
  notificationMessage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 16,
    color: colorSet.gray600,
    fontSize: 12,
    wordBreak: 'break-word'
  },
  avatar: {
    width: 32,
    height: 32,
    fontSize: 16,
    textTransform: 'uppercase'
  },
  groupChatBotAvatar: {
    width: 32,
    height: 32
  },
  beginDay: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 16,
    fontSize: 12,
    backgroundColor: colorSet.gray500,
    color: colorSet.gray000,
    padding: '8px 10px',
    borderRadius: 16,
    '&:first-child': {
      marginTop: 'auto'
    }
  },
  textMessage: {
    maxWidth: 230,
    padding: '12px 16px',
    wordBreak: 'break-word',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '144%',
    letterSpacing: '-0.3px',
    whiteSpace: 'break-spaces',
    '& a': {
      wordBreak: 'break-word',
      textDecoration: 'underline',
      color: colorSet.primary450
    }
  },
  buttonMessage: {
    maxWidth: 230,
    padding: '6px 12px',
    wordBreak: 'break-word',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '143%',
    letterSpacing: '-0.1px',
    borderRadius: 56,
    border: `1px solid ${colorSet.primary450}`,
    backgroundColor: colorSet.gray000,
    color: colorSet.primary450,
    cursor: 'pointer'
  },
  '@keyframes ripple': {
    '0%': {
      opacity: 1,
      width: '100%',
      height: '100%'
    },
    '50%': {
      opacity: 0,
      width: 'calc(100% + 20px)',
      height: 'calc(100% + 20px)'
    },
    '100%': {
      opacity: 0,
      width: '100%',
      height: '100%'
    }
  },
  animationButtonMessage: {
    borderRadius: 56,
    margin: '0 5px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colorSet.gray000,
    '& span': {
      content: '',
      position: 'absolute',
      width: '100%',
      height: '100%',
      borderRadius: 56,
      zIndex: -1,
      backgroundColor: colorSet.primary400,
      animation: `$ripple 2.0s ease-out infinite`
    },
    '&:first-child': {
      transform: 'scale(1)'
    }
  },
  userMessage: {
    backgroundColor: colorSet.primary300,
    borderRadius: '10px 0 10px 10px'
  },
  managerMessage: {
    backgroundColor: colorSet.gray000,
    borderRadius: '0 10px 10px 10px'
  },
  messageTime: {
    display: 'block',
    marginTop: 'auto',
    color: colorSet.gray600,
    fontSize: 12
  },
  userMessageTime: {
    marginRight: 8
  },
  managerMessageTime: {
    marginLeft: 8
  },
  messageBox: {
    display: 'flex',
    position: 'relative'
  },
  managerName: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '18px',
    letterSpacing: '-0.1px',
    color: colorSet.gray700,
    marginBottom: 4
  },
  managerBox: {
    marginLeft: 12
  },
  marginManagerRegroupBox: {
    marginLeft: 44
  },
  managerAvatar: {
    width: 32,
    height: 32
  },
  userReadNumber: {
    display: 'flex',
    justifyContent: 'end',
    color: colorSet.primary500,
    marginBottom: 4
  },
  managerReadNumber: {
    display: 'flex',
    justifyContent: 'start',
    color: colorSet.primary500,
    marginBottom: 4
  },
  imageMessageWrapper: {
    userDrag: 'none',
    width: 230,
    cursor: 'pointer',
    '& .loading-wrapper': {
      height: '163.97px',
      width: '100%',
      background:
        'linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1))',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '10px 10px 0px 0px'
    },
    '& .image-wrapper': {
      height: '163.97px',
      width: '100%',
      '& .click-to-see': {
        position: 'absolute',
        width: 230,
        height: '163.97px',
        borderRadius: '10px',
        top: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background:
          'linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4))',
        '& .content': {
          textAlign: 'center',
          '& .text': {
            fontWeight: 400,
            fontSize: 13,
            lineHeight: '138%',
            letterSpacing: '-0.1px',
            textAlign: 'center',
            color: colorSet.gray000
          },
          '& img': {
            width: 24,
            height: 24,
            marginBottom: 2
          }
        }
      },
      '& img': {
        height: '100%',
        width: '100%',
        borderRadius: '10px 10px 0px 0px'
      }
    },
    '& .action-button-wrapper': {
      display: 'flex',
      justifyContent: 'center',
      height: 52
    }
  },
  portrait: {
    userDrag: 'none',
    width: 120,
    borderRadius: 10,
    cursor: 'pointer'
  },
  landscape: {
    userDrag: 'none',
    width: 230,
    height: 104,
    borderRadius: 10,
    cursor: 'pointer'
  },
  twoImages: {
    width: 120,
    height: 104,
    userDrag: 'none',
    '&:nth-child(1)': {
      marginRight: 4,
      borderRadius: '10px 0 0 10px'
    },
    '&:nth-child(2)': {
      borderRadius: '0 10px 10px 0'
    }
  },
  fourImages: {
    width: 120,
    height: 104,
    userDrag: 'none',
    '&:nth-child(1)': {
      marginRight: 2,
      borderRadius: '10px 0 0 0'
    },
    '&:nth-child(2)': {
      borderRadius: '0 10px 0 0'
    },
    '&:nth-child(3)': {
      marginRight: 2,
      borderRadius: '0 0 0 10px',
      marginTop: 4
    },
    '&:nth-child(4)': {
      borderRadius: '0 0 10px 0',
      marginTop: 4
    },
    gridColumn: 'span 2'
  },
  moreThanFourImages: {
    width: 120,
    height: 104,
    userDrag: 'none',
    '&:nth-child(odd)': {
      marginRight: 4,
      borderRadius: '10px 0 0 10px'
    },
    '&:nth-child(even)': {
      borderRadius: '0 10px 10px 0'
    },
    '&:nth-child(1)': {
      borderBottomLeftRadius: 0
    },
    '&:nth-child(2)': {
      borderBottomRightRadius: 0
    },
    '&:nth-child(n+3)': {
      marginTop: 4,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0
    }
  },
  centered: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontWeight: 700,
    fontSize: 18,
    lineHeight: '132%',
    letterSpacing: '-0.3px',
    color: colorSet.gray000
  },
  threeImages: {
    userDrag: 'none',
    '&:nth-child(1)': {
      marginRight: 2,
      borderRadius: '10px 0 0 0',
      gridRowStart: 1,
      gridColumn: 'span 2',
      width: 120,
      height: 104
    },
    '&:nth-child(2)': {
      borderRadius: '0 10px 0 0',
      gridRowStart: 1,
      gridColumn: 'span 2',
      width: 120,
      height: 104
    },
    '&:nth-child(3)': {
      marginTop: 4,
      width: 230,
      height: 104,
      borderRadius: '0px 0px 10px 10px',
      gridRowStart: 2,
      gridColumn: 'span 4'
    }
  },

  threeImagesWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    justifyItems: 'end'
  },
  fourImagesWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    justifyItems: 'end'
  },
  moreThanFourImagesWrapper: {
    width: 230
  },
  fileMessageWrapper: {
    width: 230,
    borderRadius: 10,
    padding: 16,
    display: 'flex',
    backgroundColor: colorSet.gray000,
    alignItems: 'center',
    cursor: 'pointer'
  },
  fileContent: {
    width: '100%'
  },
  fileTitle: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
    '& .download-button-wrapper': {
      width: 32,
      height: 32,
      border: `1px solid ${colorSet.gray200}`,
      borderRadius: 24,
      flexShrink: 0,
      marginLeft: 'auto',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& img': {
        width: 16,
        height: 16
      },
      '&:hover': {
        backgroundColor: colorSet.gray100
      }
    },
    '& .loading-wrapper': {
      width: 32,
      height: 32,
      borderRadius: 24,
      flexShrink: 0,
      marginLeft: 'auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  fileName: {
    fontWeight: 500,
    fontSize: 16,
    marginRight: 8,
    color: colorSet.gray900,
    wordBreak: 'break-all',
    lineHeight: '150%',
    letterSpacing: '-0.1px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: 3,
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical'
  },
  fileSize: {
    color: colorSet.gray700,
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '18px',
    letterSpacing: '-0.1px',
    '&.mb-16': {
      marginBottom: 16
    }
  },
  fileIconWrapper: {
    width: 32,
    height: 32,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '100%',
    backgroundColor: colorSet.gray100
  },
  closeOutlinedIcon: {
    color: colorSet.gray500
  },
  insertDriveFileImg: {
    marginLeft: 8
  },
  leftMenu: {
    zIndex: 10,
    [theme.breakpoints.down('xs')]: {
      height: '45%',
      marginRight: 0,
      borderBottom: `1px solid ${colorSet.gray400}`
    }
  },
  requestDownload: {
    backgroundColor: colorSet.gray000,
    borderRadius: '10px 0 10px 10px',
    maxWidth: 230,
    padding: 16
  },
  requestDownloadTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 8,
    color: colorSet.primary500
  },
  requestDownloadContent: {
    fontSize: 14,
    lineHeight: '143%',
    color: colorSet.gray700
  },
  partnerHasRequested: {
    fontSize: 14,
    lineHeight: '143%'
  },
  requestDownloadManager: {
    backgroundColor: colorSet.gray000,
    borderRadius: '0 10px 10px 10px',
    maxWidth: 230,
    padding: 16
  },
  grantPermissionButton: {
    textTransform: 'none',
    marginTop: 16
  },

  divider: {
    height: 1,
    margin: '8px 0',
    background: colorSet.gray300
  }
}));

export const AttachFileButtonMenu = styled(Menu)`
  ${({ theme }) => `
  & .MuiPaper-root {
    ${theme.breakpoints.down('xs')} {
      left: 0 !important;
      top: auto !important;
      bottom: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      width: 100%;
      max-width: 100% !important;
    }
    border-radius: 6px;
    min-width: 343px;
    box-shadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px';

    & .MuiMenu-list {
      padding: 0;
    }

    & .MuiMenuItem-root {
      padding: '20px';
      padding-bottom: 20px;

      & .MuiSvgIcon-root {
        font-size: 18px;
        margin-right: 10px;
      }
    }
  }
  `}
`;

export const PopupMenu = styled(Menu)`
  ${({ theme }) => `
  & .MuiPaper-root {
    ${theme.breakpoints.down('xs')} {
      left: 0 !important;
      top: auto !important;
      bottom: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      width: 100%;
      max-width: 100% !important;
     border-radius: 12px 12px 0px 0px;
    }
    border-radius: 8px;
    min-width: 256px;
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.1), 0px 8px 10px 1px rgba(0, 0, 0, 0.07), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);

    & .MuiMenu-list {
      padding: 8px 0;
    }

    & .MuiMenuItem-root {
      padding: 8px 16px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.1px;
      & svg {
        width: 20px;
        height: 20px;
        margin-right: 12px;
      }
    }
    }
  }
  `}
`;
