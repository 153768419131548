import {
  ServiceSecurity,
  ServiceComment,
  ServiceRealTime,
  ServiceShare,
  ServiceNotice,
  ServiceAnnotation,
  DesktopImageItem1,
  DesktopImageItem2,
  DesktopImageItem3,
  DesktopGifItem1,
  DesktopGifItem2,
  DesktopGifItem3,
  MobileImageItemEn1,
  MobileImageItemKo1,
  MobileImageItemEn2,
  MobileImageItemKo2,
  MobileImageItemEn3,
  MobileImageItemKo3
} from './assets';

export type ItemProps = {
  icon: string;
  title: string;
  subTitle: string;
};
export type ItemFAQ = {
  id: number;
  question: string;
  answer: string;
};

export const DataServiceItems = [
  {
    icon: ServiceSecurity,
    title: 'landing_page.services_section.security_title',
    subTitle: 'landing_page.services_section.security_sub_title'
  },
  {
    icon: ServiceComment,
    title: 'landing_page.services_section.comment_title',
    subTitle: 'landing_page.services_section.comment_sub_title'
  },
  {
    icon: ServiceRealTime,
    title: 'landing_page.services_section.real_time_title',
    subTitle: 'landing_page.services_section.real_time_sub_title'
  },
  {
    icon: ServiceShare,
    title: 'landing_page.services_section.share_title',
    subTitle: 'landing_page.services_section.share_sub_title'
  },
  {
    icon: ServiceNotice,
    title: 'landing_page.services_section.notice_title',
    subTitle: 'landing_page.services_section.notice_sub_title'
  },
  {
    icon: ServiceAnnotation,
    title: 'landing_page.services_section.annotation_title',
    subTitle: 'landing_page.services_section.annotation_sub_title'
  }
];
export const DataFAQItems = [
  {
    id: 1,
    question: 'landing_page.faq_section.question-1',
    answer: 'landing_page.faq_section.answer-1'
  },
  {
    id: 2,
    question: 'landing_page.faq_section.question-2',
    answer: 'landing_page.faq_section.answer-2'
  },
  {
    id: 3,
    question: 'landing_page.faq_section.question-3',
    answer: 'landing_page.faq_section.answer-3'
  }
];

export const COLLABORATION = 'COLLABORATION';
export const ONLINE_CAD_VIEWER = 'ONLINE CAD VIEWER';
export const SECURE_YOUR_FILES = 'SECURE YOUR FILES';
export const USER_STAYED_PAGE_TIME = 5000;

export const CAROUSEL_ITEM_VALUE = {
  COLLABORATION: 0,
  CARD_VIEWER: 1,
  DOWNLOAD_PERMISSION: 2
};

export const CAROUSEL_ITEMS = [
  {
    img: DesktopImageItem1,
    gif: DesktopGifItem1,
    mobileImgEn: MobileImageItemEn1,
    mobileImgKo: MobileImageItemKo1,
    title: 'landing_page.carousel.title_item_1',
    subTitle: 'landing_page.carousel.sub_title_item_1',
    content: 'landing_page.carousel.content_item_1'
  },
  {
    img: DesktopImageItem2,
    gif: DesktopGifItem2,
    mobileImgEn: MobileImageItemEn2,
    mobileImgKo: MobileImageItemKo2,
    title: 'landing_page.carousel.title_item_2',
    subTitle: 'landing_page.carousel.sub_title_item_2',
    content: 'landing_page.carousel.content_item_2'
  },
  {
    img: DesktopImageItem3,
    gif: DesktopGifItem3,
    mobileImgEn: MobileImageItemEn3,
    mobileImgKo: MobileImageItemKo3,
    title: 'landing_page.carousel.title_item_3',
    subTitle: 'landing_page.carousel.sub_title_item_3',
    content: 'landing_page.carousel.content_item_3'
  }
];
