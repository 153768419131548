import React, { useEffect, useRef, useState, useLayoutEffect } from 'react';
import clsx from 'clsx';
import Empty from './assets/empty.png';
import { CustomizedTypography } from '@components/Customized';
import {
  NotificationProps,
  SERVICE,
  GROUP_CONNECT_TYPE,
  DEFAULT_ITEMS_LIMIT,
  DEFAULT_ITEMS_OFFSET,
  DEFAULT_LAST_SEQ,
  DO_NOT_SHOW_NOTIFICATION
} from './types';
import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  Divider,
  CircularProgress
} from '@material-ui/core';
import { CheckCircleOutlineOutlined } from '@material-ui/icons';
import { useStyles } from './useStyles';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@components/Provider';
import RemoveFormGroup from '@routes/Dashboard/Dialog/notification/RemoveFormGroup/RemoveFormGroup';
import useMyDrive from '@hooks/Drive/MyDrive/useMyDrive';
import {
  SubscribeCAPANotification,
  ReadNotification,
  ClearNewNotification
} from '@generated/Drive/MyDrive/graphql';
import { groupRoomPathName } from '@routes/Common/types';
import useCommon from '@hooks/Common/useCommon';
import { useHistory } from 'react-router-dom';
import useSharedWithGroups from '@hooks/Drive/MyDrive/useSharedWithGroups';
import * as Apollo from '@apollo/client';
import { CapaErrorCode } from '@generated/Common/graphql';
import { ACCESS_LEVEL_SHOW_OPTIONS } from '@routes/Dashboard/Dialog/Share/types';
import { formatTimeStamp } from '@utils/formatter';
import DownloadPermissionDialog from '@routes/Dashboard/Dialog/DownloadPermission/DownloadPermission';
import FullPageEmpty from './FullPageEmpty';
import useNotification from '@hooks/useNotification';

const NotificationPresenter = (props: NotificationProps) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const history = useHistory();
  const { onShowHideNumberNotice } = useCommon();

  const [groupRemove, setGroupRemove] = useState({});
  const [isShowHideRemoveGroupDialog, setShowHideRemoveGroupDialog] =
    useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const { onFetchGroupData } = useSharedWithGroups();
  const {
    onToggleRemoveGroupFiles,
    myDrive,
    onSetTotalNotification,
    onSetShowHideDownloadPermissionDialog,
    onSetSelectedGroupShared
  } = useMyDrive();
  const {
    onFetchCAPANotification,
    connectNotification,
    subscribeNotificationToMore,
    onFetchMoreConnectNotification,
    connectNotificationLoading
  } = useNotification();
  const classes = useStyles({
    open: props.open,
    mobile
  });
  const [internalConnectNotification, setInternalConnectNotification] =
    useState<any>([]);
  const searchResultRef = useRef<any>(null);
  const [readNotification] = Apollo.useMutation(ReadNotification);
  const [clearNewNotification] = Apollo.useMutation(ClearNewNotification);
  const { data: subscribeCAPANotification } = Apollo.useSubscription(
    SubscribeCAPANotification,
    {
      variables: { serviceType: SERVICE.CONNECT }
    }
  );
  useEffect(() => {
    setInternalConnectNotification([]);
  }, []);

  useEffect(() => {
    if (
      connectNotification &&
      connectNotification?.fetchCAPANotification &&
      connectNotification?.fetchCAPANotification?.data &&
      connectNotification?.fetchCAPANotification?.data?.notifications.length >
        0 &&
      !isFetching
    ) {
      setInternalConnectNotification(
        connectNotification?.fetchCAPANotification?.data?.notifications ?? []
      );
    }

    onSetTotalNotification({
      totalNotification:
        connectNotification?.fetchCAPANotification?.data?.count || 0
    });
  }, [connectNotification?.fetchCAPANotification?.data?.notifications]);

  useEffect(() => {
    onFetchCAPANotification({
      variables: {
        limit: DEFAULT_ITEMS_LIMIT,
        offset: DEFAULT_ITEMS_OFFSET,
        service: SERVICE.CONNECT,
        lastSeq: DEFAULT_LAST_SEQ
      }
    });

    return () => {
      setShowHideRemoveGroupDialog(false);
    };
  }, []);

  const fillTextNotification = (groupItem: any) => {
    switch (groupItem?.notificationType) {
      case GROUP_CONNECT_TYPE.CONNECT_INVITE_USER: {
        return {
          title: t('notification_popup.notification_title.group_invitation'),
          type: t('notification_popup.group_type.groups'),
          color: 'colorGroup'
        };
      }
      case GROUP_CONNECT_TYPE.CONNECT_ANNOTATION_COMMENT: {
        return {
          title: t('notification_popup.notification_title.replies'),
          type: t('notification_popup.group_type.comments'),
          color: 'colorComment'
        };
      }
      case GROUP_CONNECT_TYPE.CONNECT_DOWNLOAD_ACCESS: {
        return {
          title: t(
            'notification_popup.notification_title.download_access_request',
            { userName: groupItem?.userName, fileName: groupItem?.fileName }
          ),
          type: t('notification_popup.group_type.files'),
          color: 'colorFile'
        };
      }

      case GROUP_CONNECT_TYPE.CONNECT_DOWNLOAD_GRANTED: {
        return {
          title: t(
            'notification_popup.notification_title.download_access_granted',
            { fileName: groupItem?.fileName }
          ),
          type: t('notification_popup.group_type.files'),
          color: 'colorFile'
        };
      }

      case GROUP_CONNECT_TYPE.CONNECT_REMOVE_FROM_GROUP: {
        return {
          title: t('notification_popup.notification_title.removed_from_group'),
          type: t('notification_popup.group_type.groups'),
          color: 'colorGroup'
        };
      }

      case GROUP_CONNECT_TYPE.CONNECT_ACCESS_CHANGE: {
        return {
          title: t('notification_popup.notification_title.group_permission', {
            accessLevel: ACCESS_LEVEL_SHOW_OPTIONS.filter(
              (option: any) => option.value === groupItem.accessLevel
            )[0]
              ? t(
                  ACCESS_LEVEL_SHOW_OPTIONS.filter(
                    (option: any) => option.value === groupItem.accessLevel
                  )[0].name
                )
              : null
          }),
          type: t('notification_popup.group_type.groups'),
          color: 'colorGroup'
        };
      }

      case GROUP_CONNECT_TYPE.CONNECT_CHANGE_GROUP_NAME: {
        return {
          title: t('notification_popup.notification_title.change_group_name', {
            oldGroupName: groupItem?.oldGroupName,
            groupName: groupItem?.groupName
          }),
          type: t('notification_popup.group_type.groups'),
          color: 'colorGroup'
        };
      }

      default: {
        break;
      }
    }
  };

  const handleClickNotice = (group: any) => {
    switch (group.notificationType) {
      case GROUP_CONNECT_TYPE.CONNECT_REMOVE_FROM_GROUP:
        if (group?.toBeShown === DO_NOT_SHOW_NOTIFICATION) return;
        setGroupRemove(group);
        onFetchGroupData({
          variables: {
            groupId: group?.groupId
          }
        });
        setShowHideRemoveGroupDialog(true);
        onToggleRemoveGroupFiles({ isShowRemoveGroupFiles: true });
        break;
      case GROUP_CONNECT_TYPE.CONNECT_DOWNLOAD_ACCESS:
        if (mobile) {
          const payload = {
            groupId: group.groupId,
            groupName: group.groupName,
            connectFileId: group.connectFileId
          };
          onSetSelectedGroupShared({ selectedGroupShared: payload });
          onSetShowHideDownloadPermissionDialog({
            isShowHideDownloadPermissionDialog: true
          });
          return;
        }
        history.push({
          pathname: `${groupRoomPathName}`,
          search: `?chatroomId=${group.chatRoomId}&groupId=${group.groupId}&showDownloadPermission=true&fileId=${group.connectFileId}`,
          state: { prevLocation: window.location.href }
        });
        break;
      case GROUP_CONNECT_TYPE.CONNECT_ANNOTATION_COMMENT:
        if (mobile)
          return props?.onShowDownloadApp && props.onShowDownloadApp();
        const paramSearch = `?chatroomId=${group.chatRoomId}&groupId=${group.groupId}&fileId=${group.connectFileId}&annotationId=${group.annotationId}`;
        history.push({
          pathname: `${groupRoomPathName}`,
          search: paramSearch,
          state: { prevLocation: window.location.href }
        });
        break;
      case GROUP_CONNECT_TYPE.CONNECT_DOWNLOAD_GRANTED:
        if (mobile)
          return props?.onShowDownloadApp && props.onShowDownloadApp();
        history.push({
          pathname: `${groupRoomPathName}`,
          search: `?chatroomId=${group.chatRoomId}&groupId=${group.groupId}&fileId=${group.connectFileId}`,
          state: { prevLocation: window.location.href }
        });
        break;
      default:
        history.push({
          pathname: `${groupRoomPathName}`,
          search: `?chatroomId=${group.chatRoomId}&groupId=${group.groupId}&firstItem=true`,
          state: { prevLocation: window.location.href }
        });
        break;
    }
  };

  const onScrollContent = () => {
    const currentSearchResultRef = searchResultRef && searchResultRef.current;
    if (
      Math.ceil(currentSearchResultRef.scrollTop) +
        currentSearchResultRef.clientHeight !==
        currentSearchResultRef.scrollHeight ||
      isFetching
    )
      return;

    setIsFetching(true);
  };

  useEffect(() => {
    if (props.open) {
      onFetchCAPANotification({
        variables: {
          limit: DEFAULT_ITEMS_LIMIT,
          offset: DEFAULT_ITEMS_OFFSET,
          service: SERVICE.CONNECT,
          lastSeq: DEFAULT_LAST_SEQ
        }
      });

      clearNewNotification({
        variables: {
          service: SERVICE.CONNECT,
          seq: null
        }
      });
    }
  }, [props.open]);

  useEffect(() => {
    if (!isFetching) return;
    setTimeout(() => {
      if (onFetchMoreConnectNotification) {
        onFetchMoreConnectNotification({
          variables: {
            limit: DEFAULT_ITEMS_LIMIT,
            offset: internalConnectNotification.length,
            service: SERVICE.CONNECT,
            lastSeq: 0
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) {
              return prev;
            }

            const prevFiles = prev.fetchCAPANotification.data.notifications;
            const moreFiles =
              fetchMoreResult.fetchCAPANotification.data?.notifications;

            if (fetchMoreResult.fetchCAPANotification.data.notifications)
              fetchMoreResult.fetchCAPANotification.data.notifications = [
                ...prevFiles!,
                ...moreFiles!
              ];

            setInternalConnectNotification(
              fetchMoreResult.fetchCAPANotification.data?.notifications ?? []
            );
            return {
              ...fetchMoreResult
            };
          }
        });
      }
      setIsFetching(false);
    }, 500);
  }, [isFetching]);

  useLayoutEffect(() => {
    if (subscribeNotificationToMore !== undefined) {
      subscribeNotificationToMore &&
        subscribeNotificationToMore({
          document: SubscribeCAPANotification,
          variables: { serviceType: SERVICE.CONNECT },
          updateQuery: (prev, { subscriptionData }) => {
            if (!subscriptionData) return prev;
            onSetTotalNotification({
              totalNotification:
                subscriptionData?.data?.subscribeCAPANotification?.data?.count
            });

            return prev;
          }
        });
    }
  }, [subscribeNotificationToMore]);

  useLayoutEffect(() => {
    if (subscribeCAPANotification) {
      onShowHideNumberNotice({
        isShowNumberNotification: true
      });
      onSetTotalNotification({
        totalNotification:
          subscribeCAPANotification?.subscribeCAPANotification?.data?.count
      });
    }
  }, [subscribeCAPANotification]);

  const filterNotificationHidden = (notifications: any) => {
    return notifications
      ? notifications.filter(
          (notice: any) => notice.data.toBeShown !== DO_NOT_SHOW_NOTIFICATION
        )
      : [];
  };

  useEffect(() => {
    if (connectNotification?.fetchCAPANotification?.data?.notifications) {
      setInternalConnectNotification(
        connectNotification?.fetchCAPANotification?.data?.notifications
          ? filterNotificationHidden(
              connectNotification?.fetchCAPANotification?.data?.notifications
            )
          : []
      );
    }
  }, [connectNotification?.fetchCAPANotification?.data?.notifications]);

  const onChangeAllViewed = (isShowSnackbar = true) => {
    readNotification({
      variables: {
        service: SERVICE.CONNECT,
        seq: null
      }
    }).then((res: any) => {
      if (res.data.readNotification.result === CapaErrorCode.Success) {
        onFetchCAPANotification({
          variables: {
            limit: DEFAULT_ITEMS_LIMIT,
            offset: DEFAULT_ITEMS_OFFSET,
            service: SERVICE.CONNECT,
            lastSeq: DEFAULT_LAST_SEQ
          }
        });
        if (isShowSnackbar)
          enqueueSnackbar(t('snackbar.mark_all_as_read'), {
            variant: 'success'
          });
      }
    });
  };

  return props.open ? (
    <Grid
      container
      ref={props.divRef}
      className={
        myDrive.isShowRemoveGroupFiles
          ? classes.wrapperHide
          : !props?.isFullPage
          ? classes.wrapper
          : classes.normalWrapper
      }
    >
      <Box
        display={props.isFullPage ? 'none' : 'flex'}
        className={
          internalConnectNotification && internalConnectNotification.length > 0
            ? classes.headerNoBorder
            : classes.header
        }
      >
        <Typography component={'div'} variant="body1" className={classes.title}>
          {t('notification_popup.title')}
          <Box display="flex" alignItems="center">
            <Button
              className={classes.settingBtn}
              onClick={() => onChangeAllViewed(true)}
            >
              <CheckCircleOutlineOutlined className={classes.iconCheck} />
              {t('notification_popup.title_check_all')}
            </Button>
          </Box>
        </Typography>
        <Typography variant="body2" className={classes.subtitle}>
          {t('notification_popup.sub_title')}
        </Typography>
        <Divider light></Divider>
      </Box>
      {connectNotificationLoading ? (
        <Box className={classes.loadingWrapper}>
          <CircularProgress color="primary" size={30} />
        </Box>
      ) : internalConnectNotification &&
        internalConnectNotification.length > 0 ? (
        <div
          className={
            props.isFullPage
              ? classes.notificationFullPage
              : classes.notification
          }
          onScroll={onScrollContent}
          ref={searchResultRef}
        >
          {internalConnectNotification &&
            internalConnectNotification.map((item: any, index: any) => (
              <div
                key={index}
                className={clsx(
                  classes.notificationList,
                  item.read ? '' : classes.unreadNotification
                )}
                onClick={() => {
                  readNotification({
                    variables: {
                      service: SERVICE.CONNECT,
                      seq: item.seq
                    }
                  });
                  handleClickNotice(item.data);
                }}
              >
                <div className={classes.notificationTop}>
                  <div className={classes.notificationTitle}>
                    <span className={fillTextNotification(item.data)?.color}>
                      {fillTextNotification(item.data)?.type}
                    </span>
                  </div>
                  <div className={classes.notificationDate}>
                    {formatTimeStamp(item.createdAt, true, false, false, true)}
                  </div>
                </div>
                <div className={classes.notificationDesc}>
                  {fillTextNotification(item.data)?.title}
                </div>
                <div className={classes.notificationName}>
                  {item.data.groupName}
                </div>
                <div className={classes.notificationName}>
                  {item.data.comment}
                </div>
              </div>
            ))}
        </div>
      ) : (
        !connectNotificationLoading &&
        (!props?.isFullPage ? (
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.emptyWrapper}
          >
            <img
              src={Empty}
              alt=""
              width={102}
              height={102}
              className={classes.emptyImage}
            />
            <CustomizedTypography
              variant="body2"
              color="textPrimary"
              fontWeight="500"
            >
              {t('notification_popup.no_data')}
            </CustomizedTypography>
          </Grid>
        ) : (
          <FullPageEmpty />
        ))
      )}
      {isShowHideRemoveGroupDialog && (
        <RemoveFormGroup
          groupName={groupRemove}
          isOpen={isShowHideRemoveGroupDialog}
          onClose={() => {
            setShowHideRemoveGroupDialog(false);
            onToggleRemoveGroupFiles({ isShowRemoveGroupFiles: false });
            onFetchCAPANotification({
              variables: {
                limit: DEFAULT_ITEMS_LIMIT,
                offset: DEFAULT_ITEMS_OFFSET,
                service: SERVICE.CONNECT,
                lastSeq: DEFAULT_LAST_SEQ
              }
            });
            !props.isFullPage &&
              props.onClose({
                open: false
              });
          }}
        />
      )}
      <DownloadPermissionDialog groupData={groupRemove} />
    </Grid>
  ) : (
    <React.Fragment />
  );
};

export default NotificationPresenter;
